import Button from 'devextreme-react/button';
import React, {
  useState,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ConfirmDialog from '../../../components/common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../types/dialog';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { MarsApiService as api } from '../../../api/mars-api-service';
import VesselInfoBanner from '../../vessel-info/VesselInfoBanner';
import PortForm from '../../../components/berth-fit-check/port-form/PortForm';
import { useDataStore } from '../../../hooks/useDataStore';
import { FormProvider, useForm } from 'react-hook-form';
import { useDateFormat } from '../../../hooks/useDateFormat';
import PortInfo from '../../../components/berth-fit-check/info/port-info/PortInfo';
import countryList from '../../../components/Country.json';
import AddAttachment from '../../../components/vessel-info/attachments/add-attachment/AddAttachment';
import icons from '../../../components/common/icons/icons';
import { getAPIRoute } from '../../../utils/api-route';
import { Restricted } from '../../../contexts/PermissionContext';
import Tooltip from 'devextreme-react/tooltip';
import { getBuList } from '../../../utils/jwt-decode';
import CopyToClipboard from '../../../components/common/copy-to-clipboard/CopyToClipboard';
import TerminalTab from '../../../components/berth-fit-check/terminals-tab/TerminalTab';
import { getTerminals } from '../../../services/master-data-service';
import toast from '../../../utils/toast';
import { IOptionvalue } from '../../../types/master-data';

export const BerthCheckStatusContext = React.createContext<IOptionvalue[]>([]);

function ViewBerthfit(
  props: {
    berthfitIdProps?: any;
    setBerthfitId?: any;
    setBerthDetails?: any;
    berthDetails?: any;
    isBerthFit?: any;
    portStatus?: any;
    setPortStatus?: any;
    berthFitDetails?: any;
    setBerthFitDetails?: any;
  },
  ref: any
) {
  const {
    berthfitIdProps,
    berthDetails,
    isBerthFit,
    portStatus,
    setPortStatus,
    berthFitDetails,
    setBerthFitDetails,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { getDataSource } = useDataStore();
  const { setIsLoading } = useLoaderContext();
  const { formatDate } = useDateFormat();
  const [confirmBackDialog, setConfirmBackDialog] = useState(false);
  const [confirmFinalizeDialog, setConfirmFinalizeDialog] = useState(false);
  const [imoNumber, setImoNumber] = useState<string>();
  const [bannerInfoDetails, setBannerInfoDetails] = useState([]) as any;
  const portDataSource = getDataSource('portId', apiConfig.activePorts);
  const [terminals, setTerminals] = useState<any>([]);
  // const [berthFitDetails, setBerthFitDetails] = useState<any>();
  const [showTabs, setShowTabs] = useState<any>([]);
  const [selectedTerminals, setSelectedTerminals] = useState<any>([]);
  const [isVisible, setIsVisible] = useState(false);
  // const [portStatus, setPortStatus] = useState<any>();
  const [bu, setBu] = useState();
  const [executionComplete, setExecutionComplete] = useState(false);
  const [attachmentsAdded, setAttachmentsAdded] = useState<any>([]);
  const [warning, setWarning] = useState<any>();
  const [reload, setReload] = useState(true);
  const [isLoaded, setIsloaded] = useState<boolean>(false);
  const [portCode, setPortCode] = useState<any>();
  const [berthCheckStatus, setBerthCheckStatus] = useState<IOptionvalue[]>([]);
  const handleCancelClick = () => {
    setIsVisible(false);
  };

  const onAddClick = () => {
    setIsVisible(true);
  };

  useEffect(() => {
    if (portStatus && portStatus.attachments) {
      setAttachmentsAdded(portStatus?.attachments);
    }
  }, [portStatus]);

  const getBerthCheckStatus = async () => {
    await api
      .get({
        url: apiConfig.berthFitCheckStatus,
      })
      .then((res) => {
        setBerthCheckStatus(res);
      });
  };

  const vesselBannerDetails = async () => {
    await api
      .get({ url: apiConfig.vessels + imoNumber }, setIsLoading)
      .then((data) => {
        setBannerInfoDetails(data);
      });
  };

  const getBerthFitInfo = async (berthFitId: string, imoNumber: string) => {
    await api
      .get({ url: getAPIRoute('getBerthfitDetails', [berthFitId, imoNumber]) })
      .then((res) => {
        setBerthFitDetails(res);
        showTerminalTabs(res?.berthFitTerminals);
        if (!res?.berthFitfinalizedDate) {
          executeBerthfitCheck(imoNumber);
        } else {
          getPortStatus();
          setExecutionComplete(true);
        }
      });
  };

  const initialLoad = async () => {
    await getTerminals()?.then((data: any) => {
      setTerminals(data);
    });
    setIsloaded(true);
  };

  useEffect(() => {
    getBerthCheckStatus();
    initialLoad();
  }, []);

  useEffect(() => {
    if (imoNumber) {
      vesselBannerDetails();
      getVesselWarning();
    }
  }, [imoNumber]);

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setConfirmBackDialog(false);
      navigate('/vessel-info', { state: { imoNumber: imoNumber } });
    },
    handleClose: () => {
      setConfirmBackDialog(false);
    },
  };

  const confirmFinalizeDialogConfig: IDialogConfig = {
    id: 'confirmFinalize',
    content: t('toast.berthFitFinalizeConfirm'),
    handleSubmit: () => {
      finalizeBerthFitCheck();
      setConfirmFinalizeDialog(false);
    },
    handleClose: () => {
      setConfirmFinalizeDialog(false);
    },
  };

  useEffect(() => {
    if (location.state) {
      const { imoNumber }: any = location.state;
      setImoNumber(imoNumber);
    }
  }, [location.state]);

  useEffect(() => {
    if (berthfitIdProps && imoNumber && isLoaded) {
      getBerthFitInfo(berthfitIdProps, imoNumber);
      const buId = getBuId();
      setBu(buId);
    }
  }, [berthfitIdProps, imoNumber, isLoaded]);

  useImperativeHandle(ref, () => ({
    onClickFinalize() {
      setConfirmFinalizeDialog(true);
    },
  }));

  const finalizeBerthFitCheck = async () => {
    await api
      .put(
        {
          url: apiConfig.finalizeBerthFitCheck,
          params: { berthFitId: berthfitIdProps },
        },
        setIsLoading
      )
      .then(() => {
        getPortStatus();
        toast.success({ title: t('toast.berthFitFinalizeSuccess') });
      });
  };

  const defaultValues = {
    portId: null,
    portTerminals: [],
    portWarning: {
      warning: false,
      restrictedMessage: '',
      approvalValidTill: '',
      isApproved: '',
    },
  };

  const portForm = useForm({
    defaultValues: defaultValues,
  });

  const { isDirty } = portForm.formState;

  const onClickBack = () => {
    if (isDirty) {
      setConfirmBackDialog(true);
    } else {
      navigate('/vessel-info', { state: { imoNumber: imoNumber } });
    }
  };

  const getPortWarning = async (portId: any, portTerminals: any) => {
    if (portId) {
      await api
        .get({
          url: getAPIRoute('portWarning', [portId]),
        })
        .then((response: any) => {
          const isApproved = response.isApproved ? 'A' : 'NA';
          response.isApproved = isApproved;
          portForm.reset({
            portId: berthFitDetails.portId,
            portTerminals: portTerminals ? portTerminals : [],
            portWarning: response,
          });
        });
    }
  };

  useEffect(() => {
    if (berthFitDetails) {
      const portTerminals: any = [];
      berthFitDetails.berthFitTerminals?.forEach((element: any) => {
        portTerminals.push(element.terminalId);
      });
      setSelectedTerminals(portTerminals);
      getPortWarning(berthFitDetails.portId, portTerminals);
      portForm.reset({
        portId: berthFitDetails.portId,
        portTerminals: portTerminals,
      });
    }
  }, [berthFitDetails]);

  const onClickCheck = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      const terminalIds = portForm.getValues().portTerminals;
      const portId = portForm.getValues().portId;
      const berthFitTerminals = getTerminalDetails(terminalIds);
      const buId = getBuId();
      setBu(buId);
      if (!(JSON.stringify(selectedTerminals) == JSON.stringify(terminalIds))) {
        bertFitCheck(portId, berthFitTerminals, buId);
      }
    }
  };

  const getBuId = () => {
    const bu: any = JSON.parse(getBuList() as string);
    const buIndex = bu.findIndex((item: any) => item.isDefault);
    const id = bu[buIndex].buId;
    return id;
  };
  const bertFitCheck = async (
    portId: any,
    berthFitTerminals: any,
    buId: any
  ) => {
    const data = {
      berthId: '',
      portId: portId,
      imoNumber: bannerInfoDetails?.imoNumber,
      berthFitDate: null,
      buId: buId,
      berthFitTerminals: berthFitTerminals,
    };
    if (berthFitDetails && portId === berthFitDetails?.portId) {
      editBerthfitCheck(data);
    } else {
      saveBerthfitCheck(data);
    }
  };

  const saveBerthfitCheck = async (data: any) => {
    await api
      .post(
        {
          url: apiConfig.berthFitCheckVessel,
          data: data,
        },
        setIsLoading
      )
      .then((res) => {
        // setBerthFitDetails(res);
        // showTerminalTabs(res?.berthFitTerminals);
        navigate('/vessel-info/berth-fit-check', {
          state: {
            berthFitID: res.berthFitId,
            berthDetails: res,
            imoNumber: imoNumber,
          },
        });
      });
  };

  const editBerthfitCheck = async (data: any) => {
    (data.berthFitId = berthFitDetails.berthFitId),
      (data.berthId = berthFitDetails.berthId),
      await api
        .put(
          {
            url: apiConfig.berthFitCheckVessel,
            data: data,
          },
          setIsLoading
        )
        .then((res) => {
          setBerthFitDetails(res);
          showTerminalTabs(res?.berthFitTerminals);
        });
  };

  const getPortStatus = async () => {
    await api
      .get({
        url: apiConfig.berthExecutionPortList + 0,
        params: { berthFitId: berthDetails?.berthFitId },
      })
      .then((res) => {
        setPortStatus(res[0]);
      });
  };

  const executeBerthfitCheck = async (imoFromHistory?: any) => {
    const buId = getBuId();
    setBu(buId);
    if (berthDetails?.berthFitId) {
      await api
        .put(
          {
            url: apiConfig.executeBerth,
            params: {
              berthFitId: berthDetails?.berthFitId,
              imo: imoFromHistory
                ? imoFromHistory
                : bannerInfoDetails?.imoNumber,
              buId: buId,
            },
          },
          setIsLoading
        )
        .then(() => {
          getPortStatus();
          setExecutionComplete(true);
        });
    }
  };

  const getTerminalDetails = (terminalIds: any) => {
    if (terminalIds?.length > 0) {
      const terminalsList: any = [];
      terminalIds.forEach((item: any) => {
        const terminal = {
          terminalId: 0,
          status: '',
          comments: null,
        };
        terminal.terminalId = item;
        terminalsList.push(terminal);
      });
      return terminalsList;
    }
  };

  const showTerminalTabs = (terminals: any) => {
    const tabs: any = [];
    terminals.forEach((element: any) => {
      const tab = { id: '', title: '', template: 'terminals' };
      tab.title = element.terminalName;
      tab.id = element.terminalId;
      tabs.push(tab);
    });
    setShowTabs(tabs);
  };

  ///////////////////////////////////
  const getVesselWarning = async () => {
    await api
      .get({
        url: apiConfig.vesselsWarning + imoNumber,
      })
      .then((response: any) => {
        setWarning(response);
      });
  };

  const [isVisibleADM, setIsVisibleADM] = useState(false);
  const openTerminalModal = () => {
    setIsVisibleADM(true);
  };

  const filteredCountryList = countryList.find(
    (countryItem) => countryItem.iso_3 == bannerInfoDetails?.iso3
  );

  const copyText = useMemo(() => {
    const text = bannerInfoDetails.imoDisplayName
      ? `${bannerInfoDetails.vesselName} (IMO: ${bannerInfoDetails.imoDisplayName})`
      : `${bannerInfoDetails.vesselName} (VIN: ${bannerInfoDetails.vinNumber})`;
    return text;
  }, [bannerInfoDetails]);

  return (
    <div className={berthfitIdProps ? 'inner-wrapper p-3' : 'inner-wrapper'}>
      <div>
        {!berthfitIdProps && (
          <div className="m-l-bt-outer-wrap">
            <div className="m-l-list-title-block">
              <div className="m-l-inner-page-header m-l-inner-page-header-with-mail-icon">
                <div className="m-l-inner-page-header-left-block">
                  <div className="m-l-ttl-status-wrap">
                    <div className="m-l-br-ttl-hldr">
                      <div className="m-l-title-block">
                        <div
                          className="m-l-page-main-heading page-navigation-link"
                          // onClick={() =>
                          //   navigate('/vessel-info', {
                          //     state: { imoNumber: imoNumber },
                          //   })
                          // }
                        >
                          <Link
                            className="m-l-page-main-heading"
                            to={'/vessel-info'}
                            state={{ imoNumber: imoNumber }}
                            onContextMenu={() =>
                              localStorage.setItem('imo', imoNumber as string)
                            }
                          >
                            {bannerInfoDetails?.vesselName}
                          </Link>
                        </div>
                        {warning?.warning && (
                          <>
                            <div className="m-l-page-icon" id="warning">
                              {/* <img
                                src={imageUrl.warningToastIcon}
                                className="img-responsive"
                                alt="success icon"
                                id="warning"
                              /> */}
                              <icons.vesselWarning />
                            </div>
                            <Tooltip
                              target={'#warning'}
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              position="right"
                              contentRender={() => {
                                return (
                                  <div className="m-l-tooltip-wrapper m-l-warning-icon-tooltip">
                                    <span>{warning.sanctionedMessage}</span>
                                    <span>{warning.restrictedMessage}</span>
                                  </div>
                                );
                              }}
                            />
                          </>
                        )}
                        <CopyToClipboard text={copyText} />
                      </div>
                      <div className="m-l-sub-title-details-block">
                        <div className="m-l-flag-wrap">
                          <span className="ml-ads-tbl-flag">
                            <img
                              src={filteredCountryList?.flag}
                              className="img-responsive"
                              alt="flag"
                              height={20}
                              width={20}
                            />
                          </span>
                          <span className="m-l-page-id-text">
                            {bannerInfoDetails.flagName}
                          </span>
                        </div>
                        <div className="app-l-vertical-seperator"></div>
                        {bannerInfoDetails &&
                        bannerInfoDetails.imoDisplayName ? (
                          <div className="m-l-page-sub-heading">
                            {t('labels.IMO')}:{' '}
                            {bannerInfoDetails?.imoDisplayName}
                          </div>
                        ) : (
                          <div className="m-l-page-sub-heading">
                            {t('labels.vin')}: {bannerInfoDetails?.vinNumber}
                          </div>
                        )}
                        {berthFitDetails && (
                          <>
                            <div className="app-l-vertical-seperator"></div>
                            <span className="m-l-page-id-text">
                              {t('labels.berthFitID')} :
                              <span> {berthFitDetails?.berthId}</span>
                            </span>
                            <div className="app-l-vertical-seperator"></div>

                            <span className="m-l-page-id-text">
                              {t('labels.berthFitDate')} :
                              <span>
                                {' '}
                                {formatDate(berthFitDetails?.berthFitDate)}
                              </span>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="m-l-inner-page-body-wrap m-l-srv-pdng">
          {!berthfitIdProps && (
            <VesselInfoBanner
              bannerInfoDetails={bannerInfoDetails}
              isSanction={warning?.warning}
            ></VesselInfoBanner>
          )}
          <div className="m-l-inner-page-body">
            <div className="m-l-form-panel-block">
              <div className="m-l-form-panel-header-block">
                <div className="m-l-form-panel-header-left-block app-l-port-heading">
                  {portStatus && (
                    <span className="left-icon-port">
                      {portStatus.isOverrided && !portStatus.isPassed && (
                        <icons.Overridefail />
                      )}
                      {portStatus.isOverrided && portStatus.isPassed && (
                        <icons.OverridePass />
                      )}
                      {!portStatus.isPassed && !portStatus.isOverrided && (
                        <icons.circleCrossDanger />
                      )}
                      {portStatus.isPassed && !portStatus.isOverrided && (
                        <icons.circleTickDanger />
                      )}
                    </span>
                  )}
                  <div className="m-l-page-small-heading">
                    {t('labels.port')}
                  </div>
                  <Restricted permission="BerthFitVessel.ViewPortInfo">
                    {portForm.getValues()?.portId && (
                      <div
                        className="m-l-view-info-port"
                        onClick={openTerminalModal}
                      >
                        <span className="view-terminalinfo">
                          {t('labels.viewInfo')}
                        </span>
                      </div>
                    )}
                  </Restricted>
                  {reload && (
                    <>
                      {portForm.getValues()?.portWarning?.warning && (
                        <>
                          <span className="m-l-port-warning" id={'portCode'}>
                            <icons.PortWarning />
                          </span>
                          <Tooltip
                            target={'#portCode'}
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            position="right"
                            contentRender={() => {
                              return (
                                <div className="m-l-tooltip-wrapper m-l-warning-icon-tooltip">
                                  <span>
                                    {
                                      portForm.getValues()?.portWarning
                                        ?.restrictedMessage
                                    }
                                  </span>
                                </div>
                              );
                            }}
                          />
                        </>
                      )}
                      {portForm.getValues()?.portWarning?.isApproved == 'A' &&
                        (portForm.getValues()?.portWarning
                          ?.approvalValidTill ? (
                          new Date(
                            portForm.getValues()?.portWarning?.approvalValidTill
                          ) < new Date() ? (
                            <p className="m-l-port-approval-info">
                              Port Approval Expired. Valid Till:{' '}
                              {formatDate(
                                portForm.getValues()?.portWarning
                                  ?.approvalValidTill
                              )}
                            </p>
                          ) : (
                            <p className="m-l-port-approved-info">
                              Port Approved. Valid Till:{' '}
                              {formatDate(
                                portForm.getValues()?.portWarning
                                  ?.approvalValidTill
                              )}
                            </p>
                          )
                        ) : (
                          <p className="m-l-port-approved-info">
                            Port Approved.
                          </p>
                        ))}
                      {portForm.getValues()?.portWarning?.isApproved == 'NA' &&
                        portForm.getValues()?.portId && (
                          <p className="m-l-port-approval-info">
                            Port Not Approved.
                          </p>
                        )}
                    </>
                  )}
                </div>
                {!berthfitIdProps && (
                  <div className="m-l-inner-page-header-right-block">
                    <Button
                      className="app-c-btn app-c-btn--secondary"
                      onClick={onClickBack}
                    >
                      {t('buttons.back')}
                    </Button>
                    {
                      // <Restricted permission="BerthFit.BerthFitCheck">
                      <Button
                        className="app-c-btn app-c-btn--primary min-btn-width"
                        onClick={onClickCheck}
                      >
                        {t('buttons.check')}
                      </Button>
                      // </Restricted>
                    }
                  </div>
                )}
              </div>
              <div className="m-l-form-panel-body-block">
                <FormProvider {...portForm}>
                  <PortForm
                    portDataSource={portDataSource}
                    terminals={terminals}
                    selectedTerminals={selectedTerminals}
                    setSelectedTerminals={setSelectedTerminals}
                    isBerthFit={isBerthFit}
                    berthfitIdProps={berthfitIdProps}
                    onAddClick={onAddClick}
                    attachmentsAdded={attachmentsAdded}
                    imoNumber={imoNumber}
                    berthFitId={berthfitIdProps}
                    setReload={setReload}
                    isMastersLoaded={isLoaded}
                    setPortCode={setPortCode}
                    berthDetails={berthDetails}
                  />
                </FormProvider>
                {isVisible && (
                  <AddAttachment
                    isVisible={isVisible}
                    handleCancelClick={handleCancelClick}
                    imoNumber={imoNumber}
                    berthFitId={berthFitDetails?.berthFitId}
                    setAttachmentsAdded={setAttachmentsAdded}
                    attachmentsAdded={attachmentsAdded}
                  />
                )}
              </div>
            </div>
          </div>
          {showTabs?.length > 0 && executionComplete && (
            <BerthCheckStatusContext.Provider value={berthCheckStatus}>
              <TerminalTab
                selectedTerminals={showTabs}
                imoNumber={bannerInfoDetails?.imoNumber}
                berthFitId={berthFitDetails?.berthFitId}
                buId={bu}
                getPortStatus={getPortStatus}
              />
            </BerthCheckStatusContext.Provider>
          )}
        </div>
      </div>
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              {!berthfitIdProps && (
                <>
                  <Button
                    className="app-c-btn app-c-btn--secondary"
                    onClick={onClickBack}
                  >
                    {t('buttons.back')}
                  </Button>
                  {
                    // <Restricted permission="BerthFit.BerthFitCheck">
                    <Button
                      className="app-c-btn app-c-btn--primary min-btn-width"
                      onClick={onClickCheck}
                    >
                      {t('buttons.check')}
                    </Button>
                    // </Restricted>
                  }
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <PortInfo
        isVisible={isVisibleADM}
        handleCancelClick={() => setIsVisibleADM(false)}
        portId={portForm.getValues().portId}
        buId={bu}
        portCode={portStatus ? portStatus.portCode : portCode}
      />
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={confirmBackDialog}
      />
      <ConfirmDialog
        dialogConfig={confirmFinalizeDialogConfig}
        isOpen={confirmFinalizeDialog}
      />
    </div>
  );
}

export default forwardRef(ViewBerthfit);
