import Button from 'devextreme-react/button';
import TabPanel from 'devextreme-react/tab-panel';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import icons from '../../common/icons/icons';
import { Restricted } from '../../../contexts/PermissionContext';
import AddListPopover from '../add-list-popover/AddListPopover';
import AddNewPort from '../add-new-port-popover/AddNewPort';
import BerthGrid from '../berth-grid/BerthGrid';
import PortGrid from '../port-grid/PortGrid';
import TerminalGrid from '../terminal-grid/TerminalGrid';

export default function PortTerminalBerthTab(props: {
  setTabValue?: any;
  onValueSearch?: any;
  onClickSearch?: any;
  query?: any;
  params?: any;
}) {
  const { setTabValue, query, params } = props;
  const tabRef = useRef<TabPanel>(null);
  const [isAddList, setIsAddList] = useState(false);
  const [isAddListBerth, setIsAddListBerth] = useState(false);
  const [isAddListTerminal, setIsAddListTerminal] = useState(false);
  const [selectedTab, setSelectedTab] = useState<any>(0);
  const [isAddNewPort, setIsAddNewPort] = useState(false);
  const [searchList, setSearchList] = useState<any>();
  const [codesToList, setCodesToList] = useState<any>([]);
  const [terminalEntityNames, setTerminalEntityNames] = useState<any>([]);
  const [berthEntityNames, setBerthEntityNames] = useState<any>([]);
  const [isExportPort, setIsExportPort] = useState(false);
  const [isExportTerminal, setIsExportTerminal] = useState(false);
  const [isExportBerth, setIsExportBerth] = useState(false);
  const [clearSelection, setClearSelection] = useState(false);
  const { t } = useTranslation();

  const [showAddListPopover, setShowAddListPopover] = useState(false);

  const tabs = useMemo(() => {
    return [
      {
        title: t('labels.port'),
      },
      {
        title: t('labels.terminal'),
      },
      {
        title: t('labels.berth'),
      },
    ];
  }, []);

  const tabItemRender = (e: any) => {
    if (e.title === tabs[0].title) {
      return (
        <PortGrid
          query={query}
          setIsAddList={setIsAddList}
          searchList={searchList}
          setSearchList={setSearchList}
          params={params}
          setCodesToList={setCodesToList}
          codesToList={codesToList}
          isExport={isExportPort}
          setIsExport={setIsExportPort}
          clearSelection={clearSelection}
          setClearSelection={setClearSelection}
        />
      );
    } else if (e.title === tabs[1].title) {
      return (
        <TerminalGrid
          query={query}
          setIsAddList={setIsAddListTerminal}
          params={params}
          setTerminalEntityNames={setTerminalEntityNames}
          isExport={isExportTerminal}
          setIsExport={setIsExportTerminal}
          clearSelection={clearSelection}
          setClearSelection={setClearSelection}
        />
      );
    } else if (e.title === tabs[2].title) {
      return (
        <BerthGrid
          query={query}
          setIsAddList={setIsAddListBerth}
          params={params}
          setBerthEntityNames={setBerthEntityNames}
          isExport={isExportBerth}
          setIsExport={setIsExportBerth}
          clearSelection={clearSelection}
          setClearSelection={setClearSelection}
        />
      );
    }
  };

  const onTabSelectionChanged = () => {
    setSelectedTab(tabRef.current?.instance.option('selectedIndex'));
    setTabValue(tabRef.current?.instance.option('selectedIndex'));
  };

  const onAddClick = () => {
    setIsAddNewPort(true);
  };

  return (
    <div className="m-l-tab-panel__wrap">
      <TabPanel
        ref={tabRef}
        // height={'600px'}
        items={tabs}
        className="m-c-tab-block m-c-tab--half-width"
        elementAttr={{ 'data-testid': 'portTab' }}
        itemRender={tabItemRender}
        onSelectionChanged={onTabSelectionChanged}
        showNavButtons={true}
      />
      <div className="m-l-tab-panel-right-block">
        {selectedTab == 0 && (
          <>
            <Button
              className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
              aria-label="Upload"
              onClick={() => setIsExportPort(true)}
            >
              <div className="m-c-icon-inside-btn">
                <icons.download />
              </div>
            </Button>
            <Restricted permission="PortManagement.AddNewPort">
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                elementAttr={{ 'data-testid': 'addNew' }}
                onClick={onAddClick}
              >
                {t('labels.addNew')}
              </Button>
            </Restricted>
          </>
        )}
        {selectedTab == 1 && (
          <Button
            className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
            aria-label="Upload"
            onClick={() => setIsExportTerminal(true)}
          >
            <div className="m-c-icon-inside-btn">
              <icons.download />
            </div>
          </Button>
        )}
        {selectedTab == 2 && (
          <Button
            className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
            aria-label="Upload"
            onClick={() => setIsExportBerth(true)}
          >
            <div className="m-c-icon-inside-btn">
              <icons.download />
            </div>
          </Button>
        )}
        {isAddList && selectedTab == 0 && (
          <Restricted permission="PortManagement.AddPortToList">
            <Button
              className="app-c-btn app-c-btn--secondary"
              elementAttr={{ 'data-testid': 'addToList' }}
              onClick={() => setShowAddListPopover(true)}
              id="addToList"
            >
              {t('labels.addToList')}
            </Button>
          </Restricted>
        )}
        {isAddListTerminal && selectedTab == 1 && (
          <Restricted permission="PortManagement.AddTerminalToList">
            <Button
              className="app-c-btn app-c-btn--secondary"
              elementAttr={{ 'data-testid': 'addToList' }}
              onClick={() => setShowAddListPopover(true)}
              id="addToList"
            >
              {t('labels.addToList')}
            </Button>
          </Restricted>
        )}
        {isAddListBerth && selectedTab == 2 && (
          <Restricted permission="PortManagement.AddBerthToList">
            <Button
              className="app-c-btn app-c-btn--secondary"
              elementAttr={{ 'data-testid': 'addToList' }}
              onClick={() => setShowAddListPopover(true)}
              id="addToList"
            >
              {t('labels.addToList')}
            </Button>
          </Restricted>
        )}
      </div>
      <AddListPopover
        showPopover={showAddListPopover}
        setShowPopover={setShowAddListPopover}
        target="#addToList"
        selectedTab={selectedTab}
        codesToList={codesToList}
        berthEntityNames={berthEntityNames}
        terminalEntityNames={terminalEntityNames}
        setClearSelection={setClearSelection}
      />
      {isAddNewPort && (
        <AddNewPort
          isAddNewPort={isAddNewPort}
          setIsAddNewPort={setIsAddNewPort}
          query={query}
          setSearchList={setSearchList}
          searchList={searchList}
        />
      )}
      <div className="ml-port-tab-footer-padding">
        <div className="m-l-inner-page-footer">
          <div className="row m-l-custom-eight-space-row">
            <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
              <div className="m-l-group-button-btms">
                {selectedTab == 0 && (
                  <>
                    <Button
                      className="app-c-btn app-c-btn--secondary"
                      aria-label="Upload"
                      onClick={() => setIsExportPort(true)}
                    >
                      {t('labels.download')}
                    </Button>
                    <Restricted permission="PortManagement.AddNewPort">
                      <Button
                        className="app-c-btn app-c-btn--primary min-btn-width"
                        elementAttr={{ 'data-testid': 'addNew' }}
                        onClick={onAddClick}
                      >
                        {t('labels.addNew')}
                      </Button>
                    </Restricted>
                  </>
                )}
                {selectedTab == 1 && (
                  <Button
                    className="app-c-btn app-c-btn--secondary "
                    aria-label="Upload"
                    onClick={() => setIsExportTerminal(true)}
                  >
                    {t('labels.download')}
                  </Button>
                )}
                {selectedTab == 2 && (
                  <Button
                    className="app-c-btn app-c-btn--secondary"
                    aria-label="Upload"
                    onClick={() => setIsExportBerth(true)}
                  >
                    {t('labels.download')}
                  </Button>
                )}
                {isAddList && selectedTab == 0 && (
                  <Restricted permission="PortManagement.AddPortToList">
                    <Button
                      className="app-c-btn app-c-btn--secondary"
                      elementAttr={{ 'data-testid': 'addToList' }}
                      onClick={() => setShowAddListPopover(true)}
                      id="addToList"
                    >
                      {t('labels.addToList')}
                    </Button>
                  </Restricted>
                )}
                {isAddListTerminal && selectedTab == 1 && (
                  <Restricted permission="PortManagement.AddTerminalToList">
                    <Button
                      className="app-c-btn app-c-btn--secondary"
                      elementAttr={{ 'data-testid': 'addToList' }}
                      onClick={() => setShowAddListPopover(true)}
                      id="addToList"
                    >
                      {t('labels.addToList')}
                    </Button>
                  </Restricted>
                )}
                {isAddListBerth && selectedTab == 2 && (
                  <Restricted permission="PortManagement.AddBerthToList">
                    <Button
                      className="app-c-btn app-c-btn--secondary"
                      elementAttr={{ 'data-testid': 'addToList' }}
                      onClick={() => setShowAddListPopover(true)}
                      id="addToList"
                    >
                      {t('labels.addToList')}
                    </Button>
                  </Restricted>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
