import Button from 'devextreme-react/button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IYardDetails } from '../../../../../types/vessel-yard-tab';
import icons from '../../../../common/icons/icons';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import YardEdit from '../edit-yard/YardTabEdit';
import { Restricted } from '../../../../../contexts/PermissionContext';
import { useDateFormat } from '../../../../../hooks/useDateFormat';

export default function YardTab(props: { imoNumber?: string }) {
  const [isEdit, setIsEdit] = useState(false);
  const [yardView, setYardView] = useState(true);
  const [yardDetails, setYardDetails] = useState<IYardDetails>();
  const { imoNumber } = props;
  const { t } = useTranslation();
  const { formatDate } = useDateFormat();

  const GetYardDetails = async () => {
    await api
      .get({
        url: apiConfig.yardTab + imoNumber,
      })
      .then((response: any) => {
        setYardDetails(response);
      });
  };

  useEffect(() => {
    if (imoNumber) {
      GetYardDetails();
    }
  }, []);

  return (
    <div data-testid="yard">
      {yardView ? (
        <div className="m-l-inner-page-body">
          <div className="m-l-form-panel-block">
            <div className="m-l-tbl-square-wrapper">
              <div className="m-l-ads-table-block">
                <div className="row m-l-ads-table-row m-l-ads-table--inner-row">
                  <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 m-l-custom-sixteen-space-col-wrap">
                    <div className="row">
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                        <div className="m-l-ads-tbl-title">
                          {t('labels.yearOfBuilt')}
                        </div>
                        <div className="m-l-ads-tbl-desc">
                          {formatDate(yardDetails?.dateOfBuild)}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                        <div className="m-l-ads-tbl-title">
                          {t('labels.launchDate')}
                        </div>
                        <div className="m-l-ads-tbl-desc">
                          {' '}
                          {formatDate(yardDetails?.launchDate)}{' '}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                        <div className="m-l-ads-tbl-title">
                          {t('labels.builder')}
                        </div>
                        <div className="m-l-ads-tbl-desc">
                          {yardDetails?.builder}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                        <div className="m-l-ads-tbl-title">
                          {t('labels.yardNumber')}
                        </div>
                        <div className="m-l-ads-tbl-desc">
                          {yardDetails?.yardNumber}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                        <div className="m-l-ads-tbl-title">
                          {t('labels.countryOfBuild')}
                        </div>
                        <div className="m-l-ads-tbl-desc">
                          {yardDetails?.countryOfBuild}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                    <div className="m-l-expand-btn-wrap m-l-edit-view-mode">
                      <Restricted permission="Ship.EditYard">
                        <Button
                          className="app-c-btn app-c-icon-only-btn"
                          onClick={() => {
                            setYardView(!yardView);
                            setIsEdit(!isEdit);
                          }}
                        >
                          <icons.IconPencil />
                        </Button>
                      </Restricted>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {isEdit ? (
        <YardEdit
          imoNumber={imoNumber}
          setIsEdit={setIsEdit}
          setYardView={setYardView}
          getYardDetails={GetYardDetails}
          yardDetails={yardDetails}
        />
      ) : null}
    </div>
  );
}
