import Button from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useState, useMemo, useEffect } from 'react';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import icons from '../../../../components/common/icons/icons';
import Modal from '../../../../components/common/modal/Modal';
import { IModalConfig } from '../../../../types/modal';
import { IPortInfo } from '../../../../types/berthfit-view-info';
import { useTranslation } from 'react-i18next';
import { useDimensionsUnit } from '../../../../hooks/useDimensionsUnit';
import PortAccordion from '../../../port-management/port/port-accordion/PortAccordion';
import { BerthFItInformationParams } from '../../../../enums/berthfit-information-enum';

export default function PortInfo(props: {
  handleCancelClick: any;
  isVisible: boolean;
  portId: any;
  buId?: any;
  portCode: string | null;
}) {
  const [portInfo, setPortInfo] = useState<IPortInfo[]>([]);
  const { portId, buId, portCode } = props;
  const { t } = useTranslation();
  const { dimensionUnitText, meterToFeetConversion } = useDimensionsUnit();

  const GetPortDetails = async () => {
    await api
      .get({
        url: apiConfig.viewPort + portId,
        params: { buId: buId },
      })
      .then((response: IPortInfo[]) => {
        response.sort((a, b) => a.displayOrder - b.displayOrder);
        setPortInfo(response);
      });
  };

  useEffect(() => {
    if (portId) {
      GetPortDetails();
    }
  }, [portId]);

  const selectedPort = portInfo.find((port) => port.text === 'Port Name');

  const modalConfig: IModalConfig = {
    width: 1000,
    height: 560,
    visible: props.isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };
  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-form-modal',
    };
  }, []);

  const berthInfoGridParams = useMemo(() => {
    const params = portInfo?.filter(
      (item) =>
        item.parameterName === BerthFItInformationParams.SpecialNotes ||
        item.parameterName === BerthFItInformationParams.PortContacts
    );
    return params && params.length > 0
      ? params.map((item) => item.parameterName)
      : [];
  }, [portInfo]);

  const requiredFileds = useMemo(() => {
    return portInfo.filter(
      (item) =>
        item.parameterName !== BerthFItInformationParams.SpecialNotes &&
        item.parameterName !== BerthFItInformationParams.PortContacts
    );
  }, [portInfo]);

  return (
    <div className="m-l-model-popup__wrap">
      <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
        <div className="m-l-modal__header">
          <h2 className="modal-title">
            {' '}
            {selectedPort?.value} {t('labels.portInformation')}{' '}
          </h2>
          <div className="m-l-modal-close-button-block">
            <Button
              className="app-c-btn app-c-icon-only-btn"
              onClick={props.handleCancelClick}
            >
              <icons.IconCross />
            </Button>
          </div>
        </div>
        <div className="m-l-modal__body">
          <div className="m-l-inner-page-body">
            <ScrollView width="100%" height="100%">
              <div className="m-l-form-panel-block">
                <div className="m-l-tbl-square-wrapper">
                  <div className="m-l-ads-table-block">
                    <div className="m-l-ads-table-right-part">
                      <div className="row m-l-ads-table-row">
                        {requiredFileds.map((field, index) => {
                          return (
                            <div
                              className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items"
                              key={index}
                            >
                              <div className="m-l-ads-tbl-title">
                                {' '}
                                {field.isConversionRequired
                                  ? field.text + dimensionUnitText()
                                  : field.text}{' '}
                              </div>
                              <div className="m-l-ads-tbl-desc">
                                {field.isConversionRequired && field.value
                                  ? meterToFeetConversion(Number(field.value))
                                  : field.value}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="u-p8">
                  <PortAccordion
                    portCode={portCode}
                    isFromViewInfoModal={true}
                    parameterInfo={berthInfoGridParams}
                  />
                </div>
              </div>
            </ScrollView>
          </div>
        </div>
      </Modal>
    </div>
  );
}
