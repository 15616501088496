import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from 'devextreme-react/button';
import { FormProvider, useForm } from 'react-hook-form';
import AdvancedSearchForm from '../../components/advanced-search/advanced-search-form/AdvancedSearchForm';
import {
  IAdvancedSearch,
  IAdvancedSearchForm,
} from '../../types/advanced-search';
import Icons from '../../components/common/icons/icons';
import AdvancedSearchResultGrid from '../../components/advanced-search/advanced-search-result-grid/AdvancedSearchResultGrid';
import { IOptionvalue } from '../../types/master-data';
import { MarsApiService as api } from '../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import Country from '../../components/Country.json';
import { useTranslation } from 'react-i18next';
import DataGrid from 'devextreme-react/data-grid';
import toast from '../../utils/toast';
import { useLocation, useNavigate } from 'react-router-dom';
import icons from '../../components/common/icons/icons';
import AddNewVessel from '../../components/add-new-vessel/AddNewVessel';
import { Tooltip } from 'devextreme-react/tooltip';
import { useDataStore } from '../../hooks/useDataStore';

export default function AdvancedSearch() {
  const [collapseSearch, setcollapseSearch] = useState(false);
  const [isCollapsedSearch, setIsCollapsedSearch] = useState<any>();
  const [classSocietyList, setClassSociety] = useState<IOptionvalue[]>([]);
  const [classificationStatusList, setClassificationStatus] = useState<
    IOptionvalue[]
  >([]);
  const [vesselStatusList, setVesselStatus] = useState<IOptionvalue[]>([]);
  const [vesselTypes, setVesselTypes] = useState<IOptionvalue[]>([]);
  const [countryList, setCountries] = useState<any>([]);
  const { t } = useTranslation();
  const [totalCount, setTotalCount] = useState(0);
  const [doSearch, setDoSearch] = useState(false);
  const [advanceSearchResultList, setSearchResult] = useState<any>();
  const gridRef = useRef<DataGrid>(null);
  const downloadgridRef: any = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isAddNewVessel, setIsAddNewVessel] = useState(false);
  const { getDataSource } = useDataStore();

  useEffect(() => {
    if (location.state) {
      const { collapse }: any = location.state;
      setcollapseSearch(collapse);
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state != null && location.state == 'vesselInfo') {
      const searchParam: any = sessionStorage.getItem('searchParam');
      const searchParamJson = JSON.parse(searchParam);
      setSearchResult([]);
      setcollapseSearch(true);
      setIsCollapsedSearch(true);
      resetAdvancedSearchForm(searchParamJson.searchJson);
      searchVessel(searchParamJson.searchJson);
    } else {
      sessionStorage.removeItem('searchParam');
    }
  }, []);

  const resetAdvancedSearchForm = (advancedSearchJson: IAdvancedSearch) => {
    advancedSearchForm.reset({
      mmsi:
        advancedSearchJson.mmsi != '' ? Number(advancedSearchJson.mmsi) : null,
      imo: advancedSearchJson.imo != '' ? Number(advancedSearchJson.imo) : null,
      callSign: advancedSearchJson.callSign,
      flagCodes: advancedSearchJson.flagCodes,

      vesselName: advancedSearchJson.vesselName,
      isPreviousName: advancedSearchJson.isPreviousName,
      registeredOwner:
        advancedSearchJson.registeredOwnerImo != ''
          ? advancedSearchJson.registeredOwnerImo
          : advancedSearchJson.registeredOwnerName != ''
          ? advancedSearchJson.registeredOwnerName
          : '',
      registeredOwnerSearchType:
        advancedSearchJson.registeredOwnerImo != ''
          ? 2
          : advancedSearchJson.registeredOwnerName != ''
          ? 1
          : 2,
      vesselManager:
        advancedSearchJson.vesselManagerImo != ''
          ? advancedSearchJson.vesselManagerImo
          : advancedSearchJson.vesselManagerName != ''
          ? advancedSearchJson.vesselManagerName
          : '',
      vesselManagerSearchType:
        advancedSearchJson.vesselManagerImo != ''
          ? 2
          : advancedSearchJson.vesselManagerName != ''
          ? 1
          : 2,
      beneficialOwner:
        advancedSearchJson.beneficialOwnerImo != ''
          ? advancedSearchJson.beneficialOwnerImo
          : advancedSearchJson.beneficialOwnerName != ''
          ? advancedSearchJson.beneficialOwnerName
          : '',
      beneficialOwnerSearchType:
        advancedSearchJson.beneficialOwnerImo != ''
          ? 2
          : advancedSearchJson.beneficialOwnerName != ''
          ? 1
          : 2,
      technicalManager:
        advancedSearchJson.technicalManagerImo != ''
          ? advancedSearchJson.technicalManagerImo
          : advancedSearchJson.technicalManagerName != ''
          ? advancedSearchJson.technicalManagerName
          : '',
      technicalManagerSearchType:
        advancedSearchJson.technicalManagerImo != ''
          ? 2
          : advancedSearchJson.technicalManagerName != ''
          ? 1
          : 2,
      ismManager:
        advancedSearchJson.ismManagerImo != ''
          ? advancedSearchJson.ismManagerImo
          : advancedSearchJson.ismManagerName != ''
          ? advancedSearchJson.ismManagerName
          : '',
      ismManagerSearchType:
        advancedSearchJson.ismManagerImo != ''
          ? 2
          : advancedSearchJson.ismManagerName != ''
          ? 1
          : 2,
      grossTonnageFrom:
        advancedSearchJson.grossTonnageFrom != 0
          ? advancedSearchJson.grossTonnageFrom
          : null,
      grossTonnageTo:
        advancedSearchJson.grossTonnageTo != 0
          ? advancedSearchJson.grossTonnageTo
          : null,
      deadweightFrom:
        advancedSearchJson.deadweightFrom != 0
          ? advancedSearchJson.deadweightFrom
          : null,
      deadweightTo:
        advancedSearchJson.deadweightTo != 0
          ? advancedSearchJson.deadweightTo
          : null,
      yearOfBuildFrom:
        advancedSearchJson.yearOfBuildFrom != 0
          ? advancedSearchJson.yearOfBuildFrom
          : null,
      yearOfBuildTo:
        advancedSearchJson.yearOfBuildTo != 0
          ? advancedSearchJson.yearOfBuildTo
          : null,
      classSociety: advancedSearchJson.classSociety,
      classificationStatus: advancedSearchJson.classificationStatus,
      vesselType: advancedSearchJson.vesselType,
      vesselStatus: advancedSearchJson.vesselStatus,
    });
  };

  const defaultValues: IAdvancedSearchForm = {
    imo: null,
    callSign: '',
    mmsi: null,
    flagCodes: [],
    vesselName: '',
    isPreviousName: false,
    registeredOwner: '',
    registeredOwnerSearchType: 2,
    beneficialOwner: '',
    beneficialOwnerSearchType: 2,
    technicalManager: '',
    technicalManagerSearchType: 2,
    ismManager: '',
    ismManagerSearchType: 2,
    grossTonnageFrom: null,
    grossTonnageTo: null,
    deadweightFrom: null,
    deadweightTo: null,
    vesselManager: '',
    vesselManagerSearchType: 2,
    yearOfBuildFrom: null,
    yearOfBuildTo: null,
    classSociety: [],
    classificationStatus: [],
    vesselType: [],
    vesselStatus: '',
  };

  const advancedSearchForm = useForm({
    defaultValues: defaultValues,
  });

  const { getValues } = advancedSearchForm;

  const blankFields = () => {
    const advancedSearchFields: IAdvancedSearchForm = getValues();
    return (
      (advancedSearchFields.imo == null ||
        advancedSearchFields.imo?.toString()?.trim() == '') &&
      advancedSearchFields.callSign?.trim() == '' &&
      (advancedSearchFields.vin == null ||
        advancedSearchFields.vin?.trim() == '') &&
      (advancedSearchFields.mmsi == null ||
        advancedSearchFields.mmsi?.toString()?.trim() == '') &&
      advancedSearchFields.flagCodes.length == 0 &&
      advancedSearchFields.vesselName?.trim() == '' &&
      advancedSearchFields.vesselManager?.trim() == '' &&
      advancedSearchFields.technicalManager?.trim() == '' &&
      advancedSearchFields.ismManager?.trim() == '' &&
      advancedSearchFields.registeredOwner?.trim() == '' &&
      (advancedSearchFields.grossTonnageFrom == null ||
        advancedSearchFields.grossTonnageFrom == 0) &&
      (advancedSearchFields.grossTonnageTo == null ||
        advancedSearchFields.grossTonnageTo == 0) &&
      (advancedSearchFields.deadweightFrom == null ||
        advancedSearchFields.deadweightFrom == 0) &&
      (advancedSearchFields.deadweightTo == null ||
        advancedSearchFields.deadweightTo == 0) &&
      (advancedSearchFields.yearOfBuildFrom == null ||
        advancedSearchFields.yearOfBuildFrom == 0) &&
      (advancedSearchFields.yearOfBuildTo == null ||
        advancedSearchFields.yearOfBuildTo == 0) &&
      advancedSearchFields.beneficialOwner?.trim() == '' &&
      advancedSearchFields.classSociety.length == 0 &&
      advancedSearchFields.classificationStatus.length == 0 &&
      advancedSearchFields.vesselType.length == 0 &&
      advancedSearchFields.vesselStatus?.trim() == ''
    );
  };

  const search = (e: any) => {
    if (blankFields()) {
      toast.error({
        title: 'Error',
        message: t('errors.atleastOneConditionIsRequired'),
      });
      return;
    }
    if (e.validationGroup.validate().isValid) {
      setSearchResult([]);
      setcollapseSearch(true);
      setIsCollapsedSearch(false);
      setDoSearch(true);
    }
    return;
  };

  const collapsedSearch = (e: any) => {
    if (blankFields()) {
      toast.error({
        title: 'Error',
        message: t('errors.atleastOneConditionIsRequired'),
      });
      return;
    }
    if (e.validationGroup.validate().isValid) {
      setSearchResult([]);
      setcollapseSearch(true);
      setIsCollapsedSearch(true);
      setDoSearch(true);
    }
    return;
  };

  const reset = () => {
    advancedSearchForm.reset(defaultValues);
    setSearchResult([]);
    setTotalCount(0);
  };

  const onClickExpand = () => {
    setcollapseSearch(false);
    setIsCollapsedSearch(false);
    setSearchResult([]);
  };

  const setRequiredData = async () => {
    await api.get({ url: apiConfig.country }).then((data) => {
      data.map((item: any) => {
        const country: any = Country.find(
          (countryItem) => countryItem.iso_3 == item?.iso3
        );
        item.flag = country?.flag;
      });
      setCountries(data);
    });

    await api
      .get({ url: apiConfig.classSociety })
      .then((data: IOptionvalue[]) => {
        setClassSociety(data);
      });

    await api
      .get({ url: apiConfig.vesselStatus })
      .then((data: IOptionvalue[]) => {
        setVesselStatus(data);
      });

    await api
      .get({ url: apiConfig.vesselTypes })
      .then((res: IOptionvalue[]) => {
        setVesselTypes(res);
      });

    await api
      .get({ url: apiConfig.classificationStatus })
      .then((data: IOptionvalue[]) => {
        setClassificationStatus(data);
      });
  };

  useEffect(() => {
    setRequiredData();
  }, []);

  useEffect(() => {
    if (doSearch) {
      if (isCollapsedSearch) {
        collapsedAdvancedSearch();
      } else {
        advancedSearch();
      }
    }
  }, [doSearch]);

  const advancedSearch = () => {
    if (blankFields()) {
      return;
    }
    const advancedSearchFormValue: IAdvancedSearchForm = getValues();
    const advancedSearchJson: IAdvancedSearch = {
      imo: advancedSearchFormValue.imo
        ? advancedSearchFormValue.imo.toString()
        : '',
      callSign: advancedSearchFormValue.callSign,
      flagCodes: advancedSearchFormValue.flagCodes,
      mmsi: advancedSearchFormValue.mmsi
        ? advancedSearchFormValue.mmsi.toString()
        : '',
      vin: advancedSearchFormValue.vin ? advancedSearchFormValue.vin : '',
      vesselName: advancedSearchFormValue.vesselName,
      isPreviousName: advancedSearchFormValue.isPreviousName,
      registeredOwnerImo:
        advancedSearchFormValue.registeredOwnerSearchType == 2
          ? advancedSearchFormValue.registeredOwner
          : '',
      registeredOwnerName:
        advancedSearchFormValue.registeredOwnerSearchType == 1
          ? advancedSearchFormValue.registeredOwner
          : '',
      vesselManagerImo:
        advancedSearchFormValue.vesselManagerSearchType == 2
          ? advancedSearchFormValue.vesselManager
          : '',
      vesselManagerName:
        advancedSearchFormValue.vesselManagerSearchType == 1
          ? advancedSearchFormValue.vesselManager
          : '',
      beneficialOwnerImo:
        advancedSearchFormValue.beneficialOwnerSearchType == 2
          ? advancedSearchFormValue.beneficialOwner
          : '',
      beneficialOwnerName:
        advancedSearchFormValue.beneficialOwnerSearchType == 1
          ? advancedSearchFormValue.beneficialOwner
          : '',
      technicalManagerImo:
        advancedSearchFormValue.technicalManagerSearchType == 2
          ? advancedSearchFormValue.technicalManager
          : '',
      technicalManagerName:
        advancedSearchFormValue.technicalManagerSearchType == 1
          ? advancedSearchFormValue.technicalManager
          : '',
      ismManagerImo:
        advancedSearchFormValue.ismManagerSearchType == 2
          ? advancedSearchFormValue.ismManager
          : '',
      ismManagerName:
        advancedSearchFormValue.ismManagerSearchType == 1
          ? advancedSearchFormValue.ismManager
          : '',
      grossTonnageFrom: advancedSearchFormValue.grossTonnageFrom
        ? advancedSearchFormValue.grossTonnageFrom
        : 0,
      grossTonnageTo: advancedSearchFormValue.grossTonnageTo
        ? advancedSearchFormValue.grossTonnageTo
        : 0,
      deadweightFrom: advancedSearchFormValue.deadweightFrom
        ? advancedSearchFormValue.deadweightFrom
        : 0,
      deadweightTo: advancedSearchFormValue.deadweightTo
        ? advancedSearchFormValue.deadweightTo
        : 0,
      yearOfBuildFrom: advancedSearchFormValue.yearOfBuildFrom
        ? advancedSearchFormValue.yearOfBuildFrom
        : 0,
      yearOfBuildTo: advancedSearchFormValue.yearOfBuildTo
        ? advancedSearchFormValue.yearOfBuildTo
        : 0,
      classSociety: advancedSearchFormValue.classSociety,
      classificationStatus: advancedSearchFormValue.classificationStatus,
      vesselType: advancedSearchFormValue.vesselType,
      vesselStatus: advancedSearchFormValue.vesselStatus,
    };
    searchVessel(advancedSearchJson);
  };

  const collapsedAdvancedSearch = () => {
    if (blankFields()) {
      return;
    }
    const advancedSearchFormValue: IAdvancedSearchForm = getValues();
    const advancedSearchJson: IAdvancedSearch = {
      imo: advancedSearchFormValue.imo
        ? advancedSearchFormValue.imo.toString()
        : '',
      callSign: advancedSearchFormValue.callSign,
      flagCodes: advancedSearchFormValue.flagCodes,
      mmsi: advancedSearchFormValue.mmsi
        ? advancedSearchFormValue.mmsi.toString()
        : '',
      vesselName: advancedSearchFormValue.vesselName,
      vin: advancedSearchFormValue.vin,
      isPreviousName: false,
      registeredOwnerImo: '',
      registeredOwnerName: '',
      vesselManagerImo: '',
      vesselManagerName: '',
      beneficialOwnerImo: '',
      beneficialOwnerName: '',
      technicalManagerImo: '',
      technicalManagerName: '',
      ismManagerImo: '',
      ismManagerName: '',
      grossTonnageFrom: advancedSearchFormValue.grossTonnageFrom
        ? advancedSearchFormValue.grossTonnageFrom
        : 0,
      grossTonnageTo: advancedSearchFormValue.grossTonnageTo
        ? advancedSearchFormValue.grossTonnageTo
        : 0,
      deadweightFrom: advancedSearchFormValue.deadweightFrom
        ? advancedSearchFormValue.deadweightFrom
        : 0,
      deadweightTo: advancedSearchFormValue.deadweightTo
        ? advancedSearchFormValue.deadweightTo
        : 0,
      yearOfBuildFrom: advancedSearchFormValue.yearOfBuildFrom
        ? advancedSearchFormValue.yearOfBuildFrom
        : 0,
      yearOfBuildTo: advancedSearchFormValue.yearOfBuildTo
        ? advancedSearchFormValue.yearOfBuildTo
        : 0,
      classSociety: advancedSearchFormValue.classSociety
        ? advancedSearchFormValue.classSociety
        : 0,
      classificationStatus: advancedSearchFormValue.classificationStatus
        ? advancedSearchFormValue.classificationStatus
        : [],
      vesselType: advancedSearchFormValue.vesselType
        ? advancedSearchFormValue.vesselType
        : [],
      vesselStatus: advancedSearchFormValue.vesselStatus
        ? advancedSearchFormValue.vesselStatus
        : '',
    };
    searchVessel(advancedSearchJson);
  };

  const searchVessel = async (advancedSearchJson: any) => {
    setSearchResult([]);
    setDoSearch(false);
    const data = getDataSource(
      '',
      apiConfig.advancedSearch,
      10,
      'POST',
      advancedSearchJson,
      false,
      undefined,
      true
    );
    setSearchResult(data);
    sessionStorage.setItem(
      'searchParam',
      JSON.stringify({
        searchJson: advancedSearchJson,
      })
    );
  };

  const onAddClick = () => {
    setIsAddNewVessel(true);
  };

  return (
    <div className="inner-wrapper">
      <ul aria-label="breadcrumb" className="m-c-bredcrumb">
        <li>
          <a>Home</a>
        </li>
        <li>
          <a className="active">Advanced Search</a>
        </li>
      </ul>
      <div className="m-l-inner-page-header">
        <div className="m-l-inner-page-header-left-block">
          <div className="m-l-page-main-heading">Advanced Search</div>
        </div>
        <div className="m-l-inner-page-header-right-block">
          <Button
            elementAttr={{ 'data-testid': 'clearButton' }}
            onClick={reset}
            className="app-c-btn app-c-btn--secondary"
          >
            {t('buttons.clear')}
          </Button>
          {!collapseSearch && (
            <Button
              elementAttr={{ 'data-testid': 'searchButton' }}
              onClick={search}
              className="app-c-btn app-c-btn--primary"
            >
              {t('buttons.search')}
            </Button>
          )}
          {collapseSearch && (
            <Button
              elementAttr={{ 'data-testid': 'searchButton' }}
              onClick={collapsedSearch}
              className="app-c-btn app-c-btn--primary"
            >
              {t('buttons.search')}
            </Button>
          )}
        </div>
      </div>
      <div className="m-l-inner-page-body-wrap m-l-srv-pdng">
        <div className="m-l-inner-page-body-wrap">
          <div className="m-l-inner-page-body u-mb0">
            <div className="m-l-form-panel-block">
              <div className="m-l-form-panel-body-block">
                {collapseSearch && (
                  <div className="m-l-expand-btn-wrap">
                    <Button
                      elementAttr={{ 'data-testid': 'expandButton' }}
                      onClick={onClickExpand}
                      className="app-c-btn app-c-icon-only-btn"
                      id="expand"
                    >
                      <Icons.ExpandIcon />
                      <Tooltip
                        target={'#expand'}
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        position="bottom"
                        contentRender={() => {
                          return (
                            <div className="m-l-tooltip-wrapper">
                              {t('labels.expand')}
                            </div>
                          );
                        }}
                      />
                    </Button>
                  </div>
                )}
                <FormProvider {...advancedSearchForm}>
                  <AdvancedSearchForm
                    collapseSearch={collapseSearch}
                    countryList={countryList}
                    classSocietyList={classSocietyList}
                    classificationStatusList={classificationStatusList}
                    vesselTypes={vesselTypes}
                    vesselStatusList={vesselStatusList}
                  />
                </FormProvider>
              </div>
            </div>
          </div>
          {collapseSearch && (
            <div className="m-l-search-result-display-block u-mb0">
              <div className="m-l-results-found u-mb0">
                {totalCount} {t('labels.resultsFound')}
              </div>
              <div className="right-btn-block">
                <Button
                  className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                  aria-label="Upload"
                  onClick={() =>
                    downloadgridRef.current.exportAdvanceSearchGrid()
                  }
                >
                  <div className="m-c-icon-inside-btn">
                    <icons.download />
                  </div>
                </Button>
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width"
                  onClick={onAddClick}
                >
                  {t('buttons.addNew')}
                </Button>
              </div>
              {isAddNewVessel && (
                <AddNewVessel
                  isAddNewVessel={isAddNewVessel}
                  setIsAddNewVessel={setIsAddNewVessel}
                />
              )}
            </div>
          )}
        </div>
        {collapseSearch && (
          <div data-testid="advancedSearchResultGrid">
            <AdvancedSearchResultGrid
              advanceSearchResultList={advanceSearchResultList}
              gridRef={gridRef}
              downloadgridRef={downloadgridRef}
              setSearchResult={setSearchResult}
              navigate={(imo: number) => {
                navigate(`/vessel-info`, { state: { imoNumber: imo } });
                sessionStorage.setItem('locationState', 'advancedSearch');
              }}
              setTotalCount={setTotalCount}
            ></AdvancedSearchResultGrid>
            {/* <div className="m-l-advance-search-pagination-section">
              <div className="m-l-advance-search-pagination-result">
                <p>
                  {t('labels.result')}{' '}
                  <span>
                    {skip}-{take}
                  </span>{' '}
                  {t('labels.of')} <span>{totalCount}</span>
                </p>
              </div>
              <div className="m-l-pagination-dropdown-block">
                <a onClick={previous}>{t('labels.previous')}</a>
                <div className="pagination-dropdown">
                  <SelectBox
                    dataSource={[10, 20, 50, 100]}
                    className="m-c-input-control m-c-select-box"
                    onValueChanged={(e) => setPerPageNum(e)}
                    defaultValue={perPageCount}
                    value={perPageCount}
                  ></SelectBox>
                </div>
                <a onClick={next}>{t('labels.next')}</a>
              </div>
            </div> */}
          </div>
        )}
      </div>
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              {collapseSearch && (
                <>
                  <Button
                    className="app-c-btn app-c-btn--secondary"
                    aria-label="Upload"
                    onClick={() =>
                      downloadgridRef.current.exportAdvanceSearchGrid()
                    }
                  >
                    {t('labels.download')}
                  </Button>
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    onClick={onAddClick}
                  >
                    {t('buttons.addNew')}
                  </Button>
                </>
              )}
              <Button
                elementAttr={{ 'data-testid': 'clearButton' }}
                onClick={reset}
                className="app-c-btn app-c-btn--secondary"
              >
                {t('buttons.clear')}
              </Button>
              {!collapseSearch && (
                <Button
                  elementAttr={{ 'data-testid': 'searchButton' }}
                  onClick={search}
                  className="app-c-btn app-c-btn--primary"
                >
                  {t('buttons.search')}
                </Button>
              )}
              {collapseSearch && (
                <Button
                  elementAttr={{ 'data-testid': 'searchButton' }}
                  onClick={collapsedSearch}
                  className="app-c-btn app-c-btn--primary"
                >
                  {t('buttons.search')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
