import React, { useEffect, useMemo, useState } from 'react';
import Button from 'devextreme-react/button';
import RuleGeneralFileds from '../../../components/rules/rule-general-fields/RuleGeneralFields';
import { FormProvider, useForm } from 'react-hook-form';
import {
  IAdditionalDataCaptureForm,
  IGroupCondition,
  IRule,
  IRuleAdditionaData,
  IRuleConditionFields,
  IRuleConditions,
  IRuleGeneralForm,
  IRuleGroups,
} from '../../../types/rules';
import RumeMessageFields from '../../../components/rules/rule-message-fields/RuleMessageFields';
import AdditionalDataCapturing from '../../../components/rules/additional-data-capturing/AdditionalDataCapturing';
import { useTranslation } from 'react-i18next';
import RuleCondition from '../../../components/rules/rule-condition/RuleCondition';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import {
  IEntityAttribute,
  IMasterEntity,
  IOptionvalue,
} from '../../../types/master-data';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { IDialogConfig } from '../../../types/dialog';
import ConfirmDialog from '../../../components/common/confirm/ConfirmDialog';
import toast from '../../../utils/toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { Relation } from '../../../enums/relation-enum';
import { Restricted } from '../../../contexts/PermissionContext';
import { VettingRuleTypes } from '../../../enums/vettingrules-type-enum';
import CountRecords from '../../../components/rules/count-records/CountRecords';
import { Entity } from '../../../enums/entity-enum';
import { useDateFormat } from '../../../hooks/useDateFormat';
import {
  getAttributes,
  getEntities,
  getOPtionValueByType,
} from '../../../services/master-data-service';
import { OptionTypes } from '../../../enums/option-type-enum';

export default function EditRule() {
  const { t } = useTranslation();
  const { setIsLoading } = useLoaderContext();

  const { apiDateFormat } = useDateFormat();

  const [ruleId, setRuleId] = useState<string>();

  const [incoTerms, setIncoTerms] = useState<IOptionvalue[]>([]);
  const [entities, setEntities] = useState<IMasterEntity[]>([]);
  const [attributes, setAttributes] = useState<IEntityAttribute[]>([]);
  const [conditions, setConditions] = useState<IOptionvalue[]>([]);
  const [vesselTypes, setVesselTypes] = useState<IOptionvalue[]>([]);
  const [canUserEnterOptions, setCanUserEnterOptions] = useState<
    IOptionvalue[]
  >([]);
  const [relations, setRelations] = useState<IOptionvalue[]>([]);
  const [ruleDetails, setRuleDetails] = useState<IRule>();
  const [confrimSave, setConfirmSave] = useState(false);
  const [confrimBack, setConfirmBack] = useState(false);
  const [isRFIToVDefined, setIsRFIToVDefined] = useState<any>();
  const [isRFIToCDefined, setIsRFIToCDefined] = useState<any>();
  const [enableRfi, setEnableRfi] = useState<any>();

  const navigate = useNavigate();
  const [buId, setBuId] = useState(Number);
  const [ruleType, setRuleType] = useState();
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      const { buId, ruleType, ruleId, isActive }: any = location.state;
      if (buId) {
        setBuId(parseInt(buId));
      }
      setRuleType(ruleType);
      setRuleId(ruleId);
      setIsActive(isActive);
    }
  }, [location.state]);

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.ruleSaveConfirm'),
    handleSubmit: () => {
      saveRule();
    },
    handleClose: () => {
      setConfirmSave(false);
    },
  };
  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBackEditList'),
    handleSubmit: () => {
      setConfirmBack(false);
      if (buId) {
        navigate('/company/business-unit/edit', {
          state: { fromPage: 'ruleTab', buId: buId },
        });
      } else {
        navigate('/rules');
      }
    },
    handleClose: () => {
      setConfirmBack(false);
    },
  };

  const defaulRuleGneralFormValues: IRuleGeneralForm = {
    ruleId: 0,
    ruleName: '',
    applicableIncoTerms: [],
    applicableVesselType: [],
    ruleDetails: '',
    rfiToClient: false,
    rfiToVesselOwner: false,
    canOverride: false,
    successMessage: '',
    failMessage: '',
    incidentsFrom: null,
    pscFrom: null,
  };
  const defaultAdditionalDataCaptureValues: IAdditionalDataCaptureForm = {
    fieldArray: [
      {
        ruleAdditionalDataId: 0,
        ruleId: 0,
        entityId: null,
        attributeId: null,
        isMandatory: false,
      },
    ],
  };
  const defaultRuleConditionValues: IRuleConditions = {
    groups: [
      {
        ruleGroupId: 0,
        ruleId: 0,
        parentRuleGroupId: null,
        dispalyOrder: 0,
        groupRelation: null,
        relation: Relation.Group,
        ruleConditions: [
          {
            ruleGroupId: 0,
            ruleConditionId: 0,
            entityId: null,
            attributeId: null,
            condition: null,
            value1: '',
            value2: '',
            canUserEnter: false,
            description: '',
            conditionRelation: null,
            dispalyOrder: 0,
            attributeType: '',
            isListValue: false,
            isSelectable: false,
            selectList: '',
            selectListDisplay: '',
            selectListValue: '',
            entityName: '',
            isEntityValue: false,
            valueEntityId: null,
            valueAttributeId: null,
            canValueUserEnter: false,
            isEditable: false,
            attributeName: '',
          },
        ],
      },
    ],
  };

  const ruleGeneralForm = useForm({
    defaultValues: defaulRuleGneralFormValues,
  });

  const additionalDataCaptureForm = useForm({
    defaultValues: defaultAdditionalDataCaptureValues,
  });

  const ruleConditionForm = useForm({
    defaultValues: defaultRuleConditionValues,
  });

  const { isDirty: generalFormDirty } = ruleGeneralForm.formState;

  const { isDirty: additionalDataFormDirty } =
    additionalDataCaptureForm.formState;

  const { isDirty: ruleConditionFormDirty } = ruleConditionForm.formState;

  const getRuleById = async () => {
    await api.get({ url: apiConfig.rules + ruleId }).then((res: IRule) => {
      res.ruleGroups?.map((group) => {
        group.relation = Relation.Group;
        group.ruleConditions.map((condition) => {
          condition.attributeType = condition.attribute?.attributeType;
          condition.attributeName = condition.attribute?.attributeName;
          condition.isEditable = condition.attribute?.isEditable;
          condition.entityName = condition.entity?.entityName;
          condition.isSelectable = condition.attribute?.isSelectable;
          condition.selectList = condition.attribute?.selectList;
          condition.selectListDisplay = condition.attribute?.selectListDisplay;
          condition.selectListValue = condition.attribute?.selectListValue;
          condition.value1 = condition.isListValue
            ? Number(condition.value1)
            : condition.value1;
        });
      });
      setRuleDetails(res);
      setIsRFIToVDefined(res.isRFIToVesselOwnerTemplateDefined);
      setIsRFIToCDefined(res.isRFIToClientTemplateDefined);
    });
  };

  useEffect(() => {
    getOPtionValueByType(OptionTypes.IncoTerms)?.then((res: IOptionvalue[]) => {
      setIncoTerms(res);
    });
    getEntities()?.then((res: IMasterEntity[]) => {
      res = res.filter((item) => item.isRequiredForRule);
      setEntities(res);
    });
    getOPtionValueByType(OptionTypes.CanUserEnter)?.then(
      (res: IOptionvalue[]) => {
        setCanUserEnterOptions(res);
      }
    );
    getOPtionValueByType(OptionTypes.ShipTypeLevel3)?.then(
      (res: IOptionvalue[]) => {
        setVesselTypes(res);
      }
    );
    getOPtionValueByType(OptionTypes.Relation)?.then((res: IOptionvalue[]) => {
      res.map((item) => {
        item.visible = item.optionValueCode === Relation.Group ? false : true;
      });
      setRelations(res);
    });
    getAttributes()?.then((res: IEntityAttribute[]) => {
      res = res.filter((item) => item.isRequiredForRule);
      setAttributes(res);
    });
    getOPtionValueByType(OptionTypes.RuleConditions)?.then(
      (res: IOptionvalue[]) => {
        setConditions(res);
      }
    );
  }, []);

  useEffect(() => {
    if (ruleId) {
      getRuleById();
    }
  }, [ruleId]);

  useEffect(() => {
    if (ruleDetails) {
      setEnableRfi({
        rfiToClient: ruleDetails.rfItoClient,
        rfiToVesselOwner: ruleDetails.rfItoVesselOwner,
      });
      ruleGeneralForm.reset();
      additionalDataCaptureForm.reset();
      ruleConditionForm.reset();
      if (ruleDetails.ruleGroups?.length) {
        ruleConditionForm.reset(
          { groups: ruleDetails?.ruleGroups },
          { keepDirty: true }
        );
      }
      if (ruleDetails.ruleAdditionalData?.length) {
        additionalDataCaptureForm.reset(
          { fieldArray: ruleDetails?.ruleAdditionalData },
          { keepDirty: true }
        );
      }
      ruleGeneralForm.reset(
        {
          ruleId: ruleDetails.ruleId,
          ruleName: ruleDetails.ruleName,
          ruleDetails: ruleDetails.ruleDetails,
          rfiToClient: ruleDetails.rfItoClient,
          rfiToVesselOwner: ruleDetails.rfItoVesselOwner,
          canOverride: ruleDetails.canOverride,
          applicableIncoTerms: ruleDetails.ruleIncoTerms.map(
            (item) => item.incoTerms
          ),
          applicableVesselType: ruleDetails.ruleVesselTypes.map(
            (item) => item.vesselType
          ),
          incidentsFrom: ruleDetails.incidentsFrom,
          pscFrom: ruleDetails.pscFrom,
          successMessage: ruleDetails.successMessage,
          failMessage: ruleDetails.failureMessage,
        },
        { keepDirty: true }
      );
    }
  }, [ruleDetails]);

  const getRuleJson = (): IRule => {
    const ruleFormValue = ruleGeneralForm.getValues();
    const additionalData = additionalDataCaptureForm.getValues();
    const ruleAdditionalData: IRuleAdditionaData[] = [];
    additionalData.fieldArray.map((item) => {
      if (item.entityId) {
        ruleAdditionalData.push({
          ruleId: ruleId ? Number(ruleId) : 0,
          ruleAdditionalDataId: item.ruleAdditionalDataId,
          entityId: item.entityId,
          attributeId: item.attributeId,
          isMandatory: item.isMandatory,
        });
      }
    });

    const ruleJson: IRule = {
      ruleId: ruleFormValue.ruleId,
      ruleName: ruleFormValue.ruleName,
      ruleDetails: ruleFormValue.ruleDetails,
      rfItoClient: ruleFormValue.rfiToClient,
      rfItoVesselOwner: ruleFormValue.rfiToVesselOwner,
      canOverride: ruleFormValue.canOverride,
      successMessage: ruleFormValue.successMessage,
      failureMessage: ruleFormValue.failMessage,
      incidentsFrom: ruleFormValue.incidentsFrom,
      pscFrom: ruleFormValue.pscFrom,
      ruleVesselTypes: ruleFormValue.applicableVesselType.map(
        (item: string) => {
          const vesselType =
            ruleDetails &&
            ruleDetails.ruleVesselTypes.find(
              (type) => type.vesselType === item
            );
          return {
            ruleId: ruleFormValue.ruleId,
            ruleVesselTypeId: vesselType ? vesselType.ruleVesselTypeId : 0,
            vesselType: item,
          };
        }
      ),
      ruleIncoTerms: ruleFormValue.applicableIncoTerms.map((item: string) => {
        const inco =
          ruleDetails &&
          ruleDetails.ruleIncoTerms.find(
            (incoTerm) => incoTerm.incoTerms === item
          );
        return {
          ruleId: ruleFormValue.ruleId,
          ruleIncoTermId: inco ? inco.ruleIncoTermId : 0,
          incoTerms: item,
        };
      }),
      ruleAdditionalData: ruleAdditionalData,
      ruleGroups: getRuleConditionsJson(),
      buId: ruleId ? buId : undefined,
    };
    return ruleJson;
  };

  const getRuleConditionsJson = (): IRuleGroups[] => {
    let ruleConditions: IGroupCondition[] = JSON.parse(
      JSON.stringify(ruleConditionForm.getValues().groups)
    );
    ruleConditions = ruleConditions.filter((group: IGroupCondition) => {
      group.ruleConditions = group.ruleConditions.filter(
        (item: IRuleConditionFields) => item.entityId
      );
      return group.ruleConditions.length > 0;
    });
    const ruleGroups: IRuleGroups[] = [];
    ruleConditions.map((group, groupIndex) => {
      if (group.ruleConditions.length && group.ruleConditions) {
        ruleGroups.push({
          ruleId: ruleId ? Number(ruleId) : 0,
          ruleGroupId: group.ruleGroupId,
          parentRuleGroupId: null,
          displayOrder: groupIndex + 1,
          groupRelation: group.groupRelation ? group.groupRelation : '',
          ruleConditions: group.ruleConditions.map((rule, index) => {
            return {
              ruleConditionId: rule.ruleConditionId,
              ruleGroupId: group.ruleGroupId,
              entityId: rule.entityId,
              attributeId: rule.attributeId,
              condition: rule.condition,
              value1: rule.value1
                ? rule.attributeType === 'Date' ||
                  rule.attributeType === 'DateTime'
                  ? apiDateFormat(rule.value1)
                  : rule.value1?.toString()
                : '',
              value2:
                rule.attributeType === 'Date' ||
                rule.attributeType === 'DateTime'
                  ? rule.value2
                    ? apiDateFormat(rule.value2)
                    : ''
                  : rule.value2?.toString(),
              canUserEnter: rule.canUserEnter,
              description: rule.description,
              displayOrder: index + 1,
              conditionRelation: rule.conditionRelation
                ? rule.conditionRelation
                : '',
              isListValue: rule.isListValue,
              isEntityValue: rule.isEntityValue,
              valueAttributeId: rule.valueAttributeId,
              valueEntityId: rule.valueEntityId,
              canValueUserEnter: rule.canValueUserEnter,
            };
          }),
        });
      }
    });
    return ruleGroups;
  };

  const saveRule = async () => {
    const headerPayload: any = {
      url: apiConfig.saveRules,
      data: getRuleJson(),
    };
    const obj = { ...headerPayload };
    obj.params = { buId: buId };
    setConfirmSave(false);
    if (!ruleId) {
      await api.post(obj, setIsLoading).then((res) => {
        toast.success({ title: t('toast.updatedSuccessfully') });
        if (buId) {
          navigate('/rules/edit', {
            state: { ruleId: res.ruleId, buId: buId, isActive: isActive },
          });
        } else {
          navigate('/rules/edit', {
            state: { ruleId: res.ruleId, isActive: isActive },
          });
        }
      });
    } else {
      await api
        .put({ url: apiConfig.saveRules, data: getRuleJson() }, setIsLoading)
        .then(() => {
          getRuleById();
          toast.success({ title: t('toast.updatedSuccessfully') });
        });
    }
  };

  const onSaveClick = (e: any) => {
    const validationResult = e.validationGroup.validate();
    if (validationResult.isValid) {
      setConfirmSave(true);
    }
  };

  const onBackClick = () => {
    if (
      (generalFormDirty || additionalDataFormDirty || ruleConditionFormDirty) &&
      !(ruleId && buId && ruleType === VettingRuleTypes.Global)
    ) {
      setConfirmBack(true);
    } else {
      if (buId) {
        navigate('/company/business-unit/edit', {
          state: { fromPage: 'ruleTab', buId: buId },
        });
      } else {
        navigate('/rules');
      }
    }
  };

  const [showCountRecords, setShowSoundRecords] = useState(false);

  useEffect(() => {
    let showCounts = false;
    ruleConditionForm.getValues().groups.map((group) => {
      if (!showCounts) {
        showCounts = !!group.ruleConditions.find((item) => {
          const entity = entities.find(
            (entity) => entity.masterEntityId === item.entityId
          );
          return (
            entity?.entityName === Entity.Incident ||
            entity?.entityName === Entity.PSC ||
            entity?.entityName === Entity.IncidentHistory
          );
        });
      }
    });
    setShowSoundRecords(showCounts);
  }, [ruleConditionForm.formState]);

  return (
    <div className="inner-wrapper">
      <ul aria-label="breadcrumb" className="m-c-bredcrumb">
        <li>
          <a>Configuration </a>
        </li>
        <li>
          <a className="active">Rule</a>
        </li>
      </ul>
      <div className="m-l-inner-page-header">
        <div className="m-l-inner-page-header-left-block m-l-warning-banner-wrap">
          {!ruleId && (
            <div className="m-l-page-main-heading">
              {t('headers.addNewRule')}
            </div>
          )}
          {ruleDetails && (
            <div className="m-l-page-main-heading">
              {t('headers.ruleView')}
              {ruleDetails?.ruleName}
            </div>
          )}
          {isActive && ruleDetails && (
            <div className="m-l-page-create-company-info">
              {t('toast.activeWarningRules')}
            </div>
          )}
        </div>
        <div className="m-l-inner-page-header-right-block">
          <Button
            className="app-c-btn app-c-btn--secondary"
            elementAttr={{ 'data-testid': 'backButton' }}
            onClick={onBackClick}
          >
            {t('buttons.back')}
          </Button>
          <Restricted permission={'BURules.Add'}>
            {!ruleId && buId != 0 && (
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                elementAttr={{ 'data-testid': 'saveButton' }}
                disabled={!!ruleId}
                onClick={onSaveClick}
              >
                {t('buttons.save')}
              </Button>
            )}
          </Restricted>
          <Restricted permission={'GlobalRules.Add'}>
            {!ruleId && !buId && (
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                elementAttr={{ 'data-testid': 'saveButton' }}
                onClick={onSaveClick}
              >
                {t('buttons.save')}
              </Button>
            )}
          </Restricted>
          <Restricted permission="GlobalRules.Edit">
            {ruleId && !buId && (
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                elementAttr={{ 'data-testid': 'saveButton' }}
                onClick={onSaveClick}
              >
                {t('buttons.save')}
              </Button>
            )}
          </Restricted>
          <Restricted permission="BURules.Edit">
            {!!ruleId && !!buId && (
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                elementAttr={{ 'data-testid': 'saveButton' }}
                onClick={onSaveClick}
                disabled={ruleType === VettingRuleTypes.Global}
              >
                {t('buttons.save')}
              </Button>
            )}
          </Restricted>
        </div>
      </div>
      <div className="m-l-inner-page-body-wrap">
        <div className="m-l-inner-page-body">
          <div className="m-l-form-panel-block">
            <div className="m-l-form-panel-body-block">
              <FormProvider {...ruleGeneralForm}>
                <RuleGeneralFileds
                  buId={buId}
                  ruleId={ruleId}
                  incoTerms={incoTerms}
                  vesselTypes={vesselTypes}
                  isRFIToClientTemplateDefined={isRFIToCDefined}
                  isRFIToVesselOwnerTemplateDefined={isRFIToVDefined}
                  isRFIEditable={
                    !!(buId && ruleId && ruleType === VettingRuleTypes.Global)
                  }
                  getRuleById={getRuleById}
                  enableRfi={enableRfi}
                />
              </FormProvider>
              {showCountRecords && (
                <FormProvider {...ruleGeneralForm}>
                  <CountRecords
                    getConditionValues={ruleConditionForm.getValues}
                    ruleConditionFormState={ruleConditionForm.formState}
                    entities={entities}
                  />
                </FormProvider>
              )}
              <FormProvider {...ruleConditionForm}>
                <RuleCondition
                  buId={buId}
                  entities={entities}
                  attributes={attributes}
                  conditions={conditions}
                  canUserEnterOptions={canUserEnterOptions}
                  relations={relations}
                />
              </FormProvider>
              <FormProvider {...additionalDataCaptureForm}>
                <AdditionalDataCapturing
                  entities={entities}
                  attributes={attributes}
                  ruleDetails={ruleDetails}
                />
              </FormProvider>
              <FormProvider {...ruleGeneralForm}>
                <RumeMessageFields />
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <Button
                className="app-c-btn app-c-btn--secondary"
                elementAttr={{ 'data-testid': 'backButton' }}
                onClick={onBackClick}
              >
                {t('buttons.back')}
              </Button>
              <Restricted permission={'BURules.Add'}>
                {!ruleId && buId != 0 && (
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'saveButton' }}
                    disabled={!!ruleId}
                    onClick={onSaveClick}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Restricted>
              <Restricted permission={'GlobalRules.Add'}>
                {!ruleId && !buId && (
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'saveButton' }}
                    onClick={onSaveClick}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Restricted>
              <Restricted permission="GlobalRules.Edit">
                {ruleId && !buId && (
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'saveButton' }}
                    onClick={onSaveClick}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Restricted>
              <Restricted permission="BURules.Edit">
                {!!ruleId && !!buId && (
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'saveButton' }}
                    onClick={onSaveClick}
                    disabled={ruleType === VettingRuleTypes.Global}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Restricted>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={confrimSave}
      />
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={confrimBack}
      />
    </div>
  );
}
