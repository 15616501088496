import React, { useEffect, useRef, useState } from 'react';
import Button from 'devextreme-react/button';
import Switch from 'devextreme-react/switch';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../../../components/common/confirm/ConfirmDialog';
import Grid from '../../../components/common/grid/Grid';
import { IDialogConfig } from '../../../types/dialog';
import { IGridConfig } from '../../../types/grid';
import RuleClone from '../../../components/rules/rule-clone/CloneRule';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { IRuleActive, IRuleClone } from '../../../types/rules';
import toast from '../../../utils/toast';
import { Restricted, usePermission } from '../../../contexts/PermissionContext';
import DataGrid from 'devextreme-react/data-grid';
import { useDataStore } from '../../../hooks/useDataStore';
import { useLoaderContext } from '../../../contexts/LoaderContext';

export default function ListRules() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [rulesList, setRulesList] = useState<any>();
  const [showClonePop, setShowClonePop] = useState(0);
  const [activeConfirmDialog, setActiveConfirmDialog] = useState(false);
  const { findFeature, featureList } = usePermission();
  const { getDataSource } = useDataStore();
  const { setIsLoading } = useLoaderContext();

  const ruleGridRef = useRef<DataGrid>(null);

  const getRulesList = async () => {
    const data = getDataSource('ruleId', apiConfig.rulesList, 10);
    setRulesList(data);
  };

  useEffect(() => {
    getRulesList();
  }, []);

  const confirmStatusDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      setActiveConfirmDialog(false);
    },
    handleClose: () => {
      getRulesList();
      setActiveConfirmDialog(false);
    },
  };

  const [activeDialogConfig, setActiveDialogConfig] = useState(
    confirmStatusDialogConfig
  );

  const activateRule = async (isactive: boolean, ruleId: any) => {
    const ruleStatusJson: IRuleActive = {
      ruleId: ruleId,
      isActive: isactive,
    };
    api
      .put({ url: apiConfig.ruleStatus, data: ruleStatusJson }, setIsLoading)
      .then(() => {
        getRulesList();
      });
  };

  const handleActiveInactiveChange = async (e: any, rule: any) => {
    if (e.event) {
      const dialogConfig = {
        title: t('toast.confirmation'),
        content: e.value ? t('toast.activateRule') : t('toast.deactivateRule'),
        handleSubmit: () => {
          activateRule(e.value, rule?.ruleId);
          setActiveConfirmDialog(false);
        },
      };
      setActiveDialogConfig({
        ...confirmStatusDialogConfig,
        ...dialogConfig,
      });
      setActiveConfirmDialog(true);
    }
  };

  const handleCloneButtonClick = (ruleId: any) => {
    setShowClonePop(ruleId);
  };

  const cloneRule = async (cloneRuleObject: IRuleClone) => {
    await api
      .post({ url: apiConfig.cloneRule, data: cloneRuleObject }, setIsLoading)
      .then(() => {
        toast.custom({
          title: t('toast.savedSuccessfully'),
          message: cloneRuleObject.ruleName + t('toast.ruleClone'),
        });
        getRulesList();
        setShowClonePop(0);
      });
  };

  const onHidingClonePopover = () => {
    setShowClonePop(0);
  };

  const onRowClick = (e: any) => {
    if (
      ![
        'dx-switch-on',
        'dx-switch-off',
        'dx-switch-handle',
        'dx-button-content',
      ].includes(e.event.target.className)
    ) {
      navigate('/rules/edit', {
        state: { ruleId: e.data.ruleId, isActive: e.data.isActive },
      });
    }
  };

  const yesNoDataSource = [
    {
      name: t('labels.active'),
      value: true,
    },
    {
      name: t('labels.inActive'),
      value: false,
    },
  ];

  const gridConfig: IGridConfig = {
    ref: ruleGridRef,
    testId: 'rulesListGrid',
    dataSource: rulesList,
    rowClick: onRowClick,
    addButtonPermission: 'GlobalRules.Add',
    rowClickPermission: 'GlobalRules.ViewDetail',
    remoteOperations: true,
    defaultColumns: [
      {
        caption: t('labels.ruleName'),
        dataField: 'ruleName',
        dataType: 'string',
        minWidth: 150,
        sortOrder: 'asc',
      },
      {
        caption: t('labels.applicableVesselType'),
        dataField: 'ruleVesselTypes',
        dataType: 'string',
        minWidth: 250,
        cellTemplate: 'actionTemplate1',
        calculateCellValue: (data: any) => {
          return data.ruleVesselTypes?.length > 0
            ? data.ruleVesselTypes.map((item: any) => item.vesselType)
            : [];
        },
      },
      {
        caption: t('labels.applicableIncoTerms'),
        dataField: 'ruleIncoTerms',
        dataType: 'string',
        minWidth: 250,
        cellTemplate: 'cellTemplate',
        calculateCellValue: (data: any) => {
          return data.ruleIncoTerms?.length > 0
            ? data.ruleIncoTerms.map((item: any) => item.incoTerms)
            : [];
        },
      },
      {
        caption: t('labels.ruleDetails'),
        dataField: 'ruleDetails',
        dataType: 'string',
        minWidth: 250,
      },
      {
        caption: t('labels.status'),
        dataField: 'isActive',
        alignment: 'center',
        dataType: 'boolean',
        minWidth: 100,
        cellTemplate: 'statusTemplate',
        lookup: {
          dataSource: yesNoDataSource,
          valueExpr: 'value',
          displayExpr: 'name',
        },
      },
      {
        caption: t(' '),
        dataField: 'action',
        dataType: 'string',
        cellTemplate: 'actionTemplate',
        alignment: 'center',
        minWidth: 100,
        allowFiltering: false,
        allowSorting: false,
      },
    ],
    showHeader: true,
    header: t('headers.rules'),
    showAddButton: true,
    showExport: true,
    excelFileName: 'Rules.xlsx',
    hidePagination: false,
    noDataText: t('labels.companyRuleNoDataText'),
    onClickAddButton: () => {
      navigate('/rules/add');
    },
  };

  useEffect(() => {
    ruleGridRef.current?.instance.columnOption(
      5,
      'visible',
      findFeature('GlobalRules.Clone')
    );
  }, [featureList]);

  return (
    <>
      <div className="inner-wrapper page-inner-wrap">
        <ul aria-label="breadcrumb" className="m-c-bredcrumb">
          <li>
            <a className="active">{t('headers.rule')}</a>
          </li>
        </ul>
        <Grid
          gridConfig={gridConfig}
          statusTemplate={cellRender}
          actionTemplate={actionTemplate}
          cellTemplate={incoTermsCellTemplate}
          actionTemplate1={vesselTypeCellTemplate}
        />
        <ConfirmDialog
          dialogConfig={activeDialogConfig}
          isOpen={activeConfirmDialog}
        />
      </div>
    </>
  );

  function cellRender(data: any) {
    return (
      <div className="m-l-field-wrap">
        <div className="m-l-toggle-switch">
          <Restricted
            permission="GlobalRules.Activate/Inactive"
            disableField={true}
          >
            <Switch
              elementAttr={{ 'data-testid': 'switch' }}
              defaultValue={data.value}
              onValueChanged={(e) => {
                handleActiveInactiveChange(e, data.data);
              }}
            />
          </Restricted>
        </div>
      </div>
    );
  }

  function actionTemplate(data: any) {
    return (
      <Restricted permission="GlobalRules.Clone">
        <Button
          id={`rule${data.data.ruleId}`}
          elementAttr={{ 'data-testid': 'cloneRule' }}
          className="app-c-btn app-c-btn--secondary"
          onClick={() => {
            handleCloneButtonClick(data.data.ruleId);
          }}
        >
          {t('buttons.clone')}
        </Button>
        {showClonePop === data.data.ruleId && (
          <RuleClone
            ruleId={data.data.ruleId}
            onClickCancel={() => {
              setShowClonePop(0);
            }}
            showPopover={showClonePop === data.data.ruleId}
            cloneRule={cloneRule}
            closeOnOutsideClick={true}
            onHidingPopover={onHidingClonePopover}
            target={`#rule${data.data.ruleId}`}
          />
        )}
      </Restricted>
    );
  }

  function incoTermsCellTemplate(data: any) {
    return (
      <div className="m-l-badge-container">
        {data.value[0]}
        {data.value.length > 1 && (
          <span className="badge">{data.value.length - 1}+</span>
        )}
      </div>
    );
  }

  function vesselTypeCellTemplate(data: any) {
    return (
      <div className="m-l-badge-container">
        {data.value[0]}
        {data.value.length > 1 && (
          <span className="badge">{data.value.length - 1}+</span>
        )}
      </div>
    );
  }
}
