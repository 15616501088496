import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import AuxillaryEngineForm from '../auxillary-engine-form/AuxillaryEngineForm';
import EnergyForm from '../energy-form/EnergyForm';
import EngineForm from '../engine-form/EngineForm';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import toast from '../../../../../utils/toast';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../../../types/dialog';
import {
  IEngineForm,
  IAuxillaryEngineForm,
  IEnergyForm,
  IEngineEdit,
} from '../../../../../types/vessel-engine-tab';
import { useLoaderContext } from '../../../../../contexts/LoaderContext';
import { isTabDirtyContext } from '../../../../../pages/vessel-info/VesselInformation';

export default function EngineEdit(props: {
  setIsEdit: any;
  setEngineView: any;
  engineDetails: any;
  getEngineDetails: any;
  imoNumber?: string;
}) {
  const {
    setIsEdit,
    setEngineView,
    engineDetails,
    getEngineDetails,
    imoNumber,
  } = props;
  const { t } = useTranslation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { setIsLoading } = useLoaderContext();
  const [isconfirmBackDialog, setIsConfirmBackDialog] = useState(false);
  const [dirtyFlag, setDirtyFlag] = useState(true);
  const setTabDirtyFlag = useContext(isTabDirtyContext);
  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmSaveChanges'),
    handleSubmit: () => {
      onValueSubmit();
      setIsConfirmModalOpen(false);
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };
  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setIsEdit(false);
      setEngineView(true);
      setIsConfirmBackDialog(false);
      setTabDirtyFlag.setState(
        setTabDirtyFlag.state == 0
          ? setTabDirtyFlag.state
          : setTabDirtyFlag.state - 1
      );
    },
    handleClose: () => {
      setIsConfirmBackDialog(false);
    },
  };

  const defaultEngineFormValues: IEngineForm = {
    enginePower: '',
    mainEngineBore: '',
    mainEngineBuilder: '',
    mainEngineBuilderCode: '',
    mainEngineConsumptionInBallast: '',
    mainEngineConsumptionInloaded: '',
    mainEngineModel: '',
    mainEngineSpeedInBallast: '',
    mainEngineSpeedInLoaded: '',
    mainEngineType: '',
    powerkwmax: 0,
    speedMax: 0,
  };
  const defaultAuxillaryEngineFormValues: IAuxillaryEngineForm = {
    auxiliaryGeneratorsDescriptiveNarrative: '',
    bhp: '',
    kw: '',
    numberOfAuxiliaryEngines: 0,
    rpm: '',
  };
  const defaultEnergyFormValues: IEnergyForm = {
    cii: '',
    eedi: '',
    eeoi: '',
    eexi: '',
  };

  const vesselEngineForm = useForm({
    defaultValues: defaultEngineFormValues,
  });
  const auxillaryEngineForm = useForm({
    defaultValues: defaultAuxillaryEngineFormValues,
  });
  const energyForm = useForm({
    defaultValues: defaultEnergyFormValues,
  });

  const onHandleSave = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      setIsConfirmModalOpen(true);
    }
  };

  const { isDirty } = vesselEngineForm.formState;
  const { isDirty: isDirtyAux } = auxillaryEngineForm.formState;
  const { isDirty: isDirtyEnergy } = energyForm.formState;

  const handleBackClick = () => {
    isDirty
      ? setIsConfirmBackDialog(true)
      : (setIsEdit(false), setEngineView(true));
  };

  // const vesselEngineFormValue: IEngineForm = vesselEngineForm.getValues();
  useEffect(() => {
    vesselEngineForm.reset(undefined, { keepDirty: true });
    if (engineDetails) {
      vesselEngineForm.reset(
        {
          enginePower: engineDetails.enginePower,
          mainEngineBore: engineDetails.mainEngineBore,
          mainEngineBuilder: engineDetails.mainEngineBuilder,
          mainEngineBuilderCode: engineDetails.mainEngineBuilderCode,
          mainEngineConsumptionInBallast:
            engineDetails.mainEngineConsumptionInBallast,
          mainEngineConsumptionInloaded:
            engineDetails.mainEngineConsumptionInloaded,
          mainEngineModel: engineDetails.mainEngineModel,
          mainEngineSpeedInBallast: engineDetails.mainEngineSpeedInBallast,
          mainEngineSpeedInLoaded: engineDetails.mainEngineSpeedInLoaded,
          mainEngineType: engineDetails.mainEngineType,
          powerkwmax: engineDetails.powerkwmax,
          speedMax: engineDetails.speedMax,
        },
        { keepDirty: true }
      );
      auxillaryEngineForm.reset(
        {
          auxiliaryGeneratorsDescriptiveNarrative:
            engineDetails.auxiliaryGeneratorsDescriptiveNarrative,
          bhp: engineDetails.bhp,
          kw: engineDetails.kw,
          numberOfAuxiliaryEngines: engineDetails.numberOfAuxiliaryEngines,
          rpm: engineDetails.rpm,
        },
        { keepDirty: true }
      );
      energyForm.reset(
        {
          cii: engineDetails.cii,
          eedi: engineDetails.eedi,
          eeoi: engineDetails.eeoi,
          eexi: engineDetails.eexi,
        },
        { keepDirty: true }
      );
    }
  }, [engineDetails]);

  const onValueSubmit = () => {
    const engineFormValue: IEngineForm = vesselEngineForm.getValues();
    const auxillaryEngineFormValue: IAuxillaryEngineForm =
      auxillaryEngineForm.getValues();
    const energyFormValue: IEnergyForm = energyForm.getValues();
    const roleJsonEdit: IEngineEdit = {
      imoNumber: imoNumber,
      enginePower: engineFormValue.enginePower,
      mainEngineBore: engineFormValue.mainEngineBore,
      mainEngineBuilder: engineFormValue.mainEngineBuilder,
      mainEngineBuilderCode: engineFormValue.mainEngineBuilderCode,
      mainEngineConsumptionInBallast:
        engineFormValue.mainEngineConsumptionInBallast,
      mainEngineConsumptionInloaded:
        engineFormValue.mainEngineConsumptionInloaded,
      mainEngineModel: engineFormValue.mainEngineModel,
      mainEngineSpeedInBallast: engineFormValue.mainEngineSpeedInBallast,
      mainEngineSpeedInLoaded: engineFormValue.mainEngineSpeedInLoaded,
      mainEngineType: engineFormValue.mainEngineType,
      powerkwmax: engineFormValue.powerkwmax,
      speedMax: engineFormValue.speedMax,
      auxiliaryGeneratorsDescriptiveNarrative:
        auxillaryEngineFormValue.auxiliaryGeneratorsDescriptiveNarrative,
      bhp: auxillaryEngineFormValue.bhp,
      kw: auxillaryEngineFormValue.kw,
      numberOfAuxiliaryEngines:
        auxillaryEngineFormValue.numberOfAuxiliaryEngines,
      rpm: auxillaryEngineFormValue.rpm,
      cii: energyFormValue.cii,
      eedi: energyFormValue.eedi,
      eeoi: energyFormValue.eeoi,
      eexi: energyFormValue.eexi,
      imoDisplayName: engineDetails.imoDisplayName,
    };
    api
      .put({ url: apiConfig.engineTab, data: roleJsonEdit }, setIsLoading)
      .then((response) => {
        if (response == true) {
          getEngineDetails();
          setIsEdit(false);
          setEngineView(true);
          setTabDirtyFlag.setState(
            setTabDirtyFlag.state == 0
              ? setTabDirtyFlag.state
              : setTabDirtyFlag.state - 1
          );
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
        }
      });
  };

  useEffect(() => {
    if ((isDirty || isDirtyAux || isDirtyEnergy) && dirtyFlag) {
      setTabDirtyFlag.setState(setTabDirtyFlag.state + 1);
      setDirtyFlag(false);
    }
  }, [isDirty, isDirtyAux, isDirtyEnergy]);

  return (
    <div>
      <div className="m-l-inner-page-body">
        <div className="m-l-form-panel-block">
          <div className="m-l-tbl-square-wrapper m-l-tbl-square-wrapper--edit-mode u-pt0">
            <div className="row m-l-custom-eight-space-row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items m-l-custom-sixteen-space-col">
                <div className="m-l-details-page-small-heading">
                  Main Engine
                </div>
              </div>
              <FormProvider {...vesselEngineForm}>
                <EngineForm />
              </FormProvider>
            </div>
            <div className="row m-l-custom-eight-space-row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items u-mb0 m-l-custom-sixteen-space-col">
                <div className="m-l-details-page-small-heading m-l-details-page-small-heading--srubber-engine">
                  Auxillary Engine
                </div>
              </div>
              <FormProvider {...auxillaryEngineForm}>
                <AuxillaryEngineForm />
              </FormProvider>
            </div>
            <div className="row m-l-custom-eight-space-row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items u-mb-xl">
                <div className="m-l-details-page-sepeartor"></div>
              </div>
            </div>
            <div className="row m-l-custom-eight-space-row">
              <FormProvider {...energyForm}>
                <EnergyForm />
              </FormProvider>
            </div>

            <div className="m-l-panel-right-btn u-pb0">
              <Button
                className="app-c-btn app-c-btn--secondary min-btn-width"
                onClick={handleBackClick}
              >
                {t('labels.cancel')}
              </Button>
              <Button
                onClick={onHandleSave}
                className="app-c-btn app-c-btn--primary min-btn-width"
              >
                {t('labels.save')}
              </Button>
              <ConfirmDialog
                dialogConfig={confirmSaveDialogConfig}
                isOpen={isConfirmModalOpen}
              />
              <ConfirmDialog
                dialogConfig={confirmBackDialogConfig}
                isOpen={isconfirmBackDialog}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
