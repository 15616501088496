import React, { createContext, useContext, useEffect, useState } from 'react';
import { IPermission } from '../types/login';
import { MarsApiService as api } from '../api/mars-api-service';
import { getAPIRoute } from '../utils/api-route';
import { useAuth } from './AuthProvider';
import { getBuList } from '../utils/jwt-decode';

type PermissionContextType = {
  isAllowedTo: (feature?: string) => Promise<boolean>;
  getFeatureList: () => Promise<void>;
  findFeature: (feature?: string) => boolean;
  featureList: IPermission[];
  isFeatureListLoaded: boolean;
};
const defaultBehaviour: PermissionContextType = {
  isAllowedTo: () => Promise.resolve(false),
  getFeatureList: () => Promise.resolve(),
  findFeature: () => {
    return false;
  },
  featureList: [],
  isFeatureListLoaded: false,
};

const PermissionContext =
  createContext<PermissionContextType>(defaultBehaviour);

function PermissionProvider(props: any) {
  const isAllowedTo = async (feature?: string) => {
    if (feature) {
      return !!featureList.find((item) => item.name === feature);
    }
    return true;
  };

  const [featureList, setFeatureList] = useState<IPermission[]>([]);

  const [isFeatureListLoaded, setIsFeatureListLoaded] = useState(false);

  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn) getFeatureList();
  }, [isLoggedIn]);

  const getFeatureList = async () => {
    setIsFeatureListLoaded(false);
    const bu = JSON.parse(getBuList() as string).find(
      (item: any) => item.isDefault
    );
    await api
      .get({ url: getAPIRoute('securityFunctions', [bu.buId]) })
      .then((res) => {
        setFeatureList(res);
        setIsFeatureListLoaded(true);
      });
  };

  const findFeature = (feature?: string) => {
    if (feature) {
      return !!featureList.find((item) => item.name === feature);
    }
    return true;
  };

  return (
    <PermissionContext.Provider
      value={{
        isAllowedTo,
        getFeatureList,
        featureList,
        findFeature,
        isFeatureListLoaded,
      }}
    >
      {props.children}
    </PermissionContext.Provider>
  );
}

const usePermission = (permission?: string) => {
  const [allowed, setAllowed] = useState<boolean>();

  const {
    isAllowedTo,
    getFeatureList,
    featureList,
    findFeature,
    isFeatureListLoaded,
  } = useContext(PermissionContext);
  useEffect(() => {
    let cancel = false;
    isAllowedTo(permission).then((allowed) => {
      if (cancel) return;
      setAllowed(allowed);
    });

    return () => {
      cancel = true;
    };
  }, [permission, featureList]);

  return {
    allowed,
    featureList,
    getFeatureList,
    findFeature,
    isAllowedTo,
    isFeatureListLoaded,
  };
};
function Restricted(props: {
  permission?: string;
  disableField?: boolean;
  children: any;
}) {
  const { permission, children, disableField } = props;

  const { allowed } = usePermission(permission);

  if (disableField && !allowed) {
    return (
      <div style={{ pointerEvents: 'none', opacity: 0.5 }}>{children}</div>
    );
  }
  if (allowed) {
    return <>{children}</>;
  }
  return <></>;
}

export { PermissionProvider, usePermission, Restricted, PermissionContext };
