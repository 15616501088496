import React, { useEffect, useState } from 'react';
import { IGridConfig } from '../../../../types/grid';
import Grid from '../../../common/grid/Grid';
import PortContactEditModal from '../port-contact-edit-modal/PortContactEditMoadal';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { useDataStore } from '../../../../hooks/useDataStore';

export default function PortContactGrid(props: {
  portCode: any;
  isFromViewInfoModal?: boolean;
}) {
  const { portCode, isFromViewInfoModal } = props;
  const [showEditContactModal, setShowEditContactModal] = useState(false);
  const [portContacts, setPortContacts] = useState<any>([]);
  const [selectedContact, setSelectedContact] = useState<any>([]);
  const { getDataSource } = useDataStore();

  const onClickAddContact = () => {
    setSelectedContact(null);
    setShowEditContactModal(true);
  };

  const cancelClick = () => {
    setShowEditContactModal(false);
  };

  const onRowClick = (e: any) => {
    if (!isFromViewInfoModal) {
      setSelectedContact(e.data);
      setShowEditContactModal(true);
    }
  };

  const getPortContacts = async (portCode: any) => {
    // const dataContact = getDataSource(
    //   'portCode',
    //   apiConfig.getAllPortContacts + portCode
    // );
    // setPortContacts(dataContact);
    await api
      .get({
        url: apiConfig.getAllPortContacts + portCode,
      })
      .then((res: any) => {
        setPortContacts(res.data);
      });
  };

  useEffect(() => {
    if (portCode) {
      getPortContacts(portCode);
    }
  }, []);

  const gridConfig: IGridConfig = {
    class: 'm-c-grid m-l-grid-with--top-border m-c-righticon-grid',
    testId: 'vesselNameGrid',
    height: '350',
    dataSource: portContacts,
    // rowClickPermission: 'Ship.EditName',
    rowClick: onRowClick,
    onClickAddButton: onClickAddContact,
    // remoteOperations: true,
    defaultColumns: [
      {
        caption: 'Contact Type',
        dataField: 'contactType',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'Name',
        dataField: 'name',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'Address',
        dataField: 'address',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'Postal Code',
        dataField: 'postalCode',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'Email',
        dataField: 'email',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'Phone Number',
        dataField: 'phoneNumber',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'Website',
        dataField: 'website',
        dataType: 'string',
        minWidth: 150,
      },
    ],
    showHeader: true,
    showExport: false,
    showAddButton: !isFromViewInfoModal,
  };

  return (
    <div className="m-l-tab-accordion-item-content-section">
      <Grid gridConfig={gridConfig} />

      {showEditContactModal && (
        <PortContactEditModal
          isVisible={showEditContactModal}
          handleCancelClick={() => cancelClick()}
          portCode={portCode}
          getPortContacts={getPortContacts}
          selectedContact={selectedContact}
        />
      )}
    </div>
  );
}
