import React, { useEffect, useState } from 'react';
import TerminalDetails from '../terminal-details/TerminalDetails';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import {
  IVetRequestPorts,
  IVetRequestTerminal,
} from '../../../../types/berthfit-execution';
import { Accordion } from 'devextreme-react/accordion';
import TerminalAccordionHeader from '../terminal-accordion-header/TerminalAccordionHeader';
import { useTranslation } from 'react-i18next';
import TerminalInfo from '../../../berth-fit-check/info/terminal-info/TerminalInfo';
import { Restricted } from '../../../../contexts/PermissionContext';
import icons from '../../../common/icons/icons';
import AddAttachment from '../../attachments/add-attachment/AddAttachment';
import { useNavigate } from 'react-router-dom';

export default function BerthFitDetails(props: {
  vetRequestId?: string;
  portId: number;
  berthFitId: number;
  imoNumber?: string;
  buId: number;
  getPortList: any;
  portData: IVetRequestPorts;
}) {
  const {
    vetRequestId,
    portId,
    imoNumber,
    buId,
    getPortList,
    berthFitId,
    portData,
  } = props;

  const [selectedTerminal, setSelectedTerminal] = useState<any>();

  const [attachmentsAdded, setAttachmentsAdded] = useState<any>([]);

  const [showAttachmentModal, setShowAttachmentModal] = useState(false);

  const [terminals, setTerminals] = useState<IVetRequestTerminal[]>([]);

  const [terminalId, setTerminalId] = useState(0);

  const [terminalCode, setTerminalCode] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (portData) setAttachmentsAdded(portData.attachments);
  }, [portData]);

  const { t } = useTranslation();
  const getTerminalList = async () => {
    const params = {
      vetRequestId: vetRequestId,
      portId: portId,
    };
    await api
      .get({ url: apiConfig.berthExecutionTerminalList, params: params })
      .then((res: IVetRequestTerminal[]) => {
        res.map((item, index: number) => {
          item.key = `${item.terminalId}${index}`;
        });
        setTerminals(res);
        setSelectedTerminal(res[0]);
      });
  };

  useEffect(() => {
    getTerminalList();
  }, []);

  const onClickTitle = (e: any) => {
    if (
      e.event.target?.className === 'view-terminal-info' ||
      e.event.target?.className === 'm-l-view-info-link'
    ) {
      e?.event?.stopPropagation();
      setTerminalId(e.itemData.terminalId);
      setTerminalCode(e.itemData.terminalCode);
    }
  };

  return (
    <div className="m-l-inner-first-accordion-section">
      <Restricted permission="BerthFitVet.Attachment">
        <div
          className="m-l-upld-fl-wrapper m-l-upld__birth-fit u-mb-lg u-mt-lg"
          style={{ justifyContent: 'flex-end' }}
        >
          <div
            className="m-l-atch-icon-holder"
            onClick={() => setShowAttachmentModal(true)}
            style={{ cursor: 'pointer' }}
          >
            <icons.Attach />
            {/* <a
              className="m-l-add-atch-txt"
              onClick={() => setShowAttachmentModal(true)}
            >
              {t('labels.addAnAttachment')}
            </a> */}
          </div>
          {attachmentsAdded?.length > 0 && (
            <div className="m-l-upld-fl-holder">
              <div className="m-l-fl-wrapper">
                <span
                  className="m-l-fl-close"
                  onClick={() =>
                    navigate('/vessel-info/vet-request', {
                      state: {
                        imoNumber: imoNumber,
                        vetRequestId: vetRequestId,
                        tabValue: 7,
                      },
                    })
                  }
                >
                  {attachmentsAdded?.length}
                </span>
              </div>
            </div>
          )}
        </div>
        {showAttachmentModal && (
          <AddAttachment
            isVisible={showAttachmentModal}
            handleCancelClick={() => setShowAttachmentModal(false)}
            imoNumber={imoNumber}
            berthFitId={berthFitId}
            setAttachmentsAdded={setAttachmentsAdded}
            attachmentsAdded={attachmentsAdded}
            vetRequestId={vetRequestId}
          />
        )}
      </Restricted>
      <div className="m-l-accordion-page-title-block">
        <h5>{t('labels.terminals')}</h5>
      </div>
      <div className="m-l-accordion__main-section u-p0">
        <div className="m-c-accordion m-l-berthfit-check-second-inner-accordion open">
          <Accordion
            collapsible={true}
            items={terminals}
            onItemTitleClick={onClickTitle}
            itemTitleRender={(data) => {
              return <TerminalAccordionHeader data={data} />;
            }}
            itemRender={(data: any) => {
              return (
                <TerminalDetails
                  getPortList={getPortList}
                  buId={buId}
                  terminal={data}
                  imoNumber={imoNumber}
                />
              );
            }}
          ></Accordion>
        </div>
      </div>
      <TerminalInfo
        isVisible={terminalId ? true : false}
        handleCancelClick={() => setTerminalId(0)}
        terminalId={terminalId}
        terminalCode={terminalCode}
      />
    </div>
  );
}
