import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormBuilder from '../../../../common/form-builder/FormBuilder';
import { useDateFormat } from '../../../../../hooks/useDateFormat';

export default function CssrForm() {
  const { t } = useTranslation();
  const { control, getValues, setValue } = useFormContext();
  const { dateFormat } = useDateFormat();
  const [cssrValidityDate, setCssrValidityDate] = useState();
  const [cssrDate, setCssrDate] = useState();

  const onChangeCssrValidityDate = (e: any) => {
    setCssrValidityDate(e.value);
  };

  const onChangeCssrDate = (e: any) => {
    setCssrDate(e.value);
    const fromDate = new Date(e.value);
    if (e.value && !cssrValidityDate) {
      setValue('cssrValidityDate', fromDate.setDate(fromDate.getDate() + 30));
    }
  };

  const validateCssrValidityDate = (params: any) => {
    const cssrDate = getValues()?.cssrDate;
    if (cssrDate != '' && cssrDate != null) {
      const fromDate = new Date(cssrDate);
      const currentDate = new Date(params.value);
      return fromDate.getTime() <= currentDate.getTime();
    }
    return true;
  };

  const validateCSSRDate = (params: any) => {
    const cssrValidityDate = getValues()?.cssrValidityDate;
    if (cssrValidityDate != '' && cssrValidityDate != null) {
      const fromDate = new Date(cssrValidityDate);
      const currentDate = new Date(params.value);
      return fromDate.getTime() >= currentDate.getTime();
    }
    return true;
  };

  const cssrFormConfig = [
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'cssrDate',
      label: t('labels.cssrDate'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
      onChange: onChangeCssrDate,
      rules: [
        {
          type: 'custom',
          reevaluate: true,
          validationCallback: validateCSSRDate,
          message: t('errors.datebeforeCSSRDate'),
          ignoreEmptyValue: true,
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'cssrValidityDate',
      label: 'CSSR Validity Date',
      control: control,
      fieldType: 'date',
      format: dateFormat,
      onChange: onChangeCssrValidityDate,
      rules: [
        {
          type: 'custom',
          reevaluate: true,
          validationCallback: validateCssrValidityDate,
          message: t('errors.dateAfterCSSRDate'),
          ignoreEmptyValue: true,
        },
      ],
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={cssrFormConfig} />
      </div>
    </>
  );
}
