import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import TextArea from 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';
import { Button } from 'devextreme-react/button';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import RecommendationEmail from './email-modal/RecommendationEmail';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { VetRequestStatusCode } from '../../../enums/vet-request-status-enum';
import toast from '../../../utils/toast';
import { getAPIRoute } from '../../../utils/api-route';
import imageUrl from '../../../assets/images';
import Icons from '../../common/icons/icons';
import Modal from '../../common/modal/Modal';
import ScrollView from 'devextreme-react/scroll-view';
import { IModalConfig } from '../../../types/modal';
import ValidationGroup from 'devextreme-react/validation-group';
import { IVetAppealComment } from '../../../types/vet-appeal';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { Restricted } from '../../../contexts/PermissionContext';
import { CompanyType } from '../../../enums/company-type-enum';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import ReportToPdf from '../../../pages/vet-report/ReportToPdf';
import { IVetReport } from '../../../types/vet-report';
import icons from '../../common/icons/icons';
import Tooltip from 'devextreme-react/tooltip';
import {
  getBuList,
  getEmployeeId,
  getLoginBUName,
} from '../../../utils/jwt-decode';

export default function RecommendationTab(props: {
  vetData?: any;
  setAppealReviewers?: any;
  setIsAppeal?: any;
  appealReviewerData?: any;
  isAppeal?: boolean;
  companyType?: string;
  setVetData?: any;
  setAppealReviewerName?: any;
  setAppealTo?: any;
  setGetVetData?: any;
  vetRequestId?: any;
  recommend?: any;
  setRecommend?: any;
  notRecommend?: any;
  setNotRecommend?: any;
  setShowRecommendation?: any;
  selectedTab?: any;
  imoNumber?: any;
}) {
  const {
    selectedTab,
    vetData,
    setAppealReviewers,
    setIsAppeal,
    appealReviewerData,
    isAppeal,
    companyType,
    setVetData,
    setAppealReviewerName,
    setAppealTo,
    setGetVetData,
    vetRequestId,
    recommend,
    setRecommend,
    notRecommend,
    setNotRecommend,
    setShowRecommendation,
    imoNumber,
  } = props;
  const [comments, setComments] = useState<any>('');
  const [berthFitCommentsField, setBerthFitCommentsField] = useState('');
  const [appealComment, setAppealComment] = useState<any>('');
  const [berthFitAppealComment, setBerthFitAppealComment] = useState<any>('');
  const [appealReviewerComment, setAppealReviewerComment] = useState<any>();
  const [recommendedComment, setRecommendedComment] = useState<any>();
  const [notRecommendedComment, setNotRecommendedComment] = useState<any>();
  const [approvedComment, setApprovedComment] = useState<any>();
  const [rejectedComment, setRejectedComment] = useState<any>();
  const [conditionallyApprovedComment, setConditionallyApprovedComment] =
    useState<any>();
  const { setIsLoading } = useLoaderContext();
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [userReviewerIsSame, setUserReviewerIsSame] = useState(false);
  const [enabledStatus, setEnabledStatus] = useState(false);
  const employeeId = getEmployeeId();
  const [containFailedRules, setContainFailedRules] = useState(false);
  const [vetReviewComments, setVetReviewComments] = useState<any>();
  const { apiDateFormat, apiDateAndTimeFormat } = useDateFormat();
  const [isAttachPdf, setIsAttachPdf] = useState(false);
  const [isRecommend, setIsRecommend] = useState(false);
  const bu: any = getBuList();
  const buList = JSON.parse(bu);
  const currentlyLoggedInBuu = getLoginBUName();
  const filteredBU = buList
    ? buList.filter((bu: any) => bu.buName == currentlyLoggedInBuu)
    : 0;
  const vetId = vetData?.vetId;
  const [reviewStatus, setReviewStatus] = useState<any>();
  const reviewCommentsValidation: any = useRef();
  const [vetReviewTitle, setVetReviewTitle] = useState<any>();
  const [emailTemplate, setEmailTemplate] = useState<any>();
  const [report, setReport] = useState<any>(false);
  const [isBerthFitReport, setIsBerthFitReport] = useState<any>(false);
  const [reportDate, setReportDate] = useState<any>();
  const [isNotRecommended, setIsNotRecommended] = useState<any>(false);
  const [reportDetails, setReportDetails] = useState<IVetReport>();
  const [berthReportDetails, setBerthReportDetails] = useState<IVetReport>();
  const [pdfFile, setPdfFile] = useState<any[]>([]);

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-modal-appeal-request m-c-comment-popup',
    };
  }, []);
  const modalConfig: IModalConfig = {
    width: 1262,
    height: 297,
    visible: isAppeal,
    showTitle: false,
    closeOnOutsideClick: false,
  };
  const handleCancelClick = () => {
    setIsAppeal(false);
  };
  const handleSaveClick = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      setIsAppeal(false);
      appealVetRequest();
    } else {
      setIsAppeal(true);
    }
  };

  const handleReportClick = (e: any) => {
    setReport(true);
    setIsLoading(true);
  };

  const getReportDetails = async () => {
    await api
      .get({
        url: apiConfig.vetReport + vetRequestId,
      })
      .then((response: any) => {
        setReportDetails(response);
      });
  };

  const getBerthFitReportDetails = async () => {
    await api
      .get({
        url: apiConfig.berthfitReport,
        params: { vetRequestId, imoNumber },
      })
      .then((response: any) => {
        setBerthReportDetails(response);
      });
  };

  useEffect(() => {
    if (selectedTab === 8 && vetRequestId) {
      getReportDetails();
      getBerthFitReportDetails();
    }
  }, [selectedTab, isVisible, vetData]);

  useEffect(() => {
    if (vetData?.reviewerId == employeeId) {
      setUserReviewerIsSame(true);
    }
    if (vetData?.status === VetRequestStatusCode.processing) {
      setEnabledStatus(true);
    }

    if (
      vetData?.status === VetRequestStatusCode.recommended ||
      vetData?.status === VetRequestStatusCode.notRecommended
    ) {
      setEnabledStatus(false);
    }

    if (vetData?.containFailedRules === true) {
      setContainFailedRules(true);
    }
    if (vetData?.status) {
      getVetReviewComments();
    }
  }, [vetData]);

  useEffect(() => {
    if (selectedTab === 8 && vetData?.vetRequestId) {
      getVetExecutionResults();
    }
  }, [selectedTab, vetData]);

  useEffect(() => {
    if (
      companyType == CompanyType.RequestorCompany ||
      companyType == CompanyType.HostedCompany
    ) {
      getAppealReviewers();
    }
  }, []);

  useEffect(() => {
    if (vetReviewComments?.length > 0) {
      getAllVetComments(vetReviewComments);
    }
  }, [vetReviewComments]);

  useEffect(() => {
    if (!isVisible) {
      setPdfFile([]);
    }
  }, [isVisible]);

  const getAllVetComments = (vetReviewComments: any) => {
    const getRecommendedComment = vetReviewComments.filter(
      (reviewComment: any) =>
        reviewComment.vetRequestStatus === VetRequestStatusCode.recommended
    );
    const getNotRecommendedComment = vetReviewComments.filter(
      (reviewComment: any) =>
        reviewComment.vetRequestStatus === VetRequestStatusCode.notRecommended
    );
    const getAppealComment = vetReviewComments.filter(
      (reviewComment: any) =>
        reviewComment.vetRequestStatus === VetRequestStatusCode.appeal
    );
    const getApprovedComment = vetReviewComments.filter(
      (reviewComment: any) =>
        reviewComment.vetRequestStatus === VetRequestStatusCode.approved
    );
    const getRejectedComment = vetReviewComments.filter(
      (reviewComment: any) =>
        reviewComment.vetRequestStatus === VetRequestStatusCode.rejected
    );
    const getConditionallyApprovedComment = vetReviewComments.filter(
      (reviewComment: any) =>
        reviewComment.vetRequestStatus ===
        VetRequestStatusCode.conditionallyApproved
    );
    if (getRecommendedComment?.length > 0) {
      setRecommendedComment(getRecommendedComment[0]);
    }
    if (getNotRecommendedComment?.length > 0) {
      setNotRecommendedComment(getNotRecommendedComment[0]);
    }
    if (getAppealComment?.length > 0) {
      setAppealReviewerComment(getAppealComment[0]);
    }
    if (getApprovedComment?.length > 0) {
      setApprovedComment(getApprovedComment[0]);
    }
    if (getRejectedComment?.length > 0) {
      setRejectedComment(getRejectedComment[0]);
    }
    if (getConditionallyApprovedComment?.length > 0) {
      setConditionallyApprovedComment(getConditionallyApprovedComment[0]);
    }
  };

  const getAppealReviewers = async () => {
    await api
      .get({
        url: getAPIRoute('appealReviewers', [filteredBU[0].buId]),
      })
      .then((res) => {
        res.map(
          (appealReviewer: any) =>
            (appealReviewer.imgSrc = imageUrl.avatardrpdwn)
        );
        setAppealReviewers(res);
      });
  };

  const appealVetRequest = async () => {
    const appealCommentJson: IVetAppealComment = {
      vetRequestId: Number(vetRequestId),
      appealRequestorUserId: Number(employeeId),
      appealDate: apiDateFormat(new Date()),
      appealComment: appealComment,
      berthFitAppealComment: berthFitAppealComment,
      status: VetRequestStatusCode.appeal,
      appealToUserId: appealReviewerData.employeeId,
    };
    await api
      .post(
        {
          url: getAPIRoute('appealVetRequest', [vetRequestId]),
          data: { vetAppealDto: appealCommentJson },
        },
        setIsLoading
      )
      .then(() => {
        if (appealReviewerData) {
          setAppealReviewerName(appealReviewerData.employeeName);
          setAppealTo({
            imgSrc: appealReviewerData.imgSrc,
            employeeName: appealReviewerData.employeeName,
          });
          setGetVetData(true);
        }
        toast.custom({
          title: t('toast.appealSubmittedSuccessfully'),
        });
        getVetReviewComments();
      });
  };

  const handleNotRecommend = () => {
    setNotRecommend(false);
    if (reviewCommentsValidation.current?.instance.validate().isValid) {
      saveRecommendation('NotRecommended');
    } else {
      return false;
    }
  };

  const handleRecommend = () => {
    setRecommend(false);
    if (reviewCommentsValidation.current?.instance.validate().isValid) {
      api
        .get({
          url: apiConfig.vetRecommendationRuleSuccessValidation + vetRequestId,
        })
        .then((res) => {
          if (res) {
            saveRecommendation('Recommended');
          }
        });
    } else {
      return false;
    }
  };

  const [subject, setSubject] = useState<any>('');
  const [toEmail, setToEmail] = useState<any>('');

  const attachCallBack = (isVetCompleted: boolean) => {
    if (isVetCompleted) {
      setIsAttachPdf(true);
      setIsBerthFitReport(true);
      setReport(true);
    }
  };

  const saveRecommendation = async (reviewStatus: string) => {
    setIsAttachPdf(true);
    setIsBerthFitReport(false);
    setReport(true);
    const title =
      reviewStatus === 'Recommended'
        ? t('labels.vetRecommendation')
        : t('labels.vetNotRecommendation');
    let commentsMailContent = `<p><strong style="font-size: 10pt;"><u>Vet Recommendation Comments</u></strong></p>${comments?.replace(
      /\n/g,
      '<br/>'
    )}`;

    if (vetData.berthFitCheck) {
      commentsMailContent =
        commentsMailContent +
        '<br/><br/>' +
        `<p><strong style="font-size: 10pt;"><u>Berth Fit Recommendation Comments</u></strong></p>${berthFitCommentsField?.replace(
          /\n/g,
          '<br/>'
        )}`;
    }
    const params = {
      vetRequestId: vetRequestId,
      comment: commentsMailContent,
      title: title,
    };
    await api
      .post(
        {
          url: getAPIRoute('recommendEmailTemplate', [vetRequestId]),
          data: params,
        },
        setIsLoading
      )
      .then((data: any) => {
        setEmailTemplate(data.templateHtml);
        setSubject(data.subject);
        setToEmail(data.to);
        setVetReviewTitle(title);
        setReviewStatus(reviewStatus);
        setIsVisible(true);
      })
      .catch((e) => {
        //do nothing
      });
    setNotRecommend(false);
    setRecommend(false);
  };

  const getVetReviewComments = async () => {
    await api
      .get({ url: getAPIRoute('vetRequestComments', [vetId]) })
      .then((data: any) => {
        data ? setVetReviewComments(data) : null;
      })
      .catch((e) => {
        //do nothing
      });
  };

  const getVetExecutionResults = async () => {
    await api
      .get(
        {
          url: getAPIRoute('vetRecommendationRuleResults', [
            vetData.vetRequestId,
          ]),
        },
        setIsLoading
      )
      .then((res: any) => {
        const vetComments = res.vetRequestRecommendations.join('\n');
        setComments(vetComments);
        const berthComments = res.berthFitRecommendations.join('\n');
        setBerthFitCommentsField(berthComments);
      });
  };

  const onFormSubmit = (e: any) => {
    e.preventDefault();
  };

  const getCommentBody = useCallback((comment: string) => {
    return comment?.replace(/\n/g, '<br/>');
  }, []);

  useEffect(() => {
    if (recommend) {
      setIsRecommend(true);
      handleRecommend();
    }
  }, [recommend]);

  useEffect(() => {
    if (notRecommend) {
      setIsRecommend(false);
      handleNotRecommend();
    }
  }, [notRecommend]);

  useEffect(() => {
    setShowRecommendation(enabledStatus);
  }, [enabledStatus]);

  return (
    <div>
      {userReviewerIsSame && enabledStatus ? (
        <form
          action="#"
          onSubmit={(e) => onFormSubmit(e)}
          data-testid="recommendForm"
        >
          <div className="m-l-appl-holder">
            <div className="m-l-appl-outer-wrapper">
              <ValidationGroup ref={reviewCommentsValidation}>
                <div className="mb-btm-space m-c-form-group">
                  <label className='className="m-l-input-label"'>
                    Vet Request Recommendation
                  </label>
                  <TextArea
                    className="m-c-text-area m-c-input-control m-c-text-area-control m-c-text-area-control-h-fix"
                    inputAttr={{ 'data-testid': 'comments' }}
                    name="comments"
                    onValueChanged={(e: any) => {
                      setComments(e.value);
                    }}
                    validationMessageMode="always"
                    value={comments}
                  >
                    <Validator>
                      <RequiredRule message={t('errors.required')} />
                    </Validator>
                  </TextArea>
                </div>
                {vetData?.berthFitCheck && (
                  <div className="mb-btm-space m-c-form-group">
                    <label className='className="m-l-input-label"'>
                      Berth Fit Recommendation
                    </label>
                    <TextArea
                      className="m-c-text-area m-c-input-control m-c-text-area-control m-c-text-area-control-h-fix"
                      inputAttr={{ 'data-testid': 'berthFitCommentsField' }}
                      name="berthFitCommentsField"
                      onValueChanged={(e: any) => {
                        setBerthFitCommentsField(e.value);
                      }}
                      validationMessageMode="always"
                      value={berthFitCommentsField}
                    >
                      <Validator>
                        <RequiredRule message={t('errors.required')} />
                      </Validator>
                    </TextArea>
                  </div>
                )}
              </ValidationGroup>
            </div>
          </div>
        </form>
      ) : null}
      {isVisible ? (
        <RecommendationEmail
          vetRequestId={vetRequestId}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          reviewComment={comments}
          berthFitComment={berthFitCommentsField}
          setEnabledStatus={setEnabledStatus}
          reviewStatus={reviewStatus}
          getVetReviewComments={getVetReviewComments}
          vetReviewTitle={vetReviewTitle}
          template={emailTemplate}
          toEmail={toEmail}
          mailSubject={subject}
          setVetData={setVetData}
          vetData={vetData}
          setGetVetData={setGetVetData}
          vetReports={pdfFile}
          imoNumber={imoNumber}
          berthFitCheck={vetData?.berthFitCheck}
        ></RecommendationEmail>
      ) : null}
      <div className="m-l-accordion-panel-body-block">
        <div className="m-l-accordion__main-section">
          {/* --recommended comments */}
          {vetData?.status === VetRequestStatusCode.recommended ? (
            <>
              <div className="m-l-appl-comment-wrapper">
                <div className="m-l-appl-comment-section">
                  <div className="m-l-inner-page-header">
                    <div className="m-l-inner-page-header-left-block">
                      <h6 className="m-l-appl-title">
                        {t('labels.vetRecommendedComments')}
                      </h6>
                    </div>
                    <Restricted permission={'VetRequest.DownloadVetReport'}>
                      <div className="m-l-inner-page-header-right-block">
                        <Button
                          className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                          aria-label="Export-btn"
                          elementAttr={{ 'data-testid': 'exportButton' }}
                          id="downloadVetReport"
                          onClick={(e) => {
                            setIsBerthFitReport(false),
                              setIsNotRecommended(false),
                              setReportDate(
                                recommendedComment?.commentDateTime
                              ),
                              handleReportClick(e);
                          }}
                        >
                          <div
                            className="m-c-icon-inside-btn"
                            data-testid="export-all"
                          >
                            <icons.download />
                          </div>
                        </Button>
                        <Tooltip
                          target={'#downloadVetReport'}
                          showEvent="dxhoverstart"
                          hideEvent="dxhoverend"
                          position="bottom"
                          contentRender={() => {
                            return (
                              <div className="m-l-tooltip-wrapper">
                                {t('labels.exportVetOutcomeReport')}
                              </div>
                            );
                          }}
                        />
                      </div>
                    </Restricted>
                  </div>
                  {recommendedComment ? (
                    <div
                      className="m-l-appl-cmnt"
                      dangerouslySetInnerHTML={{
                        __html: getCommentBody(recommendedComment.commentBody),
                      }}
                    >
                      {/* {recommendedComment.commentBody} */}
                    </div>
                  ) : null}
                  <div className="m-l-appl-commentor-holder">
                    <div className="m-l-appl-commentor">
                      <div className="m-l-appl-commentor-name">
                        {recommendedComment?.username}
                      </div>
                      <ul className="m-l-appl-date">
                        <li>
                          <span className="m-l-usr-dt-item">
                            {apiDateAndTimeFormat(
                              recommendedComment?.commentDateTime
                            )}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="m-l-inner-page-footer">
                    <div className="row m-l-custom-eight-space-row">
                      <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
                        <div className="m-l-group-button-btms">
                          <Restricted
                            permission={'VetRequest.DownloadVetReport'}
                          >
                            <Button
                              className="app-c-btn app-c-btn--secondary min-btn-width"
                              onClick={(e) => {
                                setIsBerthFitReport(false),
                                  setIsNotRecommended(false),
                                  setReportDate(
                                    recommendedComment?.commentDateTime
                                  ),
                                  handleReportClick(e);
                              }}
                              aria-label="Export-btn"
                              elementAttr={{ 'data-testid': 'exportButton' }}
                            >
                              {t('labels.download')}
                            </Button>
                          </Restricted>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {vetData?.berthFitCheck && (
                <div className="m-l-appl-comment-wrapper">
                  <div className="m-l-appl-comment-section">
                    <div className="m-l-inner-page-header">
                      <div className="m-l-inner-page-header-left-block">
                        <h6 className="m-l-appl-title">
                          {t('labels.berthFitRecommendationComments')}
                        </h6>
                      </div>
                      {/* <Restricted
                        permission={'VetRequest.DownloadBerthFitReport'}
                      > */}
                      <div className="m-l-inner-page-header-right-block">
                        <Button
                          className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                          aria-label="Export-btn"
                          elementAttr={{ 'data-testid': 'exportButton' }}
                          id="downloadBerthReport"
                          onClick={(e) => {
                            setIsBerthFitReport(true),
                              setIsNotRecommended(false),
                              handleReportClick(e);
                          }}
                        >
                          <div
                            className="m-c-icon-inside-btn"
                            data-testid="export-all"
                          >
                            <icons.download />
                          </div>
                        </Button>
                        <Tooltip
                          target={'#downloadBerthReport'}
                          showEvent="dxhoverstart"
                          hideEvent="dxhoverend"
                          position="bottom"
                          contentRender={() => {
                            return (
                              <div className="m-l-tooltip-wrapper">
                                {t('labels.exportBerthReport')}
                              </div>
                            );
                          }}
                        />
                      </div>
                      {/* </Restricted> */}
                    </div>
                    {recommendedComment ? (
                      <div
                        className="m-l-appl-cmnt"
                        dangerouslySetInnerHTML={{
                          __html: getCommentBody(
                            recommendedComment.berthFitRecommendationCommentBody
                          ),
                        }}
                      >
                        {/* {recommendedComment.berthFitRecommendationCommentBody} */}
                      </div>
                    ) : null}
                    <div className="m-l-appl-commentor-holder">
                      <div className="m-l-appl-commentor">
                        <div className="m-l-appl-commentor-name">
                          {recommendedComment?.username}
                        </div>
                        <ul className="m-l-appl-date">
                          <li>
                            <span className="m-l-usr-dt-item">
                              {apiDateAndTimeFormat(
                                recommendedComment?.commentDateTime
                              )}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="m-l-inner-page-footer">
                      <div className="row m-l-custom-eight-space-row">
                        <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
                          {/* <Restricted
                            permission={'VetRequest.DownloadBerthFitReport'}
                          > */}
                          <div className="m-l-group-button-btms">
                            <Button
                              className="app-c-btn app-c-btn--secondary min-btn-width"
                              onClick={(e) => {
                                setIsBerthFitReport(true),
                                  setIsNotRecommended(false),
                                  handleReportClick(e);
                              }}
                              aria-label="Export-btn"
                              elementAttr={{ 'data-testid': 'exportButton' }}
                            >
                              {t('labels.download')}
                            </Button>
                          </div>
                          {/* </Restricted> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : null}
          {/* --not recommended comments */}
          {vetData?.status === VetRequestStatusCode.notRecommended ||
          vetData?.status === VetRequestStatusCode.appeal ||
          vetData?.status === VetRequestStatusCode.approved ||
          vetData?.status === VetRequestStatusCode.rejected ||
          vetData?.status === VetRequestStatusCode.conditionallyApproved ? (
            <>
              <div className="m-l-appl-comment-wrapper">
                <div className="m-l-appl-comment-section">
                  <div className="m-l-inner-page-header">
                    <div className="m-l-inner-page-header-left-block">
                      <h6 className="m-l-appl-title">
                        {t('labels.vetNotRecommendedComments')}
                      </h6>
                    </div>
                    <Restricted permission={'VetRequest.DownloadVetReport'}>
                      <div className="m-l-inner-page-header-right-block">
                        <Button
                          className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                          aria-label="Export-btn"
                          elementAttr={{ 'data-testid': 'exportButton' }}
                          id="downloadVetReport"
                          onClick={(e) => {
                            setIsBerthFitReport(false),
                              setIsNotRecommended(true),
                              setReportDate(
                                notRecommendedComment?.commentDateTime
                              ),
                              handleReportClick(e);
                          }}
                        >
                          <div
                            className="m-c-icon-inside-btn"
                            data-testid="export-all"
                          >
                            <icons.download />
                          </div>
                        </Button>
                        <Tooltip
                          target={'#downloadVetReport'}
                          showEvent="dxhoverstart"
                          hideEvent="dxhoverend"
                          position="bottom"
                          contentRender={() => {
                            return (
                              <div className="m-l-tooltip-wrapper">
                                {t('labels.exportVetOutcomeReport')}
                              </div>
                            );
                          }}
                        />
                      </div>
                    </Restricted>
                  </div>
                  {notRecommendedComment ? (
                    <div
                      className="m-l-appl-cmnt"
                      dangerouslySetInnerHTML={{
                        __html: getCommentBody(
                          notRecommendedComment.commentBody
                        ),
                      }}
                    >
                      {/* {notRecommendedComment.commentBody} */}
                    </div>
                  ) : null}
                  <div className="m-l-appl-commentor-holder">
                    <div className="m-l-appl-commentor">
                      <div className="m-l-appl-commentor-name">
                        {notRecommendedComment?.username}
                      </div>
                      <ul className="m-l-appl-date">
                        <li>
                          <span className="m-l-usr-dt-item">
                            {apiDateAndTimeFormat(
                              notRecommendedComment?.commentDateTime
                            )}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="m-l-inner-page-footer">
                    <div className="row m-l-custom-eight-space-row">
                      <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
                        <div className="m-l-group-button-btms">
                          <Restricted
                            permission={'VetRequest.DownloadVetReport'}
                          >
                            <Button
                              className="app-c-btn app-c-btn--secondary min-btn-width"
                              onClick={(e) => {
                                setIsBerthFitReport(false),
                                  setIsNotRecommended(true),
                                  setReportDate(
                                    notRecommendedComment?.commentDateTime
                                  ),
                                  handleReportClick(e);
                              }}
                              aria-label="Export-btn"
                              elementAttr={{ 'data-testid': 'exportButton' }}
                            >
                              {t('labels.download')}
                            </Button>
                          </Restricted>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {vetData?.berthFitCheck && (
                <div className="m-l-appl-comment-wrapper">
                  <div className="m-l-appl-comment-section">
                    <h6 className="m-l-appl-title"></h6>
                    <div className="m-l-inner-page-header">
                      <div className="m-l-inner-page-header-left-block">
                        <h6 className="m-l-appl-title">
                          {t('labels.berthFitRecommendationComments')}
                        </h6>
                      </div>
                      {/* <Restricted
                        permission={'VetRequest.DownloadBerthFitReport'}
                      > */}
                      <div className="m-l-inner-page-header-right-block">
                        <Button
                          className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                          aria-label="Export-btn"
                          elementAttr={{ 'data-testid': 'exportButton' }}
                          id="downloadBerthReport"
                          onClick={(e) => {
                            setIsBerthFitReport(true),
                              setIsNotRecommended(false),
                              handleReportClick(e);
                          }}
                        >
                          <div
                            className="m-c-icon-inside-btn"
                            data-testid="export-all"
                          >
                            <icons.download />
                          </div>
                        </Button>
                        <Tooltip
                          target={'#downloadBerthReport'}
                          showEvent="dxhoverstart"
                          hideEvent="dxhoverend"
                          position="bottom"
                          contentRender={() => {
                            return (
                              <div className="m-l-tooltip-wrapper">
                                {t('labels.exportBerthReport')}
                              </div>
                            );
                          }}
                        />
                      </div>
                      {/* </Restricted> */}
                    </div>
                    {notRecommendedComment ? (
                      <div
                        className="m-l-appl-cmnt"
                        dangerouslySetInnerHTML={{
                          __html: getCommentBody(
                            notRecommendedComment.berthFitRecommendationCommentBody
                          ),
                        }}
                      >
                        {/* {notRecommendedComment.berthFitRecommendationCommentBody} */}
                      </div>
                    ) : null}
                    <div className="m-l-appl-commentor-holder">
                      <div className="m-l-appl-commentor">
                        <div className="m-l-appl-commentor-name">
                          {notRecommendedComment?.username}
                        </div>
                        <ul className="m-l-appl-date">
                          <li>
                            <span className="m-l-usr-dt-item">
                              {apiDateAndTimeFormat(
                                notRecommendedComment?.commentDateTime
                              )}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="m-l-inner-page-footer">
                      <div className="row m-l-custom-eight-space-row">
                        <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
                          <div className="m-l-group-button-btms">
                            {/* <Restricted
                              permission={'VetRequest.DownloadBerthFitReport'}
                            > */}
                            <Button
                              className="app-c-btn app-c-btn--secondary min-btn-width"
                              onClick={(e) => {
                                setIsBerthFitReport(true),
                                  setIsNotRecommended(false),
                                  handleReportClick(e);
                              }}
                              aria-label="Export-btn"
                              elementAttr={{ 'data-testid': 'exportButton' }}
                            >
                              {t('labels.download')}
                            </Button>
                            {/* </Restricted> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : null}
          {report &&
            (isBerthFitReport
              ? berthReportDetails && reportDetails
              : reportDetails) && (
              <ReportToPdf
                pdfFile={pdfFile}
                setPdfFile={setPdfFile}
                report={report}
                setReport={setReport}
                reportDetails={reportDetails}
                isBerthFitReport={isBerthFitReport}
                berthReportDetails={berthReportDetails}
                isAttachPdf={isAttachPdf}
                setIsAttachPdf={setIsAttachPdf}
                attachCallBack={
                  vetData?.berthFitCheck ? attachCallBack : undefined
                }
                isNotRecommended={isNotRecommended}
                reviewComment={comments}
                berthFitComment={berthFitCommentsField}
                isRecommend={isRecommend}
                setIsRecommend={setIsRecommend}
                reportGeneratedDate={reportDate}
              />
            )}
          {/* --appeal comments */}
          {appealReviewerComment &&
          (vetData?.status === VetRequestStatusCode.appeal ||
            vetData?.status === VetRequestStatusCode.approved ||
            vetData?.status === VetRequestStatusCode.rejected ||
            vetData?.status === VetRequestStatusCode.conditionallyApproved) ? (
            <div className="m-l-appl-comment-wrapper">
              <div className="m-l-appl-comment-section">
                <h6 className="m-l-appl-title">{t('labels.appealComment')}</h6>

                <div className="m-l-appl-cmnt">
                  {appealReviewerComment.commentBody}
                </div>
              </div>
              <div className="m-l-appl-commentor-holder">
                <div className="m-l-appl-commentor">
                  <div className="m-l-appl-commentor-name">
                    {appealReviewerComment.username}
                  </div>
                  <ul className="m-l-appl-date">
                    <li>
                      <span className="m-l-usr-dt-item">
                        {apiDateAndTimeFormat(
                          appealReviewerComment.commentDateTime
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
          {/* --approve comments */}
          {approvedComment &&
          vetData?.status === VetRequestStatusCode.approved ? (
            <div className="m-l-appl-comment-wrapper">
              <div className="m-l-appl-comment-section">
                <div className="m-l-inner-page-header">
                  <div className="m-l-inner-page-header-left-block">
                    <h6 className="m-l-appl-title">
                      {t('labels.approvedComment')}
                    </h6>
                  </div>
                  <Restricted permission={'VetRequest.DownloadVetReport'}>
                    <div className="m-l-inner-page-header-right-block">
                      <Button
                        className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                        aria-label="Export-btn"
                        elementAttr={{ 'data-testid': 'exportButton' }}
                        id="exportAppealVetOutcomeReport"
                        onClick={(e) => {
                          setIsBerthFitReport(false),
                            setIsNotRecommended(false),
                            setReportDate(approvedComment?.commentDateTime),
                            handleReportClick(e);
                        }}
                      >
                        <div
                          className="m-c-icon-inside-btn"
                          data-testid="export-all"
                        >
                          <icons.download />
                        </div>
                      </Button>
                      <Tooltip
                        target={'#exportAppealVetOutcomeReport'}
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        position="bottom"
                        contentRender={() => {
                          return (
                            <div className="m-l-tooltip-wrapper">
                              {t('labels.exportAppealVetOutcomeReport')}
                            </div>
                          );
                        }}
                      />
                    </div>
                  </Restricted>
                </div>
                <div className="m-l-appl-cmnt">
                  {approvedComment.commentBody}
                </div>
              </div>
              <div className="m-l-appl-commentor-holder">
                <div className="m-l-appl-commentor">
                  <div className="m-l-appl-commentor-name">
                    {approvedComment.username}
                  </div>
                  <ul className="m-l-appl-date">
                    <li>
                      <span className="m-l-usr-dt-item">
                        {apiDateAndTimeFormat(approvedComment.commentDateTime)}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              {/* report download button */}
              <div className="m-l-inner-page-footer">
                <div className="row m-l-custom-eight-space-row">
                  <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
                    <div className="m-l-group-button-btms">
                      <Restricted permission={'VetRequest.DownloadVetReport'}>
                        <Button
                          className="app-c-btn app-c-btn--secondary min-btn-width"
                          onClick={(e) => {
                            setIsBerthFitReport(false),
                              setIsNotRecommended(false),
                              setReportDate(approvedComment?.commentDateTime),
                              handleReportClick(e);
                          }}
                          aria-label="Export-btn"
                          elementAttr={{ 'data-testid': 'exportButton' }}
                        >
                          {t('labels.download')}
                        </Button>
                      </Restricted>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {/* --rejected comments */}
          {rejectedComment &&
          vetData?.status === VetRequestStatusCode.rejected ? (
            <div className="m-l-appl-comment-wrapper">
              <div className="m-l-appl-comment-section">
                <div className="m-l-inner-page-header">
                  <div className="m-l-inner-page-header-left-block">
                    <h6 className="m-l-appl-title">
                      {t('labels.rejectedComment')}
                    </h6>
                  </div>
                  <Restricted permission={'VetRequest.DownloadVetReport'}>
                    <div className="m-l-inner-page-header-right-block">
                      <Button
                        className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                        aria-label="Export-btn"
                        elementAttr={{ 'data-testid': 'exportButton' }}
                        id="exportAppealVetOutcomeReport"
                        onClick={(e) => {
                          setIsBerthFitReport(false),
                            setIsNotRecommended(false),
                            setReportDate(rejectedComment?.commentDateTime),
                            handleReportClick(e);
                        }}
                      >
                        <div
                          className="m-c-icon-inside-btn"
                          data-testid="export-all"
                        >
                          <icons.download />
                        </div>
                      </Button>
                      <Tooltip
                        target={'#exportAppealVetOutcomeReport'}
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        position="bottom"
                        contentRender={() => {
                          return (
                            <div className="m-l-tooltip-wrapper">
                              {t('labels.exportAppealVetOutcomeReport')}
                            </div>
                          );
                        }}
                      />
                    </div>
                  </Restricted>
                </div>
                <div className="m-l-appl-cmnt">
                  {rejectedComment.commentBody}
                </div>
              </div>
              <div className="m-l-appl-commentor-holder">
                <div className="m-l-appl-commentor">
                  <div className="m-l-appl-commentor-name">
                    {rejectedComment.username}
                  </div>
                  <ul className="m-l-appl-date">
                    <li>
                      <span className="m-l-usr-dt-item">
                        {apiDateAndTimeFormat(rejectedComment.commentDateTime)}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* report download button */}
              <div className="m-l-inner-page-footer">
                <div className="row m-l-custom-eight-space-row">
                  <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
                    <div className="m-l-group-button-btms">
                      <Restricted permission={'VetRequest.DownloadVetReport'}>
                        <Button
                          className="app-c-btn app-c-btn--secondary min-btn-width"
                          onClick={(e) => {
                            setIsBerthFitReport(false),
                              setIsNotRecommended(false),
                              setReportDate(rejectedComment?.commentDateTime),
                              handleReportClick(e);
                          }}
                          aria-label="Export-btn"
                          elementAttr={{ 'data-testid': 'exportButton' }}
                        >
                          {t('labels.download')}
                        </Button>
                      </Restricted>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {/* --conditionally approved comments */}
          {conditionallyApprovedComment &&
          vetData?.status === VetRequestStatusCode.conditionallyApproved ? (
            <div className="m-l-appl-comment-wrapper">
              <div className="m-l-appl-comment-section">
                <div className="m-l-inner-page-header">
                  <div className="m-l-inner-page-header-left-block">
                    <h6 className="m-l-appl-title">
                      {t('labels.conditionallyApprovedComment')}
                    </h6>
                  </div>
                  <Restricted permission={'VetRequest.DownloadVetReport'}>
                    <div className="m-l-inner-page-header-right-block">
                      <Button
                        className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                        aria-label="Export-btn"
                        elementAttr={{ 'data-testid': 'exportButton' }}
                        id="exportAppealVetOutcomeReport"
                        onClick={(e) => {
                          setIsBerthFitReport(false),
                            setIsNotRecommended(false),
                            setReportDate(
                              conditionallyApprovedComment?.commentDateTime
                            ),
                            handleReportClick(e);
                        }}
                      >
                        <div
                          className="m-c-icon-inside-btn"
                          data-testid="export-all"
                        >
                          <icons.download />
                        </div>
                      </Button>
                      <Tooltip
                        target={'#exportAppealVetOutcomeReport'}
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        position="bottom"
                        contentRender={() => {
                          return (
                            <div className="m-l-tooltip-wrapper">
                              {t('labels.exportAppealVetOutcomeReport')}
                            </div>
                          );
                        }}
                      />
                    </div>
                  </Restricted>
                </div>
                <div className="m-l-appl-cmnt">
                  {conditionallyApprovedComment.commentBody}
                </div>
              </div>
              <div className="m-l-appl-commentor-holder">
                <div className="m-l-appl-commentor">
                  <div className="m-l-appl-commentor-name">
                    {conditionallyApprovedComment.username}
                  </div>
                  <ul className="m-l-appl-date">
                    <li>
                      <span className="m-l-usr-dt-item">
                        {apiDateAndTimeFormat(
                          conditionallyApprovedComment.commentDateTime
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* report download button */}
              <div className="m-l-inner-page-footer">
                <div className="row m-l-custom-eight-space-row">
                  <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
                    <div className="m-l-group-button-btms">
                      <Restricted permission={'VetRequest.DownloadVetReport'}>
                        <Button
                          className="app-c-btn app-c-btn--secondary min-btn-width"
                          onClick={(e) => {
                            setIsBerthFitReport(false),
                              setIsNotRecommended(false),
                              setReportDate(
                                conditionallyApprovedComment?.commentDateTime
                              ),
                              handleReportClick(e);
                          }}
                          aria-label="Export-btn"
                          elementAttr={{ 'data-testid': 'exportButton' }}
                        >
                          {t('labels.download')}
                        </Button>
                      </Restricted>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="m-l-model-popup__wrap">
        <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
          <div className="m-l-modal__header">
            <h2 className="modal-title">Appeal Request</h2>
            <div className="m-l-modal-close-button-block">
              <Button
                className="app-c-btn app-c-icon-only-btn"
                onClick={handleCancelClick}
              >
                <Icons.IconCross />
              </Button>
            </div>
          </div>
          <div className="m-l-modal__body">
            <ScrollView width="100%" height="100%">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="m-c-form-group">
                    <label className="m-l-input-label">Appeal Comment </label>
                    <TextArea
                      className="m-c-text-area m-c-text-area-in-form"
                      placeholder="Comment here"
                      inputAttr={{ 'data-testid': 'appealComment' }}
                      name="appealComment"
                      onValueChanged={(e: any) => {
                        setAppealComment(e.value);
                      }}
                      validationMessageMode="always"
                      value={appealComment}
                    >
                      <Validator>
                        <RequiredRule message={t('errors.required')} />
                      </Validator>
                    </TextArea>
                  </div>
                </div>
              </div>
            </ScrollView>
          </div>
          <div className="m-l-modal__footer">
            <div className="m-l-footer-right-block">
              <Button
                onClick={handleSaveClick}
                className="app-c-btn app-c-btn--primary"
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
