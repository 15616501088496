import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MarsApiService as api } from '../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import { useTranslation } from 'react-i18next';
import { IGridConfig } from '../../types/grid';
import Grid from '../../components/common/grid/Grid';
import Button from 'devextreme-react/button';
import { useDateFormat } from '../../hooks/useDateFormat';
import icons from '../../components/common/icons/icons';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import toast from '../../utils/toast';
import { useLoaderContext } from '../../contexts/LoaderContext';
import { getAPIRoute } from '../../utils/api-route';
import { Restricted } from '../../contexts/PermissionContext';

export default function InterfaceGrid() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [interfaceList, setInterfaceList] = useState<any>([]);
  const [interfaceData, setInterfaceData] = useState<any>([]);
  const [gridInstance, setGridInstance] = useState<any>();
  const { apiDateAndTimeFormat } = useDateFormat();
  const { setIsLoading } = useLoaderContext();

  const onGridInitialized = (e: any) => {
    setGridInstance(e.component);
  };

  useEffect(() => {
    if (location.state) {
      const { interfaceData }: any = location.state;
      setInterfaceData(interfaceData);
    }
  }, [location.state]);

  const getInterfaces = async () => {
    await api
      .get(
        {
          url: apiConfig.getExternalInterfacesExectionHistory,
          params: { interfaceName: interfaceData.interfaceName },
        },
        setIsLoading
      )
      .then((response: any) => {
        setInterfaceList(response);
      });
  };

  useEffect(() => {
    if (interfaceData.interfaceName) {
      getInterfaces();
    }
  }, [interfaceData]);

  const cellTemplate = (data: any) => {
    return <div>{apiDateAndTimeFormat(data.value)}</div>;
  };

  const onClickReRun = async (data: any) => {
    await api
      .schedulerPost(
        {
          url: getAPIRoute('scheduler', [
            data?.key?.interfaceName,
            data?.key?.serviceLogId,
          ]),
        },
        setIsLoading
      )
      .then((response) => {
        if (response) {
          toast.success({
            title: t('toast.reRunSuccess'),
          });
        } else {
          toast.error({
            title: t('toast.reRunFailed'),
          });
        }
        getInterfaces();
      });
  };

  const actionTemplate = (data: any) => {
    if (data.data.status === 'Fail') {
      return (
        <div>
          {
            <Restricted permission="ExternalInterfaces.ReRun">
              <Button
                className="app-c-btn app-c-btn--secondary min-btn-width"
                elementAttr={{ 'data-testid': 'addNew' }}
                onClick={(e) => {
                  e?.event?.stopPropagation();
                  onClickReRun(data);
                }}
              >
                {t('labels.reRun')}
              </Button>
            </Restricted>
          }
        </div>
      );
    }
  };

  const onRowClick = (e: any) => {
    if (e.data.status === 'Fail') {
      navigate('/external-interfaces/grid/failure', {
        state: {
          failureData: e.key,
          interfaceData: interfaceData,
        },
      });
    }
  };

  const gridConfig: IGridConfig = {
    dataSource: interfaceList,
    testId: 'companiesGrid',
    class: 'm-c-grid m-c-grid--company',
    remoteOperations: false,
    defaultColumns: [
      {
        caption: t('labels.executionStartDateTime'),
        dataField: 'executionStartDate',
        dataType: 'date',
        minWidth: 150,
        cellTemplate: 'cellTemplate',
      },
      {
        caption: t('labels.executionEndDateTime'),
        dataField: 'executionEndDate',
        dataType: 'date',
        minWidth: 150,
        cellTemplate: 'cellTemplate',
      },
      {
        caption: t('labels.status'),
        dataField: 'status',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.additionalInfo'),
        dataField: 'additionalInfo',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: '',
        dataField: '',
        dataType: 'string',
        minWidth: 150,
        cellTemplate: 'actionTemplate',
      },
    ],
    rowClick: onRowClick,
    showHeader: false,
    header: interfaceData?.interfaceName,
    showAddButton: false,
    showExport: false,
    initialized: onGridInitialized,
  };

  const goBack = () => {
    navigate('/external-interfaces');
  };

  const exportRolesGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: gridInstance,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Interface.xlsx`
        );
      });
    });
  };

  return (
    <div className="inner-wrapper">
      <div className="m-l-inner-page-header">
        <div className="m-l-inner-page-header-left-block">
          <div className="m-l-page-main-heading">
            {interfaceData?.displayName}
          </div>
        </div>
        <div className="m-l-inner-page-header-right-block">
          <Button
            className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
            aria-label="Upload"
            onClick={exportRolesGrid}
          >
            <div className="m-c-icon-inside-btn">
              <icons.download />
            </div>
          </Button>
          <Button
            className="app-c-btn app-c-btn--secondary"
            elementAttr={{ 'data-testid': 'addToList' }}
            onClick={goBack}
          >
            {t('labels.back')}
          </Button>
        </div>
      </div>
      <Grid
        gridConfig={gridConfig}
        cellTemplate={cellTemplate}
        actionTemplate={actionTemplate}
      />
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <Button
                className="app-c-btn app-c-btn--secondary"
                aria-label="Upload"
                onClick={exportRolesGrid}
              >
                {' '}
                {t('labels.download')}
              </Button>
              <Button
                className="app-c-btn app-c-btn--secondary"
                elementAttr={{ 'data-testid': 'addToList' }}
                onClick={goBack}
              >
                {t('labels.back')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
