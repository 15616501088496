import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { useDateFormat } from '../../../hooks/useDateFormat';
import icons from '../../common/icons/icons';

export default function EmailItem() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [mailList, setMailList] = useState<any[]>([]);
  const { mailDateFormat } = useDateFormat();
  const getInboxMailList = async () => {
    await api
      .get({ url: apiConfig.inboxEmailList, params: { isFromDashboard: true } })
      .then((data) => {
        if (data && data.length > 0) {
          const inboxListCopy = data.slice();
          setMailList(
            inboxListCopy.sort((a: any, b: any) =>
              b.receivedDate?.valueOf().localeCompare(a.receivedDate?.valueOf())
            )
          );
        }
      });
  };

  useEffect(() => {
    getInboxMailList();
  }, []);

  const OnClickMail = (item: any) => {
    navigate('/email', { state: { selectedEmailId: item?.incomingEmailId } });
  };

  return (
    // <div className="m-l-mail-list-container">
    //   <ul className="m-l-mail-list">
    //     {mailList?.map((item: any, index: any) => {
    //       return (
    //         <li
    //           key={index}
    //           className="m-l-mail-list-item"
    //           onClick={() => {
    //             OnClickMail(item);
    //           }}
    //         >
    //           <div className="m-l-mail-list-contr">
    //             <div className="m-mail-list-item-header">
    //               <div className="m-l-mail-list-item-frm">
    //                 <span className="m-l-mail-from">{t('labels.from')}:</span>
    //                 <span className="m-l-mail-ownr">{item.from}</span>
    //                 <div className="m-l-mail-date">
    //                   {mailDateFormat(item.receivedDate)}
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="m-l-mail-list-item-body">
    //               <div className="m-l-email-subject">
    //                 <span className="m-l-eml-sbjt-title">
    //                   {t('labels.subject')}:
    //                 </span>
    //                 <div className="m-l-em-l-inscontent">{item.subject}</div>
    //               </div>
    //             </div>
    //           </div>
    //         </li>
    //       );
    //     })}
    //   </ul>
    // </div>

    <div className="m-l-usr-alert-block-wrapper">
      <div className="m-l-usr-alr-block-header">
        <div className="m-l-notification-block">
          <icons.Mail />
        </div>
        <div className="m-l-ntfcn-title">{t('labels.email')}</div>
      </div>
      <div className="m-l-usr-alr-block-body">
        <ul className="m-l-usr-alr-items-wrapper">
          {mailList.map((item: any, index: any) => {
            return (
              <li
                key={index}
                className="m-l-mail-alr-item"
                onClick={() => {
                  OnClickMail(item);
                }}
              >
                <div className="m-l-rqr-pr-prt">
                  <div className="m-l-usr-mail-info">
                    <div className="m-l-usr-rqst-part">
                      <span className="m-l-mail-from">
                        {t('labels.from')}
                        {' : '}
                      </span>
                      <span className="m-l-mail-ownr">{item.from}</span>
                      <div className="m-l-mail-date">
                        {mailDateFormat(item.receivedDate)}
                      </div>
                    </div>
                    <div className="m-l-mail-list-item-body">
                      <div className="m-l-email-subject">
                        <span className="m-l-eml-sbjt-title">
                          {t('labels.subject')}:
                        </span>
                        <div className="m-l-em-l-inscontent">
                          {item.subject}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
