import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '../../common/grid/Grid';
import { IGridConfig } from '../../../types/grid';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { useTranslation } from 'react-i18next';
import Switch from 'devextreme-react/switch';
import ConfirmDialog from '../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../types/dialog';
import {
  VetRequestStatus,
  VetRequestStatusCode,
} from '../../../enums/vet-request-status-enum';
import {
  getBuList,
  getCompanyType,
  getLoginBUName,
} from '../../../utils/jwt-decode';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import DataGrid from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { useAuth } from '../../../contexts/AuthProvider';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { getAPIRoute } from '../../../utils/api-route';
import { CompanyType } from '../../../enums/company-type-enum';
import { useDataStore } from '../../../hooks/useDataStore';
import { Restricted } from '../../../contexts/PermissionContext';
import { IOptionvalue } from '../../../types/master-data';

function ListVetRequest(
  props: {
    isFromVetRequestHistoryTab?: boolean;
    tabRef?: any;
    imoNumber?: string;
  },
  ref?: any
) {
  const { isFromVetRequestHistoryTab, tabRef, imoNumber } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dateFormat } = useDateFormat();
  const { getDataSource } = useDataStore();
  const [vetRequestList, setVetRequests] = useState<any>();
  const [vetStatus, setVetStatus] = useState<IOptionvalue[]>([]);
  const [usedConfirmDialog, setUsedConfirmDialog] = useState(false);
  const bu: any = getBuList();
  const buList = JSON.parse(bu);
  const currentlyLoggedInBuu = getLoginBUName();
  const filteredBU = buList
    ? buList.filter((bu: any) => bu.buName == currentlyLoggedInBuu)
    : 0;
  const companyType = getCompanyType();
  const { setIsLoading } = useLoaderContext();
  const auth = useAuth();
  const confirmUsedDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      setUsedConfirmDialog(false);
    },
    handleClose: () => {
      // getVetRequestList();
      setUsedConfirmDialog(false);
    },
    title: t('toast.roleAssignedToUser'),
    content: t('toast.inactivateRole'),
  };
  const [gridInstance, setGridInstance] = useState<any>();
  const [usedDialogConfig, setUsedDialogConfig] = useState(
    confirmUsedDialogConfig
  );

  const getVetRequestListWithIMO = () => {
    const data = getDataSource(
      'vetRequestId',
      `${apiConfig.listVetRequests}${filteredBU[0].buId}?imoNumber=${imoNumber}`,
      10
    );
    setVetRequests(data);
    // await api
    //   .get(
    //     {
    //       url: apiConfig.listVetRequests + filteredBU[0].buId,
    //       params: { imoNumber: imoNumber },
    //     },
    //     setIsLoading
    //   )
    //   .then((data) => {
    //     setVetRequests(data);
    //   });
  };

  const getVetRequestList = () => {
    const data = getDataSource(
      'vetRequestId',
      `${apiConfig.listVetRequests}${filteredBU[0].buId}`,
      10
    );
    setVetRequests(data);
  };

  useEffect(() => {
    if (isFromVetRequestHistoryTab == true) {
      getVetRequestListWithIMO();
    } else {
      getVetRequestList();
    }
  }, [auth.currentlyLoggedInBu]);

  useEffect(() => {
    getVetStatus();
  }, []);

  const onRowClick = (e: any) => {
    if (
      !['dx-switch-on', 'dx-switch-off', 'dx-switch-handle'].includes(
        e.event.target.className
      )
    ) {
      tabRef?.current?.instance.option('selectedIndex', 2);
      navigate('/vessel-info/vet-request', {
        state: {
          imoNumber: e.data.imoNumber,
          vetRequestId: e.data.vetRequestId,
        },
      });
      if (!isFromVetRequestHistoryTab) {
        sessionStorage.setItem('locationState', 'listVetRequest');
      }
    }
  };

  useImperativeHandle(ref, () => ({
    exportVetRequestsGrid,
  }));

  const exportVetRequestsGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: gridInstance,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'VetRequests.xlsx'
        );
      });
    });
  };

  const handleActiveInactiveChange = async (e: any, data: any) => {
    if (e.event && data) {
      const dialogConfig = {
        title: t('toast.confirmation'),
        content: e.value
          ? t('toast.confitmUsedIndication')
          : t('toast.confitmNotUsedIndication'),
        handleSubmit: () => {
          usedIndicationUpdate(data.vetRequestId, e.value);
          setUsedConfirmDialog(false);
        },
      };

      setUsedDialogConfig({
        ...confirmUsedDialogConfig,
        ...dialogConfig,
      });
      setUsedConfirmDialog(true);
    }
  };

  const usedIndicationUpdate = (
    vetRequestId: number,
    usedIndication: boolean
  ) => {
    api
      .put(
        {
          url: getAPIRoute('vetRequestUsedIndication', [vetRequestId]),
          params: { isUsed: usedIndication },
        },
        setIsLoading
      )
      .then(() => {
        getVetRequestList();
      });
  };

  const cellPrepared = (e: any) => {
    if (e.rowType === 'data' && e.column.dataField === 'status') {
      e.cellElement.className =
        e.data.statusCode === VetRequestStatusCode.assigned ||
        e.data.statusCode === VetRequestStatusCode.processing ||
        e.data.statusCode === VetRequestStatusCode.infoReceived ||
        e.data.statusCode === VetRequestStatusCode.recommended ||
        e.data.statusCode === VetRequestStatusCode.approved ||
        e.data.statusCode === VetRequestStatusCode.conditionallyApproved
          ? 'status-active-wtout'
          : e.data.status === VetRequestStatusCode.unassigned ||
            e.data.status === VetRequestStatusCode.rejected
          ? 'status-inactive-wtout'
          : e.data.status === VetRequestStatusCode.cancelled ||
            e.data.status === VetRequestStatusCode.autoCancelled
          ? 'status-wrong-entry-wtout'
          : 'status-pending-wtout';
    }
  };

  const onGridInitialized = (e: any) => {
    setGridInstance(e.component);
  };

  const onEditorPreparing = (e: any) => {
    if (e.dataField === 'status' && e.parentType === 'filterRow') {
      e.editorName = 'dxTagBox';
      e.editorOptions = {
        dataSource: vetStatus,
        valueExpr: 'optionValueText',
        displayExpr: 'optionValueText',
        maxDisplayedTags: 1,
        showSelectionControls: true,
        placeholder: '(All)',
        multiline: false,
        applyValueMode: 'useButtons',
        onValueChanged: onChangeStatusFilter,
        // showDropDownButton: true,
        // contentRender: filterCellTemplate,
        dropDownOptions: {
          width: 260,
        },
        elementAttr: {
          class: 'm-c-input-control m-c-multiselect-dropdown',
        },
      };
    }
  };

  const getVetStatus = async () => {
    await api.get({ url: apiConfig.vetRequestStatus }).then((res) => {
      setVetStatus(res);
    });
  };

  const onChangeStatusFilter = (event: any) => {
    const selectedKeys = event.value;
    gridRef.current?.instance.columnOption(
      'status',
      'filterValues',
      selectedKeys
    );
  };

  const gridRef = useRef<DataGrid>(null);

  const gridConfig: IGridConfig = {
    ref: gridRef,
    dataSource: vetRequestList,
    testId: 'vetRequestHistoryGrid',
    cellPrepared: cellPrepared,
    initialized: onGridInitialized,
    remoteOperations: true,
    defaultColumns: [
      {
        caption: t('labels.vetId'),
        dataField: 'vetId',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.vesselName'),
        dataField: 'vesselName',
        dataType: 'string',
        minWidth: 150,
        visible: !isFromVetRequestHistoryTab,
      },
      {
        caption: t('labels.IMO'),
        dataField: 'imoDisplayName',
        dataType: 'string',
        minWidth: 150,
        visible: !isFromVetRequestHistoryTab,
      },
      {
        caption: t('labels.company'),
        dataField: 'requestorCompany',
        dataType: 'string',
        minWidth: 150,
        visible: companyType == 'M',
      },
      {
        caption: t('labels.businessUnit'),
        dataField: 'bu',
        dataType: 'string',
        minWidth: 150,
        visible: companyType != 'R',
      },
      {
        caption: t('labels.requestorName'),
        dataField: 'requestorName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.requestedDate'),
        dataField: 'requestDate',
        dataType: 'date',
        format: dateFormat,
        minWidth: 150,
        sortOrder: 'desc',
      },
      {
        caption: t('labels.lastModifiedDate'),
        dataField: 'lastModifiedDate',
        dataType: 'date',
        format: dateFormat,
        minWidth: 150,
      },
      {
        caption: t('labels.reviewer'),
        dataField: 'reviewer',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.incoTerms'),
        dataField: 'incoTerm',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.laycanFromDate'),
        dataField: 'laycanFromDate',
        dataType: 'date',
        format: dateFormat,
        minWidth: 150,
      },
      {
        caption: t('labels.laycanToDate'),
        dataField: 'laycanToDate',
        dataType: 'date',
        format: dateFormat,
        minWidth: 150,
      },
      {
        caption: t('labels.status'),
        dataField: 'status',
        dataType: 'string',
        minWidth: 150,
        cssClass: 'custom-filter-column',
      },
      {
        caption: t('labels.used'),
        dataField: 'isUsed',
        dataType: 'boolean',
        minWidth: 150,
        cellTemplate: 'statusTemplate',
        lookup: {
          dataSource: [
            { label: t('labels.used'), value: true },
            { label: t('labels.notUsed'), value: false },
          ],
          displayExpr: 'label',
          valueExpr: 'value',
        },
      },
    ],
    showHeader: true,
    header: !isFromVetRequestHistoryTab ? t('headers.vetRequest') : '',
    showAddButton: false,
    showExport: isFromVetRequestHistoryTab ? false : true,
    excelFileName: 'VetRequests.xlsx',
    noDataText: t('labels.noVetRequestFound'),
    hidePagination: false,
    rowClickPermission: 'VetRequest.ViewVetRequestDetails',
    rowClick: onRowClick,
    onClickAddButton: () => {
      navigate(-1);
    },
  };

  return (
    <div
      className={
        isFromVetRequestHistoryTab
          ? 'm-c-grid m-c-grid-in-tab m-c-auto-scroll-grid no-border-top'
          : 'inner-wrapper page-inner-wrap'
      }
    >
      <Grid
        gridConfig={gridConfig}
        statusTemplate={cellRender}
        onEditorPreparing={onEditorPreparing}
      />
      <ConfirmDialog
        dialogConfig={usedDialogConfig}
        isOpen={usedConfirmDialog}
      />
    </div>
  );
  function cellRender(data: any) {
    if (
      data?.data?.statusCode === VetRequestStatusCode.recommended ||
      data?.data?.statusCode === VetRequestStatusCode.conditionallyApproved ||
      data?.data?.statusCode === VetRequestStatusCode.approved
    ) {
      return (
        <div className="m-l-field-wrap">
          <div className="m-l-toggle-switch">
            <Restricted permission="VetRequest.ListingUsedToggle">
              <Switch
                elementAttr={{ 'data-testid': 'switch' }}
                defaultValue={data.value}
                disabled={
                  companyType !== CompanyType.HostedCompany &&
                  companyType !== CompanyType.RequestorCompany
                }
                onValueChanged={(e) => {
                  handleActiveInactiveChange(e, data.data);
                }}
              />
            </Restricted>
          </div>
        </div>
      );
    }
    return;
  }
}
export default forwardRef(ListVetRequest);
