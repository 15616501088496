import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormBuilder from '../../../../common/form-builder/FormBuilder';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import CountryList from '../../../../Country.json';
import { IFormConfig } from '../../../../../types/form-builder';
import { getAPIRoute } from '../../../../../utils/api-route';
import { blackListedEmails } from '../../../../../types/business-unit';

export default function ManagementForm(props: {
  editable: any;
  isChange: any;
  imoNumber?: string;
}) {
  const { editable, isChange } = props;
  const { t } = useTranslation();
  const { control, setValue, getValues } = useFormContext();
  const { imoNumber } = props;
  const [countries, setCountries] = useState<any>();
  const [roles, setRoles] = useState<any>();
  const countryList = CountryList.sort();
  const distinctCallingCodes = countryList
    .map((item) => item.callingCode)
    .filter((value, index, self) => self.indexOf(value) === index);

  const callingCodes: any = [];
  distinctCallingCodes.forEach((callingCode) =>
    callingCodes.push({
      callingCode: callingCode,
    })
  );

  const getCountries = async () => {
    await api.get({ url: apiConfig.country }).then((data: any) => {
      setCountries(data);
    });
  };

  const companyCodeChange = async (e: any) => {
    if (e.value) {
      await api
        .get({
          url: getAPIRoute('vesselManagementCompanyCode', [
            getValues('roleName'),
            e.value,
          ]),
        })
        .then((res) => {
          setValue('companyName', res.companyName);
          setValue('countryCode', res.countryCode);
          setValue('address', res.address);
          setValue('email', res.email);
          setValue('phoneNumber', res.phoneNumber);
          setValue('callingCode', res.callingCode);
        });
    }
  };

  const getRoles = async () => {
    await api
      .get({ url: apiConfig.vesselAllManagementRoles + imoNumber })
      .then((data: any) => {
        setRoles(data);
      });
  };

  useEffect(() => {
    getRoles();
    getCountries();
  }, []);

  const managementFormConfig: IFormConfig[] = [
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'roleName',
      label: t('labels.role'),
      control: control,
      fieldType: 'select',
      dataSource: roles,
      displayExpr: 'roleName',
      valueExpr: 'roleName',
      disabled: editable || isChange,
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'companyCode',
      label: t('labels.companyNumberImo'),
      control: control,
      isRequired: true,
      fieldType: 'text',
      disabled: editable,
      placeholder: t('labels.lrfNumber'),
      maxLength: 25,
      onChange: companyCodeChange,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'companyName',
      label: t('labels.companyName'),
      control: control,
      fieldType: 'text',
      isRequired: true,
      disabled: editable,
      maxLength: 100,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'countryCode',
      label: t('labels.country'),
      control: control,
      fieldType: 'select',
      dataSource: countries,
      displayExpr: 'countryName',
      valueExpr: 'countryCode',
      disabled: editable,
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'address',
      label: t('labels.address'),
      control: control,
      fieldType: 'text',
      disabled: editable,
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'email',
      label: t('labels.email'),
      control: control,
      fieldType: 'text',
      disabled: editable,
      maxLength: 50,
      rules: [
        {
          type: 'email',
          message: t('errors.inCorrectEmailFormat'),
        },
        {
          type: 'custom',
          validationCallback: (params: any) => {
            const regex = new RegExp(blackListedEmails.join('|'));
            return !regex.test(params?.value?.toLowerCase());
          },
          message: t('errors.publicDomainEmailNotAllowed'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'phoneNumber',
      label: t('labels.phone'),
      control: control,
      fieldType: 'customSelect',
      dataSource: callingCodes,
      displayExpr: 'callingCode',
      valueExpr: 'callingCode',
      selectBoxName: 'callingCode',
      disabled: editable,
      maxLength: 25,
      itemRender: renderItem,
      customSelectInputFieldType: 'text',
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'fromDate',
      label: t('labels.fromDate'),
      control: control,
      fieldType: 'date',
      isRequired: true,
      disabled: editable,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={managementFormConfig} />
      </div>
    </>
  );
}

const renderItem = (data: any) => {
  return (
    <div>
      <div style={{ display: 'inline-block', textAlign: 'left' }}>
        {data?.callingCode}
      </div>
    </div>
  );
};
