import React, { useCallback, useMemo } from 'react';
import icons from '../../../components/common/icons/icons';
import imageURL from '../../../assets/images';
import { BerthFitCheckStatus } from '../../../enums/berthfit-status-enum';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { useDimensionsUnit } from '../../../hooks/useDimensionsUnit';

export default function BerthfitReport(props: {
  reportDetails: any;
  isAttachPdf?: any;
  reviewComment?: any;
  logo?: any;
}) {
  const { reportDetails, isAttachPdf, reviewComment, logo } = props;
  const { formatDate, dateTimeWithTimeZone } = useDateFormat();
  const { dimensionUnitText, meterToFeetConversion } = useDimensionsUnit();
  const cardStyles = {
    logoHead: {
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
      'flex-direction': 'column',
      padding: '16px 0',
    },
    logoImage: {
      height: '120px',
      marginBottom: '30px',
    },
    logoTextWrapDiv: {
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
    },
    logoText: {
      marginBottom: '0',
      fontSize: '18px',
      color: '#174a84',
      fontWeight: '700',
    },
    stamp: {
      marginLeft: '25px',
    },
    statusImage: {
      height: '44px',
    },
    contentHeaderWrap: {
      display: 'flex',
      'justify-content': 'space-between',
      'align-items': 'center',
      paddingBottom: '10px',
    },
    headerColfirst: {
      display: 'flex',
      'align-items': 'center',
    },
    headerColfirstHeading: {
      fontSize: '22px',
      lineHeight: '24px',
      color: '#174a84',
      fontWeight: '600',
      margin: '0',
    },
    headerColfirstSubHead: {
      fontSize: '12px',
      lineHeight: '14px',
      color: '#174a84',
      fontWeight: '600',
      marginLeft: '30px',
    },
    headerColSecond: {
      display: 'flex',
      'align-items': 'center',
      marginLeft: 'auto',
    },
    headerColSecondSubHead: {
      fontSize: '12px',
      lineHeight: '14px',
      color: '#174a84',
      fontWeight: '600',
      marginLeft: '0px',
    },
    contentSubTextWrap: {
      display: 'block',
    },
    reportCard: {
      border: '1px solid #e5e5e5',
      padding: '16px 0',
      borderRadius: '8px',
      marginTop: '8px',
    },
    reportCardContainer: {
      border: '1px solid #e5e5e5',
      padding: '0px 8px 8px 8px',
      borderRadius: '8px',
      marginTop: '8px',
    },
    reportCardRow: {
      display: 'flex',
      padding: '8px 0',
      width: '100%',
    },
    reportCardCol: {
      borderRight: '1px solid #e5e5e5',
      padding: '0 5px 0 16px',
      margin: '0',
    },
    reportCardColWidth10: {
      width: '10%',
    },
    reportCardColWidth15: {
      width: '15%',
    },
    reportCardColWidth20: {
      width: '20%',
    },
    reportCardColMinWidth20: {
      minWidth: '20%',
    },
    reportCardColWidth25: {
      width: '25%',
    },
    reportCardColWidth30: {
      width: '30%',
    },
    reportCardColWidth40: {
      width: '40%',
    },
    reportCardColWidth50: {
      width: '50%',
    },
    reportCardColWidth100: {
      width: '100%',
    },
    reportPl0: {
      paddingLeft: '0',
    },
    reportLabel: {
      color: '#4f4f4f',
      fontSize: '10px',
      lineHeight: '16px',
      fontWeight: '600',
      display: 'block',
      marginBottom: '6px',
    },
    reportValue: {
      color: '#174a84',
      fontSize: '10px',
      lineHeight: '16px',
      fontWeight: '400',
    },
    reportValueGreen: {
      color: '#00b050',
    },
    reportValueRed: {
      color: '#df2f2f',
    },
    reportSectionHeading: {
      fontSize: '14px',
      lineHeight: '24px',
      color: '#174a84',
      fontWeight: '600',
      margin: '16px 0 8px',
      backgroundColor: '#ebf3fe',
      padding: '10px 16px',
      border: '1px solid #ebf3fe',
    },
    reportEqualFlex: {
      display: 'flex',
      'justify-content': 'flex-start',
      'align-items': 'flex-start',
    },
    reportFlexEqCol: {
      borderRight: '1px solid #e5e5e5',
      flex: '1',
      padding: '0',
    },
    reportBlock: {
      display: 'block',
    },
    reportBorderRightNone: {
      borderRight: 'none',
    },
    reportBorderTop: {
      borderTop: '1px solid #e5e5e5',
    },
    reportWidth100: {
      width: '100%',
    },
    reportTitleH3: {
      margin: '0',
      padding: '0 16px',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#174a84',
      fontWeight: '600',
    },
    reportRowWrapper: {
      overflow: 'hidden',
      padding: '0 16px',
    },
    executionWrap: {
      border: '1px solid #2f80ed',
      borderRadius: '8px',
      marginBottom: '20px',
    },
    exeHeader: {
      display: 'flex',
      borderBottom: '1px solid #e5e5e5',
      'align-items': 'center',
      padding: '12px 16px',
    },
    exeHeaderText: {
      color: '#000',
      fontSize: '12px',
      lineHeight: '1',
      fontWeight: '500',
      marginBottom: '0',
    },
    marginLeftAuto: {
      marginLeft: 'auto',
    },
    displayFlex: {
      display: 'flex',
    },
    alignItemsCenter: {
      'align-items': 'center',
    },
    exeHeaderLastItem: {
      marginLeft: '10px',
      paddingLeft: '10px',
      borderLeft: '1px solid #e5e5e5',
    },
    exeHeaderRightContent: {
      color: '#174a84',
      fontSize: '9px',
      fontWeight: '500',
      paddingBottom: '16px',
    },
    approvalInfo: {
      color: '#f2994a',
      fontSize: '9px',
      fontWeight: '500',
      marginLeft: '10px',
    },
    approvalInfoApproved: {
      color: '#27ae60',
      fontSize: '9px',
      fontWeight: '500',
      marginLeft: '10px',
    },
    exeBody: {
      padding: '8px',
    },
    exeTextH4: {
      color: '#828282',
      fontSize: '12px',
      fontWeight: '400',
      marginBottom: '8px',
    },
    exBgBox: {
      border: '1px solid #2f80ed',
      borderRadius: '8px',
      background: '#ebf3fe',
    },
    exBgBoxPadding: {
      padding: '20px 15px 15px',
    },
    exBorderBottom: {
      borderBottom: '1px solid #e5e5e5',
    },
    exBgBoxHeaderText: {
      margin: '0px 0px 10px',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#174a84',
      fontWeight: '600',
    },
    label: {
      color: '#828282',
      fontSize: '10px',
      fontWeight: '500',
      marginBottom: '4px',
      display: 'block',
    },
    formValue: {
      color: '#585c64',
      padding: '6px 8px 6px 8px',
      background: '#fff',
      display: 'block',
      fontSize: '1em',
      border: '1px solid #90a1b5',
      borderRadius: '4px',
      minHeight: '32px',
    },
    commentValue: {
      color: '#585c64',
      padding: '6px 8px 6px 8px',
      background: '#fff',
      display: 'block',
      fontSize: '1em',
      border: '1px solid #90a1b5',
      borderRadius: '4px',
      minHeight: '32px',
      width: '460px',
      'overflow-wrap': 'break-word',
    },
    berthCommentValue: {
      color: '#585c64',
      padding: '6px 8px 6px 8px',
      background: '#fff',
      display: 'block',
      fontSize: '1em',
      border: '1px solid #90a1b5',
      borderRadius: '4px',
      minHeight: '32px',
      width: '440px',
      'overflow-wrap': 'break-word',
    },
    paddinRight8: {
      paddingRight: '8px',
    },
    exBoxWhite: {
      border: '1px solid #2f80ed',
      borderRadius: '8px',
      background: '#fff',
    },
    table: {
      width: '100%',
    },
    tableTH: {
      fontSize: '12px',
      fontWeight: '500',
      color: '#000',
      border: '1px solid #e5e5e5',
      padding: '10px 15px',
    },
    tableTD: {
      fontSize: '12px',
      fontWeight: '400',
      color: '#000',
      border: '1px solid #e5e5e5',
      padding: '10px 15px',
    },
  };
  const getStatusStyle = (data: any) => {
    let color = '#27ae60';
    if (data?.isOverrided) {
      color = '#d27420';
    } else {
      if (data?.statusCode == BerthFitCheckStatus.NotAcceptable) {
        color = '#eb5757';
      } else if (data?.statusCode == BerthFitCheckStatus.Acceptable) {
        color = '#27ae60';
      } else {
        color = '#585c64';
      }
    }
    return {
      color: color,
      padding: '6px 8px 6px 8px',
      background: '#fff',
      display: 'block',
      fontSize: '1em',
      border: '1px solid #90a1b5',
      borderRadius: '4px',
      minHeight: '32px',
    };
  };
  const displayComments = (data: string) => {
    const value = data?.split('\n');
    return (
      <span>
        {value?.map((element, index) => {
          return (
            element && (
              <span
                style={{
                  fontSize: '10px',
                }}
                key={index}
              >
                {element}
                <br />
              </span>
            )
          );
        })}
      </span>
    );
  };

  const getCommentBody = useCallback((comment: string) => {
    return comment?.replace(/\n/g, '<br/>');
  }, []);

  const berthFitDate = useMemo(() => {
    if (
      reportDetails &&
      reportDetails.vetRequestBerthFitPorts &&
      reportDetails.vetRequestBerthFitPorts.length > 0
    ) {
      return reportDetails.vetRequestBerthFitPorts[0].berthFitFinalizedDate;
    }
  }, [reportDetails]);

  return (
    // <div style={{ marginTop: '300px' }}>
    <div className="inner-wrapper-report ps-3 pe-3">
      <div style={cardStyles.reportBlock}>
        <div style={cardStyles.reportBlock}>
          {reportDetails?.vetRequestBerthFitPorts?.length === 0 && (
            <div id="element-to-print">
              <div style={cardStyles.reportBlock}>
                <div style={cardStyles.logoHead}>
                  {logo && (
                    <img
                      src={logo}
                      style={cardStyles.logoImage}
                      alt="CompanyLogo"
                    />
                  )}
                  <div style={cardStyles.logoTextWrapDiv}>
                    <h1 style={cardStyles.logoText}>BERTH FIT REPORT</h1>
                    {reportDetails?.status && (
                      <span style={cardStyles.stamp}>
                        <img
                          style={cardStyles.statusImage}
                          src={
                            reportDetails?.status ===
                              BerthFitCheckStatus.Acceptable &&
                            reportDetails?.isOverrided
                              ? imageURL.acceptableOveride
                              : reportDetails?.status ===
                                  BerthFitCheckStatus.Acceptable &&
                                !reportDetails?.isOverrided
                              ? imageURL.accpetable
                              : reportDetails?.status ===
                                  BerthFitCheckStatus.NotAcceptable &&
                                reportDetails?.isOverrided
                              ? imageURL.notAcceptableOveride
                              : reportDetails?.status ===
                                  BerthFitCheckStatus.NotAcceptable &&
                                !reportDetails?.isOverrided
                              ? imageURL.notAcceptable
                              : reportDetails?.status ===
                                BerthFitCheckStatus.FurtherInfoNeeded
                              ? imageURL.furtherInfoNeeded
                              : ''
                          }
                        />
                      </span>
                    )}
                  </div>
                </div>
                <div style={cardStyles.contentHeaderWrap}>
                  <div style={cardStyles.headerColfirst}>
                    <h2 style={cardStyles.headerColfirstHeading}>
                      {reportDetails?.shipName}
                    </h2>
                    {reportDetails?.vetId && (
                      <span style={cardStyles.headerColfirstSubHead}>
                        VET ID: {reportDetails?.vetId}
                      </span>
                    )}
                  </div>
                  <div style={cardStyles.headerColSecond}>
                    <span style={cardStyles.headerColSecondSubHead}>
                      Berth Fit Date:{' '}
                      {berthFitDate
                        ? dateTimeWithTimeZone(berthFitDate + 'z')
                        : dateTimeWithTimeZone(new Date())}
                    </span>
                  </div>
                </div>
                <div style={cardStyles.contentSubTextWrap}>
                  {reportDetails?.imoNumber && (
                    <span style={cardStyles.headerColSecondSubHead}>
                      IMO: {reportDetails?.imoNumber}
                    </span>
                  )}
                  {!reportDetails?.imoNumber && reportDetails?.vinNumber && (
                    <span style={cardStyles.headerColSecondSubHead}>
                      VIN: {reportDetails?.vinNumber}
                    </span>
                  )}
                </div>
              </div>

              {reportDetails?.vesselInfo && (
                <div style={cardStyles.reportCard}>
                  <div style={cardStyles.reportCardRow}>
                    <div
                      style={{
                        ...cardStyles.reportCardCol,
                        ...cardStyles.reportCardColWidth10,
                      }}
                    >
                      <span style={cardStyles.reportLabel}>Age</span>
                      <span style={cardStyles.reportValue}>
                        {reportDetails?.vesselInfo?.age}
                      </span>
                    </div>
                    <div
                      style={{
                        ...cardStyles.reportCardCol,
                        ...cardStyles.reportCardColWidth20,
                      }}
                    >
                      <span style={cardStyles.reportLabel}>Status</span>
                      <span style={cardStyles.reportValue}>
                        {reportDetails?.vesselInfo?.shipStatus}
                      </span>
                    </div>
                    <div
                      style={{
                        ...cardStyles.reportCardCol,
                        ...cardStyles.reportCardColWidth20,
                      }}
                    >
                      <span style={cardStyles.reportLabel}>Vessel Type</span>
                      <span style={cardStyles.reportValue}>
                        {reportDetails?.vesselInfo?.vesselType}
                      </span>
                    </div>
                    <div
                      style={{
                        ...cardStyles.reportCardCol,
                        ...cardStyles.reportCardColWidth20,
                      }}
                    >
                      <span style={cardStyles.reportLabel}>DOC Company</span>
                      <span style={cardStyles.reportValue}>
                        {reportDetails?.vesselInfo?.docCompanyName}
                      </span>
                    </div>
                    <div
                      style={{
                        ...cardStyles.reportCardCol,
                        ...cardStyles.reportCardColWidth10,
                      }}
                    >
                      <span style={cardStyles.reportLabel}>
                        LOA {dimensionUnitText()}
                      </span>
                      <span style={cardStyles.reportValue}>
                        {meterToFeetConversion(
                          reportDetails?.vesselInfo?.lengthOverAll
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        ...cardStyles.reportCardCol,
                        ...cardStyles.reportCardColWidth15,
                        ...cardStyles.reportBorderRightNone,
                      }}
                    >
                      <span style={cardStyles.reportLabel}>Summer DWT</span>
                      <span style={cardStyles.reportValue}>
                        {reportDetails?.vesselInfo?.deadWeight}
                      </span>
                    </div>
                  </div>
                  <div style={cardStyles.reportCardRow}>
                    <div
                      style={{
                        ...cardStyles.reportCardCol,
                        ...cardStyles.reportCardColWidth10,
                      }}
                    >
                      <span style={cardStyles.reportLabel}>Call sign</span>
                      <span style={cardStyles.reportValue}>
                        {reportDetails?.vesselInfo?.callSign}
                      </span>
                    </div>
                    <div
                      style={{
                        ...cardStyles.reportCardCol,
                        ...cardStyles.reportCardColWidth20,
                      }}
                    >
                      <span style={cardStyles.reportLabel}>Class</span>
                      <span style={cardStyles.reportValue}>
                        {reportDetails?.vesselInfo?.classificationName}
                      </span>
                    </div>
                    <div
                      style={{
                        ...cardStyles.reportCardCol,
                        ...cardStyles.reportCardColWidth20,
                      }}
                    >
                      <span style={cardStyles.reportLabel}>P & I Club</span>
                      <span style={cardStyles.reportValue}>
                        {reportDetails?.vesselInfo?.piClub}
                      </span>
                    </div>
                    <div
                      style={{
                        ...cardStyles.reportCardCol,
                        ...cardStyles.reportCardColWidth20,
                      }}
                    >
                      <span style={cardStyles.reportLabel}>Previous Name</span>
                      <span style={cardStyles.reportValue}>
                        {reportDetails?.vesselInfo?.shipPrevName}
                      </span>
                    </div>
                    <div
                      style={{
                        ...cardStyles.reportCardCol,
                        ...cardStyles.reportCardColWidth10,
                      }}
                    >
                      <span style={cardStyles.reportLabel}>
                        Breadth {dimensionUnitText()}
                      </span>
                      <span style={cardStyles.reportValue}>
                        {meterToFeetConversion(
                          reportDetails?.vesselInfo?.breadthExtreme
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        ...cardStyles.reportCardCol,
                        ...cardStyles.reportCardColWidth15,
                        ...cardStyles.reportBorderRightNone,
                      }}
                    >
                      <span style={cardStyles.reportLabel}>Sanction</span>
                      <span
                        style={
                          reportDetails?.vesselInfo?.sanction === 'No'
                            ? {
                                ...cardStyles.reportValue,
                                ...cardStyles.reportValueGreen,
                              }
                            : {
                                ...cardStyles.reportValue,
                                ...cardStyles.reportValueRed,
                              }
                        }
                      >
                        {reportDetails?.vesselInfo?.sanction}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {reportDetails?.vetReqInfo && (
                <>
                  <div>
                    <h3 style={cardStyles.reportSectionHeading}>
                      Vet Request Detail
                    </h3>
                    {/* <div style={cardStyles.reportCardContainer}> */}
                    <div style={cardStyles.reportCard}>
                      <div style={cardStyles.reportEqualFlex}>
                        <div style={cardStyles.reportFlexEqCol}>
                          <div style={cardStyles.reportCardRow}>
                            <div
                              style={{
                                ...cardStyles.reportCardCol,
                                ...cardStyles.reportCardColWidth100,
                                ...cardStyles.reportBorderRightNone,
                              }}
                            >
                              <span style={cardStyles.reportLabel}>Date</span>
                              <span style={cardStyles.reportValue}>
                                {reportDetails?.vetReqInfo?.requestDate}
                              </span>
                            </div>
                          </div>
                          <div style={cardStyles.reportCardRow}>
                            <div
                              style={{
                                ...cardStyles.reportCardCol,
                                ...cardStyles.reportCardColWidth100,
                                ...cardStyles.reportBorderRightNone,
                              }}
                            >
                              <span style={cardStyles.reportLabel}>
                                Requestor Name
                              </span>
                              <span style={cardStyles.reportValue}>
                                {reportDetails?.vetReqInfo?.employeeName}
                              </span>
                            </div>
                          </div>
                          <div style={cardStyles.reportCardRow}>
                            <div
                              style={{
                                ...cardStyles.reportEqualFlex,
                                ...cardStyles.reportWidth100,
                              }}
                            >
                              <div
                                style={{
                                  ...cardStyles.reportCardCol,
                                  ...cardStyles.reportCardColWidth50,
                                }}
                              >
                                <span style={cardStyles.reportLabel}>
                                  Laycan From Date
                                </span>
                                <span style={cardStyles.reportValue}>
                                  {reportDetails?.vetReqInfo?.laycanFromDate}
                                </span>
                              </div>
                              <div
                                style={{
                                  ...cardStyles.reportCardCol,
                                  ...cardStyles.reportCardColWidth50,
                                  ...cardStyles.reportBorderRightNone,
                                }}
                              >
                                <span style={cardStyles.reportLabel}>
                                  To Date
                                </span>
                                <span style={cardStyles.reportValue}>
                                  {reportDetails?.vetReqInfo?.laycanToDate}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={cardStyles.reportFlexEqCol}>
                          <div style={cardStyles.reportCardRow}>
                            <div
                              style={{
                                ...cardStyles.reportCardCol,
                                ...cardStyles.reportCardColWidth100,
                                ...cardStyles.reportBorderRightNone,
                              }}
                            >
                              <span style={cardStyles.reportLabel}>
                                Business Unit
                              </span>
                              <span style={cardStyles.reportValue}>
                                {reportDetails?.vetReqInfo?.buName}
                              </span>
                            </div>
                          </div>
                          <div style={cardStyles.reportCardRow}>
                            <div
                              style={{
                                ...cardStyles.reportCardCol,
                                ...cardStyles.reportCardColWidth100,
                                ...cardStyles.reportBorderRightNone,
                              }}
                            >
                              <span style={cardStyles.reportLabel}>
                                Company Name
                              </span>
                              <span style={cardStyles.reportValue}>
                                {reportDetails?.vetReqInfo?.companyName}
                              </span>
                            </div>
                          </div>
                          <div style={cardStyles.reportCardRow}>
                            <div
                              style={{
                                ...cardStyles.reportCardCol,
                                ...cardStyles.reportCardColWidth100,
                                ...cardStyles.reportBorderRightNone,
                              }}
                            >
                              <span style={cardStyles.reportLabel}>
                                Inco Terms
                              </span>
                              <span style={cardStyles.reportValue}>
                                {reportDetails?.vetReqInfo?.incoTerm}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div style={cardStyles.reportFlexEqCol}>
                          <div style={cardStyles.reportCardRow}>
                            <div
                              style={{
                                ...cardStyles.reportCardCol,
                                ...cardStyles.reportCardColWidth100,
                                ...cardStyles.reportBorderRightNone,
                              }}
                            >
                              <span style={cardStyles.reportLabel}>
                                Voyage Number
                              </span>
                              <span style={cardStyles.reportValue}>
                                {reportDetails?.vetReqInfo?.voyageNumber}
                              </span>
                            </div>
                          </div>
                          <div style={cardStyles.reportCardRow}>
                            <div
                              style={{
                                ...cardStyles.reportCardCol,
                                ...cardStyles.reportCardColWidth100,
                                ...cardStyles.reportBorderRightNone,
                              }}
                            >
                              <span style={cardStyles.reportLabel}>
                                Check Berth Fit
                              </span>
                              <span style={cardStyles.reportValue}>
                                {reportDetails?.vetReqInfo?.berthFitCheck}
                              </span>
                            </div>
                          </div>
                          <div style={cardStyles.reportCardRow}>
                            <div
                              style={{
                                ...cardStyles.reportCardCol,
                                ...cardStyles.reportCardColWidth100,
                                ...cardStyles.reportBorderRightNone,
                              }}
                            >
                              <span style={cardStyles.reportLabel}>Cargo</span>
                              <span style={cardStyles.reportValue}>
                                {reportDetails?.vetReqInfo?.cargo}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div style={cardStyles.reportFlexEqCol}>
                          <div style={cardStyles.reportCardRow}>
                            <div
                              style={{
                                ...cardStyles.reportCardCol,
                                ...cardStyles.reportCardColWidth100,
                                ...cardStyles.reportBorderRightNone,
                              }}
                            >
                              <span style={cardStyles.reportLabel}>Email</span>
                              <span style={cardStyles.reportValue}>
                                {reportDetails?.vetReqInfo?.emailIds}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {reportDetails?.loadPortInfo.length > 0 && (
                    <div style={cardStyles.reportCard}>
                      <h4 style={cardStyles.reportTitleH3}>Load Port</h4>
                      <div style={cardStyles.reportRowWrapper}>
                        {reportDetails?.loadPortInfo?.map(
                          (loadPort: any, index: any) => {
                            return (
                              <div
                                style={
                                  index === 0
                                    ? cardStyles.reportCardRow
                                    : {
                                        ...cardStyles.reportCardRow,
                                        ...cardStyles.reportBorderTop,
                                      }
                                }
                              >
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth20,
                                    ...cardStyles.reportBorderRightNone,
                                    ...cardStyles.reportPl0,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    Port{index + 1}
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {loadPort?.portName}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth15,
                                    ...cardStyles.reportBorderRightNone,
                                    ...cardStyles.reportPl0,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    Check Berth Fit
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {loadPort?.berthFitCheck}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth40,
                                    ...cardStyles.reportBorderRightNone,
                                    ...cardStyles.reportPl0,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    Terminals
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {loadPort?.terminals}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth25,
                                    ...cardStyles.reportBorderRightNone,
                                    ...cardStyles.reportPl0,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    Estimated Date of Load
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {loadPort?.estimatedDateOfLoad}
                                  </span>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}
                  {reportDetails?.dischargePortInfo.length > 0 && (
                    <div style={cardStyles.reportCard}>
                      <h4 style={cardStyles.reportTitleH3}>Discharge Port</h4>
                      <div style={cardStyles.reportRowWrapper}>
                        {reportDetails?.dischargePortInfo?.map(
                          (dischargePort: any, index: any) => {
                            return (
                              <div
                                style={
                                  index === 0
                                    ? cardStyles.reportCardRow
                                    : {
                                        ...cardStyles.reportCardRow,
                                        ...cardStyles.reportBorderTop,
                                      }
                                }
                              >
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth20,
                                    ...cardStyles.reportBorderRightNone,
                                    ...cardStyles.reportPl0,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    Port{index + 1}
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {dischargePort?.portName}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth15,
                                    ...cardStyles.reportBorderRightNone,
                                    ...cardStyles.reportPl0,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    Check Berth Fit
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {dischargePort?.berthFitCheck}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth40,
                                    ...cardStyles.reportBorderRightNone,
                                    ...cardStyles.reportPl0,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    Terminals
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {dischargePort?.terminals}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth25,
                                    ...cardStyles.reportBorderRightNone,
                                    ...cardStyles.reportPl0,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    Estimated Date Of Discharge
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {dischargePort?.estimatedDateOfDischarge}
                                  </span>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}
                </>
                // </div>
              )}
            </div>
          )}
          {reportDetails?.vetRequestBerthFitPorts?.length > 0 && (
            <>
              {reportDetails?.vetRequestBerthFitPorts?.map(
                (port: any, index: any) => {
                  return (
                    <div id="element-to-print">
                      {index === 0 && (
                        <>
                          <div style={cardStyles.reportBlock}>
                            <div style={cardStyles.logoHead}>
                              {logo && (
                                <img
                                  src={logo}
                                  style={cardStyles.logoImage}
                                  alt="CompanyLogo"
                                />
                              )}
                              <div style={cardStyles.logoTextWrapDiv}>
                                <h1 style={cardStyles.logoText}>
                                  BERTH FIT REPORT
                                </h1>
                                {reportDetails?.status && (
                                  <span style={cardStyles.stamp}>
                                    <img
                                      style={cardStyles.statusImage}
                                      src={
                                        reportDetails?.status ===
                                          BerthFitCheckStatus.Acceptable &&
                                        reportDetails?.isOverrided
                                          ? imageURL.acceptableOveride
                                          : reportDetails?.status ===
                                              BerthFitCheckStatus.Acceptable &&
                                            !reportDetails?.isOverrided
                                          ? imageURL.accpetable
                                          : reportDetails?.status ===
                                              BerthFitCheckStatus.NotAcceptable &&
                                            reportDetails?.isOverrided
                                          ? imageURL.notAcceptableOveride
                                          : reportDetails?.status ===
                                              BerthFitCheckStatus.NotAcceptable &&
                                            !reportDetails?.isOverrided
                                          ? imageURL.notAcceptable
                                          : reportDetails?.status ===
                                            BerthFitCheckStatus.FurtherInfoNeeded
                                          ? imageURL.furtherInfoNeeded
                                          : ''
                                      }
                                    />
                                  </span>
                                )}
                              </div>
                            </div>
                            <div style={cardStyles.contentHeaderWrap}>
                              <div style={cardStyles.headerColfirst}>
                                <h2 style={cardStyles.headerColfirstHeading}>
                                  {reportDetails?.shipName}
                                </h2>
                                {reportDetails?.vetId && (
                                  <span
                                    style={cardStyles.headerColfirstSubHead}
                                  >
                                    VET ID: {reportDetails?.vetId}
                                  </span>
                                )}
                              </div>
                              <div style={cardStyles.headerColSecond}>
                                <span style={cardStyles.headerColSecondSubHead}>
                                  Berth Fit Date:{' '}
                                  {berthFitDate
                                    ? dateTimeWithTimeZone(berthFitDate + 'z')
                                    : dateTimeWithTimeZone(new Date())}
                                </span>
                              </div>
                            </div>
                            <div style={cardStyles.contentSubTextWrap}>
                              {reportDetails?.imoNumber && (
                                <span style={cardStyles.headerColSecondSubHead}>
                                  IMO: {reportDetails?.imoNumber}
                                </span>
                              )}
                              {!reportDetails?.imoNumber &&
                                reportDetails?.vinNumber && (
                                  <span
                                    style={cardStyles.headerColSecondSubHead}
                                  >
                                    VIN: {reportDetails?.vinNumber}
                                  </span>
                                )}
                            </div>
                          </div>

                          {reportDetails?.vesselInfo && (
                            <div style={cardStyles.reportCard}>
                              <div style={cardStyles.reportCardRow}>
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth10,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    Age
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {reportDetails?.vesselInfo?.age}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth20,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    Status
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {reportDetails?.vesselInfo?.shipStatus}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth20,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    Vessel Type
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {reportDetails?.vesselInfo?.vesselType}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth20,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    DOC Company
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {reportDetails?.vesselInfo?.docCompanyName}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth10,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    LOA {dimensionUnitText()}
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {meterToFeetConversion(
                                      reportDetails?.vesselInfo?.lengthOverAll
                                    )}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth15,
                                    ...cardStyles.reportBorderRightNone,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    Summer DWT
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {reportDetails?.vesselInfo?.deadWeight}
                                  </span>
                                </div>
                              </div>
                              <div style={cardStyles.reportCardRow}>
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth10,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    Call sign
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {reportDetails?.vesselInfo?.callSign}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth20,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    Class
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {
                                      reportDetails?.vesselInfo
                                        ?.classificationName
                                    }
                                  </span>
                                </div>
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth20,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    P & I Club
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {reportDetails?.vesselInfo?.piClub}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth20,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    Previous Name
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {reportDetails?.vesselInfo?.shipPrevName}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth10,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    Breadth {dimensionUnitText()}
                                  </span>
                                  <span style={cardStyles.reportValue}>
                                    {meterToFeetConversion(
                                      reportDetails?.vesselInfo?.breadthExtreme
                                    )}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    ...cardStyles.reportCardCol,
                                    ...cardStyles.reportCardColWidth15,
                                    ...cardStyles.reportBorderRightNone,
                                  }}
                                >
                                  <span style={cardStyles.reportLabel}>
                                    Sanction
                                  </span>
                                  <span
                                    style={
                                      reportDetails?.vesselInfo?.sanction ===
                                      'No'
                                        ? {
                                            ...cardStyles.reportValue,
                                            ...cardStyles.reportValueGreen,
                                          }
                                        : {
                                            ...cardStyles.reportValue,
                                            ...cardStyles.reportValueRed,
                                          }
                                    }
                                  >
                                    {reportDetails?.vesselInfo?.sanction}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}

                          {reportDetails?.vetReqInfo && (
                            <>
                              <div>
                                <h3 style={cardStyles.reportSectionHeading}>
                                  Vet Request Detail
                                </h3>
                                {/* <div style={cardStyles.reportCardContainer}> */}
                                <div style={cardStyles.reportCard}>
                                  <div style={cardStyles.reportEqualFlex}>
                                    <div style={cardStyles.reportFlexEqCol}>
                                      <div style={cardStyles.reportCardRow}>
                                        <div
                                          style={{
                                            ...cardStyles.reportCardCol,
                                            ...cardStyles.reportCardColWidth100,
                                            ...cardStyles.reportBorderRightNone,
                                          }}
                                        >
                                          <span style={cardStyles.reportLabel}>
                                            Date
                                          </span>
                                          <span style={cardStyles.reportValue}>
                                            {
                                              reportDetails?.vetReqInfo
                                                ?.requestDate
                                            }
                                          </span>
                                        </div>
                                      </div>
                                      <div style={cardStyles.reportCardRow}>
                                        <div
                                          style={{
                                            ...cardStyles.reportCardCol,
                                            ...cardStyles.reportCardColWidth100,
                                            ...cardStyles.reportBorderRightNone,
                                          }}
                                        >
                                          <span style={cardStyles.reportLabel}>
                                            Requestor Name
                                          </span>
                                          <span style={cardStyles.reportValue}>
                                            {
                                              reportDetails?.vetReqInfo
                                                ?.employeeName
                                            }
                                          </span>
                                        </div>
                                      </div>
                                      <div style={cardStyles.reportCardRow}>
                                        <div
                                          style={{
                                            ...cardStyles.reportEqualFlex,
                                            ...cardStyles.reportWidth100,
                                          }}
                                        >
                                          <div
                                            style={{
                                              ...cardStyles.reportCardCol,
                                              ...cardStyles.reportCardColWidth50,
                                            }}
                                          >
                                            <span
                                              style={cardStyles.reportLabel}
                                            >
                                              Laycan From Date
                                            </span>
                                            <span
                                              style={cardStyles.reportValue}
                                            >
                                              {
                                                reportDetails?.vetReqInfo
                                                  ?.laycanFromDate
                                              }
                                            </span>
                                          </div>
                                          <div
                                            style={{
                                              ...cardStyles.reportCardCol,
                                              ...cardStyles.reportCardColWidth50,
                                              ...cardStyles.reportBorderRightNone,
                                            }}
                                          >
                                            <span
                                              style={cardStyles.reportLabel}
                                            >
                                              To Date
                                            </span>
                                            <span
                                              style={cardStyles.reportValue}
                                            >
                                              {
                                                reportDetails?.vetReqInfo
                                                  ?.laycanToDate
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={cardStyles.reportFlexEqCol}>
                                      <div style={cardStyles.reportCardRow}>
                                        <div
                                          style={{
                                            ...cardStyles.reportCardCol,
                                            ...cardStyles.reportCardColWidth100,
                                            ...cardStyles.reportBorderRightNone,
                                          }}
                                        >
                                          <span style={cardStyles.reportLabel}>
                                            Business Unit
                                          </span>
                                          <span style={cardStyles.reportValue}>
                                            {reportDetails?.vetReqInfo?.buName}
                                          </span>
                                        </div>
                                      </div>
                                      <div style={cardStyles.reportCardRow}>
                                        <div
                                          style={{
                                            ...cardStyles.reportCardCol,
                                            ...cardStyles.reportCardColWidth100,
                                            ...cardStyles.reportBorderRightNone,
                                          }}
                                        >
                                          <span style={cardStyles.reportLabel}>
                                            Company Name
                                          </span>
                                          <span style={cardStyles.reportValue}>
                                            {
                                              reportDetails?.vetReqInfo
                                                ?.companyName
                                            }
                                          </span>
                                        </div>
                                      </div>
                                      <div style={cardStyles.reportCardRow}>
                                        <div
                                          style={{
                                            ...cardStyles.reportCardCol,
                                            ...cardStyles.reportCardColWidth100,
                                            ...cardStyles.reportBorderRightNone,
                                          }}
                                        >
                                          <span style={cardStyles.reportLabel}>
                                            Inco Terms
                                          </span>
                                          <span style={cardStyles.reportValue}>
                                            {
                                              reportDetails?.vetReqInfo
                                                ?.incoTerm
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={cardStyles.reportFlexEqCol}>
                                      <div style={cardStyles.reportCardRow}>
                                        <div
                                          style={{
                                            ...cardStyles.reportCardCol,
                                            ...cardStyles.reportCardColWidth100,
                                            ...cardStyles.reportBorderRightNone,
                                          }}
                                        >
                                          <span style={cardStyles.reportLabel}>
                                            Voyage Number
                                          </span>
                                          <span style={cardStyles.reportValue}>
                                            {
                                              reportDetails?.vetReqInfo
                                                ?.voyageNumber
                                            }
                                          </span>
                                        </div>
                                      </div>
                                      <div style={cardStyles.reportCardRow}>
                                        <div
                                          style={{
                                            ...cardStyles.reportCardCol,
                                            ...cardStyles.reportCardColWidth100,
                                            ...cardStyles.reportBorderRightNone,
                                          }}
                                        >
                                          <span style={cardStyles.reportLabel}>
                                            Check Berth Fit
                                          </span>
                                          <span style={cardStyles.reportValue}>
                                            {
                                              reportDetails?.vetReqInfo
                                                ?.berthFitCheck
                                            }
                                          </span>
                                        </div>
                                      </div>
                                      <div style={cardStyles.reportCardRow}>
                                        <div
                                          style={{
                                            ...cardStyles.reportCardCol,
                                            ...cardStyles.reportCardColWidth100,
                                            ...cardStyles.reportBorderRightNone,
                                          }}
                                        >
                                          <span style={cardStyles.reportLabel}>
                                            Cargo
                                          </span>
                                          <span style={cardStyles.reportValue}>
                                            {reportDetails?.vetReqInfo?.cargo}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={cardStyles.reportFlexEqCol}>
                                      <div style={cardStyles.reportCardRow}>
                                        <div
                                          style={{
                                            ...cardStyles.reportCardCol,
                                            ...cardStyles.reportCardColWidth100,
                                            ...cardStyles.reportBorderRightNone,
                                          }}
                                        >
                                          <span style={cardStyles.reportLabel}>
                                            Email
                                          </span>
                                          <span style={cardStyles.reportValue}>
                                            {
                                              reportDetails?.vetReqInfo
                                                ?.emailIds
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {reportDetails?.loadPortInfo.length > 0 && (
                                <div style={cardStyles.reportCard}>
                                  <h4 style={cardStyles.reportTitleH3}>
                                    Load Port
                                  </h4>
                                  <div style={cardStyles.reportRowWrapper}>
                                    {reportDetails?.loadPortInfo?.map(
                                      (loadPort: any, index: any) => {
                                        return (
                                          <div
                                            style={
                                              index === 0
                                                ? cardStyles.reportCardRow
                                                : {
                                                    ...cardStyles.reportCardRow,
                                                    ...cardStyles.reportBorderTop,
                                                  }
                                            }
                                          >
                                            <div
                                              style={{
                                                ...cardStyles.reportCardCol,
                                                ...cardStyles.reportCardColWidth20,
                                                ...cardStyles.reportBorderRightNone,
                                                ...cardStyles.reportPl0,
                                              }}
                                            >
                                              <span
                                                style={cardStyles.reportLabel}
                                              >
                                                Port{index + 1}
                                              </span>
                                              <span
                                                style={cardStyles.reportValue}
                                              >
                                                {loadPort?.portName}
                                              </span>
                                            </div>
                                            <div
                                              style={{
                                                ...cardStyles.reportCardCol,
                                                ...cardStyles.reportCardColWidth15,
                                                ...cardStyles.reportBorderRightNone,
                                                ...cardStyles.reportPl0,
                                              }}
                                            >
                                              <span
                                                style={cardStyles.reportLabel}
                                              >
                                                Check Berth Fit
                                              </span>
                                              <span
                                                style={cardStyles.reportValue}
                                              >
                                                {loadPort?.berthFitCheck}
                                              </span>
                                            </div>
                                            <div
                                              style={{
                                                ...cardStyles.reportCardCol,
                                                ...cardStyles.reportCardColWidth40,
                                                ...cardStyles.reportBorderRightNone,
                                                ...cardStyles.reportPl0,
                                              }}
                                            >
                                              <span
                                                style={cardStyles.reportLabel}
                                              >
                                                Terminals
                                              </span>
                                              <span
                                                style={cardStyles.reportValue}
                                              >
                                                {loadPort?.terminals}
                                              </span>
                                            </div>
                                            <div
                                              style={{
                                                ...cardStyles.reportCardCol,
                                                ...cardStyles.reportCardColWidth25,
                                                ...cardStyles.reportBorderRightNone,
                                                ...cardStyles.reportPl0,
                                              }}
                                            >
                                              <span
                                                style={cardStyles.reportLabel}
                                              >
                                                Estimated Date of Load
                                              </span>
                                              <span
                                                style={cardStyles.reportValue}
                                              >
                                                {loadPort?.estimatedDateOfLoad}
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              )}
                              {reportDetails?.dischargePortInfo.length > 0 && (
                                <div style={cardStyles.reportCard}>
                                  <h4 style={cardStyles.reportTitleH3}>
                                    Discharge Port
                                  </h4>
                                  <div style={cardStyles.reportRowWrapper}>
                                    {reportDetails?.dischargePortInfo?.map(
                                      (dischargePort: any, index: any) => {
                                        return (
                                          <div
                                            style={
                                              index === 0
                                                ? cardStyles.reportCardRow
                                                : {
                                                    ...cardStyles.reportCardRow,
                                                    ...cardStyles.reportBorderTop,
                                                  }
                                            }
                                          >
                                            <div
                                              style={{
                                                ...cardStyles.reportCardCol,
                                                ...cardStyles.reportCardColWidth20,
                                                ...cardStyles.reportBorderRightNone,
                                                ...cardStyles.reportPl0,
                                              }}
                                            >
                                              <span
                                                style={cardStyles.reportLabel}
                                              >
                                                Port{index + 1}
                                              </span>
                                              <span
                                                style={cardStyles.reportValue}
                                              >
                                                {dischargePort?.portName}
                                              </span>
                                            </div>
                                            <div
                                              style={{
                                                ...cardStyles.reportCardCol,
                                                ...cardStyles.reportCardColWidth15,
                                                ...cardStyles.reportBorderRightNone,
                                                ...cardStyles.reportPl0,
                                              }}
                                            >
                                              <span
                                                style={cardStyles.reportLabel}
                                              >
                                                Check Berth Fit
                                              </span>
                                              <span
                                                style={cardStyles.reportValue}
                                              >
                                                {dischargePort?.berthFitCheck}
                                              </span>
                                            </div>
                                            <div
                                              style={{
                                                ...cardStyles.reportCardCol,
                                                ...cardStyles.reportCardColWidth40,
                                                ...cardStyles.reportBorderRightNone,
                                                ...cardStyles.reportPl0,
                                              }}
                                            >
                                              <span
                                                style={cardStyles.reportLabel}
                                              >
                                                Terminals
                                              </span>
                                              <span
                                                style={cardStyles.reportValue}
                                              >
                                                {dischargePort?.terminals}
                                              </span>
                                            </div>
                                            <div
                                              style={{
                                                ...cardStyles.reportCardCol,
                                                ...cardStyles.reportCardColWidth25,
                                                ...cardStyles.reportBorderRightNone,
                                                ...cardStyles.reportPl0,
                                              }}
                                            >
                                              <span
                                                style={cardStyles.reportLabel}
                                              >
                                                Estimated Date Of Discharge
                                              </span>
                                              <span
                                                style={cardStyles.reportValue}
                                              >
                                                {
                                                  dischargePort?.estimatedDateOfDischarge
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              )}
                            </>
                            // </div>
                          )}
                          <h3 style={cardStyles.reportSectionHeading}>
                            Berth Fit Execution Details
                          </h3>
                        </>
                      )}
                      <div style={cardStyles.executionWrap}>
                        <div style={cardStyles.exeHeader}>
                          <div
                            style={{
                              ...cardStyles.displayFlex,
                              ...cardStyles.alignItemsCenter,
                            }}
                          >
                            <div style={{ marginRight: '10px' }}>
                              {port?.isPassed && port?.isOverrided ? (
                                <icons.OverridePass />
                              ) : port?.isPassed && !port?.isOverrided ? (
                                <icons.circleTickDanger />
                              ) : !port?.isPassed && port?.isOverrided ? (
                                <icons.Overridefail />
                              ) : (
                                <icons.circleCrossDanger />
                              )}
                            </div>
                            <h5
                              style={{
                                ...cardStyles.exBgBoxHeaderText,
                                marginBottom: '0',
                              }}
                            >
                              {port.portName}
                            </h5>
                            {port?.isApproved ? (
                              port?.approvalValidTill ? (
                                new Date(port?.approvalValidTill) <
                                new Date() ? (
                                  <span style={cardStyles.approvalInfo}>
                                    Port Approval Expired. Valid Till :{' '}
                                    {formatDate(port?.approvalValidTill)}
                                  </span>
                                ) : (
                                  <span style={cardStyles.approvalInfoApproved}>
                                    Port Approved. Valid Till :{' '}
                                    {formatDate(port?.approvalValidTill)}
                                  </span>
                                )
                              ) : (
                                <span style={cardStyles.approvalInfoApproved}>
                                  Port Approved.
                                </span>
                              )
                            ) : (
                              <span style={cardStyles.approvalInfo}>
                                Port Not Approved.
                              </span>
                            )}
                          </div>
                          <div
                            style={{
                              ...cardStyles.marginLeftAuto,
                              ...cardStyles.displayFlex,
                              ...cardStyles.alignItemsCenter,
                            }}
                          >
                            <div>
                              <span style={cardStyles.exeHeaderRightContent}>
                                Berth Fit ID : {port.berthId}
                              </span>
                            </div>
                            {/* <div style={cardStyles.exeHeaderLastItem}>
                          <span style={cardStyles.exeHeaderRightContent}>
                            Berth Fit Date :{' '}
                            {port.berthFitFinalizedDate
                              ? dateTimeWithTimeZone(
                                  port.berthFitFinalizedDate + 'z'
                                )
                              : dateTimeWithTimeZone(new Date())}
                          </span>
                        </div> */}
                          </div>
                        </div>
                        <div style={cardStyles.exeBody}>
                          <h4 style={cardStyles.exeTextH4}>Terminals</h4>

                          {port.vetRequestBerthFitTerminals?.map(
                            (terminal: any) => {
                              return (
                                <div style={cardStyles.exBgBox}>
                                  <div
                                    style={{
                                      ...cardStyles.exBgBoxPadding,
                                      ...cardStyles.exBorderBottom,
                                    }}
                                  >
                                    <div
                                      style={{
                                        ...cardStyles.displayFlex,
                                        ...cardStyles.alignItemsCenter,
                                        marginBottom: '20px',
                                      }}
                                    >
                                      <div style={{ marginRight: '10px' }}>
                                        {terminal?.isPassed &&
                                        terminal?.isOverrided ? (
                                          <icons.OverridePass />
                                        ) : terminal?.isPassed &&
                                          !terminal?.isOverrided ? (
                                          <icons.circleTickDanger />
                                        ) : !terminal?.isPassed &&
                                          terminal?.isOverrided ? (
                                          <icons.Overridefail />
                                        ) : (
                                          <icons.circleCrossDanger />
                                        )}
                                      </div>
                                      <h5
                                        style={{
                                          ...cardStyles.exBgBoxHeaderText,
                                          marginBottom: '0',
                                        }}
                                      >
                                        {terminal.terminalName}
                                      </h5>
                                      {terminal?.isApproved ? (
                                        terminal?.approvalValidTill ? (
                                          new Date(
                                            terminal?.approvalValidTill
                                          ) < new Date() ? (
                                            <span
                                              style={cardStyles.approvalInfo}
                                            >
                                              Terminal Approval Expired. Valid
                                              Till :{' '}
                                              {formatDate(
                                                terminal?.approvalValidTill
                                              )}
                                            </span>
                                          ) : (
                                            <span
                                              style={
                                                cardStyles.approvalInfoApproved
                                              }
                                            >
                                              Terminal Approved. Valid Till :{' '}
                                              {formatDate(
                                                terminal?.approvalValidTill
                                              )}
                                            </span>
                                          )
                                        ) : (
                                          <span
                                            style={
                                              cardStyles.approvalInfoApproved
                                            }
                                          >
                                            Terminal Approved.
                                          </span>
                                        )
                                      ) : (
                                        <span style={cardStyles.approvalInfo}>
                                          Terminal Not Approved.
                                        </span>
                                      )}
                                    </div>
                                    <div style={cardStyles.displayFlex}>
                                      <div
                                        style={{
                                          ...cardStyles.reportCardColMinWidth20,
                                          ...cardStyles.paddinRight8,
                                        }}
                                      >
                                        <label style={cardStyles.label}>
                                          Comment
                                        </label>
                                        <span style={cardStyles.commentValue}>
                                          {terminal.comment}
                                        </span>
                                      </div>
                                      <div
                                        style={cardStyles.reportCardColWidth30}
                                      >
                                        <label style={cardStyles.label}>
                                          Status
                                        </label>
                                        <span style={getStatusStyle(terminal)}>
                                          {terminal.status}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  {terminal.berths?.map((berth: any) => {
                                    return (
                                      <div
                                        style={{ ...cardStyles.exBgBoxPadding }}
                                      >
                                        <h4 style={cardStyles.exeTextH4}>
                                          Berth
                                        </h4>
                                        <div style={cardStyles.exBoxWhite}>
                                          <div
                                            style={{
                                              ...cardStyles.exBgBoxPadding,
                                              ...cardStyles.exBorderBottom,
                                            }}
                                          >
                                            <div
                                              style={{
                                                ...cardStyles.displayFlex,
                                                ...cardStyles.alignItemsCenter,
                                                marginBottom: '20px',
                                              }}
                                            >
                                              <div
                                                style={{ marginRight: '10px' }}
                                              >
                                                {berth?.isPassed &&
                                                berth?.isOverrided ? (
                                                  <icons.OverridePass />
                                                ) : berth?.isPassed &&
                                                  !berth?.isOverrided ? (
                                                  <icons.circleTickDanger />
                                                ) : !berth?.isPassed &&
                                                  berth?.isOverrided ? (
                                                  <icons.Overridefail />
                                                ) : (
                                                  <icons.circleCrossDanger />
                                                )}
                                              </div>
                                              <h5
                                                style={{
                                                  ...cardStyles.exBgBoxHeaderText,
                                                  marginBottom: '0',
                                                }}
                                              >
                                                {berth.berthName}
                                              </h5>
                                              {berth?.isApprovedBerth ? (
                                                berth?.berthApprovalValidTill ? (
                                                  new Date(
                                                    berth?.berthApprovalValidTill
                                                  ) < new Date() ? (
                                                    <span
                                                      style={
                                                        cardStyles.approvalInfo
                                                      }
                                                    >
                                                      Berth Approval Expired.
                                                      Valid Till :{' '}
                                                      {formatDate(
                                                        berth?.berthApprovalValidTill
                                                      )}
                                                    </span>
                                                  ) : (
                                                    <span
                                                      style={
                                                        cardStyles.approvalInfoApproved
                                                      }
                                                    >
                                                      Berth Approved. Valid Till
                                                      :{' '}
                                                      {formatDate(
                                                        berth?.berthApprovalValidTill
                                                      )}
                                                    </span>
                                                  )
                                                ) : (
                                                  <span
                                                    style={
                                                      cardStyles.approvalInfoApproved
                                                    }
                                                  >
                                                    Berth Approved.
                                                  </span>
                                                )
                                              ) : (
                                                <span
                                                  style={
                                                    cardStyles.approvalInfo
                                                  }
                                                >
                                                  Berth Not Approved.
                                                </span>
                                              )}
                                            </div>
                                            <div style={cardStyles.displayFlex}>
                                              <div
                                                style={{
                                                  ...cardStyles.reportCardColMinWidth20,
                                                  ...cardStyles.paddinRight8,
                                                }}
                                              >
                                                <label style={cardStyles.label}>
                                                  Comment
                                                </label>
                                                <span
                                                  style={
                                                    cardStyles.berthCommentValue
                                                  }
                                                >
                                                  {berth.comment}
                                                </span>
                                              </div>
                                              <div
                                                style={
                                                  cardStyles.reportCardColWidth30
                                                }
                                              >
                                                <label style={cardStyles.label}>
                                                  Status
                                                </label>
                                                <span
                                                  style={getStatusStyle(berth)}
                                                >
                                                  {berth.status}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              display: 'flex',
                                              padding: '15px 15px 0',
                                            }}
                                          >
                                            <div
                                              style={{
                                                ...cardStyles.tableTH,
                                                width: '40%',
                                              }}
                                            >
                                              Parameter Name
                                            </div>
                                            <div
                                              style={{
                                                ...cardStyles.tableTH,
                                                width: '20%',
                                              }}
                                            >
                                              Pass/Fail
                                            </div>
                                            <div
                                              style={{
                                                ...cardStyles.tableTH,
                                                width: '20%',
                                              }}
                                            >
                                              Berth Value
                                            </div>
                                            <div
                                              style={{
                                                ...cardStyles.tableTH,
                                                width: '20%',
                                              }}
                                            >
                                              Ship value
                                            </div>
                                          </div>
                                          {berth.berthDetails?.map(
                                            (berthDetails: any) => {
                                              return (
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    padding: '0 15px',
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      ...cardStyles.tableTH,
                                                      width: '40%',
                                                    }}
                                                  >
                                                    {berthDetails?.isConversionRequired
                                                      ? berthDetails?.berthParameterDisplayName +
                                                        dimensionUnitText()
                                                      : berthDetails?.berthParameterDisplayName}
                                                  </div>
                                                  <div
                                                    style={{
                                                      ...cardStyles.tableTH,
                                                      width: '20%',
                                                    }}
                                                  >
                                                    {berthDetails?.isPassed ? (
                                                      <icons.circleTickDanger />
                                                    ) : (
                                                      <icons.circleCrossDanger />
                                                    )}
                                                  </div>
                                                  <div
                                                    style={{
                                                      ...cardStyles.tableTH,
                                                      width: '20%',
                                                    }}
                                                  >
                                                    {berthDetails?.isConversionRequired
                                                      ? meterToFeetConversion(
                                                          berthDetails?.berthValue
                                                        )
                                                      : berthDetails?.berthValue}
                                                  </div>
                                                  <div
                                                    style={{
                                                      ...cardStyles.tableTH,
                                                      width: '20%',
                                                    }}
                                                  >
                                                    {berthDetails?.isConversionRequired
                                                      ? meterToFeetConversion(
                                                          berthDetails?.shipValue
                                                        )
                                                      : berthDetails?.shipValue}
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                          {/* <div style={cardStyles.exBgBoxPadding}>
                                        <div style={cardStyles.reportBlock}>
                                          <table
                                            cellPadding="0"
                                            cellSpacing="0"
                                            style={cardStyles.table}
                                          >
                                            <thead>
                                              <tr
                                                style={{
                                                  borderCollapse: 'collapse',
                                                }}
                                              >
                                                <th style={cardStyles.tableTH}>
                                                  Parameter Name
                                                </th>
                                                <th style={cardStyles.tableTH}>
                                                  Pass/Fail
                                                </th>
                                                <th style={cardStyles.tableTH}>
                                                  Berth Value
                                                </th>
                                                <th style={cardStyles.tableTH}>
                                                  Ship value
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {berth.berthDetails?.map(
                                                (berthDetails: any) => {
                                                  return (
                                                    <tr
                                                      style={{
                                                        borderCollapse:
                                                          'collapse',
                                                      }}
                                                    >
                                                      <td
                                                        style={
                                                          cardStyles.tableTD
                                                        }
                                                      >
                                                        {
                                                          berthDetails?.berthParameter
                                                        }
                                                      </td>
                                                      <td
                                                        style={
                                                          cardStyles.tableTD
                                                        }
                                                      >
                                                        {berthDetails?.isPassed ? (
                                                          <icons.circleTickDanger />
                                                        ) : (
                                                          <icons.circleCrossDanger />
                                                        )}
                                                      </td>
                                                      <td
                                                        style={
                                                          cardStyles.tableTD
                                                        }
                                                      >
                                                        {
                                                          berthDetails?.berthValue
                                                        }
                                                      </td>
                                                      <td
                                                        style={
                                                          cardStyles.tableTD
                                                        }
                                                      >
                                                        {
                                                          berthDetails?.shipValue
                                                        }
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div> */}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </>
          )}

          <div id="element-to-print">
            {reportDetails?.showBerthFitComments &&
              isAttachPdf &&
              reviewComment && (
                <div>
                  <h3 style={cardStyles.reportSectionHeading}>
                    Berth Fit Comment
                  </h3>
                  <div
                    style={{
                      ...cardStyles.reportCard,
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      minHeight: '50px',
                    }}
                  >
                    {displayComments(reviewComment)}
                  </div>
                </div>
              )}

            {reportDetails?.berthFitReviewerComments &&
              reportDetails?.berthFitReviewerComments?.length > 0 && (
                <div>
                  <h3 style={cardStyles.reportSectionHeading}>
                    Berth Fit Comment
                  </h3>
                  <div
                    style={{
                      ...cardStyles.reportCard,
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      minHeight: '50px',
                    }}
                  >
                    {displayComments(
                      reportDetails?.berthFitReviewerComments[0]?.commentBody
                    )}
                  </div>
                </div>
              )}
            {reportDetails?.disclaimerBasicVM?.marsDisclaimer && (
              <div>
                <h3 style={cardStyles.reportSectionHeading}>Mars Disclaimer</h3>
                <div
                  style={{
                    ...cardStyles.reportCard,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    minHeight: '50px',
                    fontSize: '12px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: getCommentBody(
                      reportDetails?.disclaimerBasicVM?.marsDisclaimer
                    ),
                  }}
                ></div>
              </div>
            )}
            {reportDetails?.disclaimerBasicVM?.requestorDisclaimer && (
              <div>
                <h3 style={cardStyles.reportSectionHeading}>
                  {reportDetails?.vetCompanyName + ' Disclaimer'}
                </h3>
                <div
                  style={{
                    ...cardStyles.reportCard,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    minHeight: '50px',
                    fontSize: '12px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: getCommentBody(
                      reportDetails?.disclaimerBasicVM?.requestorDisclaimer
                    ),
                  }}
                ></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}
