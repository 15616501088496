import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormConfig } from '../../../types/form-builder';
import FormBuilder from '../../common/form-builder/FormBuilder';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { useDimensionsUnit } from '../../../hooks/useDimensionsUnit';
import TerminalAccordion from '../terminal/terminal-accordion/TerminalAccordion';
import CountryList from '../../Country.json';

export default function TerminalForm(props: {
  countriesList: any;
  terminalCode: any;
  fuelsHandled: any;
  terminalId?: any;
}) {
  const { countriesList, terminalCode, fuelsHandled, terminalId } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [YesNo, setYesNo] = useState();
  const { dimensionUnitText } = useDimensionsUnit();
  const getYesNo = async () => {
    await api
      .get({ url: apiConfig.optionValue + 'YesNo' })
      .then((data: any) => {
        setYesNo(data);
      });
  };

  useEffect(() => {
    getYesNo();
  }, []);

  const terminalInfoFormConfig: IFormConfig[] = [
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'terminalName',
      label: t('labels.terminalName'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'terminalCode',
      label: t('labels.terminalCode'),
      control: control,
      fieldType: 'text',
      disabled: true,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'portName',
      label: t('labels.portName'),
      control: control,
      fieldType: 'text',
      disabled: true,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'portCode',
      label: t('labels.portCode'),
      control: control,
      fieldType: 'text',
      disabled: true,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'country',
      label: t('labels.country'),
      control: control,
      fieldType: 'select',
      dataSource: countriesList,
      displayExpr: 'countryName',
      valueExpr: 'countryCode',
      disabled: true,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'chartNumber',
      label: t('labels.terminalChartNumber'),
      control: control,
      fieldType: 'number',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'terminalLatitude',
      label: t('labels.terminalLatitude'),
      control: control,
      fieldType: 'text',
      allowDecimal: true,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'terminalLongitude',
      label: t('labels.terminalLongitude'),
      control: control,
      fieldType: 'text',
      allowDecimal: true,
    },
  ];
  const terminalInfoFormConfig1: IFormConfig[] = [
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'terminalOwner',
      label: t('labels.terminalOwner'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'terminalOperator',
      label: t('labels.terminalOperator'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'operationalStatus',
      label: t('labels.operationalStatus'),
      control: control,
      fieldType: 'radio',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
      fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
      layout: 'horizontal',
      items: YesNo,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueText',
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'operationsCarriedOut',
      label: t('labels.operationsCarriedOut'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'tidalNotes',
      label: t('labels.tidalNotes'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
      fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
      name: 'tidalRestrictions',
      label: t('labels.tidalRestrictions'),
      control: control,
      fieldType: 'radio',
      layout: 'horizontal',
      items: YesNo,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueText',
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'fuelsHandled',
      label: t('labels.fuelsHandled'),
      control: control,
      fieldType: 'multiSelect',
      dataSource: fuelsHandled,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },

    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'shiptoshipNotes',
      label: t('labels.shipToShipNotes'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
      fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
      name: 'shiptoShipAvailable',
      label: t('labels.shipToShipAvailable'),
      control: control,
      fieldType: 'radio',
      layout: 'horizontal',
      items: YesNo,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueText',
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'terminalSize',
      label: t('labels.terminalSize'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'terminalVolumeHandled',
      label: t('labels.terminalVolumeHandled'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
      fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
      name: 'isVesselPumpWater',
      label: t('labels.pumpClearance'),
      control: control,
      fieldType: 'radio',
      layout: 'horizontal',
      items: YesNo,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueText',
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'comments',
      label: t('labels.comments'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'specificBerthingRestrictions',
      label: t('labels.specificBerthingRestrictions'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
      fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
      name: 'emergencyShutdownCapability',
      label: t('labels.emergencyShutdownCapability'),
      control: control,
      fieldType: 'radio',
      layout: 'horizontal',
      items: YesNo,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueText',
    },
    {
      class:
        'col-md-3 col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'slopreceptionNotes',
      label: t('labels.slopReceptionNotes'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-md-3 col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'distanceFromPilotStation',
      label: t('labels.distanceFromPilotStation'),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
      fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
      name: 'freshWater',
      label: t('labels.freshWater'),
      control: control,
      fieldType: 'radio',
      layout: 'horizontal',
      items: YesNo,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueText',
    },
    // {
    //   class:
    //     'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
    //   name: 'terminalAddress',
    //   label: t('labels.terminalAddress'),
    //   control: control,
    //   fieldType: 'text',
    // },

    // {
    //   class:
    //     'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
    //   name: 'minimumHeightManifoldAWBerth',
    //   label: t('labels.minHeightmanifoldAwBerth') + dimensionUnitText(),
    //   control: control,
    //   fieldType: 'text',
    // },
  ];
  const terminalInfoFormConfig2: IFormConfig[] = [
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'lastStructuralSurvey',
      label: t('labels.lastStructuralSurvey'),
      control: control,
      fieldType: 'date',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'typeOfBottom',
      label: t('labels.typeOfBottom'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
      fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
      name: 'industryStandardAssessmentCarriedOut',
      label: t('labels.industryCarriedOut'),
      control: control,
      fieldType: 'radio',
      layout: 'horizontal',
      items: YesNo,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueText',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
      fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
      name: 'vapourRecoveryRequirements',
      label: t('labels.vapourRecoveryRequirements'),
      control: control,
      fieldType: 'radio',
      layout: 'horizontal',
      items: YesNo,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueText',
    },
  ];

  const formConfig2: IFormConfig[] = [
    {
      name: 'terminalAddress',
      label: 'Address',
      control: control,
      fieldType: 'text',
      maxLength: 50,
      class: 'col m-l-col__expand m-l-custom-sixteen-space-col',
    },
    {
      name: 'postalCode',
      label: '	Postal Code',
      control: control,
      fieldType: 'text',
      maxLength: 50,
      class: 'col m-l-col__expand m-l-custom-sixteen-space-col',
    },
    {
      name: 'email',
      label: 'Email',
      control: control,
      fieldType: 'text',
      maxLength: 50,
      class: 'col m-l-col__expand m-l-custom-sixteen-space-col',
      rules: [
        {
          type: 'email',
          message: t('errors.inCorrectEmailFormat'),
        },
      ],
    },
    {
      class: 'col m-l-col__expand m-l-custom-sixteen-space-col',
      name: 'phoneNumber',
      label: t('labels.phoneNumber'),
      control: control,
      fieldType: 'text',
    },
    {
      name: 'website',
      label: 'Website',
      control: control,
      fieldType: 'text',
      maxLength: 50,
      class: 'col m-l-col__expand m-l-custom-sixteen-space-col',
    },
  ];
  return (
    <div className="m-l-form-panel-body-block">
      <div className="row">
        <FormBuilder formConfig={terminalInfoFormConfig} />
        <div className="m-l-custom-sixteen-space-col">
          <div className="row m-l-custom-sixteen-space-row">
            <div className="col-lg-9">
              <div className="row">
                <FormBuilder formConfig={terminalInfoFormConfig1} />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="m-l-form__fieldset-terminal">
                <span className="m-l-form__legend">
                  {t('labels.terminalAddress')}
                </span>
                <FormBuilder formConfig={formConfig2} />
              </div>
            </div>
            <FormBuilder formConfig={terminalInfoFormConfig2} />
          </div>
        </div>
        <TerminalAccordion
          terminalCode={terminalCode}
          terminalId={terminalId}
        />
      </div>
    </div>
  );
}
