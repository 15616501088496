export enum Entity {
  Ship = 'Ship',
  Incident = 'Events',
  PSC = 'PSCInspection',
  IncidentHistory = 'IncidentHistory',
  ManagementHistory = 'ManagementHistory',
  Port = 'Ports',
  ShipVetRelatedInfos = 'ShipVetRelatedInfos',
  ShipSireInfos = 'ShipSireInfos',
  ShipCapRatings = 'ShipCapRatings',
  VetRequests = 'VetRequests',
  Management = 'Management',
}
