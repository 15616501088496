import Button from 'devextreme-react/button';
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IVesselClassificationForm } from '../../../../types/vessel-classification';
import { IVesselClassificationEdit } from '../../../../types/vessel-classification-edit';
import VesselFormClassificationl from './vessel-classification-form/VesselClassificationForm';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import toast from '../../../../utils/toast';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../../types/dialog';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import { isTabDirtyContext } from '../../../../pages/vessel-info/VesselInformation';

export default function VesselClassificationEdit(props: {
  setIsClassificationEdit: any;
  vesselclassificationList: any;
  classSociety: any;
  vesselStatus: any;
  getClassifications: any;
  survey: any;
  capRating: any;
  imoNumber?: string;
}) {
  const {
    setIsClassificationEdit,
    vesselclassificationList,
    classSociety,
    getClassifications,
    vesselStatus,
    survey,
    capRating,
    imoNumber,
  } = props;
  const { t } = useTranslation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isconfirmBackDialog, setIsConfirmBackDialog] = useState(false);
  const { apiDateFormat } = useDateFormat();
  const { setIsLoading } = useLoaderContext();
  const setTabDirtyFlag = useContext(isTabDirtyContext);
  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      onValueSubmit();
      setIsConfirmModalOpen(false);
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };
  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setIsClassificationEdit(false);
      setIsConfirmBackDialog(false);
      setTabDirtyFlag.setState(
        setTabDirtyFlag.state == 0
          ? setTabDirtyFlag.state
          : setTabDirtyFlag.state - 1
      );
    },
    handleClose: () => {
      setIsConfirmBackDialog(false);
    },
  };
  const defaultVesselFormValues: IVesselClassificationForm = {
    classsocietycode: '',
    vesselstatus: '',
    classificationdate: '',
    servicerestriction: '',
    iceclass: '',
    surveyType: '',
    specialsurveydate: '',
    annualSurveyDate: '',
    nextAnnualSurveyDate: '',
    survey2: '',
    survey2date: '',
    nextDrydockDate: '',
    classnotation: '',
    classnarrative: '',
    historicclassstatus: '',
  };

  const vesselClassificationForm = useForm({
    defaultValues: defaultVesselFormValues,
  });

  const onHandleSave = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      setIsConfirmModalOpen(true);
    }
  };

  const { isDirty } = vesselClassificationForm.formState;

  const handleBackClick = () => {
    isDirty ? setIsConfirmBackDialog(true) : setIsClassificationEdit(false);
  };

  const onValueSubmit = () => {
    const listClassificationFormValue: IVesselClassificationForm =
      vesselClassificationForm.getValues();
    const roleJsonEdit: IVesselClassificationEdit = {
      imoNumber: imoNumber,
      classificationSocietyCode: listClassificationFormValue.classsocietycode,
      classStatusCode: listClassificationFormValue.vesselstatus,
      classNotation: listClassificationFormValue.classnotation,
      classNarrative: listClassificationFormValue.classnarrative,
      iceClass: listClassificationFormValue.iceclass,
      classificationDate: apiDateFormat(
        listClassificationFormValue.classificationdate
      ),
      specialSurveyDate: apiDateFormat(
        listClassificationFormValue.specialsurveydate
      ),
      serviceRestriction: listClassificationFormValue.servicerestriction,
      surveyType: listClassificationFormValue.surveyType,
      annualSurveyDate: apiDateFormat(
        listClassificationFormValue.annualSurveyDate
      ),
      nextAnnualSurveyDate: apiDateFormat(
        listClassificationFormValue.nextAnnualSurveyDate
      ),
      survey2: listClassificationFormValue.survey2,
      survey2Date: apiDateFormat(listClassificationFormValue.survey2date),
      nextDrydockDate: apiDateFormat(
        listClassificationFormValue.nextDrydockDate
      ),
      historicClassStatusCode: listClassificationFormValue.historicclassstatus,
      imoDisplayName: vesselclassificationList.imoDisplayName,
    };
    api
      .put(
        { url: apiConfig.vesselClassificationTab, data: roleJsonEdit },
        setIsLoading
      )
      .then((response) => {
        if (response == true) {
          getClassifications();
          setIsClassificationEdit(false);
          setTabDirtyFlag.setState(
            setTabDirtyFlag.state == 0
              ? setTabDirtyFlag.state
              : setTabDirtyFlag.state - 1
          );
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
        }
      });
  };
  useEffect(() => {
    vesselClassificationForm.reset();
    if (vesselclassificationList) {
      vesselClassificationForm.reset(
        {
          classsocietycode: vesselclassificationList.classSocietyCode,
          vesselstatus: vesselclassificationList.classStatusCode,
          classificationdate: vesselclassificationList.classificationDate,
          servicerestriction: vesselclassificationList.serviceRestriction,
          iceclass: vesselclassificationList.iceClass,
          surveyType: vesselclassificationList.surveyType,
          specialsurveydate: vesselclassificationList.specialSurveyDate,
          annualSurveyDate: vesselclassificationList.annualSurveyDate,
          nextAnnualSurveyDate: vesselclassificationList.nextAnnualSurveyDate,
          survey2: vesselclassificationList.survey2,
          survey2date: vesselclassificationList.survey2Date,
          nextDrydockDate: vesselclassificationList.nextDrydockDate,
          classnotation: vesselclassificationList.classNotation,
          classnarrative: vesselclassificationList.classNarrative,
          historicclassstatus: vesselclassificationList.historicStatusCode,
        },
        { keepDirty: true }
      );
    }
  }, [vesselclassificationList]);

  useEffect(() => {
    if (isDirty) {
      setTabDirtyFlag.setState(setTabDirtyFlag.state + 1);
    }
  }, [isDirty]);

  return (
    <div className="m-l-form-panel-block">
      <div className="m-l-tbl-square-wrapper m-l-tbl-square-wrapper--edit-mode">
        <div className="row m-l-custom-eight-space-row">
          <FormProvider {...vesselClassificationForm}>
            <VesselFormClassificationl
              classSociety={classSociety}
              vesselStatus={vesselStatus}
              survey={survey}
            />
          </FormProvider>
        </div>
        <div className="m-l-panel-right-btn u-pb0">
          <Button
            className="app-c-btn app-c-btn--secondary min-btn-width"
            onClick={handleBackClick}
          >
            {t('labels.cancel')}
          </Button>
          <Button
            onClick={onHandleSave}
            className="app-c-btn app-c-btn--primary min-btn-width"
          >
            {t('labels.save')}
          </Button>
          <ConfirmDialog
            dialogConfig={confirmSaveDialogConfig}
            isOpen={isConfirmModalOpen}
          />
          <ConfirmDialog
            dialogConfig={confirmBackDialogConfig}
            isOpen={isconfirmBackDialog}
          />
        </div>
      </div>
    </div>
  );
}
