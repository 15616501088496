import React, { useEffect, useMemo, useState } from 'react';
import icons from '../icons/icons';
import { Button } from 'devextreme-react/button';
// import imageURL from '../../../assets/images';
import GlobalVessalSearch from '../../global-search/GlobalVessalSearch';
import { useNavigate } from 'react-router-dom';
import DropDownButton from 'devextreme-react/drop-down-button';
import ViewNotifications from '../../notifications/ViewNotifications';
import ConfirmDialog from '../confirm/ConfirmDialog';
import { IDialogConfig } from '../../../types/dialog';
import { useAuth } from '../../../contexts/AuthProvider';
import SwitchBU from '../../switch-bu/SwitchBu';
import ViewChangePassword from '../../change-password/ChangePassword';
import { Restricted, usePermission } from '../../../contexts/PermissionContext';
// import { getAdditionalInfo } from '../../../utils/jwt-decode';
import UserProfile from '../../user-profile/UserProfile';
import { removeAllCacheData } from '../../../services/master-data-service';
import { getRememberMe } from '../../../utils/jwt-decode';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';

const dropDownOptions = {
  width: 188,
};

export default function Header(props: { sendToParent: any }) {
  const navigate = useNavigate();
  const { currentlyLoggedInBu, logout, usersName } = useAuth();
  const [isChangePassword, setIsChangePassword] = useState(false);
  const storage: Storage =
    getRememberMe() === 'true' ? localStorage : sessionStorage;
  const tokenFlag = storage?.getItem('update_password');
  const [userProfile, setUserProfile] = useState(false);
  const [refreshConfig, setRefreshConfig] = useState<any>([]);
  const { findFeature, featureList } = usePermission();

  const onClickAdvancedSearch = () => {
    navigate('advanced-search', { state: { collapse: false } });
  };

  const actions = useMemo(() => {
    return [
      {
        id: 1,
        value: 'Profile',
        visible: findFeature('UserProfile.ViewUserProfile'),
      },
      {
        id: 2,
        value: 'Change Password ',
        visible: findFeature('UserProfile.ChangePassword'),
      },
      { id: 3, value: 'Log out' },
    ];
  }, [featureList]);

  /////////////////////switch bu  modal/////////////////////

  const [isVisible, setIsVisible] = useState(false);

  const clickButton = () => {
    const bu: any = storage.getItem('buList');
    const buLength = JSON.parse(bu).length;
    buLength > 1 ? setIsVisible(true) : setIsVisible(false);
  };
  const handleCancelClick = () => {
    setIsVisible(false);
  };
  /////////////////////password change modal/////////////////////
  const logAction = (e: any) => {
    if (e.itemData.id == 1) {
      setUserProfile(!userProfile);
    }
    if (e.itemData.id == 2) {
      clickButtonChangePWD();
    } else if (e.itemData.id == 3) {
      clickButtonLogout();
    }
  };

  const clickButtonChangePWD = () => {
    setIsChangePassword(!isChangePassword);
  };

  /////////////////Logout/////////////
  const [confirmLogoutDialog, setConfirmLogoutDialog] = useState(false);
  const clickButtonLogout = () => {
    setConfirmLogoutDialog(true);
  };
  const confirmLogoutDialogConfig: IDialogConfig = {
    id: 'confirmBackEditList',
    content: 'Logout ?',
    handleSubmit: () => {
      setConfirmLogoutDialog(false);
      sessionStorage.clear();
      localStorage.clear();
      removeAllCacheData();
      logout();
      navigate('/');
      localStorage.setItem('CREDENTIALS_FLUSH', Date.now().toString());
      localStorage.removeItem('CREDENTIALS_FLUSH');
    },
    handleClose: () => {
      setConfirmLogoutDialog(false);
    },
  };

  /////////////////////toggle sidebar//////////////////////
  const [isVisibleSidebar, setIsVisibleSidebar] = useState(false);
  const toggleSidebar = () => {
    props.sendToParent(!isVisibleSidebar);
    setIsVisibleSidebar(!isVisibleSidebar);
  };

  const getRefreshConfig = async () => {
    await api
      .get({
        url: apiConfig.configurations,
        params: {
          settingArea: 'Alert',
        },
      })
      .then((response: any) => {
        setRefreshConfig(response);
      });
  };

  useEffect(() => {
    getRefreshConfig();
  }, []);

  useEffect(() => {
    if (tokenFlag == 'true') {
      setIsChangePassword(true);
    }
  }, []);

  return (
    <header>
      <div className="m-c-header-section">
        <div className="m-l-header-left">
          <Restricted permission="Ship.GlobalSearch">
            <GlobalVessalSearch />
          </Restricted>
          <Restricted permission="Ship.AdvancedSearch">
            <Button
              className="m-l-header-advance-search-block"
              onClick={onClickAdvancedSearch}
            >
              <icons.Slider />
              <a className="dx-field-label m-l-header-advance-search-text">
                Advanced Search
              </a>
            </Button>
          </Restricted>
        </div>
        <div className="m-l-header-right">
          <div className="m-l-header-right-menu-block m-l-tgl-icon-wrap">
            {/* <Button className="app-c-btn app-c-btn--secondary sidebar-arrow-btn-sm"> */}
            <Button className="sidebar-arrow-btn-sm" onClick={toggleSidebar}>
              <div
                className={
                  'm-c-icon-inside-btn ' + (isVisibleSidebar ? 'u-hide' : '')
                }
              >
                <icons.Menu />
              </div>
              <div
                className={
                  'm-c-icon-inside-btn ' + (isVisibleSidebar ? '' : 'u-hide')
                }
              >
                <icons.IconCross />
              </div>
            </Button>
          </div>
          <div className="m-l-header-right-admin-block">
            <div className="m-l-header-item-btn-wrapper">
              <Button
                className="m-l-bgsm-btn"
                onClick={clickButton}
                text={currentlyLoggedInBu}
              >
                {/* {currentlyLoggedInBu} */}
              </Button>
            </div>
            <div className="m-l-user-name-block">
              {/* <div className="m-l-header-welcome-text">Welcome</div> */}
              <div className="m-l-header-user-name">{usersName}</div>
            </div>
            <ViewNotifications refreshConfig={refreshConfig} />
            <div className="m-l-profile-block">
              {/* <img
                src={imageURL.avatar}
                className="img-responsive"
                alt="Logo"
              /> */}
              <icons.Profile />
              <DropDownButton
                items={actions}
                keyExpr="id"
                displayExpr="value"
                dropDownOptions={dropDownOptions}
                onItemClick={logAction}
                className="m-l-user-drp-dwn"
              />
            </div>
          </div>
        </div>
      </div>

      {/* ----------------------User Profile ----------------------------- */}
      {userProfile && (
        <UserProfile
          userProfile={userProfile}
          setUserProfile={setUserProfile}
        />
      )}

      {/* ------------------Switch bu modal ---------------------------*/}
      <SwitchBU
        isVisible={isVisible}
        handleCancelClick={handleCancelClick}
        currentlyLoggedInBu={currentlyLoggedInBu}
        setIsVisible={(flag: boolean) => {
          setIsVisible(flag);
        }}
      />
      {/* ----------------- change password modal --------------------------------*/}
      {isChangePassword && (
        <ViewChangePassword
          isChangePassword={isChangePassword}
          setIsChangePassword={setIsChangePassword}
        />
      )}
      {/* ----------------- password change success modal --------------------------------*/}
      <ConfirmDialog
        dialogConfig={confirmLogoutDialogConfig}
        isOpen={confirmLogoutDialog}
      />
    </header>
  );
}
