import TextArea from 'devextreme-react/text-area';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormConfig } from '../../../types/form-builder';
import { IOptionvalue } from '../../../types/master-data';
import FormBuilder from '../../common/form-builder/FormBuilder';
import RFIDetails from '../rfi-details/RFIDetails';

export default function RuleGeneralFileds(props: {
  incoTerms: IOptionvalue[];
  vesselTypes: IOptionvalue[];
  isRFIToClientTemplateDefined?: boolean;
  isRFIToVesselOwnerTemplateDefined?: boolean;
  getRuleById?: any;
  isRFIEditable: boolean;
  ruleId?: string;
  enableRfi?: any;
  buId?: any;
}) {
  const {
    incoTerms,
    vesselTypes,
    isRFIToClientTemplateDefined,
    isRFIToVesselOwnerTemplateDefined,
    getRuleById,
    isRFIEditable,
    ruleId,
    enableRfi,
    buId,
  } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();

  const generalFormConfig: IFormConfig[] = [
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'ruleName',
      label: t('labels.ruleName'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'applicableVesselType',
      label: t('labels.applicableVesselType'),
      control: control,
      fieldType: 'multiSelect',
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      dataSource: vesselTypes,
      isRequired: true,
      showClearButton: true,
      maxDisplayedTags: 2,
      showMultiTagOnly: false,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'applicableIncoTerms',
      label: t('labels.applicableIncoTerms'),
      control: control,
      fieldType: 'multiSelect',
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      dataSource: incoTerms,
      isRequired: true,
      showClearButton: true,
      maxDisplayedTags: 2,
      showMultiTagOnly: false,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
  ];

  const ruleDetailsValueChanged = (e: any, field: any) => {
    field.onChange(e.value);
  };
  return (
    <>
      <div className="row m-l-custom-sixteen-space-row">
        <FormBuilder formConfig={generalFormConfig} />
      </div>
      <div className="m-l-rule-details-row m-l-rl-flx-rw">
        <div className="m-l-rl-flx-rw-left">
          <div className="m-c-form-group">
            <label className="m-l-input-label">{t('labels.ruleDetails')}</label>
            <Controller
              name="ruleDetails"
              control={control}
              render={({ field }) => (
                <TextArea
                  className="m-c-input-control m-c-text-area-control m-c-text-area-control-h-138"
                  inputAttr={{ 'data-testid': 'ruleDetails' }}
                  name="ruleDetails"
                  value={field.value}
                  onValueChanged={(e) => {
                    ruleDetailsValueChanged(e, field);
                  }}
                  validationMessageMode="always"
                />
              )}
            />
          </div>
        </div>
        <RFIDetails
          buId={buId}
          ruleId={ruleId}
          isRFIEditable={isRFIEditable}
          isRFIToClientTemplateDefined={isRFIToClientTemplateDefined}
          isRFIToVesselOwnerTemplateDefined={isRFIToVesselOwnerTemplateDefined}
          getRuleById={getRuleById}
          enableRfi={enableRfi}
        />
      </div>
    </>
  );
}
