import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import React, { useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IGridConfig } from '../../../types/grid';
import Grid from '../../common/grid/Grid';
import Country from '../../../components/Country.json';

function AdvancedSearchResultGrid(props: {
  advanceSearchResultList: any;
  navigate: any;
  gridRef?: any;
  downloadgridRef?: any;
  setTotalCount: any;
  setSearchResult?: any;
}) {
  const {
    advanceSearchResultList,
    navigate,
    gridRef,
    downloadgridRef,
    setTotalCount,
    setSearchResult,
  } = props;
  const { t } = useTranslation();
  const [gridInstance, setGridInstance] = useState<any>();

  const onRowClick = (e: any) => {
    navigate(e.key.imo);
  };

  const onGridInitialized = (e: any) => {
    setGridInstance(e.component);
  };

  useImperativeHandle(downloadgridRef, () => ({
    exportAdvanceSearchGrid,
  }));

  const exportAdvanceSearchGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: gridInstance,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'AdvancedSearch.xlsx'
        );
      });
    });
  };

  const onContentReady = () => {
    if (gridRef?.current?.instance?.totalCount() > 0) {
      setTotalCount(gridRef?.current?.instance?.totalCount());
    } else {
      setTotalCount(0);
    }
  };

  const onDataErrorOccurred = () => {
    setSearchResult([]);
  };

  const gridConfig: IGridConfig = {
    class: 'm-c-grid m-l-grid-with--top-border',
    testId: 'advancedSearchResultGrid',
    dataSource: advanceSearchResultList,
    remoteOperations: true,
    contentReady: onContentReady,
    dataErrorOccured: onDataErrorOccurred,
    disableLoadPanel: true,
    defaultColumns: [
      {
        caption: t('labels.imoNumber'),
        dataField: 'imoDisplayName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.vesselName'),
        dataField: 'vesselName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.grossTonnage'),
        dataField: 'grossTonnage',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.vesselType'),
        dataField: 'vesselType',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.yearOfBuild'),
        dataField: 'yearOfBuild',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.flag'),
        dataField: 'flag',
        dataType: 'string',
        minWidth: 100,
        cellTemplate: 'cellTemplate',
      },
    ],
    // hidePagination: true,
    noDataText: t('labels.companyVesselNoDataText'),
    showHeader: true,
    showExport: false,
    // excelFileName: 'AdvancedSearch.xlsx',
    height: 530,
    ref: gridRef,
    rowClick: onRowClick,
    initialized: onGridInitialized,
  };

  return <Grid gridConfig={gridConfig} cellTemplate={flagCellTemplate} />;
}

const flagCellTemplate = (data: any) => {
  const filteredCountryList = Country.find(
    (country) => country.iso_3 == data?.data?.iso3
  );
  return (
    <div>
      <img
        style={{ width: '33px', borderRadius: '5px' }}
        src={filteredCountryList?.flag}
        alt="flag"
      />
      <span className="flag-name">{data.value}</span>
    </div>
  );
};

export default AdvancedSearchResultGrid;
