import Button from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import SelectBox from 'devextreme-react/select-box';
import TabPanel from 'devextreme-react/tab-panel';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Grid from '../../../../components/common/grid/Grid';
import { IGridConfig } from '../../../../types/grid';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { getAPIRoute } from '../../../../utils/api-route';
import { IOptionvalue } from '../../../../types/master-data';
import {
  IBerthFitEdit,
  IBerthFitEditInfo,
} from '../../../../types/berthfit-config-edit';
import toast from '../../../../utils/toast';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../../components/common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../../types/dialog';
import { useLocation } from 'react-router-dom';
import {
  Restricted,
  usePermission,
} from '../../../../contexts/PermissionContext';
import CheckBox from 'devextreme-react/check-box';
import { useLoaderContext } from '../../../../contexts/LoaderContext';

export default function ViewBuBerthFit(props: {
  buId?: any;
  setIsBerthFitDirty?: any;
  isBerthFitDirty?: any;
  showConfirmSave?: any;
  setShowConfirmSave?: any;
}) {
  //Grid height calculation
  const { findFeature } = usePermission();
  const { t } = useTranslation();
  const { buId, setIsBerthFitDirty, showConfirmSave, setShowConfirmSave } =
    props;
  const sepRef: any = useRef();
  const [height, setHeight] = useState('100%');
  const [configList, setConfigList] = useState<any>([]);
  const [infoList, setInfoList] = useState<any>([]);
  const [infoTypeDropdown, setInfoTypeDropdown] = useState<any>([]);
  const [configList2, setConfigList2] = useState<any>(configList);
  const [infoList2, setInfoList2] = useState<any>(infoList);
  const [infoTypeState, setInfoTypeState] = useState<any>([]);
  const [tabSelected, setTabSelected] = useState<any>('Parameters');
  let infoType: any;
  const tabRef = useRef<TabPanel>(null);
  const filteredNotification: any = [];
  const [infoTypeFilter, setInfoTypeFilter] = useState<any>([]);
  const [isTabDirty, setIsTabDirty] = useState(false);
  const [showTabChangeConfirm, setShowTabChangeConfirm] = useState(false);
  const [parameterCheck, setParameterCheck] = useState(false);
  const [informationCheck, setInformationCheck] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const { setIsLoading } = useLoaderContext();
  function logIt() {
    const offsetTop = sepRef.current.getBoundingClientRect().top;
    const h = window.innerHeight - offsetTop - 17 + 'px';
    setHeight(h);
    // document.documentElement.style.setProperty('--height-surface', h);
  }

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmChanges'),
    handleSubmit: () => {
      editConfig();
      if (showConfirmSave) {
        setShowConfirmSave(false);
        setIsTabDirty(false);
      }
      setShowSave(false);
      setIsTabDirty(false);
    },
    handleClose: () => {
      if (showConfirmSave) {
        setShowConfirmSave(false);
      }
      setShowSave(false);
    },
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);
    if (windowDimenion.winWidth > 767) {
      window.addEventListener('resize', logIt);
      window.addEventListener('load', logIt);
    }
    return () => {
      window.removeEventListener('resize', detectSize);
      window.removeEventListener('resize', logIt);
      window.removeEventListener('load', logIt);
    };
  }, [windowDimenion]);

  //tab
  const tabs = useMemo(
    () => [
      {
        title: t('labels.parameters'),
        template: 'buInfo',
        visible: findFeature(
          buId
            ? 'BUBerthFit.ViewBerthFitParameters'
            : 'GlobalBerthFit.ViewBerthFitParameters'
        ),
      },
      {
        title: t('labels.information'),
        template: 'rules',
        visible: findFeature(
          buId
            ? 'BUBerthFit.ViewBerthFitInformation'
            : 'GlobalBerthFit.ViewBerthFitInformation'
        ),
      },
    ],
    []
  );

  const tabChangeConfirm: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmTabChange'),
    handleSubmit: () => {
      setShowTabChangeConfirm(false);
    },
    handleClose: () => {
      setShowTabChangeConfirm(false);
    },
  };

  const [tabChangeConfrimConfig, setTabChangeConfirmConfig] =
    useState(tabChangeConfirm);

  const getInfoTypes = async () => {
    await api
      .get({
        url: apiConfig.berthFitInfoTypeDropdown,
      })
      .then((res: IOptionvalue[]) => {
        setInfoTypeDropdown(res);
        filteredNotification.push(res[1]);
        filteredNotification.push(res[2]);
        filteredNotification.push(res[0]);
      });
    setInfoTypeFilter(filteredNotification);
  };

  const onClickInfoType = (e: any) => {
    if (isTabDirty) {
      const config: IDialogConfig = {
        handleSubmit: () => {
          infoType = e.value;
          setInfoTypeState(e.previousValue);
          getInfoList(e.previousValue);
          setShowTabChangeConfirm(false);
          setIsTabDirty(false);
        },
        handleClose: () => {
          setInfoTypeState(e.value);
          setShowTabChangeConfirm(false);
        },
      };
      setTabChangeConfirmConfig({ ...tabChangeConfirm, ...config });
      setShowTabChangeConfirm(true);
    } else {
      infoType = e.value;
      setInfoTypeState(e.value);
      getInfoList(infoType);
    }
  };

  const cellPrepared = (e: any) => {
    if (e.rowType === 'data' && e.column.dataField === 'status') {
      e.cellElement.className =
        e.data.status === 2 ? 'status-active' : 'status-pending';
    }
  };

  const onTabSwitch = async (e: any, data: any) => {
    setIsTabDirty(true);
    if (buId) {
      setIsBerthFitDirty(true);
    }
    configList2.forEach((item: any) => {
      if (item.berthFitConfigId === data.berthFitConfigId) {
        item.isActive = e.value;
      }
    });
    getParameterCheckVal(configList2);
    infoList2.forEach((item: any) => {
      if (item.berthFitInfoId === data.berthFitInfoId) {
        item.isActive = e.value;
      }
    });
    getInformationCheckVal(infoList2);
  };

  const editConfig = async () => {
    const roleJsonEdit: IBerthFitEdit = {
      bUId: buId,
      berthFitConfig: configList2,
    };
    const infoJsonEdit: IBerthFitEditInfo = {
      bUId: buId,
      berthFitInfo: infoList2,
      infoType: infoTypeState,
    };
    if (tabSelected === 'Parameters') {
      await api
        .put(
          { url: apiConfig.berthFitParamListGlobal, data: roleJsonEdit },
          setIsLoading
        )
        .then((res) => {
          if (res) {
            toast.success({
              title: t('toast.changesSavedSuccessfully'),
            });
          }
          getConfigList();
        });
    } else {
      await api
        .put(
          { url: apiConfig.berthFitInfoGlobal, data: infoJsonEdit },
          setIsLoading
        )
        .then((res) => {
          if (res) {
            toast.success({
              title: t('toast.changesSavedSuccessfully'),
            });
          }
          getInfoList(infoTypeState);
        });
    }
  };

  const getConfigList = async () => {
    if (buId) {
      await api
        .get({ url: getAPIRoute('berthFitParamList', [buId]) })
        .then((res) => {
          setConfigList(res);
          if (!(res?.length > 0)) {
            getConfigList2();
          }
        });
    } else {
      getConfigList2();
    }
  };

  const getConfigList2 = async () => {
    await api.get({ url: apiConfig.berthFitParamListGlobal }).then((res) => {
      setConfigList(res);
    });
  };

  const getInfoList = async (infoType2: any) => {
    if (buId) {
      await api
        .get({ url: getAPIRoute('berthFitInfo', [infoType2, buId]) })
        .then((res) => {
          setInfoList(res);
          if (!(res?.length > 0)) {
            getInfoList2(infoType2);
          }
        });
    } else {
      getInfoList2(infoType2);
    }
  };

  const getInfoList2 = async (infoType2: any) => {
    await api
      .get({ url: apiConfig.berthFitInfoGlobal + infoType2 })
      .then((res) => {
        setInfoList(res);
      });
  };

  const onTabChange = (e: any) => {
    if (e.removedItems[0].title === 'Parameters' && isTabDirty) {
      const config: IDialogConfig = {
        handleSubmit: () => {
          tabRef.current?.instance.option('selectedIndex', 1);
          setShowTabChangeConfirm(false);
          setIsTabDirty(false);
        },
        handleClose: () => {
          tabRef.current?.instance.option('selectedIndex', 0);
          setShowTabChangeConfirm(false);
        },
      };
      setTabChangeConfirmConfig({ ...tabChangeConfirm, ...config });
      setShowTabChangeConfirm(true);
    } else if (e.removedItems[0].title === 'Information' && isTabDirty) {
      const config: IDialogConfig = {
        handleSubmit: () => {
          tabRef.current?.instance.option('selectedIndex', 0);
          setShowTabChangeConfirm(false);
          setIsTabDirty(false);
        },
        handleClose: () => {
          tabRef.current?.instance.option('selectedIndex', 1);
          setShowTabChangeConfirm(false);
        },
      };
      setTabChangeConfirmConfig({ ...tabChangeConfirm, ...config });
      setShowTabChangeConfirm(true);
    }
    if (e.addedItems[0]?.template === 'buInfo') {
      setTabSelected('Parameters');
    } else {
      setTabSelected('Information');
    }
  };

  const gridConfig: IGridConfig = useMemo(() => {
    return {
      dataSource: configList,
      cellPrepared: cellPrepared,
      defaultColumns: [
        {
          caption: t('labels.parameterName'),
          dataField: 'berthFitParameterText',
          dataType: 'string',
          minWidth: 166,
        },
        {
          caption: t('labels.verify'),
          headerCellTemplate: 'headerTemplate',
          dataField: 'active',
          dataType: 'string',
          minWidth: 166,
          cellTemplate: 'statusTemplate',
          allowFiltering: false,
        },
      ],
      // rowClick: onRowClick,
      hidePagination: false,
      showSelection: false,
    };
  }, [configList2]);

  const gridConfig2: IGridConfig = useMemo(() => {
    return {
      dataSource: infoList,
      cellPrepared: cellPrepared,
      defaultColumns: [
        {
          caption: t('labels.parameterName'),
          dataField: 'berthFitParameterText',
          dataType: 'string',
          minWidth: 166,
        },
        {
          caption: t('labels.display'),
          headerCellTemplate: 'headerTemplate',
          dataField: 'active',
          dataType: 'string',
          minWidth: 166,
          cellTemplate: 'statusTemplate',
          allowFiltering: false,
        },
      ],
      // rowClick: onRowClick,
      hidePagination: false,
      showSelection: false,
    };
  }, [infoList2]);
  //form

  const [showForm, setShowForm] = useState(false);
  const actionTemplate = () => {
    return (
      <Button id="cloneButton" className="app-c-btn app-c-btn--secondary">
        clone
      </Button>
    );
  };

  const onParameterCheck = (e: any) => {
    setParameterCheck(true);
    configList2.forEach((item: any) => {
      item.isActive = e.value;
    });
    setConfigList2(configList2);
  };

  const getParameterCheckVal = (data: any) => {
    const index = data.findIndex((item: any) => item.isActive == false);
    if (index >= 0) {
      setParameterCheck(false);
    } else {
      setParameterCheck(true);
    }
  };
  const getInformationCheckVal = (data: any) => {
    data.forEach((item: any) => {
      if (!item.isActive) {
        setInformationCheck(false);
      }
    });
  };

  const onInformationCheck = (e: any) => {
    setInformationCheck(true);
    infoList2.forEach((item: any) => {
      item.isActive = e.value;
    });
  };

  const actionHeaderTemplateParameter = () => {
    return (
      <div className="m-c-form-group m-l-checkbox-in-grid-header u-mb0">
        <label className="m-l-input-label">{t('labels.verify')}</label>
        <div className="m-c-checkbox m-c-checkbox-dark">
          <CheckBox
            defaultValue={parameterCheck}
            onValueChanged={(e) => onParameterCheck(e)}
          />
        </div>
      </div>
    );
  };

  const actionHeaderTemplateInformation = () => {
    return (
      <div className="m-c-form-group m-l-checkbox-in-grid-header u-mb0">
        <label className="m-l-input-label">{t('labels.display')}</label>
        <div className="m-c-checkbox m-c-checkbox-dark">
          <CheckBox
            defaultValue={informationCheck}
            onValueChanged={(e) => onInformationCheck(e)}
          />
        </div>
      </div>
    );
  };

  const onClickSave = () => {
    setShowSave(true);
  };

  function statusTemplate(data: any) {
    return (
      <div className="m-l-field-wrap-checkbox">
        <div className="m-c-checkbox m-c-checkbox-dark">
          <CheckBox
            defaultValue={data.data.isActive}
            onValueChanged={(e) => onTabSwitch(e, data.data)}
          />
        </div>
      </div>
    );
  }

  useEffect(() => {
    getConfigList();
    getInfoTypes();
  }, []);

  useEffect(() => {
    if (configList) {
      setConfigList2(configList);
      getParameterCheckVal(configList);
    }
  }, [configList]);

  useEffect(() => {
    if (infoList) {
      setInfoList2(infoList);
      getInformationCheckVal(infoList);
    }
  }, [infoList]);

  return (
    <div className={buId ? '' : 'inner-wrapper'}>
      {/* <ul aria-label="breadcrumb" className="m-c-bredcrumb">
        <li>
          <a>Home </a>
        </li>
        <li>
          <a className="active">Create Company</a>
        </li>
      </ul> */}

      {!buId && (
        <div className="m-l-inner-page-header">
          <div className="m-l-inner-page-header-left-block">
            <div className="m-l-page-main-heading">{t('labels.berthFit')}</div>
          </div>
        </div>
      )}
      <div className="m-l-inner-page-body m-l-inner-tabgrid-page-body">
        <div className="m-l-tab-panel__wrap">
          <TabPanel
            ref={tabRef}
            items={tabs}
            className="m-c-tab-block m-c-tab-block--full-width"
            onSelectionChanged={onTabChange}
          >
            <Template
              name="buInfo"
              render={() => (
                <div className="m-l-bu-grid-form-wrap">
                  {showForm === false && (
                    <div>
                      <div
                        className="m-c-grid no-border-top no-border-bottom-row m-c-chk-btn-grid m-c-grid-in-tab"
                        ref={sepRef}
                        style={{ height: height }}
                      >
                        <Grid
                          gridConfig={gridConfig}
                          statusTemplate={statusTemplate}
                          actionTemplate={actionTemplate}
                          headerTemplate={actionHeaderTemplateParameter}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            />
            <Template
              name="rules"
              render={() => (
                <div>
                  <div className="m-l-bu-grid-form-wrap">
                    {showForm === false && (
                      <div>
                        <div className="m-l-grid-top-header__section u-bb0">
                          <div className="left-section">
                            <div className="m-l-grid-top-header-list-row">
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-6 m-l-custom-sixteen-space-col pe-0">
                                  <div className="m-c-form-group mb-btm-space u-mb0 w-auto ml-form-space-fix__information">
                                    <label className="m-l-input-label">
                                      {t('labels.informationTypes')}
                                    </label>
                                    <SelectBox
                                      dataSource={infoTypeFilter}
                                      valueExpr="optionValueCode"
                                      displayExpr="optionValueText"
                                      className="m-c-input-control m-c-select-box"
                                      value={infoTypeState}
                                      onValueChanged={(e: any) =>
                                        onClickInfoType(e)
                                      }
                                    ></SelectBox>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="m-c-grid no-border-top no-border-bottom-row m-c-chk-btn-grid m-c-grid-in-tab"
                          ref={sepRef}
                          style={{ height: height }}
                        >
                          <Grid
                            gridConfig={gridConfig2}
                            statusTemplate={statusTemplate}
                            actionTemplate={actionTemplate}
                            headerTemplate={actionHeaderTemplateInformation}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            />
          </TabPanel>
          <div className="m-l-tab-panel-right-block">
            {buId ? null : (
              <Restricted
                permission={
                  buId
                    ? tabSelected === 'Parameters'
                      ? 'BUBerthFit.SaveBerthFitParameters'
                      : 'BUBerthFit.SaveBerthFitInformation'
                    : tabSelected === 'Parameters'
                    ? 'GlobalBerthFit.SaveBerthFitParameters'
                    : 'GlobalBerthFit.SaveBerthFitInformation'
                }
              >
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width"
                  onClick={onClickSave}
                >
                  {t('buttons.save')}
                </Button>
              </Restricted>
            )}
          </div>
        </div>
      </div>
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms m-l-icon-btn-grp">
              {/* <Button
                id="copyButton"
                className="app-c-btn app-c-btn--secondary min-btn-width-sm-pls"
                onClick={() => {
                  setShowPopver(true);
                }}
              >
                <div className="m-c-icon-inside-btn">
                  <icons.CopyIcon />
                </div>
              </Button>
              <Button
                id="add"
                className="app-c-btn app-c-btn--secondary min-btn-width-sm-pls"
              >
                <div className="m-c-icon-inside-btn">
                  <icons.AddIcon />
                </div>
              </Button> */}
              {/* <Button className="app-c-btn app-c-btn--secondary">Cancel</Button> */}
              {buId ? null : (
                <Restricted
                  permission={
                    buId
                      ? tabSelected === 'Parameters'
                        ? 'BUBerthFit.SaveBerthFitParameters'
                        : 'BUBerthFit.SaveBerthFitInformation'
                      : tabSelected === 'Parameters'
                      ? 'GlobalBerthFit.SaveBerthFitParameters'
                      : 'GlobalBerthFit.SaveBerthFitInformation'
                  }
                >
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    onClick={onClickSave}
                  >
                    {t('buttons.save')}
                  </Button>
                </Restricted>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={showConfirmSave || showSave}
      />
      <ConfirmDialog
        dialogConfig={tabChangeConfrimConfig}
        isOpen={showTabChangeConfirm}
      />
    </div>
  );
}
