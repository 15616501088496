import Button from 'devextreme-react/button';
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IDialogConfig } from '../../../../types/dialog';
import { IVesselGeneralForm } from '../../../../types/vessel-general';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import VesselFormGeneral from './vessel-general-form/VesselGeneralForm';
import toast from '../../../../utils/toast';
import { IVesselGeneralEdit } from '../../../../types/vessel-general-edit';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import { isTabDirtyContext } from '../../../../pages/vessel-info/VesselInformation';
export default function GeneralEdit(props: {
  generalList: any;
  setIsGeneralEdit: any;
  vesselType: any;
  vesselStatus: any;
  pandiClub: any;
  getVesselDetail: any;
  countries: any;
  ports: any;
  sizeClass: any;
  hullType: any;
  imoNumber?: string;
  vesselBannerDetails?: any;
  setIsTabsDirty?: any;
}) {
  const {
    generalList,
    setIsGeneralEdit,
    vesselType,
    vesselStatus,
    pandiClub,
    getVesselDetail,
    sizeClass,
    hullType,
    countries,
    ports,
    imoNumber,
    vesselBannerDetails,
    setIsTabsDirty,
  } = props;

  const setTabDirtyFlag = useContext(isTabDirtyContext);
  const { t } = useTranslation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isconfirmBackDialog, setIsConfirmBackDialog] = useState(false);
  const { apiDateFormat } = useDateFormat();
  const { setIsLoading } = useLoaderContext();
  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      setIsTabsDirty(false);
      onValueSubmit();
      setIsConfirmModalOpen(false);
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };
  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setIsTabsDirty(false);
      setIsGeneralEdit(false);
      setIsConfirmBackDialog(false);
      setTabDirtyFlag.setState(
        setTabDirtyFlag.state == 0
          ? setTabDirtyFlag.state
          : setTabDirtyFlag.state - 1
      );
    },
    handleClose: () => {
      setIsConfirmBackDialog(false);
    },
  };

  const defaultVesselFormValues: IVesselGeneralForm = {
    vesselname: '',
    vesseltype: '',
    portofregistry: '',
    flag: '',
    imo: '',
    vesselstatus: '',
    servicespeed: 0,
    builtdate: '',
    hulltype: '',
    mmsinumber: '',
    decksnumber: null,
    hullmaterial: '',
    callsign: '',
    sizeclass: '',
    bulkheads: null,
    pandiclub: '',
    pandistartdate: '',
    pandienddate: '',
    handmprovider: '',
    handmstartdate: '',
    handmenddate: '',
    cofrvalidity: '',
    vrpvalidity: '',
    uscgcocvalidity: '',
    codvalidity: '',
    coivalidity: '',
    tsmsvalidity: '',
    vinnumber: '',
  };

  const vesselGeneralForm = useForm({
    defaultValues: defaultVesselFormValues,
  });

  const onHandleSave = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      setIsConfirmModalOpen(true);
    }
  };
  const { isDirty } = vesselGeneralForm.formState;

  const handleBackClick = () => {
    isDirty ? setIsConfirmBackDialog(true) : setIsGeneralEdit(false);
  };

  const { getValues } = vesselGeneralForm;
  let listGeneralFormValue: IVesselGeneralForm = getValues();

  const onValueSubmit = () => {
    listGeneralFormValue = vesselGeneralForm.getValues();
    const roleJsonEdit: IVesselGeneralEdit = {
      vesselName: listGeneralFormValue.vesselname,
      imoDisplayName: listGeneralFormValue.imo?.toString(),
      mmsiNumber: listGeneralFormValue.mmsinumber,
      callSign: listGeneralFormValue.callsign,
      flagCode: listGeneralFormValue.flag,
      vesselTypeCode: listGeneralFormValue.vesseltype,
      portOfRegistry: listGeneralFormValue.portofregistry,
      speedService: listGeneralFormValue.servicespeed,
      vesselStatusCode: listGeneralFormValue.vesselstatus,
      dateOfBuild: apiDateFormat(listGeneralFormValue.builtdate),
      hullType: listGeneralFormValue.hulltype,
      hullMaterial: listGeneralFormValue.hullmaterial,
      numberOfDecks: listGeneralFormValue.decksnumber?.toString(),
      bulkheads: listGeneralFormValue.bulkheads?.toString(),
      sizeClass: listGeneralFormValue.sizeclass,
      piClubCode: listGeneralFormValue.pandiclub,
      hmProvider: listGeneralFormValue.handmprovider,
      vinNumber: listGeneralFormValue.vinnumber,
      imoNumber: generalList?.imoNumber,
    };

    api
      .put(
        { url: apiConfig.vesselInformationGenTab, data: roleJsonEdit },
        setIsLoading
      )
      .then((response) => {
        if (response == true) {
          getVesselDetail();
          setIsGeneralEdit(false);
          setTabDirtyFlag.setState(
            setTabDirtyFlag.state == 0
              ? setTabDirtyFlag.state
              : setTabDirtyFlag.state - 1
          );
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
          if (vesselBannerDetails) vesselBannerDetails();
        }
      });
  };
  useEffect(() => {
    vesselGeneralForm.reset();
    if (generalList) {
      vesselGeneralForm.reset(
        {
          vesselname: generalList.vesselName,
          vesseltype: generalList.vesselTypeCode,
          portofregistry: generalList.portOfRegistry,
          flag: generalList.flagCode,
          imo: generalList.imoDisplayName,
          vesselstatus: generalList.vesselStatusCode,
          servicespeed: generalList.speedService,
          builtdate: generalList.dateOfBuild,
          hulltype: generalList.hullType,
          mmsinumber: generalList.mmsiNumber,
          decksnumber: generalList.numberOfDecks
            ? Number(generalList.numberOfDecks)
            : null,
          hullmaterial: generalList.hullMaterial,
          callsign: generalList.callSign,
          sizeclass: generalList.sizeClass,
          bulkheads: generalList.bulkheads
            ? Number(generalList.bulkheads)
            : null,
          pandiclub: generalList.piClubCode,
          handmprovider: generalList.hmProvider,
          vinnumber: generalList.vinNumber,
        },
        { keepDirty: true }
      );
    }
  }, [generalList]);

  useEffect(() => {
    if (isDirty) {
      setTabDirtyFlag.setState(setTabDirtyFlag.state + 1);
    }
  }, [isDirty]);

  return (
    <div className="m-l-form-panel-block">
      <div className="m-l-tbl-square-wrapper m-l-tbl-square-wrapper--edit-mode">
        <div className="row m-l-custom-eight-space-row">
          <FormProvider {...vesselGeneralForm}>
            <VesselFormGeneral
              vesselType={vesselType}
              vesselStatus={vesselStatus}
              pandiClub={pandiClub}
              countries={countries}
              ports={ports}
              sizeClass={sizeClass}
              hullType={hullType}
            />
          </FormProvider>
        </div>
        <div className="m-l-panel-right-btn u-pb0">
          <Button
            className="app-c-btn app-c-btn--secondary min-btn-width"
            onClick={handleBackClick}
          >
            {t('labels.cancel')}
          </Button>
          <Button
            onClick={onHandleSave}
            className="app-c-btn app-c-btn--primary min-btn-width"
          >
            {t('labels.save')}
          </Button>
          <ConfirmDialog
            dialogConfig={confirmSaveDialogConfig}
            isOpen={isConfirmModalOpen}
          />
          <ConfirmDialog
            dialogConfig={confirmBackDialogConfig}
            isOpen={isconfirmBackDialog}
          />
        </div>
      </div>
    </div>
  );

  // function setIsLoading(arg0: { url: listGeneralFormValue. }, setIsLoading: any) {
  //   throw new Error('Function not implemented.');
}
