import Button from 'devextreme-react/button';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { IGridConfig } from '../../../../types/grid';
import Grid from '../../../common/grid/Grid';
import { useNavigate } from 'react-router-dom';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import toast from '../../../../utils/toast';
import BusinessUnitClone from '../business-unit-clone/BusinessUnitClone';
import { IBUClone } from '../../../../types/business-unit';
import { useTranslation } from 'react-i18next';
import { IOptionvalue } from '../../../../types/master-data';
import { getDefaultColumn } from './businessUnitGridHelper';
import { Restricted } from '../../../../contexts/PermissionContext';
import { BusinessUnitStatus } from '../../../../enums/status-enum';
import DataGrid from 'devextreme-react/data-grid';
import { useLoaderContext } from '../../../../contexts/LoaderContext';

export default function BusinessUnitGrid(props: {
  personNameTitles: IOptionvalue[];
  buIncoterms: IOptionvalue[];
  isFromMenu?: boolean;
  companyId?: number;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { personNameTitles, buIncoterms, isFromMenu, companyId } = props;
  const onAddNewClick = () => {
    isFromMenu
      ? navigate('/company/business-unit/add', {
          state: { companyId: companyId, isFromMenu: 'isFromMenu' },
        })
      : navigate('/company/business-unit/add', {
          state: { companyId: companyId },
        });
  };
  const [buList, setBuList] = useState([]);

  const buGrid = useRef<DataGrid>(null);

  const [showClonePopover, setShowClonePopover] = useState(0);
  const { setIsLoading } = useLoaderContext();
  const onRowClick = (e: any) => {
    if (!e.event.target.className.includes('dx-button')) {
      isFromMenu
        ? navigate('/company/business-unit/edit', {
            state: { buId: e.key.buId, isFromMenu: 'isFromMenu' },
          })
        : navigate('/company/business-unit/edit', {
            state: { buId: e.key.buId },
          });
    }
  };
  const cellPrepared = (e: any) => {
    if (e.rowType === 'data' && e.column.dataField === 'buStatus') {
      e.cellElement.className =
        e.data.buStatus === BusinessUnitStatus.Active
          ? 'status-active-wtout'
          : 'status-inactive-wtout';
    }
  };
  const getBusinessUnitList = async () => {
    if (companyId && companyId !== undefined) {
      await api
        .get({ url: apiConfig.companyBusinessUnits + companyId })
        .then((data) => {
          setBuList(data.data);
        });
    }
  };

  const buIncoTermsTitle = (rowData: any) => {
    const incoTermsArray = [] as any;
    if (rowData.buIncoTerms && rowData.buIncoTerms.length > 0) {
      rowData.buIncoTerms.map(function (item: any) {
        const buIncoObj = buIncoterms.find(
          (buInco) => buInco.optionValueCode === item.incoTerms
        );
        if (buIncoObj?.optionValueText) {
          incoTermsArray.push(buIncoObj?.optionValueText);
        }
      });
    }
    return incoTermsArray;
  };

  const contactPersonNameWithTitle = (rowData: any) => {
    if (rowData.personNameTitle && rowData.contactName) {
      const titleObj = personNameTitles.find(
        (title) => title.optionValueCode === rowData.personNameTitle
      );
      const titleLabel = titleObj?.optionValueText || '';
      return `${titleLabel} ${rowData.contactName}`;
    }
    return rowData.contactName;
  };

  useEffect(() => {
    buGrid.current?.instance.columnOption(
      'buIncoTerms',
      'calculateCellValue',
      buIncoTermsTitle
    );
    buGrid.current?.instance.columnOption(
      'contactName',
      'calculateCellValue',
      contactPersonNameWithTitle
    );
  }, [buIncoterms, personNameTitles]);

  const defaultColumns = useMemo(() => {
    return getDefaultColumn(
      t,
      personNameTitles,
      buIncoTermsTitle,
      contactPersonNameWithTitle
    );
  }, [personNameTitles, buIncoterms]);

  useEffect(() => {
    if (companyId && companyId !== undefined) {
      getBusinessUnitList();
    }
  }, [companyId]);

  function cellTemplate(data: any) {
    return (
      <div className="m-l-badge-container">
        {data.value[0]}
        {data.value.length > 1 && (
          <span className="badge">{data.value.length - 1}+</span>
        )}
      </div>
    );
  }

  const gridConfig: IGridConfig = useMemo(() => {
    return {
      ref: buGrid,
      class: 'm-c-grid m-c-grid-in-tab',
      testId: 'bugrid',
      rowClickPermission: 'CompanyBU.ViewDetail',
      dataSource: buList,
      cellPrepared: cellPrepared,
      defaultColumns,
      rowClick: onRowClick,
      showAddButton: false,
      showHeader: false,
      onClickAddButton: onAddNewClick,
      hidePagination: false,
      noDataText: t('errors.buListNoData'),
    };
  }, [defaultColumns, buList, personNameTitles]);

  const handleCloneButtonClick = (buId: number) => {
    setShowClonePopover(buId);
  };

  const onHidingClonePopover = () => {
    setShowClonePopover(0);
  };

  const cloneBU = async (buCloneJson: IBUClone) => {
    await api
      .post(
        { url: apiConfig.businessUnitsClone, data: buCloneJson },
        setIsLoading
      )
      .then(() => {
        toast.custom({
          title: t('toast.savedSuccessfully'),
          message: t('toast.buCreated'),
        });
        getBusinessUnitList();
        setShowClonePopover(0);
      });
  };

  const actionTemplate = (data: any) => {
    return (
      <Restricted permission="CompanyBU.Clone">
        <Button
          id={`bu${data.data.buId}`}
          className="app-c-btn app-c-btn--secondary"
          onClick={(e: any) => {
            handleCloneButtonClick(data.data.buId);
          }}
        >
          {t('buttons.clone')}
        </Button>
        {showClonePopover === data.data.buId && (
          <BusinessUnitClone
            companyId={Number(companyId)}
            buDetails={data.data}
            showPopover={showClonePopover === data.data.buId}
            target={`#bu${data.data.buId}`}
            closeOnOutsideClick={true}
            onHidingPopover={onHidingClonePopover}
            cloneBU={cloneBU}
            onClickCancel={() => {
              setShowClonePopover(0);
            }}
          />
        )}
      </Restricted>
    );
  };

  return (
    <Grid
      gridConfig={gridConfig}
      statusTemplate={cellRender}
      actionTemplate={actionTemplate}
      cellTemplate={cellTemplate}
    />
  );
}

function cellRender(data: any) {
  return <div>{data.value}</div>;
}
