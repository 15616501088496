import React, { useEffect, useState } from 'react';
import { IOptionvalue } from '../../../types/master-data';
import VetPreview from './VetPreview';

export default function VetRequestDetails(props: {
  imoNumber?: string;
  vetRequestId?: string;
  setVetData?: any;
  getVetData?: boolean;
  setGetVetData?: any;
  manualStatus?: any;
  loginUserId?: number;
  vetRequestDetails: any;
  getVetRequestDetails: any;
  incoTerms: any;
  cargo: IOptionvalue[];
}) {
  const {
    imoNumber,
    vetRequestId,
    loginUserId,
    vetRequestDetails,
    getVetRequestDetails,
    incoTerms,
    cargo,
  } = props;
  const [reviewerId, setReviewerId] = useState(0);

  useEffect(() => {
    if (vetRequestDetails) {
      setReviewerId(vetRequestDetails.reviewerId);
    }
  }, [vetRequestDetails]);

  return (
    <>
      {vetRequestDetails && (
        <VetPreview
          cargoList={cargo}
          incoTerms={incoTerms}
          vetRequestDetails={vetRequestDetails}
          imoNumber={imoNumber}
          vetRequestId={Number(vetRequestId)}
          getVetRequestDetails={getVetRequestDetails}
          loginUserId={loginUserId}
          reviewerId={reviewerId}
        />
      )}
    </>
  );
}
