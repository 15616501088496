import Button from 'devextreme-react/button';
import React, { useMemo, useState } from 'react';
import { Restricted } from '../../../../contexts/PermissionContext';
import { VetRequestStatusCode } from '../../../../enums/vet-request-status-enum';
import { getCompanyType } from '../../../../utils/jwt-decode';
import CancelVetRequestCommentModal from '../cancel-vet-request-modal/CancelVetRequestCommentModal';

export default function CancelVetButton(props: {
  vetRequestDetails?: any;
  imoNumber?: string;
  vetRequestId?: any;
  getVetRequestDetails?: any;
  reviewerId?: number;
  loginUserId?: number;
}) {
  const {
    vetRequestDetails,
    imoNumber,
    vetRequestId,
    getVetRequestDetails,
    reviewerId,
    loginUserId,
  } = props;
  const companyType = getCompanyType();
  const [cancelVetRequestModalVisible, setCancelVetRequestModalVisible] =
    useState(false);

  const showCancel = useMemo(() => {
    return (
      (vetRequestDetails?.status == VetRequestStatusCode.requested &&
        companyType == 'R') ||
      ([
        VetRequestStatusCode.assigned,
        VetRequestStatusCode.requested,
        VetRequestStatusCode.processing,
        VetRequestStatusCode.unassigned,
        VetRequestStatusCode.waitingforExternalInfo,
        VetRequestStatusCode.waitingforRequestorinfo,
        VetRequestStatusCode.infoReceived,
      ].includes(vetRequestDetails?.status) &&
        companyType == 'H') ||
      (loginUserId &&
        reviewerId &&
        [
          VetRequestStatusCode.assigned,
          VetRequestStatusCode.processing,
        ].includes(vetRequestDetails?.status) &&
        companyType == 'M' &&
        loginUserId == reviewerId)
    );
  }, [vetRequestDetails, loginUserId, companyType, reviewerId]);

  const onClickCancel = () => {
    setCancelVetRequestModalVisible(true);
  };

  return (
    <>
      <Restricted permission={'VetRequest.Cancel'}>
        {showCancel && (
          <>
            <div className="app-l-vertical-seperator"></div>
            <div className="m-l-status-block">
              <div className="m-c-form-group">
                <div className="m-l-status-block-dropdown">
                  <Button
                    // className="m-l-status-btn m-l-status-btn-no-icon status-bg-orange"
                    className="app-c-btn app-c-btn--secondary"
                    onClick={onClickCancel}
                  >
                    Cancel Vet
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </Restricted>

      {cancelVetRequestModalVisible ? (
        <CancelVetRequestCommentModal
          vetRequestDetails={vetRequestDetails}
          cancelVetRequestModalVisible={cancelVetRequestModalVisible}
          setCancelVetRequestModalVisible={setCancelVetRequestModalVisible}
          vetRequestId={vetRequestId}
          getVetRequestDetails={getVetRequestDetails}
        />
      ) : null}
    </>
  );
}
