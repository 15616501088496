import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDataStore } from '../../../hooks/useDataStore';
import { IGridConfig } from '../../../types/grid';
import Grid from '../../common/grid/Grid';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import DataGrid from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { usePermission } from '../../../contexts/PermissionContext';
import { useDimensionsUnit } from '../../../hooks/useDimensionsUnit';
import { useDateFormat } from '../../../hooks/useDateFormat';

export default function TerminalGrid(props: {
  searchResultList?: any;
  query?: any;
  setIsAddList?: any;
  setIsFromAddTerminal?: any;
  isFromAddTerminal?: any;
  params?: any;
  setTerminalEntityNames?: any;
  isFromPort?: boolean;
  setIsAddListTerminal?: any;
  setCodesToList?: any;
  codesToList?: any;
  isExport?: any;
  setIsExport?: any;
  clearSelection?: any;
  setClearSelection?: any;
}) {
  const {
    searchResultList,
    query,
    setIsAddList,
    setIsFromAddTerminal,
    isFromAddTerminal,
    params,
    setTerminalEntityNames,
    isFromPort,
    setIsAddListTerminal,
    isExport,
    setIsExport,
    clearSelection,
    setClearSelection,
  } = props;
  const { t } = useTranslation();
  const { getDataSource } = useDataStore();
  const navigate = useNavigate();
  const [searchListTerminal, setSearchListTerminal] = useState<any>();
  const { findFeature } = usePermission();
  const downloadgridRef: any = useRef<any>(null);
  const gridRef = useRef<DataGrid>(null);
  const [gridInstance, setGridInstance] = useState<any>();
  const { dateFormat } = useDateFormat();
  const searchTerminal = async () => {
    const data = getDataSource(
      'terminalId',
      apiConfig.portSearch + query + '&Search=Terminals',
      10
    );
    setSearchListTerminal(data);
    if (isFromAddTerminal) {
      setIsFromAddTerminal(false);
    }
  };

  useEffect(() => {
    if (query) {
      searchTerminal();
    }
  }, [query, isFromAddTerminal]);

  useEffect(() => {
    if (isExport) {
      downloadgridRef.current.exportTerminalSearchGrid();
      setIsExport(false);
    }
  }, [isExport]);

  const onClickRow = async (e: any) => {
    navigate('/port/terminal/edit', {
      state: {
        terminalId: e.data.terminalId,
        terminalName: e.data.terminalName,
        terminalCode: e.data.terminalCode,
        query: query,
        params: params,
        portName: e.data.portName,
        portCode: e.data.portCode,
        portId: e.data.portId,
        isFromPort: isFromPort,
      },
    });
  };

  const onRowClickCheckbox = async (e: any) => {
    setTerminalEntityNames(e.selectedRowsData);
    if (e.selectedRowsData.length > 0) {
      setIsAddListTerminal ? setIsAddListTerminal(true) : setIsAddList(true);
    } else {
      setIsAddListTerminal ? setIsAddListTerminal(false) : setIsAddList(false);
    }
  };

  const onGridInitialized = (e: any) => {
    setGridInstance(e.component);
  };

  useImperativeHandle(downloadgridRef, () => ({
    exportTerminalSearchGrid,
  }));

  const exportTerminalSearchGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: gridInstance,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Terminals.xlsx'
        );
      });
    });
  };
  useEffect(() => {
    if (clearSelection) {
      gridRef.current?.instance.clearSelection();
      setClearSelection(false);
    }
  }, [clearSelection]);

  const onDataErrorOccurred = () => {
    setSearchListTerminal([]);
  };

  function incoTermsCellTemplate(data: any) {
    return (
      <div className="m-l-badge-container">
        {data?.value[0]}
        {data?.value?.length > 1 && (
          <span className="badge">{data.value.length - 1}+</span>
        )}
      </div>
    );
  }

  const gridConfig: IGridConfig = {
    class: 'm-c-grid c-m-c-grid-in-tab m-c-userlist-grid',
    testId: 'userGrid',
    dataSource: searchListTerminal,
    remoteOperations: true,
    dataErrorOccured: onDataErrorOccurred,
    defaultColumns: [
      {
        caption: t('labels.terminalName'),
        dataField: 'terminalName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.terminalCode'),
        dataField: 'terminalCode',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.portName'),
        dataField: 'portName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.portCode'),
        dataField: 'portCode',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.country'),
        dataField: 'country',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.terminalChartNumber'),
        dataField: 'chartNumber',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.terminalLatitude'),
        dataField: 'terminalLatitude',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.terminalLongitude'),
        dataField: 'terminalLongitude',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.terminalOwner'),
        dataField: 'terminalOwner',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.terminalOperator'),
        dataField: 'terminalOperator',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.operationalStatus'),
        dataField: 'operationalStatus',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.operationsCarriedOut'),
        dataField: 'operationCarriedout',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.tidalNotes'),
        dataField: 'tidalNotes',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.tidalRestrictions'),
        dataField: 'tidalRestrictions',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.fuelsHandled'),
        dataField: 'fuelsHandled',
        dataType: 'string',
        minWidth: 150,
        cellTemplate: 'cellTemplate',
      },
      {
        caption: t('labels.shipToShipNotes'),
        dataField: 'shiptoshipNotes',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.shipToShipAvailable'),
        dataField: 'shiptoShipAvailable',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.terminalSize'),
        dataField: 'terminalSize',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.terminalVolumeHandled'),
        dataField: 'terminalVolumeHandled',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.pumpClearance'),
        dataField: 'pumpOrReceiveWaterForLineClearance',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.comments'),
        dataField: 'comments',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.specificBerthingRestrictions'),
        dataField: 'specificBerthingRestrictions',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.emergencyShutdownCapability'),
        dataField: 'emergencyShutdownCapability',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.slopReceptionNotes'),
        dataField: 'slopreceptionNotes',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.distanceFromPilotStation'),
        dataField: 'distanceFromPilotStation',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.freshWater'),
        dataField: 'freshWater',
        dataType: 'string',
        minWidth: 150,
      },
      // {
      //   caption: t('labels.minHeightmanifoldAwBerth') ,
      //   dataField: 'minimumHeightManifoldAWBerth',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      {
        caption: t('labels.lastStructuralSurvey'),
        dataField: 'lastStructuralSurvey',
        dataType: 'date',
        minWidth: 150,
        format: dateFormat,
      },
      {
        caption: t('labels.typeOfBottom'),
        dataField: 'terminalTypeOfBottom',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.industryCarriedOut'),
        dataField: 'industryStandardAssessmentCarriedOut',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.vapourRecoveryRequirements'),
        dataField: 'vapourRecoveryRequirements',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.terminalAddress'),
        dataField: 'terminalAddress',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.terminalPostalCode'),
        dataField: 'postalCode',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.terminalEmail'),
        dataField: 'email',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.terminalPhoneNumber'),
        dataField: 'terminalContacts',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.terminalWebsite'),
        dataField: 'website',
        dataType: 'string',
        minWidth: 150,
      },
    ],
    // showSelection: true,
    ref: gridRef,
    showSelection: findFeature('PortManagement.AddTerminalToList'),
    selectionChanged(e: any) {
      onRowClickCheckbox(e);
    },
    hidePagination: false,
    rowClick: (e: any) => onClickRow(e),
    initialized: onGridInitialized,
    rowClickPermission: 'PortManagement.Edit Terminal',
  };

  return <Grid gridConfig={gridConfig} cellTemplate={incoTermsCellTemplate} />;
}
