import Button from 'devextreme-react/button';
import CheckBox from 'devextreme-react/check-box';
import SelectBox from 'devextreme-react/select-box';
import Tooltip from 'devextreme-react/tooltip';
import Validator from 'devextreme-react/validator';
import React, { useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { IFormConfig } from '../../../types/form-builder';
import FormField from '../form-field/FormField';
import icons from '../icons/icons';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { getAPIRoute } from '../../../utils/api-route';

export default function FieldArray(props: {
  fieldArray: any;
  fieldArrayName?: string;
  formConfig: IFormConfig[];
  appendValues: any;
  appendLimit?: any;
  showAddButton?: boolean;
  buttonClass?: string;
  buttonWrapperClass?: string;
  buttonWrapperParentClass?: string;
  filterDataSource?: boolean;
  filterFieldDataSource?: any;
}) {
  const { control } = useFormContext();
  const {
    fieldArray,
    fieldArrayName,
    formConfig,
    appendValues,
    appendLimit,
    showAddButton,
    buttonWrapperClass,
    buttonWrapperParentClass,
    filterDataSource,
    filterFieldDataSource,
  } = props;

  const value = useWatch({
    name: fieldArrayName ? fieldArrayName : 'fieldArray',
    control,
  });

  return (
    <>
      {fieldArray.fields.map((item: any, index: any) => {
        return (
          <div className="row" key={item.id}>
            {formConfig.map((config) => {
              if (
                config.fieldType != 'selectWithDefault' &&
                config.isFieldArray
              ) {
                return (
                  <div key={config.name} className={config.class}>
                    <Controller
                      name={`${fieldArrayName}[${index}].${config.name}`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormField
                          config={{ ...config }}
                          field={{ ...field }}
                          fieldState={{ ...fieldState }}
                          fieldArray={{ ...fieldArray }}
                          index={index}
                          appendValues={{ ...appendValues }}
                          appendLimit={{ appendLimit }}
                          value={{ ...value }}
                        />
                      )}
                    />
                  </div>
                );
              }
              if (
                config.fieldType == 'selectWithDefault' &&
                config.isFieldArray &&
                !config.isHidden
              ) {
                return (
                  <div key={item.id} className={config.class}>
                    <div className="mb-btm-space m-c-form-group">
                      <div className="m-l-default-checkbox-unit">
                        <label className="m-l-input-label">
                          {config.label +
                            ' ' +
                            (config.showLabelIndex ? index + 1 : '')}
                          {config.isRequired && (
                            <span className="m-l-input-required">*</span>
                          )}

                          {config.showWarning && (
                            <Controller
                              name={`${fieldArrayName}[${index}].${'portWarning'}`}
                              control={control}
                              render={({ field }) => (
                                <>
                                  {field.value?.warning && (
                                    <span
                                      className="m-l-port-warning"
                                      id={config.fieldName + index}
                                    >
                                      <icons.PortWarning />
                                    </span>
                                  )}
                                  <Tooltip
                                    target={`#${config.fieldName + index}`}
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    position="right"
                                    contentRender={() => {
                                      return (
                                        <div className="m-l-tooltip-wrapper m-l-warning-icon-tooltip">
                                          <span>
                                            {field.value?.restrictedMessage}
                                          </span>
                                        </div>
                                      );
                                    }}
                                  />
                                </>
                              )}
                            />
                          )}
                        </label>
                        <div className="m-c-checkbox">
                          <Controller
                            name={`${fieldArrayName}[${index}].${config.checkBoxName}`}
                            control={control}
                            render={({ field }) => (
                              <>
                                <CheckBox
                                  elementAttr={{
                                    'data-testid': config.checkBoxName,
                                  }}
                                  name={config.checkBoxName}
                                  value={field.value}
                                  onValueChanged={(e: any) => {
                                    field.onChange(e.value);
                                    config.onChangeCheckBox
                                      ? config.onChangeCheckBox(e, index)
                                      : null;
                                  }}
                                  disabled={
                                    config.disabled
                                      ? value[index]?.bu == null
                                      : false
                                  }
                                />

                                <span className="m-l-checkbox--small-label">
                                  {config.checkBoxLabel}
                                </span>
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <Controller
                        name={`${fieldArrayName}[${index}].${config.name}`}
                        control={control}
                        render={({ field }) => (
                          <>
                            <SelectBox
                              elementAttr={{ 'data-testid': config.name }}
                              name={config.name}
                              dataSource={config.dataSource}
                              displayExpr={config.displayExpr}
                              valueExpr={config.valueExpr}
                              searchExpr={config.searchExpr}
                              onValueChanged={(e: any) => {
                                field.onChange(e.value);
                                config.onChange
                                  ? config.onChange(e, index)
                                  : null;
                              }}
                              value={field.value}
                              className="m-c-input-control  m-c-select-box"
                              itemRender={config.itemRender}
                              searchEnabled={!config.disableSearch}
                              fieldRender={config.fieldRender}
                              readOnly={config.readonly}
                              disabled={false}
                              validationMessageMode="always"
                            >
                              <Validator validationRules={config.rules} />
                            </SelectBox>
                          </>
                        )}
                      />
                    </div>
                  </div>
                );
              }
            })}

            {showAddButton && (
              <div
                className={
                  buttonWrapperParentClass
                    ? buttonWrapperParentClass
                    : 'col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-12 m-l-custom-eight-space-col'
                }
              >
                <div
                  className={
                    buttonWrapperClass
                      ? buttonWrapperClass
                      : 'mb-btm-space m-c-form-group m-l-button-vertical-center-align m-l-fm-icon-btn'
                  }
                >
                  {fieldArray?.fields?.length != 1 &&
                    index >= 0 &&
                    index != fieldArray?.fields.length && (
                      <Button
                        className="app-c-btn app-c-btn--remove min-btn-width-sm-pls"
                        onClick={() => {
                          {
                            fieldArray.remove(index),
                              filterDataSource ? filterFieldDataSource() : null;
                          }
                        }}
                      >
                        <div className="m-c-icon-inside-btn">
                          <icons.RemoveIcon />
                        </div>
                      </Button>
                    )}
                  {!appendLimit &&
                    (fieldArray?.fields?.length == 1 ||
                      index == fieldArray?.fields.length - 1) && (
                      <Button
                        className="app-c-btn app-c-btn--secondary min-btn-width-sm-pls"
                        onClick={() => {
                          fieldArray.append(appendValues),
                            filterDataSource ? filterFieldDataSource() : null;
                        }}
                      >
                        <div className="m-c-icon-inside-btn">
                          <icons.AddIcon />
                        </div>
                      </Button>
                    )}
                  {appendLimit &&
                    (fieldArray?.fields?.length == 1 ||
                      index == fieldArray?.fields.length - 1) &&
                    index < appendLimit - 1 && (
                      <Button
                        className="app-c-btn app-c-btn--secondary min-btn-width-sm-pls"
                        onClick={() => {
                          fieldArray.append(appendValues),
                            filterDataSource ? filterFieldDataSource() : null;
                        }}
                      >
                        <div className="m-c-icon-inside-btn">
                          <icons.AddIcon />
                        </div>
                      </Button>
                    )}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
}
