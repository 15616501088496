export const htmlTable =
  '<table style="border: 1.5px solid;width: 100%;border-collapse: collapse;"><tbody><tr>columnNames</tr>tableValues</tbody></table>';
export const tableRow = '<tr>columns</tr>';
export const columnValues =
  '<td style="border: 1px solid;text-align: center; vertical-align: center;"><p>columnValue</p></td>';
export const columnNames =
  '<td style="border: 1px solid;text-align: center; vertical-align: center;"><p><strong style="font-family: Arial; color: rgb(19, 80, 212);">ColumnName</strong></p></td>';

const IncidentColumnName = [
  { name: 'Incident Date' },
  { name: 'Event Type' },
  { name: 'Place Of Incident' },
];
const PSCColumnName = [
  { name: 'Date of Inspection' },
  { name: 'Port of Inspection' },
  { name: 'Authority' },
];

export const getRFITable = (tableData: any, isIncident: any) => {
  let columnNameRow = '';
  const columnName = isIncident ? IncidentColumnName : PSCColumnName;
  columnName.forEach((element) => {
    const val = columnNames.replace('ColumnName', element.name);
    columnNameRow = columnNameRow + val;
  });
  let valueRow = '';
  tableData.forEach((element: any) => {
    let columnVal = '';
    Object.keys(element).map(function (key) {
      const val = columnValues.replace('columnValue', element[key]);
      columnVal = columnVal + val;
    });
    const rowVal = tableRow.replace('columns', columnVal);
    valueRow = valueRow + rowVal;
  });
  let table = htmlTable.replace('columnNames', columnNameRow);
  table = table.replace('tableValues', valueRow);
  return table;
};

export const replyEmailTemplate = (email: any) => {
  const template = `<p><br></p><p><br></p>
  <p>---------------------------------------------------------------------------------------------</p>
  <p><br></p>
  <p><strong>From:</strong> ${email.from}</p>
  <p><strong>Sent:</strong> ${email?.sendDate}</p><p><strong>To:</strong> ${
    email.to
  }</p><p><strong>Subject:</strong> ${
    email.subject ? email.subject : ''
  }</p><p><br></p>`;
  return {
    templateHtml: template + email.mailContent,
    to: email?.replyInbox ? email.from : email.to,
    cc: email.cc,
    subject: 'RE: ' + email.subject,
  };
};
