import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormBuilder from '../../../../common/form-builder/FormBuilder';

export default function AuxillaryEngineForm() {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const auxillaryEngineFormConfig = [
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'numberOfAuxiliaryEngines',
      label: t('labels.generator'),
      control: control,
      fieldType: 'number',
      maxLength: 7,
      rules: [
        {
          type: 'pattern',
          pattern: '^[0-9]*$',
          message: t('errors.wholeNumber'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'auxiliaryGeneratorsDescriptiveNarrative',
      label: t('labels.generatorDescription'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'rpm',
      label: t('labels.rpm'),
      control: control,
      fieldType: 'text',
      maxLength: 30,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'kw',
      label: t('labels.kw'),
      control: control,
      fieldType: 'text',
      maxLength: 30,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'bhp',
      label: t('labels.bhp'),
      control: control,
      fieldType: 'text',
      maxLength: 30,
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={auxillaryEngineFormConfig} />
      </div>
    </>
  );
}
