import React, { useCallback, useMemo, useRef } from 'react';
import HtmlEditor, {
  Toolbar,
  MediaResizing,
} from 'devextreme-react/html-editor';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { useTranslation } from 'react-i18next';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';

export default function EmailSettingHtmlEditor(props: {
  content: any;
  setContent: any;
  entities: any;
  showEditor?: any;
  isDisabled?: any;
}) {
  const { content, setContent, entities, showEditor, isDisabled } = props;
  const htmlEditor: any = useRef();
  const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
  const fontValues = [
    'Arial',
    'Courier New',
    'Georgia',
    'Impact',
    'Lucida Console',
    'Tahoma',
    'Times New Roman',
    'Verdana',
  ];
  const { t } = useTranslation();

  const dataSource = (entityId: any) => {
    return new DataSource({
      store: new CustomStore({
        key: 'employeeId',
        loadMode: 'raw',
        load: () => {
          return api.get({ url: apiConfig.attributesByEnventId + entityId });
        },
      }),
    });
  };

  const itemsList = useMemo(() => {
    const list: any[] = [
      {
        name: 'font',
        acceptedValues: fontValues,
        cssClass: 'app-l-font-class',
      },
      { name: 'separator' },
      { name: 'bold' },
      { name: 'italic' },
      { name: 'underline' },
      { name: 'background' },
      { name: 'separator' },
      {
        name: 'size',
        acceptedValues: sizeValues,
        cssClass: 'app-l-font-size-class',
      },
      { name: 'separator' },
      { name: 'alignLeft' },
      { name: 'alignCenter' },
      { name: 'alignRight' },
      { name: 'separator' },
      { name: 'orderedList' },
      { name: 'bulletList' },
      { name: 'increaseIndent' },
      { name: 'decreaseIndent' },
      { name: 'separator' },
      { name: 'insertTable' },
    ];
    entities.map((entity: any) => {
      const item = {
        name: entity.displayName,
        widget: 'dxSelectBox',
        options: {
          dataSource: dataSource(entity.masterEntityId),
          valueExpr: 'attributeName',
          displayExpr: 'displayName',
          onValueChanged: (e: any) => insertText(e, entity.entityName),
          placeholder: entity.displayName,
          searchEnabled: true,
        },
        cssClass: 'app-l-filter-dropdown',
      };
      list.push(item);
    });
    return list;
  }, [entities]);

  const insertText = useCallback((data: any, entityName: any) => {
    const selection = htmlEditor?.current?.instance?.getSelection(true);
    const text = '@' + entityName + '.' + String(data.value);
    data.value &&
      htmlEditor?.current?.instance?.insertText(selection.index, String(text));
  }, []);

  return (
    <>
      {showEditor && (
        <HtmlEditor
          height="100%"
          width="100%"
          defaultValue={content}
          disabled={isDisabled}
          valueType="html"
          onValueChanged={(e) => {
            setContent(e.value);
          }}
          ref={htmlEditor}
          className="m-c-text-editor m-c-mail-text-editor"
        >
          <MediaResizing enabled={true} />
          <Toolbar multiline={false} items={itemsList} />
        </HtmlEditor>
      )}
    </>
  );
}
