import React, { useEffect, useState } from 'react';
import icons from '../../components/common/icons/icons';
import Button from 'devextreme-react/button';
import { MarsApiService as api } from '../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import SelectBox from 'devextreme-react/select-box';
import { IGridConfig } from '../../types/grid';
import Grid from '../../components/common/grid/Grid';
import NameEditModal from './NameEditModal';
import { getAPIRoute } from '../../utils/api-route';
import { useTranslation } from 'react-i18next';
import { IDialogConfig } from '../../types/dialog';
import ConfirmDialog from '../../components/common/confirm/ConfirmDialog';
import toast from '../../utils/toast';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { Restricted } from '../../contexts/PermissionContext';
import { useLoaderContext } from '../../contexts/LoaderContext';

export default function OptionValues() {
  const { t } = useTranslation();
  const [deleteValue, setDeleteValue] = useState<any>([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const [optionTypes, setOptionTypes] = useState<any>([]);
  const [optionValues, setOptionValues] = useState<any>([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [parentOptionValues, setParentOptionValues] = useState<any>([]);
  const [selectedOptionValue, setSelectedOptionValue] = useState<any>([]);
  const [header, setHeader] = useState('');
  const [currentTypeCode, setCurrentTypeCode] = useState('');
  const [parentTypeCode, setParentTypeCode] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [pagination, setPagination] = useState(true);
  const [isTitle, setIsTitile] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isAddButton, setIsAddButton] = useState(false);
  const [isSystemDefined, setIsSystemDefined] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  let currentOptionType: any;
  const [currentOptionType1, setCurrentOptionType1] = useState<any>([]);
  const [gridInstance, setGridInstance] = useState<any>();
  const { setIsLoading } = useLoaderContext();
  const onRowClick = (e: any) => {
    setSelectedOptionValue(e.data);
    setDeleteValue(e.data);
    if (e.event.target.nodeName == 'path') {
      setShowConfirmDelete(true);
    }
    if (e.data.isSystemDefined) {
      setIsVisible(true);
      setIsEditable(false);
    } else {
      if (e.event.target.nodeName == 'path') {
        setShowConfirmDelete(true);
      } else {
        setIsVisible(true);
      }
      setIsEditable(true);
    }
  };

  const onAddClick = () => {
    setIsVisible(true);
    setIsAdd(true);
    setIsAddButton(true);
    setIsEditable(true);
  };

  const getOptionType = async () => {
    await api
      .get({
        url: apiConfig.optionTypes,
      })
      .then((res: any[]) => {
        setOptionTypes(res);
      });
  };

  const onClickDelete = () => {
    setShowConfirmDelete(true);
  };

  const confirmDeleteDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmDeleteOptionValue'),
    handleSubmit: () => {
      setShowConfirmDelete(false);
      deleteOptionValue();
    },
    handleClose: () => {
      setShowConfirmDelete(false);
    },
  };

  const deleteOptionValue = async () => {
    api
      .delete(
        {
          url: getAPIRoute('optionValueAddEdit', [deleteValue?.optionTypeCode]),
          params: {
            optionValueCode: deleteValue?.optionValueCode,
          },
        },
        setIsLoading
      )
      .then((response) => {
        if (response == true) {
          toast.success({
            title: t('toast.deleteOptionValueSuccess'),
          });
          setIsVisible(false);
          setIsAdd(false);
          setIsEditable(false);
          setIsDeleted(true);
          apiCall(deleteValue.optionTypeCode);
        }
        setIsVisible(false);
        setIsAdd(false);
        setIsEditable(false);
      });
  };

  const onGridInitialized = (e: any) => {
    setGridInstance(e.component);
  };

  const exportRolesGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: gridInstance,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `${header}.xlsx`
        );
      });
    });
  };

  const onClickOptionType = (e: any) => {
    setPagination(false);
    setIsTitile(true);
    setIsAddButton(true);
    const index = optionTypes.findIndex(
      (item: any) => item.optionTypeCode === e.value
    );
    currentOptionType = optionTypes[index];
    setCurrentOptionType1(optionTypes[index]);
    if (currentOptionType.isSystemDefined == true) {
      setIsSystemDefined(false);
    } else {
      setIsSystemDefined(true);
    }
    setHeader(currentOptionType.optionTypeName);
    setCurrentTypeCode(currentOptionType.optionTypeCode);
    setParentTypeCode(currentOptionType.parentOptionTypeCode);
    setIsTitile(true);
    apiCall(currentOptionType.optionTypeCode);
    if (currentOptionType.parentOptionTypeCode) {
      parentApiCall(currentOptionType?.parentOptionTypeCode);
    }
  };

  const apiCall = (type1: any) => {
    api
      .get({ url: getAPIRoute('optionValues', [type1]) })
      .then((res: any[]) => {
        setOptionValues(res);
      });
  };
  const parentApiCall = (type2: any) => {
    api
      .get({
        url: getAPIRoute('optionValues', [type2]),
      })
      .then((res: any[]) => {
        setParentOptionValues(res);
      });
  };

  const actionTemplate = (e: any) => {
    return (
      <Restricted permission={'OptionType.DeleteOption'}>
        <React.Fragment>
          {!e?.data?.isSystemDefined && (
            <div
              className="m-l-grid-delete-icon-wrapper left-align"
              onClick={(e) => {
                e.stopPropagation(), onClickDelete();
              }}
            >
              <icons.IconDelete />
            </div>
          )}
        </React.Fragment>
      </Restricted>
    );
  };

  const actionTemplate1 = (e: any) => {
    if (e.columnIndex == 4) {
      return (
        <div className="m-l-grid-delete-icon-wrapper left-align">
          {e.data.isDefault ? (
            <div>{t('labels.yes')}</div>
          ) : (
            <div>{t('labels.no')}</div>
          )}
        </div>
      );
    }
    if (e.columnIndex == 6) {
      return (
        <div className="m-l-grid-delete-icon-wrapper left-align">
          {e.data.isSystemDefined ? (
            <div>{t('labels.yes')}</div>
          ) : (
            <div>{t('labels.no')}</div>
          )}
        </div>
      );
    }
  };

  const gridConfig: IGridConfig = {
    dataSource: optionValues,
    defaultColumns: [
      {
        caption: 'Language Key',
        dataField: 'languageKey',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'Option Value Text',
        dataField: 'optionValueText',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'Option Value Description 1',
        dataField: 'optionValueDesc',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'Option Value Description 2',
        dataField: 'optionValueDesc2',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'Default ?',
        dataField: 'isDefault',
        dataType: 'string',
        minWidth: 150,
        calculateCellValue: (data: any) => {
          return data.isDefault ? t('labels.yes') : t('labels.no');
        },
        // cellTemplate: 'actionTemplate1',
      },
      {
        caption: 'Display Order',
        dataField: 'displayOrder',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'System Defined ?',
        dataField: 'isSystemDefined',
        dataType: 'string',
        minWidth: 150,
        calculateCellValue: (data: any) => {
          return data.isSystemDefined ? t('labels.yes') : t('labels.no');
        },
      },
      {
        caption: 'Option Value',
        dataField: 'optionValue',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'Parent Option Type',
        dataField: 'parentOptionTypeCode',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'Parent Option',
        dataField: 'parentOptionValueText',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'Action',
        dataField: 'Action',
        dataType: 'string',
        minWidth: 150,
        cellTemplate: 'actionTemplate',
        width: '5%',
      },
    ],
    rowClick: onRowClick,
    hidePagination: pagination,
    noDataText: t('labels.noRecordsAvailable'),
    hideFilterRow: false,
    showHeader: false,
    initialized: onGridInitialized,
  };
  useEffect(() => {
    getOptionType();
  }, []);
  useEffect(() => {
    if (isDeleted) {
      setSelectedOptionValue([]);
      setIsDeleted(false);
    }
  }, [isDeleted]);
  useEffect(() => {
    if (!isVisible) {
      setSelectedOptionValue([]);
    }
  }, [isVisible]);

  return (
    <div className="inner-wrapper">
      <div className="m-l-inner-page-header">
        <div className="m-l-inner-page-header-left-block">
          <div className="m-l-page-main-heading">
            {isTitle ? header : t('labels.optionType')}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 col-sm-6 col-lg-3 col-xs-12 col-xs-12">
          <div className="m-c-form-group u-mb0">
            <SelectBox
              className="m-c-input-control m-c-select-box"
              placeholder="Select option type"
              dataSource={optionTypes}
              searchEnabled
              valueExpr="optionTypeCode"
              displayExpr="optionTypeName"
              onValueChanged={(e: any) => onClickOptionType(e)}
            />
          </div>
        </div>
      </div>
      <div className="m-l-inner-page-sub-header m-l-sub-head-top">
        {/* <div className="m-l-inner-page-sub-header-left-block">
              <div className="m-l-page-main-heading">Entries</div>
            </div> */}
        <div className="m-l-inner-page-sub-header-right-block">
          <Button
            className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
            aria-label="Upload"
            onClick={exportRolesGrid}
          >
            <div className="m-c-icon-inside-btn">
              <icons.download />
            </div>
          </Button>
          <Restricted permission={'OptionType.AddNewOption'}>
            {isSystemDefined && isAddButton && (
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                onClick={onAddClick}
              >
                {t('buttons.addNew')}
              </Button>
            )}
          </Restricted>
        </div>
      </div>
      <div className="m-l-grid-top-border-wrapper-block">
        <Grid
          gridConfig={gridConfig}
          actionTemplate={actionTemplate}
          actionTemplate1={actionTemplate1}
        />
        <div className="m-l-inner-page-footer">
          <div className="row m-l-custom-eight-space-row">
            <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
              <div className="m-l-group-button-btms">
                <Button
                  className="app-c-btn app-c-btn--secondary min-btn-width"
                  aria-label="Upload"
                  onClick={exportRolesGrid}
                >
                  {t('labels.download')}
                </Button>
                <Restricted permission={'OptionType.AddNewOption'}>
                  {isSystemDefined && isAddButton && (
                    <Button
                      className="app-c-btn app-c-btn--primary min-btn-width"
                      onClick={onAddClick}
                    >
                      {t('buttons.addNew')}
                    </Button>
                  )}
                </Restricted>
              </div>
            </div>
          </div>
        </div>
        <ConfirmDialog
          dialogConfig={confirmDeleteDialogConfig}
          isOpen={showConfirmDelete}
        />
        <Restricted permission={'OptionType.EditOption'}>
          {isVisible && (
            <NameEditModal
              isVisible={isVisible}
              handleCancelClick={() => {
                setIsVisible(false), setIsAdd(false), setIsEditable(false);
              }}
              isAdd={isAdd}
              setIsAdd={setIsAdd}
              setIsVisible={setIsVisible}
              header={header}
              selectedOptionValue={selectedOptionValue}
              parentOptionValues={parentOptionValues}
              parentTypeCode={parentTypeCode}
              optionValues={optionValues}
              currentTypeCode={currentTypeCode}
              apiCall={apiCall}
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              setParentOptionValues={setParentOptionValues}
            />
          )}
        </Restricted>
      </div>
    </div>
  );
}
function cellRender(data: any) {
  return <div>{data.value}</div>;
}
