import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDimensionsUnit } from '../../../hooks/useDimensionsUnit';
import { IFormConfig } from '../../../types/form-builder';
import FormBuilder from '../../common/form-builder/FormBuilder';
import PortAccordion from '../port/port-accordion/PortAccordion';

export default function PortForm(props: {
  selectedPortInfo: any;
  countriesList: any;
  portCode: any;
}) {
  const { selectedPortInfo, countriesList, portCode } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { dimensionUnitText } = useDimensionsUnit();
  const portInfoFormConfig: IFormConfig[] = [
    {
      name: 'portName',
      label: t('labels.portName'),
      control: control,
      fieldType: 'text',
    },
    {
      name: 'portCode',
      label: t('labels.portCode'),
      control: control,
      fieldType: 'text',
      disabled: true,
    },
    {
      name: 'country',
      label: t('labels.country'),
      control: control,
      fieldType: 'select',
      dataSource: countriesList,
      displayExpr: 'countryName',
      valueExpr: 'countryCode',
    },
    {
      name: 'portSize',
      label: t('labels.portSize'),
      control: control,
      fieldType: 'text',
    },
    {
      name: 'notes',
      label: t('labels.notes'),
      control: control,
      fieldType: 'text',
    },
    {
      name: 'salinityOfWater',
      label: t('labels.salinityOfWater'),
      control: control,
      fieldType: 'text',
    },
    {
      name: 'latitude',
      label: t('labels.latitude'),
      control: control,
      fieldType: 'text',
      allowDecimal: true,
    },
    {
      name: 'longitude',
      label: t('labels.longitude'),
      control: control,
      fieldType: 'text',
      allowDecimal: true,
    },
    {
      name: 'region',
      label: t('labels.region'),
      control: control,
      fieldType: 'text',
    },
    {
      name: 'unRefCode',
      label: t('labels.unrefCode'),
      control: control,
      fieldType: 'text',
    },
    {
      name: 'portMaxDepthInnerHarbour',
      label: t('labels.portMaxDepthInnerHarbour') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
    },
    {
      name: 'portMaxDepthOuterHarbour',
      label: t('labels.portMaxDepthOuterHarbour') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
    },
    {
      name: 'portMaxAnchorageDepth',
      label: t('labels.portMaxAnchorageDepth') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
    },
    {
      name: 'portMaxPermittedAge',
      label: t('labels.portMaxPermittedAge'),
      control: control,
      fieldType: 'number',
    },
    {
      name: 'maxDepthInChannel',
      label: t('labels.maxDepthInChannel') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
    },
    {
      name: 'minDepthInChannel',
      label: t('labels.minDepthInChannel') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
    },
    {
      name: 'distanceFromPilotPoint',
      label: t('labels.distanceFromPilotPoint'),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
    },
  ];
  return (
    <div className="m-l-form-panel-body-block">
      <div className="row">
        <FormBuilder formConfig={portInfoFormConfig} />
      </div>
      <div className="row">
        <PortAccordion portCode={portCode} />
      </div>
    </div>
  );
}
