import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IGridConfig } from '../../../types/grid';
import Grid from '../../common/grid/Grid';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { useDataStore } from '../../../hooks/useDataStore';
import { usePermission } from '../../../contexts/PermissionContext';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import DataGrid from 'devextreme-react/data-grid';
import { useDimensionsUnit } from '../../../hooks/useDimensionsUnit';

export default function PortGrid(props: {
  searchResultList?: any;
  query?: any;
  setIsAddList: any;
  setSearchList: any;
  searchList: any;
  params?: any;
  setCodesToList?: any;
  codesToList?: any;
  isExport?: any;
  setIsExport?: any;
  clearSelection?: any;
  setClearSelection?: any;
}) {
  const {
    searchResultList,
    query,
    setIsAddList,
    searchList,
    setSearchList,
    params,
    setCodesToList,
    codesToList,
    isExport,
    setIsExport,
    clearSelection,
    setClearSelection,
  } = props;
  const { t } = useTranslation();
  const { getDataSource } = useDataStore();
  const navigate = useNavigate();
  const { findFeature } = usePermission();
  const downloadgridRef: any = useRef<any>(null);
  const gridRef = useRef<DataGrid>(null);
  const [gridInstance, setGridInstance] = useState<any>();
  const { dimensionUnitText, dimensionUnit } = useDimensionsUnit();
  const searchPort = async () => {
    const data = getDataSource(
      'portId',
      apiConfig.portSearch + query + '&Search=Ports',
      10,
      undefined,
      undefined,
      undefined,
      undefined,
      true
    );
    setSearchList(data);
  };

  useEffect(() => {
    if (query) {
      searchPort();
    }
  }, [query]);

  useEffect(() => {
    if (isExport) {
      downloadgridRef.current.exportPortSearchGrid();
      setIsExport(false);
    }
  }, [isExport]);

  const onClickRow = async (e: any) => {
    navigate('/port/edit', {
      state: {
        portId: e.data.portId,
        portName: e.data.portName,
        portCode: e.data.portCode,
        query: query,
        params: params,
        codesToList: codesToList,
      },
    });
  };

  const onRowClickCheckbox = async (e: any) => {
    setCodesToList(e.selectedRowsData);
    if (e.selectedRowsData.length > 0) {
      setIsAddList(true);
    } else {
      setIsAddList(false);
    }
  };

  const onGridInitialized = (e: any) => {
    setGridInstance(e.component);
  };

  useImperativeHandle(downloadgridRef, () => ({
    exportPortSearchGrid,
  }));

  const exportPortSearchGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: gridInstance,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Ports.xlsx'
        );
      });
    });
  };

  const onDataErrorOccurred = () => {
    setSearchList([]);
  };

  useEffect(() => {
    if (clearSelection) {
      gridRef.current?.instance.clearSelection();
      setClearSelection(false);
    }
  }, [clearSelection]);

  useEffect(() => {
    if (dimensionUnit) {
      gridRef.current?.instance.refresh();
      gridRef.current?.instance.columnOption(
        'portMaximumDepthInnerHarbour',
        'caption',
        t('labels.portMaxDepthInnerHarbour') + dimensionUnitText()
      );
      gridRef.current?.instance.columnOption(
        'portMaximumDepthOuterHarbour',
        'caption',
        t('labels.portMaxDepthOuterHarbour') + dimensionUnitText()
      );
      gridRef.current?.instance.columnOption(
        'portMaximumAnchorageDepth',
        'caption',
        t('labels.portMaxAnchorageDepth') + dimensionUnitText()
      );
      gridRef.current?.instance.columnOption(
        'maximumDepthinChannel',
        'caption',
        t('labels.maxDepthInChannel') + dimensionUnitText()
      );
      gridRef.current?.instance.columnOption(
        'minimumDepthinChannel',
        'caption',
        t('labels.minDepthInChannel') + dimensionUnitText()
      );
    }
  }, [dimensionUnit]);

  const gridConfig: IGridConfig = {
    class: 'm-c-grid c-m-c-grid-in-tab m-c-userlist-grid',
    testId: 'userGrid',
    dataSource: searchList,
    dataErrorOccured: onDataErrorOccurred,
    remoteOperations: true,
    defaultColumns: [
      {
        caption: t('labels.portName'),
        dataField: 'portName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.portCode'),
        dataField: 'portCode',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.country'),
        dataField: 'countryName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.portSize'),
        dataField: 'portSize',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.notes'),
        dataField: 'notes',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.salinityOfWater'),
        dataField: 'salinityOfWater',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.latitude'),
        dataField: 'latitude',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.longitude'),
        dataField: 'longitude',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.region'),
        dataField: 'region',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.unrefCode'),
        dataField: 'unrefcode',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.portMaxDepthInnerHarbour') + dimensionUnitText(),
        dataField: 'portMaximumDepthInnerHarbour',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.portMaxDepthOuterHarbour') + dimensionUnitText(),
        dataField: 'portMaximumDepthOuterHarbour',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.portMaxAnchorageDepth') + dimensionUnitText(),
        dataField: 'portMaximumAnchorageDepth',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.portMaxPermittedAge'),
        dataField: 'portMaximumPermittedVesselAge',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.maxDepthInChannel') + dimensionUnitText(),
        dataField: 'maximumDepthinChannel',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.minDepthInChannel') + dimensionUnitText(),
        dataField: 'minimumDepthinChannel',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.distanceFromPilotPoint'),
        dataField: 'distancefromPilotPoint',
        dataType: 'string',
        minWidth: 150,
      },
    ],
    // showSelection: true,
    ref: gridRef,
    showSelection: findFeature('PortManagement.AddPortToList'),
    selectionChanged(e: any) {
      onRowClickCheckbox(e);
    },
    hidePagination: false,
    rowClick: (e: any) => onClickRow(e),
    initialized: onGridInitialized,
    rowClickPermission: 'PortManagement.EditPort',
  };

  return <Grid gridConfig={gridConfig} />;
}
