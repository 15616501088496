import React, { useEffect, useState } from 'react';
import VetOutcomeReport from '../report/vetOutcome/VetOutcomeReport';
import BerthfitReport from '../report/berthfit/BerthfitReport';
import { useLoaderContext } from '../../contexts/LoaderContext';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import { MarsApiService as api } from '../../api/mars-api-service';
import * as html2pdf from 'html2pdf.js';
import { useDateFormat } from '../../hooks/useDateFormat';

export default function ReportToPdf(props: {
  report?: any;
  setReport?: any;
  reportDetails?: any;
  generateReport?: any;
  setGenerateReport?: any;
  isBerthFitReport?: any;
  berthReportDetails?: any;
  pdfFile?: any[];
  setPdfFile?: any;
  isAttachPdf?: boolean;
  setIsAttachPdf?: any;
  attachCallBack?: any;
  isBerthFitReportVessel?: any;
  isNotRecommended?: any;
  reviewComment?: any;
  berthFitComment?: any;
  isRecommend?: any;
  setIsRecommend?: any;
  reportGeneratedDate?: any;
}) {
  const {
    report,
    setReport,
    reportDetails,
    isBerthFitReport,
    berthReportDetails,
    pdfFile,
    setPdfFile,
    isAttachPdf,
    setIsAttachPdf,
    attachCallBack,
    isBerthFitReportVessel,
    isNotRecommended,
    reviewComment,
    berthFitComment,
    isRecommend,
    setIsRecommend,
    reportGeneratedDate,
  } = props;
  const [showPdf, setShowPdf] = useState(false);
  const { setIsLoading } = useLoaderContext();
  const [logo, setLogo] = useState<any>();
  const { formatDate } = useDateFormat();

  useEffect(() => {
    if (report) {
      initialLoad();
    }
  }, [report]);

  const initialLoad = async () => {
    if (report) {
      if (isBerthFitReport) {
        await getLogoImageData(berthReportDetails?.attachmentId);
        generatePdf(isBerthFitReport);
      } else {
        await getLogoImageData(reportDetails?.attachmentId);
        generatePdf(isBerthFitReport);
      }
    }
  };

  const getLogoImageData = async (attachmentId: any) => {
    if (attachmentId) {
      await api
        .get({
          url: apiConfig.deleteEditAttachment + attachmentId,
          responseType: 'blob',
        })
        .then((res) => {
          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(new Blob([res]));
          setLogo(imageUrl);
        });
    }
  };

  const generatePdf = (isBerthFit: boolean) => {
    setIsLoading(true);
    setTimeout(async () => {
      setShowPdf(true);
      const divToPrint = document.querySelectorAll('#element-to-print') as any;

      const options = {
        jsPDF: {
          format: 'a4',
          compressPdf: true,
        },
        // html2canvas:  { letterRendering: true, useCORS: true,     logging: true},
        margin: [5, 5, 5, 5],
        html2canvas: { scale: 2.5 },
        image: { type: 'jpeg', quality: 1 },
        pagebreak: { mode: 'avoid-all' },
      };
      let worker = html2pdf().set(options).from(divToPrint[0]).toPdf();
      divToPrint.forEach((page: any, index: number) => {
        if (index !== 0) {
          worker = worker
            .get('pdf')
            .then((pdf: any) => {
              pdf.addPage();
            })
            .from(page)
            .toContainer()
            .toCanvas()
            .toPdf();
        }
      });
      if (isAttachPdf) {
        worker = worker.outputPdf('blob').then((res: any) => {
          const pdfs = new File(
            [res],
            isBerthFit
              ? berthReportDetails?.vetId + '_BerthFitReport.pdf'
              : reportDetails?.vetId + '_VetReport.pdf'
          );
          setPdfFile([...(pdfFile as any[]), pdfs]);
          setIsAttachPdf(false);
          setIsRecommend(false);
          setShowPdf(false);
          setIsLoading(false);
          setReport(false);
          if (isAttachPdf && !isBerthFitReport && attachCallBack) {
            attachCallBack(true);
          }
        });
      } else {
        worker = worker
          .save(
            isBerthFit
              ? isBerthFitReportVessel
                ? berthReportDetails?.berthfitId + 'BerthFitReport.pdf'
                : berthReportDetails?.vetId + '_BerthFitReport.pdf'
              : reportDetails?.vetId + '_VetReport.pdf'
          )
          .then(() => {
            setShowPdf(false);
            setIsLoading(false);
            setReport(false);
          });
      }
    }, 1000);
  };
  const currentDate = new Date().toISOString();
  const reportDate =
    !isBerthFitReport && reportGeneratedDate
      ? formatDate(reportGeneratedDate)
      : formatDate(currentDate);

  const HtmlContent = () => {
    if (isBerthFitReport) {
      return (
        <BerthfitReport
          reportDetails={berthReportDetails}
          isAttachPdf={isAttachPdf}
          reviewComment={berthFitComment}
          logo={logo}
        />
      );
    } else {
      return (
        <VetOutcomeReport
          reportDetails={reportDetails}
          isNotRecommended={isNotRecommended}
          isAttachPdf={isAttachPdf}
          reviewComment={reviewComment}
          isRecommend={isRecommend}
          logo={logo}
          reportDate={reportDate}
        />
      );
    }
  };

  return <>{showPdf && <HtmlContent />}</>;
}
