import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormConfig } from '../../../../../types/form-builder';
import FormBuilder from '../../../../common/form-builder/FormBuilder';

export default function PasswordSettingsForm() {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const passwordFormConfig: IFormConfig[] = [
    {
      class: 'col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'minimumLength',
      label: t('labels.minimumLength'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'minimumUpperCase',
      label: t('labels.minimumUpperCase'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'minimumLowerCase',
      label: t('labels.minimumLowerCase'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class: 'col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'minimumSepcialCharacter',
      label: t('labels.minimumSepcialCharacter'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'minimumNumericCharacter',
      label: t('labels.minimumNumericCharacter'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={passwordFormConfig} />
      </div>
    </>
  );
}
