import { useCallback, useContext } from 'react';
import { format, parseISO } from 'date-fns';
import { MasterDataContext } from '../contexts/MasterDataContext';

export const useDateFormat = () => {
  const { dateFormat } = useContext(MasterDataContext);

  const formatDate = useCallback(
    (date: any) => {
      if (date) {
        return format(parseISO(date), dateFormat ? dateFormat : 'dd MMM yyyy');
      } else {
        return null;
      }
    },
    [dateFormat]
  );

  const apiDateFormat = useCallback((date?: any) => {
    if (date) {
      return format(new Date(date), "yyyy-MM-dd'T'00:00:00.000'Z'");
    } else {
      return null;
    }
  }, []);

  const apiDateTimeFormat = useCallback((date?: any) => {
    if (date) {
      return new Date(date).toISOString();
    }
    return null;
  }, []);

  const apiDateAndTimeFormat = useCallback((date?: any) => {
    if (date) {
      const dateFormated = format(new Date(date), 'dd MMM yyyy hh:mm a');
      return dateFormated;
    } else {
      return null;
    }
  }, []);

  const mailDateFormat = useCallback((date?: any) => {
    if (date) {
      const dateFormated = format(new Date(date), 'dd MMM yyyy');
      const timeFormated = format(new Date(date), 'H:MM ');
      return dateFormated + ' at ' + timeFormated;
    } else {
      return null;
    }
  }, []);

  const UTCFormat = useCallback((date?: any) => {
    if (date) {
      date = date.replace('Z', '');
      const dateFormated = format(parseISO(date), 'dd MMM yyyy HH:mm');
      return dateFormated + ' UTC';
    } else {
      return null;
    }
  }, []);

  const dateTimeWithTimeZone = useCallback((date?: any) => {
    if (date) {
      const dateFormated = format(new Date(date), 'dd MMM yyyy hh:mm a O');
      return dateFormated;
    } else {
      return null;
    }
  }, []);

  return {
    dateFormat,
    formatDate,
    apiDateFormat,
    apiDateTimeFormat,
    apiDateAndTimeFormat,
    mailDateFormat,
    UTCFormat,
    dateTimeWithTimeZone,
  };
};
