import React from 'react';
import imageURL from '../../../../assets/images';
import ReportViewer from '../../report-viewer/ReportViewer';

export default function Report(props: {
  showRepot: any;
  queryId: any;
  layoutId: any;
  reportCode: any;
  reportName: any;
  runNow: any;
}) {
  const { showRepot, queryId, layoutId, reportCode, reportName, runNow } =
    props;
  return (
    <div className="m-l-inner-page-body">
      {showRepot ? (
        <ReportViewer
          queryId={queryId}
          layoutId={layoutId}
          reportCode={reportCode}
          reportName={reportName}
          runNow={runNow}
        />
      ) : (
        <div className="m-l-form-panel-body-block">
          <div>
            <div className="m-l-report-avatar">
              <img src={imageURL.noResults} className="img-fluid" />
            </div>
            <span className="m-l-no-info">
              Please click on Run Now to generate report.
            </span>{' '}
          </div>
        </div>
      )}
    </div>
  );
}
