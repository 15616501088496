import Button from 'devextreme-react/button';
import Popover from 'devextreme-react/popover';
import SelectBox from 'devextreme-react/select-box';
import React, { useEffect, useMemo, useState } from 'react';
import icons from '../../../common/icons/icons';
import { useTranslation } from 'react-i18next';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import { getCompanyType } from '../../../../utils/jwt-decode';
import { CompanyType } from '../../../../enums/company-type-enum';
import { useResize } from '../../../../hooks/useResize';

export default function BuListCopyPopover(props: {
  showPopover: boolean;
  setShowPopover: any;
  listIds: any;
  handleCopyToButtonClick: (
    ruleObject: any,
    companyName: string,
    buName: string
  ) => void;
  target: any;
  selectedCompanyId?: any;
  selectedBuId?: any;
}) {
  const {
    setShowPopover,
    listIds,
    handleCopyToButtonClick,
    target,
    selectedCompanyId,
    selectedBuId,
  } = props;

  const { t } = useTranslation();
  const [companyList, setCompanies] = useState([]);
  const [buList, setBuList] = useState([]);
  const [companyId, setCompanyId] = useState(Number);
  const [buId, setBuId] = useState(Number);
  const [companyName, setCompanyName] = useState() as any;
  const [buName, setBuName] = useState() as any;
  const companyType = getCompanyType();

  const getRequestorCompaniesList = async () => {
    await api
      .get({ url: apiConfig.company })
      .then((data) => {
        const companyData = data?.data;
        // const loggedIncompanyId = getCompanyId();
        const fromIndex = companyData?.findIndex(
          (x: any) => x?.companyId == selectedCompanyId
        );
        const element = companyData?.splice(fromIndex, 1)[0];
        const toIndex = 0;
        companyData?.splice(toIndex, 0, element);
        setCompanies(companyData);
        if (companyType != CompanyType.Mars) {
          setCompanyId(element.companyId);
        }
      })
      .catch((e) => {
        // todo
      });
  };

  useEffect(() => {
    getRequestorCompaniesList();
  }, []);

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-add-roles__popup',
      'data-testid': 'BuListCopyPopover',
    };
  }, []);

  const onChangeCompany = (e: any) => {
    const companyId = e.value;
    setCompanyName(null);
    setBuName(null);
    setCompanyId(companyId);
    const company: any = companyList.filter((x: any) => {
      return x.companyId === companyId;
    });
    setCompanyName(company[0]?.companyName);
    getBusinessUnitList(companyId);
  };
  const getBusinessUnitList = async (companyId: number) => {
    if (companyId && companyId !== undefined) {
      await api
        .get({ url: apiConfig.companyBusinessUnits + companyId })
        .then((data) => {
          const buData = data?.data;
          if (companyId == selectedCompanyId) {
            const bufromIndex = buData?.findIndex(
              (x: any) => x?.buId == selectedBuId
            );
            const remove = 0;
            buData?.splice(bufromIndex, remove);
            setBuList(buData);
          } else {
            setBuList(buData);
          }
        });
    }
  };
  const onChangeBu = (e: any) => {
    const buId = e.value;
    setBuId(buId);
    const bu: any = buList.filter((x: any) => {
      return x.buId === buId;
    });
    setBuName(bu[0]?.buName);
  };

  const onClickCancel = () => {
    setCompanyId(Number);
    setBuId(Number);
    setShowPopover(false);
  };

  const onClickCopyToBtn = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      const ruleObject: any = {
        listIds: listIds,
        buId: buId,
      };
      handleCopyToButtonClick(ruleObject, companyName, buName);
    }
    return;
  };

  const { isMobileResolution } = useResize();
  const [position, setPosition] = useState<any>('bottom');
  useEffect(() => {
    if (isMobileResolution) {
      setPosition('left');
    } else {
      setPosition('bottom');
    }
  }, [isMobileResolution]);

  return (
    <Popover
      id="rolesPopover"
      // target="#copyButton"
      target={target}
      position={position}
      width={300}
      closeOnOutsideClick={true}
      visible={props.showPopover}
      onHiding={() => props.setShowPopover(false)}
      wrapperAttr={popupAttributes}
      hideOnParentScroll={false}
    >
      <ValidationGroup>
        <div className="m-l-grid-clone-popup-wrap">
          <div className="m-l-grid-clone-popup-header m-l-grid-copy--popup-header">
            <h2>{t('labels.copyListTo')}</h2>
            <div className="m-l-header-close__icon-block">
              <Button
                className="app-c-btn app-c-icon-only-btn"
                onClick={onClickCancel}
                elementAttr={{ 'data-testid': 'close' }}
              >
                <icons.IconCross />
              </Button>
            </div>
          </div>
          <div className="m-l-grid-clone-popup-body">
            <div className="m-c-form-group mb-btm-space">
              <label className="m-l-input-label">{t('labels.company')}</label>
              <SelectBox
                searchEnabled={true}
                valueExpr="companyId"
                displayExpr="companyName"
                disabled={companyType != CompanyType.Mars}
                value={companyId}
                elementAttr={{ 'data-testid': 'company' }}
                dataSource={companyList}
                onValueChanged={(e) => onChangeCompany(e)}
                className="m-c-input-control m-c-select-box"
                id="companySelectBox"
                validationMessageMode="always"
              >
                <Validator>
                  <RequiredRule message={t('errors.required')} />
                </Validator>
              </SelectBox>
            </div>
            <div className="m-c-form-group mb-btm-space">
              <label className="m-l-input-label">{t('labels.bu')}</label>
              <SelectBox
                searchEnabled={true}
                valueExpr="buId"
                displayExpr="buName"
                elementAttr={{ 'data-testid': 'bu' }}
                dataSource={buList}
                onValueChanged={(e) => onChangeBu(e)}
                className="m-c-input-control m-c-select-box"
                validationMessageMode="always"
              >
                <Validator>
                  <RequiredRule message={t('errors.pleaseSelectABU')} />
                </Validator>
              </SelectBox>
            </div>
          </div>
          <div className="m-l-grid-clone-popup-footer">
            <Button
              className="app-c-btn app-c-btn--secondary"
              onClick={(e) => onClickCopyToBtn(e)}
              elementAttr={{ 'data-testid': 'copy' }}
            >
              {t('labels.copyTo')}
            </Button>
          </div>
        </div>
      </ValidationGroup>
    </Popover>
  );
}
