import Button from 'devextreme-react/button';
import Popover from 'devextreme-react/popover';
import TextBox from 'devextreme-react/text-box';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResize } from '../../../hooks/useResize';
import { IRuleClone } from '../../../types/rules';

export default function RuleClone(props: {
  showPopover: boolean;
  ruleId: number;
  onClickCancel: () => void;
  target: string;
  closeOnOutsideClick: boolean;
  onHidingPopover: any;
  cloneRule: (ruleObject: any) => void;
}) {
  const {
    showPopover,
    ruleId,
    onClickCancel,
    target,
    closeOnOutsideClick,
    onHidingPopover,
    cloneRule,
  } = props;

  const popupAttributes = useMemo(() => {
    return {
      'data-testid': 'ruleClonePopover',
    };
  }, []);

  const [ruleName, setRuleName] = useState('');

  const { t } = useTranslation();

  const onClickClone = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      const ruleObject: IRuleClone = {
        ruleId: ruleId,
        ruleName: ruleName,
      };
      cloneRule(ruleObject);
    }
  };

  const { isMobileResolution } = useResize();
  const [position, setPosition] = useState<any>('bottom');
  useEffect(() => {
    if (isMobileResolution) {
      setPosition('left');
    } else {
      setPosition('bottom');
    }
  }, [isMobileResolution]);

  return (
    <>
      <Popover
        wrapperAttr={popupAttributes}
        id="rulesPopover"
        target={target}
        position={position}
        width={300}
        closeOnOutsideClick={closeOnOutsideClick}
        visible={showPopover}
        onHiding={onHidingPopover}
        hideOnParentScroll={false}
      >
        <div className="m-l-grid-clone-popup-wrap">
          <div className="m-l-grid-clone-popup-header">
            <h2>{t('labels.newRuleName')}</h2>
          </div>
          <div className="m-l-grid-clone-popup-body">
            <div className="m-c-form-group ">
              <TextBox
                inputAttr={{ 'data-testid': 'ruleName' }}
                className="m-c-input-control"
                onValueChanged={(e: any) => {
                  setRuleName(e.value);
                }}
                value={ruleName}
              >
                <Validator>
                  <RequiredRule message={t('errors.required')} />
                </Validator>
              </TextBox>
            </div>
          </div>
          <div className="m-l-grid-clone-popup-footer">
            <Button
              className="app-c-btn app-c-btn--secondary"
              onClick={onClickCancel}
              elementAttr={{ 'data-testid': 'cancel' }}
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              className="app-c-btn app-c-btn--secondary"
              onClick={onClickClone}
              elementAttr={{ 'data-testid': 'clone' }}
            >
              {t('buttons.clone')}
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
}
