import Button from 'devextreme-react/button';
import CheckBox from 'devextreme-react/check-box';
import Popover from 'devextreme-react/popover';
import ScrollView from 'devextreme-react/scroll-view';
import TextBox from 'devextreme-react/text-box';
import React, { useEffect, useMemo, useState } from 'react';
import { useResize } from '../../../hooks/useResize';
import icons from '../../common/icons/icons';

export default function RolesPopover(props: {
  onClickAdd: any;
  onSelectRole: any;
  roles: any[];
  showPopover: boolean;
  target: string;
  closeOnOutsideClick: boolean;
  onHidingPopover: any;
}) {
  const {
    onSelectRole,
    showPopover,
    target,
    closeOnOutsideClick,
    onHidingPopover,
    roles,
    onClickAdd,
  } = props;
  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-add-roles__popup',
      'data-testid': 'rolePopover',
    };
  }, []);

  const [filteredRoles, setFilteredRoles] = useState(roles);

  const onInputSearch = (e: any) => {
    setFilteredRoles(() => {
      return roles.filter((item) =>
        item.name.toLowerCase().includes(e.event.target.value)
      );
    });
  };

  const { isMobileResolution } = useResize();
  const [position, setPosition] = useState<any>('bottom');
  useEffect(() => {
    if (isMobileResolution) {
      setPosition('left');
    } else {
      setPosition('bottom');
    }
  }, [isMobileResolution]);

  return (
    <Popover
      id="rolesPopover"
      target={target}
      position={position}
      width={300}
      closeOnOutsideClick={closeOnOutsideClick}
      visible={showPopover}
      onHiding={onHidingPopover}
      wrapperAttr={popupAttributes}
      hideOnParentScroll={false}
    >
      <div className="m-l-grid-clone-popup-wrap">
        <div className="m-l-grid-clone-popup-header">
          <h2>Select Roles</h2>
          <div className="m-c-form-group m-l-popover-search-box-block">
            <div className="m-c-search-icon">
              <icons.Search />
            </div>
            <TextBox
              inputAttr={{ 'data-testid': 'searchRole' }}
              placeholder="Search"
              className="m-c-input-control"
              onInput={onInputSearch}
            ></TextBox>
          </div>
        </div>
        <ScrollView>
          <div className="m-l-grid-clone-popup-body u-pl0 u-pr0 u-pt0">
            <ul className="m-l-checkbox-list-wrap">
              {filteredRoles.map((role) => {
                return (
                  <li key={role.id}>
                    <div className="m-c-checkbox m-c-checkbox-dark">
                      <CheckBox
                        elementAttr={{ 'data-testId': role.name }}
                        text={role.name}
                        onValueChanged={(e: any) => onSelectRole(e, role)}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </ScrollView>
        <div className="m-l-grid-clone-popup-footer">
          <Button
            className="app-c-btn app-c-btn--secondary"
            onClick={onClickAdd}
            elementAttr={{ 'data-testid': 'roleAddButton' }}
          >
            Add
          </Button>
        </div>
      </div>
    </Popover>
  );
}
