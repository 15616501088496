import React, { useState, useMemo, useEffect, useRef } from 'react';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import imageURL from '../../assets/images';
import { Button } from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import Tooltip from 'devextreme-react/tooltip';
import { IModalConfig } from '../../types/modal';
import Modal from '../common/modal/Modal';
import icons from '../common/icons/icons';
import { IChangePassword } from '../../types/change-password';
import { MarsApiService as api } from '../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import Validator from 'devextreme-react/validator';
import { useLoaderContext } from '../../contexts/LoaderContext';
import { getAdditionalInfo } from '../../utils/jwt-decode';
import { useTranslation } from 'react-i18next';
import ValidationGroup from 'devextreme-react/validation-group';
import toast from '../../utils/toast';
import { IAddNewVessel } from '../../types/add-new-vessel';
import { useNavigate } from 'react-router';
import { passwordLengthEnum } from '../../enums/password-length';
import NumberBox from 'devextreme-react/number-box';

export default function AddNewVessel(props: {
  isAddNewVessel: any;
  setIsAddNewVessel: any;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const validationGroupShipName: any = useRef();
  const validationGroupIMONumber: any = useRef();
  const validationGroupVINNumber: any = useRef();
  const [shipName, setShipName] = React.useState('');
  const [imoNumber, setIMONumber] = React.useState('');
  const [vinNumber, setVINNumber] = React.useState('');
  const { isAddNewVessel, setIsAddNewVessel } = props;

  const { setIsLoading } = useLoaderContext();

  const popupAttributesChangePWD = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-modal-change__pwd',
    };
  }, []);

  const handleCancelClick = (e: any) => {
    setIsAddNewVessel(false);
    clearFields();
  };

  const clearFields = () => {
    setShipName('');
    setIMONumber('');
    setVINNumber('');
  };

  const modalConfigChangePWD: IModalConfig = {
    width: 400,
    height: 434,
    visible: isAddNewVessel,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const onAddNewVessel = (e: any) => {
    if (
      validationGroupShipName.current?.instance.validate().isValid &&
      validationGroupIMONumber.current?.instance.validate().isValid &&
      validationGroupVINNumber.current?.instance.validate().isValid
    ) {
      if (!imoNumber && !vinNumber) {
        toast.error({
          title: t('toast.enterImoOrVinNumber'),
        });
      } else {
        const roleJsonEdit: IAddNewVessel = {
          shipName: shipName,
          imoNumber: imoNumber?.toString(),
          vinNumber: vinNumber,
        };
        api
          .post(
            { url: apiConfig.createVessel, data: roleJsonEdit },
            setIsLoading
          )
          .then((response) => {
            if (response.status == true) {
              handleCancelClick(e);
              toast.success({
                title: t('toast.vesselAddedSuccess'),
              });
              navigate('/vessel-info', {
                state: { imoNumber: response.imoNumber },
              });
            }
          });
      }
    }
    return;
  };

  return (
    <div>
      {isAddNewVessel && (
        <Modal
          modalConfig={modalConfigChangePWD}
          wrapperAttr={popupAttributesChangePWD}
        >
          <div className="m-l-confirmation-dialogbox-wrap">
            <div className="m-l-confirm-dialogbox-header m-l-cmn-modal-header">
              <div className="m-l-hdr-mdl-title">
                {t('labels.addNewVessel')}
              </div>
              <Button icon="close" onClick={handleCancelClick}></Button>
            </div>
            <div className="m-l-confirm-dialogbox-body">
              <ScrollView width="100%" height="100%">
                <div className="m-l-pwd-chng-wrap">
                  <form>
                    <div className="m-c-form-group mb-btm-space-24">
                      <label className="m-l-input-label">
                        {t('labels.vesselName')}
                        <span className="m-l-input-required">*</span>
                      </label>
                      <ValidationGroup ref={validationGroupShipName}>
                        <TextBox
                          className="m-c-input-control m-c-input-text"
                          value={shipName}
                          onValueChanged={(e) => {
                            setShipName(e.value);
                          }}
                          validationMessageMode="always"
                        >
                          <Validator
                            validationRules={[
                              {
                                type: 'required',
                                message: t('errors.required'),
                              },
                            ]}
                          />
                        </TextBox>
                      </ValidationGroup>
                    </div>
                    <div className="m-c-form-group mb-btm-space-24">
                      <div className="m-l-label-icon-wrap">
                        <label className="m-l-input-label">
                          {t('labels.imoNumber')}
                        </label>
                      </div>
                      <ValidationGroup ref={validationGroupIMONumber}>
                        <NumberBox
                          className="m-c-input-control m-c-input-text"
                          value={imoNumber ? parseInt(imoNumber) : undefined}
                          inputAttr={{
                            maxlength: 10,
                          }}
                          onValueChanged={(e) => {
                            setIMONumber(e.value);
                          }}
                          validationMessageMode="always"
                        >
                          <Validator
                            validationRules={[
                              {
                                type: 'stringLength',
                                max: passwordLengthEnum.imoNumberLength,
                                message: t('errors.imoNumberLengthError', {
                                  imoLength: passwordLengthEnum.imoNumberLength,
                                }),
                              },
                            ]}
                          />
                        </NumberBox>
                      </ValidationGroup>
                    </div>
                    <div className="m-c-form-group mb-btm-space-24">
                      <label className="m-l-input-label">
                        {t('labels.vinNumber')}
                      </label>
                      <ValidationGroup ref={validationGroupVINNumber}>
                        <TextBox
                          className="m-c-input-control m-c-input-text"
                          value={vinNumber}
                          maxLength={10}
                          onValueChanged={(e) => {
                            setVINNumber(e.value);
                          }}
                          validationMessageMode="always"
                        >
                          <Validator
                            validationRules={[
                              {
                                type: 'stringLength',
                                max: passwordLengthEnum.vinNumberLength,
                                message: t('errors.vinNumberLengthError', {
                                  vinLength: passwordLengthEnum.vinNumberLength,
                                }),
                              },
                            ]}
                          />
                        </TextBox>
                      </ValidationGroup>
                    </div>
                  </form>
                </div>
              </ScrollView>
            </div>
            <div className="m-l-chp-mdl-footer">
              <Button
                className="app-c-btn app-c-btn--secondary"
                onClick={handleCancelClick}
              >
                {t('labels.cancel')}
              </Button>
              <Button
                className="app-c-btn app-c-btn--primary"
                onClick={onAddNewVessel}
              >
                {t('labels.save')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
