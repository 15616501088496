import React, { useEffect, useMemo, useState } from 'react';
import Modal from '../common/modal/Modal';
import SelectBox from 'devextreme-react/select-box';
import ScrollView from 'devextreme-react/scroll-view';
import icons from '../common/icons/icons';
import { Button } from 'devextreme-react/button';
import { IModalConfig } from '../../types/modal';
import { MarsApiService as api } from '../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import { useAuth } from '../../contexts/AuthProvider';
import { useTranslation } from 'react-i18next';
import toast from '../../utils/toast';
import { useLoaderContext } from '../../contexts/LoaderContext';
import { getRememberMe, getUserId } from '../../utils/jwt-decode';
import { usePermission } from '../../contexts/PermissionContext';

export default function SwitchBU(props: any) {
  const { isVisible, handleCancelClick } = props;
  const auth = useAuth();
  const { getFeatureList } = usePermission();
  const [selectedBu, setSelectedBu] = useState();
  const { t } = useTranslation();
  const storage: Storage =
    getRememberMe() === 'true' ? localStorage : sessionStorage;
  const bu: any = storage.getItem('buList');
  const buList = JSON.parse(bu);
  const { setIsLoading } = useLoaderContext();
  const modalConfig: IModalConfig = {
    width: 390,
    height: 418,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  useEffect(() => {
    const defaultBu = buList
      .filter((bu: any) => bu.isDefault == true)
      .map((bu: any) => bu.buId);
    setSelectedBu(defaultBu[0]);
  }, []);

  const changeDefault = () => {
    buList.map((item: any) => {
      if (item.buId == selectedBu) {
        item.isDefault = true;
      } else {
        item.isDefault = false;
      }
    });
    storage.setItem('buList', JSON.stringify(buList));
  };

  const switchBu = async () => {
    const user = getUserId();
    const filteredBu = buList.filter((bu: any) => bu.buId == selectedBu);
    const switchBuObject = {
      userId: user,
      buId: selectedBu,
      isDefault: true,
    };
    await api
      .put({ url: apiConfig.switchBu, data: switchBuObject }, setIsLoading)
      .then((data) => {
        handleCancelClick(),
          changeDefault(),
          toast.success({ title: t('toast.buChange') }),
          auth.switchBu(filteredBu[0]);
        getFeatureList();
      });
  };

  const onBuChange = (event: any) => {
    setSelectedBu(event.value);
  };

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-modal-switch__bu',
    };
  }, []);

  return (
    <div className="m-l-model-popup__wrap" data-testid="switchBU">
      <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
        <div className="m-l-confirmation-dialogbox-wrap">
          <div className="m-l-confirm-dialogbox-header">
            <Button icon="close" onClick={handleCancelClick}></Button>
          </div>
          <br></br>
          <div className="m-l-confirm-dialogbox-body">
            <ScrollView width="100%" height="100%">
              <div className="m-l-confirm-dialogbox-title-block">
                <icons.Confirmation />
                <div className="m-l-confirmation-dialogbox-title">
                  {t('headers.switchBusinessUnit')}
                </div>
              </div>
              <div className="m-l-confirmation-info">
                {t('toast.selectBusinessUnit')}
              </div>
              <div className="m-l-confirmation-selection-field">
                <div className="mb-btm-space m-c-form-group">
                  <label className="m-l-input-label">
                    {' '}
                    {t('labels.businessUnit')}{' '}
                  </label>
                  <SelectBox
                    name="switchBuId"
                    dataSource={buList}
                    displayExpr="buName"
                    valueExpr="buId"
                    onValueChanged={onBuChange}
                    className="m-c-input-control m-c-select-box"
                    defaultValue={selectedBu}
                  ></SelectBox>
                </div>
              </div>
            </ScrollView>
          </div>
          <div className="m-l-confirm-dialogbox-footer">
            <Button
              className="app-c-btn app-c-btn--primary"
              onClick={switchBu}
              elementAttr={{ 'data-testid': 'switchButton' }}
            >
              {t('buttons.switch')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
