import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDimensionsUnit } from '../../../../hooks/useDimensionsUnit';
import { IFormConfig } from '../../../../types/form-builder';
import FormBuilder from '../../../common/form-builder/FormBuilder';

export default function VesselRestrictionsAtApproachesForm(props: {
  shipTypes: any;
}) {
  const { t } = useTranslation();
  const { dimensionUnitText } = useDimensionsUnit();
  const { shipTypes } = props;

  const { control } = useFormContext();

  const formConfig: IFormConfig[] = [
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'shipType',
      label: 'Ship Type',
      control: control,
      fieldType: 'select',
      dataSource: shipTypes,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'airDraught',
      label: t('labels.airDraught'),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'maxDraftAtApproaches',
      label: t('labels.maxDraftAtApproaches') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'depthAtApproaches',
      label: t('labels.depthAtApproaches') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'minUkcValue',
      label: t('labels.minUkcAtApproaches') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'minUkcAtApproaches',
      label: t('labels.minUkcAtApproaches') + ' (%)',
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'custom',
          message: t('errors.maxHundred'),
          validationCallback: (params: any) => {
            if (params.value) {
              return params.value <= 100;
            }
            return true;
          },
          ignoreEmptyValue: true,
        },
      ],
    },
  ];

  return (
    <div className="row m-l-custom-sixteen-space-row">
      <FormBuilder formConfig={formConfig} />
    </div>
  );
}
