import React from 'react';
import { attachmentTypeEnum } from '../../../../enums/attachment-type-enum';
import FileUploaderModal from '../../../common/file-uploader-modal/FileUploaderModal';

export default function AddAttachment(props: {
  isVisible: boolean;
  handleCancelClick: any;
  vetRequestId?: string;
  imoNumber?: any;
  berthFitId?: any;
  setAttachmentsAdded?: any;
  attachmentsAdded?: any;
  tempVetId?: string;
  setTempVetId?: any;
  isFromVetCreation?: boolean;
}) {
  const {
    isVisible,
    handleCancelClick,
    vetRequestId,
    imoNumber,
    berthFitId,
    setAttachmentsAdded,
    attachmentsAdded,
    tempVetId,
    setTempVetId,
    isFromVetCreation,
  } = props;
  const attachmentType = attachmentTypeEnum.Vessel;
  const attachmentTypeVet = attachmentTypeEnum.Vet;
  const attachmentTypeBerth = attachmentTypeEnum.Berth;

  return (
    <div>
      <FileUploaderModal
        handleCancelClick={handleCancelClick}
        attachmentType={attachmentType}
        attachmentTypeVet={attachmentTypeVet}
        attachmentTypeBerth={attachmentTypeBerth}
        isVisible={isVisible}
        vetRequestId={vetRequestId}
        isFromVetCreation={isFromVetCreation}
        imoNumberFromCreateVet={imoNumber}
        berthFitId={berthFitId}
        setAttachmentsAdded={setAttachmentsAdded}
        attachmentsAdded={attachmentsAdded}
        tempVetId={tempVetId}
        setTempVetId={setTempVetId}
      />
    </div>
  );
}
