import { Button } from 'devextreme-react/button';
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import toast from '../../../../../utils/toast';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../../../types/dialog';
import EquipmentForm from '../equipment-form/EquipmentForm';
import { IEquipmentForm } from '../../../../../types/vessel-equipment-tab';
import { useLoaderContext } from '../../../../../contexts/LoaderContext';
import { isTabDirtyContext } from '../../../../../pages/vessel-info/VesselInformation';

export default function EditEquipment(props: {
  setIsEdit: any;
  setEquipmentView: any;
  equipmentDetails: any;
  getEquipmentDetails: any;
  imoNumber?: string;
}) {
  const {
    setIsEdit,
    setEquipmentView,
    equipmentDetails,
    getEquipmentDetails,
    imoNumber,
  } = props;
  const { t } = useTranslation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isconfirmBackDialog, setIsConfirmBackDialog] = useState(false);
  const { setIsLoading } = useLoaderContext();
  const setTabDirtyFlag = useContext(isTabDirtyContext);
  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmSaveChanges'),
    handleSubmit: () => {
      onValueSubmit();
      setIsConfirmModalOpen(false);
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };
  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setIsEdit(false);
      setEquipmentView(true);
      setIsConfirmBackDialog(false);
      setTabDirtyFlag.setState(
        setTabDirtyFlag.state == 0
          ? setTabDirtyFlag.state
          : setTabDirtyFlag.state - 1
      );
    },
    handleClose: () => {
      setIsConfirmBackDialog(false);
    },
  };

  const defaultEquipmentFormValues: IEquipmentForm = {
    imoNumber: '',
    propeller: 0,
    propellerDescription: '',
    propulsionType: '',
    craneSWL: 0,
    numberOfCranes: 0,
    manifold: '',
    manifoldSize: '',
    inertGas: '',
    bowStoppersNo: 0,
    bowStoppersSWL: 0,
    mooringRope: 0,
    mooringRopeMaterial: '',
    mooringRopeMBL: '',
    mooringDrums: 0,
  };

  const equipmentForm = useForm({
    defaultValues: defaultEquipmentFormValues,
  });

  const onHandleSave = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      setIsConfirmModalOpen(true);
    }
  };
  const { isDirty } = equipmentForm.formState;

  const handleBackClick = () => {
    isDirty
      ? setIsConfirmBackDialog(true)
      : (setIsEdit(false), setEquipmentView(true));
  };

  useEffect(() => {
    equipmentForm.reset();
    if (equipmentDetails) {
      equipmentForm.reset(
        {
          imoNumber: imoNumber,
          propeller: equipmentDetails.propeller,
          propellerDescription: equipmentDetails.propellerDescription,
          propulsionType: equipmentDetails.propulsionType,
          craneSWL: equipmentDetails.craneSWL,
          numberOfCranes: equipmentDetails.numberOfCranes,
          manifold: equipmentDetails.manifold,
          manifoldSize: equipmentDetails.manifoldSize,
          inertGas: equipmentDetails.inertGas,
          bowStoppersNo: equipmentDetails.bowStoppersNo,
          bowStoppersSWL: equipmentDetails.bowStoppersSWL,
          mooringRope: equipmentDetails.mooringRope,
          mooringRopeMaterial: equipmentDetails.mooringRopeMaterial,
          mooringRopeMBL: equipmentDetails.mooringRopeMBL,
          mooringDrums: equipmentDetails.mooringDrums,
        },
        { keepDirty: true }
      );
    }
  }, [equipmentDetails]);

  const onValueSubmit = () => {
    const equipmentFormValue: IEquipmentForm = equipmentForm.getValues();
    const roleJsonEdit: IEquipmentForm = {
      imoNumber: imoNumber,
      propeller: equipmentFormValue.propeller,
      propellerDescription: equipmentFormValue.propellerDescription,
      propulsionType: equipmentFormValue.propulsionType,
      craneSWL: equipmentFormValue.craneSWL,
      numberOfCranes: equipmentFormValue.numberOfCranes,
      manifold: equipmentFormValue.manifold,
      manifoldSize: equipmentFormValue.manifoldSize,
      inertGas: equipmentFormValue.inertGas,
      bowStoppersNo: equipmentFormValue.bowStoppersNo,
      bowStoppersSWL: equipmentFormValue.bowStoppersSWL,
      mooringRope: equipmentFormValue.mooringRope,
      mooringRopeMaterial: equipmentFormValue.mooringRopeMaterial,
      mooringRopeMBL: equipmentFormValue.mooringRopeMBL,
      mooringDrums: equipmentFormValue.mooringDrums,
      imoDisplayName: equipmentDetails.imoDisplayName,
    };
    api
      .put({ url: apiConfig.equipmentTab, data: roleJsonEdit }, setIsLoading)
      .then((response) => {
        if (response == true) {
          getEquipmentDetails();
          setIsEdit(false);
          setEquipmentView(true);
          setTabDirtyFlag.setState(
            setTabDirtyFlag.state == 0
              ? setTabDirtyFlag.state
              : setTabDirtyFlag.state - 1
          );
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
        }
      });
  };

  useEffect(() => {
    if (isDirty) {
      setTabDirtyFlag.setState(setTabDirtyFlag.state + 1);
    }
  }, [isDirty]);

  return (
    <div>
      <div className="m-l-inner-page-body">
        <div className="m-l-form-panel-block">
          <div className="m-l-tbl-square-wrapper m-l-tbl-square-wrapper--edit-mode">
            <div className="row m-l-custom-eight-space-row">
              <FormProvider {...equipmentForm}>
                <EquipmentForm />
              </FormProvider>
            </div>
            <div className="m-l-panel-right-btn u-pb0">
              <Button
                className="app-c-btn app-c-btn--secondary min-btn-width"
                onClick={handleBackClick}
              >
                Cancel
              </Button>
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                onClick={onHandleSave}
              >
                Save
              </Button>
              <ConfirmDialog
                dialogConfig={confirmSaveDialogConfig}
                isOpen={isConfirmModalOpen}
              />
              <ConfirmDialog
                dialogConfig={confirmBackDialogConfig}
                isOpen={isconfirmBackDialog}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
