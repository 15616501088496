import { Button } from 'devextreme-react/button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDialogConfig } from '../../../../types/dialog';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import toast from '../../../../utils/toast';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import { Restricted } from '../../../../contexts/PermissionContext';

export default function UserApprove(props: {
  userApproveList?: number[];
  userSendForApprovalList?: number[];
  getUserDetails: () => void;
  isFromUserList?: boolean;
  apiUrl?: string;
  isDirty?: boolean;
}) {
  const {
    userApproveList,
    userSendForApprovalList,
    getUserDetails,
    isFromUserList,
    apiUrl,
    isDirty,
  } = props;
  const { t } = useTranslation();
  const { setIsLoading } = useLoaderContext();
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [isSendForApprovalDialogOpen, setIsSendForApprovalDialogOpen] =
    useState(false);
  const approveApi = 'employees/approve';

  const approveConfirmDialog: IDialogConfig = {
    id: 'confirmApproval',
    content: isFromUserList
      ? t('toast.approveUsersConfirm')
      : t('toast.approveUserConfirm'),
    handleSubmit: () => {
      sendForApprovalOrApproveUsers();
      setIsApproveDialogOpen(false);
    },
    handleClose: () => {
      setIsApproveDialogOpen(false);
    },
  };

  const isSendForApprovalConfirmDialog: IDialogConfig = {
    id: 'confirmSendForApproval',
    content: isFromUserList
      ? t('toast.isSendForApprovalUsersConfirm')
      : t('toast.isSendForApprovalUserConfirm'),
    handleSubmit: () => {
      sendForApprovalOrApproveUsers();
      setIsSendForApprovalDialogOpen(false);
    },
    handleClose: () => {
      setIsSendForApprovalDialogOpen(false);
    },
  };

  const handleApproveUser = () => {
    isDirty
      ? toast.error({
          title: t('toast.error'),
          message: t('toast.saveBeforeApprove'),
        })
      : setIsApproveDialogOpen(true);
  };

  const handleSendForApprovalUser = () => {
    setIsSendForApprovalDialogOpen(true);
  };

  const sendForApprovalOrApproveUsers = () => {
    api
      .put(
        {
          url: apiUrl,
          data: {
            employeeIds:
              userApproveList?.length == 0
                ? userSendForApprovalList
                : userApproveList,
          },
        },
        setIsLoading
      )
      .then(() => {
        apiUrl == approveApi
          ? toast.success({ title: t('toast.approvedSuccessfully') })
          : toast.success({ title: t('toast.sendForApprovalSuccess') });
        getUserDetails();
      });
  };

  return (
    <>
      {!isFromUserList &&
        userSendForApprovalList?.length === 0 &&
        userApproveList?.length !== 0 && (
          <Restricted permission="CompanyUsers.ApproveUser">
            <Button
              elementAttr={{ 'data-testid': 'approvalButton' }}
              className="app-c-btn app-c-btn--secondary min-btn-width"
              onClick={handleApproveUser}
            >
              {t('buttons.approve')}
              <ConfirmDialog
                dialogConfig={approveConfirmDialog}
                isOpen={isApproveDialogOpen}
              />
            </Button>
          </Restricted>
        )}

      {!isFromUserList &&
        userApproveList?.length === 0 &&
        userSendForApprovalList?.length !== 0 && (
          <Restricted permission="CompanyUsers.SendUserForApproval">
            <Button
              elementAttr={{ 'data-testid': 'sendForApprovalButton' }}
              className="app-c-btn app-c-btn--secondary min-btn-width"
              onClick={handleSendForApprovalUser}
            >
              {t('buttons.sendForApproval')}
              <ConfirmDialog
                dialogConfig={isSendForApprovalConfirmDialog}
                isOpen={isSendForApprovalDialogOpen}
              />
            </Button>
          </Restricted>
        )}

      {isFromUserList && (
        <>
          <Restricted permission="CompanyUsers.ApproveUser">
            <Button
              elementAttr={{ 'data-testid': 'approvalButton' }}
              className="app-c-btn app-c-btn--secondary min-btn-width"
              onClick={handleApproveUser}
              disabled={
                isFromUserList &&
                (userApproveList?.length === 0 ||
                  userSendForApprovalList?.length !== 0)
              }
            >
              {t('buttons.approve')}
              <ConfirmDialog
                dialogConfig={approveConfirmDialog}
                isOpen={isApproveDialogOpen}
              />
            </Button>
          </Restricted>

          <Restricted permission="CompanyUsers.SendUserForApproval">
            <Button
              elementAttr={{ 'data-testid': 'sendForApprovalButton' }}
              className="app-c-btn app-c-btn--secondary min-btn-width"
              onClick={handleSendForApprovalUser}
              disabled={
                isFromUserList &&
                (userSendForApprovalList?.length === 0 ||
                  userApproveList?.length !== 0)
              }
            >
              {t('buttons.sendForApproval')}
              <ConfirmDialog
                dialogConfig={isSendForApprovalConfirmDialog}
                isOpen={isSendForApprovalDialogOpen}
              />
            </Button>
          </Restricted>
        </>
      )}
    </>
  );
}
