import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import TextArea from 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';
import { Button } from 'devextreme-react/button';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import Modal from '../../../components/common/modal/Modal';
import { IModalConfig } from '../../../types/modal';
import ValidationGroup from 'devextreme-react/validation-group';
import Icons from '../../../components/common/icons/icons';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { MarsApiService as api } from '../../../api/mars-api-service';
import toast from '../../../utils/toast';
import {
  VetRequestStatusCode,
  VetRequestStatus,
} from '../../../enums/vet-request-status-enum';
import { getAPIRoute } from '../../../utils/api-route';
import ScrollView from 'devextreme-react/scroll-view';
import { useLoaderContext } from '../../../contexts/LoaderContext';

export default function AppealDecisionCommentModal(props: {
  appealDecisionModalVisible?: boolean;
  setAppealDecisionvModalVisible?: any;
  vetRequestId?: any;
  appealStatus?: string;
  setAppealStatusChanged?: any;
  appealDecisionvModalTitle?: string;
}) {
  const {
    appealDecisionModalVisible,
    setAppealDecisionvModalVisible,
    vetRequestId,
    appealStatus,
    setAppealStatusChanged,
    appealDecisionvModalTitle,
  } = props;

  const [isVisible, setIsVisible] = useState(false);
  const [appealComments, setAppealComments] = useState<any>('');
  const { t } = useTranslation();
  const [appealDecisionComment, setAppealDecisionComment] = useState<any>('');
  const appealCommentValidation: any = useRef();
  const textBox = useRef<TextArea>(null);
  const [modalTitle, setModalTitle] = useState<any>('');
  const { setIsLoading } = useLoaderContext();
  const focusTextBox = useCallback(() => {
    textBox.current?.instance.focus();
  }, []);

  useEffect(() => {
    focusTextBox();
  }, []);

  const modalConfig: IModalConfig = {
    width: 1262,
    height: 290,
    visible: appealDecisionModalVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };
  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-modal-appeal-request m-c-comment-popup',
      'data-testid': 'appealDecisionModal',
    };
  }, []);

  const handleCancelClick = () => {
    setAppealDecisionvModalVisible(false);
  };

  const handleSaveClick = (e: any) => {
    if (appealCommentValidation.current?.instance.validate().isValid) {
      saveAppealApprove();
    } else {
      return false;
    }
  };

  const saveAppealApprove = async () => {
    let toastTitle = '';
    if (appealStatus === VetRequestStatusCode.approved) {
      toastTitle = t('toast.appealApprovedSuccessfully');
    }
    if (appealStatus === VetRequestStatusCode.rejected) {
      toastTitle = t('toast.appealRejectedSuccessfully');
    }
    if (appealStatus === VetRequestStatusCode.conditionallyApproved) {
      toastTitle = t('toast.conditionallyRejectedSuccessfully');
    }
    if (appealStatus === VetRequestStatus.processing) {
      toastTitle = t('toast.manualStatusUpdatedSuccessfully');
    }
    if (appealStatus === VetRequestStatusCode.waitingforExternalInfo) {
      toastTitle = t('toast.manualStatusUpdatedSuccessfully');
    }
    if (appealStatus === VetRequestStatusCode.waitingforRequestorinfo) {
      toastTitle = t('toast.manualStatusUpdatedSuccessfully');
    }

    const jsonData = {
      vetRequestId: vetRequestId,
      status: appealStatus,
      appealDecisionComment: appealDecisionComment,
      appealDecisionDate: new Date().toISOString(),
    };

    await api
      .put(
        {
          url: getAPIRoute('vetRequestAppealApprove', [vetRequestId]),
          data: jsonData,
        },
        setIsLoading
      )
      .then((res) => {
        if (res) {
          toast.success({
            title: toastTitle,
          });
          setAppealDecisionvModalVisible(false);
          setAppealStatusChanged(appealStatus);
          // show appeal comments
        }
      });
  };

  return (
    <>
      <div className="m-l-model-popup__wrap">
        <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
          <div className="m-l-modal__header">
            <h2 className="modal-title">{appealDecisionvModalTitle}</h2>
            <div className="m-l-modal-close-button-block">
              <Button
                className="app-c-btn app-c-icon-only-btn"
                onClick={handleCancelClick}
                elementAttr={{ 'data-testid': 'cancelClick' }}
              >
                <Icons.IconCross />
              </Button>
            </div>
          </div>
          <div className="m-l-modal__body">
            <ScrollView width="100%" height="100%">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="m-c-form-group">
                    <label className="m-l-input-label">
                      {t('labels.comment')}
                    </label>
                    <ValidationGroup ref={appealCommentValidation}>
                      <TextArea
                        className="m-c-text-area m-c-text-area-in-form"
                        placeholder={t(
                          'placeholders.pleaseEnterYourCommentToSubmit'
                        )}
                        inputAttr={{ 'data-testid': 'appealDecisionComment' }}
                        name="appealComment"
                        ref={textBox}
                        onValueChanged={(e: any) => {
                          setAppealDecisionComment(e.value);
                        }}
                        // onEnterKey={(e: any) => {
                        //   setAppealDecisionComment(e.value);
                        // }}
                        validationMessageMode="always"
                        value={appealDecisionComment}
                      >
                        <Validator>
                          <RequiredRule message={t('errors.required')} />
                        </Validator>
                      </TextArea>
                    </ValidationGroup>
                  </div>
                </div>
              </div>
            </ScrollView>
          </div>
          <div className="m-l-modal__footer">
            <div className="m-l-footer-right-block">
              <Button
                onClick={handleSaveClick}
                className="app-c-btn app-c-btn--primary"
                elementAttr={{ 'data-testid': 'submitBtn' }}
              >
                {t('buttons.submit')}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
