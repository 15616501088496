import React from 'react';
import FileUploaderModal from '../../common/file-uploader-modal/FileUploaderModal';

export default function AddAttachment(props: {
  isVisible: boolean;
  handleCancelClick: any;
  portCode?: any;
  terminalId?: any;
  setAttachmentsAdded?: any;
  attachmentsAdded?: any;
}) {
  const {
    isVisible,
    handleCancelClick,
    portCode,
    terminalId,
    setAttachmentsAdded,
    attachmentsAdded,
  } = props;

  return (
    <div>
      <FileUploaderModal
        handleCancelClick={handleCancelClick}
        isVisible={isVisible}
        setAttachmentsAdded={setAttachmentsAdded}
        attachmentsAdded={attachmentsAdded}
        isFromPort={true}
        portCode={portCode}
        terminalId={terminalId}
      />
    </div>
  );
}
