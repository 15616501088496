import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormConfig } from '../../../../types/form-builder';
import FormBuilder from '../../../common/form-builder/FormBuilder';

export default function CargoTypeForm(props: { berthTypeData: any }) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { berthTypeData } = props;

  const nameFormConfig: IFormConfig[] = [
    {
      class:
        'col-md-12 col-sm-12 col-lg-12 col-xs-12 col-xs-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space',
      name: 'berthType',
      label: 'Berth Type',
      control: control,
      fieldType: 'select',
      dataSource: berthTypeData,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-md-12 col-sm-12 col-lg-12 col-xs-12 col-xs-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space',
      name: 'mainCargoType',
      label: 'Main Cargo Type',
      control: control,
      fieldType: 'text',
      maxLength: 100,
    },
  ];

  return (
    <div className="row m-l-custom-sixteen-space-row">
      <FormBuilder formConfig={nameFormConfig} />
    </div>
  );
}
