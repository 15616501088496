import React from 'react';
import { Outlet } from 'react-router-dom';

export default function Layout() {
  return (
    <div id="view">
      <div className="m-l-container">
        <div className="main-container">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
