import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import FormBuilder from '../../../../common/form-builder/FormBuilder';
import { useDateFormat } from '../../../../../hooks/useDateFormat';

export default function YardForm() {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [countries, setCountries] = useState<any>();
  const { dateFormat } = useDateFormat();

  const getCountries = async () => {
    await api.get({ url: apiConfig.country }).then((data: any) => {
      setCountries(data);
    });
  };

  useEffect(() => {
    getCountries();
  }, []);

  const yardFormConfig = [
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'dateOfBuild',
      label: t('labels.yearOfBuilt'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'launchDate',
      label: t('labels.launchDate'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'builder',
      label: t('labels.builder'),
      control: control,
      fieldType: 'text',
      maxLength: 50,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'yardNumber',
      label: t('labels.yardNumber'),
      control: control,
      fieldType: 'text',
      maxLength: 50,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'countryCodeOfBuild',
      label: t('labels.countryOfBuild'),
      control: control,
      fieldType: 'select',
      dataSource: countries,
      displayExpr: 'countryName',
      valueExpr: 'countryCode',
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={yardFormConfig} />
      </div>
    </>
  );
}
