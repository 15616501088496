import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDateFormat } from '../../../../../hooks/useDateFormat';
import FormBuilder from '../../../../common/form-builder/FormBuilder';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';

export default function GeneralForm() {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const { dateFormat } = useDateFormat();
  const [managementReview, setManagementReview] = useState<any>();

  const getReviews = async () => {
    await api
      .get({ url: apiConfig.optionValue + 'ManagementChangeReview' })
      .then((data: any) => {
        setManagementReview(data);
      });
  };

  useEffect(() => {
    getReviews();
  }, []);

  const generalFormConfig = [
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'piInsuranceStartDate',
      label: t('labels.P&IInsuranceStartDate'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'piInsuranceEndDate',
      label: t('labels.P&IInsuranceEndDate'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'hmInsuranceStartDate',
      label: t('labels.H&MInsuranceStartDate'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'hmInsuranceEndDate',
      label: t('labels.H&MInsuranceEndDate'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'cofrValidityDate',
      label: t('labels.cofrValidityDate'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'vrpValidityDate',
      label: t('labels.vrpValidityDate'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'uscgcocValidityDate',
      label: t('labels.uscgcocValidityDate'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'codValidityDate',
      label: t('labels.codValidityDate'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'coiValidityDate',
      label: t('labels.coiValidityDate'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'tsmsValidityDate',
      label: t('labels.tsmsValidityDate'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'lastDrydockDate',
      label: t('labels.lastDrydockDate'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'uwildDate',
      label: t('labels.uwildDate'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'conditionClassValidTillDate',
      label: t('labels.conditionClassValidTillDate'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'questionnaireValidityDate',
      label: t('labels.questionnaireValidityDate'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'managementChangeReview',
      label: t('labels.managementChangeReview'),
      control: control,
      fieldType: 'select',
      dataSource: managementReview,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'timeCharterer',
      label: t('labels.timeCharterer'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'broker',
      label: t('labels.broker'),
      control: control,
      fieldType: 'text',
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={generalFormConfig} />
      </div>
    </>
  );
}
