export enum PasswordSetting {
  minLength = 'MinLength',
  minUpperCase = 'MinUpperCase',
  minLowerCase = 'MinLowerCase',
  minSpecialCharacters = 'MinSpecialCharacters',
  minNumericCharacters = 'MinNumericCharacters',
}

export enum GeneralSetting {
  companyInviteExpiry = 'CompanyInviteExpiry',
  notificationDuration = 'NotificationDuration',
  dateDisplayFormat = 'DateDisplayFormat',
  cancelWaitingPeriod = 'CancelWaitingPeriod',
  unassignWaitingPeriod = 'UnassignWaitingPeriod',
  fileMaxLength = 'FileMaxLength',
  allowedFileTypes = 'AllowedFileTypes',
  processingUnassignWaitingPeriod = 'ProcessingUnassignWaitingPeriod',
}
