import { MarsApiConfig } from '../api/mars-api-config';

export const getAPIRoute = (
  apiKey: keyof typeof MarsApiConfig,
  argumentArray: any
) => {
  const url: string = MarsApiConfig[apiKey];
  const t = [...argumentArray].reduce((p, c) => p.replace(/%s/, c), url);
  return t;
};
