import Button from 'devextreme-react/button';
import TreeView from 'devextreme-react/tree-view';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import RoleForm from '../../../../components/roles/roles-form/RoleForm';
import { IDialogConfig } from '../../../../types/dialog';
import { IRole, IRoleForm, RoleFields } from '../../../../types/role';
import toast from '../../../../utils/toast';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import ConfirmDialog from '../../../../components/common/confirm/ConfirmDialog';
import { Restricted } from '../../../../contexts/PermissionContext';
import icons from '../../../../components/common/icons/icons';
import Tooltip from 'devextreme-react/tooltip';
import Switch from 'devextreme-react/switch';

export default function EditRole() {
  const { t } = useTranslation();
  const location = useLocation();
  const [roleId, setRoleId] = useState<string>();
  const treeRef = useRef<TreeView>(null);
  const { setIsLoading } = useLoaderContext();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmBackDialog, setConfirmBackDialog] = useState(false);
  const [searchExpression, setSearchExpression] = useState<any>();
  const navigate = useNavigate();
  const [dataSource, setTreeDataSource] = useState<any>([]);
  const [features, setFeatures] = useState<any>([]);
  const [treeDataChanged, setTreeDataChanged] = useState(false);
  const [roleDetails, setRoleDetails] = useState<IRole>();
  const [isExpanded, setIsExpanded] = useState(false);
  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      saveRole();
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setConfirmBackDialog(false);
      navigate(-1);
    },
    handleClose: () => {
      setConfirmBackDialog(false);
    },
  };

  const defaultValues: IRoleForm = {
    name: '',
    description: '',
    isActive: false,
  };

  const roleForm = useForm({
    defaultValues: defaultValues,
  });

  const { dirtyFields } = useFormState({
    control: roleForm.control,
    disabled: true,
  });

  const { getValues } = roleForm;

  const getRoleDetails = async () => {
    await api.get({ url: apiConfig.roles + roleId }).then((res: IRole) => {
      setRoleDetails(res);
    });
  };

  const getModulesAndFeatures = async () => {
    await api.get({ url: apiConfig.modules }).then((res: IRole[]) => {
      setFeatures(res);
    });
  };

  const constructTreeDataSource = (features: any, featureIds?: any) => {
    const treeItems: any = [{ id: '1', description: 'Module', expanded: true }];
    treeItems[0].features = features;
    if (featureIds) {
      featureIds.forEach((featureId: any) => {
        treeItems[0].features.forEach((modules: any) => {
          modules.features.forEach((feature: any) => {
            if (feature.masterFeatureId === featureId) {
              feature.selected = true;
            }
          });
        });
      });
      setTreeDataSource(treeItems);
    } else {
      setTreeDataSource(treeItems);
    }
  };

  useEffect(() => {
    if (location.state) {
      const { roleId }: any = location.state;
      setRoleId(roleId);
    }
  }, [location.state]);

  useEffect(() => {
    if (roleDetails) {
      constructTreeDataSource(features, roleDetails.featureIds);
      roleForm.reset(
        {
          name: roleDetails.name,
          description: roleDetails.description,
          isActive: roleDetails.isActive,
        },
        { keepDirty: true }
      );
    } else {
      constructTreeDataSource(features);
    }
  }, [roleDetails, features]);

  useEffect(() => {
    setIsLoading(true);
    if (roleId) {
      getRoleDetails();
    }
    setIsLoading(false);
  }, [roleId]);

  useEffect(() => {
    getModulesAndFeatures();
  }, []);

  const onSelectItem = () => {
    if (roleId) {
      setTreeDataChanged(true);
    }
  };

  const handleSaveClick = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      setIsConfirmModalOpen(true);
    }
    return;
  };

  const handleBackClick = () => {
    if (roleId) {
      treeDataChanged || Object.keys(dirtyFields).length !== 0
        ? setConfirmBackDialog(true)
        : navigate('/roles');
    } else {
      treeRef.current?.instance.getSelectedNodes().length !== 0 ||
      Object.keys(dirtyFields).length !== 0
        ? setConfirmBackDialog(true)
        : navigate('/roles');
    }
  };

  const onExpand = (expand: boolean) => {
    setIsExpanded(expand);
    if (expand) {
      treeRef.current?.instance.expandAll();
    } else {
      treeRef.current?.instance.collapseAll();
    }
  };
  const saveRole = () => {
    const roleFormValue: IRoleForm = getValues();
    const selectedNodes = treeRef.current?.instance
      .getSelectedNodes()
      .filter((item) => item?.itemData?.masterFeatureId);
    let featureIds: any = [];
    selectedNodes?.map((selectedItem) =>
      featureIds.push(selectedItem.itemData?.masterFeatureId)
    );
    setIsConfirmModalOpen(false);
    if (!roleId) {
      const roleJson: IRole = {
        name: roleFormValue.name.trim(),
        description: roleFormValue.description,
        isActive: roleFormValue.isActive,
        featureIds: featureIds,
      };
      api
        .post(
          {
            url: apiConfig.roles,
            data: roleJson,
          },
          setIsLoading
        )
        .then((res) => {
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
          roleForm.reset();
          setTreeDataChanged(false);
          navigate('/roles/edit', { state: { roleId: res.id } });
        });
    } else {
      const roleJsonEdit: IRole = {
        id: roleId,
        name: roleFormValue.name.trim(),
        description: roleFormValue.description,
        isActive: roleFormValue.isActive,
        featureIds: featureIds,
      };
      api
        .put({ url: apiConfig.roles, data: roleJsonEdit }, setIsLoading)
        .then(() => {
          Object.keys(roleFormValue).map((item: string) => {
            const formKey = item as RoleFields;
            if (Object.keys(dirtyFields).includes(formKey)) {
              roleForm.resetField(formKey, {
                defaultValue: roleFormValue[formKey],
              });
            }
          });
          roleForm.reset(roleFormValue, {
            keepDirty: true,
            keepDefaultValues: true,
          });
          toast.custom({
            title: t('toast.updatedSuccessfully'),
          });
          featureIds = [];
          setTreeDataChanged(false);
        });
    }
  };
  const itemRender = (data: any) => {
    return (
      <div>
        {data?.isCritical && <span className="m-l-role-critical">*</span>}
        <span>{data?.description}</span>
      </div>
    );
  };
  return (
    <div className="inner-wrapper">
      <ul aria-label="breadcrumb" className="m-c-bredcrumb">
        <li>
          <a>{t('headers.configuration')}</a>
        </li>
        <li>
          <a className="active">{t('headers.roles')}</a>
        </li>
      </ul>
      <div className="m-l-inner-page-header">
        <div className="m-l-inner-page-header-left-block">
          <div className="m-l-page-main-heading">{t('headers.roles')}</div>
        </div>
        <div className="m-l-inner-page-header-right-block"></div>
      </div>
      <div className="m-l-inner-page-body">
        <div className="m-l-form-panel-block">
          <div className="m-l-form-panel-header-block">
            <div className="m-l-form-panel-header-left-block">
              <div className="m-l-page-small-heading">
                {t('headers.roleDetails')}
              </div>
            </div>
            <div className="m-l-form-panel-header-right-block">
              <Button
                className="app-c-btn app-c-btn--secondary"
                elementAttr={{ 'data-testid': 'backButton' }}
                onClick={handleBackClick}
              >
                {t('buttons.back')}
              </Button>
              <Restricted permission="RoleManagement.Add">
                {!roleId && (
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'saveButton' }}
                    onClick={handleSaveClick}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Restricted>
              <Restricted permission="RoleManagement.Edit">
                {roleId && (
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'saveButton' }}
                    onClick={handleSaveClick}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Restricted>
            </div>
          </div>
          <div className="m-l-form-panel-body-block u-p0">
            <FormProvider {...roleForm}>
              <RoleForm roleId={roleId} />
            </FormProvider>
          </div>
          <div className="m-l-tree-view m-l-adrole-tree-view">
            <div className="m-l-tr-hd-wrap">
              <div className="m-l-ftr-ttl">{t('labels.features')}</div>
              {/* filter */}
              <div className="m-l-role-switch">
                <div className="m-l-toggle-switch">
                  <label className="m-l-switch-label">
                    {t('labels.critical')}
                  </label>
                  <Switch
                    elementAttr={{ 'data-testid': 'roleFilter' }}
                    name="isCritical"
                    onValueChanged={(e) => {
                      e.value
                        ? setSearchExpression('true')
                        : setSearchExpression(undefined);
                    }}
                  />
                </div>
              </div>
              <div className="m-l-role-legend">
                <span className="m-l-role-critical">*</span>
                <span>{t('labels.criticalLegend')}</span>
              </div>
              {/* expand */}
              <div className="m-l-expand">
                {!isExpanded && (
                  <div className="m-l-expand-btn-wrap">
                    <Button
                      elementAttr={{ 'data-testid': 'expandButton' }}
                      onClick={() => onExpand(true)}
                      className="app-c-btn app-c-icon-only-btn"
                      id="expand"
                    >
                      <icons.ExpandIcon />
                      <Tooltip
                        target={'#expand'}
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        position="bottom"
                        contentRender={() => {
                          return (
                            <div className="m-l-tooltip-wrapper">
                              {t('labels.expand')}
                            </div>
                          );
                        }}
                      />
                    </Button>
                  </div>
                )}
                {isExpanded && (
                  <div className="m-l-expand-btn-wrap">
                    <Button
                      elementAttr={{ 'data-testid': 'expandButton' }}
                      onClick={() => onExpand(false)}
                      className="app-c-btn app-c-icon-only-btn"
                      id="expand"
                    >
                      <icons.CollapseIcon />
                      <Tooltip
                        target={'#expand'}
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        position="bottom"
                        contentRender={() => {
                          return (
                            <div className="m-l-tooltip-wrapper">
                              {t('labels.collapse')}
                            </div>
                          );
                        }}
                      />
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <TreeView
              elementAttr={{ 'data-testid': 'treeView' }}
              id="dataSource"
              ref={treeRef}
              dataSource={dataSource}
              dataStructure="tree"
              displayExpr="description"
              parentIdExpr="masterModuleId"
              keyExpr="id"
              searchExpr="isCritical"
              searchValue={searchExpression}
              itemsExpr="features"
              selectionMode="multiple"
              showCheckBoxesMode="normal"
              onItemSelectionChanged={onSelectItem}
              itemRender={(data) => itemRender(data)}
            />
          </div>
        </div>
      </div>
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <Button
                className="app-c-btn app-c-btn--secondary min-btn-width"
                elementAttr={{ 'data-testid': 'backButton' }}
                onClick={handleBackClick}
              >
                {t('buttons.back')}
              </Button>
              <Restricted permission="RoleManagement.Add">
                {!roleId && (
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'saveButton' }}
                    onClick={handleSaveClick}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Restricted>
              <Restricted permission="RoleManagement.Edit">
                {roleId && (
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'saveButton' }}
                    onClick={handleSaveClick}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Restricted>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={isConfirmModalOpen}
      />
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={confirmBackDialog}
      />
    </div>
  );
}
