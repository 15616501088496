import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import EmailSettingForm from './email-setting-form/EmailSettingForm';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { Button } from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import toast from '../../../../utils/toast';
import { IMasterEntity } from '../../../../types/master-data';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../../types/dialog';
import { EmailSettingType } from '../../../../enums/config-enum';
import { useLoaderContext } from '../../../../contexts/LoaderContext';

export default function EmailSetting(props: {
  companyId: any;
  buId: any;
  saveConfig?: any;
  setSaveConfig?: any;
}) {
  const [emailSettings, setEmailSettings] = useState<any[]>([]);
  const { t } = useTranslation();
  const { companyId, buId, saveConfig, setSaveConfig } = props;
  const { setIsLoading } = useLoaderContext();
  const [entities, setEntities] = useState<IMasterEntity[]>([]);
  const [cSalutation, setCSalutation] = useState<any>();
  const [vSalutation, setVSalutation] = useState<any>();
  const [cSignature, setCSignature] = useState<any>();
  const [vSignature, setVSignature] = useState<any>();
  const [confidentiality, setConfidentiality] = useState<any>();
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [showVSalutation, setShowVSalutation] = useState<any>();
  const [ShowCSalutation, setShowCSalutation] = useState<any>();
  const [showCSignature, setShowCSignature] = useState<any>();
  const [showVSignature, setShowVSignature] = useState<any>();
  const [showConfidentiality, setShowConfidentiality] = useState<any>();

  const getEntities = async () => {
    await api
      .get({
        url: apiConfig.masterEntities,
        params: { isRequiredForRFITemplate: true },
      })
      .then((res: IMasterEntity[]) => {
        setEntities(res);
      });
  };

  const getEmailSettings = async () => {
    if (buId && companyId) {
      await api
        .get({
          url: apiConfig.configurations,
          params: {
            settingArea: 'RuleRFITemplate',
            companyId: companyId,
            buId: buId,
          },
        })
        .then((response) => {
          setEmailSettings(response);
        });
    } else {
      await api
        .get({
          url: apiConfig.configurations,
          params: { settingArea: 'RuleRFITemplate', companyId: companyId },
        })
        .then((response) => {
          setEmailSettings(response);
        });
    }
  };

  useEffect(() => {
    getEmailSettings();
    getEntities();
  }, []);

  useEffect(() => {
    if (saveConfig) {
      setShowConfirmSave(true);
    }
  }, [saveConfig]);

  useEffect(() => {
    if (emailSettings) {
      let index = emailSettings?.findIndex(
        (item: any) => item?.settingType == EmailSettingType.Confidentiality
      );
      setConfidentiality(emailSettings[index]?.settingValue);
      setShowConfidentiality(emailSettings[index]?.settingArea);
      index = emailSettings?.findIndex(
        (item: any) =>
          item?.settingType == EmailSettingType.RFIToClientSalutation
      );
      setCSalutation(emailSettings[index]?.settingValue);
      setShowCSalutation(emailSettings[index]?.settingArea);
      index = emailSettings?.findIndex(
        (item: any) =>
          item?.settingType == EmailSettingType.RFIToVesselOwnerSalutation
      );
      setVSalutation(emailSettings[index]?.settingValue);
      setShowVSalutation(emailSettings[index]?.settingArea);
      index = emailSettings?.findIndex(
        (item: any) =>
          item?.settingType == EmailSettingType.RFIToClientSignature
      );
      setCSignature(emailSettings[3]?.settingValue);
      setShowCSignature(emailSettings[index]?.settingArea);
      index = emailSettings?.findIndex(
        (item: any) =>
          item?.settingType == EmailSettingType.RFIToVesselOwnerSignature
      );
      setVSignature(emailSettings[4]?.settingValue);
      setShowVSignature(emailSettings[index]?.settingArea);
      // setShowEditor(true);
    } else {
      setShowEditor(false);
    }
  }, [emailSettings]);

  const handleSaveClick = async () => {
    let index = emailSettings?.findIndex(
      (item: any) => item?.settingType == EmailSettingType.Confidentiality
    );
    emailSettings[index].settingValue = confidentiality;
    index = emailSettings?.findIndex(
      (item: any) => item?.settingType == EmailSettingType.RFIToClientSalutation
    );
    emailSettings[index].settingValue = cSalutation;
    index = emailSettings?.findIndex(
      (item: any) =>
        item?.settingType == EmailSettingType.RFIToVesselOwnerSalutation
    );
    emailSettings[index].settingValue = vSalutation;
    index = emailSettings?.findIndex(
      (item: any) => item?.settingType == EmailSettingType.RFIToClientSignature
    );
    emailSettings[index].settingValue = cSignature;
    index = emailSettings?.findIndex(
      (item: any) =>
        item?.settingType == EmailSettingType.RFIToVesselOwnerSignature
    );
    emailSettings[index].settingValue = vSignature;
    saveConfigs();
  };

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmSaveChanges'),
    handleSubmit: () => {
      handleSaveClick();
      setShowConfirmSave(false);
      setSaveConfig(false);
    },
    handleClose: () => {
      setShowConfirmSave(false);
      setSaveConfig(false);
    },
  };

  const saveConfigs = async () => {
    if (buId) {
      await api
        .put(
          {
            url: apiConfig.configurations,
            params: {
              configArea: 'RuleRFITemplate',
              buId: buId,
              companyId: companyId,
            },
            data: emailSettings,
          },
          setIsLoading
        )
        .then(() => {
          toast.success({
            title: t('toast.changesSavedSuccessfully'),
          });
        });
    } else {
      await api
        .put(
          {
            url: apiConfig.configurations,
            params: { configArea: 'RuleRFITemplate', companyId: companyId },
            data: emailSettings,
          },
          setIsLoading
        )
        .then(() => {
          toast.success({
            title: t('toast.changesSavedSuccessfully'),
          });
        });
    }
  };

  return (
    <div className="m-l-form-panel-block">
      <div className="m-l-form-panel-header-block">
        <div className="m-l-form-panel-header-left-block">
          <div className="m-l-page-small-heading">
            {t('labels.emailSettings')}
          </div>
        </div>
        {!buId && (
          <div className="m-l-form-panel-header-right-block">
            {/* <Button
              className="app-c-btn app-c-btn--primary min-btn-width"
              aria-label=""
              onClick={handleSaveClick}
            >
              {t('buttons.save')}
            </Button> */}
          </div>
        )}
      </div>
      <div className="m-l-form-panel-body-block">
        <EmailSettingForm
          entities={entities}
          cSalutation={cSalutation}
          setCSalutation={setCSalutation}
          vSalutation={vSalutation}
          setVSalutation={setVSalutation}
          cSignature={cSignature}
          setCSignature={setCSignature}
          vSignature={vSignature}
          setVSignature={setVSignature}
          confidentiality={confidentiality}
          setConfidentiality={setConfidentiality}
          showEditor={showEditor}
          showVSalutation={showVSalutation}
          showCSignature={showCSignature}
          showVSignature={showVSignature}
          showConfidentiality={showConfidentiality}
          ShowCSalutation={ShowCSalutation}
        />
      </div>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={showConfirmSave}
      />
    </div>
  );
}
