import React, { useCallback } from 'react';
import imageURL from '../../../assets/images';
import icons from '../../../components/common/icons/icons';
import { VetRequestStatusCode } from '../../../enums/vet-request-status-enum';
import { useDimensionsUnit } from '../../../hooks/useDimensionsUnit';

export default function VetOutcomeReport(props: {
  reportDetails: any;
  isNotRecommended: any;
  isAttachPdf?: any;
  reviewComment?: any;
  isRecommend?: any;
  logo?: any;
  reportDate?: any;
}) {
  const {
    reportDetails,
    isNotRecommended,
    isAttachPdf,
    reviewComment,
    isRecommend,
    logo,
    reportDate,
  } = props;

  const getCommentBody = useCallback((comment: string) => {
    return comment?.replace(/\n/g, '<br/>');
  }, []);
  const { dimensionUnitText, meterToFeetConversion } = useDimensionsUnit();

  const cardStyles = {
    logoHead: {
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
      'flex-direction': 'column',
      padding: '16px 0',
    },
    logoImage: {
      height: '120px',
      marginBottom: '30px',
    },
    logoTextWrapDiv: {
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
    },
    logoText: {
      marginBottom: '0',
      fontSize: '18px',
      color: '#174a84',
      fontWeight: '700',
    },
    stamp: {
      marginLeft: '25px',
    },
    statusImage: {
      height: '44px',
    },
    contentHeaderWrap: {
      display: 'flex',
      'justify-content': 'space-between',
      'align-items': 'center',
      paddingBottom: '10px',
    },
    headerColfirst: {
      display: 'flex',
      'align-items': 'center',
    },
    headerColfirstHeading: {
      fontSize: '22px',
      lineHeight: '24px',
      color: '#174a84',
      fontWeight: '600',
      margin: '0',
    },
    headerColfirstSubHead: {
      fontSize: '12px',
      lineHeight: '14px',
      color: '#174a84',
      fontWeight: '600',
      marginLeft: '30px',
    },
    headerColSecond: {
      display: 'flex',
      'align-items': 'center',
      marginLeft: 'auto',
    },
    headerColSecondSubHead: {
      fontSize: '12px',
      lineHeight: '14px',
      color: '#174a84',
      fontWeight: '600',
      marginLeft: '0px',
    },
    contentSubTextWrap: {
      display: 'block',
    },
    reportCard: {
      border: '1px solid #e5e5e5',
      padding: '16px 0',
      borderRadius: '8px',
      marginTop: '8px',
    },
    reportCardRow: {
      display: 'flex',
      padding: '8px 0',
      width: '100%',
    },
    reportCardCol: {
      borderRight: '1px solid #e5e5e5',
      padding: '0 5px 0 16px',
      margin: '0',
    },
    reportCardColWidth10: {
      width: '10%',
    },
    reportCardColWidth15: {
      width: '15%',
    },
    reportCardColWidth20: {
      width: '20%',
    },
    reportCardColWidth25: {
      width: '25%',
    },
    reportCardColWidth30: {
      width: '30%',
    },
    reportCardColWidth40: {
      width: '40%',
    },
    reportCardColWidth50: {
      width: '50%',
    },
    reportCardColWidth100: {
      width: '100%',
    },
    reportPl0: {
      paddingLeft: '0',
    },
    reportLabel: {
      color: '#4f4f4f',
      fontSize: '10px',
      lineHeight: '16px',
      fontWeight: '600',
      display: 'block',
      marginBottom: '6px',
    },
    reportValue: {
      color: '#174a84',
      fontSize: '10px',
      lineHeight: '16px',
      fontWeight: '400',
    },
    reportValueGreen: {
      color: '#00b050',
    },
    reportValueRed: {
      color: '#df2f2f',
    },

    reportSectionHeading: {
      fontSize: '14px',
      lineHeight: '24px',
      color: '#174a84',
      fontWeight: '600',
      margin: '16px 0 8px',
      backgroundColor: '#ebf3fe',
      padding: '10px 16px',
      border: '1px solid #ebf3fe',
    },
    reportCardContainer: {
      border: '1px solid #e5e5e5',
      padding: '0px 8px 8px 8px',
      borderRadius: '8px',
      marginTop: '8px',
    },
    reportEqualFlex: {
      display: 'flex',
      'justify-content': 'flex-start',
      'align-items': 'flex-start',
    },
    reportFlexEqCol: {
      borderRight: '1px solid #e5e5e5',
      flex: '1',
      padding: '0',
    },
    reportBlock: {
      display: 'block',
    },
    reportBorderRightNone: {
      borderRight: 'none',
    },
    reportBorderTop: {
      borderTop: '1px solid #e5e5e5',
    },
    reportWidth100: {
      width: '100%',
    },
    reportTitleH3: {
      margin: '0',
      padding: '0 16px',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#174a84',
      fontWeight: '600',
    },
    reportRowWrapper: {
      overflow: 'hidden',
      padding: '0 16px',
    },
    executionWrap: {
      border: '1px solid #2f80ed',
      borderRadius: '8px',
      marginBottom: '20px',
    },
    exeHeader: {
      display: 'flex',
      borderBottom: '1px solid #e5e5e5',
      alignItems: 'center',
      padding: '12px 16px',
    },
    exeHeaderText: {
      color: '#000',
      fontSize: '12px',
      lineHeight: '1',
      fontWeight: '500',
      marginBottom: '0',
    },
    marginLeftAuto: {
      marginLeft: 'auto',
    },
    displayFlex: {
      display: 'flex',
    },
    alignItemsCenter: {
      alignItems: 'center',
    },
    exeHeaderLastItem: {
      marginLeft: '10px',
      paddingLeft: '10px',
      borderLeft: '1px solid #e5e5e5',
    },
    exeHeaderRightContent: {
      color: '#174a84',
      fontSize: '10px',
      fontWeight: '500',
    },
    exeBody: {
      padding: '8px',
    },
    exeTextH4: {
      color: '#828282',
      fontSize: '12px',
      fontWeight: '400',
      marginBottom: '8px',
    },
    exBgBox: {
      border: '1px solid #2f80ed',
      borderRadius: '8px',
      background: '#ebf3fe',
    },
    exBgBoxPadding: {
      padding: '20px 15px 15px',
    },
    exBorderBottom: {
      borderBottom: '1px solid #e5e5e5',
    },
    exBgBoxHeaderText: {
      margin: '0px 0px 10px',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#174a84',
      fontWeight: '600',
    },
    label: {
      color: '#828282',
      fontSize: '10px',
      fontWeight: '500',
      marginBottom: '4px',
      display: 'block',
    },
    formValue: {
      color: '#585c64',
      padding: '6px 8px 6px 8px',
      background: '#fff',
      display: 'block',
      fontSize: '1em',
      border: '1px solid #90a1b5',
      borderRadius: '4px',
    },
    paddinRight8: {
      paddingRight: '8px',
    },
    exBoxWhite: {
      border: '1px solid #2f80ed',
      borderRadius: '8px',
      background: '#fff',
    },
    table: {
      width: '100%',
    },
    tableTH: {
      fontSize: '12px',
      fontWeight: '500',
      color: '#000',
      border: '1px solid #e5e5e5',
      padding: '10px 15px',
    },
    tableTD: {
      fontSize: '12px',
      fontWeight: '400',
      color: '#000',
      border: '1px solid #e5e5e5',
      padding: '10px 15px',
    },
    ruleContainer: {
      fontSize: '12px',
      lineHeight: '24px',
      color: '#174a84',
      fontWeight: '600',
      margin: '16px 0 8px',
      backgroundColor: '#ebf3fe',
      padding: '8px 16px',
      border: '1px solid #ebf3fe',
      borderRadius: '8px',
    },
    ruleContent: {
      display: 'flex',
      'align-items': 'center',
      width: '100%',
    },
    ruleIcon: {
      padding: '0px 10px',
    },
  };

  const displayComments = (data: string) => {
    const value = data.split('\n');
    return (
      <span>
        {value?.map((element, index) => {
          return (
            element && (
              <span
                style={{
                  fontSize: '12px',
                }}
                key={index}
              >
                {element}
                <br />
              </span>
            )
          );
        })}
      </span>
    );
  };

  const showComment = (comments: any, name: string) => {
    const index = comments.findIndex(
      (item: any) => item.vetRequestStatus === name
    );
    return index;
  };

  return (
    <div
      className="inner-wrapper-report ps-3 pe-3"
      // style={{ marginTop: '300px' }}
      id="element-to-print"
    >
      <div style={cardStyles.reportBlock}>
        <div style={cardStyles.reportBlock}>
          <div style={cardStyles.reportBlock}>
            <div style={cardStyles.logoHead}>
              {logo && (
                <img
                  src={logo}
                  style={cardStyles.logoImage}
                  alt="CompanyLogo"
                />
              )}
              <div style={cardStyles.logoTextWrapDiv}>
                <h1 style={cardStyles.logoText}>VET OUTCOME REPORT</h1>
                {(reportDetails?.status || isAttachPdf) && (
                  <span style={cardStyles.stamp}>
                    <img
                      style={cardStyles.statusImage}
                      src={
                        isAttachPdf && isRecommend
                          ? imageURL.recommended
                          : isNotRecommended
                          ? imageURL.notRecommended
                          : reportDetails?.status ===
                            VetRequestStatusCode.recommended
                          ? imageURL.recommended
                          : reportDetails?.status ===
                            VetRequestStatusCode.approved
                          ? imageURL.approved
                          : reportDetails?.status ===
                            VetRequestStatusCode.conditionallyApproved
                          ? imageURL.conditionallyApproved
                          : reportDetails?.status ===
                            VetRequestStatusCode.rejected
                          ? imageURL.rejected
                          : imageURL.notRecommended
                      }
                    />
                  </span>
                )}
              </div>
            </div>
            <div style={cardStyles.contentHeaderWrap}>
              <div style={cardStyles.headerColfirst}>
                <h2 style={cardStyles.headerColfirstHeading}>
                  {reportDetails?.shipName}
                </h2>
                <span style={cardStyles.headerColfirstSubHead}>
                  VET ID: {reportDetails?.vetId}
                </span>
              </div>
              <div style={cardStyles.headerColSecond}>
                <span style={cardStyles.headerColSecondSubHead}>
                  Date: {reportDate}
                </span>
              </div>
            </div>
            <div style={cardStyles.contentSubTextWrap}>
              {reportDetails?.imoNumber && (
                <span style={cardStyles.headerColSecondSubHead}>
                  IMO: {reportDetails?.imoNumber}
                </span>
              )}
              {!reportDetails?.imoNumber && reportDetails?.vinNumber && (
                <span style={cardStyles.headerColSecondSubHead}>
                  VIN: {reportDetails?.vinNumber}
                </span>
              )}
            </div>
          </div>
          {reportDetails?.vesselBasicInfo && (
            <div style={cardStyles.reportCard}>
              <div style={cardStyles.reportCardRow}>
                <div
                  style={{
                    ...cardStyles.reportCardCol,
                    ...cardStyles.reportCardColWidth10,
                  }}
                >
                  <span style={cardStyles.reportLabel}>Age</span>
                  <span style={cardStyles.reportValue}>
                    {reportDetails?.vesselBasicInfo?.age}
                  </span>
                </div>
                <div
                  style={{
                    ...cardStyles.reportCardCol,
                    ...cardStyles.reportCardColWidth20,
                  }}
                >
                  <span style={cardStyles.reportLabel}>Status</span>
                  <span style={cardStyles.reportValue}>
                    {reportDetails?.vesselBasicInfo?.shipStatus}
                  </span>
                </div>
                <div
                  style={{
                    ...cardStyles.reportCardCol,
                    ...cardStyles.reportCardColWidth20,
                  }}
                >
                  <span style={cardStyles.reportLabel}>Vessel Type</span>
                  <span style={cardStyles.reportValue}>
                    {reportDetails?.vesselBasicInfo?.vesselType}
                  </span>
                </div>
                <div
                  style={{
                    ...cardStyles.reportCardCol,
                    ...cardStyles.reportCardColWidth20,
                  }}
                >
                  <span style={cardStyles.reportLabel}>DOC Company</span>
                  <span style={cardStyles.reportValue}>
                    {reportDetails?.vesselBasicInfo?.docCompanyName}
                  </span>
                </div>
                <div
                  style={{
                    ...cardStyles.reportCardCol,
                    ...cardStyles.reportCardColWidth10,
                  }}
                >
                  <span style={cardStyles.reportLabel}>
                    LOA {dimensionUnitText()}
                  </span>
                  <span style={cardStyles.reportValue}>
                    {meterToFeetConversion(
                      reportDetails?.vesselBasicInfo?.lengthOverAll
                    )}
                  </span>
                </div>
                <div
                  style={{
                    ...cardStyles.reportCardCol,
                    ...cardStyles.reportCardColWidth15,
                    ...cardStyles.reportBorderRightNone,
                  }}
                >
                  <span style={cardStyles.reportLabel}>Summer DWT</span>
                  <span style={cardStyles.reportValue}>
                    {reportDetails?.vesselBasicInfo?.deadWeight}
                  </span>
                </div>
              </div>
              <div style={cardStyles.reportCardRow}>
                <div
                  style={{
                    ...cardStyles.reportCardCol,
                    ...cardStyles.reportCardColWidth10,
                  }}
                >
                  <span style={cardStyles.reportLabel}>Call sign</span>
                  <span style={cardStyles.reportValue}>
                    {reportDetails?.vesselBasicInfo?.callSign}
                  </span>
                </div>
                <div
                  style={{
                    ...cardStyles.reportCardCol,
                    ...cardStyles.reportCardColWidth20,
                  }}
                >
                  <span style={cardStyles.reportLabel}>Class</span>
                  <span style={cardStyles.reportValue}>
                    {reportDetails?.vesselBasicInfo?.classificationName}
                  </span>
                </div>
                <div
                  style={{
                    ...cardStyles.reportCardCol,
                    ...cardStyles.reportCardColWidth20,
                  }}
                >
                  <span style={cardStyles.reportLabel}>P & I Club</span>
                  <span style={cardStyles.reportValue}>
                    {reportDetails?.vesselBasicInfo?.piClub}
                  </span>
                </div>
                <div
                  style={{
                    ...cardStyles.reportCardCol,
                    ...cardStyles.reportCardColWidth20,
                  }}
                >
                  <span style={cardStyles.reportLabel}>Previous Name</span>
                  <span style={cardStyles.reportValue}>
                    {reportDetails?.vesselBasicInfo?.shipPrevName}
                  </span>
                </div>
                <div
                  style={{
                    ...cardStyles.reportCardCol,
                    ...cardStyles.reportCardColWidth10,
                  }}
                >
                  <span style={cardStyles.reportLabel}>
                    Breadth {dimensionUnitText()}
                  </span>
                  <span style={cardStyles.reportValue}>
                    {meterToFeetConversion(
                      reportDetails?.vesselBasicInfo?.breadthExtreme
                    )}
                  </span>
                </div>
                <div
                  style={{
                    ...cardStyles.reportCardCol,
                    ...cardStyles.reportCardColWidth15,
                    ...cardStyles.reportBorderRightNone,
                  }}
                >
                  <span style={cardStyles.reportLabel}>Sanction</span>
                  <span
                    style={
                      reportDetails?.vesselBasicInfo?.sanction === 'No'
                        ? {
                            ...cardStyles.reportValue,
                            ...cardStyles.reportValueGreen,
                          }
                        : {
                            ...cardStyles.reportValue,
                            ...cardStyles.reportValueRed,
                          }
                    }
                  >
                    {reportDetails?.vesselBasicInfo?.sanction}
                  </span>
                </div>
              </div>
            </div>
          )}
          {reportDetails?.vetReqBasicInfo && (
            <>
              <h3 style={cardStyles.reportSectionHeading}>
                Vet Request Detail
              </h3>
              {/* <div style={cardStyles.reportCardContainer}> */}
              <div style={cardStyles.reportCard}>
                <div style={cardStyles.reportEqualFlex}>
                  <div style={cardStyles.reportFlexEqCol}>
                    <div style={cardStyles.reportCardRow}>
                      <div
                        style={{
                          ...cardStyles.reportCardCol,
                          ...cardStyles.reportCardColWidth100,
                          ...cardStyles.reportBorderRightNone,
                        }}
                      >
                        <span style={cardStyles.reportLabel}>Date</span>
                        <span style={cardStyles.reportValue}>
                          {reportDetails?.vetReqBasicInfo?.requestDate}
                        </span>
                      </div>
                    </div>
                    <div style={cardStyles.reportCardRow}>
                      <div
                        style={{
                          ...cardStyles.reportCardCol,
                          ...cardStyles.reportCardColWidth100,
                          ...cardStyles.reportBorderRightNone,
                        }}
                      >
                        <span style={cardStyles.reportLabel}>
                          Requestor Name
                        </span>
                        <span style={cardStyles.reportValue}>
                          {reportDetails?.vetReqBasicInfo?.employeeName}
                        </span>
                      </div>
                    </div>
                    <div style={cardStyles.reportCardRow}>
                      <div
                        style={{
                          ...cardStyles.reportEqualFlex,
                          ...cardStyles.reportWidth100,
                        }}
                      >
                        <div
                          style={{
                            ...cardStyles.reportCardCol,
                            ...cardStyles.reportCardColWidth50,
                          }}
                        >
                          <span style={cardStyles.reportLabel}>
                            Laycan From Date
                          </span>
                          <span style={cardStyles.reportValue}>
                            {reportDetails?.vetReqBasicInfo?.laycanFromDate}
                          </span>
                        </div>
                        <div
                          style={{
                            ...cardStyles.reportCardCol,
                            ...cardStyles.reportCardColWidth50,
                            ...cardStyles.reportBorderRightNone,
                          }}
                        >
                          <span style={cardStyles.reportLabel}>To Date</span>
                          <span style={cardStyles.reportValue}>
                            {reportDetails?.vetReqBasicInfo?.laycanToDate}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={cardStyles.reportFlexEqCol}>
                    <div style={cardStyles.reportCardRow}>
                      <div
                        style={{
                          ...cardStyles.reportCardCol,
                          ...cardStyles.reportCardColWidth100,
                          ...cardStyles.reportBorderRightNone,
                        }}
                      >
                        <span style={cardStyles.reportLabel}>
                          Business Unit
                        </span>
                        <span style={cardStyles.reportValue}>
                          {reportDetails?.vetReqBasicInfo?.buName}
                        </span>
                      </div>
                    </div>
                    <div style={cardStyles.reportCardRow}>
                      <div
                        style={{
                          ...cardStyles.reportCardCol,
                          ...cardStyles.reportCardColWidth100,
                          ...cardStyles.reportBorderRightNone,
                        }}
                      >
                        <span style={cardStyles.reportLabel}>Company Name</span>
                        <span style={cardStyles.reportValue}>
                          {reportDetails?.vetReqBasicInfo?.companyName}
                        </span>
                      </div>
                    </div>
                    <div style={cardStyles.reportCardRow}>
                      <div
                        style={{
                          ...cardStyles.reportCardCol,
                          ...cardStyles.reportCardColWidth100,
                          ...cardStyles.reportBorderRightNone,
                        }}
                      >
                        <span style={cardStyles.reportLabel}>Inco Terms</span>
                        <span style={cardStyles.reportValue}>
                          {reportDetails?.vetReqBasicInfo?.incoTerm}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style={cardStyles.reportFlexEqCol}>
                    <div style={cardStyles.reportCardRow}>
                      <div
                        style={{
                          ...cardStyles.reportCardCol,
                          ...cardStyles.reportCardColWidth100,
                          ...cardStyles.reportBorderRightNone,
                        }}
                      >
                        <span style={cardStyles.reportLabel}>
                          Voyage Number
                        </span>
                        <span style={cardStyles.reportValue}>
                          {reportDetails?.vetReqBasicInfo?.voyageNumber}
                        </span>
                      </div>
                    </div>
                    <div style={cardStyles.reportCardRow}>
                      <div
                        style={{
                          ...cardStyles.reportCardCol,
                          ...cardStyles.reportCardColWidth100,
                          ...cardStyles.reportBorderRightNone,
                        }}
                      >
                        <span style={cardStyles.reportLabel}>
                          Check Berth Fit
                        </span>
                        <span style={cardStyles.reportValue}>
                          {reportDetails?.vetReqBasicInfo?.berthFitCheck}
                        </span>
                      </div>
                    </div>
                    <div style={cardStyles.reportCardRow}>
                      <div
                        style={{
                          ...cardStyles.reportCardCol,
                          ...cardStyles.reportCardColWidth100,
                          ...cardStyles.reportBorderRightNone,
                        }}
                      >
                        <span style={cardStyles.reportLabel}>Cargo</span>
                        <span style={cardStyles.reportValue}>
                          {reportDetails?.vetReqBasicInfo?.cargo}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style={cardStyles.reportFlexEqCol}>
                    <div style={cardStyles.reportCardRow}>
                      <div
                        style={{
                          ...cardStyles.reportCardCol,
                          ...cardStyles.reportCardColWidth100,
                          ...cardStyles.reportBorderRightNone,
                        }}
                      >
                        <span style={cardStyles.reportLabel}>Email</span>
                        <span style={cardStyles.reportValue}>
                          {reportDetails?.vetReqBasicInfo?.emailIds}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {reportDetails?.loadPortBasicInfo && (
                <div style={cardStyles.reportCard}>
                  <h4 style={cardStyles.reportTitleH3}>Load Port</h4>
                  <div style={cardStyles.reportRowWrapper}>
                    {reportDetails?.loadPortBasicInfo?.map(
                      (loadPort: any, index: any) => {
                        return (
                          <div
                            style={
                              index === 0
                                ? cardStyles.reportCardRow
                                : {
                                    ...cardStyles.reportCardRow,
                                    ...cardStyles.reportBorderTop,
                                  }
                            }
                          >
                            <div
                              style={{
                                ...cardStyles.reportCardCol,
                                ...cardStyles.reportCardColWidth20,
                                ...cardStyles.reportBorderRightNone,
                                ...cardStyles.reportPl0,
                              }}
                            >
                              <span style={cardStyles.reportLabel}>
                                Port{index + 1}
                              </span>
                              <span style={cardStyles.reportValue}>
                                {loadPort?.portName}
                              </span>
                            </div>
                            <div
                              style={{
                                ...cardStyles.reportCardCol,
                                ...cardStyles.reportCardColWidth15,
                                ...cardStyles.reportBorderRightNone,
                                ...cardStyles.reportPl0,
                              }}
                            >
                              <span style={cardStyles.reportLabel}>
                                Check Berth Fit
                              </span>
                              <span style={cardStyles.reportValue}>
                                {loadPort?.berthFitCheck}
                              </span>
                            </div>
                            <div
                              style={{
                                ...cardStyles.reportCardCol,
                                ...cardStyles.reportCardColWidth40,
                                ...cardStyles.reportBorderRightNone,
                                ...cardStyles.reportPl0,
                              }}
                            >
                              <span style={cardStyles.reportLabel}>
                                Terminals
                              </span>
                              <span style={cardStyles.reportValue}>
                                {loadPort?.terminals}
                              </span>
                            </div>
                            <div
                              style={{
                                ...cardStyles.reportCardCol,
                                ...cardStyles.reportCardColWidth25,
                                ...cardStyles.reportBorderRightNone,
                                ...cardStyles.reportPl0,
                              }}
                            >
                              <span style={cardStyles.reportLabel}>
                                Estimated Date of Load
                              </span>
                              <span style={cardStyles.reportValue}>
                                {loadPort?.estimatedDateOfLoad}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
              {reportDetails?.dischargePortBasicInfo && (
                <div style={cardStyles.reportCard}>
                  <h4 style={cardStyles.reportTitleH3}>Discharge Port</h4>
                  <div style={cardStyles.reportRowWrapper}>
                    {reportDetails?.dischargePortBasicInfo?.map(
                      (dischargePort: any, index: any) => {
                        return (
                          <div
                            style={
                              index === 0
                                ? cardStyles.reportCardRow
                                : {
                                    ...cardStyles.reportCardRow,
                                    ...cardStyles.reportBorderTop,
                                  }
                            }
                          >
                            <div
                              style={{
                                ...cardStyles.reportCardCol,
                                ...cardStyles.reportCardColWidth20,
                                ...cardStyles.reportBorderRightNone,
                                ...cardStyles.reportPl0,
                              }}
                            >
                              <span style={cardStyles.reportLabel}>
                                Port{index + 1}
                              </span>
                              <span style={cardStyles.reportValue}>
                                {dischargePort?.portName}
                              </span>
                            </div>
                            <div
                              style={{
                                ...cardStyles.reportCardCol,
                                ...cardStyles.reportCardColWidth15,
                                ...cardStyles.reportBorderRightNone,
                                ...cardStyles.reportPl0,
                              }}
                            >
                              <span style={cardStyles.reportLabel}>
                                Check Berth Fit
                              </span>
                              <span style={cardStyles.reportValue}>
                                {dischargePort?.berthFitCheck}
                              </span>
                            </div>
                            <div
                              style={{
                                ...cardStyles.reportCardCol,
                                ...cardStyles.reportCardColWidth40,
                                ...cardStyles.reportBorderRightNone,
                                ...cardStyles.reportPl0,
                              }}
                            >
                              <span style={cardStyles.reportLabel}>
                                Terminals
                              </span>
                              <span style={cardStyles.reportValue}>
                                {dischargePort?.terminals}
                              </span>
                            </div>
                            <div
                              style={{
                                ...cardStyles.reportCardCol,
                                ...cardStyles.reportCardColWidth25,
                                ...cardStyles.reportBorderRightNone,
                                ...cardStyles.reportPl0,
                              }}
                            >
                              <span style={cardStyles.reportLabel}>
                                Estimated Date Of Discharge
                              </span>
                              <span style={cardStyles.reportValue}>
                                {dischargePort?.estimatedDateOfDischarge}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
              {/* </div> */}
            </>
          )}
          {/* <div className="html2pdf__page-break"></div> */}
          {reportDetails?.ruleInfo && (
            <>
              <h3 style={cardStyles.reportSectionHeading}>Rules</h3>
              <div style={cardStyles.reportCardContainer}>
                {reportDetails?.ruleInfo?.map((rule: any) => {
                  return (
                    <div style={cardStyles.ruleContainer}>
                      <div style={cardStyles.ruleContent}>
                        <div style={cardStyles.ruleIcon}>
                          {rule.isRulePassed && rule.isRulePassedByOverride ? (
                            <icons.OverridePass />
                          ) : !rule.isRulePassed &&
                            rule.isRulePassedByOverride ? (
                            <icons.Overridefail />
                          ) : rule.isRulePassed &&
                            !rule.isRulePassedByOverride ? (
                            <icons.circleTickDanger />
                          ) : (
                            <icons.circleCrossDanger />
                          )}
                        </div>
                        <span> {rule.ruleName} </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {isAttachPdf &&
            reviewComment &&
            reportDetails?.showVetReviewComments && (
              <>
                <h3 style={cardStyles.reportSectionHeading}>
                  Reviewer Comment
                </h3>
                <div
                  style={{
                    ...cardStyles.reportCard,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    minHeight: '50px',
                  }}
                >
                  {displayComments(reviewComment)}
                </div>
              </>
            )}
          {reportDetails?.vetReviewerComments?.length > 0 &&
            showComment(reportDetails?.vetReviewerComments, 'Recommended') >=
              0 && (
              <>
                <h3 style={cardStyles.reportSectionHeading}>
                  Reviewer Comment
                </h3>
                <div
                  style={{
                    ...cardStyles.reportCard,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    minHeight: '50px',
                  }}
                >
                  {displayComments(
                    reportDetails?.vetReviewerComments[
                      showComment(
                        reportDetails?.vetReviewerComments,
                        'Recommended'
                      )
                    ]?.commentBody
                  )}
                </div>
              </>
            )}
          {isNotRecommended ? (
            <>
              {reportDetails?.vetReviewerComments?.length > 0 &&
                showComment(
                  reportDetails?.vetReviewerComments,
                  'NotRecommended'
                ) >= 0 && (
                  <>
                    <h3 style={cardStyles.reportSectionHeading}>
                      Reviewer Comment
                    </h3>
                    <div
                      style={{
                        ...cardStyles.reportCard,
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        minHeight: '50px',
                      }}
                    >
                      {displayComments(
                        reportDetails?.vetReviewerComments[
                          showComment(
                            reportDetails?.vetReviewerComments,
                            'NotRecommended'
                          )
                        ]?.commentBody
                      )}
                    </div>
                  </>
                )}
            </>
          ) : (
            <>
              {reportDetails?.vetReviewerComments?.length > 0 &&
                showComment(
                  reportDetails?.vetReviewerComments,
                  'NotRecommended'
                ) >= 0 && (
                  <>
                    <h3 style={cardStyles.reportSectionHeading}>
                      Reviewer Comment
                    </h3>
                    <div
                      style={{
                        ...cardStyles.reportCard,
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        minHeight: '50px',
                      }}
                    >
                      {displayComments(
                        reportDetails?.vetReviewerComments[
                          showComment(
                            reportDetails?.vetReviewerComments,
                            'NotRecommended'
                          )
                        ]?.commentBody
                      )}
                    </div>
                  </>
                )}
              {reportDetails?.vetReviewerComments?.length > 0 &&
                showComment(reportDetails?.vetReviewerComments, 'Appeal') >=
                  0 && (
                  <>
                    <h3 style={cardStyles.reportSectionHeading}>
                      Appeal Comment
                    </h3>
                    <div
                      style={{
                        ...cardStyles.reportCard,
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        minHeight: '50px',
                      }}
                    >
                      {displayComments(
                        reportDetails?.vetReviewerComments[
                          showComment(
                            reportDetails?.vetReviewerComments,
                            'Appeal'
                          )
                        ]?.commentBody
                      )}
                    </div>
                  </>
                )}
              {reportDetails?.vetReviewerComments?.length > 0 &&
                showComment(reportDetails?.vetReviewerComments, 'Approved') >=
                  0 && (
                  <>
                    <h3 style={cardStyles.reportSectionHeading}>
                      Approve Comment
                    </h3>
                    <div
                      style={{
                        ...cardStyles.reportCard,
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        minHeight: '50px',
                      }}
                    >
                      {displayComments(
                        reportDetails?.vetReviewerComments[
                          showComment(
                            reportDetails?.vetReviewerComments,
                            'Approved'
                          )
                        ]?.commentBody
                      )}
                    </div>
                  </>
                )}
              {reportDetails?.vetReviewerComments?.length > 0 &&
                showComment(reportDetails?.vetReviewerComments, 'Rejected') >=
                  0 && (
                  <>
                    <h3 style={cardStyles.reportSectionHeading}>
                      Reject Comment
                    </h3>
                    <div
                      style={{
                        ...cardStyles.reportCard,
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        minHeight: '50px',
                      }}
                    >
                      {displayComments(
                        reportDetails?.vetReviewerComments[
                          showComment(
                            reportDetails?.vetReviewerComments,
                            'Rejected'
                          )
                        ]?.commentBody
                      )}
                    </div>
                  </>
                )}
              {reportDetails?.vetReviewerComments?.length > 0 &&
                showComment(
                  reportDetails?.vetReviewerComments,
                  'ConditionallyApproved'
                ) >= 0 && (
                  <>
                    <h3 style={cardStyles.reportSectionHeading}>
                      Conditionally Approved Comment
                    </h3>
                    <div
                      style={{
                        ...cardStyles.reportCard,
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        minHeight: '50px',
                      }}
                    >
                      {displayComments(
                        reportDetails?.vetReviewerComments[
                          showComment(
                            reportDetails?.vetReviewerComments,
                            'ConditionallyApproved'
                          )
                        ]?.commentBody
                      )}
                    </div>
                  </>
                )}
            </>
          )}
          {reportDetails?.disclaimerBasicInfo?.marsDisclaimer && (
            <div>
              <h3 style={cardStyles.reportSectionHeading}>Mars Disclaimer</h3>
              <div
                style={{
                  ...cardStyles.reportCard,
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  minHeight: '50px',
                  fontSize: '12px',
                }}
                dangerouslySetInnerHTML={{
                  __html: getCommentBody(
                    reportDetails?.disclaimerBasicInfo?.marsDisclaimer
                  ),
                }}
              ></div>
            </div>
          )}
          {reportDetails?.disclaimerBasicInfo?.requestorDisclaimer && (
            <div>
              <h3 style={cardStyles.reportSectionHeading}>
                {reportDetails?.vetCompanyName + ' Disclaimer'}
              </h3>
              <div
                style={{
                  ...cardStyles.reportCard,
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  minHeight: '50px',
                  fontSize: '12px',
                }}
                dangerouslySetInnerHTML={{
                  __html: getCommentBody(
                    reportDetails?.disclaimerBasicInfo?.requestorDisclaimer
                  ),
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
