import React from 'react';
import { Controller } from 'react-hook-form';
import FormField from '../form-field/FormField';
import { IFormConfig } from '../../../types/form-builder';
import SelectBox from 'devextreme-react/select-box';
import Validator from 'devextreme-react/validator';
import TextBox from 'devextreme-react/text-box';
import NumberBox from 'devextreme-react/number-box';
import CheckBox from 'devextreme-react/check-box';

function FormBuilder(props: { formConfig: IFormConfig[] }) {
  const keyDown = (e: any) => {
    const { event } = e;
    const str = event.key || String.fromCharCode(event.which);
    if (/^[-+.,eE]$/.test(str)) {
      event.preventDefault();
    }
  };

  const input = (e: any, config: any) => {
    if (config.maxLength > 0) {
      if (e.event.target.value.length > 19)
        e.event.target.value = e.event.target.value.slice(0, 20);
    }
  };
  return (
    <>
      {props.formConfig.map((config) => {
        if (
          !config.isFieldArray &&
          config.fieldType === 'customSelect' &&
          !config.isHidden
        ) {
          return (
            <div
              key={config.name}
              // className="col-md-3 col-sm-6 col-lg-3 col-xs-12"
              className={
                config.class
                  ? config.class
                  : 'col-md-3 col-sm-6 col-lg-3 col-xs-12'
              }
            >
              <div className="m-c-form-group mb-btm-space">
                <label className="m-l-input-label">
                  {config.label}
                  {config.isRequired && (
                    <span className="m-l-input-required">*</span>
                  )}
                </label>
                <div className="m-l-input-group-controller">
                  <Controller
                    name={config.selectBoxName}
                    control={config.control}
                    render={({ field }) => (
                      <SelectBox
                        id={config.selectBoxName}
                        elementAttr={{ 'data-testid': config.selectBoxName }}
                        inputAttr={{ autocomplete: config.autocomplete }}
                        dataSource={config.dataSource}
                        displayExpr={config.displayExpr}
                        valueExpr={config.valueExpr}
                        onValueChanged={(e: any) => {
                          field.onChange(e.value);
                          config.onChangeSelectBox
                            ? config.onChangeSelectBox(e)
                            : null;
                        }}
                        value={field.value}
                        searchEnabled={!config.disableSearch}
                        itemRender={config.itemRender}
                        disabled={config.disabled}
                        fieldRender={config.fieldRender}
                        className="m-c-input-control"
                        placeholder=""
                      ></SelectBox>
                    )}
                  />
                  <Controller
                    name={config.name}
                    control={config.control}
                    render={({ field }) => {
                      return config.customSelectInputFieldType !== 'number' ? (
                        <TextBox
                          inputAttr={{ 'data-testid': config.name }}
                          onValueChanged={(e: any) => {
                            field.onChange(e.value);
                            config.onChange ? config.onChange(e) : null;
                          }}
                          value={field.value}
                          readOnly={config.readonly}
                          disabled={config.disabled}
                          maxLength={config.maxLength}
                          className="m-c-input-control m-l-input-control-with-dropdown"
                          validationMessageMode="always"
                        >
                          <Validator validationRules={config.rules} />
                        </TextBox>
                      ) : (
                        <NumberBox
                          inputAttr={{
                            'data-testid': config.name,
                            // maxlength: config.maxLength,
                          }}
                          onValueChanged={(e: any) => {
                            field.onChange(e.value);
                            config.onChange ? config.onChange(e) : null;
                          }}
                          value={field.value}
                          readOnly={config.readonly}
                          disabled={config.disabled}
                          mode="number"
                          onKeyDown={keyDown}
                          onInput={(e) => input(e, config)}
                          className="m-c-input-control m-l-input-control-with-dropdown"
                        >
                          <Validator validationRules={config.rules} />
                        </NumberBox>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          );
        } else if (
          !config.isFieldArray &&
          config.fieldType === 'numberRange' &&
          !config.isHidden
        ) {
          return (
            <div key={config.name} className={config.class}>
              <div className="mb-btm-space m-c-form-group m-l-input__group-block">
                <div className="m-l-left-input-group">
                  <label className="m-l-input-label">
                    {config.label}
                    {config.isRequired && (
                      <span className="m-l-input-required">*</span>
                    )}
                  </label>
                  <Controller
                    name={config.name}
                    control={config.control}
                    render={({ field }) => (
                      <NumberBox
                        inputAttr={{ 'data-testid': config.name }}
                        name={config.name}
                        onValueChanged={(e: any) => {
                          field.onChange(e.value);
                          config.onChangeSelectBox
                            ? config.onChangeSelectBox(e)
                            : null;
                        }}
                        value={field.value}
                        className="m-c-input-control"
                      >
                        <Validator validationRules={config.rules} />
                      </NumberBox>
                    )}
                  />
                </div>
                <div className="m-l-right-input-group">
                  <label className="m-l-input-label">
                    {config.toLabel}
                    {config.isRequired && (
                      <span className="m-l-input-required">*</span>
                    )}
                  </label>
                  <Controller
                    name={config.toName}
                    control={config.control}
                    render={({ field }) => (
                      <NumberBox
                        inputAttr={{ 'data-testid': config.toName }}
                        name={config.toName}
                        onValueChanged={(e: any) => {
                          field.onChange(e.value);
                          config.onChangeSelectBox
                            ? config.onChangeSelectBox(e)
                            : null;
                        }}
                        value={field.value}
                        className="m-c-input-control"
                      >
                        <Validator validationRules={config.rules} />
                      </NumberBox>
                    )}
                  />
                </div>
              </div>
            </div>
          );
        } else if (
          !config.isFieldArray &&
          config.fieldType === 'textWithCheckbox' &&
          !config.isHidden
        ) {
          return (
            <div
              key={config.name}
              className={
                config.class
                  ? config.class
                  : 'col-md-3 col-sm-6 col-lg-3 col-xs-12'
              }
            >
              <div
                className={
                  config.fieldWrapperClass
                    ? config.fieldWrapperClass
                    : 'mb-btm-space m-c-form-group'
                }
              >
                {!config.checkBoxLabel && (
                  <label className="m-l-input-label">
                    {config.label}
                    {config.isRequired && (
                      <span className="m-l-input-required">*</span>
                    )}
                  </label>
                )}
                {config.checkBoxLabel && (
                  <div className="m-l-default-checkbox-unit">
                    <label className="m-l-input-label">
                      {config.label}
                      {config.isRequired && (
                        <span className="m-l-input-required">*</span>
                      )}
                    </label>
                    <div className="m-c-checkbox">
                      <Controller
                        name={config.checkBoxName}
                        control={config.control}
                        render={({ field }) => (
                          <CheckBox
                            elementAttr={{
                              'data-testid': config.checkBoxName,
                            }}
                            name={config.checkBoxName}
                            value={field.value}
                            onValueChanged={(e: any) => {
                              field.onChange(e.value);
                              config.onChangeCheckBox
                                ? config.onChangeCheckBox(e)
                                : null;
                            }}
                            disabled={config.disabled}
                          />
                        )}
                      />

                      <span className="m-l-checkbox--small-label">
                        {config.checkBoxLabel}
                      </span>
                    </div>
                  </div>
                )}

                <Controller
                  name={config.name}
                  control={config.control}
                  render={({ field }) => (
                    <TextBox
                      inputAttr={{ 'data-testid': config.name }}
                      name={config.name}
                      onKeyUp={(e) =>
                        config.onKeyUp ? config.onKeyUp(e, field) : undefined
                      }
                      onValueChanged={(e: any) => {
                        field.onChange(e.value);
                      }}
                      value={field.value}
                      readOnly={config.readonly}
                      className={
                        config.fieldClass
                          ? config.fieldClass
                          : 'm-c-input-control'
                      }
                      maxLength={config.maxLength}
                      disabled={config.disabled}
                      placeholder={config.placeholder}
                      validationMessageMode="always"
                    >
                      <Validator validationRules={config.rules} />
                    </TextBox>
                  )}
                />
              </div>
            </div>
          );
        } else if (!config.isFieldArray && !config.isHidden) {
          return (
            <div
              key={config.name}
              className={
                config.class
                  ? config.class
                  : 'col-md-3 col-sm-6 col-lg-3 col-xs-12'
              }
            >
              <Controller
                name={config.name}
                control={config.control}
                render={({ field, fieldState }) => (
                  <FormField
                    config={{ ...config }}
                    field={{ ...field }}
                    fieldState={{ ...fieldState }}
                  />
                )}
              />
            </div>
          );
        }
      })}
    </>
  );
}

export default FormBuilder;
