import React, { useEffect, useMemo, useRef, useState } from 'react';
import BarChart from '../../common/charts/bar-chart/BarChart';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { IOptionvalue } from '../../../types/master-data';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import SelectBox from 'devextreme-react/select-box';
import { GadgetsTimePeriod } from '../../../enums/gadgets-time-period-enum';
import Popover from 'devextreme-react/popover';
import icons from '../../common/icons/icons';
import { Button } from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import { useResize } from '../../../hooks/useResize';
import { getOPtionValueByType } from '../../../services/master-data-service';
import { OptionTypes } from '../../../enums/option-type-enum';

export default function VetStatusAvgTimeGadget() {
  const { setIsLoading } = useLoaderContext();
  const [gadgetsTimePeriod, setGadgetsTimePeriod] = useState<any[]>([]);
  const [vetsByRequestor, setVetsByRequestor] = useState<any>();
  const { t } = useTranslation();
  const [timePeriod, setTimePeriod] = useState(
    gadgetsTimePeriod[0]?.optionValueCode
  );
  const [showExportPopover, setShowExportPopover] = useState(false);
  const exportPopupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-dashboard__chart-export',
      'data-testid': 'rolePopover',
    };
  }, []);
  const getGadgetsTimePeriod = async () => {
    await getOPtionValueByType(OptionTypes.GadgetsTimePeriod)?.then(
      (res: IOptionvalue[]) => {
        const data = res ? JSON.parse(JSON.stringify(res)) : [];
        const fromIndex = data?.findIndex((x: any) => x?.isDefault == true);
        const element = data?.splice(fromIndex, 1)[0];
        const toIndex = 0;
        data?.splice(toIndex, 0, element);
        setGadgetsTimePeriod(data);
      }
    );
  };

  useEffect(() => {
    if (timePeriod) {
      setDataSource();
    }
  }, [timePeriod]);

  const setDataSource = () => {
    if (timePeriod == GadgetsTimePeriod.Week) {
      getVetByRequestorGadgetTimePeriod(GadgetsTimePeriod.Week);
    } else if (timePeriod == GadgetsTimePeriod.Month) {
      getVetByRequestorGadgetTimePeriod(GadgetsTimePeriod.Month);
    } else if (timePeriod == GadgetsTimePeriod.Quarter) {
      getVetByRequestorGadgetTimePeriod(GadgetsTimePeriod.Quarter);
    } else if (timePeriod == GadgetsTimePeriod.HalfYear) {
      getVetByRequestorGadgetTimePeriod(GadgetsTimePeriod.HalfYear);
    } else {
      getVetByRequestorGadgetTimePeriod(GadgetsTimePeriod.Year);
    }
  };

  const getVetByRequestorGadgetTimePeriod = async (period: any) => {
    await api
      .get(
        {
          url: apiConfig.vetStatusAvgTime,
          params: { timePeriod: period },
        },
        setIsLoading
      )
      .then((data: any) => {
        setVetsByRequestor(data.vetStatusAverageList);
      });
  };

  const getVetByRequestorGadget = async () => {
    await api
      .get(
        {
          url: apiConfig.vetStatusAvgTime,
          params: { timePeriod: GadgetsTimePeriod.Week },
        },
        setIsLoading
      )
      .then((data: any) => {
        setVetsByRequestor(data.vetStatusAverageList);
      });
  };

  const fileFormats = [
    {
      id: 1,
      name: 'PNG File',
      value: 'png',
    },
    {
      id: 2,
      name: 'JPEG File',
      value: 'jpeg',
    },
    {
      id: 3,
      name: 'PDF File',
      value: 'pdf',
    },
    {
      id: 4,
      name: 'SVG File',
      value: 'svg',
    },
  ];

  const chartRef: any = useRef();

  const chartConfig = {
    name1: 'Hours',
    valueField1: 'hours',
    argumentField: 'vetStatus',
    type: 'bar',
    series1Color: '#3138e8',
    rotated: false,
    showLabel: false,
    ref: chartRef,
    export: {
      enabled: true,
    },
    argumentAxis: {
      label: {
        displayMode: 'rotate',
        rotationAngle: 300,
        alignment: 'right',
      },
      // type: 'discrete'
      discreteAxisDivisionMode: 'crossLabels',
    },
  };

  useMemo(() => {
    if (gadgetsTimePeriod && gadgetsTimePeriod.length > 0) {
      setTimePeriod(gadgetsTimePeriod[0].optionValueCode);
    }
  }, [gadgetsTimePeriod]);

  useEffect(() => {
    getGadgetsTimePeriod();
    // getVetByRequestorGadget();
  }, []);

  const onCLickExport = (format: any) => {
    chartRef?.current?.instance?.exportTo(t('labels.vetStatusAvgTime'), format);
  };

  const { isMobileResolution } = useResize();
  const [position, setPosition] = useState<any>('bottom');
  useEffect(() => {
    if (isMobileResolution) {
      setPosition('left');
    } else {
      setPosition('bottom');
    }
  }, [isMobileResolution]);

  return (
    <div>
      {gadgetsTimePeriod && gadgetsTimePeriod.length > 0 && (
        <div className="row">
          <div className="col-sm-4 col-md-5 col-lg-6 col-xl-4 col-6">
            <div className="m-c-form-group mb-btm-space">
              <SelectBox
                className="m-c-input-control m-c-select-box"
                placeholder="Select option type"
                dataSource={gadgetsTimePeriod}
                defaultValue={gadgetsTimePeriod[0].optionValueCode}
                searchEnabled
                valueExpr="optionValueCode"
                displayExpr="optionValueText"
                onValueChanged={(e) => setTimePeriod(e.value)}
              />
            </div>
          </div>
          <div
            className="col-sm-8 col-md-7 col-lg-6 col-xl-8 
          col-6 d-flex"
          >
            <div className="ml-dashboard-export">
              <Button
                id="linkVetStatusAvgTime"
                className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                aria-label="Export-btn"
                onClick={() => setShowExportPopover(true)}
              >
                <div className="m-c-icon-inside-btn" data-testid="export-all">
                  <icons.download />
                </div>
              </Button>
              <Popover
                target="#linkVetStatusAvgTime"
                position={position}
                width={132}
                visible={showExportPopover}
                wrapperAttr={exportPopupAttributes}
                closeOnOutsideClick={true}
                onHiding={() => setShowExportPopover(false)}
              >
                <ul className="ml-expport-list">
                  {/* <li>Print</li> */}
                  {fileFormats.map((role: any, index: any) => {
                    return (
                      <li key={index} onClick={() => onCLickExport(role.value)}>
                        {role.name}
                      </li>
                    );
                  })}
                </ul>
              </Popover>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <BarChart
          dataSource={vetsByRequestor}
          chartConfig={chartConfig}
          chartRef={chartRef}
        />
      </div>
    </div>
  );
}
