import React, { useEffect, useMemo, useRef, useState } from 'react';
import icons from '../../common/icons/icons';
import Modal from '../../common/modal/Modal';
import Button from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import TextBox from 'devextreme-react/text-box';
import Switch from 'devextreme-react/switch';
import { useTranslation } from 'react-i18next';
import { Restricted } from '../../../contexts/PermissionContext';
import { RequiredRule, Validator } from 'devextreme-react/validator';

export default function SaveFavorite(props: {
  showSaveFav: any;
  setFavoriteName: any;
  setIsDefault: any;
  setIsPrivate: any;
  setShowSaveFav: any;
  checkNameExist: any;
  isDuplicateFav?: any;
  setIsDuplicateFav: any;
  editFav: any;
  setEditFav: any;
  duplicateFavData?: any;
  updateFavorite: any;
  addOrEdit: any;
}) {
  const { t } = useTranslation();
  const {
    showSaveFav,
    setFavoriteName,
    setIsDefault,
    setIsPrivate,
    setShowSaveFav,
    checkNameExist,
    isDuplicateFav,
    setIsDuplicateFav,
    editFav,
    setEditFav,
    duplicateFavData,
    updateFavorite,
    addOrEdit,
  } = props;

  const favPopupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-edit-name-popup',
    };
  }, []);

  const favModalConfig = {
    width: 400,
    height: 300,
    visible: showSaveFav,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  useEffect(() => {
    if (editFav) {
      setFavoriteName(duplicateFavData?.favoriteName);
    }
  }, [editFav]);

  useEffect(() => {
    if (duplicateFavData) {
      if (!duplicateFavData.isDefault) {
        setIsDefault(false);
      }
      if (!duplicateFavData.isPrivate) {
        setIsPrivate(false);
      }
    }
  }, [duplicateFavData]);

  const changeFavoriteName = (e: any) => {
    setFavoriteName(e.value);
  };

  const changeIsDefault = (e: any) => {
    duplicateFavData.isDefault = e.value;
    setIsDefault(e.value);
  };

  const changeIsPrivate = (e: any) => {
    if (duplicateFavData) {
      duplicateFavData.isPrivate = e.value;
    }
    setIsPrivate(e.value);
  };

  const onSave = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      checkNameExist();
    }
  };

  return (
    <React.Fragment>
      <div className="m-l-model-popup__wrap">
        <Modal modalConfig={favModalConfig} wrapperAttr={favPopupAttributes}>
          <div className="m-l-modal__header">
            <h2 className="modal-title"> Save Favorite </h2>
            <div className="m-l-modal-close-button-block">
              <Button
                className="app-c-btn app-c-icon-only-btn"
                onClick={() => {
                  setShowSaveFav(false);
                  setIsDuplicateFav(false);
                  setEditFav(false);
                }}
              >
                <icons.IconCross />
              </Button>
            </div>
          </div>
          <div className="m-l-modal__body">
            <ScrollView width="100%" height="100%">
              <div className="m-l-edit-form-panel">
                <div className="row m-l-custom-sixteen-space-row">
                  <div className="mb-3 m-c-form-group">
                    <label className="m-l-input-label">Favorite Name</label>
                    <div className="m-l-input-label-padding">
                      {editFav ? (
                        <TextBox
                          className="m-c-input-control"
                          placeholder="Enter"
                          onValueChanged={changeFavoriteName}
                          defaultValue={duplicateFavData?.favoriteName}
                          validationMessageMode="always"
                        >
                          <Validator>
                            <RequiredRule message={t('errors.required')} />
                          </Validator>
                        </TextBox>
                      ) : (
                        <TextBox
                          className="m-c-input-control"
                          placeholder="Enter"
                          onValueChanged={changeFavoriteName}
                          validationMessageMode="always"
                        >
                          <Validator>
                            <RequiredRule message={t('errors.required')} />
                          </Validator>
                        </TextBox>
                      )}
                    </div>
                    {isDuplicateFav ? null : (
                      <div className="m-l-report-toggle-btn-holder">
                        <div className="m-l-report-toggle-btn">
                          <label className="m-l-input-label">Default</label>
                          <div className="m-l-toggle-switch">
                            {editFav ? (
                              <Switch
                                elementAttr={{ 'data-testid': 'canOverride' }}
                                name="isDefault"
                                onValueChanged={changeIsDefault}
                                defaultValue={duplicateFavData?.isDefault}
                              />
                            ) : (
                              <Switch
                                elementAttr={{ 'data-testid': 'canOverride' }}
                                name="isDefault"
                                onValueChanged={changeIsDefault}
                                // value={field.value}
                              />
                            )}
                          </div>
                        </div>

                        <Restricted permission="ReportingFrameWork.ShareFavorite">
                          <div className="m-l-report-toggle-btn">
                            <label className="m-l-input-label"> Private </label>
                            <div className="m-l-toggle-switch">
                              {editFav ? (
                                <Switch
                                  elementAttr={{ 'data-testid': 'canOverride' }}
                                  name="isPrivate"
                                  onValueChanged={changeIsPrivate}
                                  defaultValue={duplicateFavData?.isPrivate}
                                />
                              ) : (
                                <Switch
                                  elementAttr={{ 'data-testid': 'canOverride' }}
                                  name="isPrivate"
                                  onValueChanged={changeIsPrivate}
                                  // value={field.value}
                                />
                              )}
                            </div>
                          </div>
                        </Restricted>
                      </div>
                    )}
                    {/* <div className="m-l-rule-list-column1"> */}

                    {/* </div> */}

                    {/* <div className="m-l-rule-list-column1"> */}

                    {/* </div> */}
                  </div>
                </div>
              </div>
            </ScrollView>
          </div>
          <div className="m-l-modal__footer">
            <Button
              className="app-c-btn app-c-btn--secondary min-btn-width"
              onClick={() => {
                setShowSaveFav(false);
                setIsDuplicateFav(false);
                setEditFav(false);
              }}
              elementAttr={{ 'data-testid': 'backButton' }}
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              className="app-c-btn app-c-btn--primary min-btn-width"
              onClick={onSave}
              elementAttr={{ 'data-testid': 'saveButton' }}
            >
              {t('buttons.save')}
            </Button>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
}
