import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormConfig } from '../../../../../types/form-builder';
import FormBuilder from '../../../../common/form-builder/FormBuilder';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';

export default function GeneralSettingsForm() {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [dateFormatValues, setDateFormatValues] = useState<any>();
  const [fileTypes, setFileTypes] = useState<any>();
  //   const { isMarsUser } = props;

  const getDateFormat = async () => {
    await api
      .get({ url: apiConfig.optionValue + 'DateTimeFormat' })
      .then((data: any) => {
        setDateFormatValues(data);
      });
  };

  const getFileTypes = async () => {
    await api
      .get({ url: apiConfig.optionValue + 'AttachmentAllowedFileTypes' })
      .then((data: any) => {
        setFileTypes(data);
      });
  };

  useEffect(() => {
    getDateFormat();
    getFileTypes();
  }, []);

  const generalFormConfig: IFormConfig[] = [
    {
      class: 'col-lg-4 col-md-6 col-sm-12 col-xs-12',
      name: 'companyInviteExpiry',
      label: t('labels.companyInviteExpiry'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-6 col-sm-12 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'notificationDuration',
      label: t('labels.notificationDuration'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-6 col-sm-12 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'dateFormat',
      label: t('labels.dateFormat'),
      control: control,
      fieldType: 'select',
      dataSource: dateFormatValues,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueText',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class: 'col-lg-4 col-md-6 col-sm-12 col-xs-12',
      name: 'cancelWaitingPeriod',
      label: t('labels.vetRequestAutoCancel'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-6 col-sm-12 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'fileMaxLength',
      label: t('labels.attachmentFileMaximumLength'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class: 'col-lg-4 col-md-6 col-sm-12 col-xs-12',
      name: 'allowedFileTypes',
      label: t('labels.attachmentAllowedFileTypes'),
      control: control,
      fieldType: 'multiSelect',
      dataSource: fileTypes,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueText',
      showClearButton: true,
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },

    {
      class: 'col-lg-6 col-md-12 col-sm-12 col-xs-12',
      name: 'unassignWaitingPeriod',
      label: t('labels.vetRequestAutoUnassign'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class: 'col-lg-6 col-md-12 col-sm-12 col-xs-12',
      name: 'processingUnassignWaitingPeriod',
      label: t('labels.processingUnassignWaitingPeriod'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={generalFormConfig} />
      </div>
    </>
  );
}
