import React, { useEffect, useMemo, useRef, useState } from 'react';
import TabPanel from 'devextreme-react/tab-panel';
import { Template } from 'devextreme-react/core/template';
import { Button } from 'devextreme-react/button';
import icons from '../../../components/common/icons/icons';
import Grid from '../../../components/common/grid/Grid';
import { IGridConfig } from '../../../types/grid';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { getAPIRoute } from '../../../utils/api-route';
import { useLocation, useNavigate } from 'react-router-dom';
import RfiTemplate from '../../../components/common/rfi-template/RfiTemplate';
import { useTranslation } from 'react-i18next';
import toast from '../../../utils/toast';
import ConfirmDialog from '../../../components/common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../types/dialog';
import { Restricted } from '../../../contexts/PermissionContext';
import { useLoaderContext } from '../../../contexts/LoaderContext';

export default function MailList() {
  const { t } = useTranslation();
  const [mailList, setMailList] = useState<any>([]);
  const location = useLocation();
  const banner2: any = location.state;
  const vetId = banner2.bannerObj.vetId;
  const vetRequestId = banner2.bannerObj.vetRequestId;
  const banner: any = banner2.bannerObj.bannerInfoDetails;
  const tabValue: any = banner2.bannerObj.selectedTab;
  const navigate = useNavigate();
  const [rfiTemplate, showRFITemplate] = useState(false);
  const [isRFIClient, setIsRFIClient] = useState(true);
  const [ruleRFITemplateTypes, setRuleRFITemplateTypes] = useState();
  const [selectedMailIDList, setSelectedMailIDList] = useState<any[]>([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [mailId, setMailId] = useState('');
  const { setIsLoading } = useLoaderContext();

  const vesselMailList = mailList.filter(
    (item: any) => item.vetEmailType == 'V'
  );

  const requestorMailList = mailList.filter(
    (item: any) => item.vetEmailType != 'V'
  );

  const getRuleRFITemplateTypes = async () => {
    await api
      .get({
        url: apiConfig.vetEmailTypes,
      })
      .then((res) => {
        if (isRFIClient) {
          setRuleRFITemplateTypes(res[0]);
        } else {
          setRuleRFITemplateTypes(res[1]);
        }
      });
  };

  const getSelectedMails = (data: any) => {
    const mailList: any[] = [];
    data?.forEach((element: any) => {
      const vetRequestOutgoingEmailId = element.vetRequestOutgoingEmailId;
      mailList.push(vetRequestOutgoingEmailId);
    });
    setSelectedMailIDList(mailList);
  };

  useEffect(() => {
    getRuleRFITemplateTypes();
  }, [isRFIClient]);

  const onRowClick = (e: any) => {
    if (!(e.event.target.className === 'm-l-grid-delete-icon-wrapper')) {
      getSelectedMails([e.data]);
      showRFITemplate(true);
    }
  };

  const items = useMemo(() => {
    return [
      {
        title: 'Requestor',
        template: 'Requester',
      },
      {
        title: 'Vessel Owner',
        template: 'VesselOwner',
      },
    ];
  }, []);

  const getMailList = async () => {
    if (vetRequestId) {
      await api
        .get(
          {
            url: getAPIRoute('vetRequestMailList', [vetRequestId]),
          },
          setIsLoading
        )
        .then((response) => {
          setMailList(response);
        });
    }
  };

  const gridRefRequestor: any = useRef();
  const gridRefVesselOwner: any = useRef();

  const gridConfig: IGridConfig = {
    ref: gridRefRequestor,
    dataSource: requestorMailList,
    defaultColumns: [
      {
        caption: t('labels.vetRule'),
        dataField: 'ruleName',
        dataType: 'string',
        minWidth: 150,
        width: '20%',
      },
      {
        caption: t('labels.to'),
        dataField: 'to',
        dataType: 'string',
        minWidth: 150,
        width: '30%',
      },
      {
        caption: t('labels.mailSubject'),
        dataField: 'subject',
        dataType: 'string',
        minWidth: 150,
        width: '40%',
      },
      {
        caption: t('labels.attachments'),
        dataField: 'attachments',
        dataType: 'string',
        minWidth: 150,
        cellTemplate: 'cellTemplate',
        width: '30%',
      },
      {
        caption: '',
        type: 'buttons',
        minWidth: 72,
        alignment: 'right',
        allowFiltering: false,
        allowSorting: false,
        cellTemplate: 'actionTemplate',
      },
    ],
    rowClick: onRowClick,
    hidePagination: true,
    showSelection: true,
    hideFilterRow: true,
  };

  const gridConfig2: IGridConfig = {
    dataSource: vesselMailList,
    ref: gridRefVesselOwner,
    defaultColumns: [
      {
        caption: t('labels.vetRule'),
        dataField: 'ruleName',
        dataType: 'string',
        minWidth: 150,
        width: '20%',
      },
      {
        caption: t('labels.to'),
        dataField: 'to',
        dataType: 'string',
        minWidth: 150,
        width: '30%',
      },
      {
        caption: t('labels.mailSubject'),
        dataField: 'subject',
        dataType: 'string',
        minWidth: 150,
        width: '40%',
      },
      {
        caption: t('labels.attachments'),
        dataField: 'attachments',
        dataType: 'string',
        minWidth: 150,
        cellTemplate: 'cellTemplate',
        width: '30%',
      },
      {
        caption: '',
        type: 'buttons',
        minWidth: 72,
        alignment: 'right',
        allowFiltering: false,
        allowSorting: false,
        cellTemplate: 'actionTemplate',
      },
    ],
    rowClick: onRowClick,
    hidePagination: true,
    showSelection: true,
    hideFilterRow: true,
  };

  const onClickDelete = (mailID: any) => {
    setMailId(mailID);
    setShowConfirmDelete(true);
  };

  const deleteMail = async () => {
    await api
      .delete(
        {
          url: getAPIRoute('deleteRfiEmail', [vetRequestId, mailId]),
        },
        setIsLoading
      )
      .then(() => {
        getMailList();
        toast.success({ title: t('toast.maildeletedSuccessfully') });
      });
  };

  const confirmDeleteDialogConfig: IDialogConfig = {
    id: 'confirmDelete',
    content: t('toast.mailDeleteConfirm'),
    handleSubmit: () => {
      deleteMail();
      setShowConfirmDelete(false);
    },
    handleClose: () => {
      setShowConfirmDelete(false);
    },
  };

  const clickButton = () => {
    let selectedItems: any;
    if (isRFIClient) {
      selectedItems = gridRefRequestor.current?.instance?.getSelectedRowsData();
    } else {
      selectedItems =
        gridRefVesselOwner.current?.instance?.getSelectedRowsData();
    }
    selectedItems && getSelectedMails(selectedItems);
    if (selectedItems && selectedItems.length > 0) {
      showRFITemplate(true);
    }
  };

  const onTabChange = (e: any) => {
    if (e.addedItems[0]?.template === 'Requester') {
      setIsRFIClient(true);
    } else {
      setIsRFIClient(false);
    }
  };

  useEffect(() => {
    getMailList();
  }, []);

  const actionTemplate = (data: any) => {
    return (
      <Restricted permission={'VetRequest.Delete'}>
        <div
          className="m-l-grid-delete-icon-wrapper"
          onClick={(event) => {
            event?.stopPropagation(),
              onClickDelete(data?.data?.vetRequestOutgoingEmailId);
          }}
        >
          <icons.IconDelete />
        </div>
      </Restricted>
    );
  };

  return (
    <div className="inner-wrapper">
      <ul aria-label="breadcrumb" className="m-c-bredcrumb">
        <li>
          <a className="active">{t('labels.vetRequest')}</a>
        </li>
      </ul>
      <div className="m-l-inner-page-header">
        <div className="m-l-inner-page-header-left-block">
          {/* <div className="m-l-title-block"> */}
          <div className="m-l-page-main-heading">
            {banner.vesselName}
            <span className="m-l-page-id-dts">
              {t('labels.vetId')} {vetId}
            </span>
          </div>
          <div className="m-l-page-sub-heading">
            {t('labels.imo')} {banner.imoNumber}
          </div>
          <div className="m-l-page-sub-category-title">
            {t('labels.mailList')}
          </div>
          {/* </div> */}
        </div>
      </div>
      <div className="m-l-inner-page-body m-l-inner-maillist">
        <div className="m-l-tab-panel__wrap">
          <TabPanel
            items={items}
            className="m-c-tab-block"
            onSelectionChanged={onTabChange}
          >
            <Template
              name="Requester"
              render={() => (
                <div className="m-c-grid m-c-userlist-grid">
                  <Grid
                    gridConfig={gridConfig}
                    actionTemplate={actionTemplate}
                    cellTemplate={cellTemplate}
                  />
                </div>
              )}
            />
            <Template name="companyInfoForm" render={() => <div>hu</div>} />
            <Template
              name="VesselOwner"
              render={() => (
                <div className="m-c-grid m-c-userlist-grid">
                  <Grid
                    gridConfig={gridConfig2}
                    actionTemplate={actionTemplate}
                    cellTemplate={cellTemplate}
                  />
                </div>
              )}
            />
            <Template name="companyInfoForm" render={() => <div>hu</div>} />
          </TabPanel>
          <div className="m-l-tab-panel-right-block">
            <Button
              className="app-c-btn app-c-btn--secondary"
              onClick={() =>
                navigate('/vessel-info', {
                  state: {
                    imoNumber: banner.imoNumber,
                    vetRequestId: vetRequestId,
                    tabValue: tabValue,
                  },
                })
              }
            >
              {t('buttons.back')}
            </Button>
            <Restricted permission={'VetRequest.Combine&SendEmail'}>
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                onClick={clickButton}
              >
                {t('buttons.combineAndSend')}
              </Button>
            </Restricted>
          </div>
        </div>
      </div>

      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <Button className="app-c-btn app-c-btn--secondary">Cancel</Button>
              <Button className="app-c-btn app-c-btn--primary min-btn-width">
                Combine and Send
              </Button>
            </div>
          </div>
        </div>
      </div>
      {rfiTemplate && (
        <RfiTemplate
          isVisible={rfiTemplate}
          setIsVisible={showRFITemplate}
          ruleRFITemplateTypes={ruleRFITemplateTypes}
          isRFIClient={isRFIClient}
          vetRequestId={vetRequestId}
          selectedMailIDList={selectedMailIDList}
          getMailList={getMailList}
        />
      )}
      <ConfirmDialog
        dialogConfig={confirmDeleteDialogConfig}
        isOpen={showConfirmDelete}
      />
    </div>
  );
}
function cellTemplate(data: any) {
  const attachmentList: any = data.data.attachments;
  if (attachmentList?.length > 0) {
    return (
      <div className="m-l-attachment-wrapper">
        <div className="m-l-attachment-icon-wrapper">
          <icons.IconAttach12 />
        </div>
        {data.data.attachments.map((value: any, index: any) => {
          return (
            <div className="m-l-attachment-file" key={index}>
              {index != 0 && <span>{','}</span>} {value.fileName}
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
}
