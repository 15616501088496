import React from 'react';
import { useTranslation } from 'react-i18next';

export default function PageNotFound() {
  const { t } = useTranslation();

  return (
    <div className="inner-wrapper">
      <div className="m-l-msg-display-wrapper">
        <div className="m-l-main-msg-container">
          <div className="m-l-main-msg-holder">
            <div className="m-l-msg-ttl">404</div>
          </div>
          <div className="m-l-sub-msg-holder">
            <div className="m-l-sub-msg-ttl">
              <span className="m-l-msg-highlight">{t('labels.error')} </span>
              {t('labels.pageNotFound')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
