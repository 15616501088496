import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Template } from 'devextreme-react/core/template';
import TabPanel from 'devextreme-react/tab-panel';
import Management from '../Management';
import History from '../history/history';
import Button from 'devextreme-react/button';
import icons from '../../../../../components/common/icons/icons';
import ManagementEditModal from '../edit-management/EditManagement';
import { IHistoryDetails } from '../../../../../types/vessel-management-tab';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import {
  Restricted,
  usePermission,
} from '../../../../../contexts/PermissionContext';

export default function ManagementGrid(props: { imoNumber?: string }) {
  const { imoNumber } = props;
  const { findFeature } = usePermission();
  const [modalId, setModalId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const managementRef = useRef<any>();
  const [historyDetails, setHistoryDetails] = useState<IHistoryDetails[]>([]);
  const [selectedTab, setSelectedTab] = useState<number | undefined>(0);

  const onAddClick = () => {
    setIsEdit(!isEdit);
    setModalId(2);
  };

  const handleCancelClick = () => {
    setIsEdit(!isEdit);
  };

  const getHistoryDetails = async () => {
    await api
      .get({ url: apiConfig.vesselManagementRolesHistory + imoNumber })
      .then((res) => {
        setHistoryDetails(res);
      });
  };

  useEffect(() => {
    if (imoNumber) {
      getHistoryDetails();
    }
  }, [imoNumber]);

  const itemsManagement = useMemo(() => {
    return [
      {
        title: 'Company Details',
        template: 'ManagementDetails',
        visible: findFeature('Ship.ViewManagement'),
      },
      {
        title: 'History',
        template: 'History',
        visible: findFeature('Ship.ViewManagementHistory'),
      },
    ];
  }, []);

  const tabRef = useRef<TabPanel>(null);

  const onTabSelectionChanged = () => {
    setSelectedTab(tabRef.current?.instance.option('selectedIndex'));
  };

  return (
    <React.Fragment>
      <div className="m-l-inner-page-body u-mb0">
        <TabPanel
          items={itemsManagement}
          className="m-c-tab-block m-c-tab-block--full-width"
          height={450}
          ref={tabRef}
          onSelectionChanged={onTabSelectionChanged}
        >
          <Template
            name="ManagementDetails"
            render={() => (
              <Management
                ref={managementRef}
                imoNumber={imoNumber}
                getHistoryDetails={getHistoryDetails}
              />
            )}
          />
          <Template
            name="History"
            render={() => (
              <History
                imoNumber={imoNumber}
                getHistoryDetails={getHistoryDetails}
                historyDetails={historyDetails}
              />
            )}
          />
        </TabPanel>
        {selectedTab === 0 && (
          <div className="m-l-tab-panel-right-block tp-10">
            <Restricted permission="Ship.AddManagement">
              <Button className="app-c-btn app-c-btn--secondary min-btn-width-sm-pls">
                <div className="m-c-icon-inside-btn" onClick={onAddClick}>
                  <icons.AddIcon />
                </div>
              </Button>
            </Restricted>
          </div>
        )}
      </div>
      <div className="m-l-inner-page-footer top-8">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <Button className="app-c-btn app-c-btn--secondary min-btn-width-sm-pls">
                <div className="m-c-icon-inside-btn">
                  <icons.AddIcon />
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isEdit ? (
        <ManagementEditModal
          imoNumber={imoNumber}
          isVisible={isEdit}
          modalId={modalId}
          handleCancelClick={handleCancelClick}
          getVesselMAnagementRoles={
            managementRef.current.getVesselMAnagementRoles
          }
          getHistoryDetails={getHistoryDetails}
        />
      ) : null}
    </React.Fragment>
  );
}
