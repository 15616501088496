import Button from 'devextreme-react/button';
import { TextBox } from 'devextreme-react/text-box';
import React from 'react';
import imageURL from '../../assets/images';
import { MarsApiService as api } from '../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import { useNavigate } from 'react-router-dom';
import Validator from 'devextreme-react/validator';
import toast from '../../utils/toast';
import { useTranslation } from 'react-i18next';
import { useLoaderContext } from '../../contexts/LoaderContext';

export default function ViewForgotPassword() {
  const [userEmail, setUserEmail] = React.useState('');
  const navigation = useNavigate();
  const { t } = useTranslation();
  const toLogin = () => {
    navigation('/login');
  };
  const { setIsLoading } = useLoaderContext();
  const [success, Setsuccess] = React.useState(false);
  const resetPassword = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      Setsuccess(true);
      api
        .put(
          {
            url: apiConfig.forgotPassword,
            params: { userEmail: userEmail },
          },
          setIsLoading
        )
        .then((response) => {
          if (response == true) {
            toast.custom({
              title: t('toast.setNewPassword'),
            });
            toLogin();
          }
        });
    }
  };

  return (
    <div className="m-l-login-wrapper">
      <div className="m-l-login-container">
        <div className="m-l-login-left-part">
          <img
            src={imageURL.loginBg}
            className="img-responsive m-l-login-bg"
            alt="Login"
          />
        </div>
        <div className="m-l-login-right-part container">
          <div className={'m-l-login-data-cntr'}>
            <h1 className="m-l-login-data-title m-l-sm-title">
              {t('labels.forgotPasswordConfirmation')}
            </h1>
            <div className="m-l-login-data-sub m-l-align-data">
              {t('labels.forgotPasswordHeading')}
            </div>
            <form className="m-l-login-form">
              <div className="m-c-form-group mb-btm-space-24">
                <label className="m-l-input-label">{t('labels.email')}</label>
                <TextBox
                  className="m-c-input-control"
                  placeholder={t('placeholders.enterEmailAddress')}
                  mode="email"
                  value={userEmail}
                  onValueChanged={(e) => {
                    setUserEmail(e.value);
                  }}
                  validationMessageMode="always"
                >
                  <Validator
                    validationRules={[
                      {
                        type: 'required',
                        message: t('errors.enterRegisteredEmail'),
                      },
                      {
                        type: 'email',
                        message: t('errors.enterValidEmail'),
                      },
                    ]}
                  />
                </TextBox>
              </div>
              <div className="m-l-login-btn-wrap">
                <Button
                  className="app-c-btn app-c-btn--primary"
                  onClick={resetPassword}
                >
                  {t('buttons.resetPassword')}
                </Button>
              </div>
              <div className="m-l-back-to-link-wrap">
                <a onClick={toLogin} className="m-l-back-tolink">
                  {t('labels.backToSignIn')}
                </a>
              </div>
            </form>
            <div className="m-l-login-footer-wrapper">
              <div className="m-l-pwrd-by">{t('labels.poweredBy')}</div>
              <div className="m-l-lg-footer-logo">
                <img
                  src={imageURL.grayMARSLogo}
                  className="img-responsive m-l-lgn-img"
                  alt="logo"
                />
              </div>
              <div className="m-l-contact-info">{t('labels.contactUs')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
