import React, { useEffect, useState } from 'react';
import Switch from 'devextreme-react/switch';
import { useNavigate } from 'react-router-dom';
import Grid from '../../../../components/common/grid/Grid';
import { IGridConfig } from '../../../../types/grid';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { useTranslation } from 'react-i18next';
import { IDialogConfig } from '../../../../types/dialog';
import ConfirmDialog from '../../../../components/common/confirm/ConfirmDialog';
import { Restricted } from '../../../../contexts/PermissionContext';
import { useLoaderContext } from '../../../../contexts/LoaderContext';

export default function Roles() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [rolesList, setRoles] = useState();
  const [activeConfirmDialog, setActiveConfirmDialog] = useState(false);
  const { setIsLoading } = useLoaderContext();
  const confirmStatusDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      setActiveConfirmDialog(false);
    },
    handleClose: () => {
      getRolesList();
      setActiveConfirmDialog(false);
    },
    title: t('toast.roleAssignedToUser'),
    content: t('toast.inactivateRole'),
  };

  const [activeDialogConfig, setActiveDialogConfig] = useState(
    confirmStatusDialogConfig
  );

  const getRolesList = async () => {
    await api.get({ url: apiConfig.roles }).then((res) => {
      setRoles(res);
    });
  };

  const onRowClick = (e: any) => {
    if (
      !['dx-switch-on', 'dx-switch-off', 'dx-switch-handle'].includes(
        e.event.target.className
      )
    ) {
      navigate('/roles/edit', { state: { roleId: e.key.id } });
    }
  };

  const activateInactivateRoles = async (isactive: boolean, role: any) => {
    await api
      .put(
        {
          url: apiConfig.activeInactiveRole,
          data: { id: role.id, isActive: isactive },
        },
        setIsLoading
      )
      .then(() => {
        getRolesList();
      });
  };
  const handleActiveInactiveChange = async (e: any, role: any) => {
    if (e.event) {
      const dialogConfig = {
        title: t('toast.roleAssignedToUser'),
        content: e.value ? t('toast.activateRole') : t('toast.inactivateRole'),
        handleSubmit: () => {
          activateInactivateRoles(e.value, role);
          setActiveConfirmDialog(false);
        },
      };
      await api
        .get({ url: apiConfig.roleMappingUserCheck + role.id })
        .then((res) => {
          if (res) {
            setActiveDialogConfig({
              ...confirmStatusDialogConfig,
              ...dialogConfig,
            });
            setActiveConfirmDialog(true);
          } else {
            dialogConfig.title = t('toast.confirmation');
            setActiveDialogConfig({
              ...confirmStatusDialogConfig,
              ...dialogConfig,
            });
            setActiveConfirmDialog(true);
          }
        });
    }
  };

  const gridConfig: IGridConfig = {
    dataSource: rolesList,
    testId: 'rolesGrid',
    addButtonPermission: 'RoleManagement.Add',
    rowClickPermission: 'RoleManagement.ViewDetail',
    defaultColumns: [
      {
        caption: t('labels.role'),
        dataField: 'name',
        dataType: 'string',
        width: 300,
      },
      {
        caption: t('labels.roleDescription'),
        dataField: 'description',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.status'),
        dataField: 'isActive',
        dataType: 'boolean',
        alignment: 'center',
        minWidth: 150,
        cellTemplate: 'statusTemplate',
        lookup: {
          dataSource: [
            { label: t('labels.active'), value: true },
            { label: t('labels.inActive'), value: false },
          ],
          displayExpr: 'label',
          valueExpr: 'value',
        },
      },
    ],
    showHeader: true,
    header: t('headers.roles'),
    showAddButton: true,
    showExport: true,
    excelFileName: 'Roles.xlsx',
    noDataText: t('labels.companyRoleNoDataText'),
    hidePagination: false,
    rowClick: onRowClick,
    onClickAddButton: () => {
      navigate('/roles/add');
    },
  };

  useEffect(() => {
    getRolesList();
  }, []);

  return (
    <div className="inner-wrapper page-inner-wrap">
      <ul aria-label="breadcrumb" className="m-c-bredcrumb">
        <li>
          <a>{t('headers.configuration')}</a>
        </li>
        <li>
          <a className="active">{t('headers.roles')}</a>
        </li>
      </ul>
      <Grid gridConfig={gridConfig} statusTemplate={cellRender} />
      <ConfirmDialog
        dialogConfig={activeDialogConfig}
        isOpen={activeConfirmDialog}
      />
    </div>
  );
  function cellRender(data: any) {
    return (
      <div className="m-l-field-wrap">
        <div className="m-l-toggle-switch">
          <Restricted
            permission="RoleManagement.Activate/Inactive"
            disableField={true}
          >
            <Switch
              elementAttr={{ 'data-testid': 'switch' }}
              defaultValue={data.value}
              onValueChanged={(e) => {
                handleActiveInactiveChange(e, data.data);
              }}
            />
          </Restricted>
        </div>
      </div>
    );
  }
}
