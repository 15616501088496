import Button from 'devextreme-react/button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PortTerminalBerthTab from '../../../components/port-management/port-terminal-berth-tab/PortTerminalBerthTab';
import SearchFields from '../../../components/port-management/search-fields/SearchFields';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { FormProvider, useForm } from 'react-hook-form';
import { IPortSearchForm } from '../../../types/port-management';
import { useLocation } from 'react-router-dom';

export default function PortTerminalBerthView() {
  const { t } = useTranslation();
  const location = useLocation();
  const [countriesList, setCountriesList] = useState<any>();
  const [tabValue, setTabValue] = useState<any>();
  const [query, setQuery] = useState<any>();
  const [params, setParams] = useState<any>();
  let searchKey: any;

  const getCountries = async () => {
    await api.get({ url: apiConfig.country }).then((data: any) => {
      setCountriesList(data);
    });
  };

  const onClickSearch = () => {
    onValueSearch();
  };

  const defaultSearchFormValues: IPortSearchForm = {
    port: '',
    terminal: '',
    berth: '',
    countryCode: '',
  };

  const searchForm = useForm({
    defaultValues: defaultSearchFormValues,
  });

  const reset = () => {
    searchForm.reset(defaultSearchFormValues);
  };

  const getPortSearchResult = async (params: IPortSearchForm) => {
    const query =
      '?Country=' +
      params.countryCode +
      '&Port=' +
      params.port +
      '&Terminal=' +
      params.terminal +
      '&Berth=' +
      params.berth;
    setQuery(query);
    setParams(params);
    sessionStorage.setItem('portSearchQuery', query);
  };

  const onValueSearch = () => {
    const searchFormValue: IPortSearchForm = searchForm.getValues();
    const roleJsonEdit: IPortSearchForm = {
      port: searchFormValue?.port,
      terminal: searchFormValue?.terminal,
      berth: searchFormValue?.berth,
      countryCode: searchFormValue?.countryCode,
      searchKey: searchKey,
    };
    getPortSearchResult(roleJsonEdit);
  };

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (location.state) {
      const { query, params }: any = location.state;
      setQuery(query);
      setParams(params);
    }
  }, [location.state]);

  useEffect(() => {
    searchForm.reset();
    if (params) {
      searchForm.reset(
        {
          countryCode: params?.countryCode,
          port: params?.port,
          terminal: params?.terminal,
          berth: params?.berth,
        },
        { keepDirty: true }
      );
    }
  }, [params]);

  return (
    <div className="inner-wrapper">
      <div className="m-l-inner-page-header">
        <div className="m-l-inner-page-header-left-block">
          <div className="m-l-page-main-heading">
            {t('labels.portManagement')}
          </div>
        </div>
        <div className="m-l-inner-page-header-right-block">
          <Button className="app-c-btn app-c-btn--secondary" onClick={reset}>
            {t('buttons.clear')}
          </Button>
          <Button
            className="app-c-btn app-c-btn--primary"
            onClick={onClickSearch}
          >
            {t('buttons.search')}
          </Button>
        </div>
      </div>
      <div className="m-l-inner-page-body-wrap m-l-srv-pdng u-mb-lg">
        <div className="m-l-inner-page-body u-mb0">
          <FormProvider {...searchForm}>
            <SearchFields countriesList={countriesList} />
          </FormProvider>
        </div>
      </div>
      <div className="ml-port-footer-padding">
        <div className="m-l-inner-page-footer">
          <div className="row m-l-custom-eight-space-row">
            <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
              <div className="m-l-group-button-btms">
                <Button
                  className="app-c-btn app-c-btn--secondary"
                  onClick={reset}
                >
                  {t('buttons.clear')}
                </Button>
                <Button
                  className="app-c-btn app-c-btn--primary"
                  onClick={onClickSearch}
                >
                  {t('buttons.search')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m-l-inner-page-body">
        <PortTerminalBerthTab
          setTabValue={setTabValue}
          onValueSearch={onValueSearch}
          onClickSearch={onClickSearch}
          query={query}
          params={params}
        />
      </div>
    </div>
  );
}
