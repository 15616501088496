import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { IGridConfig } from '../../../../types/grid';
import Grid from '../../../common/grid/Grid';
import Switch from 'devextreme-react/switch';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import CustomStore from 'devextreme/data/custom_store';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { IUserGridData } from '../../../../types/user';
import { useTranslation } from 'react-i18next';
import { IDialogConfig } from '../../../../types/dialog';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import toast from '../../../../utils/toast';
import { EmployeeStatus } from '../../../../enums/status-enum';
import MultipleDataCellTemplate from '../../../common/MultipleDataCellTemplate/MultipleDataCellTemplate';
import { ICompany } from '../../../../types/requestor-company';
import DataGrid from 'devextreme-react/data-grid';
import { CompanyType } from '../../../../enums/company-type-enum';
import { Restricted } from '../../../../contexts/PermissionContext';
import { useLoaderContext } from '../../../../contexts/LoaderContext';

function UsersGrid(
  props: {
    userList: IUserGridData[];
    navigate: any;
    setUserApproveList: any;
    setUserSendForApprovalList: any;
    companyDetails?: ICompany;
    getCompanyUsers: () => void;
  },
  ref: any
) {
  const { t } = useTranslation();
  const {
    userList,
    navigate,
    setUserApproveList,
    setUserSendForApprovalList,
    companyDetails,
    getCompanyUsers,
  } = props;
  const [gridInstance, setGridInstance] = useState<any>();
  const [activeConfirmDialog, setConfirmDialog] = useState(false);
  const { setIsLoading } = useLoaderContext();
  const confirmStatusDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      setConfirmDialog(false);
    },
    handleClose: () => {
      setConfirmDialog(false);
    },
    title: '',
    content: '',
  };

  const [activeDialogConfig, setActiveDialogConfig] = useState(
    confirmStatusDialogConfig
  );

  useImperativeHandle(ref, () => ({
    exportUsersGrid,
  }));

  const exportUsersGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: gridInstance,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Users.xlsx'
        );
      });
    });
  };

  const onGridInitialized = (e: any) => {
    setGridInstance(e.component);
  };

  const onRowClick = (e: any) => {
    if (
      !['dx-switch-on', 'dx-switch-off', 'dx-switch-handle'].includes(
        e.event.target.className
      )
    ) {
      navigate(e.data.companyId, e.data.employeeId);
    }
  };
  const cellPrepared = (e: any) => {
    if (
      e.rowType === 'data' &&
      e.column.dataField === 'employeeStatus' &&
      (e.data?.employeeStatus === EmployeeStatus.waitingForApproval ||
        e.data?.employeeStatus === EmployeeStatus.draft)
    ) {
      e.cellElement.className = 'status-pending';
    }
  };
  const employeeStatusDataSource = {
    store: new CustomStore({
      key: 'optionValueCode',
      loadMode: 'raw',
      load: () => {
        return api.get({ url: apiConfig.employeeStatus });
      },
    }),
  };

  const onRowSelectionChanged = (e: any) => {
    setUserApproveList(
      e.selectedRowsData.filter(
        (item: IUserGridData) =>
          item.employeeStatus === EmployeeStatus.waitingForApproval
      )
    );
    setUserSendForApprovalList(
      e.selectedRowsData.filter(
        (item: IUserGridData) => item.employeeStatus === EmployeeStatus.draft
      )
    );
  };

  const gridRef = useRef<DataGrid>(null);

  const gridConfig: IGridConfig = {
    class: 'm-c-grid c-m-c-grid-in-tab m-c-userlist-grid',
    testId: 'userGrid',
    ref: gridRef,
    dataSource: userList,
    cellPrepared: cellPrepared,
    initialized: onGridInitialized,
    selectionChanged: onRowSelectionChanged,
    defaultColumns: [
      {
        caption: t('labels.name'),
        dataField: 'employeeName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.email'),
        dataField: 'employeeEmail',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.businessUnits'),
        dataField: 'buNames',
        dataType: 'string',
        minWidth: 150,
        cellTemplate: 'cellTemplate',
      },
      {
        caption: t('labels.userRoles'),
        dataField: 'roleNames',
        dataType: 'string',
        minWidth: 150,
        cellTemplate: 'cellTemplate',
      },
      {
        caption: t('labels.reviewer'),
        dataField: 'isReviewer',
        dataType: 'string',
        minWidth: 150,
        lookup: {
          dataSource: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          valueExpr: 'value',
          displayExpr: 'label',
        },
      },
      {
        caption: t('labels.status'),
        dataField: 'employeeStatus',
        alignment: 'center',
        dataType: 'string',
        minWidth: 150,
        cellTemplate: 'statusTemplate',
        lookup: {
          dataSource: employeeStatusDataSource,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
      },
    ],
    rowClick: onRowClick,
    showSelection: true,
    hidePagination: false,
    noDataText: t('labels.addNewUsers'),
  };

  const activateInactivateUsers = async (emmployessStatusJson: any) => {
    await api
      .put(
        {
          url: apiConfig.activeInactiveEmployee,
          params: emmployessStatusJson,
        },
        setIsLoading
      )
      .then(() => {
        getCompanyUsers();
      });
  };

  const handleActiveInactiveChange = async (e: any, user: any) => {
    if (e.event) {
      setConfirmDialog(true);
      const dialogConfig: IDialogConfig = {
        title: e.value ? t('toast.activateUser') : t('toast.inactivateUser'),
        content: e.value
          ? t('toast.activateUserContent')
          : t('toast.inactivateUserContent'),
        handleSubmit: () => {
          const emmployessStatusJson = {
            employeeId: user.employeeId,
            employeeStatus: e.value
              ? EmployeeStatus.active
              : EmployeeStatus.inactive,
          };
          activateInactivateUsers(emmployessStatusJson);
          setConfirmDialog(false);
          toast.custom({
            title: e.value
              ? t('toast.activatedSuccessfully')
              : t('toast.inactivatedSuccessfully'),
          });
        },
        handleClose: () => {
          getCompanyUsers();
          setConfirmDialog(false);
        },
      };
      setActiveDialogConfig(dialogConfig);
    }
  };

  useEffect(() => {
    if (companyDetails?.companyType === CompanyType.RequestorCompany) {
      gridRef.current?.instance.columnOption(4, 'visible', false);
    }
  }, [companyDetails]);

  return (
    <>
      <Grid
        gridConfig={gridConfig}
        statusTemplate={statusTemplate}
        cellTemplate={MultipleDataCellTemplate}
      />
      <ConfirmDialog
        dialogConfig={activeDialogConfig}
        isOpen={activeConfirmDialog}
      />
    </>
  );

  function statusTemplate(data: any) {
    if (
      data.value == EmployeeStatus.active ||
      data.value == EmployeeStatus.inactive
    ) {
      return (
        <div className="m-l-field-wrap">
          <div className="m-l-field-labels">
            {data.value === EmployeeStatus.active ? 'Active' : 'Inactive'}
          </div>
          <Restricted
            permission="CompanyUsers.Activate/InactiveUser"
            disableField={true}
          >
            <div className="m-l-toggle-switch">
              <Switch
                defaultValue={
                  data.value === EmployeeStatus.active ? true : false
                }
                onValueChanged={(e) => {
                  handleActiveInactiveChange(e, data.data);
                }}
              />
            </div>
          </Restricted>
        </div>
      );
    } else {
      return (
        <div>
          {data.value === EmployeeStatus.draft
            ? 'Draft'
            : 'Waiting For Approval'}
        </div>
      );
    }
  }
}

export default forwardRef(UsersGrid);
