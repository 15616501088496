import React, { useEffect, useMemo, useRef, useState } from 'react';
import Button from 'devextreme-react/button';
import List from 'devextreme-react/list';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import icons from '../../../common/icons/icons';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { IFilter } from '../../../../types/report';
import { getUserId } from '../../../../utils/jwt-decode';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import DateBox from 'devextreme-react/date-box';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import NumberBox from 'devextreme-react/number-box';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import { useTranslation } from 'react-i18next';
import ValidationGroup from 'devextreme-react/validation-group';

export default function Filters(props: {
  reportHeaderId: any;
  layoutId: any;
  reportFavouriteFilters: any;
  setReportFavouriteFilters: any;
  favoriteId: any;
  onClickRun: any;
  reportCode: any;
}) {
  const {
    reportHeaderId,
    reportFavouriteFilters,
    setReportFavouriteFilters,
    favoriteId,
    onClickRun,
    reportCode,
  } = props;
  const [filters, setFilters] = useState<any[]>([]);
  const [conditions, setConditions] = useState<any[]>([]);
  const [filterValue, setFilterValue] = useState<any>();
  const [conditionValue, setConditionValue] = useState<any>();
  const [conditionText, setConditionText] = useState<any>();
  const [conditionText2, setConditionText2] = useState<any>();
  const [showTextBox, setShowTextBox] = useState(false);
  const [filterData, setFilterData] = useState<any>([]);
  const [fieldDropDown, setFieldDropDown] = useState<any>();
  const [conditionDropDown, setConditionDropDown] = useState<any>();
  const { setIsLoading } = useLoaderContext();
  const [selectedFilter, setSelectedFilter] = useState<any>();
  const [selectedCondition, setSelectedCondition] = useState<any>();
  const { dateFormat, formatDate, apiDateFormat } = useDateFormat();
  const userId = getUserId();
  const { t } = useTranslation();
  const validationGroup: any = useRef();

  const getFilters = async () => {
    await api
      .get({
        url: apiConfig.filters + reportHeaderId,
      })
      .then((response: any) => {
        setFilters(response);
      });
  };

  const getFavFilters = async () => {
    await api
      .get({
        url: apiConfig.favourites + 'filters',
        params: {
          reportFavouriteId: favoriteId,
          reportHeaderId: reportHeaderId,
        },
      })
      .then((response: any) => {
        if (response.length) {
          const valueList: any[] = [];
          const favFilterList: any[] = [];
          response.forEach((element: any) => {
            const value = {
              filterValue: element.filterFieldDisplayName,
              conditionValue: element.conditionDisplayName,
              conditionText: element.filterValue1,
              conditionText2: element.filterValue2,
              fieldDropDown: element.reportFilterFieldId,
              conditionDropDown: element.filterConditionId,
              selectedFilter: element.filterValue1
                ? !element.filterValue2
                  ? element.filterFieldDisplayName +
                    ' ' +
                    element.conditionDisplayName +
                    ' ' +
                    element.filterValue1
                  : element.filterFieldDisplayName +
                    ' ' +
                    element.conditionDisplayName +
                    ' ' +
                    element.filterValue1 +
                    ' and ' +
                    element.filterValue2
                : element.filterFieldDisplayName +
                  ' ' +
                  element.conditionDisplayName,
              fieldType: element.filterFieldName === 'RequestDate' ? 2 : 1,
            };
            const reportFavFilters = {
              reportFavouriteFilterId: 0,
              reportFavoriteId: element.reportFavoriteId,
              displayOrder: element.displayOrder,
              reportFilterFieldId: element.reportFilterFieldId,
              filterConditionId: element.filterConditionId,
              filterValue1: element.filterValue1,
              filterValue2: element.filterValue2,
              filterValues: element.filterValue1
                ? !element.filterValue2
                  ? [element.filterValue1]
                  : [element.filterValue1, element.filterValue2]
                : [],
              selectedFilter: element.filterValue1
                ? !element.filterValue2
                  ? element.filterFieldDisplayName +
                    ' ' +
                    element.conditionDisplayName +
                    ' ' +
                    element.filterValue1
                  : element.filterFieldDisplayName +
                    ' ' +
                    element.conditionDisplayName +
                    ' ' +
                    element.filterValue1 +
                    ' and ' +
                    element.filterValue2
                : element.filterFieldDisplayName +
                  ' ' +
                  element.conditionDisplayName,
            };
            valueList.push(value);
            favFilterList.push(reportFavFilters);
          });
          setFilterData(valueList);
          setReportFavouriteFilters(favFilterList);
        } else {
          setFilterData([]);
          setReportFavouriteFilters([]);
        }
      });
  };

  const onClickCancel = () => {
    setFieldDropDown(null);
    getFavFilters();
    setConditionText(undefined);
    setConditionText2(undefined);
    setConditionDropDown(null);
    validationGroup.current?.instance.reset();
  };
  useEffect(() => {
    onClickCancel();
  }, [reportCode]);

  useEffect(() => {
    getFilters();
  }, [reportCode]);

  useEffect(() => {
    if (favoriteId) {
      getFavFilters();
    }
  }, [favoriteId]);

  const getConditions = async () => {
    await api
      .get({
        url: apiConfig.filters + 'filter-conditions',
      })
      .then((response: any) => {
        setConditions(response);
      });
  };

  useEffect(() => {
    getConditions();
  }, []);

  const onChangeFilter = (e: any) => {
    const filterName = filters.find(
      (data: any) => data.reportFilterFieldId === e.value
    );
    setFilterValue(filterName?.filterFieldDisplayName);
    setSelectedFilter(filterName);
    setFieldDropDown(e.value);
  };

  const onChangeCondition = (e: any) => {
    const conditionName = conditions.find(
      (data: any) => data.reportFilterConditionId === e.value
    );
    setConditionValue(conditionName?.conditionDisplayName);
    setSelectedCondition(conditionName);
    setConditionDropDown(e.value);
    if (e.value && conditionName.valueFieldCount > 0) {
      setShowTextBox(true);
    } else {
      setConditionText(undefined);
      setConditionText2(undefined);
      setShowTextBox(false);
    }
  };

  const onChangeConditionText = (e: any) => {
    if (selectedFilter && selectedFilter.fieldType === 2 && e.value) {
      setConditionText(apiDateFormat(e.value));
    } else {
      setConditionText(e.value);
    }
  };

  const onChangeConditionText2 = (e: any) => {
    if (selectedFilter && selectedFilter.fieldType === 2 && e.value) {
      setConditionText2(apiDateFormat(e.value));
    } else {
      setConditionText2(e.value);
    }
  };

  const toRight = (e: any) => {
    const displayOrder = filterData.length + 1;
    const reportFavFilters = {
      reportFavouriteFilterId: 0,
      reportFavoriteId: favoriteId,
      displayOrder: displayOrder,
      reportFilterFieldId: fieldDropDown,
      filterConditionId: conditionDropDown,
      filterValue1: conditionText ? conditionText : '',
      filterValue2: conditionText2 ? conditionText2 : '',
      filterValues: conditionText
        ? !conditionText2
          ? [conditionText]
          : [conditionText, conditionText2]
        : [],
      selectedFilter: conditionText
        ? !conditionText2
          ? filterValue + ' ' + conditionValue + ' ' + conditionText
          : filterValue +
            ' ' +
            conditionValue +
            ' ' +
            conditionText +
            ' and ' +
            conditionText2
        : filterValue + ' ' + conditionValue,
    };
    const value = {
      filterValue,
      conditionValue,
      conditionText,
      conditionText2,
      fieldDropDown,
      conditionDropDown,
      fieldType: selectedFilter?.fieldType,
      sqlCondition: selectedCondition?.sqlCondition,
      selectedFilter: reportFavFilters.selectedFilter,
    };
    if (filterValue) {
      if (e.validationGroup.validate().isValid) {
        setFilterData([...filterData, value]);
        setReportFavouriteFilters([
          ...reportFavouriteFilters,
          reportFavFilters,
        ]);
        setFieldDropDown(null);
        setConditionDropDown(null);
        setConditionText(undefined);
        setConditionText2(undefined);
        setConditionText(undefined);
        validationGroup.current?.instance.reset();
      }
    }
  };

  const removeFilter = (value: any) => {
    const data = filterData?.filter((item: any) => item != value);
    const favFilters = reportFavouriteFilters.filter(
      (item: any) => item.selectedFilter !== value.selectedFilter
    );
    setFilterData(data);
    setReportFavouriteFilters(favFilters);
    // const apiData = reportFavouriteFilters?.filter(
    //   (item: any) => {
    //     console.log(item.reportFilterFieldId, value.fieldDropDown)
    //     item.reportFilterFieldId != value.fieldDropDown
    //   }
    // );
    // console.log(apiData);
    // setReportFavouriteFilters(apiData);
  };

  const toLeft = () => {
    const filterDatalength = filterData.length;
    const apiDatalength = reportFavouriteFilters.length;
    const lastValue = filterData[filterDatalength - 1];
    const sliced = filterData?.slice();
    sliced.splice(filterDatalength - 1, 1);
    setFilterData(sliced);
    const apiDataSliced = reportFavouriteFilters?.slice();
    apiDataSliced.splice(apiDatalength - 1, 1);
    setReportFavouriteFilters(apiDataSliced);
    setFieldDropDown(lastValue.fieldDropDown);
    setConditionDropDown(lastValue.conditionDropDown);
    setConditionText(lastValue.conditionText);
    setConditionText2(lastValue.conditionText2);
  };

  const clearSearchFilter = () => {
    setFilterData([]);
  };

  const selectFilter = (e: any) => {
    return (
      <div className="m-l-select_filter-row">
        <div className="m-l-select__left">
          {!e.conditionText2 && (
            <span className="m-l-select__text">
              {' '}
              {/* Work Order No. Contains 00000000005{' '} */}
              {e.filterValue} {e.conditionValue}{' '}
              {e.fieldType === 2
                ? formatDate(e.conditionText)
                : e.conditionText}
            </span>
          )}
          {e.conditionText2 && (
            <span className="m-l-select__text">
              {' '}
              {/* Work Order No. Contains 00000000005{' '} */}
              {e.filterValue} {e.conditionValue}{' '}
              {e.fieldType === 2
                ? formatDate(e.conditionText)
                : e.conditionText}{' '}
              and{' '}
              {e.fieldType === 2
                ? formatDate(e.conditionText2)
                : e.conditionText2}
            </span>
          )}
        </div>
        <div className="m-l-select__right">
          <Button
            className="m-l-btn--icon-plain"
            onClick={() => {
              removeFilter(e);
            }}
          >
            <icons.Trash />
          </Button>
        </div>
      </div>
    );
  };

  const filteredConditionList = useMemo(() => {
    return conditions?.filter(
      (item) =>
        item.conditionType === selectedFilter?.fieldType ||
        item.conditionType === 0
    );
  }, [conditions, selectedFilter]);

  return (
    <div className="m-l-inner-page-body">
      <div className="m-l-form-panel-body-block">
        <ValidationGroup ref={validationGroup}>
          <div className="m-c-report__filter">
            <div className="m-c-report__f-left">
              <div className="mb-btm-space m-c-form-group">
                <label className="m-l-input-label">Field </label>
                <div className="m-c-form__inline">
                  <div className="m-c-form__input">
                    <SelectBox
                      dataSource={filters}
                      displayExpr={'filterFieldDisplayName'}
                      valueExpr={'reportFilterFieldId'}
                      className="m-c-input-control"
                      onValueChanged={onChangeFilter}
                      searchEnabled={true}
                      value={fieldDropDown}
                      validationMessageMode="always"
                    >
                      {/* <Validator>
                      <RequiredRule message={t('errors.required')} />
                    </Validator> */}
                    </SelectBox>
                  </div>
                  <div className="m-c-form__icon">
                    <div className="m-c-icon-inside-btn">
                      <Button
                        className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                        type="normal"
                        onClick={toRight}
                      >
                        <div className="m-c-icon-inside-btn">
                          <icons.ArrowBoldRight />
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="m-c-form-group">
                <label className="m-l-input-label">Condition </label>
                <div className="m-c-form__inline">
                  <div className="m-c-form__input">
                    <SelectBox
                      dataSource={filteredConditionList}
                      displayExpr={'conditionDisplayName'}
                      valueExpr={'reportFilterConditionId'}
                      className="m-c-input-control"
                      onValueChanged={onChangeCondition}
                      searchEnabled={true}
                      value={conditionDropDown}
                      validationMessageMode="always"
                    >
                      <Validator>
                        <RequiredRule message={t('errors.required')} />
                      </Validator>
                    </SelectBox>
                  </div>
                  <div className="m-c-form__icon">
                    <div className="m-c-icon-inside-btn">
                      <Button
                        className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                        type="normal"
                        onClick={toLeft}
                      >
                        <div className="m-c-icon-inside-btn">
                          <icons.ArrowBoldLeft />
                        </div>
                      </Button>
                    </div>
                    {showTextBox ? null : (
                      <div className="m-c-icon-inside-btn">
                        <Button
                          className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                          type="normal"
                          onClick={clearSearchFilter}
                        >
                          <div className="m-c-icon-inside-btn">
                            <icons.DoubleArrowLeft />
                          </div>
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {showTextBox ? (
                <div className="mb-btm-space m-c-form-group">
                  <label className="m-l-input-label">Value </label>
                  <div className="m-c-form__inline">
                    {selectedCondition?.reportFilterConditionId !== 7 && (
                      <div className="m-c-form__input">
                        {selectedFilter && selectedFilter.fieldType === 1 && (
                          <TextBox
                            value={conditionText}
                            onValueChanged={onChangeConditionText}
                            validationMessageMode="always"
                          >
                            <Validator>
                              <RequiredRule message={t('errors.required')} />
                            </Validator>
                          </TextBox>
                        )}
                        {selectedFilter && selectedFilter.fieldType === 2 && (
                          <DateBox
                            displayFormat={dateFormat}
                            className="m-c-input-control m-c-date-picker"
                            value={conditionText}
                            onValueChanged={onChangeConditionText}
                            validationMessageMode="always"
                          >
                            <Validator>
                              <RequiredRule message={t('errors.required')} />
                            </Validator>
                          </DateBox>
                        )}
                        {selectedFilter && selectedFilter.fieldType === 3 && (
                          <NumberBox
                            value={conditionText}
                            className="m-c-input-control m-c-date-picker"
                            onValueChanged={onChangeConditionText}
                            validationMessageMode="always"
                          >
                            <Validator>
                              <RequiredRule message={t('errors.required')} />
                            </Validator>
                          </NumberBox>
                        )}
                      </div>
                    )}
                    {selectedCondition?.valueFieldCount > 1 && (
                      <div
                        className="input-block"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          marginRight: '8px',
                        }}
                      >
                        <div
                          className="m-c-form__input"
                          style={{ marginBottom: '18px' }}
                        >
                          {selectedFilter && selectedFilter.fieldType === 2 && (
                            <DateBox
                              displayFormat={dateFormat}
                              className="m-c-input-control m-c-date-picker"
                              value={conditionText}
                              onValueChanged={onChangeConditionText}
                              validationMessageMode="always"
                            >
                              <Validator>
                                <RequiredRule message={t('errors.required')} />
                              </Validator>
                            </DateBox>
                          )}
                        </div>
                        <div className="m-c-form__input">
                          {selectedFilter && selectedFilter.fieldType === 2 && (
                            <DateBox
                              displayFormat={dateFormat}
                              className="m-c-input-control m-c-date-picker"
                              value={conditionText2}
                              onValueChanged={onChangeConditionText2}
                              validationMessageMode="always"
                            >
                              <Validator>
                                <RequiredRule message={t('errors.required')} />
                              </Validator>
                            </DateBox>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="m-c-form__icon">
                      <div className="m-c-icon-inside-btn">
                        <Button
                          className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                          type="normal"
                          onClick={clearSearchFilter}
                        >
                          <div className="m-c-icon-inside-btn">
                            <icons.DoubleArrowLeft />
                          </div>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="m-c-report__f-right">
              <div className="m-c-form-group">
                <label className="m-l-input-label">Selected Filters </label>
                <div className="m-l-selected__filter">
                  <List
                    dataSource={filterData}
                    height="100%"
                    itemRender={selectFilter}
                  />
                </div>
              </div>
            </div>
          </div>
        </ValidationGroup>
      </div>
      <div className="m-l-form-panel-footer-block ps-3 pe-3 pb-3">
        <Button
          className="app-c-btn app-c-btn--secondary min-btn-width"
          onClick={onClickCancel}
        >
          {t('buttons.cancel')}
        </Button>
        <Button
          className="app-c-btn app-c-btn--primary min-btn-width"
          onClick={onClickRun}
        >
          {t('buttons.runNow')}
        </Button>
      </div>
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <Button
                className="app-c-btn app-c-btn--secondary min-btn-width"
                onClick={onClickCancel}
              >
                {t('buttons.cancel')}
              </Button>
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                onClick={onClickRun}
              >
                {t('buttons.runNow')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
