import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IEntityAttribute, IMasterEntity } from '../../../types/master-data';
import FormBuilder from '../../common/form-builder/FormBuilder';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import toast from '../../../utils/toast';

export default function ListFormGeneralFields(props: {
  setAttribute: any;
  defaultAttributeId: any;
  listEntries: any;
  attribute: any;
}) {
  const { setAttribute, defaultAttributeId, listEntries, attribute } = props;
  const { t } = useTranslation();
  const { control, resetField, setValue } = useFormContext();
  const [entities, setEntities] = useState<IMasterEntity[]>([]);
  const [attributes, setAttributes] = useState<IEntityAttribute[]>([]);

  const getEntities = async () => {
    await api
      .get({ url: apiConfig.masterEntities })
      .then((res: IMasterEntity[]) => {
        setEntities(res);
      });
  };

  const getAttributes = async (entityId: any) => {
    await api
      .get({ url: apiConfig.attributesByEnventId + entityId })
      .then((res: IEntityAttribute[]) => {
        setAttributes(res);
      });
  };

  const onChangeEntity = async (e: any) => {
    setAttribute('');
    setValue(`attribute`, null);
    if (!attributes.find((item) => item.masterEntityId === e.value)) {
      await getAttributes(e.value);
    }
  };

  const onSelectAttribute = (e: any) => {
    const index = attributes.findIndex(
      (item) => item.masterAttributeId === e.value
    );
    if (listEntries?.length > 0) {
      if (attribute) {
        if (attribute?.isSelectable) {
          resetField('attribute');
          toast.error({
            title: 'Error',
            message:
              t('errors.ListAttributeMasterData1') +
              attribute?.displayName +
              t('errors.ListAttributeMasterData2'),
          });
        } else {
          if (attribute?.attributeType === attributes[index]?.attributeType) {
            setAttribute(attributes[index]);
          } else {
            resetField('attribute');
            toast.error({
              title: 'Error',
              message:
                t('errors.ListAttributeType1') +
                attribute?.attributeType +
                t('errors.ListAttributeType2') +
                attribute?.attributeType,
            });
          }
        }
      } else {
        if (
          attributes[index]?.isSelectable ||
          attributes[index]?.attributeType === 'DateTime'
        ) {
          resetField('attribute');
          toast.error({
            title: 'Error',
            message:
              t('errors.ListAttributeType1') +
              'text' +
              t('errors.ListAttributeType2') +
              'text',
          });
        } else {
          setAttribute(attributes[index]);
        }
      }
    } else {
      setAttribute(attributes[index]);
    }
  };

  useEffect(() => {
    getEntities();
  }, []);

  const defaultAttribute = (id: any) => {
    const index = attributes.findIndex((item) => item.masterAttributeId === id);
    setAttribute(attributes[index]);
  };

  useEffect(() => {
    if (defaultAttributeId) {
      defaultAttribute(defaultAttributeId);
    }
  }, [attributes]);

  const generalFormConfig = [
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'listname',
      label: t('labels.listName'),
      control: control,
      fieldType: 'text',
      isRequired: true,
      maxLength: 100,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'listDescription',
      label: t('labels.listDescription'),
      control: control,
      fieldType: 'text',
    },
  ];
  const generalFormConfig2 = [
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'entity',
      label: t('labels.entity'),
      control: control,
      fieldType: 'select',
      displayExpr: 'displayName',
      valueExpr: 'masterEntityId',
      dataSource: entities,
      onChange: onChangeEntity,
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'attribute',
      label: t('labels.attribute'),
      control: control,
      fieldType: 'select',
      displayExpr: 'displayName',
      valueExpr: 'masterAttributeId',
      dataSource: attributes,
      onChange: onSelectAttribute,
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={generalFormConfig} />
      </div>
      <div className="row">
        <FormBuilder formConfig={generalFormConfig2} />
      </div>
    </>
  );
}
