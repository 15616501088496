import Accordion from 'devextreme-react/accordion';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import VesselDimension from '../dimensions/VesselDimensions';
import ViewCapacity from '../capacity/VesselCapacity';
import VesselGeneral from '../general/VesselGeneral';
import VesselClassification from '../classification/VesselClassification';
import Name from '../name/name/Name';
import PSCInspection from '../psc/psc-inspection/PSCInspection';
import Engine from '../engine/engine/Engine';
import YardTab from '../yard/yard/YardTab';
import EquipmentTab from '../equipment/equipment/EquipmentTab';
import ViewIncidents from '../incidents/view-incidents/ViewIncidents';
import { usePermission } from '../../../../contexts/PermissionContext';
import ManagementGrid from '../management/management-grid/ManagementGrid';
import { getCompanyType } from '../../../../utils/jwt-decode';
import VesselList from '../list/VesselList';
import VesselHistory from '../VesselHistory/VesselHistory';
import VetRelatedInfo from '../vet-related-info/vet-related-info/VetRelatedInfo';

function VesselDetailsTab(
  props: {
    imoNumber?: string;
    vesselBannerDetails?: any;
    bannerInfoDetails?: any;
  },
  ref: any
) {
  const { imoNumber, vesselBannerDetails, bannerInfoDetails } = props;
  const { featureList, findFeature } = usePermission();
  const companyType = getCompanyType();
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [isTabsDirty, setIsTabsDirty] = useState(false);

  useImperativeHandle(ref, () => ({
    expandCollapseAll(isExpand: boolean) {
      if (isExpand) {
        setSelectedItems(accordionItems);
      } else {
        setSelectedItems([]);
      }
    },
  }));

  const accordionItems = useMemo(() => {
    return [
      {
        title: 'General',
        visible: findFeature('Ship.ViewGeneralInfo'),
      },
      {
        title: 'Vet Related Info',
        visible: findFeature('Ship.ViewVetRelatedInfo'),
      },
      {
        title: 'Classification',
        visible: findFeature('Ship.ViewClassification'),
      },
      {
        title: 'Company Details',
        visible: findFeature('Ship.ViewManagement'),
      },
      {
        title: 'PSC',
        visible: findFeature('Ship.ViewPSC'),
        // && !(companyType === 'R'),
      },
      {
        title: 'Incident',
        visible: findFeature('Ship.ViewIncident'),
        // && !(companyType === 'R'),
      },
      {
        title: 'Yard',
        visible: findFeature('Ship.ViewYard'),
      },
      {
        title: 'Dimensions',
        visible: findFeature('Ship.ViewDimensions'),
      },
      {
        title: 'Engine',
        visible: findFeature('Ship.ViewEngine'),
      },
      {
        title: 'Equipment',
        visible: findFeature('Ship.ViewEquipment'),
      },
      {
        title: 'Capacity',
        visible: findFeature('Ship.ViewCapacity'),
      },
      {
        title: 'Name',
        visible: findFeature('Ship.ViewName'),
      },
      {
        title: 'List',
        visible: false,
      },
      {
        title: 'Vessel History',
        visible: false,
      },
    ];
  }, [featureList]);

  const accordionItemRender = (e: any) => {
    switch (e.title) {
      case accordionItems[0].title: {
        return (
          <VesselGeneral
            imoNumber={imoNumber}
            vesselBannerDetails={vesselBannerDetails}
            setIsTabsDirty={setIsTabsDirty}
          />
        );
      }
      case accordionItems[10].title: {
        return <ViewCapacity imoNumber={imoNumber} />;
      }
      case accordionItems[2].title: {
        return <VesselClassification imoNumber={imoNumber} />;
      }
      case accordionItems[7].title: {
        return <VesselDimension imoNumber={imoNumber} />;
      }
      case accordionItems[8].title: {
        return <Engine imoNumber={imoNumber} />;
      }
      case accordionItems[9].title: {
        return <EquipmentTab imoNumber={imoNumber} />;
      }
      case accordionItems[3].title: {
        return <ManagementGrid imoNumber={imoNumber} />;
      }
      case accordionItems[11].title: {
        return <Name imoNumber={imoNumber} />;
      }
      case accordionItems[6].title: {
        return <YardTab imoNumber={imoNumber} />;
      }
      case accordionItems[5].title: {
        return accordionItems[5].visible ? (
          <ViewIncidents imoNumber={imoNumber} />
        ) : (
          <></>
        );
      }
      case accordionItems[4].title: {
        return accordionItems[4].visible ? (
          <PSCInspection
            imoNumber={imoNumber}
            vesselBannerDetails={bannerInfoDetails}
          />
        ) : (
          <></>
        );
      }
      case accordionItems[12].title: {
        return <VesselList />;
      }
      case accordionItems[13].title: {
        return <VesselHistory />;
      }
      case accordionItems[1].title: {
        return <VetRelatedInfo imoNumber={imoNumber} />;
      }
    }
  };

  const selectionChanged = (e: any) => {
    let newItems: any = [...selectedItems];
    e.removedItems.map((item: any) => {
      const index = newItems.indexOf(item);
      if (index >= 0) {
        newItems.splice(index, 1);
      }
    });
    if (e.addedItems.length) {
      newItems = [...newItems, ...e.addedItems];
    }
    setSelectedItems(newItems);
  };
  return (
    <div className="m-l-accordion-panel-body-block">
      <div className="m-l-accordion__main-section">
        <div className="m-c-accordion">
          <Accordion
            collapsible={true}
            multiple={true}
            items={accordionItems}
            itemRender={accordionItemRender}
            selectedItems={selectedItems}
            onSelectionChanged={selectionChanged}
          />
        </div>
      </div>
    </div>
  );
}

export default forwardRef(VesselDetailsTab);
