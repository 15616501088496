import Button from 'devextreme-react/button';
import DataSource from 'devextreme/data/data_source';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import Grid from '../../../components/common/grid/Grid';
import { IGridConfig } from '../../../types/grid';
import icons from '../../common/icons/icons';
import RolesPopover from '../roles-popover/RolesPopover';
import saveAs from 'file-saver';
import { IDialogConfig } from '../../../types/dialog';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../common/confirm/ConfirmDialog';
import {
  ICompanyRole,
  ICompanyRolesList,
  IRole,
} from '../../../types/company-roles';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import ReactDOMServer from 'react-dom/server';
import toast from '../../../utils/toast';
import { getAPIRoute } from '../../../utils/api-route';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { usePermission } from '../../../contexts/PermissionContext';

function RoleGrid(
  props: {
    activeRoles: IRole[];
    companyRoles: ICompanyRolesList[];
    setCompanyRoles: any;
    setDataSource: any;
    dataSource: any;
    isEdited: boolean;
    setIsEdited: any;
    navigate: any;
    deletedRoleIds: string[];
    setDeletedRoleIds: any;
    getCompanyDetails: any;
    getCompanyUsers: any;
    companyId?: number;
  },
  ref: any
) {
  const {
    activeRoles,
    companyRoles,
    setCompanyRoles,
    setDataSource,
    dataSource,
    isEdited,
    setIsEdited,
    navigate,
    deletedRoleIds,
    setDeletedRoleIds,
    getCompanyDetails,
    getCompanyUsers,
    companyId,
  } = props;
  const { t } = useTranslation();
  const deleteConfirmDialogConfig: IDialogConfig = {
    id: 'confirmDelete',
    content: t('toast.deleteRoleConfirm'),
    handleSubmit: () => {
      setDeleteConfirmDialog(false);
    },
    handleClose: () => {
      setDeleteConfirmDialog(false);
    },
  };
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmBackDialog, setConfirmBackDialog] = useState(false);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const [deleteDialogConfig, setDeleteDialogConfig] = useState(
    deleteConfirmDialogConfig
  );
  const [showPopover, setShowPopover] = useState(false);

  const [selectedRoles, setSelectedRoles] = useState<any>([]);
  const [gridInstance, setGridInstance] = useState<any>();
  const { setIsLoading } = useLoaderContext();
  const { findFeature } = usePermission();

  const popOverRoles = useMemo(() => {
    const roleIds = dataSource.store()._array.map((role: any) => role.id);
    return activeRoles.filter((role) => !roleIds.includes(role.id));
  }, [dataSource, activeRoles]);

  useImperativeHandle(ref, () => ({
    showConfirmModal() {
      if (
        (companyRoles.length === 0 && dataSource.items().length !== 0) ||
        (companyRoles.length !== 0 && isEdited)
      ) {
        setIsConfirmModalOpen(true);
      }
    },
    showCancelConfirmModal() {
      (companyRoles.length === 0 && dataSource.items().length !== 0) ||
      (companyRoles.length !== 0 && isEdited)
        ? setConfirmBackDialog(true)
        : navigate();
    },
    exportRolesGrid,
  }));
  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: async () => {
      await saveRoles();
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };
  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setConfirmBackDialog(false);
      navigate();
    },
    handleClose: () => {
      setConfirmBackDialog(false);
    },
  };

  const onGridInitialized = (e: any) => {
    setGridInstance(e.component);
  };

  const onRowRemoved = (e: any) => {
    if (companyRoles.find((item) => item.id === e.data.id)) {
      const temp = deletedRoleIds;
      temp.push(e.data.id);
      setDeletedRoleIds(temp);
    }
    setDataSource(new DataSource(dataSource.store()._array));
    setIsEdited(true);
  };

  const onRowRemoving = (e: any) => {
    e.cancel = new Promise((resolve, reject) => {
      if (dataSource._totalCount > 1) {
        if (companyRoles.find((item) => item.id === e.data.id)) {
          setDeleteDialogConfig({
            id: 'confirmDelete',
            content: t('toast.deleteRoleConfirm'),
            handleSubmit: () => {
              resolve(false);
              setDeleteConfirmDialog(false);
              toast.success({
                title: t('toast.roleDeleteSuccess'),
              });
            },
            handleClose: () => {
              resolve(true);
              setDeleteConfirmDialog(false);
            },
          });
          api
            .get({
              url: getAPIRoute('checkCompanyRoleMapping', [
                companyId,
                e.data.id,
              ]),
            })
            .then((res) => {
              if (res) {
                setDeleteConfirmDialog(true);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve(false);
        }
      } else {
        toast.error({
          title: 'Error',
          message: t('toast.singleRoleDelete'),
        });
        resolve(true);
      }
    });
  };
  const handlePopoverClick = () => {
    setShowPopover(true);
  };

  const gridConfig: IGridConfig = {
    class: 'm-c-grid m-c-righticon-grid m-c-grid__role',
    testId: 'roleGrid',
    defaultColumns: [
      {
        caption: t('labels.role'),
        dataField: 'name',
        dataType: 'string',
        minWidth: 150,
        width: '20%',
        sortOrder: 'asc',
      },
      {
        caption: t('labels.roleDescription'),
        dataField: 'description',
        dataType: 'string',
        minWidth: 150,
        width: '20%',
      },
      {
        caption: '',
        headerCellTemplate: 'headerTemplate',
        allowFiltering: false,
        allowSorting: false,
        visible: findFeature('CompanyRoles.Add'),
      },

      {
        caption: '',
        type: 'buttons',
        minWidth: 72,
        alignment: 'right',
        allowFiltering: false,
        allowSorting: false,
        buttons: [
          {
            name: 'delete',
            icon: ReactDOMServer.renderToString(<icons.Trash />),
          },
        ],
        width: '60%',
        visible: findFeature('CompanyRoles.Delete'),
      },
    ],
    showHeader: false,
    showAddButton: true,
    onClickAddButton: handlePopoverClick,
    hidePagination: true,
    enableEditing: true,
    editMode: 'row',
    allowDeleting: true,
    noDataText: t('labels.companyRoleNoDataText'),
    initialized: onGridInitialized,
    rowRemoved: onRowRemoved,
    rowRemoving: onRowRemoving,
  };

  const onHidingPopover = () => {
    setShowPopover(false);
    setSelectedRoles([]);
  };

  const saveRoles = async () => {
    const companyRoleJson: ICompanyRole = {
      companyId: Number(companyId),
      roleIds: dataSource.store()._array.map((role: ICompanyRolesList) => {
        return role.id;
      }),
    };
    setIsConfirmModalOpen(false);
    if (companyRoleJson.roleIds.length !== 0) {
      await api
        .post(
          { url: apiConfig.companyRoles, data: companyRoleJson },
          setIsLoading
        )
        .then(async () => {
          if (deletedRoleIds.length) {
            await deleteRoles();
          }
          if (companyRoles.length === 0) {
            getCompanyDetails();
          }
          setCompanyRoles(dataSource.store()._array);
          setIsEdited(false);
          toast.custom({
            title: t('toast.updatedSuccessfully'),
            message: t('toast.companyRolesUpdated'),
          });
          getCompanyUsers();
        });
    } else {
      await deleteRoles();
      setIsEdited(false);
      setCompanyRoles(dataSource.store()._array);
      getCompanyDetails();
      toast.custom({
        title: t('toast.updatedSuccessfully'),
        message: t('toast.companyRolesUpdated'),
      });
    }
  };

  const deleteRoles = async () => {
    const deleteCompanyRoleJson: ICompanyRole = {
      companyId: Number(companyId),
      roleIds: deletedRoleIds,
    };
    await api
      .delete(
        { url: apiConfig.companyRoles, data: deleteCompanyRoleJson },
        setIsLoading
      )
      .then();
  };

  const onSelectRole = (e: any, role: any) => {
    setSelectedRoles(() => {
      let temp = selectedRoles;
      const addedRole = dataSource
        .store()
        ._array.find((item: any) => item.id === role.id);

      if (e.value) {
        !addedRole && temp.push(role);
      } else {
        temp = !addedRole
          ? temp.filter((item: any) => item.id !== role.id)
          : temp;
      }
      return temp;
    });
  };

  const onClickAdd = () => {
    if (selectedRoles.length > 0) {
      setIsEdited(true);
    }
    const filteredRoleIds = deletedRoleIds.filter(
      (item) =>
        !selectedRoles
          .concat(dataSource.store()._array)
          .find((role: ICompanyRolesList) => role.id == item)
    );
    setDataSource(
      new DataSource(selectedRoles.concat(dataSource.store()._array))
    );
    setDeletedRoleIds(filteredRoleIds);
    setSelectedRoles([]);
    setShowPopover(false);
  };

  const exportRolesGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: gridInstance,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Roles.xlsx'
        );
      });
    });
  };

  const actionHeaderTemplate = () => {
    return (
      <>
        {/* <Button
          id="add"
          className="app-c-btn app-c-btn--secondary app-c-grid-header-icon-btn"
          onClick={handlePopoverClick}
          elementAttr={{ 'data-testid': 'addRoleButton' }}
        >
          <div className="m-c-icon-inside-btn">
            <icons.AddIcon />
          </div>
        </Button> */}
        <Button
          className="app-c-btn app-c-btn--primary min-btn-width app-c-grid-header-add-icon-btn"
          onClick={handlePopoverClick}
          elementAttr={{ 'data-testid': 'addRoleButton' }}
          id="add"
        >
          {t('buttons.addNew')}
        </Button>
        {/* {showPopover && (
          <RolesPopover
            onClickAdd={onClickAdd}
            onSelectRole={onSelectRole}
            roles={popOverRoles}
            showPopover={showPopover}
            target="#add"
            closeOnOutsideClick={true}
            onHidingPopover={onHidingPopover}
          />
        )} */}
      </>
    );
  };

  return (
    <>
      <Grid
        dataSource={dataSource}
        gridConfig={gridConfig}
        headerTemplate={actionHeaderTemplate}
      />
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={isConfirmModalOpen}
      />
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={confirmBackDialog}
      />
      <ConfirmDialog
        dialogConfig={deleteDialogConfig}
        isOpen={deleteConfirmDialog}
      />
      {showPopover && (
        <RolesPopover
          onClickAdd={onClickAdd}
          onSelectRole={onSelectRole}
          roles={popOverRoles}
          showPopover={showPopover}
          target="#add"
          closeOnOutsideClick={true}
          onHidingPopover={onHidingPopover}
        />
      )}
    </>
  );
}

const RolesGrid = forwardRef(RoleGrid);
export default RolesGrid;
