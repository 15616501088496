import Button from 'devextreme-react/button';
import React, { useContext, useEffect, useState } from 'react';
import { IVesselCapacityForm } from '../../../../types/vessel-capacity';
import { FormProvider, useForm } from 'react-hook-form';
import VesselFormCapacity from './vessel-capacity-form/VesselCapacityForm';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { IVesselCapacityEdit } from '../../../../types/vessel-capacity-edit';
import toast from '../../../../utils/toast';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../../types/dialog';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import { isTabDirtyContext } from '../../../../pages/vessel-info/VesselInformation';
export default function CapacityEdit(props: {
  setIsCapacityEdit: any;
  capacityList: any;
  getCapacity: any;
  yesNo: any;
  fuel: any;
  fuelType: any;
  imoNumber?: string;
}) {
  const {
    setIsCapacityEdit,
    capacityList,
    getCapacity,
    yesNo,
    fuel,
    fuelType,
    imoNumber,
  } = props;
  const setTabDirtyFlag = useContext(isTabDirtyContext);
  const { t } = useTranslation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isconfirmBackDialog, setIsConfirmBackDialog] = useState(false);
  const { setIsLoading } = useLoaderContext();
  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      onValueSubmit();
      setIsConfirmModalOpen(false);
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };
  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setIsCapacityEdit(false);
      setIsConfirmBackDialog(false);
      setTabDirtyFlag.setState(
        setTabDirtyFlag.state == 0
          ? setTabDirtyFlag.state
          : setTabDirtyFlag.state - 1
      );
    },
    handleClose: () => {
      setIsConfirmBackDialog(false);
    },
  };
  const defaultVesselFormValues: IVesselCapacityForm = {
    grosstonnage: 0,
    nettonnage: 0,
    deadweight: 0,
    teu: 0,
    cargoholds: '',
    bale: 0,
    grain: 0,
    fuel: '',
    fueltype: '',
    ballastwatertreatmentsystem: false,
  };
  const vesselCapacityForm = useForm({
    defaultValues: defaultVesselFormValues,
  });
  const onHandleSave = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      setIsConfirmModalOpen(true);
    }
  };
  const { isDirty } = vesselCapacityForm.formState;

  const handleBackClick = () => {
    isDirty ? setIsConfirmBackDialog(true) : setIsCapacityEdit(false);
  };
  const { getValues } = vesselCapacityForm;
  let listCapacityFormValue: IVesselCapacityForm = getValues();
  const onValueSubmit = () => {
    listCapacityFormValue = vesselCapacityForm.getValues();
    const roleJsonEdit: IVesselCapacityEdit = {
      imoNumber: imoNumber,
      grossTonnage: listCapacityFormValue.grosstonnage,
      netTonnage: listCapacityFormValue.nettonnage,
      deadweight: listCapacityFormValue.deadweight,
      teu: listCapacityFormValue.teu,
      cargoHold: listCapacityFormValue.cargoholds,
      baleCapacity: listCapacityFormValue.bale,
      grainCapacity: listCapacityFormValue.grain,
      fuelTypeCode: listCapacityFormValue.fueltype,
      fuelCode: listCapacityFormValue.fuel,
      ballastWaterTreatmentSystem:
        listCapacityFormValue.ballastwatertreatmentsystem === 'true'
          ? true
          : false,
      imoDisplayName: capacityList.imoDisplayName,
    };

    api
      .put(
        { url: apiConfig.vesselCapacityTab, data: roleJsonEdit },
        setIsLoading
      )
      .then((response) => {
        if (response == true) {
          getCapacity();
          setIsCapacityEdit(false);
          setTabDirtyFlag.setState(
            setTabDirtyFlag.state == 0
              ? setTabDirtyFlag.state
              : setTabDirtyFlag.state - 1
          );
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
        }
      });
  };
  useEffect(() => {
    vesselCapacityForm.reset();
    if (capacityList) {
      vesselCapacityForm.reset(
        {
          grosstonnage: capacityList.grossTonnage,
          nettonnage: capacityList.netTonnage,
          deadweight: capacityList.deadweight,
          teu: capacityList.teu,
          cargoholds: capacityList.cargoHold,
          bale: capacityList.baleCapacity,
          grain: capacityList.grainCapacity,
          fuel: capacityList.fuelCode,
          fueltype: capacityList.fuelTypeCode,
          ballastwatertreatmentsystem:
            capacityList.ballastWaterTreatmentSystem.toString(),
        },
        { keepDirty: true }
      );
    }
  }, [capacityList]);

  useEffect(() => {
    if (isDirty) {
      setTabDirtyFlag.setState(setTabDirtyFlag.state + 1);
    }
  }, [isDirty]);

  return (
    <div className="m-l-inner-page-body">
      <div className="m-l-form-panel-block">
        <div className="m-l-tbl-square-wrapper m-l-tbl-square-wrapper--edit-mode">
          <div className="row m-l-custom-eight-space-row">
            <FormProvider {...vesselCapacityForm}>
              <VesselFormCapacity
                yesNo={yesNo}
                fuel={fuel}
                fuelType={fuelType}
              />
            </FormProvider>
          </div>
          <div className="m-l-panel-right-btn u-pb0">
            <Button
              className="app-c-btn app-c-btn--secondary min-btn-width"
              onClick={handleBackClick}
            >
              {t('labels.cancel')}
            </Button>
            <Button
              onClick={onHandleSave}
              className="app-c-btn app-c-btn--primary min-btn-width"
            >
              {t('labels.save')}
            </Button>
            <ConfirmDialog
              dialogConfig={confirmSaveDialogConfig}
              isOpen={isConfirmModalOpen}
            />
            <ConfirmDialog
              dialogConfig={confirmBackDialogConfig}
              isOpen={isconfirmBackDialog}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
