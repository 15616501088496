import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormBuilder from '../../../../common/form-builder/FormBuilder';

export default function EnergyForm() {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const energyFormConfig = [
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'eedi',
      label: t('labels.eedi'),
      control: control,
      fieldType: 'text',
      maxLength: 10,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'eexi',
      label: t('labels.eexi'),
      control: control,
      fieldType: 'text',
      maxLength: 10,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'eeoi',
      label: t('labels.eeoi'),
      control: control,
      fieldType: 'text',
      maxLength: 10,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'cii',
      label: t('labels.cii'),
      control: control,
      fieldType: 'text',
      maxLength: 10,
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={energyFormConfig} />
      </div>
    </>
  );
}
