export type IBusinessUnitForm = {
  buName?: string;
  buEmail?: string;
  isGroupEmail?: boolean;
  callingCode?: string | null;
  phoneNumber?: number | null;
  addressLine1?: string;
  addressLine2?: string;
  countryId?: number | null;
  state?: string;
  incoTerms?: any;
  zipCode?: string;
  name?: string;
  emailId?: string;
  contactCallingCode?: string | null;
  personPhone?: number | null;
  titleId?: string | null;
  buId?: number;
  addressId?: number;
  contactId?: number;
  buStatus?: string;
  buIncoTermId?: number;
  companyId?: number;
};

export type BuFields =
  | 'buName'
  | 'buEmail'
  | 'callingCode'
  | 'phoneNumber'
  | 'addressLine1'
  | 'addressLine2'
  | 'countryId'
  | 'state'
  | 'zipCode'
  | 'buStatus'
  | 'titleId'
  | 'name'
  | 'emailId'
  | 'personPhone'
  | 'contactCallingCode'
  | 'companyId'
  | 'buId'
  | 'addressId'
  | 'contactId'
  | 'buIncoTermId'
  | 'incoTerms';

export type IBuIncoTerms = {
  incoTerms?: any;
  buIncoTermId?: number;
  buId?: number;
};
export type IContact = {
  contactId?: number;
  buId?: number;
  personNameTitle?: string | null;
  contactName?: string;
  contactEmail?: string;
  callingCode?: string | null;
  phoneNumber?: string | null;
};

export type IAddress = {
  addressId?: number;
  addressType?: string;
  addressLine1?: string;
  addressLine2?: string;
  countryId?: number | null;
  state?: string;
  zipCode?: string;
  callingCode?: string | null;
  phoneNumber?: string | null;
  buId?: number;
};
export type IBusinessUnit = {
  buId?: number;
  companyId?: number;
  buName?: string;
  buEmail?: string;
  isGroupEmail?: boolean;
  buStatus?: string;
  buIncoTerms?: IBuIncoTerms[];
  buAddresses?: IAddress[];
  buContacts?: IContact[];
};
export const blackListedEmails: string[] = [
  '@gmail.',
  '@yahoo.',
  '@hotmail.',
  '@aol.',
  '@outlook.',
];

export type IBUClone = {
  companyId: number;
  clonedBUId: number;
  buName: string;
  buStatus: string;
};
