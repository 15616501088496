import Button from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IListEntriesObject,
  IListEntryForm,
  ListAttributes,
} from '../../../types/list';
import { IModalConfig } from '../../../types/modal';
import icons from '../../common/icons/icons';
import Modal from '../../common/modal/Modal';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { MarsApiService as api } from '../../../api/mars-api-service';
import toast from '../../../utils/toast';
import { FormProvider, useForm } from 'react-hook-form';
import ListEntryFormFields from '../list-entry-form/ListEntryFormField';
import ValidationGroup from 'devextreme-react/validation-group';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { listTypes } from '../../../enums/buList-type-enum';
import ConfirmDialog from '../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../types/dialog';
import { useLoaderContext } from '../../../contexts/LoaderContext';

export default function EditEntries(props: {
  isVisible: any;
  setIsVisible: any;
  listId: any;
  getListById: any;
  listEntryId: any;
  listEntries: any;
  setListEntryId: any;
  attribute: any;
  listType?: any;
}) {
  const {
    isVisible,
    setIsVisible,
    listId,
    getListById,
    listEntryId,
    listEntries,
    setListEntryId,
    attribute,
    listType,
  } = props;
  const { t } = useTranslation();
  const validationGroup: any = useRef();
  const [entryStatus, setEntryStatus] = useState();
  const [removedDate, setRemovedDate] = useState();
  const [confirmSaveDialog, setConfirmSaveDialog] = useState(false);
  const { setIsLoading } = useLoaderContext();
  const { apiDateFormat } = useDateFormat();
  const defaultListEntryValues: IListEntryForm = {
    entryName: '',
    entryDisplayName: '',
    reason: '',
    enteredDate: null,
    validTillDate: null,
    removedDate: null,
    entryStatus: 'Active',
    createdBy: null,
    modifiedBy: null,
    createdDate: null,
    modifiedDate: null,
  };

  const listEntryForm = useForm({
    defaultValues: defaultListEntryValues,
  });

  let listEntryFormValue: IListEntryForm = listEntryForm.getValues();

  const getEntriesById = async () => {
    listEntryForm.reset();
    await api
      .get({
        url:
          apiConfig.listById + listId + apiConfig.listEntriesById + listEntryId,
      })
      .then((res) => {
        setEntryStatus(res.entryStatus);
        setRemovedDate(res.removedDate);
        listEntryForm.reset(
          {
            entryName: res.entryName,
            reason: res.reason,
            enteredDate: res.enteredDate,
            validTillDate: res.validTillDate,
            removedDate: res.removedDate,
            entryStatus: res.entryStatus,
            createdBy: res.createdBy,
            modifiedBy: res.modifiedBy,
            createdDate: res.createdDate,
            modifiedDate: res.modifiedDate,
          },
          { keepDirty: true }
        );
      });
  };

  useEffect(() => {
    if (listEntryId) {
      getEntriesById();
    }
  }, [listEntryId]);

  const modalConfig: IModalConfig = {
    width: 1011,
    height: listEntryId ? 455 : 310,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-add-new-modal',
    };
  }, []);

  const handleCancelClick = () => {
    setIsVisible(false);
    setListEntryId('');
    listEntryForm.reset(defaultListEntryValues);
  };

  const handleSaveClick = (e: any) => {
    const requiredValidation = validationGroup.current.instance.validate();
    if (requiredValidation.isValid) {
      listEntryFormValue = listEntryForm.getValues();
      setConfirmSaveDialog(true);
    }
  };

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: async () => {
      setConfirmSaveDialog(false);
      onSaveEntries();
    },
    handleClose: () => {
      setConfirmSaveDialog(false);
    },
  };

  const nameValidation = () => {
    if (typeof listEntryFormValue.entryName === 'string') {
      const nameIndex = listEntries.findIndex(
        (item: any) =>
          item.entryName.toLowerCase() ===
          listEntryFormValue.entryName.toLowerCase()
      );
      if (
        nameIndex != -1 &&
        listEntries[nameIndex].listEntryId != listEntryId
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      const nameIndex = listEntries.findIndex(
        (item: any) => item.entryName === listEntryFormValue.entryName
      );
      if (
        nameIndex != -1 &&
        listEntries[nameIndex].listEntryId != listEntryId
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const validTillDateValidation = () => {
    const dateValidation =
      listEntryFormValue.validTillDate && listEntryFormValue.enteredDate
        ? new Date(listEntryFormValue.validTillDate) >=
          new Date(listEntryFormValue.enteredDate)
        : true;
    return dateValidation;
  };

  const removedDateValidation = () => {
    const entryDateValidation =
      listEntryFormValue.removedDate && listEntryFormValue.enteredDate
        ? new Date(listEntryFormValue.removedDate) >=
          new Date(listEntryFormValue.enteredDate)
        : true;

    const dateValidation = listEntryFormValue.removedDate
      ? new Date(listEntryFormValue.removedDate) <= new Date()
      : true;
    dateValidation
      ? !entryDateValidation &&
        toast.error({ title: 'Error', message: t('errors.removedDateCheck') })
      : toast.error({ title: 'Error', message: t('errors.removedDate') });
    return dateValidation ? entryDateValidation : false;
  };

  const onSaveEntries = () => {
    if (nameValidation()) {
      if (validTillDateValidation()) {
        if (removedDateValidation()) {
          const listEntriesObject: IListEntriesObject = {
            listEntryId: listEntryId ? listEntryId : 0,
            listId: listId,
            entryName:
              attribute?.attributeType == ListAttributes.DateTime
                ? apiDateFormat(listEntryFormValue.entryName)
                : String(listEntryFormValue.entryName),
            entryDisplayName: listEntryFormValue.entryDisplayName
              ? String(listEntryFormValue.entryDisplayName)
              : attribute?.attributeType == ListAttributes.DateTime
              ? apiDateFormat(listEntryFormValue.entryName)
              : String(listEntryFormValue.entryName),
            reason: listEntryFormValue.reason,
            enteredDate: apiDateFormat(listEntryFormValue.enteredDate),
            validTillDate: apiDateFormat(listEntryFormValue.validTillDate),
            removedDate: apiDateFormat(listEntryFormValue.removedDate),
          };
          if (listEntryId) {
            saveEditedEntriesToList(listEntriesObject);
          } else {
            saveEntriesToList(listEntriesObject);
          }
        }
      } else {
        toast.error({ title: 'Error', message: t('errors.validTillDate') });
      }
    } else {
      toast.error({
        title: 'Error',
        message:
          t('labels.name') +
          ' ' +
          String(listEntryFormValue.entryName) +
          ' ' +
          t('errors.alreadyExist'),
      });
    }
  };

  const saveEntriesToList = async (listEntriesObject: IListEntriesObject) => {
    setIsVisible(false);
    await api
      .post(
        { url: apiConfig.listEntries, data: listEntriesObject },
        setIsLoading
      )
      .then(() => {
        getListById();
        toast.success({
          title: t('toast.listAdded'),
        });
      });
  };

  const saveEditedEntriesToList = async (
    listEntriesObject: IListEntriesObject
  ) => {
    setIsVisible(false);
    setListEntryId('');
    await api
      .put(
        {
          url: apiConfig.listById + listId + apiConfig.editEntries,
          data: listEntriesObject,
        },
        setIsLoading
      )
      .then(() => {
        getListById();
        toast.success({
          title: t('toast.listAdded'),
        });
      });
  };

  return (
    <div className="inner-wrapper">
      <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
        <div className="m-l-modal__header">
          <h2 className="modal-title"> {t('headers.entries')}</h2>
          <div className="m-l-modal-close-button-block">
            <Button
              className="app-c-btn app-c-icon-only-btn"
              onClick={handleCancelClick}
            >
              <icons.IconCross />
            </Button>
          </div>
        </div>
        <div className="m-l-modal__body">
          <ScrollView width="100%" height="100%">
            <div className="m-l-adnw-form-panel">
              <FormProvider {...listEntryForm}>
                <ValidationGroup ref={validationGroup}>
                  <ListEntryFormFields
                    listEntryId={listEntryId}
                    entryStatus={entryStatus}
                    removedDate={removedDate}
                    attribute={attribute}
                  />
                </ValidationGroup>
              </FormProvider>
            </div>
          </ScrollView>
        </div>
        <div className="m-l-modal__footer">
          <Button
            className="app-c-btn app-c-btn--primary"
            onClick={handleSaveClick}
            disabled={listType === listTypes.Global}
          >
            {t('buttons.save')}
          </Button>
        </div>
      </Modal>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={confirmSaveDialog}
      />
    </div>
  );
}
