import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IGeneralSettings } from '../../../../types/general-settings';
import GeneralSettingsForm from './general-settings-form/GeneralSettingsForm';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import Button from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import { GeneralSetting } from '../../../../enums/settings-type-enum';
import toast from '../../../../utils/toast';
import ValidationGroup from 'devextreme-react/validation-group';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../../types/dialog';
import { useLoaderContext } from '../../../../contexts/LoaderContext';

export default function GeneralSettings(props: {
  companyId: any;
  saveConfig?: any;
  setSaveConfig?: any;
}) {
  const { companyId, saveConfig, setSaveConfig } = props;
  const validationGroup: any = useRef();
  const [generalSettings, setGeneralSettings] = useState<any[]>([]);
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const { t } = useTranslation();
  const { setIsLoading } = useLoaderContext();
  const getSettings = async () => {
    await api
      .get({
        url: apiConfig.configurations,
        params: { settingArea: 'Global', companyId: companyId },
      })
      .then((data: any) => {
        if (data.length > 0) {
          setGeneralSettings(data);
        } else {
          getSettings2();
        }
      });
  };

  const getSettings2 = async () => {
    await api
      .get({
        url: apiConfig.configurations,
        params: { settingArea: 'Global' },
      })
      .then((data: any) => {
        setGeneralSettings(data);
      });
  };

  const companyInviteExpiry = generalSettings.find(
    (generalSetting) =>
      generalSetting.settingType === GeneralSetting.companyInviteExpiry
  );

  const notificationDuration = generalSettings.find(
    (generalSetting) =>
      generalSetting.settingType === GeneralSetting.notificationDuration
  );

  const dateFormat = generalSettings.find(
    (generalSetting) =>
      generalSetting.settingType === GeneralSetting.dateDisplayFormat
  );

  const vetRequestAutoCancel = generalSettings.find(
    (generalSetting) =>
      generalSetting.settingType === GeneralSetting.cancelWaitingPeriod
  );

  const vetRequestAutoUnassign = generalSettings.find(
    (generalSetting) =>
      generalSetting.settingType === GeneralSetting.unassignWaitingPeriod
  );

  const vetRequestProcessAutoUnassign = generalSettings.find(
    (generalSetting) =>
      generalSetting.settingType ===
      GeneralSetting.processingUnassignWaitingPeriod
  );

  const attachmentFileMaximumLength = generalSettings.find(
    (generalSetting) =>
      generalSetting.settingType === GeneralSetting.fileMaxLength
  );

  const attachmentAllowedFileTypes = generalSettings.find(
    (generalSetting) =>
      generalSetting.settingType === GeneralSetting.allowedFileTypes
  );

  const fileTypesArray = attachmentAllowedFileTypes?.settingValue?.split(',');

  useEffect(() => {
    getSettings();
  }, []);

  const defaultFormValues: IGeneralSettings = {
    companyInviteExpiry: null,
    notificationDuration: null,
    dateFormat: null,
    cancelWaitingPeriod: null,
    unassignWaitingPeriod: null,
    processingUnassignWaitingPeriod: null,
    fileMaxLength: null,
    allowedFileTypes: null,
  };

  const generalSettingsForm = useForm({
    defaultValues: defaultFormValues,
  });

  const { isDirty } = generalSettingsForm.formState;

  const onValueSubmit = () => {
    const requiredValidation = validationGroup.current.instance.validate();
    if (requiredValidation.isValid) {
      const formValue: IGeneralSettings = generalSettingsForm.getValues();
      let allowedTypes = '';
      formValue.allowedFileTypes?.forEach(async (element: any) => {
        if (element != '') {
          allowedTypes = allowedTypes + element + ',';
        }
      });
      allowedTypes = allowedTypes.slice(0, -1);
      companyInviteExpiry.settingValue =
        formValue.companyInviteExpiry?.toString();
      notificationDuration.settingValue =
        formValue.notificationDuration?.toString();
      dateFormat.settingValue = formValue.dateFormat;
      vetRequestAutoCancel.settingValue =
        formValue.cancelWaitingPeriod?.toString();
      vetRequestAutoUnassign.settingValue =
        formValue.unassignWaitingPeriod?.toString();
      vetRequestProcessAutoUnassign.settingValue =
        formValue.processingUnassignWaitingPeriod?.toString();
      attachmentFileMaximumLength.settingValue =
        formValue.fileMaxLength?.toString();
      attachmentAllowedFileTypes.settingValue = allowedTypes;
      const roleJsonEdit = [
        companyInviteExpiry,
        notificationDuration,
        dateFormat,
        vetRequestAutoCancel,
        vetRequestAutoUnassign,
        vetRequestProcessAutoUnassign,
        attachmentFileMaximumLength,
        attachmentAllowedFileTypes,
      ];
      api
        .put(
          {
            url: apiConfig.configurations,
            params: { configArea: 'Global', companyId: companyId },
            data: roleJsonEdit,
          },
          setIsLoading
        )
        .then((response) => {
          if (response) {
            getSettings();
            toast.custom({
              title: t('toast.changesSavedSuccessfully'),
            });
          }
        });
      setSaveConfig(false);
    }
  };

  useEffect(() => {
    generalSettingsForm.reset();
    if (generalSettings) {
      generalSettingsForm.reset({
        companyInviteExpiry: parseInt(companyInviteExpiry?.settingValue),
        notificationDuration: parseInt(notificationDuration?.settingValue),
        dateFormat: dateFormat?.settingValue,
        cancelWaitingPeriod: parseInt(vetRequestAutoCancel?.settingValue),
        unassignWaitingPeriod: parseInt(vetRequestAutoUnassign?.settingValue),
        processingUnassignWaitingPeriod: parseInt(
          vetRequestProcessAutoUnassign?.settingValue
        ),
        fileMaxLength: parseInt(attachmentFileMaximumLength?.settingValue),
        allowedFileTypes: fileTypesArray,
      });
    }
  }, [generalSettings]);

  const handleSaveClick = async () => {
    setShowConfirmSave(true);
  };

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmSaveChanges'),
    handleSubmit: () => {
      onValueSubmit();
      setShowConfirmSave(false);
      setSaveConfig(false);
    },
    handleClose: () => {
      setShowConfirmSave(false);
      setSaveConfig(false);
    },
  };

  useEffect(() => {
    if (saveConfig && validationGroup.current?.instance.validate().isValid) {
      handleSaveClick();
    } else {
      setSaveConfig(false);
    }
  }, [saveConfig]);

  return (
    <ValidationGroup ref={validationGroup}>
      <div className="m-l-form-panel-block">
        <div className="m-l-form-panel-header-block">
          <div className="m-l-form-panel-header-left-block">
            <div className="m-l-page-small-heading">
              {t('labels.generalSettings')}
            </div>
          </div>
          <div className="m-l-form-panel-header-right-block">
            {/* <Button
              className="app-c-btn app-c-btn--primary min-btn-width"
              aria-label=""
              onClick={onValueSubmit}
            >
              {t('labels.save')}
            </Button> */}
          </div>
        </div>
        <div className="m-l-form-panel-body-block">
          <FormProvider {...generalSettingsForm}>
            <GeneralSettingsForm />
          </FormProvider>
        </div>
        <ConfirmDialog
          dialogConfig={confirmSaveDialogConfig}
          isOpen={showConfirmSave}
        />
      </div>
    </ValidationGroup>
  );
}
