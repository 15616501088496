export type IIncidentForm = {
  incidentDate: any;
  placeOfIncident: any;
  locationName: any;
  environment: any;
  marsdenGrid: any;
  casualityZoneCode?: any;
  casualityZone: any;
  eventTypeCode?: any;
  eventType: any;
  eventTypeDetailCode?: any;
  eventTypeDetail: any;
  significanceCode?: any;
  significance: any;
  loadStatusCode?: any;
  loadStatus: any;
  totalLoss: any;
  pollution: any;
  fire: any;
  significantDamage: any;
  additionalEventCode?: any;
  additionalEvent: any;
  injury: any;
  injuryTypeCode?: any;
  injuryType: any;
  headline: any;
  description: any;
  rootCauseCode?: any;
  rootCause: any;
  incidentAction: any;
  incidentActionsCode?: any;
  comments: any;
  status: any;
  isClosedByMars?: any;
  source?: any;
  stateOfIncident?: any;
  companyId?: any;
  isMarsOrExternal?: any;
  marsStatus?: any;
  attachments?: any;
};

export type IAddIncidentObject = {
  imoNumber: string;
  incidentDate: any;
  placeOfIncident: string;
  locationName: string;
  environment: string;
  marsdenGrid: string;
  casualityZoneCode: string;
  eventTypeCode: string;
  eventTypeDetailCode: string;
  significanceCode: string;
  loadStatusCode: string;
  totalLoss: boolean;
  pollution: boolean;
  fire: boolean;
  significantDamage: boolean;
  additionalEventCode: string;
  injury: boolean;
  injuryTypeCode: string;
  headline: string;
  description: string;
  rootCauseCode: string;
  incidentActionsCode: string;
  comments: string;
  status: number;
  eventId: any;
};

export enum MarsStatus {
  Open = '1',
  Closed = '2',
  AwaitingInfo = '3',
  WrongEntry = '4',
}
