import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormBuilder from '../../../../common/form-builder/FormBuilder';

export default function EngineForm() {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const engineFormConfig = [
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'mainEngineType',
      label: t('labels.engineType'),
      control: control,
      fieldType: 'text',
      maxLength: 30,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'mainEngineBuilder',
      label: t('labels.engineBuilder'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'mainEngineBuilderCode',
      label: t('labels.engineBuilderCode'),
      control: control,
      fieldType: 'text',
      maxLength: 30,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'mainEngineModel',
      label: t('labels.engineModel'),
      control: control,
      fieldType: 'text',
      maxLength: 50,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'enginePower',
      label: t('labels.enginePower'),
      control: control,
      fieldType: 'text',
      maxLength: 50,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'powerkwmax',
      label: t('labels.engTotalBhp'),
      control: control,
      fieldType: 'number',
      maxLength: 30,
      rules: [
        {
          type: 'pattern',
          pattern: '^[0-9]*$',
          message: t('errors.wholeNumber'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'mainEngineBore',
      label: t('labels.engineBore'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'speedMax',
      label: t('labels.maximumSpeed'),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,12})([.][0-9]{0,2})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'mainEngineConsumptionInBallast',
      label: t('labels.consumptionInBallast'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'mainEngineSpeedInBallast',
      label: t('labels.speedInBallast'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'mainEngineConsumptionInloaded',
      label: t('labels.consumptionInLoaded'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'mainEngineSpeedInLoaded',
      label: t('labels.speedInLoaded'),
      control: control,
      fieldType: 'text',
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={engineFormConfig} />
      </div>
    </>
  );
}
