import React, { useState, useMemo, useEffect, useRef } from 'react';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { Button } from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import Tooltip from 'devextreme-react/tooltip';
import { IModalConfig } from '../../../types/modal';
import Modal from '../../common/modal/Modal';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import Validator from 'devextreme-react/validator';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { useTranslation } from 'react-i18next';
import ValidationGroup from 'devextreme-react/validation-group';
import toast from '../../../utils/toast';
import { IAddNewVessel } from '../../../types/add-new-vessel';
import { useNavigate } from 'react-router';
import { passwordLengthEnum } from '../../../enums/password-length';
import NumberBox from 'devextreme-react/number-box';
import SelectBox from 'devextreme-react/select-box';
import { IAddNewPort } from '../../../types/port-configurations';
import { useDataStore } from '../../../hooks/useDataStore';

export default function AddNewPort(props: {
  isAddNewPort: any;
  setIsAddNewPort: any;
  query?: any;
  setSearchList: any;
  searchList: any;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getDataSource } = useDataStore();
  const validationGroupPortName: any = useRef();
  const validationGroupPortCode: any = useRef();
  const validationGroupCountry: any = useRef();
  const [portName, setPortName] = useState('');
  const [portCode, setPortCode] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [countriesList, setCountriesList] = useState<any>();
  const { isAddNewPort, setIsAddNewPort, query, searchList, setSearchList } =
    props;

  const { setIsLoading } = useLoaderContext();

  const getCountries = async () => {
    await api.get({ url: apiConfig.country }).then((data: any) => {
      setCountriesList(data);
    });
  };

  const popupAttributesChangePWD = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-modal-change__pwd',
    };
  }, []);

  const handleCancelClick = (e: any) => {
    setIsAddNewPort(false);
    clearFields();
  };

  const clearFields = () => {
    setPortName('');
    setPortCode('');
  };

  const modalConfigChangePWD: IModalConfig = {
    width: 400,
    height: 434,
    visible: isAddNewPort,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const onAddNewPort = (e: any) => {
    if (
      validationGroupPortName.current?.instance.validate().isValid &&
      validationGroupPortCode.current?.instance.validate().isValid &&
      validationGroupCountry.current?.instance.validate().isValid
    ) {
      const roleJsonEdit: IAddNewPort = {
        portName: portName,
        portCode: portCode?.toString(),
        countryCode: countryCode,
      };
      api
        .post({ url: apiConfig.viewPort, data: roleJsonEdit }, setIsLoading)
        .then((response) => {
          if (response) {
            handleCancelClick(e);
            toast.success({
              title: t('toast.portAddedSuccess'),
            });
            if (query) {
              const data = getDataSource(
                'portId',
                apiConfig.portSearch + query + '&Search=Ports',
                10
              );
              setSearchList(data);
            }
          }
        });
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <div>
      {isAddNewPort && (
        <Modal
          modalConfig={modalConfigChangePWD}
          wrapperAttr={popupAttributesChangePWD}
        >
          <div className="m-l-confirmation-dialogbox-wrap">
            <div className="m-l-confirm-dialogbox-header m-l-cmn-modal-header">
              <div className="m-l-hdr-mdl-title">{t('labels.addNewPort')}</div>
              <Button icon="close" onClick={handleCancelClick}></Button>
            </div>
            <div className="m-l-confirm-dialogbox-body">
              <ScrollView width="100%" height="100%">
                <div className="m-l-pwd-chng-wrap">
                  <form>
                    <div className="m-c-form-group mb-btm-space-24">
                      <label className="m-l-input-label">
                        {t('labels.portName')}
                        <span className="m-l-input-required">*</span>
                      </label>
                      <ValidationGroup ref={validationGroupPortName}>
                        <TextBox
                          className="m-c-input-control m-c-input-text"
                          value={portName}
                          onValueChanged={(e) => {
                            setPortName(e.value);
                          }}
                          validationMessageMode="always"
                        >
                          <Validator
                            validationRules={[
                              {
                                type: 'required',
                                message: t('errors.required'),
                              },
                            ]}
                          />
                        </TextBox>
                      </ValidationGroup>
                    </div>
                    <div className="m-c-form-group mb-btm-space-24">
                      <div className="m-l-label-icon-wrap">
                        <label className="m-l-input-label">
                          {t('labels.portCode')}
                          <span className="m-l-input-required">*</span>
                        </label>
                      </div>
                      <ValidationGroup ref={validationGroupPortCode}>
                        <TextBox
                          className="m-c-input-control m-c-input-text"
                          value={portCode}
                          onValueChanged={(e) => {
                            setPortCode(e.value);
                          }}
                          validationMessageMode="always"
                        >
                          <Validator
                            validationRules={[
                              {
                                type: 'required',
                                message: t('errors.required'),
                              },
                            ]}
                          />
                        </TextBox>
                      </ValidationGroup>
                    </div>
                    <div className="m-c-form-group mb-btm-space-24">
                      <label className="m-l-input-label">
                        {t('labels.country')}
                        <span className="m-l-input-required">*</span>
                      </label>
                      <ValidationGroup ref={validationGroupCountry}>
                        <SelectBox
                          className="m-c-input-control m-c-select-box"
                          placeholder="Select Country"
                          dataSource={countriesList}
                          searchEnabled
                          valueExpr="countryCode"
                          displayExpr="countryName"
                          onValueChanged={(e) => setCountryCode(e.value)}
                        >
                          <Validator
                            validationRules={[
                              {
                                type: 'required',
                                message: t('errors.required'),
                              },
                            ]}
                          />
                        </SelectBox>
                      </ValidationGroup>
                    </div>
                  </form>
                </div>
              </ScrollView>
            </div>
            <div className="m-l-chp-mdl-footer">
              <Button
                className="app-c-btn app-c-btn--secondary"
                onClick={handleCancelClick}
              >
                {t('labels.cancel')}
              </Button>
              <Button
                className="app-c-btn app-c-btn--primary"
                onClick={onAddNewPort}
              >
                {t('labels.save')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
