import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormBuilder from '../../../../common/form-builder/FormBuilder';

export default function VesselFormClassificationl(props: {
  classSociety: any;
  vesselStatus: any;
  survey: any;
}) {
  const { classSociety, vesselStatus, survey } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();

  const classificationFormConfig = [
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'classsocietycode',
      label: t('labels.classSociety'),
      control: control,
      fieldType: 'select',
      dataSource: classSociety,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'vesselstatus',
      label: t('labels.classStatus'),
      control: control,
      fieldType: 'select',
      dataSource: vesselStatus,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      dropDownOptions: {
        resizeEnabled: true,
        width: '400px',
        maxWidth: '500px',
      },
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'classnotation',
      label: t('labels.classNotation'),
      control: control,
      fieldType: 'text',
      maxLength: 50,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'iceclass',
      label: t('labels.iceClass'),
      control: control,
      fieldType: 'text',
      maxLength: 50,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'servicerestriction',
      label: t('labels.serviceRestriction'),
      control: control,
      fieldType: 'text',
      maxLength: 50,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'classificationdate',
      label: t('labels.classificationDate'),
      control: control,
      fieldType: 'date',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'specialsurveydate',
      label: t('labels.specialSurveyDate'),
      control: control,
      fieldType: 'date',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'surveyType',
      label: t('labels.survey'),
      control: control,
      fieldType: 'select',
      dataSource: survey,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'annualSurveyDate',
      label: t('labels.surveyDate'),
      control: control,
      fieldType: 'date',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'nextAnnualSurveyDate',
      label: t('labels.nextSurveyDate'),
      control: control,
      fieldType: 'date',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'survey2',
      label: t('labels.survey2'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'survey2date',
      label: t('labels.survey2Date'),
      control: control,
      fieldType: 'date',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'nextDrydockDate',
      label: t('labels.survey2NextSurveyDate'),
      control: control,
      fieldType: 'date',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'classnarrative',
      label: t('labels.classNarrative'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'historicclassstatus',
      label: t('labels.historicClassStatus'),
      control: control,
      fieldType: 'select',
      dataSource: vesselStatus,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      dropDownOptions: {
        resizeEnabled: true,
        width: '400px',
        maxWidth: '500px',
      },
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={classificationFormConfig} />
      </div>
    </>
  );
}
