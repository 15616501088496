import React, { useMemo, useRef } from 'react';
import { TextBox } from 'devextreme-react/text-box';
import { Button } from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import { IModalConfig } from '../../../types/modal';
import Modal from '../../common/modal/Modal';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import Validator from 'devextreme-react/validator';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { useTranslation } from 'react-i18next';
import ValidationGroup from 'devextreme-react/validation-group';
import toast from '../../../utils/toast';
import { IAddNewTerminal } from '../../../types/port-configurations';
import { removeKey } from '../../../services/master-data-service';

export default function AddNewTerminal(props: {
  isAddNewTerminal: any;
  setIsAddNewTerminal: any;
  portCode?: any;
  setQuery?: any;
  query?: any;
  setIsFromAddTerminal?: any;
}) {
  const { t } = useTranslation();
  const validationGroupTerminalName: any = useRef();
  const validationGroupTerminalCode: any = useRef();
  const [terminalName, setTerminalName] = React.useState('');
  const [terminalCode, setTerminalCode] = React.useState('');
  const {
    isAddNewTerminal,
    setIsAddNewTerminal,
    portCode,
    setQuery,
    query,
    setIsFromAddTerminal,
  } = props;

  const { setIsLoading } = useLoaderContext();

  const popupAttributesChangePWD = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-modal-change__pwd',
    };
  }, []);

  const handleCancelClick = () => {
    setIsAddNewTerminal(false);
    clearFields();
  };

  const clearFields = () => {
    setTerminalName('');
    setTerminalCode('');
  };

  const modalConfigChangePWD: IModalConfig = {
    width: 400,
    height: 434,
    visible: isAddNewTerminal,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const onAddNewVessel = () => {
    if (
      validationGroupTerminalName.current?.instance.validate().isValid &&
      validationGroupTerminalCode.current?.instance.validate().isValid
    ) {
      const roleJsonEdit: IAddNewTerminal = {
        terminalName: terminalName,
        terminalCode: terminalCode?.toString(),
        portCode: portCode,
      };
      api
        .post({ url: apiConfig.viewTerminal, data: roleJsonEdit }, setIsLoading)
        .then((response) => {
          if (response) {
            handleCancelClick();
            toast.success({
              title: t('toast.terminalAddedSuccess'),
            });
            setQuery(query);
            setIsFromAddTerminal(true);
            removeKey('terminals');
          }
        });
    }
  };

  return (
    <div>
      {isAddNewTerminal && (
        <Modal
          modalConfig={modalConfigChangePWD}
          wrapperAttr={popupAttributesChangePWD}
        >
          <div className="m-l-confirmation-dialogbox-wrap">
            <div className="m-l-confirm-dialogbox-header m-l-cmn-modal-header">
              <div className="m-l-hdr-mdl-title">
                {t('labels.addNewTerminal')}
              </div>
              <Button icon="close" onClick={handleCancelClick}></Button>
            </div>
            <div className="m-l-confirm-dialogbox-body">
              <ScrollView width="100%" height="100%">
                <div className="m-l-pwd-chng-wrap">
                  <form>
                    <div className="m-c-form-group mb-btm-space-24">
                      <label className="m-l-input-label">
                        {t('labels.terminalName')}
                        <span className="m-l-input-required">*</span>
                      </label>
                      <ValidationGroup ref={validationGroupTerminalName}>
                        <TextBox
                          className="m-c-input-control m-c-input-text"
                          value={terminalName}
                          onValueChanged={(e) => {
                            setTerminalName(e.value);
                          }}
                          validationMessageMode="always"
                        >
                          <Validator
                            validationRules={[
                              {
                                type: 'required',
                                message: t('errors.required'),
                              },
                            ]}
                          />
                        </TextBox>
                      </ValidationGroup>
                    </div>
                    <div className="m-c-form-group mb-btm-space-24">
                      <div className="m-l-label-icon-wrap">
                        <label className="m-l-input-label">
                          {t('labels.terminalCode')}
                          <span className="m-l-input-required">*</span>
                        </label>
                      </div>
                      <ValidationGroup ref={validationGroupTerminalCode}>
                        <TextBox
                          className="m-c-input-control m-c-input-text"
                          value={terminalCode}
                          onValueChanged={(e) => {
                            setTerminalCode(e.value);
                          }}
                          validationMessageMode="always"
                        >
                          <Validator
                            validationRules={[
                              {
                                type: 'required',
                                message: t('errors.required'),
                              },
                            ]}
                          />
                        </TextBox>
                      </ValidationGroup>
                    </div>
                  </form>
                </div>
              </ScrollView>
            </div>
            <div className="m-l-chp-mdl-footer">
              <Button
                className="app-c-btn app-c-btn--secondary"
                onClick={handleCancelClick}
              >
                {t('labels.cancel')}
              </Button>
              <Button
                className="app-c-btn app-c-btn--primary"
                onClick={onAddNewVessel}
              >
                {t('labels.save')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
