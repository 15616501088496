import TextBox from 'devextreme-react/text-box';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IFormConfig } from '../../../../types/form-builder';
import FormBuilder from '../../../common/form-builder/FormBuilder';
import CountryList from '../../../Country.json';
import { ICountry, IOptionvalue } from '../../../../types/master-data';
import { blackListedEmails } from '../../../../types/business-unit';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { BusinessUnitStatus } from '../../../../enums/status-enum';

export default function BusinessUnitForm(props: {
  countries: ICountry[];
  personNameTitles: IOptionvalue[];
  buStatusList: IOptionvalue[];
  buIncoterms: IOptionvalue[];
}) {
  const { countries, personNameTitles, buStatusList, buIncoterms } = props;
  const location = useLocation();
  const [actionDisabled, setActionDisabled] = useState(Boolean);

  useEffect(() => {
    const action = location.pathname.split('/')[3];
    action == 'edit' ? setActionDisabled(false) : setActionDisabled(true);
  }, [location]);

  const { control, setValue } = useFormContext();
  const { t } = useTranslation();
  const countryList = CountryList.sort();
  const distinctCallingCodes = countryList
    .map((item) => item.callingCode)
    .filter((value, index, self) => self.indexOf(value) === index);

  const callingCodes: any = [];
  distinctCallingCodes.forEach((callingCode) =>
    callingCodes.push({
      callingCode: callingCode,
    })
  );

  const onChangeCountry = (e: any) => {
    const country = countries.find(
      (country: ICountry) => country.countryId == e.value
    );
    const filteredCountryList = countryList.find(
      (callingCode) => callingCode.iso_3 == country?.iso3
    );
    if (filteredCountryList) {
      setValue('callingCode', filteredCountryList.callingCode);
      setValue('contactCallingCode', filteredCountryList.callingCode);
    }
  };
  const formConfig: IFormConfig[] = [
    {
      name: 'buName',
      label: t('labels.buName'),
      control: control,
      fieldType: 'text',
      isRequired: true,
      maxLength: 50,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      name: 'buEmail',
      label: t('labels.email'),
      control: control,
      fieldType: 'textWithCheckbox',
      isRequired: true,
      maxLength: 50,
      checkBoxName: 'isGroupEmail',
      checkBoxLabel: t('labels.isGroupEmail'),
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
        {
          type: 'email',
          message: t('errors.inCorrectEmailFormat'),
        },
        {
          type: 'custom',
          validationCallback: (params: any) => {
            const regex = new RegExp(blackListedEmails.join('|'));
            return !regex.test(params.value.toLowerCase());
          },
          message: t('errors.publicDomainEmailNotAllowed'),
        },
      ],
    },
    {
      name: 'countryId',
      label: t('labels.country'),
      control: control,
      fieldType: 'select',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
      dataSource: countries,
      displayExpr: 'countryName',
      valueExpr: 'countryId',
      onChange: onChangeCountry,
      autocomplete: 'new-user',
    },
    {
      name: 'buStatus',
      label: t('labels.status'),
      control: control,
      fieldType: 'select',
      disableSearch: true,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      dataSource: buStatusList,
      disabled: actionDisabled,
      fieldRender: (data: any) => {
        return (
          <TextBox
            className={
              data?.optionValueCode === BusinessUnitStatus.Active
                ? 'active-lbl'
                : 'inactive-lbl'
            }
            defaultValue={data && data.optionValueText}
            readOnly={true}
          />
        );
      },
    },
    {
      name: 'addressLine1',
      label: t('labels.addressLine1'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
    },
    {
      name: 'addressLine2',
      label: t('labels.addressLine2'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
    },
    {
      name: 'state',
      label: t('labels.state'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
    },
    {
      name: 'zipCode',
      label: t('labels.zipCode'),
      control: control,
      fieldType: 'text',
      maxLength: 24,
    },
    {
      name: 'phoneNumber',
      label: t('labels.phoneNumber'),
      control: control,
      fieldType: 'customSelect',
      dataSource: callingCodes,
      displayExpr: 'callingCode',
      valueExpr: 'callingCode',
      selectBoxName: 'callingCode',
      maxLength: 20,
      itemRender: renderItem,
      customSelectInputFieldType: 'number',
      autocomplete: 'new-user',
      rules: [
        {
          type: 'pattern',
          pattern: '^[0-9]+$',
          message: t('errors.inCorrectPhoneNumberFormat'),
        },
      ],
      // rules: [
      //   {
      //     type: 'custom',
      //     validationCallback: () => {
      //       return getValues().callingCode ? true : false;
      //     },
      //     reevaluate: true,
      //     message: t('errors.dialCodeNotEntered'),
      //     ignoreEmptyValue: true,
      //   },
      // ],
    },
    {
      name: 'incoTerms',
      label: t('labels.buIncoTerms'),
      control: control,
      fieldType: 'multiSelect',
      disableSearch: true,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      dataSource: buIncoterms,
      fieldRender: (data: any) => {
        return (
          <TextBox
            defaultValue={data && data.optionValueText}
            readOnly={true}
          />
        );
      },
    },
  ];

  const personForm = [
    {
      name: 'name',
      label: t('labels.name'),
      control: control,
      fieldType: 'customSelect',
      dataSource: personNameTitles,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      selectBoxName: 'titleId',
      maxLength: 100,
      autocomplete: 'new-user',
    },
    {
      name: 'emailId',
      label: t('labels.email'),
      control: control,
      fieldType: 'text',
      rules: [
        {
          type: 'email',
          message: t('errors.inCorrectEmailFormat'),
        },
        {
          type: 'custom',
          validationCallback: (params: any) => {
            const regex = new RegExp(blackListedEmails.join('|'));
            return !regex.test(params.value.toLowerCase());
          },
          message: t('errors.publicDomainEmailNotAllowed'),
        },
      ],
    },
    {
      name: 'personPhone',
      label: t('labels.phoneNumber'),
      control: control,
      fieldType: 'customSelect',
      dataSource: callingCodes,
      displayExpr: 'callingCode',
      valueExpr: 'callingCode',
      selectBoxName: 'contactCallingCode',
      customSelectInputFieldType: 'number',
      itemRender: renderItem,
      maxLength: 20,
      autocomplete: 'new-user',
      rules: [
        {
          type: 'pattern',
          pattern: '^[0-9]+$',
          message: t('errors.inCorrectPhoneNumberFormat'),
        },
      ],
    },
  ];
  return (
    <form data-testid="buForm">
      <div className="m-l-form-panel-body-block">
        <div className="row">
          <FormBuilder formConfig={[...formConfig]} />
        </div>
        <div className="row m-l-head-tab-bottom-section">
          <div className="m-l-contact-info-block">
            <div className="m-l-page-sub-heading">
              {t('headers.contactPersonInformation')}
            </div>
          </div>
          <FormBuilder formConfig={[...personForm]} />
        </div>
      </div>
    </form>
  );
}

const renderItem = (data: any) => {
  return (
    <div>
      <div style={{ display: 'inline-block', textAlign: 'left' }}>
        {data?.callingCode}
      </div>
    </div>
  );
};
