import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormBuilder from '../../common/form-builder/FormBuilder';

export default function RumeMessageFields() {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const messageFieldsConfig = [
    {
      class:
        'col-md-6 col-sm-12 col-lg-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'successMessage',
      label: t('labels.successMessage'),
      control: control,
      fieldType: 'textArea',
      maxLength: 250,
    },
    {
      class:
        'col-md-6 col-sm-12 col-lg-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'failMessage',
      label: t('labels.failMessage'),
      control: control,
      fieldType: 'textArea',
      maxLength: 250,
    },
  ];
  return (
    <div className="row m-l-custom-sixteen-space-row">
      <FormBuilder formConfig={messageFieldsConfig} />
    </div>
  );
}
