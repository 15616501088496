import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getAPIRoute } from '../../../../utils/api-route';
import SanctionForm from './sanction-form/SanctionsForm';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { Button } from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import toast from '../../../../utils/toast';
import { IDialogConfig } from '../../../../types/dialog';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { SanctionsSettingType } from '../../../../enums/config-enum';
import { ISanctionConfigForm } from '../../../../types/config';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import { getCompanyType } from '../../../../utils/jwt-decode';

export default function SanctionList(props: {
  companyId: any;
  buId: any;
  saveConfig?: any;
  setSaveConfig?: any;
  selectedCompanyType?: any;
  companyTypeFlag?: any;
}) {
  const {
    companyId,
    buId,
    saveConfig,
    setSaveConfig,
    selectedCompanyType,
    companyTypeFlag,
  } = props;
  const [sanctions, setSanctions] = useState<any>([]);
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const { t } = useTranslation();
  const { setIsLoading } = useLoaderContext();
  const companyType = getCompanyType();
  const defaultValues: ISanctionConfigForm = {
    sanctionedPortList: null,
    sanctionedCountryList: null,
    sanctionedVesselList: null,
    restrictedVesselList: null,
    sanctionedBareboatOwner: null,
    sanctionedDOCCompany: null,
    sanctionedGroupOwner: null,
    sanctionedOperator: null,
    sanctionedRegisteredOwner: null,
    sanctionedShipManager: null,
    sanctionedTechnicalManager: null,
    approvedPortList: null,
    approvedTerminalList: null,
    approvedBerthList: null,
  };

  const generalForm = useForm({
    defaultValues: defaultValues,
  });

  const getSanctions = async () => {
    if (buId) {
      await api
        .get({
          url: apiConfig.configurations,
          params: {
            settingArea: 'sanctionList',
            buId: buId,
            companyId: companyId,
          },
        })
        .then((response) => {
          setSanctions(response);
        });
    } else {
      await api
        .get({
          url: apiConfig.configurations,
          params: { settingArea: 'sanctionList', companyId: companyId },
        })
        .then((response) => {
          setSanctions(response);
        });
    }
  };

  useEffect(() => {
    getSanctions();
  }, []);

  useEffect(() => {
    if (saveConfig) {
      setShowConfirmSave(true);
    }
  }, [saveConfig]);

  useEffect(() => {
    if (sanctions) {
      const sanctionedPortListindex = sanctions?.findIndex(
        (item: any) =>
          item?.settingType == SanctionsSettingType.SanctionedPortList
      );
      const sanctionedCountryListindex = sanctions?.findIndex(
        (item: any) =>
          item?.settingType == SanctionsSettingType.SanctionedCountryList
      );
      const sanctionedVesselListindex = sanctions?.findIndex(
        (item: any) =>
          item?.settingType == SanctionsSettingType.SanctionedVesselList
      );
      const restrictedVesselListindex = sanctions?.findIndex(
        (item: any) =>
          item?.settingType == SanctionsSettingType.RestrictedVesselList
      );
      const sanctionedBareboatOwnerindex = sanctions?.findIndex(
        (item: any) =>
          item?.settingType == SanctionsSettingType.SanctionedBareboatOwner
      );
      const sanctionedDOCCompanyindex = sanctions?.findIndex(
        (item: any) =>
          item?.settingType == SanctionsSettingType.SanctionedDOCCompany
      );
      const sanctionedGroupOwnerindex = sanctions?.findIndex(
        (item: any) =>
          item?.settingType == SanctionsSettingType.SanctionedGroupOwner
      );
      const sanctionedOperatorindex = sanctions?.findIndex(
        (item: any) =>
          item?.settingType == SanctionsSettingType.SanctionedOperator
      );
      const sanctionedRegisteredOwnerindex = sanctions?.findIndex(
        (item: any) =>
          item?.settingType == SanctionsSettingType.SanctionedRegisteredOwner
      );
      const sanctionedShipManagerindex = sanctions?.findIndex(
        (item: any) =>
          item?.settingType == SanctionsSettingType.SanctionedShipManager
      );
      const sanctionedTechnicalManagerindex = sanctions?.findIndex(
        (item: any) =>
          item?.settingType == SanctionsSettingType.SanctionedTechnicalManager
      );
      const approvedPortListindex = sanctions?.findIndex(
        (item: any) =>
          item?.settingType == SanctionsSettingType.ApprovedPortList
      );
      const approvedTerminalListindex = sanctions?.findIndex(
        (item: any) =>
          item?.settingType == SanctionsSettingType.ApprovedTerminalList
      );
      const approvedBerthListindex = sanctions?.findIndex(
        (item: any) =>
          item?.settingType == SanctionsSettingType.ApprovedBerthList
      );
      generalForm.reset({
        sanctionedPortList: Number(
          sanctions[sanctionedPortListindex]?.settingValue
        ),
        sanctionedCountryList: Number(
          sanctions[sanctionedCountryListindex]?.settingValue
        ),
        sanctionedVesselList: Number(
          sanctions[sanctionedVesselListindex]?.settingValue
        ),
        restrictedVesselList: Number(
          sanctions[restrictedVesselListindex]?.settingValue
        ),
        sanctionedBareboatOwner: Number(
          sanctions[sanctionedBareboatOwnerindex]?.settingValue
        ),
        sanctionedDOCCompany: Number(
          sanctions[sanctionedDOCCompanyindex]?.settingValue
        ),
        sanctionedGroupOwner: Number(
          sanctions[sanctionedGroupOwnerindex]?.settingValue
        ),
        sanctionedOperator: Number(
          sanctions[sanctionedOperatorindex]?.settingValue
        ),
        sanctionedRegisteredOwner: Number(
          sanctions[sanctionedRegisteredOwnerindex]?.settingValue
        ),
        sanctionedShipManager: Number(
          sanctions[sanctionedShipManagerindex]?.settingValue
        ),
        sanctionedTechnicalManager: Number(
          sanctions[sanctionedTechnicalManagerindex]?.settingValue
        ),
        approvedPortList: Number(
          sanctions[approvedPortListindex]?.settingValue
        ),
        approvedTerminalList: Number(
          sanctions[approvedTerminalListindex]?.settingValue
        ),
        approvedBerthList: Number(
          sanctions[approvedBerthListindex]?.settingValue
        ),
      });
    }
  }, [sanctions]);

  const handleSaveClick = async () => {
    let index = sanctions?.findIndex(
      (item: any) =>
        item?.settingType == SanctionsSettingType.SanctionedPortList
    );
    sanctions[index].settingValue = generalForm.getValues().sanctionedPortList
      ? String(generalForm.getValues().sanctionedPortList)
      : '';
    index = sanctions?.findIndex(
      (item: any) =>
        item?.settingType == SanctionsSettingType.SanctionedCountryList
    );
    sanctions[index].settingValue = generalForm.getValues()
      .sanctionedCountryList
      ? String(generalForm.getValues().sanctionedCountryList)
      : '';
    index = sanctions?.findIndex(
      (item: any) =>
        item?.settingType == SanctionsSettingType.SanctionedVesselList
    );
    sanctions[index].settingValue = generalForm.getValues().sanctionedVesselList
      ? String(generalForm.getValues().sanctionedVesselList)
      : '';
    index = sanctions?.findIndex(
      (item: any) =>
        item?.settingType == SanctionsSettingType.RestrictedVesselList
    );
    sanctions[index].settingValue = generalForm.getValues().restrictedVesselList
      ? String(generalForm.getValues().restrictedVesselList)
      : '';
    index = sanctions?.findIndex(
      (item: any) =>
        item?.settingType == SanctionsSettingType.SanctionedBareboatOwner
    );
    sanctions[index].settingValue = generalForm.getValues()
      .sanctionedBareboatOwner
      ? String(generalForm.getValues().sanctionedBareboatOwner)
      : '';
    index = sanctions?.findIndex(
      (item: any) =>
        item?.settingType == SanctionsSettingType.SanctionedDOCCompany
    );
    sanctions[index].settingValue = generalForm.getValues().sanctionedDOCCompany
      ? String(generalForm.getValues().sanctionedDOCCompany)
      : '';
    index = sanctions?.findIndex(
      (item: any) =>
        item?.settingType == SanctionsSettingType.SanctionedGroupOwner
    );
    sanctions[index].settingValue = generalForm.getValues().sanctionedGroupOwner
      ? String(generalForm.getValues().sanctionedGroupOwner)
      : '';
    index = sanctions?.findIndex(
      (item: any) =>
        item?.settingType == SanctionsSettingType.SanctionedOperator
    );
    sanctions[index].settingValue = generalForm.getValues().sanctionedOperator
      ? String(generalForm.getValues().sanctionedOperator)
      : '';
    index = sanctions?.findIndex(
      (item: any) =>
        item?.settingType == SanctionsSettingType.SanctionedRegisteredOwner
    );
    sanctions[index].settingValue = generalForm.getValues()
      .sanctionedRegisteredOwner
      ? String(generalForm.getValues().sanctionedRegisteredOwner)
      : '';
    index = sanctions?.findIndex(
      (item: any) =>
        item?.settingType == SanctionsSettingType.SanctionedShipManager
    );
    sanctions[index].settingValue = generalForm.getValues()
      .sanctionedShipManager
      ? String(generalForm.getValues().sanctionedShipManager)
      : '';
    index = sanctions?.findIndex(
      (item: any) =>
        item?.settingType == SanctionsSettingType.SanctionedTechnicalManager
    );
    sanctions[index].settingValue = generalForm.getValues()
      .sanctionedTechnicalManager
      ? String(generalForm.getValues().sanctionedTechnicalManager)
      : '';
    index = sanctions?.findIndex(
      (item: any) => item?.settingType == SanctionsSettingType.ApprovedPortList
    );
    sanctions[index].settingValue = generalForm.getValues().approvedPortList
      ? String(generalForm.getValues().approvedPortList)
      : '';
    index = sanctions?.findIndex(
      (item: any) =>
        item?.settingType == SanctionsSettingType.ApprovedTerminalList
    );
    sanctions[index].settingValue = generalForm.getValues().approvedTerminalList
      ? String(generalForm.getValues().approvedTerminalList)
      : '';
    index = sanctions?.findIndex(
      (item: any) => item?.settingType == SanctionsSettingType.ApprovedBerthList
    );
    sanctions[index].settingValue = generalForm.getValues().approvedBerthList
      ? String(generalForm.getValues().approvedBerthList)
      : '';
    saveConfigs();
  };

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmSaveChanges'),
    handleSubmit: () => {
      handleSaveClick();
      setShowConfirmSave(false);
      setSaveConfig(false);
    },
    handleClose: () => {
      setShowConfirmSave(false);
      setSaveConfig(false);
    },
  };

  const saveConfigs = async () => {
    if (buId) {
      await api
        .put(
          {
            url: apiConfig.configurations,
            params: {
              configArea: 'SanctionList',
              buId: buId,
              companyId: companyId,
            },
            data: sanctions,
          },
          setIsLoading
        )
        .then(() => {
          toast.success({
            title: t('toast.changesSavedSuccessfully'),
          });
        });
    } else {
      await api
        .put(
          {
            url: apiConfig.configurations,
            params: { configArea: 'SanctionList', companyId: companyId },
            data: sanctions,
          },
          setIsLoading
        )
        .then(() => {
          toast.success({
            title: t('toast.changesSavedSuccessfully'),
          });
        });
    }
  };

  return (
    <div className="m-l-form-panel-block">
      <div className="m-l-form-panel-header-block">
        <div className="m-l-form-panel-header-left-block">
          <div className="m-l-page-small-heading">
            {t('labels.restrictions&Approvals')}
          </div>
        </div>
        {!buId && (
          <div className="m-l-form-panel-header-right-block">
            {/* <Button
              className="app-c-btn app-c-btn--primary min-btn-width"
              aria-label=""
              onClick={handleSaveClick}
            >
              {t('buttons.save')}
            </Button> */}
          </div>
        )}
      </div>
      <div className="m-l-form-panel-body-block">
        <FormProvider {...generalForm}>
          <SanctionForm
            buId={buId}
            companyId={companyId}
            companyType={companyType}
            selectedCompanyType={selectedCompanyType}
            companyTypeFlag={companyTypeFlag}
          />
        </FormProvider>
      </div>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={showConfirmSave}
      />
    </div>
  );
}
