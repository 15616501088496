import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Entity } from '../../../enums/entity-enum';
import { IFormConfig } from '../../../types/form-builder';
import { IMasterEntity } from '../../../types/master-data';
import FormBuilder from '../../common/form-builder/FormBuilder';

export default function CountRecords(props: {
  getConditionValues: any;
  ruleConditionFormState: any;
  entities: IMasterEntity[];
}) {
  const { getConditionValues, ruleConditionFormState, entities } = props;
  const { t } = useTranslation();

  const { control } = useFormContext();

  const [showPSC, setShowPSC] = useState(false);

  const customInspectionFromValidation = (params: any) => {
    let isPSC = false;
    getConditionValues().groups.map((group: any) => {
      if (!isPSC) {
        isPSC = !!group.ruleConditions.find(
          (item: any) => item.entityName === Entity.PSC
        );
      }
    });
    return isPSC ? !!params.value : true;
  };

  const customIncidentFromValidation = (params: any) => {
    let isIncident = false;
    getConditionValues().groups.map((group: any) => {
      if (!isIncident) {
        isIncident = !!group.ruleConditions.find(
          (item: any) =>
            item.entityName === Entity.Incident ||
            item.entityName === Entity.IncidentHistory
        );
      }
    });

    return isIncident ? !!params.value : true;
  };

  useEffect(() => {
    let showCounts = false;
    getConditionValues()?.groups?.map((group: any) => {
      if (!showCounts) {
        showCounts = !!group.ruleConditions.find((item: any) => {
          const entity = entities?.find(
            (entity) => entity.masterEntityId === item.entityId
          );
          return entity?.entityName === Entity.PSC;
        });
      }
    });
    setShowPSC(showCounts);
  }, [ruleConditionFormState, getConditionValues, entities]);

  const formConfig: IFormConfig[] = [
    {
      class:
        'col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'incidentsFrom',
      label: t('labels.incidentsFromInMonths'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      isHidden: showPSC,
      rules: [
        {
          type: 'custom',
          message: t('errors.required'),
          reevaluate: true,
          validationCallback: customIncidentFromValidation,
        },
      ],
    },
    {
      class:
        'col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'pscFrom',
      label: t('labels.pscFromInMonths'),
      control: control,
      fieldType: 'number',
      isHidden: !showPSC,
      rules: [
        {
          type: 'custom',
          message: t('errors.required'),
          reevaluate: true,
          validationCallback: customInspectionFromValidation,
        },
      ],
    },
  ];

  return (
    <div className="m-l-sub-section">
      <div className="row m-l-rule-details-row u-mb0 m-l-custom-sixteen-space-row">
        <div className="col-xl-5 col-lg-3 col-md-12 col-sm-12 col-xs-12 m-l-custom-sixteen-space-col">
          <div className="row m-l-custom-sixteen-space-row">
            <FormBuilder formConfig={formConfig} />
          </div>
        </div>
      </div>
    </div>
  );
}
