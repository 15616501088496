import React from 'react';
import { useTranslation } from 'react-i18next';
import { IPortInfo } from '../../../../types/vessel-info-rules';

export default function RulePortDetails(props: {
  loadPorts: IPortInfo[] | undefined;
  dischargePorts: IPortInfo[] | undefined;
}) {
  const { t } = useTranslation();

  const { loadPorts, dischargePorts } = props;

  return (
    <div className="m-l-ldprt-dischrg-row">
      <div className="row m-l-custom-eight-space-row">
        {loadPorts && loadPorts.length > 0 && (
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 m-l-custom-eight-space-col">
            <div className="m-l-ldprt-part">
              <div className="m-l-form-panel-block m-l-card-form">
                <div className="m-l-form-panel-header-block">
                  <div className="m-l-form-panel-header-left-block">
                    <div className="m-l-page-small-heading">
                      {t('labels.loadPort')}
                    </div>
                  </div>
                </div>

                <div className="m-l-form-panel-body-block m-l-preview-fm">
                  {loadPorts?.map((item, index) => {
                    return (
                      <div key={index}>
                        {index != 0 && <hr className="m-c-divider" />}
                        <div className="row">
                          <div className="col-xl col-lg col-md-6 col-sm-6 col-xs-12">
                            <div className="m-c-form-group">
                              <label className="m-l-input-label">
                                {t('labels.port')} {index + 1}
                              </label>
                              <>
                                <div className="m-l-page-sub-heading mtop-4">
                                  {item.portName} ({item.countryName})
                                </div>
                                {/* {loadPort.portFlag != '' && (
                                    <div className="m-l-flag-icon">
                                      {' '}
                                      <img
                                        src={loadPort.portFlag}
                                        className="img-responsive"
                                        alt={loadPort.loadPortName}
                                      />
                                    </div>
                                  )} */}
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        {dischargePorts && dischargePorts.length > 0 && (
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 m-l-custom-eight-space-col">
            <div className="m-l-ldprt-part">
              <div className="m-l-form-panel-block m-l-card-form">
                <div className="m-l-form-panel-header-block">
                  <div className="m-l-form-panel-header-left-block">
                    <div className="m-l-page-small-heading">
                      {t('labels.dischargePort')}
                    </div>
                  </div>
                </div>

                <div className="m-l-form-panel-body-block m-l-preview-fm">
                  {dischargePorts?.map((item, index) => {
                    return (
                      <div key={index}>
                        {index != 0 && <hr className="m-c-divider" />}
                        <div className="row">
                          <div className="col-xl col-lg col-md-6 col-sm-6 col-xs-12">
                            <div className="m-c-form-group">
                              <label className="m-l-input-label">
                                {t('labels.port')} {index + 1}
                              </label>
                              <div className="m-l-page-sub-heading mtop-4">
                                {item.portName} ({item.countryName})
                              </div>
                              {/* {dischargePort.portFlag != '' && (
                                <div className="m-l-flag-icon">
                                  {' '}
                                  <img
                                    src={dischargePort.portFlag}
                                    className="img-responsive"
                                    alt={
                                      dischargePort.dischargePortName
                                    }
                                  />
                                </div>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
