import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormConfig } from '../../../../types/form-builder';
import FormBuilder from '../../../common/form-builder/FormBuilder';

export default function PortContactEditForm(props: {
  contactType: any;
  portContactRef: any;
}) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { contactType, portContactRef } = props;

  const formConfig: IFormConfig[] = [
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'contactType',
      label: 'Contact Type',
      control: control,
      fieldType: 'select',
      dataSource: contactType,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      isRequired: true,
      ref: portContactRef,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'name',
      label: t('labels.name'),
      control: control,
      fieldType: 'text',
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'address',
      label: 'Address',
      control: control,
      fieldType: 'text',
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'postalCode',
      label: 'Postal Code',
      control: control,
      fieldType: 'text',
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'email',
      label: 'Email',
      control: control,
      fieldType: 'text',
      rules: [
        {
          type: 'email',
          message: t('errors.inCorrectEmailFormat'),
        },
      ],
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'phoneNumber',
      label: t('labels.phoneNumber'),
      control: control,
      fieldType: 'text',
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'website',
      label: 'Website',
      control: control,
      fieldType: 'text',
    },
  ];

  return (
    <div className="row m-l-custom-sixteen-space-row">
      <FormBuilder formConfig={formConfig} />
    </div>
  );
}
