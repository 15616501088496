import React from 'react';
import Popup from 'devextreme-react/popup';
export default function Modal(props: any) {
  const { modalConfig, wrapperAttr } = props;
  return (
    <Popup
      width={modalConfig.width}
      height={modalConfig.height}
      visible={modalConfig.visible}
      showTitle={modalConfig.showTitle}
      closeOnOutsideClick={modalConfig.closeOnOutsideClick}
      wrapperAttr={wrapperAttr}
      minWidth={'340px'}
    >
      {props.children}
    </Popup>
  );
}
