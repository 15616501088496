import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormBuilder from '../../../../common/form-builder/FormBuilder';

export default function VesselFormGeneral(props: {
  vesselType: any;
  vesselStatus: any;
  pandiClub: any;
  countries: any;
  ports: any;
  sizeClass: any;
  hullType: any;
}) {
  const {
    vesselStatus,
    vesselType,
    pandiClub,
    countries,
    ports,
    sizeClass,
    hullType,
  } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();

  const generalFormConfig = [
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'vesselname',
      label: t('labels.vesselName'),
      control: control,
      fieldType: 'text',
      disabled: true,
      maxLength: 50,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'imo',
      label: t('labels.imoNumber'),
      control: control,
      fieldType: 'number',
      disabled: false,
      maxLength: 10,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'mmsinumber',
      label: t('labels.mmsiNumber'),
      control: control,
      fieldType: 'text',
      maxLength: 9,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'callsign',
      label: t('labels.callSignNo'),
      control: control,
      fieldType: 'text',
      maxLength: 13,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'vinnumber',
      label: t('labels.vinNumber'),
      control: control,
      fieldType: 'text',
      maxLength: 10,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'flag',
      label: t('labels.flag'),
      control: control,
      fieldType: 'select',
      dataSource: countries,
      displayExpr: 'countryName',
      valueExpr: 'countryCode',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'vesseltype',
      label: t('labels.vesselType'),
      control: control,
      fieldType: 'select',
      dataSource: vesselType,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'portofregistry',
      label: t('labels.portOfRegistry'),
      control: control,
      fieldType: 'select',
      dataSource: ports,
      displayExpr: 'portName',
      valueExpr: 'portName',
      maxLength: 50,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'servicespeed',
      label: t('labels.serviceSpeed'),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,12})([.][0-9]{0,2})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'vesselstatus',
      label: t('labels.vesselStatus'),
      control: control,
      fieldType: 'select',
      dataSource: vesselStatus,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'builtdate',
      label: t('labels.builtDate'),
      control: control,
      fieldType: 'date',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'hulltype',
      label: t('labels.hullType'),
      control: control,
      fieldType: 'select',
      dataSource: hullType,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueText',
      maxLength: 50,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'hullmaterial',
      label: t('labels.hullMaterial'),
      control: control,
      fieldType: 'text',
      maxLength: 50,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'decksnumber',
      label: t('labels.decksNumber'),
      control: control,
      fieldType: 'number',
      maxLength: 50,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'bulkheads',
      label: t('labels.bulkheads'),
      control: control,
      fieldType: 'number',
      maxLength: 7,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'sizeclass',
      label: t('labels.sizeClass'),
      control: control,
      fieldType: 'select',
      dataSource: sizeClass,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueText',
      maxLength: 50,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'pandiclub',
      label: t('labels.P&IClub'),
      control: control,
      fieldType: 'select',
      dataSource: pandiClub,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'handmprovider',
      label: t('labels.H&M'),
      control: control,
      fieldType: 'text',
      maxLength: 50,
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={generalFormConfig} />
      </div>
    </>
  );
}
