import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormBuilder from '../../../../common/form-builder/FormBuilder';

export default function VesselFormCapacity(props: {
  yesNo: any;
  fuel: any;
  fuelType: any;
}) {
  const { yesNo, fuel, fuelType } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();
  const items = [
    {
      id: 1,
      name: 'test',
    },
    {
      id: 2,
      name: 'test2',
    },
  ];
  const capacityFormConfig = [
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'grosstonnage',
      label: t('labels.grossTonnage'),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
      //   isRequired: true,
      //   rules: [
      //     {
      //       type: 'required',
      //       message: t('errors.required'),
      //     },
      //   ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'nettonnage',
      label: t('labels.netTonnage'),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'deadweight',
      label: t('labels.deadweight'),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'teu',
      label: t('labels.teu'),
      control: control,
      fieldType: 'number',
      maxLength: 7,
      rules: [
        {
          type: 'pattern',
          pattern: '^[0-9]*$',
          message: t('errors.wholeNumber'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'cargoholds',
      label: t('labels.cargoHolds'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'bale',
      label: t('labels.bale'),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'grain',
      label: t('labels.grain'),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'fuel',
      label: t('labels.fuel'),
      control: control,
      fieldType: 'select',
      dataSource: fuel,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'fueltype',
      label: t('labels.fuelType'),
      control: control,
      fieldType: 'select',
      dataSource: fuelType,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'ballastwatertreatmentsystem',
      label: t('labels.ballastWaterTreatmentSystem'),
      control: control,
      fieldType: 'select',
      dataSource: yesNo,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={capacityFormConfig} />
      </div>
    </>
  );
}
