import { useCallback, useContext } from 'react';
import { MasterDataContext } from '../contexts/MasterDataContext';
import { DimensionUnit } from '../enums/dimension-unit-enum';

export const useDimensionsUnit = () => {
  const { dimensionUnit, setDimensionUnit } = useContext(MasterDataContext);
  const conversionFactor = 3.28084;
  const feetToMeterConversion = useCallback(
    (value?: number) => {
      if (value && dimensionUnit === DimensionUnit.Feet) {
        return Number((value / conversionFactor).toFixed(3));
      }
      return value;
    },
    [dimensionUnit]
  );

  const meterToFeetConversion = useCallback(
    (value?: number) => {
      if (value && dimensionUnit === DimensionUnit.Feet) {
        return Number((value * conversionFactor).toFixed(3));
      }
      return value;
    },
    [dimensionUnit]
  );

  const dimensionUnitText = useCallback(() => {
    return dimensionUnit === DimensionUnit.Feet ? ' (ft)' : ' (m)';
  }, [dimensionUnit]);

  const calculateGridCellValue = useCallback(
    (data: any) => {
      if (data && dimensionUnit === DimensionUnit.Feet) {
        return (data / conversionFactor).toFixed(3);
      }
      return data;
    },
    [dimensionUnit]
  );

  const calculateGridFilterExpression = useCallback(
    (key: string, expression: string, data: any) => {
      if (data && dimensionUnit === DimensionUnit.Feet) {
        return [key, expression, (data * conversionFactor).toFixed(3)];
      }
      return [key, expression, data];
    },
    [dimensionUnit]
  );

  return {
    dimensionUnit,
    setDimensionUnit,
    feetToMeterConversion,
    dimensionUnitText,
    meterToFeetConversion,
    calculateGridCellValue,
    calculateGridFilterExpression,
  };
};
