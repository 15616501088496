import React from 'react';
import { useTranslation } from 'react-i18next';
import { IVetRequestTerminal } from '../../../../types/berthfit-execution';
import BerthAccordion from '../berth-accordion/BerthAccordion';
import BerthFitComments from '../berth-fit-comments/BerthFitComments';

export default function TerminalDetails(props: {
  terminal: IVetRequestTerminal;
  imoNumber?: string;
  buId: number;
  getPortList: any;
}) {
  const { terminal, imoNumber, buId, getPortList } = props;

  const { t } = useTranslation();

  return (
    <div className="m-l-accordion-inner-page-wrap">
      <BerthFitComments
        getPortList={getPortList}
        berthTerminalComment={terminal?.comment}
        berthTerminalStatus={terminal?.status}
        terminalId={terminal?.terminalId}
        berthFitId={terminal?.berthFitId}
        isOverrided={terminal?.isOverrided}
      />
      <div className="m-l-inner-accordion__with-left-icons-wrap">
        <div className="m-l-accordion-page-title-block">
          <h5>{t('labels.berths')}</h5>
        </div>
        <div className="m-l-accordion__main-section u-p0">
          <BerthAccordion
            getPortList={getPortList}
            berths={terminal?.berth}
            imoNumber={imoNumber}
            buId={buId}
            berthFitId={terminal?.berthFitId}
          />
        </div>
      </div>
    </div>
  );
}
