export const MarsApiConfig = {
  companyStatus: 'options/companystatus',
  personNameTitle: 'options/personnametitle',
  company: 'companies/',
  country: 'countries',
  buStatus: 'options/BUStatus',
  buPersonNameTitle: 'options/PersonNameTitle',
  createbusinessunits: 'businessunits',
  buIncoTerms: 'options/incoterms',
  companyBusinessUnits: 'businessunits/company/',
  getBuDetails: 'businessunits/',
  roles: 'roles/',
  modules: 'modules',
  companyRoles: 'company-roles/',
  getCompanyRoles: 'company-roles/company/',
  businessUnitsClone: 'businessunits/clone',
  employees: 'employees/',
  employeeStatus: 'options/employeeStatus',
  activeInactiveRole: 'roles/status',
  roleMappingUserCheck: 'employees/roles/',
  employeeList: 'employees/company/',
  checkCompanyRoleMapping: 'company-roles/company/%s/role/%s',
  employeeApprove: 'employees/approve',
  employeeSendForApprove: 'employees/approval-request',
  companyTypes: 'options/companyType',
  activeInactiveEmployee: 'employees/status',
  companyInvitation: 'companies/invitation',
  vesselGlobalSearch: 'vessels/global-search/',
  classSociety: 'options/ClassificationSocietyCode',
  vesselStatus: 'options/statusCodes',
  vesselClassStatus: 'options/ClassStatus',
  masterEntities: 'masters/entities',
  entityAttributes: 'masters/entities/%s/attributes',
  optionValue: 'options/',
  canUserEnterOptions: 'options/canuserenter',
  relation: 'options/relation',
  optionVesselType: 'options/ShipTypeLevel5',
  advancedSearch: 'vessels/advanced-search',
  rules: 'rules/',
  notifications: 'notifications',
  rulesList: 'rules',
  ruleStatus: '/rules/status',
  login: '/users/login',
  attributes: 'masters/attributes',
  attributesByEnventId: 'masters/attributes/entity/',
  ruleConditions: 'options/rule-conditions',
  vessels: 'vessels/',
  cloneRule: '/rules/clone',
  notificationsReadUnread: 'notifications/mark-as-read-unread',
  list: '/lists',
  listById: 'lists/',
  listEntries: 'lists/entries',
  listEntriesById: '/entries/',
  editEntries: '/entries',
  switchBu: 'businessunits/default',
  configurations: '/configurations',
  changePassword: 'users/reset-password',
  marsAccountManager: 'employees/company/1',
  forgotPassword: 'users/forgot-password',
  vesselInformationGenTab: 'vessels/general/',
  vesselManagementRoles: 'vessels/management-roles/',
  attachments: 'attachments/',
  copyRule: 'rules/copy',
  vesselNames: 'vessels/history/',
  vesselCapacityTab: 'vessels/capacity/',
  vesselDimensionTab: 'vessels/dimensions/',
  cargoType: 'options/cargotype',
  fuel: 'options/fuel',
  fuelType: 'options/fuelType',
  scrubberType: 'options/scrubberType',
  cargo: 'options/cargo',
  trueFalse: 'options/TrueFalse',
  hullType: 'options/HullType',
  sizeClass: 'options/sizeClass',
  survey: 'options/survey',
  capRating: 'options/CAPRating',
  ports: '/ports',
  terminals: '/terminals',
  vetRequests: 'vet-requests/',
  classificationStatus: 'options/classStatus',
  vesselTypes: 'options/shiptypelevel5',
  engineTab: 'vessels/engine/',
  vesselClassificationTab: 'vessels/classification/',
  notificationsArchive: 'notifications/archive',
  incidents: 'vessels/incidents/',
  addIncidents: 'vessels/incident',
  eventTypeCodes: 'options/EventTypeCodes',
  eventTypeDetailCodes: 'options/EventTypeDetailCodes',
  significanceCodes: 'options/SignificanceCodes',
  loadStatusCodes: 'options/LoadStatusCodes',
  injuryTypeCodes: 'options/InjuryTypeCodes',
  rootCauseCodes: 'options/IncidentRootCauseCodes',
  incidentActionsCodes: 'options/IncidentActionsCodes',
  casualityZoneCodes: 'options/CasualityZoneCodes',
  incidentStatus: 'options/incidentStatus',
  pscStatus: 'options/PSCinspectionStatus',
  authority: 'options/pscmou',
  pandiClub: 'options/PandIClubCode',
  pscInspectionType: 'options/pscinspectiontype',
  defectCode: 'options/defectcode',
  rootCause: 'options/PSCRootCauseCode',
  subArea: 'options/subarea',
  action: 'options/PSCActionCode',
  riskArea: 'options/riskarea',
  pscInspectionStatus: 'options/pscinspectionstatus',
  incidentById: 'vessels/incident/',
  equipmentTab: 'vessels/equipment/',
  vesselNameDetails: 'vessels/vessel/%s/history/%s',
  updateVesselName: 'vessels/history/vessel-name',
  saveRules: 'rules',
  securityFunctions: 'users/%s/features',
  pscInspections: 'vessels/pscinspections/',
  pscInspection: 'vessels/pscinspection/',
  yardTab: 'vessels/yard/',
  listVetRequests: 'vet-requests/list/bu/',
  stateOfIncident: 'options/StateOfIncident',
  stateOfPSCInspection: 'options/StateOfPSCInspection',
  pscInspectionDetails: 'vessels/pscinspection/',
  dateFormat: 'configurations?settingArea=Global',
  vettingRuleList: 'vet-requests/rules/',
  vesselManagementRole: 'vessels/management-role/',
  vettingRuleDetails: 'vet-requests/%s/rule-details/%s',
  listWithEntityAttribute: 'lists/%s/%s',
  ruleExecute: 'rule-engine/execute/',
  vetRequestNotes: 'vet-requests/notes',
  vetRequestGetNotes: 'vet-requests/notes-type/%s',
  vesselAllManagementRoles: 'vessels/all-management-roles/',
  vesselManagementRoleDetails: 'vessels/vessel/%s/management-role/%s/code/%s',
  vettingRuleAdditionalInfoDetails:
    'vet-requests/rule-details/additional-info/',
  vetRequestRuleValues: 'vet-requests/%s/rule-values/%s',
  pscInspectionAccept: 'vessels/pscinspections-accept/',
  activePorts: 'ports/active-ports',
  vesselManagementRolesHistory: 'vessels/management-roles-history/',
  vesselManagementHistorySingle:
    'vessels/vessel/%s/management-role/%s/history/%s',
  vesselManagementCompanyCode: 'vessel-managements/management-role/%s/code/%s',
  vesselManagementRoleChange: 'vessels/change/management-role-details/',
  rfiTemplate: 'rules/rfi-template',
  ruleRFITemplateTypes: 'options/RuleRFITemplateTypes',
  getRfiTemplate: '/rules/%s/rfi-template/type/%s',
  vesselAttachments: 'vessels/attachments/',
  attachmentTypes: 'options/attachmentTypes',
  changeAttachmentType: 'vessels/attachment/%s/attachment-type/',
  vetRequestAssign: 'vet-requests/%s/assign/',
  vetRequestRecommend: 'recommend/',
  recommendEmailTemplate: 'vet-requests/%s/recommend/emailTemplate',
  vetRequestCancel: 'vet-requests/%s/cancel/',
  deleteEditAttachment: 'attachments/',
  vetRequestAttachment: 'vet-requests/%s/attachments/',
  vetRequestChangeAttachmentType:
    'vet-requests/%s/attachment/%s/attachment-type',
  optionTypes: 'options/option-types',
  optionValues: 'options/details/%s',
  appealReviewers: 'employees/bu/%s/appeal-reviewer',
  appealVetRequest: 'vet-requests/%s/appeal',
  vetRfiEmail: '/vet-requests/%s/rfi-email/type/%s',
  vetAppealHistory: '/vet-requests/%s/imo/%s/appealHistory',
  optionValueAddEdit: 'options/%s/option-value',
  optionsSupportingLanguages: '/options/details/SupportingLanguages',
  vetRequestMacros: '/vet-requests/%s/rfi-email',
  vetRequestComments: 'vet-requests/%s/recommend/',
  vetRequestAppealApprove: 'vet-requests/%s/appeal-approve/',
  vetRequestMailList: 'vet-requests/%s/mail-list',
  VetRequestById: 'vet-requests/',
  vetRelatedInfo: 'vessels/vetrelatedinfo/',
  vetEmailTypes: 'options/VetEmailTypes',
  vetRequestShipDetails: 'vet-requests/%s/vesselinfo',
  combineRfiEmail: '/vet-requests/%s/combine-rfi-email/%s',
  buLists: '/lists',
  vetRequestStatus: 'options/VetRequestStatus',
  excelUploadToList: 'lists/%s/entries/upload',
  vetOutboxEmailList: 'vet-requests/%s/outbox-mails',
  vetInboxEmailList: 'vet-requests/%s/incoming-mails',
  vetOutboxEmailById: 'vet-requests/outbox-mail/%s',
  vetInboxEmailById: 'vet-requests/incoming-mail/%s',
  outboxEmailList: 'vet-requests/company/outbox-mails',
  inboxEmailList: 'vet-requests/company/inbox-mails',
  contract: 'companies/contract',
  cloneBuList: '/lists/clone',
  contractInformation: 'companies/%s/contracts',
  deleteRfiEmail: '/vet-requests/%s/rfi-email/%s',
  replyEmail: '/vet-requests/reply-mails',
  searchVetRequestOutboxEmails: 'vet-requests/%s/search-outbox-mails',
  searchVetRequestInboxEmails: 'vet-requests/%s/search-inbox-mails',
  contractById: 'companies/%s/contract/%s',
  copyBuList: 'lists/copy',
  listsStatus: '/lists/status',
  buRuleTypes: 'options/RuleType',
  attachmentsSettings: 'configurations',
  shipTypeLevel3: 'options/shiptypelevel3',
  berthFitCheckVessel: 'berth-fits/vessel',
  berthFitParamListGlobal: 'berth-fits/config',
  berthFitParamList: 'berth-fits/config?bUId=%s',
  berthFitInfoGlobal: 'berth-fits/info/',
  berthFitInfo: 'berth-fits/info/%s?bUId=%s',
  berthFitInfoTypeDropdown: 'options/details/BerthInfoType',
  berthExecutionPortList: 'vetrequest-berth/ports/',
  berthFitHistory: '/berth-fits/vessel/%s/history',
  berthExecutionTerminalList: 'vetrequest-berth/terminals',
  viewPort: 'ports/',
  berthPost: '/berth',
  viewBerth: 'berth-fits/',
  viewTerminal: 'terminals/',
  searchOutboxEmails: 'vet-requests/search-outbox-mails',
  searchInboxEmails: 'vet-requests/search-inbox-mails',
  vetRequestBerthParameters: 'vetrequest-berth/',
  dashboard: 'dashboard',
  gadgetsTimePeriod: 'options/GadgetsTimePeriod',
  vetByStatus: 'dashboard/vets-by-status',
  berthFitCheckStatus: 'options/details/BerthFitCheckStatus',
  getBerthfitDetails: '/berth-fits/%s/vessel/%s',
  berthFitCheckStatusOptions: '/options/BerthFitCheckStatus?isLazyLoaded=false',
  berthFitExecution: 'rule-engine/execute-berth',
  berthFitOverrideResult: 'vetrequest-berth/override-result',
  berthExecutionStatus: 'options/berthFitCheckStatus',
  vetsByCompany: '/dashboard/vets-by-company',
  vetsByRequestor: '/dashboard/vets-by-requestor',
  topReviewers: '/dashboard/top-reviewers',
  sanctionsList: '/lists/lists/entity/%s/attribute/%s',
  createVessel: '/vessels/create',
  executeBerth: 'rule-engine/execute-berth',
  berthOverrideResult: 'vetrequest-berth/override-result',
  yesNo: 'options/details/YesNo',
  turnAroundByCompany: 'dashboard/turn-around-by-company',
  berthFitsGadget: 'dashboard/berth-fits',
  myRecentvetRequest: 'dashboard/my-recent-vet-requests',
  recentVessels: 'dashboard/RecentVessels',
  vetTurnAroundTimeTrend: 'dashboard/vet-turnaround-time-trend',
  rfiMailGadget: 'dashboard/rfi-Mail',
  vetStatusAvgTime: 'dashboard/vet-status-average-time',
  vetRecommendationRuleResults: 'vet-requests/%s/recommendations',
  vesselsWarning: 'vessels/warning/',
  dashboardList: '/dashboard/user-dashboard',
  dashboardDisplayOrder: 'dashboard/display-order',
  userProfile: 'employees/employee-profile',
  gadgetDeleteRestore: '/dashboard',
  changeDashboardDisplayOrder: '/dashboard/display-order',
  vetRecommendationRuleSuccessValidation:
    'vet-requests/validate-recommendation/',
  reportDesigner: 'designer',
  reportViewer: 'viewer',
  vetRequestRuleIncident: 'vet-requests/%s/rule-details/%s/incident',
  vetRequestRulePsc: 'vet-requests/%s/rule-details/%s/psc_inspection',
  vetRequestUsedIndication: 'vet-requests/%s/used-indication',
  vetReport: 'vet-requests/recomment-report/',
  reportLayouts: 'layouts/header/',
  reportFavorites: 'favourites/header/',
  reportHeaders: 'report-headers',
  filters: 'filters/',
  companyLogo: 'companies/%s/logo',
  deleteTempVetData: '/vet-requests/',
  viewer: 'viewer/%s/%s/%s',
  berthfitReport: '/vetrequest-berth/berthFit-report',
  favourites: 'favourites/',
  reportFavoritesNameExist: 'favourites/%s/isfavouriteexist',
  duplicateFavorite: 'favourites/duplicate/',
  duplicateLayout: 'layouts/duplicate/',
  reportLayoutNameExist: '/layouts/%s/islayoutexist',
  layouts: 'layouts/',
  berthFitAttachments: 'berth-fits/%s/attachments',
  rulesManagementList: 'vet-requests/%s/rule-details/%s/management',
  rulesManagementHistoryList:
    'vet-requests/%s/rule-details/%s/management-history',
  rulePorts: 'vet-requests/%s/rule-details/%s/ports',
  portWarning: 'vet-requests/port/%s/sanction-list-warning',
  portSearch: 'ports/search',
  addToListEntries: 'lists/list-entries',
  getPortInfo: '/ports/port-info/',
  getTerminalInfo: '/terminals/terminal-info/',
  getExternalInterfaces: '/external-interfaces',
  getExternalInterfacesExectionHistory:
    '/external-interfaces/execution-history',
  getExternalInterfacesFailHistory:
    '/external-interfaces/execution-failed-history/',
  finalizeBerthFitCheck: 'berth-fits/finalize',
  externalInterfacesReRun: '/external-interfaces/',
  getPortNotes: '/options/details/PortNotesType',
  getPortContacts: '/options/details/PortContactType',
  portNote: 'port-notes/',
  getAllPortNotes: 'port-notes?portCode=',
  addPortContact: 'port-contacts/',
  getAllPortContacts: 'port-contacts?portCode=',
  getPortContactById: 'port-contacts/portcontactId?portContactId=',
  berthType: 'options/BerthType',
  shipType: 'options/WOPShipType',
  berthStatus: 'options/BerthStatus',
  berthShipHandled: '/berth-ship-type',
  vesselHandled: 'terminals/vessels-handled/',
  getShipType: 'options/details/WOPShiptype',
  berthVesselRestrictions: '/berth-vessel-restrictions',
  getShipFuelType: 'options/details/FuelType',
  berthCargo: '/berth-cargo/',
  berthLoadDischarge: '/berth-load-and-disrate/',
  scheduler: 'scheduler/%s/%s',
  portAttachment: 'ports/attachments',
  terminalAttachment: 'terminals/attachments',
  uscgVetInfo: 'scheduler/uscg-vet-info/',
  uscgDimensions: 'scheduler/uscg-dimensions/',
  uscgPSC: 'scheduler/uscg-psc-inspections/',
};
