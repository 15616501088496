import { MarsApiService as api } from '../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../api/mars-api-config';
import { getBuList } from '../utils/jwt-decode';

const masterDataCacheMap: Map<string, Promise<any[]>> = new Map();

export const getEntities = (): Promise<any> | undefined => {
  if (!masterDataCacheMap.has('entity')) {
    masterDataCacheMap.set(
      'entity',
      api.get({ url: apiConfig.masterEntities })
    );
  }
  return masterDataCacheMap.get('entity');
};

export const getAttributes = (): Promise<any> | undefined => {
  if (!masterDataCacheMap.has('attribute')) {
    masterDataCacheMap.set('attribute', api.get({ url: apiConfig.attributes }));
  }
  return masterDataCacheMap.get('attribute');
};

export const getOPtionValueByType = (
  type: string
): Promise<any> | undefined => {
  if (!masterDataCacheMap.has(type)) {
    masterDataCacheMap.set(
      type,
      api.get({ url: apiConfig.optionValue + type })
    );
  }
  return masterDataCacheMap.get(type);
};

export const getUserProfile = (): Promise<any> | undefined => {
  if (!masterDataCacheMap.has('profile')) {
    const buList = JSON.parse(getBuList() as string);
    const defaultBu = buList.find((bu: any) => bu.isDefault == true);
    masterDataCacheMap.set(
      'profile',
      api.get({ url: apiConfig.userProfile, params: { buId: defaultBu?.buId } })
    );
  }
  return masterDataCacheMap.get('profile');
};

export const getTerminals = (): Promise<any> | undefined => {
  if (!masterDataCacheMap.has('terminals')) {
    masterDataCacheMap.set('terminals', api.get({ url: apiConfig.terminals }));
  }
  return masterDataCacheMap.get('terminals');
};

export const removeKey = (type: string) => {
  masterDataCacheMap.delete(type);
};

export const removeAllCacheData = () => {
  masterDataCacheMap.clear();
};
