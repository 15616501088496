import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Export,
  Format,
  Label,
  Legend,
  MinorTick,
  Series,
  Tick,
  TickInterval,
  Tooltip,
  ValueAxis,
} from 'devextreme-react/chart';
import React from 'react';

export default function BarChart(props: {
  dataSource: any;
  chartConfig: any;
  chartRef?: any;
}) {
  const { dataSource, chartConfig, chartRef } = props;
  const toolTipRender = (e: any) => {
    return {
      text: `${e.value}`,
    };
  };
  return (
    <>
      <Chart
        id="chart"
        dataSource={dataSource}
        rotated={chartConfig.rotated}
        ref={chartRef}
        argumentAxis={chartConfig.argumentAxis}
      >
        <CommonSeriesSettings
          argumentField={chartConfig.argumentField}
          type={chartConfig.type}
          barPadding={chartConfig.barPadding}
        >
          <Label visible={chartConfig.showLabel}>
            {/* <Format type="fixedPoint" precision={0} /> */}
          </Label>
        </CommonSeriesSettings>
        <Export enabled={false} />
        <Series
          argumentField={chartConfig.argumentField}
          valueField={chartConfig.valueField1}
          name={chartConfig.name1}
          color={
            chartConfig.series1Color ? chartConfig.series1Color : '#060957'
          }
        />
        {chartConfig.name2 && (
          <Series
            argumentField={chartConfig.argumentField}
            valueField={chartConfig.valueField2}
            name={chartConfig.name2}
            color={
              chartConfig.series2Color ? chartConfig.series2Color : '#3138e8'
            }
          />
        )}
        <ValueAxis position="left">{/* <Title text="millions" /> */}</ValueAxis>
        <Legend
          verticalAlignment="bottom"
          horizontalAlignment="center"
          itemTextPosition="top"
        />
        <Tooltip
          enabled={true}
          location="edge"
          customizeTooltip={(e: any) => toolTipRender(e)}
        />
      </Chart>
    </>
  );
}
