import Button from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { IGridConfig } from '../../../types/grid';
import { IModalConfig } from '../../../types/modal';
import FileUploaderModal from '../../common/file-uploader-modal/FileUploaderModal';
import Grid from '../../common/grid/Grid';
import icons from '../../common/icons/icons';
import Modal from '../../common/modal/Modal';

export default function ExcelUpload(props: {
  isVisible: boolean;
  handleCancelClick: any;
  listId: any;
  getListById: any;
}) {
  const { isVisible, handleCancelClick, listId, getListById } = props;
  const { t } = useTranslation();
  const statusCheck = [
    { name: t('labels.pass'), value: 'Pass' },
    { name: t('labels.fail'), value: 'Fail' },
  ];
  const modalConfig2: IModalConfig = {
    width: 1080,
    height: 560,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const [showUploadError, setShowUploadError] = useState<boolean>(false);
  const [uploadedFilesError, setUploadedFilesError] = useState<any>([]);
  const { formatDate, dateFormat } = useDateFormat();
  const popupAttributes2 = useMemo(() => {
    return {
      id: 'elementId',
      class:
        'm-l-modal-main__wrap m-l-add-new-modal m-l-modal-excel-upload-grid',
    };
  }, []);

  function dateTimeCellTemplate(data: any) {
    if (data.value && data.value != '') {
      const formatedDate = formatDate(data?.value);
      return <div className="m-l-badge-container">{formatedDate}</div>;
    }
  }

  const gridConfig: IGridConfig = {
    dataSource: uploadedFilesError,
    defaultColumns: [
      {
        caption: t('labels.row'),
        dataField: 'rowNumber',
        dataType: 'string',
        minWidth: 100,
        width: '10%',
      },
      {
        caption: t('labels.pass?'),
        dataField: 'status',
        dataType: 'string',
        width: '15%',
        cellTemplate: 'cellTemplate',
        minWidth: 100,
        lookup: {
          dataSource: statusCheck,
          valueExpr: 'value',
          displayExpr: 'name',
        },
        sortOrder: 'asc',
      },
      {
        caption: t('labels.error'),
        dataField: 'error',
        dataType: 'string',
        minWidth: 150,
        width: '30%',
      },
      {
        caption: t('labels.entryName'),
        dataField: 'name',
        dataType: 'string',
        minWidth: 150,
        width: '30%',
      },
      {
        caption: t('labels.reason'),
        dataField: 'reason',
        dataType: 'string',
        minWidth: 150,
        width: '30%',
      },
      {
        caption: t('labels.enteredDate'),
        dataField: 'enteredDate',
        dataType: 'date',
        minWidth: 150,
        width: '30%',
        format: dateFormat,
      },
      {
        caption: t('labels.validTillDate'),
        dataField: 'validTillDate',
        dataType: 'date',
        minWidth: 150,
        width: '30%',
        format: dateFormat,
      },
      {
        caption: t('labels.removedDate'),
        dataField: 'removedDate',
        dataType: 'date',
        minWidth: 150,
        width: '30%',
        format: dateFormat,
      },
    ],
    hidePagination: false,
    showSelection: false,
    hideFilterRow: false,
    class: 'm-c-grid',
  };

  const cellTemplate = (data: any) => {
    if (data.value === 'Pass') {
      return (
        <div className="m-l-icon-btn-holder">
          <icons.circleTickDanger />
        </div>
      );
    } else {
      return (
        <div className="m-l-icon-btn-holder">
          <icons.circleCrossDanger />
        </div>
      );
    }
  };

  const handleCrossClick = () => {
    setShowUploadError(false);
  };

  return (
    <div>
      <FileUploaderModal
        handleCancelClick={handleCancelClick}
        isVisible={isVisible}
        listId={listId}
        setShowUploadError={setShowUploadError}
        setUploadedFilesError={setUploadedFilesError}
        getListById={getListById}
      />
      {showUploadError && (
        <Modal modalConfig={modalConfig2} wrapperAttr={popupAttributes2}>
          <div className="m-l-modal__header">
            <h2 className="modal-title"> {t('headers.excelUploadFail')}</h2>
            <div className="m-l-modal-close-button-block">
              <Button
                className="app-c-btn app-c-icon-only-btn"
                onClick={handleCrossClick}
              >
                <icons.IconCross />
              </Button>
            </div>
          </div>
          <div className="m-l-modal__body">
            <ScrollView width="100%" height="100%">
              {/* <div className="m-l-grid-wrapper-block"> */}
              <Grid
                gridConfig={gridConfig}
                cellTemplate={cellTemplate}
                actionTemplate={dateTimeCellTemplate}
              />
              {/* </div> */}
            </ScrollView>
          </div>
          <div className="m-l-modal__footer">
            <Button
              text={t('buttons.ok')}
              type="default"
              stylingMode="outlined"
              onClick={handleCrossClick}
              className="app-c-btn app-c-btn--primary"
            />
          </div>
        </Modal>
      )}
    </div>
  );
}
