import Button from 'devextreme-react/button';
import React, { useEffect, useState } from 'react';
import icons from '../../../common/icons/icons';
import CapacityEdit from './VesselCapacityEdit';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { useTranslation } from 'react-i18next';
import { IOptionvalue } from '../../../../types/master-data';
import { Restricted } from '../../../../contexts/PermissionContext';
export default function ViewCapacity(props: { imoNumber?: string }) {
  const [isCapacityEdit, setIsCapacityEdit] = useState(false);
  const [capacityList, setCapacityList] = useState<any>([]);
  const [yesNo, setYesNo] = useState<IOptionvalue[]>([]);
  const [fuel, setFuel] = useState<IOptionvalue[]>([]);
  const [fuelType, setFuelType] = useState<IOptionvalue[]>([]);
  const { imoNumber } = props;
  const { t } = useTranslation();
  const [flag, setFlag] = useState<any>([]);
  const showCapacityEdit = () => {
    setIsCapacityEdit(!isCapacityEdit);
  };

  const getCapacity = async () => {
    await api
      .get({
        url: apiConfig.vesselCapacityTab + imoNumber,
      })
      .then((response: any) => {
        setCapacityList(response);
        if (response.ballastWaterTreatmentSystem == true) {
          setFlag('Yes');
        } else {
          setFlag('No');
        }
      });
  };

  const getYesNo = async () => {
    await api
      .get({
        url: apiConfig.trueFalse,
      })
      .then((res: IOptionvalue[]) => {
        setYesNo(res);
      });
  };
  const getFuel = async () => {
    await api
      .get({
        url: apiConfig.fuel,
      })
      .then((res: IOptionvalue[]) => {
        setFuel(res);
      });
  };
  const getFuelType = async () => {
    await api
      .get({
        url: apiConfig.fuelType,
      })
      .then((res: IOptionvalue[]) => {
        setFuelType(res);
      });
  };

  useEffect(() => {
    getYesNo();
    getFuel();
    getFuelType();
  }, []);

  useEffect(() => {
    if (imoNumber) {
      getCapacity();
    }
  }, []);

  return (
    <span>
      <div className="m-l-inner-page-body">
        {/* <div className="m-l-expand-btn-wrap m-l-edit-view-mode">
                                    <Button className="app-c-btn app-c-icon-only-btn">
                                      <icons.IconPencil />
                                    </Button>
                                  </div> */}
        {!isCapacityEdit ? (
          <div className="m-l-form-panel-block">
            <div className="m-l-tbl-square-wrapper">
              <div className="m-l-ads-table-block">
                <div className="m-l-ads-table-right-part">
                  <div className="row m-l-ads-table-row m-l-ads-table--inner-row">
                    <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 m-l-custom-sixteen-space-col-wrap">
                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.grossTonnage')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {capacityList.grossTonnage}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.netTonnage')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {capacityList.netTonnage}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.deadweight')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {capacityList.deadweight}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.teu')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {capacityList.teu}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.cargoHolds')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {capacityList.cargoHold}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.bale')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {capacityList.baleCapacity}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.grain')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {capacityList.grainCapacity}
                          </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.fuel')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {capacityList.fuel}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.fuelType')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {capacityList.fuelType}
                          </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.ballastWaterTreatmentSystem')}
                          </div>
                          <div className="m-l-ads-tbl-desc">{flag}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                      <div className="m-l-expand-btn-wrap m-l-edit-view-mode">
                        <Restricted permission="Ship.EditCapacity">
                          <Button
                            className="app-c-btn app-c-icon-only-btn"
                            onClick={showCapacityEdit}
                          >
                            <icons.IconPencil />
                          </Button>
                        </Restricted>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <CapacityEdit
            imoNumber={imoNumber}
            setIsCapacityEdit={setIsCapacityEdit}
            capacityList={capacityList}
            getCapacity={getCapacity}
            yesNo={yesNo}
            fuel={fuel}
            fuelType={fuelType}
          />
        )}
      </div>
    </span>
  );
}
