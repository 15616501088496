import React, { useEffect, useState } from 'react';
import { IGridConfig } from '../../../types/grid';
import Grid from '../../common/grid/Grid';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { useTranslation } from 'react-i18next';
import { useDateFormat } from '../../../hooks/useDateFormat';
import imageURL from '../../../assets/images';
import icons from '../../common/icons/icons';
import Tooltip from 'devextreme-react/tooltip';
import { Button } from 'devextreme-react/button';
import AddAttachment from './AddAttachment';
import toast from '../../../utils/toast';
import FileSaver from 'file-saver';
import ConfirmDialog from '../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../types/dialog';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { Restricted } from '../../../contexts/PermissionContext';

export default function Attachments(props: {
  isFromViewInfoModal?: boolean;
  portCode?: any;
  terminalId?: any;
}) {
  const { isFromViewInfoModal, portCode, terminalId } = props;
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmRestore, setShowConfirmRestore] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [attachments, setAttachments] = useState<any>();
  const { t } = useTranslation();
  const { apiDateAndTimeFormat } = useDateFormat();
  const [attachmentId, setAttachmentId] = useState();
  const { setIsLoading } = useLoaderContext();

  const actionList = [
    { isDeleted: true, optionValue: 'Deleted' },
    { isDeleted: false, optionValue: 'Not Deleted' },
  ];

  const actionTemplate = (e: any) => {
    const attachmentTooltipId = 'ToolTip' + String(e.data.attachmentId);
    return (
      <React.Fragment>
        <div className="m-l-grid-delete-icon-wrapper left-align">
          {e.data.isDeleted ? (
            <Restricted permission={'PortManagement.RestoreAttachment'}>
              <div id={attachmentTooltipId}>
                <icons.ArchiveIcon />
                <Tooltip
                  target={`#${attachmentTooltipId}`}
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  position="right"
                  contentRender={() => {
                    return (
                      <div className="m-l-tooltip-wrapper">
                        {t('labels.restore')}
                      </div>
                    );
                  }}
                />
              </div>
            </Restricted>
          ) : (
            <Restricted permission={'PortManagement.DeleteAttachment'}>
              <div id={attachmentTooltipId}>
                <icons.IconDelete />
                <Tooltip
                  target={`#${attachmentTooltipId}`}
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  position="right"
                  contentRender={() => {
                    return (
                      <div className="m-l-tooltip-wrapper">
                        {t('labels.delete')}
                      </div>
                    );
                  }}
                />
              </div>
            </Restricted>
          )}
        </div>
      </React.Fragment>
    );
  };

  const actionTemplate1 = (e: any) => {
    if (e.value == 'PNG') {
      return (
        <div
          className="m-l-file-icon-wrapper"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div className="m-l-user-image-block">
            <div className="m-l-avatar-block">
              <img src={imageURL.png} className="img-fluid" alt="Logo" />
            </div>
          </div>
        </div>
      );
    } else if (e.value == 'PDF') {
      return (
        <div
          className="m-l-file-icon-wrapper"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div className="m-l-user-image-block">
            <div className="m-l-avatar-block">
              <img src={imageURL.pdf} className="img-fluid" alt="Logo" />
            </div>
          </div>
        </div>
      );
    } else if (e.value == 'DOC') {
      return (
        <div
          className="m-l-file-icon-wrapper"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div className="m-l-user-image-block">
            <div className="m-l-avatar-block">
              <img src={imageURL.doc} className="img-fluid" alt="Logo" />
            </div>
          </div>
        </div>
      );
    } else if (e.value == 'XLS') {
      return (
        <div
          className="m-l-file-icon-wrapper"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div className="m-l-user-image-block">
            <div className="m-l-avatar-block">
              <img src={imageURL.xls} className="img-fluid" alt="Logo" />
            </div>
          </div>
        </div>
      );
    } else if (e.value == 'JPEG') {
      return (
        <div
          className="m-l-file-icon-wrapper"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div className="m-l-user-image-block">
            <div className="m-l-avatar-block">
              <img src={imageURL.jpeg} className="img-fluid" alt="Logo" />
            </div>
          </div>
        </div>
      );
    } else if (e.value == 'JPG') {
      return (
        <div
          className="m-l-file-icon-wrapper"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div className="m-l-user-image-block">
            <div className="m-l-avatar-block">
              <img src={imageURL.jpg} className="img-fluid" alt="Logo" />
            </div>
          </div>
        </div>
      );
    } else if (e.value == 'DOCX') {
      return (
        <div
          className="m-l-file-icon-wrapper"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div className="m-l-user-image-block">
            <div className="m-l-avatar-block">
              <img src={imageURL.docx} className="img-fluid" alt="Logo" />
            </div>
          </div>
        </div>
      );
    } else if (e.value == 'XLSX') {
      return (
        <div
          className="m-l-file-icon-wrapper"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div className="m-l-user-image-block">
            <div className="m-l-avatar-block">
              <img src={imageURL.xlsx} className="img-fluid" alt="Logo" />
            </div>
          </div>
        </div>
      );
    }
  };

  const rowPrepared = (e: any) => {
    if (e?.data?.isDeleted === true) {
      e.rowElement.className =
        'dx-row dx-data-row dx-column-lines grid-row-disabled';
    }
  };

  const onRowClick = (e: any) => {
    if (e.event?.target?.cellIndex === 5 && !e.data?.isDeleted) {
      return;
    }
    if (e.event.target.cellIndex || e.event.target.cellIndex == 0) {
      if (e.data.isDeleted) {
        toast.error({
          title: 'Error',
          message: t('errors.deletedAttachment'),
        });
      } else {
        api
          .get({
            url: apiConfig.deleteEditAttachment + e.data.attachmentId,
            responseType: 'blob',
          })
          .then((res) => {
            FileSaver.saveAs(new Blob([res]), e.data.attachmentName);
          });
      }
    } else if (e.event.target.nodeName == 'path') {
      if (e.data.isDeleted) {
        setAttachmentId(e.data.attachmentId);
        setShowConfirmRestore(true);
      } else {
        setAttachmentId(e.data.attachmentId);
        setShowConfirmDelete(true);
      }
    }
  };

  const gridConfig: IGridConfig = {
    class: 'm-c-grid m-l-grid-with--top-border m-c-righticon-grid',
    testId: 'vesselNameGrid',
    height: '350',
    dataSource: attachments,
    rowPrepared: rowPrepared,
    rowClick: onRowClick,
    defaultColumns: [
      {
        caption: t('labels.attachmentName'),
        dataField: 'attachmentName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.uploadedBy'),
        dataField: 'uploadedBy',
        dataType: 'string',
        minWidth: 150,
        width: '20%',
      },
      {
        caption: t('labels.uploadedOn'),
        dataField: 'uploadedOn',
        dataType: 'date',
        minWidth: 150,
        width: '20%',
        cellTemplate: 'statusTemplate',
      },
      {
        caption: t('labels.size'),
        dataField: 'attachmentSize',
        dataType: 'string',
        minWidth: 150,
        width: '13%',
      },
      {
        caption: t('labels.fileType'),
        dataField: 'fileType',
        dataType: 'string',
        minWidth: 150,
        allowFiltering: false,
        allowSorting: false,
        cellTemplate: 'actionTemplate1',
        width: '8%',
      },

      {
        caption: t('labels.action'),
        dataField: 'isDeleted',
        dataType: 'string',
        minWidth: 140,
        cellTemplate: 'actionTemplate',
        width: '5%',
        lookup: {
          dataSource: actionList,
          valueExpr: 'isDeleted',
          displayExpr: 'optionValue',
        },
      },
    ],
    showHeader: false,
    showExport: false,
    showAddButton: false,
  };

  useEffect(() => {
    getAttachments();
  }, []);

  const onClickAttach = () => {
    setIsVisible(true);
  };

  const handleCancelClick = () => {
    setIsVisible(false);
    getAttachments();
  };

  const getAttachments = async () => {
    if (portCode) {
      await api
        .get({
          url: apiConfig.portAttachment + '/' + portCode,
        })
        .then((res) => {
          setAttachments(res);
        });
    } else if (terminalId) {
      await api
        .get({
          url: apiConfig.terminalAttachment + '/' + terminalId,
        })
        .then((res) => {
          setAttachments(res);
        });
    }
  };

  const deleteAttachment = async () => {
    await api
      .put(
        {
          url: apiConfig.deleteEditAttachment + attachmentId,
          params: { isDelete: true },
        },
        setIsLoading
      )
      .then(() => {
        getAttachments();
        toast.success({
          title: t('toast.deletedAttachmentSuccessfully'),
        });
      });
  };

  const restoreAttachment = async () => {
    await api
      .put(
        {
          url: apiConfig.deleteEditAttachment + attachmentId,
          params: { isRestore: true },
        },
        setIsLoading
      )
      .then(() => {
        getAttachments();
        toast.success({
          title: t('toast.restoredAttachmentSuccessfully'),
        });
      });
  };

  const confirmDeleteDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmDelete'),
    handleSubmit: () => {
      setShowConfirmDelete(false);
      deleteAttachment();
    },
    handleClose: () => {
      setShowConfirmDelete(false);
    },
  };

  const confirmRestoreDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmRestore'),
    handleSubmit: () => {
      setShowConfirmRestore(false);
      restoreAttachment();
    },
    handleClose: () => {
      setShowConfirmRestore(false);
    },
  };

  return (
    <div className="m-l-tab-accordion-item-content-section">
      <div
        className="m-l-attachment-block u-mb-lg u-mt-lg"
        style={{ justifyContent: 'flex-end' }}
      >
        <div className="m-l-atch-icon-holder">
          <Restricted permission={'PortManagement.AddAttachment'}>
            <Button
              className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
              onClick={onClickAttach}
              id="attach"
            >
              <div className="m-c-icon-inside-btn" data-testid="export-all">
                <icons.AttachBlueIcon />
              </div>
              <Tooltip
                target={'#attach'}
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                position="bottom"
                contentRender={() => {
                  return (
                    <div className="m-l-tooltip-wrapper">
                      {t('labels.addAnAttachment')}
                    </div>
                  );
                }}
              />
            </Button>
          </Restricted>
        </div>
      </div>
      <Grid
        gridConfig={gridConfig}
        actionTemplate={actionTemplate}
        actionTemplate1={actionTemplate1}
        statusTemplate={dateTimeCellTemplate}
      />
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <Restricted permission={'PortManagement.AddAttachment'}>
                <Button
                  className="app-c-btn app-c-btn--secondary"
                  onClick={onClickAttach}
                  id="attach"
                >
                  {t('buttons.attach')}
                </Button>
              </Restricted>
            </div>
          </div>
        </div>
      </div>
      {isVisible ? (
        <AddAttachment
          isVisible={isVisible}
          handleCancelClick={handleCancelClick}
          portCode={portCode}
          terminalId={terminalId}
        />
      ) : null}
      <ConfirmDialog
        dialogConfig={confirmDeleteDialogConfig}
        isOpen={showConfirmDelete}
      />
      <ConfirmDialog
        dialogConfig={confirmRestoreDialogConfig}
        isOpen={showConfirmRestore}
      />
    </div>
  );

  function dateTimeCellTemplate(data: any) {
    const formattedDate = apiDateAndTimeFormat(data.value);
    return <div className="m-l-badge-container">{formattedDate}</div>;
  }
}
