import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import BerthForm from '../../../components/port-management/berth-form/BerthForm';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import Button from 'devextreme-react/button';
import toast from '../../../utils/toast';
import { useTranslation } from 'react-i18next';
import { IBerthInfo, IEditBerthInfo } from '../../../types/port-management';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { IDialogConfig } from '../../../types/dialog';
import ConfirmDialog from '../../../components/common/confirm/ConfirmDialog';
import { useDimensionsUnit } from '../../../hooks/useDimensionsUnit';
import { IOptionvalue } from '../../../types/master-data';
import { BerthFieldTypes } from '../../../enums/port-enum';

export default function EditBerth() {
  const location = useLocation();
  const [selectedBerthInfo, setSelectedBerthInfo] = useState<IBerthInfo>();
  const [selectedBerthName, setSelectedBerthName] = useState<any>();
  const [berthId, setBerthId] = useState<any>();
  const [terminalCode, setTerminalCode] = useState<any>();
  const [terminalName, setTerminalName] = useState<any>();
  const [terminalId, setTerminalId] = useState<any>();
  const [query, setQuery] = useState<any>();
  const [params, setParams] = useState<any>();
  const navigate = useNavigate();
  const { feetToMeterConversion, meterToFeetConversion, dimensionUnit } =
    useDimensionsUnit();
  const { t } = useTranslation();
  const [isFromTerminal, setIsFromTerminal] = useState(false);
  const [isFromPort, setIsFromPort] = useState(false);
  const [portId, setPortId] = useState<any>();
  const [portName, setPortName] = useState<any>();
  const [portCode, setPortCode] = useState<any>();
  const { apiDateFormat } = useDateFormat();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isconfirmBackDialog, setIsConfirmBackDialog] = useState(false);

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      goBackNavigation();
      setIsConfirmBackDialog(false);
    },
    handleClose: () => {
      setIsConfirmBackDialog(false);
    },
  };
  const [berthTypesData, setBerthTypes] = useState<IOptionvalue[]>([]);
  const [berthStatus, setBerthStatus] = useState<IOptionvalue[]>([]);

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      onAddNewBerth();
      setIsConfirmModalOpen(false);
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };

  const getSelectedBerthInfo = async (berthId: any) => {
    if (berthId) {
      await api
        .get({ url: apiConfig.berthPost + '/' + berthId })
        .then((data: any) => {
          setSelectedBerthInfo(data);
          setSelectedBerthName(data?.berthName);
        });
    }
  };

  useEffect(() => {
    getBerthTypes();
    getBerthStatus();
  }, []);

  useEffect(() => {
    if (location.state) {
      const {
        berthId,
        berthName,
        query,
        params,
        terminalId,
        terminalCode,
        terminalName,
        isFromTerminal,
        isFromPort,
        portId,
        portName,
        portCode,
      }: any = location.state;
      getSelectedBerthInfo(berthId);
      setBerthId(berthId);
      setSelectedBerthName(berthName);
      setTerminalCode(terminalCode);
      setTerminalName(terminalName);
      setTerminalId(terminalId);
      setQuery(query);
      setParams(params);
      setIsFromTerminal(isFromTerminal);
      setIsFromPort(isFromPort);
      setPortCode(portCode);
      setPortId(portId);
      setPortName(portName);
    }
  }, [location.state]);

  const defaultBerthFormValues: IBerthInfo = {
    berthId: null,
    berthCode: '',
    terminalCode: '',
    terminalId: null,
    terminalName: '',
    portCode: '',
    portName: '',
    countryCode: '',
    country: '',
    berthStyle: '',
    berthName: '',
    // isActive: null,
    berthFieldType: null,
    // berthDocument: '',
    gangwayArrangements: '',
    // typesOfBottom: '',
    // waterSalinity: '',
    lasthydrographicalsurvey: '',
    // laststructuralsurvey: '',
    // berthOwner: '',
    // berthOperator: '',
    // isTidalRestrictions: null,
    // isVapourRecoveryRequiremts: null,
    mooringRequirements: '',
    // tidalNotes: '',
    // vapourRecoveryNotes: '',
    // isEmergencyToWoffPrerequire: null,
    // maxPermittedVesselAge: null,
    // specificBerthingRestrictions: '',
    gasCubicCapacityGasCarriersMaximum: null,
    gasCubicCapacityGasCarriersMinimum: null,
    // berthCoordinates: '',
    // isFreshWater: null,
    // slopreceptionNotes: '',
    // isShiptoshipAvailable: null,
    // shiptoshipNotes: '',
    // freshwaterNotes: '',
    connectorTypeDesc: '',
    connectionFlangeSize: '',
    berthnotes: '',
    minimumHeightManifoldAboveDeck: null,
    maximumDistanceManifoldShipSideRail: null,
    minimumDistancefromManifoldShipSideRail: null,
    maximumHeightManifoldAboveDeckDripTray: null,
    minimumHeightManifoldAboveDeckDripTray: null,
    maximumManifoldSpacing: null,
    minimumManifoldSpacing: null,
    minimumManifoldSetback: null,
    maximumManifoldSetback: null,
    maximumHeightManifoldAWL: null,
    minimumHeightManifoldAWL: null,
    minimumPMBForwardOfShipManifold: null,
    minimumPMBAftOfShipManifold: null,
    maximumAirDraughtUnderShoreGear: null,
    pblAftArrival: null,
    maximumFreeboard: null,
    maximumBowCentreManifoldDistance: null,
    minimumPMB: null,
    maximumSternCentreManifoldDistance: null,
    hoseDerrickMinimumSWL: null,
    maximumDraughtAlongside: null,
    maximumAirDraughtAlongside: null,
    minimumAlongsideUKC: null,
    depthAlongSide: null,
    berthingSide: '',
    minimumFreeboard: null,
    minimumDeadweight: null,
    maximumDeadweight: null,
    pblForwardArrival: null,
    maximumDisplacementAlongside: null,
    maximumLengthOverall: null,
    minimumLengthOverall: null,
    minimumBeam: null,
    maximumBeam: null,
    // maxDraftAtApproaches: null,
    // airDraught: null,
    // depthAtApproaches: null,
    // minUkcAtApproaches: null,
    // minUkcValm: null,
    berthTypes: null,
    // cargoHandled: '',
    // loadingRate: null,
    status: '',
    // shipHandled: '',
    // shipsHandledShipSizeClassification: '',
    // shipsHandledShipType: '',
    // cargoType: '',
    minimumBowCentreManifoldDistance: null,
    minimumSternCentreManifoldDistance: null,
    latitude: '',
    longitude: '',
  };

  const berthInfoForm = useForm({ defaultValues: defaultBerthFormValues });

  const { isDirty } = berthInfoForm.formState;

  const { getValues } = berthInfoForm;
  // let berthFormValue = getValues();

  const getBerthTypes = async () => {
    await api
      .get({
        url: apiConfig.berthType,
      })
      .then((res: IOptionvalue[]) => {
        setBerthTypes(res);
      });
  };
  const getBerthStatus = async () => {
    await api
      .get({
        url: apiConfig.berthStatus,
      })
      .then((res: IOptionvalue[]) => {
        setBerthStatus(res);
      });
  };

  const onAddNewBerth = () => {
    const berthFormValue: IBerthInfo = berthInfoForm.getValues();
    const roleJsonEdit: IEditBerthInfo = {
      berthId: berthId,
      berthCode: berthFormValue.berthCode,
      terminalCode: berthFormValue.terminalCode,
      terminalId: berthFormValue.terminalId,
      terminalName: berthFormValue.terminalName,
      portCode: berthFormValue.portCode,
      portName: berthFormValue.portName,
      countryCode: berthFormValue.countryCode,
      country: berthFormValue.country,
      berthStyle: berthFormValue.berthStyle,
      berthName: berthFormValue.berthName,
      berthFieldType: berthFormValue.berthFieldType,
      // berthDocument: berthFormValue.berthDocument,
      gangwayArrangements: berthFormValue.gangwayArrangements,
      // typesOfBottom: berthFormValue.typesOfBottom,
      // waterSalinity: berthFormValue.waterSalinity,
      lasthydrographicalsurvey: apiDateFormat(
        berthFormValue.lasthydrographicalsurvey
      ),
      // laststructuralsurvey: apiDateFormat(berthFormValue.laststructuralsurvey),
      // berthOwner: berthFormValue.berthOwner,
      // berthOperator: berthFormValue.berthOperator,
      // isTidalRestrictions: berthFormValue.isTidalRestrictions,
      // isVapourRecoveryRequiremts: berthFormValue.isVapourRecoveryRequiremts,
      mooringRequirements: berthFormValue.mooringRequirements,
      // tidalNotes: berthFormValue.tidalNotes,
      // vapourRecoveryNotes: berthFormValue.vapourRecoveryNotes,
      // isEmergencyToWoffPrerequire: berthFormValue.isEmergencyToWoffPrerequire,
      // maxPermittedVesselAge: berthFormValue.maxPermittedVesselAge,
      // specificBerthingRestrictions: berthFormValue.specificBerthingRestrictions,
      gasCubicCapacityGasCarriersMaximum:
        berthFormValue.gasCubicCapacityGasCarriersMaximum,
      gasCubicCapacityGasCarriersMinimum:
        berthFormValue.gasCubicCapacityGasCarriersMinimum,
      latitude: berthFormValue.latitude,
      longitude: berthFormValue.longitude,
      // isFreshWater: berthFormValue.isFreshWater,
      // slopreceptionNotes: berthFormValue.slopreceptionNotes,
      // isShiptoshipAvailable: berthFormValue.isShiptoshipAvailable,
      // shiptoshipNotes: berthFormValue.shiptoshipNotes,0
      // freshwaterNotes: berthFormValue.freshwaterNotes,
      connectorTypeDesc: berthFormValue.connectorTypeDesc,
      connectionFlangeSize: berthFormValue.connectionFlangeSize,
      berthnotes: berthFormValue.berthnotes,
      minimumHeightManifoldAboveDeck:
        berthFormValue.minimumHeightManifoldAboveDeck &&
        feetToMeterConversion(berthFormValue.minimumHeightManifoldAboveDeck),
      maximumDistanceManifoldShipSideRail:
        berthFormValue.maximumDistanceManifoldShipSideRail &&
        feetToMeterConversion(
          berthFormValue.maximumDistanceManifoldShipSideRail
        ),
      minimumDistancefromManifoldShipSideRail:
        berthFormValue.minimumDistancefromManifoldShipSideRail &&
        feetToMeterConversion(
          berthFormValue.minimumDistancefromManifoldShipSideRail
        ),
      maximumHeightManifoldAboveDeckDripTray:
        berthFormValue.maximumHeightManifoldAboveDeckDripTray &&
        feetToMeterConversion(
          berthFormValue.maximumHeightManifoldAboveDeckDripTray
        ),
      minimumHeightManifoldAboveDeckDripTray:
        berthFormValue.minimumHeightManifoldAboveDeckDripTray &&
        feetToMeterConversion(
          berthFormValue.minimumHeightManifoldAboveDeckDripTray
        ),
      maximumManifoldSpacing:
        berthFormValue.maximumManifoldSpacing &&
        feetToMeterConversion(berthFormValue.maximumManifoldSpacing),
      minimumManifoldSpacing:
        berthFormValue.minimumManifoldSpacing &&
        feetToMeterConversion(berthFormValue.minimumManifoldSpacing),
      minimumManifoldSetback:
        berthFormValue.minimumManifoldSetback &&
        feetToMeterConversion(berthFormValue.minimumManifoldSetback),
      maximumManifoldSetback:
        berthFormValue.maximumManifoldSetback &&
        feetToMeterConversion(berthFormValue.maximumManifoldSetback),
      maximumHeightManifoldAWL:
        berthFormValue.maximumHeightManifoldAWL &&
        feetToMeterConversion(berthFormValue.maximumHeightManifoldAWL),
      minimumHeightManifoldAWL:
        berthFormValue.minimumHeightManifoldAWL &&
        feetToMeterConversion(berthFormValue.minimumHeightManifoldAWL),
      minimumPMBForwardOfShipManifold:
        berthFormValue.minimumPMBForwardOfShipManifold &&
        feetToMeterConversion(berthFormValue.minimumPMBForwardOfShipManifold),
      minimumPMBAftOfShipManifold:
        berthFormValue.minimumPMBAftOfShipManifold &&
        feetToMeterConversion(berthFormValue.minimumPMBAftOfShipManifold),
      maximumAirDraughtUnderShoreGear:
        berthFormValue.maximumAirDraughtUnderShoreGear &&
        feetToMeterConversion(berthFormValue.maximumAirDraughtUnderShoreGear),
      pblAftArrival:
        berthFormValue.pblAftArrival &&
        feetToMeterConversion(berthFormValue.pblAftArrival),
      maximumFreeboard:
        berthFormValue.maximumFreeboard &&
        feetToMeterConversion(berthFormValue.maximumFreeboard),
      maximumBowCentreManifoldDistance:
        berthFormValue.maximumBowCentreManifoldDistance &&
        feetToMeterConversion(berthFormValue.maximumBowCentreManifoldDistance),
      minimumPMB:
        berthFormValue.minimumPMB &&
        feetToMeterConversion(berthFormValue.minimumPMB),
      maximumSternCentreManifoldDistance:
        berthFormValue.maximumSternCentreManifoldDistance &&
        feetToMeterConversion(
          berthFormValue.maximumSternCentreManifoldDistance
        ),
      hoseDerrickMinimumSWL: berthFormValue.hoseDerrickMinimumSWL,
      maximumDraughtAlongside:
        berthFormValue.maximumDraughtAlongside &&
        feetToMeterConversion(berthFormValue.maximumDraughtAlongside),
      maximumAirDraughtAlongside:
        berthFormValue.maximumAirDraughtAlongside &&
        feetToMeterConversion(berthFormValue.maximumAirDraughtAlongside),
      minimumAlongsideUKC:
        berthFormValue.minimumAlongsideUKC &&
        feetToMeterConversion(berthFormValue.minimumAlongsideUKC),
      depthAlongSide:
        berthFormValue.depthAlongSide &&
        feetToMeterConversion(berthFormValue.depthAlongSide),
      berthingSide: berthFormValue.berthingSide,
      minimumFreeboard:
        berthFormValue.minimumFreeboard &&
        feetToMeterConversion(berthFormValue.minimumFreeboard),
      minimumDeadweight: berthFormValue.minimumDeadweight,
      maximumDeadweight: berthFormValue.maximumDeadweight,
      pblForwardArrival:
        berthFormValue.pblForwardArrival &&
        feetToMeterConversion(berthFormValue.pblForwardArrival),
      maximumDisplacementAlongside: berthFormValue.maximumDisplacementAlongside,
      maximumLengthOverall:
        berthFormValue.maximumLengthOverall &&
        feetToMeterConversion(berthFormValue.maximumLengthOverall),
      minimumLengthOverall:
        berthFormValue.minimumLengthOverall &&
        feetToMeterConversion(berthFormValue.minimumLengthOverall),
      minimumBeam:
        berthFormValue.minimumBeam &&
        feetToMeterConversion(berthFormValue.minimumBeam),
      maximumBeam:
        berthFormValue.maximumBeam &&
        feetToMeterConversion(berthFormValue.maximumBeam),
      // maxDraftAtApproaches:
      //   berthFormValue.maxDraftAtApproaches &&
      //   feetToMeterConversion(berthFormValue.maxDraftAtApproaches),
      // airDraught: berthFormValue.airDraught,
      // depthAtApproaches:
      //   berthFormValue.depthAtApproaches &&
      //   feetToMeterConversion(berthFormValue.depthAtApproaches),
      // minUkcAtApproaches:
      //   berthFormValue.minUkcAtApproaches &&
      //   feetToMeterConversion(berthFormValue.minUkcAtApproaches),
      // minUkcValm: berthFormValue.minUkcValm,
      berthTypes: berthFormValue.berthTypes,
      // cargoHandled: berthFormValue.cargoHandled,
      // loadingRate: berthFormValue.loadingRate,
      status: berthFormValue.status,
      // shipHandled: berthFormValue.shipHandled,
      // shipSize: berthFormValue.shipsHandledShipSizeClassification,
      // shipType: berthFormValue.shipsHandledShipType,
      // cargoType: berthFormValue.cargoType,
      minimumBowCentreManifoldDistance:
        berthFormValue.minimumBowCentreManifoldDistance &&
        feetToMeterConversion(berthFormValue.minimumBowCentreManifoldDistance),
      minimumSternCentreManifoldDistance:
        berthFormValue.minimumSternCentreManifoldDistance &&
        feetToMeterConversion(
          berthFormValue.minimumSternCentreManifoldDistance
        ),
    };
    api
      .put({ url: apiConfig.berthPost, data: roleJsonEdit })
      .then((response) => {
        if (response) {
          getSelectedBerthInfo(berthId);
          toast.success({
            title: t('toast.berthUpdateSuccess'),
          });
          // setQuery(query);
        }
      });
  };

  useEffect(() => {
    berthInfoForm.reset();
    if (selectedBerthInfo) {
      berthInfoForm.reset(
        {
          portName: selectedBerthInfo?.portName,
          portCode: selectedBerthInfo?.portCode,
          terminalCode: selectedBerthInfo?.terminalCode,
          terminalName: selectedBerthInfo?.terminalName,
          berthCode: selectedBerthInfo?.berthCode,
          berthName: selectedBerthInfo?.berthName,
          berthnotes: selectedBerthInfo.berthnotes,
          country: selectedBerthInfo?.country,
          berthStyle: selectedBerthInfo.berthStyle,
          berthFieldType: selectedBerthInfo.berthFieldType
            ? selectedBerthInfo.berthFieldType
            : BerthFieldTypes.Dry,
          gangwayArrangements: selectedBerthInfo.gangwayArrangements,
          connectionFlangeSize: selectedBerthInfo.connectionFlangeSize,
          connectorTypeDesc: selectedBerthInfo.connectorTypeDesc,
          lasthydrographicalsurvey: selectedBerthInfo.lasthydrographicalsurvey,
          minimumHeightManifoldAboveDeck:
            selectedBerthInfo.minimumHeightManifoldAboveDeck &&
            meterToFeetConversion(
              selectedBerthInfo.minimumHeightManifoldAboveDeck
            ),
          maximumDistanceManifoldShipSideRail:
            selectedBerthInfo.maximumDistanceManifoldShipSideRail &&
            meterToFeetConversion(
              selectedBerthInfo.maximumDistanceManifoldShipSideRail
            ),
          minimumDistancefromManifoldShipSideRail:
            selectedBerthInfo.minimumDistancefromManifoldShipSideRail &&
            meterToFeetConversion(
              selectedBerthInfo.minimumDistancefromManifoldShipSideRail
            ),
          maximumHeightManifoldAboveDeckDripTray:
            selectedBerthInfo.maximumHeightManifoldAboveDeckDripTray &&
            meterToFeetConversion(
              selectedBerthInfo.maximumHeightManifoldAboveDeckDripTray
            ),
          minimumHeightManifoldAboveDeckDripTray:
            selectedBerthInfo.minimumHeightManifoldAboveDeckDripTray &&
            meterToFeetConversion(
              selectedBerthInfo.minimumHeightManifoldAboveDeckDripTray
            ),
          maximumManifoldSpacing:
            selectedBerthInfo.maximumManifoldSpacing &&
            meterToFeetConversion(selectedBerthInfo.maximumManifoldSpacing),
          minimumManifoldSpacing:
            selectedBerthInfo.minimumManifoldSpacing &&
            meterToFeetConversion(selectedBerthInfo.minimumManifoldSpacing),
          minimumManifoldSetback:
            selectedBerthInfo.minimumManifoldSetback &&
            meterToFeetConversion(selectedBerthInfo.minimumManifoldSetback),
          maximumManifoldSetback:
            selectedBerthInfo.maximumManifoldSetback &&
            meterToFeetConversion(selectedBerthInfo.maximumManifoldSetback),
          maximumHeightManifoldAWL:
            selectedBerthInfo.maximumHeightManifoldAWL &&
            meterToFeetConversion(selectedBerthInfo.maximumHeightManifoldAWL),
          minimumHeightManifoldAWL:
            selectedBerthInfo.minimumHeightManifoldAWL &&
            meterToFeetConversion(selectedBerthInfo.minimumHeightManifoldAWL),
          minimumPMBForwardOfShipManifold:
            selectedBerthInfo.minimumPMBForwardOfShipManifold &&
            meterToFeetConversion(
              selectedBerthInfo.minimumPMBForwardOfShipManifold
            ),
          minimumPMBAftOfShipManifold:
            selectedBerthInfo.minimumPMBAftOfShipManifold &&
            meterToFeetConversion(
              selectedBerthInfo.minimumPMBAftOfShipManifold
            ),
          maximumAirDraughtUnderShoreGear:
            selectedBerthInfo.maximumAirDraughtUnderShoreGear &&
            meterToFeetConversion(
              selectedBerthInfo.maximumAirDraughtUnderShoreGear
            ),
          pblAftArrival:
            selectedBerthInfo.pblAftArrival &&
            meterToFeetConversion(selectedBerthInfo.pblAftArrival),
          maximumFreeboard:
            selectedBerthInfo.maximumFreeboard &&
            meterToFeetConversion(selectedBerthInfo.maximumFreeboard),
          maximumBowCentreManifoldDistance:
            selectedBerthInfo.maximumBowCentreManifoldDistance &&
            meterToFeetConversion(
              selectedBerthInfo.maximumBowCentreManifoldDistance
            ),
          minimumPMB:
            selectedBerthInfo.minimumPMB &&
            meterToFeetConversion(selectedBerthInfo.minimumPMB),
          maximumSternCentreManifoldDistance:
            selectedBerthInfo.maximumSternCentreManifoldDistance &&
            meterToFeetConversion(
              selectedBerthInfo.maximumSternCentreManifoldDistance
            ),
          hoseDerrickMinimumSWL: selectedBerthInfo.hoseDerrickMinimumSWL,
          maximumDraughtAlongside:
            selectedBerthInfo.maximumDraughtAlongside &&
            meterToFeetConversion(selectedBerthInfo.maximumDraughtAlongside),
          maximumAirDraughtAlongside:
            selectedBerthInfo.maximumAirDraughtAlongside &&
            meterToFeetConversion(selectedBerthInfo.maximumAirDraughtAlongside),
          minimumAlongsideUKC:
            selectedBerthInfo.minimumAlongsideUKC &&
            meterToFeetConversion(selectedBerthInfo.minimumAlongsideUKC),
          depthAlongSide:
            selectedBerthInfo.depthAlongSide &&
            meterToFeetConversion(selectedBerthInfo.depthAlongSide),
          berthingSide: selectedBerthInfo.berthingSide,
          minimumFreeboard:
            selectedBerthInfo.minimumFreeboard &&
            meterToFeetConversion(selectedBerthInfo.minimumFreeboard),
          minimumDeadweight: selectedBerthInfo.minimumDeadweight,
          maximumDeadweight: selectedBerthInfo.maximumDeadweight,
          pblForwardArrival:
            selectedBerthInfo.pblForwardArrival &&
            meterToFeetConversion(selectedBerthInfo.pblForwardArrival),
          maximumDisplacementAlongside:
            selectedBerthInfo.maximumDisplacementAlongside,
          maximumLengthOverall:
            selectedBerthInfo.maximumLengthOverall &&
            meterToFeetConversion(selectedBerthInfo.maximumLengthOverall),
          minimumLengthOverall:
            selectedBerthInfo.minimumLengthOverall &&
            meterToFeetConversion(selectedBerthInfo.minimumLengthOverall),
          minimumBeam:
            selectedBerthInfo.minimumBeam &&
            meterToFeetConversion(selectedBerthInfo.minimumBeam),
          maximumBeam:
            selectedBerthInfo.maximumBeam &&
            meterToFeetConversion(selectedBerthInfo.maximumBeam),
          // maxDraftAtApproaches:
          //   selectedBerthInfo.maxDraftAtApproaches &&
          //   meterToFeetConversion(selectedBerthInfo.maxDraftAtApproaches),
          // airDraught: selectedBerthInfo.airDraught,
          // depthAtApproaches:
          //   selectedBerthInfo.depthAtApproaches &&
          //   meterToFeetConversion(selectedBerthInfo.depthAtApproaches),
          // minUkcAtApproaches:
          //   selectedBerthInfo.minUkcAtApproaches &&
          //   meterToFeetConversion(selectedBerthInfo.minUkcAtApproaches),
          // minUkcValm: selectedBerthInfo.minUkcValm,
          berthTypes: selectedBerthInfo.berthTypes,
          // cargoHandled: selectedBerthInfo.cargoHandled,
          // loadingRate: selectedBerthInfo.loadingRate,
          status: selectedBerthInfo.status,
          // shipHandled: selectedBerthInfo.shipHandled,
          // shipsHandledShipSizeClassification:
          //   selectedBerthInfo.shipsHandledShipSizeClassification,
          // shipsHandledShipType: selectedBerthInfo.shipsHandledShipType,
          // cargoType: selectedBerthInfo.cargoType,
          minimumSternCentreManifoldDistance:
            selectedBerthInfo.minimumSternCentreManifoldDistance &&
            meterToFeetConversion(
              selectedBerthInfo.minimumSternCentreManifoldDistance
            ),
          minimumBowCentreManifoldDistance:
            selectedBerthInfo.minimumBowCentreManifoldDistance &&
            meterToFeetConversion(
              selectedBerthInfo.minimumBowCentreManifoldDistance
            ),
          // berthDocument: selectedBerthInfo.berthDocument,
          mooringRequirements: selectedBerthInfo.mooringRequirements,
          gasCubicCapacityGasCarriersMaximum:
            selectedBerthInfo.gasCubicCapacityGasCarriersMaximum,
          gasCubicCapacityGasCarriersMinimum:
            selectedBerthInfo.gasCubicCapacityGasCarriersMinimum,
          latitude: selectedBerthInfo.latitude,
          longitude: selectedBerthInfo.longitude,
        },
        { keepDirty: true }
      );
    }
  }, [selectedBerthInfo, dimensionUnit]);

  const goBack = () => {
    if (isDirty) {
      setIsConfirmBackDialog(true);
    } else {
      goBackNavigation();
    }
  };

  const goBackNavigation = () => {
    if (isFromTerminal) {
      navigate('/port/terminal/edit', {
        state: {
          terminalId: terminalId,
          terminalName: terminalName,
          terminalCode: terminalCode,
          query: query,
          params: params,
          portName: portName,
          portCode: portCode,
          portId: portId,
          isFromPort: isFromPort ? isFromPort : false,
        },
      });
    } else {
      navigate('/port', {
        state: {
          query: query,
          params: params,
        },
      });
    }
  };

  const onClickSave = () => {
    setIsConfirmModalOpen(true);
  };

  return (
    <div className="inner-wrapper">
      <div className="m-l-inner-page-header">
        <div className="m-l-inner-page-header-left-block">
          <div className="m-l-page-main-heading">
            {t('labels.berth')} {'-' + selectedBerthName}
          </div>
        </div>
        <div className="m-l-inner-page-header-right-block">
          <Button
            className="app-c-btn app-c-btn--secondary"
            elementAttr={{ 'data-testid': 'addToList' }}
            onClick={goBack}
          >
            {t('labels.back')}
          </Button>
          <Button
            className="app-c-btn app-c-btn--primary min-btn-width"
            elementAttr={{ 'data-testid': 'addNew' }}
            onClick={onClickSave}
          >
            {t('labels.save')}
          </Button>
        </div>
      </div>
      <div className="m-l-inner-page-body-wrap">
        <div className="m-l-inner-page-body">
          <div className="m-l-form-panel-block">
            <FormProvider {...berthInfoForm}>
              <BerthForm
                berthTypes={berthTypesData}
                berthStatus={berthStatus}
                berthCode={selectedBerthInfo?.berthCode}
              />
            </FormProvider>
          </div>
        </div>
      </div>
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <Button
                className="app-c-btn app-c-btn--secondary"
                elementAttr={{ 'data-testid': 'addToList' }}
                onClick={goBack}
              >
                {t('labels.back')}
              </Button>
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                elementAttr={{ 'data-testid': 'addNew' }}
                onClick={onClickSave}
              >
                {t('labels.save')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={isConfirmModalOpen}
      />
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={isconfirmBackDialog}
      />
    </div>
  );
}
