import React, { useEffect, useState } from 'react';
import { IGridConfig } from '../../../../types/grid';
import Grid from '../../../common/grid/Grid';
import NotesEditModal from '../notes-edit-modal/NotesEditModal';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { useDataStore } from '../../../../hooks/useDataStore';

export default function NotesGrid(props: {
  portCode: any;
  isFromViewInfoModal?: boolean;
}) {
  const { portCode, isFromViewInfoModal } = props;
  const { getDataSource } = useDataStore();
  const [showEditNoteModal, setShowEditNoteModal] = useState(false);
  const [portNotes, setPortNotes] = useState<any>([]);
  const [selectedNote, setSelectedNote] = useState<any>([]);

  const onClickAddNote = () => {
    setSelectedNote(null);
    setShowEditNoteModal(true);
  };

  const cancelClick = () => {
    setShowEditNoteModal(false);
  };

  const onRowClick = (e: any) => {
    if (!isFromViewInfoModal) {
      setSelectedNote(e.data.portNoteId);
      setShowEditNoteModal(true);
    }
  };

  const getPortNotes = async (portCode: any) => {
    await api
      .get({
        url: apiConfig.getAllPortNotes + portCode,
      })
      .then((res: any) => {
        setPortNotes(res.data);
      });
  };

  useEffect(() => {
    if (portCode) {
      getPortNotes(portCode);
    }
  }, []);

  const gridConfig: IGridConfig = {
    class: 'm-c-grid m-l-grid-with--top-border m-c-righticon-grid',
    testId: 'vesselNameGrid',
    height: '350',
    dataSource: portNotes,
    // rowClickPermission: 'Ship.EditName',
    rowClick: onRowClick,
    onClickAddButton: onClickAddNote,
    // remoteOperations: true,
    defaultColumns: [
      {
        caption: 'Note Type',
        dataField: 'portNotesTypeName',
        dataType: 'string',
        minWidth: 150,
        width: 300,
      },
      {
        caption: 'Notes',
        dataField: 'notes',
        dataType: 'string',
        minWidth: 150,
      },
    ],
    showHeader: true,
    showExport: false,
    showAddButton: !isFromViewInfoModal,
  };

  return (
    <div className="m-l-tab-accordion-item-content-section">
      <Grid gridConfig={gridConfig} />
      {showEditNoteModal && (
        <NotesEditModal
          isVisible={showEditNoteModal}
          handleCancelClick={() => cancelClick()}
          portCode={portCode}
          getPortNotes={getPortNotes}
          selectedNote={selectedNote}
        />
      )}
    </div>
  );
}
