export enum EmployeeStatus {
  draft = '1',
  waitingForApproval = '2',
  active = '3',
  inactive = '4',
}

export enum CompanyStatus {
  draft = '1',
  active = '2',
  inActive = '3',
}

export enum ListEntryStatus {
  active = 1,
  inActive = 2,
}

export enum IncidentStatus {
  Open = '1',
  Closed = '2',
  AwaitingInfo = '3',
  WrongEntry = '4',
}

export enum BusinessUnitStatus {
  Active = '1',
  Inactive = '2',
}
export enum BerthFitHistoryStatus {
  acceptable = 'A',
  notAcceptable = 'NA',
  furtherInfoNeeded = 'F',
}
