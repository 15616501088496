export type IListGeneralForm = {
  listname: string;
  listDescription: string;
  entity: any;
  attribute: any;
};

export type IListObject = {
  listId?: any;
  listName: string;
  listDesc: string;
  masterEntityId: any;
  masterAttributeId: any;
  listEntries?: any;
};

export type IListEntryForm = {
  entryName: string;
  reason: string;
  enteredDate: any;
  validTillDate: any;
  removedDate: any;
  entryStatus?: any;
  createdBy?: null;
  modifiedBy?: null;
  createdDate?: null;
  modifiedDate?: null;
  entryDisplayName?: string;
};

export type IListEntriesObject = {
  listEntryId?: number;
  listId?: number;
  entryName: any;
  reason: string;
  enteredDate: any;
  validTillDate: any;
  removedDate: any;
  entryStatus?: any;
  entryValue?: any;
  entryDisplayName?: any;
};

export enum ListAttributes {
  DateTime = 'DateTime',
  Number = 'Number',
}
