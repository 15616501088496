import React, { useEffect, useState } from 'react';
// import { Stimulsoft } from 'stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import 'stimulsoft-reports-js/Css/stimulsoft.designer.office2013.whiteblue.css';
import 'stimulsoft-reports-js/Css/stimulsoft.viewer.office2013.whiteblue.css';
import { useLocation } from 'react-router-dom';
import {
  getAdditionalInfoToken,
  getRememberMe,
  getToken,
} from '../../../utils/jwt-decode';
import { useLoaderContext } from '../../../contexts/LoaderContext';

declare let Stimulsoft: any;
declare let StiJsViewer: any;
declare let StiMobileDesigner: any;
export default function ReportDesigner() {
  let options: any;
  let designer: any;

  const [loaded, setLoaded] = useState(false);
  const [reportCode, setReportCode] = useState();
  const [layoutId, setLayoutId] = useState();
  const [queryId, setQueryId] = useState();
  const location = useLocation();
  const { setIsLoading } = useLoaderContext();

  useEffect(() => {
    if (reportCode && layoutId) {
      const url = `${process.env.REACT_APP_BASE_URL}${apiConfig.reportDesigner}?reportCode=${reportCode}&reportLayoutId=${layoutId}&queryId=${queryId}`;
      loadScript(url);
    }
  }, [reportCode, layoutId]);

  useEffect(() => {
    if (loaded) {
      getReport();
    }
  }, [loaded]);

  useEffect(() => {
    if (getRememberMe() === 'true') {
      const data = localStorage.getItem('designerProps');
      sessionStorage.setItem('designerProps', data as string);
      localStorage.removeItem('designerProps');
    }
    const designerProps = sessionStorage.getItem('designerProps');
    if (designerProps) {
      const obj = JSON.parse(designerProps);
      const { reportLayoutId, reportCode, queryId }: any = obj;
      setReportCode(reportCode);
      setLayoutId(reportLayoutId);
      setQueryId(queryId);
    }
    return () => {
      sessionStorage.removeItem('designerProps');
      localStorage.removeItem('designerProps');
    };
  }, []);

  const getReport = () => {
    const url = `${process.env.REACT_APP_BASE_URL}${apiConfig.reportDesigner}?reportCode=${reportCode}&reportLayoutId=${layoutId}&queryId=${queryId}`;
    customHttpOverrides();
    Stimulsoft.Designer.parameters.requestUrl = url;
    Stimulsoft.Viewer.parameters.requestUrl = url;
    options = new Stimulsoft.Designer.StiDesignerOptions();
    Stimulsoft.Designer.showExit = true;
    options.dictionary.permissionBusinessObjects = 'View';
    options.behavior.allowChangeWindowTitle = false;
    options.server.allowAutoUpdateCache = false;
    options.fileMenu.visible = false;
    options.behavior.showSaveDialog = false;
    designer = new Stimulsoft.Designer.StiDesigner(
      options,
      'StiDesigner',
      false
    );
    designer.renderHtml('designer');
  };

  const loadScript = (url: string) => {
    setIsLoading(true);
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    script.setAttribute('data-group', 'stimulsoft');
    script.onload = () => {
      setLoaded(true);
      setIsLoading(false);
    };
    script.onerror = () => {
      setIsLoading(false);
    };
    document.body.appendChild(script);
  };

  const customHttpOverrides = () => {
    const token = getToken();
    const additionalInfoToken = getAdditionalInfoToken();
    stimulSoftViewerPost(token, additionalInfoToken);
    stimulSoftDesignerPost(token, additionalInfoToken);
  };

  const stimulSoftViewerPost = (
    token: string | null,
    additionalInfoToken: string | null
  ) => {
    StiJsViewer.prototype.postAjax = function (
      url: any,
      data: { action: string; responseType: any },
      callback: (arg0: string, arg1: any) => void
    ) {
      if (data && data.action === 'GetReport') {
        this.options.paramsVariablesStartValues = null;
        if (this.controls.toolbar) {
          this.controls.toolbar.setEnabled(false);
          if (this.controls.navigatePanel) {
            this.controls.navigatePanel.setEnabled(false);
          }
        }
      }
      const xmlHttp = this.createConnection();
      this.openConnection(xmlHttp, url, data ? data.responseType : 'text');
      if (this.options.server.requestTimeout !== 0) {
        setTimeout(() => {
          if (xmlHttp.readyState < 4) {
            xmlHttp.abort();
          }
        }, this.options.server.requestTimeout * 1000);
      }
      xmlHttp.onreadystatechange = () => {
        if (xmlHttp.readyState === 4) {
          this.service.isRequestInProcess = false;
          clearTimeout(this.dashboardProcessTimeout);
          let status = 0;
          try {
            status = xmlHttp.status;
          } catch (e) {
            return;
          }
          if (status === 0) {
            callback('ServerError:Timeout response from the server.', this);
          } else if (status === 200) {
            callback(
              xmlHttp.response ? xmlHttp.response : xmlHttp.responseText,
              this
            );
          } else {
            if (this.options.server.showServerErrorPage && xmlHttp.response) {
              this.controls.reportPanel.innerHTML = xmlHttp.response;
            }
            callback(
              'ServerError:' + status + ' - ' + xmlHttp.statusText,
              this
            );
          }
        }
      };
      this.service.isRequestInProcess = true;
      const params = this.createPostParameters(data, false);
      xmlHttp.setRequestHeader('Authorization', 'Bearer ' + token);
      xmlHttp.setRequestHeader('Additional-Info', additionalInfoToken);
      xmlHttp.send(params);
    };
  };

  const stimulSoftDesignerPost = (token: any, additionalInfoToken: any) => {
    StiMobileDesigner.prototype.PostAjax = function (
      url: string,
      data: any,
      callback: any,
      requestType: any
    ) {
      const xmlHttp = (this.xmlHttp = this.CreateXMLHttp());
      this.xmlHttpAbortedByUser = false;
      if (this.options.actions) {
        url = this.GetMvcActionUrl(url, data);
      }
      if (this.options.requestTimeout !== 0) {
        const requestTimeout = this.CheckRequestTimeout(data);
        setTimeout(() => {
          if (xmlHttp.readyState < 4) {
            xmlHttp.abort();
          }
        }, (requestTimeout || this.options.requestTimeout) * 1000);
      }
      xmlHttp.open(requestType || 'POST', url);
      if (
        this.options.requestHeaderContentType &&
        this.options.requestHeaderContentType !== ''
      ) {
        xmlHttp.setRequestHeader(
          'Content-Type',
          this.options.requestHeaderContentType
        );
      } else {
        xmlHttp.setRequestHeader(
          'Content-Type',
          'application/x-www-form-urlencoded'
        );
      }
      xmlHttp.responseType = 'text';
      if (data && data.responseType) {
        xmlHttp.responseType = data.responseType;
      }
      xmlHttp.onreadystatechange = () => {
        if (xmlHttp.readyState === 4) {
          let status = 0;
          try {
            status = xmlHttp.status;
          } catch (e) {
            return;
          }
          if (status === 0) {
            callback('ServerError:Timeout response from the server.', this);
          } else if (status === 200) {
            callback(
              xmlHttp.response ? xmlHttp.response : xmlHttp.responseText,
              this
            );
          } else {
            callback(
              'ServerError:' + status + ' - ' + xmlHttp.statusText,
              this
            );
          }
        }
      };
      const params = this.CreatePostParameters(data, false);
      xmlHttp.setRequestHeader('Authorization', 'Bearer ' + token);
      xmlHttp.setRequestHeader('Additional-Info', additionalInfoToken);
      xmlHttp.send(params);
    };
  };

  return (
    <div className="app-l-report-designer">
      <div id="designer"></div>
    </div>
  );
}
