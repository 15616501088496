import { Accordion } from 'devextreme-react/accordion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { MarsApiService as api } from '../../../api/mars-api-service';
import TerminalInfo from '../../../components/berth-fit-check/info/terminal-info/TerminalInfo';
import TerminalAccordionHeader from '../terminal-accordion-header/TerminalAccordionHeader';
import TerminalDetails from '../terminal-details/TerminalDetails';

export default function TerminalTab(props: {
  selectedTerminals: any;
  imoNumber: any;
  berthFitId: any;
  buId: any;
  getPortStatus: any;
}) {
  const { imoNumber, berthFitId, buId, getPortStatus } = props;
  const { t } = useTranslation();
  const [terminalId, setTerminalId] = useState(0);
  const [terminalCode, setTerminalCode] = useState(null);
  const [terminalDetails, setTerminalDetails] = useState([]);

  useEffect(() => {
    if (berthFitId && imoNumber) getTerminalList(true);
  }, [berthFitId, imoNumber]);

  const getTerminalList = async (isInitialLoad?: boolean) => {
    const params = {
      imoNumber: imoNumber,
      berthFitId: berthFitId,
    };
    await api
      .get({ url: apiConfig.berthExecutionTerminalList, params: params })
      .then((res: any) => {
        setTerminalDetails(res);
      });
    if (!isInitialLoad) {
      getPortStatus();
    }
  };

  const onClickTitle = (e: any) => {
    if (
      e.event.target?.className === 'view-terminalinfo' ||
      e.event.target?.className === 'm-l-view-info-link'
    ) {
      e?.event?.stopPropagation();
      setTerminalId(e.itemData.terminalId);
      setTerminalCode(e.itemData.terminalCode);
    }
  };

  return (
    <div className="m-l-inner-page-body u-mb0">
      <div className="m-l-accordion__main-section main-top-level-accordion-bg">
        <div className="m-l-accordion-page-title-block">
          <h5>{t('labels.terminals')}</h5>
        </div>
        <div className="m-c-accordion m-l-accordion--plane m-l-berthfit-check-accordion-section open">
          <Accordion
            collapsible={true}
            items={terminalDetails}
            itemTitleRender={(data) => {
              return <TerminalAccordionHeader data={data} />;
            }}
            onItemTitleClick={onClickTitle}
            itemRender={(data: any) => {
              return (
                <TerminalDetails
                  terminal={data}
                  berths={data?.berth}
                  buId={buId}
                  imoNumber={imoNumber}
                  berthFitId={berthFitId}
                  getTerminalList={getTerminalList}
                />
              );
            }}
          ></Accordion>
        </div>
      </div>
      <TerminalInfo
        isVisible={terminalId ? true : false}
        handleCancelClick={() => setTerminalId(0)}
        terminalId={terminalId}
        buId={buId}
        terminalCode={terminalCode}
      />
    </div>
  );
}
