import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IMasterEntity } from '../../../../types/master-data';
import FormBuilder from '../../../common/form-builder/FormBuilder';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import TextArea from 'devextreme-react/text-area';
import EmailSettingHtmlEditor from '../email-settings/email-setting-form/EmailSettingHtmlEditor';
import { usePermission } from '../../../../contexts/PermissionContext';

export default function BerthFitReportSettingForm(props: {
  entities: any;
  showEditor: any;
  requestorDisclaimer: any;
  marsDisclaimer: any;
  setMarsDisclaimer: any;
  setRequestorDisclaimer: any;
  buId?: any;
  showMarsDisclaimer: any;
  showReqDisclaimer: any;
}) {
  const {
    entities,
    requestorDisclaimer,
    setRequestorDisclaimer,
    marsDisclaimer,
    setMarsDisclaimer,
    showEditor,
    buId,
    showMarsDisclaimer,
    showReqDisclaimer,
  } = props;
  const { t } = useTranslation();
  const { findFeature } = usePermission();
  return (
    <div className="">
      <div className="m-l-srv-btm">
        <label className="m-l-email-input-label">
          {t('labels.marsDisclaimerContent')}
        </label>
        <EmailSettingHtmlEditor
          content={marsDisclaimer}
          setContent={setMarsDisclaimer}
          entities={entities}
          showEditor={showMarsDisclaimer ? true : false}
          isDisabled={
            buId
              ? !findFeature(
                  'BUCompanyConfiguration.EditBerthFitReportMARSDisclaimerContent'
                )
              : !findFeature(
                  'CompanyConfiguration.EditBerthFitReportMARSDisclaimerContent'
                )
          }
        />
      </div>
      <div className="m-l-srv-btm ">
        <label className="m-l-email-input-label">
          {t('labels.requestorDisclaimerContent')}
        </label>
        <EmailSettingHtmlEditor
          content={requestorDisclaimer}
          setContent={setRequestorDisclaimer}
          entities={entities}
          showEditor={showReqDisclaimer ? true : false}
          isDisabled={false}
        />
      </div>
    </div>
  );
}
