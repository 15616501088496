import React, { useEffect, useMemo, useState } from 'react';
import icons from '../../common/icons/icons';
import Modal from '../../common/modal/Modal';
import Button from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import TextBox from 'devextreme-react/text-box';
import Switch from 'devextreme-react/switch';
import { useTranslation } from 'react-i18next';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import { Restricted } from '../../../contexts/PermissionContext';

export default function SaveLayout(props: {
  showSave: any;
  setLayoutName: any;
  setIsDefault: any;
  setIsPrivate: any;
  setShowSave: any;
  checkNameExist: any;
  isDuplicate?: any;
  setIsDuplicate: any;
  edit: any;
  setEdit: any;
  duplicateData?: any;
  updateLayout?: any;
  addOrEdit?: any;
}) {
  const { t } = useTranslation();
  const {
    showSave,
    setLayoutName,
    setIsDefault,
    setIsPrivate,
    setShowSave,
    checkNameExist,
    isDuplicate,
    setIsDuplicate,
    edit,
    setEdit,
    duplicateData,
    updateLayout,
    addOrEdit,
  } = props;

  const PopupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-edit-name-popup',
    };
  }, []);

  const ModalConfig = {
    width: 400,
    height: 300,
    visible: showSave,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  useEffect(() => {
    if (edit) {
      setLayoutName(duplicateData?.layoutName);
    }
  }, [edit]);

  const changeLayoutName = (e: any) => {
    setLayoutName(e.value);
  };

  const changeIsDefault = (e: any) => {
    setIsDefault(e.value);
  };

  const changeIsPrivate = (e: any) => {
    setIsPrivate(e.value);
  };

  return (
    <React.Fragment>
      <div className="m-l-model-popup__wrap">
        <Modal modalConfig={ModalConfig} wrapperAttr={PopupAttributes}>
          <div className="m-l-modal__header">
            <h2 className="modal-title"> Save Layout </h2>
            <div className="m-l-modal-close-button-block">
              <Button
                className="app-c-btn app-c-icon-only-btn"
                onClick={() => {
                  setShowSave(false);
                  setIsDuplicate(false);
                  setEdit(false);
                }}
              >
                <icons.IconCross />
              </Button>
            </div>
          </div>
          <div className="m-l-modal__body">
            <ScrollView width="100%" height="100%">
              <div className="m-l-edit-form-panel">
                <div className="row m-l-custom-sixteen-space-row">
                  <div className="mb-3 m-c-form-group">
                    <label className="m-l-input-label">Layout Name</label>
                    <div className="m-l-input-label-padding">
                      {edit ? (
                        <TextBox
                          className="m-c-input-control"
                          placeholder="Enter"
                          onValueChanged={changeLayoutName}
                          defaultValue={duplicateData?.layoutName}
                          validationMessageMode="always"
                        >
                          <Validator>
                            <RequiredRule message={t('errors.required')} />
                          </Validator>
                        </TextBox>
                      ) : (
                        <TextBox
                          className="m-c-input-control"
                          placeholder="Enter"
                          onValueChanged={changeLayoutName}
                          validationMessageMode="always"
                        >
                          <Validator>
                            <RequiredRule message={t('errors.required')} />
                          </Validator>
                        </TextBox>
                      )}
                    </div>
                    {isDuplicate ? null : (
                      <div className="m-l-report-toggle-btn-holder">
                        <div className="m-l-report-toggle-btn">
                          <label className="m-l-input-label">Default</label>
                          <div className="m-l-toggle-switch">
                            {edit ? (
                              <Switch
                                elementAttr={{ 'data-testid': 'canOverride' }}
                                name="isDefault"
                                onValueChanged={changeIsDefault}
                                defaultValue={duplicateData?.isDefault}
                              />
                            ) : (
                              <Switch
                                elementAttr={{ 'data-testid': 'canOverride' }}
                                name="isDefault"
                                onValueChanged={changeIsDefault}
                                // value={field.value}
                              />
                            )}
                          </div>
                        </div>

                        <Restricted permission="ReportingFrameWork.ShareLayout">
                          <div className="m-l-report-toggle-btn">
                            <label className="m-l-input-label"> Private </label>
                            <div className="m-l-toggle-switch">
                              {edit ? (
                                <Switch
                                  elementAttr={{ 'data-testid': 'canOverride' }}
                                  name="isPrivate"
                                  onValueChanged={changeIsPrivate}
                                  defaultValue={duplicateData?.isPrivate}
                                />
                              ) : (
                                <Switch
                                  elementAttr={{ 'data-testid': 'canOverride' }}
                                  name="isPrivate"
                                  onValueChanged={changeIsPrivate}
                                  // value={field.value}
                                />
                              )}
                            </div>
                          </div>
                        </Restricted>
                      </div>
                    )}
                    {/* <div className="m-l-rule-list-column1"> */}

                    {/* </div> */}

                    {/* <div className="m-l-rule-list-column1"> */}

                    {/* </div> */}
                  </div>
                </div>
              </div>
            </ScrollView>
          </div>
          <div className="m-l-modal__footer">
            <Button
              className="app-c-btn app-c-btn--secondary min-btn-width"
              onClick={() => {
                setShowSave(false);
                setIsDuplicate(false);
                setEdit(false);
              }}
              elementAttr={{ 'data-testid': 'backButton' }}
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              className="app-c-btn app-c-btn--primary min-btn-width"
              onClick={(e: any) => checkNameExist(e)}
              elementAttr={{ 'data-testid': 'saveButton' }}
            >
              {t('buttons.save')}
            </Button>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
}
