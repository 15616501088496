import Button from 'devextreme-react/button';
import Popover from 'devextreme-react/popover';
import SelectBox from 'devextreme-react/select-box';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IDuplicateData,
  IDuplicateFavorite,
  IFavorites,
  ISaveFavorite,
} from '../../../types/report';
import icons from '../../common/icons/icons';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { Link, useNavigate } from 'react-router-dom';
import { getAPIRoute } from '../../../utils/api-route';
import toast from '../../../utils/toast';
import ManageFavorite from './ManageFavorite';
import SaveFavorite from './SaveFavorite';
import ManageLayout from './ManageLayout';
import {
  getRememberMe,
  getUserId,
  getUserName,
} from '../../../utils/jwt-decode';
import ConfirmDialog from '../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../types/dialog';
import { Restricted } from '../../../contexts/PermissionContext';
import { useResize } from '../../../hooks/useResize';

export default function ReportHeader(props: {
  isVisible: any;
  setIsVisible: any;
  layoutsData: any;
  reportHeaderId: any;
  setLayoutId: any;
  layoutId: any;
  reportCode: any;
  reportFavouriteFilters: any;
  favoriteId: any;
  setFavoriteId: any;
  sharedLayoutsData: any;
  getLayoutDetails?: any;
  getSharedLayoutDetails?: any;
  allLayoutsData?: any;
  reportOptions: any;
  setReportOptions: any;
  standardLayoutIndex?: any;
  getAllLayoutDetails?: any;
  queryId: any;
  allFavoritesData: any;
  setAllFavoritesData: any;
  myDefaultLayoutIndex?: any;
}) {
  const {
    isVisible,
    setIsVisible,
    reportHeaderId,
    layoutsData,
    setLayoutId,
    layoutId,
    reportCode,
    reportFavouriteFilters,
    favoriteId,
    setFavoriteId,
    sharedLayoutsData,
    getLayoutDetails,
    getSharedLayoutDetails,
    allLayoutsData,
    reportOptions,
    setReportOptions,
    standardLayoutIndex,
    getAllLayoutDetails,
    queryId,
    allFavoritesData,
    setAllFavoritesData,
    myDefaultLayoutIndex,
  } = props;
  const userId = getUserId();
  const [showLayoutPopover, setShowLayoutPopover] = useState(false);
  const [showFavPopover, setShowFavPopover] = useState(false);
  const [showSaveFav, setShowSaveFav] = useState(false);
  const [favVisible, setFavVisible] = useState(false);
  const { t } = useTranslation();
  const [favoritesData, setFavoritesData] = useState<IFavorites[]>([]);
  const [sharedFavoritesData, setSharedFavoritesData] = useState<IFavorites[]>(
    []
  );
  const navigate = useNavigate();
  const userName = getUserName();
  const [isDefault, setIsDefault] = useState<any>(false);
  const [isPrivate, setIsPrivate] = useState<any>(false);
  const [favoriteName, setFavoriteName] = useState<any>('');
  const [isDuplicateFav, setIsDuplicateFav] = useState(false);
  const [duplicateFavData, setDuplicateFavData] = useState<any>();
  const [editFav, setEditFav] = useState(false);
  const [showConfirmEdit, setShowConfirmEdit] = useState(false);
  const [showAddOrEdit, setShowAddOrEdit] = useState(false);
  const [selectedFavData, setSelectedFavData] = useState<IFavorites>();
  const [addOrEdit, setAddOrEdit] = useState(false);
  const [layoutIsPublic, setLayoutIsPublic] = useState(false);
  const [showShareLayout, setShowShareLayout] = useState(false);
  const [isStandardLayout, setIsStandardLayout] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  const confirmAddOrUpdate: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.updateFavorite'),
    okButtonLabel: 'Update',
    cancelButtonLabel: 'Create New',
    handleSubmit: () => {
      setAddOrEdit(true);
      setDuplicateFavData(selectedFavData);
      setEditFav(true);
      setShowSaveFav(true);
      setShowAddOrEdit(false);
    },
    handleClose: () => {
      setShowSaveFav(true);
      setShowAddOrEdit(false);
    },
    handleCancel: () => {
      setShowAddOrEdit(false);
    },
  };

  const confirmShareLayout: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.shareLayout'),
    handleSubmit: () => {
      saveFavoriteShareLayout(true);
      setShowShareLayout(false);
      setLayoutIsPublic(false);
    },
    handleClose: () => {
      saveFavoriteShareLayout(false);
      setShowShareLayout(false);
      setLayoutIsPublic(false);
    },
  };

  useEffect(() => {
    if (selectedFavData?.reportLayoutId) {
      setLayoutId(selectedFavData?.reportLayoutId);

      const selectedLayoutData = allLayoutsData.find((data: any) => {
        return data.reportLayoutId === selectedFavData?.reportLayoutId;
      });
      if (selectedLayoutData?.userId !== userId) {
        setIsStandardLayout(true);
      } else {
        setIsStandardLayout(false);
      }
    } else if (allLayoutsData[myDefaultLayoutIndex]) {
      setLayoutId(allLayoutsData[myDefaultLayoutIndex]?.reportLayoutId);

      const selectedLayoutData = allLayoutsData.find((data: any) => {
        return (
          data.reportLayoutId ===
          allLayoutsData[myDefaultLayoutIndex]?.reportLayoutId
        );
      });
      if (selectedLayoutData?.userId !== userId) {
        setIsStandardLayout(true);
      } else {
        setIsStandardLayout(false);
      }
    } else {
      setLayoutId(allLayoutsData[standardLayoutIndex]?.reportLayoutId);

      const selectedLayoutData = allLayoutsData.find((data: any) => {
        return (
          data.reportLayoutId ===
          allLayoutsData[standardLayoutIndex]?.reportLayoutId
        );
      });
      if (selectedLayoutData?.userId !== userId) {
        setIsStandardLayout(true);
      } else {
        setIsStandardLayout(false);
      }
    }
  }, [selectedFavData, standardLayoutIndex, myDefaultLayoutIndex]);

  useEffect(() => {
    const defaultFav = allFavoritesData.find(
      (favorite: any) => favorite.isDefault === true
    );
    setFavoriteId(defaultFav?.reportFavoriteId);
  }, [allFavoritesData]);

  useEffect(() => {
    if (favoriteId) {
      const selectedData = allFavoritesData.find(
        (data: any) => data.reportFavoriteId === favoriteId
      );
      setSelectedFavData(selectedData);
      if (selectedData?.userId === userId) {
        setIsEditable(true);
      } else {
        setIsEditable(false);
      }
    }
  }, [favoriteId]);

  useEffect(() => {
    if (layoutId) {
      const selectedLayoutData = allLayoutsData.find((data: any) => {
        return data.reportLayoutId === layoutId;
      });
      if (!selectedLayoutData?.isPrivate) {
        setLayoutIsPublic(true);
      } else {
        setLayoutIsPublic(false);
      }
      if (selectedLayoutData?.userId !== userId) {
        setIsStandardLayout(true);
      } else {
        setIsStandardLayout(false);
      }
    }
  }, [layoutId]);

  const layoutPopOverAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-result-layout__dropdown',
      'data-testid': 'Popover',
    };
  }, []);

  const getFavoriteDetails = async () => {
    await api
      .get({
        url: apiConfig.reportFavorites + reportHeaderId,
        params: { isPrivate: true, isGetAll: false },
      })
      .then((response: any) => {
        setFavoritesData(response.data);
      });
  };

  const getAllFavoriteDetails = async () => {
    await api
      .get({
        url: apiConfig.reportFavorites + reportHeaderId,
        params: { isGetAll: true },
      })
      .then((response: any) => {
        setAllFavoritesData(response.data);
      });
  };

  const getSharedFavoriteDetails = async () => {
    await api
      .get({
        url: apiConfig.reportFavorites + reportHeaderId,
        params: { isPrivate: false, isGetAll: false },
      })
      .then((response: any) => {
        setSharedFavoritesData(response.data);
      });
  };

  useEffect(() => {
    if (reportHeaderId) {
      getFavoriteDetails();
      getSharedFavoriteDetails();
      getAllFavoriteDetails();
    }
  }, [reportHeaderId]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('designerProps');
      localStorage.removeItem('designerProps');
    };
  }, []);

  const setSelectedLayoutId = (e: any) => {
    setLayoutId(e.value);
  };

  const setSelectedFavoriteId = (e: any) => {
    setFavoriteId(e.value);
  };

  const addOrUpdate = () => {
    if (favoriteId) {
      if (isEditable) {
        setShowAddOrEdit(true);
      } else {
        setShowSaveFav(true);
      }
    } else {
      setShowSaveFav(true);
    }
  };

  const onClickSettings = () => {
    const storage: Storage =
      getRememberMe() !== 'true' ? sessionStorage : localStorage;
    const designerProps = {
      reportCode: reportCode,
      reportLayoutId: layoutId,
      queryId: queryId,
    };
    storage.setItem('designerProps', JSON.stringify(designerProps));
    // navigate('/report-designer', {
    //   state: {
    //     reportCode: reportCode,
    //     reportLayoutId: layoutId,
    //     queryId: queryId,
    //   },
    // });
  };

  const deleteFavorite = async () => {
    await api
      .delete({
        url: apiConfig.favourites + duplicateFavData?.reportFavoriteId,
      })
      .then((res) => {
        if (res) {
          toast.custom({
            title: t('toast.deleteSuccessful'),
          });
          getFavoriteDetails();
          getAllFavoriteDetails();
          getSharedFavoriteDetails();
          setShowSaveFav(false);
        }
      });
  };

  const checkNameExist = async () => {
    const favouriteName = favoriteName;
    await api
      .get({
        url: getAPIRoute('reportFavoritesNameExist', [favouriteName]),
        params: { reportHeaderId: reportHeaderId },
      })
      .then((res) => {
        if (editFav) {
          if (addOrEdit) {
            updateFavorite();
            setEditFav(false);
            setAddOrEdit(false);
            setShowSaveFav(false);
          } else {
            setShowConfirmEdit(true);
          }
        } else if (res) {
          toast.error({
            title: 'Error',
            message: favouriteName + ' ' + t('errors.favNameExist'),
          });
        } else if (isDuplicateFav) {
          duplicateFavorite();
        } else {
          checkLayoutIsPublic();
        }
      });
  };

  const checkLayoutIsPublic = () => {
    if (isPrivate) {
      saveFavorite();
    } else {
      if (layoutIsPublic) {
        saveFavorite();
      } else {
        setShowShareLayout(true);
      }
    }
  };

  const saveFavorite = () => {
    const favoriteData: ISaveFavorite = {
      reportFavourite: {
        reportFavoriteId: 0,
        favoriteName: favoriteName,
        reportHeaderId: reportHeaderId,
        reportOptions: {
          sortByName: reportOptions ? reportOptions : 'companyname',
        },
        isPrivate: isPrivate,
        reportLayoutId: layoutId,
        isDefault: isDefault,
        isShareLayout: false,
        createdUserName: userName,
        userId: userId,
      },
      reportFavouriteFilter: reportFavouriteFilters,
    };
    api
      .post({ url: apiConfig.favourites, data: favoriteData })
      .then((response) => {
        if (response) {
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
          getFavoriteDetails();
          getAllFavoriteDetails();
          getSharedFavoriteDetails();
          setShowSaveFav(false);
          setIsPrivate(false);
          setIsDefault(false);
        }
      });
  };

  const saveFavoriteShareLayout = (isShareLayout: boolean) => {
    const favoriteData: ISaveFavorite = {
      reportFavourite: {
        reportFavoriteId: 0,
        favoriteName: favoriteName,
        reportHeaderId: reportHeaderId,
        reportOptions: {
          sortByName: reportOptions ? reportOptions : 'companyname',
        },
        isPrivate: isPrivate,
        reportLayoutId: isShareLayout
          ? layoutId
          : allLayoutsData[standardLayoutIndex]?.reportLayoutId,
        isDefault: isDefault,
        isShareLayout: isShareLayout,
        createdUserName: userName,
        userId: userId,
      },
      reportFavouriteFilter: reportFavouriteFilters,
    };
    api
      .post({ url: apiConfig.favourites, data: favoriteData })
      .then((response) => {
        if (response) {
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
          getFavoriteDetails();
          getAllFavoriteDetails();
          getSharedFavoriteDetails();
          setShowSaveFav(false);
          setIsPrivate(false);
          setIsDefault(false);
        }
      });
  };

  const editFavorite = () => {
    const date = new Date();
    const favoriteData: IDuplicateFavorite = {
      companyId: duplicateFavData?.companyId
        ? duplicateFavData.companyId
        : null,
      reportFavoriteId: duplicateFavData?.reportFavoriteId
        ? duplicateFavData?.reportFavoriteId
        : 0,
      favoriteName: favoriteName,
      reportHeaderId: reportHeaderId,
      reportOptions: {
        sortByName: duplicateFavData?.reportOptionsObject?.sortByName
          ? duplicateFavData?.reportOptionsObject?.sortByName
          : '',
      },
      isPrivate: isPrivate,
      reportLayoutId: duplicateFavData?.reportLayoutId
        ? duplicateFavData?.reportLayoutId
        : layoutId,
      isDefault: isDefault,
      isShareLayout: false,
      createdUserName: userName,
      // createdBy: userName,
      createdDate: date.toISOString(),
      userId: duplicateFavData?.userId ? duplicateFavData?.userId : null,
    };
    api
      .put({ url: apiConfig.favourites + 'header', data: favoriteData })
      .then((response) => {
        setEditFav(false);
        if (response) {
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
          getFavoriteDetails();
          getAllFavoriteDetails();
          getSharedFavoriteDetails();
          getLayoutDetails();
          setShowSaveFav(false);
          setIsPrivate(false);
          setIsDefault(false);
        }
      });
  };

  const makeFavDefault = () => {
    const date = new Date();
    const favoriteData: IDuplicateFavorite = {
      companyId: duplicateFavData?.companyId
        ? duplicateFavData.companyId
        : null,
      reportFavoriteId: duplicateFavData?.reportFavoriteId
        ? duplicateFavData?.reportFavoriteId
        : 0,
      favoriteName: duplicateFavData?.favoriteName
        ? duplicateFavData?.favoriteName
        : '',
      reportHeaderId: reportHeaderId,
      reportOptions: {
        sortByName: duplicateFavData?.reportOptionsObject?.sortByName
          ? duplicateFavData?.reportOptionsObject?.sortByName
          : '',
      },
      isPrivate: duplicateFavData?.isPrivate
        ? duplicateFavData?.isPrivate
        : false,
      reportLayoutId: duplicateFavData?.reportLayoutId
        ? duplicateFavData?.reportLayoutId
        : layoutId,
      isDefault: true,
      isShareLayout: false,
      createdUserName: duplicateFavData?.createdUserName
        ? duplicateFavData?.createdUserName
        : '',
      // createdBy: duplicateFavData?.createdUserName
      //   ? duplicateFavData?.createdUserName
      //   : '',
      createdDate: date.toISOString(),
      userId: duplicateFavData?.userId ? duplicateFavData?.userId : null,
    };
    api
      .put({ url: apiConfig.favourites + 'header', data: favoriteData })
      .then((response) => {
        if (response) {
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
          getFavoriteDetails();
          getAllFavoriteDetails();
          getSharedFavoriteDetails();
          setShowSaveFav(false);
          setEditFav(false);
          setIsPrivate(false);
          setIsDefault(false);
        }
      });
  };

  const updateFavorite = () => {
    const favoriteData: ISaveFavorite = {
      reportFavourite: {
        companyId: duplicateFavData?.companyId
          ? duplicateFavData.companyId
          : null,
        reportFavoriteId: duplicateFavData?.reportFavoriteId
          ? duplicateFavData?.reportFavoriteId
          : 0,
        favoriteName: favoriteName,
        reportHeaderId: reportHeaderId,
        reportOptions: {
          sortByName: reportOptions ? reportOptions : 'companyname',
        },
        isPrivate: duplicateFavData?.isPrivate
          ? duplicateFavData?.isPrivate
          : false,
        reportLayoutId: layoutId,
        isDefault: duplicateFavData?.isDefault
          ? duplicateFavData?.isDefault
          : false,
        isShareLayout: false,
        createdUserName: userName,
        // createdBy: userName,
        // createdDate: date.toISOString(),
        userId: userId,
      },
      reportFavouriteFilter: reportFavouriteFilters,
    };
    api
      .put({ url: apiConfig.favourites, data: favoriteData })
      .then((response) => {
        setEditFav(false);
        setAddOrEdit(false);
        setShowSaveFav(false);
        if (response) {
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
          getFavoriteDetails();
          getAllFavoriteDetails();
          getSharedFavoriteDetails();
          setIsPrivate(false);
          setIsDefault(false);
          // setShowSaveFav(false);
          // setEditFav(false);
          // setAddOrEdit(false);
        }
      });
  };

  const duplicateFavorite = () => {
    const date = new Date();
    if (duplicateFavData) {
      const favoriteData: IDuplicateFavorite = {
        reportFavoriteId: 0,
        favoriteName: favoriteName,
        reportHeaderId: reportHeaderId,
        reportOptions: {
          sortByName: duplicateFavData?.reportOptionsObject?.sortByName
            ? duplicateFavData?.reportOptionsObject?.sortByName
            : '',
        },
        isPrivate: duplicateFavData?.isPrivate
          ? duplicateFavData?.isPrivate
          : false,
        reportLayoutId: duplicateFavData?.reportLayoutId
          ? duplicateFavData?.reportLayoutId
          : layoutId,
        isDefault: duplicateFavData?.isDefault
          ? duplicateFavData?.isDefault
          : false,
        isShareLayout: false,
        createdUserName: userName,
        // createdBy: userName,
        createdDate: date.toISOString(),
        userId: duplicateFavData?.userId ? duplicateFavData?.userId : null,
      };
      api
        .post({
          url: apiConfig.duplicateFavorite + duplicateFavData?.reportFavoriteId,
          data: favoriteData,
        })
        .then((response) => {
          if (response) {
            toast.custom({
              title: t('toast.savedSuccessfully'),
            });
            getFavoriteDetails();
            getAllFavoriteDetails();
            getSharedFavoriteDetails();
            setShowSaveFav(false);
            setIsDuplicateFav(false);
            setIsPrivate(false);
            setIsDefault(false);
          }
        });
    }
  };

  const { isMobileResolution } = useResize();
  const [position, setPosition] = useState<any>('bottom');
  useEffect(() => {
    if (isMobileResolution) {
      setPosition('left');
    } else {
      setPosition('bottom');
    }
  }, [isMobileResolution]);

  return (
    <>
      <div className="m-l-inner-page-header">
        <div className="m-l-inner-page-header-left-block">
          <div className="m-l-page-main-heading">
            {' '}
            {reportCode == 'bfr' ? 'Berth Fit Report' : 'Vet Report'}
          </div>
        </div>
        <div className="m-l-inner-page-header-right-block">
          <div className="m-c-form__inline m-c-form__report">
            <div className="m-c-form__icon">
              <Button
                id="fav"
                className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                type="normal"
                onClick={() => {
                  setShowFavPopover(true);
                }}
              >
                <div className="m-c-icon-inside-btn">
                  <icons.Heart />
                </div>
              </Button>
              <Popover
                target="#fav"
                position={position}
                width={132}
                visible={showFavPopover}
                wrapperAttr={layoutPopOverAttributes}
                closeOnOutsideClick={true}
                onHiding={() => setShowFavPopover(false)}
              >
                <ul className="ml-expport-list">
                  <li onClick={addOrUpdate}> Save Favorite </li>
                  <li onClick={() => setFavVisible(true)}> Manage Favorite </li>
                </ul>
              </Popover>
            </div>
            <div className="m-c-form__input">
              <div className="m-c-form-group">
                <SelectBox
                  className="m-c-input-control m-c-select-box"
                  dataSource={allFavoritesData}
                  displayExpr={'favoriteName'}
                  valueExpr={'reportFavoriteId'}
                  onValueChanged={setSelectedFavoriteId}
                  value={favoriteId}
                  searchEnabled={true}
                />
              </div>
            </div>
          </div>
          <div className="m-c-form__inline m-c-form__report">
            <div className="m-c-form__icon">
              <Button
                id="layout"
                className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                aria-label="Export-btn"
                onClick={() => setShowLayoutPopover(true)}
              >
                <div className="m-c-icon-inside-btn" data-testid="export-all">
                  <icons.Layout />
                </div>
              </Button>
              <Popover
                target="#layout"
                position={position}
                width={132}
                visible={showLayoutPopover}
                wrapperAttr={layoutPopOverAttributes}
                closeOnOutsideClick={true}
                onHiding={() => setShowLayoutPopover(false)}
              >
                <ul className="ml-expport-list">
                  <li onClick={() => setIsVisible(true)}> Manage layout </li>
                </ul>
              </Popover>
            </div>
            <div className="m-c-form__input">
              <div className="m-c-form-group">
                <SelectBox
                  dataSource={allLayoutsData}
                  displayExpr={'layoutName'}
                  valueExpr={'reportLayoutId'}
                  className="m-c-input-control m-c-select-box"
                  value={layoutId}
                  onValueChanged={setSelectedLayoutId}
                  searchEnabled={true}
                />
              </div>
            </div>
          </div>
          {isStandardLayout ? null : (
            <Restricted permission="ReportingFrameWork.ReportDesigner">
              <Button
                id="manage"
                className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                type="normal"
                onClick={onClickSettings}
              >
                <Link
                  className="m-l-page-main-heading"
                  onClick={onClickSettings}
                  to={{ pathname: '/report-designer' }}
                  target="_blank"
                >
                  <div className="m-c-icon-inside-btn">
                    <icons.IconSettings />
                  </div>
                </Link>
              </Button>
            </Restricted>
          )}
        </div>

        {favVisible ? (
          <ManageFavorite
            setFavVisible={setFavVisible}
            favVisible={favVisible}
            favoritesData={favoritesData}
            sharedFavoritesData={sharedFavoritesData}
            setIsDuplicateFav={setIsDuplicateFav}
            setShowSaveFav={setShowSaveFav}
            setDuplicateFavData={setDuplicateFavData}
            deleteFavorite={deleteFavorite}
            setEditFav={setEditFav}
            setShowConfirmEdit={setShowConfirmEdit}
            showConfirmEdit={showConfirmEdit}
            editFavorite={editFavorite}
            makeFavDefault={makeFavDefault}
            setIsDefault={setIsDefault}
            setIsPrivate={setIsPrivate}
          />
        ) : null}

        {showSaveFav ? (
          <SaveFavorite
            setFavoriteName={setFavoriteName}
            setIsDefault={setIsDefault}
            setIsPrivate={setIsPrivate}
            setShowSaveFav={setShowSaveFav}
            showSaveFav={showSaveFav}
            checkNameExist={checkNameExist}
            isDuplicateFav={isDuplicateFav}
            setIsDuplicateFav={setIsDuplicateFav}
            editFav={editFav}
            setEditFav={setEditFav}
            duplicateFavData={duplicateFavData}
            addOrEdit={addOrEdit}
            updateFavorite={updateFavorite}
          />
        ) : null}

        {isVisible ? (
          <ManageLayout
            setIsVisible={setIsVisible}
            isVisible={isVisible}
            layoutsData={layoutsData}
            sharedLayoutsData={sharedLayoutsData}
            getLayoutDetails={getLayoutDetails}
            getSharedLayoutDetails={getSharedLayoutDetails}
            reportHeaderId={reportHeaderId}
            allLayoutsData={allLayoutsData}
            getAllLayoutDetails={getAllLayoutDetails}
            getAllFavoriteDetails={getAllFavoriteDetails}
          />
        ) : null}
        <ConfirmDialog
          dialogConfig={confirmAddOrUpdate}
          isOpen={showAddOrEdit}
        />
        <ConfirmDialog
          dialogConfig={confirmShareLayout}
          isOpen={showShareLayout}
        />
      </div>
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <div className="m-c-form__inline m-c-form__report">
                <div className="m-c-form__icon">
                  <Button
                    id="fav2"
                    className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                    type="normal"
                    onClick={() => {
                      setShowFavPopover(true);
                    }}
                  >
                    <div className="m-c-icon-inside-btn">
                      <icons.Heart />
                    </div>
                  </Button>
                  <Popover
                    target="#fav"
                    position="right"
                    width={132}
                    visible={showFavPopover}
                    wrapperAttr={layoutPopOverAttributes}
                    closeOnOutsideClick={true}
                    onHiding={() => setShowFavPopover(false)}
                  >
                    <ul className="ml-expport-list">
                      <li onClick={addOrUpdate}> Save Favorite </li>
                      <li onClick={() => setFavVisible(true)}>
                        {' '}
                        Manage Favorite{' '}
                      </li>
                    </ul>
                  </Popover>
                </div>
                <div className="m-c-form__input">
                  <div className="m-c-form-group">
                    <SelectBox
                      className="m-c-input-control m-c-select-box"
                      dataSource={allFavoritesData}
                      displayExpr={'favoriteName'}
                      valueExpr={'reportFavoriteId'}
                      onValueChanged={setSelectedFavoriteId}
                      value={favoriteId}
                      searchEnabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="m-c-form__inline m-c-form__report">
                <div className="m-c-form__icon">
                  <Button
                    id="layout2"
                    className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                    aria-label="Export-btn"
                    onClick={() => setShowLayoutPopover(true)}
                  >
                    <div
                      className="m-c-icon-inside-btn"
                      data-testid="export-all"
                    >
                      <icons.Layout />
                    </div>
                  </Button>
                  <Popover
                    target="#layout2"
                    position="right"
                    width={132}
                    visible={showLayoutPopover}
                    wrapperAttr={layoutPopOverAttributes}
                    closeOnOutsideClick={true}
                    onHiding={() => setShowLayoutPopover(false)}
                  >
                    <ul className="ml-expport-list">
                      <li onClick={() => setIsVisible(true)}>
                        {' '}
                        Manage layout{' '}
                      </li>
                    </ul>
                  </Popover>
                </div>
                <div className="m-c-form__input">
                  <div className="m-c-form-group">
                    <SelectBox
                      dataSource={allLayoutsData}
                      displayExpr={'layoutName'}
                      valueExpr={'reportLayoutId'}
                      className="m-c-input-control m-c-select-box"
                      value={layoutId}
                      onValueChanged={setSelectedLayoutId}
                      searchEnabled={true}
                    />
                  </div>
                </div>
              </div>
              {isStandardLayout ? null : (
                <Restricted permission="ReportingFrameWork.ReportDesigner">
                  <Button
                    id="manage"
                    className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                    type="normal"
                    onClick={onClickSettings}
                  >
                    <Link
                      className="m-l-page-main-heading"
                      onClick={onClickSettings}
                      to={{ pathname: '/report-designer' }}
                      target="_blank"
                    >
                      <div className="m-c-icon-inside-btn">
                        <icons.IconSettings />
                      </div>
                    </Link>
                  </Button>
                </Restricted>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
