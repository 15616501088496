import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Export,
  Grid,
  Legend,
  Series,
  Tooltip,
} from 'devextreme-react/chart';
import React from 'react';

export default function LineChart(props: {
  dataSource: any;
  series: any;
  chartConfig: any;
  chartRef?: any;
}) {
  const { dataSource, series, chartConfig, chartRef } = props;
  const toolTipRender = (e: any) => {
    return {
      text: `${e.value} h`,
    };
  };
  return (
    <>
      <Chart palette="Violet" dataSource={dataSource} ref={chartRef}>
        <CommonSeriesSettings
          argumentField={chartConfig.argumentField}
          type="line"
        />
        {series.map((item: any) => (
          <Series key={item.name} valueField={item.name} name={item.name} />
        ))}
        <ArgumentAxis
          valueMarginsEnabled={false}
          discreteAxisDivisionMode="crossLabels"
        >
          <Grid visible={true} />
        </ArgumentAxis>
        <Tooltip
          enabled={true}
          customizeTooltip={(e: any) => toolTipRender(e)}
        />
        <Export enabled={false} />
        <Legend
          verticalAlignment="bottom"
          horizontalAlignment="center"
          itemTextPosition="bottom"
        />
      </Chart>
    </>
  );
}
