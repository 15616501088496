import Button from 'devextreme-react/button';
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IVesselDimensionsForm } from '../../../../types/vessel-dimensions';
import { IVesselDimensionsEdit } from '../../../../types/vessel-dimensions-edit';
import VesselFormDimensions from './vessel-dimensions-form/VesselDimensionsForm';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import toast from '../../../../utils/toast';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../../types/dialog';
import { IOptionvalue } from '../../../../types/master-data';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import { isTabDirtyContext } from '../../../../pages/vessel-info/VesselInformation';
import { useDimensionsUnit } from '../../../../hooks/useDimensionsUnit';
export default function VesselDimensionEdit(props: {
  vesseldimensionList: any;
  setIsDimensionEdit: any;
  GetDimensions: any;
  imoNumber?: string;
}) {
  const { setIsDimensionEdit, vesseldimensionList, GetDimensions, imoNumber } =
    props;
  const { t } = useTranslation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isconfirmBackDialog, setIsConfirmBackDialog] = useState(false);
  const [yesNo, setYesNo] = useState<IOptionvalue[]>([]);
  const { setIsLoading } = useLoaderContext();
  const { dimensionUnit, feetToMeterConversion, meterToFeetConversion } =
    useDimensionsUnit();
  const setTabDirtyFlag = useContext(isTabDirtyContext);
  const getYesNo = async () => {
    await api
      .get({
        url: apiConfig.yesNo,
      })
      .then((res: IOptionvalue[]) => {
        setYesNo(res);
      });
  };

  const defaultVesselFormValues: IVesselDimensionsForm = {
    lengthoverall: 0,
    lengthreg: 0,
    lengthbp: 0,
    breadthregistered: 0,
    breadthextreme: 0,
    breadthmoulded: 0,
    depth: 0,
    draught: 0,
    ktminnormalballast: 0,
    ktminsummerdraft: 0,
    manifoldheightinnormalballast: 0,
    manifoldheightinsummerdraft: 0,
    displacement: 0,
    bulbousbow: false,
    tcm: 0,
    pblinnormalballast: 0,
    pblinsummerdraft: 0,
    scm: 0,
    bcm: 0,
    freeboardsummer: 0,
    freeboardwinter: 0,
    freeboardtropical: 0,
    freeboardBallast: 0,
    hosederrick: 0,
    pblforwardArrival: 0,
    pblarrival: 0,
    distancefrommanifold: 0,
    heighOfmanifold: 0,
    manifoldspacing: 0,
    vapourrecoverysystem: 0,
    manifoldsize: 0,
    connectortype: 0,
    loadingrate: 0,
  };

  const vesselDimensionsForm = useForm({
    defaultValues: defaultVesselFormValues,
  });

  const onHandleSave = () => {
    setIsConfirmModalOpen(true);
  };

  const { isDirty } = vesselDimensionsForm.formState;

  const handleBackClick = () => {
    isDirty ? setIsConfirmBackDialog(true) : setIsDimensionEdit(false);
  };

  const { getValues } = vesselDimensionsForm;
  let listDimensionsFormValue: IVesselDimensionsForm = getValues();
  const onValueSubmit = () => {
    listDimensionsFormValue = vesselDimensionsForm.getValues();
    const roleJsonEdit: IVesselDimensionsEdit = {
      imoNumber: imoNumber,
      lengthOverallLOA: feetToMeterConversion(
        listDimensionsFormValue.lengthoverall
      ),
      lengthRegistered: feetToMeterConversion(
        listDimensionsFormValue.lengthreg
      ),
      lengthBetweenPerpendiculars: feetToMeterConversion(
        listDimensionsFormValue.lengthbp
      ),
      breadthRegistered: feetToMeterConversion(
        listDimensionsFormValue.breadthregistered
      ),
      breadthExtreme: feetToMeterConversion(
        listDimensionsFormValue.breadthextreme
      ),
      breadthMoulded: feetToMeterConversion(
        listDimensionsFormValue.breadthmoulded
      ),
      depth: feetToMeterConversion(listDimensionsFormValue.depth),
      draught: feetToMeterConversion(listDimensionsFormValue.draught),
      keeltoMastNormalBallast: feetToMeterConversion(
        listDimensionsFormValue.ktminnormalballast
      ),
      keeltoMastSummerBallast: feetToMeterConversion(
        listDimensionsFormValue.ktminsummerdraft
      ),
      manifoldHeightNormalBallast: feetToMeterConversion(
        listDimensionsFormValue.manifoldheightinnormalballast
      ),
      manifoldHeightSummerDraft: feetToMeterConversion(
        listDimensionsFormValue.manifoldheightinsummerdraft
      ),
      displacement: listDimensionsFormValue.displacement,
      bulbousBow: listDimensionsFormValue.bulbousbow,
      tonnesPerCentimetreImmersion: listDimensionsFormValue.tcm,
      parallelBodyLengthBallast: feetToMeterConversion(
        listDimensionsFormValue.pblinnormalballast
      ),
      parallelBodyLengthSummer: feetToMeterConversion(
        listDimensionsFormValue.pblinsummerdraft
      ),
      sterntoCentreManifold: feetToMeterConversion(listDimensionsFormValue.scm),
      bowtoCentreManifold: feetToMeterConversion(listDimensionsFormValue.bcm),
      freeboardSummer: feetToMeterConversion(
        listDimensionsFormValue.freeboardsummer
      ),
      freeboardWinter: feetToMeterConversion(
        listDimensionsFormValue.freeboardwinter
      ),
      freeboardTropical: feetToMeterConversion(
        listDimensionsFormValue.freeboardtropical
      ),
      freeboardBallast: feetToMeterConversion(
        listDimensionsFormValue.freeboardBallast
      ),
      hoseDerrickSWL: listDimensionsFormValue.hosederrick,
      pblForwardArrival: feetToMeterConversion(
        listDimensionsFormValue.pblforwardArrival
      ),
      pblAftArrival: feetToMeterConversion(listDimensionsFormValue.pblarrival),
      distancefromManifoldtoShipSideRail: feetToMeterConversion(
        listDimensionsFormValue.distancefrommanifold
      ),
      heightofManifoldAboveDeckDripTray: feetToMeterConversion(
        listDimensionsFormValue.heighOfmanifold
      ),
      manifoldSize: feetToMeterConversion(listDimensionsFormValue.manifoldsize),
      manifoldSpacing: feetToMeterConversion(
        listDimensionsFormValue.manifoldspacing
      ),
      loadingRate: listDimensionsFormValue.loadingrate,
      connectorType: listDimensionsFormValue.connectortype,
      vapourRecoverySystem: listDimensionsFormValue.vapourrecoverysystem,
      imoDisplayName: vesseldimensionList.imoDisplayName,
      // vapourRecoverySystem: listDimensionsFormValue.bowtocentermanifold,
    };
    api
      .put(
        { url: apiConfig.vesselDimensionTab, data: roleJsonEdit },
        setIsLoading
      )
      .then((response) => {
        if (response == true) {
          GetDimensions();
          setIsDimensionEdit(false);
          setTabDirtyFlag.setState(
            setTabDirtyFlag.state == 0
              ? setTabDirtyFlag.state
              : setTabDirtyFlag.state - 1
          );
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
        }
      });
  };
  useEffect(() => {
    vesselDimensionsForm.reset();
    if (vesseldimensionList) {
      vesselDimensionsForm.reset(
        {
          lengthoverall: meterToFeetConversion(
            vesseldimensionList.lengthOverallLOA
          ),
          lengthreg: meterToFeetConversion(
            vesseldimensionList.lengthRegistered
          ),
          lengthbp: meterToFeetConversion(
            vesseldimensionList.lengthBetweenPerpendiculars
          ),
          breadthregistered: meterToFeetConversion(
            vesseldimensionList.breadthRegistered
          ),
          breadthextreme: meterToFeetConversion(
            vesseldimensionList.breadthExtreme
          ),
          breadthmoulded: meterToFeetConversion(
            vesseldimensionList.breadthMoulded
          ),
          depth: meterToFeetConversion(vesseldimensionList.depth),
          draught: meterToFeetConversion(vesseldimensionList.draught),
          ktminnormalballast: meterToFeetConversion(
            vesseldimensionList.keeltoMastNormalBallast
          ),
          ktminsummerdraft: meterToFeetConversion(
            vesseldimensionList.keeltoMastSummerBallast
          ),
          manifoldheightinnormalballast: meterToFeetConversion(
            vesseldimensionList.manifoldHeightNormalBallast
          ),
          manifoldheightinsummerdraft: meterToFeetConversion(
            vesseldimensionList.manifoldHeightSummerDraft
          ),
          displacement: vesseldimensionList.displacement,
          bulbousbow: vesseldimensionList.bulbousBow,
          tcm: vesseldimensionList.tonnesPerCentimetreImmersion,
          pblinnormalballast: meterToFeetConversion(
            vesseldimensionList.parallelBodyLengthBallast
          ),
          pblinsummerdraft: meterToFeetConversion(
            vesseldimensionList.parallelBodyLengthSummer
          ),
          scm: meterToFeetConversion(vesseldimensionList.sterntoCentreManifold),
          bcm: meterToFeetConversion(vesseldimensionList.bowtoCentreManifold),
          freeboardsummer: meterToFeetConversion(
            vesseldimensionList.freeboardSummer
          ),
          freeboardwinter: meterToFeetConversion(
            vesseldimensionList.freeboardWinter
          ),
          freeboardtropical: meterToFeetConversion(
            vesseldimensionList.freeboardTropical
          ),
          freeboardBallast: meterToFeetConversion(
            vesseldimensionList.freeboardBallast
          ),
          hosederrick: vesseldimensionList.hoseDerrickSWL,
          pblforwardArrival: meterToFeetConversion(
            vesseldimensionList.pblForwardArrival
          ),
          pblarrival: meterToFeetConversion(vesseldimensionList.pblAftArrival),
          distancefrommanifold: meterToFeetConversion(
            vesseldimensionList.distancefromManifoldtoShipSideRail
          ),
          heighOfmanifold: meterToFeetConversion(
            vesseldimensionList.heightofManifoldAboveDeckDripTray
          ),
          manifoldspacing: meterToFeetConversion(
            vesseldimensionList.manifoldSpacing
          ),
          vapourrecoverysystem: vesseldimensionList.vapourRecoverySystem,
          manifoldsize: meterToFeetConversion(vesseldimensionList.manifoldSize),
          connectortype: vesseldimensionList.connectorType,
          loadingrate: vesseldimensionList.loadingRate,
        },
        { keepDirty: true }
      );
    }
  }, [vesseldimensionList, dimensionUnit]);

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      onValueSubmit();
      setIsConfirmModalOpen(false);
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };
  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setIsDimensionEdit(false);
      setIsConfirmBackDialog(false);
      setTabDirtyFlag.setState(
        setTabDirtyFlag.state == 0
          ? setTabDirtyFlag.state
          : setTabDirtyFlag.state - 1
      );
    },
    handleClose: () => {
      setIsConfirmBackDialog(false);
    },
  };

  useEffect(() => {
    getYesNo();
  }, []);

  useEffect(() => {
    if (isDirty) {
      setTabDirtyFlag.setState(setTabDirtyFlag.state + 1);
    }
  }, [isDirty]);

  return (
    <div className="m-l-form-panel-block">
      <div className="m-l-tbl-square-wrapper m-l-tbl-square-wrapper--edit-mode">
        <div className="row m-l-custom-eight-space-row">
          <FormProvider {...vesselDimensionsForm}>
            <VesselFormDimensions yesNo={yesNo} />
          </FormProvider>
        </div>
        <div className="m-l-panel-right-btn u-pb0">
          <Button
            className="app-c-btn app-c-btn--secondary min-btn-width"
            onClick={handleBackClick}
          >
            {t('labels.cancel')}
          </Button>
          <Button
            onClick={onHandleSave}
            className="app-c-btn app-c-btn--primary min-btn-width"
          >
            {t('labels.save')}
          </Button>
          <ConfirmDialog
            dialogConfig={confirmSaveDialogConfig}
            isOpen={isConfirmModalOpen}
          />
          <ConfirmDialog
            dialogConfig={confirmBackDialogConfig}
            isOpen={isconfirmBackDialog}
          />
        </div>
      </div>
    </div>
  );
}
