import React from 'react';
import Button from 'devextreme-react/button';
import Icons from '../icons/icons';

export default function FieldArrayAddButton(props: {
  fieldArray: any;
  index: any;
  appendValues: any;
  appendLimit: any;
}) {
  const { fieldArray, index, appendValues, appendLimit } = props;
  return (
    <div>
      {fieldArray?.fields?.length != 1 &&
        ((index >= 0 && index != fieldArray?.fields.length - 1) ||
          index == appendLimit.appendLimit - 1) && (
          <Button
            elementAttr={{ 'data-testid': 'fieldArrayAddButton' }}
            onClick={() => fieldArray.remove(index)}
            className="app-c-btn app-c-btn--remove min-btn-width-sm-pls"
          >
            <div className="m-c-icon-inside-btn">
              <Icons.RemoveIcon />
            </div>
          </Button>
        )}
      {(fieldArray?.fields?.length == 1 ||
        index == fieldArray?.fields.length - 1) &&
        index < appendLimit.appendLimit - 1 && (
          <Button
            elementAttr={{ 'data-testid': 'fieldArrayRemoveButton' }}
            onClick={() => {
              fieldArray.append(appendValues);
            }}
            className="app-c-btn app-c-btn--secondary min-btn-width-sm-pls"
          >
            <div className="m-c-icon-inside-btn">
              <Icons.AddIcon />
            </div>
          </Button>
        )}
    </div>
  );
}
