import { format, parseISO } from 'date-fns';

export const formatDateString = (date: string) => {
  return format(parseISO(date), 'MMM dd yyyy');
};

export const formatDate = (date: any) => {
  if (date) {
    return format(parseISO(date), 'dd MMM yyyy');
  } else {
    return null;
  }
};

export const formatDate2 = (date?: any) => {
  if (date) {
    return new Date(date).toISOString();
  } else {
    return null;
  }
};

export const formatDate3 = (date?: any) => {
  if (date) {
    return format(parseISO(date), 'yyyy-MM-dd');
  } else {
    return null;
  }
};

export const apiDateFormat = (date?: any) => {
  if (date) {
    const dateFormated = format(new Date(date), 'dd MMM yyyy ');
    const time = new Date(date).toLocaleTimeString('en', {
      timeStyle: 'short',
    });
    return dateFormated + time;
  } else {
    return null;
  }
};

export const apiDateTimeFormat = (date?: any) => {
  if (date) {
    return new Date(date).toLocaleTimeString();
  }
  return null;
};

export const getPreviousDate = (date: any) => {
  const yesterday = new Date(date);
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};
