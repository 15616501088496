import DateBox from 'devextreme-react/date-box';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import Validator, {
  PatternRule,
  RequiredRule,
} from 'devextreme-react/validator';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CompanyType } from '../../../../enums/company-type-enum';
import { Entity } from '../../../../enums/entity-enum';
import { useDataStore } from '../../../../hooks/useDataStore';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import { useDimensionsUnit } from '../../../../hooks/useDimensionsUnit';
import { IRuleAdditionalFields } from '../../../../types/vessel-info-rules';
import { getCompanyType } from '../../../../utils/jwt-decode';

export default function AdditionalInfoFormFields(props: {
  additionalInfoDetails: IRuleAdditionalFields[];
  index: number;
}) {
  const { additionalInfoDetails, index } = props;

  const { t } = useTranslation();

  const { control } = useFormContext();

  const { dateFormat } = useDateFormat();

  const { getDataSource } = useDataStore();

  const { dimensionUnitText } = useDimensionsUnit();

  const companyType = getCompanyType();

  const dataSource = useMemo(() => {
    if (additionalInfoDetails.length) {
      return getDataSource(
        additionalInfoDetails[index].selectedListValue,
        additionalInfoDetails[index].selectList
      );
    }
    return [];
  }, [additionalInfoDetails]);

  const disableHostedCompanyUser = useMemo(() => {
    return (
      additionalInfoDetails[index]?.entityName !== Entity.ShipVetRelatedInfos &&
      additionalInfoDetails[index]?.entityName !== Entity.ShipCapRatings &&
      additionalInfoDetails[index]?.entityName !== Entity.ShipSireInfos &&
      companyType === CompanyType.HostedCompany
    );
  }, [index, additionalInfoDetails]);

  return (
    <div
      className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col"
      data-testid="additionalInfoFormFields"
    >
      <div className="m-c-form-group mb-btm-space">
        <label className="m-l-input-label">
          {additionalInfoDetails[index].isConversionRequired
            ? additionalInfoDetails[index].attributeDisplayName +
              dimensionUnitText()
            : additionalInfoDetails[index].attributeDisplayName}
        </label>
        <Controller
          name={`additionalInfoFieldArray[${index}].fieldValue`}
          control={control}
          render={({ field }) => {
            if (additionalInfoDetails[index].attributeType === 'Boolean') {
              return (
                <div className="m-c-form-group">
                  <SelectBox
                    dataSource={[
                      { label: 'Yes', value: 'True' },
                      { label: 'No', value: 'False' },
                    ]}
                    displayExpr="label"
                    valueExpr="value"
                    value={field.value}
                    onValueChanged={(e) => field.onChange(e.value)}
                    className="m-c-input-control  m-c-select-box"
                    validationMessageMode="always"
                    searchEnabled={false}
                    disabled={disableHostedCompanyUser}
                  >
                    <Validator>
                      {additionalInfoDetails[index].isMandatory && (
                        <RequiredRule message={t('errors.required')} />
                      )}
                    </Validator>
                  </SelectBox>
                </div>
              );
            } else if (additionalInfoDetails[index].selectList) {
              return (
                <div className="m-c-form-group">
                  <SelectBox
                    name="fieldValue"
                    className="m-c-input-control  m-c-select-box"
                    dataSource={dataSource}
                    displayExpr={
                      additionalInfoDetails[index].selectedListDisplay
                    }
                    valueExpr={additionalInfoDetails[index].selectedListValue}
                    searchEnabled={true}
                    onValueChanged={(e) => field.onChange(e.value)}
                    value={field.value}
                    validationMessageMode="always"
                    disabled={
                      !additionalInfoDetails[index].isEditable ||
                      disableHostedCompanyUser
                    }
                  >
                    <Validator>
                      {additionalInfoDetails[index].isMandatory && (
                        <RequiredRule message={t('errors.required')} />
                      )}
                    </Validator>
                  </SelectBox>
                </div>
              );
            } else if (
              additionalInfoDetails[index].attributeType !== 'DateTime' &&
              additionalInfoDetails[index].attributeType !== 'Date'
            ) {
              return (
                <div className="m-c-form-group">
                  <TextBox
                    name={'fieldValue'}
                    onValueChanged={(e) => field.onChange(e.value)}
                    value={field.value}
                    className="m-c-input-control"
                    validationMessageMode="always"
                    disabled={
                      !additionalInfoDetails[index].isEditable ||
                      disableHostedCompanyUser
                    }
                  >
                    <Validator>
                      {additionalInfoDetails[index].isMandatory && (
                        <RequiredRule message={t('errors.required')} />
                      )}
                      {additionalInfoDetails[index].attributeType ===
                        'Number' && (
                        <PatternRule
                          pattern={/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/}
                          message={t('errors.numberValidation')}
                        />
                      )}
                    </Validator>
                  </TextBox>
                </div>
              );
            } else {
              return (
                <div className="m-c-form-group">
                  <DateBox
                    name="fieldValue"
                    className="m-c-input-control m-c-date-picker"
                    onValueChanged={(e) => field.onChange(e.value)}
                    value={field.value}
                    displayFormat={dateFormat}
                    validationMessageMode="always"
                    disabled={
                      !additionalInfoDetails[index].isEditable ||
                      disableHostedCompanyUser
                    }
                  >
                    <Validator>
                      {additionalInfoDetails[index].isMandatory && (
                        <RequiredRule message={t('errors.required')} />
                      )}
                    </Validator>
                  </DateBox>
                </div>
              );
            }
          }}
        />
      </div>
    </div>
  );
}
