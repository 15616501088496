import React from 'react';
import { useTranslation } from 'react-i18next';
import EmailSettingHtmlEditor from './EmailSettingHtmlEditor';

export default function EmailSettingForm(props: {
  entities: any;
  cSalutation: any;
  setCSalutation: any;
  vSalutation: any;
  setVSalutation: any;
  cSignature: any;
  setCSignature: any;
  vSignature: any;
  setVSignature: any;
  confidentiality: any;
  setConfidentiality: any;
  showEditor: any;
  showVSalutation?: any;
  showCSignature?: any;
  showVSignature?: any;
  showConfidentiality?: any;
  ShowCSalutation?: any;
}) {
  const {
    entities,
    cSalutation,
    setCSalutation,
    vSalutation,
    setVSalutation,
    cSignature,
    setCSignature,
    vSignature,
    setVSignature,
    confidentiality,
    setConfidentiality,
    showVSalutation,
    showCSignature,
    showVSignature,
    showConfidentiality,
    ShowCSalutation,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="">
      <div className="m-l-srv-btm">
        <label className="m-l-email-input-label">
          {t('labels.rFIToClientSalutation')}
        </label>
        <EmailSettingHtmlEditor
          content={cSalutation}
          setContent={setCSalutation}
          entities={entities}
          showEditor={ShowCSalutation ? true : false}
          isDisabled={false}
        />
      </div>
      <div className="m-l-srv-btm ">
        <label className="m-l-email-input-label">
          {t('labels.rFIToVesselOwnerSalutation')}
        </label>
        <EmailSettingHtmlEditor
          content={vSalutation}
          setContent={setVSalutation}
          entities={entities}
          showEditor={showVSalutation ? true : false}
          isDisabled={false}
        />
      </div>
      <div className="m-l-srv-btm ">
        <label className="m-l-email-input-label">
          {t('labels.rFIToClientSignature')}
        </label>
        <EmailSettingHtmlEditor
          content={cSignature}
          setContent={setCSignature}
          entities={entities}
          showEditor={showCSignature ? true : false}
          isDisabled={false}
        />
      </div>
      <div className="m-l-srv-btm ">
        <label className="m-l-email-input-label">
          {t('labels.rFIToVesselOwnerSignature')}
        </label>
        <EmailSettingHtmlEditor
          content={vSignature}
          setContent={setVSignature}
          entities={entities}
          showEditor={showVSignature ? true : false}
          isDisabled={false}
        />
      </div>
      <div className="m-c-form-group m-l-srv-btm u-mb0">
        <label className="m-l-email-input-label">
          {t('labels.confidentiality')}
        </label>
        <EmailSettingHtmlEditor
          content={confidentiality}
          setContent={setConfidentiality}
          entities={entities}
          showEditor={showConfidentiality ? true : false}
          isDisabled={false}
        />
        {/* <TextArea
          className="m-c-input-control m-c-text-area-control m-c-text-area-control-h-138"
          inputAttr={{ 'data-testid': 'confidentiality' }}
          name="confidentiality"
          value={confidentiality}
          onValueChanged={(e) => {
            setConfidentiality(e.value);
          }}
          width="100%"
          height={'150px'}
        /> */}
      </div>
    </div>
  );
}
