import Button from 'devextreme-react/button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IEquipmentDetails } from '../../../../../types/vessel-equipment-tab';
import icons from '../../../../common/icons/icons';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import EditEquipment from '../edit-equipment/EditEquipment';
import { Restricted } from '../../../../../contexts/PermissionContext';

export default function EquipmentTab(props: { imoNumber?: string }) {
  const [isEdit, setIsEdit] = useState(false);
  const [equipmentView, setEquipmentView] = useState(true);
  const [equipmentDetails, setEquipmentDetails] = useState<IEquipmentDetails>();
  const { imoNumber } = props;
  const { t } = useTranslation();

  const GetEquipmentDetails = async () => {
    await api
      .get({
        url: apiConfig.equipmentTab + imoNumber,
      })
      .then((response: any) => {
        setEquipmentDetails(response);
      });
  };

  useEffect(() => {
    if (imoNumber) {
      GetEquipmentDetails();
    }
  }, []);

  return (
    <div data-testid="equipment">
      {equipmentView ? (
        <div className="m-l-inner-page-body">
          <div className="m-l-form-panel-block">
            <div className="m-l-tbl-square-wrapper">
              <div className="m-l-ads-table-block">
                <div className="m-l-ads-table-right-part">
                  <div className="row m-l-ads-table-row m-l-ads-table--inner-row">
                    <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 m-l-custom-sixteen-space-col-wrap">
                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {' '}
                            {t('labels.propeller')}{' '}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {equipmentDetails?.propeller}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.propellerDescription')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {equipmentDetails?.propellerDescription}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.propellingType')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {equipmentDetails?.propulsionType}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.manifold')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {equipmentDetails?.manifold}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.manifoldSize')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {equipmentDetails?.manifoldSize}
                          </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.inertGas')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {equipmentDetails?.inertGas}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.numberOfCranes')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {equipmentDetails?.numberOfCranes}
                          </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.craneSWL')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {equipmentDetails?.craneSWL}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.chainStoppersNos')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {equipmentDetails?.bowStoppersNo}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.chainStopperSWL')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {equipmentDetails?.bowStoppersSWL}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.mooringRope')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {equipmentDetails?.mooringRope}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.mooringRopeMaterial')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {equipmentDetails?.mooringRopeMaterial}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.mooringRopeMBL')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {equipmentDetails?.mooringRopeMBL}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.mooringDrums')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {equipmentDetails?.mooringDrums}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                      <div className="m-l-expand-btn-wrap m-l-edit-view-mode">
                        <Restricted permission="Ship.EditEquipment">
                          <Button
                            className="app-c-btn app-c-icon-only-btn"
                            onClick={() => {
                              setEquipmentView(!equipmentView);
                              setIsEdit(!isEdit);
                            }}
                          >
                            <icons.IconPencil />
                          </Button>
                        </Restricted>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {isEdit ? (
        <EditEquipment
          imoNumber={imoNumber}
          setIsEdit={setIsEdit}
          setEquipmentView={setEquipmentView}
          equipmentDetails={equipmentDetails}
          getEquipmentDetails={GetEquipmentDetails}
        />
      ) : null}
    </div>
  );
}
