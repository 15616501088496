import Button from 'devextreme-react/button';
import TabPanel from 'devextreme-react/tab-panel';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import BerthGrid from '../../../components/port-management/berth-grid/BerthGrid';
import TerminalForm from '../../../components/port-management/terminal-form/TerminalForm';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import AddNewBerth from '../../../components/port-management/add-new-berth-popover/AddNewBerth';
import { IPortSearchForm } from '../../../types/port-management';
import toast from '../../../utils/toast';
import { useTranslation } from 'react-i18next';
import { IEditTerminalInfo } from '../../../types/port-configurations';
import AddListPopover from '../../../components/port-management/add-list-popover/AddListPopover';
import icons from '../../../components/common/icons/icons';
import { removeKey } from '../../../services/master-data-service';
import { IDialogConfig } from '../../../types/dialog';
import ConfirmDialog from '../../../components/common/confirm/ConfirmDialog';
import { useDimensionsUnit } from '../../../hooks/useDimensionsUnit';
import { Restricted } from '../../../contexts/PermissionContext';

export default function EditTerminal() {
  const tabRef = useRef<TabPanel>(null);
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<any>(0);
  const [selectedTerminalInfo, setSelectedTerminalInfo] = useState<any>();
  const [selectedTerminalName, setSelectedTerminalName] = useState<any>();
  const [selectedTerminalCode, setSelectedTerminalCode] = useState<any>();
  const [portName, setPortName] = useState<any>();
  const [portCode, setPortCode] = useState<any>();
  const [portId, setPortId] = useState<any>();
  const [terminalId, setTerminalId] = useState<any>();
  const [isAddNewBerth, setIsAddNewBerth] = useState(false);
  const [query, setQuery] = useState<any>();
  const [queryOg, setQueryOg] = useState<any>();
  const [isFromAddBerth, setIsFromAddBerth] = useState(false);
  const [params, setParams] = useState<any>();
  const navigate = useNavigate();
  const [countriesList, setCountriesList] = useState<any>();
  const { t } = useTranslation();
  const [isFromPort, setIsFromPort] = useState(false);
  const [showAddListPopover, setShowAddListPopover] = useState(false);
  const [berthEntityNames, setBerthEntityNames] = useState<any>([]);
  const [fuelsHandled, setFuelsHandled] = useState<any>([]);
  const [isAddListBerth, setIsAddListBerth] = useState(false);
  const [isExportBerth, setIsExportBerth] = useState(false);
  const [clearSelection, setClearSelection] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isConfirmTabChangeDialog, setIsConfirmTabChangeDialog] =
    useState(false);
  const [isconfirmBackDialog, setIsConfirmBackDialog] = useState(false);

  const { feetToMeterConversion, meterToFeetConversion, dimensionUnit } =
    useDimensionsUnit();
  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      onEditTerminal();
      setIsConfirmModalOpen(false);
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };

  const confirmTabChangeDialogConfig: IDialogConfig = {
    id: 'confirmTabChange',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      getSelectedTerminalInfo(terminalId);
      setIsConfirmTabChangeDialog(false);
    },
    handleClose: () => {
      tabRef.current?.instance.option('selectedIndex', 0);
      setIsConfirmTabChangeDialog(false);
    },
  };

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      goBackNavigation();
      setIsConfirmBackDialog(false);
    },
    handleClose: () => {
      setIsConfirmBackDialog(false);
    },
  };

  const getSelectedTerminalInfo = async (terminalId: any) => {
    if (terminalId) {
      await api
        .get({ url: apiConfig.getTerminalInfo + terminalId })
        .then((data: any) => {
          setSelectedTerminalInfo(data);
          setSelectedTerminalName(data?.terminalName);
        });
    }
  };

  const getCountries = async () => {
    await api.get({ url: apiConfig.country }).then((data: any) => {
      setCountriesList(data);
    });
  };

  const getPortSearchResult = async (params: IPortSearchForm) => {
    const query =
      '?Country=' +
      params.countryCode +
      '&Port=' +
      params.port +
      '&Terminal=' +
      selectedTerminalCode +
      '&Berth=' +
      params.berth;
    setQuery(query);
  };

  useEffect(() => {
    if (location.state) {
      const {
        terminalId,
        terminalName,
        terminalCode,
        query,
        params,
        portName,
        portCode,
        isFromPort,
        portId,
      }: any = location.state;
      getSelectedTerminalInfo(terminalId);
      setTerminalId(terminalId);
      setSelectedTerminalName(terminalName);
      setSelectedTerminalCode(terminalCode);
      setPortName(portName);
      setPortCode(portCode);
      setPortId(portId);
      getCountries();
      setQuery(query);
      queryOg ? setQueryOg(queryOg) : setQueryOg(query);
      setParams(params);
      setIsFromPort(isFromPort);
    }
  }, [location.state]);

  useEffect(() => {
    if (params) {
      getPortSearchResult(params);
    }
  }, [params]);

  const getFuelHandledDropDown = async () => {
    await api.get({ url: apiConfig.getShipFuelType }).then((data: any) => {
      setFuelsHandled(data);
    });
  };

  useEffect(() => {
    getFuelHandledDropDown();
  }, []);

  const tabs = useMemo(() => {
    return [
      {
        title: t('labels.terminalInfo'),
      },
      {
        title: t('labels.berths'),
      },
    ];
  }, []);

  const defaultTerminalFormValues = {
    terminalName: '',
    terminalCode: '',
    portName: '',
    portCode: '',
    country: null,
    portSize: '',
    terminalAddress: '',
    comments: '',
    emergencyShutdownCapability: '',
    freshWater: '',
    terminalLatitude: '',
    terminalLongitude: '',
    minimumHeightManifoldAWBerth: null,
    terminalDocuments: '',
    terminalOwner: '',
    industryStandardAssessmentCarriedOut: '',
    auditDate: '',
    vesselHandled: '',
    operationalStatus: '',
    terminalOperator: '',
    terminalSize: '',
    chartNumber: '',
    fuelsHandled: null,
    terminalVolumeHandled: '',
    tidalRestrictions: '',
    tidalNotes: '',
    specificBerthingRestrictions: '',
    slopreceptionNotes: '',
    operationsCarriedOut: '',
    shiptoShipAvailable: '',
    shiptoshipNotes: '',
    distanceFromPilotStation: '',
    lastStructuralSurvey: '',
    isVesselPumpWater: '',
    vapourRecoveryRequirements: '',
    typeOfBottom: '',
    postalCode: '',
    email: '',
    phoneNumber: '',
    website: '',
    callingCode: '',
  };

  const terminalInfoForm = useForm({
    defaultValues: defaultTerminalFormValues,
  });

  const { isDirty } = terminalInfoForm.formState;

  const { getValues } = terminalInfoForm;
  let terminalFormValue = getValues();

  const onEditTerminal = () => {
    terminalFormValue = terminalInfoForm.getValues();
    const roleJsonEdit: IEditTerminalInfo = {
      terminalId: terminalId,
      terminalAddress: {
        terminalAddress: terminalFormValue.terminalAddress,
        phoneNumber:
          terminalFormValue?.phoneNumber === ''
            ? ''
            : terminalFormValue?.phoneNumber?.toString(),
        email: terminalFormValue.email,
        website: terminalFormValue.website,
        postalCode: terminalFormValue.postalCode,
      },
      fuelsHandled: terminalFormValue.fuelsHandled,
      comments: terminalFormValue.comments,
      terminalName: terminalFormValue.terminalName,
      isEmergencyToWoffPrerequire:
        terminalFormValue.emergencyShutdownCapability === 'Yes' ? true : false,
      isFreshWater: terminalFormValue.freshWater === 'Yes' ? true : false,
      terminalLatitude: terminalFormValue.terminalLatitude
        ? terminalFormValue.terminalLatitude?.toString()
        : null,
      terminalLongitude: terminalFormValue.terminalLongitude
        ? terminalFormValue.terminalLongitude?.toString()
        : null,
      terminalContacts: null,
      terminalOwner: terminalFormValue.terminalOwner,
      isIndustryStandardAssesmentCarriedout:
        terminalFormValue.industryStandardAssessmentCarriedOut === 'Yes'
          ? true
          : false,
      operationalStatus:
        terminalFormValue.operationalStatus === 'Yes' ? true : false,
      terminalOperator: terminalFormValue.terminalOperator,
      terminalSize: terminalFormValue.terminalSize?.toString(),
      chartNumber: terminalFormValue.chartNumber?.toString(),
      terminalVolumeHandled:
        terminalFormValue.terminalVolumeHandled?.toString(),
      operationCarriedout: terminalFormValue.operationsCarriedOut,
      isTidalRestrictions:
        terminalFormValue.tidalRestrictions === 'Yes' ? true : false,
      tidalNotes: terminalFormValue.tidalNotes,
      specificBerthingRestrictions:
        terminalFormValue.specificBerthingRestrictions,
      slopreceptionNotes: terminalFormValue.slopreceptionNotes,
      isShiptoshipAvailable:
        terminalFormValue.shiptoShipAvailable === 'Yes' ? true : false,
      shiptoshipNotes: terminalFormValue.shiptoshipNotes,
      distanceFromPilotStation: parseFloat(
        terminalFormValue.distanceFromPilotStation
      ),
      lastStructuralSurvey: terminalFormValue.lastStructuralSurvey,
      isVesselPumpWater:
        terminalFormValue.isVesselPumpWater === 'Yes' ? true : false,
      terminalTypeOfBottom: terminalFormValue.typeOfBottom,
      isVapourRecoveryRequiremts:
        terminalFormValue.vapourRecoveryRequirements === 'Yes' ? true : false,
    };
    api
      .put({ url: apiConfig.viewTerminal, data: roleJsonEdit })
      .then((response) => {
        if (response) {
          toast.success({
            title: t('toast.terminalUpdateSuccess'),
          });
          // setQuery(query);
          getSelectedTerminalInfo(terminalId);
          removeKey('terminals');
        }
      });
  };

  const tabItemRender = (e: any) => {
    if (e.title === tabs[0].title) {
      return (
        <FormProvider {...terminalInfoForm}>
          <TerminalForm
            countriesList={countriesList}
            terminalCode={selectedTerminalCode}
            fuelsHandled={fuelsHandled}
            terminalId={terminalId}
          />
        </FormProvider>
      );
    } else if (e.title === tabs[1].title) {
      return (
        <BerthGrid
          query={query}
          setIsFromAddBerth={setIsFromAddBerth}
          isFromAddBerth={isFromAddBerth}
          isFromTerminal={true}
          setIsAddListBerth={setIsAddListBerth}
          setBerthEntityNames={setBerthEntityNames}
          params={params}
          isFromPort={isFromPort ? isFromPort : false}
          portId={portId}
          portName={portName}
          portCode={portCode}
          isExport={isExportBerth}
          setIsExport={setIsExportBerth}
          clearSelection={clearSelection}
          setClearSelection={setClearSelection}
        />
      );
    }
  };

  const onTabSelectionChanged = () => {
    setSelectedTab(tabRef.current?.instance.option('selectedIndex'));
    if (isDirty && selectedTab == 0) {
      setIsConfirmTabChangeDialog(true);
    }
  };

  const onAddClick = () => {
    setIsAddNewBerth(true);
  };

  const onClickSave = () => {
    setIsConfirmModalOpen(true);
  };

  const goBack = () => {
    if (isDirty && selectedTab == 0) {
      setIsConfirmBackDialog(true);
    } else {
      goBackNavigation();
    }
  };

  const goBackNavigation = () => {
    if (isFromPort) {
      navigate('/port/edit', {
        state: {
          portId: portId,
          portName: portName,
          portCode: portCode,
          query: queryOg,
          params: params,
        },
      });
    } else {
      navigate('/port', {
        state: {
          query: queryOg,
          params: params,
        },
      });
    }
  };

  useEffect(() => {
    terminalInfoForm.reset();
    if (selectedTerminalInfo) {
      terminalInfoForm.reset(
        {
          portName: selectedTerminalInfo.portName,
          portCode: selectedTerminalInfo.portCode,
          country: selectedTerminalInfo.countryCode,
          terminalName: selectedTerminalInfo.terminalName,
          terminalCode: selectedTerminalInfo.terminalCode,
          fuelsHandled: selectedTerminalInfo.fuelsHandled,
          comments: selectedTerminalInfo.comments,
          emergencyShutdownCapability:
            selectedTerminalInfo.emergencyShutdownCapability,
          freshWater: selectedTerminalInfo.freshWater,
          // callingCode:selectedTerminalInfo.callingCode,
          terminalLatitude: selectedTerminalInfo.terminalLatitude,
          terminalLongitude: selectedTerminalInfo.terminalLongitude,
          terminalOwner: selectedTerminalInfo.terminalOwner,
          industryStandardAssessmentCarriedOut:
            selectedTerminalInfo.industryStandardAssessmentCarriedOut,
          vesselHandled: selectedTerminalInfo.vesselHandled,
          operationalStatus: selectedTerminalInfo.operationalStatusDisplay,
          terminalOperator: selectedTerminalInfo.terminalOperator,
          terminalSize: selectedTerminalInfo.terminalSize,
          chartNumber: selectedTerminalInfo.chartNumber,
          terminalVolumeHandled: selectedTerminalInfo.terminalVolumeHandled,
          tidalRestrictions: selectedTerminalInfo.tidalRestrictions,
          tidalNotes: selectedTerminalInfo.tidalNotes,
          specificBerthingRestrictions:
            selectedTerminalInfo.specificBerthingRestrictions,
          slopreceptionNotes: selectedTerminalInfo.slopreceptionNotes,
          operationsCarriedOut: selectedTerminalInfo.operationCarriedout,
          shiptoShipAvailable: selectedTerminalInfo.shiptoShipAvailable,
          shiptoshipNotes: selectedTerminalInfo.shiptoshipNotes,
          distanceFromPilotStation:
            selectedTerminalInfo.distanceFromPilotStation,
          lastStructuralSurvey: selectedTerminalInfo.lastStructuralSurvey,
          isVesselPumpWater:
            selectedTerminalInfo.pumpOrReceiveWaterForLineClearance,
          vapourRecoveryRequirements:
            selectedTerminalInfo.vapourRecoveryRequirements,
          typeOfBottom: selectedTerminalInfo.terminalTypeOfBottom,
          postalCode: selectedTerminalInfo.terminalAddressView.postalCode,
          email: selectedTerminalInfo.terminalAddressView.email,
          phoneNumber: selectedTerminalInfo.terminalAddressView.phoneNumber,
          website: selectedTerminalInfo.terminalAddressView.website,
          terminalAddress:
            selectedTerminalInfo.terminalAddressView.terminalAddress,
        },
        { keepDirty: true }
      );
    }
  }, [selectedTerminalInfo, dimensionUnit]);

  return (
    <div className="inner-wrapper">
      <div className="m-l-inner-page-header">
        <div className="m-l-inner-page-header-left-block">
          <div className="m-l-page-main-heading">
            {t('labels.terminal')} {'-' + selectedTerminalName}
          </div>
        </div>
      </div>
      <div className="m-l-inner-page-body">
        <div className="m-l-tab-panel__wrap">
          <TabPanel
            ref={tabRef}
            // height={'600px'}
            items={tabs}
            className="m-c-tab-block m-c-tab--half-width"
            elementAttr={{ 'data-testid': 'portTab' }}
            itemRender={tabItemRender}
            onSelectionChanged={onTabSelectionChanged}
            showNavButtons={true}
          />
          <div className="m-l-tab-panel-right-block">
            <Button
              className="app-c-btn app-c-btn--secondary"
              elementAttr={{ 'data-testid': 'addToList' }}
              onClick={goBack}
            >
              {t('labels.back')}
            </Button>
            {selectedTab === 0 && (
              <>
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width"
                  elementAttr={{ 'data-testid': 'addNew' }}
                  onClick={onClickSave}
                >
                  {t('labels.save')}
                </Button>
                <ConfirmDialog
                  dialogConfig={confirmSaveDialogConfig}
                  isOpen={isConfirmModalOpen}
                />
                <ConfirmDialog
                  dialogConfig={confirmBackDialogConfig}
                  isOpen={isconfirmBackDialog}
                />
                {/* <ConfirmDialog
                  dialogConfig={confirmTabChangeDialogConfig}
                  isOpen={isConfirmTabChangeDialog}
                /> */}
              </>
            )}
            {selectedTab === 1 && (
              <>
                <Button
                  className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                  aria-label="Upload"
                  onClick={() => setIsExportBerth(true)}
                >
                  <div className="m-c-icon-inside-btn">
                    <icons.download />
                  </div>
                </Button>
                {isAddListBerth && (
                  <Button
                    className="app-c-btn app-c-btn--secondary"
                    elementAttr={{ 'data-testid': 'addToList' }}
                    onClick={() => setShowAddListPopover(true)}
                    id="addToList"
                  >
                    {t('labels.addToList')}
                  </Button>
                )}
                <Restricted permission="PortManagement.AddNewBerth">
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'addNew' }}
                    onClick={onAddClick}
                  >
                    {t('labels.addNew')}
                  </Button>
                </Restricted>
              </>
            )}
          </div>
          <AddListPopover
            showPopover={showAddListPopover}
            setShowPopover={setShowAddListPopover}
            target="#addToList"
            selectedTab={2}
            berthEntityNames={berthEntityNames}
            setClearSelection={setClearSelection}
          />
          {isAddNewBerth && (
            <AddNewBerth
              isAddNewBerth={isAddNewBerth}
              setIsAddNewBerth={setIsAddNewBerth}
              terminalCode={selectedTerminalCode}
              setIsFromAddBerth={setIsFromAddBerth}
            />
          )}
        </div>
      </div>
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <Button
                className="app-c-btn app-c-btn--secondary"
                elementAttr={{ 'data-testid': 'addToList' }}
                onClick={goBack}
              >
                {t('labels.back')}
              </Button>
              {selectedTab === 0 && (
                <>
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'addNew' }}
                    onClick={onClickSave}
                  >
                    {t('labels.save')}
                  </Button>
                  <ConfirmDialog
                    dialogConfig={confirmSaveDialogConfig}
                    isOpen={isConfirmModalOpen}
                  />
                  <ConfirmDialog
                    dialogConfig={confirmBackDialogConfig}
                    isOpen={isconfirmBackDialog}
                  />
                </>
              )}
              {selectedTab === 1 && (
                <>
                  <Button
                    className="app-c-btn app-c-btn--secondary n"
                    aria-label="Upload"
                    onClick={() => setIsExportBerth(true)}
                  >
                    {t('labels.download')}
                  </Button>
                  {isAddListBerth && (
                    <Button
                      className="app-c-btn app-c-btn--secondary"
                      elementAttr={{ 'data-testid': 'addToList' }}
                      onClick={() => setShowAddListPopover(true)}
                      id="addToList"
                    >
                      {t('labels.addToList')}
                    </Button>
                  )}
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'addNew' }}
                    onClick={onAddClick}
                  >
                    {t('labels.addNew')}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        dialogConfig={confirmTabChangeDialogConfig}
        isOpen={isConfirmTabChangeDialog}
      />
    </div>
  );
}
