import Axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { getAdditionalInfoToken, getToken } from '../utils/jwt-decode';

// default SERVICE_URL will go here.
const API_URL = process.env.REACT_APP_BASE_URL;
const SCHEDULER_API_URL = process.env.REACT_APP_SCHEDULER_BASE_URL;

function getApiConfig() {
  const token = getToken();
  const additionalInfoToken = getAdditionalInfoToken();
  const mainConfig: AxiosRequestConfig = {
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
      'Additional-Info': additionalInfoToken ? `${additionalInfoToken}` : '',
    },
  };

  return mainConfig;
}

const ApiCall = (ajaxParams: any): AxiosPromise => Axios(ajaxParams);

function GET({
  url = '',
  params = {},
  responseType,
}: AxiosRequestConfig): AxiosPromise {
  const config = getApiConfig();
  const ajaxParams = {
    ...config,
    url,
    params,
    method: 'GET',
    responseType,
  };

  return ApiCall(ajaxParams);
}

function POST({
  url = '',
  params = {},
  data = {},
}: AxiosRequestConfig): AxiosPromise {
  const config = getApiConfig();

  const ajaxParams = {
    ...config,
    url,
    data,
    params,
    method: 'POST',
  };

  return ApiCall(ajaxParams);
}

function PUT({
  url = '',
  params = {},
  data = {},
  headers = {},
}: AxiosRequestConfig): AxiosPromise {
  const config = getApiConfig();

  const ajaxParams = {
    ...config,
    url,
    data,
    params,
    method: 'PUT',
    headers: {
      ...config.headers,
      ...headers,
    },
  };

  return ApiCall(ajaxParams);
}

function DELETE({
  url = '',
  params = {},
  data = {},
}: AxiosRequestConfig): AxiosPromise {
  const config = getApiConfig();

  const ajaxParams = {
    ...config,
    url,
    data,
    params,
    method: 'DELETE',
  };

  return ApiCall(ajaxParams);
}

function SCHEDULERPOST({
  url = '',
  params = {},
  data = {},
}: AxiosRequestConfig): AxiosPromise {
  const config = getApiConfig();
  config.baseURL = SCHEDULER_API_URL;
  const ajaxParams = {
    ...config,
    url,
    data,
    params,
    method: 'POST',
  };
  return ApiCall(ajaxParams);
}

export default {
  GET,
  POST,
  PUT,
  DELETE,
  SCHEDULERPOST,
};
