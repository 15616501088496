import CustomStore from 'devextreme/data/custom_store';
import { IOptionvalue } from '../../../../types/master-data';
import { IColumnConfig } from '../../../../types/grid';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';

const countryDataSource = {
  store: new CustomStore({
    key: 'countryId',
    loadMode: 'raw',
    load: () => {
      return api.get({ url: apiConfig.country });
    },
  }),
};

const buStatusDataSource = {
  store: new CustomStore({
    key: 'optionValueCode',
    loadMode: 'raw',
    load: () => {
      return api.get({ url: apiConfig.buStatus });
    },
  }),
};

export const getDefaultColumn = (
  t: any,
  personNameTitles: IOptionvalue[],
  buIncoTermsTitle: any,
  contactPersonNameWithTitle: any
): IColumnConfig[] => {
  return [
    {
      name: 'Business Unit Name',
      dataField: 'buName',
      dataType: 'string',
      minWidth: 150,
      caption: t('labels.businessUnitName'),
    },
    {
      name: 'Business Unit Email ID',
      dataField: 'buEmail',
      dataType: 'string',
      minWidth: 150,
      caption: t('labels.businessUnitEmail'),
    },
    {
      caption: t('labels.country'),
      dataField: 'countryId',
      dataType: 'string',
      minWidth: 150,
      lookup: {
        dataSource: countryDataSource,
        valueExpr: 'countryId',
        displayExpr: 'countryName',
      },
    },
    {
      name: 'Contact Person Name',
      dataField: 'contactName',
      dataType: 'string',
      minWidth: 150,
      caption: t('labels.contactPersonName'),
      calculateCellValue: (rowData: any) => contactPersonNameWithTitle(rowData),
    },
    {
      name: 'Contact Person Email ID',
      dataField: 'contactEmail',
      dataType: 'string',
      minWidth: 150,
      caption: t('labels.contactPersonEmail'),
    },
    {
      caption: t('labels.buIncoTerms'),
      dataField: 'buIncoTerms',
      dataType: 'string',
      minWidth: 100,
      cellTemplate: 'cellTemplate',
      calculateCellValue: (rowData: any) => buIncoTermsTitle(rowData),
    },
    {
      caption: t('labels.status'),
      dataField: 'buStatus',
      dataType: 'string',
      minWidth: 100,
      lookup: {
        dataSource: buStatusDataSource,
        valueExpr: 'optionValueCode',
        displayExpr: 'optionValueText',
      },
    },
    {
      name: 'Action',
      dataField: 'action',
      dataType: 'string',
      cellTemplate: 'actionTemplate',
      alignment: 'center',
      minWidth: 100,
      width: 100,
      caption: t('labels.action'),
      allowFiltering: false,
      allowSorting: false,
    },
  ];
};
