import Accordion from 'devextreme-react/accordion';
import React, { useState } from 'react';
import { IVetRequestBerth } from '../../../../types/berthfit-execution';
import BerthInfo from '../../../berth-fit-check/info/berth-info/BerthInfo';
import BerthAccordionHeader from '../berth-accordion-header/BerthAccordionHeader';
import BerthDetails from '../berth-details/BerthDetails';

export default function BerthAccordion(props: {
  berths: IVetRequestBerth[];
  imoNumber?: string;
  buId: number;
  berthFitId: number;
  getPortList: any;
}) {
  const { berths, imoNumber, buId, berthFitId, getPortList } = props;

  const [selectedItems, setSelectedItems] = useState([]);

  const [showBerthInfo, setShowBerthInfo] = useState(0);
  const [berthCode, setBerthCode] = useState();

  const selectionChanged = (e: any) => {
    let newItems: any = [...selectedItems];
    e.removedItems.map((item: any) => {
      const index = newItems.indexOf(item);
      if (index >= 0) {
        newItems.splice(index, 1);
      }
    });
    if (e.addedItems.length) {
      newItems = [...newItems, ...e.addedItems];
    }
    setSelectedItems(newItems);
  };

  const onClickTitle = (e: any) => {
    if (
      e.event.target.className === 'view-berth-info' ||
      e.event.target.className === 'm-l-view-info-link'
    ) {
      e?.event?.stopPropagation();
      setBerthCode(e.itemData.berthCode);
      setShowBerthInfo(e.itemData.berthId);
    }
  };

  return (
    <div className="m-c-accordion m-l-accordion--plane m-l-berthfit-check-third-inner-accordion open">
      <Accordion
        items={berths}
        itemTitleRender={(data: any) => {
          return <BerthAccordionHeader data={data} />;
        }}
        collapsible={true}
        selectedItems={selectedItems}
        onSelectionChanged={selectionChanged}
        onItemClick={onClickTitle}
        itemRender={(data: IVetRequestBerth) => {
          return (
            <BerthDetails
              getPortList={getPortList}
              berthDetails={data}
              imoNumber={imoNumber}
              buId={buId}
              berthFitId={berthFitId}
            />
          );
        }}
      ></Accordion>
      {showBerthInfo !== 0 && (
        <BerthInfo
          isVisible={showBerthInfo !== 0}
          handleCancelClick={() => setShowBerthInfo(0)}
          berthId={showBerthInfo}
          berthCode={berthCode}
        />
      )}
    </div>
  );
}
