import ReactDOM from 'react-dom';
import React from 'react';
import imageURL from '../../../assets/images';
import Icons from '../icons/icons';

let config = { title: '', message: '', type: '', contentReady: undefined };
let component: any;
export function ToastComponent(element?: any) {
  ReactDOM.render(
    <ToastTemplate
      title={config.title}
      message={config.message}
      type={config.type}
    />,
    element
  );
}

const ToastTemplate = (props: any) => {
  return (
    <div
      className={
        props.type === 'success'
          ? 'm-l-toast-message-wrap m-l-toast-success-msg-wrap'
          : props.type === 'error'
          ? 'm-l-toast-message-wrap m-l-toast-error-msg-wrap'
          : props.type === 'warning'
          ? 'm-l-toast-message-wrap m-l-toast-warning-msg-wrap'
          : 'm-l-toast-message-wrap'
      }
    >
      <div
        className="toast-close-button"
        onClick={() => {
          component.hide();
        }}
      >
        <Icons.toastCloseIcon />
      </div>
      <div className="m-l-toast-message-icon-wrap">
        <img
          src={
            props.type === 'success'
              ? imageURL.successToastIcon
              : props.type === 'error'
              ? imageURL.errorToastIcon
              : props.type === 'warning'
              ? imageURL.warningToastIcon
              : imageURL.avatar
          }
          className="img-responsive"
          alt="success icon"
        />
      </div>
      <div className="m-l-toast-message-block">
        <h2>{props.title}</h2>
        <p>{props.message}</p>
      </div>
    </div>
  );
};

export const setConfig = (tconfig: any) => {
  config = tconfig;
};

export const contentReady = (e: any) => {
  component = e?.component;
};
