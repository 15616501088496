import * as React from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
export type LoadingState = { isLoading?: boolean; setIsLoading?: any };
const LoaderContext = React.createContext<LoadingState>({});

function LoaderProvider(props: any) {
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <LoaderContext.Provider value={{ isLoading, setIsLoading }}>
      {props.children}
      <LoadPanel
        visible={isLoading}
        showIndicator={true}
        shading={true}
        shadingColor="rgba(0,0,0,0)"
      />
    </LoaderContext.Provider>
  );
}

function useLoaderContext() {
  const context = React.useContext(LoaderContext);
  return context;
}

export { LoaderProvider, useLoaderContext };
