import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDimensionsUnit } from '../../hooks/useDimensionsUnit';

export default function VesselInfoBanner(props: {
  bannerInfoDetails: any;
  isSanction: any;
}) {
  const { bannerInfoDetails, isSanction } = props;
  const { t } = useTranslation();

  const { dimensionUnitText, meterToFeetConversion } = useDimensionsUnit();

  return (
    <div className="m-l-inner-page-body">
      <div className="m-l-form-panel-block">
        <div className="m-l-tbl-square-wrapper">
          <div className="m-l-ads-table-block">
            <div className="m-l-ads-table-right-part">
              <div className="row m-l-ads-table-row">
                <div className="col-xl-1 col-lg-2 col-md-3 col-sm-6 m-l-ads-table-items">
                  <div className="m-l-ads-tbl-title">{t('labels.age')}</div>
                  <div className="m-l-ads-tbl-desc">
                    {bannerInfoDetails.age}
                  </div>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                  <div className="m-l-ads-tbl-title">{t('labels.status')}</div>
                  <div className="m-l-ads-tbl-desc">
                    {bannerInfoDetails.vesselStatus}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-2 col-md-3 col-sm-6 m-l-ads-table-items">
                  <div className="m-l-ads-tbl-title">
                    {t('labels.vesselType')}
                  </div>
                  <div className="m-l-ads-tbl-desc">
                    {bannerInfoDetails.vesselType}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                  <div className="m-l-ads-tbl-title">
                    {t('labels.docCompany')}
                  </div>
                  <div className="m-l-ads-tbl-desc">
                    {bannerInfoDetails.docCompany}
                  </div>
                </div>
                <div className="col-xl-1 col-lg-1 col-md-3 col-sm-6 m-l-ads-table-items">
                  <div className="m-l-ads-tbl-title">
                    {t('labels.LOA')}
                    {dimensionUnitText()}
                  </div>
                  <div className="m-l-ads-tbl-desc">
                    {meterToFeetConversion(bannerInfoDetails.lengthOverallLOA)}
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-6 m-l-ads-table-items">
                  <div className="m-l-ads-tbl-title">
                    {t('labels.summerDWT')}
                  </div>
                  <div className="m-l-ads-tbl-desc">
                    {bannerInfoDetails.deadweight}
                  </div>
                </div>
                <div className="col-xl-1 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                  <div className="m-l-ads-tbl-title">
                    {t('labels.callSignNo')}
                  </div>
                  <div className="m-l-ads-tbl-desc">
                    {bannerInfoDetails.callSign}
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-6 m-l-ads-table-items">
                  <div className="m-l-ads-tbl-title">{t('labels.Class')}</div>
                  <div className="m-l-ads-tbl-desc">
                    {bannerInfoDetails.classificationSocietyName}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                  <div className="m-l-ads-tbl-title">
                    {t('labels.pandIClub')}
                  </div>
                  <div className="m-l-ads-tbl-desc">
                    {bannerInfoDetails.pandIClubName}
                  </div>
                </div>

                <div className="col-xl-3 col-lg-2 col-md-3 col-sm-6 m-l-ads-table-items">
                  <div className="m-l-ads-tbl-title">
                    {t('labels.previousName')}
                  </div>
                  <div className="m-l-ads-tbl-desc">
                    {bannerInfoDetails.previousName}
                  </div>
                </div>
                <div className="col-xl-1 col-lg-2 col-md-3 col-sm-6 m-l-ads-table-items">
                  <div className="m-l-ads-tbl-title">
                    {t('labels.breadth')}
                    {dimensionUnitText()}
                  </div>
                  <div className="m-l-ads-tbl-desc">
                    {meterToFeetConversion(bannerInfoDetails.breadthExtreme)}
                  </div>
                </div>
                <div className="col-xl-1 col-lg-2 col-md-3 col-sm-6 m-l-ads-table-items">
                  <div className="m-l-ads-tbl-title">
                    {t('labels.sanction')}
                  </div>
                  <div
                    className={
                      bannerInfoDetails?.isSanction === true
                        ? 'm-l-ads-tbl-desc m-l-red-label'
                        : 'm-l-ads-tbl-desc m-l-gr-label'
                    }
                  >
                    {bannerInfoDetails?.isSanction === true
                      ? t('labels.yes')
                      : t('labels.no')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
