import Accordion from 'devextreme-react/accordion';
import React, { useEffect, useState } from 'react';
import PortAccordionHeader from '../port-accordion-header/PortAccordionHeader';
import BerthFitDetails from '../berth-fit-details/BerthFitDetails';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { IVetRequestPorts } from '../../../../types/berthfit-execution';
import { IOptionvalue } from '../../../../types/master-data';
import PortInfo from '../../../berth-fit-check/info/port-info/PortInfo';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import { useTranslation } from 'react-i18next';
import { getAPIRoute } from '../../../../utils/api-route';

export const BerthCheckStatusContext = React.createContext<IOptionvalue[]>([]);

export default function BerthFitTab(props: {
  vetRequestId?: string;
  imoNumber?: string;
  buId: number;
  isRuleExecutable: boolean;
  setShowBerthFitDownloadFromVet: any;
}) {
  const {
    vetRequestId,
    imoNumber,
    buId,
    isRuleExecutable,
    setShowBerthFitDownloadFromVet,
  } = props;

  const [selectedItems, setSelectedItems] = useState([]);

  const [ports, setPorts] = useState<IVetRequestPorts[]>([]);

  const [berthCheckStatus, setBerthCheckStatus] = useState<IOptionvalue[]>([]);

  const [showPortInfo, setShowPortInfo] = useState(0);

  const [portCode, setPortCode] = useState(null);

  const { formatDate } = useDateFormat();

  const { t } = useTranslation();

  const { setIsLoading } = useLoaderContext();

  const selectionChanged = (e: any) => {
    let newItems: any = [...selectedItems];
    e.removedItems.map((item: any) => {
      const index = newItems.indexOf(item);
      if (index >= 0) {
        newItems.splice(index, 1);
      }
    });
    if (e.addedItems.length) {
      newItems = [...newItems, ...e.addedItems];
    }
    setSelectedItems(newItems);
  };

  const berthFitExectution = async () => {
    if (isRuleExecutable) {
      await api
        .put(
          {
            url: apiConfig.berthFitExecution,
            params: {
              imo: imoNumber,
              vetRequestId: vetRequestId,
              buId: buId,
            },
          },
          setIsLoading
        )
        .then();
    }
    await getPortList();
  };

  const getBerthCheckStatus = async () => {
    await api
      .get({ url: apiConfig.berthExecutionStatus })
      .then((res: IOptionvalue[]) => {
        setBerthCheckStatus(res);
      });
  };

  const getPortList = async () => {
    await api
      .get({ url: apiConfig.berthExecutionPortList + vetRequestId })
      .then(async (res: IVetRequestPorts[]) => {
        await Promise.all(
          res.map(async (item) => {
            item.berthFitDate = formatDate(item.berthFitDate);
            if (item.berthFitFinalizedDate) {
              setShowBerthFitDownloadFromVet(true);
            } else {
              setShowBerthFitDownloadFromVet(false);
            }
            await api
              .get({
                url: getAPIRoute('portWarning', [item.portId]),
              })
              .then((response: any) => {
                item.portWarning = response;
              });
          })
        );
        setPorts(res);
      });
  };

  useEffect(() => {
    if (vetRequestId) {
      getBerthCheckStatus();
      berthFitExectution();
    }
  }, [vetRequestId]);

  const onClickTitle = (e: any) => {
    if (
      e.event.target?.className === 'view-port-info' ||
      e.event.target?.className ===
        'm-l-view-info-link m-l-view-info-link--web-view'
    ) {
      e?.event?.stopPropagation();
      setShowPortInfo(e.itemData.portId);
      setPortCode(e.itemData.portCode);
    }
  };

  return (
    <div>
      <div className="m-l-accordion__main-section main-top-level-accordion-bg">
        <div className="m-l-accordion-page-title-block">
          <h5>{t('labels.ports')}</h5>
        </div>
        <div className="m-c-accordion m-l-accordion--plane m-l-berthfit-check-accordion-section open">
          <Accordion
            collapsible={true}
            items={ports}
            itemTitleRender={(data) => {
              return <PortAccordionHeader data={data} />;
            }}
            selectedItems={selectedItems}
            onSelectionChanged={selectionChanged}
            onItemTitleClick={onClickTitle}
            itemRender={(data: IVetRequestPorts) => {
              return (
                <BerthCheckStatusContext.Provider value={berthCheckStatus}>
                  <BerthFitDetails
                    portData={data}
                    getPortList={getPortList}
                    vetRequestId={vetRequestId}
                    portId={data.portId}
                    berthFitId={data.berthFitId}
                    imoNumber={imoNumber}
                    buId={buId}
                  />
                </BerthCheckStatusContext.Provider>
              );
            }}
          ></Accordion>
        </div>
      </div>
      {showPortInfo !== 0 && (
        <PortInfo
          isVisible={showPortInfo !== 0}
          handleCancelClick={() => {
            setShowPortInfo(0);
            setPortCode(null);
          }}
          portId={showPortInfo}
          buId={buId}
          portCode={portCode}
        />
      )}
    </div>
  );
}
