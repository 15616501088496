import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../../types/dialog';
import { IContractInfoForm } from '../../../../types/contract-information';
import ServicesForm from '../services-form/ServicesForm';
import VettingForm from '../vetting-form/VettingForm';
import GeneralForm from '../general-form/GeneralForm';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import toast from '../../../../utils/toast';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import ValidationGroup from 'devextreme-react/validation-group';
import { useLoaderContext } from '../../../../contexts/LoaderContext';

export default function AddContract(props: {
  setAddContract: any;
  confirmAddContract: any;
  companyId: any;
  setConfirmAddContract: any;
  cancelAddContract: any;
  setCancelAddContract: any;
  contractInformation: any;
  setContractIsEdit: any;
  contractIsEdit: any;
  getContractById: any;
  isMarsUser: any;
}) {
  const {
    setAddContract,
    setConfirmAddContract,
    confirmAddContract,
    companyId,
    cancelAddContract,
    setCancelAddContract,
    contractInformation,
    setContractIsEdit,
    contractIsEdit,
    getContractById,
    isMarsUser,
  } = props;
  const { t } = useTranslation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isconfirmBackDialog, setIsConfirmBackDialog] = useState(false);
  const { apiDateFormat } = useDateFormat();
  const validationGroup: any = useRef();
  const { setIsLoading } = useLoaderContext();
  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmSaveContract'),
    handleSubmit: () => {
      onValueSubmit();
      setIsConfirmModalOpen(false);
    },
    handleClose: () => {
      setConfirmAddContract(false);
      setIsConfirmModalOpen(false);
    },
  };
  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setConfirmAddContract(false);
      setCancelAddContract(false);
      setAddContract(false);
      if (!contractIsEdit) {
        setContractIsEdit(true);
      }
      setIsConfirmBackDialog(false);
    },
    handleClose: () => {
      setIsConfirmBackDialog(false);
      setCancelAddContract(false);
    },
  };

  useEffect(() => {
    if (contractInformation) {
      contractInfoForm.reset({
        contractId: contractInformation.contractId,
        companyId: contractInformation.companyId,
        agreedVetNumbers: contractInformation.agreedVetNumbers,
        numberOfActiveUsers: contractInformation.numberOfActiveUsers,
        startDate: contractInformation.startDate,
        endDate: contractInformation.endDate,
        setupCost: contractInformation.setupCost,
        escalationPercentage: contractInformation.escalationPercentage,
        otherCost: contractInformation.otherCost,
        tax: contractInformation.tax,
        contractServices: contractInformation.contractServices,
        contractVettings: contractInformation.contractVettings,
      });
    }
  }, [contractInformation]);

  useEffect(() => {
    if (confirmAddContract) {
      const requiredValidation = validationGroup.current.instance.validate();
      if (requiredValidation.isValid) {
        setIsConfirmModalOpen(true);
      } else {
        setConfirmAddContract(false);
      }
    }
  }, [confirmAddContract]);

  useEffect(() => {
    if (cancelAddContract) {
      handleBackClick();
    }
  }, [cancelAddContract]);

  const defaultFormValues: IContractInfoForm = {
    contractId: 0,
    companyId: 0,
    agreedVetNumbers: null,
    numberOfActiveUsers: null,
    startDate: '',
    endDate: '',
    setupCost: null,
    escalationPercentage: null,
    otherCost: null,
    tax: null,
    contractServices: [
      {
        contractServiceId: 0,
        contractId: 0,
        serviceType: null,
        price: null,
      },
    ],
    contractVettings: [
      {
        contractVettingId: 0,
        contractId: 0,
        vesselType: '',
        numberOfVets: null,
        vetPrice: null,
        additionalVetPrice: null,
        autoVetPrice: null,
        sameSIREVetPrice: null,
      },
    ],
  };

  const contractInfoForm = useForm({
    defaultValues: defaultFormValues,
  });

  const { isDirty } = contractInfoForm.formState;

  const handleBackClick = () => {
    isDirty
      ? setIsConfirmBackDialog(true)
      : (setConfirmAddContract(false),
        setCancelAddContract(false),
        setAddContract(false),
        setContractIsEdit(true));
  };

  const onValueSubmit = () => {
    const contractFormValue: IContractInfoForm = contractInfoForm.getValues();
    const contractVetting: any = contractFormValue.contractVettings?.filter(
      (item: any) =>
        item.vesselType != undefined ||
        item.additionalVetPrice != undefined ||
        item.numberOfVets != undefined ||
        item.vetPrice != undefined ||
        item.sameSIREVetPrice != undefined ||
        item.autoVetPrice != undefined
    );
    const contractService: any = contractFormValue.contractServices?.filter(
      (item: any) => item.serviceType != null || item.price != null
    );
    const roleJsonEdit: IContractInfoForm = {
      contractId:
        contractInformation && contractInformation.contractId
          ? contractInformation.contractId
          : 0,
      companyId: companyId,
      agreedVetNumbers: contractFormValue.agreedVetNumbers,
      numberOfActiveUsers: contractFormValue.numberOfActiveUsers,
      startDate: apiDateFormat(contractFormValue.startDate),
      endDate: apiDateFormat(contractFormValue.endDate),
      setupCost: contractFormValue.setupCost,
      escalationPercentage: contractFormValue.escalationPercentage,
      otherCost: contractFormValue.otherCost,
      tax: contractFormValue.tax,
      contractServices: contractService,
      contractVettings: contractVetting,
    };
    if (contractIsEdit) {
      api
        .put({ url: apiConfig.contract, data: roleJsonEdit }, setIsLoading)
        .then((response) => {
          getContractById();
          setConfirmAddContract(false);
          setAddContract(false);
          setContractIsEdit(true);
          if (response) {
            toast.custom({
              title: t('toast.contractSavedSuccessfully'),
            });
          }
        });
      setConfirmAddContract(false);
    } else {
      api
        .post({ url: apiConfig.contract, data: roleJsonEdit }, setIsLoading)
        .then((response) => {
          setConfirmAddContract(false);
          setAddContract(false);
          setContractIsEdit(true);
          if (response) {
            toast.custom({
              title: t('toast.contractSavedSuccessfully'),
            });
          }
        });
      setConfirmAddContract(false);
    }
    // } else{
    //   setConfirmAddContract(false);
    // }
  };

  return (
    <ValidationGroup ref={validationGroup}>
      {/* // <div className="m-l-inner-page-body"> */}
      <div className="m-l-form-panel-block">
        <div className="m-l-form-panel-body-block">
          {/* <div className="row"> */}
          <FormProvider {...contractInfoForm}>
            <GeneralForm isMarsUser={isMarsUser} />
          </FormProvider>
          {/* </div> */}
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items u-mb0 m-l-custom-sixteen-space-col">
              <div className="m-l-details-page-small-heading u-mt0">
                {t('labels.services')}
              </div>
            </div>
            <FormProvider {...contractInfoForm}>
              <ServicesForm isMarsUser={isMarsUser} />
            </FormProvider>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items">
              <div className="m-l-details-page-small-heading u-mt0">
                {t('labels.vetting')}
              </div>
            </div>
            <FormProvider {...contractInfoForm}>
              <VettingForm isMarsUser={isMarsUser} />
            </FormProvider>
          </div>

          <div className="m-l-panel-right-btn u-pb0">
            <ConfirmDialog
              dialogConfig={confirmSaveDialogConfig}
              isOpen={isConfirmModalOpen}
            />
            <ConfirmDialog
              dialogConfig={confirmBackDialogConfig}
              isOpen={isconfirmBackDialog}
            />
          </div>
        </div>
      </div>
      {/* // </div> */}
    </ValidationGroup>
  );
}
