import Button from 'devextreme-react/button';
import DateBox from 'devextreme-react/date-box';
import Popover from 'devextreme-react/popover';
import ScrollView from 'devextreme-react/scroll-view';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IAddToList } from '../../../types/port-configurations';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { useDataStore } from '../../../hooks/useDataStore';
import toast from '../../../utils/toast';
import { useTranslation } from 'react-i18next';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { getAPIRoute } from '../../../utils/api-route';
import { getBuList, getCompanyId } from '../../../utils/jwt-decode';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import icons from '../../../components/common/icons/icons';
import { useResize } from '../../../hooks/useResize';

export default function AddVesselToListPopover(props: {
  target?: string;
  showPopover?: boolean;
  setShowPopover?: any;
  imoNumber?: any;
  vesselName: any;
  vinNumber?: any;
}) {
  const {
    target,
    showPopover,
    setShowPopover,
    imoNumber,
    vesselName,
    vinNumber,
  } = props;
  const [reason, setReason] = useState('');
  const [enteredDate, setEnteredDate] = useState<any>();
  const [validDate, setvalidDate] = useState<any>();
  const [removedDate, setRemovedDate] = useState<any>();
  const { t } = useTranslation();
  const { dateFormat, apiDateFormat } = useDateFormat();
  const [listDataSource, setListDataSource] = useState();
  const companyId = getCompanyId();
  const [listId, setListId] = useState<any>();
  const [listName, setListName] = useState();
  const date = new Date();
  const validationGroupList: any = useRef();

  const bu: any = getBuList();
  const [buId, setBuId] = useState();
  const buList = JSON.parse(bu);

  useEffect(() => {
    if (buList) {
      const defaultBu = buList.find((bu: any) => bu.isDefault == true);
      setBuId(defaultBu.buId);
    }
  }, [buList]);

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-add-roles__popup',
      'data-testid': 'BuRuleCopyPopover',
    };
  }, []);

  useEffect(() => {
    setListId(undefined);
    setListName(undefined);
  }, [imoNumber, vinNumber]);

  const onListChange = (e: any) => {
    setListName(e.event.target.innerText);
    setListId(e.value);
  };

  const checkValidTillDate = () => {
    if (
      enteredDate != '' &&
      enteredDate != null &&
      validDate != '' &&
      validDate != null
    ) {
      const enterDate = new Date(enteredDate);
      const validTill = new Date(validDate);
      if (validTill.getTime() >= enterDate.getTime()) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const checkRemovedDate = () => {
    if (
      enteredDate != '' &&
      enteredDate != null &&
      removedDate != '' &&
      removedDate != null
    ) {
      const enterDate = new Date(enteredDate);
      const removeDate = new Date(removedDate);
      if (removeDate.getTime() >= enterDate.getTime()) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const getDataSource = async (entityName: any, attributeName: any) => {
    await api
      .get({
        url: getAPIRoute('sanctionsList', [entityName, attributeName]),
        params: { companyId: companyId, buId: buId },
      })
      .then((res) => {
        setListDataSource(res);
      });
  };

  useEffect(() => {
    if (imoNumber || vinNumber) {
      if (buId) {
        const entityName = 'Ship';
        const attributeName = 'ImoDisplayName';
        getDataSource(entityName, attributeName);
      }
    }
  }, [imoNumber, vinNumber, buId]);

  const onAddNewList = (e: any) => {
    if (
      validationGroupList.current?.instance.validate().isValid &&
      e.validationGroup.validate().isValid
    ) {
      if (checkValidTillDate()) {
        if (checkRemovedDate()) {
          const data = {
            entryName: imoNumber ? imoNumber : vinNumber,
            entryDisplayName: vesselName,
          };
          const roleJsonEdit: IAddToList = {
            listId: listId,
            entryNames: [data],
            reason: reason,
            enteredDate: apiDateFormat(enteredDate),
            validTillDate: apiDateFormat(validDate),
            removedDate: apiDateFormat(removedDate),
          };
          api
            .post({ url: apiConfig.addToListEntries, data: roleJsonEdit })
            .then((response) => {
              if (response) {
                setShowPopover(false);
                setReason('');
                setEnteredDate(null);
                setRemovedDate(null);
                setvalidDate(null);
                toast.success({
                  title: t('toast.vesselAddedToListSuccess', {
                    vesselName: vesselName,
                    listName: listName,
                  }),
                });
              }
            });
        } else {
          toast.error({
            title: 'Error',
            message: t('errors.removedDateCheck'),
          });
        }
      } else {
        toast.error({ title: 'Error', message: t('errors.validTillDate') });
      }
    }
  };

  const { isMobileResolution } = useResize();
  const [position, setPosition] = useState<any>('bottom');
  useEffect(() => {
    if (isMobileResolution) {
      setPosition('left');
    } else {
      setPosition('bottom');
    }
  }, [isMobileResolution]);

  return (
    <Popover
      id="rolesPopover"
      target={target}
      position={position}
      width={300}
      closeOnOutsideClick={true}
      visible={showPopover}
      onHiding={() => setShowPopover(false)}
      wrapperAttr={popupAttributes}
      hideOnParentScroll={false}
    >
      <div className="m-l-grid-clone-popup-wrap">
        <div className="m-l-grid-clone-popup-header m-l-grid-copy--popup-header">
          <h2>{t('labels.addToList')}</h2>
          <div className="m-l-header-close__icon-block">
            <Button
              className="app-c-btn app-c-icon-only-btn"
              onClick={() => setShowPopover(false)}
              elementAttr={{ 'data-testid': 'close' }}
            >
              <icons.IconCross />
            </Button>
          </div>
        </div>
        <ScrollView>
          <div className="m-l-grid-clone-popup-body">
            <div className="m-c-form-group mb-btm-space">
              <label className="m-l-input-label">
                {t('labels.list')} <span className="m-l-input-required">*</span>{' '}
              </label>
              <ValidationGroup ref={validationGroupList}>
                <SelectBox
                  searchEnabled={true}
                  valueExpr="listId"
                  displayExpr="listName"
                  elementAttr={{ 'data-testid': 'company' }}
                  dataSource={listDataSource}
                  className="m-c-input-control m-c-select-box"
                  id="companySelectBox"
                  onValueChanged={onListChange}
                  validationMessageMode="always"
                >
                  <Validator
                    validationRules={[
                      {
                        type: 'required',
                        message: t('errors.required'),
                      },
                    ]}
                  />
                </SelectBox>
              </ValidationGroup>
            </div>
            <div className="m-c-form-group mb-btm-space">
              <label className="m-l-input-label">{t('labels.reason')}</label>
              <TextBox
                className="m-c-input-control"
                value={reason}
                onValueChanged={(e) => {
                  setReason(e.value);
                }}
              ></TextBox>
            </div>
            <div className="m-c-form-group mb-btm-space">
              <label className="m-l-input-label">
                {t('labels.enteredDate')}
              </label>
              <DateBox
                className="m-c-input-control m-c-date-picker"
                type="date"
                max={date}
                validationMessageMode="always"
                value={enteredDate}
                displayFormat={dateFormat}
                onValueChanged={(e) => {
                  setEnteredDate(e.value);
                }}
              ></DateBox>
            </div>
            <div className="m-c-form-group mb-btm-space">
              <label className="m-l-input-label">
                {t('labels.validTillDate')}
              </label>
              <DateBox
                className="m-c-input-control m-c-date-picker"
                type="date"
                validationMessageMode="always"
                value={validDate}
                displayFormat={dateFormat}
                onValueChanged={(e) => {
                  setvalidDate(e.value);
                }}
              ></DateBox>
            </div>
            <div className="m-c-form-group mb-btm-space">
              <label className="m-l-input-label">
                {t('labels.removedDate')}
              </label>
              <DateBox
                className="m-c-input-control m-c-date-picker"
                type="date"
                max={date}
                validationMessageMode="always"
                value={removedDate}
                displayFormat={dateFormat}
                onValueChanged={(e) => {
                  setRemovedDate(e.value);
                }}
              ></DateBox>
            </div>
          </div>
        </ScrollView>
        <div className="m-l-grid-clone-popup-footer">
          <Button
            className="app-c-btn app-c-btn--secondary"
            elementAttr={{ 'data-testid': 'copy' }}
            onClick={onAddNewList}
          >
            {t('labels.add')}
          </Button>
        </div>
      </div>
    </Popover>
  );
}
