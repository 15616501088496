import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IDialogConfig } from '../../../../../types/dialog';
import ConfirmDialog from '../../../../common/confirm/ConfirmDialog';
import { Button } from 'devextreme-react/button';
import { IVetRelatedInfo } from '../../../../../types/vessel-vet-related-info';
import { FormProvider, useForm } from 'react-hook-form';
import GeneralForm from '../general-form/GeneralForm';
import CapForm from '../cap-form/CapForm';
import ScrubberForm from '../scrubber-form/ScrubberForm';
import CssrForm from '../cssr-form/CssrForm';
import MatrixForm from '../matrix-form/MatrixForm';
import SireForm from '../sire-form/SireForm';
import toast from '../../../../../utils/toast';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import { useDateFormat } from '../../../../../hooks/useDateFormat';
import { useLoaderContext } from '../../../../../contexts/LoaderContext';
import { isTabDirtyContext } from '../../../../../pages/vessel-info/VesselInformation';

export default function EditVetInfo(props: {
  setIsEdit: any;
  setListView: any;
  vetRelatedInfo: any;
  getInfo: any;
  imoNumber?: string;
}) {
  const { setIsEdit, setListView, vetRelatedInfo, getInfo, imoNumber } = props;
  const { t } = useTranslation();
  const { setIsLoading } = useLoaderContext();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isconfirmBackDialog, setIsConfirmBackDialog] = useState(false);
  const { apiDateFormat } = useDateFormat();
  const setTabDirtyFlag = useContext(isTabDirtyContext);
  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.saveChanges'),
    handleSubmit: () => {
      onValueSubmit();
      setIsConfirmModalOpen(false);
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };
  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.cancelWithoutSaving'),
    handleSubmit: () => {
      setIsEdit(false);
      setListView(true);
      setIsConfirmBackDialog(false);
      setTabDirtyFlag.setState(
        setTabDirtyFlag.state == 0
          ? setTabDirtyFlag.state
          : setTabDirtyFlag.state - 1
      );
    },
    handleClose: () => {
      setIsConfirmBackDialog(false);
    },
  };

  const defaultFormValues: IVetRelatedInfo = {
    imoNumber: '',
    companyId: 0,
    piInsuranceStartDate: '',
    piInsuranceEndDate: '',
    hmInsuranceStartDate: '',
    hmInsuranceEndDate: '',
    cofrValidityDate: '',
    vrpValidityDate: '',
    uscgcocValidityDate: '',
    codValidityDate: '',
    coiValidityDate: '',
    tsmsValidityDate: '',
    lastDrydockDate: '',
    uwildDate: '',
    conditionClassValidTillDate: '',
    questionnaireValidityDate: '',
    managementChangeReview: false,
    matrixDate: '',
    matrixValidityDate: '',
    cssrDate: '',
    cssrValidityDate: '',
    scrubberFitted: false,
    scrubberType: '',
    scrubberTypeCode: '',
    capRatingHull: 0,
    capRatingHullDate: '',
    capRatingMachinery: 0,
    capRatingMachineryDate: '',
    capRatingCargoSystem: 0,
    capRatingCargoSystemDate: '',
    capRatingElectricalEquipment: 0,
    capRatingElectricalEquipmentDate: '',
    capRatingArrangements: 0,
    capRatingArrangementsDate: '',
    capRatingPropulsionPlant: 0,
    capRatingPropulsionPlantDate: '',
    capRatingBallastTankRating: 0,
    capRatingBallastTankRatingDate: '',
    capRatingExternalStructure: 0,
    capRatingExternalStructureDate: '',
    sireDate: '',
    sireValidityDate: '',
    operationsType: '',
    operationsTypeCode: '',
    conductedBy: '',
    comments: '',
    timeCharterer: '',
    broker: '',
  };

  const vetInfoForm = useForm({
    defaultValues: defaultFormValues,
  });

  const onHandleSave = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      setIsConfirmModalOpen(true);
    }
  };

  const { isDirty } = vetInfoForm.formState;

  const handleBackClick = () => {
    isDirty
      ? setIsConfirmBackDialog(true)
      : (setIsEdit(false), setListView(true));
  };

  const onValueSubmit = () => {
    const vetInfoFormValue: IVetRelatedInfo = vetInfoForm.getValues();
    const roleJsonEdit: IVetRelatedInfo = {
      imoNumber: imoNumber,
      companyId: vetInfoFormValue.companyId,
      piInsuranceStartDate: apiDateFormat(
        vetInfoFormValue.piInsuranceStartDate
      ),
      piInsuranceEndDate: apiDateFormat(vetInfoFormValue.piInsuranceEndDate),
      hmInsuranceStartDate: apiDateFormat(
        vetInfoFormValue.hmInsuranceStartDate
      ),
      hmInsuranceEndDate: apiDateFormat(vetInfoFormValue.hmInsuranceEndDate),
      cofrValidityDate: apiDateFormat(vetInfoFormValue.cofrValidityDate),
      vrpValidityDate: apiDateFormat(vetInfoFormValue.vrpValidityDate),
      uscgcocValidityDate: apiDateFormat(vetInfoFormValue.uscgcocValidityDate),
      codValidityDate: apiDateFormat(vetInfoFormValue.codValidityDate),
      coiValidityDate: apiDateFormat(vetInfoFormValue.coiValidityDate),
      tsmsValidityDate: apiDateFormat(vetInfoFormValue.tsmsValidityDate),
      lastDrydockDate: apiDateFormat(vetInfoFormValue.lastDrydockDate),
      uwildDate: apiDateFormat(vetInfoFormValue.uwildDate),
      conditionClassValidTillDate: apiDateFormat(
        vetInfoFormValue.conditionClassValidTillDate
      ),
      questionnaireValidityDate: apiDateFormat(
        vetInfoFormValue.questionnaireValidityDate
      ),
      managementChangeReview:
        vetInfoFormValue.managementChangeReview === 'true' ? true : false,
      matrixDate: apiDateFormat(vetInfoFormValue.matrixDate),
      matrixValidityDate: apiDateFormat(vetInfoFormValue.matrixValidityDate),
      cssrDate: apiDateFormat(vetInfoFormValue.cssrDate),
      cssrValidityDate: apiDateFormat(vetInfoFormValue.cssrValidityDate),
      scrubberFitted: vetInfoFormValue.scrubberFitted,
      scrubberTypeCode: vetInfoFormValue.scrubberTypeCode,
      capRatingHull: vetInfoFormValue.capRatingHull,
      capRatingHullDate: apiDateFormat(vetInfoFormValue.capRatingHullDate),
      capRatingMachinery: vetInfoFormValue.capRatingMachinery,
      capRatingMachineryDate: apiDateFormat(
        vetInfoFormValue.capRatingMachineryDate
      ),
      capRatingCargoSystem: vetInfoFormValue.capRatingCargoSystem,
      capRatingCargoSystemDate: apiDateFormat(
        vetInfoFormValue.capRatingCargoSystemDate
      ),
      capRatingElectricalEquipment:
        vetInfoFormValue.capRatingElectricalEquipment,
      capRatingElectricalEquipmentDate: apiDateFormat(
        vetInfoFormValue.capRatingElectricalEquipmentDate
      ),
      capRatingArrangements: vetInfoFormValue.capRatingArrangements,
      capRatingArrangementsDate: apiDateFormat(
        vetInfoFormValue.capRatingArrangementsDate
      ),
      capRatingPropulsionPlant: vetInfoFormValue.capRatingPropulsionPlant,
      capRatingPropulsionPlantDate: apiDateFormat(
        vetInfoFormValue.capRatingPropulsionPlantDate
      ),
      capRatingBallastTankRating: vetInfoFormValue.capRatingBallastTankRating,
      capRatingBallastTankRatingDate: apiDateFormat(
        vetInfoFormValue.capRatingBallastTankRatingDate
      ),
      capRatingExternalStructure: vetInfoFormValue.capRatingExternalStructure,
      capRatingExternalStructureDate: apiDateFormat(
        vetInfoFormValue.capRatingExternalStructureDate
      ),
      sireDate: apiDateFormat(vetInfoFormValue.sireDate),
      sireValidityDate: apiDateFormat(vetInfoFormValue.sireValidityDate),
      operationsTypeCode: vetInfoFormValue.operationsTypeCode,
      conductedBy: vetInfoFormValue.conductedBy,
      comments: vetInfoFormValue.comments,
      timeCharterer: vetInfoFormValue.timeCharterer,
      broker: vetInfoFormValue.broker,
    };
    api
      .put({ url: apiConfig.vetRelatedInfo, data: roleJsonEdit }, setIsLoading)
      .then((response) => {
        if (response == true) {
          getInfo();
          setIsEdit(false);
          setListView(true);
          setTabDirtyFlag.setState(
            setTabDirtyFlag.state == 0
              ? setTabDirtyFlag.state
              : setTabDirtyFlag.state - 1
          );
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
        }
      });
  };

  useEffect(() => {
    vetInfoForm.reset();
    if (vetRelatedInfo) {
      vetInfoForm.reset(
        {
          imoNumber: imoNumber,
          companyId: vetRelatedInfo.companyId,
          piInsuranceStartDate: vetRelatedInfo.piInsuranceStartDate,
          piInsuranceEndDate: vetRelatedInfo.piInsuranceEndDate,
          hmInsuranceStartDate: vetRelatedInfo.hmInsuranceStartDate,
          hmInsuranceEndDate: vetRelatedInfo.hmInsuranceEndDate,
          cofrValidityDate: vetRelatedInfo.cofrValidityDate,
          vrpValidityDate: vetRelatedInfo.vrpValidityDate,
          uscgcocValidityDate: vetRelatedInfo.uscgcocValidityDate,
          codValidityDate: vetRelatedInfo.codValidityDate,
          coiValidityDate: vetRelatedInfo.coiValidityDate,
          tsmsValidityDate: vetRelatedInfo.tsmsValidityDate,
          lastDrydockDate: vetRelatedInfo.lastDrydockDate,
          uwildDate: vetRelatedInfo.uwildDate,
          conditionClassValidTillDate:
            vetRelatedInfo.conditionClassValidTillDate,
          questionnaireValidityDate: vetRelatedInfo.questionnaireValidityDate,
          managementChangeReview:
            vetRelatedInfo.managementChangeReview?.toString(),
          matrixDate: vetRelatedInfo.matrixDate,
          matrixValidityDate: vetRelatedInfo.matrixValidityDate,
          cssrDate: vetRelatedInfo.cssrDate,
          cssrValidityDate: vetRelatedInfo.cssrValidityDate,
          scrubberFitted: vetRelatedInfo.scrubberFitted,
          scrubberType: vetRelatedInfo.scrubberType,
          scrubberTypeCode: vetRelatedInfo.scrubberTypeCode,
          capRatingHull: vetRelatedInfo?.capRatingHull?.toString(),
          capRatingHullDate: vetRelatedInfo.capRatingHullDate,
          capRatingMachinery: vetRelatedInfo?.capRatingMachinery?.toString(),
          capRatingMachineryDate: vetRelatedInfo.capRatingMachineryDate,
          capRatingCargoSystem:
            vetRelatedInfo?.capRatingCargoSystem?.toString(),
          capRatingCargoSystemDate: vetRelatedInfo.capRatingCargoSystemDate,
          capRatingElectricalEquipment:
            vetRelatedInfo?.capRatingElectricalEquipment?.toString(),
          capRatingElectricalEquipmentDate:
            vetRelatedInfo.capRatingElectricalEquipmentDate,
          capRatingArrangements:
            vetRelatedInfo?.capRatingArrangements?.toString(),
          capRatingArrangementsDate: vetRelatedInfo.capRatingArrangementsDate,
          capRatingPropulsionPlant:
            vetRelatedInfo?.capRatingPropulsionPlant?.toString(),
          capRatingPropulsionPlantDate:
            vetRelatedInfo.capRatingPropulsionPlantDate,
          capRatingBallastTankRating:
            vetRelatedInfo?.capRatingBallastTankRating?.toString(),
          capRatingBallastTankRatingDate:
            vetRelatedInfo.capRatingBallastTankRatingDate,
          capRatingExternalStructure:
            vetRelatedInfo?.capRatingExternalStructure?.toString(),
          capRatingExternalStructureDate:
            vetRelatedInfo.capRatingExternalStructureDate,
          sireDate: vetRelatedInfo.sireDate,
          sireValidityDate: vetRelatedInfo.sireValidityDate,
          operationsType: vetRelatedInfo.operationsType,
          operationsTypeCode: vetRelatedInfo.operationsTypeCode,
          conductedBy: vetRelatedInfo.conductedBy,
          comments: vetRelatedInfo.comments,
          timeCharterer: vetRelatedInfo.timeCharterer,
          broker: vetRelatedInfo.broker,
        },
        { keepDirty: true }
      );
    }
  }, [vetRelatedInfo]);

  useEffect(() => {
    if (isDirty) {
      setTabDirtyFlag.setState(setTabDirtyFlag.state + 1);
    }
  }, [isDirty]);

  return (
    <div className="m-l-inner-page-body">
      <div className="m-l-form-panel-block">
        <div className="m-l-tbl-square-wrapper m-l-tbl-square-wrapper--edit-mode u-pt0">
          <div className="row m-l-custom-eight-space-row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items m-l-custom-sixteen-space-col">
              <div className="m-l-details-page-small-heading">
                {t('labels.general')}
              </div>
            </div>
            <FormProvider {...vetInfoForm}>
              <GeneralForm />
            </FormProvider>
          </div>
          <div className="row m-l-custom-eight-space-row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items u-mb0 m-l-custom-sixteen-space-col">
              <div className="m-l-details-page-small-heading">
                {t('labels.scrubber')}
              </div>
            </div>
            <FormProvider {...vetInfoForm}>
              <ScrubberForm vetRelatedInfo={vetRelatedInfo} />
            </FormProvider>
          </div>
          <div className="row m-l-custom-eight-space-row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items">
              <div className="m-l-details-page-small-heading">
                {t('labels.capRatingDetails')}
              </div>
            </div>
            <FormProvider {...vetInfoForm}>
              <CapForm />
            </FormProvider>
          </div>
          <div className="row m-l-custom-eight-space-row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items">
              <div className="m-l-details-page-small-heading">
                {t('labels.sire')}
              </div>
            </div>
            <FormProvider {...vetInfoForm}>
              <SireForm />
            </FormProvider>
          </div>
          <div className="row m-l-custom-eight-space-row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items">
              <div className="m-l-details-page-small-heading">
                {t('labels.matrix')}
              </div>
            </div>
            <FormProvider {...vetInfoForm}>
              <MatrixForm />
            </FormProvider>
          </div>
          <div className="row m-l-custom-eight-space-row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items">
              <div className="m-l-details-page-small-heading">
                {t('labels.cssr')}
              </div>
            </div>
            <FormProvider {...vetInfoForm}>
              <CssrForm />
            </FormProvider>
          </div>

          <div className="m-l-panel-right-btn u-pb0">
            <Button
              className="app-c-btn app-c-btn--secondary min-btn-width"
              onClick={handleBackClick}
            >
              Cancel
            </Button>
            <Button
              onClick={onHandleSave}
              className="app-c-btn app-c-btn--primary min-btn-width"
            >
              Save
            </Button>
            <ConfirmDialog
              dialogConfig={confirmSaveDialogConfig}
              isOpen={isConfirmModalOpen}
            />
            <ConfirmDialog
              dialogConfig={confirmBackDialogConfig}
              isOpen={isconfirmBackDialog}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
