import Accordion from 'devextreme-react/accordion';
import React, { useMemo, useState } from 'react';
import { BerthFItInformationParams } from '../../../../enums/berthfit-information-enum';
import Attachments from '../../attachments/Attachments';
import NotesGrid from '../notes-grid/NotesGrid';
import PortContactGrid from '../port-contact-grid/PortContactGrid';

export default function PortAccordion(props: {
  portCode: any;
  isFromViewInfoModal?: boolean;
  parameterInfo?: any[];
}) {
  const { portCode, isFromViewInfoModal, parameterInfo } = props;
  const [selectedItems, setSelectedItems] = useState<any>([]);

  const accordionItems = useMemo(() => {
    return [
      {
        title: 'Special Notes',
        visible:
          !isFromViewInfoModal ||
          (isFromViewInfoModal &&
            parameterInfo?.includes(BerthFItInformationParams.SpecialNotes)),
      },
      {
        title: 'Contacts',
        visible:
          !isFromViewInfoModal ||
          (isFromViewInfoModal &&
            parameterInfo?.includes(BerthFItInformationParams.PortContacts)),
      },
      {
        title: 'Attachements',
        visible: !isFromViewInfoModal,
      },
    ];
  }, [parameterInfo]);

  const accordionItemRender = (e: any) => {
    switch (e.title) {
      case accordionItems[0].title: {
        return (
          <NotesGrid
            portCode={portCode}
            isFromViewInfoModal={isFromViewInfoModal}
          />
        );
      }
      case accordionItems[1].title: {
        return (
          <PortContactGrid
            portCode={portCode}
            isFromViewInfoModal={isFromViewInfoModal}
          />
        );
      }
      case accordionItems[2].title: {
        return (
          <Attachments
            isFromViewInfoModal={isFromViewInfoModal}
            portCode={portCode}
          />
        );
      }
    }
  };

  const selectionChanged = (e: any) => {
    let newItems: any = [...selectedItems];
    e.removedItems.map((item: any) => {
      const index = newItems.indexOf(item);
      if (index >= 0) {
        newItems.splice(index, 1);
      }
    });
    if (e.addedItems.length) {
      newItems = [...newItems, ...e.addedItems];
    }
    setSelectedItems(newItems);
  };

  return (
    <div className="m-l-accordion-panel-body-block">
      <div className="m-l-accordion__main-section u-p0">
        <div className="m-c-accordion">
          <Accordion
            collapsible={true}
            multiple={true}
            items={accordionItems}
            itemRender={accordionItemRender}
            selectedItems={selectedItems}
            onSelectionChanged={selectionChanged}
          />
        </div>
      </div>
    </div>
  );
}
