import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Button } from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import { IModalConfig } from '../../types/modal';
import Modal from '../common/modal/Modal';
import { MarsApiService as api } from '../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import { useTranslation } from 'react-i18next';
import toast from '../../utils/toast';
import { FormProvider, useForm } from 'react-hook-form';
import { IUserProfile } from '../../types/user';
import UserProfileForm from './user-profile-form/UserProfileForm';
import { IDialogConfig } from '../../types/dialog';
import ConfirmDialog from '../common/confirm/ConfirmDialog';
import icons from '../common/icons/icons';
import { useLoaderContext } from '../../contexts/LoaderContext';
import ValidationGroup from 'devextreme-react/validation-group';
import { getEmployeeId } from '../../utils/jwt-decode';
import { removeKey } from '../../services/master-data-service';
import { DimensionUnit } from '../../enums/dimension-unit-enum';
import { useDimensionsUnit } from '../../hooks/useDimensionsUnit';
import { Restricted } from '../../contexts/PermissionContext';

export default function UserProfile(props: {
  userProfile: any;
  setUserProfile: any;
}) {
  const { t } = useTranslation();
  const { userProfile, setUserProfile } = props;
  const [userDetails, setUserDetails] = useState<IUserProfile>();
  const bu: any = sessionStorage.getItem('buList')
    ? sessionStorage.getItem('buList')
    : localStorage.getItem('buList');
  const [buId, setBuId] = useState();
  const buList = JSON.parse(bu);
  const [isconfirmBackDialog, setIsConfirmBackDialog] = useState(false);
  const { setIsLoading } = useLoaderContext();
  const employeeId = getEmployeeId();
  const validationGroup: any = useRef();
  const { setDimensionUnit } = useDimensionsUnit();

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.goBackConfirm'),
    handleSubmit: () => {
      setUserProfile(false);
      setIsConfirmBackDialog(false);
    },
    handleClose: () => {
      setIsConfirmBackDialog(false);
    },
  };

  const popupAttributesChangePWD = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-edit-name-popup',
    };
  }, []);

  useEffect(() => {
    const defaultBu = buList.find((bu: any) => bu.isDefault == true);
    setBuId(defaultBu.buId);
  }, []);

  const getUserDetails = async () => {
    await api
      .get({
        url: apiConfig.userProfile,
        params: { buId: buId },
      })
      .then((response: any) => {
        setUserDetails(response);
      });
  };

  useEffect(() => {
    if (buId) {
      getUserDetails();
    }
  }, [buId]);

  const modalConfigChangePWD: IModalConfig = {
    width: 400,
    height: 540,
    visible: userProfile,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const defaultFormValues: IUserProfile = {
    employeeName: '',
    employeeEmail: '',
    employeeCode: null,
    callingCode: '',
    phoneNumber: null,
    employeeStatus: '',
    unitOfLength: DimensionUnit.Meter,
  };

  const userProfileForm = useForm({
    defaultValues: defaultFormValues,
  });

  const { isDirty } = userProfileForm.formState;

  const handleCancelClick = () => {
    isDirty ? setIsConfirmBackDialog(true) : setUserProfile(false);
  };

  const handleSubmit = async () => {
    const requiredValidation = validationGroup.current.instance.validate();
    if (requiredValidation.isValid) {
      const userFormValue: IUserProfile = userProfileForm.getValues();
      const roleJsonEdit: IUserProfile = {
        employeeId: employeeId,
        employeeName: userFormValue.employeeName,
        callingCode: userFormValue.callingCode,
        phoneNumber: userFormValue.phoneNumber,
        unitOfLength: userFormValue.unitOfLength,
      };
      api
        .put({ url: apiConfig.userProfile, data: roleJsonEdit }, setIsLoading)
        .then((response) => {
          if (response == true) {
            removeKey('profile');
            getUserDetails();
            setDimensionUnit(roleJsonEdit.unitOfLength);
            toast.custom({
              title: t('toast.changesSavedSuccessfully'),
            });
            setUserProfile(false);
          }
        });
    }
  };

  useEffect(() => {
    userProfileForm.reset();
    if (userDetails) {
      userProfileForm.reset(
        {
          employeeName: userDetails.employeeName,
          employeeEmail: userDetails.employeeEmail,
          employeeCode: userDetails.employeeCode,
          callingCode: userDetails?.callingCode,
          phoneNumber: userDetails.phoneNumber,
          employeeStatus: userDetails.employeeStatus,
          unitOfLength: userDetails.unitOfLength,
        },
        { keepDirty: true }
      );
    }
  }, [userDetails]);

  return (
    <ValidationGroup ref={validationGroup}>
      <div className="m-l-model-popup__wrap">
        <Modal
          modalConfig={modalConfigChangePWD}
          wrapperAttr={popupAttributesChangePWD}
        >
          <div className="m-l-modal__header">
            <h2 className="modal-title">{t('labels.userProfile')}</h2>
            <div className="m-l-modal-close-button-block">
              <Button
                className="app-c-btn app-c-icon-only-btn"
                onClick={handleCancelClick}
              >
                <icons.IconCross />
              </Button>
            </div>
          </div>
          <div className="m-l-modal__body">
            <ScrollView width="100%" height="100%">
              <div className="m-l-edit-form-panel">
                <FormProvider {...userProfileForm}>
                  <UserProfileForm buRoleList={userDetails?.buRoleList} />
                </FormProvider>
              </div>
            </ScrollView>
          </div>
          <div className="m-l-modal__footer">
            <Button
              className="app-c-btn app-c-btn--secondary"
              onClick={handleCancelClick}
            >
              {t('labels.cancel')}
            </Button>
            <Restricted permission="UserProfile.EditUserProfile">
              <Button
                className="app-c-btn app-c-btn--primary"
                onClick={handleSubmit}
              >
                {t('labels.save')}
              </Button>
            </Restricted>
          </div>
          <ConfirmDialog
            dialogConfig={confirmBackDialogConfig}
            isOpen={isconfirmBackDialog}
          />
        </Modal>
      </div>
    </ValidationGroup>
  );
}
