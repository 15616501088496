import React, { useContext, useEffect, useState } from 'react';
import Button from 'devextreme-react/button';
import { FormProvider, useForm } from 'react-hook-form';
import YardForm from '../yard-form/YardForm';
import { useTranslation } from 'react-i18next';
import { IDialogConfig } from '../../../../../types/dialog';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import toast from '../../../../../utils/toast';
import { IYardForm } from '../../../../../types/vessel-yard-tab';
import ConfirmDialog from '../../../../common/confirm/ConfirmDialog';
import { useDateFormat } from '../../../../../hooks/useDateFormat';
import { useLoaderContext } from '../../../../../contexts/LoaderContext';
import { isTabDirtyContext } from '../../../../../pages/vessel-info/VesselInformation';

export default function YardEdit(props: {
  setIsEdit: any;
  setYardView: any;
  yardDetails: any;
  getYardDetails: any;
  imoNumber?: string;
}) {
  const { setYardView, setIsEdit, yardDetails, getYardDetails, imoNumber } =
    props;
  const { apiDateFormat } = useDateFormat();
  const { t } = useTranslation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isconfirmBackDialog, setIsConfirmBackDialog] = useState(false);
  const { setIsLoading } = useLoaderContext();
  const setTabDirtyFlag = useContext(isTabDirtyContext);
  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmSaveChanges'),
    handleSubmit: () => {
      onValueSubmit();
      setIsConfirmModalOpen(false);
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };
  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setIsEdit(false);
      setYardView(true);
      setIsConfirmBackDialog(false);
      setTabDirtyFlag.setState(
        setTabDirtyFlag.state == 0
          ? setTabDirtyFlag.state
          : setTabDirtyFlag.state - 1
      );
    },
    handleClose: () => {
      setIsConfirmBackDialog(false);
    },
  };

  const defaultYardFormValues: IYardForm = {
    imoNumber: '',
    dateOfBuild: '',
    launchDate: '',
    builder: '',
    yardNumber: '',
    countryCodeOfBuild: '',
  };

  const yardForm = useForm({
    defaultValues: defaultYardFormValues,
  });

  const onHandleSave = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      setIsConfirmModalOpen(true);
    }
  };
  const { isDirty } = yardForm.formState;

  const handleBackClick = () => {
    isDirty
      ? setIsConfirmBackDialog(true)
      : (setIsEdit(false), setYardView(true));
  };

  useEffect(() => {
    yardForm.reset();
    if (yardDetails) {
      yardForm.reset(
        {
          imoNumber: imoNumber,
          dateOfBuild: yardDetails.dateOfBuild,
          launchDate: yardDetails.launchDate,
          builder: yardDetails.builder,
          yardNumber: yardDetails.yardNumber,
          countryCodeOfBuild: yardDetails.countryCodeOfBuild,
        },
        { keepDirty: true }
      );
    }
  }, [yardDetails]);

  const onValueSubmit = () => {
    const yardFormValue: IYardForm = yardForm.getValues();
    const roleJsonEdit: IYardForm = {
      imoNumber: imoNumber,
      dateOfBuild: apiDateFormat(yardFormValue.dateOfBuild),
      launchDate: apiDateFormat(yardFormValue.launchDate),
      builder: yardFormValue.builder,
      yardNumber: yardFormValue.yardNumber,
      countryCodeOfBuild: yardFormValue.countryCodeOfBuild,
      imoDisplayName: yardDetails.imoDisplayName,
    };
    api
      .put({ url: apiConfig.yardTab, data: roleJsonEdit }, setIsLoading)
      .then((response) => {
        if (response == true) {
          setTabDirtyFlag.setState(
            setTabDirtyFlag.state == 0
              ? setTabDirtyFlag.state
              : setTabDirtyFlag.state - 1
          );
          getYardDetails();
          setIsEdit(false);
          setYardView(true);
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
        }
      });
  };

  useEffect(() => {
    if (isDirty) {
      setTabDirtyFlag.setState(setTabDirtyFlag.state + 1);
    }
  }, [isDirty]);

  return (
    <div className="m-l-inner-page-body">
      <div className="m-l-form-panel-block">
        <div className="m-l-tbl-square-wrapper m-l-tbl-square-wrapper--edit-mode">
          <div className="row m-l-custom-eight-space-row">
            <FormProvider {...yardForm}>
              <YardForm />
            </FormProvider>
          </div>
          <div className="m-l-panel-right-btn u-pb0">
            <Button
              className="app-c-btn app-c-btn--secondary min-btn-width"
              onClick={handleBackClick}
            >
              Cancel
            </Button>
            <Button
              className="app-c-btn app-c-btn--primary min-btn-width"
              onClick={onHandleSave}
            >
              Save
            </Button>
            <ConfirmDialog
              dialogConfig={confirmSaveDialogConfig}
              isOpen={isConfirmModalOpen}
            />
            <ConfirmDialog
              dialogConfig={confirmBackDialogConfig}
              isOpen={isconfirmBackDialog}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
