import React, { useEffect, useRef, useState } from 'react';
import {
  IPortInfo,
  IRuleDetails,
  IRuleList,
  IRuleValues,
} from '../../../../types/vessel-info-rules';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import Switch from 'devextreme-react/switch';
import icons from '../../../common/icons/icons';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import RuleDetailsFormFields from '../rule-details-form-fields/RuleDetailsFormFields';
import AdditionalInfoModal from '../additional-info-modal/AdditionalInfoModal';
import { getAPIRoute } from '../../../../utils/api-route';
import { IDialogConfig } from '../../../../types/dialog';
import toast from '../../../../utils/toast';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import ValidationGroup from 'devextreme-react/validation-group';
import RfiTemplate from '../../../common/rfi-template/RfiTemplate';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import IncidentGrid from '../incident-grid/IncidentGrid';
import { Entity } from '../../../../enums/entity-enum';
import PSCGrid from '../psc-grid/PSCGrid';
import { Restricted } from '../../../../contexts/PermissionContext';
import ManagementHistoryGrid from '../management-history-grid/ManagementHistoryGrid';
import { Tooltip } from 'devextreme-react/tooltip';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import RulePortDetails from '../rule-port-details/RulePortDetails';
import ManagementGrid from '../management-grid/ManagementGrid';
import { IOptionvalue } from '../../../../types/master-data';
import { useDimensionsUnit } from '../../../../hooks/useDimensionsUnit';

export default function RuleDetails(props: {
  selectedRule: IRuleList;
  imoNumber?: string;
  vetRequestId: number;
  getRuleList: (
    isFromRfi?: boolean,
    runOverrideRules?: boolean,
    ruleId?: number
  ) => Promise<void>;
  getMailCount?: any;
  isReviewer: boolean;
  showRunButton?: boolean;
}) {
  const { t } = useTranslation();

  const {
    selectedRule,
    vetRequestId,
    imoNumber,
    getRuleList,
    getMailCount,
    isReviewer,
    showRunButton,
  } = props;

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      saveRuleValues();
    },
    handleClose: () => {
      setShowConfirmSave(false);
    },
  };

  const confirmRuleExecuteDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.individualRuleExecuteConfirm', {
      ruleName: selectedRule?.ruleName,
    }),
    handleSubmit: async () => {
      setConfirmRuleExecute(false);
      await getRuleList(false, true, selectedRule?.ruleId);
      toast.success({ title: t('toast.ruleExecutedSuccessfully') });
    },
    handleClose: () => {
      setConfirmRuleExecute(false);
    },
  };
  const [showRFITemplate, setShowRFITemplate] = useState(false);
  const [isRFIClient, setIsRFIClient] = useState(false);
  const [ruleRFITemplateTypes, setRuleRFITemplateTypes] = useState();

  const [confirmRuleExecute, setConfirmRuleExecute] = useState(false);

  const { setIsLoading } = useLoaderContext();
  const [ruleDetails, setRuleDetails] = useState<IRuleDetails>();

  const [showAdditionalInfoModal, setShowAdditionalInfoModal] = useState(false);

  const [showConfirmSave, setShowConfirmSave] = useState(false);

  const [showIncident, setShowIncident] = useState(false);

  const [showPSC, setShowPSC] = useState(false);

  const [showManagementHistory, setShowManagementHistory] = useState(false);

  const [showManagement, setShowManagement] = useState(false);

  const [incidentsList, setIncidentsList] = useState([]);

  const [pscList, setPscList] = useState([]);

  const [loadPorts, setLoadPorts] = useState<IPortInfo[] | undefined>([]);

  const [dischargePorts, setDisachargePorts] = useState<
    IPortInfo[] | undefined
  >([]);

  const ruleValidationGroup = useRef<ValidationGroup>(null);

  const getRuleDetails = async () => {
    await api
      .get({
        url: getAPIRoute('vettingRuleDetails', [
          vetRequestId,
          selectedRule.ruleId,
        ]),
        params: { ruleVersion: selectedRule.version },
      })
      .then((res: IRuleDetails) => {
        // setLoadPortAndDischargePort(res);
        setShowIncident(
          !!res.ruleConditions.find(
            (item) =>
              item.entityName === Entity.Incident ||
              item.entityName === Entity.IncidentHistory
          )
        );
        setShowPSC(
          !!res.ruleConditions.find((item) => item.entityName === Entity.PSC)
        );
        setShowManagementHistory(
          !!res.ruleConditions.find(
            (item) => item.entityName === Entity.ManagementHistory
          )
        );
        setShowManagement(
          !!res.ruleConditions.find(
            (item) => item.entityName === Entity.Management
          )
        );
        res.ruleConditions = res.ruleConditions.filter(
          (item) =>
            item.entityName !== Entity.Incident &&
            item.entityName !== Entity.PSC &&
            item.entityName !== Entity.IncidentHistory &&
            item.entityName !== Entity.ManagementHistory &&
            item.entityName !== Entity.Port &&
            item.entityName !== Entity.Management
        );
        setRuleDetails(res);
      });
  };

  const setLoadPortAndDischargePort = () => {
    api
      .get({
        url: getAPIRoute('rulePorts', [vetRequestId, selectedRule?.ruleId]),
      })
      .then((res: IPortInfo[]) => {
        if (res && res.length > 0) {
          const loadPortList = res?.filter((item) => item.isLoadPort);
          setLoadPorts(loadPortList);
          const dischargePortList = res?.filter((item) => item.isDichargePort);
          setDisachargePorts(dischargePortList);
        }
      });
    // const portCondition = ruleData.ruleConditions?.find(
    //   (item) => item.entityName === Entity.Port
    // );
    // if (portCondition) {
    //   const loadPortList = portCondition.portsInfo?.filter(
    //     (item) => item.isLoadPort
    //   );
    //   setLoadPorts(loadPortList);
    //   const dischargePortList = portCondition.portsInfo?.filter(
    //     (item) => item.isDichargePort
    //   );
    //   setDisachargePorts(dischargePortList);
    // }
  };

  const ruleDetailForm = useForm();

  const { control, reset, getValues } = ruleDetailForm;

  const { dimensionUnit, meterToFeetConversion, feetToMeterConversion } =
    useDimensionsUnit();

  const { dirtyFields } = ruleDetailForm.formState;

  const { apiDateFormat } = useDateFormat();

  const ruleDetailFieldArray = useFieldArray({
    control: control,
    name: 'ruleDetailFormArray',
  });

  useEffect(() => {
    if (selectedRule.ruleId) {
      getRuleDetails();
      setLoadPortAndDischargePort();
    }
  }, [selectedRule]);

  useEffect(() => {
    reset();
    if (ruleDetails) {
      reset(
        {
          isPassed: selectedRule.isRulePassed,
          message: selectedRule.isRulePassed
            ? ruleDetails?.successMessage
            : ruleDetails?.failureMessage,
          ruleDetailFormArray: [],
        },
        { keepDirty: true }
      );
      if (ruleDetails?.ruleConditions?.length) {
        ruleDetails.ruleConditions.map((condition) => {
          ruleDetailFieldArray.append({
            attributeValue:
              condition.isConversionRequired && condition.value
                ? meterToFeetConversion(Number(condition.value))
                : condition.value,
          });
        });
      }
    }
  }, [ruleDetails, dimensionUnit]);

  const getRuleValueJson = (): IRuleValues => {
    return {
      imo: imoNumber,
      message: getValues().message,
      isOverrided: selectedRule.isRulePassed !== getValues().isPassed,
      isMessageUpdated: !!Object.keys(dirtyFields).find(
        (key) => key === 'message'
      ),
      vetRuleConditionValues: getValues().ruleDetailFormArray?.map(
        (item: any, index: number) => {
          const value =
            ruleDetails?.ruleConditions[index].isConversionRequired &&
            item.attributeValue
              ? feetToMeterConversion(Number(item.attributeValue))
              : item.attributeValue;
          return {
            entityId: ruleDetails?.ruleConditions[index].entityId,
            attributeId: ruleDetails?.ruleConditions[index].attributeId,
            entityName: ruleDetails?.ruleConditions[index].entityName,
            attributeName: ruleDetails?.ruleConditions[index].attributeName,
            attributeType: ruleDetails?.ruleConditions[index].attributeType,
            value: value
              ? ruleDetails?.ruleConditions[index].attributeType ===
                  'DateTime' ||
                ruleDetails?.ruleConditions[index].attributeType === 'Date'
                ? apiDateFormat(value)
                : value?.toString()
              : undefined,
          };
        }
      ),
    };
  };

  const onClickSave = () => {
    if (ruleValidationGroup.current?.instance.validate().isValid)
      setShowConfirmSave(true);
  };

  const saveRuleValues = async () => {
    api
      .put(
        {
          url: getAPIRoute('vetRequestRuleValues', [
            vetRequestId,
            selectedRule.ruleId,
          ]),
          data: getRuleValueJson(),
        },
        setIsLoading
      )
      .then(() => {
        toast.success({ title: t('toast.updatedSuccessfully') });
        getRuleList();
      });
    setShowConfirmSave(false);
  };

  const getRuleRFITemplateTypes = async () => {
    await api
      .get({
        url: apiConfig.vetEmailTypes,
      })
      .then((res) => {
        if (isRFIClient) {
          const type = res.find(
            (item: IOptionvalue) => item.optionValueCode === 'C'
          );
          setRuleRFITemplateTypes(type);
        } else {
          const type = res.find(
            (item: IOptionvalue) => item.optionValueCode === 'V'
          );
          setRuleRFITemplateTypes(type);
        }
      });
  };

  useEffect(() => {
    getRuleRFITemplateTypes();
  }, [isRFIClient]);

  return (
    <div className="accordion-item__body-rl-block" data-testid="ruleDetails">
      <div className="row m-l-cap-wrapper">
        <div className="col-xl-6 col-lg-6 co-md-6 col-sm-6 col-12 m-l-cap-left-part">
          <div className="m-l-cap-instruction-wrap">
            <div className="m-l-cap-instrn-title">
              {t('labels.ruleDescription')}
            </div>
            <div className="m-l-cap-instrns">{ruleDetails?.ruleDetails}</div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 co-md-6 col-sm-6 col-12 m-l-cap-right-part">
          <div className="m-l-cap-right-icon-part">
            <Restricted permission="VetRequest.RFI">
              {(ruleDetails?.rfItoClient || ruleDetails?.rfItoVesselOwner) && (
                <div className="m-l-info-attr">{t('labels.rfi')}</div>
              )}
              {ruleDetails?.rfItoClient && (
                <Button
                  className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                  aria-label=""
                  id="rfiToRequestor"
                  disabled={!isReviewer}
                  onClick={() => {
                    setIsRFIClient(true);
                    setShowRFITemplate(true);
                  }}
                >
                  <div className="m-c-icon-inside-btn">
                    <icons.IconBuildings />
                  </div>
                  <Tooltip
                    target={'#rfiToRequestor'}
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    position="bottom"
                    contentRender={() => {
                      return (
                        <div className="m-l-tooltip-wrapper">
                          {t('labels.rfiToRequestor')}
                        </div>
                      );
                    }}
                  />
                </Button>
              )}
              {ruleDetails?.rfItoVesselOwner && (
                <Button
                  className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                  aria-label=""
                  id="rfiToVesselOwner"
                  disabled={!isReviewer}
                  onClick={() => {
                    setIsRFIClient(false);
                    setShowRFITemplate(true);
                  }}
                >
                  <div className="m-c-icon-inside-btn">
                    <icons.IconShip />
                  </div>
                  <Tooltip
                    target={'#rfiToVesselOwner'}
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    position="bottom"
                    contentRender={() => {
                      return (
                        <div className="m-l-tooltip-wrapper">
                          {t('labels.rfiToVesselOwner')}
                        </div>
                      );
                    }}
                  />
                </Button>
              )}
            </Restricted>
            {ruleDetails?.isAdditionalDataPresent && (
              <Button
                className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                aria-label=""
                id="additionalDataCapturing"
                onClick={() => setShowAdditionalInfoModal(true)}
                disabled={!isReviewer}
              >
                <div className="m-c-icon-inside-btn">
                  <icons.AddIcon />
                </div>
                <Tooltip
                  target={'#additionalDataCapturing'}
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  position="bottom"
                  contentRender={() => {
                    return (
                      <div className="m-l-tooltip-wrapper">
                        {t('headers.additionalDataCapturing')}
                      </div>
                    );
                  }}
                />
              </Button>
            )}
          </div>
          <div className="m-l-cap-right-toggle-part">
            <div className="m-l-cap-toggle-switch-label">
              {t('labels.pass')}
            </div>
            <div className="m-l-toggle-switch">
              <Controller
                name="isPassed"
                control={control}
                render={({ field }) => (
                  <Restricted
                    permission="VetRequest.OverrideRule"
                    disableField={true}
                  >
                    <Switch
                      name="isPassed"
                      value={field.value}
                      onValueChanged={(e) => field.onChange(e.value)}
                      disabled={!ruleDetails?.canOverride || !isReviewer}
                    />
                  </Restricted>
                )}
              />
            </div>
            {selectedRule &&
              (selectedRule.isRulePassedByOverride ||
                selectedRule.isRuleFailedByOverride) &&
              showRunButton && (
                <Restricted permission="VetRequest.ReRunRules">
                  <Button
                    className="app-c-btn app-c-btn--secondary u-ml"
                    onClick={() => setConfirmRuleExecute(true)}
                  >
                    {t('buttons.reRun')}
                  </Button>
                </Restricted>
              )}
          </div>
        </div>
      </div>
      <ValidationGroup ref={ruleValidationGroup}>
        <div className="row m-l-cap-rules-wrapper">
          {ruleDetailFieldArray.fields.map((item: any, index: number) => {
            return (
              <FormProvider {...ruleDetailForm} key={item.id}>
                <RuleDetailsFormFields
                  ruleDetails={ruleDetails}
                  index={index}
                />
              </FormProvider>
            );
          })}
        </div>
      </ValidationGroup>
      <div className="row m-l-cap-message-wrapper u-mb">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="mb-3 m-c-form-group">
            <label className="m-l-input-label">{t('labels.message')}</label>
            <Controller
              name={`message`}
              control={control}
              render={({ field }) => (
                <TextBox
                  name="message"
                  className="m-c-input-control"
                  value={field.value}
                  onValueChanged={(e) => field.onChange(e.value)}
                ></TextBox>
              )}
            />
          </div>
          <div className="m-l-cap-footer">
            <Restricted permission="VetRequest.SaveRules">
              <div className="m-l-cap-btn">
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width"
                  onClick={onClickSave}
                  elementAttr={{ 'data-testid': 'ruleDetailSave' }}
                  disabled={!isReviewer}
                >
                  {t('buttons.save')}
                </Button>
              </div>
            </Restricted>
          </div>
        </div>
      </div>
      {showIncident && (
        <Restricted permission="Ship.ViewIncident">
          <IncidentGrid
            vetRequestId={vetRequestId}
            isReviewer={isReviewer}
            imoNumber={imoNumber}
            ruleId={selectedRule.ruleId}
            getRuleList={getRuleList}
            setIncidentsList={setIncidentsList}
          />
        </Restricted>
      )}
      {showPSC && (
        <Restricted permission="Ship.ViewPSC">
          <PSCGrid
            isReviewer={isReviewer}
            imoNumber={imoNumber}
            ruleId={selectedRule?.ruleId}
            getRuleList={getRuleList}
            setPscList={setPscList}
            vetRequestId={vetRequestId}
          />
        </Restricted>
      )}
      {showManagementHistory && (
        <Restricted permission="Ship.ViewManagementHistory">
          <ManagementHistoryGrid
            imoNumber={imoNumber}
            vetRequestId={vetRequestId}
            ruleId={selectedRule?.ruleId}
          />
        </Restricted>
      )}
      {showManagement && (
        <Restricted permission="Ship.ViewManagement">
          <ManagementGrid
            vetRequestId={vetRequestId}
            ruleId={selectedRule?.ruleId}
            isReviewer={isReviewer}
            getRuleList={getRuleList}
            imoNumber={imoNumber}
          />
        </Restricted>
      )}
      {showAdditionalInfoModal && (
        <AdditionalInfoModal
          imoNumber={imoNumber}
          ruleId={selectedRule?.ruleId}
          isVisible={showAdditionalInfoModal}
          vetRequestId={vetRequestId}
          getRuleList={getRuleList}
          handleCancelClick={() => setShowAdditionalInfoModal(false)}
        />
      )}
      {((loadPorts && loadPorts.length > 0) ||
        (dischargePorts && dischargePorts.length > 0)) && (
        <RulePortDetails
          loadPorts={loadPorts}
          dischargePorts={dischargePorts}
        />
      )}
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={showConfirmSave}
      />
      <ConfirmDialog
        dialogConfig={confirmRuleExecuteDialogConfig}
        isOpen={confirmRuleExecute}
      />
      {showRFITemplate && (
        <RfiTemplate
          isVisible={showRFITemplate}
          setIsVisible={setShowRFITemplate}
          ruleRFITemplateTypes={ruleRFITemplateTypes}
          isRFIClient={isRFIClient}
          vetRequestId={vetRequestId}
          ruleId={selectedRule.ruleId}
          getRuleList={getRuleList}
          incidentsList={incidentsList}
          pscList={pscList}
          getMailCount={getMailCount}
        />
      )}
    </div>
  );
}
