import Button from 'devextreme-react/button';
import TabPanel from 'devextreme-react/tab-panel';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PortForm from '../../../components/port-management/port-form/PortForm';
import TerminalGrid from '../../../components/port-management/terminal-grid/TerminalGrid';
import { useLocation, useNavigate } from 'react-router-dom';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import AddNewTerminal from '../../../components/port-management/add-new-terminal-popover/AddNewTerminal';
import { IPortSearchForm } from '../../../types/port-management';
import toast from '../../../utils/toast';
import { useTranslation } from 'react-i18next';
import { IEditPortInfo } from '../../../types/port-configurations';
import AddListPopover from '../../../components/port-management/add-list-popover/AddListPopover';
import icons from '../../../components/common/icons/icons';
import { useDimensionsUnit } from '../../../hooks/useDimensionsUnit';
import { IDialogConfig } from '../../../types/dialog';
import ConfirmDialog from '../../../components/common/confirm/ConfirmDialog';
import { Restricted } from '../../../contexts/PermissionContext';

export default function EditPort() {
  const tabRef = useRef<TabPanel>(null);
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<any>(0);
  const [selectedPortInfo, getselectedPortInfo] = useState<any>();
  const [selectedPortName, getselectedPortName] = useState<any>();
  const [codesToList, setCodesToList] = useState<any>();
  const [selectedPortCode, getselectedPortCode] = useState<any>();
  const [isAddNewTerminal, setIsAddNewTerminal] = useState(false);
  const [query, setQuery] = useState<any>();
  const [queryOg, setQueryOg] = useState<any>();
  const [isFromAddTerminal, setIsFromAddTerminal] = useState(false);
  const [params, setParams] = useState<any>();
  const [countriesList, setCountriesList] = useState<any>();
  const [portId, setPortId] = useState<any>();
  const { t } = useTranslation();
  const [isAddListTerminal, setIsAddListTerminal] = useState(false);
  const [showAddListPopover, setShowAddListPopover] = useState(false);
  const [terminalEntityNames, setTerminalEntityNames] = useState<any>([]);
  const [isExportTerminal, setIsExportTerminal] = useState(false);
  const [clearSelection, setClearSelection] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isConfirmTabChangeDialog, setIsConfirmTabChangeDialog] =
    useState(false);
  const [isConfirmBackDialog, setIsConfirmBackDialog] = useState(false);

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      onAddNewPort();
      setIsConfirmModalOpen(false);
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };

  const confirmTabChangeDialogConfig: IDialogConfig = {
    id: 'confirmTabChange',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      getSelectedPortInfo(portId);
      setIsConfirmTabChangeDialog(false);
    },
    handleClose: () => {
      tabRef.current?.instance.option('selectedIndex', 0);
      setIsConfirmTabChangeDialog(false);
    },
  };

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      goBackNavigation();
      setIsConfirmBackDialog(false);
    },
    handleClose: () => {
      setIsConfirmBackDialog(false);
    },
  };

  const navigate = useNavigate();
  const { feetToMeterConversion, meterToFeetConversion, dimensionUnit } =
    useDimensionsUnit();
  const getSelectedPortInfo = async (portId: any) => {
    if (portId) {
      await api
        .get({ url: apiConfig.getPortInfo + portId })
        .then((data: any) => {
          getselectedPortInfo(data);
          getselectedPortName(data?.portName);
        });
    }
  };

  const getCountries = async () => {
    await api.get({ url: apiConfig.country }).then((data: any) => {
      setCountriesList(data);
    });
  };

  const getPortSearchResult = async (params: IPortSearchForm) => {
    const query =
      '?Country=' +
      params.countryCode +
      '&Port=' +
      selectedPortCode +
      '&Terminal=' +
      params.terminal +
      '&Berth=' +
      params.berth;
    setQuery(query);
  };

  useEffect(() => {
    if (location.state) {
      const { portId, portName, portCode, query, params, codesToList }: any =
        location.state;
      getSelectedPortInfo(portId);
      getCountries();
      setPortId(portId);
      getselectedPortName(portName);
      getselectedPortCode(portCode);
      setQuery(query);
      queryOg ? setQueryOg(queryOg) : setQueryOg(query);
      setParams(params);
      setCodesToList(codesToList);
    }
  }, [location.state]);

  useEffect(() => {
    if (params) {
      getPortSearchResult(params);
    }
  }, [params]);

  const tabs = useMemo(() => {
    return [
      {
        title: t('labels.portInfo'),
      },
      {
        title: t('labels.terminals'),
      },
    ];
  }, []);

  const defaultPortFormValues = {
    portName: '',
    portCode: '',
    country: null,
    portSize: '',
    notes: null,
    salinityOfWater: '',
    latitude: '',
    longitude: '',
    region: null,
    unRefCode: '',
    portMaxDepthInnerHarbour: '',
    portMaxDepthOuterHarbour: '',
    portMaxAnchorageDepth: '',
    portMaxPermittedAge: '',
    maxDepthInChannel: '',
    minDepthInChannel: '',
    distanceFromPilotPoint: '',
  };

  const portInfoForm = useForm({ defaultValues: defaultPortFormValues });

  const { isDirty } = portInfoForm.formState;

  const { getValues } = portInfoForm;
  let portFormValue = getValues();

  const onAddNewPort = () => {
    portFormValue = portInfoForm.getValues();
    const roleJsonEdit: IEditPortInfo = {
      portId: portId,
      portCode: portFormValue.portCode,
      portName: portFormValue.portName,
      countryCode: portFormValue.country,
      unRefCode: portFormValue.unRefCode,
      regionName: portFormValue.region,
      latitude: portFormValue.latitude.toString(),
      longitude: portFormValue.longitude.toString(),
      portSizeType: portFormValue.portSize,
      salinityOfWaterdesc: portFormValue.salinityOfWater,
      portMaxDepthInnerHarbour: feetToMeterConversion(
        parseFloat(portFormValue.portMaxDepthInnerHarbour)
      ),
      portMaxDepthOuterHarbour: feetToMeterConversion(
        parseFloat(portFormValue.portMaxDepthOuterHarbour)
      ),
      portMaxAnchorAgeDepth: feetToMeterConversion(
        parseFloat(portFormValue.portMaxAnchorageDepth)
      ),
      portMaxVesselAge: parseFloat(portFormValue.portMaxPermittedAge),
      maxDepthInchannel: feetToMeterConversion(
        parseFloat(portFormValue.maxDepthInChannel)
      ),
      minDepthInchannel: feetToMeterConversion(
        parseFloat(portFormValue.minDepthInChannel)
      ),
      distanceFromPilotPoint: parseFloat(portFormValue.distanceFromPilotPoint),
      portParameterNotes: portFormValue.notes,
      portCoordinates: null,
    };
    api
      .put({ url: apiConfig.viewPort, data: roleJsonEdit })
      .then((response) => {
        if (response) {
          getSelectedPortInfo(portId);
          toast.success({
            title: t('toast.portUpdateSuccess'),
          });
          // setQuery(query);
        }
      });
  };

  const tabItemRender = (e: any) => {
    if (e.title === tabs[0].title) {
      return (
        <FormProvider {...portInfoForm}>
          <PortForm
            selectedPortInfo={selectedPortInfo}
            countriesList={countriesList}
            portCode={selectedPortCode}
          />
        </FormProvider>
      );
    } else if (e.title === tabs[1].title) {
      return (
        <TerminalGrid
          query={query}
          setIsFromAddTerminal={setIsFromAddTerminal}
          isFromAddTerminal={isFromAddTerminal}
          isFromPort={true}
          setIsAddListTerminal={setIsAddListTerminal}
          setTerminalEntityNames={setTerminalEntityNames}
          params={params}
          isExport={isExportTerminal}
          setIsExport={setIsExportTerminal}
          clearSelection={clearSelection}
          setClearSelection={setClearSelection}
        />
      );
    }
  };

  const onTabSelectionChanged = () => {
    setSelectedTab(tabRef.current?.instance.option('selectedIndex'));
    if (isDirty && selectedTab == 0) {
      setIsConfirmTabChangeDialog(true);
    }
  };

  const onClickSave = () => {
    setIsConfirmModalOpen(true);
  };

  useEffect(() => {
    portInfoForm.reset();
    if (selectedPortInfo) {
      portInfoForm.reset(
        {
          portName: selectedPortInfo.portName,
          portCode: selectedPortInfo.portCode,
          country: selectedPortInfo.countryCode,
          portSize: selectedPortInfo.portSizeType,
          notes: selectedPortInfo.portParameterNotes,
          salinityOfWater: selectedPortInfo.salinityOfWaterdesc,
          latitude: selectedPortInfo.latitude,
          longitude: selectedPortInfo.longitude,
          region: selectedPortInfo.regionName,
          unRefCode: selectedPortInfo.unRefCode,
          portMaxDepthInnerHarbour:
            selectedPortInfo.portMaxDepthInnerHarbour &&
            meterToFeetConversion(selectedPortInfo.portMaxDepthInnerHarbour),
          portMaxDepthOuterHarbour:
            selectedPortInfo.portMaxDepthOuterHarbour &&
            meterToFeetConversion(selectedPortInfo.portMaxDepthOuterHarbour),
          portMaxAnchorageDepth:
            selectedPortInfo.portMaxAnchorAgeDepth &&
            meterToFeetConversion(selectedPortInfo.portMaxAnchorAgeDepth),
          portMaxPermittedAge: selectedPortInfo.portMaxVesselAge,
          maxDepthInChannel:
            selectedPortInfo.maxDepthInchannel &&
            meterToFeetConversion(selectedPortInfo.maxDepthInchannel),
          minDepthInChannel:
            selectedPortInfo.minDepthInchannel &&
            meterToFeetConversion(selectedPortInfo.minDepthInchannel),
          distanceFromPilotPoint: selectedPortInfo.distanceFromPilotPoint,
        },
        { keepDirty: true }
      );
    }
  }, [selectedPortInfo, dimensionUnit]);

  const onAddClick = () => {
    setIsAddNewTerminal(true);
  };

  const goBack = () => {
    if (isDirty && selectedTab == 0) {
      setIsConfirmBackDialog(true);
    } else {
      goBackNavigation();
    }
  };

  const goBackNavigation = () => {
    navigate('/port', {
      state: {
        query: sessionStorage.getItem('portSearchQuery'),
        params: params,
      },
    });
  };

  return (
    <div className="inner-wrapper">
      <div className="m-l-inner-page-header">
        <div className="m-l-inner-page-header-left-block">
          <div className="m-l-page-main-heading">
            {t('labels.port')} {'-' + selectedPortName}
          </div>
        </div>
      </div>
      <div className="m-l-inner-page-body">
        <div className="m-l-tab-panel__wrap">
          <TabPanel
            ref={tabRef}
            // height={'600px'}
            items={tabs}
            className="m-c-tab-block m-c-tab--half-width"
            elementAttr={{ 'data-testid': 'portTab' }}
            itemRender={tabItemRender}
            onSelectionChanged={() => onTabSelectionChanged()}
            showNavButtons={true}
          />
          <div className="m-l-tab-panel-right-block">
            <Button
              className="app-c-btn app-c-btn--secondary"
              elementAttr={{ 'data-testid': 'addToList' }}
              onClick={goBack}
            >
              {t('labels.back')}
            </Button>
            {selectedTab === 0 && (
              <>
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width"
                  elementAttr={{ 'data-testid': 'addNew' }}
                  onClick={onClickSave}
                >
                  {t('labels.save')}
                </Button>
              </>
            )}
            {selectedTab === 1 && (
              <>
                <Button
                  className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                  aria-label="Upload"
                  onClick={() => setIsExportTerminal(true)}
                >
                  <div className="m-c-icon-inside-btn">
                    <icons.download />
                  </div>
                </Button>
                {isAddListTerminal && (
                  <Button
                    className="app-c-btn app-c-btn--secondary"
                    elementAttr={{ 'data-testid': 'addToList' }}
                    onClick={() => setShowAddListPopover(true)}
                    id="addToList"
                  >
                    {t('labels.addToList')}
                  </Button>
                )}
                <Restricted permission="PortManagement.AddNewTerminal">
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'addNew' }}
                    onClick={onAddClick}
                  >
                    {t('labels.addNew')}
                  </Button>
                </Restricted>
              </>
            )}
          </div>
          <AddListPopover
            showPopover={showAddListPopover}
            setShowPopover={setShowAddListPopover}
            target="#addToList"
            selectedTab={selectedTab}
            codesToList={codesToList}
            terminalEntityNames={terminalEntityNames}
            setClearSelection={setClearSelection}
          />
          {isAddNewTerminal && (
            <AddNewTerminal
              isAddNewTerminal={isAddNewTerminal}
              setIsAddNewTerminal={setIsAddNewTerminal}
              portCode={selectedPortCode}
              query={query}
              setQuery={setQuery}
              setIsFromAddTerminal={setIsFromAddTerminal}
            />
          )}
        </div>
      </div>
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <Button
                className="app-c-btn app-c-btn--secondary"
                elementAttr={{ 'data-testid': 'addToList' }}
                onClick={goBack}
              >
                {t('labels.back')}
              </Button>
              {selectedTab === 0 && (
                <>
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'addNew' }}
                    onClick={onClickSave}
                  >
                    {t('labels.save')}
                  </Button>
                </>
              )}
              {selectedTab === 1 && (
                <>
                  <Button
                    className="app-c-btn app-c-btn--secondary"
                    aria-label="Upload"
                    onClick={() => setIsExportTerminal(true)}
                  >
                    <div className="m-c-icon-inside-btn">
                      {t('labels.download')}
                    </div>
                  </Button>
                  {isAddListTerminal && (
                    <Button
                      className="app-c-btn app-c-btn--secondary"
                      elementAttr={{ 'data-testid': 'addToList' }}
                      onClick={() => setShowAddListPopover(true)}
                      id="addToList"
                    >
                      {t('labels.addToList')}
                    </Button>
                  )}
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'addNew' }}
                    onClick={onAddClick}
                  >
                    {t('labels.addNew')}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={isConfirmModalOpen}
      />
      <ConfirmDialog
        dialogConfig={confirmTabChangeDialogConfig}
        isOpen={isConfirmTabChangeDialog}
      />
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={isConfirmBackDialog}
      />
    </div>
  );
}
