import React, { useEffect, useRef, useState } from 'react';
import BerthAccordionHeader from '../../../components/berth-fit-check/berth-accordian-header/BerthAccordionHeader';
import TerminalBerthDetails from '../../../components/berth-fit-check/berth-details/TerminalBerthDetails';
import { useTranslation } from 'react-i18next';
import BerthInfo from '../../../components/berth-fit-check/info/berth-info/BerthInfo';
import Accordion from 'devextreme-react/accordion';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import {
  IVetBerthFitOverride,
  IVetBerthFitOverrideEdit,
} from '../../../types/vetrequest-berthfit-override';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { FormProvider, useForm } from 'react-hook-form';
import toast from '../../../utils/toast';
import ValidationGroup from 'devextreme-react/validation-group';
import BerthDetailsForm from '../../../components/berth-fit-check/berth-details-form/BerthDetailsForm';

export default function TerminalDetails(props: {
  berths: any;
  buId: any;
  imoNumber: any;
  berthFitId: any;
  getTerminalList: any;
  terminal: any;
}) {
  const { berths, imoNumber, berthFitId, buId, getTerminalList, terminal } =
    props;
  const { t } = useTranslation();
  const [berthId, setBerthId] = useState(0);
  const [berthCode, setBerthCode] = useState();
  const [status, setStatus] = useState('');
  const validationGroup: any = useRef();
  const onClickTitle = (e: any) => {
    if (
      e.event.target?.className === 'view-berthinfo' ||
      e.event.target?.className === 'm-l-view-info-link'
    ) {
      e?.event?.stopPropagation();
      setBerthCode(e.itemData.berthCode);
      setBerthId(e.itemData.berthId);
    }
  };
  const { setIsLoading } = useLoaderContext();
  const defaultValues = {
    comments: null,
    status: null,
  };

  const berthForm = useForm({
    defaultValues: defaultValues,
  });

  const { getValues } = berthForm;

  const handleSaveClick = () => {
    const requiredValidation = validationGroup.current.instance.validate();
    let listBerthFormValue: IVetBerthFitOverride = getValues();
    if (requiredValidation.isValid) {
      listBerthFormValue = berthForm.getValues();
      const roleJsonEdit: IVetBerthFitOverrideEdit = {
        comments: listBerthFormValue?.comments,
        status: listBerthFormValue.status,
        berthFitId: berthFitId,
        terminalId: terminal?.terminalId,
        isStatusChanged: terminal.isOverrided
          ? terminal.isOverrided
          : terminal.status === listBerthFormValue.status
          ? false
          : true,
      };
      api
        .put(
          { url: apiConfig.berthOverrideResult, data: roleJsonEdit },
          setIsLoading
        )
        .then((response) => {
          if (response) {
            toast.custom({
              title: t('toast.onSaveBerthfitComments'),
            });
            getTerminalList();
          }
        });
    }
  };

  useEffect(() => {
    setStatus(terminal.status);
  }, [terminal.status]);

  useEffect(() => {
    berthForm.reset();
    if (terminal) {
      berthForm.reset(
        {
          comments: terminal.comment,
          status: terminal.status,
        },
        { keepDirty: true }
      );
    }
  }, [terminal]);
  return (
    <div className="m-l-inner-first-accordion-section">
      <FormProvider {...berthForm}>
        <ValidationGroup ref={validationGroup}>
          <BerthDetailsForm
            handleSaveClick={handleSaveClick}
            berthParameters={{}}
            dataProps={terminal}
            status={status}
          />
        </ValidationGroup>
      </FormProvider>
      <div className="m-l-accordion-page-title-block">
        <h5>{t('labels.berths')}</h5>
      </div>
      <div className="m-l-accordion__main-section u-p0">
        <div className="m-c-accordion m-l-berthfit-check-second-inner-accordion open">
          <Accordion
            collapsible={true}
            items={berths}
            onItemClick={onClickTitle}
            itemTitleRender={(data: any) => {
              return <BerthAccordionHeader data={data} />;
            }}
            itemRender={(data: any) => {
              return (
                <TerminalBerthDetails
                  data={data}
                  buId={buId}
                  imoNumber={imoNumber}
                  berthFitId={berthFitId}
                  getTerminalList={getTerminalList}
                />
              );
            }}
          ></Accordion>
        </div>
      </div>
      <BerthInfo
        isVisible={berthId ? true : false}
        handleCancelClick={() => setBerthId(0)}
        berthId={berthId}
        buId={buId}
        berthCode={berthCode}
      />
    </div>
  );
}
