import TextBox from 'devextreme-react/text-box';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePermission } from '../../../../../../../contexts/PermissionContext';
import { IncidentStatus } from '../../../../../../../enums/status-enum';
import { MarsStatus } from '../../../../../../../types/incidents';
import { getCompanyType } from '../../../../../../../utils/jwt-decode';
import FormBuilder from '../../../../../../common/form-builder/FormBuilder';
import icons from '../../../../../../common/icons/icons';

export default function IncidentForm(props: {
  eventType: any;
  significance: any;
  eventTypeDetails: any;
  loadStatus: any;
  injuryType: any;
  rootCause: any;
  incidentActions: any;
  casualityZone: any;
  status: any;
  eventId: any;
  source: any;
  isMarsUser: any;
  isClosedMars: any;
  stateOfIncident: any;
  stateOfIncidentOptVal: any;
  disableEdit: any;
  isMarsOrExternal: any;
  marsStatus?: any;
  statusOptionVal?: any;
}) {
  const {
    eventType,
    significance,
    eventTypeDetails,
    loadStatus,
    injuryType,
    rootCause,
    incidentActions,
    casualityZone,
    status,
    eventId,
    source,
    isMarsUser,
    isClosedMars,
    stateOfIncident,
    stateOfIncidentOptVal,
    disableEdit,
    isMarsOrExternal,
    marsStatus,
    statusOptionVal,
  } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();
  // const [state, setState] = useState('');
  const [marsStatusVal, setMarsStatusVal] = useState('');
  const [injury, setInjury] = useState(false);
  const companyType = getCompanyType();
  const yesOrNoDataSource = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ];
  const { findFeature } = usePermission();
  const onChangeInjury = (e: any) => {
    setInjury(e.value);
  };

  const statusItemRender = (data: any) => {
    return (
      <div
        className={
          data?.optionValueCode === IncidentStatus.Open
            ? 'm-l-status-item status-bg-red'
            : data?.optionValueCode === IncidentStatus.Closed
            ? 'm-l-status-item status-bg-green'
            : data?.optionValueCode === IncidentStatus.WrongEntry
            ? 'status-wrong-entry-wtout'
            : 'm-l-status-item status-bg-orange'
        }
      >
        {data?.optionValueText}
      </div>
    );
  };

  const hideAdditionalFields = useMemo(() => {
    if (companyType === 'R') {
      return !findFeature('Ship.ViewIncidentAdditionalDetails');
    } else {
      if (findFeature('Ship.EditIncident') || findFeature('Ship.AddIncident')) {
        return false;
      } else {
        return !findFeature('Ship.ViewIncidentAdditionalDetails');
      }
    }
  }, [companyType]);

  const statusFieldRender = (data: any) => {
    return (
      <TextBox
        className={
          data?.optionValueCode === IncidentStatus.Open
            ? 'inactive-lbl'
            : data?.optionValueCode === IncidentStatus.Closed
            ? 'active-lbl'
            : data?.optionValueCode === IncidentStatus.WrongEntry
            ? 'status-wrong-entry-wtout'
            : 'draft-lbl'
        }
        defaultValue={data?.optionValueText}
        readOnly={true}
      />
    );
  };

  const generalFormConfig = [
    {
      class:
        'col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'incidentDate',
      label: t('labels.dateofIncident'),
      control: control,
      fieldType: 'date',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
      disabled: disableEdit,
    },
    {
      class:
        'col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'placeOfIncident',
      label: t('labels.placeOfIncident'),
      control: control,
      fieldType: 'text',
      disabled: disableEdit,
      maxLength: 100,
    },
    {
      class:
        'col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'significance',
      label: t('labels.significance'),
      control: control,
      fieldType: 'select',
      dataSource: significance,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      disabled: disableEdit,
    },
  ];
  const generalFormConfig2 = [
    {
      class: 'col-sm-6 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
      fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
      name: 'totalLoss',
      label: t('labels.totalLoss'),
      control: control,
      fieldType: 'radio',
      items: yesOrNoDataSource,
      layout: 'horizontal',
      displayExpr: 'name',
      valueExpr: 'value',
      disabled: disableEdit,
    },
    {
      class: 'col-sm-6 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
      fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
      name: 'pollution',
      label: t('labels.pollution'),
      control: control,
      fieldType: 'radio',
      items: yesOrNoDataSource,
      layout: 'horizontal',
      displayExpr: 'name',
      valueExpr: 'value',
      disabled: disableEdit,
    },
  ];
  const generalFormConfig3 = [
    {
      class:
        'col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'eventType',
      label: t('labels.eventType'),
      control: control,
      fieldType: 'select',
      dataSource: eventType,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
      disabled: disableEdit,
    },
    {
      class:
        'col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'eventTypeDetail',
      label: t('labels.eventTypeDetails'),
      control: control,
      fieldType: 'select',
      dataSource: eventTypeDetails,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      disabled: disableEdit,
    },
    {
      class:
        'col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'additionalEvent',
      label: t('labels.additionalEvent'),
      control: control,
      fieldType: 'select',
      dataSource: eventType,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      disabled: disableEdit,
    },
  ];
  const generalFormConfig4 = [
    {
      class: 'col-sm-6 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
      fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
      name: 'fire',
      label: t('labels.fire'),
      control: control,
      fieldType: 'radio',
      items: yesOrNoDataSource,
      layout: 'horizontal',
      displayExpr: 'name',
      valueExpr: 'value',
      disabled: disableEdit,
    },
    {
      class: 'col-sm-6 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
      fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
      name: 'significantDamage',
      label: t('labels.significantDamage'),
      control: control,
      fieldType: 'radio',
      items: yesOrNoDataSource,
      layout: 'horizontal',
      displayExpr: 'name',
      valueExpr: 'value',
      disabled: disableEdit,
    },
  ];
  const generalFormConfig5 = [
    {
      class:
        'col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'casualityZone',
      label: t('labels.causalityZone'),
      control: control,
      fieldType: 'select',
      dataSource: casualityZone,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      disabled: disableEdit,
      maxLength: 100,
    },
    {
      class:
        'col-xl-3 col-lg-4  col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'locationName',
      label: t('labels.locationName'),
      control: control,
      fieldType: 'text',
      disabled: disableEdit,
      maxLength: 100,
    },
    {
      class:
        'col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'marsdenGrid',
      label: t('labels.marsdenGrid'),
      control: control,
      fieldType: 'text',
      disabled: disableEdit,
      maxLength: 20,
    },
  ];
  const generalFormConfig6 = [
    {
      class: 'col-sm-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
      fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
      name: 'injury',
      label: t('labels.injury'),
      control: control,
      fieldType: 'radio',
      items: yesOrNoDataSource,
      layout: 'horizontal',
      displayExpr: 'name',
      valueExpr: 'value',
      disabled: disableEdit,
      onChange: onChangeInjury,
    },
  ];
  const generalFormConfig7 = [
    {
      class: hideAdditionalFields
        ? 'col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col'
        : 'col-sm-6  m-l-custom-sixteen-space-col',
      name: 'loadStatus',
      label: t('labels.loadStatus'),
      control: control,
      fieldType: 'select',
      dataSource: loadStatus,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      disabled: disableEdit,
    },
    {
      class: hideAdditionalFields
        ? 'col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col'
        : 'col-sm-6  m-l-custom-sixteen-space-col',
      name: 'environment',
      label: t('labels.environment'),
      control: control,
      fieldType: 'text',
      disabled: disableEdit,
      maxLength: 100,
    },
  ];
  const generalFormConfig8 = [
    {
      class: 'col-xl-3 col-lg-4 col-sm-12 col-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-24',
      fieldClass:
        'm-c-text-area m-c-text-area-custom m-c-textrea-incindent-comments',
      name: 'comments',
      label: t('labels.comments'),
      control: control,
      fieldType: 'textArea',
      disabled:
        companyType === 'R'
          ? true
          : eventId
          ? isMarsUser && !isMarsOrExternal
            ? true
            : false
          : false,
      isHidden: hideAdditionalFields,
    },
    {
      class:
        'col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'injuryType',
      label: t('labels.injuryType'),
      control: control,
      fieldType: 'select',
      dataSource: injuryType,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      disabled: disableEdit,
      isHidden: hideAdditionalFields ? hideAdditionalFields : !injury,
    },
  ];

  const generalFormConfig11 = [
    {
      class:
        'col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'injuryType',
      label: t('labels.injuryType'),
      control: control,
      fieldType: 'select',
      dataSource: injuryType,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      disabled: true,
      isHidden: !injury ? !injury : !hideAdditionalFields,
    },
  ];
  const generalFormConfig9 = [
    {
      class: hideAdditionalFields
        ? 'col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col'
        : 'col-sm-6  m-l-custom-sixteen-space-col',
      name: 'headline',
      label: t('labels.headline'),
      control: control,
      fieldType: 'text',
      disabled: disableEdit,
    },
    {
      class: hideAdditionalFields
        ? 'col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col'
        : 'col-sm-6  m-l-custom-sixteen-space-col',
      name: 'description',
      label: t('labels.description'),
      control: control,
      fieldType: 'text',
      disabled: disableEdit,
    },
  ];

  const generalFormConfig10 = [
    {
      class:
        'col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'rootCause',
      label: t('labels.rootCause'),
      control: control,
      fieldType: 'select',
      dataSource: rootCause,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      disabled: disableEdit,
      dropDownOptions: {
        resizeEnabled: true,
        width: '450px',
      },
      isHidden: hideAdditionalFields,
    },
    {
      class:
        'col-xl-3 col-lg-4  col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'incidentAction',
      label: t('labels.actions'),
      control: control,
      fieldType: 'select',
      dataSource: incidentActions,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      disabled: disableEdit,
      dropDownOptions: {
        resizeEnabled: true,
        width: '450px',
      },
    },
    {
      class:
        'col-xl-3 col-lg-4  col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'status',
      label: t('labels.status'),
      control: control,
      fieldType: 'select',
      dataSource: status,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      disabled:
        companyType === 'R'
          ? true
          : eventId
          ? isMarsUser && !isMarsOrExternal
            ? true
            : false
          : false,
      fieldRender: statusFieldRender,
      itemRender: statusItemRender,
      isHidden: hideAdditionalFields,
    },
  ];

  const getStateOfIncident = () => {
    if (stateOfIncident) {
      const item = stateOfIncidentOptVal?.find(
        (item: { optionValueCode: any }) =>
          item.optionValueCode == stateOfIncident
      );
      return item?.optionValueText;
    } else {
      return '';
    }
  };

  const getMarsStatus = () => {
    if (marsStatus) {
      const item = statusOptionVal?.find(
        (item: { optionValueCode: any }) => item.optionValueCode == marsStatus
      );
      setMarsStatusVal(item?.optionValueText);
    } else {
      setMarsStatusVal('');
    }
  };

  // useEffect(() => {
  //   getStateOfIncident();
  // }, [stateOfIncident, stateOfIncidentOptVal]);

  useEffect(() => {
    getMarsStatus();
  }, [marsStatus, statusOptionVal]);

  const marsStatusRender = (data: string) => {
    return (
      <div
        className={
          marsStatus === MarsStatus.Open
            ? 'm-l-status-item status-bg-red'
            : marsStatus === MarsStatus.Closed
            ? 'm-l-status-item status-bg-green'
            : marsStatus === MarsStatus.WrongEntry
            ? 'status-wrong-entry-wtout'
            : 'm-l-status-item status-bg-orange'
        }
      >
        {data}
      </div>
    );
  };

  return (
    <>
      <div className="row m-l-custom-sixteen-space-row m-l-incident__block">
        <FormBuilder formConfig={generalFormConfig} />
        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
          <div className="row m-l-custom-sixteen-space-row">
            <FormBuilder formConfig={generalFormConfig2} />
          </div>
        </div>
        <FormBuilder formConfig={generalFormConfig3} />
        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
          <div className="row m-l-custom-sixteen-space-row">
            <FormBuilder formConfig={generalFormConfig4} />
          </div>
        </div>
        <FormBuilder formConfig={generalFormConfig5} />
        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
          <div className="row m-l-custom-sixteen-space-row">
            <FormBuilder formConfig={generalFormConfig6} />
          </div>
        </div>
        <div
          className={
            hideAdditionalFields
              ? 'm-l-custom-sixteen-space-col'
              : 'col-xl-6 col-lg-8 m-l-custom-sixteen-space-col'
          }
        >
          <div className="row m-l-custom-sixteen-space-row">
            <FormBuilder formConfig={generalFormConfig7} />
            <FormBuilder formConfig={generalFormConfig9} />
          </div>
        </div>
        <FormBuilder formConfig={generalFormConfig8} />
      </div>
      <div className="row m-l-custom-sixteen-space-row">
        <FormBuilder formConfig={generalFormConfig11} />
        <FormBuilder formConfig={generalFormConfig10} />
        <div className="col-xl-3 col-lg-8 col-sm-12 col-xs-12 m-l-custom-sixteen-space-col">
          <div className="row m-l-custom-sixteen-space-row">
            {eventId && (
              <>
                {isMarsUser && (
                  <>
                    <div className="col-sm-6 m-l-custom-sixteen-space-col">
                      <div className="m-c-form-group mb-btm-space-24 tp-space-source">
                        <label className="m-l-input-label">
                          {t('labels.source')}
                        </label>
                        <div className="m-l-data-label">{source}</div>
                      </div>
                    </div>
                    {stateOfIncident && (
                      <div className="col-sm-6  m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-24 tp-space-source">
                          <label className="m-l-input-label">
                            {t('labels.incidentStatus')}
                          </label>
                          <div className="m-l-data-label">
                            {getStateOfIncident()}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {!isMarsUser && !hideAdditionalFields && (
                  <div className="col-sm-6 m-l-custom-sixteen-space-col">
                    <div className="m-c-form-group mb-btm-space-24 tp-space-source">
                      <label className="m-l-input-label">
                        {t('labels.MARSStatus')}
                      </label>
                      <div className="m-l-data-label">
                        {marsStatusRender(marsStatusVal)}
                      </div>
                    </div>
                  </div>

                  // <div className="col-sm-6  m-l-custom-sixteen-space-col">
                  //   <div className="m-l-label-data-wrapper">
                  //     <div className="m-c-form-group mb-btm-space-24">
                  //       <label className="m-l-input-label">
                  //         {t('labels.MARSStatus')}
                  //       </label>
                  //       <span className="m-l-data-label ml-16">
                  //         {marsStatusRender(marsStatusVal)}
                  //       </span>
                  //     </div>
                  //   </div>
                  // </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
