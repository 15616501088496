import Button from 'devextreme-react/button';
import React, { useCallback, useEffect, useState } from 'react';
import icons from '../../../components/common/icons/icons';
import './DashboardUser.scss';
import EmailItem from '../../../components/dashboard-user/Email/EmailItem';
import ConfirmDialog from '../../../components/common/confirm/ConfirmDialog';
import SwitchBU from '../../../components/switch-bu/SwitchBu';
import { IDialogConfig } from '../../../types/dialog';
import { useTranslation } from 'react-i18next';
import VetByStatusGadget from '../../../components/dashboard-user/vets-by-status-gadget/VetByStatusGadget';
import VetsByCompanyGadget from '../../../components/dashboard-user/vets-by-company-gadget/VetsByCompanyGadget';
import TopReviewersGadget from '../../../components/dashboard-user/top-reviewers-gadget/TopReviewersGadget';
import VetsByRequestorGadget from '../../../components/dashboard-user/vets-by-requestor-gadget/VetsByRequestorGadget';
import VetStatusAvgTimeGadget from '../../../components/dashboard-user/vet-status-avg-time-gadget/VetStatusAvgTimeGadget';
import AlertGadget from '../../../components/dashboard-user/alert-gadget/Alert-Gadget';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import {
  getAdditionalInfo,
  getBuList,
  getCompanyId,
  getCompanyType,
  getLoginBUName,
  getRememberMe,
} from '../../../utils/jwt-decode';
import { ICompany } from '../../../types/requestor-company';
import BerthFitUtilityGadget from '../../../components/dashboard-user/berth-fit-tool-gadget/BerthFitUtilityGadget';
import MyRecentVetRequestGadget from '../../../components/dashboard-user/recent-vet-requests-gadget/MyRecentVetRequestGadget';
import RecentVesselsGadget from '../../../components/dashboard-user/recent-vessels-gadget/RecentVesselsGadget';
import VetTurnAroundTimeTrendGadget from '../../../components/dashboard-user/vet-turnaround-time-trend/VetTurnAroundTimeTrendGadget';
import TurnaroundByCompanyGadget from '../../../components/dashboard-user/turnaround-company-gadget/TurnaroundByCompanyGadget';
import RfiMailGadget from '../../../components/dashboard-user/rfi-mail-gadget/RfiMailGadget';
import DashBoardListPopover from './dashboard-list-popover/DashboardListPopover';
import { GadgetsEnum } from '../../../enums/dashboard-gadgets';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { Restricted } from '../../../contexts/PermissionContext';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
export default function DashBoardUser() {
  const storage: Storage =
    getRememberMe() === 'true' ? localStorage : sessionStorage;
  const companyId = getCompanyId();
  const additionalInfoToken = getAdditionalInfo();
  const [showPopover, setShowPopover] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [currentlyLoggedInBu, setCurrentlyLoggedInBu] = useState<any>([]);
  const [unreadActiveNotificationList, setUnreadActiveNotficationList] =
    useState<any>([]);
  const { t } = useTranslation();
  const companyType = getCompanyType();
  const [company, setCompany] = useState<ICompany>();
  const [dashboardList, setDashboardList] = useState<any>([]);
  const { setIsLoading } = useLoaderContext();
  const [dashboardListFilter, setDashboardListFilter] = useState<any>();
  useEffect(() => {
    setCurrentlyLoggedInBu(getLoginBUName());
    const bu: any = getBuList();
    const buLength = JSON.parse(bu).length;
    buLength > 1 ? setIsConfirmModalOpen(true) : setIsConfirmModalOpen(false);
    const action = storage.getItem('buConfirmation');
    action == 'false'
      ? setIsConfirmModalOpen(false)
      : buLength > 1
      ? setIsConfirmModalOpen(true)
      : setIsConfirmModalOpen(false);
  }, []);
  const [refreshConfig, setRefreshConfig] = useState<any>([]);

  const getRefreshConfig = async () => {
    await api
      .get({
        url: apiConfig.configurations,
        params: {
          settingArea: 'Alert',
        },
      })
      .then((response: any) => {
        setRefreshConfig(response);
      });
  };

  const handleCancelClick = () => {
    setIsVisible(false);
  };
  const switchBU = () => {
    setIsVisible(true);
  };

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.continueWithDefaultBu', {
      currentlyLoggedInBu: currentlyLoggedInBu,
    }),
    cancelButtonLabel: 'Switch',
    handleSubmit: () => {
      storage.setItem('buConfirmation', 'false');
      setIsConfirmModalOpen(false);
    },
    handleClose: (e: any) => {
      storage.setItem('buConfirmation', 'false');
      if (e.event.target.innerText === 'Switch') {
        switchBU();
      }
      setIsConfirmModalOpen(false);
    },
  };

  const getNotificationList = async () => {
    await api.get({ url: apiConfig.notifications }).then((data) => {
      const unreadActiveList = JSON.parse(JSON.stringify(data));
      unreadActiveList?.notificationGroups?.filter((item: any) => {
        item.notifications = item.notifications?.filter(
          (notifications: any) =>
            notifications.notificationStatus === 'N' &&
            notifications.isArchived === false
        );
      });
      const filteredNotification: any = [];
      unreadActiveList?.notificationGroups?.forEach((item: any) => {
        if (item.notifications?.length > 0) {
          filteredNotification.push(item);
        }
      });
      setUnreadActiveNotficationList(filteredNotification);
    });
  };

  const getCompanyDetails = () => {
    api.get({ url: apiConfig.company + companyId }).then((res: ICompany) => {
      setCompany(res);
      storage.setItem('companyName', res.companyName);
    });
  };

  const getDashboardList = () => {
    api
      .get({
        url: apiConfig.dashboardList,
        params: { additionalInfoToken: additionalInfoToken },
      })
      .then((res) => {
        const numAscending = [...res].sort(
          (a, b) => a.displayOrder - b.displayOrder
        );
        setDashboardList(numAscending);
      });
  };

  useEffect(() => {
    if (companyId) {
      getCompanyDetails();
    }
  }, [companyId]);

  useEffect(() => {
    getRefreshConfig();
    getNotificationList();
    getDashboardList();
  }, []);

  useEffect(() => {
    if (
      refreshConfig &&
      refreshConfig.length > 0 &&
      refreshConfig[0]?.settingValue != '0'
    ) {
      const interval = setInterval(() => {
        getNotificationList();
      }, parseInt(refreshConfig[0]?.settingValue) * 60000);

      return () => clearInterval(interval);
    }
  }, [refreshConfig]);

  const handlePopoverClick = () => {
    setShowPopover(true);
  };

  const onHidingPopover = () => {
    setShowPopover(false);
  };

  const onClickCloseIcon = async (e: any, id: any) => {
    await api
      .put(
        {
          url: apiConfig.gadgetDeleteRestore,
          params: {
            featureId: id,
            additionalInfoToken: additionalInfoToken,
            isRestore: false,
          },
        },
        setIsLoading
      )
      .then(() => {
        getDashboardList();
      });
  };
  const onSelectDashboard = async (e: any, role: any) => {
    await api
      .put(
        {
          url: apiConfig.gadgetDeleteRestore,
          params: {
            featureId: role?.featureId,
            additionalInfoToken: additionalInfoToken,
            isRestore: e.value,
          },
        },
        setIsLoading
      )
      .then(() => {
        getDashboardList();
      });
  };
  useEffect(() => {
    let filteredList = dashboardList?.filter(
      (item: any) => item.isDeleted === false
    );
    filteredList = filteredList?.filter(
      (item: any) =>
        item.featureName != GadgetsEnum.Alerts &&
        item.featureName != GadgetsEnum.Emails
    );
    const numAscending = [...filteredList].sort(
      (a, b) => a.displayOrder - b.displayOrder
    );
    setDashboardListFilter(numAscending);
  }, [dashboardList]);

  const insertValues = useCallback(
    (featureName: string) => {
      const filteredList = dashboardList?.filter(
        (item: any) => item.featureName === featureName
      );
      return filteredList ? !filteredList[0]?.isDeleted : false;
    },
    [dashboardList]
  );

  const getChangedPos = async (currentPos: any, newPos: any) => {
    const list: any = [];
    list.push(
      dashboardListFilter[currentPos]?.featureId,
      dashboardListFilter[newPos]?.featureId
    );
    await api
      .put(
        {
          url: apiConfig.changeDashboardDisplayOrder,
          data: list,
          params: {
            additionalInfoToken: additionalInfoToken,
          },
        },
        setIsLoading
      )
      .then(() => {
        // getDashboardList();
      });
  };

  const onSortEnd = (e: any) => {
    document.body.style.cursor = 'default';
    if (e.newIndex !== e.oldIndex) {
      const tempArray = JSON.parse(JSON.stringify(dashboardListFilter));
      const tempVar = tempArray[e.oldIndex];
      tempArray[e.oldIndex] = tempArray[e.newIndex];
      tempArray[e.newIndex] = tempVar;
      setDashboardListFilter(tempArray);
      getChangedPos(e.newIndex, e.oldIndex);
    }
  };

  return (
    <div className="inner-wrapper m-l-usrblck">
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={isConfirmModalOpen}
      />
      {isVisible && (
        <SwitchBU isVisible={isVisible} handleCancelClick={handleCancelClick} />
      )}

      <div className="m-l-user-dashboard">
        <div className="m-l-user-dashboard-left-pane">
          <div className="m-l-user-dashboard-header-part">
            <div className="left-block">
              <div className="m-l-page-main-heading">
                {company?.companyName}
              </div>
            </div>
            {/* <div className="m-l-page-tm-dt-desc">
              10:27:38 AM, Feb 10, 2021, GMT +00:00s
            </div> */}
            <div className="right-block">
              <div className="m-l-expand-btn-wrap ">
                <Button
                  className="app-c-btn app-c-icon-only-btn"
                  onClick={handlePopoverClick}
                  id="dashBoard"
                >
                  <icons.IconSettings />
                </Button>
                {showPopover && (
                  <DashBoardListPopover
                    dashboardList={dashboardList}
                    showPopover={showPopover}
                    target="#dashBoard"
                    closeOnOutsideClick={true}
                    onHidingPopover={onHidingPopover}
                    onSelectDashboard={onSelectDashboard}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="m-l-user-dashboard-body-scn">
            {/* <ul className="m-l-usdata-box-wrapper"> */}
            {/* <Draggable> */}
            <DraggableRender
              dashboardListFilter={dashboardListFilter}
              onSortEnd={onSortEnd}
              onClickCloseIcon={onClickCloseIcon}
            />
            {/* </Draggable> */}
            {/* </ul> */}
          </div>
        </div>
        {dashboardList?.length > 0 && (
          <div className="m-l-user-dashboard-right-pane">
            {/* <ScrollView direction="vertical">   */}
            {insertValues('Dashboard.Alerts') && (
              <Restricted permission="Dashboard.Alerts">
                <AlertGadget
                  unreadActiveNotificationList={unreadActiveNotificationList}
                />
              </Restricted>
            )}
            {insertValues('Dashboard.Emails') &&
              (companyType === 'M' || companyType === 'H') && (
                <Restricted permission="Dashboard.Emails">
                  <EmailItem />
                </Restricted>
              )}
            {/* </ScrollView> */}
          </div>
        )}
      </div>
    </div>
  );
}

const SortableContainerElem = SortableContainer((props: any) => {
  return <ul className="m-l-usdata-box-wrapper">{props?.children}</ul>;
});

const DraggableRender = (props: {
  dashboardListFilter: any[];
  onSortEnd: any;
  onClickCloseIcon: any;
}) => {
  const { dashboardListFilter, onSortEnd, onClickCloseIcon } = props;
  if (dashboardListFilter && dashboardListFilter.length) {
    return (
      <SortableContainerElem
        axis="xy"
        onSortEnd={onSortEnd}
        // pressDelay={200}
        distance={20}
        transitionDuration={0}
        onSortStart={() => (document.body.style.cursor = 'grabbing')}
      >
        {dashboardListFilter?.map((value: any, index: any) => {
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              value={value}
              axis="xy"
              onClickCloseIcon={onClickCloseIcon}
            />
          );
        })}
      </SortableContainerElem>
    );
  }
  return null;
};

const SortableItem = SortableElement((props: any) => {
  const { t } = useTranslation();
  return (
    <div className="grabbable">
      {props?.value.featureName === 'Dashboard.VetsByStatus' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body m-l-fxdhd-box">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">{t('labels.VetsbyStatus')}</div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <div className="m-l-us-databox-body">
              <div className="m-l-das-graph-block">
                <VetByStatusGadget />
              </div>
            </div>
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.VetsByCompany' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body m-l-fxdhd-box">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.VetsbyCompany')}
              </div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <div className="m-l-us-databox-body">
              <div className="m-l-das-graph-block">
                <VetsByCompanyGadget />
              </div>
            </div>
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.TurnaroundByCompany' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body m-l-fxdhd-box">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.TurnaroundbyCompany')}
              </div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <div className="m-l-us-databox-body">
              <div className="m-l-das-graph-block">
                <TurnaroundByCompanyGadget />
              </div>
            </div>
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.RFI' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body m-l-fxdhd-box">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">{t('labels.RFI')}</div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            {/* <div className="m-l-us-databox-body">
          <div className="m-l-das-graph-block"> */}
            <RfiMailGadget />
            {/* </div>
        </div> */}
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.VetsByRequestor' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body m-l-fxdhd-box">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.VetsbyRequestor')}
              </div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <div className="m-l-us-databox-body">
              <div className="m-l-das-graph-block">
                <VetsByRequestorGadget />
              </div>
            </div>
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.VetStatusAvgTime' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body m-l-fxdhd-box">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.vetStatusAvgTime')}
              </div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <div className="m-l-us-databox-body">
              <div className="m-l-das-graph-block">
                <VetStatusAvgTimeGadget />
              </div>
            </div>
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.TopReviewers' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body m-l-fxdhd-box">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">{t('labels.TopReviewers')}</div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <div className="m-l-us-databox-body">
              <div className="m-l-das-graph-block">
                <TopReviewersGadget />
              </div>
            </div>
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.VetTurnaroundTimeTrend' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.VetTurnaroundTimeTrend')}
              </div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <VetTurnAroundTimeTrendGadget />
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.BerthFitToolUtilization' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.BerthFitToolUtilization')}
              </div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <BerthFitUtilityGadget />
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.MyRecentVetRequests' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.MyRecentVetRequests')}
              </div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <MyRecentVetRequestGadget />
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.MyRecentVessels' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.MyRecentVessels')}
              </div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <RecentVesselsGadget />
          </div>
        </div>
      )}
    </div>
  );
});
