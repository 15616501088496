import React from 'react';
import { useTranslation } from 'react-i18next';
import icons from '../../components/common/icons/icons';
export default function NotAuthorized() {
  const { t } = useTranslation();
  return (
    <div className="inner-wrapper">
      <div className="m-l-msg-display-wrapper">
        <div className="m-l-main-msg-container">
          <div className="m-l-main-msg-holder">
            <div className="m-l-icon-holder">
              {' '}
              <icons.ErrorIcon />
            </div>
          </div>
          <div className="m-l-sub-msg-holder">
            <div className="m-l-sub-highlight-ttl">
              {t('headers.notAuthorized')}{' '}
            </div>
            <div className="m-l-sub-msg-ttl">{t('labels.youHaveNoAccess')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
