import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { IFormConfig } from '../../../types/form-builder';
import { useTranslation } from 'react-i18next';
import FormBuilder from '../../common/form-builder/FormBuilder';
import icons from '../../common/icons/icons';
import { Restricted } from '../../../contexts/PermissionContext';
import { useNavigate } from 'react-router-dom';
import { getAPIRoute } from '../../../utils/api-route';
import { MarsApiService as api } from '../../../api/mars-api-service';
import SelectBox from 'devextreme-react/select-box';
export default function PortForm(props: {
  portDataSource: any;
  terminals: any;
  selectedTerminals: any;
  setSelectedTerminals: any;
  isBerthFit: any;
  berthfitIdProps: any;
  attachmentsAdded: any[];
  imoNumber?: string;
  onAddClick: any;
  berthFitId: any;
  setReload: any;
  isMastersLoaded: boolean;
  setPortCode: any;
  berthDetails: any;
}) {
  const { t } = useTranslation();
  const { control, getValues, setValue } = useFormContext();
  const {
    portDataSource,
    terminals,
    selectedTerminals,
    setSelectedTerminals,
    isBerthFit,
    berthfitIdProps,
    attachmentsAdded,
    imoNumber,
    onAddClick,
    berthFitId,
    setReload,
    isMastersLoaded,
    setPortCode,
    berthDetails,
  } = props;
  const terminalRef = useRef<any>();
  const portRef = useRef<SelectBox>();

  const navigate = useNavigate();

  const onValueChange = (e: any) => {
    if (selectedTerminals?.length > 0) {
      let valueChanged = false;
      const terminal: any = [...selectedTerminals];
      terminal.map((item: any) => {
        if (!e.value.includes(item)) {
          valueChanged = true;
        }
      });
      if (valueChanged) {
        e.component.option('value', selectedTerminals);
        // toast.error({ title: t('errors.cannotDeleteTerminal') });
      }
    }
    const maxItems = 5;
    if (e.event && e.value.length > maxItems) {
      const newValue = e.value.slice(0, maxItems);
      e.component.option('value', newValue);
    }
  };

  const getPortWarning = async (portId: any) => {
    if (portId) {
      await api
        .get({
          url: getAPIRoute('portWarning', [portId]),
        })
        .then((response: any) => {
          setReload(false);
          const isApproved = response.isApproved ? 'A' : 'NA';
          response.isApproved = isApproved;
          setValue(`portWarning`, response);
          setReload(true);
        });
    }
  };

  const onChangePort = async (e: any) => {
    if (e.event) {
      await getPortWarning(e.value);
    }
    const selectedItem = portRef.current?.instance.option('selectedItem');
    selectedItem ? setPortCode(selectedItem.portCode) : setPortCode(undefined);
    if (e.value && e.previousValue != null) {
      setSelectedTerminals([]);
      setValue(`portTerminals`, '');
      terminalRef.current?.instance
        .getDataSource()
        .filter(['port.portId', '=', e.value]);
      terminalRef.current?.instance.getDataSource()?.load();
    }
  };

  useEffect(() => {
    terminalRef?.current?.instance
      .getDataSource()
      ?.filter(['port.portId', '=', getValues().portId]);
  }, [portDataSource]);

  const randomNumber = useCallback(() => {
    return Math.random();
  }, []);

  const searchExpression = ['port', 'countryName'];

  const generalFormConfig: IFormConfig[] = useMemo(() => {
    if (isMastersLoaded) {
      return [
        {
          ref: portRef,
          class: 'col-xl-2 col-lg-3 col-md-12 col-sm-12 col-12',
          name: 'portId',
          label: t('labels.port'),
          control: control,
          fieldType: 'select',
          displayExpr: 'portName',
          valueExpr: 'portId',
          searchExpr: searchExpression,
          disabled: !!berthFitId,
          dataSource: portDataSource,
          placeholder: t('labels.select'),
          onChange: onChangePort,
          isRequired: true,
          rules: [
            {
              type: 'required',
              message: t('errors.required'),
            },
          ],
        },
        {
          name: 'portTerminals',
          label: t('labels.terminals'),
          control: control,
          fieldType: 'multiSelect',
          displayExpr: 'terminalName',
          valueExpr: 'terminalId',
          dataSource: terminals,
          showClearButton: true,
          maxDisplayedTags: 2,
          disabled: !!berthFitId,
          showMultiTagOnly: false,
          onChange: onValueChange,
          placeholder: t('labels.select'),
          class: 'col-xl-6 col-lg-6  col-sm-12',
          ref: terminalRef,
          isRequired: true,
          rules: [
            {
              type: 'required',
              message: t('errors.required'),
            },
          ],
        },
      ];
    } else {
      return [];
    }
  }, [isBerthFit, isMastersLoaded]);
  return (
    <div className="row">
      <FormBuilder formConfig={generalFormConfig} />

      {berthfitIdProps && (
        <Restricted permission="BerthFitVessel.Attachment">
          <div className="col-xl col-lg col-md-12 col-sm-12 col-12">
            <div
              className="m-l-upld-fl-wrapper m-l-upld__birth-fit u-mt-xl"
              style={{ justifyContent: 'flex-end' }}
            >
              <div
                className="m-l-atch-icon-holder"
                onClick={onAddClick}
                style={{ cursor: 'pointer' }}
              >
                <icons.Attach />
              </div>
              {attachmentsAdded?.length > 0 && (
                <div className="m-l-upld-fl-holder">
                  <div className="m-l-fl-wrapper">
                    <span
                      className="m-l-fl-close"
                      onClick={() =>
                        navigate('/vessel-info/berth-fit-check', {
                          state: {
                            imoNumber: imoNumber,
                            attachmentId: randomNumber(),
                            berthFitID: berthFitId,
                            berthDetails: berthDetails,
                          },
                        })
                      }
                    >
                      {attachmentsAdded?.length}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Restricted>
      )}
    </div>
  );
}
