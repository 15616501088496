import React, { useEffect, useRef, useState } from 'react';
import BerthGrid from '../berth-grid/BerthGrid';
import { useTranslation } from 'react-i18next';
import BerthDetailsForm from '../berth-details-form/BerthDetailsForm';
import ValidationGroup from 'devextreme-react/validation-group';
import { FormProvider, useForm } from 'react-hook-form';
import { IBerthParameter } from '../../../types/berthfit-execution';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import {
  IVetBerthFitOverride,
  IVetBerthFitOverrideEdit,
} from '../../../types/vetrequest-berthfit-override';
import toast from '../../../utils/toast';
import { useLoaderContext } from '../../../contexts/LoaderContext';
export default function TerminalBerthDetails(props: {
  data: any;
  imoNumber: any;
  buId: any;
  berthFitId: any;
  getTerminalList?: any;
}) {
  const { data, imoNumber, buId, berthFitId, getTerminalList } = props;
  const { t } = useTranslation();
  const validationGroup: any = useRef();
  const [status, setStatus] = useState('');
  const { setIsLoading } = useLoaderContext();
  const defaultValues = {
    comments: null,
    status: null,
  };

  const berthForm = useForm({
    defaultValues: defaultValues,
  });

  const { getValues } = berthForm;

  const handleSaveClick = (e: any) => {
    const requiredValidation = validationGroup.current.instance.validate();
    let listBerthFormValue: IVetBerthFitOverride = getValues();
    if (requiredValidation.isValid) {
      listBerthFormValue = berthForm.getValues();
      const roleJsonEdit: IVetBerthFitOverrideEdit = {
        comments: listBerthFormValue?.comments,
        status: listBerthFormValue.status,
        berthFitId: berthFitId,
        terminalId: data?.terminalId,
        berthId: data?.berthId,
        isStatusChanged: data.isOverrided
          ? data.isOverrided
          : data.status === listBerthFormValue.status
          ? false
          : true,
      };
      api
        .put(
          { url: apiConfig.berthOverrideResult, data: roleJsonEdit },
          setIsLoading
        )
        .then((response) => {
          if (response) {
            toast.custom({
              title: t('toast.onSaveBerthfitComments'),
            });
            getTerminalList();
          }
        });
    }
  };

  useEffect(() => {
    setStatus(data.status);
  }, [data.status]);

  useEffect(() => {
    berthForm.reset();
    if (data) {
      berthForm.reset(
        {
          comments: data.comment,
          status: data.status,
        },
        { keepDirty: true }
      );
    }
  }, [data]);

  const [berthParameters, setBerthParameters] = useState<IBerthParameter[]>([]);

  const getBerthDetails = async () => {
    await api
      .get({
        url: apiConfig.vetRequestBerthParameters + data.berthId,
        params: {
          imo: imoNumber,
          buId: buId,
          berthFitId: berthFitId,
          terminalId: data?.terminalId,
        },
      })
      .then((res: IBerthParameter[]) => {
        setBerthParameters(res);
      });
  };

  useEffect(() => {
    getBerthDetails();
  }, []);

  return (
    // <div className="accordion-item__body-rl-block incident-wrap">
    //   <div className="m-l-form-panel-block">
    //     <div className="m-l-form-panel-header-block m-l-berthfit-panel-header-block">
    //       <div className="m-l-form-panel-header-left-block app-l-port-heading">
    //         <div className="m-l-page-small-heading">{data?.berthName}</div>
    //         <Restricted permission="BerthFit.ViewBerthInfo">
    //           <a className="m-l-page-link" onClick={openTerminalModal}>
    //             {t('labels.viewInfo')}
    //           </a>
    //         </Restricted>
    //         <p className="m-l-port-info">
    //           {/* Approved port & valid Till :{' '} */}
    //           <span>{data?.berthApprovalInfo}</span>
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="row m-l-berth-info-wrapper mrbtm-space">
    //     <div className="col-xl-8 col-lg-8 co-md-8 col-sm-8 col-12 m-l-berth-info-right-part">
    //       <div className="row">
    //         {/* <div className="col-xl-5 col-lg-5 co-md-5 col-sm-6 col-12">
    //           <div className="m-c-form-group mb-btm-space">
    //             <label className="m-l-input-label">
    //               {t('labels.comments')}
    //             </label>
    //             <TextBox className="m-c-input-control">

    //             </TextBox>
    //           </div>
    //         </div> */}
    //         {/* <div className="col-xl-5 col-lg-5 co-md-5 col-sm-6 col-12 m-l-custom-sixteen-space-col">
    //           <div className="m-c-form-group mb-btm-space">
    //             <label className="m-l-input-label">{t('labels.status')}</label>
    //             <SelectBox
    //               dataSource={statusData}
    //               displayExpr="optionValueText"
    //               valueExpr="optionValueCode"
    //               className="m-c-input-control m-c-select-box"
    //             ></SelectBox>
    //           </div>
    //         </div> */}
    //         <FormProvider {...berthForm}>
    //           <ValidationGroup ref={validationGroup}>
    //             <BerthDetailsForm
    //               handleSaveClick={handleSaveClick}
    //               berthParameters={berthParameters}
    //               dataProps={data}
    //               status={status}
    //             />
    //           </ValidationGroup>
    //         </FormProvider>
    //         {/* <div className="col-xl-2 col-lg-2 co-md-2 col-sm-6 col-12 m-l-berth-info-btn-block">
    //           <Button
    //             className="app-c-btn app-c-btn--primary min-btn-width"
    //             aria-label=""
    //             onClick={handleSaveClick}
    //           >
    //             {t('buttons.save')}
    //           </Button>
    //         </div> */}
    //       </div>
    //     </div>
    //   </div>
    //   <div className="row m-l-cap-rules-wrapper">
    //     <BerthGrid berthParameters={berthParameters} />
    //   </div>
    //   <BerthInfo
    //     isVisible={isVisibleM}
    //     handleCancelClick={() => setIsVisibleM(false)}
    //     berthId={data.berthId}
    //     buId={buId}
    //   />
    // </div>

    <div className="m-l-accordion-inner-page-wrap">
      <FormProvider {...berthForm}>
        <ValidationGroup ref={validationGroup}>
          <BerthDetailsForm
            handleSaveClick={handleSaveClick}
            berthParameters={berthParameters}
            dataProps={data}
            status={status}
          />
        </ValidationGroup>
      </FormProvider>
      <div className="m-l-accordion-inner-page-grid-row">
        <BerthGrid berthParameters={berthParameters} />
      </div>
    </div>
  );
}
