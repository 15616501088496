import Button from 'devextreme-react/button';
import React, { useEffect, useState } from 'react';
import icons from '../../../common/icons/icons';
import GeneralEdit from './VesselGeneralEdit';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import countryList from '../../../Country.json';
import { useTranslation } from 'react-i18next';
import { ICountry, IOptionvalue } from '../../../../types/master-data';
import { Restricted } from '../../../../contexts/PermissionContext';
import { useDateFormat } from '../../../../hooks/useDateFormat';

export default function VesselGeneral(props: {
  imoNumber?: string;
  vesselBannerDetails?: any;
  setIsTabsDirty?: any;
}) {
  const [isGeneralEdit, setIsGeneralEdit] = useState(false);
  const [generalList, setGeneralList] = useState<any>([]);
  const { imoNumber, vesselBannerDetails, setIsTabsDirty } = props;
  const { t } = useTranslation();
  const [vesselType, setVesselType] = useState<IOptionvalue[]>([]);
  const [vesselStatus, setVesselStatus] = useState<IOptionvalue[]>([]);
  const [pandiClub, setPandIClub] = useState<IOptionvalue[]>([]);
  const [sizeClass, setSizeClass] = useState<IOptionvalue[]>([]);
  const [hullType, setHullType] = useState<IOptionvalue[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [ports, setPorts] = useState<any>([]);
  const { formatDate } = useDateFormat();

  const showGeneralEdit = () => {
    setIsGeneralEdit(true);
  };

  const getVesselType = async () => {
    await api
      .get({
        url: apiConfig.optionVesselType,
      })
      .then((res: IOptionvalue[]) => {
        setVesselType(res);
      });
  };

  const getSizeClass = async () => {
    await api
      .get({
        url: apiConfig.sizeClass,
      })
      .then((res: IOptionvalue[]) => {
        setSizeClass(res);
      });
  };

  const getHullType = async () => {
    await api
      .get({
        url: apiConfig.hullType,
      })
      .then((res: IOptionvalue[]) => {
        setHullType(res);
      });
  };

  const getPorts = async () => {
    await api.get({ url: apiConfig.ports }).then((data: any) => {
      setPorts(data);
    });
  };

  const getPandIClub = async () => {
    await api
      .get({
        url: apiConfig.pandiClub,
      })
      .then((res: IOptionvalue[]) => {
        setPandIClub(res);
      });
  };

  const getVesselStatus = async () => {
    await api
      .get({
        url: apiConfig.vesselStatus,
      })
      .then((res: IOptionvalue[]) => {
        setVesselStatus(res);
      });
  };

  const filteredCountryList = countryList.find(
    (countryItem) => countryItem.iso_3 == generalList?.iso3
  );

  const getVesselDetail = async () => {
    await api
      .get({
        url: apiConfig.vesselInformationGenTab + imoNumber,
      })
      .then((response: any) => {
        setGeneralList(response);
      });
  };

  const getCountry = async () => {
    await api.get({ url: apiConfig.country }).then((data: ICountry[]) => {
      setCountries(data);
    });
  };

  useEffect(() => {
    getVesselType();
    getVesselStatus();
    getPandIClub();
    getCountry();
    getPorts();
    getHullType();
    getSizeClass();
  }, []);

  useEffect(() => {
    if (imoNumber) {
      getVesselDetail();
    }
  }, []);

  return (
    <span>
      <div className="m-l-inner-page-body">
        {/* <div className="m-l-expand-btn-wrap m-l-edit-view-mode">
                    <Button className="app-c-btn app-c-icon-only-btn">
                      <icons.IconPencil />
                    </Button>
                  </div> */}
        {!isGeneralEdit ? (
          <div className="m-l-form-panel-block">
            <div className="m-l-tbl-square-wrapper m-l-tbl-square-wrapper--p-b-8">
              <div className="m-l-ads-table-block">
                <div className="m-l-ads-table-right-part">
                  <div className="row m-l-ads-table-row m-l-ads-table--inner-row">
                    <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 m-l-custom-sixteen-space-col-wrap">
                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.vesselName')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {generalList.vesselName}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.imoNumber')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {generalList.imoDisplayName}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.mmsiNumber')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {generalList.mmsiNumber}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.callSignNo')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {generalList.callSign}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.vinNumber')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {generalList.vinNumber}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.flag')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            <span className="ml-ads-tbl-flag">
                              <img
                                src={filteredCountryList?.flag}
                                className="img-responsive"
                                alt="flag"
                              />
                            </span>
                            {''}
                            {generalList.flag}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.vesselType')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {generalList.vesselType}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.portOfRegistry')}{' '}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {generalList.portOfRegistry}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.serviceSpeed')}{' '}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {generalList.speedService}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.vesselStatus')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {[generalList.vesselStatus]}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.builtDate')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {formatDate(generalList.dateOfBuild)}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.hullType')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {generalList.hullType}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.hullMaterial')}{' '}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {generalList.hullMaterial}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.decksNumber')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {generalList.numberOfDecks}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.bulkheads')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {generalList.bulkheads}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.sizeClass')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {generalList.sizeClass}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.P&IClub')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {generalList.piClubName}
                          </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.H&M')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {generalList.hmProvider}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                      <div className="m-l-expand-btn-wrap m-l-edit-view-mode">
                        <Restricted permission="Ship.EditGeneralInfo">
                          <Button
                            className="app-c-btn app-c-icon-only-btn"
                            onClick={showGeneralEdit}
                          >
                            <icons.IconPencil />
                          </Button>
                        </Restricted>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <GeneralEdit
            vesselBannerDetails={vesselBannerDetails}
            imoNumber={imoNumber}
            generalList={generalList}
            setIsGeneralEdit={setIsGeneralEdit}
            vesselType={vesselType}
            vesselStatus={vesselStatus}
            pandiClub={pandiClub}
            getVesselDetail={getVesselDetail}
            countries={countries}
            ports={ports}
            sizeClass={sizeClass}
            hullType={hullType}
            setIsTabsDirty={setIsTabsDirty}
          />
        )}
      </div>
    </span>
  );
}
