import Tooltip from 'devextreme-react/tooltip';
import React, { useState } from 'react';
import { copyTextToClipboard } from '../../../utils/copy-to-clipboard';
import imageUrl from '../../../assets/images';

export default function CopyToClipboard(props: { text: string }) {
  const { text } = props;

  const [copied, setCopied] = useState(false);

  const [showCopyTooltip, setShowCopyTooltip] = useState(false);

  const copyText = () => {
    copyTextToClipboard(text);
    setCopied(true);
  };

  return (
    <div
      className="m-l-page-icon"
      id="copy"
      style={{ cursor: 'pointer' }}
      onClick={() => copyText()}
      onMouseEnter={() => setShowCopyTooltip(true)}
      onMouseLeave={() => {
        setShowCopyTooltip(false);
        setCopied(false);
      }}
    >
      <img
        src={imageUrl.copyIcon}
        className="img-responsive"
        alt="success icon"
        id="warning"
      />
      {/* <icons.vesselWarning /> */}
      <Tooltip
        target={'#copy'}
        visible={showCopyTooltip}
        position="right"
        contentRender={() => {
          return (
            <div className="m-l-tooltip-wrapper m-l-warning-icon-tooltip">
              {!copied && <span>Copy to clipboard</span>}
              {copied && <span>Copied to clipboard!</span>}
            </div>
          );
        }}
      />
    </div>
  );
}
