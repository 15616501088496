import React, { useContext, useEffect, useState } from 'react';
import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import { useTranslation } from 'react-i18next';
import { BerthCheckStatusContext } from '../berth-fit-tab/BerthFitTab';
import { IBerthResult } from '../../../../types/berthfit-execution';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { IOptionvalue } from '../../../../types/master-data';
import { BerthFitCheckStatus } from '../../../../enums/berthfit-status-enum';
import toast from '../../../../utils/toast';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import { Restricted } from '../../../../contexts/PermissionContext';

export default function BerthFitComments(props: {
  berthFitId: number;
  terminalId?: number;
  berthId?: number;
  berthTerminalComment: string;
  berthTerminalStatus: string;
  isOverrided: boolean;
  getPortList: any;
}) {
  const {
    berthFitId,
    terminalId,
    berthId,
    berthTerminalStatus,
    berthTerminalComment,
    isOverrided,
    getPortList,
  } = props;

  const { t } = useTranslation();

  const [status, setStatus] = useState('');

  const [comments, setComments] = useState('');

  const berthCheckStatus = useContext(BerthCheckStatusContext);
  const { setIsLoading } = useLoaderContext();
  useEffect(() => {
    setStatus(berthTerminalStatus);
  }, [berthTerminalStatus]);

  useEffect(() => {
    setComments(berthTerminalComment ?? '');
  }, [berthTerminalComment]);

  const getBerthCommentSaveJson = (): IBerthResult => {
    return {
      comments: comments,
      status: status,
      berthFitId: berthFitId,
      terminalId: terminalId,
      berthId: berthId,
      isStatusChanged: isOverrided
        ? isOverrided
        : status !== berthTerminalStatus,
    };
  };

  const onClickSave = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      api
        .put(
          {
            url: apiConfig.berthFitOverrideResult,
            data: getBerthCommentSaveJson(),
          },
          setIsLoading
        )
        .then(() => {
          getPortList();
          toast.success({ title: t('toast.berthCommentSuccess') });
        });
    }
  };

  const statusItemRender = (data: IOptionvalue) => {
    return (
      <div
        className={
          data?.optionValueCode === BerthFitCheckStatus.NotAcceptable
            ? 'm-l-status-item status-bg-red'
            : data?.optionValueCode === BerthFitCheckStatus.Acceptable
            ? 'm-l-status-item status-bg-green'
            : data?.optionValueCode === BerthFitCheckStatus.FurtherInfoNeeded
            ? 'status-wrong-entry-wtout'
            : 'm-l-status-item status-bg-orange'
        }
      >
        {data?.optionValueText}
      </div>
    );
  };

  const statusFieldRender = (data: IOptionvalue) => {
    return (
      <TextBox
        className={
          isOverrided || status !== berthTerminalStatus
            ? 'draft-lbl'
            : data?.optionValueCode === BerthFitCheckStatus.NotAcceptable
            ? 'inactive-lbl'
            : data?.optionValueCode === BerthFitCheckStatus.Acceptable
            ? 'active-lbl'
            : 'status-wrong-entry-wtout'
        }
        defaultValue={data?.optionValueText}
        readOnly={true}
      />
    );
  };

  return (
    <ValidationGroup>
      {/* <div className="m-l-berthfit-info-wrapper mrbtm-space">
        <div className="m-l-berthfit-info-left-part">
          <div className="row">
            <div className="col-xl-4 col-lg-4 co-md-4 col-sm-6 col-12">
              <div className="m-c-form-group mb-btm-space">
                <label className="m-l-input-label">
                  {t('labels.comments')}
                </label>
                <TextBox
                  className="m-c-input-control"
                  value={comments}
                  onValueChanged={(e) => setComments(e.value)}
                  validationMessageMode="always"
                >
                  <Validator>
                    <RequiredRule message={t('errors.required')} />
                  </Validator>
                </TextBox>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 co-md-4 col-sm-6 col-12 m-l-custom-sixteen-space-col">
              <div className="m-c-form-group mb-btm-space">
                <label className="m-l-input-label">{t('labels.status')}</label>
                <SelectBox
                  dataSource={berthCheckStatus}
                  valueExpr="optionValueCode"
                  displayExpr="optionValueText"
                  className="m-c-input-control m-c-select-box"
                  value={status}
                  onValueChanged={(e) => setStatus(e.value)}
                  itemRender={statusItemRender}
                  fieldRender={status ? statusFieldRender : undefined}
                  validationMessageMode="always"
                >
                  <Validator>
                    <RequiredRule message={t('errors.required')} />
                  </Validator>
                </SelectBox>
              </div>
            </div>
          </div>
        </div>
        <div className="m-l-berth-info-btn-right-part">
          <div className="col-xl-3 col-lg-3 co-md-3 col-sm-6 col-12 m-l-berth-info-btn-block">
            <Restricted permission="BerthFitVet.SaveBerthFit">
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                aria-label=""
                onClick={onClickSave}
              >
                {t('buttons.save')}
              </Button>
            </Restricted>
          </div>
        </div>
      </div> */}
      <div className="row m-l-accordion-inner-page-comment-row">
        <div className="col-xl-4 col-lg-4 co-md-4 col-sm-12 col-12 inner-comment-box-block">
          <Restricted permission="BerthFitVet.AddComments">
            <div className="m-c-form-group mb-btm-space">
              <label className="m-l-input-label"> {t('labels.comments')}</label>
              <TextBox
                className="m-c-input-control"
                value={comments}
                onValueChanged={(e) => setComments(e.value)}
                validationMessageMode="always"
              >
                <Validator>
                  <RequiredRule message={t('errors.required')} />
                </Validator>
              </TextBox>
            </div>
          </Restricted>
        </div>
        <div className="col-xl-3 col-lg-3 co-md-3 col-sm-12 col-12 inner-comment-status-block">
          <div className="m-c-form-group mb-btm-space">
            <label className="m-l-input-label">{t('labels.status')}</label>
            <SelectBox
              dataSource={berthCheckStatus}
              valueExpr="optionValueCode"
              displayExpr="optionValueText"
              className="m-c-input-control m-c-select-box"
              value={status}
              onValueChanged={(e) => setStatus(e.value)}
              itemRender={statusItemRender}
              fieldRender={status ? statusFieldRender : undefined}
              validationMessageMode="always"
            >
              <Validator>
                <RequiredRule message={t('errors.required')} />
              </Validator>
            </SelectBox>
          </div>
        </div>
        <div className="col-xl-2 col-lg-2 co-md-2 col-sm-12 inner-comment-btn-block">
          <div className="m-c-form-group mb-btm-space">
            <Button
              className="app-c-btn app-c-btn--secondary min-btn-width"
              aria-label=""
              onClick={onClickSave}
            >
              {t('buttons.save')}
            </Button>
          </div>
        </div>
      </div>
    </ValidationGroup>
  );
}
