import TagBox from 'devextreme-react/tag-box';
import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormConfig } from '../../../types/form-builder';
import { ICountry, IOptionvalue } from '../../../types/master-data';
import FormBuilder from '../../common/form-builder/FormBuilder';

export default function AdvancedSearchForm(props: {
  collapseSearch: any;
  countryList: ICountry;
  classSocietyList: IOptionvalue[];
  classificationStatusList: IOptionvalue[];
  vesselTypes: IOptionvalue[];
  vesselStatusList: IOptionvalue[];
}) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const {
    collapseSearch,
    countryList,
    classSocietyList,
    classificationStatusList,
    vesselTypes,
    vesselStatusList,
  } = props;
  const items = [
    {
      id: 1,
      name: 'Name',
    },
    {
      id: 2,
      name: 'IMO',
    },
  ];

  const tagRender = (data: any) => {
    return (
      <div style={{ paddingLeft: '4px' }}>
        <img style={{ width: '35px', borderRadius: '5px' }} src={data.flag} />
      </div>
    );
  };

  const onOpened = (e: any) => {
    e.component.content().querySelector('.dx-list-select-all').style.display =
      'none';
  };

  const formConfig: IFormConfig[] = [
    {
      name: 'imo',
      label: t('labels.imoNumber'),
      control: control,
      fieldType: 'number',
      maxLength: 100,
      rules: [
        {
          type: 'stringLength',
          message: t('errors.searchWithLessThan2chara'),
          min: 3,
          ignoreEmptyValue: true,
        },
      ],
      class: 'col-sm-6 m-l-custom-sixteen-space-col',
    },
    {
      name: 'callSign',
      label: t('labels.callSignNumber'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
      isHidden: !collapseSearch,
      rules: [
        {
          type: 'stringLength',
          message: t('errors.searchWithLessThan2chara'),
          min: 3,
          ignoreEmptyValue: true,
        },
      ],
      class: 'col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
    },
    {
      name: 'mmsi',
      label: t('labels.mmsiNumber'),
      control: control,
      fieldType: 'number',
      maxLength: 100,
      isHidden: !collapseSearch,
      rules: [
        {
          type: 'stringLength',
          message: t('errors.searchWithLessThan2chara'),
          min: 3,
          ignoreEmptyValue: true,
        },
      ],
      class:
        'col-md-4 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
    },
    {
      name: 'flagCodes',
      label: t('labels.flag'),
      control: control,
      fieldType: 'multiSelect',
      dataSource: countryList,
      displayExpr: 'countryName',
      valueExpr: 'countryCode',
      onOpened: onOpened,
      showClearButton: true,
      isHidden: !collapseSearch,
      class:
        'col-md-4 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      tagRender: tagRender,
    },
    {
      name: 'flagCodes',
      label: t('labels.flag'),
      control: control,
      fieldType: 'multiSelect',
      dataSource: countryList,
      displayExpr: 'countryName',
      valueExpr: 'countryCode',
      onOpened: onOpened,
      showClearButton: true,
      isHidden: collapseSearch,
      class: 'col-sm-6 m-l-custom-sixteen-space-col',
      tagRender: tagRender,
    },

    {
      name: 'vesselName',
      label: t('labels.vesselName'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
      isHidden: collapseSearch,
      rules: [
        {
          type: 'stringLength',
          message: t('errors.searchWithLessThan2chara'),
          min: 3,
          ignoreEmptyValue: true,
        },
      ],
      class: 'col-sm-6 m-l-custom-sixteen-space-col',
    },
    {
      name: 'isPreviousName',
      label: t('labels.previousName'),
      control: control,
      fieldType: 'switch',
      maxLength: 100,
      isHidden: collapseSearch,
      class: 'col-sm-6 m-l-custom-sixteen-space-col',
    },

    {
      name: 'vin',
      label: t('labels.vinNumber'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
      isHidden: collapseSearch,
      rules: [
        {
          type: 'stringLength',
          message: t('errors.searchWithLessThan2chara'),
          min: 3,
          ignoreEmptyValue: true,
        },
      ],
      class: 'col-sm-6 m-l-custom-sixteen-space-col',
    },
    {
      name: 'callSign',
      label: t('labels.callSignNumber'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
      isHidden: collapseSearch,
      rules: [
        {
          type: 'stringLength',
          message: t('errors.searchWithLessThan2chara'),
          min: 3,
          ignoreEmptyValue: true,
        },
      ],
      class: 'col-sm-6 m-l-custom-sixteen-space-col',
    },

    {
      name: 'yearOfBuildFrom',
      label: t('labels.yearOfBuildFrom'),
      toName: 'yearOfBuildTo',
      toLabel: t('labels.to'),
      control: control,
      fieldType: 'numberRange',
      maxLength: 100,
      isHidden: collapseSearch,
      class: 'col-sm-6 m-l-custom-sixteen-space-col',
    },
    {
      name: 'mmsi',
      label: t('labels.mmsiNumber'),
      control: control,
      fieldType: 'number',
      maxLength: 100,
      isHidden: collapseSearch,
      rules: [
        {
          type: 'stringLength',
          message: t('errors.searchWithLessThan2chara'),
          min: 3,
          ignoreEmptyValue: true,
        },
      ],
      class: 'col-sm-6  m-l-custom-sixteen-space-col',
    },

    {
      name: 'grossTonnageFrom',
      label: t('labels.grossTonageFrom'),
      toName: 'grossTonnageTo',
      toLabel: t('labels.to'),
      control: control,
      fieldType: 'numberRange',
      maxLength: 100,
      isHidden: collapseSearch,
      class: 'col-sm-6 m-l-custom-sixteen-space-col',
    },

    // {
    //   name: 'deadweightFrom',
    //   label: t('labels.deadWeightFrom'),
    //   control: control,
    //   fieldType: 'number',
    //   maxLength: 100,
    //   isHidden: collapseSearch,
    //   class:
    //     'col-md-4 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
    // },

    //////////////////////
    // {
    //   name: 'yearOfBuildFrom',
    //   label: t('labels.yearOfBuildFrom'),
    //   control: control,
    //   fieldType: 'number',
    //   maxLength: 100,
    //   isHidden: collapseSearch,
    //   class:
    //     'col-md-4 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
    // },
    // {
    //   name: 'yearOfBuildTo',
    //   label: t('labels.to'),
    //   control: control,
    //   fieldType: 'number',
    //   maxLength: 100,
    //   isHidden: collapseSearch,
    //   class:
    //     'col-md-4 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
    // },
    //////////////////////////////
    {
      name: 'classSociety',
      label: t('labels.classSociety'),
      control: control,
      fieldType: 'multiSelect',
      dataSource: classSocietyList,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      showClearButton: true,
      onOpened: onOpened,
      isHidden: collapseSearch,
      class: 'col-sm-6  m-l-custom-sixteen-space-col',
    },

    {
      name: 'deadweightFrom',
      label: t('labels.deadWeightFrom'),
      toName: 'deadweightTo',
      toLabel: t('labels.to'),
      control: control,
      fieldType: 'numberRange',
      maxLength: 100,
      isHidden: collapseSearch,
      class: 'col-sm-6 m-l-custom-sixteen-space-col',
    },

    {
      name: 'classificationStatus',
      label: t('labels.classificationStatus'),
      control: control,
      fieldType: 'multiSelect',
      dataSource: classificationStatusList,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      showClearButton: true,
      onOpened: onOpened,
      isHidden: collapseSearch,
      class: 'col-sm-6  m-l-custom-sixteen-space-col',
    },
    {
      name: 'vesselType',
      label: t('labels.vesselType'),
      control: control,
      fieldType: 'multiSelect',
      dataSource: vesselTypes,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      showClearButton: true,
      onOpened: onOpened,
      isHidden: collapseSearch,
      class: 'col-sm-6 m-l-custom-sixteen-space-col',
    },
    {
      name: 'vesselStatus',
      label: t('labels.vesselStatus'),
      control: control,
      fieldType: 'select',
      dataSource: vesselStatusList,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      isHidden: collapseSearch,
      class: ' col-sm-6  m-l-custom-sixteen-space-col',
    },
  ];
  const formConfig2: IFormConfig[] = [
    {
      name: 'registeredOwner',
      label: t('labels.registeredOwner'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
      isHidden: collapseSearch,
      rules: [
        {
          type: 'stringLength',
          message: t('errors.searchWithLessThan2chara'),
          min: 3,
          ignoreEmptyValue: true,
        },
      ],
      class: 'col m-l-col__expand m-l-custom-sixteen-space-col',
    },
    {
      name: 'registeredOwnerSearchType',
      label: '',
      control: control,
      fieldType: 'radio',
      items: items,
      layout: 'horizontal',
      displayExpr: 'name',
      isHidden: collapseSearch,
      valueExpr: 'id',

      class: 'col m-l-col__fixed m-l-custom-sixteen-space-col',
    },
    {
      name: 'beneficialOwner',
      label: t('labels.beneficialOwner'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
      isHidden: collapseSearch,
      class: 'col m-l-col__expand m-l-custom-sixteen-space-col',
    },
    {
      name: 'beneficialOwnerSearchType',
      label: '',
      control: control,
      fieldType: 'radio',
      items: items,
      layout: 'horizontal',
      displayExpr: 'name',
      valueExpr: 'id',
      isHidden: collapseSearch,
      class: 'col m-l-col__fixed m-l-custom-sixteen-space-col',
    },
    {
      name: 'vesselManager',
      label: t('labels.vesselOrCommercialManager'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
      isHidden: collapseSearch,
      rules: [
        {
          type: 'stringLength',
          message: t('errors.searchWithLessThan2chara'),
          min: 3,
          ignoreEmptyValue: true,
        },
      ],
      class: 'col m-l-col__expand m-l-custom-sixteen-space-col',
    },
    {
      name: 'vesselManagerSearchType',
      label: '',
      control: control,
      fieldType: 'radio',
      items: items,
      layout: 'horizontal',
      displayExpr: 'name',
      valueExpr: 'id',
      isHidden: collapseSearch,
      class: 'col m-l-col__fixed m-l-custom-sixteen-space-col',
    },
    {
      name: 'ismManager',
      label: t('labels.docCompany'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
      isHidden: collapseSearch,
      rules: [
        {
          type: 'stringLength',
          message: t('errors.searchWithLessThan2chara'),
          min: 3,
          ignoreEmptyValue: true,
        },
      ],
      class: 'col m-l-col__expand m-l-custom-sixteen-space-col',
    },
    {
      name: 'ismManagerSearchType',
      label: '',
      control: control,
      fieldType: 'radio',
      items: items,
      layout: 'horizontal',
      displayExpr: 'name',
      valueExpr: 'id',
      isHidden: collapseSearch,
      class: 'col m-l-col__fixed m-l-custom-sixteen-space-col',
    },
    {
      name: 'technicalManager',
      label: t('labels.technicalManager'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
      isHidden: collapseSearch,
      class: 'col m-l-col__expand m-l-custom-sixteen-space-col',
    },
    // empty space
    // {
    //     name: 'yearOfBuildTo',
    //     label: t('labels.to'),
    //     control: control,
    //     fieldType: 'number',
    //     maxLength: 100,
    //     isHidden: true,
    //     class:
    //       'col-md-4 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',

    //   },
    {
      name: 'technicalManagerSearchType',
      label: '',
      control: control,
      fieldType: 'radio',
      items: items,
      layout: 'horizontal',
      displayExpr: 'name',
      valueExpr: 'id',
      isHidden: collapseSearch,
      class: 'col m-l-col__fixed m-l-custom-sixteen-space-col',
    },
  ];
  return (
    <div>
      <form>
        <div className="m-l-form-panel-body-block m-l-rl-pnl-body-block">
          <div className="row">
            <div
              className={
                !collapseSearch ? 'col-lg-7' : 'col-lg-12 m-l-search__collapse'
              }
            >
              <div className="row">
                <FormBuilder formConfig={formConfig} />
              </div>
            </div>
            {!collapseSearch && (
              <div className="col-lg-5 align-self-center">
                <div className="m-l-form__fieldset">
                  <span className="m-l-form__legend">
                    {t('labels.legalEntities')}
                  </span>
                  <div className="row">
                    <FormBuilder formConfig={formConfig2} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
