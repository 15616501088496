import Button from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IModalConfig } from '../../../../types/modal';
import icons from '../../../common/icons/icons';
import Modal from '../../../common/modal/Modal';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import {
  IRuleAdditionalFields,
  IRuleValues,
} from '../../../../types/vessel-info-rules';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import AdditionalInfoFormFields from '../additional-info-form-fields/AdditionalInfoFormFields';
import { IDialogConfig } from '../../../../types/dialog';
import { getAPIRoute } from '../../../../utils/api-route';
import toast from '../../../../utils/toast';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import ValidationGroup from 'devextreme-react/validation-group';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import { useDimensionsUnit } from '../../../../hooks/useDimensionsUnit';

export default function AdditionalInfoModal(props: {
  isVisible: boolean;
  handleCancelClick: any;
  ruleId: number;
  imoNumber?: string;
  vetRequestId: number;
  getRuleList: () => Promise<void>;
}) {
  const { ruleId, imoNumber, vetRequestId, getRuleList, handleCancelClick } =
    props;

  const { t } = useTranslation();

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      saveRuleValues();
    },
    handleClose: () => {
      setShowConfirmSave(false);
    },
  };

  const modalConfig: IModalConfig = {
    width: 799,
    height: 305,
    visible: props.isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-form-modal',
    };
  }, []);

  const [additionalInfoDetails, setAdditionalInfoDetails] = useState<
    IRuleAdditionalFields[]
  >([]);

  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const { setIsLoading } = useLoaderContext();
  const additionalInfoForm = useForm();

  const { control, reset, getValues } = additionalInfoForm;

  const validationGroupRef = useRef<ValidationGroup>(null);

  const { apiDateFormat } = useDateFormat();

  const { dimensionUnit, meterToFeetConversion, feetToMeterConversion } =
    useDimensionsUnit();

  const additionalInfoFieldArray = useFieldArray({
    control: control,
    name: 'additionalInfoFieldArray',
  });

  const getAdditionalInfoDetails = async () => {
    api
      .get({
        url: apiConfig.vettingRuleAdditionalInfoDetails + ruleId,
        params: { imo: imoNumber, vetRequestId: vetRequestId },
      })
      .then((res: IRuleAdditionalFields[]) => {
        setAdditionalInfoDetails(res);
      });
  };

  useEffect(() => {
    if (ruleId && imoNumber) {
      getAdditionalInfoDetails();
    }
  }, [ruleId, imoNumber]);

  useEffect(() => {
    reset();
    if (additionalInfoDetails?.length) {
      additionalInfoDetails.map((field) => {
        additionalInfoFieldArray.append({
          fieldValue:
            field.isConversionRequired && field.value
              ? meterToFeetConversion(Number(field.value))
              : field.value,
        });
      });
    }
  }, [additionalInfoDetails, dimensionUnit]);

  const getRuleValueJson = (): IRuleValues => {
    return {
      imo: imoNumber,
      isOverrided: false,
      isMessageUpdated: false,
      vetRuleConditionValues: getValues().additionalInfoFieldArray.map(
        (item: any, index: number) => {
          const value =
            additionalInfoDetails[index].isConversionRequired && item.fieldValue
              ? feetToMeterConversion(Number(item.fieldValue))
              : item.fieldValue;
          return {
            entityId: additionalInfoDetails[index].entityId,
            entityName: additionalInfoDetails[index].entityName,
            attributeId: additionalInfoDetails[index].attributeId,
            attributeName: additionalInfoDetails[index].attributeName,
            attributeType: additionalInfoDetails[index].attributeType,
            value: value
              ? additionalInfoDetails[index].attributeType === 'DateTime' ||
                additionalInfoDetails[index].attributeType === 'Date'
                ? apiDateFormat(value)
                : value?.toString()
              : undefined,
          };
        }
      ),
    };
  };

  const onClickSave = () => {
    if (validationGroupRef.current?.instance.validate().isValid) {
      setShowConfirmSave(true);
    }
  };

  const saveRuleValues = async () => {
    api
      .put(
        {
          url: getAPIRoute('vetRequestRuleValues', [vetRequestId, ruleId]),
          data: getRuleValueJson(),
        },
        setIsLoading
      )
      .then(() => {
        toast.success({
          title: t('toast.savedSuccessfully'),
          message: t('toast.additionalInfoSaved'),
        });
        getRuleList();
      });
    setShowConfirmSave(false);
    handleCancelClick();
  };

  return (
    <div className="m-l-model-popup__wrap" data-testid="additionalInfoModal">
      <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
        <div className="m-l-modal__header">
          <h2 className="modal-title">
            {t('headers.additionalDataCapturing')}
          </h2>
          <div className="m-l-modal-close-button-block">
            <Button
              className="app-c-btn app-c-icon-only-btn"
              onClick={props.handleCancelClick}
            >
              <icons.IconCross />
            </Button>
          </div>
        </div>
        <div className="m-l-modal__body additional-data-capturing-body-block">
          <ScrollView width="100%" height="100%">
            <div className="m-l-adnw-form-panel">
              <ValidationGroup ref={validationGroupRef}>
                <div className="row m-l-custom-sixteen-space-row">
                  {additionalInfoFieldArray.fields.map(
                    (item: any, index: number) => {
                      return (
                        <FormProvider {...additionalInfoForm} key={item.id}>
                          <AdditionalInfoFormFields
                            additionalInfoDetails={additionalInfoDetails}
                            index={index}
                          />
                        </FormProvider>
                      );
                    }
                  )}
                </div>
              </ValidationGroup>
            </div>
          </ScrollView>
        </div>
        <div className="m-l-modal__footer">
          <Button
            className="app-c-btn app-c-btn--primary min-btn-width"
            onClick={onClickSave}
            elementAttr={{ 'data-testid': 'additionalInfoSaveButton' }}
          >
            {t('buttons.save')}
          </Button>
        </div>
      </Modal>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={showConfirmSave}
      />
    </div>
  );
}
