import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import FormBuilder from '../../../../common/form-builder/FormBuilder';
import { useDateFormat } from '../../../../../hooks/useDateFormat';

export default function SireForm() {
  const { t } = useTranslation();
  const { control, getValues, setValue } = useFormContext();
  const [operations, setOperations] = useState<any>();
  const { dateFormat } = useDateFormat();
  const [sireValidityDate, setSireValidityDate] = useState();
  const [sireDate, setSireDate] = useState();

  const getOperationsTypes = async () => {
    await api
      .get({ url: apiConfig.optionValue + 'SIREOperationTypes' })
      .then((data: any) => {
        setOperations(data);
      });
  };

  const onChangeSireValidityDate = (e: any) => {
    setSireValidityDate(e.value);
  };

  const onChangeSireDate = (e: any) => {
    setSireDate(e.value);
    const fromDate = new Date(e.value);
    if (e.value && !sireValidityDate) {
      setValue('sireValidityDate', fromDate.setMonth(fromDate.getMonth() + 6));
    }
  };

  const validateSireValidityDate = (params: any) => {
    const sireDate = getValues()?.sireDate;
    if (sireDate != '' && sireDate != null) {
      const fromDate = new Date(sireDate);
      const currentDate = new Date(params.value);
      return fromDate.getTime() <= currentDate.getTime();
    }
    return true;
  };

  const validateSireDate = (params: any) => {
    const sireValidityDate = getValues()?.sireValidityDate;
    if (sireValidityDate != '' && sireValidityDate != null) {
      const fromDate = new Date(sireValidityDate);
      const currentDate = new Date(params.value);
      return fromDate.getTime() >= currentDate.getTime();
    }
    return true;
  };

  useEffect(() => {
    getOperationsTypes();
  }, []);

  const sireFormConfig = [
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'sireDate',
      label: t('labels.sireDate'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
      onChange: onChangeSireDate,
      rules: [
        {
          type: 'custom',
          reevaluate: true,
          validationCallback: validateSireDate,
          message: t('errors.dateBeforeSireDate'),
          ignoreEmptyValue: true,
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'sireValidityDate',
      label: 'SIRE Validity Date',
      control: control,
      fieldType: 'date',
      format: dateFormat,
      onChange: onChangeSireValidityDate,
      rules: [
        {
          type: 'custom',
          reevaluate: true,
          validationCallback: validateSireValidityDate,
          message: t('errors.dateAfterSireDate'),
          ignoreEmptyValue: true,
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'operationsTypeCode',
      label: t('labels.sireType'),
      control: control,
      fieldType: 'select',
      dataSource: operations,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'conductedBy',
      label: t('labels.conductedBy'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-md-6 col-sm-12 col-lg-6 col-xs-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-24',
      fieldClass:
        'm-c-text-area m-c-text-area-custom m-c-textrea-sire-comments',
      name: 'comments',
      label: t('labels.comments'),
      control: control,
      fieldType: 'textArea',
      maxLength: 10,
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={sireFormConfig} />
      </div>
    </>
  );
}
