import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { Button } from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import toast from '../../../../utils/toast';
import { IMasterEntity } from '../../../../types/master-data';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../../types/dialog';
import CheckBox from 'devextreme-react/check-box';
import ReportSettingForm from '../report-settings/report-setting-form/ReportSettingForm';
import BerthFitReportSettingForm from './BerthfitReportSettingForm';
import { BerthFitReportSettings } from '../../../../enums/config-enum';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import { usePermission } from '../../../../contexts/PermissionContext';

export default function BerthfitReportSetting(props: {
  companyId: any;
  buId?: any;
  saveConfig?: any;
  setSaveConfig?: any;
}) {
  const { buId, saveConfig, setSaveConfig, companyId } = props;
  const { t } = useTranslation();
  const { findFeature } = usePermission();
  const [marsDisclaimer, setMarsDisclaimer] = useState(false);
  const [marsDisclaimerText, setMarsDisclaimerText] = useState<any[]>([]);
  const [requestorDisclaimer, setRequestorDisclaimer] = useState(false);
  const [requestorDisclaimerText, setRequestorDisclaimerText] = useState<any[]>(
    []
  );
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [showMarsDisclaimer, setShowMarsDisclaimer] = useState<any[]>([]);
  const [showReqDisclaimer, setShowReqDisclaimer] = useState<any[]>([]);
  const [reportConfiguration, setReportConfiguration] = useState<any[]>([]);
  const [vesselDetails, setVesselDetails] = useState(false);
  const [berthfitComments, setBerthfitComments] = useState(false);
  const [vetDetails, setvetDetails] = useState(false);
  const [appealStatusComments, setAppealStatusComments] = useState(false);
  const [berthfitexecutionDetails, setBerthfitexecutionDetails] =
    useState(false);
  const [entities, setEntities] = useState<IMasterEntity[]>([]);
  const { setIsLoading } = useLoaderContext();
  const getreportConfigurationGlobal = async () => {
    if (buId && companyId) {
      await api
        .get({
          url: apiConfig.configurations,
          params: { settingArea: 'BerthFit', companyId: companyId, buId: buId },
        })
        .then((response) => {
          setReportConfiguration(response);
        });
    } else {
      await api
        .get({
          url: apiConfig.configurations,
          params: { settingArea: 'BerthFit', companyId: companyId },
        })
        .then((response) => {
          setReportConfiguration(response);
        });
    }
  };

  useEffect(() => {
    getreportConfigurationGlobal();
  }, []);

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmSaveChanges'),
    handleSubmit: () => {
      saveConfigs();
      setShowConfirmSave(false);
      setSaveConfig(false);
    },
    handleClose: () => {
      setShowConfirmSave(false);
      setSaveConfig(false);
    },
  };

  const handleSaveClick = async () => {
    let index = reportConfiguration?.findIndex(
      (item: any) => item?.settingType == BerthFitReportSettings.MARSDisclaimer
    );
    reportConfiguration[index].settingDescription = marsDisclaimerText;
    index = reportConfiguration?.findIndex(
      (item: any) =>
        item?.settingType == BerthFitReportSettings.RequestorDisclaimer
    );
    reportConfiguration[index].settingDescription = requestorDisclaimerText;
    setShowConfirmSave(true);
  };

  const onClickCheckBox = async (e: any, settingType: any) => {
    const index = reportConfiguration?.findIndex(
      (item: any) => item?.settingType == settingType
    );
    reportConfiguration[index].settingValue = e.value ? 'yes' : 'no';
  };

  useEffect(() => {
    if (saveConfig) {
      handleSaveClick();
    }
  }, [saveConfig]);

  useEffect(() => {
    if (reportConfiguration) {
      let index = reportConfiguration?.findIndex(
        (item: any) => item?.settingType == BerthFitReportSettings.VesselDetails
      );
      setVesselDetails(
        reportConfiguration[index]?.settingValue === 'yes' ? true : false
      );
      index = reportConfiguration?.findIndex(
        (item: any) => item?.settingType == BerthFitReportSettings.VetDetails
      );
      setvetDetails(
        reportConfiguration[index]?.settingValue === 'yes' ? true : false
      );
      index = reportConfiguration?.findIndex(
        (item: any) =>
          item?.settingType == BerthFitReportSettings.BerthExecutionDetails
      );
      setBerthfitexecutionDetails(
        reportConfiguration[index]?.settingValue === 'yes' ? true : false
      );
      index = reportConfiguration?.findIndex(
        (item: any) =>
          item?.settingType == BerthFitReportSettings.BerthFitComments
      );
      setBerthfitComments(
        reportConfiguration[index]?.settingValue === 'yes' ? true : false
      );
      index = reportConfiguration?.findIndex(
        (item: any) =>
          item?.settingType == BerthFitReportSettings.AppealStatusComments
      );
      setAppealStatusComments(
        reportConfiguration[index]?.settingValue === 'yes' ? true : false
      );
      index = reportConfiguration?.findIndex(
        (item: any) =>
          item?.settingType == BerthFitReportSettings.MARSDisclaimer
      );
      setMarsDisclaimer(
        reportConfiguration[index]?.settingValue === 'yes' ? true : false
      );
      setMarsDisclaimerText(reportConfiguration[index]?.settingDescription);
      setShowMarsDisclaimer(reportConfiguration[index]?.settingType);
      index = reportConfiguration?.findIndex(
        (item: any) =>
          item?.settingType == BerthFitReportSettings.RequestorDisclaimer
      );
      setRequestorDisclaimer(
        reportConfiguration[index]?.settingValue === 'yes' ? true : false
      );
      setRequestorDisclaimerText(
        reportConfiguration[index]?.settingDescription
      );
      setShowReqDisclaimer(reportConfiguration[index]?.settingType);
    } else {
      setShowEditor(false);
    }
  }, [reportConfiguration]);

  const saveConfigs = async () => {
    if (buId) {
      await api
        .put(
          {
            url: apiConfig.configurations,
            params: {
              configArea: 'BerthFit',
              buId: buId,
              companyId: companyId,
            },
            data: reportConfiguration,
          },
          setIsLoading
        )
        .then(() => {
          toast.success({
            title: t('toast.changesSavedSuccessfully'),
          });
        });
    } else {
      await api
        .put(
          {
            url: apiConfig.configurations,
            params: { configArea: 'BerthFit', companyId: companyId },
            data: reportConfiguration,
          },
          setIsLoading
        )
        .then(() => {
          toast.success({
            title: t('toast.changesSavedSuccessfully'),
          });
        });
    }
  };

  return (
    <div className="m-l-form-panel-block">
      <div className="m-l-form-panel-header-block">
        <div className="m-l-form-panel-header-left-block">
          <div className="m-l-page-small-heading">
            {t('labels.berthfitReportSettings')}
          </div>
        </div>
        <div className="m-l-form-panel-header-right-block">
          {/* {!buId && <Button
            className="app-c-btn app-c-btn--primary min-btn-width"
            aria-label=""
          >
            {t('buttons.save')}
          </Button>} */}
        </div>
      </div>
      <div className="m-l-grid-top-header__section u-bb0">
        <div className="left-section">
          <div className="m-l-grid-top-header-list-row">
            <div className="row">
              <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 col-12 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{ 'data-testid': 'vesselDetailsToggle' }}
                    onValueChanged={(e: any) => {
                      onClickCheckBox(e, BerthFitReportSettings.VesselDetails);
                      setVesselDetails(e.value);
                    }}
                    value={vesselDetails}
                  />
                </div>
                <h2 className="checkbox-label">{t('labels.vesselDetails')}</h2>
              </div>
              <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{ 'data-testid': 'vetDetailsToggle' }}
                    onValueChanged={(e: any) => {
                      onClickCheckBox(e, BerthFitReportSettings.VetDetails);
                      setvetDetails(e.value);
                    }}
                    value={vetDetails}
                  />
                </div>
                <h2 className="checkbox-label">{t('labels.vetDetails')}</h2>
              </div>
              <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{ 'data-testid': 'rulesToggle' }}
                    onValueChanged={(e: any) => {
                      onClickCheckBox(
                        e,
                        BerthFitReportSettings.BerthExecutionDetails
                      );
                      setBerthfitexecutionDetails(e.value);
                    }}
                    value={berthfitexecutionDetails}
                  />
                </div>
                <h2 className="checkbox-label">
                  {t('labels.berthfitExecutionDetails')}
                </h2>
              </div>
              <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{ 'data-testid': 'requestorCommentsToggle' }}
                    onValueChanged={(e: any) => {
                      onClickCheckBox(
                        e,
                        BerthFitReportSettings.BerthFitComments
                      );
                      setBerthfitComments(e.value);
                    }}
                    value={berthfitComments}
                  />
                </div>
                <h2 className="checkbox-label">
                  {t('labels.berthFitComments')}
                </h2>
              </div>
              {/* {buId && (
                <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column">
                  <div className="m-c-checkbox m-c-checkbox-dark">
                    <CheckBox
                      elementAttr={{ 'data-testid': 'appealStatusToggle' }}
                      onValueChanged={(e: any) => {
                        onClickCheckBox(
                          e,
                          BerthFitReportSettings.AppealStatusCommentsInBerth
                        );
                        setAppealStatusComments(e.value);
                      }}
                      value={appealStatusComments}
                    />
                  </div>
                  <h2 className="checkbox-label">
                    {t('labels.appealStatusComments')}
                  </h2>
                </div>
              )} */}
              <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{ 'data-testid': 'marsDisclaimerToggle' }}
                    onValueChanged={(e: any) => {
                      onClickCheckBox(e, BerthFitReportSettings.MARSDisclaimer);
                      setMarsDisclaimer(e.value);
                    }}
                    disabled={
                      buId
                        ? !findFeature(
                            'BUCompanyConfiguration.EditBerthFitReportMARSDisclaimerCheckbox'
                          )
                        : !findFeature(
                            'CompanyConfiguration.EditBerthFitReportMARSDisclaimerCheckbox'
                          )
                    }
                    value={marsDisclaimer}
                  />
                </div>
                <h2 className="checkbox-label">{t('labels.marsDisclaimer')}</h2>
              </div>
              <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{ 'data-testid': 'requestorDisclaimerToggle' }}
                    onValueChanged={(e: any) => {
                      onClickCheckBox(
                        e,
                        BerthFitReportSettings.RequestorDisclaimer
                      );
                      setRequestorDisclaimer(e.value);
                    }}
                    value={requestorDisclaimer}
                  />
                </div>
                <h2 className="checkbox-label">
                  {t('labels.requestorDisclaimer')}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m-l-form-panel-body-block">
        <BerthFitReportSettingForm
          entities={entities}
          requestorDisclaimer={requestorDisclaimerText}
          setRequestorDisclaimer={setRequestorDisclaimerText}
          marsDisclaimer={marsDisclaimerText}
          setMarsDisclaimer={setMarsDisclaimerText}
          showEditor={showEditor}
          buId={buId}
          showMarsDisclaimer={showMarsDisclaimer}
          showReqDisclaimer={showReqDisclaimer}
        />
      </div>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={showConfirmSave}
      />
    </div>
  );
}
