import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Grid from '../../../components/common/grid/Grid';
import { IGridConfig } from '../../../types/grid';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { MarsApiService as api } from '../../../api/mars-api-service';
import Switch from 'devextreme-react/switch';
import { IDialogConfig } from '../../../types/dialog';
import ConfirmDialog from '../../../components/common/confirm/ConfirmDialog';
import Button from 'devextreme-react/button';
import { Restricted, usePermission } from '../../../contexts/PermissionContext';
import { useLoaderContext } from '../../../contexts/LoaderContext';

export default function ViewList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [list, setList] = useState();
  const [activeConfirmDialog, setActiveConfirmDialog] = useState(false);
  const { setIsLoading } = useLoaderContext();
  const { findFeature, featureList } = usePermission();

  const confirmStatusDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      setActiveConfirmDialog(false);
    },
    handleClose: () => {
      getList();
      setActiveConfirmDialog(false);
    },
  };

  const [activeDialogConfig, setActiveDialogConfig] = useState(
    confirmStatusDialogConfig
  );
  const getList = async () => {
    await api.get({ url: apiConfig.list }).then((data) => {
      setList(data);
    });
  };

  useEffect(() => {
    getList();
  }, []);

  const onRowClick = (e: any) => {
    if (
      ![
        'dx-switch-on',
        'dx-switch-off',
        'dx-switch-handle',
        'dx-button-content',
      ].includes(e.event.target.className)
    ) {
      navigate('/list/edit', { state: { listId: e.key.listId } });
    }
  };

  const yesNoDataSource = [
    {
      name: t('labels.active'),
      value: true,
    },
    {
      name: t('labels.inActive'),
      value: false,
    },
  ];

  const gridConfig: IGridConfig = {
    addButtonPermission: 'GlobalList.AddNewList',
    rowClickPermission: 'GlobalList.ViewDetail',
    testId: 'listViewGrid',
    dataSource: list,
    defaultColumns: [
      {
        caption: t('labels.listName'),
        dataField: 'listName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.listDescription'),
        dataField: 'listDesc',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.entity'),
        dataField: 'masterEntityName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.attribute'),
        dataField: 'masterAttributeName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.status'),
        alignment: 'center',
        dataField: 'isActive',
        dataType: 'string',
        minWidth: 150,
        // visible: findFeature('GlobalList.ActivateInactiveList'),
        cellTemplate: 'statusTemplate',
        allowFiltering: true,
        allowSorting: true,
        lookup: {
          dataSource: yesNoDataSource,
          valueExpr: 'value',
          displayExpr: 'name',
        },
      },
    ],
    showHeader: true,
    header: t('headers.lists'),
    showAddButton: true,
    showExport: false,
    hidePagination: false,
    noDataText: t('labels.companyListNoDataText'),
    rowClick: onRowClick,
    onClickAddButton: () => {
      navigate('/list/add');
    },
  };

  const cellRender = (data: any) => {
    return (
      <div className="m-l-field-wrap">
        <div className="m-l-toggle-switch">
          <Restricted permission="GlobalList.ActivateInactiveList">
            <Switch
              elementAttr={{ 'data-testid': 'statusToggleswitch' }}
              defaultValue={data.value}
              onValueChanged={(e) => {
                handleActiveInactiveChange(e, data.data);
              }}
            />
          </Restricted>
        </div>
      </div>
    );
  };

  const handleActiveInactiveChange = async (e: any, list: any) => {
    if (e.event) {
      const dialogConfig = {
        title: t('toast.confirmation'),
        content: e.value
          ? t('toast.activeListStatus')
          : t('toast.inactiveListStatus'),
        handleSubmit: () => {
          activateList(e.value, list?.listId);
          setActiveConfirmDialog(false);
        },
      };
      setActiveDialogConfig({
        ...confirmStatusDialogConfig,
        ...dialogConfig,
      });
      setActiveConfirmDialog(true);
    }
  };

  const activateList = async (isactive: boolean, listId: any) => {
    const statusJson: any = {
      listId: listId,
      isActive: isactive,
    };
    api
      .put({ url: apiConfig.listsStatus, data: statusJson }, setIsLoading)
      .then(() => {
        getList();
      });
  };

  return (
    <div className="inner-wrapper page-inner-wrap">
      <ul aria-label="breadcrumb" className="m-c-bredcrumb">
        <li>
          <a className="active">{t('headers.list')}</a>
        </li>
      </ul>
      <Grid gridConfig={gridConfig} statusTemplate={cellRender} />
      <ConfirmDialog
        dialogConfig={activeDialogConfig}
        isOpen={activeConfirmDialog}
      />
    </div>
  );
}
