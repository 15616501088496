import React, { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormConfig } from '../../../types/form-builder';
import FormBuilder from '../../common/form-builder/FormBuilder';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import Button from 'devextreme-react/button';
import { IOptionvalue } from '../../../types/master-data';
import { BerthFitCheckStatus } from '../../../enums/berthfit-status-enum';
import TextBox from 'devextreme-react/text-box';
import { usePermission } from '../../../contexts/PermissionContext';
import { BerthCheckStatusContext } from '../../../pages/berth-fit-check/view-berth-fit-check/ViewBerthfit';

export default function BerthDetailsForm(props: {
  handleSaveClick: any;
  berthParameters: any;
  dataProps?: any;
  status?: any;
}) {
  const { handleSaveClick, dataProps, status } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [dataSource, setDataSource] = useState();
  const { findFeature } = usePermission();

  const berthCheckStatus = useContext(BerthCheckStatusContext);

  const getDataSource = async () => {
    await api
      .get({
        url: apiConfig.berthFitCheckStatus,
      })
      .then((res) => {
        setDataSource(res);
      });
  };

  // useEffect(() => {
  //   getDataSource();
  // }, []);

  const statusItemRender = (data: IOptionvalue) => {
    return (
      <div
        className={
          data?.optionValueCode === BerthFitCheckStatus.NotAcceptable
            ? 'm-l-status-item status-bg-red'
            : data?.optionValueCode === BerthFitCheckStatus.Acceptable
            ? 'm-l-status-item status-bg-green'
            : data?.optionValueCode === BerthFitCheckStatus.FurtherInfoNeeded
            ? 'status-wrong-entry-wtout'
            : 'm-l-status-item status-bg-orange'
        }
      >
        {data?.optionValueText}
      </div>
    );
  };
  const statusFieldRender = (data: IOptionvalue) => {
    return (
      <TextBox
        className={
          dataProps.isOverrided || data?.optionValueCode !== status
            ? 'draft-lbl'
            : data?.optionValueCode === BerthFitCheckStatus.NotAcceptable
            ? 'inactive-lbl'
            : data?.optionValueCode === BerthFitCheckStatus.Acceptable
            ? 'active-lbl'
            : 'status-wrong-entry-wtout'
        }
        defaultValue={data?.optionValueText}
        readOnly={true}
      />
    );
  };

  const generalFormConfig: IFormConfig[] = [
    {
      class:
        'col-xl-4 col-lg-4 co-md-4 col-sm-12 col-12 inner-comment-box-block',
      name: 'comments',
      label: t('labels.comments'),
      control: control,
      fieldType: 'text',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-xl-3 col-lg-3 co-md-3 col-sm-12 col-12 inner-comment-status-block',
      name: 'status',
      label: t('labels.status'),
      control: control,
      fieldType: 'select',
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      isHidden: !findFeature('BerthFitVessel.OverrideBerthFitStatus'),
      dataSource: berthCheckStatus,
      itemRender: statusItemRender,
      fieldRender: status ? statusFieldRender : undefined,
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
  ];

  return (
    // <div className="row">
    //   <FormBuilder formConfig={generalFormConfig} />
    //   <div className="col-xl-2 col-lg-2 co-md-2 col-sm-6 col-12 m-l-berth-info-btn-block">
    //     <Button
    //       className="app-c-btn app-c-btn--primary min-btn-width"
    //       aria-label=""
    //       onClick={handleSaveClick}
    //     >
    //       {t('buttons.save')}
    //     </Button>
    //   </div>
    // </div>

    <div className="row m-l-accordion-inner-page-comment-row">
      <FormBuilder formConfig={generalFormConfig} />
      {/* <Restricted permission="BerthFitVessel.SaveBerthFit"> */}
      <div className="col-xl-2 col-lg-2 co-md-2 col-sm-12 inner-comment-btn-block">
        <div className="m-c-form-group mb-btm-space">
          <Button
            className="app-c-btn app-c-btn--secondary min-btn-width"
            aria-label=""
            onClick={handleSaveClick}
          >
            {t('buttons.save')}
          </Button>
        </div>
      </div>
      {/* </Restricted> */}
    </div>
  );
}
