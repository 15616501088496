import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MarsApiService as api } from '../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import { useTranslation } from 'react-i18next';
import { IGridConfig } from '../../types/grid';
import Grid from '../../components/common/grid/Grid';
import Button from 'devextreme-react/button';
import icons from '../../components/common/icons/icons';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';

export default function FailureGrid() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [failureList, setFailureList] = useState<any>([]);
  const [interfaceData, setInterfaceData] = useState<any>([]);
  const [failureData, setFailureData] = useState<any>([]);
  const [gridInstance, setGridInstance] = useState<any>();

  const onGridInitialized = (e: any) => {
    setGridInstance(e.component);
  };

  useEffect(() => {
    if (location.state) {
      const { interfaceData, failureData }: any = location.state;
      setInterfaceData(interfaceData);
      setFailureData(failureData);
    }
  }, [location.state]);

  const getReasonsToFail = async (serviceLogId: any) => {
    await api
      .get({
        url: apiConfig.getExternalInterfacesFailHistory + serviceLogId,
      })
      .then((response: any) => {
        setFailureList(response);
      });
  };

  useEffect(() => {
    if (failureData.serviceLogId) {
      getReasonsToFail(failureData.serviceLogId);
    }
  }, [failureData]);

  const gridConfig: IGridConfig = {
    dataSource: failureList,
    testId: 'companiesGrid',
    class: 'm-c-grid m-c-grid--company',
    remoteOperations: false,
    defaultColumns: [
      {
        caption: t('labels.reasonToFail'),
        dataField: 'reasonToFail',
        dataType: 'string',
        minWidth: 150,
      },
    ],
    // rowClick: onRowClick,
    showHeader: false,
    header: interfaceData?.interfaceName,
    showAddButton: false,
    showExport: false,
    initialized: onGridInitialized,
  };

  const goBack = () => {
    navigate('/external-interfaces/grid', {
      state: {
        interfaceData: interfaceData,
      },
    });
  };

  const exportRolesGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: gridInstance,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `ReasonToFail.xlsx`
        );
      });
    });
  };

  return (
    <div className="inner-wrapper">
      <div className="m-l-inner-page-header">
        <div className="m-l-inner-page-header-left-block">
          <div className="m-l-page-main-heading">
            {interfaceData?.displayName}
          </div>
        </div>
        <div className="m-l-inner-page-header-right-block">
          <Button
            className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
            aria-label="Upload"
            onClick={exportRolesGrid}
          >
            <div className="m-c-icon-inside-btn">
              <icons.download />
            </div>
          </Button>
          <Button
            className="app-c-btn app-c-btn--secondary"
            elementAttr={{ 'data-testid': 'addToList' }}
            onClick={goBack}
          >
            {t('labels.back')}
          </Button>
        </div>
      </div>
      <Grid gridConfig={gridConfig} />
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <Button
                className="app-c-btn app-c-btn--secondary "
                aria-label="Upload"
                onClick={exportRolesGrid}
              >
                {t('labels.download')}
              </Button>
              <Button
                className="app-c-btn app-c-btn--secondary"
                elementAttr={{ 'data-testid': 'addToList' }}
                onClick={goBack}
              >
                {t('labels.back')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
