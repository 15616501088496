import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  IRuleFilter,
  IRuleFilterKey,
  IRuleList,
} from '../../../../types/vessel-info-rules';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { Accordion } from 'devextreme-react/accordion';
import RulesAccordionHeader from '../rules-accordion-header/RulesAccordionHeader';
import RuleDetails from '../rule-details/RuleDetails';
import RuleFilter from '../rule-filter/RuleFilter';
import { useTranslation } from 'react-i18next';
import { IDialogConfig } from '../../../../types/dialog';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import toast from '../../../../utils/toast';
import { getEmployeeId } from '../../../../utils/jwt-decode';

function RulesTab(
  props: {
    isRuleExecutable?: boolean;
    vetRequestId?: string;
    imoNumber?: string;
    getMailCount?: any;
    vetData?: any;
    setShowRunOverride?: any;
    showRunButton?: boolean;
  },
  ref: any
) {
  const { t } = useTranslation();
  const {
    vetRequestId,
    imoNumber,
    vetData,
    setShowRunOverride,
    showRunButton,
  } = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const employeeId = Number(getEmployeeId());

  const confirmRuleExecuteDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.ruleExecuteConfirm'),
    handleSubmit: async () => {
      setConfirmRuleExecute(false);
      await getRuleList();
      toast.success({ title: t('toast.ruleExecutedSuccessfully') });
    },
    handleClose: () => {
      setConfirmRuleExecute(false);
    },
  };

  const defaultFilterValues: IRuleFilter = {
    all: true,
    isClientRule: false,
    isMarsRule: false,
    isRulePassed: false,
    isRuleFailed: false,
    isRulePassedByOverride: false,
    isRuleFailedByOverride: false,
  };

  const { setIsLoading } = useLoaderContext();

  const [ruleList, setRuleList] = useState<IRuleList[]>([]);

  const [confirmRuleExecute, setConfirmRuleExecute] = useState(false);

  const [executeRuleConfirmConfig, setExecuteRuleConfirmConfig] =
    useState<IDialogConfig>(confirmRuleExecuteDialogConfig);

  const [ruleFilter, setRuleFilter] =
    useState<IRuleFilter>(defaultFilterValues);

  useImperativeHandle(ref, () => ({
    showConfirmRuleExecute(runOverrideRules?: boolean) {
      if (runOverrideRules) {
        setExecuteRuleConfirmConfig({
          ...confirmRuleExecuteDialogConfig,
          content: t('toast.overriddenRuleExectionConfirm'),
          handleSubmit: async () => {
            setConfirmRuleExecute(false);
            await getRuleList(false, runOverrideRules);
            toast.success({ title: t('toast.ruleExecutedSuccessfully') });
          },
        });
      } else {
        setExecuteRuleConfirmConfig(confirmRuleExecuteDialogConfig);
      }
      setConfirmRuleExecute(true);
    },
  }));

  const getRuleList = async (
    isFromRfi?: boolean,
    runOverrideRules?: boolean,
    ruleId?: number
  ) => {
    if (props.isRuleExecutable && !isFromRfi) {
      await api
        .put(
          {
            url: apiConfig.ruleExecute + vetRequestId,
            params: {
              isExecuteOverridenRules: runOverrideRules,
              ruleId: ruleId,
            },
          },
          setIsLoading
        )
        .then();
    }
    await api
      .get({ url: apiConfig.vettingRuleList + vetRequestId }, setIsLoading)
      .then((res: IRuleList[]) => {
        res.map((item) => {
          item.isRuleFailed = !item.isRulePassed;
          item.all = true;
        });
        setRuleList(res);
        const overridedRule = res.find(
          (item) => item.isRulePassedByOverride || item.isRuleFailedByOverride
        );
        if (overridedRule && setShowRunOverride) {
          setShowRunOverride(true);
        } else {
          setShowRunOverride(false);
        }
      });
  };

  useEffect(() => {
    if (vetRequestId) {
      getRuleList();
    }
  }, [vetRequestId]);

  const isReviewer = useMemo(() => {
    return employeeId === vetData?.reviewerId;
  }, [employeeId, vetData]);

  const accordionItems = useMemo(() => {
    if (ruleFilter.all) {
      return ruleList;
    } else {
      ruleList.map((rule) => {
        let isVisible = true;
        let isAnyFilter = false;
        Object.keys(ruleFilter).map((key: string) => {
          const filterKey = key as IRuleFilterKey;
          if (
            filterKey !== 'all' &&
            ruleFilter[filterKey] &&
            ruleFilter[filterKey] !== rule[filterKey]
          ) {
            isVisible = false;
          }
          if (ruleFilter[filterKey]) {
            isAnyFilter = true;
          }
        });
        rule.isVisible = isVisible && isAnyFilter;
      });

      return ruleList.filter((rule) => rule.isVisible);
    }
  }, [ruleList, ruleFilter]);

  const onFilterChanged = useCallback((e: any, filterKey: IRuleFilterKey) => {
    if (filterKey === 'all' && e) {
      setRuleFilter(defaultFilterValues);
    } else if (filterKey !== 'all' && ruleFilter.all) {
      setRuleFilter((previousValue) => {
        return { ...previousValue, [filterKey]: e, all: false };
      });
    } else {
      setRuleFilter((previousValue) => {
        return { ...previousValue, [filterKey]: e };
      });
    }
  }, []);

  const selectionChanged = (e: any) => {
    let newItems: any = [...selectedItems];
    e.removedItems.map((item: any) => {
      const index = newItems.indexOf(item);
      if (index >= 0) {
        newItems.splice(index, 1);
      }
    });
    if (e.addedItems.length) {
      newItems = [...newItems, ...e.addedItems];
    }
    setSelectedItems(newItems);
  };

  return (
    <div data-testid="rulesTab">
      <RuleFilter ruleFilter={ruleFilter} onFilterChanged={onFilterChanged} />
      <div className="m-l-accordion__main-section">
        <div className="m-c-accordion m-l-accordion-rules-tab">
          <Accordion
            collapsible={true}
            items={accordionItems}
            itemTitleRender={RulesAccordionHeader}
            selectedItems={selectedItems}
            onSelectionChanged={selectionChanged}
            itemRender={(data: IRuleList) => {
              return (
                <RuleDetails
                  isReviewer={isReviewer}
                  vetRequestId={Number(vetRequestId)}
                  selectedRule={data}
                  imoNumber={imoNumber}
                  getRuleList={getRuleList}
                  getMailCount={props.getMailCount}
                  showRunButton={showRunButton}
                />
              );
            }}
          />
        </div>
      </div>
      <ConfirmDialog
        dialogConfig={executeRuleConfirmConfig}
        isOpen={confirmRuleExecute}
      />
    </div>
  );
}

export default forwardRef(RulesTab);
