import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'devextreme-react/button';
import UserForm from '../../../components/requestor-company/users/user-form/UserForm';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { IOptionvalue } from '../../../types/master-data';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { IDialogConfig } from '../../../types/dialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { IUser, IUserForm } from '../../../types/user';
import toast from '../../../utils/toast';
import ConfirmDialog from '../../../components/common/confirm/ConfirmDialog';
import { ICompany } from '../../../types/requestor-company';
import { BusinessUnitStatus, EmployeeStatus } from '../../../enums/status-enum';
import UserApprove from '../../../components/requestor-company/users/user-approve/UserApprove';
import { ICompanyRolesList } from '../../../types/company-roles';
import { CompanyType } from '../../../enums/company-type-enum';
import { Restricted } from '../../../contexts/PermissionContext';
import { DimensionUnit } from '../../../enums/dimension-unit-enum';

export default function EditUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsLoading } = useLoaderContext();
  const [userId, setUserId] = useState<string>();
  const [companyId, setCompanyId] = useState<string>();
  const [isFromMenu, setIsFromMenu] = useState<string>();
  const [employeeStatusList, setEmployeeStatusList] = useState<IOptionvalue[]>(
    []
  );
  const [personNameTitles, setPersonNameTitles] = useState<IOptionvalue[]>([]);
  const [userDetails, setUserDetails] = useState<IUser>();
  const [companyBussinessUnits, setCompanyBussinessUnits] = useState<any>();
  const [companyRoles, setCompanyRoles] = useState<ICompanyRolesList[]>();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmBackDialog, setConfirmBackDialog] = useState(false);
  const [companyDetails, setCompanyDetails] = useState<ICompany>();
  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      saveUser();
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setConfirmBackDialog(false);
      navigateToCompany();
    },
    handleClose: () => {
      setConfirmBackDialog(false);
    },
  };

  const defaultValues: any = {
    employeeName: '',
    employeeEmail: '',
    companyId: null,
    personNameTitle: null,
    employeeCode: '',
    callingCode: '',
    phoneNumber: null,
    employeeStatus: '1',
    isReviewer: false,
    unitOfLength: DimensionUnit.Meter,
    employeeBUs: [
      {
        bu: null,
        isDefault: true,
        userRoles: [],
      },
    ],
  };

  const userForm = useForm({
    defaultValues: defaultValues,
  });

  const { isDirty } = userForm.formState;

  const handleSaveClick = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      setIsConfirmModalOpen(true);
    }
    return;
  };

  const getUserDetails = async () => {
    await api.get({ url: apiConfig.employees + userId }).then((data: IUser) => {
      setUserDetails(data);
    });
  };

  const filterStatusList = useMemo(() => {
    return userDetails &&
      (userDetails.employeeStatus === EmployeeStatus.draft ||
        userDetails.employeeStatus === EmployeeStatus.waitingForApproval)
      ? true
      : false;
  }, [userDetails]);

  const getCompanyDetails = async (setIsLoading?: any) => {
    await api
      .get({ url: apiConfig.company + companyId }, setIsLoading)
      .then((data: ICompany) => {
        setCompanyDetails(data);
      });
  };

  const getEmployeeStatus = async () => {
    await api.get({ url: apiConfig.employeeStatus }).then((data) => {
      data.map((item: IOptionvalue) => {
        item.visible =
          item.optionValueCode === EmployeeStatus.draft ||
          item.optionValueCode === EmployeeStatus.waitingForApproval
            ? filterStatusList
            : true;
      });
      setEmployeeStatusList(data);
    });
  };

  const getTitleName = async () => {
    await api
      .get({ url: apiConfig.personNameTitle })
      .then((data: IOptionvalue[]) => {
        setPersonNameTitles(data);
      });
  };

  const getCompanyBUs = async () => {
    await api
      .get({ url: apiConfig.companyBusinessUnits + companyId })
      .then((data: any) => {
        const buData = data.data;
        buData.map((item: any) => {
          item.visible = item.buStatus !== BusinessUnitStatus.Inactive;
        });
        setCompanyBussinessUnits(buData);
      });
  };

  const getCompanyRoles = async () => {
    await api
      .get({ url: apiConfig.getCompanyRoles + companyId })
      .then((data: any) => {
        setCompanyRoles(data);
      });
  };

  useEffect(() => {
    if (location.state) {
      const { companyId, userId, isFromMenu }: any = location.state;
      setCompanyId(companyId);
      setUserId(userId);
      setIsFromMenu(isFromMenu);
    }
  }, [location.state]);

  useEffect(() => {
    if (companyId) {
      getEmployeeStatus();
      getTitleName();
      getCompanyBUs();
      getCompanyRoles();
    }
  }, [companyId]);

  useEffect(() => {
    if (userId) {
      getUserDetails();
    }
    if (companyId) {
      getCompanyDetails(setIsLoading);
    }
  }, [userId, companyId]);

  useEffect(() => {
    getEmployeeStatus();
    const defaultTitle = personNameTitles.find(
      (title) => title.isDefault && title.visible
    );
    if (userDetails) {
      userForm.reset();
      const employeeBUArray = constructFieldArray(userDetails.employeeBUs);
      userForm.reset(
        {
          employeeName: userDetails.employeeName,
          employeeCode: userDetails.employeeCode,
          personNameTitle: userDetails.personNameTitle
            ? userDetails.personNameTitle
            : defaultTitle
            ? defaultTitle.optionValueCode
            : null,
          employeeEmail: userDetails.employeeEmail,
          callingCode: userDetails.callingCode,
          phoneNumber: userDetails.phoneNumber
            ? parseInt(userDetails.phoneNumber)
            : null,
          employeeStatus: userDetails.employeeStatus,
          isReviewer: userDetails.isReviewer,
          employeeBUs: employeeBUArray,
          unitOfLength: userDetails.unitOfLength,
        },
        { keepDirty: true }
      );
    } else {
      userForm.resetField('personNameTitle', {
        defaultValue: defaultTitle ? defaultTitle?.optionValueCode : null,
      });
    }
  }, [personNameTitles, userDetails]);

  const constructFieldArray = (employeeBUs: any) => {
    const fieldArray: any = [];
    if (employeeBUs.length > 0) {
      employeeBUs.forEach((item: any) => {
        const fieldArrayObj: any = {
          bu: '',
          isDefault: false,
          userRoles: [],
        };
        fieldArrayObj.bu = item.buId;
        fieldArrayObj.isDefault = item.isDefault;
        if (item.employeeBURoles.length > 0) {
          item.employeeBURoles.forEach((role: any) => {
            fieldArrayObj.userRoles.push(role.roleId);
          });
        }
        fieldArray.push(fieldArrayObj);
      });
    }
    return fieldArray;
  };

  const handleBackClick = () => {
    isDirty ? setConfirmBackDialog(true) : navigateToCompany();
  };

  const getBUAndUserRoles = (userFormValue: any) => {
    const employeeBUs: any = [];
    const fieldArray = userFormValue.employeeBUs;
    if (fieldArray.length > 0) {
      fieldArray.forEach((field: any) => {
        if (field.bu != null) {
          const employeeBUObject: any = {
            employeeBUId: 0,
            employeeId: 0,
            buId: 0,
            employeeBURoles: [],
          };
          let employeeBU: any = {};
          if (userId) {
            userDetails?.employeeBUs?.filter((bu: any) => {
              if (bu.buId == field.bu) {
                employeeBU = bu;
              }
            });
          }
          employeeBUObject.employeeBUId = userId ? employeeBU.employeeBUId : 0;
          employeeBUObject.employeeId = userId ? employeeBU.employeeId : 0;
          employeeBUObject.buId = field.bu;
          employeeBUObject.isDefault = field.isDefault;
          if (field.userRoles.length > 0) {
            field.userRoles.forEach((userRole: any) => {
              const userRoleObject: any = {};
              let employeeBURole: any = {};
              const companyRole = companyRoles?.find(
                (item) => item.id == userRole
              );
              if (userId) {
                employeeBU.employeeBURoles?.filter((role: any) => {
                  if (role.roleId == userRole) {
                    employeeBURole = role;
                  }
                });
              }
              userRoleObject.employeeBURoleId = userId
                ? employeeBURole?.employeeBURoleId
                : 0;
              userRoleObject.employeeBUId = userId
                ? employeeBURole?.employeeBUId
                : 0;
              userRoleObject.roleId = userRole;
              userRoleObject.companyRoleId = companyRole
                ? companyRole.companyRoleId
                : 0;
              employeeBUObject.employeeBURoles.push(userRoleObject);
            });
          }
          employeeBUs.push(employeeBUObject);
        }
      });
    }
    return employeeBUs;
  };

  const contructUserJson = (userFormValue: any) => {
    return {
      employeeId: userId ? userId : 0,
      companyId: Number(companyId),
      personNameTitle: userFormValue.personNameTitle,
      employeeName: userFormValue.employeeName,
      employeeEmail: userFormValue.employeeEmail,
      employeeCode: userFormValue.employeeCode,
      callingCode: userFormValue.callingCode,
      phoneNumber: userFormValue.phoneNumber?.toString(),
      employeeStatus: userFormValue.employeeStatus,
      employeeBUs: getBUAndUserRoles(userFormValue),
      isReviewer:
        companyDetails?.companyType !== CompanyType.RequestorCompany
          ? userFormValue.isReviewer
          : undefined,
      unitOfLength: userFormValue.unitOfLength,
    };
  };

  const saveUser = () => {
    setIsConfirmModalOpen(false);
    if (
      userForm.getValues().employeeBUs.length == 0 ||
      userForm.getValues().employeeBUs[0]?.userRoles.length == 0
    ) {
      toast.error({
        title: 'Error',
        message: t('errors.pleaseAssignBUAndUserRole'),
      });
      return;
    }
    if (userForm.getValues().employeeBUs.length > 0) {
      const employeeWithDefaultBURoles = userForm
        .getValues()
        .employeeBUs.filter((item: any) => item.isDefault === true);
      if (employeeWithDefaultBURoles.length == 0) {
        toast.error({
          title: 'Error',
          message: t('errors.defaultBURoleNeeded'),
        });
        return;
      }
    }
    const userFormValue: IUserForm = userForm.getValues();
    const userJson: IUser = contructUserJson(userFormValue);
    if (!userId) {
      api
        .post(
          {
            url: apiConfig.employees,
            data: userJson,
          },
          setIsLoading
        )
        .then((res) => {
          navigate('/company/user/edit', {
            state: {
              companyId: res.companyId,
              userId: res.employeeId,
              isFromMenu: isFromMenu,
            },
          });
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
        });
    } else {
      api
        .put({ url: apiConfig.employees, data: userJson }, setIsLoading)
        .then(() => {
          toast.custom({
            title: t('toast.updatedSuccessfully'),
          });
          getUserDetails();
        });
    }
  };

  const navigateToCompany = () => {
    isFromMenu === 'isFromMenu'
      ? navigate('/company-details', {
          state: { fromPage: 'usersGrid', companyId: companyId },
        })
      : navigate('/company/edit', {
          state: { fromPage: 'usersGrid', companyId: companyId },
        });
  };

  return (
    <div className="inner-wrapper">
      <ul aria-label="breadcrumb" className="m-c-bredcrumb">
        <li>
          <a>{t('headers.company')}</a>
        </li>
        <li>
          <a className="active">{t('headers.user')}</a>
        </li>
      </ul>
      <div className="m-l-inner-page-header">
        <div className="m-l-inner-page-header-left-block">
          <div className="m-l-page-main-heading">
            {companyDetails?.companyName}
          </div>
        </div>
      </div>
      <div className="m-l-inner-page-body">
        <div className="m-l-form-panel-block">
          <div className="m-l-form-panel-header-block">
            <div className="m-l-form-panel-header-left-block">
              {!userId && !userDetails && (
                <div className="m-l-page-small-heading">
                  {t('headers.createNewUser')}
                </div>
              )}
              {userId && userDetails && (
                <div className="m-l-page-small-heading">
                  {userDetails.employeeName}
                </div>
              )}
            </div>
            <div className="m-l-form-panel-header-right-block">
              <Button
                elementAttr={{ 'data-testid': 'backButton' }}
                onClick={handleBackClick}
                className="app-c-btn app-c-btn--secondary"
              >
                {t('buttons.back')}
              </Button>
              {userId &&
                userDetails &&
                userDetails.employeeStatus == EmployeeStatus.draft && (
                  <UserApprove
                    isFromUserList={false}
                    userApproveList={[]}
                    userSendForApprovalList={[userDetails.employeeId]}
                    getUserDetails={getUserDetails}
                    apiUrl={apiConfig.employeeSendForApprove}
                  />
                )}
              {userId &&
                userDetails &&
                userDetails.employeeStatus ==
                  EmployeeStatus.waitingForApproval && (
                  <UserApprove
                    isDirty={isDirty}
                    isFromUserList={false}
                    userApproveList={[userDetails.employeeId]}
                    userSendForApprovalList={[]}
                    getUserDetails={getUserDetails}
                    apiUrl={apiConfig.employeeApprove}
                  />
                )}
              <Restricted permission="CompanyUsers.Add">
                {!userId && (
                  <Button
                    elementAttr={{ 'data-testid': 'saveButton' }}
                    onClick={handleSaveClick}
                    className="app-c-btn app-c-btn--primary min-btn-width"
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Restricted>
              <Restricted permission="CompanyUsers.Edit">
                {userId && (
                  <Button
                    elementAttr={{ 'data-testid': 'saveButton' }}
                    onClick={handleSaveClick}
                    className="app-c-btn app-c-btn--primary min-btn-width"
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Restricted>
            </div>
          </div>
          {/* <div className="m-l-form-panel-body-block">
            <div className="row m-l-custom-eight-space-row"> */}
          <FormProvider {...userForm}>
            <UserForm
              employeeStatusList={employeeStatusList}
              personNameTitles={personNameTitles}
              companyBusinessUnits={companyBussinessUnits}
              companyRoles={companyRoles}
              userDetails={userDetails}
              companyDetails={companyDetails}
            />
          </FormProvider>
          {/* </div>
          </div> */}
        </div>
      </div>
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <Button
                elementAttr={{ 'data-testid': 'backButton' }}
                onClick={handleBackClick}
                className="app-c-btn app-c-btn--secondary"
              >
                {t('buttons.back')}
              </Button>
              {userId &&
                userDetails &&
                userDetails.employeeStatus == EmployeeStatus.draft && (
                  <UserApprove
                    isFromUserList={false}
                    userApproveList={[]}
                    userSendForApprovalList={[userDetails.employeeId]}
                    getUserDetails={getUserDetails}
                    apiUrl={apiConfig.employeeSendForApprove}
                  />
                )}
              {userId &&
                userDetails &&
                userDetails.employeeStatus ==
                  EmployeeStatus.waitingForApproval && (
                  <UserApprove
                    isDirty={isDirty}
                    isFromUserList={false}
                    userApproveList={[userDetails.employeeId]}
                    userSendForApprovalList={[]}
                    getUserDetails={getUserDetails}
                    apiUrl={apiConfig.employeeApprove}
                  />
                )}
              <Restricted permission="CompanyUsers.Add">
                {!userId && (
                  <Button
                    elementAttr={{ 'data-testid': 'saveButton' }}
                    onClick={handleSaveClick}
                    className="app-c-btn app-c-btn--primary min-btn-width"
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Restricted>
              <Restricted permission="CompanyUsers.Edit">
                {userId && (
                  <Button
                    elementAttr={{ 'data-testid': 'saveButton' }}
                    onClick={handleSaveClick}
                    className="app-c-btn app-c-btn--primary min-btn-width"
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Restricted>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={isConfirmModalOpen}
      />
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={confirmBackDialog}
      />
    </div>
  );
}
