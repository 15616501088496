import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IGridConfig } from '../../../../types/grid';
import Grid from '../../../common/grid/Grid';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { IHistoryDetails } from '../../../../types/vessel-management-tab';
import { getAPIRoute } from '../../../../utils/api-route';

export default function ManagementHistoryGrid(props: {
  imoNumber?: string;
  vetRequestId: number;
  ruleId: number;
}) {
  const { imoNumber, vetRequestId, ruleId } = props;

  const { t } = useTranslation();

  const [managementHistory, setManagementHistory] = useState<IHistoryDetails[]>(
    []
  );

  useEffect(() => {
    getManagementHistory();
  }, []);

  const getManagementHistory = async () => {
    await api
      .get({
        url: getAPIRoute('rulesManagementHistoryList', [vetRequestId, ruleId]),
      })
      .then((res) => {
        setManagementHistory(res);
      });
  };

  const gridConfig: IGridConfig = {
    class:
      'm-c-grid full-border-checkbox-grid m-l-incident-grid m-c-auto-scroll-grid',
    testId: 'managementRolesGrid',
    dataSource: managementHistory,
    defaultColumns: [
      {
        caption: t('labels.fromDate'),
        dataField: 'date',
        dataType: 'date',
        format: 'dd MMM yyyy',
        minWidth: 150,
      },
      {
        caption: t('labels.groupOwner'),
        dataField: 'groupOwner',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.shipManager'),
        dataField: 'shipManager',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.technicalManager'),
        dataField: 'technicalManager',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.docCompany'),
        dataField: 'docCompany',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.operator'),
        dataField: 'operator',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.registeredOwner'),
        dataField: 'registeredOwner',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.barboatOwner'),
        dataField: 'bareboatOwner',
        dataType: 'string',
        minWidth: 150,
      },
    ],
    showHeader: false,
    showExport: false,
    showAddButton: false,
    hideFilterRow: true,
    excelFileName: 'VesselManagementRoles.xlsx',
  };

  return (
    <>
      <div className="row m-l-cap-wrapper mrbtm-space">
        <div className="col-xl-6 col-lg-6 co-md-6 col-sm-6 col-12 m-l-cap-left-part">
          <div className="m-l-page-small-sub-heading">
            {t('Management History')}
          </div>
        </div>
      </div>
      <div className="row m-l-cap-rules-wrapper">
        <Grid gridConfig={gridConfig} />
      </div>
    </>
  );
}
