import PieChart, { Export, Legend, Series } from 'devextreme-react/pie-chart';
import React from 'react';

export default function DoughnutChart(props: {
  dataSource: any;
  pieChartConfig: any;
  chartRef?: any;
}) {
  const { dataSource, pieChartConfig, chartRef } = props;

  const customizeItems = (data: any) => {
    data.map((item: any) => {
      item.text = item.points[0].data.label + ' - ' + item.points[0].data.count;
    });
    return data;
  };

  const labelConfig = {
    visible: true,
    format: 'fixedPoint',
    customizeText(data: any) {
      return `${data.argumentText}: ${data.valueText}%`;
    },
    connector: {
      visible: true,
      width: 1,
    },
  };

  const tooltipConfig = {
    enabled: true,
    format: 'fixedPoint',
    customizeTooltip(data: any) {
      return { text: `${data.argumentText}<br>${data.valueText}%` };
    },
  };

  return (
    <PieChart
      id="pie"
      ref={chartRef}
      type={pieChartConfig.type}
      dataSource={dataSource}
      onPointClick={(e: any) => e.target.select()}
      tooltip={tooltipConfig}
    >
      <Series
        argumentField={pieChartConfig.argumentField}
        valueField={pieChartConfig.valueField}
      ></Series>
      <Export enabled={false} />
      <Legend
        margin={0}
        horizontalAlignment="left"
        verticalAlignment="top"
        customizeItems={customizeItems}
      />
    </PieChart>
  );
}
