import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDataStore } from '../../../hooks/useDataStore';
import { IGridConfig } from '../../../types/grid';
import Grid from '../../common/grid/Grid';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { usePermission } from '../../../contexts/PermissionContext';
import DataGrid from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { useDimensionsUnit } from '../../../hooks/useDimensionsUnit';
import { useDateFormat } from '../../../hooks/useDateFormat';

export default function BerthGrid(props: {
  query?: any;
  setIsAddList?: any;
  setIsFromAddBerth?: any;
  isFromAddBerth?: any;
  params?: any;
  setBerthEntityNames?: any;
  isFromTerminal?: boolean;
  setIsAddListBerth?: any;
  isFromPort?: any;
  portId?: any;
  portName?: any;
  portCode?: any;
  isExport?: any;
  setIsExport?: any;
  clearSelection?: any;
  setClearSelection?: any;
}) {
  const {
    query,
    setIsAddList,
    setIsFromAddBerth,
    isFromAddBerth,
    params,
    setBerthEntityNames,
    isFromTerminal,
    setIsAddListBerth,
    isFromPort,
    portCode,
    portId,
    portName,
    isExport,
    setIsExport,
    clearSelection,
    setClearSelection,
  } = props;
  const { t } = useTranslation();
  const { getDataSource } = useDataStore();
  const navigate = useNavigate();
  const [searchList, setSearchList] = useState<any>();
  const { findFeature } = usePermission();
  const downloadgridRef: any = useRef<any>(null);
  const gridRef = useRef<DataGrid>(null);
  const [gridInstance, setGridInstance] = useState<any>();
  const { dimensionUnitText, dimensionUnit } = useDimensionsUnit();
  const { dateFormat } = useDateFormat();
  const searchBerth = async () => {
    const data = getDataSource(
      'berthId',
      apiConfig.portSearch + query + '&Search=Berths',
      10
    );
    setSearchList(data);
    if (isFromAddBerth) {
      setIsFromAddBerth(false);
    }
  };

  useEffect(() => {
    if (query) {
      searchBerth();
    }
  }, [query, isFromAddBerth]);

  useEffect(() => {
    if (isExport) {
      downloadgridRef.current.exportBerthSearchGrid();
      setIsExport(false);
    }
  }, [isExport]);

  const onClickRow = async (e: any) => {
    navigate('/port/terminal/berth/edit', {
      state: {
        berthId: e.data.berthId,
        berthName: e.data.berthName,
        query: query,
        params: params,
        terminalCode: e.data.terminalCode,
        terminalName: e.data.terminalName,
        terminalId: e.data.terminalId,
        isFromTerminal: isFromTerminal,
        isFromPort: isFromPort ? isFromPort : false,
        portCode: portCode ? portCode : null,
        portId: portId ? portId : null,
        portName: portName ? portName : null,
      },
    });
  };

  const onRowClickCheckbox = async (e: any) => {
    setBerthEntityNames(e.selectedRowsData);
    if (e.selectedRowsData.length > 0) {
      setIsAddListBerth ? setIsAddListBerth(true) : setIsAddList(true);
    } else {
      setIsAddListBerth ? setIsAddListBerth(false) : setIsAddList(false);
    }
  };

  const onGridInitialized = (e: any) => {
    setGridInstance(e.component);
  };

  useImperativeHandle(downloadgridRef, () => ({
    exportBerthSearchGrid,
  }));

  const exportBerthSearchGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: gridInstance,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Berths.xlsx'
        );
      });
    });
  };

  const onDataErrorOccurred = () => {
    setSearchList([]);
  };

  useEffect(() => {
    if (clearSelection) {
      gridRef.current?.instance.clearSelection();
      setClearSelection(false);
    }
  }, [clearSelection]);

  useEffect(() => {
    if (dimensionUnit) {
      gridRef.current?.instance.refresh();
      gridRef.current?.instance.columnOption(
        'maximumLengthOverall',
        'caption',
        t('labels.maximumLengthOverall') + dimensionUnitText()
      );
      gridRef.current?.instance.columnOption(
        'minimumLengthOverall',
        'caption',
        t('labels.minimumLengthOverall') + dimensionUnitText()
      );
      gridRef.current?.instance.columnOption(
        'maximumBeam',
        'caption',
        t('labels.maximumBeam') + dimensionUnitText()
      );
      // gridRef.current?.instance.columnOption(
      //   'maximumSternCentreManifoldDistance',
      //   'caption',
      //   t('labels.maximumSternCentreManifoldDistance') + dimensionUnitText()
      // );
      // gridRef.current?.instance.columnOption(
      //   'minimumPMB',
      //   'caption',
      //   t('labels.minimumPMB') + dimensionUnitText()
      // );
      gridRef.current?.instance.columnOption(
        'pblForwardArrival',
        'caption',
        t('labels.pblForwardArrival') + dimensionUnitText()
      );
      gridRef.current?.instance.columnOption(
        'pblAftArrival',
        'caption',
        t('labels.pblArrival') + dimensionUnitText()
      );
      // gridRef.current?.instance.columnOption(
      //   'maximumBowCentreManifoldDistance',
      //   'caption',
      //   t('labels.maximumBowCentreManifoldDistance') + dimensionUnitText()
      // );
      gridRef.current?.instance.columnOption(
        'maximumFreeboard',
        'caption',
        t('labels.maximumFreeboard') + dimensionUnitText()
      );
      gridRef.current?.instance.columnOption(
        'minimumFreeboard',
        'caption',
        t('labels.minimumFreeboard') + dimensionUnitText()
      );
      // gridRef.current?.instance.columnOption(
      //   'maximumHeightManifoldAWL',
      //   'caption',
      //   t('labels.maximumHeightManifoldAWL') + dimensionUnitText()
      // );
      // gridRef.current?.instance.columnOption(
      //   'minimumHeightManifoldAWL',
      //   'caption',
      //   t('labels.minimumHeightManifoldAWL') + dimensionUnitText()
      // );
      // gridRef.current?.instance.columnOption(
      //   'minimumHeightManifoldAboveDeck',
      //   'caption',
      //   t('labels.minimumHeightManifoldAboveDeck') + dimensionUnitText()
      // );
      // gridRef.current?.instance.columnOption(
      //   'maximumDistanceManifoldShipSideRail',
      //   'caption',
      //   t('labels.maximumDistanceManifoldShipSideRail') + dimensionUnitText()
      // );
      // gridRef.current?.instance.columnOption(
      //   'minimumDistancefromManifoldShipSideRail',
      //   'caption',
      //   t('labels.minimumDistancefromManifoldShipSideRail') +
      //     dimensionUnitText()
      // );
      // gridRef.current?.instance.columnOption(
      //   'maximumHeightManifoldAboveDeckDripTray',
      //   'caption',
      //   t('labels.maximumHeightManifoldAboveDeckDripTray') + dimensionUnitText()
      // );
      // gridRef.current?.instance.columnOption(
      //   'minimumHeightManifoldAboveDeckDripTray',
      //   'caption',
      //   t('labels.minimumHeightManifoldAboveDeckDripTray') + dimensionUnitText()
      // );
      // gridRef.current?.instance.columnOption(
      //   'maximumManifoldSpacing',
      //   'caption',
      //   t('labels.maximumManifoldSpacing') + dimensionUnitText()
      // );
      // gridRef.current?.instance.columnOption(
      //   'minimumBowCentreManifoldDistance',
      //   'caption',
      //   t('labels.minimumBowCentreManifoldDistance') + dimensionUnitText()
      // );
      // gridRef.current?.instance.columnOption(
      //   'miminimumSternCentreManifoldDistancenSCM',
      //   'caption',
      //   t('labels.minimumSternCentreManifoldDistance') + dimensionUnitText()
      // );
      gridRef.current?.instance.columnOption(
        'minimumBeam',
        'caption',
        t('labels.minimumBeam') + dimensionUnitText()
      );
      gridRef.current?.instance.columnOption(
        'depthAlongSide',
        'caption',
        t('labels.depthAlongSide') + dimensionUnitText()
      );
      gridRef.current?.instance.columnOption(
        'minimumAlongsideUKC',
        'caption',
        t('labels.minimumAlongsideUKC') + dimensionUnitText()
      );
      gridRef.current?.instance.columnOption(
        'maximumDraughtAlongside',
        'caption',
        t('labels.maximumDraughtAlongside') + dimensionUnitText()
      );
      gridRef.current?.instance.columnOption(
        'maximumAirDraughtAlongside',
        'caption',
        t('labels.maximumAirDraughtAlongside') + dimensionUnitText()
      );
      // gridRef.current?.instance.columnOption(
      //   'maximumDraughtatApproaches',
      //   'caption',
      //   t('labels.maxDraftAtApproaches') + dimensionUnitText()
      // );
      // gridRef.current?.instance.columnOption(
      //   'depthatApproaches',
      //   'caption',
      //   t('labels.depthAtApproaches') + dimensionUnitText()
      // );
      // gridRef.current?.instance.columnOption(
      //   'minimumUKCatApproaches',
      //   'caption',
      //   t('labels.minUkcAtApproaches') + dimensionUnitText()
      // );
      // gridRef.current?.instance.columnOption(
      //   'maximumAirDraughtUnderShoreGear',
      //   'caption',
      //   t('labels.maximumAirDraughtUnderShoreGear') + dimensionUnitText()
      // );
      // gridRef.current?.instance.columnOption(
      //   'minimumPMBAftOfShipManifold',
      //   'caption',
      //   t('labels.minimumPMBAftOfShipManifold') + dimensionUnitText()
      // );
      // gridRef.current?.instance.columnOption(
      //   'maximumManifoldSetback',
      //   'caption',
      //   t('labels.maximumManifoldSetback') + dimensionUnitText()
      // );
      // gridRef.current?.instance.columnOption(
      //   'minimumManifoldSetback',
      //   'caption',
      //   t('labels.minimumManifoldSetback') + dimensionUnitText()
      // );
      // gridRef.current?.instance.columnOption(
      //   'minimumManifoldSpacing',
      //   'caption',
      //   t('labels.minimumManifoldSpacing') + dimensionUnitText()
      // );
      // gridRef.current?.instance.columnOption(
      //   'airDraughtApproaches',
      //   'caption',
      //   t('labels.airDraughtApproaches') + dimensionUnitText()
      // );
    }
  }, [dimensionUnit]);

  const gridConfig: IGridConfig = {
    class: 'm-c-grid c-m-c-grid-in-tab m-c-userlist-grid',
    testId: 'userGrid',
    dataSource: searchList,
    remoteOperations: true,
    dataErrorOccured: onDataErrorOccurred,
    defaultColumns: [
      {
        caption: t('labels.berthName'),
        dataField: 'berthName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.berthCode'),
        dataField: 'berthCode',
        dataType: 'string',
        minWidth: 150,
      },

      {
        caption: t('labels.portName'),
        dataField: 'portName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.portCode'),
        dataField: 'portCode',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.country'),
        dataField: 'country',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.terminalName'),
        dataField: 'terminalName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.terminalCode'),
        dataField: 'terminalCode',
        dataType: 'string',
        minWidth: 150,
      },

      {
        caption: t('labels.berthFieldType'),
        dataField: 'berthFieldType',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.berthStyle'),
        dataField: 'berthStyle',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.berthingSide'),
        dataField: 'berthingSide',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.latitude'),
        dataField: 'latitude',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.longitude'),
        dataField: 'longitude',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.berthType'),
        dataField: 'berthTypes',
        dataType: 'string',
        minWidth: 150,
        cellTemplate: 'cellTemplate',
      },
      {
        caption: t('labels.berthNotes'),
        dataField: 'berthnotes',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.mooringRequirements'),
        dataField: 'mooringRequirements',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.gangwayArrangements'),
        dataField: 'gangwayArrangements',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.connectionFlangeSize'),
        dataField: 'connectionFlangeSize',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.connectorType'),
        dataField: 'connectorTypeDesc',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.lasthydrographicalsurvey'),
        dataField: 'lasthydrographicalsurvey',
        dataType: 'date',
        minWidth: 150,
        format: dateFormat,
      },
      {
        caption: t('labels.status'),
        dataField: 'statusText',
        dataType: 'string',
        minWidth: 150,
      },

      {
        caption: t('labels.maximumDeadweightMT'),
        dataField: 'maximumDeadweight',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.minimumDeadweightMT'),
        dataField: 'minimumDeadweight',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.maximumLengthOverall') + dimensionUnitText(),
        dataField: 'maximumLengthOverall',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.minimumLengthOverall') + dimensionUnitText(),
        dataField: 'minimumLengthOverall',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.maximumBeam') + dimensionUnitText(),
        dataField: 'maximumBeam',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.minimumBeam') + dimensionUnitText(),
        dataField: 'minimumBeam',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.maximumFreeboard') + dimensionUnitText(),
        dataField: 'maximumFreeboard',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.minimumFreeboard') + dimensionUnitText(),
        dataField: 'minimumFreeboard',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.pblForwardArrival') + dimensionUnitText(),
        dataField: 'pblForwardArrival',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.pblArrival') + dimensionUnitText(),
        dataField: 'pblAftArrival',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.depthAlongSide') + dimensionUnitText(),
        dataField: 'depthAlongSide',
        dataType: 'string',
        minWidth: 150,
      },

      {
        caption: t('labels.maximumDisplacementAlongsideMT'),
        dataField: 'maximumDisplacementAlongside',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.maximumDraughtAlongside') + dimensionUnitText(),
        dataField: 'maximumDraughtAlongside',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.minimumAlongsideUKC') + dimensionUnitText(),
        dataField: 'minimumAlongsideUKC',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.maximumAirDraughtAlongside') + dimensionUnitText(),
        dataField: 'maximumAirDraughtAlongside',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.gasCubicCapacityGasCarriersMaximum'),
        dataField: 'gasCubicCapacityGasCarriersMaximum',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.gasCubicCapacityGasCarriersMinimum'),
        dataField: 'gasCubicCapacityGasCarriersMinimum',
        dataType: 'string',
        minWidth: 150,
      },

      // {
      //   caption:
      //     t('labels.maximumSternCentreManifoldDistance') + dimensionUnitText(),
      //   dataField: 'maximumSternCentreManifoldDistance',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption:
      //     t('labels.minimumSternCentreManifoldDistance') + dimensionUnitText(),
      //   dataField: 'minimumSternCentreManifoldDistance',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption: t('labels.hoseDerrickMinimumSWLMT'),
      //   dataField: 'hoseDerrickMinimumSWL',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption: t('labels.minimumPMB') + dimensionUnitText(),
      //   dataField: 'minimumPMB',
      //   dataType: 'string',
      //   minWidth: 150,
      // },

      // {
      //   caption:
      //     t('labels.maximumBowCentreManifoldDistance') + dimensionUnitText(),
      //   dataField: 'maximumBowCentreManifoldDistance',
      //   dataType: 'string',
      //   minWidth: 150,
      // },

      // {
      //   caption: t('labels.maximumHeightManifoldAWL') + dimensionUnitText(),
      //   dataField: 'maximumHeightManifoldAWL',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption:
      //     t('labels.minimumHeightManifoldAboveDeck') + dimensionUnitText(),
      //   dataField: 'minimumHeightManifoldAboveDeck',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption:
      //     t('labels.maximumDistanceManifoldShipSideRail') + dimensionUnitText(),
      //   dataField: 'maximumDistanceManifoldShipSideRail',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption:
      //     t('labels.minimumDistancefromManifoldShipSideRail') +
      //     dimensionUnitText(),
      //   dataField: 'minimumDistancefromManifoldShipSideRail',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption:
      //     t('labels.maximumHeightManifoldAboveDeckDripTray') +
      //     dimensionUnitText(),
      //   dataField: 'maximumHeightManifoldAboveDeckDripTray',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption:
      //     t('labels.minimumHeightManifoldAboveDeckDripTray') +
      //     dimensionUnitText(),
      //   dataField: 'minimumHeightManifoldAboveDeckDripTray',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption: t('labels.maximumManifoldSpacing') + dimensionUnitText(),
      //   dataField: 'maximumManifoldSpacing',
      //   dataType: 'string',
      //   minWidth: 150,
      // },

      // {
      //   caption: t('labels.loadingRate'),
      //   dataField: 'loadingRate',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption:
      //     t('labels.minimumBowCentreManifoldDistance') + dimensionUnitText(),
      //   dataField: 'minimumBowCentreManifoldDistance',
      //   dataType: 'string',
      //   minWidth: 150,
      // },

      // {
      //   caption: t('labels.berthDocument'),
      //   dataField: 'berthDocuments',
      //   dataType: 'string',
      //   minWidth: 150,
      // },

      // {
      //   caption: t('labels.maxDraftAtApproaches') + dimensionUnitText(),
      //   dataField: 'maximumDraughtatApproaches',
      //   dataType: 'string',
      //   minWidth: 150,
      // },

      // {
      //   caption: t('labels.cargoHandled'),
      //   dataField: 'cargoHandled',
      //   dataType: 'string',
      //   minWidth: 150,
      // },

      // {
      //   caption: 'Audit Details',
      //   dataField: 'auditDetails',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption: t('labels.cargoType'),
      //   dataField: 'cargoType',
      //   dataType: 'string',
      //   minWidth: 150,
      // },

      // {
      //   caption: t('labels.depthAtApproaches') + dimensionUnitText(),
      //   dataField: 'depthatApproaches',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption: t('labels.minUkcAtApproaches') + dimensionUnitText(),
      //   dataField: 'minimumUKCatApproaches',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption: t('labels.minUkcValm'),
      //   dataField: 'minimumUKCatValm',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption:
      //     t('labels.maximumAirDraughtUnderShoreGear') + dimensionUnitText(),
      //   dataField: 'maximumAirDraughtUnderShoreGear',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption:
      //     t('labels.minimumPMBForwardOfShipManifold') + dimensionUnitText(),
      //   dataField: 'minimumPMBForwardOfShipManifold',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption: t('labels.minimumPMBAftOfShipManifold') + dimensionUnitText(),
      //   dataField: 'minimumPMBAftOfShipManifold',
      //   dataType: 'string',
      //   minWidth: 150,
      // },

      // {
      //   caption: t('labels.minimumHeightManifoldAWL') + dimensionUnitText(),
      //   dataField: 'minimumHeightManifoldAWL',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption: t('labels.maximumManifoldSetback') + dimensionUnitText(),
      //   dataField: 'maximumManifoldSetback',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption: t('labels.minimumManifoldSetback') + dimensionUnitText(),
      //   dataField: 'minimumManifoldSetback',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption: t('labels.minimumManifoldSpacing') + dimensionUnitText(),
      //   dataField: 'minimumManifoldSpacing',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption: t('labels.shipsHandledShipType'),
      //   dataField: 'shipsHandledShipType',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption: t('labels.shipsHandledShipSizeClassification'),
      //   dataField: 'shipsHandledShipSizeClassification',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
      // {
      //   caption: t('labels.airDraughtApproaches') + dimensionUnitText(),
      //   dataField: 'airDraughtApproaches',
      //   dataType: 'string',
      //   minWidth: 150,
      // },
    ],
    // showSelection: true,
    ref: gridRef,
    showSelection: findFeature('PortManagement.AddBerthToList'),
    selectionChanged(e: any) {
      onRowClickCheckbox(e);
    },
    hidePagination: false,
    rowClick: (e: any) => onClickRow(e),
    initialized: onGridInitialized,
    rowClickPermission: 'PortManagement.EditBerth',
  };

  const cellTemplate = (data: any) => {
    return (
      <div className="m-l-badge-container">
        {data.value[0]}
        {data.value.length > 1 && (
          <span className="badge">{data.value.length - 1}+</span>
        )}
      </div>
    );
  };

  return <Grid gridConfig={gridConfig} cellTemplate={cellTemplate} />;
}
