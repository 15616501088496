import Button from 'devextreme-react/button';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IDialogConfig } from '../../../../../types/dialog';
import { IOptionvalue } from '../../../../../types/master-data';
import { IModalConfig } from '../../../../../types/modal';
import { IPSCInspectionForm } from '../../../../../types/psc-inspection';
import ConfirmDialog from '../../../../common/confirm/ConfirmDialog';
import icons from '../../../../common/icons/icons';
import Modal from '../../../../common/modal/Modal';
import PSCDefectForm from '../psc-defects-form/PSCDefectsForm';
import PSCInspectionForm from '../psc-inspection-form/PSCInspectionForm';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import { getCompanyType } from '../../../../../utils/jwt-decode';
import { useDateFormat } from '../../../../../hooks/useDateFormat';
import toast from '../../../../../utils/toast';
import {
  Restricted,
  usePermission,
} from '../../../../../contexts/PermissionContext';
import { useLoaderContext } from '../../../../../contexts/LoaderContext';
import { isTabDirtyContext } from '../../../../../pages/vessel-info/VesselInformation';
import FileUploaderModal from '../../../../common/file-uploader-modal/FileUploaderModal';
import FileSaver from 'file-saver';
import Tooltip from 'devextreme-react/tooltip';
import { getOPtionValueByType } from '../../../../../services/master-data-service';
import { OptionTypes } from '../../../../../enums/option-type-enum';
import ScrollView from 'devextreme-react/scroll-view';

export default function EditPSCInspection(props: {
  imoNumber?: string;
  isVisible: boolean;
  pscInspectionId: number;
  portDataSource: any;
  getPSCInspections: () => void;
  setIsVisible: (isVisible: boolean) => void;
  isMarsUser: any;
  companyId: any;
  getRuleList?: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const { apiDateFormat } = useDateFormat();
  const companyType = getCompanyType();
  const [pscmou, setAuthority] = useState<IOptionvalue[]>([]);
  const [inspectionType, setInspectionType] = useState<IOptionvalue[]>([]);
  const [defectCode, setDefectCode] = useState<IOptionvalue[]>([]);
  const [riskArea, setRiskArea] = useState<IOptionvalue[]>([]);
  const [subArea, setSubArea] = useState<IOptionvalue[]>([]);
  const [rootCause, setRootCause] = useState<IOptionvalue[]>([]);
  const [actions, setActions] = useState<IOptionvalue[]>([]);
  const [inspectionStatus, setInspectionStatus] = useState<IOptionvalue[]>([]);
  const [disableEdit, setDisableEdit] = useState(false);
  const [showAttachment, setShowAttachment] = useState(false);
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showConfirmUpdate, setShowConfirmUpdate] = useState(false);
  const [showConfirmBack, setShowConfirmBack] = useState(false);
  const [showConfirmAccept, setShowConfirmAccept] = useState(false);
  const [showConfirmIgnore, setShowConfirmIgnore] = useState(false);
  const [isVisibleAttach, setIsVisibleAttach] = useState(false);
  const [isAttachmentDirty, setIsAttachmentDirty] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any>();
  const [stateOfPSCInspectionVal, setStateOfPSCInspectionVal] = useState<
    IOptionvalue[]
  >([]);
  const [attachmentsAdded, setAttachmentsAdded] = useState<any>([]);
  const [isMastersLoaded, setIsMastersLoaded] = useState<boolean>(false);
  const {
    imoNumber,
    isVisible,
    pscInspectionId,
    portDataSource,
    setIsVisible,
    getPSCInspections,
    isMarsUser,
    companyId,
    getRuleList,
  } = props;
  const { setIsLoading } = useLoaderContext();
  const { findFeature } = usePermission();
  const setTabDirtyFlag = useContext(isTabDirtyContext);

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      savePSCInspection();
    },
    handleClose: () => {
      setShowConfirmSave(false);
    },
  };

  const getUploadedFiles = (data: any) => {
    setIsAttachmentDirty(true);
    if (uploadedFiles) {
      setUploadedFiles([...uploadedFiles, ...data]);
    } else {
      setUploadedFiles(data);
    }
  };

  const confirmUpdateDialogConfig: IDialogConfig = {
    id: 'confirmUpdate',
    content: t('toast.savePSCChanges'),
    handleSubmit: () => {
      savePSCInspection();
    },
    handleClose: () => {
      setShowConfirmUpdate(false);
    },
  };

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setShowConfirmBack(false);
      setIsAttachmentDirty(false);
      setIsVisible(false);
      if (setTabDirtyFlag.setState) {
        setTabDirtyFlag.setState(
          setTabDirtyFlag.state == 0
            ? setTabDirtyFlag.state
            : setTabDirtyFlag.state - 1
        );
      }
    },
    handleClose: () => {
      setShowConfirmBack(false);
    },
  };

  const confirmAcceptDialogConfig: IDialogConfig = {
    id: 'confirmAccept',
    content: t('toast.pscInspectionAccept'),
    handleSubmit: () => {
      acceptPSC();
      setShowConfirmAccept(false);
    },
    handleClose: () => {
      setShowConfirmAccept(false);
    },
  };

  const confirmIgnoreDialogConfig: IDialogConfig = {
    id: 'confirmIgnore',
    content: t('toast.pscInspectionIgnore'),
    handleSubmit: () => {
      ignorePSC();
      setShowConfirmIgnore(false);
    },
    handleClose: () => {
      setShowConfirmIgnore(false);
    },
  };

  const inspectionFormDefaultValues: IPSCInspectionForm = {
    pscInspectionId: 0,
    companyId: 0,
    imoNumber: '',
    pscmou: null,
    inspectionDate: null,
    portId: null,
    pscInspectionType: null,
    isDetained: false,
    status: 'Open',
    marsStatus: null,
    comment: '',
    noOfDeficiencies: 0,
    source: '',
    duration: null,
    attachments: null,
    pscDeficiencies: [
      {
        pscDefeciancyId: 0,
        defectCode: null,
        deficiencyDescription: '',
        riskArea: null,
        subArea: null,
        rootCauseCode: null,
        actionCode: null,
      },
    ],
  };

  const pscInspectionForm = useForm({
    defaultValues: inspectionFormDefaultValues,
  });

  const { isDirty } = pscInspectionForm.formState;

  const [pscInspectionDetails, setPscInspectionDetails] =
    useState<IPSCInspectionForm>();

  const popupAttributesPsc = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-psc-modal',
    };
  }, []);

  const showPSCDefects = useMemo(() => {
    if (companyType === 'R') {
      return findFeature('Ship.ViewPSCAdditionalDetails');
    } else {
      if (findFeature('Ship.EditPSC') || findFeature('Ship.AddPSC')) {
        return true;
      } else {
        return findFeature('Ship.ViewPSCAdditionalDetails');
      }
    }
  }, [companyType]);

  const modalConfigPsc: IModalConfig = {
    width: 1262,
    height: !showPSCDefects ? 450 : 605,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  useEffect(() => {
    initialLoad();
  }, []);

  const initialLoad = async () => {
    await Promise.all([
      getDefectCode(),
      getAuthority(),
      getInspectionType(),
      getRiskArea(),
      getSubArea(),
      getRootCause(),
      getActions(),
      getInspectionStatus(),
      getStateOfPSCInspection(),
    ]);
    setIsMastersLoaded(true);
  };

  useEffect(() => {
    if (pscInspectionId) {
      getPscInspection();
    }
  }, [pscInspectionId]);

  useEffect(() => {
    pscInspectionForm.reset();
    if (pscInspectionDetails) {
      if (
        !pscInspectionDetails.pscDeficiencies ||
        pscInspectionDetails.pscDeficiencies.length === 0
      ) {
        pscInspectionDetails.pscDeficiencies =
          inspectionFormDefaultValues.pscDeficiencies;
      }
      pscInspectionForm.reset(pscInspectionDetails, { keepDirty: true });
    }
  }, [defectCode, pscInspectionDetails]);

  const isEditable = () => {
    if (pscInspectionDetails) {
      if (isMarsUser) {
        if (pscInspectionDetails.isMarsOrExternal) {
          setDisableEdit(false);
        } else {
          setDisableEdit(true);
        }
      } else {
        if (pscInspectionDetails.companyId == companyId || isMarsUser) {
          setDisableEdit(false);
        } else {
          setDisableEdit(true);
        }
      }
    }
  };

  useEffect(() => {
    isEditable();
    showAttachmentIcon();
  }, [pscInspectionDetails]);

  const getPscInspection = () => {
    api
      .get({ url: apiConfig.pscInspectionDetails + pscInspectionId })
      .then((res) => {
        setPscInspectionDetails(res);
      });
  };

  useEffect(() => {
    if (pscInspectionDetails?.attachments) {
      setAttachmentsAdded(pscInspectionDetails.attachments);
    }
  }, [pscInspectionDetails]);

  const getAuthority = async () => {
    await api.get({ url: apiConfig.authority }).then((res: IOptionvalue[]) => {
      setAuthority(res);
    });
  };
  const getStateOfPSCInspection = async () => {
    await api
      .get({ url: apiConfig.stateOfPSCInspection })
      .then((res: IOptionvalue[]) => {
        setStateOfPSCInspectionVal(res);
      });
  };

  const getInspectionType = async () => {
    await api
      .get({ url: apiConfig.pscInspectionType })
      .then((res: IOptionvalue[]) => {
        setInspectionType(res);
      });
  };

  const getDefectCode = async () => {
    await getOPtionValueByType(OptionTypes.DefectCode)?.then(
      (res: IOptionvalue[]) => {
        setDefectCode(res);
      }
    );
  };

  const getRiskArea = async () => {
    await getOPtionValueByType(OptionTypes.RiskArea)?.then(
      (res: IOptionvalue[]) => {
        setRiskArea(res);
      }
    );
  };

  const getSubArea = async () => {
    await getOPtionValueByType(OptionTypes.SubArea)?.then(
      (res: IOptionvalue[]) => {
        setSubArea(res);
      }
    );
  };

  const getRootCause = async () => {
    await getOPtionValueByType(OptionTypes.RootCause)?.then(
      (res: IOptionvalue[]) => {
        setRootCause(res);
      }
    );
  };

  const getActions = async () => {
    await getOPtionValueByType(OptionTypes.ActionCode)?.then(
      (res: IOptionvalue[]) => {
        setActions(res);
      }
    );
  };

  const getInspectionStatus = async () => {
    await getOPtionValueByType(OptionTypes.PSCInspectionStatus)?.then(
      (res: IOptionvalue[]) => {
        const status = res ? JSON.parse(JSON.stringify(res)) : [];
        status.map((item: IOptionvalue) => {
          item.visible =
            item.optionValueCode === 'WrongEntry' ? isMarsUser : true;
        });
        setInspectionStatus(status);
      }
    );
  };

  const onClickSave = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      onSavePSCInspection();
    }
  };

  const incidentDateValidation = () => {
    const dateValidation = pscInspectionForm.getValues().inspectionDate
      ? new Date(pscInspectionForm.getValues().inspectionDate) <= new Date()
      : true;
    return dateValidation;
  };

  const onSavePSCInspection = () => {
    if (incidentDateValidation()) {
      pscInspectionId ? setShowConfirmUpdate(true) : setShowConfirmSave(true);
    } else {
      toast.error({
        title: 'Error',
        message: t('errors.incidentDateValidation'),
      });
    }
  };

  const onClickBack = () => {
    isDirty || isAttachmentDirty
      ? setShowConfirmBack(true)
      : setIsVisible(false);
  };

  const savePSCInspection = async () => {
    const pscInspectionFormValues = pscInspectionForm.getValues();
    const deficiencies = pscInspectionFormValues?.pscDeficiencies?.filter(
      (item) =>
        !!item.defectCode ||
        !!item.actionCode ||
        !!item.deficiencyDescription ||
        !!item.riskArea ||
        !!item.rootCauseCode ||
        !!item.subArea
    );
    const data = new FormData();
    data.append(
      'inspectionDate',
      pscInspectionFormValues.inspectionDate
        ? JSON.parse(
            JSON.stringify(
              apiDateFormat(pscInspectionFormValues.inspectionDate)
            )
          )
        : ''
    );
    data.append('imoNumber', imoNumber ? imoNumber : '');
    data.append(
      'pscInspectionId',
      pscInspectionId.toString() ? pscInspectionId.toString() : '0'
    );
    data.append(
      'companyId',
      pscInspectionDetails?.source !== 'External' ? companyId : ''
    );
    data.append(
      'noOfDeficiencies',
      pscInspectionFormValues.noOfDeficiencies
        ? pscInspectionFormValues.noOfDeficiencies
        : deficiencies.length === 0
        ? ''
        : deficiencies.length
    );
    data.append(
      'portId',
      pscInspectionFormValues.portId ? pscInspectionFormValues.portId : ''
    );
    data.append(
      'pscmou',
      pscInspectionFormValues.pscmou ? pscInspectionFormValues.pscmou : ''
    );
    data.append(
      'pscInspectionType',
      pscInspectionFormValues.pscInspectionType
        ? pscInspectionFormValues.pscInspectionType
        : ''
    );
    data.append('isDetained', pscInspectionFormValues.isDetained);
    data.append(
      'duration',
      pscInspectionFormValues.duration ? pscInspectionFormValues.duration : ''
    );
    data.append(
      'comments',
      pscInspectionFormValues.comment ? pscInspectionFormValues.comment : ''
    );
    data.append(
      'status',
      pscInspectionFormValues.status ? pscInspectionFormValues.status : ''
    );
    data.append('pscDeficiencies', JSON.stringify(deficiencies));

    if (uploadedFiles) {
      for (const item in uploadedFiles) {
        data.append('AttachedFiles', uploadedFiles[item]);
      }
    }

    if (pscInspectionId) {
      attachmentsAdded?.forEach((element: any) => {
        if (element?.attachmentId) {
          data.append('AttachmentIds', element?.attachmentId);
        }
      });
      await api
        .put({ url: apiConfig.pscInspection, data: data }, setIsLoading)
        .then((res) => {
          setShowConfirmSave(false);
          if (setTabDirtyFlag.setState) {
            setTabDirtyFlag.setState(
              setTabDirtyFlag.state == 0
                ? setTabDirtyFlag.state
                : setTabDirtyFlag.state - 1
            );
          }
          if (res) {
            toast.success({ title: t('toast.updatedSuccessfully') });
            setIsVisible(false);
            getPSCInspections();
            if (getRuleList) getRuleList();
          }
        })
        .catch(() => setShowConfirmUpdate(false));
    } else {
      await api
        .post(
          {
            url: apiConfig.pscInspection,
            data: data,
          },
          setIsLoading
        )
        .then((res) => {
          setShowConfirmUpdate(false);
          if (setTabDirtyFlag.setState) {
            setTabDirtyFlag.setState(
              setTabDirtyFlag.state == 0
                ? setTabDirtyFlag.state
                : setTabDirtyFlag.state - 1
            );
          }
          if (res) {
            toast.success({ title: t('toast.savedSuccessfully') });
            getPSCInspections();
            setIsVisible(false);
            if (getRuleList) getRuleList();
          }
        })
        .catch(() => setShowConfirmSave(false));
    }
  };

  const acceptPSC = async () => {
    await api
      .put(
        {
          url: apiConfig.pscInspectionAccept + pscInspectionId,
          params: { IsIgnored: false },
        },
        setIsLoading
      )
      .then((res) => {
        if (res) {
          toast.success({ title: t('toast.pscAccepted') });
          getPSCInspections();
          setIsVisible(false);
        }
      });
  };

  const ignorePSC = async () => {
    await api
      .put(
        {
          url: apiConfig.pscInspectionAccept + pscInspectionId,
          params: { IsIgnored: true },
        },
        setIsLoading
      )
      .then((res) => {
        if (res) {
          toast.success({ title: t('toast.pscIgnored') });
          getPSCInspections();
          setIsVisible(false);
        }
      });
  };

  const onAcceptPSC = () => {
    setShowConfirmAccept(true);
  };

  const onIgnorePSC = () => {
    setShowConfirmIgnore(true);
  };

  useEffect(() => {
    if (isDirty && setTabDirtyFlag.setState) {
      setTabDirtyFlag.setState(setTabDirtyFlag.state + 1);
    }
  }, [isDirty]);

  const handleCancelClick = () => {
    setIsVisibleAttach(false);
  };

  const onAddClick = () => {
    setIsVisibleAttach(true);
  };

  const downloadAttachment = (file: any) => {
    api
      .get({
        url: apiConfig.deleteEditAttachment + file.attachmentId,
        responseType: 'blob',
      })
      .then((res) => {
        FileSaver.saveAs(new Blob([res]), file.fileName);
      });
  };

  const removeFile = (index: number) => {
    const file = uploadedFiles?.slice();
    file.splice(index, 1);
    setUploadedFiles(file);
    if (uploadedFiles.length === 1) {
      setIsAttachmentDirty(false);
    }
  };

  const removeFileAdded = (index: number) => {
    const file = attachmentsAdded?.slice();
    file?.splice(index, 1);
    setAttachmentsAdded(file);
    setIsAttachmentDirty(true);
  };

  const showAttachmentIcon = () => {
    if (pscInspectionDetails) {
      if (companyType === 'R') {
        setShowAttachment(false);
      } else if (isMarsUser) {
        if (pscInspectionDetails.isMarsOrExternal) {
          setShowAttachment(true);
        } else {
          setShowAttachment(false);
        }
      } else {
        if (companyType === 'H' && pscInspectionDetails.isMarsOrExternal) {
          setShowAttachment(true);
        } else if (pscInspectionDetails.companyId == companyId) {
          setShowAttachment(true);
        } else {
          setShowAttachment(false);
        }
      }
    }
  };

  const renderContent = (data: any) => {
    return <div className="m-l-sidebar-tooltip">{data}</div>;
  };

  return (
    <Modal modalConfig={modalConfigPsc} wrapperAttr={popupAttributesPsc}>
      <div className="m-l-modal__header">
        {pscInspectionId && (
          <h2 className="modal-title">{t('headers.editPSCInspection')}</h2>
        )}
        {!pscInspectionId && (
          <h2 className="modal-title">{t('headers.addPSCInspection')}</h2>
        )}
        <div className="m-l-modal-close-button-block">
          <Button
            className="app-c-btn app-c-icon-only-btn"
            onClick={onClickBack}
          >
            <icons.IconCross />
          </Button>
        </div>
      </div>
      <div className="m-l-modal__body">
        <ScrollView width="100%" height="100%">
          <div className="m-l-psc-form-panel">
            <FormProvider {...pscInspectionForm}>
              <PSCInspectionForm
                pscInspectionId={pscInspectionId}
                pscmou={pscmou}
                inspectionType={inspectionType}
                port={portDataSource}
                inspectionStatus={inspectionStatus}
                disableEdit={disableEdit}
                isMarsOrExternal={pscInspectionDetails?.isMarsOrExternal}
                marsStatus={pscInspectionDetails?.marsStatus}
                isMarsUser={isMarsUser}
                stateOfPSCInspection={
                  pscInspectionDetails?.stateOfPSCInspection
                }
                stateOfPSCInspectionVal={stateOfPSCInspectionVal}
                isMastersLoaded={isMastersLoaded}
                pscInspectionDetails={pscInspectionDetails}
              />
            </FormProvider>
            {isVisibleAttach && (
              <FileUploaderModal
                isVisible={isVisibleAttach}
                handleCancelClick={handleCancelClick}
                setAttachmentsAdded={setAttachmentsAdded}
                attachmentsAdded={attachmentsAdded}
                getUploadedFiles={getUploadedFiles}
              />
            )}
            {showPSCDefects && (
              <div className="m-l-data-entry-wrapper">
                {/* <hr className="m-l-horizontal-line" /> */}
                <FormProvider {...pscInspectionForm}>
                  <PSCDefectForm
                    defectCode={defectCode}
                    riskArea={riskArea}
                    subArea={subArea}
                    actions={actions}
                    rootCause={rootCause}
                    disableEdit={disableEdit}
                  />
                </FormProvider>
              </div>
            )}
          </div>
        </ScrollView>
      </div>
      <div className="m-l-modal__footer">
        <div className="m-l-footer-left-block">
          {/* <div className="m-l-upld-fl-wrapper m-l-upld__birth-fit"> */}
          {/* <div className="m-l-atch-icon-holder"> */}
          <Restricted permission={'Ship.AddPSCAttachment'}>
            {!pscInspectionId ? (
              <a onClick={onAddClick} className="m-l-email-atch-icon-hldr">
                <icons.Attach />
              </a>
            ) : pscInspectionDetails && showAttachment ? (
              <a onClick={onAddClick} className="m-l-email-atch-icon-hldr">
                <icons.Attach />
              </a>
            ) : null}
          </Restricted>
          <div className="m-l-files-attached-list">
            {attachmentsAdded &&
              attachmentsAdded?.map((file: any, index: number) => {
                return (
                  file?.fileName && (
                    <Restricted permission={'Ship.ViewPSCAttachment'}>
                      <div className="m-l-files-attached-block" key={index}>
                        <div
                          className="m-l-files-attached-list m-l-files-download"
                          key={index}
                          onClick={() => {
                            downloadAttachment(file);
                          }}
                        >
                          <div
                            className="m-l-files-attached-list"
                            id={'name' + index}
                          >
                            <span>{file.fileName}</span>
                          </div>
                          <Tooltip
                            target={'#index'}
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            position="top"
                            contentRender={() => {
                              return (
                                <div className="m-l-tooltip-wrapper">
                                  {file.name}
                                </div>
                              );
                            }}
                          />
                        </div>
                        <Restricted permission={'Ship.DeletePSCAttachment'}>
                          <div
                            className="m-l-files-close-icon"
                            onClick={() => removeFileAdded(index)}
                          >
                            <icons.Close />
                          </div>
                        </Restricted>
                      </div>
                      <Tooltip
                        target={'#name' + index}
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        position="top"
                        contentRender={() => renderContent(file.fileName)}
                      />
                    </Restricted>
                  )
                );
              })}
            {uploadedFiles &&
              uploadedFiles?.map((file: any, index: number) => {
                return (
                  file?.name && (
                    <div className="m-l-files-attached-block" key={index}>
                      <div
                        className="m-l-files-attached-list m-l-files-download"
                        key={index}
                      >
                        <div
                          className="m-l-files-attached-list"
                          id={'uploadName' + index}
                        >
                          <span>{file.name}</span>
                        </div>
                      </div>
                      <div
                        className="m-l-files-close-icon"
                        onClick={() => removeFile(index)}
                      >
                        <icons.Close />
                      </div>
                      <Tooltip
                        target={'#uploadName' + index}
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        position="top"
                        contentRender={() => renderContent(file.name)}
                      />
                    </div>
                  )
                );
              })}
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>
        <Restricted permission={'Ship.AcceptPSC'}>
          {pscInspectionDetails?.stateOfPSCInspection === null &&
            pscInspectionDetails?.isMarsOrExternal === false &&
            companyType === 'M' && (
              <>
                <Button
                  className="app-c-btn app-c-btn--secondary min-btn-width"
                  onClick={onAcceptPSC}
                >
                  {t('buttons.accept')}
                </Button>
                <Restricted permission="Ship.IgnorePsc">
                  <Button
                    className="app-c-btn app-c-btn--secondary min-btn-width"
                    onClick={onIgnorePSC}
                  >
                    {t('buttons.ignore')}
                  </Button>
                </Restricted>
              </>
            )}
        </Restricted>
        <Button
          className="app-c-btn app-c-btn--secondary min-btn-width"
          onClick={onClickBack}
        >
          {t('buttons.cancel')}
        </Button>
        {!pscInspectionId && (
          <Button
            className="app-c-btn app-c-btn--primary min-btn-width"
            onClick={onClickSave}
          >
            {t('buttons.save')}
          </Button>
        )}
        {!(companyType === 'R') && (
          <Restricted permission={'Ship.EditPSC'}>
            {pscInspectionId && (
              <>
                {isMarsUser && pscInspectionDetails?.isMarsOrExternal && (
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    onClick={onClickSave}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
                {!isMarsUser && (
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    onClick={onClickSave}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </>
            )}
          </Restricted>
        )}
      </div>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={showConfirmSave}
      />
      <ConfirmDialog
        dialogConfig={confirmUpdateDialogConfig}
        isOpen={showConfirmUpdate}
      />
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={showConfirmBack}
      />
      <ConfirmDialog
        dialogConfig={confirmAcceptDialogConfig}
        isOpen={showConfirmAccept}
      />
      <ConfirmDialog
        dialogConfig={confirmIgnoreDialogConfig}
        isOpen={showConfirmIgnore}
      />
    </Modal>
  );
}
