import Accordion, { Item } from 'devextreme-react/accordion';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReportOptions from './report-options/ReportOptions';
import Filters from './filters/Filters';
import Report from './report/Report';
import { IFilter } from '../../../types/report';
import { getUserId } from '../../../utils/jwt-decode';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';

export default function ReportAccordion(props: {
  reportHeaderId: any;
  layoutId: any;
  reportCode: any;
  reportFavouriteFilters: any;
  setReportFavouriteFilters: any;
  favoriteId: any;
  reportOptions: any;
  setReportOptions: any;
  reportName: any;
  queryId: any;
  setQueryId: any;
  allFavoritesData: any;
}) {
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const {
    reportHeaderId,
    layoutId,
    reportCode,
    reportFavouriteFilters,
    setReportFavouriteFilters,
    favoriteId,
    reportOptions,
    setReportOptions,
    reportName,
    queryId,
    setQueryId,
    allFavoritesData,
  } = props;
  // const [reportOptions, setReportOptions] = useState<any>([]);
  const [showReport, setShowReport] = useState(false);
  const userId = getUserId();
  const { setIsLoading } = useLoaderContext();
  const [runNow, setRunNow] = useState(0);
  const accordionRef = useRef<any>();

  const accordionItems = useMemo(() => {
    return [
      {
        title: 'Report Options',
      },
      {
        title: 'Filters',
      },
      {
        title: 'Report',
      },
    ];
  }, []);

  useEffect(() => {
    if (reportHeaderId) {
      generateQueryId();
    }
  }, [reportHeaderId, reportFavouriteFilters]);

  const setReportOptFav = () => {
    const selectedFav = allFavoritesData.find(
      (favorite: any) => favorite.reportFavoriteId === favoriteId
    );
    setReportOptions(selectedFav?.reportOptionsObject.sortByName);
  };

  useEffect(() => {
    setReportOptFav();
  }, [favoriteId]);

  const onClickCancelReportOptions = () => {
    setReportOptFav();
  };

  const onClickRun = () => {
    accordionRef.current?.instance.expandItem(2);
    setRunNow(runNow + 1);
    const filterData: IFilter = {
      reportOptions: {
        sortByName: reportOptions,
      },
      reportHeaderId: reportHeaderId,
      layoutId: layoutId ? layoutId : 1,
      filterOptions: {},
      reportFavouriteFilters: reportFavouriteFilters,
      currentUserId: userId,
    };
    api
      .post({ url: apiConfig.filters, data: filterData }, setIsLoading)
      .then((response) => {
        if (response) {
          setQueryId(response);
          setShowReport(true);
        }
      });
  };

  const generateQueryId = () => {
    const filterData: IFilter = {
      reportOptions: {
        sortByName: 'companyname',
      },
      reportHeaderId: reportHeaderId,
      layoutId: layoutId ? layoutId : 1,
      filterOptions: {},
      reportFavouriteFilters: reportFavouriteFilters,
      currentUserId: userId,
    };
    api.post({ url: apiConfig.filters, data: filterData }).then((response) => {
      if (response) {
        setQueryId(response);
      }
    });
  };

  const selectionChanged = (e: any) => {
    let newItems: any = [...selectedItems];
    e.removedItems.map((item: any) => {
      const index = newItems.indexOf(item);
      if (index >= 0) {
        newItems.splice(index, 1);
      }
    });
    if (e.addedItems.length) {
      newItems = [...newItems, ...e.addedItems];
    }
    setSelectedItems(newItems);
  };

  const accordionItemRender = (e: any) => {
    switch (e.title) {
      case accordionItems[0].title: {
        return (
          <ReportOptions
            onClickRun={onClickRun}
            onClickCancel={onClickCancelReportOptions}
            setReportOptions={setReportOptions}
            reportOptions={reportOptions}
            reportCode={reportCode}
          />
        );
      }
      case accordionItems[1].title: {
        return (
          <Filters
            reportHeaderId={reportHeaderId}
            layoutId={layoutId}
            reportFavouriteFilters={reportFavouriteFilters}
            setReportFavouriteFilters={setReportFavouriteFilters}
            favoriteId={favoriteId}
            onClickRun={onClickRun}
            reportCode={reportCode}
          />
        );
      }
      case accordionItems[2].title: {
        return (
          <Report
            showRepot={showReport}
            queryId={queryId}
            layoutId={layoutId}
            reportCode={reportCode}
            reportName={reportName}
            runNow={runNow}
          />
        );
      }
    }
  };

  return (
    <div className="m-l-accordion__main-section">
      <div className="m-c-accordion">
        <Accordion
          collapsible={true}
          multiple={true}
          items={accordionItems}
          itemRender={accordionItemRender}
          selectedItems={selectedItems}
          onSelectionChanged={selectionChanged}
          activeStateEnabled={true}
          ref={accordionRef}
        />
      </div>
    </div>
  );
}
