import Button from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IModalConfig } from '../../../../types/modal';
import icons from '../../../common/icons/icons';
import Modal from '../../../common/modal/Modal';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import {
  IAddNewPortNotes,
  IEditPortNotes,
} from '../../../../types/port-configurations';
import toast from '../../../../utils/toast';
import NotesEditForm from './NotesEditForm';
import { IDialogConfig } from '../../../../types/dialog';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import ValidationGroup from 'devextreme-react/validation-group';

export default function NotesEditModal(props: {
  isVisible: boolean;
  handleCancelClick: any;
  portCode: any;
  getPortNotes: any;
  selectedNote?: any;
}) {
  const { t } = useTranslation();
  const [notesType, setNotesType] = useState<any>([]);
  const [selectedNoteValues, setSelectedNoteValues] = useState<any>([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [showConfirmBack, setShowConfirmBack] = useState(false);
  const portNotesRef = useRef<any>();
  const { handleCancelClick, isVisible, portCode, getPortNotes, selectedNote } =
    props;

  const modalConfig: IModalConfig = {
    width: 450,
    height: 310,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      onValueSubmit();
      setIsConfirmModalOpen(false);
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };

  const getPortNotesDropDown = async () => {
    await api.get({ url: apiConfig.getPortNotes }).then((data: any) => {
      setNotesType(data);
    });
  };

  const getSelectedNoteValue = async () => {
    await api
      .get({ url: apiConfig.portNote + selectedNote })
      .then((data: any) => {
        setSelectedNoteValues(data);
      });
  };

  useEffect(() => {
    getPortNotesDropDown();
  }, []);

  useEffect(() => {
    if (selectedNote) {
      getSelectedNoteValue();
    }
  }, [selectedNote]);

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-edit-name-popup',
    };
  }, []);

  const formDefaultValues = {
    noteType: null,
    notesDescription: null,
  };

  const portNotesForm = useForm({ defaultValues: formDefaultValues });
  const { isDirty } = portNotesForm.formState;

  const { getValues } = portNotesForm;
  let portNotesFormValue = getValues();

  const onCloseClick = () => {
    if (isDirty) {
      setShowConfirmBack(true);
    } else {
      portNotesForm.reset(formDefaultValues);
      setShowConfirmBack(false);
      handleCancelClick();
    }
  };

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setShowConfirmBack(false);
      portNotesForm.reset(formDefaultValues);
      handleCancelClick();
    },
    handleClose: () => {
      setShowConfirmBack(false);
    },
  };

  const onClickSave = () => {
    if (portNotesRef?.current?.instance.validate().isValid) {
      setIsConfirmModalOpen(true);
    }
  };

  const onValueSubmit = async () => {
    portNotesFormValue = portNotesForm.getValues();
    if (selectedNote) {
      const roleJsonEdit: IEditPortNotes = {
        portNoteId: selectedNote,
        portCode: portCode,
        portNotesTypeName: portNotesFormValue.noteType,
        notes: portNotesFormValue.notesDescription,
      };
      api
        .put({ url: apiConfig.portNote, data: roleJsonEdit })
        .then((response) => {
          if (response) {
            toast.success({
              title: t('toast.noteEditSuccess'),
            });
            handleCancelClick();
            getPortNotes(portCode);
          }
        });
    } else {
      const roleJsonEdit: IAddNewPortNotes = {
        portCode: portCode,
        portNotesTypeName: portNotesFormValue.noteType,
        notes: portNotesFormValue.notesDescription,
      };
      api
        .post({ url: apiConfig.portNote, data: roleJsonEdit })
        .then((response) => {
          if (response) {
            toast.success({
              title: t('toast.noteAddedSuccess'),
            });
            handleCancelClick();
            getPortNotes(portCode);
          }
        });
    }
  };

  useEffect(() => {
    portNotesForm.reset();
    if (selectedNoteValues) {
      portNotesForm.reset(
        {
          noteType: selectedNoteValues.portNotesTypeName,
          notesDescription: selectedNoteValues.notes,
        },
        { keepDirty: true }
      );
    }
  }, [selectedNoteValues]);

  return (
    <div className="m-l-model-popup__wrap" data-testid="editName">
      <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
        <div className="m-l-modal__header">
          <h2 className="modal-title">
            {selectedNote ? 'Edit Notes' : 'Add Notes'}{' '}
          </h2>
          <div className="m-l-modal-close-button-block">
            <Button
              className="app-c-btn app-c-icon-only-btn"
              onClick={onCloseClick}
            >
              <icons.IconCross />
            </Button>
          </div>
        </div>
        <div className="m-l-modal__body">
          <ScrollView width="100%" height="100%">
            <div className="m-l-edit-form-panel">
              <div className="row m-l-custom-sixteen-space-row">
                <FormProvider {...portNotesForm}>
                  <ValidationGroup ref={portNotesRef}>
                    <NotesEditForm
                      notesType={notesType}
                      portNotesRef={portNotesRef}
                    />
                  </ValidationGroup>
                </FormProvider>
              </div>
            </div>
          </ScrollView>
        </div>
        <div className="m-l-modal__footer">
          <Button
            className="app-c-btn app-c-btn--secondary min-btn-width"
            elementAttr={{ 'data-testid': 'backButton' }}
            onClick={onCloseClick}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            className="app-c-btn app-c-btn--primary min-btn-width"
            elementAttr={{ 'data-testid': 'saveButton' }}
            onClick={onClickSave}
          >
            {t('buttons.save')}
          </Button>
          <ConfirmDialog
            dialogConfig={confirmSaveDialogConfig}
            isOpen={isConfirmModalOpen}
          />
          <ConfirmDialog
            dialogConfig={confirmBackDialogConfig}
            isOpen={showConfirmBack}
          />
        </div>
      </Modal>
    </div>
  );
}
