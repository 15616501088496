import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import TextArea from 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';
import { Button } from 'devextreme-react/button';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import Modal from '../../../common/modal/Modal';
import { IModalConfig } from '../../../../types/modal';
import ValidationGroup from 'devextreme-react/validation-group';
import Icons from '../../../common/icons/icons';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import toast from '../../../../utils/toast';
import { getAPIRoute } from '../../../../utils/api-route';
import ScrollView from 'devextreme-react/scroll-view';
import { useLoaderContext } from '../../../../contexts/LoaderContext';

export default function CancelVetRequestCommentModal(props: {
  cancelVetRequestModalVisible?: boolean;
  setCancelVetRequestModalVisible?: any;
  vetRequestId?: any;
  getVetRequestDetails: any;
  vetRequestDetails: any;
}) {
  const {
    cancelVetRequestModalVisible,
    setCancelVetRequestModalVisible,
    vetRequestId,
    getVetRequestDetails,
    vetRequestDetails,
  } = props;
  const { t } = useTranslation();
  const [cancelVetRequestComment, setCancelVetRequestComment] =
    useState<any>('');
  const cancelCommentValidation: any = useRef();
  const textBox = useRef<TextArea>(null);
  const modalTitle = 'Cancel Vet';

  const focusTextBox = useCallback(() => {
    textBox.current?.instance.focus();
  }, []);
  const { setIsLoading } = useLoaderContext();
  useEffect(() => {
    focusTextBox();
  }, []);

  const modalConfig: IModalConfig = {
    width: 1262,
    height: 297,
    visible: cancelVetRequestModalVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };
  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-modal-appeal-request m-c-comment-popup',
    };
  }, []);

  const handleCancelClick = () => {
    setCancelVetRequestModalVisible(false);
  };

  const handleSaveClick = (e: any) => {
    if (cancelCommentValidation.current?.instance.validate().isValid) {
      cancelVetRequest();
    } else {
      return false;
    }
  };

  const cancelVetRequest = async () => {
    await api
      .put(
        {
          url: getAPIRoute('vetRequestCancel', [vetRequestId]),
          params: { comment: cancelVetRequestComment },
        },
        setIsLoading
      )
      .then((res: any) => {
        if (res) {
          getVetRequestDetails();
          setCancelVetRequestModalVisible(false);
          toast.custom({
            title: t('toast.successfullyCancelled'),
            message: t('toast.vetRequestHasBeenCancelled', {
              vetRequestId: vetRequestDetails?.vetId,
            }),
          });
        }
      });
  };

  return (
    <>
      <div className="m-l-model-popup__wrap">
        <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
          <div className="m-l-modal__header">
            <h2 className="modal-title">{modalTitle}</h2>
            <div className="m-l-modal-close-button-block">
              <Button
                className="app-c-btn app-c-icon-only-btn"
                onClick={handleCancelClick}
              >
                <Icons.IconCross />
              </Button>
            </div>
          </div>
          <div className="m-l-modal__body">
            <ScrollView width="100%" height="100%">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="m-c-form-group">
                    <label className="m-l-input-label">
                      {t('labels.comment')}
                    </label>
                    <ValidationGroup ref={cancelCommentValidation}>
                      <TextArea
                        className="m-c-text-area m-c-text-area-in-form"
                        placeholder={t(
                          'placeholders.pleaseEnterYourCommentToSubmit'
                        )}
                        inputAttr={{ 'data-testid': 'appealDecisionComment' }}
                        name="cancelComment"
                        ref={textBox}
                        onValueChanged={(e: any) => {
                          setCancelVetRequestComment(e.value);
                        }}
                        onEnterKey={(e: any) => {
                          setCancelVetRequestComment(e.value);
                        }}
                        validationMessageMode="always"
                        value={cancelVetRequestComment}
                      >
                        <Validator>
                          <RequiredRule message={t('errors.required')} />
                        </Validator>
                      </TextArea>
                    </ValidationGroup>
                  </div>
                </div>
              </div>
            </ScrollView>
          </div>
          <div className="m-l-modal__footer">
            <div className="m-l-footer-right-block">
              <Button
                onClick={handleSaveClick}
                className="app-c-btn app-c-btn--primary"
              >
                {t('buttons.submit')}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
