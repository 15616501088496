import Button from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IModalConfig } from '../../../../types/modal';
import toast from '../../../../utils/toast';
import icons from '../../../common/icons/icons';
import Modal from '../../../common/modal/Modal';
import ShipHandledForm from './ShipHandledForm';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import { IOptionvalue } from '../../../../types/master-data';
import ValidationGroup from 'devextreme-react/validation-group';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../../types/dialog';

export default function EditShipHandledModal(props: {
  isVisible: boolean;
  handleCancelClick: any;
  isEdit: boolean;
  berthShiptypeHandleId: any;
  berthCode: any;
  getShipHandled: any;
}) {
  const { t } = useTranslation();
  const { setIsLoading } = useLoaderContext();
  const {
    handleCancelClick,
    isVisible,
    isEdit,
    berthShiptypeHandleId,
    berthCode,
    getShipHandled,
  } = props;
  const validationGroup: any = useRef();
  const [shipTypes, setShipTypes] = useState<IOptionvalue[]>([]);
  const [shipHandledDetails, setShipHandledDetails] = useState<any>();
  const [showConfirmBack, setShowConfirmBack] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const modalConfig: IModalConfig = {
    width: 450,
    height: 310,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-edit-name-popup',
    };
  }, []);

  const formDefaultValues = {
    shipType: '',
    shipSize: '',
  };

  const shipHandledForm = useForm({
    defaultValues: formDefaultValues,
  });

  const { isDirty } = shipHandledForm.formState;
  const onCloseClick = () => {
    if (isDirty) {
      setShowConfirmBack(true);
    } else {
      shipHandledForm.reset(formDefaultValues);
      setShowConfirmBack(false);
      handleCancelClick();
    }
  };
  const getShipTypes = async () => {
    await api
      .get({
        url: apiConfig.shipType,
      })
      .then((res: IOptionvalue[]) => {
        setShipTypes(res);
      });
  };

  useEffect(() => {
    getShipTypes();
  }, []);

  const getShipHandledById = async () => {
    if (berthShiptypeHandleId) {
      await api
        .get({
          url: apiConfig.berthShipHandled + '/' + berthShiptypeHandleId,
        })
        .then((res: any) => {
          setShipHandledDetails(res);
        });
    }
  };

  useEffect(() => {
    getShipHandledById();
  }, [berthShiptypeHandleId]);

  useEffect(() => {
    shipHandledForm.reset();
    if (shipHandledDetails) {
      shipHandledForm.reset(
        {
          shipType: shipHandledDetails?.shipType,
          shipSize: shipHandledDetails?.shipSize,
        },
        { keepDirty: true }
      );
    }
  }, [shipHandledDetails]);

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setShowConfirmBack(false);
      shipHandledForm.reset(formDefaultValues);
      handleCancelClick();
    },
    handleClose: () => {
      setShowConfirmBack(false);
    },
  };

  const onClickSave = () => {
    const requiredValidation = validationGroup.current.instance.validate();
    if (requiredValidation.isValid) {
      setIsConfirmModalOpen(true);
    }
  };

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      handleSaveClick();
      setIsConfirmModalOpen(false);
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };

  const handleSaveClick = () => {
    const requiredValidation = validationGroup.current.instance.validate();
    if (requiredValidation.isValid) {
      const shipHandledFormValues = shipHandledForm.getValues();
      const requestBody = {
        berthShiptypeHandleId: 0,
        berthCode: berthCode,
        shipType: shipHandledFormValues.shipType,
        shipSize: shipHandledFormValues.shipSize,
      };
      if (berthShiptypeHandleId != null) {
        requestBody.berthShiptypeHandleId = berthShiptypeHandleId;
        edit(requestBody);
      } else {
        addNew(requestBody);
      }
    }
  };

  const addNew = async (data: any) => {
    await api
      .post(
        {
          url: apiConfig.berthShipHandled,
          data: data,
        },
        setIsLoading
      )
      .then(() => {
        handleCancelClick();
        getShipHandled();
        toast.success({
          title: t('toast.savedSuccessfully'),
        });
      });
  };

  const edit = async (data: any) => {
    await api
      .put(
        {
          url: apiConfig.berthShipHandled,
          data: data,
        },
        setIsLoading
      )
      .then(() => {
        getShipHandled();
        handleCancelClick();
        toast.success({
          title: t('toast.savedSuccessfully'),
        });
      });
  };

  return (
    <div className="m-l-model-popup__wrap" data-testid="editName">
      <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
        <div className="m-l-modal__header">
          {isEdit && <h2 className="modal-title">Edit Ship Handled</h2>}
          {!isEdit && <h2 className="modal-title">Add Ship Handled</h2>}
          <div className="m-l-modal-close-button-block">
            <Button
              className="app-c-btn app-c-icon-only-btn"
              onClick={() => {
                onCloseClick();
              }}
            >
              <icons.IconCross />
            </Button>
          </div>
        </div>
        <div className="m-l-modal__body">
          <ScrollView width="100%" height="100%">
            <div className="m-l-edit-form-panel">
              <FormProvider {...shipHandledForm}>
                <ValidationGroup ref={validationGroup}>
                  <ShipHandledForm shipTypes={shipTypes} />
                </ValidationGroup>
              </FormProvider>
            </div>
          </ScrollView>
        </div>
        <div className="m-l-modal__footer">
          <Button
            className="app-c-btn app-c-btn--secondary min-btn-width"
            elementAttr={{ 'data-testid': 'backButton' }}
            onClick={() => {
              onCloseClick();
            }}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            className="app-c-btn app-c-btn--primary min-btn-width"
            elementAttr={{ 'data-testid': 'saveButton' }}
            onClick={() => onClickSave()}
          >
            {t('buttons.save')}
          </Button>
        </div>
        <ConfirmDialog
          dialogConfig={confirmBackDialogConfig}
          isOpen={showConfirmBack}
        />
        <ConfirmDialog
          dialogConfig={confirmSaveDialogConfig}
          isOpen={isConfirmModalOpen}
        />
      </Modal>
    </div>
  );
}
