import Button from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useState, useMemo, useEffect } from 'react';
import icons from '../../../../components/common/icons/icons';
import Modal from '../../../../components/common/modal/Modal';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { IModalConfig } from '../../../../types/modal';
import { ITerminalInfo } from '../../../../types/berthfit-view-info';
import { useTranslation } from 'react-i18next';
import { useDimensionsUnit } from '../../../../hooks/useDimensionsUnit';
import TerminalAccordion from '../../../port-management/terminal/terminal-accordion/TerminalAccordion';
import { BerthFItInformationParams } from '../../../../enums/berthfit-information-enum';

export default function TerminalInfo(props: {
  handleCancelClick: any;
  isVisible: boolean;
  terminalId: any;
  buId?: any;
  terminalCode?: any;
}) {
  const [terminalInfo, setTerminalInfo] = useState<ITerminalInfo[]>([]);
  const { terminalId, buId, terminalCode } = props;
  const { t } = useTranslation();
  const { dimensionUnitText, meterToFeetConversion } = useDimensionsUnit();

  const GetTerminalDetails = async () => {
    await api
      .get({
        url: apiConfig.viewTerminal + terminalId,
        params: { buId: buId },
      })
      .then((response: ITerminalInfo[]) => {
        response.sort((a, b) => a.displayOrder - b.displayOrder);
        setTerminalInfo(response);
      });
  };

  useEffect(() => {
    if (terminalId) {
      GetTerminalDetails();
    }
  }, [terminalId]);

  const selectedTerminal = terminalInfo.find(
    (terminal) => terminal.text === 'Terminal Name'
  );

  const selectedParameterInfo = terminalInfo.find(
    (terminal) =>
      terminal.parameterName === BerthFItInformationParams.VesselHandled
  );

  const requiredFields = useMemo(() => {
    return terminalInfo.filter(
      (item) => item.parameterName !== BerthFItInformationParams.VesselHandled
    );
  }, [terminalInfo]);

  const modalConfig: IModalConfig = {
    width: 1000,
    height: 560,
    visible: props.isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };
  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-form-modal',
    };
  }, []);

  return (
    <div className="m-l-model-popup__wrap">
      <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
        <div className="m-l-modal__header">
          <h2 className="modal-title">
            {' '}
            {selectedTerminal?.value} {t('labels.terminalInformation')}{' '}
          </h2>
          <div className="m-l-modal-close-button-block">
            <Button
              className="app-c-btn app-c-icon-only-btn"
              onClick={props.handleCancelClick}
            >
              <icons.IconCross />
            </Button>
          </div>
        </div>
        <div className="m-l-modal__body">
          <div className="m-l-inner-page-body">
            <ScrollView width="100%" height="100%">
              <div className="m-l-form-panel-block">
                <div className="m-l-tbl-square-wrapper">
                  <div className="m-l-ads-table-block">
                    <div className="m-l-ads-table-right-part">
                      <div className="row m-l-ads-table-row">
                        {requiredFields.map((field, index) => {
                          return (
                            <div
                              className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items"
                              key={index}
                            >
                              <div className="m-l-ads-tbl-title">
                                {' '}
                                {field.isConversionRequired
                                  ? field.text + dimensionUnitText()
                                  : field.text}{' '}
                              </div>
                              <div className="m-l-ads-tbl-desc">
                                {field.isConversionRequired && field.value
                                  ? meterToFeetConversion(Number(field.value))
                                  : field.value}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="u-p8">
                  <TerminalAccordion
                    terminalCode={terminalCode}
                    isFromViewInfoModal={true}
                    parameterInfo={[selectedParameterInfo?.parameterName]}
                  />
                </div>
              </div>
            </ScrollView>
          </div>
        </div>
      </Modal>
    </div>
  );
}
