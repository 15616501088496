import React, { useEffect, useState } from 'react';
import Button from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { IListGeneralForm, IListObject } from '../../../types/list';
import ListFormGeneralFields from '../../../components/list/list-form-general-field/ListFormGeneralField';
import ConfirmDialog from '../../../components/common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../types/dialog';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from '../../../utils/toast';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { MarsApiService as api } from '../../../api/mars-api-service';
import EntriesList from '../../../components/list/entries-list/EntriesList';
import { Restricted } from '../../../contexts/PermissionContext';
import { listTypes } from '../../../enums/buList-type-enum';
import { useLoaderContext } from '../../../contexts/LoaderContext';

export default function EditList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [listId, setListId] = useState<any>();
  const [confirmBackDialog, setConfirmBackDialog] = useState(false);
  const [confirmSaveDialog, setConfirmSaveDialog] = useState(false);
  const [editListId, setEditListId] = useState(listId);
  const [title, setTitle] = useState('');
  const [attribute, setAttribute] = useState();
  const [defaultAttributeId, setDefaultAttributeId] = useState();
  const [listDetails, setListDetails] = useState<IListObject>();
  const [buId, setBuId] = useState(Number);
  const [listType, setListType] = useState<any>();
  const { setIsLoading } = useLoaderContext();
  useEffect(() => {
    if (location.state) {
      const { listId, listType, buId }: any = location.state;
      setListId(listId);
      setEditListId(listId);
      setBuId(parseInt(buId));
      setListType(listType);
    }
  }, [location.state]);

  useEffect(() => {
    if (listId) {
      getListById();
    }
  }, [listId]);

  useEffect(() => {
    listGeneralForm.reset();
    if (listDetails) {
      setTitle(listDetails.listName);
      setDefaultAttributeId(listDetails.masterAttributeId);
      listGeneralForm.reset(
        {
          listname: listDetails.listName,
          listDescription: listDetails.listDesc,
          entity: listDetails.masterEntityId,
          attribute: listDetails.masterAttributeId,
        },
        { keepDirty: true }
      );
    }
  }, [listDetails]);

  const defaultListFormValues: IListGeneralForm = {
    listname: '',
    listDescription: '',
    entity: null,
    attribute: null,
  };

  const listGeneralForm = useForm({
    defaultValues: defaultListFormValues,
  });

  const { getValues } = listGeneralForm;
  const listGeneralFormValue: IListGeneralForm = getValues();

  //on click save button
  const onClickSave = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      setConfirmSaveDialog(true);
    }
    return;
  };

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: async () => {
      setConfirmSaveDialog(false);
      if (editListId) {
        saveEditedList();
      } else {
        saveList();
      }
    },
    handleClose: () => {
      setConfirmSaveDialog(false);
    },
  };

  const saveList = async () => {
    const listObject: IListObject = {
      listName: listGeneralFormValue.listname,
      listDesc: listGeneralFormValue.listDescription,
      masterEntityId: listGeneralFormValue.entity,
      masterAttributeId: listGeneralFormValue.attribute,
    };

    const obj: any = { ...listObject };
    obj.buId = buId;

    await api
      .post({ url: apiConfig.list, data: obj }, setIsLoading)
      .then((response) => {
        setTitle(response.listName);
        setEditListId(response.listId);
        toast.success({
          title: t('toast.listAdded'),
        });
        if (buId) {
          navigate('/list/edit', {
            state: { listId: response.listId, buId: buId },
          });
        } else {
          navigate('/list/edit', { state: { listId: response.listId } });
        }
      });
  };

  const saveEditedList = async () => {
    const listObject: IListObject = {
      listId: editListId,
      listName: listGeneralFormValue.listname,
      listDesc: listGeneralFormValue.listDescription,
      masterEntityId: listGeneralFormValue.entity,
      masterAttributeId: listGeneralFormValue.attribute,
    };
    await api
      .put({ url: apiConfig.list, data: listObject }, setIsLoading)
      .then((response) => {
        setTitle(response.listName);
        setEditListId(response.listId);
        toast.success({
          title: t('toast.listAdded'),
        });
        getListById();
      });
  };

  //on click back button
  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBackEditList',
    content: t('toast.confirmBackEditList'),
    handleSubmit: () => {
      setConfirmBackDialog(false);
      if (buId) {
        navigate('/company/business-unit/edit', {
          state: { fromPage: 'listTab', buId: buId },
        });
      } else {
        navigate('/list');
      }
    },
    handleClose: () => {
      setConfirmBackDialog(false);
    },
  };

  const { isDirty } = listGeneralForm.formState;

  const handleBackClick = () => {
    // isDirty ? setConfirmBackDialog(true) : navigate('/list');
    if (isDirty && !(listId && buId && listType === listTypes.Global)) {
      setConfirmBackDialog(true);
    } else {
      if (buId) {
        navigate('/company/business-unit/edit', {
          state: { fromPage: 'listTab', buId: buId },
        });
      } else {
        navigate('/list');
      }
    }
  };

  //view list to edit
  const getListById = async () => {
    await api.get({ url: apiConfig.listById + editListId }).then((res) => {
      setListDetails(res);
    });
  };

  return (
    <div className="inner-wrapper">
      <ul aria-label="breadcrumb" className="m-c-bredcrumb">
        <li>
          <a className="active">{t('headers.lists')}</a>
        </li>
      </ul>
      <div className="m-l-inner-page-header m-l-srv-btm">
        <div className="m-l-inner-page-header-left-block">
          <div className="m-l-page-main-heading">
            {editListId ? title : t('headers.addList')}
          </div>
        </div>
        <div className="m-l-inner-page-header-right-block">
          <Button
            className="app-c-btn app-c-btn--secondary"
            elementAttr={{ 'data-testid': 'backButton' }}
            onClick={handleBackClick}
          >
            {t('buttons.back')}
          </Button>
          <Restricted permission="GlobalList.AddNewList">
            {!listId && !buId && (
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                elementAttr={{ 'data-testid': 'saveButton' }}
                onClick={onClickSave}
              >
                {t('buttons.save')}
              </Button>
            )}
          </Restricted>
          <Restricted permission="GlobalList.EditList">
            {!!listId && !buId && (
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                elementAttr={{ 'data-testid': 'saveButton' }}
                disabled={listType === listTypes.Global}
                onClick={onClickSave}
              >
                {t('buttons.save')}
              </Button>
            )}
          </Restricted>
          <Restricted permission="BULists.Edit">
            {!!listId && !!buId && (
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                elementAttr={{ 'data-testid': 'saveButton' }}
                onClick={onClickSave}
                disabled={listType === listTypes.Global}
              >
                {t('buttons.save')}
              </Button>
            )}
          </Restricted>
          <Restricted permission="BULists.Add">
            {!listId && !!buId && (
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                elementAttr={{ 'data-testid': 'saveButton' }}
                onClick={onClickSave}
                disabled={listType === listTypes.Global}
              >
                {t('buttons.save')}
              </Button>
            )}
          </Restricted>
        </div>
      </div>
      <div className="m-l-inner-page-body-wrap">
        <div className="m-l-inner-page-body">
          <div className="m-l-form-panel-block">
            <div className="m-l-form-panel-body-block ">
              <FormProvider {...listGeneralForm}>
                <ListFormGeneralFields
                  setAttribute={setAttribute}
                  defaultAttributeId={defaultAttributeId}
                  listEntries={listDetails?.listEntries}
                  attribute={attribute}
                />
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={confirmBackDialog}
      />
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={confirmSaveDialog}
      />
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <Button
                className="app-c-btn app-c-btn--secondary"
                elementAttr={{ 'data-testid': 'backButton' }}
                onClick={handleBackClick}
              >
                {t('buttons.back')}
              </Button>
              <Restricted permission="GlobalList.AddNewList">
                {!listId && !buId && (
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'saveButton' }}
                    onClick={onClickSave}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Restricted>
              <Restricted permission="GlobalList.EditList">
                {!!listId && !buId && (
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'saveButton' }}
                    disabled={listType === listTypes.Global}
                    onClick={onClickSave}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Restricted>
              <Restricted permission="BULists.Edit">
                {!!listId && !!buId && (
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'saveButton' }}
                    onClick={onClickSave}
                    disabled={listType === listTypes.Global}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Restricted>
              <Restricted permission="BULists.Add">
                {!listId && !!buId && (
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'saveButton' }}
                    onClick={onClickSave}
                    disabled={listType === listTypes.Global}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Restricted>
            </div>
          </div>
        </div>
      </div>
      {editListId ? (
        <EntriesList
          listEntries={listDetails?.listEntries}
          getListById={getListById}
          listId={editListId}
          attribute={attribute}
          listDetails={listDetails}
          listType={listType}
          buId={buId}
        />
      ) : null}
    </div>
  );
}
