import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IOptionvalue } from '../../../../../types/master-data';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import FormBuilder from '../../../../common/form-builder/FormBuilder';

export default function CapForm() {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [capRating, setCapRating] = useState<IOptionvalue[]>([]);

  const getCapRating = async () => {
    await api
      .get({
        url: apiConfig.capRating,
      })
      .then((res: IOptionvalue[]) => {
        setCapRating(res);
      });
  };

  useEffect(() => {
    getCapRating();
  }, []);

  const capFormConfig = [
    {
      class:
        'col-md-2 col-sm-6 col-lg-2 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'capRatingHull',
      label: t('labels.hull'),
      control: control,
      fieldType: 'select',
      dataSource: capRating,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueText',
    },
    {
      class:
        'col-md-2 col-sm-6 col-lg-2 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'capRatingHullDate',
      label: t('labels.date'),
      control: control,
      fieldType: 'date',
    },
    {
      class:
        'col-md-2 col-sm-6 col-lg-2 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'capRatingMachinery',
      label: t('labels.machinery'),
      control: control,
      fieldType: 'select',
      dataSource: capRating,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
    {
      class:
        'col-md-2 col-sm-6 col-lg-2 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'capRatingMachineryDate',
      label: t('labels.date'),
      control: control,
      fieldType: 'date',
    },
    {
      class:
        'col-md-2 col-sm-6 col-lg-2 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'capRatingCargoSystem',
      label: t('labels.cargoSystem'),
      control: control,
      fieldType: 'select',
      dataSource: capRating,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
    {
      class:
        'col-md-2 col-sm-6 col-lg-2 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'capRatingCargoSystemDate',
      label: t('labels.date'),
      control: control,
      fieldType: 'date',
    },
    {
      class:
        'col-md-2 col-sm-6 col-lg-2 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'capRatingElectricalEquipment',
      label: t('labels.electricalEquipment'),
      control: control,
      fieldType: 'select',
      dataSource: capRating,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
    {
      class:
        'col-md-2 col-sm-6 col-lg-2 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'capRatingElectricalEquipmentDate',
      label: t('labels.date'),
      control: control,
      fieldType: 'date',
    },
    {
      class:
        'col-md-2 col-sm-6 col-lg-2 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'capRatingArrangements',
      label: t('labels.arrangements'),
      control: control,
      fieldType: 'select',
      dataSource: capRating,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
    {
      class:
        'col-md-2 col-sm-6 col-lg-2 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'capRatingArrangementsDate',
      label: t('labels.date'),
      control: control,
      fieldType: 'date',
    },
    {
      class:
        'col-md-2 col-sm-6 col-lg-2 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'capRatingPropulsionPlant',
      label: t('labels.propulsionPlants'),
      control: control,
      fieldType: 'select',
      dataSource: capRating,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
    {
      class:
        'col-md-2 col-sm-6 col-lg-2 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'capRatingPropulsionPlantDate',
      label: t('labels.date'),
      control: control,
      fieldType: 'date',
    },
    {
      class:
        'col-md-2 col-sm-6 col-lg-2 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'capRatingBallastTankRating',
      label: t('labels.ballastTankRating'),
      control: control,
      fieldType: 'select',
      dataSource: capRating,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
    {
      class:
        'col-md-2 col-sm-6 col-lg-2 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'capRatingBallastTankRatingDate',
      label: t('labels.date'),
      control: control,
      fieldType: 'date',
    },
    {
      class:
        'col-md-2 col-sm-6 col-lg-2 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'capRatingExternalStructure',
      label: t('labels.externalStructure'),
      control: control,
      fieldType: 'select',
      dataSource: capRating,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
    {
      class:
        'col-md-2 col-sm-6 col-lg-2 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'capRatingExternalStructureDate',
      label: t('labels.date'),
      control: control,
      fieldType: 'date',
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={capFormConfig} />
      </div>
    </>
  );
}
