import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePermission } from '../../../../../contexts/PermissionContext';
import ReportSettingHtmlEditor from './ReportSettingHtmlEditor';

export default function ReportSettingForm(props: {
  entities: any;
  showEditor: any;
  requestorDisclaimer: any;
  marsDisclaimer: any;
  setMarsDisclaimer: any;
  setRequestorDisclaimer: any;
  buId?: any;
  showMarsDisclaimer: any;
  showReqDisclaimer: any;
}) {
  const {
    entities,
    requestorDisclaimer,
    setRequestorDisclaimer,
    marsDisclaimer,
    setMarsDisclaimer,
    buId,
    showMarsDisclaimer,
    showReqDisclaimer,
  } = props;
  const { t } = useTranslation();
  const { findFeature } = usePermission();
  return (
    <div className="">
      <div className="m-l-srv-btm">
        <label className="m-l-email-input-label">
          {t('labels.marsDisclaimerContent')}
        </label>
        <ReportSettingHtmlEditor
          content={marsDisclaimer}
          setContent={setMarsDisclaimer}
          entities={entities}
          isDisabled={
            buId
              ? !findFeature(
                  'BUCompanyConfiguration.EditVetReportMARSDisclaimerContent'
                )
              : !findFeature(
                  'CompanyConfiguration.EditVetReportMARSDisclaimerContent'
                )
          }
          showEditor={showMarsDisclaimer ? true : false}
        />
      </div>
      <div className="m-l-srv-btm ">
        <label className="m-l-email-input-label">
          {t('labels.requestorDisclaimerContent')}
        </label>
        <ReportSettingHtmlEditor
          content={requestorDisclaimer}
          setContent={setRequestorDisclaimer}
          entities={entities}
          isDisabled={false}
          showEditor={showReqDisclaimer ? true : false}
        />
      </div>
    </div>
  );
}
