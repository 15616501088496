import React, { useEffect, useMemo, useState } from 'react';
import { usePermission } from '../../../contexts/PermissionContext';
import BerthfitReportSetting from './berthfit-report-settings/BerthfitReportSettings';
import ConfigurationsList from './ConfigurationsList';
import EmailSetting from './email-settings/EmailSetting';
import GeneralSettings from './general-settings/GeneralSettings';
import PasswordSettings from './password-settings/PasswordSettings';
import ReportSetting from './report-settings/ReportSetting';
import SanctionList from './sanction-List/SanctionsList';

export default function CompanyConfigurations(props: {
  companyId: any;
  buId?: any;
  companyType?: any;
  saveConfig?: any;
  setSaveConfig?: any;
  companyTypeFlag?: any;
  selectedConfig: any;
  setSelectedConfig: any;
  //   vetRequestId?: string;
  //   portId: number;
  //   berthFitId: number;
}) {
  const {
    companyId,
    buId,
    companyType,
    saveConfig,
    setSaveConfig,
    companyTypeFlag,
    selectedConfig,
    setSelectedConfig,
  } = props;
  const { findFeature, featureList } = usePermission();

  const buConfigurationListItem = useMemo(() => {
    return [
      {
        id: 3,
        value: 'labels.restrictions&Approvals',
        visible: findFeature(
          'BUCompanyConfiguration.ViewRestrictionsApprovals'
        ),
      },
      {
        id: 4,
        value: 'labels.emailSettings',
        visible: findFeature('BUCompanyConfiguration.ViewEmailSettings')
          ? companyTypeFlag === 'M' || companyTypeFlag === 'H'
            ? true
            : false
          : false,
      },
      {
        id: 5,
        value: 'labels.reportSettings',
        visible: findFeature('BUCompanyConfiguration.ViewVetReportSettings'),
      },
      {
        id: 6,
        value: 'labels.berthfitReportSettings',
        visible: findFeature(
          'BUCompanyConfiguration.ViewBerthFitReportSettings'
        ),
      },
    ];
  }, [featureList, companyTypeFlag]);

  const items = [
    {
      id: 1,
      value: 'labels.generalSettings',
      visible: findFeature('CompanyConfiguration.ViewGeneralSettings')
        ? companyType === 'M'
          ? true
          : false
        : false,
    },
    {
      id: 2,
      value: 'labels.passwordSettings',
      visible: findFeature('CompanyConfiguration.ViewPasswordSettings')
        ? companyType === 'M'
          ? true
          : false
        : false,
    },
    {
      id: 3,
      value: 'labels.restrictions&Approvals',
      visible: findFeature('CompanyConfiguration.ViewRestrictionsApprovals'),
    },
    {
      id: 4,
      value: 'labels.emailSettings',
      visible: findFeature('CompanyConfiguration.ViewEmailSettings')
        ? companyType === 'M' || companyType === 'H'
          ? true
          : false
        : false,
    },
    {
      id: 5,
      value: 'labels.reportSettings',
      visible: findFeature('CompanyConfiguration.ViewVetReportSettings'),
      //   ? companyType === 'M' || companyType === 'H'
      //     ? true
      //     : false
      //   : false,
    },
    {
      id: 6,
      value: 'labels.berthfitReportSettings',
      visible: findFeature('CompanyConfiguration.ViewBerthFitReportSettings'),
      //   ? companyType === 'M' || companyType === 'H'
      //     ? true
      //     : false
      //   : false,
    },
  ];

  const itemsList = items.filter((data: any) => data.visible == true);

  const buItemsList = useMemo(() => {
    return buConfigurationListItem.filter((data: any) => data.visible == true);
  }, [buConfigurationListItem]);

  useEffect(() => {
    if (!buId) {
      itemsList && setSelectedConfig(itemsList[0]?.id);
    } else {
      buItemsList && setSelectedConfig(buItemsList[0]?.id);
    }
  }, []);

  return (
    <div className="m-l-vertical-tab-email-compose-section">
      <div className="m-l-terminal-accordion-main-block">
        <div className="m-l-terminal-accordion-left-block">
          {buId ? (
            <ConfigurationsList
              configurationList={buItemsList}
              selectedConfig={selectedConfig}
              setSelectedConfig={setSelectedConfig}
            />
          ) : (
            <ConfigurationsList
              configurationList={itemsList}
              selectedConfig={selectedConfig}
              setSelectedConfig={setSelectedConfig}
            />
          )}
        </div>
        <div className="m-l-terminal-accordion-right-block m-l-terminal-general-right-block">
          {selectedConfig == 1 ? (
            <GeneralSettings
              companyId={companyId}
              saveConfig={saveConfig}
              setSaveConfig={setSaveConfig}
            />
          ) : selectedConfig == 2 ? (
            <PasswordSettings
              companyId={companyId}
              saveConfig={saveConfig}
              setSaveConfig={setSaveConfig}
            />
          ) : selectedConfig == 3 ? (
            <SanctionList
              companyId={companyId}
              selectedCompanyType={
                companyTypeFlag ? companyTypeFlag : companyType
              }
              companyTypeFlag={companyTypeFlag}
              buId={buId}
              saveConfig={saveConfig}
              setSaveConfig={setSaveConfig}
            />
          ) : selectedConfig == 4 ? (
            <EmailSetting
              companyId={companyId}
              buId={buId}
              saveConfig={saveConfig}
              setSaveConfig={setSaveConfig}
            />
          ) : selectedConfig == 5 ? (
            <ReportSetting
              companyId={companyId}
              buId={buId}
              saveConfig={saveConfig}
              setSaveConfig={setSaveConfig}
            />
          ) : selectedConfig == 6 ? (
            <BerthfitReportSetting
              companyId={companyId}
              buId={buId}
              saveConfig={saveConfig}
              setSaveConfig={setSaveConfig}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
