import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IPasswordSettings } from '../../../../types/password-settings';
import PasswordSettingsForm from './password-settings-form/PasswordSettingsForm';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import Button from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import toast from '../../../../utils/toast';
import { PasswordSetting } from '../../../../enums/settings-type-enum';
import ValidationGroup from 'devextreme-react/validation-group';
import { IDialogConfig } from '../../../../types/dialog';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';

export default function PasswordSettings(props: {
  companyId: any;
  saveConfig?: any;
  setSaveConfig?: any;
}) {
  const { companyId, saveConfig, setSaveConfig } = props;
  const [passwordSettings, setPasswordSettings] = useState<any[]>([]);
  const { t } = useTranslation();
  const validationGroup: any = useRef();
  const [showConfirmSave, setShowConfirmSave] = useState(false);

  const getPassword = async () => {
    await api
      .get({
        url: apiConfig.configurations,
        params: { settingArea: 'Password', companyId: companyId },
      })
      .then((data: any) => {
        if (data.length > 0) {
          setPasswordSettings(data);
        } else {
          getPassword2();
        }
      });
  };

  const getPassword2 = async () => {
    await api
      .get({
        url: apiConfig.configurations,
        params: { settingArea: 'Password' },
      })
      .then((data: any) => {
        setPasswordSettings(data);
      });
  };

  const minLength = passwordSettings.find(
    (passwordSetting) =>
      passwordSetting.settingType === PasswordSetting.minLength
  );

  const minUC = passwordSettings.find(
    (passwordSetting) =>
      passwordSetting.settingType === PasswordSetting.minUpperCase
  );

  const minLC = passwordSettings.find(
    (passwordSetting) =>
      passwordSetting.settingType === PasswordSetting.minLowerCase
  );

  const minSC = passwordSettings.find(
    (passwordSetting) =>
      passwordSetting.settingType === PasswordSetting.minSpecialCharacters
  );

  const minNC = passwordSettings.find(
    (passwordSetting) =>
      passwordSetting.settingType === PasswordSetting.minNumericCharacters
  );

  useEffect(() => {
    getPassword();
  }, []);

  const defaultFormValues: IPasswordSettings = {
    minimumLength: null,
    minimumUpperCase: null,
    minimumLowerCase: null,
    minimumSepcialCharacter: null,
    minimumNumericCharacter: null,
  };

  const passwordSettingsForm = useForm({
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    passwordSettingsForm.reset();
    if (passwordSettings) {
      passwordSettingsForm.reset({
        minimumLength: parseInt(minLength?.settingValue),
        minimumUpperCase: parseInt(minUC?.settingValue),
        minimumLowerCase: parseInt(minLC?.settingValue),
        minimumSepcialCharacter: parseInt(minSC?.settingValue),
        minimumNumericCharacter: parseInt(minNC?.settingValue),
      });
    }
  }, [passwordSettings]);

  const handleSaveClick = async () => {
    setShowConfirmSave(true);
  };

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmSaveChanges'),
    handleSubmit: () => {
      onValueSubmit();
      setShowConfirmSave(false);
      setSaveConfig(false);
    },
    handleClose: () => {
      setShowConfirmSave(false);
      setSaveConfig(false);
    },
  };

  const onValueSubmit = () => {
    const requiredValidation = validationGroup.current.instance.validate();
    if (requiredValidation.isValid) {
      const formValue: IPasswordSettings = passwordSettingsForm.getValues();
      minLength.settingValue = formValue.minimumLength?.toString();
      minUC.settingValue = formValue.minimumUpperCase?.toString();
      minLC.settingValue = formValue.minimumLowerCase?.toString();
      minSC.settingValue = formValue.minimumSepcialCharacter?.toString();
      minNC.settingValue = formValue.minimumNumericCharacter?.toString();
      const roleJsonEdit = [minLength, minUC, minLC, minSC, minNC];
      api
        .put({
          url: apiConfig.configurations,
          params: { configArea: 'Password', companyId: companyId },
          data: roleJsonEdit,
        })
        .then((response) => {
          if (response) {
            getPassword();
            toast.custom({
              title: t('toast.changesSavedSuccessfully'),
            });
          }
        });
      setSaveConfig(false);
    }
  };

  useEffect(() => {
    if (saveConfig && validationGroup.current?.instance.validate().isValid) {
      handleSaveClick();
    } else {
      setSaveConfig(false);
    }
  }, [saveConfig]);

  return (
    <ValidationGroup ref={validationGroup}>
      <div className="m-l-form-panel-block">
        <div className="m-l-form-panel-header-block">
          <div className="m-l-form-panel-header-left-block">
            <div className="m-l-page-small-heading">
              {t('labels.passwordSettings')}
            </div>
          </div>
          {/* <div className="m-l-form-panel-header-right-block">
            <Button
              className="app-c-btn app-c-btn--primary min-btn-width"
              aria-label=""
              onClick={onValueSubmit}
            >
              {t('labels.save')}
            </Button>
          </div> */}
        </div>
        <div className="m-l-form-panel-body-block">
          <FormProvider {...passwordSettingsForm}>
            <PasswordSettingsForm />
          </FormProvider>
        </div>
        <ConfirmDialog
          dialogConfig={confirmSaveDialogConfig}
          isOpen={showConfirmSave}
        />
      </div>
    </ValidationGroup>
  );
}
