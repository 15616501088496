import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import FormBuilder from '../../../../common/form-builder/FormBuilder';
export default function ScrubberForm(props: { vetRelatedInfo: any }) {
  const { vetRelatedInfo } = props;
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const [scrubberType, setScrubberType] = useState<any>();
  const [scrubberTypeFlag, setScrubberTypeFlag] = useState<any>();
  const getScrubberTypes = async () => {
    await api
      .get({ url: apiConfig.optionValue + 'scrubbertype' })
      .then((data: any) => {
        setScrubberType(data);
      });
  };
  const ScrubberTypeEnable = (e: any) => {
    if (e.value === true) {
      setScrubberTypeFlag(false);
    } else {
      setScrubberTypeFlag(true);
      setValue('scrubberTypeCode', null);
    }
  };

  useEffect(() => {
    getScrubberTypes();
  }, []);

  useEffect(() => {
    if (vetRelatedInfo) {
      if (vetRelatedInfo.scrubberFitted === false) {
        setScrubberTypeFlag(true);
      }
    }
  }, [vetRelatedInfo]);

  const scrubberFitted = [
    { name: t('labels.yes'), value: true },
    { name: t('labels.no'), value: false },
  ];

  const scrubberFormConfig = [
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
      fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
      name: 'scrubberFitted',
      label: t('labels.scrubberFitted'),
      control: control,
      fieldType: 'radio',
      layout: 'horizontal',
      items: scrubberFitted,
      displayExpr: 'name',
      valueExpr: 'value',
      onChange: ScrubberTypeEnable,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'scrubberTypeCode',
      label: t('labels.scrubberType'),
      control: control,
      fieldType: 'select',
      dataSource: scrubberType,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      disabled: scrubberTypeFlag,
    },
  ];
  return (
    <>
      <div className="row">
        <FormBuilder formConfig={scrubberFormConfig} />
      </div>
    </>
  );
}
