import Button from 'devextreme-react/button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Restricted } from '../../../../contexts/PermissionContext';
import { IGridConfig } from '../../../../types/grid';
import Grid from '../../../common/grid/Grid';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { getAPIRoute } from '../../../../utils/api-route';
import ManagementEditModal from '../../vessel-details/management/edit-management/EditManagement';

export default function ManagementGrid(props: {
  vetRequestId: number;
  ruleId: number;
  isReviewer: boolean;
  imoNumber?: string;
  getRuleList: any;
}) {
  const { vetRequestId, ruleId, isReviewer, imoNumber, getRuleList } = props;

  const { t } = useTranslation();

  const [managementList, setManagementList] = useState<any[]>([]);

  const [showEditManagement, setShowEditManagement] = useState(false);

  const [modalId, setModalId] = useState(0);

  const [managementRow, setManagementRow] = useState<any>();

  const getManagementRoles = async () => {
    await api
      .get({ url: getAPIRoute('rulesManagementList', [vetRequestId, ruleId]) })
      .then((res: any) => {
        setManagementList(res);
      });
  };

  useEffect(() => {
    getManagementRoles();
  }, []);

  const gridConfig: IGridConfig = {
    class:
      'm-c-grid full-border-checkbox-grid m-l-incident-grid m-c-auto-scroll-grid',
    testId: 'managementRolesGrid',
    dataSource: managementList,
    defaultColumns: [
      {
        caption: t('labels.role'),
        dataField: 'roleName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.companyNumberImo'),
        dataField: 'companyNumber',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.companyName'),
        dataField: 'companyName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.address'),
        dataField: 'address',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.country'),
        dataField: 'country',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.email'),
        dataField: 'email',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.phone'),
        dataField: 'phoneNumber',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.fromDate'),
        dataField: 'dateFrom',
        dataType: 'date',
        format: 'dd MMM yyyy',
        minWidth: 150,
      },
      {
        caption: '',
        dataField: 'action',
        dataType: 'string',
        cellTemplate: 'actionTemplate',
        alignment: 'center',
        minWidth: 100,
        allowFiltering: false,
        allowSorting: false,
      },
    ],
    showHeader: false,
    showExport: false,
    showAddButton: false,
    excelFileName: 'VesselManagementRoles.xlsx',
    rowClick: (e) => showManagementModal(0, e?.data),
  };

  const showManagementModal = (modalId: number, roleData?: any, e?: any) => {
    if (modalId === 1) {
      e?.event?.stopPropagation();
    }
    if (roleData) {
      getManagementRow(roleData?.roleName, roleData?.companyNumber);
    } else {
      setManagementRow(undefined);
    }
    setModalId(modalId);
    setShowEditManagement(true);
  };

  const getManagementRow = async (roleName: any, roleCode: any) => {
    await api
      .get({
        url: getAPIRoute('vesselManagementRoleDetails', [
          imoNumber,
          roleName,
          roleCode,
        ]),
      })
      .then((res) => {
        setManagementRow(res);
      });
  };

  const actionTemplate = (data: any) => {
    return (
      <>
        <Restricted permission="Ship.ChangeManagement">
          <Button
            className="app-c-btn app-c-btn--secondary"
            onClick={(e) => showManagementModal(1, data?.data, e)}
          >
            {t('buttons.change')}
          </Button>
        </Restricted>
      </>
    );
  };

  return (
    <>
      <div className="row m-l-cap-wrapper mrbtm-space">
        <div className="col-xl-6 col-lg-6 co-md-6 col-sm-6 col-12 m-l-cap-left-part">
          <div className="m-l-page-small-sub-heading">
            {t('labels.addManagementInfo')}
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 co-md-6 col-sm-6 col-12 m-l-cap-right-part">
          <div className="m-l-cap-right-icon-part">
            <Restricted permission="Ship.AddManagement">
              {isReviewer && (
                <Button
                  className="app-c-btn app-c-btn--primary"
                  onClick={() => showManagementModal(2)}
                >
                  {t('labels.addManagementInfo')}
                </Button>
              )}
            </Restricted>
          </div>
        </div>
      </div>
      <div className="row m-l-cap-rules-wrapper">
        <Grid gridConfig={gridConfig} actionTemplate={actionTemplate} />
        {showEditManagement && (
          <ManagementEditModal
            imoNumber={imoNumber}
            isVisible={showEditManagement}
            modalId={modalId}
            managementRow={managementRow}
            handleCancelClick={() => setShowEditManagement(false)}
            getVesselMAnagementRoles={getManagementRoles}
            getRuleList={getRuleList}
          />
        )}
      </div>
    </>
  );
}
