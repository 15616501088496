import React, { useEffect, useState } from 'react';
import { IGridConfig } from '../../../../types/grid';
import Grid from '../../../common/grid/Grid';
import EditShipHandledModal from './EditShipHandledModal';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';

export default function ShipHandledGrid(props: {
  berthCode: any;
  isFromViewInfoModal?: boolean;
}) {
  const { berthCode, isFromViewInfoModal } = props;
  const [showEditModal, setShowEditModal] = useState(false);
  const [dataSource, setDataSource] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const [berthShiptypeHandleId, setBerthShiptypeHandleId] = useState<any>('');

  const onClickAdd = () => {
    setShowEditModal(true);
    setIsEdit(false);
  };

  const onRowClick = (e: any) => {
    if (!isFromViewInfoModal) {
      setBerthShiptypeHandleId(e?.data?.berthShiptypeHandleId);
      setShowEditModal(true);
      setIsEdit(true);
    }
  };

  const getShipHandled = async () => {
    if (berthCode) {
      await api
        .get({
          url: apiConfig.berthShipHandled,
          params: {
            berthCode: berthCode,
          },
        })
        .then((res: any) => {
          setDataSource(res?.data);
        });
    }
  };
  useEffect(() => {
    getShipHandled();
  }, []);

  useEffect(() => {
    if (!showEditModal) {
      setBerthShiptypeHandleId(null);
    }
  }, [showEditModal]);

  const gridConfig: IGridConfig = {
    class: 'm-c-grid m-l-grid-with--top-border m-c-righticon-grid',
    testId: 'vesselTypeGrid',
    height: '350',
    dataSource: dataSource,
    rowClick: onRowClick,
    onClickAddButton: onClickAdd,
    defaultColumns: [
      {
        caption: 'Ship Type',
        dataField: 'shipType',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'Ship Size',
        dataField: 'shipSize',
        dataType: 'string',
        minWidth: 150,
      },
    ],
    showHeader: true,
    showExport: false,
    showAddButton: !isFromViewInfoModal,
  };

  return (
    <div className="m-l-tab-accordion-item-content-section">
      <Grid gridConfig={gridConfig} />
      {showEditModal && (
        <EditShipHandledModal
          isVisible={showEditModal}
          handleCancelClick={() => setShowEditModal(false)}
          isEdit={isEdit}
          berthCode={berthCode}
          berthShiptypeHandleId={berthShiptypeHandleId}
          // setBerthShiptypeHandleId={setBerthShiptypeHandleId}
          getShipHandled={getShipHandled}
        />
      )}
    </div>
  );
}
