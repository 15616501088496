import Button from 'devextreme-react/button';
import Popover from 'devextreme-react/popover';
import TextBox from 'devextreme-react/text-box';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRuleClone } from '../../../../types/rules';
import ValidationGroup from 'devextreme-react/validation-group';
import { useResize } from '../../../../hooks/useResize';

export default function ListClone(props: {
  showPopover: boolean;
  listId: number;
  onClickCancel: () => void;
  target: string;
  closeOnOutsideClick: boolean;
  onHidingPopover: any;
  cloneList: (ruleObject: any) => void;
}) {
  const {
    showPopover,
    listId,
    onClickCancel,
    target,
    closeOnOutsideClick,
    onHidingPopover,
    cloneList,
  } = props;

  const popupAttributes = useMemo(() => {
    return {
      'data-testid': 'listClonePopover',
    };
  }, []);

  const [listName, setListName] = useState('');
  const { t } = useTranslation();
  const cloneValidation: any = useRef();

  const onClickClone = (e: any) => {
    if (cloneValidation.current?.instance.validate().isValid) {
      const listObject: any = {
        listIds: listId,
        listName: listName,
      };
      cloneList(listObject);
    }
  };

  const { isMobileResolution } = useResize();
  const [position, setPosition] = useState<any>('bottom');
  useEffect(() => {
    if (isMobileResolution) {
      setPosition('left');
    } else {
      setPosition('bottom');
    }
  }, [isMobileResolution]);

  return (
    <>
      <Popover
        wrapperAttr={popupAttributes}
        id="rulesPopover"
        target={target}
        position={position}
        width={300}
        closeOnOutsideClick={closeOnOutsideClick}
        visible={showPopover}
        onHiding={onHidingPopover}
        hideOnParentScroll={false}
      >
        <div className="m-l-grid-clone-popup-wrap">
          <div className="m-l-grid-clone-popup-header">
            <h2>{t('labels.newListName')}</h2>
          </div>
          <div className="m-l-grid-clone-popup-body">
            <div className="m-c-form-group ">
              <ValidationGroup ref={cloneValidation}>
                <TextBox
                  inputAttr={{ 'data-testid': 'listName' }}
                  className="m-c-input-control"
                  onValueChanged={(e: any) => {
                    setListName(e.value);
                  }}
                  value={listName}
                  validationMessageMode="always"
                >
                  <Validator>
                    <RequiredRule message={t('errors.required')} />
                  </Validator>
                </TextBox>
              </ValidationGroup>
            </div>
          </div>
          <div className="m-l-grid-clone-popup-footer">
            <Button
              className="app-c-btn app-c-btn--secondary"
              onClick={onClickCancel}
              elementAttr={{ 'data-testid': 'cancel' }}
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              className="app-c-btn app-c-btn--secondary"
              onClick={onClickClone}
              elementAttr={{ 'data-testid': 'clone' }}
            >
              {t('buttons.clone')}
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
}
