import Button from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useState, useMemo, useEffect } from 'react';
import icons from '../../components/common/icons/icons';
import Modal from '../../components/common/modal/Modal';
import { IModalConfig } from '../../types/modal';
import { FormProvider, useForm } from 'react-hook-form';
import OptionValueForm from './edit-optionvalues/OptionValueForm';
import { MarsApiService as api } from '../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import { IOptionvalue } from '../../types/master-data';
import { IOptionValueEdit, IOptionValueForm } from '../../types/option-value';
import { getAPIRoute } from '../../utils/api-route';
import ConfirmDialog from '../../components/common/confirm/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { IDialogConfig } from '../../types/dialog';
import toast from '../../utils/toast';
import { useLoaderContext } from '../../contexts/LoaderContext';

export default function NameEditModal(props: {
  handleCancelClick: any;
  isVisible: boolean;
  isAdd: any;
  setIsAdd: any;
  setIsVisible: any;
  header: any;
  selectedOptionValue: any;
  parentOptionValues: any;
  optionValues: any;
  currentTypeCode: any;
  apiCall: any;
  parentTypeCode: any;
  isEditable: any;
  setIsEditable: any;
  setParentOptionValues: any;
}) {
  const {
    isAdd,
    handleCancelClick,
    header,
    selectedOptionValue,
    parentOptionValues,
    currentTypeCode,
    apiCall,
    parentTypeCode,
    isEditable,
    setParentOptionValues,
  } = props;
  const [yesNo, setYesNo] = useState<IOptionvalue[]>([]);
  const { t } = useTranslation();
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  let currentOptionType: any;
  const [languages, setLanguages] = useState<IOptionvalue[]>([]);
  let index: any;
  const modalConfig: IModalConfig = {
    width: 1011,
    height: 350,
    visible: props.isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };
  const { setIsLoading } = useLoaderContext();
  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-management-edit-modal',
    };
  }, []);

  const getYesNo = async () => {
    await api
      .get({
        url: apiConfig.trueFalse,
      })
      .then((res: IOptionvalue[]) => {
        setYesNo(res);
      });
  };

  const getLanguages = async () => {
    await api
      .get({
        url: apiConfig.optionsSupportingLanguages,
      })
      .then((res: IOptionvalue[]) => {
        setLanguages(res);
      });
  };

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: isAdd
      ? t('toast.confirmAddOptionValue')
      : t('toast.confirmEditOptionValue'),
    handleSubmit: () => {
      onValueSubmit();
      setShowConfirmSave(false);
    },
    handleClose: () => {
      setShowConfirmSave(false);
    },
  };

  const defaultOptionFormValues: IOptionValueForm = {
    languageKey: '',
    optionValueText: '',
    optionValueDesc: '',
    optionValueDesc2: '',
    displayOrder: 0,
    isDefault: false,
    parentOption: '',
    systemDefined: '',
  };

  const optionValueForm = useForm({
    defaultValues: defaultOptionFormValues,
  });
  const { getValues } = optionValueForm;
  let listOptionForm: IOptionValueForm = getValues();

  const onClickSave = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      setShowConfirmSave(true);
    }
  };

  const onValueSubmit = () => {
    listOptionForm = optionValueForm.getValues();
    index = parentOptionValues.findIndex(
      (item: any) => item.optionValueCode === listOptionForm.parentOption
    );
    currentOptionType = parentOptionValues[index];
    if (isAdd) {
      const roleJsonEdit: IOptionValueEdit = {
        languageKey: listOptionForm.languageKey,
        optionValueText: listOptionForm.optionValueText,
        optionValueDesc: listOptionForm.optionValueDesc,
        optionValueDesc2: listOptionForm?.optionValueDesc2,
        displayOrder: listOptionForm?.displayOrder,
        isDefault: listOptionForm.isDefault,
        parentOptionTypeCode: currentOptionType?.optionTypeCode,
        parentOptionValueCode: currentOptionType?.optionValueCode,
      };
      api
        .post(
          {
            url: getAPIRoute('optionValueAddEdit', [currentTypeCode]),
            data: roleJsonEdit,
          },
          setIsLoading
        )
        .then((response) => {
          if (response == true) {
            toast.success({
              title: t('toast.addOptionValueSuccess'),
            });
            handleCancelClick();
            apiCall(currentTypeCode);
          }
        });
    } else {
      const roleJsonEdit: IOptionValueEdit = {
        languageKey: listOptionForm.languageKey,
        optionValueText: listOptionForm.optionValueText,
        optionValueDesc: listOptionForm.optionValueDesc,
        optionValueDesc2: listOptionForm?.optionValueDesc2,
        displayOrder: listOptionForm?.displayOrder,
        isDefault: listOptionForm.isDefault,
        parentOptionTypeCode: currentOptionType?.optionTypeCode,
        parentOptionValueCode: currentOptionType?.optionValueCode,
      };

      api
        .put(
          {
            url: getAPIRoute('optionValueAddEdit', [
              selectedOptionValue.optionTypeCode,
            ]),
            data: roleJsonEdit,
            params: {
              optionValueCode: selectedOptionValue?.optionValueCode,
              optionValueInt: selectedOptionValue.optionValueInt,
            },
          },
          setIsLoading
        )
        .then((response) => {
          if (response == true) {
            toast.success({
              title: t('toast.updatedSuccessfully'),
            });
            handleCancelClick();
            apiCall(currentTypeCode);
          }
        });
    }
  };
  useEffect(() => {
    if (parentTypeCode == null) {
      setParentOptionValues([]);
    }
  }, []);

  useEffect(() => {
    optionValueForm.reset();
    getYesNo();
    getLanguages();
    if (selectedOptionValue) {
      optionValueForm.reset(
        {
          languageKey: selectedOptionValue.languageKey,
          optionValueText: selectedOptionValue.optionValueText,
          optionValueDesc: selectedOptionValue.optionValueDesc,
          optionValueDesc2: selectedOptionValue.optionValueDesc2,
          displayOrder: selectedOptionValue.displayOrder,
          isDefault: selectedOptionValue.isDefault,
          parentOption: selectedOptionValue.parentOptionValueCode,
          systemDefined: selectedOptionValue.isSystemDefined,
        },
        { keepDirty: true }
      );
    }
  }, [selectedOptionValue]);
  useEffect(() => {
    optionValueForm.reset();
  }, [isAdd]);

  useEffect(() => {
    if (isAdd && languages?.length > 0) {
      const defaultLanguage = languages.find((item) => item.isDefault);
      if (defaultLanguage) {
        optionValueForm.resetField('languageKey', {
          defaultValue: defaultLanguage?.optionValueCode,
        });
      }
    }
  }, [isAdd, languages]);

  return (
    <div className="m-l-model-popup__wrap">
      <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
        <div className="m-l-modal__header">
          <h2 className="modal-title">
            {isAdd ? (
              <div>
                {t('labels.add')} {header}
              </div>
            ) : isEditable ? (
              <div>
                {t('labels.edit')} {header}
              </div>
            ) : (
              <div>{header}</div>
            )}
          </h2>
          <div className="m-l-modal-close-button-block">
            <Button
              className="app-c-btn app-c-icon-only-btn"
              onClick={handleCancelClick}
            >
              <icons.IconCross />
            </Button>
          </div>
        </div>
        <div className="m-l-modal__body">
          <ScrollView width="100%" height="100%">
            <div className="m-l-adnw-form-panel">
              <FormProvider {...optionValueForm}>
                <OptionValueForm
                  yesNo={yesNo}
                  languages={languages}
                  parentOptionValues={parentOptionValues}
                />
              </FormProvider>
            </div>
          </ScrollView>
        </div>
        <div className="m-l-modal__footer">
          <Button
            className="app-c-btn app-c-btn--secondary"
            onClick={handleCancelClick}
          >
            {t('buttons.cancel')}
          </Button>
          {isEditable && (
            <Button
              className="app-c-btn app-c-btn--primary min-btn-width"
              onClick={onClickSave}
            >
              {t('buttons.save')}
            </Button>
          )}
        </div>
      </Modal>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={showConfirmSave}
      />
    </div>
  );
}
