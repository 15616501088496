import React, { useEffect, useMemo, useState } from 'react';
import Button from 'devextreme-react/button';
import './CreateVetRequest.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { parseISO } from 'date-fns';
import { IVetRequestForm } from '../../../types/vet-request';
import CreateVetRequestForm from '../../../components/vet-request/create-vet-request/create-vet-request-form/CreateVetRequestForm';
import LoadPortForm from '../../../components/vet-request/create-vet-request/load-port-form/LoadPortForm';
import DischargePortForm from '../../../components/vet-request/create-vet-request/discharge-port-form/DischargePortForm';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { IOptionvalue } from '../../../types/master-data';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import VesselInfoBanner from '../../vessel-info/VesselInfoBanner';
import PreviewVetRequest from '../preview-vet-request/PreviewVetRequest';
import { IDialogConfig } from '../../../types/dialog';
import ConfirmDialog from '../../../components/common/confirm/ConfirmDialog';
import VetComments from '../vet-comments/Vet-Comments';
import { VetRequestStatus } from '../../../enums/vet-request-status-enum';
import { useDataStore } from '../../../hooks/useDataStore';
import {
  getAdditionalInfo,
  getBuList,
  getEmail,
  getEmployeeId,
  getLoginBUName,
  getUserName,
} from '../../../utils/jwt-decode';
import { useAuth } from '../../../contexts/AuthProvider';
import countryList from '../../../components/Country.json';
import Tooltip from 'devextreme-react/tooltip';
import toast from '../../../utils/toast';
import icons from '../../../components/common/icons/icons';
import CopyToClipboard from '../../../components/common/copy-to-clipboard/CopyToClipboard';

export default function CreateVetRequest() {
  const usersName = getUserName();
  const currentlyLoggedInBu = getLoginBUName();
  const buList: any = getBuList();
  const [imoNumber, setImoNumber] = useState<string>();
  const [vetRequestId, setVetRequestId] = useState<string>();
  const [newVetRequest, setNewVetRequest] = useState<string>();
  const [cargoTypes, setCargoTypes] = useState<IOptionvalue[]>([]);
  const [cargo, setCargo] = useState<IOptionvalue[]>([]);
  const [incoTerms, setIncoTerms] = useState<IOptionvalue[]>([]);
  const [terminals, setTerminals] = useState<any>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsLoading } = useLoaderContext();
  const [showPreview, setShowPreview] = useState(false);
  const [vetRequestDetails, setVetRequestDetails] = useState<any>();
  const [confirmBackDialog, setConfirmBackDialog] = useState(false);
  const [confirmPreviewDialog, setConfirmPreviewDialog] = useState(false);
  const location = useLocation();
  const [emails, setEmails] = useState<any>([]);
  const [emailError, setEmailError] = useState('');
  const { getDataSource } = useDataStore();
  const tokenFlag = getAdditionalInfo();
  const portDataSource = getDataSource('portId', apiConfig.activePorts);
  const auth = useAuth();
  const [checkBerthFit, setCheckBerthFit] = useState(false);
  const [laycanFromDate, setLaycanFromDate] = useState();
  const [laycanToDate, setLaycanToDate] = useState();
  const userEmail = getEmail();
  const [lowestLoadDate, setLowestLoadDate] = useState<any>();
  const [warning, setWarning] = useState<any>();
  const [tempVetId, setTempVetId] = useState<string>();
  const employeeId = Number(getEmployeeId());
  const [attachmentsAdded, setAttachmentsAdded] = useState<any>([]);
  const [isLoaded, setIsloaded] = useState<boolean>(false);

  const selectedBu = JSON.parse(buList)?.filter((item: any) => {
    return item.isDefault == true;
  });

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setConfirmBackDialog(false);
      if (newVetRequest === 'newVetRequest') {
        removeTempVetData();
        navigate('/vessel-info', { state: { imoNumber: imoNumber } });
      } else if (vetRequestId) {
        navigate('/vessel-info/vet-request', {
          state: { imoNumber: imoNumber, vetRequestId: vetRequestId },
        });
      } else {
        removeTempVetData();
        navigate('/vessel-info', { state: { imoNumber: imoNumber } });
      }
    },
    handleClose: () => {
      setConfirmBackDialog(false);
    },
  };

  const confirmPreviewDialogConfig: IDialogConfig = {
    id: 'confirmPreview',
    title: 'Disclaimer',
    content: t('toast.proceedWithoutDischargePort'),
    okButtonLabel: 'Proceed',
    cancelButtonLabel: 'Cancel',
    handleSubmit: () => {
      setConfirmPreviewDialog(false);
      previewVetRequest();
    },
    handleClose: () => {
      setConfirmPreviewDialog(false);
    },
  };

  const [previewDialogConfig, setPreviewDialogConfig] = useState(
    confirmPreviewDialogConfig
  );

  const defaultValues: IVetRequestForm = {
    vetRequestId: null,
    requestDate: new Date(),
    requestorUserId: usersName,
    emailIds: '',
    buId: currentlyLoggedInBu,
    cargoType: '',
    vetRequestCargos: [],
    voyageNumber: '',
    incoTerm: '',
    laycanFromDate: '',
    laycanToDate: '',
    berthFitCheck: true,
    status: '',
    reviewerId: null,
    vetRequestLoadPorts: [
      {
        loadPortId: null,
        berthFitCheck: true,
        portName: '',
        portDetails: [],
        estimatedDateOfLoad: '',
        vetRequestLoadPortTerminals: [],
        portWarning: '',
      },
    ],
    vetRequestDischargePorts: [
      {
        dischargePortId: null,
        berthFitCheck: true,
        portName: '',
        portDetails: [],
        estimatedDateOfDischarge: '',
        vetRequestDischargePortTerminals: [],
        portWarning: '',
      },
    ],
  };

  const createVetRequestForm = useForm({
    defaultValues: defaultValues,
  });

  const { isDirty } = createVetRequestForm.formState;

  useEffect(() => {
    if (location.state) {
      const { imoNumber, vetRequestId, newVetRequest }: any = location.state;
      setImoNumber(imoNumber);
      setVetRequestId(vetRequestId);
      setNewVetRequest(newVetRequest);
    }
  }, [location.state]);

  useEffect(() => {
    if (!vetRequestDetails) {
      const mail = [];
      mail.push(userEmail);
      if (selectedBu[0]?.isGroupEmail) {
        if (String(userEmail) != String(selectedBu[0].buEmail)) {
          mail.push(selectedBu[0].buEmail);
        }
      }
      setEmails(mail);
    }
  }, [vetRequestDetails]);

  const onClickBack = () => {
    const vetRequestEmails = vetRequestDetails?.emailIds;
    const emailChanged =
      vetRequestEmails && vetRequestEmails != ''
        ? JSON.stringify(vetRequestEmails) == JSON.stringify(emails.toString())
        : emails.length == 0
        ? true
        : false;
    if (isDirty || !emailChanged) {
      setConfirmBackDialog(true);
    } else if (newVetRequest === 'newVetRequest') {
      removeTempVetData();
      navigate('/vessel-info', { state: { imoNumber: imoNumber } });
    } else if (vetRequestId) {
      navigate('/vessel-info/vet-request', {
        state: { imoNumber: imoNumber, vetRequestId: vetRequestId },
      });
    } else {
      removeTempVetData();
      navigate('/vessel-info', { state: { imoNumber: imoNumber } });
    }
  };

  const getVesselWarning = async () => {
    await api
      .get({
        url: apiConfig.vesselsWarning + imoNumber,
      })
      .then((response: any) => {
        setWarning(response);
      });
  };

  const getCargoTypes = async () => {
    await api
      .get({ url: apiConfig.cargoType }, setIsLoading)
      .then((data: IOptionvalue[]) => {
        setCargoTypes(data);
      });
  };

  const getCargos = async () => {
    await api
      .get({ url: apiConfig.cargo }, setIsLoading)
      .then((data: IOptionvalue[]) => {
        setCargo(data);
      });
  };

  const getIncoTerms = async () => {
    await api
      .get({ url: apiConfig.buIncoTerms }, setIsLoading)
      .then((data: any) => {
        setIncoTerms(data);
      });
  };

  const getTerminals = async () => {
    await api
      .get({ url: apiConfig.terminals }, setIsLoading)
      .then((data: any) => {
        setTerminals(data);
      });
  };

  const [bannerInfoDetails, setBannerInfoDetails] = useState([]) as any;

  const vesselBannerDetails = async () => {
    await api
      .get({ url: apiConfig.vessels + imoNumber }, setIsLoading)
      .then((data) => {
        setBannerInfoDetails(data);
      });
  };

  const getVetRequestDetails = async () => {
    await api
      .get({ url: apiConfig.vetRequests + vetRequestId })
      .then((data: any) => {
        data.vetRequestLoadPorts = data.vetRequestLoadPorts
          ? data.vetRequestLoadPorts
          : [];
        setVetRequestDetails(data);
      });
  };
  const initialLoad = async () => {
    await Promise.all([
      getCargoTypes(),
      getCargos(),
      getIncoTerms(),
      getTerminals(),
    ]);
    setIsloaded(true);
  };

  useEffect(() => {
    initialLoad();
  }, []);

  useEffect(() => {
    if (imoNumber) {
      vesselBannerDetails();
      getVesselWarning();
    }
  }, [imoNumber]);

  useEffect(() => {
    if (vetRequestId) {
      getVetRequestDetails();
    }
  }, [vetRequestId]);

  useEffect(() => {
    createVetRequestForm.setValue('buId', getLoginBUName());
  }, [auth.currentlyLoggedInBu]);

  const resetValues = () => {
    createVetRequestForm.reset(defaultValues);
    setVetRequestDetails(undefined);
  };

  useEffect(() => {
    createVetRequestForm.reset();
    if (vetRequestDetails) {
      if (vetRequestDetails.imoNumber == imoNumber) {
        if (vetRequestDetails?.reviewer?.employeeId == employeeId) {
          if (
            !(
              vetRequestDetails.status == VetRequestStatus.assigned ||
              vetRequestDetails.status == VetRequestStatus.processing
            )
          ) {
            setShowPreview(!showPreview);
          }
        } else {
          if (vetRequestDetails.status != VetRequestStatus.requested) {
            setShowPreview(!showPreview);
          }
        }
        let cargoType: any = '';
        const vetRequestCargos: any = [];
        if (vetRequestDetails.vetRequestCargos.length > 0) {
          const filteredCargo: any = cargo.find(
            (cargo: any) =>
              cargo.optionValueCode ==
              vetRequestDetails.vetRequestCargos[0].cargo
          );
          vetRequestDetails.vetRequestCargos.forEach((vetRequestCargo: any) => {
            vetRequestCargos.push(vetRequestCargo.cargo);
          });
          cargoType = filteredCargo?.parentOptionValueCode;
        }
        const vetRequestLoadPorts =
          vetRequestDetails.vetRequestLoadPorts.length > 0
            ? constructVetRequestLoadPorts(
                vetRequestDetails.vetRequestLoadPorts
              )
            : [
                {
                  loadPortId: null,
                  berthFitCheck: vetRequestDetails.berthFitCheck,
                  estimatedDateOfLoad: '',
                  portName: '',
                  portDetails: [],
                  vetRequestLoadPortTerminals: [],
                  portWarning: '',
                },
              ];
        const vetRequestDischargePorts =
          vetRequestDetails.vetRequestDischargePorts.length > 0
            ? constructVetRequestDischargePorts(
                vetRequestDetails.vetRequestDischargePorts
              )
            : [
                {
                  dischargePortId: null,
                  berthFitCheck: vetRequestDetails.berthFitCheck,
                  estimatedDateOfDischarge: '',
                  portName: '',
                  portDetails: [],
                  vetRequestDischargePortTerminals: [],
                  portWarning: '',
                },
              ];
        if (vetRequestDetails.emailIds != '') {
          setEmails(vetRequestDetails.emailIds.split(','));
        }
        createVetRequestForm.reset(
          {
            vetRequestId: vetRequestDetails.vetRequestId,
            requestDate: parseISO(vetRequestDetails.requestDate),
            requestorUserId: vetRequestDetails.requestorUser?.employeeName
              ? vetRequestDetails.requestorUser?.employeeName
              : usersName,
            buId: vetRequestDetails.buName,
            cargoType: cargoType,
            vetRequestCargos: vetRequestCargos,
            voyageNumber: vetRequestDetails.voyageNumber,
            incoTerm: vetRequestDetails.incoTerm,
            laycanFromDate: vetRequestDetails.laycanFromDate
              ? parseISO(vetRequestDetails.laycanFromDate)
              : null,
            laycanToDate: vetRequestDetails.laycanToDate
              ? parseISO(vetRequestDetails.laycanToDate)
              : null,
            berthFitCheck: vetRequestDetails.berthFitCheck,
            status: vetRequestDetails.status,
            reviewerId: vetRequestDetails.reviewerId,
            vetRequestLoadPorts: vetRequestLoadPorts,
            vetRequestDischargePorts: vetRequestDischargePorts,
          },
          { keepDirty: true }
        );
      } else {
        navigate('/vet-request/', { state: { imoNumber: imoNumber } });
      }
    }
  }, [vetRequestDetails]);

  const constructVetRequestLoadPorts = (vetRequestLoadPorts: any) => {
    const loadPorts: any = [];
    vetRequestLoadPorts.forEach((vetRequestLoadPort: any, index: any) => {
      const portDetails: any = [];
      portDetails.push({
        id: vetRequestLoadPort.loadPortId,
        portName: vetRequestLoadPort.loadPort?.portName,
        countryCode: vetRequestLoadPort.loadPort?.countryCode,
      });
      loadPorts.push({
        vetRequestLoadPortId: vetRequestLoadPort.vetRequestLoadPortId,
        portName: vetRequestLoadPort.loadPort?.portName,
        vetRequestId: vetRequestLoadPort.vetRequestId,
        berthFitCheck: vetRequestLoadPort.berthFitCheck,
        loadPortId: vetRequestLoadPort.loadPortId,
        portDetails: portDetails,
        estimatedDateOfLoad: vetRequestLoadPort.estimatedDateOfLoad
          ? parseISO(vetRequestLoadPort.estimatedDateOfLoad)
          : null,
        portWarning: '',
        vetRequestLoadPortTerminals: [],
      });
      if (vetRequestLoadPort.vetRequestLoadPortTerminals.length != 0) {
        vetRequestLoadPort.vetRequestLoadPortTerminals.forEach(
          (vetRequestLoadPortTerminal: any) => {
            loadPorts[index].vetRequestLoadPortTerminals?.push(
              vetRequestLoadPortTerminal.terminalId
            );
          }
        );
      }
    });
    return loadPorts;
  };

  const constructVetRequestDischargePorts = (vetRequestDischargePorts: any) => {
    const dischargePorts: any = [];
    vetRequestDischargePorts.forEach(
      (vetRequestDischargePort: any, index: any) => {
        const portDetails: any = [];
        portDetails.push({
          id: vetRequestDischargePort.dischargePortId,
          portName: vetRequestDischargePort.dischargePort?.portName,
          countryCode: vetRequestDischargePort.dischargePort?.countryCode,
        });
        dischargePorts.push({
          vetRequestDischargePortId:
            vetRequestDischargePort.vetRequestDischargePortId,
          vetRequestId: vetRequestDischargePort.vetRequestId,
          dischargePortId: vetRequestDischargePort.dischargePortId,
          berthFitCheck: vetRequestDischargePort.berthFitCheck,
          portDetails: portDetails,
          estimatedDateOfDischarge:
            vetRequestDischargePort.estimatedDateOfDischarge
              ? parseISO(vetRequestDischargePort.estimatedDateOfDischarge)
              : null,
          portWarning: '',
          vetRequestDischargePortTerminals: [],
        });
        if (
          vetRequestDischargePort.vetRequestDischargePortTerminals.length != 0
        ) {
          vetRequestDischargePort.vetRequestDischargePortTerminals.forEach(
            (vetRequestDischargePortTerminal: any) => {
              dischargePorts[index].vetRequestDischargePortTerminals?.push(
                vetRequestDischargePortTerminal.terminalId
              );
            }
          );
        }
      }
    );
    return dischargePorts;
  };

  const preview = (e: any) => {
    if (e.validationGroup.validate().isValid && emailError == '') {
      const vetRequestValues = createVetRequestForm.getValues();
      const dischargePorts = vetRequestValues.vetRequestDischargePorts?.filter(
        (dischargePort: any) =>
          dischargePort.dischargePortId != null &&
          dischargePort.dischargePortId != ''
      );

      const loadPorts = vetRequestValues.vetRequestLoadPorts?.filter(
        (loadPort: any) =>
          loadPort.loadPortId != null && loadPort.loadPortId != ''
      );
      if (
        dischargePorts &&
        dischargePorts.length == 0 &&
        loadPorts &&
        loadPorts.length == 0
      ) {
        toast.error({
          title: t('toast.error'),
          message: t('toast.vetRequestPortValidation'),
        });
        return;
      }
      if (dischargePorts && dischargePorts.length == 0) {
        setPreviewDialogConfig({
          ...confirmPreviewDialogConfig,
          content: t('toast.proceedWithoutDischargePort'),
        });
        setConfirmPreviewDialog(true);
      } else if (loadPorts && loadPorts.length == 0) {
        setPreviewDialogConfig({
          ...confirmPreviewDialogConfig,
          content: t('toast.proceedWithoutLoadPort'),
        });
        setConfirmPreviewDialog(true);
      } else {
        previewVetRequest();
      }
    }
    return;
  };

  const previewVetRequest = () => {
    setShowPreview(!showPreview);
  };

  const filteredCountryList = countryList.find(
    (countryItem) => countryItem.iso_3 == bannerInfoDetails?.iso3
  );

  const removeTempVetData = async () => {
    if (tempVetId && !vetRequestId) {
      await api.delete({ url: apiConfig.deleteTempVetData + tempVetId }).then();
    }
  };

  const copyText = useMemo(() => {
    const text = bannerInfoDetails.imoDisplayName
      ? `${bannerInfoDetails.vesselName} (IMO: ${bannerInfoDetails.imoDisplayName})`
      : `${bannerInfoDetails.vesselName} (VIN: ${bannerInfoDetails.vinNumber})`;
    return text;
  }, [bannerInfoDetails]);

  return (
    <div className="inner-wrapper">
      <div>
        <div className="m-l-bt-outer-wrap">
          <div className="m-l-list-title-block">
            <div className="m-l-inner-page-header m-l-inner-page-header-with-mail-icon">
              <div className="m-l-inner-page-header-left-block">
                <div className="m-l-ttl-status-wrap">
                  <div className="m-l-br-ttl-hldr">
                    <div className="m-l-title-block">
                      <div
                        className="m-l-page-main-heading page-navigation-link"
                        // onClick={() => {
                        //   removeTempVetData();
                        //   navigate('/vessel-info', {
                        //     state: { imoNumber: imoNumber },
                        //   });
                        // }}
                      >
                        <Link
                          className="m-l-page-main-heading"
                          to={'/vessel-info'}
                          state={{ imoNumber: imoNumber }}
                          onClick={() => removeTempVetData()}
                          onContextMenu={() =>
                            localStorage.setItem('imo', imoNumber as string)
                          }
                        >
                          {bannerInfoDetails?.vesselName}
                        </Link>
                      </div>
                      {warning?.warning && (
                        <>
                          <div className="m-l-page-icon" id="warning">
                            {/* <img
                              src={imageUrl.warningToastIcon}
                              className="img-responsive"
                              alt="success icon"
                              id="warning"
                            /> */}
                            <icons.vesselWarning />
                          </div>
                          <Tooltip
                            target={'#warning'}
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            position="right"
                            contentRender={() => {
                              return (
                                <div className="m-l-tooltip-wrapper m-l-warning-icon-tooltip">
                                  <span>{warning.sanctionedMessage}</span>
                                  <span>{warning.restrictedMessage}</span>
                                </div>
                              );
                            }}
                          />
                        </>
                      )}
                      <CopyToClipboard text={copyText} />
                    </div>
                    <div className="m-l-sub-title-details-block">
                      <div className="m-l-flag-wrap">
                        <span className="ml-ads-tbl-flag">
                          <img
                            src={filteredCountryList?.flag}
                            className="img-responsive"
                            alt="flag"
                            height={20}
                            width={20}
                          />
                        </span>
                        <span className="m-l-page-id-text">
                          {bannerInfoDetails.flagName}
                        </span>
                      </div>
                      <div className="app-l-vertical-seperator"></div>
                      {bannerInfoDetails && bannerInfoDetails.imoDisplayName ? (
                        <div className="m-l-page-sub-heading">
                          {t('labels.IMO')}: {bannerInfoDetails?.imoDisplayName}
                        </div>
                      ) : (
                        <div className="m-l-page-sub-heading">
                          {t('labels.vin')}: {bannerInfoDetails?.vinNumber}
                        </div>
                      )}
                      {vetRequestId && (
                        <>
                          <div className="app-l-vertical-seperator"></div>
                          <span className="m-l-page-id-text">
                            {t('labels.VETID')}: {vetRequestDetails?.vetId}
                          </span>
                          <div className="app-l-vertical-seperator"></div>
                        </>
                      )}
                      {vetRequestId && (
                        <>
                          <div className="m-l-status-block">
                            <div className="m-c-form-group">
                              <label className="m-l-input-label">
                                <span className="m-l-page-id-text">Status</span>
                              </label>
                              <div className="m-l-status-block-dropdown">
                                <Button className="m-l-status-btn m-l-status-btn-no-icon status-bg-orange status-bg-orange-border">
                                  {vetRequestDetails?.status}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="m-l-inner-page-body-wrap m-l-srv-pdng">
          <VesselInfoBanner
            bannerInfoDetails={bannerInfoDetails}
            isSanction={warning?.warning}
          ></VesselInfoBanner>
          {!showPreview &&
            (!vetRequestId ||
              vetRequestDetails?.status == VetRequestStatus.requested ||
              vetRequestDetails?.status == VetRequestStatus.assigned ||
              vetRequestDetails?.status == VetRequestStatus.processing) && (
              <>
                <div className="m-l-form-panel-block m-l-card-form">
                  <div className="m-l-form-panel-header-block">
                    <div className="m-l-form-panel-header-left-block">
                      {!vetRequestId && (
                        <div className="m-l-page-small-heading">
                          {t('labels.createVetRequest')}
                        </div>
                      )}
                      {vetRequestId && (
                        <div className="m-l-page-small-heading">
                          {t('labels.editVetRequest')}
                        </div>
                      )}
                    </div>
                    <div className="m-l-form-panel-header-right-block">
                      <Button
                        onClick={onClickBack}
                        className="app-c-btn app-c-btn--secondary"
                      >
                        {t('buttons.back')}
                      </Button>
                      <Button
                        onClick={preview}
                        className="app-c-btn app-c-btn--primary min-btn-width"
                      >
                        {t('buttons.preview')}
                      </Button>
                    </div>
                  </div>
                  <div className="m-l-form-panel-body-block m-l-pdng-btm0">
                    <FormProvider {...createVetRequestForm}>
                      <CreateVetRequestForm
                        attachmentsAdded={attachmentsAdded}
                        setAttachmentsAdded={setAttachmentsAdded}
                        cargoTypes={cargoTypes}
                        cargo={cargo}
                        incoTerms={incoTerms}
                        emails={emails}
                        setEmails={setEmails}
                        emailError={emailError}
                        setEmailError={setEmailError}
                        setCheckBerthFit={setCheckBerthFit}
                        imoNumber={bannerInfoDetails.imoNumber}
                        attachments={vetRequestDetails?.vetAttachments}
                        setLaycanFromDate={setLaycanFromDate}
                        setLaycanToDate={setLaycanToDate}
                        setTempVetId={setTempVetId}
                        tempVetId={tempVetId}
                        vetRequestDetails={
                          vetRequestDetails ? vetRequestDetails : null
                        }
                      />
                    </FormProvider>
                  </div>
                </div>
                <div className="m-l-ldprt-dischrg-row">
                  <div className="row m-l-custom-eight-space-row">
                    <FormProvider {...createVetRequestForm}>
                      <LoadPortForm
                        portDataSource={portDataSource}
                        terminals={terminals}
                        checkBerthFit={checkBerthFit}
                        setCheckBerthFit={setCheckBerthFit}
                        laycanFromDate={laycanFromDate}
                        laycanToDate={laycanToDate}
                        setLowestLoadDate={setLowestLoadDate}
                        vetRequestDetails={
                          vetRequestDetails ? vetRequestDetails : null
                        }
                        isMastersLoaded={isLoaded}
                      />
                    </FormProvider>
                    <FormProvider {...createVetRequestForm}>
                      <DischargePortForm
                        portDataSource={portDataSource}
                        terminals={terminals}
                        checkBerthFit={checkBerthFit}
                        setCheckBerthFit={setCheckBerthFit}
                        laycanFromDate={laycanFromDate}
                        laycanToDate={laycanToDate}
                        lowestLoadDate={lowestLoadDate}
                        vetRequestDetails={
                          vetRequestDetails ? vetRequestDetails : null
                        }
                        isMastersLoaded={isLoaded}
                      />
                    </FormProvider>
                  </div>
                </div>
              </>
            )}

          {showPreview && (
            <PreviewVetRequest
              cargoList={cargo}
              imoNumber={imoNumber}
              vetRequestId={vetRequestId}
              vetRequestValues={createVetRequestForm.getValues()}
              previewVetRequest={previewVetRequest}
              terminals={terminals}
              incoTerms={incoTerms}
              vetRequestDetails={vetRequestDetails}
              emails={emails}
              getVetRequestDetails={getVetRequestDetails}
              tempVetId={tempVetId}
              resetValues={resetValues}
            />
          )}
          {!showPreview && (
            <div className="row m-l-custom-eight-space-row">
              <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder m-l-page-with-btm-panel">
                <div className="m-l-group-button-btms">
                  <Button
                    className="app-c-btn app-c-btn--secondary"
                    onClick={onClickBack}
                  >
                    {t('buttons.back')}
                  </Button>
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    onClick={preview}
                  >
                    {t('buttons.preview')}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* bottom panel */}
      {
        <VetComments
          vetId={vetRequestDetails?.vetId}
          tokenFlag={tokenFlag}
          tempVetId={tempVetId}
          setTempVetId={setTempVetId}
        />
      }

      {/* file upload modal */}
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={confirmBackDialog}
      />
      <ConfirmDialog
        dialogConfig={previewDialogConfig}
        isOpen={confirmPreviewDialog}
      />
    </div>
  );
}
