import React, { useMemo, useEffect, useState, useRef } from 'react';
import Button from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import TabPanel from 'devextreme-react/tab-panel';
import icons from '../../../components/common/icons/icons';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { IDialogConfig } from '../../../types/dialog';
import ConfirmDialog from '../../../components/common/confirm/ConfirmDialog';
import { IVetComments, IVetCommentsEdit } from '../../../types/vet-comments';
import { getAPIRoute } from '../../../utils/api-route';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { Restricted, usePermission } from '../../../contexts/PermissionContext';
import Validator from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import { VetRequestStatusCode } from '../../../enums/vet-request-status-enum';
import TextArea from 'devextreme-react/text-area';
import { CompanyType } from '../../../enums/company-type-enum';
import { Notes } from '../../../enums/vet-notes-enum';

export default function VetComments(props: {
  vetId?: string;
  tokenFlag?: any;
  processingManualStatus?: any;
  tempVetId?: string;
  setTempVetId?: any;
}) {
  const { vetId, tokenFlag, processingManualStatus, tempVetId, setTempVetId } =
    props;
  const { findFeature, featureList } = usePermission();
  const itemsMessage = useMemo(() => {
    return [
      {
        title: 'Requestor Comment',
        template: 'RequestorComment',
        visible: findFeature('VetRequest.RequestorComment'),
      },
      {
        title: 'Public Note',
        template: 'PublicNote',
        visible: findFeature('VetRequest.PublicNote'),
      },
      {
        title: 'Private Note',
        template: 'PrivateNote',
        visible: findFeature('VetRequest.PrivateNote'),
      },
    ];
  }, [featureList]);

  const relationRef = useRef<any>(null);
  const { UTCFormat } = useDateFormat();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsLoading } = useLoaderContext();
  const [reqComment, setReqComment] = React.useState('');
  const [commentIdReq, setCommentIdReq] = useState<number>(0);
  const [commentIdPublic, setCommentIdPublic] = useState<number>(0);
  const [commentIdPrivate, setCommentIdPrivate] = useState<number>(0);
  const [publicNote, setPublicNote] = React.useState('');
  const [privateNote, setPrivateNote] = React.useState('');
  const [publicNotesList, setPublicNotesList] = useState<any>([]);
  const [privateNotesList, setPrivateNotesList] = useState<any>([]);
  const [reqCommentList, setReqCommentList] = useState<any>([]);
  const [showEditButtonReq, setShowEditButtonReq] = useState(false);
  const [showEditButtonPublic, setShowEditButtonPublic] = useState(false);
  const [showEditButtonPrivate, setShowEditButtonPrivate] = useState(false);
  const [showAddButtonReq, setShowAddButtonReq] = useState(true);
  const [showAddButtonPublic, setShowAddButtonPublic] = useState(true);
  const [showAddButtonPrivate, setShowAddButtonPrivate] = useState(true);
  const [confirmBackDialog, setConfirmBackDialog] = useState(false);
  const tabRef = useRef<TabPanel>(null);

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setConfirmBackDialog(false);
      navigate(-1);
    },
    handleClose: () => {
      setConfirmBackDialog(false);
    },
  };

  const getPublicNotes = async (tempId?: string) => {
    await api
      .get({
        url: getAPIRoute('vetRequestGetNotes', ['P']),
        params: { vetId: vetId, tempVetId: tempVetId ? tempVetId : tempId },
      })
      .then((res) => {
        setPublicNotesList(res);
      });
  };

  const getPrivateNotes = async (tempId?: string) => {
    await api
      .get({
        url: getAPIRoute('vetRequestGetNotes', ['R']),
        params: { vetId: vetId, tempVetId: tempVetId ? tempVetId : tempId },
      })
      .then((res) => {
        setPrivateNotesList(res);
      });
  };

  const getReqComments = async (tempId?: string) => {
    await api
      .get({
        url: getAPIRoute('vetRequestGetNotes', ['C']),
        params: { vetId: vetId, tempVetId: tempVetId ? tempVetId : tempId },
      })
      .then((res) => {
        setReqCommentList(res);
      });
  };

  const handleNotes = (flag: any) => {
    let message: any;
    if (flag == Notes.RequestorComments) {
      message = reqComment;
    }
    if (flag == Notes.PublicNote) {
      message = publicNote;
    }
    if (flag == Notes.PrivateNote) {
      message = privateNote;
    }

    const roleJsonEdit: IVetComments = {
      vetRequestNotesId: 0,
      vetId: vetId,
      commentBody: message,
      vetRequestNoteType: flag,
      tempVetId: !vetId ? tempVetId : undefined,
    };

    api
      .post(
        { url: apiConfig.vetRequestNotes, data: roleJsonEdit },
        setIsLoading
      )
      .then(async (response) => {
        if (response?.tempVetId) {
          setTempVetId(response?.tempVetId);
        }
        if (response) {
          await getPublicNotes(response?.tempVetId);
          await getPrivateNotes(response?.tempVetId);
          await getReqComments(response?.tempVetId);
          refreshFields(flag);
        }
      });
  };

  const isEdited = (flag: any) => {
    if (flag) {
      return true;
    } else {
      return false;
    }
  };

  const isCreatedUser = (flag: any) => {
    if (flag == tokenFlag.employee_id) {
      return true;
    } else {
      return false;
    }
  };

  const userCompanyType = () => {
    if (tokenFlag.company_type == CompanyType.Mars) {
      return false;
    } else {
      return true;
    }
  };

  const flagRefresh = async (flag: any) => {
    if (flag == Notes.RequestorComments) {
      setShowEditButtonReq(false);
      setShowAddButtonReq(true);
    }
    if (flag == Notes.PublicNote) {
      setShowEditButtonPublic(false);
      setShowAddButtonPublic(true);
    }
    if (flag == Notes.PrivateNote) {
      setShowEditButtonPrivate(false);
      setShowAddButtonPrivate(true);
    }
    refreshFields(flag);
  };

  //message panel//
  const [showResults, setShowResults] = useState(false);
  const popUp = () => setShowResults(true);
  const validationGroupReqComment = useRef<ValidationGroup>(null);
  const validationGroupPrivateNote = useRef<ValidationGroup>(null);
  const validationGroupPublicNote = useRef<ValidationGroup>(null);
  const closePopup = () => setShowResults(false);
  const [selectedTab, setSelectedTab] = useState<any>(0);

  const onTabSelectionChanged = () => {
    setSelectedTab(tabRef.current?.instance?.option('selectedIndex'));
  };

  const handleReqComment = () => {
    if (validationGroupReqComment.current?.instance.validate().isValid) {
      handleNotes(Notes.RequestorComments);
    }
  };
  const handlePublicNote = () => {
    if (validationGroupPublicNote.current?.instance.validate().isValid) {
      handleNotes(Notes.PublicNote);
    }
  };
  const handlePrivateNote = () => {
    if (validationGroupPrivateNote.current?.instance.validate().isValid) {
      handleNotes(Notes.PrivateNote);
    }
  };

  const refreshFields = async (flag?: any) => {
    if (flag == Notes.RequestorComments) {
      setReqComment('');
      validationGroupReqComment.current?.instance.reset();
    }
    if (flag == Notes.PublicNote) {
      setPublicNote('');
      validationGroupPublicNote.current?.instance.reset();
    }
    if (flag == Notes.PrivateNote) {
      setPrivateNote('');
      validationGroupPrivateNote.current?.instance.reset();
    }
  };

  const editPublicNote = (flag: any) => {
    let message: any;
    let commentId: any;
    if (flag == Notes.RequestorComments) {
      message = reqComment;
      commentId = commentIdReq;
    }
    if (flag == Notes.PublicNote) {
      message = publicNote;
      commentId = commentIdPublic;
    }
    if (flag == Notes.PrivateNote) {
      message = privateNote;
      commentId = commentIdPrivate;
    }
    const roleJsonEdit: IVetCommentsEdit = {
      vetRequestNotesId: commentId,
      commentBody: message,
    };

    api
      .put({ url: apiConfig.vetRequestNotes, data: roleJsonEdit }, setIsLoading)
      .then(async (response) => {
        if (response == true) {
          await getPrivateNotes();
          await getPublicNotes();
          await getReqComments();
          flagRefresh(flag);
        }
      });
  };

  useEffect(() => {
    // if (vetId && showResults) {
    if (vetId) {
      getPublicNotes();
      getPrivateNotes();
      getReqComments();
    }
  }, [vetId, showResults]);

  useEffect(() => {
    if (
      processingManualStatus &&
      (processingManualStatus === VetRequestStatusCode.waitingforExternalInfo ||
        processingManualStatus ===
          VetRequestStatusCode.waitingforRequestorinfo ||
        processingManualStatus === VetRequestStatusCode.cancelled)
    ) {
      if (vetId) {
        getPublicNotes();
      }
    }
  }, [processingManualStatus]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (relationRef.current && !relationRef.current.contains(event.target)) {
        setShowResults(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [relationRef]);

  const displayComments = (data: string) => {
    const value = data.split('\n');
    return (
      <span>
        {value?.map((element, index) => {
          return (
            element && (
              <span key={index}>
                {element}
                <br />
              </span>
            )
          );
        })}
      </span>
    );
  };

  return (
    <div className="inner-wrapper">
      {/* bottom panel */}
      <div
        className={
          'm-l-panel-from-bottom m-l-panel__bottom ' +
          (showResults ? 'm-l-panel__show' : 'm-l-panel__hide')
        }
      >
        <div
          ref={relationRef}
          className={
            'm-c-panel__container ' +
            (showResults ? 'm-l-panel-expanded' : 'm-l-panel-collapsed')
          }
          onClick={popUp}
        >
          <TabPanel
            items={itemsMessage}
            ref={tabRef}
            itemTitleRender={(data) => {
              return (
                <>
                  <span className="dx-tab-text">{data?.title}</span>
                  {data?.template === 'RequestorComment' &&
                    reqCommentList.length > 0 && (
                      <span className="m-l-vet-comments-notification-mark"></span>
                    )}
                  {data?.template === 'PublicNote' &&
                    publicNotesList.length > 0 && (
                      <span className="m-l-vet-comments-notification-mark"></span>
                    )}
                  {data?.template === 'PrivateNote' &&
                    privateNotesList.length > 0 && (
                      <span className="m-l-vet-comments-notification-mark"></span>
                    )}
                </>
              );
            }}
            className="m-c-tab-block m-c-tab-in-panel m-c-tab-block--full-width"
            onSelectionChanged={onTabSelectionChanged}
          >
            <Template
              name="RequestorComment"
              render={() => {
                if (selectedTab == 0) {
                  return (
                    <div className="m-l-rqst-comment-wrapper">
                      <div className="m-l-rqst-msg-items">
                        {reqCommentList?.map((value: any, index: any) => {
                          return (
                            <div key={index}>
                              <>
                                <div className="m-l-rqst-msg-item">
                                  <div className="m-l-name-date-row">
                                    <div className="m-l-usr-name">
                                      {value.username}
                                    </div>
                                    <ul className="m-l-usr-date-time">
                                      <li>
                                        <span className="m-l-usr-dt-item">
                                          {UTCFormat(value.commentDateTime)}
                                          {isEdited(value.isEdited) && (
                                            <span>{'  Edited'}</span>
                                          )}
                                        </span>
                                      </li>
                                    </ul>
                                    {isCreatedUser(value.employeeId) && (
                                      <div
                                        className="m-l-rqst-edit-icon"
                                        onClick={() => {
                                          setReqComment(value.commentBody),
                                            setShowEditButtonReq(true),
                                            setShowAddButtonReq(false),
                                            setCommentIdReq(
                                              value.vetRequestNotesId
                                            );
                                        }}
                                      >
                                        <icons.IconEdit />
                                      </div>
                                    )}
                                  </div>
                                  <div className="m-l-rqst-msg-block">
                                    {displayComments(value.commentBody)}
                                  </div>
                                </div>
                              </>
                            </div>
                          );
                        })}
                      </div>

                      {userCompanyType() ? (
                        <div>
                          <div className="m-l-msg-input-field">
                            {selectedTab == 0 && (
                              <div className="m-c-form-group">
                                <ValidationGroup
                                  ref={validationGroupReqComment}
                                >
                                  {showResults && (
                                    <TextArea
                                      className="m-c-input-control"
                                      placeholder={t('labels.requestorComment')}
                                      value={reqComment}
                                      onValueChanged={(e) => {
                                        setReqComment(e.value);
                                      }}
                                    >
                                      <Validator
                                        validationRules={[
                                          {
                                            type: 'required',
                                            message: t(
                                              'labels.enterReqCommentNote'
                                            ),
                                          },
                                        ]}
                                      />
                                    </TextArea>
                                  )}
                                </ValidationGroup>
                              </div>
                            )}
                          </div>
                          <div className="m-l-msg-snd-btn">
                            <div className="app-c-right-section-btn">
                              {showEditButtonReq && (
                                <>
                                  <Button
                                    className="app-c-btn app-c-btn--secondary min-btn-width"
                                    onClick={() => flagRefresh('C')}
                                  >
                                    {t('labels.cancel')}
                                  </Button>
                                  <Button
                                    className="app-c-btn app-c-btn--primary min-btn-width"
                                    onClick={() => editPublicNote('C')}
                                  >
                                    {t('labels.update')}
                                  </Button>
                                </>
                              )}
                              {showAddButtonReq && (
                                <Button
                                  className="app-c-btn app-c-btn--primary min-btn-width"
                                  onClick={handleReqComment}
                                >
                                  {t('labels.add')}
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                } else {
                  return <></>;
                }
              }}
            />
            <Template
              name="PublicNote"
              render={() => {
                if (selectedTab == 1) {
                  return (
                    <div className="m-l-rqst-comment-wrapper">
                      <div className="m-l-rqst-msg-items">
                        <div className="m-l-rqst-msg-item">
                          {publicNotesList?.map((value: any, index: any) => {
                            return (
                              <div key={index}>
                                <>
                                  <div className="m-l-rqst-msg-item">
                                    <div className="m-l-name-date-row">
                                      <div className="m-l-usr-name">
                                        {value.username}
                                      </div>
                                      <ul className="m-l-usr-date-time">
                                        <li>
                                          <span className="m-l-usr-dt-item">
                                            {UTCFormat(value.commentDateTime)}

                                            {isEdited(value.isEdited) && (
                                              <span>{'  Edited'}</span>
                                            )}
                                          </span>
                                        </li>
                                      </ul>
                                      {isCreatedUser(value.employeeId) && (
                                        <div
                                          className="m-l-rqst-edit-icon"
                                          onClick={() => {
                                            setPublicNote(value.commentBody),
                                              setShowEditButtonPublic(true),
                                              setShowAddButtonPublic(false),
                                              setCommentIdPublic(
                                                value.vetRequestNotesId
                                              );
                                          }}
                                        >
                                          <icons.IconEdit />
                                        </div>
                                      )}
                                    </div>
                                    <div className="m-l-rqst-msg-block">
                                      {displayComments(value.commentBody)}
                                    </div>
                                  </div>
                                </>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <Restricted permission="VetRequest.PublicNote">
                        <div className="m-l-msg-input-field">
                          <div className="m-c-form-group">
                            <ValidationGroup ref={validationGroupPublicNote}>
                              {showResults && (
                                <TextArea
                                  className="m-c-input-control"
                                  placeholder={t('labels.publicNote')}
                                  value={publicNote}
                                  onValueChanged={(e) => {
                                    setPublicNote(e.value);
                                  }}
                                >
                                  <Validator
                                    validationRules={[
                                      {
                                        type: 'required',
                                        message: t('labels.enterPublicNote'),
                                      },
                                    ]}
                                  />
                                </TextArea>
                              )}
                            </ValidationGroup>
                          </div>
                        </div>
                        <div className="m-l-msg-snd-btn">
                          <div className="app-c-right-section-btn">
                            {showEditButtonPublic && (
                              <>
                                <Button
                                  className="app-c-btn app-c-btn--secondary min-btn-width"
                                  onClick={() => flagRefresh('P')}
                                >
                                  {t('labels.cancel')}
                                </Button>
                                <Button
                                  className="app-c-btn app-c-btn--primary min-btn-width"
                                  onClick={() => editPublicNote('P')}
                                >
                                  {t('labels.update')}
                                </Button>
                              </>
                            )}
                            {showAddButtonPublic && (
                              <Button
                                className="app-c-btn app-c-btn--primary min-btn-width"
                                onClick={handlePublicNote}
                              >
                                {t('labels.add')}
                              </Button>
                            )}
                          </div>
                        </div>
                      </Restricted>
                    </div>
                  );
                } else {
                  return <></>;
                }
              }}
            />
            <Template
              name="PrivateNote"
              render={() => {
                // <div className="m-l-rqst-comment-wrapper">
                //   <div className="m-l-rqst-msg-items"></div>
                // </div>
                if (selectedTab == 2) {
                  return (
                    <div className="m-l-rqst-comment-wrapper">
                      <div className="m-l-rqst-msg-items">
                        {privateNotesList?.map((value: any, index: any) => {
                          return (
                            <div key={index}>
                              <>
                                <div className="m-l-rqst-msg-item">
                                  <div className="m-l-name-date-row">
                                    <div className="m-l-usr-name">
                                      {value.username}
                                    </div>
                                    <ul className="m-l-usr-date-time">
                                      <li>
                                        <span className="m-l-usr-dt-item">
                                          {UTCFormat(value.commentDateTime)}
                                          {isEdited(value.isEdited) && (
                                            <span>{'  Edited'}</span>
                                          )}
                                        </span>
                                      </li>
                                    </ul>
                                    {isCreatedUser(value.employeeId) && (
                                      <div
                                        className="m-l-rqst-edit-icon"
                                        onClick={() => {
                                          setPrivateNote(value.commentBody),
                                            setShowEditButtonPrivate(true),
                                            setShowAddButtonPrivate(false),
                                            setCommentIdPrivate(
                                              value.vetRequestNotesId
                                            );
                                        }}
                                      >
                                        <icons.IconEdit />
                                      </div>
                                    )}
                                  </div>
                                  <div className="m-l-rqst-msg-block">
                                    {displayComments(value.commentBody)}
                                  </div>
                                </div>
                              </>
                            </div>
                          );
                        })}
                      </div>

                      <Restricted permission="VetRequest.PrivateNote">
                        <div className="m-l-msg-input-field">
                          <div className="m-c-form-group">
                            <ValidationGroup ref={validationGroupPrivateNote}>
                              {showResults && (
                                <TextArea
                                  className="m-c-input-control"
                                  placeholder={t('labels.privateNote')}
                                  value={privateNote}
                                  onValueChanged={(e) => {
                                    setPrivateNote(e.value);
                                  }}
                                >
                                  <Validator
                                    validationRules={[
                                      {
                                        type: 'required',
                                        message: t('labels.enterPrivateNote'),
                                      },
                                    ]}
                                  />
                                </TextArea>
                              )}
                            </ValidationGroup>
                          </div>
                        </div>
                        <div className="m-l-msg-snd-btn">
                          <div className="app-c-right-section-btn">
                            {showEditButtonPrivate && (
                              <>
                                <Button
                                  className="app-c-btn app-c-btn--secondary min-btn-width"
                                  onClick={() => flagRefresh('R')}
                                >
                                  {t('labels.cancel')}
                                </Button>
                                <Button
                                  className="app-c-btn app-c-btn--primary min-btn-width"
                                  onClick={() => editPublicNote('R')}
                                >
                                  {t('labels.update')}
                                </Button>
                              </>
                            )}
                            {showAddButtonPrivate && (
                              <Button
                                className="app-c-btn app-c-btn--primary min-btn-width"
                                onClick={handlePrivateNote}
                              >
                                {t('labels.add')}
                              </Button>
                            )}
                          </div>
                        </div>
                      </Restricted>
                    </div>
                  );
                } else {
                  <></>;
                }
              }}
            />
          </TabPanel>
        </div>
        {!showResults && (
          <div
            className={'m-c-message-box-up-icon  m-l-base-icon'}
            onClick={popUp}
          >
            <icons.ChevronUp />
          </div>
        )}
        {showResults && (
          <div
            className={'m-c-message-box-up-icon m-l-toggle-icon'}
            onClick={closePopup}
          >
            <icons.ChevronUp />
          </div>
        )}
      </div>
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={confirmBackDialog}
      />
    </div>
  );
}
