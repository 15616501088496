import DateBox from 'devextreme-react/date-box';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import Validator, {
  PatternRule,
  RequiredRule,
} from 'devextreme-react/validator';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CanUserEnter } from '../../../../enums/can-user-enter-enum';
import { CompanyType } from '../../../../enums/company-type-enum';
import { Entity } from '../../../../enums/entity-enum';
import { useDataStore } from '../../../../hooks/useDataStore';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import { useDimensionsUnit } from '../../../../hooks/useDimensionsUnit';
import { IRuleDetails } from '../../../../types/vessel-info-rules';
import { getCompanyType } from '../../../../utils/jwt-decode';

export default function RuleDetailsFormFields(props: {
  ruleDetails?: IRuleDetails;
  index: number;
}) {
  const { ruleDetails, index } = props;

  const { t } = useTranslation();

  const { control } = useFormContext();

  const { dateFormat } = useDateFormat();

  const { getDataSource } = useDataStore();

  const { dimensionUnitText } = useDimensionsUnit();

  const companyType = getCompanyType();

  const dataSource = useMemo(() => {
    if (ruleDetails?.ruleConditions) {
      return getDataSource(
        ruleDetails?.ruleConditions[index].selectedListValue,
        ruleDetails?.ruleConditions[index].selectList
      );
    }
    return [];
  }, [ruleDetails]);

  const disableHostedCompanyUser = useMemo(() => {
    return (
      ruleDetails?.ruleConditions[index]?.entityName !==
        Entity.ShipVetRelatedInfos &&
      ruleDetails?.ruleConditions[index]?.entityName !==
        Entity.ShipCapRatings &&
      ruleDetails?.ruleConditions[index]?.entityName !== Entity.ShipSireInfos &&
      companyType === CompanyType.HostedCompany
    );
  }, [index, ruleDetails]);

  return (
    <div
      className="col-xl-3 col-lg-3 co-md-6 col-sm-12 col-12"
      data-testid="ruleDetailsFormField"
    >
      <div className="m-c-form-group mb-btm-space">
        <label className="m-l-input-label">
          {ruleDetails?.ruleConditions[index].isConversionRequired
            ? ruleDetails?.ruleConditions[index].attributeDisplayName +
              dimensionUnitText()
            : ruleDetails?.ruleConditions[index].attributeDisplayName}
        </label>
        <Controller
          name={`ruleDetailFormArray[${index}].attributeValue`}
          control={control}
          render={({ field }) => {
            if (
              ruleDetails?.ruleConditions[index].attributeType === 'Boolean'
            ) {
              return (
                <div className="m-c-form-group">
                  <SelectBox
                    dataSource={[
                      { label: 'Yes', value: 'True' },
                      { label: 'No', value: 'False' },
                    ]}
                    displayExpr="label"
                    valueExpr="value"
                    value={field.value}
                    onValueChanged={(e) => field.onChange(e.value)}
                    className="m-c-input-control  m-c-select-box"
                    validationMessageMode="always"
                    searchEnabled={false}
                    disabled={
                      !ruleDetails?.ruleConditions[index].canUserEnter ||
                      disableHostedCompanyUser ||
                      !ruleDetails?.ruleConditions[index].isEditable
                    }
                  >
                    <Validator>
                      {ruleDetails?.ruleConditions[index].canUserEnter ===
                        CanUserEnter.Yes && (
                        <RequiredRule message={t('errors.required')} />
                      )}
                    </Validator>
                  </SelectBox>
                </div>
              );
            } else if (ruleDetails?.ruleConditions[index].selectList) {
              return (
                <div className="m-c-form-group">
                  <SelectBox
                    className="m-c-input-control  m-c-select-box"
                    dataSource={dataSource}
                    displayExpr={
                      ruleDetails?.ruleConditions[index].selectedListDisplay
                    }
                    valueExpr={
                      ruleDetails?.ruleConditions[index].selectedListValue
                    }
                    searchEnabled={true}
                    onValueChanged={(e) => field.onChange(e.value)}
                    value={field.value}
                    validationMessageMode="always"
                    disabled={
                      !ruleDetails?.ruleConditions[index].canUserEnter ||
                      !ruleDetails?.ruleConditions[index].isEditable ||
                      disableHostedCompanyUser
                    }
                  >
                    <Validator>
                      {ruleDetails?.ruleConditions[index].canUserEnter ===
                        CanUserEnter.Yes && (
                        <RequiredRule message={t('errors.required')} />
                      )}
                    </Validator>
                  </SelectBox>
                </div>
              );
            } else if (
              ruleDetails?.ruleConditions[index].attributeType !== 'DateTime' &&
              ruleDetails?.ruleConditions[index].attributeType !== 'Date'
            ) {
              return (
                <div className="m-c-form-group">
                  <TextBox
                    name={'attributeValue'}
                    onValueChanged={(e) => field.onChange(e.value)}
                    value={field.value}
                    className="m-c-input-control"
                    disabled={
                      !ruleDetails?.ruleConditions[index].canUserEnter ||
                      !ruleDetails?.ruleConditions[index].isEditable ||
                      disableHostedCompanyUser
                    }
                    validationMessageMode="always"
                  >
                    <Validator>
                      {ruleDetails?.ruleConditions[index].canUserEnter ===
                        CanUserEnter.Yes && (
                        <RequiredRule message={t('errors.required')} />
                      )}
                      {ruleDetails?.ruleConditions[index].attributeType ===
                        'Number' && (
                        <PatternRule
                          pattern={/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/}
                          message={t('errors.numberValidation')}
                        />
                      )}
                    </Validator>
                  </TextBox>
                </div>
              );
            } else {
              return (
                <div className="m-c-form-group">
                  <DateBox
                    className="m-c-input-control m-c-date-picker"
                    onValueChanged={(e) => field.onChange(e.value)}
                    value={field.value}
                    displayFormat={dateFormat}
                    disabled={
                      !ruleDetails?.ruleConditions[index].canUserEnter ||
                      disableHostedCompanyUser ||
                      !ruleDetails?.ruleConditions[index].isEditable
                    }
                    validationMessageMode="always"
                  >
                    <Validator>
                      {ruleDetails?.ruleConditions[index].canUserEnter ===
                        CanUserEnter.Yes && (
                        <RequiredRule message={t('errors.required')} />
                      )}
                    </Validator>
                  </DateBox>
                </div>
              );
            }
          }}
        />
      </div>
    </div>
  );
}
