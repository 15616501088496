import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';
import DataGrid, {
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  Selection,
} from 'devextreme-react/data-grid';
import { IGridConfig } from '../../../types/grid';
import { Template } from 'devextreme-react/core/template';
import icons from '../icons/icons';
import Button from 'devextreme-react/button';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import DataSource from 'devextreme/data/data_source';
import { Restricted, usePermission } from '../../../contexts/PermissionContext';
import { useTranslation } from 'react-i18next';

export default function Grid(props: {
  gridConfig: IGridConfig;
  actionTemplate?: any;
  headerTemplate?: any;
  statusTemplate?: any;
  cellTemplate?: any;
  actionTemplate1?: any;
  dataSource?: DataSource;
  showScroller?: boolean;
  onEditorPreparing?: any;
}) {
  const gridRef = useRef<DataGrid>(null);
  const {
    gridConfig,
    actionTemplate,
    headerTemplate,
    statusTemplate,
    cellTemplate,
    actionTemplate1,
    dataSource,
    showScroller,
    onEditorPreparing,
  } = props;

  const { findFeature } = usePermission();
  const { t } = useTranslation();
  const exportGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: gridConfig.ref
        ? gridConfig.ref.current?.instance
        : gridRef.current?.instance,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          gridConfig.excelFileName ? gridConfig.excelFileName : 'DataGrid.xlsx'
        );
      });
    });
  };
  const className = useMemo(() => {
    if (
      (!dataSource || dataSource.totalCount() < 1) &&
      (!gridConfig.dataSource || gridConfig.dataSource.length === 0)
    ) {
      return gridConfig.class
        ? `${gridConfig.class} m-c-grid-fixed--data-height`
        : `m-c-grid m-c-grid-fixed--data-height`;
    } else {
      return gridConfig.class ? gridConfig.class : `m-c-grid`;
    }
  }, [dataSource, gridConfig]);

  const rowClick = (e: any) => {
    if (
      gridConfig &&
      gridConfig.rowClick &&
      findFeature(gridConfig.rowClickPermission)
    ) {
      gridConfig.rowClick(e);
    }
  };
  return (
    <>
      {gridConfig.showHeader && (
        <div className="m-l-inner-page-header">
          <div className="m-l-inner-page-header-left-block">
            {gridConfig.header && (
              <div className="m-l-page-main-heading">{gridConfig.header}</div>
            )}
          </div>
          <div className="m-l-inner-page-header-right-block">
            {gridConfig.showExport && (
              <Button
                className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                onClick={exportGrid}
                aria-label="Export-btn"
                elementAttr={{ 'data-testid': 'exportButton' }}
              >
                <div className="m-c-icon-inside-btn" data-testid="export-all">
                  <icons.download />
                </div>
              </Button>
            )}
            {gridConfig.showAddButton && (
              <Restricted permission={gridConfig.addButtonPermission}>
                <Button
                  className="app-c-btn app-c-btn--primary"
                  elementAttr={{ 'data-testid': 'addNew' }}
                  onClick={gridConfig.onClickAddButton}
                >
                  {t('labels.addNew')}
                </Button>
              </Restricted>
            )}
          </div>
        </div>
      )}
      <div className={className} data-testid={gridConfig.testId}>
        <DataGrid
          ref={gridConfig.ref ? gridConfig.ref : gridRef}
          dataSource={
            gridConfig.dataSource ? gridConfig.dataSource : dataSource
          }
          loadPanel={{ enabled: !gridConfig?.disableLoadPanel }}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnResizingMode="widget"
          defaultColumns={gridConfig.defaultColumns}
          showBorders={true}
          showColumnLines={true}
          remoteOperations={gridConfig.remoteOperations}
          onRowClick={rowClick}
          errorRowEnabled={false}
          onCellPrepared={gridConfig.cellPrepared}
          onRowPrepared={gridConfig.rowPrepared}
          onInitialized={gridConfig.initialized}
          onRowRemoving={gridConfig.rowRemoving}
          onRowRemoved={gridConfig.rowRemoved}
          onContentReady={gridConfig.contentReady}
          onSelectionChanged={gridConfig.selectionChanged}
          onDataErrorOccurred={gridConfig.dataErrorOccured}
          noDataText={gridConfig.noDataText ? gridConfig.noDataText : 'No data'}
          height={gridConfig.height}
          onEditorPreparing={onEditorPreparing}
          className={
            gridConfig.hideFilterRow
              ? 'm-l-no-filter-row-in-grid'
              : 'm-l-with-filter-row-in-grid'
          }
        >
          <FilterRow
            visible={gridConfig.hideFilterRow ? false : true}
            showOperationChooser={true}
          />
          <HeaderFilter visible={false} />
          {showScroller && (
            <Scrolling mode="standard" columnRenderingMode="virtual" />
          )}
          <Paging defaultPageSize={10} />
          <Pager
            visible={gridConfig.hidePagination ? false : true}
            displayMode="adaptive"
            showPageSizeSelector={true}
            showNavigationButtons={true}
            showInfo={true}
            infoText={'Page {0} of {1} ({2} items)'}
          />
          {gridConfig.showSelection && (
            <Selection
              allowSelectAll={true}
              mode="multiple"
              selectAllMode="allPages"
              showCheckBoxesMode="always"
            />
          )}

          {gridConfig.enableEditing && (
            <Editing
              mode={gridConfig.editMode}
              allowDeleting={gridConfig.allowDeleting}
              allowUpdating={gridConfig.allowUpdating}
              confirmDelete={false}
              useIcons={true}
            />
          )}

          <Export enabled={true} allowExportSelectedData={false} />
          {statusTemplate && (
            <Template name="statusTemplate" render={statusTemplate}></Template>
          )}
          {actionTemplate && (
            <Template name="actionTemplate" render={actionTemplate}></Template>
          )}
          {headerTemplate && (
            <Template name="headerTemplate" render={headerTemplate}></Template>
          )}
          {cellTemplate && (
            <Template name="cellTemplate" render={cellTemplate}></Template>
          )}
          {actionTemplate1 && (
            <Template
              name="actionTemplate1"
              render={actionTemplate1}
            ></Template>
          )}
        </DataGrid>
      </div>
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              {gridConfig.showExport && (
                <Button
                  className="app-c-btn app-c-btn--secondary min-btn-width"
                  onClick={exportGrid}
                  aria-label="Export-btn"
                  elementAttr={{ 'data-testid': 'exportButton' }}
                >
                  {t('labels.download')}
                </Button>
              )}
              {gridConfig.showAddButton && (
                <Restricted permission={gridConfig.addButtonPermission}>
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'addNew' }}
                    onClick={gridConfig.onClickAddButton}
                  >
                    {t('labels.addNew')}
                  </Button>
                </Restricted>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Grid.propTypes = {
  showScroller: PropTypes.bool,
};

Grid.defaultProps = {
  showScroller: true,
};
