import SelectBox from 'devextreme-react/select-box';
import React, { useEffect, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormConfig } from '../../../../../types/form-builder';
import { IOptionvalue } from '../../../../../types/master-data';
import { IDefectFormArray } from '../../../../../types/psc-inspection';
import FieldArray from '../../../../common/field-array/FieldArray';

export default function PSCDefectForm(props: {
  defectCode: IOptionvalue[];
  riskArea: IOptionvalue[];
  subArea: IOptionvalue[];
  rootCause: IOptionvalue[];
  actions: IOptionvalue[];
  disableEdit: boolean;
}) {
  const { defectCode, riskArea, subArea, rootCause, actions, disableEdit } =
    props;
  const { t } = useTranslation();
  const subAreabRef = useRef<SelectBox[]>([]);
  const { control, getValues, setValue } = useFormContext();

  const pscDefectsFieldArray = useFieldArray({
    control: control,
    name: 'pscDeficiencies',
  });

  const fieldArrayappendValues: IDefectFormArray = {
    pscDefeciancyId: 0,
    defectCode: null,
    deficiencyDescription: '',
    riskArea: null,
    subArea: null,
    rootCauseCode: null,
    actionCode: null,
  };

  const onChangeDefectCode = (e: any, index: number) => {
    if (e.value) {
      const selectedCode = defectCode.find(
        (item) => item.optionValueCode === e.value
      );
      const riskAreaCode = riskArea.find(
        (item) => item.optionValueCode === selectedCode?.parentOptionValueCode
      );
      setValue(
        `pscDeficiencies[${index}].deficiencyDescription`,
        selectedCode?.optionValueDesc
      );
      setValue(
        `pscDeficiencies[${index}].riskArea`,
        riskAreaCode?.optionValueCode
      );
    } else {
      setValue(`pscDeficiencies[${index}].deficiencyDescription`, '');
    }
  };

  const onChangeDefectDesc = (e: any, index: number) => {
    if (e.selectedItem) {
      const selectedDesc = defectCode.find((item) => {
        return item.optionValueDesc === e.selectedItem.optionValueDesc;
      });
      setValue(
        `pscDeficiencies[${index}].defectCode`,
        e.selectedItem?.optionValueCode
      );
    }
    // else {
    //   setValue(`pscDeficiencies[${index}].defectCode`, '');
    // }
  };

  const onChangeRiskArea = (e: any, index: number) => {
    setValue(`pscDeficiencies[${index}].subArea`, null);
    subAreabRef.current[index]?.instance
      .getDataSource()
      .filter(['parentOptionValueCode', '=', e.value]);
    subAreabRef.current[index]?.instance.getDataSource()?.load();
  };

  useEffect(() => {
    subAreabRef.current?.map((fieldRef: any, index: number) => {
      fieldRef?.instance
        .getDataSource()
        .filter([
          'parentOptionValueCode',
          '=',
          getValues().pscDeficiencies[index]?.riskArea,
        ]);
      fieldRef?.instance.getDataSource()?.load();
    });
  }, [getValues().pscDeficiencies]);

  const defectFormConfig: IFormConfig[] = [
    {
      class:
        'col-xl col-lg-3 col-md-3 col-sm-3 col-12 m-l-custom-sixteen-space-col',
      name: 'defectCode',
      fieldType: 'select',
      control: control,
      label: t('labels.defectCode'),
      displayExpr: 'optionValueCode',
      valueExpr: 'optionValueCode',
      dataSource: defectCode,
      isFieldArray: true,
      fieldWrapperClass: 'mb-btm-space-30 m-c-form-group',
      onChange: onChangeDefectCode,
      disabled: disableEdit,
    },
    {
      class:
        'col-xl col-lg-3 col-md-3 col-sm-3 col-12 m-l-custom-sixteen-space-col',
      name: 'deficiencyDescription',
      fieldType: 'autoComplete',
      control: control,
      label: t('labels.defectDescription'),
      isFieldArray: true,
      fieldWrapperClass: 'mb-btm-space-30 m-c-form-group',
      // readonly: true,
      disabled: disableEdit,
      displayExpr: 'optionValueDesc',
      valueExpr: 'optionValueDesc',
      dataSource: defectCode,
      minSearchLength: 1,
      selectionChanged: onChangeDefectDesc,
      dropDownOptions: {
        resizeEnabled: true,
        width: '400px',
      },
    },
    {
      class:
        'col-xl col-lg-3 col-md-3 col-sm-3 col-12 m-l-custom-sixteen-space-col',
      name: 'riskArea',
      fieldType: 'select',
      control: control,
      label: t('labels.riskArea'),
      dataSource: riskArea,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      isFieldArray: true,
      showFieldArrayAddButton: true,
      fieldWrapperClass: 'mb-btm-space-30 m-c-form-group',
      onChange: onChangeRiskArea,
      disabled: disableEdit,
      dropDownOptions: {
        resizeEnabled: true,
        width: '400px',
      },
    },
    {
      class:
        'col-xl col-lg-3 col-md-3 col-sm-3 col-12 m-l-custom-sixteen-space-col',
      name: 'subArea',
      fieldType: 'select',
      control: control,
      label: t('labels.subArea'),
      dataSource: subArea,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      isFieldArray: true,
      showFieldArrayAddButton: true,
      fieldWrapperClass: 'mb-btm-space-30 m-c-form-group',
      ref: subAreabRef,
      disabled: disableEdit,
      dropDownOptions: {
        resizeEnabled: true,
        width: '450px',
      },
    },
    {
      class:
        'col-xl col-lg-3 col-md-3 col-sm-3 col-12 m-l-custom-sixteen-space-col',
      name: 'rootCauseCode',
      fieldType: 'select',
      control: control,
      label: t('labels.rootCause'),
      dataSource: rootCause,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      isFieldArray: true,
      showFieldArrayAddButton: true,
      fieldWrapperClass: 'mb-btm-space-30 m-c-form-group',
      disabled: disableEdit,
      dropDownOptions: {
        resizeEnabled: true,
        width: '450px',
      },
    },
    {
      class:
        'col-xl col-lg-3 col-md-3 col-sm-3 col-12 m-l-custom-sixteen-space-col',
      name: 'actionCode',
      fieldType: 'select',
      control: control,
      label: t('labels.actions'),
      dataSource: actions,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      isFieldArray: true,
      showFieldArrayAddButton: true,
      fieldWrapperClass: 'mb-btm-space-30 m-c-form-group',
      disabled: disableEdit,
      dropDownOptions: {
        resizeEnabled: true,
        width: '400px',
      },
    },
  ];

  return (
    <>
      <FieldArray
        showAddButton={!disableEdit}
        fieldArray={pscDefectsFieldArray}
        fieldArrayName="pscDeficiencies"
        formConfig={defectFormConfig}
        appendValues={fieldArrayappendValues}
        buttonWrapperParentClass="col-xl-auto col-lg-auto col-md-auto col-sm-auto col-12 m-l-custom-sixteen-space-col"
        buttonWrapperClass="mb-btm-space-30 m-c-form-group m-l-button-vertical-center-align m-l-fm-icon-btn"
      />
    </>
  );
}
