import React from 'react';
import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import { useTranslation } from 'react-i18next';

export default function ReportFunctions(props: {
  onClickRun: any;
  setReportOptions: any;
  reportOptions: any;
  onClickCancel: any;
  reportCode?: any;
}) {
  const {
    onClickRun,
    setReportOptions,
    reportOptions,
    onClickCancel,
    reportCode,
  } = props;
  const { t } = useTranslation();
  const sortValues = [
    {
      displayName: 'Company Name',
      value: 'companyname',
    },
    {
      displayName: 'Request Date',
      value: 'requestdate',
    },
    {
      displayName: 'Vessel Name',
      value: 'ShipName',
    },
    {
      displayName: 'Vet Status',
      value: 'Status',
    },
    {
      displayName: 'IMO Number',
      value: 'IMONumber',
    },
    {
      displayName: 'Inco Term',
      value: 'IncoTerm',
    },
    {
      displayName: 'Reviewer',
      value: 'Reviewer',
    },
    {
      displayName: 'Requestor',
      value: 'Requestor',
    },
    {
      displayName: 'Vessel Type',
      value: 'ShipType',
    },
    {
      displayName: 'P & I Club',
      value: 'PIClub',
    },
    {
      displayName: 'Classification Name',
      value: 'ClassificationName',
    },
    {
      displayName: 'DOC Company Name',
      value: 'DOCCompanyName',
    },
    {
      displayName: 'Vessel Status',
      value: 'ShipStatus',
    },
    {
      displayName: 'Vessel Previous Name',
      value: 'ShipPrevName',
    },
    // {
    //   displayName: 'Laycan From Date',
    //   value: 'LaycanFromDate',
    // },
    // {
    //   displayName: 'Laycan To Date',
    //   value: 'LaycanToDate',
    // },
    {
      displayName: 'Business Unit',
      value: 'BUName',
    },
  ];
  const berthFitSortValues = [
    {
      displayName: 'Company Name',
      value: 'companyname',
    },
    {
      displayName: 'IMO Number',
      value: 'imonumber',
    },
    {
      displayName: 'Business Unit',
      value: 'BUName',
    },
    {
      displayName: 'Vessel Type',
      value: 'ShipType',
    },
    {
      displayName: 'Vessel Previous Name',
      value: 'ShipPrevName',
    },
    {
      displayName: 'P & I Club',
      value: 'PIClub',
    },
    {
      displayName: 'Classification Name',
      value: 'ClassificationName',
    },
    {
      displayName: 'DOC Company Name',
      value: 'DOCCompanyName',
    },
    {
      displayName: 'Vessel Status',
      value: 'ShipStatus',
    },
    {
      displayName: 'BerthFit Finalized Date',
      value: 'FinalizedDate',
    },
  ];
  const setReportOpt = (e: any) => {
    setReportOptions(e.value);
  };

  return (
    <div className="m-l-inner-page-body">
      <div className="m-l-form-panel-body-block">
        <div className="row m-l-rl-list-wrap">
          <div className="col-md-3 col-sm-6 col-lg-3 col-xs-12">
            <div className="m-c-form-group mb-btm-space">
              <label className="m-l-input-label">Sort By</label>
              <SelectBox
                dataSource={
                  reportCode == 'bfr' ? berthFitSortValues : sortValues
                }
                displayExpr={'displayName'}
                valueExpr={'value'}
                className="m-c-input-control m-c-select-box"
                onValueChanged={setReportOpt}
                value={reportOptions ? reportOptions : 'companyname'}
              />
            </div>
          </div>
          {/* <div className="col-md-9 col-sm-6 col-lg-9 col-xs-12">
            <div className="mb-btm-space m-c-form-group h-100 d-flex align-items-center">
              <div className="m-c-checkbox m-c-checkbox-dark">
                <CheckBox defaultValue={true} text="Label" />
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="m-l-form-panel-footer-block ps-3 pe-3 pb-3">
        <Button
          className="app-c-btn app-c-btn--secondary min-btn-width"
          onClick={onClickCancel}
        >
          {t('buttons.cancel')}
        </Button>
        <Button
          className="app-c-btn app-c-btn--primary min-btn-width"
          onClick={onClickRun}
        >
          {t('buttons.runNow')}
        </Button>
      </div>
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <Button
                className="app-c-btn app-c-btn--secondary min-btn-width"
                onClick={onClickCancel}
              >
                {t('buttons.cancel')}
              </Button>
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                onClick={onClickRun}
              >
                {t('buttons.runNow')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
