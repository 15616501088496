import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Restricted, usePermission } from '../../../contexts/PermissionContext';
import { IGridConfig } from '../../../types/grid';
import icons from '../../common/icons/icons';
import Grid from '../../common/grid/Grid';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { getAPIRoute } from '../../../utils/api-route';
import AddContract from './add-contract-information/AddContract';
import { useDateFormat } from '../../../hooks/useDateFormat';

export default function ContractInformation(props: {
  companyId: any;
  setContractId: any;
  setAddContract: any;
}) {
  const [contractInformation, setContractInformation] = useState<[]>([]);
  const [contractData, setContractData] = useState(false);
  const { t } = useTranslation();
  const { companyId, setContractId, setAddContract } = props;
  const { dateFormat } = useDateFormat();
  const { findFeature } = usePermission();

  const getContractInformation = async () => {
    await api
      .get({
        url: getAPIRoute('contractInformation', [companyId]),
      })
      .then((res) => {
        if (res.length > 0) {
          setContractData(true);
        }
        setContractInformation(res);
      });
  };

  const onRowClick = (e: any) => {
    if (findFeature('CompanyContract.ViewDetail')) {
      setContractId(e.data.contractId);
      setAddContract(true);
    }
  };

  useEffect(() => {
    getContractInformation();
  }, []);

  const gridConfig: IGridConfig = {
    class: 'm-c-grid m-c-grid-in-tab m-c-auto-scroll-grid no-border-top',
    testId: 'managementRolesGrid',
    dataSource: contractInformation,
    defaultColumns: [
      {
        caption: t('labels.commencementDate'),
        dataField: 'startDate',
        dataType: 'date',
        format: dateFormat,
        minWidth: 150,
        sortOrder: 'desc',
      },
      {
        caption: t('labels.endDate'),
        dataField: 'endDate',
        dataType: 'date',
        format: dateFormat,
        minWidth: 150,
      },
      {
        caption: t('labels.agreedVetNumbers'),
        dataField: 'agreedVetNumbers',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.numberOfActiveUsers'),
        dataField: 'numberOfActiveUsers',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.setupCosts'),
        dataField: 'setupCost',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.escalationPercentage'),
        dataField: 'escalationPercentage',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.otherCosts'),
        dataField: 'otherCost',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.taxes'),
        dataField: 'tax',
        dataType: 'string',
        minWidth: 150,
      },
    ],
    showHeader: false,
    showExport: false,
    excelFileName: 'VesselManagementRoles.xlsx',
    rowClick: onRowClick,
  };

  return (
    <React.Fragment>
      {contractData ? (
        <Grid gridConfig={gridConfig} />
      ) : (
        <div className="u-height-full">
          <div className="no-list-block no-list-min-hgt">
            <icons.NoListIcon />
            <div className="no-list-text">
              {t('labels.noListFound')} <p>{t('labels.addNew')}</p>{' '}
              {t('labels.buttonTo')} <br />
              {t('labels.addContractInformation')}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
