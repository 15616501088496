import React, { useEffect, useState } from 'react';
import { IGridConfig } from '../../../../types/grid';
import Grid from '../../../common/grid/Grid';
import VesselHandledEditModal from '../terminal-vessel-handled-edit/VesselHandledEditModal';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';

export default function VesselHandledGrid(props: {
  terminalCode: any;
  isFromViewInfoModal?: boolean;
}) {
  const { terminalCode, isFromViewInfoModal } = props;
  const [showEditNoteModal, setShowEditNoteModal] = useState(false);
  const [vesselHandled, setVesselHandled] = useState<any>([]);
  const [vesselHandledId, setVesselHandledId] = useState<any>([]);

  const onClickAddNote = () => {
    setVesselHandledId(null);
    setShowEditNoteModal(true);
  };

  const onRowClick = (e: any) => {
    if (!isFromViewInfoModal) {
      setVesselHandledId(e.data.terminalShipTypeHandleId);
      setShowEditNoteModal(true);
    }
  };

  const getVesselHandled = async (terminalCode: any) => {
    await api
      .get({ url: apiConfig.vesselHandled + terminalCode })
      .then((data: any) => {
        setVesselHandled(data);
      });
  };

  useEffect(() => {
    if (terminalCode) {
      getVesselHandled(terminalCode);
    }
  }, [terminalCode]);

  const gridConfig: IGridConfig = {
    class: 'm-c-grid m-l-grid-with--top-border m-c-righticon-grid',
    testId: 'vesselNameGrid',
    height: '350',
    dataSource: vesselHandled,
    // rowClickPermission: 'Ship.EditName',
    rowClick: onRowClick,
    onClickAddButton: onClickAddNote,
    defaultColumns: [
      {
        caption: 'Ship Type',
        dataField: 'shipType',
        dataType: 'string',
        minWidth: 150,
        width: 300,
      },
      {
        caption: 'Ship Size',
        dataField: 'shipSize',
        dataType: 'string',
        minWidth: 150,
      },
    ],
    showHeader: true,
    showExport: false,
    showAddButton: !isFromViewInfoModal,
  };

  const cancelClick = () => {
    setShowEditNoteModal(false);
  };

  return (
    <div className="m-l-tab-accordion-item-content-section">
      <Grid gridConfig={gridConfig} />
      {showEditNoteModal && (
        <VesselHandledEditModal
          isVisible={showEditNoteModal}
          handleCancelClick={() => cancelClick()}
          getVesselHandled={getVesselHandled}
          terminalCode={terminalCode}
          vesselHandledId={vesselHandledId}
        />
      )}
    </div>
  );
}
