import SelectBox from 'devextreme-react/select-box';
import React, { useEffect, useState } from 'react';
import countryList from '../../components/Country.json';
import { MarsApiService as api } from '../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoaderContext } from '../../contexts/LoaderContext';
import { useTranslation } from 'react-i18next';
import toast from '../../utils/toast';
import { Button } from 'devextreme-react/button';
import AddNewVessel from '../../components/add-new-vessel/AddNewVessel';
import icons from '../../components/common/icons/icons';

export default function AdvanceSearch() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const searchParams = urlParams.get('q');

  const { setIsLoading } = useLoaderContext();
  const { t } = useTranslation();
  const [vesselGlobalSearchList, setVesselGlobalSearchList] = useState(
    []
  ) as any;
  const [totalCount, setTotalCount] = useState(0);
  const [perPageCount, setPerPageCount] = useState(10);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(perPageCount);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [isAddNewVessel, setIsAddNewVessel] = useState(false);

  useEffect(() => {
    if (take && skip >= 0) {
      if (searchParams) {
        vesselGlobalSearch(searchParams);
      }
    }
  }, [searchParams, take, skip, location.key]);

  useEffect(() => {
    if (location.key) {
      setTake(perPageCount);
      setSkip(0);
    }
  }, [location.key]);

  const createSearchQry = (searchText: string, skip: number, take: number) => {
    return `?searchText=${searchText}&skip=${skip}&take=${perPageCount}&requireTotalCount=true`;
  };

  const vesselGlobalSearch = async (searchParams: any) => {
    const qry = createSearchQry(searchParams, skip, take);
    setVesselGlobalSearchList([]);
    setTotalCount(0);

    if (searchParams.length >= 3) {
      await api
        .get({ url: apiConfig.vesselGlobalSearch + qry }, setIsLoading)
        .then((data) => {
          const result = data.data.map((item: any) => item.vessel);
          setVesselGlobalSearchList(result);
          setTotalCount(data.totalCount);
          if (take > data.totalCount) {
            setTake(data.totalCount);
          }
        });
    } else {
      toast.error({
        title: 'Error',
        message: t('errors.searchWithLessThan2chara'),
      });
    }
  };

  const setPerPageNum = (value: any) => {
    setPerPageCount(parseInt(value));
    if (value > totalCount) {
      setTake(totalCount);
      setDisableNext(true);
    } else {
      setDisableNext(false);
      setTake(parseInt(value));
    }
    setSkip(0);
  };

  const next = () => {
    if (disableNext === true) {
      return;
    }
    const skipValue = take;
    const takeValue = perPageCount + take;
    setSkip(take);
    if (takeValue > totalCount) {
      setTake(totalCount);
      setDisableNext(true);
      setDisablePrev(false);
    } else {
      setDisableNext(false);
      setTake(takeValue);
    }
  };

  const previous = () => {
    if (disablePrev === true) {
      return;
    }

    const skipValue = skip - perPageCount;
    const takeValue = take - perPageCount;

    if (skipValue <= 0) {
      setSkip(0);
      setDisablePrev(true);
      setDisableNext(false);
    } else {
      setDisablePrev(false);
      setSkip(skipValue);
    }
    if (takeValue < perPageCount) {
      setTake(perPageCount);
    } else {
      setTake(takeValue);
    }
  };

  const viewVessalInfo = (imo: string) => {
    navigate('/vessel-info', { state: { imoNumber: imo } });
  };

  const onAddClick = () => {
    setIsAddNewVessel(true);
  };

  return (
    <div className="inner-wrapper">
      <ul aria-label="breadcrumb" className="m-c-bredcrumb">
        <li>
          <a>{t('headers.home')}</a>
        </li>
        <li>
          <a className="active">{t('labels.searchResults')}</a>
        </li>
      </ul>
      {/* <div className="m-l-inner-page-header">
        <div className="m-l-inner-page-header-left-block">
          <div className="m-l-page-main-heading">ABC Cargo</div>
        </div>
      </div> */}
      <div className="m-l-search-result-display-block u-mt0">
        <div className="m-l-results-found u-mb0">
          {totalCount} {t('labels.resultsFound')}
        </div>
        <div className="right-btn-block">
          <Button
            className="app-c-btn app-c-btn--primary min-btn-width"
            onClick={onAddClick}
          >
            {t('buttons.addNew')}
          </Button>
        </div>
        {isAddNewVessel && (
          <AddNewVessel
            isAddNewVessel={isAddNewVessel}
            setIsAddNewVessel={setIsAddNewVessel}
          />
        )}
      </div>
      {isAddNewVessel && (
        <AddNewVessel
          isAddNewVessel={isAddNewVessel}
          setIsAddNewVessel={setIsAddNewVessel}
        />
      )}
      <div className="m-l-advance-search-section">
        {vesselGlobalSearchList.length > 0 &&
          vesselGlobalSearchList.map((item: any, index: any) => {
            const filteredCountryList = countryList.find(
              (countryItem) => countryItem.iso_3 == item?.iso3
            );
            return (
              <div
                className="m-l-advance-search-block"
                key={index}
                onClick={() => viewVessalInfo(item.imo)}
              >
                <div className="m-l-advance-search-header-block">
                  <div className="m-l-heading">{item.vesselName}</div>
                </div>
                <div className="row m-l-advance-search-body-block u-pb0">
                  <div className="col-md-3 col-sm-6 col-lg-3 col-xs-12">
                    <div className="m-l-advance-search-item-block mb-3">
                      <span>{t('labels.vesselType')}</span>
                      <h5>{item.vesselType}</h5>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-6 col-lg-2 col-xs-12">
                    <div className="m-l-advance-search-item-block mb-3">
                      <span>{t('labels.IMO')}</span>
                      <h5>{item.imoDisplayName}</h5>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-6 col-lg-2 col-xs-12">
                    <div className="m-l-advance-search-item-block mb-3">
                      <span>{t('labels.owner')}</span>
                      <h5>{item.owner}</h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-lg-3 col-xs-12">
                    <div className="m-l-advance-search-item-block mb-3">
                      <span>{t('labels.operatorName')}</span>
                      <h5>{item.operator}</h5>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-6 col-lg-2 col-xs-12">
                    <div className="m-l-advance-search-item-block mb-3">
                      <span>{t('labels.flag')}</span>
                      <div className="m-l-flag-block">
                        <img
                          src={filteredCountryList?.flag}
                          className="img-responsive"
                          alt="flag"
                          height={20}
                          width={20}
                        />
                        <span>{item.flag}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="m-l-advance-search-pagination-section">
        <div className="m-l-advance-search-pagination-result">
          <p>
            {t('labels.result')}{' '}
            <span>
              {skip}-{take}
            </span>{' '}
            {t('labels.of')} <span>{totalCount}</span>
          </p>
        </div>
        <div className="m-l-pagination-dropdown-block">
          <a onClick={previous} data-testid="prev-page">
            {t('labels.previous')}
          </a>
          <div className="pagination-dropdown">
            <SelectBox
              dataSource={[10, 20, 50, 100]}
              className="m-c-input-control m-c-select-box"
              onValueChanged={(e) => setPerPageNum(e.value)}
              defaultValue={perPageCount}
            ></SelectBox>
          </div>
          <a onClick={next} data-testid="next-page">
            {t('labels.next')}
          </a>
        </div>
      </div>
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                onClick={onAddClick}
              >
                {t('buttons.addNew')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
