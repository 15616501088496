import React, { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { IFormConfig } from '../../../../types/form-builder';
import FormBuilder from '../../../common/form-builder/FormBuilder';
import { useTranslation } from 'react-i18next';
import FieldArray from '../../../common/field-array/FieldArray';
import { IOptionvalue } from '../../../../types/master-data';
import CountryList from '../../../Country.json';
import SelectBox from 'devextreme-react/select-box';
import {
  blackListedEmails,
  ICompany,
} from '../../../../types/requestor-company';
import TextBox from 'devextreme-react/text-box';
import { IUser } from '../../../../types/user';
import { CompanyType } from '../../../../enums/company-type-enum';
import { EmployeeStatus } from '../../../../enums/status-enum';
import { Restricted } from '../../../../contexts/PermissionContext';
import { DimensionUnit } from '../../../../enums/dimension-unit-enum';

export default function UserForm(props: {
  employeeStatusList: IOptionvalue[];
  personNameTitles: IOptionvalue[];
  companyBusinessUnits: any;
  companyRoles: any;
  userDetails?: IUser;
  companyDetails?: ICompany;
}) {
  const { t } = useTranslation();
  const { control, getValues } = useFormContext();
  const {
    employeeStatusList,
    personNameTitles,
    companyBusinessUnits,
    companyRoles,
    userDetails,
    companyDetails,
  } = props;

  const [buArray, setBUArray] = useState<any>();

  const userFormArray = useFieldArray({
    control: control,
    name: 'employeeBUs',
  });

  const fieldArrayappendValues = {
    bu: null,
    isDefault: false,
    userRoles: [],
  };

  const countryList = CountryList.sort();

  const distinctCallingCodes = countryList
    .map((item) => item.callingCode)
    .filter((value, index, self) => self.indexOf(value) === index);

  const callingCodes: any = [];
  distinctCallingCodes.forEach((callingCode) =>
    callingCodes.push({
      callingCode: callingCode,
    })
  );

  const onChangeCheckBox = (e: any, i: any) => {
    const values = [...getValues().employeeBUs];
    values.forEach((item: any, index: any) => {
      if (index !== i) {
        userFormArray.update(index, {
          bu: item.bu,
          isDefault: false,
          userRoles: item.userRoles,
        });
      } else {
        userFormArray.update(index, {
          bu: item.bu,
          isDefault: true,
          userRoles: item.userRoles,
        });
      }
    });
  };

  const onChange = (e: any) => {
    if (e.value) {
      const buArray = [...getValues().employeeBUs];
      setBUArray(buArray);
    }
  };

  const validateBU = () => (params: any) => {
    let duplicateBUCount: any = 0;
    buArray?.forEach((item: any) => {
      if (item.bu === params.value) {
        duplicateBUCount = duplicateBUCount + 1;
      }
    });
    return duplicateBUCount >= 2 ? false : true;
  };

  const formConfig: IFormConfig[] = [
    {
      name: 'employeeName',
      label: t('labels.name'),
      control: control,
      fieldType: 'customSelect',
      isRequired: true,
      dataSource: personNameTitles,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      selectBoxName: 'personNameTitle',
      disableSearch: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
        {
          type: 'pattern',
          pattern: '^([a-zA-Z_!@#$%^&*.-]+[ ]*)*$',
          message: t('errors.nameFormat'),
        },
      ],
      maxLength: 100,
      autocomplete: 'new-user',
    },
    {
      name: 'employeeEmail',
      label: t('labels.email'),
      control: control,
      fieldType: 'text',
      maxLength: 50,
      isRequired: true,
      disabled:
        !!userDetails &&
        userDetails.employeeStatus !== EmployeeStatus.draft &&
        userDetails.employeeStatus !== EmployeeStatus.waitingForApproval,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
        {
          type: 'email',
          message: t('errors.inCorrectEmailFormat'),
        },
        {
          type: 'custom',
          validationCallback: (params: any) => {
            const regex = new RegExp(blackListedEmails.join('|'));
            return !regex.test(params.value.toLowerCase());
          },
          message: t('errors.publicDomainEmailNotAllowed'),
        },
      ],
    },
    {
      name: 'employeeCode',
      label: t('labels.employeeId'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
    },
    {
      name: 'phoneNumber',
      label: t('labels.phoneNumber'),
      control: control,
      fieldType: 'customSelect',
      dataSource: callingCodes,
      displayExpr: 'callingCode',
      valueExpr: 'callingCode',
      selectBoxName: 'callingCode',
      customSelectInputFieldType: 'number',
      maxLength: 20,
      autocomplete: 'new-user',
    },
    {
      name: 'isReviewer',
      label: t('labels.reviewer'),
      control: control,
      fieldType: 'checkbox',
      isHidden: companyDetails?.companyType === CompanyType.RequestorCompany,
    },
    {
      name: 'bu',
      checkBoxName: 'isDefault',
      checkBoxLabel: t('labels.default'),
      label: t('labels.businessUnit'),
      control: control,
      fieldType: 'selectWithDefault',
      disableSearch: true,
      displayExpr: 'buName',
      valueExpr: 'buId',
      dataSource: companyBusinessUnits,
      isFieldArray: true,
      isRequired: true,
      onChangeCheckBox: onChangeCheckBox,
      disabled: true,
      class: 'col-md-4 col-sm-6 col-lg-4 col-xs-12',
      rules: [
        {
          type: 'custom',
          validationCallback: validateBU(),
          message: t('errors.buAlreadySelected'),
        },
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      name: 'userRoles',
      label: t('labels.userRoles'),
      control: control,
      fieldType: 'multiSelect',
      disableSearch: false,
      displayExpr: 'name',
      valueExpr: 'id',
      isRequired: true,
      dataSource: companyRoles,
      isFieldArray: true,
      // showFieldArrayAddButton: true,
      disabled: true,
      isUserRoles: true,
      class: 'col-md-5 col-sm-9 col-lg-7 col-xs-12',
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
  ];

  const radioButtonConfig: IFormConfig = {
    name: 'unitOfLength',
    label: 'Length Unit',
    control: control,
    fieldType: 'radio',
    items: [
      { value: DimensionUnit.Meter, name: 'Meter' },
      { value: DimensionUnit.Feet, name: 'Feet' },
    ],
    layout: 'horizontal',
    displayExpr: 'name',
    valueExpr: 'value',
    class: 'm-c-input-control m-c-input-password',
    fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
    fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
  };

  return (
    // <form>
    <div className="m-l-form-panel-body-block">
      <div className="row m-l-rl-list-wrap">
        <FormBuilder formConfig={formConfig} />
      </div>
      <div className="row m-l-status-field-mobile-view-row">
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 m-l-status-field-mobile-view-column">
          <FieldArray
            showAddButton={true}
            fieldArray={userFormArray}
            fieldArrayName="employeeBUs"
            formConfig={formConfig}
            appendValues={fieldArrayappendValues}
            appendLimit={companyBusinessUnits?.length}
          ></FieldArray>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 m-l-status-field-mobile-view-column-status">
          <div className="m-c-form-group mb-btm-space">
            <label className="m-l-input-label">Status</label>
            <Controller
              name="employeeStatus"
              control={control}
              render={({ field }) => (
                <Restricted
                  permission="CompanyUsers.Activate/InactiveUser"
                  disableField={true}
                >
                  <SelectBox
                    className="m-c-input-control m-c-select-box"
                    elementAttr={{ 'data-testid': 'employeeStatus' }}
                    name="employeeStatus"
                    value={field.value}
                    dataSource={employeeStatusList}
                    displayExpr="optionValueText"
                    valueExpr="optionValueCode"
                    onValueChanged={(e: any) => {
                      field.onChange(e.value);
                    }}
                    disabled={
                      !userDetails ||
                      userDetails.employeeStatus === EmployeeStatus.draft ||
                      userDetails.employeeStatus ===
                        EmployeeStatus.waitingForApproval
                    }
                    fieldRender={
                      employeeStatusList.length ? statusFieldRender : undefined
                    }
                  ></SelectBox>
                </Restricted>
              )}
            />
          </div>
        </div>
      </div>
      <div className="row m-l-rl-list-wrap">
        <FormBuilder formConfig={[radioButtonConfig]} />
      </div>
    </div>
    // </form>
  );
}

const statusFieldRender = (data: any) => {
  return (
    <TextBox
      className={
        data?.optionValueCode === EmployeeStatus.draft ||
        data?.optionValueCode === EmployeeStatus.waitingForApproval
          ? 'draft-lbl'
          : data?.optionValueCode === EmployeeStatus.active
          ? 'active-lbl'
          : 'inactive-lbl'
      }
      defaultValue={data && data.optionValueText}
      readOnly={true}
    />
  );
};
