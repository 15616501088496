import SelectBox from 'devextreme-react/select-box';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SanctionAttribute } from '../../../enums/config-enum';
import { useDataStore } from '../../../hooks/useDataStore';
import { IFormConfig } from '../../../types/form-builder';
import { ListAttributes } from '../../../types/list';
import FormBuilder from '../../common/form-builder/FormBuilder';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { useDateFormat } from '../../../hooks/useDateFormat';

export default function ListEntryFormFields(props: {
  listEntryId: any;
  entryStatus: any;
  removedDate: any;
  attribute: any;
}) {
  const { listEntryId, entryStatus, removedDate, attribute } = props;
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const date = new Date();
  const [type, setType] = useState('text');
  const [dataSource, setDataSource] = useState<any>();
  const [diplayExp, setDiplayExp] = useState('');
  const [valueExp, setValueExp] = useState('');
  const [fieldClass, setFieldClass] = useState('');

  const { getDataSource } = useDataStore();
  const entryNameRef = useRef<SelectBox>();
  const onChangeRemovedDate = (e: any) => {
    if (entryStatus) {
      setValue('entryStatus', entryStatus);
      setFieldClass(
        entryStatus === 'Inactive'
          ? 'm-c-input-control inactive-textbox'
          : 'm-c-input-control active-textbox'
      );
    } else if (e.value) {
      setValue('entryStatus', 'Inactive');
      setFieldClass('m-c-input-control inactive-textbox');
    } else {
      setValue('entryStatus', 'Active');
      setFieldClass('m-c-input-control active-textbox');
    }
  };

  const getData = async (selectListValue: any, selectList: any) => {
    const data = getDataSource(selectListValue, selectList);
    setDataSource(data);
  };
  const nameFieldType = () => {
    if (attribute.isAutosuggestionEnabled) {
      getAutoCompleteDataSource(
        attribute.selectListValue,
        attribute.selectList
      );
      setType('autoComplete');
      setDiplayExp(attribute.selectListDisplay);
      setValueExp(attribute.selectListValue);
    } else if (attribute.isSelectable) {
      setType('select');
      getData(attribute.selectListValue, attribute.selectList);
      setDiplayExp(attribute.selectListDisplay);
      setValueExp(attribute.selectListValue);
    } else if (attribute.attributeType === ListAttributes.DateTime) {
      setType('date');
      setValueExp('');
      setDiplayExp('');
    } else if (attribute.attributeType === ListAttributes.Number) {
      setType('number');
      setValueExp('');
      setDiplayExp('');
    } else {
      setType('text');
      setValueExp('');
      setDiplayExp('');
    }
  };

  useEffect(() => {
    if (attribute) {
      nameFieldType();
    }
  }, [attribute]);

  useEffect(() => {
    setFieldClass(
      entryStatus === 'Inactive'
        ? 'm-c-input-control inactive-textbox'
        : 'm-c-input-control active-textbox'
    );
  }, [entryStatus]);
  const searchExpression = ['port', 'countryName'];

  const autoCompleteItemRender = (data: any) => {
    return <span>{data[diplayExp]}</span>;
  };

  const onSelectionChange = (e: any) => {
    if (attribute.isSelectable) {
      setValue(
        'entryDisplayName',
        e?.selectedItem[attribute.selectListDisplay]
      );
    }
  };

  const onChangeEntryValue = (e: any) => {
    if (attribute?.isAutosuggestionEnabled) {
      setTimeout(() => {
        getAutoCompleteDataSource(
          attribute.selectListValue,
          attribute.selectList,
          e.value
        );
      }, 500);
    }
  };

  const getAutoCompleteDataSource = (
    selectListValue: any,
    selectList: any,
    param?: any
  ) => {
    const data = new DataSource({
      store: new CustomStore({
        key: selectListValue,
        loadMode: 'processed',
        load: () => {
          return api.get({
            url: selectList + `?${attribute?.selectListValue}=${param}`,
          });
        },
      }),
    });

    setDataSource(data);
  };

  const generalFormConfig: IFormConfig[] = [
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'entryName',
      label: attribute ? attribute.displayName : t('labels.name'),
      control: control,
      fieldType: type,
      dataSource: dataSource,
      displayExpr: diplayExp,
      valueExpr: valueExp,
      onChange: onChangeEntryValue,
      selectionChanged: onSelectionChange,
      searchExpr:
        attribute?.attributeName === SanctionAttribute.PortCode
          ? searchExpression
          : false,
      isRequired: true,
      maxLength: type === 'text' ? 100 : undefined,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
      ref: entryNameRef,
      itemRender: type === 'autoComplete' ? autoCompleteItemRender : undefined,
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'reason',
      label: t('labels.reason'),
      control: control,
      fieldType: 'text',
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      fieldClass: fieldClass
        ? fieldClass
        : removedDate
        ? 'm-c-input-control inactive-textbox'
        : 'm-c-input-control active-textbox',
      name: 'entryStatus',
      label: t('labels.status'),
      control: control,
      disabled: true,
      fieldType: 'text',
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'enteredDate',
      label: t('labels.enteredDate'),
      control: control,
      fieldType: 'date',
      max: date,
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'validTillDate',
      label: t('labels.validTillDate'),
      control: control,
      fieldType: 'date',
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'removedDate',
      label: t('labels.removedDate'),
      control: control,
      fieldType: 'date',
      onChange: onChangeRemovedDate,
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'createdBy',
      label: t('labels.createdBy'),
      control: control,
      fieldType: 'text',
      disabled: true,
      isHidden: !listEntryId,
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'modifiedBy',
      label: t('labels.modifiedBy'),
      control: control,
      fieldType: 'text',
      disabled: true,
      isHidden: !listEntryId,
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'createdDate',
      label: t('labels.createdDate'),
      control: control,
      fieldType: 'date',
      disabled: true,
      isHidden: !listEntryId,
    },
    {
      class: 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12',
      name: 'modifiedDate',
      label: t('labels.modifiedDate'),
      control: control,
      fieldType: 'date',
      disabled: true,
      isHidden: !listEntryId,
    },
  ];

  return (
    <div className="row">
      <FormBuilder formConfig={generalFormConfig} />
    </div>
  );
}
