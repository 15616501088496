export type ICompany = {
  companyId?: number;
  companyName: string;
  abbreviation: string;
  companyEmail: string;
  companyCode?: string;
  legalEntityNumber?: string;
  companyType: string;
  companyStatus: string;
  isSendInvite?: boolean;
  isResendInvite?: boolean;
  addresses?: IAddress[];
  contacts?: IContact[];
  marsAccountManager?: number | null;
  logo?: any;
  isBUOrRolesEmpty?: any;
};

export type IContact = {
  contactId?: number;
  companyId?: number;
  personNameTitle?: string | null;
  contactName?: string;
  contactEmail?: string;
  callingCode?: string | null;
  phoneNumber?: string | null;
};

export type IAddress = {
  addressId?: number;
  addressType?: string;
  addressLine1?: string;
  addressLine2?: string;
  countryId?: number | null;
  state?: string;
  zipCode?: string;
  callingCode?: string | null;
  phoneNumber?: string | null;
  companyId?: number;
};

export type ICompanyInfoForm = {
  companyName: string;
  abbreviation: string;
  companyEmail: string;
  legalEntityNumber?: string;
  callingCode?: string | null;
  phoneNumber?: number | null;
  addressLine1?: string;
  addressLine2?: string;
  countryId?: number | null;
  state?: string;
  zipCode?: string;
  companyStatus: string;
  marsAccountManager?: number | null;
  titleId?: string | null;
  name?: string;
  email?: string;
  contactCallingCode?: string | null;
  phone?: number | null;
  companyId?: number;
  addressId?: number;
  contactId?: number;
  companyType?: boolean;
};

export const blackListedEmails: string[] = [
  '@gmail.',
  '@yahoo.',
  '@hotmail.',
  '@aol.',
  '@outlook.',
];

export type CompanyFields =
  | 'companyName'
  | 'abbreviation'
  | 'companyEmail'
  | 'legalEntityNumber'
  | 'callingCode'
  | 'phoneNumber'
  | 'addressLine1'
  | 'addressLine2'
  | 'countryId'
  | 'state'
  | 'zipCode'
  | 'companyStatus'
  | 'marsAccountManager'
  | 'titleId'
  | 'name'
  | 'email'
  | 'phone'
  | 'contactCallingCode'
  | 'companyId'
  | 'addressId'
  | 'contactId';
