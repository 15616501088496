import CustomStore from 'devextreme/data/custom_store';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IGridConfig } from '../../../../types/grid';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import Grid from '../../../common/grid/Grid';
import EditIncident from '../../vessel-details/incidents/view-incidents/edit-incident/EditIncident';
import { getCompanyId, getCompanyType } from '../../../../utils/jwt-decode';
import Button from 'devextreme-react/button';
import { CompanyType } from '../../../../enums/company-type-enum';
import {
  Restricted,
  usePermission,
} from '../../../../contexts/PermissionContext';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import { getAPIRoute } from '../../../../utils/api-route';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import toast from '../../../../utils/toast';
import { IDialogConfig } from '../../../../types/dialog';
import { IOptionvalue } from '../../../../types/master-data';
import { IncidentStatus } from '../../../../enums/status-enum';
import DataGrid from 'devextreme-react/data-grid';

export default function IncidentGrid(props: {
  imoNumber?: string;
  ruleId: number;
  setIncidentsList: any;
  getRuleList: () => Promise<void>;
  isReviewer?: boolean;
  vetRequestId: number;
}) {
  const { t } = useTranslation();
  const { setIsLoading } = useLoaderContext();
  const {
    imoNumber,
    ruleId,
    getRuleList,
    setIncidentsList,
    isReviewer,
    vetRequestId,
  } = props;

  const [incidents, setIncidents] = useState([]);

  const [showEditIncidentModal, setShowEditIncidentModal] = useState(false);

  const [eventId, setEventId] = useState<number | null>();

  const companyType = getCompanyType();

  const { findFeature } = usePermission();

  const companyId = getCompanyId();
  const gridRef = useRef<DataGrid>(null);

  const [confirmAccept, setConfirmAccept] = useState(false);
  const [confirmIgnore, setConfirmIgnore] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState<any>();
  const [incidentState, setIncidentState] = useState<IOptionvalue[]>([]);
  const [isMarsUser, setIsMarsUser] = useState<boolean>();

  const IsCompanyTypeMars = () => {
    if (companyType === 'M') {
      setIsMarsUser(true);
    } else {
      setIsMarsUser(false);
    }
  };
  const incidentStatus = {
    store: new CustomStore({
      key: 'optionValueCode',
      loadMode: 'raw',
      load: () => {
        return api.get({ url: apiConfig.incidentStatus });
      },
    }),
  };

  const stateOfIncidentVal: any = {
    store: new CustomStore({
      key: 'optionValueCode',
      loadMode: 'raw',
      load: () => {
        return api.get({ url: apiConfig.stateOfIncident }).then((res) => {
          setIncidentState(res);
          return res;
        });
      },
    }),
  };

  const showAdditionalFields = useMemo(() => {
    if (companyType === CompanyType.RequestorCompany) {
      return findFeature('Ship.ViewIncidentAdditionalDetails');
    } else {
      if (findFeature('Ship.EditIncident') || findFeature('Ship.AddIncident')) {
        return true;
      } else {
        return findFeature('Ship.ViewIncidentAdditionalDetails');
      }
    }
  }, [companyType]);

  const cellPrepared = (e: any) => {
    if (e.rowType === 'data' && e.column.dataField === 'incidentStatus') {
      e.cellElement.className =
        e.data.incidentStatus === IncidentStatus.Closed
          ? 'status-active-wtout'
          : e.data.incidentStatus === IncidentStatus.AwaitingInfo
          ? 'status-pending-wtout'
          : e.data.incidentStatus === IncidentStatus.WrongEntry
          ? 'status-wrong-entry-wtout'
          : 'status-inactive-wtout';
    }
    if (e.rowType === 'data' && e.column.dataField === 'marsStatus') {
      e.cellElement.className =
        e.data.marsStatus === IncidentStatus.Closed
          ? 'status-active-wtout'
          : e.data.marsStatus === IncidentStatus.AwaitingInfo
          ? 'status-pending-wtout'
          : e.data.marsStatus === IncidentStatus.WrongEntry
          ? 'status-wrong-entry-wtout'
          : 'status-inactive-wtout';
    }
  };

  const onRowClick = (e: any) => {
    setEventId(e.data.eventId);
    setShowEditIncidentModal(true);
  };

  const { formatDate } = useDateFormat();
  const selectionChanged = (e: any) => {
    const incidents: any[] = [];
    e.selectedRowsData.map((element: any) => {
      const incident = { incidentDate: '', eventType: '', placeOfIncident: '' };
      incident.incidentDate =
        element.incidentDate && formatDate(element.incidentDate);
      incident.eventType = element.eventType;
      incident.placeOfIncident = element.placeOfIncident;
      incidents.push(incident);
    });
    setIncidentsList(incidents);
  };

  const gridConfig: IGridConfig = {
    ref: gridRef,
    testId: 'incidentsViewGrid',
    class:
      'm-c-grid full-border-checkbox-grid m-l-incident-grid m-c-auto-scroll-grid',
    dataSource: incidents,
    rowClick: onRowClick,
    rowClickPermission: 'Ship.ViewIncidentDetail',
    defaultColumns: [
      {
        caption: t('labels.eventType'),
        dataField: 'eventType',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.additionalEventType'),
        dataField: 'additionalEventType',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.significance'),
        dataField: 'significance',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.placeOfIncident'),
        dataField: 'placeOfIncident',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.incidentDate'),
        dataField: 'incidentDate',
        dataType: 'date',
        minWidth: 150,
        format: 'dd MMM yyyy',
        sortOrder: 'des',
      },
      {
        caption: t('labels.source'),
        dataField: 'source',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.status'),
        dataField: 'incidentStatus',
        dataType: 'string',
        minWidth: 150,
        lookup: {
          dataSource: incidentStatus,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
        visible:
          companyType === CompanyType.RequestorCompany
            ? false
            : showAdditionalFields,
        // cellRender: 'cellPrepared',
      },
      {
        caption: t('labels.MARSStatus'),
        dataField: 'marsStatus',
        dataType: 'string',
        minWidth: 150,
        lookup: {
          dataSource: incidentStatus,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
      },
      {
        caption: t('labels.action'),
        dataField: 'stateOfIncident',
        dataType: 'string',
        width: 200,
        cellTemplate: 'actionTemplate',
        lookup: {
          dataSource: stateOfIncidentVal,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
      },
    ],
    showHeader: false,
    showExport: false,
    hidePagination: false,
    showSelection: true,
    cellPrepared: cellPrepared,
    selectionChanged: selectionChanged,
  };

  const getIncidentsList = async () => {
    await api
      .get({
        url: getAPIRoute('vetRequestRuleIncident', [vetRequestId, ruleId]),
        params: { ruleId: ruleId },
      })
      .then((data) => {
        setIncidents(data);
      });
  };

  const showAddIncidentModal = () => {
    setEventId(null);
    setShowEditIncidentModal(true);
  };

  useEffect(() => {
    getIncidentsList();
    IsCompanyTypeMars();
  }, []);

  useEffect(() => {
    if (isMarsUser) {
      gridRef.current?.instance.columnOption(5, 'visible', true);
      gridRef.current?.instance.columnOption(7, 'visible', false);
    } else {
      gridRef.current?.instance.columnOption(
        7,
        'visible',
        showAdditionalFields
      );
      gridRef.current?.instance.columnOption(5, 'visible', false);
    }
  }, [isMarsUser]);

  const acceptIncident = async (id: any) => {
    await api
      .put(
        { url: apiConfig.incidentById + id, params: { IsIgnored: false } },
        setIsLoading
      )
      .then(() => {
        toast.custom({
          title: t('toast.incidentAcceptSuccess'),
        });
        getRuleList();
        getIncidentsList();
        setSelectedEventId('');
      });
  };

  const ignoreIncident = async (id: any) => {
    await api
      .put(
        { url: apiConfig.incidentById + id, params: { IsIgnored: true } },
        setIsLoading
      )
      .then(() => {
        toast.custom({
          title: t('toast.incidentIgnoreSuccess'),
        });
        getRuleList();
        getIncidentsList();
        setSelectedEventId('');
      });
  };

  const confirmAcceptDialogConfig: IDialogConfig = {
    id: 'confirmAcceptIncident',
    content: t('labels.acceptIncident'),
    handleSubmit: () => {
      acceptIncident(selectedEventId);
      setConfirmAccept(false);
    },
    handleClose: () => {
      setConfirmAccept(false);
      setSelectedEventId('');
    },
  };

  const confirmIgnoreDialogConfig: IDialogConfig = {
    id: 'confirmIgnoreIncident',
    content: t('labels.ignoreIncident'),
    handleSubmit: () => {
      ignoreIncident(selectedEventId);
      setConfirmIgnore(false);
    },
    handleClose: () => {
      setConfirmIgnore(false);
      setSelectedEventId('');
    },
  };
  const onClickAccept = (id: any) => {
    setConfirmAccept(true);
    setSelectedEventId(id);
  };

  const onClickIgnore = (id: any) => {
    setConfirmIgnore(true);
    setSelectedEventId(id);
  };
  const getStateOfIncidentText = (stateOfIncident: any) => {
    const item = incidentState?.find(
      (item: any) => item.optionValueCode == stateOfIncident
    );
    return item?.optionValueText;
  };

  const actionTemplate = (data: any) => {
    return (
      <div className="m-l-icon-btn-holder">
        {isMarsUser &&
          (data.data.stateOfIncident ? (
            <div className="m-l-field-labels">
              {getStateOfIncidentText(data.data.stateOfIncident)}
            </div>
          ) : (
            !data.data.isMarsOrExternal && (
              <>
                <Button
                  className="app-c-btn app-c-btn--secondary"
                  onClick={() => onClickAccept(data.data.eventId)}
                >
                  {t('buttons.accept')}
                </Button>
                <Restricted permission="Ship.IgnoreIncident">
                  <Button
                    className="app-c-btn app-c-btn--secondary"
                    onClick={() => onClickIgnore(data.data.eventId)}
                  >
                    {t('buttons.ignore')}
                  </Button>
                </Restricted>
              </>
            )
          ))}
      </div>
    );
  };

  return (
    <>
      <div className="row m-l-cap-wrapper mrbtm-space">
        <div className="col-xl-6 col-lg-6 co-md-6 col-sm-6 col-12 m-l-cap-left-part">
          <div className="m-l-page-small-sub-heading">
            {t('headers.incidentDetails')}
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 co-md-6 col-sm-6 col-12 m-l-cap-right-part">
          <div className="m-l-cap-right-icon-part">
            <Restricted permission="Ship.AddIncident">
              {isReviewer && companyType !== CompanyType.RequestorCompany && (
                <Button
                  className="app-c-btn app-c-btn--primary"
                  onClick={showAddIncidentModal}
                >
                  {t('buttons.addNewIncident')}
                </Button>
              )}
            </Restricted>
          </div>
        </div>
      </div>
      <div className="row m-l-cap-rules-wrapper">
        <Grid gridConfig={gridConfig} actionTemplate={actionTemplate} />
        {showEditIncidentModal && (
          <EditIncident
            isVisible={showEditIncidentModal}
            setIsVisible={setShowEditIncidentModal}
            eventId={eventId}
            imoNumber={imoNumber}
            getIncidentsList={getIncidentsList}
            isMarsUser={companyType === CompanyType.Mars}
            companyId={companyId}
            getRuleList={getRuleList}
          />
        )}
        {confirmAccept && (
          <ConfirmDialog
            dialogConfig={confirmAcceptDialogConfig}
            isOpen={confirmAccept}
          />
        )}
        {confirmIgnore && (
          <ConfirmDialog
            dialogConfig={confirmIgnoreDialogConfig}
            isOpen={confirmIgnore}
          />
        )}
      </div>
    </>
  );
}
