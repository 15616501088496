import Button from 'devextreme-react/button';
import Popover from 'devextreme-react/popover';
import TextBox from 'devextreme-react/text-box';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessUnitStatus } from '../../../../enums/status-enum';
import { useResize } from '../../../../hooks/useResize';
import { IBUClone } from '../../../../types/business-unit';

export default function BusinessUnitClone(props: {
  showPopover: boolean;
  target: string;
  closeOnOutsideClick: boolean;
  onHidingPopover: any;
  companyId: number;
  buDetails: any;
  cloneBU: (cloneBUJson: IBUClone) => void;
  onClickCancel: () => void;
}) {
  const {
    showPopover,
    target,
    closeOnOutsideClick,
    onHidingPopover,
    cloneBU,
    onClickCancel,
    companyId,
    buDetails,
  } = props;
  const popupAttributes = useMemo(() => {
    return {
      'data-testid': 'buClonePopover',
    };
  }, []);

  const [buName, setBuName] = useState('');

  const { t } = useTranslation();

  const getBUCloneJson = (): IBUClone => {
    return {
      companyId: companyId,
      clonedBUId: buDetails.buId,
      buName: buName?.trim(),
      buStatus: BusinessUnitStatus.Active,
    };
  };

  const onClickClone = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      cloneBU(getBUCloneJson());
    }
  };

  const { isMobileResolution } = useResize();
  const [position, setPosition] = useState<any>('bottom');
  useEffect(() => {
    if (isMobileResolution) {
      setPosition('left');
    } else {
      setPosition('bottom');
    }
  }, [isMobileResolution]);

  return (
    <>
      <Popover
        wrapperAttr={popupAttributes}
        id="rolesPopover"
        target={target}
        position={position}
        width={300}
        closeOnOutsideClick={closeOnOutsideClick}
        visible={showPopover}
        onHiding={onHidingPopover}
        hideOnParentScroll={false}
      >
        <div className="m-l-grid-clone-popup-wrap">
          <div className="m-l-grid-clone-popup-header">
            <h2>{t('labels.newBUName')}</h2>
          </div>
          <div className="m-l-grid-clone-popup-body">
            <div className="m-c-form-group ">
              <TextBox
                inputAttr={{ 'data-testid': 'buName' }}
                className="m-c-input-control"
                onValueChanged={(e: any) => {
                  setBuName(e.value);
                }}
                value={buName}
                validationMessageMode="always"
              >
                <Validator>
                  <RequiredRule message={t('errors.required')} />
                </Validator>
              </TextBox>
            </div>
          </div>
          <div className="m-l-grid-clone-popup-footer">
            <Button
              className="app-c-btn app-c-btn--secondary"
              onClick={onClickCancel}
              elementAttr={{ 'data-testid': 'cancel' }}
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              className="app-c-btn app-c-btn--secondary"
              onClick={onClickClone}
              elementAttr={{ 'data-testid': 'clone' }}
            >
              {t('buttons.clone')}
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
}
