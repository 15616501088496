import DataSource from 'devextreme/data/data_source';
import { useCallback, useEffect, useState } from 'react';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { getAdditionalInfoToken, getToken } from '../utils/jwt-decode';
import toast from '../utils/toast';
import { useLoaderContext } from '../contexts/LoaderContext';

export const useDataStore = (key?: string, url?: string, pageSize?: number) => {
  const API_URL = process.env.REACT_APP_BASE_URL;

  const [dataSource, setDataSource] = useState<any>(new DataSource([]));
  const { setIsLoading } = useLoaderContext();
  useEffect(() => {
    if (key && url) {
      const customDataSource = getDataSource(key, url, pageSize);
      setDataSource(customDataSource);
    }
  }, [key, url]);

  const getDataSource = useCallback(
    (
      key: string,
      url: string,
      pageSize?: number,
      method?: string,
      data?: any,
      removePagination?: boolean,
      mapFunction?: any,
      showErrorToast?: boolean
    ) => {
      const token = getToken();
      const additionalInfoToken = getAdditionalInfoToken();
      return new DataSource({
        pageSize: pageSize ? pageSize : 40,
        paginate: removePagination ? false : true,
        customQueryParams: data,
        store: createStore({
          cacheRawData: true,
          key: key,
          loadUrl: API_URL + url,
          onBeforeSend: (opertaion, ajaxOptions) => {
            if (method === 'POST') {
              setIsLoading(true);
            }
            ajaxOptions.headers = {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
              'Additional-Info': `${additionalInfoToken}`,
            };
            if (method) {
              ajaxOptions.method = method;
            }
            if (data) {
              ajaxOptions.data = {
                ...ajaxOptions.data,
                customParam: JSON.stringify(data),
              };
            }
          },
          onLoaded: () => {
            if (method === 'POST') {
              setIsLoading(false);
            }
          },
        }),
        map: mapFunction ? (item: any) => mapFunction(item) : undefined,
        onLoadError: (error) => {
          if (method === 'POST') {
            setIsLoading(false);
          }
          if (error && error.message && showErrorToast) {
            toast.error({ title: 'Error', message: error.message });
          }
        },
      });
    },
    []
  );

  return { dataSource, getDataSource };
};
