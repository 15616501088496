import React, { useEffect } from 'react';
import 'devextreme/dist/css/dx.light.css';
import './App.scss';
import { LoaderProvider } from './contexts/LoaderContext';
import Router from './router/Router';
import { AuthProvider } from './contexts/AuthProvider';
import { PermissionProvider } from './contexts/PermissionContext';
import { useLocation } from 'react-router-dom';
import packageJson from '../package.json';

const buildDateGreaterThan = (latestDate: any, currentDate: any) => {
  if (latestDate > currentDate) {
    return true;
  } else {
    return false;
  }
};

function App() {
  const location = useLocation();
  useEffect(() => {
    window.localStorage.setItem(
      'REQUESTING_SHARED_CREDENTIALS',
      Date.now().toString()
    );
    window.localStorage.removeItem('REQUESTING_SHARED_CREDENTIALS');
  }, []);

  useEffect(() => {
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;

        const shouldForceRefresh = buildDateGreaterThan(
          latestVersionDate,
          currentVersionDate
        );
        if (shouldForceRefresh) {
          refreshCacheAndReload();
        }
      });
  }, []);

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload();
  };
  return (
    <AuthProvider>
      <PermissionProvider>
        <LoaderProvider>
          <Router />
        </LoaderProvider>
      </PermissionProvider>
    </AuthProvider>
  );
}

export default App;
