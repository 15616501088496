import React, { useEffect, useMemo, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { IFormConfig } from '../../../../types/form-builder';
import { useTranslation } from 'react-i18next';
import FieldArray from '../../../common/field-array/FieldArray';
import TagBox from 'devextreme-react/tag-box';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import { usePermission } from '../../../../contexts/PermissionContext';
import parseISO from 'date-fns/parseISO';
import { getAPIRoute } from '../../../../utils/api-route';
import { MarsApiService as api } from '../../../../api/mars-api-service';
export default function LoadPortForm(props: {
  portDataSource: any;
  terminals: any;
  checkBerthFit: any;
  setCheckBerthFit: any;
  laycanFromDate?: any;
  laycanToDate?: any;
  setLowestLoadDate: any;
  vetRequestDetails?: any;
  isMastersLoaded: boolean;
}) {
  const { t } = useTranslation();
  const { control, getValues, setValue, resetField } = useFormContext();
  const {
    portDataSource,
    terminals,
    checkBerthFit,
    setCheckBerthFit,
    setLowestLoadDate,
    vetRequestDetails,
    isMastersLoaded,
  } = props;
  const terminalRef = useRef<TagBox[]>([]);
  const portDetails: any[] = [];
  const loadPortFormArray = useFieldArray({
    control: control,
    name: 'vetRequestLoadPorts',
  });
  const { featureList, findFeature } = usePermission();

  const setLoadValues = (dates: any) => {
    let lowest = '';
    const formDates = getValues()?.vetRequestLoadPorts.filter((item: any) => {
      if (lowest) {
        if (item.estimatedDateOfLoad < lowest) {
          lowest = item.estimatedDateOfLoad;
        }
      } else {
        lowest = item.estimatedDateOfLoad;
      }
    });
    setLowestLoadDate(lowest);
  };

  const fieldArrayappendValues = {
    loadPortId: '',
    estimatedDateOfLoad: '',
    vetRequestLoadPortTerminals: [],
    berthFitCheck: getValues()?.berthFitCheck,
  };

  const validateDate = (params: any) => {
    if (
      vetRequestDetails?.estimatedDateOfLoad &&
      String(params.value) ===
        String(parseISO(vetRequestDetails?.estimatedDateOfLoad))
    ) {
      return true;
    } else {
      const date = new Date();
      const currentDate = new Date(params.value);
      const formattedDate = date.setHours(0, 0, 0, 0);
      const formattedCurrentDate = currentDate.setHours(0, 0, 0, 0);
      return formattedDate <= formattedCurrentDate;
    }
  };

  const validateLaycanDate = (params: any) => {
    const laycanFromDate = getValues().laycanFromDate;
    const laycanToDate = getValues().laycanToDate;

    if (
      laycanFromDate != '' &&
      laycanFromDate != null &&
      laycanToDate != '' &&
      laycanToDate != null
    ) {
      const fromDate = new Date(laycanFromDate);
      const toDate = new Date(laycanToDate);
      const currentDate = new Date(params.value);
      return (
        fromDate.getTime() <= currentDate.getTime() &&
        toDate.getTime() >= currentDate.getTime()
      );
    }
    return true;
  };

  const onValueChange = (e: any) => {
    const maxItems = 5;
    if (e.event && e.value.length > maxItems) {
      const newValue = e.value.slice(0, maxItems);
      e.component.option('value', newValue);
    }
  };

  const getLoadportWarning = async (portId: any, index: any) => {
    if (portId) {
      await api
        .get({
          url: getAPIRoute('portWarning', [portId]),
        })
        .then((response: any) => {
          resetField(`vetRequestLoadPorts[${index}].portWarning`, {
            defaultValue: response,
            keepDirty: false,
          });
        });
    } else {
      resetField(`vetRequestLoadPorts[${index}].portWarning`, {
        defaultValue: '',
        keepDirty: false,
      });
    }
  };
  const onChangePort = (e: any, index: any) => {
    getLoadportWarning(e.value, index);
    if (
      getValues().vetRequestLoadPorts[index]?.vetRequestLoadPortTerminals
        ?.length > 0
    ) {
      setValue(`vetRequestLoadPorts[${index}].vetRequestLoadPortTerminals`, []);
    }
    setValue(
      `vetRequestLoadPorts[${index}].portName`,
      e.event.target.innerText
    );
    const ids = portDetails.map((code) => code.id);
    const filteredPortDetails = portDetails.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );
    setValue(`vetRequestLoadPorts[${index}].portDetails`, filteredPortDetails);
    terminalRef.current[index]?.instance
      .getDataSource()
      .filter(['port.portId', '=', e.value]);
    terminalRef.current[index]?.instance.getDataSource()?.load();
  };

  const filterFieldDataSource = () => {
    if (terminalRef.current.length > 0) {
      terminalRef.current.map((fieldRef: any, index: number) => {
        fieldRef?.instance
          .getDataSource()
          .filter([
            'port.portId',
            '=',
            getValues().vetRequestLoadPorts[index]?.loadPortId,
          ]);
        fieldRef?.instance.getDataSource()?.load();
      });
    }
  };

  useEffect(() => {
    terminalRef.current?.map((fieldRef, index) => {
      fieldRef?.instance
        .getDataSource()
        .filter([
          'port.portId',
          '=',
          getValues().vetRequestLoadPorts[index].loadPortId,
        ]);
      fieldRef?.instance.getDataSource()?.load();
    });
  }, [portDataSource]);

  useEffect(() => {
    if (checkBerthFit) {
      setPortsCheckForBerthFit(getValues().berthFitCheck);
    }
  }, [getValues().berthFitCheck, checkBerthFit]);

  const setPortsCheckForBerthFit = (berthFitCheck: boolean) => {
    const values = [...getValues().vetRequestLoadPorts];
    values.forEach((item: any, index: any) => {
      setValue(`vetRequestLoadPorts[${index}].berthFitCheck`, berthFitCheck);
      // loadPortFormArray.update(index, {
      //   loadPortId: item.loadPortId,
      //   estimatedDateOfLoad: item.estimatedDateOfLoad,
      //   vetRequestLoadPortTerminals: item.vetRequestLoadPortTerminals,
      //   portDetails: item.portDetails,
      //   portName: item.portName,
      //   berthFitCheck: berthFitCheck,
      // });
    });
  };

  useEffect(() => {
    if (vetRequestDetails && isMastersLoaded) {
      const values = vetRequestDetails?.vetRequestLoadPorts;
      values?.forEach((item: any, index: any) => {
        getLoadportWarning(item?.loadPortId, index);
      });
    }
  }, [vetRequestDetails, isMastersLoaded]);

  const onChangeCheckBox = (e: any) => {
    setCheckBerthFit(false);
    const loadPortValues = [...getValues().vetRequestLoadPorts];
    const berthFitCheckLoadPortsLength = loadPortValues.filter(
      (loadPort: any) => loadPort.berthFitCheck == true
    ).length;
    const dischargePortValues = [...getValues().vetRequestDischargePorts];
    const berthFitCheckDischargePortsLength = dischargePortValues.filter(
      (dischargePort: any) => dischargePort.berthFitCheck == true
    ).length;
    if (e.event) {
      if (
        e.value == false &&
        berthFitCheckLoadPortsLength == 0 &&
        berthFitCheckDischargePortsLength == 0
      ) {
        setValue('berthFitCheck', false);
      } else if (
        e.value == true &&
        (berthFitCheckLoadPortsLength > 0 ||
          berthFitCheckDischargePortsLength > 0)
      ) {
        setValue('berthFitCheck', true);
      }
    }
  };

  const customTerminalValidation = (params: any) => {
    const index = parseInt(params.validator._$element[0].id);
    const rowData = getValues().vetRequestLoadPorts[index];
    if (
      rowData.loadPortId &&
      rowData.berthFitCheck &&
      rowData.vetRequestLoadPortTerminals?.length === 0 &&
      findFeature('BerthFitVet.BerthfitCheck')
    ) {
      return false;
    }
    return true;
  };

  const searchExpression = ['port', 'countryName'];

  const { dateFormat } = useDateFormat();
  const formConfig: IFormConfig[] = useMemo(() => {
    if (featureList?.length !== 0 && isMastersLoaded) {
      return [
        {
          name: 'loadPortId',
          checkBoxName: 'berthFitCheck',
          checkBoxLabel: t('labels.checkBerthFit'),
          showWarning: true,
          fieldName: 'loadport',
          label: t('labels.port'),
          showLabelIndex: true,
          control: control,
          fieldType: findFeature('BerthFitVet.BerthfitCheck')
            ? 'selectWithDefault'
            : 'select',
          displayExpr: 'portName',
          valueExpr: 'portId',
          searchExpr: searchExpression,
          dataSource: portDataSource,
          isFieldArray: true,
          // isRequired: true,
          class:
            'col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 m-l-custom-eight-space-col',
          // rules: [
          //   {
          //     type: 'required',
          //     message: t('errors.required'),
          //   },
          // ],
          // fieldRender: fieldRender,
          onChange: onChangePort,
          onChangeCheckBox: onChangeCheckBox,
          isHidden: featureList && featureList.length === 0,
        },
        {
          name: 'vetRequestLoadPortTerminals',
          label: t('labels.terminals'),
          control: control,
          fieldType: 'multiSelect',
          displayExpr: 'terminalName',
          valueExpr: 'terminalId',
          dataSource: terminals,
          isFieldArray: true,
          showClearButton: true,
          maxDisplayedTags: 2,
          showMultiTagOnly: false,
          onChange: onValueChange,
          placeholder: t('labels.select'),
          class:
            'col-xl col-lg col-md-12 col-sm-12 col-12 m-l-custom-eight-space-col',
          ref: terminalRef,
          rules: [
            {
              type: 'custom',
              message: t('errors.required'),
              reevaluate: true,
              validationCallback: customTerminalValidation,
            },
          ],
        },
        {
          name: 'estimatedDateOfLoad',
          label: t('labels.estimatedDateOfLoad'),
          control: control,
          fieldType: 'date',
          isFieldArray: true,
          onChange: setLoadValues,
          rules: [
            {
              type: 'custom',
              validationCallback: validateDate,
              message: t('errors.mustBeFutureDate'),
              reevaluate: true,
              ignoreEmptyValue: true,
            },
            {
              type: 'custom',
              validationCallback: validateLaycanDate,
              message: t('errors.dateBetweenLaycan'),
              ignoreEmptyValue: true,
            },
          ],
          // placeholder: t('labels.dataPlaceholder'),
          placeholder: dateFormat?.toUpperCase(),
          class:
            'col-xl-2 col-lg-3 col-md-12 col-sm-12 col-12 m-l-custom-eight-space-col port-pdng',
        },
      ];
    } else {
      return [];
    }
  }, [featureList, isMastersLoaded]);

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 m-l-custom-eight-space-col">
      <div className="m-l-ldprt-part m-l-crvt-block">
        <div className="m-l-form-panel-block m-l-card-form">
          <div className="m-l-form-panel-header-block">
            <div className="m-l-form-panel-header-left-block">
              <div className="m-l-page-small-heading">Load Port</div>
            </div>
          </div>

          <div className="m-l-form-panel-body-block m-l-pdng-btm0">
            <div className="row m-l-custom-eight-space-row m-l-lp-data">
              <FieldArray
                fieldArray={loadPortFormArray}
                fieldArrayName="vetRequestLoadPorts"
                formConfig={formConfig}
                appendValues={fieldArrayappendValues}
                appendLimit={3}
                showAddButton={true}
                filterDataSource={true}
                filterFieldDataSource={filterFieldDataSource}
                buttonWrapperClass="mb-btm-space m-c-form-group m-l-button-vertical-center-align m-l-fm-icon-btn u-width-full"
                buttonWrapperParentClass="col-xl-auto col-lg-auto col-md-12 col-sm-12 col-12 m-l-custom-eight-space-col"
              ></FieldArray>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
