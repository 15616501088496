import Button from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IModalConfig } from '../../../../types/modal';
import icons from '../../../common/icons/icons';
import Modal from '../../../common/modal/Modal';
import CargoTypeForm from './CargoTypeForm';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../../types/dialog';
import { IBerthCargoType } from '../../../../types/port-management';
import toast from '../../../../utils/toast';
import ValidationGroup from 'devextreme-react/validation-group';

export default function CargoTypeEdit(props: {
  isVisible: boolean;
  handleCancelClick: any;
  berthCode: any;
  getCargoData: any;
  berthProductHandleId: any;
  setBerthProductHandleId: any;
}) {
  const { t } = useTranslation();

  const {
    handleCancelClick,
    isVisible,
    berthCode,
    getCargoData,
    berthProductHandleId,
    setBerthProductHandleId,
  } = props;
  const [berthTypeData, setBerthTypeData] = useState<any>();
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [dataById, setDataById] = useState<any>();
  const validationGroup: any = useRef();
  const [isconfirmBackDialog, setIsConfirmBackDialog] = useState(false);

  const modalConfig: IModalConfig = {
    width: 450,
    height: 350,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-edit-name-popup',
    };
  }, []);

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmSaveChanges'),
    handleSubmit: () => {
      onValueSubmit();
    },
    handleClose: () => {
      setShowConfirmSave(false);
    },
  };

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      handleCancelClick();
      setDataById(undefined);
      setBerthProductHandleId(undefined);
    },
    handleClose: () => {
      setIsConfirmBackDialog(false);
    },
  };

  const getBerthTypeData = async () => {
    await api
      .get({ url: apiConfig.optionValue + 'BerthType' })
      .then((data: any) => {
        setBerthTypeData(data);
      });
  };

  useEffect(() => {
    getBerthTypeData();
  }, []);

  const getDataById = async () => {
    await api
      .get({
        url: apiConfig.berthCargo + berthProductHandleId,
      })
      .then((res) => {
        setDataById(res);
      });
  };

  useEffect(() => {
    if (berthProductHandleId) {
      getDataById();
    }
  }, [berthProductHandleId]);

  const formDefaultValues = {
    berthCode: '',
    berthType: '',
    mainCargoType: '',
  };

  const cargoTypeForm = useForm({
    defaultValues: formDefaultValues,
  });

  const { isDirty } = cargoTypeForm.formState;

  useEffect(() => {
    cargoTypeForm.reset();
    if (dataById) {
      cargoTypeForm.reset(
        {
          berthType: dataById.berthTypeCode,
          mainCargoType: dataById.mainCargoType,
        },
        { keepDirty: true }
      );
    }
  }, [dataById]);

  const onValueSubmit = () => {
    const formValue: IBerthCargoType = cargoTypeForm.getValues();
    const formData: IBerthCargoType = {
      berthCode: berthCode,
      berthType: formValue.berthType,
      mainCargoType: formValue.mainCargoType,
    };
    if (berthProductHandleId) {
      formData.berthProductHandleId = berthProductHandleId;
      edit(formData);
    } else {
      add(formData);
    }
  };

  const add = async (data: any) => {
    await api
      .post({ url: apiConfig.berthCargo, data: data })
      .then((response) => {
        if (response) {
          handleCancelClick();
          setShowConfirmSave(false);
          getCargoData();
          setBerthProductHandleId(undefined);
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
        }
      });
  };

  const edit = async (data: any) => {
    await api
      .put({ url: apiConfig.berthCargo, data: data })
      .then((response) => {
        if (response) {
          handleCancelClick();
          setShowConfirmSave(false);
          getCargoData();
          setBerthProductHandleId(undefined);
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
        }
      });
  };

  const onClickCancel = () => {
    isDirty
      ? setIsConfirmBackDialog(true)
      : (handleCancelClick(),
        setDataById(undefined),
        setBerthProductHandleId(undefined));
  };

  const onClickSave = () => {
    const requiredValidation = validationGroup.current.instance.validate();
    if (requiredValidation.isValid) {
      setShowConfirmSave(true);
    }
  };

  return (
    <div className="m-l-model-popup__wrap" data-testid="editName">
      <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
        <div className="m-l-modal__header">
          {berthProductHandleId ? (
            <h2 className="modal-title"> Edit Cargo Type</h2>
          ) : (
            <h2 className="modal-title">Add New</h2>
          )}

          <div className="m-l-modal-close-button-block">
            <Button
              className="app-c-btn app-c-icon-only-btn"
              onClick={onClickCancel}
            >
              <icons.IconCross />
            </Button>
          </div>
        </div>
        <div className="m-l-modal__body">
          <ScrollView width="100%" height="100%">
            <div className="m-l-edit-form-panel">
              <FormProvider {...cargoTypeForm}>
                <ValidationGroup ref={validationGroup}>
                  <CargoTypeForm berthTypeData={berthTypeData} />
                </ValidationGroup>
              </FormProvider>
            </div>
          </ScrollView>
        </div>
        <div className="m-l-modal__footer">
          <Button
            className="app-c-btn app-c-btn--secondary min-btn-width"
            elementAttr={{ 'data-testid': 'backButton' }}
            onClick={onClickCancel}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            className="app-c-btn app-c-btn--primary min-btn-width"
            elementAttr={{ 'data-testid': 'saveButton' }}
            onClick={() => onClickSave()}
          >
            {t('buttons.save')}
          </Button>
        </div>
        <ConfirmDialog
          dialogConfig={confirmSaveDialogConfig}
          isOpen={showConfirmSave}
        />
        <ConfirmDialog
          dialogConfig={confirmBackDialogConfig}
          isOpen={isconfirmBackDialog}
        />
      </Modal>
    </div>
  );
}
