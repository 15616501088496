import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DimensionUnit } from '../../../enums/dimension-unit-enum';
import { IFormConfig } from '../../../types/form-builder';
import FormBuilder from '../../common/form-builder/FormBuilder';
import CountryList from '../../Country.json';

export default function UserProfileForm(props: { buRoleList: any }) {
  const { buRoleList } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [selectedBu, setSelectedBu] = useState();
  const [userRoles, setUserRoles] = useState();
  const countryList = CountryList.sort();
  const distinctCallingCodes = countryList
    .map((item) => item.callingCode)
    .filter((value, index, self) => self.indexOf(value) === index);

  const callingCodes: any = [];
  distinctCallingCodes.forEach((callingCode) =>
    callingCodes.push({
      callingCode: callingCode,
    })
  );

  const setBu = (e: any) => {
    setSelectedBu(e.value);
  };

  useEffect(() => {
    const buRoles = buRoleList?.find((item: any) => item.buName == selectedBu);
    setUserRoles(buRoles?.userBuRoles);
  }, [selectedBu]);

  const renderItem = (data: any) => {
    return (
      <div>
        <div style={{ display: 'inline-block', textAlign: 'left' }}>
          {data?.callingCode}
        </div>
      </div>
    );
  };

  const userProfileFormConfig: IFormConfig[] = [
    {
      class: 'm-c-input-control m-c-input-password',
      name: 'employeeName',
      label: t('labels.name'),
      control: control,
      fieldType: 'text',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
        {
          type: 'pattern',
          pattern: '^([a-zA-Z_!@#$%^&*.-]+[ ]*)*$',
          message: t('errors.nameFormat'),
        },
      ],
    },
    {
      class: 'm-c-input-control m-c-input-password',
      name: 'employeeEmail',
      label: t('labels.emailId'),
      control: control,
      fieldType: 'text',
      disabled: true,
    },
    {
      class: 'm-c-input-control m-c-input-password',
      name: 'employeeCode',
      label: t('labels.employeeId'),
      control: control,
      fieldType: 'number',
      disabled: true,
    },
    {
      class: 'm-c-input-control m-c-input-password',
      name: 'phoneNumber',
      label: t('labels.phoneNumber'),
      control: control,
      fieldType: 'customSelect',
      dataSource: callingCodes,
      displayExpr: 'callingCode',
      valueExpr: 'callingCode',
      selectBoxName: 'callingCode',
      maxLength: 24,
      itemRender: renderItem,
      customSelectInputFieldType: 'text',
      rules: [
        {
          type: 'pattern',
          pattern: '^[0-9]+$',
          message: t('errors.inCorrectPhoneNumberFormat'),
        },
      ],
    },
    {
      class: 'm-c-input-control m-c-input-password',
      name: 'employeeStatus',
      label: t('labels.status'),
      control: control,
      fieldType: 'text',
      disabled: true,
    },
    {
      name: 'unitOfLength',
      label: 'Length Unit',
      control: control,
      fieldType: 'radio',
      items: [
        { value: DimensionUnit.Meter, name: 'Meter' },
        { value: DimensionUnit.Feet, name: 'Feet' },
      ],
      layout: 'horizontal',
      displayExpr: 'name',
      valueExpr: 'value',
      class: 'm-c-input-control m-c-input-password',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
      fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={userProfileFormConfig} />
      </div>
    </>
  );
}
