export enum VetRequestStatus {
  requested = 'Requested',
  assigned = 'Assigned',
  unassigned = 'Unassigned',
  inProgress = 'In Progress',
  waitingforExternalInfo = 'Waiting for External Info',
  waitingforRequestorinfo = 'Waiting for Requestor info',
  infoReceived = 'Info Received',
  cancelled = 'Cancelled',
  autoCancelled = 'Auto Cancelled',
  recommended = 'Recommended',
  notRecommended = 'Not Recommended',
  appeal = 'Appeal',
  approved = 'Approved',
  conditionallyApproved = 'Conditionally Approved',
  rejected = 'Rejected',
  processing = 'Processing',
}

export enum VetRequestStatusCode {
  requested = 'Requested',
  assigned = 'Assigned',
  unassigned = 'Unassigned',
  inProgress = 'InProgress',
  waitingforExternalInfo = 'WaitingForExternalInfo',
  waitingforRequestorinfo = 'WaitingForRequestorInfo',
  infoReceived = 'InfoReceived',
  cancelled = 'Cancelled',
  autoCancelled = 'AutoCancelled',
  recommended = 'Recommended',
  notRecommended = 'NotRecommended',
  appeal = 'Appeal',
  approved = 'Approved',
  conditionallyApproved = 'ConditionallyApproved',
  rejected = 'Rejected',
  processing = 'Processing',
}
