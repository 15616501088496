import Button from 'devextreme-react/button';
import DateBox from 'devextreme-react/date-box';
import Popover from 'devextreme-react/popover';
import ScrollView from 'devextreme-react/scroll-view';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IAddToList } from '../../../types/port-configurations';
import icons from '../../common/icons/icons';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import toast from '../../../utils/toast';
import { useTranslation } from 'react-i18next';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { getAPIRoute } from '../../../utils/api-route';
import { getBuList, getCompanyId } from '../../../utils/jwt-decode';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import { useResize } from '../../../hooks/useResize';

export default function AddListPopover(props: {
  target: string;
  showPopover: boolean;
  setShowPopover: any;
  selectedTab: any;
  codesToList?: any;
  terminalEntityNames?: any;
  berthEntityNames?: any;
  setClearSelection?: any;
}) {
  const {
    target,
    showPopover,
    setShowPopover,
    selectedTab,
    codesToList,
    berthEntityNames,
    terminalEntityNames,
    setClearSelection,
  } = props;
  const [reason, setReason] = useState('');
  const [enteredDate, setEnteredDate] = useState<any>();
  const [validDate, setvalidDate] = useState<any>();
  const [removedDate, setRemovedDate] = useState<any>();
  // const { getDataSource } = useDataStore();
  const { t } = useTranslation();
  const { dateFormat, apiDateFormat } = useDateFormat();
  const [listDataSource, setListDataSource] = useState();
  const companyId = getCompanyId();
  const [entryNames, setEntryNames] = useState<any[]>([]);
  const [listId, setListId] = useState<any>();
  const [listName, setListName] = useState();
  const validationGroup: any = useRef();
  const date = new Date();
  const bu: any = getBuList();
  const [buId, setBuId] = useState();
  const buList = JSON.parse(bu);
  const { isMobileResolution } = useResize();
  const [position, setPosition] = useState<any>('bottom');

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-add-roles__popup',
      'data-testid': 'BuRuleCopyPopover',
    };
  }, []);

  useEffect(() => {
    const defaultBu = buList.find((bu: any) => bu.isDefault == true);
    setBuId(defaultBu.buId);
  }, []);

  useEffect(() => {
    if (isMobileResolution) {
      setPosition('left');
    } else {
      setPosition('bottom');
    }
  }, [isMobileResolution]);

  useEffect(() => {
    setListId(undefined);
    setListName(undefined);
    validationGroup.current?.instance.reset();
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab === 0) {
      const entryData = codesToList?.map((item: any) => {
        const data = {
          entryName: item.portCode,
          entryDisplayName: item.portName,
        };
        return data;
      });
      setEntryNames(entryData);
    }
  }, [codesToList]);

  useEffect(() => {
    if (selectedTab === 1) {
      const entryData = terminalEntityNames.map((item: any) => {
        const data = {
          entryName: item.terminalCode,
          entryDisplayName: item.terminalName,
        };
        return data;
      });
      setEntryNames(entryData);
    }
  }, [terminalEntityNames]);

  useEffect(() => {
    if (selectedTab === 2) {
      const entryData = berthEntityNames.map((item: any) => {
        const data = {
          entryName: item.berthCode,
          entryDisplayName: item.berthName,
        };
        return data;
      });
      setEntryNames(entryData);
    }
  }, [berthEntityNames]);

  const onListChange = (e: any) => {
    setListName(e.event?.target?.innerText);
    setListId(e.value);
  };

  const checkRemovedDate = () => {
    if (
      enteredDate != '' &&
      enteredDate != null &&
      removedDate != '' &&
      removedDate != null
    ) {
      const enterDate = new Date(enteredDate);
      const removeDate = new Date(removedDate);
      if (removeDate.getTime() >= enterDate.getTime()) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const checkValidTillDate = () => {
    if (
      enteredDate != '' &&
      enteredDate != null &&
      validDate != '' &&
      validDate != null
    ) {
      const enterDate = new Date(enteredDate);
      const validTill = new Date(validDate);
      if (validTill.getTime() >= enterDate.getTime()) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const getDataSource = async (entityName: any, attributeName: any) => {
    await api
      .get({
        url: getAPIRoute('sanctionsList', [entityName, attributeName]),
        params: { companyId: companyId, buId: buId },
      })
      .then((res) => {
        setListDataSource(res);
      });
  };

  useEffect(() => {
    if (!showPopover) {
      setvalidDate('');
      setEnteredDate('');
      setRemovedDate('');
      setReason('');
    }
  }, [showPopover]);

  useEffect(() => {
    if (buId) {
      if (selectedTab === 0) {
        const entityName = 'Ports';
        const attributeName = 'PortCode';
        getDataSource(entityName, attributeName);
      } else if (selectedTab === 1) {
        const entityName = 'Terminal';
        const attributeName = 'TerminalCode';
        getDataSource(entityName, attributeName);
      } else if (selectedTab === 2) {
        const entityName = 'Berth';
        const attributeName = 'BerthCode';
        getDataSource(entityName, attributeName);
      }
    }
  }, [selectedTab, buId]);

  const onAddNewList = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      if (checkValidTillDate()) {
        if (checkRemovedDate()) {
          const roleJsonEdit: IAddToList = {
            listId: listId,
            entryNames: entryNames,
            reason: reason,
            enteredDate: apiDateFormat(enteredDate),
            validTillDate: apiDateFormat(validDate),
            removedDate: apiDateFormat(removedDate),
          };
          api
            .post({ url: apiConfig.addToListEntries, data: roleJsonEdit })
            .then((response) => {
              if (response) {
                setClearSelection(true);
                setShowPopover(false);
                setReason('');
                setEnteredDate(null);
                setRemovedDate(null);
                setvalidDate(null);
                if (selectedTab === 0) {
                  toast.success({
                    title: t('toast.portAdded', {
                      listName: listName,
                    }),
                  });
                } else if (selectedTab === 1) {
                  toast.success({
                    title: t('toast.terminalAdded', {
                      listName: listName,
                    }),
                  });
                } else if (selectedTab === 2) {
                  toast.success({
                    title: t('toast.berthAdded', {
                      listName: listName,
                    }),
                  });
                }
              }
            });
        } else {
          toast.error({
            title: 'Error',
            message: t('errors.removedDateCheck'),
          });
        }
      } else {
        toast.error({ title: 'Error', message: t('errors.validTillDate') });
      }
    }
  };

  return (
    <Popover
      id="rolesPopover"
      // target="#copyButton"
      target={target}
      position={position}
      width={300}
      closeOnOutsideClick={true}
      visible={showPopover}
      onHiding={() => setShowPopover(false)}
      wrapperAttr={popupAttributes}
      hideOnParentScroll={false}
    >
      <div className="m-l-grid-clone-popup-wrap">
        <div className="m-l-grid-clone-popup-header m-l-grid-copy--popup-header">
          <h2>{t('labels.addToList')}</h2>
          <div className="m-l-header-close__icon-block">
            <Button
              className="app-c-btn app-c-icon-only-btn"
              onClick={() => setShowPopover(false)}
              elementAttr={{ 'data-testid': 'close' }}
            >
              <icons.IconCross />
            </Button>
          </div>
        </div>
        <ScrollView>
          <div className="m-l-grid-clone-popup-body">
            <div className="m-c-form-group mb-btm-space">
              <label className="m-l-input-label">
                {t('labels.list')} <span className="m-l-input-required">*</span>{' '}
              </label>
              {/* <ValidationGroup ref={validationGroup}> */}
              <SelectBox
                searchEnabled={true}
                valueExpr="listId"
                displayExpr="listName"
                elementAttr={{ 'data-testid': 'company' }}
                dataSource={listDataSource}
                className="m-c-input-control m-c-select-box"
                id="companySelectBox"
                onValueChanged={onListChange}
                validationMessageMode="always"
              >
                <Validator ref={validationGroup}>
                  <RequiredRule message={t('errors.required')} />
                </Validator>
              </SelectBox>
              {/* </ValidationGroup> */}
            </div>
            <div className="m-c-form-group mb-btm-space">
              <label className="m-l-input-label">{t('labels.reason')}</label>
              <TextBox
                className="m-c-input-control"
                value={reason}
                onValueChanged={(e) => {
                  setReason(e.value);
                }}
              ></TextBox>
            </div>
            <div className="m-c-form-group mb-btm-space">
              <label className="m-l-input-label">
                {t('labels.enteredDate')}
              </label>
              <DateBox
                className="m-c-input-control m-c-date-picker"
                type="date"
                max={date}
                validationMessageMode="always"
                value={enteredDate}
                displayFormat={dateFormat}
                onValueChanged={(e) => {
                  setEnteredDate(e.value);
                }}
              ></DateBox>
            </div>
            <div className="m-c-form-group mb-btm-space">
              <label className="m-l-input-label">
                {t('labels.validTillDate')}
              </label>
              <DateBox
                className="m-c-input-control m-c-date-picker"
                type="date"
                validationMessageMode="always"
                value={validDate}
                displayFormat={dateFormat}
                onValueChanged={(e) => {
                  setvalidDate(e.value);
                }}
              ></DateBox>
            </div>
            <div className="m-c-form-group mb-btm-space">
              <label className="m-l-input-label">
                {t('labels.removedDate')}
              </label>
              <DateBox
                className="m-c-input-control m-c-date-picker"
                type="date"
                max={date}
                validationMessageMode="always"
                value={removedDate}
                displayFormat={dateFormat}
                onValueChanged={(e) => {
                  setRemovedDate(e.value);
                }}
              ></DateBox>
            </div>
          </div>
        </ScrollView>
        <div className="m-l-grid-clone-popup-footer">
          <Button
            className="app-c-btn app-c-btn--secondary"
            elementAttr={{ 'data-testid': 'copy' }}
            onClick={onAddNewList}
          >
            {t('labels.add')}
          </Button>
        </div>
      </div>
      {/* </ValidationGroup> */}
    </Popover>
  );
}
