import React, { useState, useEffect, useRef } from 'react';
import TextBox from 'devextreme-react/text-box';
import icons from '../common/icons/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import Validator, { StringLengthRule } from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import Tooltip from 'devextreme-react/tooltip';

export default function GlobalVessalSearch() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const textBoxRef: any = useRef();
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const q = urlParams.get('q');

  const searchSetData = (event: any) => {
    setSearchTerm(event.value);
  };

  useEffect(() => {
    if (q) {
      setSearchTerm(q);
    }
  }, [q]);

  const handleSearch = () => {
    if (searchTerm.length < 3) {
      return false;
    } else {
      const searchTermQry = searchTerm?.trim();
      if (searchTermQry) {
        navigate('/global-search/?q=' + searchTermQry);
      }
    }
  };

  const isValidationHidden =
    textBoxRef.current?.instance?._validationMessage?.NAME;

  return (
    <div className="m-l-header-search-area-block" data-testid="searchInput">
      <ValidationGroup>
        <TextBox
          id="searchbox"
          placeholder={t('placeholders.vesselName')}
          className="m-c-input-control"
          value={searchTerm ? searchTerm : ''}
          // valueChangeEvent="keyup"
          onValueChanged={(event) => searchSetData(event)}
          onEnterKey={handleSearch}
          ref={textBoxRef}
        >
          <Validator>
            <StringLengthRule
              min={3}
              ignoreEmptyValue={true}
              message={t('errors.searchWithLessThan2chara')}
            />
          </Validator>
        </TextBox>
      </ValidationGroup>
      <Tooltip
        target={'#searchbox'}
        showEvent="dxhoverstart"
        hideEvent="dxhoverend"
        position="right"
        contentRender={() => {
          return (
            <div className="m-l-tooltip-wrapper">{t('labels.searchInfo')}</div>
          );
        }}
      />
      <div
        className="m-c-search-icon"
        onClick={handleSearch}
        data-testid="vessal-search"
      >
        <icons.Search />
      </div>
    </div>
  );
}
