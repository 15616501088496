import RadioGroup from 'devextreme-react/radio-group';
import React, { useMemo, useState } from 'react';
import Grid from '../../common/grid/Grid';
import icons from '../../common/icons/icons';
import Modal from '../../common/modal/Modal';
import Button from 'devextreme-react/button';
import { IGridConfig } from '../../../types/grid';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../types/dialog';

export default function ManageFavorite(props: {
  favVisible: any;
  favoritesData: any;
  setFavVisible: any;
  sharedFavoritesData: any;
  setIsDuplicateFav: any;
  setShowSaveFav: any;
  setDuplicateFavData: any;
  deleteFavorite: any;
  setEditFav: any;
  showConfirmEdit: any;
  setShowConfirmEdit: any;
  editFavorite: any;
  makeFavDefault: any;
  setIsPrivate: any;
  setIsDefault: any;
}) {
  const {
    favVisible,
    favoritesData,
    setFavVisible,
    sharedFavoritesData,
    setIsDuplicateFav,
    setShowSaveFav,
    setDuplicateFavData,
    deleteFavorite,
    setEditFav,
    showConfirmEdit,
    setShowConfirmEdit,
    editFavorite,
    makeFavDefault,
    setIsPrivate,
    setIsDefault,
  } = props;
  const [isMyFav, setIsMyFav] = useState(true);
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showDefaultDelete, setShowDefaultDelete] = useState(false);
  const [showMakeDefault, setShowMakeDefault] = useState(false);
  const [showSharedDefaultDelete, setShowSharedDefaultDelete] = useState(false);

  const priorities = ['My Favorites', 'Shared Favorites'];
  const { t } = useTranslation();
  const [rowClickName, setRowClickName] = useState();
  const [rowClickIsDefault, setRowClickIsDefault] = useState();
  const [makeSharedDefault, setMakeSharedDefault] = useState(false);

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.duplicateFavorite', {
      name: rowClickName,
    }),
    handleSubmit: () => {
      setIsDuplicateFav(true);
      setShowSaveFav(true);
      setShowConfirmSave(false);
    },
    handleClose: () => {
      setIsDuplicateFav(false);
      setShowSaveFav(false);
      setShowConfirmSave(false);
    },
  };

  const confirmDeleteDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.deleteFavorite', {
      name: rowClickName,
    }),
    handleSubmit: () => {
      deleteFavorite();
      setShowConfirmDelete(false);
    },
    handleClose: () => {
      setShowConfirmDelete(false);
    },
  };

  const privateFilterData = [
    { value: true, display: 'Yes' },
    { value: false, display: 'No' },
  ];

  const confirmDefaultDeleteConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.deleteDefaultFavorite', {
      name: rowClickName,
    }),
    handleSubmit: () => {
      deleteFavorite();
      setShowDefaultDelete(false);
    },
    handleClose: () => {
      setShowDefaultDelete(false);
    },
  };

  const confirmSharedDefaultDelete: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.sharedDeleteDefaultFavorite'),
    handleSubmit: () => {
      deleteFavorite();
      setShowSharedDefaultDelete(false);
    },
    handleClose: () => {
      setShowSharedDefaultDelete(false);
    },
  };

  const confirmEditConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.editFavorite', {
      name: rowClickName,
    }),
    handleSubmit: () => {
      editFavorite();
      setShowConfirmEdit(false);
    },
    handleClose: () => {
      setShowConfirmEdit(false);
    },
  };

  const confirmMakeDefault: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.makeDefaultFav', {
      name: rowClickName,
    }),
    handleSubmit: () => {
      makeFavDefault();
      setShowMakeDefault(false);
    },
    handleClose: () => {
      setShowMakeDefault(false);
    },
  };

  const confirmMakeSharedDefault: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.makeSharedDefault'),
    handleSubmit: () => {
      makeFavDefault();
      setMakeSharedDefault(false);
    },
    handleClose: () => {
      setMakeSharedDefault(false);
    },
  };

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-modal__layout',
    };
  }, []);

  // const onRowClick = (e: any) => {
  //   setRowClickIsDefault(e.key.isDefault);
  //   setDuplicateFavData(e.key);
  //   setRowClickName(e.values[0]);
  // }

  const confirmDuplicate = (rowData: any) => {
    setDuplicateFavData(rowData);
    setRowClickName(rowData.favoriteName);
    setShowConfirmSave(true);
  };

  const editFav = (rowData: any) => {
    setDuplicateFavData(rowData);
    setRowClickName(rowData.favoriteName);
    setIsPrivate(rowData.isPrivate);
    setIsDefault(rowData.isDefault);
    setEditFav(true);
    setShowSaveFav(true);
  };

  const makeFavoriteDefault = (rowData: any) => {
    // rowData.isDefault = true;
    setDuplicateFavData(rowData);
    setRowClickName(rowData.favoriteName);
    setShowMakeDefault(true);
  };

  const makeSharedFavoriteDefault = (rowData: any) => {
    setDuplicateFavData(rowData);
    setRowClickName(rowData.favoriteName);
    setMakeSharedDefault(true);
  };

  const deleteFav = (rowData: any) => {
    setDuplicateFavData(rowData);
    setRowClickName(rowData.favoriteName);
    if (rowData.isDefault) {
      setShowDefaultDelete(true);
    } else if (rowData.isSharedDefault) {
      setShowSharedDefaultDelete(true);
    } else {
      setShowConfirmDelete(true);
    }
  };

  const modalConfig = {
    width: 1262,
    visible: favVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const myFavGridConfig: IGridConfig = {
    showHeader: false,
    defaultColumns: [
      {
        caption: 'Favorite Name',
        dataField: 'favoriteName',
        dataType: 'string',
        minWidth: 200,
      },
      {
        caption: 'Private',
        dataField: 'isPrivate',
        dataType: 'string',
        cellTemplate: 'actionTemplate1',
        minWidth: 200,
        lookup: {
          dataSource: privateFilterData,
          valueExpr: 'value',
          displayExpr: 'display',
        },
      },
      {
        caption: 'Action',
        dataField: 'Action',
        dataType: 'string',
        cellTemplate: 'statusTemplate',
        width: 200,
        allowFiltering: false,
        allowSorting: false,
      },
    ],
    dataSource: favoritesData,
    hideFilterRow: false,
    // rowClick: onRowClick,
  };

  const sharedFavGridConfig: IGridConfig = {
    showHeader: true,
    defaultColumns: [
      {
        caption: 'Favorite Name',
        dataField: 'favoriteName',
        dataType: 'string',
        minWidth: 200,
      },
      {
        caption: 'Author',
        dataField: 'createdUserName',
        dataType: 'string',
        minWidth: 200,
      },
      {
        caption: 'Action',
        dataField: 'Action',
        dataType: 'string',
        cellTemplate: 'statusTemplate',
        width: 200,
        allowFiltering: false,
        allowSorting: false,
      },
    ],
    dataSource: sharedFavoritesData,
    hideFilterRow: false,
    // rowClick: onRowClick,
  };

  const cellRender = (data: any) => {
    return <div>{data?.data?.isPrivate ? 'Yes' : 'No'}</div>;
  };

  const statusTemplate = (e: any) => {
    return (
      <React.Fragment>
        {isMyFav ? (
          <div className="app-c-grid-actions">
            <Button
              className="m-l-btn--icon-plain"
              onClick={() => {
                confirmDuplicate(e.key);
              }}
            >
              <icons.CopyIcon />
            </Button>
            <Button
              className="m-l-btn--icon-plain"
              onClick={() => {
                editFav(e.key);
              }}
            >
              <icons.IconEdit />
            </Button>
            <Button
              className="m-l-btn--icon-plain"
              onClick={() => {
                deleteFav(e.key);
              }}
            >
              <icons.Trash />
            </Button>
            {e.key.isDefault && (
              <Button className="m-l-btn--icon-plain">
                <icons.FilledStar />
              </Button>
            )}
            {!e.key.isDefault && (
              <Button
                className="m-l-btn--icon-plain"
                onClick={() => {
                  makeFavoriteDefault(e.key);
                }}
              >
                <icons.Star />
              </Button>
            )}
          </div>
        ) : (
          <div className="app-c-grid-actions">
            <Button
              className="m-l-btn--icon-plain"
              onClick={() => {
                confirmDuplicate(e.key);
              }}
            >
              <icons.CopyIcon />
            </Button>
            {e.key.isDefault && (
              <Button className="m-l-btn--icon-plain">
                <icons.FilledStar />
              </Button>
            )}
            {!e.key.isDefault && (
              <Button
                className="m-l-btn--icon-plain"
                onClick={() => {
                  makeSharedFavoriteDefault(e.key);
                }}
              >
                <icons.Star />
              </Button>
            )}
          </div>
        )}
      </React.Fragment>
    );
  };

  const statusTemplate2 = (e: any) => {
    return (
      <React.Fragment>
        {e.key.isPrivate ? <p>Yes</p> : <p>No</p>}
      </React.Fragment>
    );
  };

  const changeManageFav = (e: any) => {
    if (e.value === 'My Favorites') {
      setIsMyFav(true);
    } else {
      setIsMyFav(false);
    }
  };

  return (
    <React.Fragment>
      <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
        <div className="m-l-modal__header">
          <h2 className="modal-title">Manage Favorite</h2>
          <div className="m-l-modal-close-button-block">
            <Button
              className="app-c-btn app-c-icon-only-btn"
              onClick={() => setFavVisible(false)}
            >
              <icons.IconCross />
            </Button>
          </div>
        </div>
        <div className="m-l-modal__body">
          <div className="mb-btm-space m-c-form-group">
            <div className="m-c-radio-button">
              <RadioGroup
                items={priorities}
                defaultValue={priorities[0]}
                layout="horizontal"
                onValueChanged={changeManageFav}
              />
            </div>
          </div>
          {isMyFav && (
            <Grid
              gridConfig={myFavGridConfig}
              statusTemplate={statusTemplate}
              actionTemplate1={cellRender}
            />
          )}
          {!isMyFav && (
            <Grid
              gridConfig={sharedFavGridConfig}
              statusTemplate={statusTemplate}
            />
          )}
        </div>
      </Modal>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={showConfirmSave}
      />
      <ConfirmDialog
        dialogConfig={confirmDeleteDialogConfig}
        isOpen={showConfirmDelete}
      />
      <ConfirmDialog
        dialogConfig={confirmDefaultDeleteConfig}
        isOpen={showDefaultDelete}
      />
      <ConfirmDialog
        dialogConfig={confirmEditConfig}
        isOpen={showConfirmEdit}
      />
      <ConfirmDialog
        dialogConfig={confirmMakeDefault}
        isOpen={showMakeDefault}
      />
      <ConfirmDialog
        dialogConfig={confirmMakeSharedDefault}
        isOpen={makeSharedDefault}
      />
      <ConfirmDialog
        dialogConfig={confirmSharedDefaultDelete}
        isOpen={showSharedDefaultDelete}
      />
    </React.Fragment>
  );
}
