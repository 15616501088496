import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDimensionsUnit } from '../../../../../hooks/useDimensionsUnit';

import FormBuilder from '../../../../common/form-builder/FormBuilder';

export default function VesselFormDimensions(props: { yesNo: any }) {
  const { yesNo } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { dimensionUnitText } = useDimensionsUnit();

  const yesOrNoDataSource = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ];

  const dimensionsFormConfig = [
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'lengthoverall',
      label: t('labels.lengthOverall') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
      //   isRequired: true,
      //   rules: [
      //     {
      //       type: 'required',
      //       message: t('errors.required'),
      //     },
      //   ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'lengthreg',
      label: t('labels.lengthReg') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'lengthbp',
      label: t('labels.lengthBp') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'breadthregistered',
      label: t('labels.breadthRegistered') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'breadthextreme',
      label: t('labels.breadthExtreme') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'breadthmoulded',
      label: t('labels.breadthMoulded') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'depth',
      label: t('labels.depth') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'draught',
      label: t('labels.draught') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'ktminnormalballast',
      label: t('labels.ktmInNormalBallast') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'ktminsummerdraft',
      label: t('labels.ktmInSummerDraft') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'manifoldheightinnormalballast',
      label: t('labels.manifoldHeightInNormalBallast') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'manifoldheightinsummerdraft',
      label: t('labels.manifoldHeightInSummerDraft') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'displacement',
      label: t('labels.displacement'),
      control: control,
      fieldType: 'number',
      // allowDecimal:true
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
      fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
      name: 'bulbousbow',
      label: t('labels.bulbousBow'),
      control: control,
      fieldType: 'radio',
      items: yesOrNoDataSource,
      layout: 'horizontal',
      displayExpr: 'name',
      valueExpr: 'value',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'tcm',
      label: t('labels.tcm'),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'pblinnormalballast',
      label: t('labels.pblInNormalBallast') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'pblinsummerdraft',
      label: t('labels.pblInSummerDwt') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'scm',
      label: t('labels.scm') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'bcm',
      label: t('labels.bcm') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'freeboardsummer',
      label: t('labels.freeboardSummer') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'freeboardwinter',
      label: t('labels.freeboardWinter') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'freeboardtropical',
      label: t('labels.freeboardTropical') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'freeboardBallast',
      label: t('labels.freeboardBallast') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'hosederrick',
      label: t('labels.hoseDerrick'),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'pblforwardArrival',
      label: t('labels.pblForwardArrival') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'pblarrival',
      label: t('labels.pblArrival') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'distancefrommanifold',
      label: t('labels.distanceFromManifold') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'heighOfmanifold',
      label: t('labels.heighOfManifold') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'manifoldspacing',
      label: t('labels.manifoldSpacing') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'vapourrecoverysystem',
      label: t('labels.vapourRecoverySystem'),
      control: control,
      fieldType: 'select',
      dataSource: yesNo,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'manifoldsize',
      label: t('labels.manifoldSize') + dimensionUnitText(),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'connectortype',
      label: t('labels.connectorType'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'loadingrate',
      label: t('labels.loadingRate'),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,13})([.][0-9]{0,3})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={dimensionsFormConfig} />
      </div>
    </>
  );
}
