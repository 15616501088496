import React, { useState } from 'react';
import { Drawer } from 'devextreme-react/drawer';
import SidebarMenuList from '../../components/common/sidebar/SidebarMenuItems';
import Header from '../../components/common/header/Header';
import { Outlet } from 'react-router-dom';
import { MasterDataProvider } from '../../contexts/MasterDataContext';

export default function Layout() {
  const [isParentData, setIsParentData] = useState(false);

  return (
    <div>
      <MasterDataProvider>
        <Drawer
          position={'before'}
          opened={true}
          component={SidebarMenuList}
          className={
            isParentData ? 'm-l-show-sidebar-in-sm' : 'm-l-hide-sidebar-in-sm'
          }
        >
          <Header sendToParent={setIsParentData} />
          <div id="view">
            <div className="m-l-container">
              <div className="main-container">
                <Outlet />
              </div>
            </div>
          </div>
        </Drawer>
      </MasterDataProvider>
    </div>
  );
}
