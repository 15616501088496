import React from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormBuilder from '../../common/form-builder/FormBuilder';
import { IFormConfig } from '../../../types/form-builder';

export default function SearchFields(props: { countriesList?: any }) {
  const { countriesList } = props;

  const { t } = useTranslation();

  const { control } = useFormContext();

  const searchFormConfig: IFormConfig[] = [
    {
      name: 'countryCode',
      label: t('labels.country'),
      control: control,
      fieldType: 'select',
      dataSource: countriesList,
      displayExpr: 'countryName',
      valueExpr: 'countryCode',
      class: 'col-sm-6 m-l-custom-sixteen-space-col',
    },
    {
      name: 'port',
      label: t('labels.port'),
      control: control,
      fieldType: 'text',
      class: 'col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
    },
    {
      name: 'terminal',
      label: t('labels.terminal'),
      control: control,
      fieldType: 'text',
      class:
        'col-md-4 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
    },
    {
      name: 'berth',
      label: t('labels.berth'),
      control: control,
      fieldType: 'text',
      showClearButton: true,
      class:
        'col-md-4 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
    },
  ];
  return (
    <div className="m-l-form-panel-block">
      <div className="m-l-form-panel-body-block">
        <div className="m-l-form-panel-body-block m-l-rl-pnl-body-block">
          <div className="col-lg-12 m-l-search__collapse">
            <div className="row">
              <FormBuilder formConfig={searchFormConfig} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
