import Button from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  IAddIncidentObject,
  IIncidentForm,
} from '../../../../../../types/incidents';
import { IModalConfig } from '../../../../../../types/modal';
import icons from '../../../../../common/icons/icons';
import Modal from '../../../../../common/modal/Modal';
import IncidentForm from './incident-form/IncidentForm';
import { MarsApiService as api } from '../../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../../api/mars-api-config';
import toast from '../../../../../../utils/toast';
import { IOptionvalue } from '../../../../../../types/master-data';
import ConfirmDialog from '../../../../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../../../../types/dialog';
import { IncidentStatus } from '../../../../../../enums/status-enum';
import { Restricted } from '../../../../../../contexts/PermissionContext';
import { useDateFormat } from '../../../../../../hooks/useDateFormat';
import { useLoaderContext } from '../../../../../../contexts/LoaderContext';
import { isTabDirtyContext } from '../../../../../../pages/vessel-info/VesselInformation';
import { getCompanyType } from '../../../../../../utils/jwt-decode';
import FileUploaderModal from '../../../../../common/file-uploader-modal/FileUploaderModal';
import FileSaver from 'file-saver';
import Tooltip from 'devextreme-react/tooltip';

export default function EditIncident(props: {
  isVisible: any;
  setIsVisible: any;
  eventId: any;
  imoNumber: any;
  getIncidentsList: any;
  isMarsUser: any;
  companyId: any;
  getRuleList?: () => Promise<void>;
}) {
  const {
    isVisible,
    setIsVisible,
    eventId,
    imoNumber,
    getIncidentsList,
    isMarsUser,
    companyId,
    getRuleList,
  } = props;
  const { setIsLoading } = useLoaderContext();
  const { apiDateFormat } = useDateFormat();
  const { t } = useTranslation();
  const [confirmBackDialog, setConfirmBackDialog] = useState(false);
  const [confirmSaveDialog, setConfirmSaveDialog] = useState(false);
  const [source, setSource] = useState();
  const [isClosedMars, setIsClosedMars] = useState();
  const [incidentDetails, setIncidentDetails] = useState<IIncidentForm>();
  const [disableEdit, setDisableEdit] = useState(false);
  const [confirmAccept, setConfirmAccept] = useState(false);
  const [confirmIgnore, setConfirmIgnore] = useState(false);

  //dropdown values///////////////////////////////////////////////////////////////////////
  const [eventType, setEventType] = useState<IOptionvalue[]>([]);
  const [significance, setSignificance] = useState<IOptionvalue[]>([]);
  const [eventTypeDetails, setEventTypeDetails] = useState<IOptionvalue[]>([]);
  const [loadStatus, setLoadStatus] = useState<IOptionvalue[]>([]);
  const [injuryType, setInjuryType] = useState<IOptionvalue[]>([]);
  const [rootCause, setRootCause] = useState<IOptionvalue[]>([]);
  const [incidentActions, setIncidentActions] = useState<IOptionvalue[]>([]);
  const [casualityZone, setCasualityZone] = useState<IOptionvalue[]>([]);
  const [status, setStatus] = useState<IOptionvalue[]>([]);
  const [statusOptionVal, setStatusOptionVal] = useState<IOptionvalue[]>([]);
  const [stateOfIncidentVal, setStateOfIncidentVal] = useState<IOptionvalue[]>(
    []
  );
  const setTabDirtyFlag = useContext(isTabDirtyContext);
  const companyType = getCompanyType();
  const [isVisibleAttach, setIsVisibleAttach] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any>();
  const [attachmentsAdded, setAttachmentsAdded] = useState<any>([]);
  const [isAttachmentDirty, setIsAttachmentDirty] = useState(false);
  const [showAttachment, setShowAttachment] = useState(false);

  useEffect(() => {
    if (incidentDetails?.attachments) {
      setAttachmentsAdded(incidentDetails?.attachments);
    }
  }, [incidentDetails]);

  const getUploadedFiles = (data: any) => {
    setIsAttachmentDirty(true);
    if (uploadedFiles) {
      setUploadedFiles([...uploadedFiles, ...data]);
    } else {
      setUploadedFiles(data);
    }
  };

  const getEventTypes = async () => {
    await api
      .get({
        url: apiConfig.eventTypeCodes,
      })
      .then((res: IOptionvalue[]) => {
        setEventType(res);
      });
  };

  const getStatus = async () => {
    await api
      .get({
        url: apiConfig.incidentStatus,
      })
      .then((res: IOptionvalue[]) => {
        setStatusOptionVal(res);
        if (isMarsUser) {
          setStatus(res);
        } else {
          const data = res.filter(
            (item: any) => item.optionValueCode != IncidentStatus.WrongEntry
          );
          setStatus(data);
        }
      });
  };

  const getSignificance = async () => {
    await api
      .get({
        url: apiConfig.significanceCodes,
      })
      .then((res: IOptionvalue[]) => {
        setSignificance(res);
      });
  };

  const getLoadStatus = async () => {
    await api
      .get({
        url: apiConfig.loadStatusCodes,
      })
      .then((res: IOptionvalue[]) => {
        setLoadStatus(res);
      });
  };

  const getEventTypeDetails = async () => {
    await api
      .get({
        url: apiConfig.eventTypeDetailCodes,
      })
      .then((res: IOptionvalue[]) => {
        setEventTypeDetails(res);
      });
  };
  const getInjuryType = async () => {
    await api
      .get({
        url: apiConfig.injuryTypeCodes,
      })
      .then((res: IOptionvalue[]) => {
        setInjuryType(res);
      });
  };

  const getRootCause = async () => {
    await api
      .get({
        url: apiConfig.rootCauseCodes,
      })
      .then((res: IOptionvalue[]) => {
        setRootCause(res);
      });
  };

  const getIncidentActions = async () => {
    await api
      .get({
        url: apiConfig.incidentActionsCodes,
      })
      .then((res: IOptionvalue[]) => {
        setIncidentActions(res);
      });
  };

  const getCasualityZone = async () => {
    await api
      .get({
        url: apiConfig.casualityZoneCodes,
      })
      .then((res: IOptionvalue[]) => {
        setCasualityZone(res);
      });
  };

  const getStateOfIncident = async () => {
    await api
      .get({
        url: apiConfig.stateOfIncident,
      })
      .then((res: IOptionvalue[]) => {
        setStateOfIncidentVal(res);
      });
  };

  useEffect(() => {
    getEventTypes();
    getEventTypeDetails();
    getSignificance();
    getLoadStatus();
    getInjuryType();
    getRootCause();
    getIncidentActions();
    getCasualityZone();
    getStatus();
    getStateOfIncident();
  }, []);

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-add-new-incident-modal',
    };
  }, []);

  const modalConfig: IModalConfig = {
    width: 1262,
    height: 650,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const defaultincidentFormValues: IIncidentForm = {
    incidentDate: null,
    placeOfIncident: '',
    locationName: '',
    environment: '',
    marsdenGrid: '',
    casualityZone: '',
    eventType: '',
    eventTypeDetail: '',
    significance: '',
    loadStatus: '',
    totalLoss: null,
    pollution: null,
    fire: null,
    significantDamage: null,
    additionalEvent: '',
    injury: null,
    injuryType: '',
    headline: '',
    description: '',
    rootCause: '',
    incidentAction: '',
    comments: '',
    status: IncidentStatus.Open,
    source: '',
    isClosedByMars: '',
  };

  const incidentGeneralForm = useForm({
    defaultValues: defaultincidentFormValues,
  });
  const { getValues } = incidentGeneralForm;
  let incidentFormValue: IIncidentForm = getValues();

  const handleSaveClick = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      onSaveIncident();
    }
  };

  const incidentDateValidation = () => {
    const dateValidation = incidentFormValue.incidentDate
      ? new Date(incidentFormValue.incidentDate) <= new Date()
      : true;
    return dateValidation;
  };

  const onSaveIncident = () => {
    incidentFormValue = incidentGeneralForm.getValues();
    if (incidentDateValidation()) {
      setConfirmSaveDialog(true);
    } else {
      toast.error({
        title: 'Error',
        message: t('errors.incidentDateValidation'),
      });
    }
  };

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: async () => {
      setConfirmSaveDialog(false);
      if (eventId) {
        saveEdittedIncidents();
      } else {
        saveNewIncidents();
      }
    },
    handleClose: () => {
      setConfirmSaveDialog(false);
    },
  };

  const getIncidentsObject = async () => {
    const data = new FormData();
    if (!eventId) {
      data.append('imoNumber', eventId ? null : imoNumber);
    }
    data.append(
      'incidentDate',
      incidentFormValue.incidentDate
        ? JSON.parse(
            JSON.stringify(apiDateFormat(incidentFormValue.incidentDate))
          )
        : ''
    );
    data.append(
      'placeOfIncident',
      incidentFormValue.placeOfIncident ? incidentFormValue.placeOfIncident : ''
    );
    data.append(
      'locationName',
      incidentFormValue.locationName ? incidentFormValue.locationName : ''
    );
    data.append(
      'environment',
      incidentFormValue.environment ? incidentFormValue.environment : ''
    );
    data.append(
      'marsdenGrid',
      incidentFormValue.marsdenGrid ? incidentFormValue.marsdenGrid : ''
    );
    data.append(
      'casualityZoneCode',
      incidentFormValue.casualityZone ? incidentFormValue.casualityZone : ''
    );
    data.append(
      'EventTypeCode',
      incidentFormValue.eventType ? incidentFormValue.eventType : ''
    );
    data.append(
      'eventTypeDetailCode',
      incidentFormValue.eventTypeDetail ? incidentFormValue.eventTypeDetail : ''
    );
    data.append(
      'significanceCode',
      incidentFormValue.significance ? incidentFormValue.significance : ''
    );
    data.append(
      'loadStatusCode',
      incidentFormValue.loadStatus ? incidentFormValue.loadStatus : ''
    );
    data.append(
      'totalLoss',
      incidentFormValue.totalLoss ? incidentFormValue.totalLoss : ''
    );
    data.append(
      'pollution',
      incidentFormValue.pollution ? incidentFormValue.pollution : ''
    );
    data.append('fire', incidentFormValue.fire ? incidentFormValue.fire : '');
    data.append(
      'significantDamage',
      incidentFormValue.significantDamage
        ? incidentFormValue.significantDamage
        : ''
    );
    data.append(
      'additionalEventCode',
      incidentFormValue.additionalEvent ? incidentFormValue.additionalEvent : ''
    );
    data.append(
      'injury',
      incidentFormValue.injury ? incidentFormValue.injury : ''
    );
    data.append(
      'injuryTypeCode',
      incidentFormValue.injuryType ? incidentFormValue.injuryType : ''
    );
    data.append(
      'headline',
      incidentFormValue.headline ? incidentFormValue.headline : ''
    );
    data.append(
      'description',
      incidentFormValue.description ? incidentFormValue.description : ''
    );
    data.append(
      'rootCauseCode',
      incidentFormValue.rootCause ? incidentFormValue.rootCause : ''
    );
    data.append(
      'incidentActionsCode',
      incidentFormValue.incidentAction ? incidentFormValue.incidentAction : ''
    );
    data.append(
      'comments',
      incidentFormValue.comments ? incidentFormValue.comments : ''
    );
    data.append(
      'status',
      incidentFormValue.status ? incidentFormValue.status : ''
    );
    for (const item in uploadedFiles) {
      data.append('AttachedFiles', uploadedFiles[item]);
    }
    return data;
  };

  const saveNewIncidents = async () => {
    const data = await getIncidentsObject();
    await api
      .post(
        {
          url: apiConfig.addIncidents,
          data: data,
        },
        setIsLoading
      )
      .then(() => {
        setIsVisible(false);
        toast.custom({
          title: t('toast.savedSuccessfully'),
          message: t('toast.incidentAddedSuccessfully'),
        });
        if (setTabDirtyFlag.setState) {
          setTabDirtyFlag.setState(
            setTabDirtyFlag.state == 0
              ? setTabDirtyFlag.state
              : setTabDirtyFlag.state - 1
          );
        }
        getIncidentsList();
        if (getRuleList) getRuleList();
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          toast.error({
            title: 'Error',
            message: t('errors.incidentAlreadyExists'),
          });
        }
      });
  };

  const saveEdittedIncidents = async () => {
    const isEdited = IsAcceptedIncidentEdited(dirtyFields);
    const data = await getIncidentsObject();
    data.append('eventId', eventId ? eventId : null);

    attachmentsAdded?.forEach((element: any) => {
      if (element?.attachmentId) {
        data.append('AttachmentIds', element?.attachmentId);
      }
    });

    await api
      .put(
        {
          url: apiConfig.addIncidents,
          data: data,
          params: { isEdited: isEdited },
        },
        setIsLoading
      )
      .then(() => {
        setIsVisible(false);
        if (setTabDirtyFlag.setState) {
          setTabDirtyFlag.setState(
            setTabDirtyFlag.state == 0
              ? setTabDirtyFlag.state
              : setTabDirtyFlag.state - 1
          );
        }
        toast.custom({
          title: t('toast.savedSuccessfully'),
          message: t('toast.incidentUpdated'),
        });
        getIncidentsList();
        if (getRuleList) getRuleList();
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          toast.error({
            title: 'Error',
            message: t('errors.incidentAlreadyExists'),
          });
        }
      });
  };

  //on click back button
  const { isDirty } = incidentGeneralForm.formState;
  const { dirtyFields } = incidentGeneralForm.formState;

  const IsAcceptedIncidentEdited = (dirtyFields: any) => {
    const dirtyFieldsArray = Object.keys(dirtyFields);
    if (dirtyFieldsArray.length > 0) {
      if (dirtyFieldsArray.length > 2) {
        return true;
      } else if (
        dirtyFieldsArray.length == 2 &&
        dirtyFieldsArray.includes('comments') &&
        dirtyFieldsArray.includes('status')
      ) {
        return false;
      } else if (
        (dirtyFieldsArray.length < 2 &&
          dirtyFieldsArray.includes('comments')) ||
        dirtyFieldsArray.includes('status')
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const handleBackClick = () => {
    isDirty || isAttachmentDirty
      ? setConfirmBackDialog(true)
      : setIsVisible(false);
  };

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBackEditIncident',
    content: t('toast.confirmBackEditList'),
    handleSubmit: () => {
      setConfirmBackDialog(false);
      setIsAttachmentDirty(false);
      setIsVisible(false);
      if (setTabDirtyFlag.setState) {
        setTabDirtyFlag.setState(
          setTabDirtyFlag.state == 0
            ? setTabDirtyFlag.state
            : setTabDirtyFlag.state - 1
        );
      }
    },
    handleClose: () => {
      setConfirmBackDialog(false);
    },
  };

  // reset form values for edit
  const getIncidentById = async () => {
    await api.get({ url: apiConfig.incidentById + eventId }).then((res) => {
      setIncidentDetails(res);
    });
  };

  useEffect(() => {
    if (eventId) {
      getIncidentById();
    }
  }, [eventId]);

  useEffect(() => {
    incidentGeneralForm.reset();
    if (incidentDetails) {
      setSource(incidentDetails.source);
      setIsClosedMars(incidentDetails.isClosedByMars);
      incidentGeneralForm.reset(
        {
          incidentDate: incidentDetails.incidentDate,
          placeOfIncident: incidentDetails.placeOfIncident,
          environment: incidentDetails.environment,
          marsdenGrid: incidentDetails.marsdenGrid,
          casualityZone: incidentDetails.casualityZoneCode,
          locationName: incidentDetails.locationName,
          eventType: incidentDetails.eventTypeCode,
          loadStatus: incidentDetails.loadStatusCode,
          eventTypeDetail: incidentDetails.eventTypeDetailCode,
          significance: incidentDetails.significanceCode,
          totalLoss: incidentDetails.totalLoss,
          pollution: incidentDetails.pollution,
          fire: incidentDetails.fire,
          significantDamage: incidentDetails.significantDamage,
          additionalEvent: incidentDetails.additionalEventCode,
          injury: incidentDetails.injury,
          injuryType: incidentDetails.injuryTypeCode,
          source: incidentDetails.source,
          headline: incidentDetails.headline,
          description: incidentDetails.description,
          comments: incidentDetails.comments,
          rootCause: incidentDetails.rootCauseCode,
          incidentAction: incidentDetails.incidentActionsCode,
          status: incidentDetails.status,
          isClosedByMars: incidentDetails.isClosedByMars,
        },
        { keepDirty: true }
      );
    }
  }, [incidentDetails]);

  const isEditable = () => {
    if (incidentDetails) {
      if (isMarsUser) {
        if (incidentDetails.isMarsOrExternal) {
          setDisableEdit(false);
        } else {
          setDisableEdit(true);
        }
      } else {
        if (incidentDetails.companyId == companyId || isMarsUser) {
          setDisableEdit(false);
        } else {
          setDisableEdit(true);
        }
      }
    }
  };

  useEffect(() => {
    isEditable();
    showAttachmentIcon();
  }, [incidentDetails]);

  const confirmAcceptDialogConfig: IDialogConfig = {
    id: 'confirmAcceptIncident',
    content: t('labels.acceptIncident'),
    handleSubmit: () => {
      acceptIncident(eventId);
      setConfirmAccept(false);
    },
    handleClose: () => {
      setConfirmAccept(false);
    },
  };

  const acceptIncident = async (id: any) => {
    setConfirmAccept(false);
    setIsVisible(false);
    await api
      .put(
        { url: apiConfig.incidentById + id, params: { IsIgnored: false } },
        setIsLoading
      )
      .then(() => {
        toast.custom({
          title: t('toast.incidentAcceptSuccess'),
        });
        getIncidentById();
        getIncidentsList();
        if (getRuleList) {
          getRuleList();
        }
      });
  };

  const confirmIgnoreDialogConfig: IDialogConfig = {
    id: 'confirmIgnoreIncident',
    content: t('labels.ignoreIncident'),
    handleSubmit: () => {
      ignoreIncident(eventId);
      setConfirmIgnore(false);
    },
    handleClose: () => {
      setConfirmIgnore(false);
    },
  };

  const ignoreIncident = async (id: any) => {
    setConfirmIgnore(false);
    setIsVisible(false);
    await api
      .put(
        { url: apiConfig.incidentById + id, params: { IsIgnored: true } },
        setIsLoading
      )
      .then(() => {
        toast.custom({
          title: t('toast.incidentIgnoreSuccess'),
        });
        getIncidentById();
        getIncidentsList();
        if (getRuleList) {
          getRuleList();
        }
      });
  };

  useEffect(() => {
    if (isDirty) {
      if (setTabDirtyFlag.setState)
        setTabDirtyFlag.setState(setTabDirtyFlag.state + 1);
    }
  }, [isDirty]);

  const handleCancelClick = () => {
    setIsVisibleAttach(false);
  };

  const onAddClick = () => {
    setIsVisibleAttach(true);
  };

  const downloadAttachment = (file: any) => {
    api
      .get({
        url: apiConfig.deleteEditAttachment + file.attachmentId,
        responseType: 'blob',
      })
      .then((res) => {
        FileSaver.saveAs(new Blob([res]), file.fileName);
      });
  };

  const removeFile = (index: number) => {
    const file = uploadedFiles?.slice();
    file.splice(index, 1);
    setUploadedFiles(file);
    if (uploadedFiles.length === 1) {
      setIsAttachmentDirty(false);
    }
  };

  const removeFileAdded = (index: number, data: any) => {
    const file = attachmentsAdded?.slice();
    file?.splice(index, 1);
    setAttachmentsAdded(file);
    setIsAttachmentDirty(true);
  };

  const renderContent = (data: any) => {
    return <div className="m-l-sidebar-tooltip">{data}</div>;
  };

  const showAttachmentIcon = () => {
    if (incidentDetails) {
      if (companyType === 'R') {
        setShowAttachment(false);
      } else if (isMarsUser) {
        if (incidentDetails.isMarsOrExternal) {
          setShowAttachment(true);
        } else {
          setShowAttachment(false);
        }
      } else {
        if (companyType === 'H' && incidentDetails.isMarsOrExternal) {
          setShowAttachment(true);
        } else if (incidentDetails.companyId == companyId) {
          setShowAttachment(true);
        } else {
          setShowAttachment(false);
        }
      }
    }
  };

  return (
    <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
      <div className="m-l-modal__header">
        <h2 className="modal-title">
          {eventId ? t('headers.editIncident') : t('headers.addNewIncident')}
        </h2>
        <div className="m-l-modal-close-button-block">
          <Button
            className="app-c-btn app-c-icon-only-btn"
            onClick={handleBackClick}
          >
            <icons.IconCross />
          </Button>
        </div>
      </div>
      <div className="m-l-modal__body">
        <ScrollView width="100%" height="100%">
          <div className="m-l-adnw-icdnt-form-panel">
            <FormProvider {...incidentGeneralForm}>
              <IncidentForm
                eventType={eventType}
                significance={significance}
                eventTypeDetails={eventTypeDetails}
                loadStatus={loadStatus}
                injuryType={injuryType}
                rootCause={rootCause}
                incidentActions={incidentActions}
                casualityZone={casualityZone}
                status={status}
                statusOptionVal={statusOptionVal}
                eventId={eventId}
                source={source}
                isMarsUser={isMarsUser}
                isClosedMars={isClosedMars}
                stateOfIncident={incidentDetails?.stateOfIncident}
                stateOfIncidentOptVal={stateOfIncidentVal}
                disableEdit={disableEdit}
                isMarsOrExternal={incidentDetails?.isMarsOrExternal}
                marsStatus={incidentDetails?.marsStatus}
              />
            </FormProvider>
          </div>
        </ScrollView>
      </div>
      <div className="m-l-modal__footer">
        <div className="m-l-footer-left-block">
          {/* <div className="m-l-upld-fl-wrapper m-l-upld__birth-fit"> */}
          {/* <div className="m-l-atch-icon-holder"> */}
          <Restricted permission={'Ship.AddIncidentAttachment'}>
            {!eventId ? (
              <a onClick={onAddClick} className="m-l-email-atch-icon-hldr">
                <icons.Attach />
              </a>
            ) : incidentDetails && showAttachment ? (
              <a onClick={onAddClick} className="m-l-email-atch-icon-hldr">
                <icons.Attach />
              </a>
            ) : null}
          </Restricted>
          <div className="m-l-files-attached-list">
            {attachmentsAdded &&
              attachmentsAdded?.map((file: any, index: number) => {
                return (
                  file?.fileName && (
                    <Restricted permission={'Ship.ViewIncidentAttachment'}>
                      <div className="m-l-files-attached-block" key={index}>
                        <div
                          className="m-l-files-attached-list m-l-files-download"
                          key={index}
                          onClick={() => {
                            downloadAttachment(file);
                          }}
                        >
                          <div
                            className="m-l-files-attached-list"
                            id={'name' + index}
                          >
                            <span>{file.fileName}</span>
                          </div>
                        </div>
                        <Restricted
                          permission={'Ship.DeleteIncidentAttachment'}
                        >
                          <div
                            className="m-l-files-close-icon"
                            onClick={() => removeFileAdded(index, file)}
                          >
                            <icons.Close />
                          </div>
                        </Restricted>
                      </div>
                      <Tooltip
                        target={'#name' + index}
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        position="top"
                        contentRender={() => renderContent(file.fileName)}
                      />
                    </Restricted>
                  )
                );
              })}
            {uploadedFiles &&
              uploadedFiles?.map((file: any, index: number) => {
                return (
                  file?.name && (
                    <div className="m-l-files-attached-block" key={index}>
                      <div
                        className="m-l-files-attached-list m-l-files-download"
                        key={index}
                      >
                        <div
                          className="m-l-files-attached-list"
                          id={'uploadName' + index}
                        >
                          <span>{file.name}</span>
                        </div>
                      </div>
                      <div
                        className="m-l-files-close-icon"
                        onClick={() => removeFile(index)}
                      >
                        <icons.Close />
                      </div>
                      <Tooltip
                        target={'#uploadName' + index}
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        position="top"
                        contentRender={() => renderContent(file.name)}
                      />
                    </div>
                  )
                );
              })}
          </div>
          {/* </div> */}
          {/* </div> */}
          {isVisibleAttach && (
            <FileUploaderModal
              isVisible={isVisibleAttach}
              handleCancelClick={handleCancelClick}
              setAttachmentsAdded={setAttachmentsAdded}
              attachmentsAdded={attachmentsAdded}
              getUploadedFiles={getUploadedFiles}
            />
          )}
        </div>
        {/* <div className="m-l-group-btn-wrap"> */}
        <Restricted permission={'Ship.AcceptIncident'}>
          {eventId &&
            isMarsUser &&
            !incidentDetails?.stateOfIncident &&
            !incidentDetails?.isMarsOrExternal && (
              <>
                <Restricted permission="Ship.AcceptIncident">
                  <Button
                    className="app-c-btn app-c-btn--secondary min-btn-width"
                    onClick={() => {
                      setConfirmAccept(true);
                    }}
                  >
                    {t('buttons.accept')}
                  </Button>
                </Restricted>
                <Restricted permission="Ship.IgnoreIncident">
                  <Button
                    className="app-c-btn app-c-btn--secondary min-btn-width"
                    onClick={() => {
                      setConfirmIgnore(true);
                    }}
                  >
                    {t('buttons.ignore')}
                  </Button>
                </Restricted>
              </>
            )}
        </Restricted>
        <Button
          className="app-c-btn app-c-btn--secondary min-btn-width"
          onClick={handleBackClick}
        >
          {t('buttons.cancel')}
        </Button>
        <Restricted
          permission={eventId ? 'Ship.EditIncident' : 'Ship.AddIncident'}
        >
          {!(
            incidentDetails &&
            isMarsUser &&
            !incidentDetails?.isMarsOrExternal
          ) &&
            !(companyType === 'R') && (
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                onClick={handleSaveClick}
              >
                {t('buttons.save')}
              </Button>
            )}
        </Restricted>
        {/* </div> */}
      </div>
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={confirmBackDialog}
      />
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={confirmSaveDialog}
      />
      {confirmAccept && (
        <ConfirmDialog
          dialogConfig={confirmAcceptDialogConfig}
          isOpen={confirmAccept}
        />
      )}
      {confirmIgnore && (
        <ConfirmDialog
          dialogConfig={confirmIgnoreDialogConfig}
          isOpen={confirmIgnore}
        />
      )}
    </Modal>
  );
}
