import React, { useEffect } from 'react';
import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeAllCacheData } from '../services/master-data-service';
import { AuthContextType } from '../types/login';
import {
  getLoginBUName,
  getRememberMe,
  getToken,
  getUserName,
} from '../utils/jwt-decode';

const AuthContext = createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentlyLoggedInBu, setCurrentlyLoggedInBu] = useState<any>([]);
  const [usersName, setUsersName] = useState<any>([]);
  const [rememberMe, setRememberMe] = useState<any>('false');

  const navigate = useNavigate();

  const login = (data: any) => {
    setUser(data.userId);
    setAuthToken(data.token);
    setIsLoggedIn(!!getToken());
    setCurrentlyLoggedInBu(getLoginBUName());
    setUsersName(getUserName());
    setRememberMe(getRememberMe());
  };

  const logout = () => {
    setUser(null);
    setAuthToken(null);
    setIsLoggedIn(!!getToken());
    setCurrentlyLoggedInBu(null);
    setUsersName(null);
  };

  const switchBu = (data: any) => {
    const storage: Storage =
      getRememberMe() === 'true' ? localStorage : sessionStorage;
    storage.setItem('loggedInBuName', data.buName);
    setCurrentlyLoggedInBu(data.buName);
  };

  useEffect(() => {
    setIsLoggedIn(!!getToken());
    setCurrentlyLoggedInBu(getLoginBUName);
    setUsersName(getUserName());
    window.addEventListener('storage', (event) => {
      const token = sessionStorage.getItem('token');
      if (event.key === 'REQUESTING_SHARED_CREDENTIALS' && token) {
        localStorage.setItem('DATA_SHARING', JSON.stringify(sessionStorage));
        localStorage.removeItem('DATA_SHARING');
      }
      if (event.key === 'DATA_SHARING' && !token) {
        if (event.newValue) {
          const data = JSON.parse(event.newValue);
          Object.keys(data).map((key) => {
            sessionStorage.setItem(key, data[key]);
          });
          login(data);
        }
      }
      if (event.key === 'CREDENTIALS_FLUSH' && token) {
        removeAllCacheData();
        sessionStorage.clear();
        localStorage.clear();
        logout();
        navigate('/');
      }
    });
    window.addEventListener('logOut', () => {
      removeAllCacheData();
      sessionStorage.clear();
      localStorage.clear();
      logout();
      navigate('/');
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        authToken,
        login,
        logout,
        switchBu,
        isLoggedIn,
        currentlyLoggedInBu,
        usersName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
