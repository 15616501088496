import avatar from './avatar.png';
import home from './home.svg';
import dashboard from './dashboard.svg';
import group from './group.svg';
import settings from './settings.svg';
import successIcon from './successIcon.svg';
import vesselImage from './vesselImage.png';
import flag from './flag.png';
import loginBg from './loginBg.png';
import grayMARSLogo from './grayMARSLogo.svg';
import profilepic from './profilepic.png';
import vet from './vetsGraph.png';
import vessel from './vesselsGraph.png';
import dlr from './dlr.svg';
import VesselIcon from './VesselIcon.svg';
import toastWarnning from './toastWarnning.svg';
import toastError from './toastError.svg';
import avatarlg from './avatar-lg.png';
import avatarsm from './avatar-sm.png';
import successIconlg from './Tick-mark.svg';
import avatardrpdwn from './avatar-drpdwn.svg';
import openedeye from './eye-opened.svg';
import closedeye from './eye-closed.svg';
import vetRequestIcon from './VetRequest.svg';
import EmailIcon from './Email.svg';
import GraphIcon from './Graph.svg';
import ReportsIcon from './Reports.svg';
import doc from './doc.svg';
import docx from './docx.svg';
import jpeg from './jpeg.svg';
import jpg from './jpg.svg';
import pdf from './pdf.svg';
import png from './png.svg';
import xls from './xls.svg';
import xlsx from './xlsx.svg';
import successToastIcon from './toastSuccess.svg';
import warningToastIcon from './toastwarnningSmall.svg';
import errorToastIcon from './ToastErrorSmall.svg';
import noResults from './noresults.svg';
import stamp from './stamp.png';
import marsLogo from './marsLogo.png';
import accpetable from './Acceptable.png';
import acceptableOveride from './acceptableAmber.png';
import furtherInfoNeeded from './FurtherInfoNeeded.png';
import notAcceptableOveride from './notAcceptableAmber.png';
import notAcceptable from './notAcceptable.png';
import approved from './Approved.png';
import conditionallyApproved from './conditionallyApproved.png';
import notRecommended from './NotRecommended.png';
import recommended from './Recommended.png';
import rejected from './Rejected.png';
import copyIcon from './copy.png';

export default {
  avatar,
  home,
  dashboard,
  group,
  settings,
  successIcon,
  vesselImage,
  flag,
  loginBg,
  grayMARSLogo,
  profilepic,
  vet,
  vessel,
  dlr,
  VesselIcon,
  toastWarnning,
  toastError,
  avatarlg,
  avatarsm,
  successIconlg,
  avatardrpdwn,
  openedeye,
  closedeye,
  vetRequestIcon,
  EmailIcon,
  GraphIcon,
  ReportsIcon,
  doc,
  docx,
  jpeg,
  jpg,
  pdf,
  png,
  xls,
  xlsx,
  successToastIcon,
  warningToastIcon,
  errorToastIcon,
  noResults,
  stamp,
  marsLogo,
  accpetable,
  acceptableOveride,
  furtherInfoNeeded,
  notAcceptableOveride,
  notAcceptable,
  approved,
  conditionallyApproved,
  notRecommended,
  recommended,
  rejected,
  copyIcon,
};
