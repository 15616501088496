import React, { useEffect, useState } from 'react';
import { Restricted } from '../../../../../contexts/PermissionContext';
import Button from 'devextreme-react/button';
import icons from '../../../../common/icons/icons';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import { useTranslation } from 'react-i18next';
import { IVetRelatedInfo } from '../../../../../types/vessel-vet-related-info';
import { useDateFormat } from '../../../../../hooks/useDateFormat';
import EditVetInfo from '../edit-vet-info/EditVetInfo';
import { getCompanyType } from '../../../../../utils/jwt-decode';
import { CompanyType } from '../../../../../enums/company-type-enum';

export default function VetRelatedInfo(props: { imoNumber?: string }) {
  const [listView, setListView] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();
  const { imoNumber } = props;
  const [vetRelatedInfo, setVetRelatedInfo] = useState<IVetRelatedInfo>();
  const { formatDate } = useDateFormat();
  const companyType = getCompanyType();

  const GetInfo = async () => {
    await api
      .get({
        url: apiConfig.vetRelatedInfo + imoNumber,
      })
      .then((response: any) => {
        setVetRelatedInfo(response);
      });
  };

  useEffect(() => {
    if (imoNumber) {
      GetInfo();
    }
  }, []);

  return (
    <div>
      {listView ? (
        <div className="m-l-inner-page-body">
          <div className="m-l-expand-btn-wrap m-l-edit-view-mode m-l-edit-view-engine-tab-mode">
            <Restricted permission="Ship.EditVetRelatedInfo">
              {companyType != CompanyType.RequestorCompany && (
                <Button
                  className="app-c-btn app-c-icon-only-btn"
                  onClick={() => {
                    setListView(!listView);
                    setIsEdit(!isEdit);
                  }}
                >
                  <icons.IconPencil />
                </Button>
              )}
            </Restricted>
          </div>
          <div className="m-l-form-panel-block m-l-form-panel-block--tab-mode">
            <div className="m-l-tbl-square-wrapper u-pt0">
              <div className="m-l-ads-table-block">
                <div className="m-l-ads-table-right-part">
                  <div className="row m-l-ads-table-row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items u-mb0 m-l-custom-sixteen-space-col">
                      <div className="m-l-details-page-small-heading">
                        {t('labels.general')}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.P&IInsuranceStartDate')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {formatDate(vetRelatedInfo?.piInsuranceStartDate)}{' '}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.P&IInsuranceEndDate')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {formatDate(vetRelatedInfo?.piInsuranceEndDate)}{' '}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.H&MInsuranceStartDate')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {formatDate(vetRelatedInfo?.hmInsuranceStartDate)}{' '}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.H&MInsuranceEndDate')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {formatDate(vetRelatedInfo?.hmInsuranceEndDate)}{' '}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.cofrValidityDate')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {formatDate(vetRelatedInfo?.cofrValidityDate)}{' '}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.vrpValidityDate')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {formatDate(vetRelatedInfo?.vrpValidityDate)}{' '}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.uscgcocValidityDate')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {formatDate(vetRelatedInfo?.uscgcocValidityDate)}{' '}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.codValidityDate')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {formatDate(vetRelatedInfo?.codValidityDate)}{' '}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.coiValidityDate')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {formatDate(vetRelatedInfo?.coiValidityDate)}{' '}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.tsmsValidityDate')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {formatDate(vetRelatedInfo?.tsmsValidityDate)}{' '}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.lastDrydockDate')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {formatDate(vetRelatedInfo?.lastDrydockDate)}{' '}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.uwildDate')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {formatDate(vetRelatedInfo?.uwildDate)}{' '}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.conditionClassValidTillDate')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {formatDate(
                          vetRelatedInfo?.conditionClassValidTillDate
                        )}{' '}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.questionnaireValidityDate')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {formatDate(
                          vetRelatedInfo?.questionnaireValidityDate
                        )}{' '}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.managementChangeReview')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {vetRelatedInfo?.managementChangeReview ? (
                          <div className="m-l-ads-tbl-desc">
                            {t('labels.acceptable')}
                          </div>
                        ) : (
                          <div className="m-l-ads-tbl-desc">
                            {t('labels.notAcceptable')}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.timeCharterer')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {vetRelatedInfo?.timeCharterer}{' '}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.broker')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {vetRelatedInfo?.broker}{' '}
                      </div>
                    </div>
                  </div>

                  <div className="row m-l-ads-table-row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items u-mb0 m-l-custom-sixteen-space-col">
                      <div className="m-l-details-page-small-heading">
                        {t('labels.scrubber')}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {' '}
                        {t('labels.scrubberFitted')}{' '}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {vetRelatedInfo?.scrubberFitted ? (
                          <div className="m-l-ads-tbl-desc">
                            {t('labels.yes')}
                          </div>
                        ) : (
                          <div className="m-l-ads-tbl-desc">
                            {t('labels.no')}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {' '}
                        {t('labels.scrubberType')}{' '}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {vetRelatedInfo?.scrubberType}{' '}
                      </div>
                    </div>
                  </div>

                  <div className="row m-l-ads-table-row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items u-mb0 m-l-custom-sixteen-space-col">
                      <div className="m-l-details-page-small-heading">
                        {t('labels.capRatingDetails')}
                      </div>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-prvw-container">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.hull')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {vetRelatedInfo?.capRatingHull}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.date')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {formatDate(vetRelatedInfo?.capRatingHullDate)}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.machinery')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {vetRelatedInfo?.capRatingMachinery}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.date')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {formatDate(
                                  vetRelatedInfo?.capRatingMachineryDate
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-prvw-container">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.cargoSystem')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {vetRelatedInfo?.capRatingCargoSystem}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.date')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {formatDate(
                                  vetRelatedInfo?.capRatingCargoSystemDate
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.electricalEquipment')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {vetRelatedInfo?.capRatingElectricalEquipment}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.date')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {formatDate(
                                  vetRelatedInfo?.capRatingElectricalEquipmentDate
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-prvw-container">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.arrangements')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {vetRelatedInfo?.capRatingArrangements}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.date')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {formatDate(
                                  vetRelatedInfo?.capRatingArrangementsDate
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.propulsionPlants')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {vetRelatedInfo?.capRatingPropulsionPlant}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.date')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {formatDate(
                                  vetRelatedInfo?.capRatingPropulsionPlantDate
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-prvw-container">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.ballastTankRating')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {vetRelatedInfo?.capRatingBallastTankRating}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.date')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {formatDate(
                                  vetRelatedInfo?.capRatingBallastTankRatingDate
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.externalStructure')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {vetRelatedInfo?.capRatingExternalStructure}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.date')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {formatDate(
                                  vetRelatedInfo?.capRatingExternalStructureDate
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row m-l-ads-table-row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items u-mb0 m-l-custom-sixteen-space-col">
                      <div className="m-l-details-page-small-heading">
                        {t('labels.sire')}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-prvw-container">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.sireDate')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {formatDate(vetRelatedInfo?.sireDate)}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.sireValidityDate')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {formatDate(vetRelatedInfo?.sireValidityDate)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.sireType')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {vetRelatedInfo?.operationsType}{' '}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.conductedBy')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {vetRelatedInfo?.conductedBy}{' '}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.comments')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {vetRelatedInfo?.comments}{' '}
                      </div>
                    </div>
                  </div>

                  <div className="row m-l-ads-table-row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items u-mb0 m-l-custom-sixteen-space-col">
                      <div className="m-l-details-page-small-heading">
                        {t('labels.matrix')}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-prvw-container">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.matrixDate')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {formatDate(vetRelatedInfo?.matrixDate)}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.matrixValidityDate')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {formatDate(vetRelatedInfo?.matrixValidityDate)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row m-l-ads-table-row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items u-mb0 m-l-custom-sixteen-space-col">
                      <div className="m-l-details-page-small-heading">
                        {t('labels.cssr')}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-prvw-container">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.cssrDate')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {formatDate(vetRelatedInfo?.cssrDate)}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="m-c-form-group mb-btm-space-24">
                              <label className="m-l-input-label">
                                {t('labels.cssrValidityDate')}
                              </label>
                              <div className="m-l-input-data-preview">
                                {formatDate(vetRelatedInfo?.cssrValidityDate)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.cssrDate')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {formatDate(vetRelatedInfo?.cssrDate)}{' '}
                      </div>
                    </div> */}

                    {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        CSSR Validity Date
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {' '}
                        {formatDate(vetRelatedInfo?.cssrValidityDate)}{' '}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {isEdit ? (
        <EditVetInfo
          imoNumber={imoNumber}
          setIsEdit={setIsEdit}
          setListView={setListView}
          vetRelatedInfo={vetRelatedInfo}
          getInfo={GetInfo}
        />
      ) : null}
    </div>
  );
}
