import { useEffect, useState } from 'react';

export const useResize = () => {
  const [isMobileResolution, setIsMobileResolution] = useState<boolean>(false);

  useEffect(() => {
    if (window.innerWidth <= 760) {
      setIsMobileResolution(true);
    }
    window.addEventListener('resize', handleResizeEvent);
    return () => {
      window.removeEventListener('resize', handleResizeEvent);
    };
  }, []);

  const handleResizeEvent = () => {
    if (window.innerWidth <= 760) {
      setIsMobileResolution(true);
    } else {
      setIsMobileResolution(false);
    }
  };

  return {
    isMobileResolution,
  };
};
