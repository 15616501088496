import DropDownButton from 'devextreme-react/drop-down-button';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import icons from '../../../../components/common/icons/icons';
import TextBox from 'devextreme-react/text-box';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import { getAPIRoute } from '../../../../utils/api-route';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { EmailSort } from '../../../../enums/email-sort-enum';

export default function Inbox(props: {
  vetRequestId: any;
  inboxList: any[];
  setMailDetail: any;
  setShowMailItem: any;
  showMailItem: boolean;
  setInboxEmailList: any;
  sortBy: string;
  setSortBy: any;
  inboxSearchValue: string;
  setInboxSearchValue: any;
  location: any;
}) {
  const {
    vetRequestId,
    inboxList,
    setMailDetail,
    setShowMailItem,
    showMailItem,
    setInboxEmailList,
    sortBy,
    setSortBy,
    inboxSearchValue,
    setInboxSearchValue,
    location,
  } = props;
  const { apiDateAndTimeFormat } = useDateFormat();
  const [defaultSort, setDefaultSort] = useState<number | undefined>();
  const textBox = useRef<TextBox>(null);
  const [incomingEmailId, setIncomingEmailId] = useState(0);
  const [selectedIncomingEmailId, setSelectedIncomingEmailId] = useState(0);

  useEffect(() => {
    if (location.state) {
      const { selectedEmailId }: any = location.state;
      if (selectedEmailId) {
        setSelectedIncomingEmailId(selectedEmailId);
      }
    }
  }, [location.state]);

  const actions = useMemo(() => {
    return [
      { id: 1, text: EmailSort.oldestOnTop },
      { id: 2, text: EmailSort.newestOnTop },
      { id: 3, text: EmailSort.subject },
      { id: 4, text: EmailSort.fromAddress },
    ];
  }, []);

  const clickMailItem = (incomingEmailId: any) => {
    inboxList.map((inboxEmail: any) => {
      if (inboxEmail.incomingEmailId == incomingEmailId) {
        inboxEmail.selected = true;
      } else {
        inboxEmail.selected = false;
      }
    });
    setShowMailItem(true);
    setIncomingEmailId(incomingEmailId);
  };

  const getMailBody = async () => {
    await api
      .get({ url: getAPIRoute('vetInboxEmailById', [incomingEmailId]) })
      .then((res) => {
        setMailDetail(res);
      });
  };

  useEffect(() => {
    if (incomingEmailId && incomingEmailId != 0) {
      getMailBody();
    }
  }, [incomingEmailId]);

  useEffect(() => {
    switch (sortBy) {
      case EmailSort.oldestOnTop: {
        setDefaultSort(1);
        break;
      }
      case EmailSort.newestOnTop: {
        setDefaultSort(2);
        break;
      }
      case EmailSort.subject: {
        setDefaultSort(3);
        break;
      }
      case EmailSort.toAddress: {
        setDefaultSort(4);
        break;
      }
      default: {
        setDefaultSort(undefined);
        break;
      }
    }
  }, [sortBy]);

  const focusTextBox = useCallback(() => {
    textBox.current?.instance.focus();
  }, []);

  useEffect(() => {
    focusTextBox();
    if (inboxList.length > 0) {
      if (selectedIncomingEmailId) {
        clickMailItem(selectedIncomingEmailId);
      } else {
        if (window.innerWidth > 767) {
          setFirstMailAsSelected();
        }
      }
    } else {
      setShowMailItem(false);
    }
  }, [inboxList, selectedIncomingEmailId]);

  const setFirstMailAsSelected = () => {
    inboxList.map((inboxEmail: any, index: number) => {
      if (index == 0) {
        inboxEmail.selected = true;
      } else {
        inboxEmail.selected = false;
      }
    });
    setShowMailItem(true);
    setIncomingEmailId(inboxList[0].incomingEmailId);
  };

  const sortByOldestOnTop = (inboxList: any) => {
    const outboxListCopy = inboxList.slice();
    setInboxEmailList(
      outboxListCopy.sort((a: any, b: any) =>
        a.receivedDate?.valueOf().localeCompare(b.receivedDate?.valueOf())
      )
    );
  };

  const sortByNewestOnTop = (inboxList: any) => {
    const outboxListCopy = inboxList.slice();
    setInboxEmailList(
      outboxListCopy.sort((a: any, b: any) =>
        b.receivedDate?.valueOf().localeCompare(a.receivedDate?.valueOf())
      )
    );
  };

  const sortByFromAddress = (inboxList: any) => {
    const outboxListCopy = inboxList.slice();
    setInboxEmailList(
      outboxListCopy.sort((a: any, b: any) =>
        a.from?.valueOf().localeCompare(b.from?.valueOf())
      )
    );
  };

  const sortBySubject = (inboxList: any) => {
    const outboxListCopy = inboxList.slice();
    setInboxEmailList(
      outboxListCopy.sort((a: any, b: any) =>
        a.subject?.valueOf().localeCompare(b.subject?.valueOf())
      )
    );
  };

  const onClickSort = (e: any) => {
    if (inboxList && inboxList.length > 0) {
      inboxList.map((inboxEmail: any) => {
        inboxEmail.selected = false;
      });
      switch (e.itemData.id) {
        case 1: {
          sortByOldestOnTop(inboxList);
          setSortBy(EmailSort.oldestOnTop);
          break;
        }
        case 2: {
          sortByNewestOnTop(inboxList);
          setSortBy(EmailSort.newestOnTop);
          break;
        }
        case 3: {
          sortBySubject(inboxList);
          setSortBy(EmailSort.subject);
          break;
        }
        case 4: {
          sortByFromAddress(inboxList);
          setSortBy(EmailSort.toAddress);
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  const onInputSearch = async (e: any) => {
    setInboxSearchValue(e.event.target.value);
    await api
      .get({
        url:
          location.pathname != '/email'
            ? getAPIRoute('searchVetRequestInboxEmails', [vetRequestId])
            : apiConfig.searchInboxEmails,
        params: { searchString: e.event.target.value },
      })
      .then((data) => {
        if (data && data.length > 0) {
          if (defaultSort == 1) {
            sortByOldestOnTop(data);
          } else if (defaultSort == 2) {
            sortByNewestOnTop(data);
          } else if (defaultSort == 3) {
            sortBySubject(data);
          } else if (defaultSort == 4) {
            sortByFromAddress(data);
          }
        } else {
          setInboxEmailList([]);
        }
      });
  };

  return (
    <div className="m-l-mail-left-data-wrapper">
      <div className="m-l-mail-left-header">
        <div className="m-l-vsl-dropdown-btn m-l-gray-dropdown-wiout-border">
          <DropDownButton
            items={actions}
            keyExpr="id"
            displayExpr="text"
            text="Sort Order"
            onItemClick={onClickSort}
            selectedItemKey={defaultSort}
            useSelectMode={true}
            focusStateEnabled={true}
          />
        </div>
        <div className="right-block">
          <div className="m-l-input-search-area-block">
            <TextBox
              ref={textBox}
              placeholder="Search"
              className="m-c-input-control"
              onInput={onInputSearch}
              value={inboxSearchValue}
            ></TextBox>
            <div className="m-c-search-icon">
              <icons.Search />
            </div>
          </div>
        </div>
      </div>

      {inboxList && inboxList.length > 0 && (
        // <div className="m-l-msg-items-wrapper">
        <div
          className={
            vetRequestId
              ? 'm-l-msg-items-wrapper  m-l-msg-items-wrapper--inside-tab'
              : 'm-l-msg-items-wrapper'
          }
        >
          {inboxList.map((email: any) => {
            return (
              <div
                key={email.incomingEmailId}
                className={
                  email.selected && showMailItem
                    ? 'm-l-msg-item selected'
                    : 'm-l-msg-item'
                }
                onClick={() => clickMailItem(email.incomingEmailId)}
              >
                <div className="m-l-email-profile-pic-wrap">
                  {/* <img
                    src={imageURL.avatarlg}
                    className="img-responsive m-l-email-profile-pic"
                    alt="profile-pic"
                  /> */}
                  <icons.Profile />
                </div>
                <div className="m-l-emlr-data-wrap">
                  <div className="m-l-emlr-name">{email.from}</div>
                  <div className="m-l-emlr-desc">{email.subject}</div>
                </div>
                <div className="m-l-eml-cm-tm">
                  <div className="m-l-eml-time">
                    {apiDateAndTimeFormat(email.receivedDate)}
                  </div>
                  {email.noOfAttachments && email.noOfAttachments != 0 ? (
                    <div className="m-l-eml-attch">
                      <div className="m-l-eml-atth-icon">
                        {' '}
                        <icons.IconAttach12 />
                      </div>
                      <div className="m-l-eml-atth-fl">
                        {email.noOfAttachments}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
