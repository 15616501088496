export enum OptionTypes {
  IncoTerms = 'incoterms',
  CanUserEnter = 'canuserenter',
  ShipTypeLevel3 = 'shiptypelevel3',
  Relation = 'relation',
  RuleConditions = 'rule-conditions',
  AttachmentTypes = 'attachmenttypes',
  DefectCode = 'defectcode',
  RootCause = 'pscrootcausecode',
  SubArea = 'subarea',
  ActionCode = 'pscactioncode',
  RiskArea = 'riskarea',
  PSCInspectionStatus = 'pscinspectionstatus',
  GadgetsTimePeriod = 'gadgetstimeperiod',
}
