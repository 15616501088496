import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BerthFieldTypes } from '../../../enums/port-enum';
import { useDimensionsUnit } from '../../../hooks/useDimensionsUnit';
import { IFormConfig } from '../../../types/form-builder';
import FormBuilder from '../../common/form-builder/FormBuilder';
import BerthAccordion from './berth-accordion/BerthAccordion';

export default function BerthForm(props: {
  berthTypes: any;
  berthCode: any;
  berthStatus: any;
}) {
  const { berthTypes, berthStatus } = props;
  const { berthCode } = props;
  const { t } = useTranslation();
  const { dimensionUnitText, dimensionUnit } = useDimensionsUnit();
  const { control, getValues } = useFormContext();
  const [berthField, setBerthField] = useState<any>();

  const berthFieldTypes = [
    {
      id: BerthFieldTypes.Dry,
      value: 'Dry',
    },
    {
      id: BerthFieldTypes.Oil,
      value: 'Oil',
    },
    {
      id: BerthFieldTypes.Gas,
      value: 'Gas',
    },
  ];

  const berthFieldType = () => {
    setBerthField(getValues('berthFieldType'));
  };

  const berthInfoFormConfig: IFormConfig[] = useMemo(
    () => [
      {
        name: 'berthName',
        label: t('labels.berthName'),
        control: control,
        fieldType: 'text',
        isRequired: true,
        rules: [
          {
            type: 'required',
            message: t('errors.required'),
          },
        ],
      },
      {
        name: 'berthCode',
        label: t('labels.berthCode'),
        control: control,
        fieldType: 'text',
        disabled: true,
      },
      {
        name: 'portName',
        label: t('labels.portName'),
        control: control,
        fieldType: 'text',
        disabled: true,
      },
      {
        name: 'portCode',
        label: t('labels.portCode'),
        control: control,
        fieldType: 'text',
        disabled: true,
      },
      {
        name: 'country',
        label: t('labels.country'),
        control: control,
        fieldType: 'text',
        disabled: true,
      },
      {
        name: 'terminalName',
        label: t('labels.terminalName'),
        control: control,
        fieldType: 'text',
        disabled: true,
      },
      {
        name: 'terminalCode',
        label: t('labels.terminalCode'),
        control: control,
        fieldType: 'text',
        disabled: true,
      },
      {
        fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
        fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
        name: 'berthFieldType',
        label: t('labels.berthFieldType'),
        control: control,
        fieldType: 'radio',
        layout: 'horizontal',
        items: berthFieldTypes,
        displayExpr: 'value',
        valueExpr: 'id',
        onChange: berthFieldType,
      },
      {
        name: 'berthStyle',
        label: t('labels.berthStyle'),
        control: control,
        fieldType: 'text',
      },
      {
        name: 'berthingSide',
        label: t('labels.berthingSide'),
        control: control,
        fieldType: 'text',
      },
      {
        name: 'latitude',
        label: t('labels.latitude'),
        control: control,
        fieldType: 'text',
      },
      {
        name: 'longitude',
        label: t('labels.longitude'),
        control: control,
        fieldType: 'text',
      },
      {
        name: 'berthTypes',
        label: t('labels.berthType'),
        control: control,
        fieldType: 'multiSelect',
        dataSource: berthTypes,
        displayExpr: 'optionValueText',
        valueExpr: 'optionValueCode',
        showClearButton: true,
        class:
          'col-md-4 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      },
      {
        name: 'berthnotes',
        label: t('labels.berthNotes'),
        control: control,
        fieldType: 'text',
      },
      {
        name: 'mooringRequirements',
        label: t('labels.mooringRequirements'),
        control: control,
        fieldType: 'text',
      },
      {
        name: 'gangwayArrangements',
        label: t('labels.gangwayArrangements'),
        control: control,
        fieldType: 'text',
      },
      {
        name: 'connectionFlangeSize',
        label: t('labels.connectionFlangeSize'),
        control: control,
        fieldType: 'text',
      },
      {
        name: 'connectorTypeDesc',
        label: t('labels.connectorType'),
        control: control,
        fieldType: 'text',
      },
      {
        name: 'lasthydrographicalsurvey',
        label: t('labels.lasthydrographicalsurvey'),
        control: control,
        fieldType: 'date',
      },
      {
        fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
        fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
        name: 'status',
        label: t('labels.status'),
        control: control,
        fieldType: 'select',
        dataSource: berthStatus,
        displayExpr: 'optionValueText',
        valueExpr: 'optionValueCode',
      },
      {
        name: 'maximumDeadweight',
        label: t('labels.maximumDeadweightMT'),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
      },
      {
        name: 'minimumDeadweight',
        label: t('labels.minimumDeadweightMT'),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
      },
      {
        name: 'maximumLengthOverall',
        label: t('labels.maximumLengthOverall') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
      },
      {
        name: 'minimumLengthOverall',
        label: t('labels.minimumLengthOverall') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
      },
      {
        name: 'maximumBeam',
        label: t('labels.maximumBeam') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
      },
      {
        name: 'minimumBeam',
        label: t('labels.minimumBeam') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
      },
      {
        name: 'maximumFreeboard',
        label: t('labels.maximumFreeboard') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
      },
      {
        name: 'minimumFreeboard',
        label: t('labels.minimumFreeboard') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
      },
      {
        name: 'pblForwardArrival',
        label: t('labels.pblForwardArrival') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
      },
      {
        name: 'pblAftArrival',
        label: t('labels.pblArrival') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
      },
      {
        name: 'depthAlongSide',
        label: t('labels.depthAlongSide') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
      },
      {
        name: 'maximumDisplacementAlongside',
        label: t('labels.maximumDisplacementAlongsideMT'),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
      },
      {
        name: 'maximumDraughtAlongside',
        label: t('labels.maximumDraughtAlongside') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
      },
      {
        name: 'minimumAlongsideUKC',
        label: t('labels.minimumAlongsideUKC') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
      },
      {
        name: 'maximumAirDraughtAlongside',
        label: t('labels.maximumAirDraughtAlongside') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
      },
      {
        name: 'maximumAirDraughtUnderShoreGear',
        label:
          t('labels.maximumAirDraughtUnderShoreGear') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'maximumHeightManifoldAWL',
        label: t('labels.maximumHeightManifoldAWL') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'minimumHeightManifoldAWL',
        label: t('labels.minimumHeightManifoldAWL') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'maximumManifoldSetback',
        label: t('labels.maximumManifoldSetback') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'minimumManifoldSetback',
        label: t('labels.minimumManifoldSetback') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'maximumHeightManifoldAboveDeckDripTray',
        label:
          t('labels.maximumHeightManifoldAboveDeckDripTray') +
          dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'minimumHeightManifoldAboveDeckDripTray',
        label:
          t('labels.minimumHeightManifoldAboveDeckDripTray') +
          dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'maximumManifoldSpacing',
        label: t('labels.maximumManifoldSpacing') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'minimumManifoldSpacing',
        label: t('labels.minimumManifoldSpacing') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'maximumSternCentreManifoldDistance',
        label:
          t('labels.maximumSternCentreManifoldDistance') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'minimumSternCentreManifoldDistance',
        label:
          t('labels.minimumSternCentreManifoldDistance') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'maximumBowCentreManifoldDistance',
        label:
          t('labels.maximumBowCentreManifoldDistance') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'minimumBowCentreManifoldDistance',
        label:
          t('labels.minimumBowCentreManifoldDistance') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'maximumDistanceManifoldShipSideRail',
        label:
          t('labels.maximumDistanceManifoldShipSideRail') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'minimumDistancefromManifoldShipSideRail',
        label:
          t('labels.minimumDistancefromManifoldShipSideRail') +
          dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'minimumPMB',
        label: t('labels.minimumPMB') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'minimumPMBForwardOfShipManifold',
        label:
          t('labels.minimumPMBForwardOfShipManifold') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'minimumPMBAftOfShipManifold',
        label: t('labels.minimumPMBAftOfShipManifold') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'minimumHeightManifoldAboveDeck',
        label: t('labels.minimumHeightManifoldAboveDeck') + dimensionUnitText(),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'hoseDerrickMinimumSWL',
        label: t('labels.hoseDerrickMinimumSWLMT'),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden:
          !(berthField == BerthFieldTypes.Gas) &&
          !(berthField == BerthFieldTypes.Oil),
      },
      {
        name: 'gasCubicCapacityGasCarriersMaximum',
        label: t('labels.gasCubicCapacityGasCarriersMaximum'),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden: !(berthField == BerthFieldTypes.Gas),
      },
      {
        name: 'gasCubicCapacityGasCarriersMinimum',
        label: t('labels.gasCubicCapacityGasCarriersMinimum'),
        control: control,
        fieldType: 'number',
        allowDecimal: true,
        isHidden: !(berthField == BerthFieldTypes.Gas),
      },

      // {
      //   name: 'loadingRate',
      //   label: t('labels.loadingRate'),
      //   control: control,
      //   fieldType: 'text',
      // },
      // {
      //   name: 'shipHandled',
      //   label: t('labels.shipHandled'),
      //   control: control,
      //   fieldType: 'text',
      // },
      // {
      //   name: 'berthDocument',
      //   label: t('labels.berthDocument'),
      //   control: control,
      //   fieldType: 'text',
      // },
      // {
      //   name: 'maxDraftAtApproaches',
      //   label: t('labels.maxDraftAtApproaches') + dimensionUnitText(),
      //   control: control,
      //   fieldType: 'number',
      // },
      // {
      //   name: 'cargoHandled',
      //   label: t('labels.cargoHandled'),
      //   control: control,
      //   fieldType: 'text',
      // },
      // {
      //   name: 'auditDetails',
      //   label: 'Audit Details',
      //   control: control,
      //   fieldType: 'text',
      // },
      // {
      //   name: 'cargoType',
      //   label: t('labels.cargoType'),
      //   control: control,
      //   fieldType: 'text',
      // },
      // {
      //   name: 'depthAtApproaches',
      //   label: t('labels.depthAtApproaches') + dimensionUnitText(),
      //   control: control,
      //   fieldType: 'text',
      // },
      // {
      //   name: 'minUkcAtApproaches',
      //   label: t('labels.minUkcAtApproaches') + dimensionUnitText(),
      //   control: control,
      //   fieldType: 'number',
      // },
      // {
      //   name: 'minUkcValm',
      //   label: t('labels.minUkcValm'),
      //   control: control,
      //   fieldType: 'number',
      // },
      // {
      //   name: 'shipsHandledShipType',
      //   label: t('labels.shipsHandledShipType'),
      //   control: control,
      //   fieldType: 'text',
      // },
      // {
      //   name: 'shipsHandledShipSizeClassification',
      //   label: t('labels.shipsHandledShipSizeClassification'),
      //   control: control,
      //   fieldType: 'text',
      // },
      // {
      //   name: 'airDraughtApproaches',
      //   label: 'Air Draught Approaches ' + dimensionUnitText(),
      //   control: control,
      //   fieldType: 'text',
      // },
    ],
    [berthField, dimensionUnit, berthTypes]
  );
  return (
    <div className="m-l-form-panel-body-block">
      <div className="row">
        <FormBuilder formConfig={berthInfoFormConfig} />
      </div>
      <div className="row">
        <BerthAccordion berthCode={berthCode} />
      </div>
    </div>
  );
}
