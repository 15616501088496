import Button from 'devextreme-react/button';
import RadioGroup from 'devextreme-react/radio-group';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDialogConfig } from '../../../types/dialog';
import { IGridConfig } from '../../../types/grid';
import ConfirmDialog from '../../common/confirm/ConfirmDialog';
import Grid from '../../common/grid/Grid';
import icons from '../../common/icons/icons';
import Modal from '../../common/modal/Modal';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import {
  getCompanyId,
  getUserId,
  getUserName,
} from '../../../utils/jwt-decode';
import { getAPIRoute } from '../../../utils/api-route';
import toast from '../../../utils/toast';
import SaveLayout from './SaveLayout';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { IDuplicateFavorite, IDuplicateLayout } from '../../../types/report';
import { Restricted } from '../../../contexts/PermissionContext';

export default function ManageLayout(props: {
  isVisible: any;
  layoutsData: any;
  setIsVisible: any;
  sharedLayoutsData: any;
  getLayoutDetails?: any;
  getSharedLayoutDetails?: any;
  reportHeaderId?: any;
  allLayoutsData?: any;
  getAllLayoutDetails?: any;
  getAllFavoriteDetails: any;
}) {
  const {
    isVisible,
    layoutsData,
    setIsVisible,
    sharedLayoutsData,
    getLayoutDetails,
    getSharedLayoutDetails,
    reportHeaderId,
    allLayoutsData,
    getAllLayoutDetails,
    getAllFavoriteDetails,
  } = props;
  const [isMyLayouts, setIsMyLayouts] = useState(true);
  const [layout, setLayout] = useState<any>();
  const [showConfirmCopy, setShowConfirmCopy] = useState(false);
  const [copyLayout, setCopyLayout] = useState(false);
  const [editLayout, setEditLayout] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [isDefault, setIsDefault] = useState<any>(false);
  const [isPrivate, setIsPrivate] = useState<any>(false);
  const [layoutName, setLayoutName] = useState<any>('');
  const [showConfirmEdit, setShowConfirmEdit] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showSharedConfirmDelete, setShowSharedConfirmDelete] = useState(false);
  const { setIsLoading } = useLoaderContext();
  const { t } = useTranslation();
  const userId = getUserId();
  const userName = getUserName();
  const [showMakeDefault, setShowMakeDefault] = useState(false);
  const [rowClickName, setRowClickName] = useState();
  const [duplicateLayoutData, setduplicateLayoutData] = useState<any>();
  const companyId = getCompanyId();
  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-modal__layout',
    };
  }, []);

  const priorities = ['My Layouts', 'Shared Layouts'];

  const privateFilterData = [
    { value: true, display: 'Yes' },
    { value: false, display: 'No' },
  ];

  const modalConfig = {
    width: 1262,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const confirmMakeDefault: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.makeDefaultFav', {
      name: rowClickName,
    }),
    handleSubmit: () => {
      makeLayoutToDefault();
      setShowMakeDefault(false);
    },
    handleClose: () => {
      setShowMakeDefault(false);
    },
  };

  const onDelete = (e: any) => {
    setLayout(e);
    if (e?.isSharedDefault) {
      setShowSharedConfirmDelete(true);
    } else {
      setShowConfirmDelete(true);
    }
  };

  const gridConfig: IGridConfig = {
    showHeader: false,
    class:
      'm-c-grid m-l-incident-grid m-c-auto-scroll-grid m-l-grid-with--top-border',
    defaultColumns: [
      {
        caption: 'Layout Name',
        dataField: 'layoutName',
        dataType: 'string',
        // width: 170,
        minWidth: 200,
      },
      {
        caption: 'Private',
        dataField: 'isPrivate',
        cellTemplate: 'actionTemplate1',
        dataType: 'string',
        minWidth: 200,
        lookup: {
          dataSource: privateFilterData,
          valueExpr: 'value',
          displayExpr: 'display',
        },
      },
      {
        caption: 'Action',
        dataField: 'action',
        dataType: 'string',
        cellTemplate: 'statusTemplate',
        width: 200,
        minWidth: 200,
        allowFiltering: false,
        allowSorting: false,
      },
    ],
    dataSource: layoutsData,
    hideFilterRow: false,
  };

  const sharedGridConfig: IGridConfig = {
    showHeader: false,
    // class:
    //   'm-c-grid m-l-incident-grid m-c-auto-scroll-grid m-l-grid-with--top-border',
    defaultColumns: [
      {
        caption: 'Layout Name',
        dataField: 'layoutName',
        dataType: 'string',
        minWidth: 200,
      },
      {
        caption: 'Author',
        dataField: 'createdUserName',
        dataType: 'string',
        minWidth: 200,
      },
      {
        caption: 'Action',
        dataField: 'Action',
        dataType: 'string',
        cellTemplate: 'statusTemplate',
        width: 200,
        allowFiltering: false,
        allowSorting: false,
      },
    ],
    dataSource: sharedLayoutsData,
    hideFilterRow: false,
  };

  const confirmCopyDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('Duplicate ' + layout?.layoutName + '. Continue?'),
    handleSubmit: () => {
      setShowSave(true);
      setCopyLayout(true);
      setShowConfirmCopy(false);
    },
    handleClose: () => {
      setCopyLayout(false);
      setShowConfirmCopy(false);
    },
  };

  const confirmEditDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.editFavorite', {
      name: layout?.layoutName,
    }),
    handleSubmit: () => {
      onEditLayout();
      setShowConfirmEdit(false);
    },
    handleClose: () => {
      setEditLayout(false);
      setShowConfirmEdit(false);
    },
  };

  const confirmDeleteDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: layout?.isDefault
      ? layout?.layoutName + ' is marked as default. Delete?'
      : 'Delete ' + layout?.layoutName + '. Continue?',
    handleSubmit: () => {
      deleteLayout();
      setShowConfirmDelete(false);
    },
    handleClose: () => {
      setShowConfirmDelete(false);
    },
  };

  const confirmSharedDeleteDialog: IDialogConfig = {
    id: 'confirmSave',
    content:
      'This Layout is being used as default by other users. Delete Layout?',
    handleSubmit: () => {
      deleteLayout();
      setShowSharedConfirmDelete(false);
    },
    handleClose: () => {
      setShowSharedConfirmDelete(false);
    },
  };

  const makeLayoutToDefault = () => {
    const date = new Date();
    const favoriteData: IDuplicateLayout = {
      reportLayoutId: duplicateLayoutData?.reportLayoutId
        ? duplicateLayoutData?.reportLayoutId
        : 0,
      layoutName: duplicateLayoutData?.layoutName
        ? duplicateLayoutData?.layoutName
        : '',
      layoutType: duplicateLayoutData?.layoutType,
      userId: duplicateLayoutData?.userId,
      reportHeaderId: reportHeaderId,
      isPrivate: duplicateLayoutData?.isPrivate
        ? duplicateLayoutData?.isPrivate
        : false,
      companyId: duplicateLayoutData?.companyId
        ? duplicateLayoutData?.companyId
        : null,
      isDefault: duplicateLayoutData?.isDefault
        ? duplicateLayoutData?.isDefault
        : true,
      createdUserName: duplicateLayoutData?.createdUserName
        ? duplicateLayoutData?.createdUserName
        : '',
    };
    api
      .put({ url: apiConfig.layouts + 'header', data: favoriteData })
      .then((response) => {
        if (response) {
          getLayoutDetails();
          getSharedLayoutDetails();
          getAllLayoutDetails();
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
        }
      });
  };

  const checkNameExist = async (e: any) => {
    if (e.validationGroup.validate().isValid) {
      await api
        .get({
          url: getAPIRoute('reportLayoutNameExist', [layoutName]),
          params: { reportHeaderId: reportHeaderId },
        })
        .then((res) => {
          if (editLayout) {
            setShowConfirmEdit(true);
          } else if (res) {
            toast.error({
              title: 'Error',
              message: layoutName + ' ' + t('errors.favNameExist'),
            });
          } else if (copyLayout) {
            duplicateLayout();
          }
        });
    }
  };

  const duplicateLayout = async () => {
    const date = new Date();
    const data = {
      reportLayoutId: 0,
      reportHeaderId: reportHeaderId,
      layoutName: layoutName,
      layoutType: layout?.layoutType,
      isPrivate: layout?.isPrivate,
      isDefault: layout?.isDefault,
      companyId: layout?.companyId,
      createdUserName: userName,
      userId: layout?.userId,
    };
    api
      .post(
        {
          url: apiConfig.duplicateLayout + layout?.reportLayoutId,
          data: data,
        },
        setIsLoading
      )
      .then((response) => {
        if (response) {
          getLayoutDetails();
          getSharedLayoutDetails();
          getAllLayoutDetails();
          setShowSave(false);
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
        }
      });
  };

  const onEditLayout = async () => {
    const date = new Date();
    const data = {
      reportLayoutId: layout?.reportLayoutId,
      reportHeaderId: reportHeaderId,
      layoutName: layoutName,
      layoutType: layout?.layoutType,
      userId: layout?.userId,
      createdUserName: layout?.createdUserName,
      isPrivate: isPrivate,
      companyId: layout?.companyId,
      isDefault: isDefault,
    };
    api
      .put(
        {
          url: apiConfig.reportLayouts,
          data: data,
        },
        setIsLoading
      )
      .then((res) => {
        if (res) {
          setShowSave(false);
        }
        getLayoutDetails();
        getSharedLayoutDetails();
        toast.custom({
          title: t('toast.savedSuccessfully'),
        });
      });
  };

  const deleteLayout = async () => {
    await api
      .delete(
        {
          url: apiConfig.layouts + layout?.reportLayoutId,
        },
        setIsLoading
      )
      .then((res) => {
        if (res) {
          toast.custom({
            title: t('toast.deleteSuccessful'),
          });
          getLayoutDetails();
          getSharedLayoutDetails();
          getAllLayoutDetails();
          getAllFavoriteDetails();
        }
      });
  };

  const cellRender = (data: any) => {
    return <div>{data?.data?.isPrivate ? 'Yes' : 'No'}</div>;
  };

  const makeLayoutDefault = (rowData: any) => {
    // rowData.isDefault = true;
    setduplicateLayoutData(rowData);
    setRowClickName(rowData.layoutName);
    setShowMakeDefault(true);
  };

  const statusTemplate = (data: any) => {
    return (
      <React.Fragment>
        {isMyLayouts ? (
          <div className="app-c-grid-actions">
            <Restricted permission="ReportingFrameWork.DuplicateStandardLayout">
              <Button
                className="m-l-btn--icon-plain"
                onClick={() => {
                  setLayout(data?.data);
                  setShowConfirmCopy(true);
                }}
              >
                <icons.CopyIcon />
              </Button>
            </Restricted>
            <Button
              className="m-l-btn--icon-plain"
              onClick={() => {
                setLayout(data?.data);
                setEditLayout(true);
                setShowSave(true);
              }}
            >
              <icons.IconEdit />
            </Button>
            <Button
              className="m-l-btn--icon-plain"
              onClick={() => {
                onDelete(data.key);
              }}
            >
              <icons.Trash />
            </Button>
            {data.key.isDefault && (
              <Button className="m-l-btn--icon-plain">
                <icons.FilledStar />
              </Button>
            )}
            {!data.key.isDefault && (
              // <Restricted permission="ReportingFrameWork.DefaultStandardLayout">
              <Button
                className="m-l-btn--icon-plain"
                onClick={() => {
                  makeLayoutDefault(data.key);
                }}
              >
                <icons.Star />
              </Button>
              // </Restricted>
            )}
          </div>
        ) : (
          <div className="app-c-grid-actions">
            <Restricted permission="ReportingFrameWork.DuplicateStandardLayout">
              <Button
                className="m-l-btn--icon-plain"
                onClick={() => {
                  setLayout(data?.data);
                  setShowConfirmCopy(true);
                }}
              >
                <icons.CopyIcon />
              </Button>
            </Restricted>
            {data.key.isDefault && (
              <Button className="m-l-btn--icon-plain">
                <icons.FilledStar />
              </Button>
            )}
            {!data.key.isDefault && (
              // <Restricted permission="ReportingFrameWork.DefaultStandardLayout">
              <Button
                className="m-l-btn--icon-plain"
                onClick={() => {
                  makeLayoutDefault(data.key);
                }}
              >
                <icons.Star />
              </Button>
              // </Restricted>
            )}
          </div>
        )}
      </React.Fragment>
    );
  };

  const changeManageLayout = (e: any) => {
    if (e.value === 'My Layouts') {
      setIsMyLayouts(true);
    } else {
      setIsMyLayouts(false);
    }
  };

  return (
    <React.Fragment>
      <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
        <div className="m-l-modal__header">
          <h2 className="modal-title">Manage Layout</h2>
          <div className="m-l-modal-close-button-block">
            <Button
              className="app-c-btn app-c-icon-only-btn"
              onClick={() => setIsVisible(false)}
            >
              <icons.IconCross />
            </Button>
          </div>
        </div>
        <div className="m-l-modal__body">
          <div className="mb-btm-space m-c-form-group">
            <div className="m-c-radio-button">
              <RadioGroup
                items={priorities}
                defaultValue={priorities[0]}
                layout="horizontal"
                onValueChanged={changeManageLayout}
              />
            </div>
          </div>
          {isMyLayouts && (
            <Grid
              gridConfig={gridConfig}
              statusTemplate={statusTemplate}
              actionTemplate1={cellRender}
            />
          )}
          {!isMyLayouts && (
            <Grid
              gridConfig={sharedGridConfig}
              statusTemplate={statusTemplate}
            />
          )}
        </div>
      </Modal>
      <ConfirmDialog
        dialogConfig={confirmCopyDialogConfig}
        isOpen={showConfirmCopy}
      />
      <ConfirmDialog
        dialogConfig={confirmEditDialogConfig}
        isOpen={showConfirmEdit}
      />
      <ConfirmDialog
        dialogConfig={confirmDeleteDialogConfig}
        isOpen={showConfirmDelete}
      />
      <ConfirmDialog
        dialogConfig={confirmMakeDefault}
        isOpen={showMakeDefault}
      />
      <ConfirmDialog
        dialogConfig={confirmSharedDeleteDialog}
        isOpen={showSharedConfirmDelete}
      />
      {showSave && (
        <SaveLayout
          showSave={showSave}
          setShowSave={setShowSave}
          checkNameExist={checkNameExist}
          setLayoutName={setLayoutName}
          setIsDefault={setIsDefault}
          setIsPrivate={setIsPrivate}
          isDuplicate={copyLayout}
          setIsDuplicate={setCopyLayout}
          edit={editLayout}
          setEdit={setEditLayout}
          duplicateData={layout}
        />
      )}
    </React.Fragment>
  );
}
