import React, { createContext, useEffect, useState } from 'react';
import { MarsApiService as api } from '../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../api/mars-api-config';
import { getBuList } from '../utils/jwt-decode';

type MasterDataContextType = {
  dateFormat: string | undefined;
  setDimensionUnit: any;
  getDateFormat: () => Promise<void>;
  dimensionUnit: number | undefined;
  getDimensionUnit: () => Promise<void>;
};

const defaultData: MasterDataContextType = {
  dateFormat: undefined,
  dimensionUnit: undefined,
  setDimensionUnit: undefined,
  getDimensionUnit: () => Promise.resolve(),
  getDateFormat: () => Promise.resolve(),
};

const MasterDataContext = createContext<MasterDataContextType>(defaultData);

function MasterDataProvider(props: any) {
  const [dateFormat, setDateFormat] = useState<string | undefined>();
  const [dimensionUnit, setDimensionUnit] = useState<number | undefined>();

  const getDateFormat = async () => {
    if (!dateFormat) {
      await api.get({ url: apiConfig.dateFormat }).then((res) => {
        if (res.length) {
          const dateArea = res.find(
            (item: any) => item.settingType === 'DateDisplayFormat'
          );
          setDateFormat(dateArea?.settingValue);
        }
      });
    }
  };

  const getDimensionUnit = async () => {
    const buList = JSON.parse(getBuList() as string);
    const defaultBu = buList.find((bu: any) => bu.isDefault == true);
    if (!dimensionUnit) {
      await api
        .get({
          url: apiConfig.userProfile,
          params: { buId: defaultBu.buId },
        })
        .then((res) => {
          setDimensionUnit(res.unitOfLength);
        });
    }
  };

  useEffect(() => {
    getDateFormat();
    getDimensionUnit();
  }, []);

  return (
    <MasterDataContext.Provider
      value={{
        dateFormat,
        dimensionUnit,
        setDimensionUnit,
        getDimensionUnit,
        getDateFormat,
      }}
    >
      {props.children}
    </MasterDataContext.Provider>
  );
}

export { MasterDataProvider, MasterDataContext };
