import { Button } from 'devextreme-react/button';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IGridConfig } from '../../../../../types/grid';
import Grid from '../../../../common/grid/Grid';
import EditPSCInspection from '../edit-psc-inspection/EditPSCInspection';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import { IPSCInspection } from '../../../../../types/psc-inspection';
import toast from '../../../../../utils/toast';
import Icons from '../../../../common/icons/icons';
import { IDialogConfig } from '../../../../../types/dialog';
import ConfirmDialog from '../../../../common/confirm/ConfirmDialog';
import { getCompanyId, getCompanyType } from '../../../../../utils/jwt-decode';
import {
  Restricted,
  usePermission,
} from '../../../../../contexts/PermissionContext';
import { useDataStore } from '../../../../../hooks/useDataStore';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid from 'devextreme-react/data-grid';
import { useLoaderContext } from '../../../../../contexts/LoaderContext';
import { CompanyType } from '../../../../../enums/company-type-enum';

export default function PSCInspection(props: {
  imoNumber?: string;
  vesselBannerDetails?: any;
}) {
  const { imoNumber, vesselBannerDetails } = props;
  const { t } = useTranslation();
  const [showEditPSCModal, setShowEditPSCModal] = useState(false);
  const [inspections, setInspections] = useState<IPSCInspection[]>([]);
  const [selectedInspectionId, setSelectedInspectionId] = useState<any>();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmAccept, setShowConfirmAccept] = useState(false);
  const [showConfirmIgnore, setShowConfirmIgnore] = useState(false);
  const [deletePSC, setDeletePSC] = useState<IPSCInspection>();
  const [pscInspectionId, setPSCInspectionId] = useState<number>(0);
  const companyId = getCompanyId();
  const companyType = getCompanyType();
  const { findFeature } = usePermission();
  const { getDataSource } = useDataStore();
  const [isMarsUser, setIsMarsUser] = useState<boolean>();
  const gridRef = useRef<DataGrid>(null);
  const portDataSource = getDataSource('portId', apiConfig.activePorts);
  const { setIsLoading } = useLoaderContext();
  const IsCompanyTypeMars = () => {
    if (companyType === 'M') {
      setIsMarsUser(true);
    } else {
      setIsMarsUser(false);
    }
  };

  const confirmDeleteDialogConfig: IDialogConfig = {
    id: 'confirmDelete',
    content: t('toast.pscInspectionDeleteConfirm'),
    handleSubmit: () => {
      pscDelete();
      setShowConfirmDelete(false);
    },
    handleClose: () => {
      setShowConfirmDelete(false);
    },
  };

  const confirmAcceptDialogConfig: IDialogConfig = {
    id: 'confirmAccept',
    content: t('toast.pscInspectionAccept'),
    handleSubmit: () => {
      acceptPSC();
      setShowConfirmAccept(false);
    },
    handleClose: () => {
      setShowConfirmAccept(false);
    },
  };

  const confirmIgnoreDialogConfig: IDialogConfig = {
    id: 'confirmIgnore',
    content: t('toast.pscInspectionIgnore'),
    handleSubmit: () => {
      ignorePSC();
      setShowConfirmIgnore(false);
    },
    handleClose: () => {
      setShowConfirmIgnore(false);
    },
  };

  const onClickRow = (e: any) => {
    if (!findFeature('Ship.ViewPSCDetail')) {
      return;
    } else if (
      e.event &&
      e.event.target.className !== 'dx-button-content' &&
      e.event.target.nodeName !== 'svg' &&
      e.event.target.nodeName !== 'path' &&
      e.event.target.className !== 'click-delete'
    ) {
      setShowEditPSCModal(true);
      setSelectedInspectionId(e.data.pscInspectionId);
    }
  };

  const getPSCInspections = async () => {
    await api
      .get({
        url: apiConfig.pscInspections + imoNumber,
      })
      .then((res: IPSCInspection[]) => {
        setInspections(res);
      });
  };

  const pscInspectionStatus = {
    store: new CustomStore({
      key: 'optionValueCode',
      loadMode: 'raw',
      load: () => {
        return api.get({ url: apiConfig.pscStatus });
      },
    }),
  };

  const stateOfPSCInspection: any = {
    store: new CustomStore({
      key: 'optionValueCode',
      loadMode: 'raw',
      load: () => {
        return api.get({ url: apiConfig.stateOfPSCInspection }).then((res) => {
          return res;
        });
      },
    }),
  };
  const showPSCStatus = useMemo(() => {
    if (companyType === CompanyType.RequestorCompany) {
      return findFeature('Ship.ViewPSCAdditionalDetails');
    } else {
      if (findFeature('Ship.EditPSC') || findFeature('Ship.AddPSC')) {
        return true;
      } else {
        return findFeature('Ship.ViewPSCAdditionalDetails');
      }
    }
  }, [companyType]);
  useEffect(() => {
    if (imoNumber) {
      getPSCInspections();
      IsCompanyTypeMars();
    }
  }, [imoNumber]);

  useEffect(() => {
    if (!showEditPSCModal) {
      setSelectedInspectionId('');
    }
  }, [showEditPSCModal]);

  useEffect(() => {
    if (isMarsUser) {
      gridRef.current?.instance.columnOption(9, 'visible', true);
      gridRef.current?.instance.columnOption(8, 'visible', false);
    } else {
      gridRef.current?.instance.columnOption(8, 'visible', showPSCStatus);
      gridRef.current?.instance.columnOption(9, 'visible', false);
    }
  }, [isMarsUser]);

  const cellPrepared = (e: any) => {
    if (e.rowType === 'data' && e.column.dataField === 'status') {
      e.cellElement.className =
        e.data.status === 'Closed'
          ? 'status-active-wtout'
          : e.data.status === 'AwaitingInfo'
          ? 'status-pending-wtout'
          : e.data.status === 'WrongEntry'
          ? 'status-wrong-entry-wtout'
          : 'status-inactive-wtout';
    }
    if (e.rowType === 'data' && e.column.dataField === 'marsStatus') {
      e.cellElement.className =
        e.data.marsStatus === 'Closed'
          ? 'status-active-wtout'
          : e.data.marsStatus === 'AwaitingInfo'
          ? 'status-pending-wtout'
          : e.data.marsStatus === 'WrongEntry'
          ? 'status-wrong-entry-wtout'
          : 'status-inactive-wtout';
    }
  };

  const pscDelete = () => {
    api
      .delete(
        { url: apiConfig.pscInspections + deletePSC?.pscInspectionId },
        setIsLoading
      )
      .then(() => {
        getPSCInspections();
        toast.success({ title: t('toast.deletedSuccessfully') });
      });
  };

  const gridConfig: IGridConfig = {
    ref: gridRef,
    cellPrepared: cellPrepared,
    class: 'm-c-grid m-l-grid-with--top-border',
    testId: 'managementRolesGrid',
    onClickAddButton: () => {
      setShowEditPSCModal(true);
    },
    rowClick: onClickRow,
    dataSource: inspections,
    defaultColumns: [
      {
        caption: t('labels.authority'),
        dataField: 'pscmou',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.portOfInspection'),
        dataField: 'port',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.dateOfInspection'),
        dataField: 'inspectionDate',
        dataType: 'date',
        minWidth: 150,
        format: 'dd MMM yyyy',
      },
      {
        caption: t('labels.typeOfInspection'),
        dataField: 'pscInspectionType',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.noOfDeficiencies'),
        dataField: 'noOfDeficiencies',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.detention'),
        dataField: 'isDetained',
        dataType: 'string',
        minWidth: 150,
        lookup: {
          dataSource: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          valueExpr: 'value',
          displayExpr: 'label',
        },
      },
      {
        caption: t('labels.durationDays'),
        dataField: 'duration',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.status'),
        dataField: 'status',
        dataType: 'string',
        minWidth: 150,
        lookup: {
          dataSource: pscInspectionStatus,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
        visible:
          companyType === CompanyType.RequestorCompany ? false : showPSCStatus,
      },
      {
        caption: t('labels.MARSStatus'),
        dataField: 'marsStatus',
        dataType: 'string',
        minWidth: 150,
        lookup: {
          dataSource: pscInspectionStatus,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
      },
      {
        caption: t('labels.source'),
        dataField: 'source',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.action'),
        dataField: 'stateOfPSCInspection',
        dataType: 'string',
        cellTemplate: 'actionTemplate',
        alignment: 'center',
        minWidth: 200,
        lookup: {
          dataSource: stateOfPSCInspection,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
      },
    ],
    showHeader: true,
    showExport: true,
    showAddButton: !(companyType === 'R') && findFeature('Ship.AddPSC'),
    excelFileName: vesselBannerDetails + '_PSC.xlsx',
  };

  const onAcceptPSC = (data: any) => {
    setShowConfirmAccept(true);
    setPSCInspectionId(data.pscInspectionId);
  };

  const onIgnorePSC = (data: any) => {
    setShowConfirmIgnore(true);
    setPSCInspectionId(data.pscInspectionId);
  };

  const acceptPSC = async () => {
    await api
      .put(
        {
          url: apiConfig.pscInspectionAccept + pscInspectionId,
          params: { IsIgnored: false },
        },
        setIsLoading
      )
      .then((res) => {
        if (res) {
          toast.success({ title: t('toast.pscAccepted') });
          getPSCInspections();
        }
      });
  };

  const ignorePSC = async () => {
    await api
      .put(
        {
          url: apiConfig.pscInspectionAccept + pscInspectionId,
          params: { IsIgnored: true },
        },
        setIsLoading
      )
      .then((res) => {
        if (res) {
          toast.success({ title: t('toast.pscIgnored') });
          getPSCInspections();
        }
      });
  };
  const onClickDelete = (data: IPSCInspection) => {
    setDeletePSC(data);
    setShowConfirmDelete(true);
  };

  const actionTemplate = (data: any) => {
    return (
      <div className="m-l-icon-btn-holder">
        {!data.data.stateOfPSCInspection &&
          !data.data.isMarsOrExternal &&
          isMarsUser && (
            <>
              <Button
                onClick={() => {
                  onAcceptPSC(data.data);
                }}
                className="app-c-btn app-c-btn--secondary"
              >
                {t('buttons.accept')}
              </Button>
              <Restricted permission="Ship.IgnorePsc">
                <Button
                  onClick={() => {
                    onIgnorePSC(data.data);
                  }}
                  className="app-c-btn app-c-btn--secondary"
                >
                  {t('buttons.ignore')}
                </Button>
              </Restricted>
            </>
          )}
        {data.data.stateOfPSCInspection && isMarsUser && (
          <div>{data.data.stateOfPSCInspection}</div>
        )}
        <Restricted permission={'Ship.DeletePSC'}>
          {isMarsUser
            ? data.data.isMarsOrExternal && (
                <div
                  className="m-l-grid-delete-icon-wrapper"
                  onClick={() => onClickDelete(data.data)}
                >
                  <Icons.Trash />
                </div>
              )
            : companyId == data.data.companyId && (
                <div
                  className="m-l-grid-delete-icon-wrapper"
                  onClick={() => onClickDelete(data.data)}
                >
                  <Icons.Trash />
                </div>
              )}
        </Restricted>
      </div>
    );
  };

  return (
    <>
      <Grid gridConfig={gridConfig} actionTemplate={actionTemplate} />
      {showEditPSCModal && (
        <EditPSCInspection
          imoNumber={imoNumber}
          pscInspectionId={selectedInspectionId}
          isVisible={showEditPSCModal}
          portDataSource={portDataSource}
          setIsVisible={(isVisible: boolean) => setShowEditPSCModal(isVisible)}
          getPSCInspections={getPSCInspections}
          isMarsUser={isMarsUser}
          companyId={companyId}
        />
      )}
      <ConfirmDialog
        dialogConfig={confirmDeleteDialogConfig}
        isOpen={showConfirmDelete}
      />
      <ConfirmDialog
        dialogConfig={confirmAcceptDialogConfig}
        isOpen={showConfirmAccept}
      />
      <ConfirmDialog
        dialogConfig={confirmIgnoreDialogConfig}
        isOpen={showConfirmIgnore}
      />
    </>
  );
}
