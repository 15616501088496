export enum SanctionsSettingType {
  SanctionedPortList = 'RestrictedPortList',
  SanctionedCountryList = 'SanctionedCountryList',
  SanctionedVesselList = 'SanctionedVesselList',
  SanctionedBareboatOwner = 'RestrictedBareboatOwner',
  SanctionedDOCCompany = 'RestrictedDOCCompany',
  SanctionedGroupOwner = 'RestrictedGroupOwner',
  SanctionedOperator = 'RestrictedOperator',
  SanctionedRegisteredOwner = 'RestrictedRegisteredOwner',
  SanctionedShipManager = 'RestrictedShipManager',
  SanctionedTechnicalManager = 'RestrictedTechnicalManager',
  ApprovedPortList = 'ApprovedPortList',
  ApprovedTerminalList = 'ApprovedTerminalList',
  ApprovedBerthList = 'ApprovedBerthList',
  RestrictedVesselList = 'RestrictedVesselList',
}

export enum EmailSettingType {
  Confidentiality = 'Confidentiality',
  RFIToClientSalutation = 'RFIToClientSalutation',
  RFIToVesselOwnerSalutation = 'RFIToVesselOwnerSalutation',
  RFIToClientSignature = 'RFIToClientSignature',
  RFIToVesselOwnerSignature = 'RFIToVesselOwnerSignature',
}

export enum SanctionEntity {
  Ship = 'Ship',
  Port = 'Ports',
  Terminal = 'Terminal',
  Berth = 'Berth',
  None = 'None',
  Companies = 'Companies',
  Countries = 'Countries',
}

export enum SanctionAttribute {
  ShipManagerCompanyCode = 'ShipManagerCompanyCode',
  TechnicalManagerCode = 'TechnicalManagerCode',
  FlagCode = 'FlagCode',
  BareboatCharterCompanyCode = 'BareboatCharterCompanyCode',
  DOCCompanyCode = 'DOCCompanyCode',
  OperatorCompanyCode = 'OperatorCompanyCode',
  GroupBeneficialOwnerCompanyCode = 'GroupBeneficialOwnerCompanyCode',
  RegisteredOwnerCode = 'RegisteredOwnerCode',
  IMONumber = 'IMONumber',
  IMODisplayName = 'imoDisplayName',
  PortName = 'PortName',
  PortCode = 'PortCode',
  TerminalName = 'TerminalCode',
  BerthName = 'BerthCode',
  None = 'None',
  CompanyName = 'CompanyName',
  CountryCode = 'CountryCode',
}

export enum BerthFitReportSettings {
  VesselDetails = 'VesselDetails',
  VetDetails = 'VetDetails',
  BerthExecutionDetails = 'BerthExecutionDetails',
  MARSDisclaimer = 'MARSDisclaimer',
  RequestorDisclaimer = 'RequestorDisclaimer',
  Rules = 'Rules',
  RequestorComments = 'RequestorComments',
  AppealStatusCommentsInBerth = 'AppealStatusComments',
  AppealStatusComments = 'AppealStatusComments',
  BerthFitComments = 'BerthFitComments',
}
