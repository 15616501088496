import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'devextreme-react/button';
import { formatDate } from '../../../utils/date';
import CountryList from '../../../components/Country.json';
import { useNavigate } from 'react-router-dom';
import { IVetRequest, IVetRequestForm } from '../../../types/vet-request';
import toast from '../../../utils/toast';
import { useTranslation } from 'react-i18next';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { VetRequestStatus } from '../../../enums/vet-request-status-enum';
import { IDialogConfig } from '../../../types/dialog';
import ConfirmDialog from '../../../components/common/confirm/ConfirmDialog';
import {
  getBuList,
  getCompanyName,
  getCompanyType,
  getEmployeeId,
  getLoginBUName,
} from '../../../utils/jwt-decode';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { IOptionvalue } from '../../../types/master-data';
import { Restricted, usePermission } from '../../../contexts/PermissionContext';
import icons from '../../../components/common/icons/icons';
import Tooltip from 'devextreme-react/tooltip';

export default function PreviewVetRequest(props: {
  vetRequestValues: any;
  previewVetRequest?: any;
  terminals: any;
  incoTerms: any;
  vetRequestDetails: any;
  emails?: any;
  getVetRequestDetails?: any;
  imoNumber?: string;
  vetRequestId?: string;
  cargoList: IOptionvalue[];
  tempVetId?: string;
  resetValues: any;
}) {
  const {
    vetRequestValues,
    previewVetRequest,
    terminals,
    incoTerms,
    vetRequestDetails,
    emails,
    getVetRequestDetails,
    cargoList,
    tempVetId,
    resetValues,
  } = props;
  const cargos = vetRequestValues.vetRequestCargos;
  const vetRequestLoadPorts = vetRequestValues.vetRequestLoadPorts;
  const vetRequestDischargePorts = vetRequestValues.vetRequestDischargePorts;
  const [loadPorts, setLoadPorts] = useState<any>([]);
  const [dischargePorts, setDischargePorts] = useState<any>([]);
  const [incoTerm, setIncoTerm] = useState<any>('');
  const requestedDate = formatDate(vetRequestValues.requestDate.toISOString());
  const { imoNumber, vetRequestId } = props;
  const currentlyLoggedInBuu = getLoginBUName();
  const employeeId = Number(getEmployeeId());
  const bu: any = getBuList();
  const buList = JSON.parse(bu);
  const filteredBU = buList
    ? buList.filter((bu: any) => bu.buName == currentlyLoggedInBuu)
    : 0;
  const { t } = useTranslation();
  const { setIsLoading } = useLoaderContext();
  const navigate = useNavigate();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] =
    useState(false);
  const companyType = getCompanyType();
  const { apiDateFormat } = useDateFormat();
  const [isAssignedToMars, setIsAssignedToMars] = useState(false);
  const { featureList, findFeature } = usePermission();

  const companyName = getCompanyName();

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      submitVetRequest();
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
      setIsAssignedToMars(false);
    },
  };

  const confirmSubmitDialogConfig: IDialogConfig = {
    id: 'confirmSubmit',
    content: t('toast.confirmSubmit'),
    handleSubmit: () => {
      submitVetRequest();
    },
    handleClose: () => {
      setIsConfirmSubmitModalOpen(false);
      setIsAssignedToMars(false);
    },
  };

  const onClickBack = () => {
    previewVetRequest();
  };

  const showBerthFit = useMemo(() => {
    return findFeature('BerthFitVet.BerthfitCheck');
  }, [featureList]);

  const contructLoadPorts = () => {
    const filteredLoadPorts = vetRequestLoadPorts.filter(
      (item: any) => item.loadPortId != null && item.loadPortId != ''
    );
    if (filteredLoadPorts.length > 0) {
      filteredLoadPorts.map((loadPort: any) => {
        loadPort.loadPortTerminals = [];
        if (loadPort.portDetails?.length > 0) {
          const filteredCountryCode = loadPort.portDetails.find(
            (portDetail: any) => portDetail.id === loadPort.loadPortId
          );
          loadPort.loadPortName = filteredCountryCode.portName;
          const portFlag = CountryList.find(
            (country: any) => country.iso_3 == filteredCountryCode.countryCode
          );
          if (portFlag) {
            loadPort.portFlag = portFlag.flag;
          } else {
            loadPort.portFlag = '';
          }
        }
        if (terminals && terminals.length > 0) {
          loadPort.vetRequestLoadPortTerminals.map((loadPortTerminal: any) => {
            const filteredLoadPortTerminal = terminals.find(
              (terminal: any) => terminal.terminalId == loadPortTerminal
            );
            if (filteredLoadPortTerminal) {
              loadPort.loadPortTerminals?.push(
                filteredLoadPortTerminal.terminalName
              );
            }
          });
        }
      });
      setLoadPorts(filteredLoadPorts);
    }
  };

  const constructDischargePorts = () => {
    const filteredDischargePorts = vetRequestDischargePorts.filter(
      (item: any) => item.dischargePortId != null && item.dischargePortId != ''
    );
    if (filteredDischargePorts.length > 0) {
      filteredDischargePorts.map((dischargePort: any) => {
        dischargePort.dischargePortTerminals = [];
        if (dischargePort.portDetails?.length > 0) {
          const filteredCountryCode = dischargePort.portDetails.find(
            (portDetail: any) => portDetail.id === dischargePort.dischargePortId
          );
          dischargePort.dischargePortName = filteredCountryCode.portName;
          const portFlag = CountryList.find(
            (country: any) => country.iso_3 == filteredCountryCode.countryCode
          );
          if (portFlag) {
            dischargePort.portFlag = portFlag.flag;
          } else {
            dischargePort.portFlag = '';
          }
        }
        if (terminals && terminals.length > 0) {
          dischargePort.vetRequestDischargePortTerminals.map(
            (dischargePortTerminal: any) => {
              const filteredDischargePortTerminal = terminals.find(
                (terminal: any) => terminal.terminalId == dischargePortTerminal
              );
              if (filteredDischargePortTerminal) {
                dischargePort.dischargePortTerminals?.push(
                  filteredDischargePortTerminal.terminalName
                );
              }
            }
          );
        }
      });
      setDischargePorts(filteredDischargePorts);
    }
  };

  const constructIncoTerm = () => {
    if (incoTerms && incoTerms.length > 0) {
      const incoTerm = incoTerms.find(
        (option: any) => option.optionValueCode == vetRequestValues.incoTerm
      );
      setIncoTerm(incoTerm.optionValueText);
    }
  };

  useEffect(() => {
    contructLoadPorts();
    constructDischargePorts();
    constructIncoTerm();
  }, [terminals, incoTerms]);

  const getVetRequestCargos = (formValue: IVetRequestForm) => {
    const cargos: any = [];
    let filteredCargo: any;
    const vetRequestCargos = formValue.vetRequestCargos;
    if (vetRequestCargos.length > 0) {
      vetRequestCargos.forEach((vetRequestCargo: any) => {
        if (vetRequestId) {
          filteredCargo = vetRequestDetails.vetRequestCargos.find(
            (vetCargo: any) => vetCargo.cargo == vetRequestCargo
          );
        }
        cargos.push({
          vetRequestCargoId: filteredCargo
            ? filteredCargo.vetRequestCargoId
            : 0,
          vetRequestId: vetRequestId ? vetRequestId : 0,
          cargo: vetRequestCargo,
        });
      });
    }
    return cargos;
  };

  const getVetRequestLoadPorts = (formValue: IVetRequestForm) => {
    const loadPorts: any = [];
    const vetRequestLoadPorts = formValue.vetRequestLoadPorts;
    if (vetRequestLoadPorts && vetRequestLoadPorts[0].loadPortId != null) {
      vetRequestLoadPorts.forEach((vetRequestLoadPort: any, index: any) => {
        loadPorts.push({
          vetRequestLoadPortId:
            vetRequestId && vetRequestLoadPort.vetRequestLoadPortId
              ? vetRequestLoadPort.vetRequestLoadPortId
              : 0,
          vetRequestId: vetRequestId ? vetRequestId : 0,
          loadPortId: vetRequestLoadPort.loadPortId,
          estimatedDateOfLoad:
            vetRequestLoadPort.estimatedDateOfLoad != '' &&
            vetRequestLoadPort.estimatedDateOfLoad != null
              ? apiDateFormat(vetRequestLoadPort.estimatedDateOfLoad)
              : null,
          displayOrder: index + 1,
          vetRequestLoadPortTerminals: [],
          berthFitCheck: showBerthFit
            ? vetRequestLoadPort.berthFitCheck
            : false,
        });
        if (vetRequestLoadPort.vetRequestLoadPortTerminals.length > 0) {
          vetRequestLoadPort.vetRequestLoadPortTerminals.forEach(
            (loadPortTerminal: any) => {
              let filteredLoadPortTerminal: any;
              if (vetRequestId) {
                const filteredLoadPort =
                  vetRequestDetails.vetRequestLoadPorts.find(
                    (item: any) =>
                      item.loadPortId == vetRequestLoadPort.loadPortId
                  );
                filteredLoadPortTerminal = filteredLoadPort
                  ? filteredLoadPort.vetRequestLoadPortTerminals.find(
                      (item: any) => item.terminalId == loadPortTerminal
                    )
                  : undefined;
              }
              loadPorts[index].vetRequestLoadPortTerminals.push({
                vetRequestLoadPortTerminalId: filteredLoadPortTerminal
                  ? filteredLoadPortTerminal.vetRequestLoadPortTerminalId
                  : 0,
                vetRequestLoadPortId: filteredLoadPortTerminal
                  ? filteredLoadPortTerminal.vetRequestLoadPortId
                  : 0,
                terminalId: loadPortTerminal,
              });
            }
          );
        }
      });
    }
    const filteredLoadPorts = loadPorts.filter(
      (item: any) => item.loadPortId !== null && item.loadPortId !== ''
    );
    return filteredLoadPorts;
  };

  const getVetRequestDischargePorts = (formValue: IVetRequestForm) => {
    const dischargePorts: any = [];
    const filteredVetRequestDischargePorts =
      formValue.vetRequestDischargePorts?.filter(
        (item: any) => item.dischargePortName
      );
    if (filteredVetRequestDischargePorts) {
      filteredVetRequestDischargePorts.forEach(
        (vetRequestDischargePort: any, index: any) => {
          dischargePorts.push({
            vetRequestDischargePortId:
              vetRequestId && vetRequestDischargePort.vetRequestDischargePortId
                ? vetRequestDischargePort.vetRequestDischargePortId
                : 0,
            vetRequestId: vetRequestId ? vetRequestId : 0,
            dischargePortId: vetRequestDischargePort.dischargePortId,
            estimatedDateOfDischarge:
              vetRequestDischargePort.estimatedDateOfDischarge != '' &&
              vetRequestDischargePort.estimatedDateOfDischarge != null
                ? apiDateFormat(
                    vetRequestDischargePort.estimatedDateOfDischarge
                  )
                : null,
            displayOrder: index + 1,
            vetRequestDischargePortTerminals: [],
            berthFitCheck: showBerthFit
              ? vetRequestDischargePort.berthFitCheck
              : false,
          });
          if (
            vetRequestDischargePort.vetRequestDischargePortTerminals.length > 0
          ) {
            vetRequestDischargePort.vetRequestDischargePortTerminals.forEach(
              (dischargePortTerminal: any) => {
                let filteredDischargePortTerminal: any;
                if (vetRequestId) {
                  const filteredDischargePort =
                    vetRequestDetails.vetRequestDischargePorts.find(
                      (item: any) =>
                        item.dischargePortId ==
                        vetRequestDischargePort.dischargePortId
                    );
                  filteredDischargePortTerminal = filteredDischargePort
                    ? filteredDischargePort.vetRequestDischargePortTerminals.find(
                        (item: any) => item.terminalId == dischargePortTerminal
                      )
                    : undefined;
                }
                dischargePorts[index].vetRequestDischargePortTerminals.push({
                  vetRequestDischargePortTerminalId:
                    filteredDischargePortTerminal
                      ? filteredDischargePortTerminal.vetRequestDischargePortTerminalId
                      : 0,
                  vetRequestDischargePortId: filteredDischargePortTerminal
                    ? filteredDischargePortTerminal.vetRequestDischargePortId
                    : 0,
                  terminalId: dischargePortTerminal,
                });
              }
            );
          }
        }
      );
    }
    const filteredDischargePorts = dischargePorts.filter(
      (item: any) =>
        item.dischargePortId !== null && item.dischargePortId !== ''
    );
    return filteredDischargePorts;
  };

  const constructVetRequestJson = (formValue: IVetRequestForm) => {
    return {
      vetRequestId: vetRequestId ? Number(vetRequestId) : 0,
      vetId: vetRequestId ? vetRequestDetails.vetId : '',
      imoNumber: imoNumber,
      requestDate: formValue.requestDate,
      requestorUserId: employeeId,
      buId: filteredBU[0].buId,
      emailIds: emails?.length > 0 ? emails.toString() : '',
      voyageNumber: formValue.voyageNumber,
      incoTerm: formValue.incoTerm,
      isAssignedToMars: isAssignedToMars,
      laycanFromDate:
        formValue.laycanFromDate != '' && formValue.laycanFromDate != null
          ? apiDateFormat(formValue.laycanFromDate)
          : null,
      laycanToDate:
        formValue.laycanToDate != '' && formValue.laycanToDate != null
          ? apiDateFormat(formValue.laycanToDate)
          : null,
      berthFitCheck: showBerthFit ? formValue.berthFitCheck : false,
      status: vetRequestId
        ? vetRequestDetails.status
        : VetRequestStatus.requested,
      reviewerId: null,
      vetRequestCargos: getVetRequestCargos(formValue),
      vetRequestLoadPorts: getVetRequestLoadPorts(formValue),
      vetRequestDischargePorts: getVetRequestDischargePorts(formValue),
      tempVetId: !vetRequestId ? tempVetId : null,
    };
  };

  const handleSubmit = (isAssignedToMars: boolean) => {
    vetRequestId
      ? setIsConfirmModalOpen(true)
      : setIsConfirmSubmitModalOpen(true);
    setIsAssignedToMars(isAssignedToMars);
  };

  const uscgVetInfo = async () => {
    await api.schedulerPost({ url: apiConfig.uscgVetInfo + imoNumber }).then();
  };

  const uscgDimensions = async () => {
    await api
      .schedulerPost({ url: apiConfig.uscgDimensions + imoNumber })
      .then();
  };

  const uscgPSC = async () => {
    await api.schedulerPost({ url: apiConfig.uscgPSC + imoNumber }).then();
  };

  const submitVetRequest = () => {
    const vetRequestFormValue: IVetRequestForm = vetRequestValues;
    const vetRequestJson: IVetRequest =
      constructVetRequestJson(vetRequestFormValue);
    if (!vetRequestId) {
      api
        .post(
          {
            url: apiConfig.vetRequests,
            data: vetRequestJson,
          },
          setIsLoading
        )
        .then((res) => {
          toast.custom({
            title: 'Vet ' + res.vetId + ' Created Successful',
          });
          resetValues();
          previewVetRequest();
          uscgVetInfo();
          uscgDimensions();
          uscgPSC();
          navigate('/vet-request/edit', {
            state: {
              newVetRequest: !vetRequestValues.vetRequestId
                ? 'newVetRequest'
                : '',
              imoNumber: res.imoNumber,
              vetRequestId: res.vetRequestId,
            },
          });
        });
    } else {
      api
        .put({ url: apiConfig.vetRequests, data: vetRequestJson }, setIsLoading)
        .then(() => {
          toast.custom({
            title: t('toast.updatedSuccessfully'),
            message: 'Vet Request details updated successfully',
          });
          resetValues();
          previewVetRequest();
          getVetRequestDetails();
        });
    }
  };

  const getCargoName = useCallback(
    (cargo) => {
      return cargoList.find((item) => item.optionValueCode === cargo)
        ?.optionValueText;
    },
    [cargoList]
  );

  return (
    <>
      <div className="m-l-form-panel-block m-l-card-form">
        <div className="m-l-form-panel-header-block">
          <div className="m-l-form-panel-header-left-block">
            <div className="m-l-page-small-heading">
              {t('labels.vetRequestPreview')}
            </div>
          </div>
          <div className="m-l-form-panel-header-right-block">
            <Button
              className="app-c-btn app-c-btn--secondary"
              onClick={onClickBack}
            >
              {t('buttons.back')}
            </Button>
            {((vetRequestDetails?.reviewer?.employeeId == employeeId &&
              (vetRequestDetails?.status == VetRequestStatus.processing ||
                vetRequestDetails?.status == VetRequestStatus.assigned)) ||
              vetRequestDetails?.status == VetRequestStatus.requested ||
              !vetRequestId) && (
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                onClick={() =>
                  handleSubmit(
                    companyType == 'R' || companyType == 'M' ? true : false
                  )
                }
              >
                {!vetRequestId ? t('buttons.submit') : t('buttons.save')}
              </Button>
            )}
            {companyType == 'H' && !vetRequestId && (
              <Restricted permission="VetRequest.SubmitToMARS">
                <Button
                  className="app-c-btn app-c-btn--secondary"
                  onClick={() => handleSubmit(true)}
                >
                  {t('buttons.submitToMARS')}
                </Button>
              </Restricted>
            )}
          </div>
        </div>

        <div className="m-l-form-panel-body-block">
          <div className="row m-l-vt-rqst-prvw-row">
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="m-l-prvw-container">
                <div className="mb-btm-space-24 m-c-form-group">
                  <label className="m-l-input-label">{t('labels.date')}</label>
                  <div className="m-l-input-data-preview">{requestedDate}</div>
                </div>
                <div className="mb-btm-space-24 m-c-form-group">
                  <label className="m-l-input-label">
                    {t('labels.requestorName')}
                  </label>
                  <div className="m-l-input-data-preview">
                    {vetRequestValues.requestorUserId}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className="m-c-form-group">
                      <label className="m-l-input-label">
                        {t('labels.laycanFromDate')}
                      </label>
                      {vetRequestValues.laycanFromDate != '' &&
                        vetRequestValues.laycanFromDate != null && (
                          <div className="m-l-input-data-preview">
                            {formatDate(
                              new Date(
                                vetRequestValues.laycanFromDate
                              ).toISOString()
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className="m-c-form-group">
                      <label className="m-l-input-label">
                        {t('labels.toDate')}
                      </label>
                      {vetRequestValues.laycanToDate != '' &&
                        vetRequestValues.laycanToDate != null && (
                          <div className="m-l-input-data-preview">
                            {formatDate(
                              new Date(
                                vetRequestValues.laycanToDate
                              ).toISOString()
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="m-l-prvw-container">
                <div className="mb-btm-space-24 m-c-form-group">
                  <label className="m-l-input-label">
                    {t('labels.businessUnit')}
                  </label>
                  <div className="m-l-input-data-preview">
                    {vetRequestValues.buId}
                  </div>
                </div>
                <div className="mb-btm-space-24 m-c-form-group">
                  <label className="m-l-input-label">
                    {t('labels.companyName')}
                  </label>
                  <div className="m-l-input-data-preview">
                    {vetRequestDetails
                      ? vetRequestDetails?.requestorUser.company.companyName
                      : companyName}
                  </div>
                </div>
                <div className="m-c-form-group">
                  <label className="m-l-input-label">
                    {t('labels.incoTerms')}
                  </label>
                  <div className="m-l-input-data-preview">{incoTerm}</div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="m-l-prvw-container">
                <div className="mb-btm-space-24 m-c-form-group">
                  <label className="m-l-input-label">
                    {t('labels.voyageNumber')}
                  </label>
                  <div className="m-l-input-data-preview">
                    {vetRequestValues.voyageNumber}
                  </div>
                </div>
                <div className="mb-btm-space-24 m-c-form-group">
                  <label className="m-l-input-label">
                    {t('labels.checkBerthFit')}
                  </label>
                  <div className="m-l-input-data-preview">
                    {vetRequestValues.berthFitCheck && showBerthFit
                      ? t('labels.yes')
                      : t('labels.no')}
                  </div>
                </div>
                <div className="mb-btm-space-24 m-c-form-group">
                  <label className="m-l-input-label">{t('labels.cargo')}</label>
                  {cargos.length != 0 &&
                    cargos.map((cargo: any, index: any) => {
                      return (
                        <div
                          key={index}
                          className="m-l-input-data-preview mb-8"
                        >
                          {getCargoName(cargo)}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="m-l-prvw-container">
                <div className="mb-btm-space-24 m-c-form-group">
                  <label className="m-l-input-label">
                    {t('labels.emailId')}
                  </label>
                  <div className="m-l-input-data-preview mb-8">
                    {emails.length != 0 &&
                      emails.map((emailId: any, index: any) => {
                        return <div key={index}>{emailId}</div>;
                      })}
                  </div>
                </div>
                {/* <div className="m-l-upld-fl-wrapper">
                  <div className="m-l-atch-icon-holder">
                    <Icons.Attach />
                  </div>
                  <div className="m-l-upld-fl-holder">
                    <div className="m-l-fl-wrapper">PSC.PDF</div>
                    <div className="m-l-fl-wrapper">PSC.PDF</div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m-l-ldprt-dischrg-row">
        <div className="row m-l-custom-eight-space-row">
          {loadPorts.length > 0 && (
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 m-l-custom-eight-space-col">
              <div className="m-l-ldprt-part">
                <div className="m-l-form-panel-block m-l-card-form">
                  <div className="m-l-form-panel-header-block">
                    <div className="m-l-form-panel-header-left-block">
                      <div className="m-l-page-small-heading">
                        {t('labels.loadPort')}
                      </div>
                    </div>
                  </div>

                  <div className="m-l-form-panel-body-block m-l-preview-fm">
                    {loadPorts?.map((loadPort: any, index: any) => {
                      return (
                        <div key={index}>
                          {index != 0 && <hr className="m-c-divider" />}
                          <div className="row">
                            <div className="col-xl col-lg col-md-6 col-sm-6 col-xs-12">
                              <div className="m-c-form-group">
                                <label className="m-l-input-label">
                                  {t('labels.port')} {index + 1}
                                </label>
                                {loadPort.portWarning?.warning && (
                                  <>
                                    <span
                                      className="m-l-port-warning"
                                      id={'loadport' + index}
                                    >
                                      <icons.PortWarning />
                                    </span>
                                    <Tooltip
                                      target={`#${'loadport' + index}`}
                                      showEvent="dxhoverstart"
                                      hideEvent="dxhoverend"
                                      position="right"
                                      contentRender={() => {
                                        return (
                                          <div className="m-l-tooltip-wrapper m-l-warning-icon-tooltip">
                                            <span>
                                              {
                                                loadPort.portWarning
                                                  ?.restrictedMessage
                                              }
                                            </span>
                                          </div>
                                        );
                                      }}
                                    />
                                  </>
                                )}
                                <div className="m-l-page-sub-heading mtop-4">
                                  {loadPort.portName}
                                </div>
                                {/* {loadPort.portFlag != '' && (
                                  <div className="m-l-flag-icon">
                                    {' '}
                                    <img
                                      src={loadPort.portFlag}
                                      className="img-responsive"
                                      alt={loadPort.loadPortName}
                                    />
                                  </div>
                                )} */}
                              </div>
                            </div>
                            <div className="col-xl col-lg col-md-6 col-sm-6 col-xs-12">
                              <div className="m-c-form-group">
                                <label className="m-l-input-label">
                                  {t('labels.checkBerthFit')}
                                </label>
                                <div className="m-l-page-sub-heading mtop-4">
                                  {loadPort.berthFitCheck && showBerthFit
                                    ? t('labels.yes')
                                    : t('labels.no')}
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                              <div className="m-c-form-group">
                                <label className="m-l-input-label">
                                  {t('labels.terminals')}
                                </label>
                                {loadPort.loadPortTerminals?.length != 0 && (
                                  <div className="m-l-input-data-preview mtop-4">
                                    {loadPort.loadPortTerminals?.join(', ')}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-xl col-lg col-md-6 col-sm-6 col-xs-12">
                              <div className="m-c-form-group">
                                <label className="m-l-input-label">
                                  {t('labels.estimatedDateOfLoad')}
                                </label>
                                {loadPort.estimatedDateOfLoad != '' &&
                                  loadPort.estimatedDateOfLoad != null && (
                                    <div className="m-l-input-data-preview mtop-4">
                                      {formatDate(
                                        new Date(
                                          loadPort.estimatedDateOfLoad
                                        ).toISOString()
                                      )}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
          {dischargePorts.length > 0 && (
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 m-l-custom-eight-space-col">
              <div className="m-l-ldprt-part">
                <div className="m-l-form-panel-block m-l-card-form">
                  <div className="m-l-form-panel-header-block">
                    <div className="m-l-form-panel-header-left-block">
                      <div className="m-l-page-small-heading">
                        {t('labels.dischargePort')}
                      </div>
                    </div>
                  </div>

                  <div className="m-l-form-panel-body-block m-l-preview-fm">
                    {dischargePorts?.map((dischargePort: any, index: any) => {
                      return (
                        <div key={index}>
                          {index != 0 && <hr className="m-c-divider" />}
                          <div className="row">
                            <div className="col-xl col-lg col-md-6 col-sm-6 col-xs-12">
                              <div className="m-c-form-group">
                                <label className="m-l-input-label">
                                  {t('labels.port')} {index + 1}
                                </label>
                                {dischargePort.portWarning?.warning && (
                                  <>
                                    <span
                                      className="m-l-port-warning"
                                      id={'dischargeport' + index}
                                    >
                                      <icons.PortWarning />
                                    </span>
                                    <Tooltip
                                      target={`#${'dischargeport' + index}`}
                                      showEvent="dxhoverstart"
                                      hideEvent="dxhoverend"
                                      position="right"
                                      contentRender={() => {
                                        return (
                                          <div className="m-l-tooltip-wrapper m-l-warning-icon-tooltip">
                                            <span>
                                              {
                                                dischargePort.portWarning
                                                  ?.restrictedMessage
                                              }
                                            </span>
                                          </div>
                                        );
                                      }}
                                    />
                                  </>
                                )}
                                <div className="m-l-page-sub-heading mtop-4">
                                  {dischargePort.portName
                                    ? dischargePort.portName
                                    : dischargePort.dischargePortName}
                                </div>
                                {/* {dischargePort.portFlag != '' && (
                                  <div className="m-l-flag-icon">
                                    {' '}
                                    <img
                                      src={dischargePort.portFlag}
                                      className="img-responsive"
                                      alt={dischargePort.dischargePortName}
                                    />
                                  </div>
                                )} */}
                              </div>
                            </div>
                            <div className="col-xl col-lg col-md-6 col-sm-6 col-xs-12">
                              <div className="m-c-form-group">
                                <label className="m-l-input-label">
                                  {t('labels.checkBerthFit')}
                                </label>
                                <div className="m-l-page-sub-heading mtop-4">
                                  {dischargePort.berthFitCheck && showBerthFit
                                    ? t('labels.yes')
                                    : t('labels.no')}
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                              <div className="m-c-form-group">
                                <label className="m-l-input-label">
                                  {t('labels.terminals')}
                                </label>
                                {dischargePort.dischargePortTerminals?.length !=
                                  0 && (
                                  <div className="m-l-input-data-preview mtop-4">
                                    {dischargePort.dischargePortTerminals?.join(
                                      ', '
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-xl col-lg col-md-6 col-sm-6 col-xs-12">
                              <div className="m-c-form-group">
                                <label className="m-l-input-label">
                                  {t('labels.estimatedDateOfDischarge')}
                                </label>
                                {dischargePort.estimatedDateOfDischarge != '' &&
                                  dischargePort.estimatedDateOfDischarge !=
                                    null && (
                                    <div className="m-l-input-data-preview mtop-4">
                                      {formatDate(
                                        new Date(
                                          dischargePort.estimatedDateOfDischarge
                                        ).toISOString()
                                      )}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={isConfirmModalOpen}
      />
      <ConfirmDialog
        dialogConfig={confirmSubmitDialogConfig}
        isOpen={isConfirmSubmitModalOpen}
      />
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <Button
                className="app-c-btn app-c-btn--secondary"
                onClick={onClickBack}
              >
                {t('buttons.back')}
              </Button>
              {(vetRequestDetails?.status == VetRequestStatus.requested ||
                !vetRequestId) && (
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width"
                  onClick={() =>
                    handleSubmit(
                      companyType == 'R' || companyType == 'M' ? true : false
                    )
                  }
                >
                  {!vetRequestId ? t('buttons.submit') : t('buttons.save')}
                </Button>
              )}
              {companyType == 'H' && !vetRequestId && (
                <Restricted permission="VetRequest.SubmitToMARS">
                  <Button
                    className="app-c-btn app-c-btn--secondary"
                    onClick={() => handleSubmit(true)}
                  >
                    {t('buttons.submitToMARS')}
                  </Button>
                </Restricted>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
