import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IFormConfig } from '../../../types/form-builder';
import FormBuilder from '../../common/form-builder/FormBuilder';
import CountryList from '../../Country.json';
import { useTranslation } from 'react-i18next';
import { ICountry, IOptionvalue } from '../../../types/master-data';
import { blackListedEmails, ICompany } from '../../../types/requestor-company';
import TextBox from 'devextreme-react/text-box';
import { IUserList } from '../../../types/user';
import { CompanyStatus } from '../../../enums/status-enum';
import icons from '../../common/icons/icons';
import AddAttachment from '../../vessel-info/attachments/add-attachment/AddAttachment';
import FileUploaderModal from '../../common/file-uploader-modal/FileUploaderModal';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { getCompanyId, getCompanyType } from '../../../utils/jwt-decode';
import Button from 'devextreme-react/button';
import { getAPIRoute } from '../../../utils/api-route';
import { IDialogConfig } from '../../../types/dialog';
import ConfirmDialog from '../../common/confirm/ConfirmDialog';
import toast from '../../../utils/toast';
import { Restricted } from '../../../contexts/PermissionContext';

export default function CompanyInfoForm(props: {
  companyStatusList: IOptionvalue[];
  personNameTitles: IOptionvalue[];
  countries: ICountry[];
  companyDetails?: ICompany;
  marsAccountManagers: IUserList[];
  getCompanyDetails?: any;
}) {
  const {
    companyStatusList,
    personNameTitles,
    countries,
    companyDetails,
    marsAccountManagers,
    getCompanyDetails,
  } = props;
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const countryList = CountryList.sort();
  const [isVisible, setIsVisible] = useState(false);
  const [isFromCompanyInfo, setIsFromCompanyInfo] = useState(false);
  const [showUploadLogo, setShowUploadLogo] = useState(false);
  const [image, setImage] = useState<any>([]);
  const companyId = getCompanyId();
  const companyType = getCompanyType();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const distinctCallingCodes = countryList
    .map((item) => item.callingCode)
    .filter((value, index, self) => self.indexOf(value) === index);

  const callingCodes: any = [];
  distinctCallingCodes.forEach((callingCode) =>
    callingCodes.push({
      callingCode: callingCode,
    })
  );

  const handleCancelClick = () => {
    setIsVisible(false);
    setIsFromCompanyInfo(true);
  };

  const onAddClick = () => {
    setIsFromCompanyInfo(true);
    setIsVisible(true);
  };

  useEffect(() => {
    if (companyDetails?.companyId == companyId || companyType === 'M') {
      setShowUploadLogo(true);
    } else {
      setShowUploadLogo(false);
    }
  }, [companyDetails]);

  const onChangeCountry = (e: any) => {
    const country = countries.find(
      (country: ICountry) => country.countryId == e.value
    );
    const filteredCountryList = countryList.find(
      (callingCode) => callingCode.iso_3 == country?.iso3
    );
    if (filteredCountryList) {
      setValue('callingCode', filteredCountryList.callingCode);
    }
  };

  const confirmDeleteDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmDeleteCompanyLogo'),
    handleSubmit: () => {
      setShowConfirmDelete(false);
      deleteLogo();
    },
    handleClose: () => {
      setShowConfirmDelete(false);
    },
  };

  const deleteLogo = async () => {
    const data: any = new FormData();
    data.append('file', null);
    await api
      .put({
        url: getAPIRoute('companyLogo', [companyDetails?.companyId]),
        data: data,
      })
      .then((res) => {
        toast.success({
          title: t('toast.deletedLogoSuccessfully'),
        });
        getCompanyDetails();
      });
  };

  const onClickCloseIcon = async (e: any) => {
    setShowConfirmDelete(true);
  };

  const formConfig: IFormConfig[] = [
    {
      name: 'companyName',
      label: t('labels.companyName'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      name: 'abbreviation',
      onKeyUp: (e: any, field: any) => {
        e.event.target.value = e.event.target.value.toUpperCase();
      },
      label: t('labels.abbreviation'),
      control: control,
      fieldType: 'text',
      maxLength: 3,
      isRequired: true,
      disabled: !!companyDetails,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
        {
          type: 'stringLength',
          message: t('errors.abbreviationLength'),
          min: 3,
        },
      ],
    },
    {
      name: 'companyEmail',
      label: t('labels.companyEmail'),
      control: control,
      fieldType: 'text',
      isRequired: true,
      maxLength: 50,
      disabled:
        !!companyDetails &&
        companyDetails.companyStatus !== CompanyStatus.draft,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
        {
          type: 'email',
          message: t('errors.inCorrectEmailFormat'),
        },
        {
          type: 'custom',
          validationCallback: (params: any) => {
            const regex = new RegExp(blackListedEmails.join('|'));
            return !regex.test(params.value.toLowerCase());
          },
          message: t('errors.publicDomainEmailNotAllowed'),
        },
      ],
    },
    {
      name: 'countryId',
      label: t('labels.country'),
      control: control,
      fieldType: 'select',
      dataSource: countries,
      displayExpr: 'countryName',
      valueExpr: 'countryId',
      onChange: onChangeCountry,
      autocomplete: 'new-user',
    },
    {
      name: 'legalEntityNumber',
      label: t('labels.legalEntityNumber'),
      control: control,
      fieldType: 'text',
      maxLength: 24,
    },
    {
      name: 'addressLine1',
      label: t('labels.addressLine1'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
    },
    {
      name: 'addressLine2',
      label: t('labels.addressLine2'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
    },
    {
      name: 'phoneNumber',
      label: t('labels.phoneNumber'),
      control: control,
      fieldType: 'customSelect',
      dataSource: callingCodes,
      displayExpr: 'callingCode',
      valueExpr: 'callingCode',
      selectBoxName: 'callingCode',
      maxLength: 20,
      customSelectInputFieldType: 'number',
      itemRender: renderItem,
      autocomplete: 'new-user',
    },
    {
      name: 'state',
      label: t('labels.state'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
    },
    {
      name: 'zipCode',
      label: t('labels.zipCode'),
      control: control,
      fieldType: 'text',
      maxLength: 24,
    },
    {
      name: 'companyStatus',
      label: t('labels.status'),
      control: control,
      fieldType: 'select',
      disableSearch: true,
      displayExpr: 'optionValueText',
      defaultValue: CompanyStatus.draft,
      valueExpr: 'optionValueCode',
      dataSource: companyStatusList,
      disabled:
        !companyDetails || companyDetails.companyStatus === CompanyStatus.draft,
      fieldRender: companyStatusList.length ? statusFieldRender : undefined,
    },
    {
      name: 'marsAccountManager',
      label: t('labels.marsAccountManager'),
      control: control,
      fieldType: 'select',
      displayExpr: 'employeeName',
      valueExpr: 'employeeId',
      dataSource: marsAccountManagers,
      isRequired: true,
      maxLength: 18,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      name: 'companyType',
      label: t('labels.hostedClient'),
      control: control,
      fieldType: 'checkbox',
      isHidden: companyDetails?.companyType === 'M',
      disabled:
        companyDetails && companyDetails?.companyStatus !== CompanyStatus.draft,
    },
  ];

  const personForm: IFormConfig[] = [
    {
      name: 'name',
      label: t('labels.name'),
      control: control,
      fieldType: 'customSelect',
      dataSource: personNameTitles,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      selectBoxName: 'titleId',
      maxLength: 100,
      autocomplete: 'new-user',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
        {
          type: 'pattern',
          pattern: '^([a-zA-Z_!@#$%^&*.-]+[ ]*)*$',
          message: t('errors.nameFormat'),
        },
      ],
    },
    {
      name: 'email',
      label: t('labels.email'),
      control: control,
      fieldType: 'text',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
        {
          type: 'email',
          message: t('errors.inCorrectEmailFormat'),
        },
        {
          type: 'custom',
          validationCallback: (params: any) => {
            const regex = new RegExp(blackListedEmails.join('|'));
            return !regex.test(params.value.toLowerCase());
          },
          message: t('errors.publicDomainEmailNotAllowed'),
        },
      ],
    },
    {
      name: 'phone',
      label: t('labels.phoneNumber'),
      control: control,
      fieldType: 'customSelect',
      dataSource: callingCodes,
      displayExpr: 'callingCode',
      valueExpr: 'callingCode',
      selectBoxName: 'contactCallingCode',
      customSelectInputFieldType: 'number',
      maxLength: 20,
      itemRender: renderItem,
      autocomplete: 'new-user',
    },
  ];
  return (
    <form>
      <div className="m-l-form-panel-body-block">
        <div className="row">
          <FormBuilder formConfig={formConfig} />
          <div className="col-md-3 col-sm-6 col-lg-3 col-xs-12">
            {showUploadLogo && companyDetails && (
              <Restricted permission="Company.UploadCompanyLogo">
                <div className="m-l-upld-fl-wrapper m-l-upld__birth-fit">
                  <div className="m-l-atch-icon-holder">
                    <icons.Attach />
                    <a className="m-l-add-atch-txt" onClick={onAddClick}>
                      {t('labels.uploadCompanyLogo')}
                    </a>
                  </div>
                </div>
              </Restricted>
            )}
          </div>
          <div className="col-md-3 col-sm-6 col-lg-3 col-xs-12">
            {companyDetails?.logo && (
              <div className="m-l-profile__image">
                {showUploadLogo && (
                  <Restricted permission="Company.RemoveCompanyLogo">
                    <Button
                      className="app-c-icon-only-btn"
                      onClick={(e) => onClickCloseIcon(e)}
                    >
                      <icons.MinusIcon />
                    </Button>
                  </Restricted>
                )}
                <img
                  src={companyDetails?.logo}
                  className="img-fluid"
                  alt=""
                ></img>
              </div>
            )}
          </div>
        </div>
        {/* {showUploadLogo && (
          <Restricted permission="Company.UploadCompanyLogo">
            <div className="m-l-upld-fl-wrapper m-l-upld__birth-fit">
              <div className="m-l-atch-icon-holder">
                <icons.Attach />
                <a className="m-l-add-atch-txt" onClick={onAddClick}>
                  {t('labels.uploadCompanyLogo')}
                </a>
              </div>
            </div>
          </Restricted>
        )} */}

        {/* {companyDetails?.logo && (
          <div className="m-l-us-databox-header">
            {showUploadLogo && (
              <Restricted permission="Company.RemoveCompanyLogo">
                <Button
                  className="m-l-us-close-btn"
                  onClick={(e) => onClickCloseIcon(e)}
                >
                  <icons.MinusIcon />
                </Button>
              </Restricted>
            )}
            <img
              src={companyDetails?.logo}
              className="img-responsive"
              alt=""
              width="200"
              height="200"
            ></img>
          </div>
        )} */}
        {isVisible && (
          <FileUploaderModal
            isVisible={isVisible}
            handleCancelClick={handleCancelClick}
            isFromCompanyInfo={isFromCompanyInfo}
            getCompanyDetails={getCompanyDetails}
            companyIdLogo={companyDetails?.companyId}
          />
        )}
        <div className="row m-l-head-tab-bottom-section">
          <div className="m-l-contact-info-block">
            <div className="m-l-page-sub-heading">
              {t('headers.contactPersonInformation')}
            </div>
          </div>
          <FormBuilder formConfig={personForm} />
        </div>
      </div>
      <ConfirmDialog
        dialogConfig={confirmDeleteDialogConfig}
        isOpen={showConfirmDelete}
      />
    </form>
  );
}

const renderItem = (data: any) => {
  return (
    <div>
      <div style={{ display: 'inline-block', textAlign: 'left' }}>
        {data?.callingCode}
      </div>
    </div>
  );
};

const statusFieldRender = (data: any) => {
  return (
    <TextBox
      className={
        data?.optionValueCode === CompanyStatus.draft
          ? 'draft-lbl'
          : data?.optionValueCode === CompanyStatus.active
          ? 'active-lbl'
          : 'inactive-lbl'
      }
      defaultValue={data && data.optionValueText}
      readOnly={true}
    />
  );
};
