import TabPanel from 'devextreme-react/tab-panel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import icons from '../../../components/common/icons/icons';
import imageURL from '../../../assets/images';
import ScrollView from 'devextreme-react/scroll-view';
import { useDateFormat } from '../../../hooks/useDateFormat';
import Outbox from './outbox/Outbox';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { getAPIRoute } from '../../../utils/api-route';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import Button from 'devextreme-react/button';
import RfiTemplate from '../../common/rfi-template/RfiTemplate';
import Inbox from './inbox/Inbox';
import { useLocation } from 'react-router-dom';
import { EmailSort } from '../../../enums/email-sort-enum';
import ResizingPane from 'react-resizing-pane';
import { useTranslation } from 'react-i18next';
import FileSaver from 'file-saver';
import { Restricted, usePermission } from '../../../contexts/PermissionContext';

export default function Email(props: { vetRequestId?: string }) {
  const { vetRequestId } = props;
  const [outboxEmailList, setOutboxEmailList] = useState<any[]>([]);
  const [inboxEmailList, setInboxEmailList] = useState<any[]>([]);
  const { findFeature } = usePermission();

  const items = useMemo(() => {
    return [
      {
        title: 'Inbox',
        visible: findFeature('Email.ViewInbox'),
      },
      {
        title: 'Outbox',
        visible: findFeature('Email.ViewOutbox'),
      },
    ];
  }, [outboxEmailList, inboxEmailList]);
  const tabRef = useRef<TabPanel>(null);
  const [showMailItem, setShowMailItem] = React.useState(false);
  const [showRfiModal, setShowRfiModal] = useState(false);
  const [mailDetail, setMailDetail] = useState<any>();
  const [selectedTab, setSelectedTab] = useState<number | undefined>(0);
  const [replyContent, setReplyContent] = useState<any>('');
  const { apiDateAndTimeFormat } = useDateFormat();
  const [inboxSortBy, setInboxSortBy] = useState(EmailSort.newestOnTop);
  const [outboxSortBy, setOutboxSortBy] = useState(EmailSort.newestOnTop);
  const [searchValue, setSearchValue] = useState<string>('');
  const [inboxSearchValue, setInboxSearchValue] = useState<string>('');
  const location = useLocation();
  const { t } = useTranslation();
  const onTabSelectionChanged = () => {
    if (selectedTab != tabRef.current?.instance.option('selectedIndex')) {
      setMailDetail('');
      setSelectedTab(tabRef.current?.instance.option('selectedIndex'));
    }
  };

  const itemRender = (e: any) => {
    if (e.title === items[0].title) {
      return selectedTab === 0 ? (
        <Inbox
          vetRequestId={vetRequestId}
          inboxList={inboxEmailList}
          setMailDetail={setMailDetail}
          setShowMailItem={setShowMailItem}
          setInboxEmailList={setInboxEmailList}
          sortBy={inboxSortBy}
          setSortBy={setInboxSortBy}
          inboxSearchValue={inboxSearchValue}
          setInboxSearchValue={setInboxSearchValue}
          showMailItem={showMailItem}
          location={location}
        />
      ) : null;
    } else if (e.title === items[1].title) {
      return selectedTab === 1 ? (
        <Outbox
          vetRequestId={vetRequestId}
          outboxList={outboxEmailList}
          setMailDetail={setMailDetail}
          setShowMailItem={setShowMailItem}
          setOutboxEmailList={setOutboxEmailList}
          sortBy={outboxSortBy}
          setSortBy={setOutboxSortBy}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          showMailItem={showMailItem}
          location={location}
        />
      ) : null;
    }
  };

  const getVetRequestInboxMailList = async () => {
    await api
      .get({ url: getAPIRoute('vetInboxEmailList', [vetRequestId]) })
      .then((data) => {
        if (data && data.length > 0) {
          const inboxListCopy = data.slice();
          setInboxEmailList(
            inboxListCopy.sort((a: any, b: any) =>
              b.receivedDate?.valueOf().localeCompare(a.receivedDate?.valueOf())
            )
          );
          setShowMailItem(false);
        }
      });
  };

  const getVetRequestOutboxMailList = async () => {
    await api
      .get({ url: getAPIRoute('vetOutboxEmailList', [vetRequestId]) })
      .then((data) => {
        if (data && data.length > 0) {
          const outboxListCopy = data.slice();
          setOutboxEmailList(
            outboxListCopy.sort((a: any, b: any) =>
              b.sendDate?.valueOf().localeCompare(a.sendDate?.valueOf())
            )
          );
          setShowMailItem(false);
        }
      });
  };

  const getInboxMailList = async () => {
    await api.get({ url: apiConfig.inboxEmailList }).then((data) => {
      if (data && data.length > 0) {
        const inboxListCopy = data.slice();
        setInboxEmailList(
          inboxListCopy.sort((a: any, b: any) =>
            b.receivedDate?.valueOf().localeCompare(a.receivedDate?.valueOf())
          )
        );
        setShowMailItem(false);
      }
    });
  };

  const getOutboxMailList = async () => {
    await api.get({ url: apiConfig.outboxEmailList }).then((data) => {
      if (data && data.length > 0) {
        const outboxListCopy = data.slice();
        setOutboxEmailList(
          outboxListCopy.sort((a: any, b: any) =>
            b.sendDate?.valueOf().localeCompare(a.sendDate?.valueOf())
          )
        );
        setShowMailItem(false);
      }
    });
  };

  useEffect(() => {
    if (location.pathname != '/email') {
      getVetRequestInboxMailList();
      getVetRequestOutboxMailList();
    } else {
      getInboxMailList();
      getOutboxMailList();
    }
  }, []);

  const onClickReply = (email: any) => {
    const mailContents = { ...email };
    if (selectedTab === 0) {
      mailContents.replyInbox = true;
    }
    mailContents.vetRequestId = email.vetRequestId;
    mailContents.sendDate = apiDateAndTimeFormat(email.sendDate);
    setReplyContent(mailContents);
    setShowRfiModal(true);
  };

  const clickBack = () => {
    setShowMailItem(false);
  };

  const downloadAttachment = (file: any) => {
    if (file?.attachmentId) {
      api
        .get({
          url: apiConfig.deleteEditAttachment + file.attachmentId,
          responseType: 'blob',
        })
        .then((res) => {
          FileSaver.saveAs(new Blob([res]), file.fileName);
        });
    }
  };

  const getMailList = useCallback(() => {
    if (location.pathname != '/email') {
      selectedTab === 0
        ? getVetRequestInboxMailList()
        : getVetRequestOutboxMailList();
    } else {
      selectedTab === 0 ? getInboxMailList() : getOutboxMailList();
    }
  }, [selectedTab]);

  const attachmentType = (type: any) => {
    const value = type.toUpperCase();
    if (value == '.PNG') {
      return imageURL.png;
    } else if (value == '.PDF') {
      return imageURL.pdf;
    } else if (value == '.DOC') {
      return imageURL.doc;
    } else if (value == '.XLS') {
      return imageURL.xls;
    } else if (value == '.JPEG') {
      return imageURL.jpeg;
    } else if (value == '.JPG') {
      imageURL.jpg;
    } else if (value == '.DOCX') {
      return imageURL.docx;
    } else if (value == '.XLSX') {
      return imageURL.xlsx;
    }
  };

  return (
    <div
      className={
        location.pathname != '/email' ? '' : 'inner-wrapper page-inner-wrap'
      }
    >
      {location.pathname == '/email' && (
        <div className="m-l-inner-page-header">
          <div className="m-l-inner-page-header-left-block">
            <div className="m-l-page-main-heading">
              <div>Email</div>
            </div>
          </div>
        </div>
      )}
      <div
        className={
          location.pathname != '/email'
            ? 'm-l-mail-wrapper'
            : 'm-l-mail-wrapper m-l-mail-wrapper--bg-white'
        }
      >
        <ResizingPane
          className="resize-pane"
          storageId={0}
          sides={['right']}
          width={426}
          height={undefined}
          style={{ border: '0px solid rgb(229, 229, 229)' }}
        >
          <div
            className={
              'm-l-mail-left-holder ' +
              (showMailItem ? 'm-l-mail__hide-mob' : '')
            }
          >
            <TabPanel
              ref={tabRef}
              items={items}
              className="m-c-tab-block m-c-tab-block--full-width"
              onSelectionChanged={onTabSelectionChanged}
              itemRender={itemRender}
            />
          </div>
        </ResizingPane>
        <div
          className={
            'm-l-mail-right-holder ' +
            (showMailItem ? '' : 'm-l-mail__hide-mob')
          }
        >
          <ScrollView width="100%" height="100%">
            {showMailItem ? (
              <div className="m-l-mail-main-data-part">
                <div className="m-l-back-btn-mob">
                  <Button
                    className="app-c-btn app-c-btn--secondary min-btn-width"
                    onClick={clickBack}
                  >
                    <div className="m-c-icon-inside-btn u-pr-lg">
                      <icons.AngleLeft />
                    </div>
                    Back
                  </Button>
                </div>
                <div className="m-l-mail-main-data-header">
                  <div className="m-l-mail-main-data-header-left">
                    <div className="m-l-mail-data-prof-sm-data">
                      <div className="m-l-mail-main-prof-pic-cntr">
                        {/* <img
                          src={imageURL.avatarsm}
                          className="img-responsive m-l-email-right-profile-pic"
                          alt="profile-pic"
                        /> */}
                        <icons.Profile />
                      </div>
                      {selectedTab == 0 ? (
                        <div className="m-l-mail-imp-data-wrap">
                          <div className="m-l-mail-main-title">
                            {mailDetail?.from}
                          </div>
                          <div className="m-l-mail-sub-title">
                            {mailDetail?.subject}
                          </div>
                          <div className="m-l-mail-to-part">
                            to:{' '}
                            <span className="m-l-mail-to-id">
                              {mailDetail?.to}
                            </span>
                          </div>
                          <div className="m-l-email-atch-file-set-wrap">
                            {mailDetail?.attachments?.length > 0 &&
                              mailDetail?.attachments.map(
                                (attachment: any, index: number) => {
                                  return (
                                    <div
                                      key={index}
                                      className="m-l-email-atch-icon-fl-wrap"
                                      onClick={() => {
                                        downloadAttachment(attachment);
                                      }}
                                    >
                                      <div className="m-l-email-atch-file-type-hldr">
                                        <div className="m-l-file-icon-wrapper">
                                          <div className="m-l-user-image-block">
                                            <div className="m-l-avatar-block">
                                              <img
                                                src={attachmentType(
                                                  attachment?.fileType
                                                )}
                                                className="img-fluid"
                                                alt="Logo"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="m-l-email-attach-file-wrap">
                                        <p className="m-l-email-attach-file-name">
                                          {attachment.fileName}
                                        </p>
                                        {/* <span className="m-l-email-attach-file-size">
                                          {attachment.fileSize}
                                        </span> */}
                                        {/* <div className="m-l-files-close-icon">
                                <icons.Close />
                              </div> */}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </div>
                      ) : (
                        <div className="m-l-mail-imp-data-wrap">
                          <div className="m-l-mail-main-title">
                            {mailDetail?.to}
                          </div>
                          <div className="m-l-mail-sub-title">
                            {mailDetail?.subject}
                          </div>
                          <div className="m-l-mail-to-part">
                            From:{' '}
                            <span className="m-l-mail-to-id">
                              {mailDetail?.from}
                            </span>
                          </div>
                          <div className="m-l-email-atch-file-set-wrap">
                            {mailDetail?.attachments?.length > 0 &&
                              mailDetail?.attachments.map(
                                (attachment: any, index: number) => {
                                  return (
                                    <div
                                      key={index}
                                      className="m-l-email-atch-icon-fl-wrap"
                                      onClick={() => {
                                        downloadAttachment(attachment);
                                      }}
                                    >
                                      <div className="m-l-email-atch-file-type-hldr">
                                        <div className="m-l-file-icon-wrapper">
                                          <div className="m-l-user-image-block">
                                            <div className="m-l-avatar-block">
                                              <img
                                                src={attachmentType(
                                                  attachment?.fileType
                                                )}
                                                className="img-fluid"
                                                alt="Logo"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="m-l-email-attach-file-wrap">
                                        <p className="m-l-email-attach-file-name">
                                          {attachment.fileName}
                                        </p>
                                        {/* <span className="m-l-email-attach-file-size">
                                          {attachment.fileSize}
                                        </span> */}
                                        {/* <div className="m-l-files-close-icon">
                                <icons.Close />
                              </div> */}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="m-l-mail-main-data-header-right">
                    <div className="m-l-email-send-date">
                      {mailDetail?.receivedDate
                        ? apiDateAndTimeFormat(mailDetail?.receivedDate)
                        : mailDetail?.sendDate
                        ? apiDateAndTimeFormat(mailDetail?.sendDate)
                        : null}
                    </div>
                    {vetRequestId ? (
                      <Restricted permission="VetRequest.ReplyEmails">
                        <Button
                          text={'Reply'}
                          type="default"
                          stylingMode="contained"
                          onClick={() => onClickReply(mailDetail)}
                          className="app-c-btn app-c-btn--secondary"
                        >
                          <div
                            className="m-c-icon-btn"
                            data-testid="export-all"
                          >
                            <icons.ReplayIcon />
                          </div>
                          {t('labels.reply')}
                        </Button>
                      </Restricted>
                    ) : (
                      <Restricted permission="Email.Reply">
                        <Button
                          text={'Reply'}
                          type="default"
                          stylingMode="contained"
                          onClick={() => onClickReply(mailDetail)}
                          className="app-c-btn app-c-btn--secondary"
                        >
                          <div
                            className="m-c-icon-btn"
                            data-testid="export-all"
                          >
                            <icons.ReplayIcon />
                          </div>
                          {t('labels.reply')}
                        </Button>
                      </Restricted>
                    )}
                  </div>
                </div>
                <div className="m-l-mail-main-data-body">
                  <div
                    className="m-l-email-content-wrapper"
                    dangerouslySetInnerHTML={{
                      __html: mailDetail?.mailContent,
                    }}
                  ></div>
                  {/* {mailDetail?.attachments?.length > 0 &&
                    mailDetail?.attachments.map( */}
                  {/* <div className="m-l-email-atch-file-set-wrap">
                    {data?.length > 0 &&
                      data.map((attachment: any, index: number) => {
                        return (
                          <div
                            key={index}
                            className="m-l-email-atch-icon-fl-wrap"
                          >
                            <a className="m-l-email-atch-icon-hldr">
                              <icons.Attach />
                            </a>
                            <div className="m-l-email-attach-file-wrap">
                              <span>{attachment.fileName}</span>
                              <div className="m-l-files-close-icon">
                                <icons.Close />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div> 
                  {/* <Button
                    text={'Reply'}
                    type="default"
                    stylingMode="contained"
                    onClick={() => onClickReply(mailDetail)}
                    className="app-c-btn app-c-btn--primary min-btn-width"
                  /> */}
                </div>
              </div>
            ) : (
              <div className="no-list-block no-list-min-hgt">
                <icons.Confirmation />
                <div className="no-list-text">
                  <p>Select an item to read</p>
                  <br />
                  Nothing is selected
                </div>
              </div>
            )}
          </ScrollView>
        </div>
        {showRfiModal && (
          <RfiTemplate
            isVisible={showRfiModal}
            setIsVisible={setShowRfiModal}
            vetRequestId={replyContent?.vetRequestId}
            isReplyMail={true}
            replyDefaultContent={replyContent}
            getEmaiList={getMailList}
          />
        )}
      </div>
    </div>
  );
}
