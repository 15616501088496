import Button from 'devextreme-react/button';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Restricted } from '../../../contexts/PermissionContext';
import { listTypes } from '../../../enums/buList-type-enum';
import { IListEntriesObject } from '../../../types/list';
import icons from '../../common/icons/icons';
import EditEntries from '../edit-entries-to-list/EditEntries';
import EditListGrid from '../edit-list-grid/EditListGrid';
import ExcelUpload from '../excel-upload-to-list/ExcelUpload';

export default function EntriesList(props: {
  listEntries: IListEntriesObject;
  getListById: any;
  listId: any;
  attribute: any;
  listDetails: any;
  listType?: any;
  buId?: any;
}) {
  const {
    listEntries,
    getListById,
    listId,
    attribute,
    listDetails,
    listType,
    buId,
  } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [isExceUpload, setIsExcelUpload] = useState(false);
  const [listEntryId, setListEntryId] = useState<any>();
  const { t } = useTranslation();
  const entriesGridRef = useRef<any>();

  const showEditEntries = (id: any) => {
    if (id) {
      setIsVisible(true);
    }
  };

  const handleCancelClick = () => {
    setIsExcelUpload(false);
  };

  return (
    <>
      <div className="m-l-inner-page-body-wrap">
        <div className="m-l-inner-page-sub-header m-l-sub-head-top">
          <div className="m-l-inner-page-sub-header-left-block">
            <div className="m-l-page-main-heading">{t('headers.entries')}</div>
          </div>
          <div className="m-l-inner-page-sub-header-right-block">
            <Button
              className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
              aria-label="Upload"
              onClick={() =>
                entriesGridRef.current.exportListEntriesGrid(
                  listDetails.listName
                )
              }
              // disabled={listType === listTypes.Global}
            >
              <div className="m-c-icon-inside-btn">
                <icons.download />
              </div>
            </Button>
            {buId ? (
              <Restricted permission="BULists.ExcelUploadEntries">
                <Button
                  className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                  aria-label="Upload"
                  onClick={() => {
                    setIsExcelUpload(true);
                  }}
                  disabled={listType === listTypes.Global}
                >
                  <div className="m-c-icon-inside-btn">
                    <icons.IconUpload />
                  </div>
                </Button>
              </Restricted>
            ) : (
              <Restricted permission="GlobalList.ExcelUploadEntries">
                <Button
                  className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                  aria-label="Upload"
                  onClick={() => {
                    setIsExcelUpload(true);
                  }}
                  disabled={listType === listTypes.Global}
                >
                  <div className="m-c-icon-inside-btn">
                    <icons.IconUpload />
                  </div>
                </Button>
              </Restricted>
            )}

            {buId ? (
              <Restricted permission="BULists.AddEntries">
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width"
                  onClick={() => {
                    setIsVisible(true);
                  }}
                  disabled={listType === listTypes.Global}
                >
                  {t('buttons.addNew')}
                </Button>
              </Restricted>
            ) : (
              <Restricted permission="GlobalList.AddEntriesToList">
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width"
                  onClick={() => {
                    setIsVisible(true);
                  }}
                  disabled={listType === listTypes.Global}
                >
                  {t('buttons.addNew')}
                </Button>
              </Restricted>
            )}
          </div>
        </div>
        <EditListGrid
          listEntries={listEntries}
          setListEntryId={setListEntryId}
          showEditEntries={showEditEntries}
          ref={entriesGridRef}
          attribute={attribute}
          buId={buId}
        />
      </div>
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <Button
                className="app-c-btn app-c-btn--secondary min-btn-width"
                aria-label="Upload"
                onClick={() =>
                  entriesGridRef.current.exportListEntriesGrid(
                    listDetails.listName
                  )
                }
                disabled={listType === listTypes.Global}
              >
                {t('labels.download')}
              </Button>
              {buId ? (
                <Restricted permission="BULists.ExcelUploadEntries">
                  <Button
                    className="app-c-btn app-c-btn--secondary min-btn-width"
                    aria-label="Upload"
                    onClick={() => {
                      setIsExcelUpload(true);
                    }}
                    disabled={listType === listTypes.Global}
                  >
                    {t('labels.upload')}
                  </Button>
                </Restricted>
              ) : (
                <Restricted permission="GlobalList.ExcelUploadEntries">
                  <Button
                    className="app-c-btn app-c-btn--secondary min-btn-width"
                    aria-label="Upload"
                    onClick={() => {
                      setIsExcelUpload(true);
                    }}
                    disabled={listType === listTypes.Global}
                  >
                    {t('labels.upload')}
                  </Button>
                </Restricted>
              )}

              {buId ? (
                <Restricted permission="BULists.AddEntries">
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    onClick={() => {
                      setIsVisible(true);
                    }}
                    disabled={listType === listTypes.Global}
                  >
                    {t('buttons.addNew')}
                  </Button>
                </Restricted>
              ) : (
                <Restricted permission="GlobalList.AddEntriesToList">
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    onClick={() => {
                      setIsVisible(true);
                    }}
                    disabled={listType === listTypes.Global}
                  >
                    {t('buttons.addNew')}
                  </Button>
                </Restricted>
              )}
            </div>
          </div>
        </div>
      </div>
      {isVisible ? (
        <EditEntries
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          getListById={getListById}
          listId={listId}
          listEntryId={listEntryId}
          listEntries={listEntries}
          setListEntryId={setListEntryId}
          attribute={attribute}
          listType={listType}
        />
      ) : null}
      {isExceUpload ? (
        <ExcelUpload
          isVisible={isExceUpload}
          handleCancelClick={handleCancelClick}
          listId={listId}
          getListById={getListById}
        />
      ) : null}
    </>
  );
}
