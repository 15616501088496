import Button from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IModalConfig } from '../../../../types/modal';
import icons from '../../../common/icons/icons';
import Modal from '../../../common/modal/Modal';
import CountryList from '../../../Country.json';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import toast from '../../../../utils/toast';
import PortContactEditForm from './PortContactEditForm';
import { IAddNewPortContacts } from '../../../../types/port-configurations';
import { IDialogConfig } from '../../../../types/dialog';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import ValidationGroup from 'devextreme-react/validation-group';

export default function PortContactEditModal(props: {
  isVisible: boolean;
  handleCancelClick: any;
  portCode: any;
  getPortContacts: any;
  selectedContact?: any;
}) {
  const [contactType, setContactType] = useState<any>([]);
  const [selectedContactValues, setSelectedContactValues] = useState<any>([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const portContactRef = useRef<any>();
  const [showConfirmBack, setShowConfirmBack] = useState(false);

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      onValueSubmit();
      setIsConfirmModalOpen(false);
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };

  const { t } = useTranslation();

  const {
    isVisible,
    handleCancelClick,
    portCode,
    getPortContacts,
    selectedContact,
  } = props;

  const modalConfig: IModalConfig = {
    width: 1011,
    height: 400,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-add-new-modal',
    };
  }, []);

  const getPortContactsDropDown = async () => {
    await api.get({ url: apiConfig.getPortContacts }).then((data: any) => {
      setContactType(data);
    });
  };

  const getSelectedContactValue = async () => {
    await api
      .get({
        url: apiConfig.addPortContact + selectedContact.portContactDetailId,
      })
      .then((data: any) => {
        setSelectedContactValues(data);
      });
  };

  useEffect(() => {
    getPortContactsDropDown();
  }, []);

  useEffect(() => {
    if (selectedContact) {
      getSelectedContactValue();
    }
  }, [selectedContact]);

  const defaultFormValues = {
    contactType: null,
    name: null,
    address: null,
    postalCode: null,
    email: null,
    phoneNumber: '',
    website: null,
  };

  const portContactForm = useForm({ defaultValues: defaultFormValues });
  const { isDirty } = portContactForm.formState;

  const { getValues } = portContactForm;
  let portContactFormValue = getValues();

  const onCloseClick = () => {
    if (isDirty) {
      setShowConfirmBack(true);
    } else {
      portContactForm.reset(defaultFormValues);
      setShowConfirmBack(false);
      handleCancelClick();
    }
  };

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setShowConfirmBack(false);
      portContactForm.reset(defaultFormValues);
      handleCancelClick();
    },
    handleClose: () => {
      setShowConfirmBack(false);
    },
  };

  const onClickSave = () => {
    if (portContactRef?.current?.instance.validate().isValid) {
      setIsConfirmModalOpen(true);
    }
  };

  const onValueSubmit = async () => {
    portContactFormValue = portContactForm.getValues();
    if (selectedContact) {
      const roleJsonEdit: IAddNewPortContacts = {
        portContactDetailId: selectedContact.portContactDetailId,
        portContactHeaderId: selectedContact.portContactHeaderId,
        portCode: portCode,
        contactType: portContactFormValue.contactType,
        name: portContactFormValue.name ? portContactFormValue.name : '',
        address1: portContactFormValue.address
          ? portContactFormValue.address
          : '',
        postalCode: portContactFormValue.postalCode
          ? portContactFormValue.postalCode
          : '',
        email: portContactFormValue.email ? portContactFormValue.email : '',
        phoneNumber:
          portContactFormValue.phoneNumber === ''
            ? ''
            : portContactFormValue?.phoneNumber?.toString(),
        website: portContactFormValue.website
          ? portContactFormValue.website
          : '',
      };
      api
        .put({ url: apiConfig.addPortContact, data: roleJsonEdit })
        .then((response) => {
          if (response) {
            handleCancelClick();
            toast.success({
              title: t('toast.contactEditSuccess'),
            });
            getPortContacts(portCode);
          }
        });
    } else {
      const roleJsonEdit: IAddNewPortContacts = {
        portContactDetailId: 0,
        portContactHeaderId: 0,
        portCode: portCode,
        contactType: portContactFormValue.contactType,
        name: portContactFormValue.name ? portContactFormValue.name : '',
        address1: portContactFormValue.address
          ? portContactFormValue.address
          : '',
        postalCode: portContactFormValue.postalCode
          ? portContactFormValue.postalCode
          : '',
        email: portContactFormValue.email ? portContactFormValue.email : '',
        phoneNumber:
          portContactFormValue.phoneNumber == undefined
            ? ''
            : portContactFormValue?.phoneNumber?.toString(),
        website: portContactFormValue.website
          ? portContactFormValue.website
          : '',
      };
      api
        .post({ url: apiConfig.addPortContact, data: roleJsonEdit })
        .then((response) => {
          if (response) {
            handleCancelClick();
            toast.success({
              title: t('toast.contactAddedSuccess'),
            });
            getPortContacts(portCode);
          }
        });
    }
  };

  useEffect(() => {
    portContactForm.reset();
    if (selectedContactValues) {
      portContactForm.reset(
        {
          contactType: selectedContactValues.contactTypeCode,
          name: selectedContactValues.name,
          address: selectedContactValues.address,
          postalCode: selectedContactValues.postalCode,
          email: selectedContactValues.email,
          phoneNumber: selectedContactValues.phoneNumber,
          website: selectedContactValues.website,
        },
        { keepDirty: true }
      );
    }
  }, [selectedContactValues]);

  return (
    <div className="inner-wrapper">
      <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
        <div className="m-l-modal__header">
          <h2 className="modal-title">
            {' '}
            {selectedContact ? 'Edit Contact' : 'Add Contact'}{' '}
          </h2>
          <div className="m-l-modal-close-button-block">
            <Button
              className="app-c-btn app-c-icon-only-btn"
              onClick={onCloseClick}
            >
              <icons.IconCross />
            </Button>
          </div>
        </div>
        <div className="m-l-modal__body">
          <ScrollView width="100%" height="100%">
            <div className="m-l-adnw-form-panel">
              <div className="row">
                <FormProvider {...portContactForm}>
                  <ValidationGroup ref={portContactRef}>
                    <PortContactEditForm
                      contactType={contactType}
                      portContactRef={portContactRef}
                    />
                  </ValidationGroup>
                </FormProvider>
              </div>
            </div>
          </ScrollView>
        </div>
        <div className="m-l-modal__footer">
          <Button
            className="app-c-btn app-c-btn--secondary min-btn-width"
            elementAttr={{ 'data-testid': 'backButton' }}
            onClick={onCloseClick}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            className="app-c-btn app-c-btn--primary min-btn-width"
            onClick={onClickSave}
          >
            {t('buttons.save')}
          </Button>
          <ConfirmDialog
            dialogConfig={confirmSaveDialogConfig}
            isOpen={isConfirmModalOpen}
          />
          <ConfirmDialog
            dialogConfig={confirmBackDialogConfig}
            isOpen={showConfirmBack}
          />
        </div>
      </Modal>
    </div>
  );
}
