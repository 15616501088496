import React from 'react';
import { Restricted } from '../../../../contexts/PermissionContext';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import { IVetRequestBerth } from '../../../../types/berthfit-execution';
import icons from '../../../common/icons/icons';

const BerthAccordionHeader = (props: { data: IVetRequestBerth }) => {
  const { data } = props;
  const { formatDate } = useDateFormat();
  return (
    <div>
      <div className="m-l-accordion__header">
        <div className="left-block">
          <span className="left-icon-block">
            {data.isOverrided && !data.isPassed && <icons.Overridefail />}
            {data.isOverrided && data.isPassed && <icons.OverridePass />}
            {!data.isPassed && !data.isOverrided && <icons.circleCrossDanger />}
            {data.isPassed && !data.isOverrided && <icons.circleTickDanger />}
          </span>
          {/* <div className="left-title-block"> */}
          <h5 className="title">{data.berthName}</h5>
          {/* </div> */}
          <Restricted permission="BerthFitVet.ViewBerthInfo">
            <div className="m-l-view-info-link">
              <span className="view-berth-info">View Info</span>
            </div>
          </Restricted>
          {data.isBerthApproved ? (
            data.berthApprovalValidTill ? (
              new Date(data.berthApprovalValidTill) < new Date() ? (
                <p className="m-l-port-approval-info">
                  Berth Approval Expired. Valid Till :{' '}
                  {formatDate(data.berthApprovalValidTill)}
                </p>
              ) : (
                <p className="m-l-port-approval-info-approved">
                  Berth Approved. Valid Till :{' '}
                  {formatDate(data.berthApprovalValidTill)}
                </p>
              )
            ) : (
              <p className="m-l-port-approval-info-approved">Berth Approved.</p>
            )
          ) : (
            <p className="m-l-port-approval-info">Berth Not Approved.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BerthAccordionHeader;
