import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormConfig } from '../../../../types/form-builder';
import FieldArray from '../../../common/field-array/FieldArray';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';

export default function ServicesForm(props: { isMarsUser: any }) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [services, setServices] = useState<any>();
  const { isMarsUser } = props;

  const getServices = async () => {
    await api
      .get({ url: apiConfig.optionValue + 'ContractServices' })
      .then((data: any) => {
        setServices(data);
      });
  };

  useEffect(() => {
    getServices();
  }, []);

  const fieldArrayappendValues = {
    pscDefeciancyId: 0,
    defectCode: null,
    defectDescription: '',
    riskArea: null,
    subArea: null,
    rootCauseCode: null,
    actionCode: null,
  };

  const contractFieldArray = useFieldArray({
    control: control,
    name: 'contractServices',
  });

  const formConfig: IFormConfig[] = [
    {
      name: 'serviceType',
      label: t('labels.service'),
      control: control,
      fieldType: 'select',
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      dataSource: services,
      isFieldArray: true,
      disabled: !isMarsUser,
      class: 'col-xl-2 col-lg-3 col-md-12 col-sm-12 col-12',
    },
    {
      name: 'price',
      label: t('labels.pricing'),
      control: control,
      fieldType: 'number',
      disabled: !isMarsUser,
      class:
        'col-xl-2 col-lg-3 col-md-12 col-sm-12 col-12 m-l-custom-eight-space-col',
      isFieldArray: true,
    },
  ];

  return (
    // <div className="m-l-pdng-btm0">
    <div className="row">
      <FieldArray
        showAddButton={true}
        fieldArray={contractFieldArray}
        fieldArrayName="contractServices"
        formConfig={formConfig}
        appendValues={fieldArrayappendValues}
        buttonWrapperParentClass="col-xl-auto col-lg-auto col-md-auto col-sm-auto col-12 m-l-custom-sixteen-space-col"
        buttonWrapperClass="mb-btm-space-30 m-c-form-group m-l-button-vertical-center-align m-l-fm-icon-btn"
      />
    </div>
    // </div>
  );
}
