import CheckBox from 'devextreme-react/check-box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IRuleFilter,
  IRuleFilterKey,
} from '../../../../types/vessel-info-rules';

export default function RuleFilter(props: {
  ruleFilter: IRuleFilter;
  onFilterChanged: (e: any, filterKey: IRuleFilterKey) => void;
}) {
  const { t } = useTranslation();

  const { ruleFilter, onFilterChanged } = props;

  return (
    <div
      className="m-l-grid-top-header__section m-l-rule-toggle-list"
      data-testid="ruleFilter"
    >
      <div className="left-section">
        <div className="m-l-grid-top-header-list-row">
          <div className="row m-l-toggle-button-horizontal-list">
            <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column justify-content-start">
              <div className="m-c-checkbox m-c-checkbox-dark">
                <CheckBox
                  value={ruleFilter.all}
                  onValueChange={(e) => onFilterChanged(e, 'all')}
                  elementAttr={{ 'data-testid': 'all' }}
                />
              </div>
              <h2 className="checkbox-label">{t('labels.all')}</h2>
            </div>
            <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column justify-content-start">
              <div className="m-c-checkbox m-c-checkbox-dark">
                <CheckBox
                  value={ruleFilter.isMarsRule}
                  onValueChange={(e) => onFilterChanged(e, 'isMarsRule')}
                  elementAttr={{ 'data-testid': 'isMarsRule' }}
                />
              </div>
              <h2 className="checkbox-label">{t('labels.marsRules')}</h2>
            </div>
            <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column justify-content-start">
              <div className="m-c-checkbox m-c-checkbox-dark">
                <CheckBox
                  value={ruleFilter.isClientRule}
                  onValueChange={(e) => onFilterChanged(e, 'isClientRule')}
                  elementAttr={{ 'data-testid': 'isClientRule' }}
                />
              </div>
              <h2 className="checkbox-label">{t('labels.clientRules')}</h2>
            </div>
            <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column justify-content-start">
              <div className="m-c-checkbox m-c-checkbox-dark">
                <CheckBox
                  value={ruleFilter.isRulePassed}
                  onValueChange={(e) => onFilterChanged(e, 'isRulePassed')}
                  elementAttr={{ 'data-testid': 'isRulePassed' }}
                />
              </div>
              <h2 className="checkbox-label">{t('labels.pass')}</h2>
            </div>
            <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column justify-content-start">
              <div className="m-c-checkbox m-c-checkbox-dark">
                <CheckBox
                  value={ruleFilter.isRuleFailed}
                  onValueChange={(e) => onFilterChanged(e, 'isRuleFailed')}
                  elementAttr={{ 'data-testid': 'isRuleFailed' }}
                />
              </div>
              <h2 className="checkbox-label">{t('labels.fail')}</h2>
            </div>
            <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column justify-content-start">
              <div className="m-c-checkbox m-c-checkbox-dark">
                <CheckBox
                  value={ruleFilter.isRulePassedByOverride}
                  onValueChange={(e) =>
                    onFilterChanged(e, 'isRulePassedByOverride')
                  }
                  elementAttr={{ 'data-testid': 'isRulePassedByOverride' }}
                />
              </div>
              <h2 className="checkbox-label">{t('labels.overridePass')}</h2>
            </div>
            <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column justify-content-start">
              <div className="m-c-checkbox m-c-checkbox-dark">
                <CheckBox
                  value={ruleFilter.isRuleFailedByOverride}
                  onValueChange={(e) =>
                    onFilterChanged(e, 'isRuleFailedByOverride')
                  }
                  elementAttr={{ 'data-testid': 'isRuleFailedByOverride' }}
                />
              </div>
              <h2 className="checkbox-label">{t('labels.overrideFail')}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
