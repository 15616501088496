import Button from 'devextreme-react/button';
import React from 'react';
import { IRuleList } from '../../../../types/vessel-info-rules';
import icons from '../../../common/icons/icons';

const RulesAccordionHeader = (data: IRuleList) => {
  return (
    <div data-testid="rulesAccordionHeader">
      <div className="m-l-accordion__header">
        {data.isRulePassedByOverride && (
          <div className="left-block">
            <span className="left-icon-block">
              <icons.OverridePass />
            </span>
            {data.ruleName}
          </div>
        )}
        {data.isRuleFailedByOverride && (
          <div className="left-block">
            <span className="left-icon-block">
              <icons.Overridefail />
            </span>
            {data.ruleName}
          </div>
        )}
        {data.isRulePassed &&
          !data.isRulePassedByOverride &&
          !data.isRuleFailedByOverride && (
            <div className="left-block">
              <span className="left-icon-block">
                <icons.circleTickDanger />
              </span>
              {data.ruleName}
            </div>
          )}
        {!data.isRulePassed &&
          !data.isRulePassedByOverride &&
          !data.isRuleFailedByOverride && (
            <div className="left-block">
              <span className="left-icon-block">
                <icons.circleCrossDanger />
              </span>
              {data.ruleName}
            </div>
          )}
        <div className="right-block">
          {data.isRFISendToRequester && (
            <Button
              className="app-c-btn app-c-btn--tertiary"
              elementAttr={{ 'data-testid': 'isRFISendToRequester' }}
            >
              {/* <icons.IconCross /> */}
              <div className="m-c-icon-inside-btn">
                <icons.IconCompany />
              </div>
              to company
            </Button>
          )}
          {data.isRFISendToVesselOwner && (
            <Button
              className="app-c-btn app-c-btn--tertiary"
              elementAttr={{ 'data-testid': 'isRFISendToRequester' }}
            >
              {/* <icons.IconCross /> */}
              <div className="m-c-icon-inside-btn">
                <icons.IconCompany />
              </div>
              to vessel
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RulesAccordionHeader;
