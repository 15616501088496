import React, { useEffect, useMemo, useState } from 'react';
import { IModalConfig } from '../../../types/modal';
import Modal from '../modal/Modal';
import Button from 'devextreme-react/button';
import icons from '../icons/icons';
import { useTranslation } from 'react-i18next';
import TextBox from 'devextreme-react/text-box';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { IMasterEntity } from '../../../types/master-data';
import RFIHtmlEditor from './html-editor/RfiHtmlEditor';
import toast from '../../../utils/toast';
import ConfirmDialog from '../confirm/ConfirmDialog';
import { IDialogConfig } from '../../../types/dialog';
import { getAPIRoute } from '../../../utils/api-route';
import FileUploaderModal from '../file-uploader-modal/FileUploaderModal';
import Validator from 'devextreme-react/validator';
import { RequiredRule } from 'devextreme-react/data-grid';
import { getRFITable, replyEmailTemplate } from './DefaultTemplates';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { RfiTemplateTypes } from '../../../enums/rfi-enum';

export default function RfiTemplate(props: {
  isVisible: any;
  setIsVisible: any;
  ruleRFITemplateTypes?: any;
  isRFIClient?: any;
  vetRequestId?: any;
  ruleId?: any;
  getRuleById?: any;
  getRuleList?: any;
  incidentsList?: any;
  pscList?: any;
  isRFIEditable?: boolean;
  getMailCount?: any;
  selectedMailIDList?: any;
  isRFIToClientTemplateDefined?: any;
  isRFIToVesselOwnerTemplateDefined?: any;
  getMailList?: any;
  isReplyMail?: boolean;
  replyDefaultContent?: any;
  getEmaiList?: any;
  buId?: any;
}) {
  const {
    isVisible,
    setIsVisible,
    ruleRFITemplateTypes,
    isRFIClient,
    vetRequestId,
    ruleId,
    getRuleById,
    getRuleList,
    incidentsList,
    pscList,
    isRFIEditable,
    getMailCount,
    selectedMailIDList,
    isRFIToClientTemplateDefined,
    isRFIToVesselOwnerTemplateDefined,
    getMailList,
    isReplyMail,
    replyDefaultContent,
    getEmaiList,
    buId,
  } = props;
  const { t } = useTranslation();
  const [ccAddress, setCcAddress] = useState('');
  const [toAddress, setToAddress] = useState('');
  const [defaultValues, setDefaultValues] = useState({
    to: '',
    cc: '',
    subject: 'RFI for VET ID @VetRequest.VetId; IMO @Ship.IMONumber;',
    template: '',
    attachedFiles: [],
  });
  const [isChanged, setIsChanged] = useState(false);
  const [rfiTemplateDetails, setRfiTemplateDetails] = useState<any>();
  const [subject, setSubject] = useState(
    'RFI for VET ID @VetRequest.VetId; IMO @Ship.IMONumber;'
  );
  const [isconfirmBackDialog, setIsConfirmBackDialog] = useState(false);
  const [isconfirmSendDialog, setIsConfirmSendDialog] = useState(false);
  const [isconfirmSendReplyDialog, setIsConfirmSendReplyDialog] =
    useState(false);
  const [entities, setEntities] = useState<IMasterEntity[]>([]);
  const [macros, setMacros] = useState<any>();
  const [shipDetails, setShipDetails] = useState<any>();
  const [isAddVisible, setIsAddVisible] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const { setIsLoading } = useLoaderContext();
  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-modal-mailcontent',
    };
  }, []);

  const [content, setContent] = useState<any>();
  const [showEditor, setShowEditor] = useState(false);
  const [isError, setIsError] = useState(false);

  const modalConfig: IModalConfig = {
    width: 896,
    height: 680,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const isValueChange = () => {
    if (isChanged) {
      if (
        toAddress == defaultValues.to &&
        ccAddress == defaultValues.cc &&
        subject == defaultValues.subject &&
        content == defaultValues.template
      ) {
        return false;
      } else {
        return true;
      }
    }
  };
  const handleBackClick = () => {
    if (isValueChange() && !isRFIEditable) {
      setIsConfirmBackDialog(true);
    } else {
      setIsVisible(false);
    }
  };

  const getCombinedRfiDetails = async () => {
    await api
      .post(
        {
          url: getAPIRoute('combineRfiEmail', [
            vetRequestId,
            isRFIClient ? RfiTemplateTypes.Client : RfiTemplateTypes.Vessel,
          ]),
          data: selectedMailIDList,
        },
        setIsLoading
      )
      .then((response) => {
        setRfiTemplateDetails(response);
        setIsError(false);
      })
      .catch((e) => {
        setIsError(true);
      });
  };
  const ruleRFITemplateDetails = async () => {
    if (vetRequestId) {
      if (isRFIClient) {
        await api
          .get(
            {
              url: getAPIRoute('getRfiTemplate', [
                ruleId,
                RfiTemplateTypes.Client,
              ]),
              params: { vetRequestId: vetRequestId },
            },
            setIsLoading
          )
          .then((response) => {
            setRfiTemplateDetails(response);
          });
      } else {
        await api
          .get(
            {
              url: getAPIRoute('getRfiTemplate', [
                ruleId,
                RfiTemplateTypes.Vessel,
              ]),
              params: { vetRequestId: vetRequestId },
            },
            setIsLoading
          )
          .then((response) => {
            setRfiTemplateDetails(response);
          });
      }
    } else {
      if (isRFIClient) {
        await api
          .get({
            url: getAPIRoute('getRfiTemplate', [
              ruleId,
              RfiTemplateTypes.Client,
            ]),
            params: { buId: buId },
          })
          .then((response) => {
            setRfiTemplateDetails(response);
          }, setIsLoading);
      } else {
        await api
          .get({
            url: getAPIRoute('getRfiTemplate', [
              ruleId,
              RfiTemplateTypes.Vessel,
            ]),
            params: { buId: buId },
          })
          .then((response) => {
            setRfiTemplateDetails(response);
          }, setIsLoading);
      }
    }
  };

  useEffect(() => {
    if (isReplyMail && replyDefaultContent) {
      const template = replyEmailTemplate(replyDefaultContent);
      setRfiTemplateDetails(template);
    }
  }, [isReplyMail, replyDefaultContent]);

  useEffect(() => {
    if (rfiTemplateDetails) {
      const defaultValuesObj = { ...defaultValues };
      if (vetRequestId) {
        if (incidentsList?.length > 0) {
          const incidentTable = getRFITable(incidentsList, true);
          let newContent = rfiTemplateDetails.templateHtml.replace(
            '@Ship.PSCInspectionDetails',
            ''
          );
          newContent = newContent.replace(
            '@Ship.IncidentDetails',
            incidentTable
          );
          setContent(newContent);
          defaultValuesObj.template = newContent;
        } else if (pscList?.length > 0) {
          const pscTable = getRFITable(pscList, false);
          let newContent = rfiTemplateDetails.templateHtml.replace(
            '@Ship.IncidentDetails',
            ''
          );
          newContent = newContent.replace(
            '@Ship.PSCInspectionDetails',
            pscTable
          );
          setContent(newContent);
          defaultValuesObj.template = newContent;
        } else {
          let newContent = rfiTemplateDetails.templateHtml.replace(
            '@Ship.IncidentDetails',
            ''
          );
          newContent = newContent.replace('@Ship.PSCInspectionDetails', '');
          setContent(newContent);
          defaultValuesObj.template = rfiTemplateDetails.templateHtml;
        }
      } else {
        setContent(rfiTemplateDetails.templateHtml);
        defaultValuesObj.template = rfiTemplateDetails.templateHtml;
      }
      setCcAddress(rfiTemplateDetails.cc ? rfiTemplateDetails.cc : '');
      setToAddress(rfiTemplateDetails.to ? rfiTemplateDetails.to : '');
      setSubject(rfiTemplateDetails.subject);
      rfiTemplateDetails.attachedFiles?.forEach((element: any) => {
        element.name = element.fileName;
      });
      setUploadedFiles(
        rfiTemplateDetails.attachedFiles ? rfiTemplateDetails.attachedFiles : []
      );
      defaultValuesObj.attachedFiles = rfiTemplateDetails.attachedFiles
        ? rfiTemplateDetails.attachedFiles
        : [];
      defaultValuesObj.cc = rfiTemplateDetails.cc ? rfiTemplateDetails.cc : '';
      defaultValuesObj.to = rfiTemplateDetails.to ? rfiTemplateDetails.to : '';
      defaultValuesObj.subject = rfiTemplateDetails.subject;
      setDefaultValues(defaultValuesObj);
      setShowEditor(true);
    }
  }, [rfiTemplateDetails]);

  const getEntities = async () => {
    await api
      .get({
        url: apiConfig.masterEntities,
        params: { isRequiredForRFITemplate: true },
      })
      .then((res: IMasterEntity[]) => {
        setEntities(res);
      });
  };

  const getMacros = async () => {
    await api
      .get({
        url: getAPIRoute('vetRequestMacros', [vetRequestId]),
      })
      .then((res) => {
        setMacros(res);
      });
  };

  const getShipDetails = async () => {
    await api
      .get({
        url: getAPIRoute('vetRequestShipDetails', [vetRequestId]),
      })
      .then((res) => {
        setShipDetails(res);
      });
  };

  const getTitle = () => {
    if (selectedMailIDList && selectedMailIDList.length > 1) {
      return 'Combine RFIs';
    } else {
      return isRFIClient
        ? t('labels.rfiToRequestor')
        : t('labels.rfiToVesselOwner');
    }
  };

  useEffect(() => {
    getEntities();
  }, []);

  useEffect(() => {
    if (vetRequestId) {
      getShipDetails();
      getMacros();
    }
  }, [vetRequestId]);

  useEffect(() => {
    if (ruleId) {
      ruleRFITemplateDetails();
    }
  }, []);

  useEffect(() => {
    if (selectedMailIDList && selectedMailIDList.length > 0) {
      getCombinedRfiDetails();
    }
  }, [selectedMailIDList]);

  const onClickSave = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      const rfiData = {
        ruleId: ruleId,
        to: toAddress ? toAddress : '',
        cc: ccAddress ? ccAddress : '',
        subject: subject,
        templateHtml: content,
        templateType: isRFIClient
          ? RfiTemplateTypes.Client
          : RfiTemplateTypes.Vessel,
      };
      if (isRFIToVesselOwnerTemplateDefined && !isRFIClient) {
        editRFI(rfiData);
      } else if (isRFIToClientTemplateDefined && isRFIClient) {
        editRFI(rfiData);
      } else {
        saveRFI(rfiData);
      }
    }
  };

  const saveRFI = (rfiData: any) => {
    api
      .post(
        {
          url: apiConfig.rfiTemplate,
          data: rfiData,
        },
        setIsLoading
      )
      .then(() => {
        setIsChanged(false);
        getRuleById();
        toast.success({
          title: t('toast.templateSavedSuccessfully'),
        });
        setIsVisible(false);
      });
  };

  const editRFI = (rfiData: any) => {
    rfiData.ruleRFITemplateId = rfiTemplateDetails?.ruleRFITemplateId;
    api
      .put(
        {
          url: apiConfig.rfiTemplate,
          data: rfiData,
        },
        setIsLoading
      )
      .then(() => {
        setIsChanged(false);
        toast.success({
          title: t('toast.templateSavedSuccessfully'),
        });
        setIsVisible(false);
      });
  };

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setIsConfirmBackDialog(false);
      setIsVisible(false);
    },
    handleClose: () => {
      setIsConfirmBackDialog(false);
    },
  };

  const confirmSendDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmRfiSend'),
    handleSubmit: () => {
      setIsConfirmSendDialog(false);
      onSendRfi(false);
    },
    handleClose: () => {
      setIsConfirmSendDialog(false);
    },
  };

  const confirmSendReplyDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmSendEmail'),
    handleSubmit: () => {
      setIsConfirmSendReplyDialog(false);
      onSendRfi(false);
    },
    handleClose: () => {
      setIsConfirmSendReplyDialog(false);
    },
  };

  const onCloseAttachment = () => {
    setIsAddVisible(false);
  };

  const getUploadedFiles = (data: any) => {
    if (uploadedFiles) {
      setUploadedFiles([...uploadedFiles, ...data]);
    } else {
      setUploadedFiles(data);
    }
  };

  const removeFile = (index: number) => {
    const file = uploadedFiles?.slice();
    file.splice(index, 1);
    setUploadedFiles(file);
  };

  const onClickSend = async (e: any, isAddToMail: any) => {
    if (e.validationGroup.validate().isValid) {
      if (vetRequestId && !content) {
        setIsNotValid(true);
      } else {
        setIsNotValid(false);
        if (isAddToMail) {
          onSendRfi(true);
        } else {
          if (isReplyMail) {
            setIsConfirmSendReplyDialog(true);
            return;
          }
          setIsConfirmSendDialog(true);
        }
      }
    }
  };

  const sendReplyEmail = async () => {
    const data = new FormData();
    data.append('vetRequestId', vetRequestId);
    data.append('toEmails', toAddress);
    data.append('ccEmails', ccAddress ? ccAddress : '');
    data.append('subject', subject);
    data.append('mailContent', content);
    for (const item in uploadedFiles) {
      data.append('attachedFiles', uploadedFiles[item]);
    }
    await api
      .post({ url: apiConfig.replyEmail, data: data }, setIsLoading)
      .then(() => {
        toast.success({ title: t('toast.sendEmailSuccessFully') });
        setIsVisible(false);
        if (getEmaiList()) getEmaiList();
      });
  };

  const onSendRfi = async (isAddToMail: any) => {
    if (isReplyMail) {
      sendReplyEmail();
      return;
    }
    const data = new FormData();
    data.append('vetRequestId', vetRequestId);
    data.append('ruleId', ruleId ? ruleId : '');
    data.append('to', toAddress);
    data.append('cc', ccAddress ? ccAddress : '');
    data.append('subject', subject);
    data.append('mailContent', content);
    for (const item in uploadedFiles) {
      data.append('attachedFiles', uploadedFiles[item]);
    }
    data.append('addToMailList', isAddToMail);
    data.append(
      'vetEmailType',
      isRFIClient ? RfiTemplateTypes.Client : RfiTemplateTypes.Vessel
    );

    uploadedFiles?.forEach((element: any) => {
      if (element?.attachmentId) {
        data.append('attachmentIds', element?.attachmentId);
      }
    });

    if (selectedMailIDList) {
      for (const item in selectedMailIDList) {
        data.append('vetRequestOutgoingEmailIds', selectedMailIDList[item]);
      }
    }
    const rfiData = data;
    await api
      .post(
        {
          url: getAPIRoute('vetRfiEmail', [
            vetRequestId,
            isRFIClient ? RfiTemplateTypes.Client : RfiTemplateTypes.Vessel,
          ]),
          data: rfiData,
        },
        setIsLoading
      )
      .then(() => {
        toast.success({
          title: isAddToMail
            ? t('toast.rFIEmailMailList')
            : t('toast.rFIEmailsent'),
        });
        setIsVisible(false);
        !isAddToMail && !selectedMailIDList && getRuleList(true);
        !isAddToMail && selectedMailIDList && getMailList(true);
        isAddToMail && getMailCount();
      });
  };

  const isLoaded = useMemo(() => {
    if (!isError) {
      if (vetRequestId) {
        if (shipDetails && macros) {
          return true;
        }
      } else {
        return true;
      }
    } else return false;
  }, [shipDetails, macros, isError]);

  return isLoaded ? (
    <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
      <div className="m-l-modal__header">
        <h2 className="modal-title">{getTitle()}</h2>
        <div className="m-l-modal-close-button-block">
          <Button
            className="app-c-btn app-c-icon-only-btn"
            onClick={handleBackClick}
          >
            <icons.IconCross />
          </Button>
        </div>
      </div>
      <div className="m-l-modal__body">
        <div className="mail-list-form h-100">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="m-c-form-group mb-btm-space-22">
              <div className="m-c-mail-compose-to-select-box">
                <span>{t('labels.to')}:</span>
                <TextBox
                  className="m-c-input-control m-c-select-box m-c-select-for-to"
                  value={toAddress}
                  onValueChanged={(e) => {
                    setToAddress(e.value);
                    setIsChanged(true);
                  }}
                  validationMessageMode="always"
                >
                  {vetRequestId && (
                    <Validator>
                      <RequiredRule message={t('errors.toAddressRequired')} />
                    </Validator>
                  )}
                </TextBox>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="m-c-form-group mb-btm-space-22">
              <div className="m-c-mail-compose-to-select-box">
                <span>{t('labels.cc')}:</span>
                <TextBox
                  className="m-c-input-control m-c-select-box m-c-select-for-cc"
                  value={ccAddress}
                  onValueChanged={(e) => {
                    setCcAddress(e.value);
                    setIsChanged(true);
                  }}
                ></TextBox>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="m-c-form-group mb-btm-space">
              <div className="m-c-mail-compose-subject-input-box">
                <span>{t('labels.subject')}:</span>
                <TextBox
                  className="m-c-input-control m-c-input-for-subject"
                  value={subject}
                  validationMessageMode="always"
                  onValueChanged={(e) => {
                    setSubject(e.value);
                    setIsChanged(true);
                  }}
                >
                  <Validator>
                    <RequiredRule message={t('errors.subjectRequired')} />
                  </Validator>
                </TextBox>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 m-c-flex-fill">
            <div className="m-c-form-group mb-btm-space m-c-html-editor-wrapper">
              <RFIHtmlEditor
                content={content}
                setContent={setContent}
                setIsChanged={setIsChanged}
                entities={entities}
                macros={macros}
                vetRequestId={vetRequestId}
                showEditor={showEditor}
                shipDetails={shipDetails}
                isNotValid={isNotValid}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="m-l-modal__footer">
        <div className="m-l-footer-left-block">
          {vetRequestId && (
            <>
              <a
                onClick={() => setIsAddVisible(true)}
                className="m-l-email-atch-icon-hldr"
              >
                <icons.Attach />
              </a>

              <div className="m-l-files-attached-list">
                {uploadedFiles &&
                  uploadedFiles?.map((file: any, index: number) => {
                    return (
                      file?.name && (
                        <div className="m-l-files-attached-block" key={index}>
                          <div className="m-l-files-attached-list">
                            <span>{file.name}</span>
                          </div>
                          <div
                            className="m-l-files-close-icon"
                            onClick={() => removeFile(index)}
                          >
                            <icons.Close />
                          </div>
                        </div>
                      )
                    );
                  })}
              </div>
            </>
          )}
        </div>

        <div className="m-l-footer-right-block">
          <Button
            text={t('buttons.cancel')}
            type="default"
            stylingMode="outlined"
            onClick={handleBackClick}
            className="app-c-btn app-c-btn--secondary"
          />
          {!vetRequestId && !isReplyMail ? (
            <Button
              text={t('buttons.save')}
              type="default"
              stylingMode="contained"
              onClick={onClickSave}
              disabled={isRFIEditable}
              className="app-c-btn app-c-btn--primary min-btn-width"
            />
          ) : (
            <>
              <Button
                text={t('buttons.send')}
                type="default"
                stylingMode="contained"
                onClick={(e) => {
                  onClickSend(e, false);
                }}
                className="app-c-btn app-c-btn--primary min-btn-width"
              />
              {!(selectedMailIDList && selectedMailIDList.length > 0) &&
              !isReplyMail ? (
                <Button
                  text={t('buttons.addtoMailList')}
                  type="default"
                  stylingMode="contained"
                  onClick={(e) => {
                    onClickSend(e, true);
                  }}
                  className="app-c-btn app-c-btn--primary "
                />
              ) : null}
            </>
          )}
        </div>
      </div>
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={isconfirmBackDialog}
      />
      <ConfirmDialog
        dialogConfig={confirmSendDialogConfig}
        isOpen={isconfirmSendDialog}
      />
      <ConfirmDialog
        dialogConfig={confirmSendReplyDialogConfig}
        isOpen={isconfirmSendReplyDialog}
      />
      <FileUploaderModal
        isVisible={isAddVisible}
        handleCancelClick={onCloseAttachment}
        attachmentType={undefined}
        attachmentTypeVet={undefined}
        getUploadedFiles={getUploadedFiles}
      />
    </Modal>
  ) : (
    <div></div>
  );
}
