import React from 'react';
import { IFormConfig } from '../../../types/form-builder';
import TextBox from 'devextreme-react/text-box';
import { NumberBox } from 'devextreme-react/number-box';
import SelectBox from 'devextreme-react/select-box';
import { CheckBox } from 'devextreme-react/check-box';
import TagBox from 'devextreme-react/tag-box';
import DateBox from 'devextreme-react/date-box';
import { Validator } from 'devextreme-react/validator';
import Switch from 'devextreme-react/switch';
import FieldArrayAddButton from '../field-array/FieldArrayAddButton';
import TextArea from 'devextreme-react/text-area';
import RadioGroup from 'devextreme-react/radio-group';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { Restricted } from '../../../contexts/PermissionContext';
import { Autocomplete } from 'devextreme-react/autocomplete';

function FormField(props: {
  config: IFormConfig;
  field: any;
  fieldState: any;
  fieldArray?: any;
  index?: any;
  appendValues?: any;
  appendLimit?: any;
  value?: any;
}) {
  const { config, field, fieldArray, index, appendValues, appendLimit, value } =
    props;

  const valueChanged = (e: any) => {
    if (
      config.fieldType === 'multiSelect' &&
      e.value.length !== e.previousValue.length
    ) {
      field.onChange(e.value);
    }
    if (config.fieldType !== 'multiSelect') {
      field.onChange(e.value);
    }
    config.onChange ? config.onChange(e, index) : null;
  };

  const keyDown = (e: any, config: any) => {
    const { event } = e;
    const str = event.key || String.fromCharCode(event.which);
    const expr = config.allowDecimal ? /^[-+,eE]$/ : /^[-+.,eE]$/;
    if (expr.test(str)) {
      event.preventDefault();
    }
  };

  const { dateFormat } = useDateFormat();

  return (
    <>
      {config.fieldType === 'text' && (
        <div
          className={
            config.fieldWrapperClass
              ? config.fieldWrapperClass
              : 'mb-btm-space m-c-form-group'
          }
        >
          <label className="m-l-input-label">
            {config.label}
            {config.isRequired && <span className="m-l-input-required">*</span>}
          </label>
          <TextBox
            inputAttr={{ 'data-testid': config.name }}
            name={config.name}
            onKeyUp={(e) =>
              config.onKeyUp ? config.onKeyUp(e, field) : undefined
            }
            onValueChanged={valueChanged}
            value={field.value}
            readOnly={config.readonly}
            className={
              config.fieldClass ? config.fieldClass : 'm-c-input-control'
            }
            maxLength={config.maxLength}
            disabled={config.disabled}
            placeholder={config.placeholder}
            validationMessageMode="always"
          >
            <Validator validationRules={config.rules} />
          </TextBox>
        </div>
      )}
      {config.fieldType === 'number' && (
        <div className="mb-btm-space m-c-form-group">
          <label className="m-l-input-label">
            {config.label}{' '}
            {config.isRequired && <span className="m-l-input-required">*</span>}{' '}
          </label>
          <NumberBox
            inputAttr={{
              maxlength: config.maxLength,
            }}
            className="m-c-input-control"
            elementAttr={{ 'data-testid': config.name }}
            onKeyDown={(e: any) => keyDown(e, config)}
            onValueChanged={valueChanged}
            value={field.value}
            name={config.name}
            disabled={config.disabled}
            placeholder={config.placeholder}
            step={0}
            validationMessageMode="always"
            format={config.format}
          >
            <Validator validationRules={config.rules} />
          </NumberBox>
        </div>
      )}
      {config.fieldType === 'select' && (
        <div
          className={
            config.fieldWrapperClass
              ? config.fieldWrapperClass
              : 'mb-btm-space m-c-form-group'
          }
        >
          <label
            className={
              config.labelClass ? config.labelClass : 'm-l-input-label'
            }
          >
            {config.label + ' ' + (config.showLabelIndex ? index + 1 : '')}
            {config.isRequired && <span className="m-l-input-required">*</span>}
          </label>
          <SelectBox
            ref={
              config.ref && config.isFieldArray
                ? (element: any) => (config.ref.current[index] = element)
                : config.ref
            }
            elementAttr={{ 'data-testid': config.name }}
            inputAttr={{ autocomplete: config.autocomplete }}
            name={config.name}
            dataSource={config.dataSource}
            displayExpr={config.displayExpr}
            searchExpr={config.searchExpr}
            valueExpr={config.valueExpr}
            onValueChanged={valueChanged}
            value={field.value}
            className="m-c-input-control  m-c-select-box"
            itemRender={config.itemRender}
            searchEnabled={!config.disableSearch}
            fieldRender={config.fieldRender}
            readOnly={config.readonly}
            disabled={config.disabled}
            placeholder={config.placeholder}
            validationMessageMode="always"
            dropDownOptions={config.dropDownOptions}
            onSelectionChanged={config.selectionChanged}
          >
            <Validator
              elementAttr={
                config.isFieldArray ? { id: index } : { id: config.name }
              }
              validationRules={config.rules}
            />
          </SelectBox>
        </div>
      )}

      {config.fieldType === 'checkbox' && (
        <div className="mb-3 m-c-checkbox m-c-checkbox-dark m-c-checkbox-normal">
          <CheckBox
            elementAttr={{ 'data-testid': config.name }}
            name={config.name}
            value={field.value}
            onValueChanged={valueChanged}
            text={config.label}
            disabled={config.disabled}
          />
        </div>
      )}
      {config.fieldType === 'switch' && (
        // <div className="mb-3  m-c-form-group m-c-tg-switch-field">
        <div className="mb-3  m-c-form-group">
          <label className="m-l-input-label">{config.label}</label>
          <div className="m-l-toggle-switch m-l-tg-switch-align ">
            <Restricted permission={config.permission} disableField={true}>
              <Switch
                elementAttr={{ 'data-testid': config.name }}
                name={config.name}
                value={field.value}
                onValueChanged={valueChanged}
              />
            </Restricted>
          </div>
        </div>
      )}
      {config.fieldType === 'multiSelect' && (
        <div className="mb-btm-space m-c-form-group">
          <label className="m-l-input-label">
            {config.label}
            {config.isRequired && <span className="m-l-input-required">*</span>}
          </label>
          {config.showFieldArrayAddButton && (
            <div className="m-l-input-group-with__btn-block">
              <div className="m-l-user-role-category-select-box">
                <TagBox
                  ref={
                    config.ref && config.isFieldArray
                      ? (element: any) => (config.ref.current[index] = element)
                      : config.ref
                  }
                  className="m-c-input-control m-c-multiselect-dropdown"
                  elementAttr={{ 'data-testid': config.name }}
                  name={config.name}
                  dataSource={config.dataSource}
                  showSelectionControls={true}
                  onValueChanged={valueChanged}
                  defaultValue={field.value}
                  value={field.value}
                  disabled={config.disabled ? value[index]?.bu == null : false}
                  displayExpr={config.displayExpr}
                  valueExpr={config.valueExpr}
                  searchEnabled={!config.disableSearch}
                  maxDisplayedTags={
                    config.maxDisplayedTags ? config.maxDisplayedTags : 2
                  }
                  showMultiTagOnly={config.showMultiTagOnly}
                  placeholder={config.placeholder}
                  validationMessageMode="always"
                  multiline={false}
                >
                  <Validator validationRules={config.rules} />
                </TagBox>
              </div>
              <FieldArrayAddButton
                fieldArray={fieldArray}
                index={index}
                appendValues={appendValues}
                appendLimit={appendLimit}
              ></FieldArrayAddButton>
            </div>
          )}
          {!config.showFieldArrayAddButton && (
            <div className="m-l-user-role-category-select-box">
              <TagBox
                ref={
                  config.ref && config.isFieldArray
                    ? (element: any) => (config.ref.current[index] = element)
                    : config.ref
                }
                className="m-c-input-control m-c-multiselect-dropdown"
                elementAttr={{ 'data-testid': config.name }}
                name={config.name}
                dataSource={config.dataSource}
                showSelectionControls={true}
                onValueChanged={valueChanged}
                defaultValue={field.value}
                value={field.value}
                disabled={
                  config.isUserRoles
                    ? config.disabled
                      ? value[index]?.bu == null
                      : false
                    : config.disabled
                }
                displayExpr={config.displayExpr}
                valueExpr={config.valueExpr}
                searchEnabled={!config.disableSearch}
                tagRender={config.tagRender}
                showClearButton={config.showClearButton}
                onOpened={config.onOpened}
                maxDisplayedTags={
                  config.maxDisplayedTags ? config.maxDisplayedTags : 2
                }
                showMultiTagOnly={config.showMultiTagOnly}
                placeholder={config.placeholder}
                validationMessageMode="always"
                multiline={false}
              >
                <Validator
                  elementAttr={
                    config.isFieldArray ? { id: index } : { id: config.name }
                  }
                  validationRules={config.rules}
                />
              </TagBox>
            </div>
          )}
        </div>
      )}
      {config.fieldType === 'date' && (
        <div
          className={
            config.fieldWrapperClass
              ? config.fieldWrapperClass
              : 'mb-btm-space m-c-form-group'
          }
        >
          <label className="m-l-input-label">
            {config.label}
            {config.isRequired && <span className="m-l-input-required">*</span>}
          </label>
          <DateBox
            className="m-c-input-control m-c-date-picker"
            elementAttr={{ 'data-testid': config.name }}
            name={config.name}
            value={field.value}
            onValueChanged={valueChanged}
            type="date"
            disabled={config.disabled}
            min={config.min}
            max={config.max}
            displayFormat={dateFormat}
            placeholder={config.placeholder}
            validationMessageMode="always"
          >
            <Validator validationRules={config.rules} />
          </DateBox>
        </div>
      )}
      {config.fieldType === 'radio' && (
        <div
          className={
            config.fieldWrapperClass
              ? config.fieldWrapperClass
              : 'mb-btm-space m-c-form-group m-c-radio-button__block'
          }
        >
          {config.label && (
            <label className="m-l-input-label">{config.label}</label>
          )}
          <div
            className={
              config.fieldClass ? config.fieldClass : 'm-c-radio-button'
            }
          >
            <RadioGroup
              name={config.name}
              value={field.value}
              items={config.items}
              onValueChanged={valueChanged}
              displayExpr={config.displayExpr}
              valueExpr={config.valueExpr}
              defaultValue={config.defaultValue}
              layout={config.layout}
              disabled={config.disabled}
            ></RadioGroup>
          </div>
        </div>
      )}
      {config.fieldType === 'textArea' && (
        <div
          className={
            config.fieldWrapperClass
              ? config.fieldWrapperClass
              : 'mb-btm-space m-c-form-group'
          }
        >
          <label className="m-l-input-label">{config.label}</label>
          <TextArea
            className={
              config.fieldClass
                ? config.fieldClass
                : 'm-c-input-control m-c-text-area-control m-c-text-area-control-h-138'
            }
            inputAttr={{ 'data-testid': config.name }}
            name={config.name}
            value={field.value}
            onValueChanged={valueChanged}
            disabled={config.disabled}
            placeholder={config.placeholder}
            validationMessageMode="always"
          ></TextArea>
        </div>
      )}
      {config.fieldType === 'autoComplete' && (
        <div
          className={
            config.fieldWrapperClass
              ? config.fieldWrapperClass
              : 'mb-btm-space m-c-form-group'
          }
        >
          <label className="m-l-input-label">
            {config.label}
            {config.isRequired && <span className="m-l-input-required">*</span>}
          </label>
          <Autocomplete
            name={config.name}
            dataSource={config.dataSource}
            value={field.value}
            onValueChanged={valueChanged}
            disabled={config.disabled}
            placeholder={config.placeholder}
            className="m-c-input-control  m-c-select-box"
            validationMessageMode="always"
            valueExpr={config.valueExpr}
            minSearchLength={
              config.minSearchLength ? config.minSearchLength : 4
            }
            itemRender={config.itemRender}
            onSelectionChanged={(e: any) =>
              config.selectionChanged
                ? config.selectionChanged(e, index)
                : undefined
            }
            dropDownOptions={config.dropDownOptions}
            // searchTimeout={5000}
          >
            <Validator validationRules={config.rules} />
          </Autocomplete>
        </div>
      )}
    </>
  );
}

export default FormField;
