import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '../../../components/common/grid/Grid';
import { IGridConfig } from '../../../types/grid';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import CustomStore from 'devextreme/data/custom_store';
import { useTranslation } from 'react-i18next';
import { CompanyStatus } from '../../../enums/status-enum';
import { useDataStore } from '../../../hooks/useDataStore';

export default function ListRequestorCompany() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getDataSource } = useDataStore();
  const [requestorCompanyList, setRequestorCompanies] = useState<any>();

  const getRequestorCompaniesList = async () => {
    const data = getDataSource('companyId', apiConfig.company, 10);
    setRequestorCompanies(data);
    // await api.get({ url: apiConfig.company }).then((data) => {
    //   setRequestorCompanies(data);
    // });
  };

  useEffect(() => {
    getRequestorCompaniesList();
  }, []);

  const onRowClick = (e: any) => {
    navigate('/company/edit', { state: { companyId: e.data.companyId } });
  };

  const cellPrepared = (e: any) => {
    if (e.rowType === 'data' && e.column.dataField === 'companyStatus') {
      e.cellElement.className =
        e.data.companyStatus === CompanyStatus.active
          ? 'status-active'
          : e.data.companyStatus === CompanyStatus.inActive
          ? 'status-inactive'
          : 'status-pending';
    }
  };

  const companyStatusDataSource = {
    store: new CustomStore({
      key: 'optionValueCode',
      loadMode: 'raw',
      load: () => {
        return api.get({ url: apiConfig.companyStatus });
      },
    }),
  };

  const marsAccountManagerDataSource = {
    store: new CustomStore({
      key: 'employeeId',
      loadMode: 'raw',
      load: () => {
        return api.get({ url: apiConfig.marsAccountManager });
      },
    }),
  };

  const gridConfig: IGridConfig = {
    dataSource: requestorCompanyList,
    testId: 'companiesGrid',
    // class: 'm-c-grid m-c-grid--company',
    addButtonPermission: 'Company.Add',
    rowClickPermission: 'Company.ViewDetail',
    cellPrepared: cellPrepared,
    remoteOperations: true,
    defaultColumns: [
      {
        caption: t('labels.companyId'),
        dataField: 'companyCode',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.companyName'),
        dataField: 'companyName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.companyType'),
        dataField: 'companyType',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.companyEmail'),
        dataField: 'companyEmail',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.phoneNumber'),
        dataField: 'phoneNumber',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.country'),
        dataField: 'countryName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.marsAccountManager'),
        dataField: 'marsAccountManager',
        dataType: 'string',
        minWidth: 150,
        lookup: {
          dataSource: marsAccountManagerDataSource,
          valueExpr: 'employeeId',
          displayExpr: 'employeeName',
        },
      },
      {
        caption: t('labels.status'),
        dataField: 'companyStatus',
        dataType: 'string',
        minWidth: 100,
        lookup: {
          dataSource: companyStatusDataSource,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
      },
    ],
    rowClick: onRowClick,
    showHeader: true,
    header: t('headers.companies'),
    showAddButton: true,
    showExport: true,
    excelFileName: 'Companies.xlsx',
    noDataText: t('labels.companyNoDataText'),
    onClickAddButton: () => {
      navigate('/company/add');
    },
  };

  return (
    <div className="inner-wrapper page-inner-wrap">
      <ul aria-label="breadcrumb" className="m-c-bredcrumb">
        <li>
          <a>{t('headers.companies')}</a>
        </li>
      </ul>
      <Grid gridConfig={gridConfig} />
    </div>
  );
}
