import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormBuilder from '../../../../common/form-builder/FormBuilder';
import { useDateFormat } from '../../../../../hooks/useDateFormat';

export default function MatrixForm() {
  const { t } = useTranslation();
  const { control, getValues, setValue } = useFormContext();
  const { dateFormat } = useDateFormat();
  const [matrixValidityDate, setmatrixValidityDate] = useState();
  const [matrixDate, setMatrixDate] = useState();

  const onChangeMatrixValidityDate = (e: any) => {
    setmatrixValidityDate(e.value);
  };

  const onChangeMatrixDate = (e: any) => {
    setMatrixDate(e.value);
    const fromDate = new Date(e.value);
    if (e.value && !matrixValidityDate) {
      setValue('matrixValidityDate', fromDate.setDate(fromDate.getDate() + 30));
    }
  };

  const validateMatrixValidityDate = (params: any) => {
    const matrixDate = getValues()?.matrixDate;
    if (matrixDate != '' && matrixDate != null) {
      const fromDate = new Date(matrixDate);
      const currentDate = new Date(params.value);
      return fromDate.getTime() <= currentDate.getTime();
    }
    return true;
  };

  const validateMatrixDate = (params: any) => {
    const matrixValidityDate = getValues()?.matrixValidityDate;
    if (matrixValidityDate != '' && matrixValidityDate != null) {
      const fromDate = new Date(matrixValidityDate);
      const currentDate = new Date(params.value);
      return fromDate.getTime() >= currentDate.getTime();
    }
    return true;
  };

  const matrixFormConfig = [
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'matrixDate',
      label: t('labels.matrixDate'),
      control: control,
      fieldType: 'date',
      format: dateFormat,
      onChange: onChangeMatrixDate,
      rules: [
        {
          type: 'custom',
          reevaluate: true,
          validationCallback: validateMatrixDate,
          message: t('errors.datebeforeMatrixDate'),
          ignoreEmptyValue: true,
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'matrixValidityDate',
      label: 'Matrix Validity Date',
      control: control,
      fieldType: 'date',
      format: dateFormat,
      onChange: onChangeMatrixValidityDate,
      rules: [
        {
          type: 'custom',
          reevaluate: true,
          validationCallback: validateMatrixValidityDate,
          message: t('errors.dateAfterMatrixDate'),
          ignoreEmptyValue: true,
        },
      ],
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={matrixFormConfig} />
      </div>
    </>
  );
}
