import DropDownButton from 'devextreme-react/drop-down-button';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import icons from '../../../../components/common/icons/icons';
import TextBox from 'devextreme-react/text-box';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import { getAPIRoute } from '../../../../utils/api-route';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { EmailSort } from '../../../../enums/email-sort-enum';

export default function Outbox(props: {
  vetRequestId: any;
  outboxList: any[];
  setMailDetail: any;
  setShowMailItem: any;
  setOutboxEmailList: any;
  sortBy: string;
  setSortBy: any;
  searchValue: string;
  setSearchValue: any;
  showMailItem: boolean;
  location: any;
}) {
  const {
    vetRequestId,
    outboxList,
    setMailDetail,
    setShowMailItem,
    setOutboxEmailList,
    sortBy,
    setSortBy,
    searchValue,
    setSearchValue,
    showMailItem,
    location,
  } = props;
  const { apiDateAndTimeFormat } = useDateFormat();
  const [defaultSort, setDefaultSort] = useState<number | undefined>();
  const textBox = useRef<TextBox>(null);
  const { setIsLoading } = useLoaderContext();
  const [vetRequestOutgoingEmailId, setVetRequestOutgoingEmailId] = useState(0);

  const actions = useMemo(() => {
    return [
      { id: 1, text: EmailSort.oldestOnTop },
      { id: 2, text: EmailSort.newestOnTop },
      { id: 3, text: EmailSort.subject },
      { id: 4, text: EmailSort.toAddress },
    ];
  }, []);

  const clickMailItem = (vetRequestOutgoingEmailId: number) => {
    outboxList.map((outboxEmail: any) => {
      if (outboxEmail.vetRequestOutgoingEmailId == vetRequestOutgoingEmailId) {
        outboxEmail.selected = true;
      } else {
        outboxEmail.selected = false;
      }
    });
    setShowMailItem(true);
    setVetRequestOutgoingEmailId(vetRequestOutgoingEmailId);
  };

  const getMailBody = async () => {
    await api
      .get(
        { url: getAPIRoute('vetOutboxEmailById', [vetRequestOutgoingEmailId]) },
        setIsLoading
      )
      .then((res) => {
        setMailDetail(res);
      });
  };

  useEffect(() => {
    if (vetRequestOutgoingEmailId && vetRequestOutgoingEmailId != 0) {
      getMailBody();
    }
  }, [vetRequestOutgoingEmailId]);

  useEffect(() => {
    switch (sortBy) {
      case EmailSort.oldestOnTop: {
        setDefaultSort(1);
        break;
      }
      case EmailSort.newestOnTop: {
        setDefaultSort(2);
        break;
      }
      case EmailSort.subject: {
        setDefaultSort(3);
        break;
      }
      case EmailSort.toAddress: {
        setDefaultSort(4);
        break;
      }
      default: {
        setDefaultSort(undefined);
        break;
      }
    }
  }, [sortBy]);

  const focusTextBox = useCallback(() => {
    textBox.current?.instance.focus();
  }, []);

  useEffect(() => {
    focusTextBox();
    if (outboxList.length > 0) {
      if (window.innerWidth > 767) {
        setFirstMailAsSelected();
      }
    } else {
      setShowMailItem(false);
    }
  }, [outboxList]);

  const setFirstMailAsSelected = () => {
    outboxList.map((outboxEmail: any, index: number) => {
      if (index == 0) {
        outboxEmail.selected = true;
      } else {
        outboxEmail.selected = false;
      }
    });
    setShowMailItem(true);
    setVetRequestOutgoingEmailId(outboxList[0].vetRequestOutgoingEmailId);
  };

  const sortByOldestOnTop = (outboxList: any) => {
    const outboxListCopy = outboxList.slice();
    setOutboxEmailList(
      outboxListCopy.sort((a: any, b: any) =>
        a.sendDate?.valueOf().localeCompare(b.sendDate?.valueOf())
      )
    );
  };

  const sortByNewestOnTop = (outboxList: any) => {
    const outboxListCopy = outboxList.slice();
    setOutboxEmailList(
      outboxListCopy.sort((a: any, b: any) =>
        b.sendDate?.valueOf().localeCompare(a.sendDate?.valueOf())
      )
    );
  };

  const sortByToAddress = (outboxList: any) => {
    const outboxListCopy = outboxList.slice();
    setOutboxEmailList(
      outboxListCopy.sort((a: any, b: any) =>
        a.to?.valueOf().localeCompare(b.to?.valueOf())
      )
    );
  };

  const sortBySubject = (outboxList: any) => {
    const outboxListCopy = outboxList.slice();
    setOutboxEmailList(
      outboxListCopy.sort((a: any, b: any) =>
        a.subject?.valueOf().localeCompare(b.subject?.valueOf())
      )
    );
  };

  const onClickSort = (e: any) => {
    if (outboxList && outboxList.length > 0) {
      switch (e.itemData.id) {
        case 1: {
          sortByOldestOnTop(outboxList);
          setSortBy(EmailSort.oldestOnTop);
          break;
        }
        case 2: {
          sortByNewestOnTop(outboxList);
          setSortBy(EmailSort.newestOnTop);
          break;
        }
        case 3: {
          sortBySubject(outboxList);
          setSortBy(EmailSort.subject);
          break;
        }
        case 4: {
          sortByToAddress(outboxList);
          setSortBy(EmailSort.toAddress);
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  const onInputSearch = async (e: any) => {
    setSearchValue(e.event.target.value);
    await api
      .get({
        url:
          location.pathname != '/email'
            ? getAPIRoute('searchVetRequestOutboxEmails', [vetRequestId])
            : apiConfig.searchOutboxEmails,
        params: { searchString: e.event.target.value },
      })
      .then((data) => {
        if (data && data.length > 0) {
          if (defaultSort == 1) {
            sortByOldestOnTop(data);
          } else if (defaultSort == 2) {
            sortByNewestOnTop(data);
          } else if (defaultSort == 3) {
            sortBySubject(data);
          } else if (defaultSort == 4) {
            sortByToAddress(data);
          }
        } else {
          setOutboxEmailList([]);
        }
      });
  };

  return (
    <div className="m-l-mail-left-data-wrapper">
      <div className="m-l-mail-left-header">
        <div className="m-l-vsl-dropdown-btn m-l-gray-dropdown-wiout-border">
          <DropDownButton
            items={actions}
            keyExpr="id"
            displayExpr="text"
            text="Sort Order"
            onItemClick={onClickSort}
            selectedItemKey={defaultSort}
            useSelectMode={true}
            focusStateEnabled={true}
          />
        </div>
        <div className="right-block">
          <div className="m-l-input-search-area-block">
            <TextBox
              ref={textBox}
              placeholder="Search"
              className="m-c-input-control"
              onInput={onInputSearch}
              value={searchValue}
            ></TextBox>
            <div className="m-c-search-icon">
              <icons.Search />
            </div>
          </div>
        </div>
      </div>
      {outboxList && outboxList.length > 0 && (
        <div
          className={
            vetRequestId
              ? 'm-l-msg-items-wrapper  m-l-msg-items-wrapper--inside-tab'
              : 'm-l-msg-items-wrapper'
          }
        >
          {outboxList.map((email: any) => {
            return (
              <div
                key={email.vetRequestOutgoingEmailId}
                className={
                  email.selected && showMailItem
                    ? 'm-l-msg-item selected'
                    : 'm-l-msg-item'
                }
                onClick={() => clickMailItem(email.vetRequestOutgoingEmailId)}
              >
                <div className="m-l-email-profile-pic-wrap">
                  {/* <img
                    src={imageURL.avatarlg}
                    className="img-responsive m-l-email-profile-pic"
                    alt="profile-pic"
                  /> */}
                  <icons.Profile />
                </div>
                <div className="m-l-emlr-data-wrap">
                  <div className="m-l-emlr-name">{email.to}</div>
                  <div className="m-l-emlr-desc">{email.subject}</div>
                </div>
                <div className="m-l-eml-cm-tm">
                  <div className="m-l-eml-time">
                    {apiDateAndTimeFormat(email.sendDate)}
                  </div>
                  {email.noOfAttachments && email.noOfAttachments != 0 ? (
                    <div className="m-l-eml-attch">
                      <div className="m-l-eml-atth-icon">
                        {' '}
                        <icons.IconAttach12 />
                      </div>
                      <div className="m-l-eml-atth-fl">
                        {email.noOfAttachments}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
