import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import {
  getAdditionalInfoToken,
  getEmail,
  getToken,
  getUserName,
} from '../../../utils/jwt-decode';
import toast from '../../../utils/toast';
import 'stimulsoft-reports-js/Css/stimulsoft.designer.office2013.whiteblue.css';
import 'stimulsoft-reports-js/Css/stimulsoft.viewer.office2013.whiteblue.css';

declare let Stimulsoft: any;
declare let StiJsViewer: any;
export default function ReportViewer(props: {
  queryId: any;
  layoutId: any;
  reportCode: any;
  reportName: any;
  runNow: any;
}) {
  let options: any;
  let viewer: any;

  const [loaded, setLoaded] = useState(false);
  const { setIsLoading } = useLoaderContext();
  const { queryId, layoutId, reportCode, reportName, runNow } = props;
  const userEmail = getEmail();
  const userName = getUserName();
  const { t } = useTranslation();

  useEffect(() => {
    setLoaded(false);
    const url = `${process.env.REACT_APP_BASE_URL}${apiConfig.reportViewer}?queryId=${queryId}&reportCode=${reportCode}&layoutId=${layoutId}`;
    loadScript(url);
  }, [runNow, layoutId]);

  useEffect(() => {
    if (loaded) {
      getReport();
    }
  }, [loaded]);

  const loadScript = (url: string) => {
    setIsLoading(true);
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    script.setAttribute('data-group', 'stimulsoft');
    script.onload = () => {
      setLoaded(true);
      setIsLoading(false);
    };
    document.body.appendChild(script);
  };

  const getReport = () => {
    const url = `${process.env.REACT_APP_BASE_URL}${apiConfig.reportViewer}?queryId=${queryId}&reportCode=${reportCode}&layoutId=${layoutId}`;
    customHttpOverrides();
    Stimulsoft.Viewer.parameters.requestUrl = url;
    options = new Stimulsoft.Viewer.StiViewerOptions();
    options.toolbar.showSendEmailButton = true;
    options.toolbar.newPageAfter = true;
    options.toolbar.showOpenButton = false;
    options.toolbar.showAboutButton = false;
    options.server.requestTimeout = 1800;
    options.height = '1200px';
    options.appearance.allowMobileMode = false;
    options.appearance.scrollbarsMode = true;
    getExportFormat();
    options.toolbar.printDestination = 'Direct';
    viewer = new Stimulsoft.Viewer.StiViewer(options, 'StiViewer', false);
    viewer.renderHtml('viewer');
  };

  const getExportFormat = () => {
    options.exports.showExportToOpenDocumentWriter = false;
    options.exports.showExportToCsv = false;
    options.exports.showExportToDbf = false;
    options.exports.showExportToDif = false;
    options.exports.showExportToExcelXml = false;
    options.exports.showExportToPowerPoint = false;
    options.exports.showExportToHtml = false;
    options.exports.showExportToHtml5 = false;
    options.exports.showExportToJson = false;
    options.exports.showExportToMht = false;
    options.exports.showExportToOpenDocumentCalc = false;
    options.exports.showExportToRtf = false;
    options.exports.showExportToSylk = false;
    options.exports.showExportToXml = false;
    options.exports.showExportToText = false;
    options.exports.openAfterExport = false;
  };

  const customHttpOverrides = () => {
    const token = getToken();
    const additionalInfoToken = getAdditionalInfoToken();
    stimulSoftViewerPost(token, additionalInfoToken);
  };

  const stimulSoftViewerPost = (
    token: string | null,
    additionalInfoToken: string | null
  ) => {
    StiJsViewer.prototype.postAjax = function (
      url: any,
      data: { action: string; responseType: any; printAction: any },
      callback: (arg0: string, arg1: any) => void
    ) {
      // console.log(data, 'data')
      if (data && data.action === 'GetReport') {
        setIsLoading(true);
        this.options.paramsVariablesStartValues = null;
        if (this.controls.toolbar) {
          this.controls.toolbar.setEnabled(false);
          if (this.controls.navigatePanel) {
            this.controls.navigatePanel.setEnabled(false);
          }
        }
      }
      if (data && data.action === 'InitVars') {
        this.options.email.defaultEmailAddress = userEmail;
        this.options.email.defaultEmailSubject = 'MARS - ' + reportName;
        this.options.email.defaultEmailMessage =
          userName +
          ' shared ' +
          reportName +
          ' with you \n' +
          '<br/>' +
          'MARS team';
      }
      const xmlHttp = this.createConnection();
      this.openConnection(xmlHttp, url, data ? data.responseType : 'text');
      if (this.options.server.requestTimeout !== 0) {
        setTimeout(() => {
          if (xmlHttp.readyState < 4) {
            xmlHttp.abort();
          }
        }, this.options.server.requestTimeout * 1000);
      }
      xmlHttp.onreadystatechange = () => {
        setIsLoading(false);
        // console.log(this.options);

        if (xmlHttp.readyState === 4) {
          this.service.isRequestInProcess = false;
          clearTimeout(this.dashboardProcessTimeout);
          let status = 0;
          try {
            status = xmlHttp.status;
          } catch (e) {
            console.log(e);
          }
          if (status === 0) {
            callback('ServerError:Timeout response from the server.', this);
          } else if (status === 400) {
            const response = JSON.parse(xmlHttp.response);
            if (response) {
              toast.error({
                title: 'Error',
                message: response.Message,
              });
            }
          } else if (status === 200) {
            if (data.action != 'EmailReport') {
              callback(
                xmlHttp.response ? xmlHttp.response : xmlHttp.responseText,
                this
              );
            }
          } else {
            if (this.options.server.showServerErrorPage && xmlHttp.response) {
              this.controls.reportPanel.innerHTML = xmlHttp.response;
            }
            callback(
              'ServerError:' + status + ' - ' + xmlHttp.statusText,
              this
            );
          }
          if (data.action === 'EmailReport' && status === 200) {
            toast.success({
              title: t('toast.emailSent'),
            });
          }
        }
      };
      this.service.isRequestInProcess = true;
      const params = this.createPostParameters(data, false);
      xmlHttp.setRequestHeader('Authorization', 'Bearer ' + token);
      xmlHttp.setRequestHeader('Additional-Info', additionalInfoToken);
      xmlHttp.send(params);
    };
  };

  return (
    <div className="app-l-report-designer">
      <div id="viewer"></div>
      <div className="app-l-designer-close low-res">
        <i className="icon-close-light"></i>
      </div>
    </div>
  );
}
