import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormConfig } from '../../../../types/form-builder';
import FieldArray from '../../../common/field-array/FieldArray';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import toast from '../../../../utils/toast';

export default function VettingForm(props: { isMarsUser: any }) {
  const { t } = useTranslation();
  const { control, getValues } = useFormContext();
  const [vesselTypes, setVesselTypes] = useState<any>();
  const { isMarsUser } = props;

  const checkValue = (e: any) => {
    const values = getValues();
    const exist = values.contractVettings.filter(
      (contractVetting: any) => contractVetting.vesselType === e.value
    );
    if (exist.length > 1) {
      toast.error({
        title: 'Error',
        message: t('toast.serviceType', { type: e.value }),
      });
    }
  };

  const getVesselTypes = async () => {
    await api
      .get({ url: apiConfig.optionValue + 'ShipTypeLevel2' })
      .then((data: any) => {
        setVesselTypes(data);
      });
  };

  useEffect(() => {
    getVesselTypes();
  }, []);

  const fieldArrayappendValues = {
    pscDefeciancyId: 0,
    defectCode: null,
    defectDescription: '',
    riskArea: null,
    subArea: null,
    rootCauseCode: null,
    actionCode: null,
  };

  const vettingFieldArray = useFieldArray({
    control: control,
    name: 'contractVettings',
  });

  const formConfig: IFormConfig[] = [
    {
      name: 'vesselType',
      label: t('labels.vesselType'),
      control: control,
      fieldType: 'select',
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueText',
      disabled: !isMarsUser,
      dataSource: vesselTypes,
      class: 'col-xl col-lg col-md-12 col-sm-12 col-12',
      isFieldArray: true,
      onChange: checkValue,
    },
    {
      name: 'numberOfVets',
      label: t('labels.numberOfVets'),
      control: control,
      fieldType: 'number',
      disabled: !isMarsUser,
      maxLength: 18,
      class:
        'col-xl col-lg col-md-12 col-sm-12 col-12 m-l-custom-eight-space-col',
      isFieldArray: true,
    },
    {
      name: 'vetPrice',
      label: t('labels.vetPrice'),
      control: control,
      fieldType: 'number',
      disabled: !isMarsUser,
      class:
        'col-xl col-lg col-md-12 col-sm-12 col-12 m-l-custom-eight-space-col',
      isFieldArray: true,
    },
    {
      name: 'additionalVetPrice',
      label: t('labels.additionalVetPrice'),
      control: control,
      fieldType: 'number',
      disabled: !isMarsUser,
      class:
        'col-xl col-lg col-md-12 col-sm-12 col-12 m-l-custom-eight-space-col',
      isFieldArray: true,
    },
    {
      name: 'autoVetPrice',
      label: t('labels.autoVet'),
      control: control,
      fieldType: 'number',
      disabled: !isMarsUser,
      class:
        'col-xl col-lg col-md-12 col-sm-12 col-12 m-l-custom-eight-space-col',
      isFieldArray: true,
    },
    {
      name: 'sameSIREVetPrice',
      label: t('labels.sameSIREVet'),
      control: control,
      fieldType: 'number',
      disabled: !isMarsUser,
      class:
        'col-xl col-lg col-md-12 col-sm-12 col-12 m-l-custom-eight-space-col',
      isFieldArray: true,
    },
  ];

  return (
    <div className="row">
      <FieldArray
        showAddButton={true}
        fieldArray={vettingFieldArray}
        fieldArrayName="contractVettings"
        formConfig={formConfig}
        appendValues={fieldArrayappendValues}
        buttonWrapperParentClass="col-xl-auto col-lg-auto col-md-auto col-sm-auto col-12 m-l-custom-sixteen-space-col"
        buttonWrapperClass="mb-btm-space-30 m-c-form-group m-l-button-vertical-center-align m-l-fm-icon-btn"
      />
    </div>
  );
}
