import Button from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import ValidationGroup from 'devextreme-react/validation-group';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDimensionsUnit } from '../../../../hooks/useDimensionsUnit';
import { IOptionvalue } from '../../../../types/master-data';
import { IModalConfig } from '../../../../types/modal';
import icons from '../../../common/icons/icons';
import Modal from '../../../common/modal/Modal';
import VesselRestrictionsAtApproachesForm from './VesselRestrictionsAtApproachesForm';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import toast from '../../../../utils/toast';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../../types/dialog';

export default function EditVesselRestrictionsAtApproaches(props: {
  isVisible: boolean;
  handleCancelClick: any;
  isEdit: boolean;
  berthCode: any;
  getVesselApproaches: any;
  berthPermissibleVesselRestrictionId: any;
}) {
  const { t } = useTranslation();
  const { setIsLoading } = useLoaderContext();
  const { dimensionUnit, feetToMeterConversion, meterToFeetConversion } =
    useDimensionsUnit();
  const {
    handleCancelClick,
    isVisible,
    isEdit,
    berthCode,
    getVesselApproaches,
    berthPermissibleVesselRestrictionId,
  } = props;
  const [shipTypes, setShipTypes] = useState<IOptionvalue[]>([]);
  const [vesselApproaches, setVesselApproaches] = useState<any>();
  const [showConfirmBack, setShowConfirmBack] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const modalConfig: IModalConfig = {
    width: 1011,
    height: 340,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };
  const validationGroup: any = useRef();
  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-edit-name-popup',
    };
  }, []);

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setShowConfirmBack(false);
      handleCancelClick();
    },
    handleClose: () => {
      setShowConfirmBack(false);
    },
  };

  const getShipTypes = async () => {
    await api
      .get({
        url: apiConfig.shipType,
      })
      .then((res: IOptionvalue[]) => {
        setShipTypes(res);
      });
  };

  const getVesselApproachesById = async () => {
    if (berthCode) {
      await api
        .get({
          url: apiConfig.berthVesselRestrictions,
          params: {
            berthPermissibleVesselRestrictionId:
              berthPermissibleVesselRestrictionId,
          },
        })
        .then((res: any) => {
          setVesselApproaches(res);
        });
    }
  };

  const formDefaultValues = {
    shipType: '',
    airDraught: null,
    maxDraftAtApproaches: null,
    depthAtApproaches: null,
    minUkcAtApproaches: null,
    minUkcValue: null,
  };

  const approachesForm = useForm({
    defaultValues: formDefaultValues,
  });

  useEffect(() => {
    getShipTypes();
  }, []);

  useEffect(() => {
    if (berthPermissibleVesselRestrictionId) {
      getVesselApproachesById();
    }
  }, [berthPermissibleVesselRestrictionId]);

  const { isDirty } = approachesForm.formState;
  const onCloseClick = () => {
    if (isDirty) {
      setShowConfirmBack(true);
    } else {
      setShowConfirmBack(false);
      handleCancelClick();
    }
  };

  useEffect(() => {
    approachesForm.reset();
    if (vesselApproaches) {
      approachesForm.reset(
        {
          shipType: vesselApproaches.shipType,
          airDraught: vesselApproaches.airDraught,
          maxDraftAtApproaches:
            vesselApproaches.maxDraftAtApproaches &&
            meterToFeetConversion(vesselApproaches.maxDraftAtApproaches),
          depthAtApproaches:
            vesselApproaches.depthAtApproaches &&
            meterToFeetConversion(vesselApproaches.depthAtApproaches),
          minUkcValue:
            vesselApproaches.minUkcValue &&
            meterToFeetConversion(vesselApproaches.minUkcValue),
          minUkcAtApproaches: vesselApproaches.minUkcAtApproaches,
        },
        { keepDirty: true }
      );
    }
  }, [vesselApproaches, dimensionUnit]);

  const onClickSave = () => {
    const requiredValidation = validationGroup.current.instance.validate();
    if (requiredValidation.isValid) {
      setIsConfirmModalOpen(true);
    }
  };

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      handleSaveClick();
      setIsConfirmModalOpen(false);
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };

  const handleSaveClick = () => {
    const requiredValidation = validationGroup.current.instance.validate();
    if (requiredValidation.isValid) {
      const approachesFormValues = approachesForm.getValues();
      const requestBody = {
        berthPermissibleVesselRestrictionId: 0,
        berthCode: berthCode,
        shipType: approachesFormValues.shipType,
        airDraught: approachesFormValues.airDraught,
        maxDraftAtApproaches:
          approachesFormValues.maxDraftAtApproaches &&
          feetToMeterConversion(approachesFormValues.maxDraftAtApproaches),
        depthAtApproaches:
          approachesFormValues.depthAtApproaches &&
          feetToMeterConversion(approachesFormValues.depthAtApproaches),
        minUkcValue:
          approachesFormValues.minUkcValue &&
          feetToMeterConversion(approachesFormValues.minUkcValue),
        minUkcAtApproaches: approachesFormValues.minUkcAtApproaches,
      };
      if (berthPermissibleVesselRestrictionId != null) {
        requestBody.berthPermissibleVesselRestrictionId =
          berthPermissibleVesselRestrictionId;
      } else {
        requestBody.berthPermissibleVesselRestrictionId = 0;
      }
      saveVesselApproaches(requestBody);
    }
  };
  const saveVesselApproaches = async (data: any) => {
    await api
      .put(
        {
          url: apiConfig.berthVesselRestrictions,
          data: data,
        },
        setIsLoading
      )
      .then(() => {
        getVesselApproaches();
        toast.success({
          title: t('toast.savedSuccessfully'),
        });
        handleCancelClick();
      });
  };
  return (
    <div className="m-l-model-popup__wrap" data-testid="editName">
      <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
        <div className="m-l-modal__header">
          {isEdit && (
            <h2 className="modal-title">
              Edit Vessel Restrictions at Approaches
            </h2>
          )}
          {!isEdit && (
            <h2 className="modal-title">
              Add Vessel Restrictions at Approaches
            </h2>
          )}
          <div className="m-l-modal-close-button-block">
            <Button
              className="app-c-btn app-c-icon-only-btn"
              onClick={() => onCloseClick()}
            >
              <icons.IconCross />
            </Button>
          </div>
        </div>
        <div className="m-l-modal__body">
          <ScrollView width="100%" height="100%">
            <div className="m-l-edit-form-panel">
              <FormProvider {...approachesForm}>
                <ValidationGroup ref={validationGroup}>
                  <VesselRestrictionsAtApproachesForm shipTypes={shipTypes} />
                </ValidationGroup>
              </FormProvider>
            </div>
          </ScrollView>
        </div>
        <div className="m-l-modal__footer">
          <Button
            className="app-c-btn app-c-btn--secondary min-btn-width"
            elementAttr={{ 'data-testid': 'backButton' }}
            onClick={() => onCloseClick()}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            className="app-c-btn app-c-btn--primary min-btn-width"
            elementAttr={{ 'data-testid': 'saveButton' }}
            onClick={() => onClickSave()}
          >
            {t('buttons.save')}
          </Button>
        </div>
        <ConfirmDialog
          dialogConfig={confirmBackDialogConfig}
          isOpen={showConfirmBack}
        />
        <ConfirmDialog
          dialogConfig={confirmSaveDialogConfig}
          isOpen={isConfirmModalOpen}
        />
      </Modal>
    </div>
  );
}
