import React, { useEffect, useState } from 'react';
import { IGridConfig } from '../../../../types/grid';
import { IBerthCargoType } from '../../../../types/port-management';
import Grid from '../../../common/grid/Grid';
import CargoTypeEdit from '../cargo-type-edit-modal/CargoTypeEdit';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';

export default function CargoTypeGrid(props: {
  berthCode: any;
  isFromViewInfoModal?: boolean;
}) {
  const { berthCode, isFromViewInfoModal } = props;
  const [showEditModal, setShowEditModal] = useState(false);
  const [cargoData, setCargoData] = useState<IBerthCargoType[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [berthProductHandleId, setBerthProductHandleId] = useState(null);

  const getCargoData = async () => {
    await api
      .get({ url: apiConfig.berthCargo, params: { berthCode: berthCode } })
      .then((res) => {
        setCargoData(res.data);
      });
  };

  useEffect(() => {
    getCargoData();
  }, []);

  const onClickAddNew = () => {
    setIsEdit(false);
    setShowEditModal(true);
  };

  const onRowClick = (e: any) => {
    if (!isFromViewInfoModal) {
      setBerthProductHandleId(e.key.berthProductHandleId);
      setIsEdit(true);
      setShowEditModal(true);
    }
  };

  const gridConfig: IGridConfig = {
    class: 'm-c-grid m-l-grid-with--top-border m-c-righticon-grid',
    testId: 'vesselCargoTypeGrid',
    height: '350',
    dataSource: cargoData,
    rowClick: onRowClick,
    onClickAddButton: onClickAddNew,
    defaultColumns: [
      {
        caption: 'Berth Type',
        dataField: 'berthType',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'Main Cargo Type',
        dataField: 'mainCargoType',
        dataType: 'string',
        minWidth: 150,
      },
    ],
    showHeader: true,
    showExport: false,
    showAddButton: !isFromViewInfoModal,
  };

  return (
    <div className="m-l-tab-accordion-item-content-section">
      <Grid gridConfig={gridConfig} />
      {showEditModal && (
        <CargoTypeEdit
          isVisible={showEditModal}
          handleCancelClick={() => setShowEditModal(false)}
          berthCode={berthCode}
          getCargoData={getCargoData}
          berthProductHandleId={berthProductHandleId}
          setBerthProductHandleId={setBerthProductHandleId}
        />
      )}
    </div>
  );
}
