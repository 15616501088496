import SelectBox from 'devextreme-react/select-box';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Entity } from '../../../enums/entity-enum';
import { IFormConfig } from '../../../types/form-builder';
import { IEntityAttribute, IMasterEntity } from '../../../types/master-data';
import { IAdditionalDataCaptureFields } from '../../../types/rules';
import FieldArray from '../../common/field-array/FieldArray';

export default function AdditionalDataCapturing(props: {
  entities: IMasterEntity[];
  attributes: IEntityAttribute[];
  ruleDetails?: any;
  getAttributes?: (entityId: number) => Promise<void>;
}) {
  const { entities, attributes, ruleDetails } = props;
  const { t } = useTranslation();
  const { control, setValue, getValues } = useFormContext();
  const attributeRef = useRef<SelectBox[]>([]);
  const mandatoryRef = useRef<SelectBox[]>([]);

  const [filteredEntity, setFilteredEntity] = useState<IMasterEntity[]>([]);

  const additionalDataFieldArray = useFieldArray({
    control: control,
    name: 'fieldArray',
  });

  const fieldArrayappendValues: IAdditionalDataCaptureFields = {
    ruleAdditionalDataId: 0,
    ruleId: 0,
    entityId: null,
    attributeId: null,
    isMandatory: false,
  };

  useEffect(() => {
    const tempEntities = JSON.parse(JSON.stringify(entities));
    tempEntities.map((item: any) => {
      item.visible =
        item.entityName !== Entity.Incident &&
        item.entityName !== Entity.PSC &&
        item.entityName !== Entity.IncidentHistory &&
        item.entityName !== Entity.ManagementHistory &&
        item.entityName !== Entity.Port
          ? true
          : false;
    });
    setFilteredEntity(tempEntities);
  }, [entities]);

  useEffect(() => {
    attributeRef.current?.map((ref, index) => {
      ref?.instance
        .getDataSource()
        .filter([
          'masterEntityId',
          '=',
          getValues().fieldArray[index].entityId,
        ]);
      ref?.instance.getDataSource()?.load();
    });
  }, [attributes]);

  useEffect(() => {
    if (ruleDetails && attributes.length > 0) {
      mandatoryRef.current?.map((ref, index) => {
        const selectedItem =
          attributeRef.current[index]?.instance.option('selectedItem');
        if (selectedItem && !selectedItem?.isEditable) {
          ref?.instance.getDataSource().filter(['value', '=', false]);
        } else {
          ref?.instance.getDataSource().filter(null);
        }
        ref?.instance.getDataSource()?.load();
      });
    }
  }, [attributes, ruleDetails]);

  const onChangeEntity = async (e: any, index: number) => {
    setValue(`fieldArray[${index}].attributeId`, null);
    attributeRef.current[index]?.instance
      .getDataSource()
      .filter(['masterEntityId', '=', e.value]);
    attributeRef.current[index]?.instance.getDataSource()?.load();
  };

  const validationCallback = (params: any) => {
    const index = parseInt(params.validator._$element[0].id);
    const rowData = getValues().fieldArray[index];
    if (params.value === null && (rowData.entityId || rowData.attributeId)) {
      return false;
    }
    return true;
  };

  const onChangeAttribute = (e: any, index: number) => {
    setValue(`fieldArray[${index}].isMandatory`, false);
    const selectedItem =
      attributeRef.current[index]?.instance.option('selectedItem');
    if (selectedItem && !selectedItem?.isEditable) {
      mandatoryRef.current[index]?.instance
        .getDataSource()
        .filter(['value', '=', false]);
      mandatoryRef.current[index]?.instance.getDataSource()?.load();
    } else {
      mandatoryRef.current[index]?.instance.getDataSource().filter(null);
      mandatoryRef.current[index]?.instance.getDataSource()?.load();
    }
  };

  const uniqueAttributeCheck = (params: any) => {
    const index = parseInt(params.validator._$element[0].id);
    const attributeIdIndex = getValues().fieldArray.findIndex(
      (item: any) => item.attributeId === params.value
    );
    return attributeIdIndex < 0 || attributeIdIndex === index;
  };

  const additionalDataFormConfig: IFormConfig[] = useMemo(() => {
    return [
      {
        class:
          'col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 m-l-row-custom-column m-l-custom-sixteen-space-col',
        name: 'entityId',
        fieldType: 'select',
        control: control,
        label: t('labels.entity'),
        displayExpr: 'displayName',
        valueExpr: 'masterEntityId',
        dataSource: filteredEntity,
        isFieldArray: true,
        fieldWrapperClass: 'mb-3 m-c-form-group',
        onChange: onChangeEntity,
        rules: [
          {
            type: 'custom',
            message: t('errors.required'),
            reevaluate: true,
            validationCallback: validationCallback,
          },
        ],
      },
      {
        class:
          'col-md-6 col-sm-6 col-lg-3 col-xs-12 m-l-row-custom-column m-l-custom-sixteen-space-col',
        name: 'attributeId',
        fieldType: 'select',
        onChange: onChangeAttribute,
        control: control,
        label: t('labels.attribute'),
        displayExpr: 'displayName',
        valueExpr: 'masterAttributeId',
        dataSource: attributes,
        isFieldArray: true,
        fieldWrapperClass: 'mb-3 m-c-form-group',
        ref: attributeRef,
        dropDownOptions: {
          resizeEnabled: true,
          width: '500px',
        },
        rules: [
          {
            type: 'custom',
            message: t('errors.required'),
            reevaluate: true,
            validationCallback: validationCallback,
          },
          {
            type: 'custom',
            message: t('errors.attributeAlreadyAdded'),
            reevaluate: true,
            validationCallback: uniqueAttributeCheck,
            ignoreEmptyValue: true,
          },
        ],
      },
      {
        class:
          'col-md-6 col-sm-6 col-lg-3 col-xs-12 m-l-row-custom-column m-l-custom-sixteen-space-col',
        name: 'isMandatory',
        fieldType: 'select',
        control: control,
        label: t('labels.mandatory'),
        dataSource: [
          { name: 'Yes', value: true },
          { name: 'No', value: false },
        ],
        displayExpr: 'name',
        valueExpr: 'value',
        isFieldArray: true,
        showFieldArrayAddButton: true,
        disableSearch: true,
        fieldWrapperClass: 'mb-3 m-c-form-group',
        ref: mandatoryRef,
        rules: [
          {
            type: 'custom',
            message: t('errors.required'),
            reevaluate: true,
            validationCallback: validationCallback,
          },
        ],
      },
    ];
  }, [attributes, filteredEntity]);

  return (
    <>
      <h5 className="m-l-sub-section-heading">
        {t('headers.additionalDataCapturing')}
      </h5>
      <div className="m-l-sub-section">
        <FieldArray
          fieldArrayName="fieldArray"
          fieldArray={additionalDataFieldArray}
          formConfig={additionalDataFormConfig}
          appendValues={fieldArrayappendValues}
          showAddButton={true}
        />
      </div>
    </>
  );
}
