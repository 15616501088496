import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormConfig } from '../../../../types/form-builder';
import FormBuilder from '../../../common/form-builder/FormBuilder';

export default function GeneralForm(props: { isMarsUser: any }) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { isMarsUser } = props;

  const equipmentFormConfig: IFormConfig[] = [
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'agreedVetNumbers',
      label: t('labels.agreedVetNumbers'),
      control: control,
      fieldType: 'number',
      disabled: !isMarsUser,
      maxLength: 18,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'numberOfActiveUsers',
      label: t('labels.numberOfActiveUsers'),
      control: control,
      fieldType: 'number',
      disabled: !isMarsUser,
      maxLength: 7,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'startDate',
      label: t('labels.commencementDate'),
      control: control,
      fieldType: 'date',
      isRequired: true,
      disabled: !isMarsUser,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'endDate',
      label: t('labels.endDate'),
      control: control,
      fieldType: 'date',
      isRequired: true,
      disabled: !isMarsUser,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'setupCost',
      label: t('labels.setupCosts'),
      control: control,
      fieldType: 'number',
      disabled: !isMarsUser,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'escalationPercentage',
      label: t('labels.escalationPercentage'),
      control: control,
      fieldType: 'number',
      disabled: !isMarsUser,
      rules: [
        {
          type: 'pattern',
          pattern: '^([0-9]{0,5})([.][0-9]{0,2})?$',
          message: t('errors.sizeExceed'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'otherCost',
      label: t('labels.otherCosts'),
      control: control,
      fieldType: 'number',
      disabled: !isMarsUser,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'tax',
      label: t('labels.taxes'),
      control: control,
      fieldType: 'number',
      disabled: !isMarsUser,
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={equipmentFormConfig} />
      </div>
    </>
  );
}
