import React, { useCallback, useEffect, useState } from 'react';
import Grid from '../../../common/grid/Grid';
import { IGridConfig } from '../../../../types/grid';
import icons from '../../../common/icons/icons';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { useTranslation } from 'react-i18next';
import { IDialogConfig } from '../../../../types/dialog';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { getAPIRoute } from '../../../../utils/api-route';
import CustomStore from 'devextreme/data/custom_store';
import toast from '../../../../utils/toast';
import SelectBox from 'devextreme-react/select-box';
import FileSaver from 'file-saver';
import imageURL from '../../../../assets/images';
import { Restricted } from '../../../../contexts/PermissionContext';
import { attachmentTypeEnum } from '../../../../enums/attachment-type-enum';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import { Tooltip } from 'devextreme-react/tooltip';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import { CompanyType } from '../../../../enums/company-type-enum';
import { getOPtionValueByType } from '../../../../services/master-data-service';
import { OptionTypes } from '../../../../enums/option-type-enum';

export default function AttachmentGrid(props: {
  vesselAttachments: any;
  getAttachments: any;
  vetRequestId: any;
  imoNumber?: string;
  isMarsUser?: any;
  setIsMarsUser?: any;
  isHostedUser?: any;
  setIsHostedUser?: any;
  isRequestedUser?: any;
  setIsRequestedUser?: any;
  companyUser?: any;
}) {
  const {
    vesselAttachments,
    getAttachments,
    companyUser,
    isRequestedUser,
    vetRequestId,
    imoNumber,
    isMarsUser,
    isHostedUser,
  } = props;
  const [attachmentTypes, setAttachmentTypes] = useState<any[]>([]);
  const [attachmentType, setAttachmentType] = useState();
  const [attachmentTypeCode, setAttachmentTypeCode] = useState();
  const [attachmentTypeOld, setAttachmentTypeOld] = useState();
  const [attachmentId, setAttachmentId] = useState();
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmRestore, setShowConfirmRestore] = useState(false);
  const { t } = useTranslation();
  const { apiDateAndTimeFormat } = useDateFormat();
  const { setIsLoading } = useLoaderContext();
  const actions = attachmentTypes?.filter(
    (item) =>
      item.optionValueCode == attachmentTypeEnum.Global ||
      item.optionValueCode == attachmentTypeEnum.Vessel ||
      item.optionValueCode == attachmentTypeEnum.Berth
  );

  const actions2 = attachmentTypes?.filter(
    (item) => item.optionValueText != ''
  );

  const actions3 = attachmentTypes?.filter(
    (item) => item.optionValueCode != attachmentTypeEnum.Global
  );

  const actionList = [
    { isDeleted: true, optionValue: 'Deleted' },
    { isDeleted: false, optionValue: 'Not Deleted' },
  ];

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.attachmentTypeChange', {
      attachmentType: attachmentType,
    }),
    handleSubmit: () => {
      changeAttachmentType();
    },
    handleClose: () => {
      getAttachments();
      setShowConfirmSave(false);
    },
  };

  const confirmDeleteDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmDelete'),
    handleSubmit: () => {
      setShowConfirmDelete(false);
      deleteAttachment();
    },
    handleClose: () => {
      setShowConfirmDelete(false);
    },
  };

  const confirmRestoreDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmRestore'),
    handleSubmit: () => {
      setShowConfirmRestore(false);
      restoreAttachment();
    },
    handleClose: () => {
      setShowConfirmRestore(false);
    },
  };

  const deleteAttachment = async () => {
    await api
      .put(
        {
          url: apiConfig.deleteEditAttachment + attachmentId,
          params: { isDelete: true },
        },
        setIsLoading
      )
      .then(() => {
        getAttachments();
        toast.success({
          title: t('toast.deletedAttachmentSuccessfully'),
        });
      });
  };

  const restoreAttachment = async () => {
    await api
      .put(
        {
          url: apiConfig.deleteEditAttachment + attachmentId,
          params: { isRestore: true },
        },
        setIsLoading
      )
      .then(() => {
        getAttachments();
        toast.success({
          title: t('toast.restoredAttachmentSuccessfully'),
        });
      });
  };

  const rowPrepared = (e: any) => {
    if (e?.data?.isDeleted === true) {
      e.rowElement.className =
        'dx-row dx-data-row dx-column-lines grid-row-disabled';
    }
  };

  const disableDelete = useCallback(
    (data) => {
      return (
        (data?.uploaderCompanyType === CompanyType.RequestorCompany &&
          data?.attachmentTypeCode === attachmentTypeEnum.Vet &&
          isMarsUser &&
          !data?.isDeleted) ||
        (data?.uploaderCompanyType === CompanyType.Mars &&
          data.attachmentTypeCode === attachmentTypeEnum.Vet &&
          isRequestedUser &&
          !data?.isDeleted)
      );
    },
    [isMarsUser, isRequestedUser, isHostedUser]
  );

  const actionTemplate = (e: any) => {
    const attachmentTooltipId = 'ToolTip' + String(e.data.attachmentId);
    return (
      <React.Fragment>
        {e.data.attachmentType == 'Global' && isMarsUser ? (
          <div className="m-l-grid-delete-icon-wrapper left-align">
            {e.data.isDeleted ? (
              <Restricted
                permission={
                  vetRequestId
                    ? 'VetRequest.RestoreAttachments'
                    : 'Ship.RestoreAttachments'
                }
              >
                <div id={attachmentTooltipId}>
                  <icons.ArchiveIcon />
                  <Tooltip
                    target={`#${attachmentTooltipId}`}
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    position="right"
                    contentRender={() => {
                      return (
                        <div className="m-l-tooltip-wrapper">
                          {t('labels.restore')}
                        </div>
                      );
                    }}
                  />
                </div>
              </Restricted>
            ) : (
              <Restricted
                permission={
                  vetRequestId
                    ? 'VetRequest.DeleteAttachments'
                    : 'Ship.DeleteAttachments'
                }
              >
                {' '}
                <div id={attachmentTooltipId}>
                  <icons.IconDelete />{' '}
                  <Tooltip
                    target={`#${attachmentTooltipId}`}
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    position="right"
                    contentRender={() => {
                      return (
                        <div className="m-l-tooltip-wrapper">
                          {t('labels.delete')}
                        </div>
                      );
                    }}
                  />
                </div>
              </Restricted>
            )}
          </div>
        ) : (
          <React.Fragment>
            {e.data.attachmentType == 'Global' ? null : (
              <div
                className="m-l-grid-delete-icon-wrapper left-align"
                style={
                  disableDelete(e.data)
                    ? { opacity: 0.5, pointerEvents: 'none', cursor: 'default' }
                    : undefined
                }
              >
                {e.data.isDeleted ? (
                  <Restricted
                    permission={
                      vetRequestId
                        ? 'VetRequest.RestoreAttachments'
                        : 'Ship.RestoreAttachments'
                    }
                  >
                    {' '}
                    <div id={attachmentTooltipId}>
                      <icons.ArchiveIcon />
                      <Tooltip
                        target={`#${attachmentTooltipId}`}
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        position="right"
                        contentRender={() => {
                          return (
                            <div className="m-l-tooltip-wrapper">
                              {t('labels.restore')}
                            </div>
                          );
                        }}
                      />
                    </div>
                  </Restricted>
                ) : (
                  <Restricted
                    permission={
                      vetRequestId
                        ? 'VetRequest.DeleteAttachments'
                        : 'Ship.DeleteAttachments'
                    }
                  >
                    {' '}
                    <div id={attachmentTooltipId}>
                      <icons.IconDelete />{' '}
                      <Tooltip
                        target={`#${attachmentTooltipId}`}
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        position="right"
                        contentRender={() => {
                          return (
                            <div className="m-l-tooltip-wrapper">
                              {t('labels.delete')}
                            </div>
                          );
                        }}
                      />
                    </div>
                  </Restricted>
                )}
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const actionTemplate1 = (e: any) => {
    if (e.value == 'PNG') {
      return (
        <div
          className="m-l-file-icon-wrapper"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div className="m-l-user-image-block">
            <div className="m-l-avatar-block">
              <img src={imageURL.png} className="img-fluid" alt="Logo" />
            </div>
          </div>
        </div>
      );
    } else if (e.value == 'PDF') {
      return (
        <div
          className="m-l-file-icon-wrapper"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div className="m-l-user-image-block">
            <div className="m-l-avatar-block">
              <img src={imageURL.pdf} className="img-fluid" alt="Logo" />
            </div>
          </div>
        </div>
      );
    } else if (e.value == 'DOC') {
      return (
        <div
          className="m-l-file-icon-wrapper"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div className="m-l-user-image-block">
            <div className="m-l-avatar-block">
              <img src={imageURL.doc} className="img-fluid" alt="Logo" />
            </div>
          </div>
        </div>
      );
    } else if (e.value == 'XLS') {
      return (
        <div
          className="m-l-file-icon-wrapper"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div className="m-l-user-image-block">
            <div className="m-l-avatar-block">
              <img src={imageURL.xls} className="img-fluid" alt="Logo" />
            </div>
          </div>
        </div>
      );
    } else if (e.value == 'JPEG') {
      return (
        <div
          className="m-l-file-icon-wrapper"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div className="m-l-user-image-block">
            <div className="m-l-avatar-block">
              <img src={imageURL.jpeg} className="img-fluid" alt="Logo" />
            </div>
          </div>
        </div>
      );
    } else if (e.value == 'JPG') {
      return (
        <div
          className="m-l-file-icon-wrapper"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div className="m-l-user-image-block">
            <div className="m-l-avatar-block">
              <img src={imageURL.jpg} className="img-fluid" alt="Logo" />
            </div>
          </div>
        </div>
      );
    } else if (e.value == 'DOCX') {
      return (
        <div
          className="m-l-file-icon-wrapper"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div className="m-l-user-image-block">
            <div className="m-l-avatar-block">
              <img src={imageURL.docx} className="img-fluid" alt="Logo" />
            </div>
          </div>
        </div>
      );
    } else if (e.value == 'XLSX') {
      return (
        <div
          className="m-l-file-icon-wrapper"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div className="m-l-user-image-block">
            <div className="m-l-avatar-block">
              <img src={imageURL.xlsx} className="img-fluid" alt="Logo" />
            </div>
          </div>
        </div>
      );
    }
  };

  const onRowClick = (e: any) => {
    if (e.event?.target?.cellIndex === 6 && !e.data?.isDeleted) {
      return;
    }

    if (e.event.target.cellIndex || e.event.target.cellIndex == 0) {
      if (e.data.isDeleted) {
        toast.error({
          title: 'Error',
          message: t('errors.deletedAttachment'),
        });
      } else {
        api
          .get({
            url: apiConfig.deleteEditAttachment + e.data.attachmentId,
            responseType: 'blob',
          })
          .then((res) => {
            FileSaver.saveAs(new Blob([res]), e.data.attachmentName);
          });
      }
    } else if (e.event.target.nodeName == 'path') {
      if (e.data.isDeleted) {
        setAttachmentId(e.data.attachmentId);
        setShowConfirmRestore(true);
      } else {
        setAttachmentId(e.data.attachmentId);
        setShowConfirmDelete(true);
      }
    }
  };

  const onSuccessChangeAttachmentType = async () => {
    toast.custom({
      title: t('toast.convertedSuccessfully'),
      message: t('toast.convertedSuccessfullyContent', {
        attachmentTypeOld: attachmentTypeOld,
        attachmentTypeNew: attachmentType,
      }),
    });
  };

  const findAttachmentName = async (name: any) => {
    const typeCode = attachmentTypes.filter(
      (item) => item.optionValueText === name
    );
    setAttachmentTypeCode(typeCode[0].optionValueCode);
  };

  const changeAttachmentType = async () => {
    if (vetRequestId) {
      await api
        .put(
          {
            url: getAPIRoute('vetRequestChangeAttachmentType', [
              vetRequestId,
              attachmentId,
            ]),
            params: { attachmentType: attachmentTypeCode },
          },
          setIsLoading
        )
        .then(() => {
          getAttachments();
          onSuccessChangeAttachmentType();
        });
    } else {
      await api
        .put(
          {
            url: getAPIRoute('changeAttachmentType', [attachmentId]),
            params: { attachmentType: attachmentTypeCode },
          },
          setIsLoading
        )
        .then(() => {
          getAttachments();
          onSuccessChangeAttachmentType();
        });
    }

    setShowConfirmSave(false);
  };

  const attachmentTypeSource = {
    store: new CustomStore({
      key: 'optionValueCode',
      loadMode: 'raw',
      load: () => {
        return getOPtionValueByType(OptionTypes.AttachmentTypes) as any;
      },
    }),
    filter: ['optionValueCode', '<>', 'C'],
  };

  const getAttachmentTypes = async () => {
    getOPtionValueByType(OptionTypes.AttachmentTypes)?.then((res: any[]) => {
      const types = res ? JSON.parse(JSON.stringify(res)) : [];
      types?.map((item: any) => {
        if (
          item.optionValueCode === attachmentTypeEnum.Berth ||
          item.optionValueCode === attachmentTypeEnum.Company
        ) {
          item.visible = false;
        }
      });
      setAttachmentTypes(types);
    });
  };

  const onClickSave = (id: any, e: any) => {
    setAttachmentType(e.value);
    setAttachmentTypeOld(e.previousValue);
    findAttachmentName(e.value);
    setAttachmentId(id.attachmentId);
    setShowConfirmSave(true);
  };

  useEffect(() => {
    if (imoNumber) {
      if (vetRequestId) {
        getAttachments();
      } else {
        getAttachments();
      }
      getAttachmentTypes();
      companyUser();
    }
  }, [imoNumber]);

  const cellTemplate = (e: any) => {
    let newDataSource = actions;
    if (!(e.data.attachmentTypeCode === attachmentTypeEnum.Berth)) {
      const data = actions.filter(
        (item: any) => item.optionValueCode != attachmentTypeEnum.Berth
      );
      newDataSource = data;
    }
    return (
      <div className="m-l-vsl-dropdown-btn m-l-gray-dropdown-wiout-border dropdown-inside-grid">
        <Restricted
          permission={
            vetRequestId
              ? 'VetRequest.ChangeAttachmentType'
              : 'Ship.ChangeAttachmentType'
          }
        >
          <SelectBox
            dataSource={
              vetRequestId
                ? isMarsUser
                  ? actions2
                  : e.data?.attachmentTypeCode == attachmentTypeEnum.Global
                  ? newDataSource
                  : actions3
                : newDataSource
            }
            valueExpr="optionValueText"
            displayExpr="optionValueText"
            disabled={
              companyUser() ||
              (isHostedUser &&
                e.data.attachmentTypeCode == attachmentTypeEnum.Global) ||
              e.data.attachmentTypeCode === attachmentTypeEnum.Berth
            }
            defaultValue={e.value}
            onValueChanged={(ch: any) => {
              onClickSave(e.data, ch);
            }}
          />
        </Restricted>
      </div>
    );
  };

  const gridConfig: IGridConfig = {
    dataSource: vesselAttachments,
    rowPrepared: rowPrepared,
    defaultColumns: [
      {
        caption: t('labels.attachmentName'),
        dataField: 'attachmentName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.uploadedBy'),
        dataField: 'uploadedBy',
        dataType: 'string',
        minWidth: 150,
        width: '20%',
      },
      {
        caption: t('labels.uploadedOn'),
        dataField: 'uploadedOn',
        dataType: 'date',
        // format: 'dd MMM yyyy ',
        minWidth: 150,
        width: '20%',
        cellTemplate: 'statusTemplate',
      },
      {
        caption: t('labels.size'),
        dataField: 'attachmentSize',
        dataType: 'string',
        minWidth: 150,
        width: '13%',
      },
      {
        caption: 'Attachment type',
        dataField: 'attachmentType',
        dataType: 'string',
        visible: !isRequestedUser,
        minWidth: 150,
        width: '13%',
        cellTemplate: 'cellTemplate',
        lookup: {
          dataSource: attachmentTypeSource,
          valueExpr: 'optionValueText',
          displayExpr: 'optionValueText',
        },
      },
      {
        caption: t('labels.fileType'),
        dataField: 'fileType',
        dataType: 'string',
        minWidth: 150,
        allowFiltering: false,
        allowSorting: false,
        cellTemplate: 'actionTemplate1',
        width: '8%',
      },

      {
        caption: t('labels.action'),
        dataField: 'isDeleted',
        dataType: 'string',
        minWidth: 140,
        cellTemplate: 'actionTemplate',
        width: '5%',
        lookup: {
          dataSource: actionList,
          valueExpr: 'isDeleted',
          displayExpr: 'optionValue',
        },
      },
    ],
    showHeader: false,
    hidePagination: false,
    rowClick: onRowClick,
    noDataText: t('labels.noAttachmentsFound'),
  };
  return (
    <div className="m-c-grid m-c-grid-in-tab m-c-auto-scroll-grid no-border-top">
      <Grid
        gridConfig={gridConfig}
        actionTemplate={actionTemplate}
        actionTemplate1={actionTemplate1}
        cellTemplate={cellTemplate}
        statusTemplate={dateTimeCellTemplate}
      />
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={showConfirmSave}
      />
      <ConfirmDialog
        dialogConfig={confirmDeleteDialogConfig}
        isOpen={showConfirmDelete}
      />
      <ConfirmDialog
        dialogConfig={confirmRestoreDialogConfig}
        isOpen={showConfirmRestore}
      />
    </div>
  );
  function dateTimeCellTemplate(data: any) {
    const formatedDate = apiDateAndTimeFormat(data.value);
    return <div className="m-l-badge-container">{formatedDate}</div>;
  }
}
