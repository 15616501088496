import Button from 'devextreme-react/button';
import Switch from 'devextreme-react/switch';
import React, { useEffect, useState, useRef } from 'react';
import Grid from '../../../common/grid/Grid';
import { IGridConfig } from '../../../../types/grid';
import { useTranslation } from 'react-i18next';
import toast from '../../../../utils/toast';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../../types/dialog';
import {
  IBuRuleActive,
  IBuRuleClone,
  IBuRuleCopy,
} from '../../../../types/rules';
import VettingRuleClone from '../rule-clone/VettingCloneRule';
import BUCopyPopover from '../copy-vetting-rules/BUCopyPopover';
import { VettingRuleTypes } from '../../../../enums/vettingrules-type-enum';
import { useNavigate } from 'react-router-dom';
import {
  Restricted,
  usePermission,
} from '../../../../contexts/PermissionContext';
import DataGrid from 'devextreme-react/data-grid';
import CheckBox from 'devextreme-react/check-box';
import { useDataStore } from '../../../../hooks/useDataStore';
import { useLoaderContext } from '../../../../contexts/LoaderContext';

export default function VettingRules(props: {
  ruleBuName: any;
  buId: any;
  setDisableCopyBtn?: any;
  disableCopyBtn?: any;
  showPopover?: any;
  setShowPopver?: any;
  showAdd?: any;
}) {
  const { t } = useTranslation();
  const [buRulesList, setBuRulesList] = useState<any>([]);
  const {
    buId,
    setDisableCopyBtn,
    disableCopyBtn,
    showPopover,
    setShowPopver,
    showAdd,
  } = props;
  const [gridInstance, setGridInstance] = useState<any>();
  const [selection, setSelection] = useState<any>({
    allRules: false,
    marsRules: true,
    buRules: true,
    activeRules: true,
    inActiveRules: false,
  });
  const [selectAllAuto, setSelectAllAuto] = useState<boolean>(false);
  const [activeConfirmDialog, setActiveConfirmDialog] = useState(false);
  const [showClonePop, setShowClonePop] = useState(0);
  const { setIsLoading } = useLoaderContext();
  const [copyRuleIds, setCopyRuleIds] = useState([]);
  const { ruleBuName } = props;
  const [selectAllCheckBox, setSelectAllCheckBox] = useState([]) as any;
  const [checkBoxUpdating, setCheckBoxUpdating] = useState(false);
  const navigate = useNavigate();
  const { findFeature, featureList } = usePermission();
  const ruleGridRef = useRef<DataGrid>(null);
  const { getDataSource } = useDataStore();
  const confirmStatusDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      setActiveConfirmDialog(false);
    },
    handleClose: () => {
      buRulesList?.reload();
      setActiveConfirmDialog(false);
    },
  };

  const [activeDialogConfig, setActiveDialogConfig] = useState(
    confirmStatusDialogConfig
  );
  //Grid height calculation
  const sepRef: any = useRef();
  const [height, setHeight] = useState('100%');
  function logIt() {
    const offsetTop = sepRef.current.getBoundingClientRect().top;
    const h = window.innerHeight - offsetTop - 17 + 'px';
    setHeight(h);
  }

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });
  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);
    if (windowDimenion.winWidth > 767) {
      window.addEventListener('resize', logIt);
      window.addEventListener('load', logIt);
    }
    return () => {
      window.removeEventListener('resize', detectSize);
      window.removeEventListener('resize', logIt);
      window.removeEventListener('load', logIt);
    };
  }, [windowDimenion]);
  //height

  const activateRule = async (isactive: boolean, ruleId: any, buId: any) => {
    const ruleStatusJson: IBuRuleActive = {
      ruleId: ruleId,
      isActive: isactive,
      buId: buId,
    };
    api
      .put({ url: apiConfig.ruleStatus, data: ruleStatusJson }, setIsLoading)
      .then(() => {
        buRulesList?.reload();
      });
  };

  useEffect(() => {
    getBuRulesList();
  }, [buId]);

  useEffect(() => {
    ruleGridRef.current?.instance.columnOption(
      7,
      'visible',
      findFeature('BURules.Clone')
    );
  }, [featureList]);

  useEffect(() => {
    const dataGrid = gridInstance;
    if (dataGrid) {
      if (!selection.inActiveRules) {
        dataGrid.filter(['isActive', '=', true]);
      }
    }
  }, [buRulesList, gridInstance]);

  useEffect(() => {
    if (copyRuleIds && copyRuleIds.length > 0) {
      setDisableCopyBtn(false);
    } else {
      setDisableCopyBtn(true);
    }
  }, [copyRuleIds]);

  const onGridInitialized = (e: any) => {
    setGridInstance(e.component);
  };

  const getBuRulesList = async () => {
    if (buId && buId !== undefined) {
      const data = getDataSource(
        'ruleId',
        `${apiConfig.rules}?buId=${buId}`,
        10
      );
      setBuRulesList(data);
    }
  };

  const onRowClick = (e: any) => {
    if (!findFeature('BURules.ViewDetail')) {
      return;
    } else if (
      ![
        'dx-switch-on',
        'dx-switch-off',
        'dx-switch-handle',
        'dx-button-content',
      ].includes(e.event.target.className)
    ) {
      navigate('/rules/edit', {
        state: {
          buId,
          ruleType: e.data.ruleTypeCode,
          ruleId: e.data.ruleId,
          isActive: e.data.isActive,
        },
      });
    }
  };

  const onRowSelectionChanged = (e: any) => {
    setCopyRuleIds([]);
    const copyRows = e.selectedRowsData
      .filter((x: any) => x.ruleTypeCode === VettingRuleTypes.BU)
      .map((y: any) => {
        return y.ruleId;
      });
    if (copyRows.length > 0) {
      setCopyRuleIds(copyRows);
    }

    const deselectRowKeys: any = [];
    e.selectedRowsData.forEach((item: any) => {
      if (!isSelectable(item)) deselectRowKeys.push(e.component.keyOf(item));
    });
    if (deselectRowKeys.length) {
      e.component.deselectRows(deselectRowKeys);
    }
    setCheckBoxUpdating(true);
    const obj = { ...selectAllCheckBox };
    obj.option = { value: isSelectAll(e.component) };
    setSelectAllCheckBox(obj);
    setCheckBoxUpdating(false);
  };

  const isSelectable = (item: any) => {
    return item.ruleTypeCode !== VettingRuleTypes.Global;
  };

  const isSelectAll = (dataGrid: any) => {
    let items: any = [];
    dataGrid
      .getDataSource()
      .store()
      .load()
      .done(function (data: any) {
        items = data;
      });
    const selectableItems = items.filter(isSelectable);
    const selectedRowKeys = dataGrid.option('selectedRowKeys');
    if (!selectedRowKeys.length) {
      return false;
    }
    return selectedRowKeys.length >= selectableItems.length ? true : undefined;
  };

  const onEditorPreparing = (e: any) => {
    const dataGrid = e.component;
    if (e.command === 'select') {
      if (e.parentType === 'dataRow' && e.row) {
        if (!isSelectable(e.row.data)) e.editorOptions.disabled = true;
      } else if (e.parentType === 'headerRow') {
        e.editorOptions.onInitialized = (e: any) => {
          setSelectAllCheckBox(e.component);
        };
        e.editorOptions.value = isSelectAll(dataGrid);
        e.editorOptions.onValueChanged = (e: any) => {
          if (!e.event) {
            if (e.previousValue && !checkBoxUpdating) {
              e.component.option('value', e.previousValue);
            }
            return;
          }
          if (isSelectAll(dataGrid) === e.value) {
            return;
          }
          e.value ? dataGrid.selectAll() : dataGrid.deselectAll();
          e.event.preventDefault();
        };
      }
    }
  };

  const yesNoDataSource = [
    {
      name: t('labels.active'),
      value: true,
    },
    {
      name: t('labels.inActive'),
      value: false,
    },
  ];

  const gridConfig: IGridConfig = {
    testId: 'buRulesListGrid',
    dataSource: buRulesList,
    remoteOperations: true,
    defaultColumns: [
      {
        caption: t('labels.ruleName'),
        dataField: 'ruleName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.vesselType'),
        dataField: 'ruleVesselTypes',
        dataType: 'string',
        minWidth: 150,
        cellTemplate: 'actionTemplate1',
      },
      {
        caption: t('labels.applicableIncoTerms'),
        dataField: 'ruleIncoTerms',
        dataType: 'string',
        minWidth: 150,
        cellTemplate: 'cellTemplate',
      },
      {
        caption: t('labels.ruleDetails'),
        dataField: 'ruleDetails',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.ruleType'),
        dataField: 'ruleType',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.status'),
        dataField: 'isActive',
        alignment: 'center',
        dataType: 'string',
        minWidth: 100,
        cellTemplate: 'statusTemplate',
        allowFiltering: true,
        allowSorting: true,
        lookup: {
          dataSource: yesNoDataSource,
          valueExpr: 'value',
          displayExpr: 'name',
        },
      },
      {
        caption: t('labels.action'),
        dataField: 'action',
        dataType: 'string',
        minWidth: 100,
        width: 100,
        cellTemplate: 'actionTemplate',
        allowFiltering: false,
        allowSorting: false,
        alignment: 'center',
      },
    ],
    rowClick: onRowClick,
    hidePagination: false,
    showSelection: true,
    initialized: onGridInitialized,
    selectionChanged: onRowSelectionChanged,
  };

  const vesselTypeCellTemplate = (data: any) => {
    return (
      <div className="m-l-badge-container">
        {data.value[0]?.vesselType}
        {data.value.length > 1 && (
          <span className="badge">{data.value.length - 1}+</span>
        )}
      </div>
    );
  };

  const incoTermsCellTemplate = (data: any) => {
    return (
      <div className="m-l-badge-container">
        {data.value[0]?.incoTerms}
        {data.value.length > 1 && (
          <span className="badge">{data.value.length - 1}+</span>
        )}
      </div>
    );
  };

  const changeSelection = (key: string, e: any) => {
    const newState = !selection[key];
    if (selectAllAuto) {
      setSelectAllAuto(false);
    } else {
      const allRules = Object.keys(selection).reduce((prev: any, curr: any) => {
        if (curr === 'allRules' || curr === key) return prev;
        return prev && selection[curr];
      }, newState);
      if (allRules !== selection.allRules) {
        setSelectAllAuto(true);
      }
      setSelection({ ...selection, allRules, [key]: newState });
      applyFilter(e, key);
    }
  };

  const applyFilter = (e: any, value: any) => {
    const dataGrid = gridInstance;
    const marsRulesFilter = ['ruleTypeCode', '=', VettingRuleTypes.Global];
    const buRulesFilter = ['ruleTypeCode', '=', VettingRuleTypes.BU];
    const activeFilter = ['isActive', '=', true];
    const inActiveFilter = ['isActive', '=', false];

    const { allRules, marsRules, buRules, activeRules, inActiveRules } =
      selection;
    let filterArr: any = [];

    if (e.value === true) {
      if (marsRules === true || value === 'marsRules') {
        filterArr = [...filterArr, marsRulesFilter];
      }
      if (buRules === true || value === 'buRules') {
        filterArr = [...filterArr, buRulesFilter];
      }
      if (activeRules === true || value === 'activeRules') {
        filterArr = [...filterArr, activeFilter];
      }
      if (inActiveRules === true || value === 'inActiveRules') {
        filterArr = [...filterArr, inActiveFilter];
      }
      if (allRules === false && value === 'allRules' && e.value === true) {
        if (marsRules === false) {
          filterArr = [...filterArr, marsRulesFilter];
        }
        if (buRules === false) {
          filterArr = [...filterArr, buRulesFilter];
        }
        if (activeRules === false) {
          filterArr = [...filterArr, activeFilter];
        }
        if (inActiveRules === false) {
          filterArr = [...filterArr, inActiveFilter];
        }
      }
    } else {
      filterArr = [
        marsRulesFilter,
        buRulesFilter,
        activeFilter,
        inActiveFilter,
      ] as any;
      if (marsRules === false || value === 'marsRules') {
        const index = filterArr.indexOf(marsRulesFilter);
        filterArr.splice(index, 1);
      }
      if (buRules === false || value === 'buRules') {
        const index = filterArr.indexOf(buRulesFilter);
        filterArr.splice(index, 1);
      }
      if (activeRules === false || value === 'activeRules') {
        const index = filterArr.indexOf(activeFilter);
        filterArr.splice(index, 1);
      }
      if (inActiveRules === false || value === 'inActiveRules') {
        const index = filterArr.indexOf(inActiveFilter);
        filterArr.splice(index, 1);
      }
    }

    filterArr = filterArr.reduce((prevItem: any, currentItem: any) => {
      let foundFilter = false;
      if (!prevItem.length) return [currentItem];
      const newFilter = (prevItem || []).map((item: any) => {
        if (item[0] === currentItem[0]) {
          foundFilter = true;
          return [item, 'or', currentItem];
        }
        return item;
      });
      if (!foundFilter) {
        newFilter.push(currentItem);
      }
      return newFilter;
    }, []);

    dataGrid.filter(filterArr);
  };

  const cellRender = (data: any) => {
    return (
      <div className="m-l-field-wrap">
        <div className="m-l-toggle-switch">
          <Restricted permission="BURules.ActivateInactivate">
            <Switch
              elementAttr={{ 'data-testid': 'statusToggleswitch' }}
              defaultValue={data.value}
              onValueChanged={(e) => {
                handleActiveInactiveChange(e, data.data);
              }}
            />
          </Restricted>
        </div>
      </div>
    );
  };

  const handleActiveInactiveChange = async (e: any, rule: any) => {
    if (e.event) {
      const dialogConfig = {
        title: t('toast.confirmation'),
        content: e.value
          ? t('toast.activeBuRuleStatus')
          : t('toast.inactiveBuRuleStatus'),
        handleSubmit: () => {
          activateRule(e.value, rule?.ruleId, buId);
          setActiveConfirmDialog(false);
        },
      };
      setActiveDialogConfig({
        ...confirmStatusDialogConfig,
        ...dialogConfig,
      });
      setActiveConfirmDialog(true);
    }
  };

  const cloneRule = async (cloneRuleObject: IBuRuleClone) => {
    const buid: string = buId as string;
    const cloneBuRuleObject = { ...cloneRuleObject };
    cloneBuRuleObject.buId = parseInt(buid);
    await api
      .post({ url: apiConfig.cloneRule, data: cloneBuRuleObject }, setIsLoading)
      .then(() => {
        toast.custom({
          title: t('toast.savedSuccessfully'),
          message: cloneBuRuleObject.ruleName + t('toast.cloneBuRules'),
        });
        buRulesList?.reload();
        setShowClonePop(0);
      });
  };

  const handleCloneButtonClick = (ruleId: any) => {
    setShowClonePop(ruleId);
  };
  const onHidingClonePopover = () => {
    setShowClonePop(0);
  };

  const actionTemplate = (data: any) => {
    return (
      <Restricted permission="BURules.Clone">
        <Button
          id={`rule${data.data.ruleId}`}
          elementAttr={{ 'data-testid': 'cloneRule' }}
          className="app-c-btn app-c-btn--secondary"
          onClick={() => {
            handleCloneButtonClick(data.data.ruleId);
          }}
        >
          {t('buttons.clone')}
        </Button>
        {showClonePop === data.data.ruleId && (
          <VettingRuleClone
            ruleId={data.data.ruleId}
            onClickCancel={() => {
              setShowClonePop(0);
            }}
            showPopover={showClonePop === data.data.ruleId}
            cloneRule={cloneRule}
            closeOnOutsideClick={true}
            onHidingPopover={onHidingClonePopover}
            target={`#rule${data.data.ruleId}`}
          />
        )}
      </Restricted>
    );
  };

  const copyRule = async (
    copyRuleObject: IBuRuleCopy,
    companyName: string,
    buName: string
  ) => {
    await api
      .post({ url: apiConfig.copyRule, data: copyRuleObject }, setIsLoading)
      .then(() => {
        toast.custom({
          title: t('toast.copiedSuccessfully'),
          message:
            ruleBuName +
            t('toast.copiedtoCompanyBuName') +
            companyName +
            '-' +
            buName,
        });
        setShowPopver(false);
      });
  };

  const handleCopyToButtonClick = async (
    copRuleObject: IBuRuleCopy,
    companyName: string,
    buName: string
  ) => {
    const dialogConfig = {
      title: t('toast.confirmation'),
      content: t('toast.copyConfirmation', {
        company: companyName,
        bu: buName,
      }),
      handleSubmit: () => {
        copyRule(copRuleObject, companyName, buName);
        setActiveConfirmDialog(false);
      },
    };
    setActiveDialogConfig({
      ...confirmStatusDialogConfig,
      ...dialogConfig,
    });
    setActiveConfirmDialog(true);
  };

  const handleAddNew = () => {
    navigate('/rules/business-unit/add/', { state: { buId: buId } });
  };

  useEffect(() => {
    if (showAdd) {
      handleAddNew();
    }
  }, [showAdd]);

  return (
    <div className="m-l-bu-grid-form-wrap">
      <div>
        <div className="m-l-grid-top-header__section u-bb0">
          <div className="left-section">
            <div className="m-l-grid-top-header-list-row">
              <div className="row">
                <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 col-12 m-l-grid-top-header-list-column  justify-content-start  justify-content-start">
                  <div className="m-c-checkbox m-c-checkbox-dark">
                    <CheckBox
                      elementAttr={{ 'data-testid': 'allRulesToggle' }}
                      disabled={selectAllAuto}
                      onValueChanged={(e) => {
                        if (selectAllAuto) {
                          setSelectAllAuto(false);
                        } else {
                          if (!selection.allRules) {
                            let count = 0;
                            Object.keys(selection).forEach((key) => {
                              if (key !== 'allRules' && !selection[key]) {
                                count += 1;
                              }
                            });
                            if (count) {
                              setSelectAllAuto(true);
                            }
                            setSelection({
                              allRules: true,
                              marsRules: true,
                              buRules: true,
                              activeRules: true,
                              inActiveRules: true,
                            });
                            applyFilter(e, 'allRules');
                          } else {
                            setSelection({
                              ...selection,
                              allRules: !selection.allRules,
                            });
                          }
                        }
                      }}
                      value={selection.allRules}
                    />
                  </div>
                  <h2 className="checkbox-label">{t('labels.All')}</h2>
                </div>
                <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column  justify-content-start">
                  <div className="m-c-checkbox m-c-checkbox-dark">
                    <CheckBox
                      elementAttr={{ 'data-testid': 'marsRulesToggle' }}
                      disabled={selectAllAuto}
                      onValueChanged={(e) => {
                        changeSelection('marsRules', e);
                      }}
                      value={selection.marsRules}
                    />
                  </div>
                  <h2 className="checkbox-label">{t('labels.MarsRules')}</h2>
                </div>
                <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column  justify-content-start">
                  <div className="m-c-checkbox m-c-checkbox-dark">
                    <CheckBox
                      elementAttr={{ 'data-testid': 'buRulesToggle' }}
                      disabled={selectAllAuto}
                      onValueChanged={(e) => {
                        changeSelection('buRules', e);
                      }}
                      value={selection.buRules}
                    />
                  </div>
                  <h2 className="checkbox-label">{t('labels.BuRules')}</h2>
                </div>
                <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column  justify-content-start">
                  <div className="m-c-checkbox m-c-checkbox-dark">
                    <CheckBox
                      elementAttr={{ 'data-testid': 'activeRulesToggle' }}
                      disabled={selectAllAuto}
                      onValueChanged={(e) => {
                        changeSelection('activeRules', e);
                      }}
                      value={selection.activeRules}
                    />
                  </div>
                  <h2 className="checkbox-label">{t('labels.active')}</h2>
                </div>
                <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column  justify-content-start">
                  <div className="m-c-checkbox m-c-checkbox-dark">
                    <CheckBox
                      elementAttr={{ 'data-testid': 'inActiveRulesToggle' }}
                      disabled={selectAllAuto}
                      onValueChanged={(e) => {
                        changeSelection('inActiveRules', e);
                      }}
                      value={selection.inActiveRules}
                    />
                  </div>
                  <h2 className="checkbox-label">{t('labels.inActive')}</h2>
                </div>
              </div>
            </div>
          </div>
          {!disableCopyBtn && (
            <BUCopyPopover
              showPopover={showPopover}
              setShowPopover={setShowPopver}
              ruleIds={copyRuleIds}
              handleCopyToButtonClick={handleCopyToButtonClick}
              target="#copyButton"
            />
          )}
        </div>
        <div
          className="m-c-grid m-c-userlist-grid m-c-chk-btn-grid m-c-grid-in-tab"
          ref={sepRef}
          style={{ height: height }}
        >
          <Grid
            gridConfig={gridConfig}
            statusTemplate={cellRender}
            actionTemplate={actionTemplate}
            cellTemplate={incoTermsCellTemplate}
            actionTemplate1={vesselTypeCellTemplate}
            onEditorPreparing={onEditorPreparing}
          />
          <ConfirmDialog
            dialogConfig={activeDialogConfig}
            isOpen={activeConfirmDialog}
          />
        </div>
      </div>
    </div>
  );
}
