import Button from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IModalConfig } from '../../../../types/modal';
import icons from '../../../common/icons/icons';
import Modal from '../../../common/modal/Modal';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../../types/dialog';
import { IBerthLoadingRate } from '../../../../types/port-management';
import toast from '../../../../utils/toast';
import LoadingRateForm from './LoadingRateForm';
import ValidationGroup from 'devextreme-react/validation-group';

export default function LoadingRateEdit(props: {
  isVisible: boolean;
  handleCancelClick: any;
  berthCode: any;
  getLoadingRateData: any;
  isEdit: any;
  setIsEdit: any;
  loadingRateId: any;
  setLoadingRateId: any;
}) {
  const { t } = useTranslation();
  const {
    handleCancelClick,
    isVisible,
    berthCode,
    getLoadingRateData,
    isEdit,
    setIsEdit,
    loadingRateId,
    setLoadingRateId,
  } = props;
  const [berthTypeData, setBerthTypeData] = useState<any>();
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [loadingRate, setLoadingRate] = useState<IBerthLoadingRate>();
  const validationGroup: any = useRef();
  const [isconfirmBackDialog, setIsConfirmBackDialog] = useState(false);

  const modalConfig: IModalConfig = {
    width: 450,
    height: 425,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmSaveChanges'),
    handleSubmit: () => {
      onValueSubmit();
    },
    handleClose: () => {
      setShowConfirmSave(false);
    },
  };

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      handleCancelClick();
      setIsEdit(false);
      setLoadingRate(undefined);
      setLoadingRateId(undefined);
    },
    handleClose: () => {
      setIsConfirmBackDialog(false);
    },
  };

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-edit-name-popup',
    };
  }, []);

  const getBerthTypeData = async () => {
    await api
      .get({ url: apiConfig.optionValue + 'BerthType' })
      .then((data: any) => {
        setBerthTypeData(data);
      });
  };

  useEffect(() => {
    getBerthTypeData();
  }, []);

  const getDataById = async () => {
    await api
      .get({
        url: apiConfig.berthLoadDischarge + loadingRateId,
      })
      .then((res) => {
        setLoadingRate(res);
      });
  };

  useEffect(() => {
    if (loadingRateId) {
      getDataById();
    }
  }, [loadingRateId]);

  const formDefaultValues: IBerthLoadingRate = {
    berthLoadAndDisrateId: null,
    berthTypeDesc: '',
    loadingRate: null,
    dischargeRate: null,
    berthCode: '',
  };

  const loadingRateForm = useForm({
    defaultValues: formDefaultValues,
  });

  const { isDirty } = loadingRateForm.formState;

  useEffect(() => {
    loadingRateForm.reset();
    if (loadingRate) {
      loadingRateForm.reset(
        {
          berthLoadAndDisrateId: loadingRateId,
          berthTypeDesc: loadingRate.berthTypeDesc,
          loadingRate: loadingRate.loadingRate,
          dischargeRate: loadingRate.dischargeRate,
          berthCode: loadingRate.berthCode,
        },
        { keepDirty: true }
      );
    }
  }, [loadingRate]);

  const onValueSubmit = () => {
    const formValue: IBerthLoadingRate = loadingRateForm.getValues();
    const formData: IBerthLoadingRate = {
      berthLoadAndDisrateId: 0,
      berthTypeDesc: formValue.berthTypeDesc,
      loadingRate: formValue.loadingRate,
      dischargeRate: formValue.dischargeRate,
      berthCode: berthCode,
    };
    if (loadingRateId) {
      formData.berthLoadAndDisrateId = loadingRateId;
      edit(formData);
    } else {
      add(formData);
    }
  };

  const add = async (data: any) => {
    await api
      .post({ url: apiConfig.berthLoadDischarge, data: data })
      .then((response) => {
        if (response) {
          handleCancelClick();
          setShowConfirmSave(false);
          getLoadingRateData();
          setLoadingRateId(undefined);
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
        }
      });
  };

  const edit = async (data: any) => {
    await api
      .put({ url: apiConfig.berthLoadDischarge, data: data })
      .then((response) => {
        if (response) {
          handleCancelClick();
          setShowConfirmSave(false);
          getLoadingRateData();
          setLoadingRateId(undefined);
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
        }
      });
  };

  const onClickCancel = () => {
    isDirty
      ? setIsConfirmBackDialog(true)
      : (handleCancelClick(),
        setIsEdit(false),
        setLoadingRate(undefined),
        setLoadingRateId(undefined));
  };

  const onClickSave = () => {
    const requiredValidation = validationGroup.current.instance.validate();
    if (requiredValidation.isValid) {
      setShowConfirmSave(true);
    }
  };

  return (
    <div className="m-l-model-popup__wrap" data-testid="editName">
      <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
        <div className="m-l-modal__header">
          {isEdit ? (
            <h2 className="modal-title">Edit Loading Rate</h2>
          ) : (
            <h2 className="modal-title">Add New</h2>
          )}
          <div className="m-l-modal-close-button-block">
            <Button
              className="app-c-btn app-c-icon-only-btn"
              onClick={onClickCancel}
            >
              <icons.IconCross />
            </Button>
          </div>
        </div>
        <div className="m-l-modal__body">
          <ScrollView width="100%" height="100%">
            <div className="m-l-edit-form-panel">
              <FormProvider {...loadingRateForm}>
                <ValidationGroup ref={validationGroup}>
                  <LoadingRateForm berthTypeData={berthTypeData} />
                </ValidationGroup>
              </FormProvider>
            </div>
          </ScrollView>
        </div>
        <div className="m-l-modal__footer">
          <Button
            className="app-c-btn app-c-btn--secondary min-btn-width"
            elementAttr={{ 'data-testid': 'backButton' }}
            onClick={onClickCancel}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            className="app-c-btn app-c-btn--primary min-btn-width"
            elementAttr={{ 'data-testid': 'saveButton' }}
            onClick={() => onClickSave()}
          >
            {t('buttons.save')}
          </Button>
        </div>
        <ConfirmDialog
          dialogConfig={confirmSaveDialogConfig}
          isOpen={showConfirmSave}
        />
        <ConfirmDialog
          dialogConfig={confirmBackDialogConfig}
          isOpen={isconfirmBackDialog}
        />
      </Modal>
    </div>
  );
}
