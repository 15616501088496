import Accordion from 'devextreme-react/accordion';
import React, { useMemo, useState } from 'react';
import VesselRestrictionsAtApproaches from '../vessel-restrictions-at-approaches/VesselRestrictionsAtApproaches';
import ShipHandledGrid from '../ship-handled/ShipHandledGrid';
import CargoTypeGrid from '../cargo-type-grid/CargoTypeGrid';
import LoadingRateGrid from '../loading-rate-grid/LoadingRateGrid';
import { BerthFItInformationParams } from '../../../../enums/berthfit-information-enum';

export default function BerthAccordion(props: {
  berthCode: any;
  isFromViewInfoModal?: boolean;
  parameterInfo?: any[];
}) {
  const { berthCode } = props;
  const { isFromViewInfoModal, parameterInfo } = props;
  const [selectedItems, setSelectedItems] = useState<any>([]);

  const accordionItems = useMemo(() => {
    return [
      {
        title: 'Ship Handled',
        visible:
          !isFromViewInfoModal ||
          (isFromViewInfoModal &&
            parameterInfo?.includes(BerthFItInformationParams.ShipHandled)),
      },
      {
        title: 'Vessel Restrictions at Approaches',
        visible:
          !isFromViewInfoModal ||
          (isFromViewInfoModal &&
            parameterInfo?.includes(
              BerthFItInformationParams.VesselRestrictionsApproaches
            )),
      },
      {
        title: 'Loading Rate',
        visible:
          !isFromViewInfoModal ||
          (isFromViewInfoModal &&
            parameterInfo?.includes(BerthFItInformationParams.LoadingRate)),
      },
      {
        title: 'Cargo Type',
        visible:
          !isFromViewInfoModal ||
          (isFromViewInfoModal &&
            parameterInfo?.includes(BerthFItInformationParams.CargoType)),
      },
    ];
  }, [parameterInfo]);

  const accordionItemRender = (e: any) => {
    switch (e.title) {
      case accordionItems[0].title: {
        return (
          <ShipHandledGrid
            berthCode={berthCode}
            isFromViewInfoModal={isFromViewInfoModal}
          />
        );
      }
      case accordionItems[1].title: {
        return (
          <VesselRestrictionsAtApproaches
            berthCode={berthCode}
            isFromViewInfoModal={isFromViewInfoModal}
          />
        );
      }
      case accordionItems[2].title: {
        return (
          <LoadingRateGrid
            berthCode={berthCode}
            isFromViewInfoModal={isFromViewInfoModal}
          />
        );
      }
      case accordionItems[3].title: {
        return (
          <CargoTypeGrid
            berthCode={berthCode}
            isFromViewInfoModal={isFromViewInfoModal}
          />
        );
      }
    }
  };

  const selectionChanged = (e: any) => {
    let newItems: any = [...selectedItems];
    e.removedItems.map((item: any) => {
      const index = newItems.indexOf(item);
      if (index >= 0) {
        newItems.splice(index, 1);
      }
    });
    if (e.addedItems.length) {
      newItems = [...newItems, ...e.addedItems];
    }
    setSelectedItems(newItems);
  };

  return (
    <div className="m-l-accordion-panel-body-block">
      <div className="m-l-accordion__main-section u-p0">
        <div className="m-c-accordion">
          <Accordion
            collapsible={true}
            multiple={true}
            items={accordionItems}
            itemRender={accordionItemRender}
            selectedItems={selectedItems}
            onSelectionChanged={selectionChanged}
          />
        </div>
      </div>
    </div>
  );
}
