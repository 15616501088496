import React, { useCallback, useEffect, useRef } from 'react';
import Grid from '../../common/grid/Grid';
import icons from '../../common/icons/icons';
import { IGridConfig } from '../../../types/grid';
import { IBerthParameter } from '../../../types/berthfit-execution';
import { useTranslation } from 'react-i18next';
import { useDimensionsUnit } from '../../../hooks/useDimensionsUnit';
import DataGrid from 'devextreme-react/data-grid';

export default function BerthGrid(props: { berthParameters: any }) {
  const { berthParameters } = props;
  const { t } = useTranslation();

  const gridRef = useRef<DataGrid>(null);

  const { dimensionUnit, dimensionUnitText, meterToFeetConversion } =
    useDimensionsUnit();

  const statusCheck = [
    { name: t('labels.pass'), value: true },
    { name: t('labels.fail'), value: false },
  ];

  const cellTemplate = (data: any) => {
    if (data.value == true) {
      return (
        <div className="m-l-icon-btn-holder">
          <icons.circleTickDanger />
        </div>
      );
    } else {
      return (
        <div className="m-l-icon-btn-holder">
          <icons.circleCrossDanger />
        </div>
      );
    }
  };

  const calculateParameterDisplayNameCellValue = useCallback(
    (data) => {
      if (data.isConversionRequired) {
        return data.berthParameterDisplayName + dimensionUnitText();
      } else {
        return data.berthParameterDisplayName;
      }
    },
    [dimensionUnit]
  );

  const calculateBerthCellValue = useCallback(
    (data) => {
      if (data.isConversionRequired) {
        return meterToFeetConversion(data.berthValue);
      } else {
        return data.berthValue;
      }
    },
    [dimensionUnit]
  );

  const calculateShipCellValue = useCallback(
    (data) => {
      if (data.isConversionRequired) {
        return meterToFeetConversion(data.shipValue);
      } else {
        return data.shipValue;
      }
    },
    [dimensionUnit]
  );

  useEffect(() => {
    if (dimensionUnit) {
      gridRef.current?.instance.columnOption(
        'berthParameterDisplayName',
        'calculateCellValue',
        calculateParameterDisplayNameCellValue
      );
      gridRef.current?.instance.columnOption(
        'shipValue',
        'calculateCellValue',
        calculateShipCellValue
      );
      gridRef.current?.instance.columnOption(
        'berthValue',
        'calculateCellValue',
        calculateBerthCellValue
      );
    }
  }, [dimensionUnit]);

  const gridConfig: IGridConfig = {
    dataSource: berthParameters,
    ref: gridRef,
    class:
      'm-c-grid m-l-incident-grid m-c-auto-scroll-grid m-l-grid-with--top-border',
    defaultColumns: [
      {
        caption: 'Parameter Name',
        dataField: 'berthParameterDisplayName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'Pass / Fail',
        dataField: 'isPassed',
        dataType: 'string',
        minWidth: 198,
        cellTemplate: 'cellTemplate',
        allowFiltering: true,
        lookup: {
          dataSource: statusCheck,
          valueExpr: 'value',
          displayExpr: 'name',
        },
      },
      {
        caption: 'Berth Value',
        dataField: 'berthValue',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'Ship Value',
        dataField: 'shipValue',
        dataType: 'string',
        minWidth: 150,
      },
    ],
    hidePagination: false,
    showSelection: false,
    hideFilterRow: false,
  };
  return <Grid gridConfig={gridConfig} cellTemplate={cellTemplate} />;
}
