import CheckBox from 'devextreme-react/check-box';
import DateBox from 'devextreme-react/date-box';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import Validator, { CustomRule } from 'devextreme-react/validator';
import CustomStore from 'devextreme/data/custom_store';
import React, { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { getAPIRoute } from '../../../utils/api-route';
import DataSource from 'devextreme/data/data_source';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { Condition } from '../../../enums/condition-enum';
import { useDataStore } from '../../../hooks/useDataStore';
import TagBox from 'devextreme-react/tag-box';
import EntityAttributeValueFields from '../entity-attribute-value-fields/EntityAttributeValueFields';
import { IEntityAttribute, IMasterEntity } from '../../../types/master-data';
import { RequiredRule } from 'devextreme-react/data-grid';
import { Attribute } from '../../../enums/attribute-enum';

export default function RuleConditionValueField(props: {
  index: number;
  nestedIndex: number;
  entities: IMasterEntity[];
  attributes: IEntityAttribute[];
  buId: number;
}) {
  const { t } = useTranslation();

  const { index, nestedIndex, entities, attributes, buId } = props;

  const { control, getValues, setValue } = useFormContext();

  const [forceRender, setForceRender] = useState(false);

  const { dateFormat } = useDateFormat();

  const { getDataSource } = useDataStore();

  const customRequiredValidation = (params: any, index: any) => {
    const rowData = getValues().groups[nestedIndex].ruleConditions[index];

    if (
      !params.value &&
      (rowData.value1 ||
        rowData.description ||
        rowData.canUserEnter ||
        rowData.attributeId ||
        rowData.entityId ||
        rowData.condition ||
        rowData.isEntityValue)
    ) {
      return false;
    }
    return true;
  };

  const customNumberFieldValidation = (params: any) => {
    params.rule.message = params.value
      ? t('errors.numberValidation')
      : t('errors.required');
    if (
      getValues().groups[nestedIndex]?.ruleConditions[index]?.attributeType ===
      'Number'
    ) {
      return /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/.test(params.value);
    }
    return true;
  };

  const valueChanged = (e: any, field: any) => {
    field.onChange(e.value);
  };

  const onTagBoxValueChange = (e: any, field: any) => {
    if (e.value.length !== e.previousValue.length) {
      field.onChange(e.value.toString());
    }
  };

  const onIsListValueChange = (e: any, field: any) => {
    setValue(`groups[${nestedIndex}].ruleConditions[${index}].value1`, '');
    valueChanged(e, field);
    setForceRender(!forceRender);
  };

  const valueDataSource = useMemo(() => {
    return new DataSource({
      store: new CustomStore({
        key: 'listId',
        loadMode: 'raw',
        load: () => {
          return api.get({
            url: getAPIRoute('listWithEntityAttribute', [
              getValues().groups[nestedIndex]?.ruleConditions[index]
                ?.entityId ?? 0,
              getValues().groups[nestedIndex]?.ruleConditions[index]
                ?.attributeId ?? 0,
            ]),
            params: { buId: buId !== 0 ? buId : null },
          });
        },
      }),
    });
  }, [
    getValues().groups[nestedIndex].ruleConditions[index].entityId,
    getValues().groups[nestedIndex].ruleConditions[index].attributeId,
  ]);

  const dataSource = useMemo(() => {
    return getDataSource(
      getValues().groups[nestedIndex].ruleConditions[index].selectListValue,
      getValues().groups[nestedIndex].ruleConditions[index].selectList
    );
  }, [
    getValues().groups[nestedIndex].ruleConditions[index].selectListValue,
    getValues().groups[nestedIndex].ruleConditions[index].selectList,
  ]);

  return !getValues().groups[nestedIndex].ruleConditions[index]
    .isEntityValue ? (
    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 m-l-row-custom-column m-l-custom-sixteen-space-col">
      {getValues().groups[nestedIndex].ruleConditions[index].condition !==
        'BETWEEN' && (
        <div className="mb-btm-space m-c-form-group">
          <div className="m-l-default-checkbox-unit">
            <label className="m-l-input-label">{t('labels.value')}</label>
            <div className="m-c-checkbox">
              {' '}
              {[Condition.In, Condition.NotIn].includes(
                getValues().groups[nestedIndex].ruleConditions[index].condition
              ) ? (
                <Controller
                  name={`groups[${nestedIndex}].ruleConditions[${index}].isListValue`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <CheckBox
                        elementAttr={{
                          'data-testid': 'isListValue',
                        }}
                        name="isListValue"
                        value={field.value}
                        onValueChanged={(e) => onIsListValueChange(e, field)}
                      />
                      <span className="m-l-checkbox--small-label">
                        {t('labels.list')}
                      </span>
                    </>
                  )}
                />
              ) : (
                <Controller
                  name={`groups[${nestedIndex}].ruleConditions[${index}].isEntityValue`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <CheckBox
                        elementAttr={{
                          'data-testid': 'isListValue',
                        }}
                        name="isEntityValue"
                        value={field.value}
                        onValueChanged={(e) => onIsListValueChange(e, field)}
                      />
                      <span className="m-l-checkbox--small-label">
                        {t('labels.entity')}
                      </span>
                    </>
                  )}
                />
              )}
            </div>
          </div>
          <Controller
            name={`groups[${nestedIndex}].ruleConditions[${index}].value1`}
            control={control}
            render={({ field }) => {
              if (
                getValues().groups[nestedIndex].ruleConditions[index]
                  .attributeType === 'Boolean'
              ) {
                return (
                  <SelectBox
                    elementAttr={{ 'data-testid': 'value1' }}
                    dataSource={[
                      { label: 'Yes', value: '1' },
                      { label: 'No', value: '0' },
                    ]}
                    displayExpr="label"
                    valueExpr="value"
                    value={field.value}
                    onValueChanged={(e) => valueChanged(e, field)}
                    className="m-c-input-control  m-c-select-box"
                    validationMessageMode="always"
                    searchEnabled={false}
                  >
                    <Validator>
                      <CustomRule
                        reevaluate={true}
                        message={t('errors.required')}
                        validationCallback={(params: any) =>
                          customRequiredValidation(params, index)
                        }
                      />
                    </Validator>
                  </SelectBox>
                );
              } else if (
                getValues().groups[nestedIndex].ruleConditions[index]
                  .attributeType !== 'Date' &&
                getValues().groups[nestedIndex].ruleConditions[index]
                  .attributeType !== 'DateTime' &&
                !getValues().groups[nestedIndex].ruleConditions[index]
                  .isListValue &&
                (!getValues().groups[nestedIndex].ruleConditions[index]
                  .isSelectable ||
                  [
                    Condition.Contains,
                    Condition.BeginsWith,
                    Condition.EndsWith,
                  ].includes(
                    getValues().groups[nestedIndex].ruleConditions[index]
                      .condition
                  ))
              ) {
                return (
                  <TextBox
                    name="value1"
                    value={field.value}
                    inputAttr={{ 'data-testid': 'value' }}
                    className="m-c-input-control m-c-select-box"
                    onValueChanged={(e) => valueChanged(e, field)}
                    validationMessageMode="always"
                  >
                    <Validator elementAttr={{ id: 'entity' }}>
                      <CustomRule
                        reevaluate={true}
                        message={t('errors.required')}
                        validationCallback={(params: any) =>
                          customRequiredValidation(params, index)
                        }
                      />
                      <CustomRule
                        reevaluate={true}
                        message={t('errors.numberValidation')}
                        validationCallback={(params: any) =>
                          customNumberFieldValidation(params)
                        }
                      />
                    </Validator>
                  </TextBox>
                );
              } else if (
                getValues().groups[nestedIndex].ruleConditions[index]
                  .isListValue
              ) {
                return (
                  <SelectBox
                    elementAttr={{ 'data-testid': 'value1' }}
                    dataSource={valueDataSource}
                    displayExpr="listName"
                    valueExpr="listId"
                    value={field.value}
                    onValueChanged={(e) => valueChanged(e, field)}
                    className="m-c-input-control  m-c-select-box"
                    validationMessageMode="always"
                    searchEnabled={true}
                  >
                    <Validator elementAttr={{ id: 'entity' }}>
                      <CustomRule
                        reevaluate={true}
                        message={t('errors.required')}
                        validationCallback={(params: any) =>
                          customRequiredValidation(params, index)
                        }
                      />
                    </Validator>
                  </SelectBox>
                );
              } else if (
                getValues().groups[nestedIndex].ruleConditions[index]
                  .isSelectable &&
                [Condition.In, Condition.NotIn].includes(
                  getValues().groups[nestedIndex].ruleConditions[index]
                    .condition
                )
              ) {
                return (
                  <div className="m-l-user-role-category-select-box">
                    <TagBox
                      className="m-c-input-control m-c-multiselect-dropdown"
                      elementAttr={{ 'data-testid': 'value1' }}
                      dataSource={dataSource}
                      displayExpr={
                        getValues().groups[nestedIndex].ruleConditions[index]
                          .selectListDisplay
                      }
                      valueExpr={
                        getValues().groups[nestedIndex].ruleConditions[index]
                          .selectListValue
                      }
                      value={field.value ? field.value.split(',') : []}
                      onValueChanged={(e) => onTagBoxValueChange(e, field)}
                      showSelectionControls={true}
                      searchEnabled={true}
                      showClearButton={true}
                      maxDisplayedTags={1}
                      showMultiTagOnly={true}
                      validationMessageMode="always"
                      multiline={false}
                      searchExpr={
                        getValues().groups[nestedIndex]?.ruleConditions[index]
                          ?.attributeName === Attribute.PortName
                          ? ['port', 'countryName']
                          : undefined
                      }
                    >
                      <Validator elementAttr={{ id: 'entity' }}>
                        <RequiredRule message={t('errors.required')} />
                      </Validator>
                    </TagBox>
                  </div>
                );
              } else if (
                getValues().groups[nestedIndex].ruleConditions[index]
                  .isSelectable
              ) {
                return (
                  <SelectBox
                    elementAttr={{ 'data-testid': 'value1' }}
                    dataSource={dataSource}
                    displayExpr={
                      getValues().groups[nestedIndex].ruleConditions[index]
                        .selectListDisplay
                    }
                    valueExpr={
                      getValues().groups[nestedIndex].ruleConditions[index]
                        .selectListValue
                    }
                    value={field.value}
                    onValueChanged={(e) => valueChanged(e, field)}
                    className="m-c-input-control  m-c-select-box"
                    validationMessageMode="always"
                    searchEnabled={true}
                    searchExpr={
                      getValues().groups[nestedIndex].ruleConditions[index]
                        .attributeName === Attribute.PortName
                        ? ['port', 'countryName']
                        : undefined
                    }
                  >
                    <Validator elementAttr={{ id: 'entity' }}>
                      <CustomRule
                        reevaluate={true}
                        message={t('errors.required')}
                        validationCallback={(params: any) =>
                          customRequiredValidation(params, index)
                        }
                      />
                    </Validator>
                  </SelectBox>
                );
              } else
                return (
                  <DateBox
                    inputAttr={{ 'data-testid': 'value1' }}
                    className="m-c-input-control m-c-date-picker"
                    name="value1"
                    value={field.value}
                    onValueChanged={(e) => valueChanged(e, field)}
                    type="date"
                    displayFormat={dateFormat}
                    validationMessageMode="always"
                  >
                    <Validator elementAttr={{ id: 'entity' }}>
                      <CustomRule
                        reevaluate={true}
                        message={t('errors.required')}
                        validationCallback={(params: any) =>
                          customRequiredValidation(params, index)
                        }
                      />
                    </Validator>
                  </DateBox>
                );
            }}
          />
        </div>
      )}
      {getValues().groups[nestedIndex].ruleConditions[index].condition ===
        'BETWEEN' && (
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 m-l-row-custom-column m-l-custom-sixteen-space-col">
            <div className="mb-btm-space m-c-form-group">
              <label className="m-l-input-label">{t('labels.from')}</label>
              <Controller
                name={`groups[${nestedIndex}].ruleConditions[${index}].value1`}
                control={control}
                render={({ field }) => {
                  return getValues().groups[nestedIndex].ruleConditions[index]
                    .attributeType !== 'Date' &&
                    getValues().groups[nestedIndex].ruleConditions[index]
                      .attributeType !== 'DateTime' ? (
                    <TextBox
                      name="value1"
                      value={field.value}
                      inputAttr={{ 'data-testid': 'value' }}
                      className="m-c-input-control m-c-select-box"
                      onValueChanged={(e) => valueChanged(e, field)}
                      validationMessageMode="always"
                    >
                      <Validator elementAttr={{ id: 'entity' }}>
                        <CustomRule
                          reevaluate={true}
                          message={t('errors.required')}
                          validationCallback={(params: any) =>
                            customRequiredValidation(params, index)
                          }
                        />
                        <CustomRule
                          reevaluate={true}
                          message={t('errors.numberValidation')}
                          validationCallback={(params: any) =>
                            customNumberFieldValidation(params)
                          }
                        />
                      </Validator>
                    </TextBox>
                  ) : (
                    <DateBox
                      inputAttr={{ 'data-testid': 'value1' }}
                      className="m-c-input-control m-c-date-picker"
                      name="value1"
                      value={field.value}
                      onValueChanged={(e) => valueChanged(e, field)}
                      type="date"
                      displayFormat={dateFormat}
                      validationMessageMode="always"
                    >
                      <Validator elementAttr={{ id: 'entity' }}>
                        <CustomRule
                          reevaluate={true}
                          message={t('errors.required')}
                          validationCallback={(params: any) =>
                            customRequiredValidation(params, index)
                          }
                        />
                      </Validator>
                    </DateBox>
                  );
                }}
              />
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 m-l-row-custom-column m-l-custom-sixteen-space-col">
            <div className="mb-btm-space m-c-form-group">
              <label className="m-l-input-label">{t('labels.to')}</label>
              <Controller
                name={`groups[${nestedIndex}].ruleConditions[${index}].value2`}
                control={control}
                render={({ field }) => {
                  return getValues().groups[nestedIndex].ruleConditions[index]
                    .attributeType !== 'Date' &&
                    getValues().groups[nestedIndex].ruleConditions[index]
                      .attributeType !== 'DateTime' ? (
                    <TextBox
                      name="value2"
                      value={field.value}
                      inputAttr={{ 'data-testid': 'value2' }}
                      className="m-c-input-control m-c-select-box"
                      onValueChanged={(e) => valueChanged(e, field)}
                      validationMessageMode="always"
                    >
                      <Validator elementAttr={{ id: 'entity' }}>
                        <CustomRule
                          reevaluate={true}
                          message={t('errors.required')}
                          validationCallback={(params: any) =>
                            customRequiredValidation(params, index)
                          }
                        />
                        <CustomRule
                          reevaluate={true}
                          message={t('errors.numberValidation')}
                          validationCallback={(params: any) =>
                            customNumberFieldValidation(params)
                          }
                        />
                      </Validator>
                    </TextBox>
                  ) : (
                    <DateBox
                      inputAttr={{ 'data-testid': 'value2' }}
                      className="m-c-input-control m-c-date-picker"
                      name="value2"
                      value={field.value}
                      onValueChanged={(e) => valueChanged(e, field)}
                      type="date"
                      displayFormat={dateFormat}
                      validationMessageMode="always"
                    >
                      <Validator elementAttr={{ id: 'entity' }}>
                        <CustomRule
                          reevaluate={true}
                          message={t('errors.required')}
                          validationCallback={(params: any) =>
                            customRequiredValidation(params, index)
                          }
                        />
                      </Validator>
                    </DateBox>
                  );
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <EntityAttributeValueFields
      renderParent={() => setForceRender(!forceRender)}
      customRequiredValidation={customRequiredValidation}
      index={index}
      nestedIndex={nestedIndex}
      entities={entities}
      attributes={attributes}
    />
  );
}
