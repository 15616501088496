import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IFormConfig } from '../../../types/form-builder';
import FormBuilder from '../../common/form-builder/FormBuilder';
import { useTranslation } from 'react-i18next';
import { IDialogConfig } from '../../../types/dialog';
import ConfirmDialog from '../../common/confirm/ConfirmDialog';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';

export default function RolesForm(props: { roleId?: string }) {
  const { roleId } = props;
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const [activeConfirmDialog, setActiveConfirmDialog] = useState(false);

  const confirmStatusDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      setActiveConfirmDialog(false);
    },
    handleClose: () => {
      setActiveConfirmDialog(false);
    },
    title: t('toast.roleAssignedToUser'),
    content: t('toast.inactivateRole'),
  };

  const [activeDialogConfig, setActiveDialogConfig] = useState(
    confirmStatusDialogConfig
  );

  const handleActiveInactiveChange = async (e: any) => {
    if (e.event && roleId) {
      const dialogConfig = {
        title: t('toast.roleAssignedToUser'),
        content: e.value ? t('toast.activateRole') : t('toast.inactivateRole'),
        handleSubmit: () => {
          setActiveConfirmDialog(false);
        },
        handleClose: () => {
          setActiveConfirmDialog(false);
          setValue('isActive', !e.value);
        },
      };
      await api
        .get({ url: apiConfig.roleMappingUserCheck + roleId })
        .then((res) => {
          if (res) {
            setActiveDialogConfig({
              ...confirmStatusDialogConfig,
              ...dialogConfig,
            });
            setActiveConfirmDialog(true);
          }
        });
    }
  };

  const formConfig: IFormConfig[] = [
    {
      name: 'name',
      label: t('labels.role'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      name: 'description',
      label: t('labels.roleDescription'),
      control: control,
      fieldType: 'text',
      maxLength: 100,
    },
    {
      name: 'isActive',
      label: t('labels.active'),
      control: control,
      fieldType: 'switch',
      permission: 'RoleManagement.Activate/Inactive',
      onChange: handleActiveInactiveChange,
    },
  ];

  return (
    <div>
      <form>
        <div className="m-l-form-panel-body-block m-l-rl-pnl-body-block">
          <div className="row m-l-rl-list-wrap">
            <FormBuilder formConfig={formConfig} />
          </div>
        </div>
      </form>
      <ConfirmDialog
        dialogConfig={activeDialogConfig}
        isOpen={activeConfirmDialog}
      />
    </div>
  );
}
