import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormBuilder from '../../../../common/form-builder/FormBuilder';

export default function EquipmentForm() {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const inertGas = [
    {
      name: 'Flue Gas',
    },
    {
      name: 'ICG',
    },
    {
      name: 'Nitrogen Generator',
    },
  ];

  const equipmentFormConfig = [
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'propeller',
      label: t('labels.propeller'),
      control: control,
      fieldType: 'number',
      maxLength: 7,
      rules: [
        {
          type: 'pattern',
          pattern: '^[0-9]*$',
          message: t('errors.wholeNumber'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'propellerDescription',
      label: t('labels.propellerDescription'),
      control: control,
      fieldType: 'text',
      maxLength: 50,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'propulsionType',
      label: t('labels.propellingType'),
      control: control,
      fieldType: 'text',
      maxLength: 30,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'manifold',
      label: t('labels.manifold'),
      control: control,
      fieldType: 'text',
      maxLength: 50,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'manifoldSize',
      label: t('labels.manifoldSize'),
      control: control,
      fieldType: 'text',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'inertGas',
      label: t('labels.inertGas'),
      control: control,
      fieldType: 'select',
      dataSource: inertGas,
      displayExpr: 'name',
      valueExpr: 'name',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'numberOfCranes',
      label: t('labels.numberOfCranes'),
      control: control,
      fieldType: 'number',
      maxLength: 7,
      rules: [
        {
          type: 'pattern',
          pattern: '^[0-9]*$',
          message: t('errors.wholeNumber'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'craneSWL',
      label: t('labels.craneSWL'),
      control: control,
      fieldType: 'number',
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'bowStoppersNo',
      label: t('labels.chainStoppersNos'),
      control: control,
      fieldType: 'number',
      maxLength: 7,
      rules: [
        {
          type: 'pattern',
          pattern: '^[0-9]*$',
          message: t('errors.wholeNumber'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'bowStoppersSWL',
      label: t('labels.chainStopperSWL'),
      control: control,
      fieldType: 'number',
      maxLength: 7,
      rules: [
        {
          type: 'pattern',
          pattern: '^[0-9]*$',
          message: t('errors.wholeNumber'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'mooringRope',
      label: t('labels.mooringRope'),
      control: control,
      fieldType: 'number',
      maxLength: 7,
      rules: [
        {
          type: 'pattern',
          pattern: '^[0-9]*$',
          message: t('errors.wholeNumber'),
        },
      ],
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'mooringRopeMaterial',
      label: t('labels.mooringRopeMaterial'),
      control: control,
      fieldType: 'text',
      maxLength: 50,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'mooringRopeMBL',
      label: t('labels.mooringRopeMBL'),
      control: control,
      fieldType: 'text',
      maxLength: 50,
    },
    {
      class:
        'col-md-3 col-sm-6 col-lg-3 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'mooringDrums',
      label: t('labels.mooringDrums'),
      control: control,
      fieldType: 'number',
      maxLength: 7,
      rules: [
        {
          type: 'pattern',
          pattern: '^[0-9]*$',
          message: t('errors.wholeNumber'),
        },
      ],
    },
  ];

  return (
    <>
      <div className="row">
        <FormBuilder formConfig={equipmentFormConfig} />
      </div>
    </>
  );
}
