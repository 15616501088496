import Button from 'devextreme-react/button';
import React, { useEffect, useState } from 'react';
import icons from '../../../common/icons/icons';
import VesselDimensionEdit from './VesselDimensionsEdit';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { useTranslation } from 'react-i18next';
import { Restricted } from '../../../../contexts/PermissionContext';
import { useDimensionsUnit } from '../../../../hooks/useDimensionsUnit';
export default function VesselDimension(props: { imoNumber?: string }) {
  const [isdimensionEdit, setIsDimensionEdit] = useState(false);
  const [vesseldimensionList, setVesselDimensionList] = useState<any>([]);
  const { dimensionUnitText, meterToFeetConversion } = useDimensionsUnit();
  const { imoNumber } = props;
  const { t } = useTranslation();
  const [flag, setFlag] = useState<any>([]);
  const showDimensionEdit = () => {
    setIsDimensionEdit(!isdimensionEdit);
  };
  const GetDimensions = async () => {
    await api
      .get({
        url: apiConfig.vesselDimensionTab + imoNumber,
      })
      .then((response: any) => {
        setVesselDimensionList(response);
        if (response.vapourRecoverySystem == 'Y') {
          setFlag('Yes');
        } else {
          setFlag('No');
        }
      });
  };
  useEffect(() => {
    if (imoNumber) {
      GetDimensions();
    }
  }, []);
  return (
    <span>
      <div className="m-l-inner-page-body">
        {/* <div className="m-l-expand-btn-wrap m-l-edit-view-mode">
                                    <Button className="app-c-btn app-c-icon-only-btn">
                                      <icons.IconPencil />
                                    </Button>
                                  </div> */}
        {!isdimensionEdit ? (
          <div className="m-l-form-panel-block">
            <div className="m-l-tbl-square-wrapper">
              <div className="m-l-ads-table-block">
                <div className="m-l-ads-table-right-part">
                  <div className="row m-l-ads-table-row m-l-ads-table--inner-row">
                    <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 m-l-custom-sixteen-space-col-wrap">
                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.lengthOverall')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.lengthOverallLOA
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.lengthReg')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.lengthRegistered
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.lengthBp')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.lengthBetweenPerpendiculars
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.breadthRegistered')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.breadthRegistered
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.breadthExtreme')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.breadthExtreme
                            )}
                          </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.breadthMoulded')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.breadthMoulded
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.depth')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(vesseldimensionList.depth)}
                          </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.draught')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(vesseldimensionList.draught)}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.ktmInNormalBallast')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.keeltoMastNormalBallast
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.ktmInSummerDraft')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.keeltoMastSummerBallast
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.manifoldHeightInNormalBallast')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.manifoldHeightNormalBallast
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.manifoldHeightInSummerDraft')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.manifoldHeightSummerDraft
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.displacement')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {vesseldimensionList.displacement}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.bulbousBow')}
                          </div>
                          {vesseldimensionList.bulbousBow ? (
                            <div className="m-l-ads-tbl-desc">
                              {t('labels.yes')}
                            </div>
                          ) : (
                            <div className="m-l-ads-tbl-desc">
                              {t('labels.no')}
                            </div>
                          )}
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.tcm')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {vesseldimensionList.tonnesPerCentimetreImmersion}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.pblInNormalBallast')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.parallelBodyLengthBallast
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.pblInSummerDwt')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.parallelBodyLengthSummer
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.scm')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.sterntoCentreManifold
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.bcm')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.bowtoCentreManifold
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.freeboardSummer')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.freeboardSummer
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.freeboardWinter')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.freeboardWinter
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.freeboardTropical')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.freeboardTropical
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.freeboardBallast')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.freeboardBallast
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.hoseDerrick')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {vesseldimensionList.hoseDerrickSWL}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.pblForwardArrival')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.pblForwardArrival
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.pblArrival')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.pblAftArrival
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.distanceFromManifold')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.distancefromManifoldtoShipSideRail
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.heighOfManifold')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.heightofManifoldAboveDeckDripTray
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.manifoldSpacing')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.manifoldSpacing
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.vapourRecoverySystem')}
                          </div>
                          <div className="m-l-ads-tbl-desc">{flag}</div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.manifoldSize')}
                            {dimensionUnitText()}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {meterToFeetConversion(
                              vesseldimensionList.manifoldSize
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.connectorType')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {vesseldimensionList.connectorType}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.loadingRate')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {vesseldimensionList.loadingRate}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                      <div className="m-l-expand-btn-wrap m-l-edit-view-mode">
                        <Restricted permission="Ship.EditDimensions">
                          <Button
                            className="app-c-btn app-c-icon-only-btn"
                            onClick={showDimensionEdit}
                          >
                            <icons.IconPencil />
                          </Button>
                        </Restricted>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <VesselDimensionEdit
            imoNumber={imoNumber}
            setIsDimensionEdit={setIsDimensionEdit}
            vesseldimensionList={vesseldimensionList}
            GetDimensions={GetDimensions}
          />
        )}
      </div>
    </span>
  );
}
