import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormBuilder from '../../../components/common/form-builder/FormBuilder';

export default function OptionValueForm(props: {
  yesNo: any;
  languages: any;
  parentOptionValues: any;
}) {
  const { languages, parentOptionValues } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();

  const defaultRadio = [
    { name: t('labels.yes'), value: true },
    { name: t('labels.no'), value: false },
  ];

  const generalFormConfig = [
    {
      class:
        'col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'languageKey',
      label: t('labels.languageKey'),
      control: control,
      fieldType: 'select',
      dataSource: languages,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'optionValueText',
      label: t('labels.optionValueText'),
      control: control,
      fieldType: 'text',
      maxLength: 1000,
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'displayOrder',
      label: t('labels.displayOrder'),
      control: control,
      fieldType: 'number',
      maxLength: 7,
    },
    {
      class:
        'col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'optionValueDesc',
      label: t('labels.optionValueDesc1'),
      control: control,
      fieldType: 'text',
      maxLength: 1000,
    },
    {
      class:
        'col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'optionValueDesc2',
      label: t('labels.optionValueDesc2'),
      control: control,
      fieldType: 'text',
      maxLength: 1000,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
    {
      class:
        'col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'parentOption',
      label: t('labels.parentOption'),
      control: control,
      fieldType: 'select',
      dataSource: parentOptionValues,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      maxLength: 100,
      disabled: parentOptionValues.length > 0 ? false : true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
      fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
      name: 'isDefault',
      label: t('labels.default'),
      control: control,
      fieldType: 'radio',
      layout: 'horizontal',
      items: defaultRadio,
      displayExpr: 'name',
      valueExpr: 'value',
    },
    {
      class:
        'col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'systemDefined',
      label: t('labels.systemDefined'),
      control: control,
      fieldType: 'display',
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
    },
  ];

  return (
    <>
      <div className="row m-l-custom-sixteen-space-row">
        <FormBuilder formConfig={generalFormConfig} />
      </div>
    </>
  );
}
