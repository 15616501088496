import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import { IGridConfig } from '../../../../../types/grid';
import Grid from '../../../../common/grid/Grid';
import ReactDOMServer from 'react-dom/server';
import icons from '../../../../common/icons/icons';
import {
  IVesselName,
  IVesselNameDelete,
} from '../../../../../types/vessel-name-tab';
import EditName from '../edit-name/EditName';
import Button from 'devextreme-react/button';
import DataGrid from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { IDialogConfig } from '../../../../../types/dialog';
import ConfirmDialog from '../../../../common/confirm/ConfirmDialog';
import toast from '../../../../../utils/toast';
import { useDateFormat } from '../../../../../hooks/useDateFormat';
import { usePermission } from '../../../../../contexts/PermissionContext';
import { useLoaderContext } from '../../../../../contexts/LoaderContext';

export default function Name(props: { imoNumber?: string }) {
  const { imoNumber } = props;

  const { dateFormat } = useDateFormat();

  const { t } = useTranslation();

  const confirmDeleteDialogConfig: IDialogConfig = {
    id: 'confirmDelete',
    content: t('toast.vesselNameDeleteConfirm'),
    handleSubmit: () => {
      vesselNameDelete();
      setShowConfirmDelete(false);
    },
    handleClose: () => {
      setShowConfirmDelete(false);
    },
  };

  const [vesselNames, setVesselNames] = useState<IVesselName[]>([]);

  const [showEditNameModal, setShowEditNameModal] = useState(false);

  const [selectedHistoryId, setSelectedHistoryId] = useState(0);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [deleteName, setDeleteName] = useState<IVesselName>();

  const nameGridRef = useRef<DataGrid>(null);

  const { featureList, findFeature } = usePermission();

  const { setIsLoading } = useLoaderContext();

  const getVesselName = async () => {
    await api.get({ url: apiConfig.vesselNames + imoNumber }).then((res) => {
      setVesselNames(res);
    });
  };

  useEffect(() => {
    if (imoNumber) {
      getVesselName();
    }
  }, [imoNumber]);

  const onRowClick = (e: any) => {
    if (
      e.event.target.nodeName !== 'svg' &&
      e.event.target.nodeName !== 'path' &&
      e.event.target.className !== 'click-delete'
    ) {
      setSelectedHistoryId(e.data.vesselNameHistoryId);
      setShowEditNameModal(true);
    }
  };

  const onAddClick = () => {
    setSelectedHistoryId(0);
    setShowEditNameModal(true);
  };

  const vesselNameDelete = () => {
    const deleteJson: IVesselNameDelete = {
      imoNumber: imoNumber,
      historyId: deleteName?.vesselNameHistoryId,
    };
    api
      .delete(
        { url: apiConfig.updateVesselName, data: deleteJson },
        setIsLoading
      )
      .then(() => {
        getVesselName();
        toast.success({ title: t('toast.deletedSuccessfully') });
      });
  };

  const onClickDelete = (data: IVesselName) => {
    setDeleteName(data);
    setShowConfirmDelete(true);
  };

  const gridConfig: IGridConfig = {
    ref: nameGridRef,
    class: 'm-c-grid m-l-grid-with--top-border m-c-righticon-grid',
    testId: 'vesselNameGrid',
    dataSource: vesselNames,
    rowClickPermission: 'Ship.EditName',
    rowClick: onRowClick,
    onClickAddButton: onAddClick,
    defaultColumns: [
      {
        caption: t('labels.name'),
        dataField: 'name',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.dateFrom'),
        dataField: 'dateFrom',
        dataType: 'date',
        format: dateFormat,
        minWidth: 150,
      },
      {
        caption: '',
        type: 'buttons',
        minWidth: 150,
        cellTemplate: 'actionTemplate',
        alignment: 'right',
        visible: findFeature('Ship.DeleteName'),
        allowFiltering: false,
        allowSorting: false,
        buttons: [
          {
            name: 'delete',
            icon: ReactDOMServer.renderToString(<icons.Trash />),
          },
        ],
      },
    ],
    enableEditing: true,
    allowDeleting: true,
    showHeader: true,
    showExport: true,
    showAddButton: findFeature('Ship.AddName'),

    excelFileName: 'Vesselnames.xlsx',
  };

  const actionTemplate = (data: any) => {
    return (
      <div className="m-l-grid-delete-icon-wrapper">
        <div
          className="click-delete"
          id="delete"
          onClick={() => {
            onClickDelete(data.data);
          }}
        >
          <icons.IconDelete />
        </div>
      </div>
    );
  };

  const exportRolesGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: nameGridRef.current?.instance,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Vesselnames.xlsx'
        );
      });
    });
  };

  return (
    <div className="m-l-tab-accordion-item-content-section">
      {/* <div className="m-l-management-page-grid-action-button">
        <Button
          className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
          aria-label="Upload"
          onClick={() => exportRolesGrid()}
        >
          <div className="m-c-icon-inside-btn">
            <icons.download />
          </div>
        </Button>
        <Button className="app-c-btn app-c-btn--primary" onClick={onAddClick}>
          {t('buttons.addNew')}
        </Button>
      </div> */}
      <Grid gridConfig={gridConfig} actionTemplate={actionTemplate} />
      {showEditNameModal && (
        <EditName
          getVesselName={getVesselName}
          imoNumber={imoNumber}
          historyId={selectedHistoryId}
          isVisible={showEditNameModal}
          handleCancelClick={() => setShowEditNameModal(false)}
        />
      )}
      {/* <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <Button
                className="app-c-btn app-c-btn--secondary "
                aria-label="Upload"
                onClick={() => exportRolesGrid()}
              >
                {t('labels.download')}
              </Button>
              <Button
                className="app-c-btn app-c-btn--primary"
                onClick={onAddClick}
              >
                {t('buttons.addNew')}
              </Button>
            </div>
          </div>
        </div>
      </div> */}
      <ConfirmDialog
        dialogConfig={confirmDeleteDialogConfig}
        isOpen={showConfirmDelete}
      />
    </div>
  );
}
