import React, { useEffect, useMemo, useState } from 'react';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { useTranslation } from 'react-i18next';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { Workbook } from 'exceljs';
import jsPDF from 'jspdf';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import 'jspdf-autotable';
import Popover from 'devextreme-react/popover';
import Button from 'devextreme-react/button';
import icons from '../../common/icons/icons';
import { useResize } from '../../../hooks/useResize';

export default function RfiMailGadget() {
  const { setIsLoading } = useLoaderContext();
  const { t } = useTranslation();
  const [gridInstance, setGridInstance] = useState<any>();
  const [showExportPopover, setShowExportPopover] = useState(false);
  const [rfiMailGadget, setRfiMailGadget] = useState<any>();
  const exportPopupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-dashboard__chart-export',
      'data-testid': 'rolePopover',
    };
  }, []);

  const getRfiMailGadget = async () => {
    await api
      .get(
        {
          url: apiConfig.rfiMailGadget,
        },
        setIsLoading
      )
      .then((data: any) => {
        setRfiMailGadget(data);
      });
  };

  useEffect(() => {
    getRfiMailGadget();
  }, []);

  const fileFormats = [
    {
      id: 1,
      name: 'Excel File',
      value: 'xlsx',
    },
    {
      id: 2,
      name: 'PDF File',
      value: 'pdf',
    },
  ];

  const onGridInitialized = (e: any) => {
    setGridInstance(e.component);
  };

  const exportRolesGrid = (format: any) => {
    if (format === 'xlsx') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');

      exportDataGrid({
        component: gridInstance,
        worksheet: worksheet,
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            `RFI.${format}`
          );
        });
      });
    } else if (format === 'pdf') {
      const doc = new jsPDF();
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: gridInstance,
      }).then(() => {
        doc.save('RFI.pdf');
      });
    }
  };

  const { isMobileResolution } = useResize();
  const [position, setPosition] = useState<any>('bottom');
  useEffect(() => {
    if (isMobileResolution) {
      setPosition('left');
    } else {
      setPosition('bottom');
    }
  }, [isMobileResolution]);

  return (
    <div className="m-c-grid m-c-block-grid-list">
      <div className="m-c-form-group u-mb0">
        <div className="row">
          <div className="col-16 d-flex ps-4 pe-4">
            <div className="ml-dashboard-export">
              <Button
                id="linkRfi"
                className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                aria-label="Export-btn"
                onClick={() => setShowExportPopover(true)}
              >
                <div className="m-c-icon-inside-btn" data-testid="export-all">
                  <icons.download />
                </div>
              </Button>
              <Popover
                target="#linkRfi"
                position={position}
                width={132}
                visible={showExportPopover}
                wrapperAttr={exportPopupAttributes}
                closeOnOutsideClick={true}
                onHiding={() => setShowExportPopover(false)}
              >
                <ul className="ml-expport-list">
                  {/* <li>Print</li> */}
                  {fileFormats.map((role: any, index: any) => {
                    return (
                      <li
                        key={index}
                        onClick={() => exportRolesGrid(role.value)}
                      >
                        {role.name}
                      </li>
                    );
                  })}
                </ul>
              </Popover>
            </div>
          </div>
        </div>
      </div>
      <div className="m-c-grid m-c-grid-fixed--data-height">
        <DataGrid
          dataSource={rfiMailGadget?.rfiData}
          // columnAutoWidth={true}
          showBorders={true}
          onInitialized={onGridInitialized}
        >
          <Column
            dataField="timeperiod"
            dataType="string"
            minWidth="100"
            caption=" "
          />
          <Column
            dataField="requestor"
            caption={t('labels.rfiToCompany')}
            dataType="string"
          />
          <Column
            dataField="ismManager"
            caption={t('labels.rfiToVessel')}
            dataType="string"
          />
          <Column
            dataField="infoReceived"
            caption={t('labels.inforeceived')}
            dataType="string"
          />
          <Column
            dataField="open"
            caption={t('labels.open')}
            dataType="string"
          />
        </DataGrid>
      </div>
    </div>
    // </>
  );
}
