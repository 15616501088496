import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import Tooltip from 'devextreme-react/tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Relation } from '../../../enums/relation-enum';
import {
  IEntityAttribute,
  IMasterEntity,
  IOptionvalue,
} from '../../../types/master-data';
import { IGroupCondition } from '../../../types/rules';
import icons from '../../common/icons/icons';
import RuleConditionFields from '../rule-condition-fields/RuleConditionFields';

export default function RuleCondition(props: {
  entities: IMasterEntity[];
  attributes: IEntityAttribute[];
  conditions: IOptionvalue[];
  canUserEnterOptions: IOptionvalue[];
  relations: IOptionvalue[];
  getConditions?: (optionCode: string) => Promise<void>;
  getAttributes?: (entityId: number) => Promise<void>;
  buId: number;
}) {
  const {
    entities,
    attributes,
    conditions,
    canUserEnterOptions,
    relations,
    buId,
    getConditions,
  } = props;

  const { t } = useTranslation();

  const { control, getValues, setValue } = useFormContext();

  const [groupIndex, setGroupIndex] = useState<any>([]);

  const [showRelationPopover, setShowRelationPopover] = useState(-1);

  const [forceRender, setForceRender] = useState(false);

  const relationRef = useRef<any>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (relationRef.current && !relationRef.current.contains(event.target)) {
        setShowRelationPopover(-1);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [relationRef]);

  const appendValues: IGroupCondition = {
    ruleGroupId: 0,
    ruleId: 0,
    parentRuleGroupId: null,
    dispalyOrder: 0,
    groupRelation: null,
    relation: Relation.Group,
    ruleConditions: [
      {
        ruleGroupId: 0,
        ruleConditionId: 0,
        entityId: null,
        attributeId: null,
        condition: null,
        value1: '',
        value2: '',
        canUserEnter: false,
        description: '',
        dispalyOrder: 0,
        conditionRelation: null,
        attributeType: '',
        isListValue: false,
        isSelectable: false,
        selectList: '',
        selectListDisplay: '',
        selectListValue: '',
        entityName: '',
        isEntityValue: false,
        valueEntityId: null,
        valueAttributeId: null,
        canValueUserEnter: false,
        isEditable: false,
        attributeName: '',
      },
    ],
  };

  const groupFormArray = useFieldArray({
    control: control,
    name: 'groups',
  });

  const setGroupValue = (index: number) => {
    setValue(`groups[${index}].relation`, Relation.Group);
    setValue(`groups[${index}].groupRelation`, Relation.Or);
    groupFormArray.append(appendValues);
    setGroupIndex([...groupIndex, index]);
  };

  const selectRelation = (relation: string | undefined, index: number) => {
    setValue(`groups[${index}].groupRelation`, relation);
    setShowRelationPopover(-1);
    groupFormArray.append(appendValues);
  };

  const valueChanged = (e: any, field: any) => {
    field.onChange(e.value);
  };

  const removeGroup = (index: number) => {
    groupFormArray.remove(index);
    if (index === getValues().groups.length) {
      setValue(`groups[${index - 1}].groupRelation`, null);
      setForceRender(!forceRender);
    }
  };

  const tooltipContentRender = (item: IOptionvalue) => {
    return (
      <>
        {item.optionValueCode === Relation.Or && (
          <div className="m-l-tooltip-wrapper">
            {t('tooltips.orConditionTooltip')}
          </div>
        )}
        {item.optionValueCode === Relation.And && (
          <div className="m-l-tooltip-wrapper">
            {t('tooltips.andConditionTooltip')}
          </div>
        )}
        {item.optionValueCode === Relation.Group && (
          <div className="m-l-tooltip-wrapper">
            {t('tooltips.groupConditionTooltip')}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {groupFormArray.fields.map((item: any, index: number) => {
        return (
          <div key={item.id}>
            <div
              data-testid="ruleConditionFieldWrapper"
              className="m-l-sub-section"
            >
              <RuleConditionFields
                buId={buId}
                nestedIndex={index}
                relations={relations}
                entities={entities}
                attributes={attributes}
                conditions={conditions}
                canUserEnterOptions={canUserEnterOptions}
                getConditions={getConditions}
                setGroupValue={setGroupValue}
                removeGroup={removeGroup}
              />
            </div>
            <div className="row m-l-category-dropdown-wrap">
              {getValues().groups[index].relation &&
                !getValues().groups[index].groupRelation && (
                  <div className="app-c-add-btn-wrap">
                    <Button
                      elementAttr={{ 'data-testid': 'groupAddButton' }}
                      className="app-c-btn app-c-btn--secondary min-btn-width-sm-pls"
                      onClick={() => {
                        setShowRelationPopover(index);
                      }}
                    >
                      <div className="m-c-icon-inside-btn">
                        <icons.AddIcon />
                      </div>
                    </Button>
                    {showRelationPopover === index && (
                      <ul
                        className="m-l-category-list-block"
                        ref={relationRef}
                        data-testid="groupRelationList"
                      >
                        {relations.map((item) => {
                          if (item.visible !== false) {
                            return (
                              <li
                                id={`${item.optionValueCode}${index}`}
                                key={item.optionValueCode}
                                onClick={() =>
                                  selectRelation(item.optionValueCode, index)
                                }
                              >
                                <span>{item.optionValueText}</span>
                                <Tooltip
                                  target={`#${item.optionValueCode}${index}`}
                                  showEvent="dxhoverstart"
                                  hideEvent="dxhoverend"
                                  position="right"
                                  contentRender={() =>
                                    tooltipContentRender(item)
                                  }
                                />
                              </li>
                            );
                          }
                        })}
                      </ul>
                    )}
                  </div>
                )}
              {getValues().groups[index].groupRelation && (
                <div className="row m-l-category-dropdown-wrap u-mt0 u-ml0 u-mr0">
                  <div className="m-l-category__dropdown-block">
                    <Controller
                      name={`groups[${index}].groupRelation`}
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          name="canUsrelationerEnter"
                          value={field.value}
                          elementAttr={{ 'data-testid': 'relation' }}
                          displayExpr="optionValueText"
                          valueExpr="optionValueCode"
                          dataSource={relations}
                          className="m-c-input-control m-c-select-box"
                          onValueChanged={(e) => valueChanged(e, field)}
                          itemRender={(data) => {
                            return (
                              <div
                                style={{ width: '100%' }}
                                id={`${data.optionValueCode}${index}`}
                              >
                                {data.optionValueText}
                                <Tooltip
                                  target={`#${data.optionValueCode}${index}`}
                                  showEvent="dxhoverstart"
                                  hideEvent="dxhoverend"
                                  position="right"
                                  contentRender={() =>
                                    tooltipContentRender(data)
                                  }
                                />
                              </div>
                            );
                          }}
                        ></SelectBox>
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
}
