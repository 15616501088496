import React, { useMemo, useState } from 'react';
import icons from '../../common/icons/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { useLoaderContext } from '../../../contexts/LoaderContext';

export default function AlertGadget(props: {
  unreadActiveNotificationList: any;
}) {
  const { t } = useTranslation();
  const { unreadActiveNotificationList } = props;
  const navigation = useNavigate();
  const [notificationsCount, setNotificationsCount] = useState<any[]>([]);
  const { setIsLoading } = useLoaderContext();
  const notificationCount = useMemo(() => {
    if (unreadActiveNotificationList.length > 0) {
      let notifications: any = [];
      unreadActiveNotificationList?.forEach((item: any) => {
        notifications = [...notifications, ...item.notifications];
      });
      setNotificationsCount(notifications.length);
      if (notificationsCount) {
        return true;
      } else {
        return false;
      }
    }
  }, [unreadActiveNotificationList]);

  const notificationsArchive = async (notification: any) => {
    const obj = JSON.parse(notification.relativeUrl);
    navigation(obj.url, {
      state: obj.state,
    });
    await api.put(
      {
        url: apiConfig.notificationsArchive,
        data: { notificationId: notification.notificationId },
      },
      setIsLoading
    );
  };

  return (
    <div className="m-l-usr-alert-block-wrapper">
      <div className="m-l-usr-alr-block-header">
        <div className="m-l-notification-block">
          {notificationCount ? (
            <span className="m-l-notification-mark"></span>
          ) : null}
          <icons.Bell />
        </div>
        <div className="m-l-ntfcn-title">
          {t('labels.alerts')}{' '}
          {notificationsCount ? <span>({notificationsCount})</span> : <span />}
        </div>
      </div>
      <div className="m-l-usr-alr-block-body">
        <ul className="m-l-usr-alr-items-wrapper">
          {unreadActiveNotificationList?.map((value: any, index: any) => {
            return (
              <div key={index}>
                {value?.notifications.map((item: any, index: any) => {
                  return (
                    <li key={index} className="m-l-usr-alr-item">
                      <div
                        className="m-l-rqr-pr-prt"
                        onClick={() => notificationsArchive(item)}
                      >
                        <div className="m-l-usr-avatar-part">
                          {/* <img
                            src={imageURL.profilepic}
                            className="img-responsive m-l-usr-profilepic"
                            alt="profile pic"
                          /> */}
                          <icons.Profile />
                        </div>
                        <div className="m-l-usr-rqr-info">
                          <div className="m-l-usr-rqst-part">
                            {/* {item.htmlTitle} */}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item.htmlTitle,
                              }}
                              className="m-l-user-name"
                            ></span>
                          </div>
                          <div className="m-l-usralr-dt-part">
                            {item.receivedTime}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </div>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
