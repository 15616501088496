import React from 'react';

export default function MultipleDataCellTemplate(data: any) {
  return (
    <div className="m-l-badge-container" data-testid="multipleDataCellTemplate">
      {data.value[0]}
      {data.value.length > 1 && (
        <span className="badge">{data.value.length - 1}+</span>
      )}
    </div>
  );
}
