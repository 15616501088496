import DataGrid from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDataStore } from '../../../hooks/useDataStore';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { IGridConfig } from '../../../types/grid';
import { IListEntriesObject, ListAttributes } from '../../../types/list';
import Grid from '../../common/grid/Grid';

function EntriesListGrid(
  props: {
    listEntries: IListEntriesObject;
    setListEntryId: any;
    showEditEntries: any;
    attribute: any;
    buId?: any;
  },
  ref: any
) {
  const { listEntries, setListEntryId, showEditEntries, attribute, buId } =
    props;
  const { t } = useTranslation();
  const [gridInstance, setGridInstance] = useState<any>();
  const { formatDate, apiDateAndTimeFormat, dateFormat, apiDateFormat } =
    useDateFormat();
  const [dataSource, setDataSource] = useState<any>();
  const [diplayExp, setDiplayExp] = useState('');
  const [valueExp, setValueExp] = useState('');

  const onRowClick = (e: any) => {
    setListEntryId(e.key.listEntryId);
    showEditEntries(e.key.listEntryId);
  };
  const gridRef = useRef<DataGrid>();
  const onGridInitialized = (e: any) => {
    setGridInstance(e.component);
  };

  useImperativeHandle(ref, () => ({
    exportListEntriesGrid,
  }));

  const exportListEntriesGrid = (name: any) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    gridRef.current?.instance.beginUpdate();
    gridRef.current?.instance.columnOption('listEntryId', 'visible', true);
    exportDataGrid({
      component: gridInstance,
      worksheet: worksheet,
    })
      .then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            `${name}.xlsx`
          );
        });
      })
      .then(() => {
        gridRef.current?.instance.columnOption('listEntryId', 'visible', false);
        gridRef.current?.instance.endUpdate();
      });
  };

  const { getDataSource } = useDataStore();
  const getData = async (selectListValue: any, selectList: any) => {
    const data = getDataSource(selectListValue, selectList);
    setDataSource(data);
  };

  const nameFieldType = () => {
    if (attribute.isSelectable) {
      getData(attribute.selectListValue, attribute.selectList);
      setDiplayExp(attribute.selectListDisplay);
      setValueExp(attribute.selectListValue);
    } else if (attribute.attributeType === 'DateTime') {
      setValueExp('');
      setDiplayExp('');
    } else {
      setValueExp('');
      setDiplayExp('');
    }
    return dataSource;
  };

  const nameCellTemplate = useCallback(
    (data: any) => {
      if (attribute?.attributeType === ListAttributes.DateTime) {
        return formatDate(apiDateFormat(data.entryName));
      } else {
        return data.entryName;
      }
    },
    [attribute]
  );

  useEffect(() => {
    gridRef.current?.instance?.columnOption(
      'entryName',
      'calculateCellValue',
      nameCellTemplate
    );
  }, [attribute]);

  const modifiedDateTimeCellTemplate = () => (data: any) => {
    return apiDateAndTimeFormat(data.modifiedDate);
  };

  const createdDateTimeCellTemplate = () => (data: any) => {
    return apiDateAndTimeFormat(data.createdDate);
  };

  const gridConfig: IGridConfig = {
    dataSource: listEntries,
    cellPrepared: cellPrepared,
    rowClickPermission: buId
      ? 'BULists.EditEntries'
      : 'GlobalList.EditEntriesToList',
    defaultColumns: [
      {
        caption: t('labels.name'),
        dataField: 'entryName',
        dataType: 'string',
        minWidth: 150,
        calculateCellValue: nameCellTemplate,
        lookup: dataSource && {
          dataSource: nameFieldType,
          valueExpr: valueExp,
          displayExpr: diplayExp,
        },
      },
      {
        caption: t('labels.reason'),
        dataField: 'reason',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.enteredDate'),
        dataField: 'enteredDate',
        dataType: 'date',
        minWidth: 150,
        format: dateFormat,
      },
      {
        caption: t('labels.validTillDate'),
        dataField: 'validTillDate',
        dataType: 'date',
        minWidth: 150,
        format: dateFormat,
      },
      {
        caption: t('labels.removedDate'),
        dataField: 'removedDate',
        dataType: 'date',
        minWidth: 150,
        format: dateFormat,
      },
      {
        caption: t('labels.listEntryId'),
        dataField: 'listEntryId',
        dataType: 'number',
        minWidth: 150,
        visible: false,
      },
      {
        caption: t('labels.active'),
        dataField: 'entryStatus',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.createdBy'),
        dataField: 'createdBy',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.modifiedBy'),
        dataField: 'modifiedBy',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.createdDate'),
        dataField: 'createdDate',
        dataType: 'date',
        minWidth: 150,
        calculateCellValue: createdDateTimeCellTemplate(),
      },
      {
        caption: t('labels.modifiedDate'),
        dataField: 'modifiedDate',
        dataType: 'date',
        minWidth: 150,
        calculateCellValue: modifiedDateTimeCellTemplate(),
      },
    ],
    showHeader: false,
    showAddButton: false,
    showExport: false,
    hidePagination: false,
    noDataText: t('labels.addNewList'),
    rowClick: onRowClick,
    ref: gridRef,
    initialized: onGridInitialized,
  };

  return (
    <div className="m-c-grid" data-testid="EditListGrid">
      <Grid gridConfig={gridConfig} />
    </div>
  );
}

const cellPrepared = (e: any) => {
  if (e.rowType === 'data' && e.column.dataField === 'entryStatus') {
    e.cellElement.className =
      e.data.entryStatus === 'Inactive'
        ? 'status-inactive-wtout'
        : 'status-active-wtout';
  }
};
const EditListGrid = forwardRef(EntriesListGrid);
export default EditListGrid;
