import Button from 'devextreme-react/button';
import CheckBox from 'devextreme-react/check-box';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import React, { useEffect, useState } from 'react';
import imageURL from '../../assets/images';
import { MarsApiService as api } from '../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import './Login.scss';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toast from '../../utils/toast';
import { useAuth } from '../../contexts/AuthProvider';
import { getAdditionalInfo } from '../../utils/jwt-decode';
import { useLoaderContext } from '../../contexts/LoaderContext';

export default function VesselInformation() {
  const [userName, setUserName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [rememberMe, setRememberMe] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const location = useLocation();
  const [from, setFrom] = useState<any>();
  const navigation = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (location.state) {
      const { from }: any = location.state;
      if (from && from.pathname) {
        setFrom(from);
      }
    }
  }, []);

  useEffect(() => {
    document.body.classList.add('login-page');

    return () => {
      document.body.classList.remove('login-page');
    };
  });
  const [loginVisible, SetloginVisible] = React.useState(true);
  const [success, Setsuccess] = React.useState(false);
  const forgotPassword = () => {
    navigation('/forgot-password');
  };
  const session = sessionStorage.getItem('token')
    ? sessionStorage.getItem('token')
    : localStorage.getItem('token');
  const { setIsLoading } = useLoaderContext();
  const auth = useAuth();
  const onLogin = async () => {
    if (userName && password) {
      await api
        .post(
          {
            url: apiConfig.login,
            params: { username: userName, password: password },
          },
          setIsLoading
        )
        .then(async (res) => {
          const storage: Storage = rememberMe ? localStorage : sessionStorage;
          storage.setItem('token', res?.token);
          storage.setItem('additionalInfoToken', res?.additionalInfoToken);
          storage.setItem('buList', JSON.stringify(res?.buList));
          storage.setItem('usersName', res?.name);
          storage.setItem('employeeId', res?.employeeId);
          storage.setItem('userId', res?.userId);
          storage.setItem('rememberMe', rememberMe?.toString());
          const tokenFlag = getAdditionalInfo();
          storage.setItem('update_password', tokenFlag.update_password);
          const loggedinBu = (res?.buList || []).find((y: any) => {
            return y.isDefault === true;
          });
          if (!loggedinBu && res?.buList.length > 0) {
            await switchBu(res?.buList[0], res?.userId, res?.buList);
            storage.setItem('loggedInBuName', res?.buList[0].buName);
          } else {
            storage.setItem('loggedInBuName', loggedinBu.buName);
          }
          auth.login(res);
          navigation('/');
        })
        .catch((e) => {
          if (
            e.response &&
            (e.response.status === 401 || e.response.status === 404)
          ) {
            toast.error({
              title: 'Error',
              message: t('errors.incorrectPassword'),
            });
          } else if (e.response) {
            toast.error({ title: 'Error', message: e.response.data?.Message });
          }
        });
    } else {
      toast.error({ title: 'Error', message: t('errors.enterUserName') });
    }
  };

  const switchBu = async (bu: any, userId: any, buList: any[]) => {
    const switchBuObject = {
      userId: userId,
      buId: bu.buId,
      isDefault: true,
    };
    await api
      .put({ url: apiConfig.switchBu, data: switchBuObject }, setIsLoading)
      .then(() => {
        buList.map((item: any) => {
          if (item.buId == bu.buId) {
            item.isDefault = true;
          } else {
            item.isDefault = false;
          }
        });
        sessionStorage.getItem('token')
          ? sessionStorage.setItem('buList', JSON.stringify(buList))
          : localStorage.setItem('buList', JSON.stringify(buList));
        auth.switchBu(bu);
      });
  };

  const passwordButton = {
    icon: showPassword ? imageURL.openedeye : imageURL.closedeye,
    onClick: () => {
      setShowPassword(!showPassword);
    },
  };

  if (session) {
    if (
      from &&
      (from.pathname === '/vessel-info' || from.pathname === '/report-designer')
    ) {
      return <Navigate to={from.pathname} />;
    } else {
      return <Navigate to="/" />;
    }
  } else {
    return (
      <div className="m-l-login-wrapper">
        <div className="m-l-login-container">
          <div className="m-l-login-left-part">
            <img
              src={imageURL.loginBg}
              className="img-responsive m-l-login-bg"
              alt="Login"
            />
          </div>
          <div className="m-l-login-right-part container">
            {/* ----------------------Login-------------------------------- */}
            <div
              className={
                'm-l-login-data-cntr ' + (loginVisible ? '' : 'u-hide')
              }
            >
              <h1 className="m-l-login-data-title">{t('headers.login')}</h1>
              <div className="m-l-login-data-sub">
                {t('labels.welcomeback')}
              </div>
              <form className="m-l-login-form">
                <div className="m-c-form-group">
                  <label className="m-l-input-label">{t('labels.email')}</label>
                  <TextBox
                    className="m-c-input-control"
                    mode="email"
                    value={userName}
                    onValueChanged={(e) => {
                      setUserName(e.value);
                    }}
                  />
                </div>
                <div className="m-c-form-group">
                  <label className="m-l-input-label">
                    {t('labels.password')}
                  </label>
                  <TextBox
                    className="m-c-input-control m-c-input-password"
                    mode={showPassword ? 'text' : 'password'}
                    value={password}
                    onValueChanged={(e) => {
                      setPassword(e.value);
                    }}
                  >
                    <TextBoxButton
                      name="password"
                      location="after"
                      options={passwordButton}
                    />
                  </TextBox>
                </div>
                <div className="m-l-fg-pwd-wrap">
                  <div className="m-l-fg-pwd-left">
                    <div className="m-c-checkbox">
                      <CheckBox
                        text={t('labels.remeberMe')}
                        value={rememberMe}
                        onValueChanged={(e) => setRememberMe(e.value)}
                      />
                    </div>
                  </div>
                  <div className="m-fg-pwd-right">
                    <a className="m-l-fg-frgt-link" onClick={forgotPassword}>
                      {t('labels.forgottPassword')}
                    </a>
                  </div>
                </div>
                <div className="m-l-login-btn-wrap">
                  <Button
                    className="app-c-btn app-c-btn--primary"
                    onClick={onLogin}
                  >
                    {t('buttons.login')}
                  </Button>
                </div>
              </form>
              <div className="m-l-login-footer-wrapper">
                <div className="m-l-pwrd-by">powered by</div>
                <div className="m-l-lg-footer-logo">
                  <img
                    src={imageURL.grayMARSLogo}
                    className="img-responsive m-l-lgn-img"
                    alt="logo"
                  />
                </div>
                <div className="m-l-contact-info">
                  Contact us : technicalsupport@marsglobalsolutions.com
                </div>
              </div>
            </div>
            {/* ----------------------password changed successfully-------------------------------- */}
            <div className={'m-l-login-data-cntr ' + (success ? '' : 'u-hide')}>
              <div className="m-l-login-form m-l-sucess-block">
                <div className="m-l-aprve-img-wrap">
                  <img
                    src={imageURL.successIconlg}
                    className="img-responsive"
                    alt="success icon"
                  />
                </div>

                <div className="m-l-lgn-success">
                  Password has been changed successfully
                </div>
                <div className="m-l-login-btn-wrap">
                  <Button className="app-c-btn app-c-btn--primary">
                    Back to Log In
                  </Button>
                </div>
              </div>
              <div className="m-l-login-footer-wrapper">
                <div className="m-l-pwrd-by">powered by</div>
                <div className="m-l-lg-footer-logo">
                  <img
                    src={imageURL.grayMARSLogo}
                    className="img-responsive m-l-lgn-img"
                    alt="logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
