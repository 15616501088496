import jwt_decode from 'jwt-decode';

export const getAdditionalInfo = () => {
  const token: any = sessionStorage.getItem('additionalInfoToken')
    ? sessionStorage.getItem('additionalInfoToken')
    : localStorage.getItem('additionalInfoToken');
  if (token) {
    const decodedToken: any = jwt_decode(token);
    return decodedToken;
  }
};

export const getTokenInfo = () => {
  const token: any = sessionStorage.getItem('token')
    ? sessionStorage.getItem('token')
    : localStorage.getItem('token');
  if (token) {
    const decodedToken: any = jwt_decode(token);
    return decodedToken;
  }
};

export const getEmail = () => {
  const decodedToken = getTokenInfo();
  return decodedToken?.email;
};

export const getCompanyType = () => {
  const decodedToken = getAdditionalInfo();
  return decodedToken?.company_type;
};

export const getCompanyId = () => {
  const decodedToken = getAdditionalInfo();
  return decodedToken?.company_id;
};

export const getCompanyName = () => {
  return sessionStorage.getItem('companyName')
    ? sessionStorage.getItem('companyName')
    : localStorage.getItem('companyName');
};

export const getUserName = () => {
  return sessionStorage.getItem('usersName')
    ? sessionStorage.getItem('usersName')
    : localStorage.getItem('usersName');
};

export const getEmployeeId = () => {
  return sessionStorage.getItem('employeeId')
    ? sessionStorage.getItem('employeeId')
    : localStorage.getItem('employeeId');
};

export const getUserId = () => {
  return sessionStorage.getItem('userId')
    ? sessionStorage.getItem('userId')
    : localStorage.getItem('userId');
};

export const getLoginBUName = () => {
  return sessionStorage.getItem('loggedInBuName')
    ? sessionStorage.getItem('loggedInBuName')
    : localStorage.getItem('loggedInBuName');
};

export const getToken = () => {
  return sessionStorage.getItem('token')
    ? sessionStorage.getItem('token')
    : localStorage.getItem('token');
};

export const getAdditionalInfoToken = () => {
  return sessionStorage.getItem('additionalInfoToken')
    ? sessionStorage.getItem('additionalInfoToken')
    : localStorage.getItem('additionalInfoToken');
};

export const getRememberMe = () => {
  return sessionStorage.getItem('rememberMe')
    ? sessionStorage.getItem('rememberMe')
    : localStorage.getItem('rememberMe');
};

export const getBuList = () => {
  return sessionStorage.getItem('buList')
    ? sessionStorage.getItem('buList')
    : localStorage.getItem('buList');
};
