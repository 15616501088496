import React, { useEffect, useState } from 'react';
import {
  IBerthParameter,
  IVetRequestBerth,
} from '../../../../types/berthfit-execution';
import BerthFitComments from '../berth-fit-comments/BerthFitComments';
import BerthParameterGrid from '../berth-parameter-grid/BerthParameterGrid';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { MarsApiService as api } from '../../../../api/mars-api-service';

export default function BerthDetails(props: {
  berthDetails: IVetRequestBerth;
  imoNumber?: string;
  buId: number;
  berthFitId: number;
  getPortList: any;
}) {
  const { berthDetails, imoNumber, buId, berthFitId, getPortList } = props;

  const [berthParameters, setBerthParameters] = useState<IBerthParameter[]>([]);

  const getBerthDetails = async () => {
    await api
      .get({
        url: apiConfig.vetRequestBerthParameters + berthDetails.berthId,
        params: {
          imo: imoNumber,
          buId: buId,
          terminalId: berthDetails.terminalId,
          berthFitId: berthFitId,
        },
      })
      .then((res: IBerthParameter[]) => {
        setBerthParameters(res);
      });
  };

  useEffect(() => {
    if (berthDetails) {
      getBerthDetails();
    }
  }, [berthDetails]);

  return (
    // <div className="accordion-item__body-rl-block incident-wrap">
    //   <BerthFitComments
    //     berthFitId={berthFitId}
    //     berthTerminalComment={berthDetails?.comment}
    //     berthTerminalStatus={berthDetails?.status}
    //     terminalId={berthDetails?.terminalId}
    //     berthId={berthDetails?.berthId}
    //     isOverrided={berthDetails?.isOverrided}
    //   />
    //   <div className="row m-l-cap-rules-wrapper">
    //     <BerthParameterGrid berthParameters={berthParameters} />
    //   </div>
    // </div>

    <div className="m-l-third-layer-accordion-inner-page-wrap">
      <BerthFitComments
        getPortList={getPortList}
        berthFitId={berthFitId}
        berthTerminalComment={berthDetails?.comment}
        berthTerminalStatus={berthDetails?.status}
        terminalId={berthDetails?.terminalId}
        berthId={berthDetails?.berthId}
        isOverrided={berthDetails?.isOverrided}
      />
      <div className="m-l-accordion-inner-page-grid-row">
        <BerthParameterGrid berthParameters={berthParameters} />
      </div>
    </div>
  );
}
