import React from 'react';
import { Restricted } from '../../../../contexts/PermissionContext';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import icons from '../../../common/icons/icons';

const TerminalAccordionHeader = (props: { data: any }) => {
  const { data } = props;
  const { formatDate } = useDateFormat();
  return (
    <div>
      <div className="m-l-accordion__header">
        <div className="left-block">
          <span className="left-icon-block">
            {data.isOverrided && !data.isPassed && <icons.Overridefail />}
            {data.isOverrided && data.isPassed && <icons.OverridePass />}
            {!data.isPassed && !data.isOverrided && <icons.circleCrossDanger />}
            {data.isPassed && !data.isOverrided && <icons.circleTickDanger />}
          </span>
          <h5 className="title">{data.terminalName}</h5>
          <Restricted permission="BerthFitVet.ViewTerminalInfo">
            <div className="m-l-view-info-link">
              <span className="view-terminal-info">View Info</span>
            </div>
          </Restricted>
          {data.isTerminalApproved ? (
            data.terminalApprovalValidTill ? (
              new Date(data.terminalApprovalValidTill) < new Date() ? (
                <p className="m-l-port-approval-info">
                  Terminal Approval Expired. Valid Till :{' '}
                  {formatDate(data.terminalApprovalValidTill)}
                </p>
              ) : (
                <p className="m-l-port-approval-info-approved">
                  Terminal Approved. Valid Till :{' '}
                  {formatDate(data.terminalApprovalValidTill)}
                </p>
              )
            ) : (
              <p className="m-l-port-approval-info-approved">
                Terminal Approved.
              </p>
            )
          ) : (
            <p className="m-l-port-approval-info">Terminal Not Approved.</p>
          )}
          <p className="m-l-port-info">{data?.terminalApprovalInfo}</p>
        </div>
      </div>
    </div>
  );
};

export default TerminalAccordionHeader;
