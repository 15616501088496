import React from 'react';
import { Restricted } from '../../../../contexts/PermissionContext';
import { IVetRequestPorts } from '../../../../types/berthfit-execution';
import icons from '../../../common/icons/icons';
import Tooltip from 'devextreme-react/tooltip';
import { useDateFormat } from '../../../../hooks/useDateFormat';

const PortAccordionHeader = (props: { data: IVetRequestPorts }) => {
  const { data } = props;
  const { formatDate, dateTimeWithTimeZone } = useDateFormat();
  return (
    <div>
      <div className="m-l-accordion__header">
        <div className="left-block">
          <span className="left-icon-block">
            {data.isOverrided && !data.isPassed && <icons.Overridefail />}
            {data.isOverrided && data.isPassed && <icons.OverridePass />}
            {!data.isPassed && !data.isOverrided && <icons.circleCrossDanger />}
            {data.isPassed && !data.isOverrided && <icons.circleTickDanger />}
          </span>
          <h5 className="title">{data?.portName}</h5>
          <Restricted permission="BerthFitVet.ViewPortInfo">
            <div className="m-l-view-info-link m-l-view-info-link--web-view">
              <span className="view-port-info">View Info</span>
            </div>
          </Restricted>
          {data.portWarning?.warning && (
            <>
              <span className="m-l-port-warning" id={data?.portCode}>
                <icons.PortWarning />
              </span>
              <Tooltip
                target={`#${data?.portCode}`}
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                position="right"
                contentRender={() => {
                  return (
                    <div className="m-l-tooltip-wrapper m-l-warning-icon-tooltip">
                      <span>{data.portWarning?.restrictedMessage}</span>
                    </div>
                  );
                }}
              />
            </>
          )}
          {data.isApprovedPort ? (
            data.portApprovalValidTill ? (
              new Date(data.portApprovalValidTill) < new Date() ? (
                <p className="m-l-port-approval-info">
                  Port Approval Expired. Valid Till :{' '}
                  {formatDate(data.portApprovalValidTill)}
                </p>
              ) : (
                <p className="m-l-port-approval-info-approved">
                  Port Approved. Valid Till :{' '}
                  {formatDate(data.portApprovalValidTill)}
                </p>
              )
            ) : (
              <p className="m-l-port-approval-info-approved">Port Approved.</p>
            )
          ) : (
            <p className="m-l-port-approval-info">Port Not Approved.</p>
          )}
        </div>
        <div className="right-block">
          <div className="right-block-berthfit-id-date-wrap">
            <div className="right-block-berthfit-id-block">
              <span className="id">Berth Fit ID: </span>
              <span className="value">{data.berthId}</span>
            </div>
            {data?.berthFitFinalizedDate && (
              <div className="right-block-berthfit-date-block">
                <span className="id">Berth Fit Date: </span>
                <span className="value">
                  {' '}
                  {dateTimeWithTimeZone(data?.berthFitFinalizedDate + 'z')}
                </span>
              </div>
            )}
          </div>
          <div className="m-l-view-info-link m-l-view-info-link--mobile-view">
            <span>View Info</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortAccordionHeader;
