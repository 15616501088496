import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MarsApiService as api } from '../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import { useTranslation } from 'react-i18next';
import { IGridConfig } from '../../types/grid';
import Grid from '../../components/common/grid/Grid';
import { useDateFormat } from '../../hooks/useDateFormat';
import { useLoaderContext } from '../../contexts/LoaderContext';

export default function ExternalInterfaces() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [interfaceList, setInterfaceList] = useState<any>([]);
  const { apiDateAndTimeFormat } = useDateFormat();

  const { setIsLoading } = useLoaderContext();
  const getExternalInterfaces = async () => {
    await api
      .get(
        {
          url: apiConfig.getExternalInterfaces,
        },
        setIsLoading
      )
      .then((response: any) => {
        setInterfaceList(response);
      });
  };

  useEffect(() => {
    getExternalInterfaces();
  }, []);

  const cellTemplate = (data: any) => {
    return <div>{apiDateAndTimeFormat(data.value)}</div>;
  };

  const onRowClick = (e: any) => {
    navigate('/external-interfaces/grid', {
      state: {
        interfaceData: e.key,
      },
    });
  };

  const gridConfig: IGridConfig = {
    dataSource: interfaceList,
    testId: 'companiesGrid',
    class: 'm-c-grid m-c-grid--company',
    remoteOperations: false,
    defaultColumns: [
      {
        caption: t('labels.interfaceName'),
        dataField: 'displayName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.lastExecutionStartDateTime'),
        dataField: 'lastExecutionStartDate',
        dataType: 'date',
        minWidth: 150,
        cellTemplate: 'cellTemplate',
      },
      {
        caption: t('labels.lastExectionEnddateTime'),
        dataField: 'lastExecutionEndDate',
        dataType: 'date',
        minWidth: 150,
        cellTemplate: 'cellTemplate',
      },
      {
        caption: t('labels.nextExecutionDateTime'),
        dataField: 'nextExecutionDate',
        dataType: 'date',
        minWidth: 150,
        cellTemplate: 'cellTemplate',
      },
      {
        caption: t('labels.lastExectionStatus'),
        dataField: 'status',
        dataType: 'string',
        minWidth: 150,
      },
    ],
    rowClick: onRowClick,
    rowClickPermission: 'ExternalInterfaces.ViewDetail',
    showHeader: true,
    header: t('headers.externalInterfaces'),
    showAddButton: false,
    showExport: true,
    excelFileName: 'Interfaces.xlsx',
  };

  return (
    <div className="inner-wrapper page-inner-wrap">
      <ul aria-label="breadcrumb" className="m-c-bredcrumb">
        <li>
          <a>{t('headers.externalInterfaces')}</a>
        </li>
      </ul>
      <Grid gridConfig={gridConfig} cellTemplate={cellTemplate} />
    </div>
  );
}
