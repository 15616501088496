import React, { useEffect, useState } from 'react';
import './Report.scss';
import ReportHeader from '../../components/report/report-header/ReportHeader';
import ReportAccordion from '../../components/report/report-accordion/ReportAccordion';
import { MarsApiService as api } from '../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import { IFavorites, ILayouts } from '../../types/report';
import { useLocation } from 'react-router-dom';

export default function Report() {
  const [isVisible, setIsVisible] = useState(false);
  const [layoutsData, setLayoutsData] = useState<ILayouts[]>([]);
  const [allLayoutsData, setAllLayoutsData] = useState<ILayouts[]>([]);
  const [sharedLayoutsData, setSharedLayoutsData] = useState<ILayouts[]>([]);
  const location = useLocation();
  const [reportHeaderId, setReportHeaderId] = useState();
  const [reportCode, setReportCode] = useState();
  const [layoutId, setLayoutId] = useState();
  const [favoriteId, setFavoriteId] = useState();
  const [reportFavouriteFilters, setReportFavouriteFilters] = useState<any>([]);
  const [reportOptions, setReportOptions] = useState<any>();
  const [standardLayoutIndex, setStandardLayoutIndex] = useState<any>([]);
  const [myDefaultLayoutIndex, setMyDefaultLayoutIndex] = useState<any>([]);
  let index: any;
  const [reportName, setReportName] = useState();
  const [queryId, setQueryId] = useState(false);
  const [allFavoritesData, setAllFavoritesData] = useState<IFavorites[]>([]);

  const getLayoutDetails = async () => {
    await api
      .get({
        url: apiConfig.reportLayouts + reportHeaderId,
        params: { isPrivate: true, isGetAll: false },
      })
      .then((response: any) => {
        setLayoutsData(response.data);
      });
  };

  const getAllLayoutDetails = async () => {
    await api
      .get({
        url: apiConfig.reportLayouts + reportHeaderId,
        params: { isGetAll: true },
      })
      .then((response: any) => {
        setAllLayoutsData(response.data);
        index = response.data?.findIndex((item: any) => item?.layoutType == 0);
        setStandardLayoutIndex(index);
        index = response.data?.findIndex(
          (item: any) => item?.isDefault == true
        );
        setMyDefaultLayoutIndex(index);
      });
  };

  const getSharedLayoutDetails = async () => {
    await api
      .get({
        url: apiConfig.reportLayouts + reportHeaderId,
        params: { isPrivate: false, isGetAll: false },
      })
      .then((response: any) => {
        setSharedLayoutsData(response.data);
      });
  };

  useEffect(() => {
    if (reportHeaderId) {
      getLayoutDetails();
      getSharedLayoutDetails();
      getAllLayoutDetails();
    }
  }, [reportHeaderId]);

  useEffect(() => {
    if (location.state) {
      const { reportHeaderId }: any = location.state;
      const { reportCode }: any = location.state;
      const { reportName }: any = location.state;
      setReportCode(reportCode);
      setReportHeaderId(reportHeaderId);
      setReportName(reportName);
    }
  }, [location.state]);

  return (
    <div className="inner-wrapper page-inner-wrap">
      <ReportHeader
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        layoutsData={layoutsData}
        reportHeaderId={reportHeaderId}
        setLayoutId={setLayoutId}
        layoutId={layoutId}
        reportCode={reportCode}
        reportFavouriteFilters={reportFavouriteFilters}
        favoriteId={favoriteId}
        setFavoriteId={setFavoriteId}
        sharedLayoutsData={sharedLayoutsData}
        getLayoutDetails={getLayoutDetails}
        getSharedLayoutDetails={getSharedLayoutDetails}
        allLayoutsData={allLayoutsData}
        reportOptions={reportOptions}
        setReportOptions={setReportOptions}
        standardLayoutIndex={standardLayoutIndex}
        getAllLayoutDetails={getAllLayoutDetails}
        queryId={queryId}
        allFavoritesData={allFavoritesData}
        setAllFavoritesData={setAllFavoritesData}
        myDefaultLayoutIndex={myDefaultLayoutIndex}
      />
      <div className="m-l-inner-page-body">
        <ReportAccordion
          reportHeaderId={reportHeaderId}
          layoutId={layoutId}
          reportCode={reportCode}
          reportFavouriteFilters={reportFavouriteFilters}
          setReportFavouriteFilters={setReportFavouriteFilters}
          favoriteId={favoriteId}
          reportOptions={reportOptions}
          setReportOptions={setReportOptions}
          reportName={reportName}
          queryId={queryId}
          setQueryId={setQueryId}
          allFavoritesData={allFavoritesData}
        />
      </div>
      {/* <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              <div className="m-c-form__inline m-c-form__report">
                <div className="m-c-form__icon">
                  <Button
                    className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                    type="normal"
                  >
                    <div className="m-c-icon-inside-btn">
                      <icons.Heart />
                    </div>
                  </Button>
                </div>
                <div className="m-c-form__input">
                  <div className="m-c-form-group">
                    <SelectBox className="m-c-input-control m-c-select-box" />
                  </div>
                </div>
              </div>
              <div className="m-c-form__inline m-c-form__report">
                <div className="m-c-form__icon">
                  <Button
                    id="layout"
                    className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                    aria-label="Export-btn"
                    onClick={() => setShowExportPopover(true)}
                  >
                    <div
                      className="m-c-icon-inside-btn"
                      data-testid="export-all"
                    >
                      <icons.Layout />
                    </div>
                  </Button>
                  <Popover
                    target="#layout"
                    position="bottom"
                    width={132}
                    visible={showExportPopover}
                    wrapperAttr={exportPopupAttributes}
                    closeOnOutsideClick={true}
                    onHiding={() => setShowExportPopover(false)}
                  >
                    <ul className="ml-expport-list">
                      <li onClick={() => setIsVisible(true)}>
                        {' '}
                        Manage layout{' '}
                      </li>
                    </ul>
                  </Popover>
                </div>
                <div className="m-c-form__input">
                  <div className="m-c-form-group">
                    <SelectBox
                      className="m-c-input-control m-c-select-box"
                      placeholder="Standard layout"
                    />
                  </div>
                </div>
              </div>
              <Button
                className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                type="normal"
              >
                <div className="m-c-icon-inside-btn">
                  <icons.IconSettings />
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
