import Button from 'devextreme-react/button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import icons from '../../common/icons/icons';

export default function ConfigurationsList(props: {
  configurationList: any;
  selectedConfig: any;
  setSelectedConfig: any;
  // setConfigurationList: any;
}) {
  const { configurationList, selectedConfig, setSelectedConfig } = props;
  const { t } = useTranslation();
  return (
    <ul className="m-l-vertical-tab-block">
      {configurationList?.map((item: any) => {
        return (
          <li
            key={item.id}
            className={selectedConfig === item.id ? 'tab-selected' : ''}
            onClick={() => setSelectedConfig(item.id)}
          >
            {t(item.value)}
          </li>
        );
      })}
    </ul>
  );
}
