import React, { useEffect, useMemo, useState } from 'react';
import './uiComponent.scss';
import Button from 'devextreme-react/button';
import icons from '../../../components/common/icons/icons';
import { TextBox } from 'devextreme-react/text-box';
import SelectBox from 'devextreme-react/select-box';
import CountryList from '../../../../src/components/Country.json';
import TabPanel from 'devextreme-react/tab-panel';
import Grid from '../../../components/common/grid/Grid';
import { IGridConfig } from '../../../types/grid';

import { IDialogConfig } from '../../../types/dialog';
import ConfirmDialog from '../../../components/common/confirm/ConfirmDialog';
import toast from '../../../utils/toast';
import DateBox from 'devextreme-react/date-box';
import { Accordion, Item } from 'devextreme-react/accordion';
import TagBox from 'devextreme-react/tag-box';

import FormBuilder from '../../../components/common/form-builder/FormBuilder';
import { useForm, Controller, useFormContext } from 'react-hook-form';
import CheckBox from 'devextreme-react/check-box';
import Switch from 'devextreme-react/switch';
import ScrollView from 'devextreme-react/scroll-view';
import { IModalConfig } from '../../../types/modal';
import Modal from '../../../components/common/modal/Modal';
import RadioGroup from 'devextreme-react/radio-group';
import TextArea from 'devextreme-react/text-area';

export default function UiComponent() {
  useEffect(() => {
    document.body.classList.add('ui-page');

    return () => {
      document.body.classList.remove('ui-page');
    };
  });

  //form with custom select
  const companyForm = useForm({
    defaultValues: {
      name: '',
      emailId: '',
      personPhone: '',
    },
  });
  const countryList = CountryList.sort();
  const { control } = useForm();
  const handleSaveClick = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      // console.log(getValues());
    }
    return;
  };
  const { getValues, reset } = companyForm;
  const personForm1 = [
    {
      name: 'name',
      label: 'Name',
      control: control,
      fieldType: 'customSelect',
      dataSource: [
        { label: 'Mr.', value: 1 },
        { label: 'Mrs.', value: 2 },
      ],
      displayExpr: 'label',
      valueExpr: 'value',
      searchEnabled: false,
      selectBoxName: 'title',
      rules: [
        {
          type: 'required',
          message: 'Company Name required',
        },
      ],
    },
    {
      name: 'emailId',
      label: 'Email ID',
      control: control,
      fieldType: 'text',
    },
    {
      name: 'personPhone',
      label: 'Phone Number',
      control: control,
      fieldType: 'customSelect',
      dataSource: countryList,
      displayExpr: 'callingCode',
      valueExpr: 'value',
      searchEnabled: true,
      selectBoxName: 'personPhoneCode',
      itemRender: renderItem,
      rules: [
        {
          type: 'required',
          message: 'Company Name required',
        },
      ],
    },
    {
      name: 'companyStatus',
      label: 'Flag',
      control: control,
      fieldType: 'select',
      disableSearch: true,
      displayExpr: 'label',
      valueExpr: 'value',
      dataSource: countryList,
      fieldRender: (data: any) => {
        return (
          <>
            <div className="m-c-selectbox-with-iconflag">
              <img
                src={data && data.flag}
                alt={data && data.value}
                className="m-c-slbox-flag"
              />
              <TextBox defaultValue={data && data.label} readOnly={true} />
            </div>
          </>
        );
      },
    },
  ];
  // --------------- select box ------------//
  const data = [
    {
      ID: 1,
      Name: 'abc',
    },
    {
      ID: 2,
      Name: 'xyz',
    },
  ];
  // --------------- multi select dropdown -----------//
  const simpleProducts = [
    'Terminal 01',
    'Terminal 02',
    'Terminal 03',
    'Terminal 04',
    'Terminal 05',
  ];
  const disabledValue = [simpleProducts[0]];
  //  -------------- tab--------------------//
  const items = [
    {
      title: 'Requestor Company Info',
      template: 'companyInfoForm',
    },
    {
      title: 'Contract Information',
      template: 'contractInformation',
    },
  ];
  // -------------------grid----------------//
  const onRowClick = (e: any) => {
    // console.log(e);
  };

  const dataSource = [
    {
      ID: 1,
      CompanyName: 'Super Mart of the West',
      Address: '702 SW 8th Street',
      City: 'Bentonville',
      State: 'Arkansas',
      Zipcode: 72716,
      Phone: '(800) 555-2797',
      Fax: '(800) 555-2171',
      Website: 'http://www.nowebsitesupermart.com',
      Status: 'Active',
    },
    {
      ID: 2,
      CompanyName: 'Electronics Depot',
      Address: '2455 Paces Ferry Road NW',
      City: 'Atlanta',
      State: 'Georgia',
      Zipcode: 30339,
      Phone: '(800) 595-3232',
      Fax: '(800) 595-3231',
      Website: 'http://www.nowebsitedepot.com',
      Status: 'Active',
    },
    {
      ID: 3,
      CompanyName: 'K&S Music',
      Address: '1000 Nicllet Mall',
      City: 'Minneapolis',
      State: 'Minnesota',
      Zipcode: 55403,
      Phone: '(612) 304-6073',
      Fax: '(612) 304-6074',
      Website: 'http://www.nowebsitemusic.com',
      Status: 'Active',
    },
    {
      ID: 4,
      CompanyName: "Tom's Club",
      Address: '999 Lake Drive',
      City: 'Issaquah',
      State: 'Washington',
      Zipcode: 98027,
      Phone: '(800) 955-2292',
      Fax: '(800) 955-2293',
      Website: 'http://www.nowebsitetomsclub.com',
      Status: 'Active',
    },
    {
      ID: 5,
      CompanyName: 'E-Mart',
      Address: '3333 Beverly Rd',
      City: 'Hoffman Estates',
      State: 'Illinois',
      Zipcode: 60179,
      Phone: '(847) 286-2500',
      Fax: '(847) 286-2501',
      Website: 'http://www.nowebsiteemart.com',
      Status: 'Active',
    },
    {
      ID: 6,
      CompanyName: 'Walters',
      Address: '200 Wilmot Rd',
      City: 'Deerfield',
      State: 'Illinois',
      Zipcode: 60015,
      Phone: '(847) 940-2500',
      Fax: '(847) 940-2501',
      Website: 'http://www.nowebsitewalters.com',
      Status: 'Active',
    },
  ];

  const gridConfig: IGridConfig = {
    dataSource: dataSource,
    defaultColumns: [
      {
        caption: 'Company Name',
        dataField: 'CompanyName',
        dataType: 'string',
      },
      {
        caption: 'City',
        dataField: 'City',
        dataType: 'string',
      },
      {
        caption: 'State',
        dataField: 'State',
        dataType: 'string',
      },
      {
        caption: 'Phone',
        dataField: 'Phone',
        dataType: 'string',
      },
      {
        caption: 'Fax',
        dataField: 'Fax',
        dataType: 'string',
      },
      {
        caption: 'Status',
        dataField: 'Status',
        dataType: 'string',
        cellTemplate: 'statusTemplate',
      },
    ],
    rowClick: onRowClick,
  };
  // ---------------------modal--------------//
  const [isOpen, setIsOpen] = useState(false);
  const handleDialogOpen = () => {
    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = () => {
    setIsOpen(false);
  };

  const getDialogConfig = (): IDialogConfig => {
    return {
      title: 'Confirmation',
      content: 'Are you sure you need to update the information',
      okButtonLabel: 'Yes',
      cancelButtonLabel: 'No',
      handleSubmit: handleSubmit,
      handleClose: handleDialogClose,
    };
  };

  //--------------Accordion---------------//
  const accordionData = [
    {
      title: 'Personal Data',
      firstName: 'John',
      lastName: 'Smith',
      birthYear: 1986,
    },
    {
      title: 'Contacts',
      phone: '(555)555-5555',
      email: 'John.Smith@example.com',
    },
    {
      title: 'Address',
      state: 'CA',
      city: 'San Francisco',
      street: 'Stanford Ave',
    },
  ];

  //////////////////////////////add new incident modal/////////////////////////////////
  const items1 = ['Yes', 'No'];
  const [isVisible, setIsVisible] = useState(false);
  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-add-new-incident-modal',
    };
  }, []);
  const handleSaveClick1 = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const handleCancelClick = () => {
    setIsVisible(false);
  };

  const clickButton = () => {
    setIsVisible(true);
  };

  const modalConfig: IModalConfig = {
    width: 1262,
    height: 882,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };
  //////////////////////////////PSC modal/////////////////////////////////
  const [isVisiblePsc, setIsVisiblePsc] = useState(false);
  const popupAttributesPsc = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-psc-modal',
    };
  }, []);
  const handleSaveClickPsc = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      setIsVisiblePsc(false);
    } else {
      setIsVisiblePsc(true);
    }
  };

  const handleCancelClickPsc = () => {
    setIsVisiblePsc(false);
  };

  const clickButtonPsc = () => {
    setIsVisiblePsc(true);
  };

  const modalConfigPsc: IModalConfig = {
    width: 1262,
    height: 530,
    visible: isVisiblePsc,
    showTitle: false,
    closeOnOutsideClick: false,
  };
  //dropdown
  const [isStatusblock, setStatusBlock] = useState(false);
  const showStatus = () => {
    setStatusBlock(!isStatusblock);
  };

  return (
    <div>
      <div className="m-l-container">
        <div className="main-container app-l-ui-layout">
          <div className="ui-header">
            <h1>Mars</h1>
            <div className="ui-hd-right">
              <h4>UI Overview</h4>
              <h5>Components & Styleguides</h5>
            </div>
          </div>
          <div className="container app-l-uicontainer">
            {/*************  --------------------Buttons-------------------- ************/}
            <div className="app-l-cmp-wrapper">
              <h3>Buttons</h3>
              <hr />
              <div className="app-l-cmp-data-container">
                <div className="app-l-cmp-row">
                  <div className="app-l-cmp-items">
                    <h6>Primary Button</h6>
                    <Button className="app-c-btn app-c-btn--primary min-btn-width">
                      Save
                    </Button>
                  </div>
                  <div className="app-l-cmp-items">
                    <h6>Primary Button with icon</h6>
                    <Button className="app-c-btn app-c-btn--primary ">
                      <div className="m-c-icon-inside-btn u-pr-lg">
                        <icons.Plus />
                      </div>
                      Add New
                    </Button>
                  </div>
                  <div className="app-l-cmp-items">
                    <h6>Disabled</h6>
                    <Button
                      className="app-c-btn app-c-btn--primary min-btn-width"
                      disabled
                    >
                      Save
                    </Button>
                  </div>
                </div>
                <div className="app-l-cmp-row">
                  <div className="app-l-cmp-items">
                    <h6>Secondary Button</h6>
                    <Button className="app-c-btn app-c-btn--secondary  min-btn-width">
                      Cancel
                    </Button>
                  </div>
                  <div className="app-l-cmp-items">
                    <h6>Secondary Button with icon left side</h6>
                    <Button className="app-c-btn app-c-btn--secondary min-btn-width">
                      <div className="m-c-icon-inside-btn u-pr-lg">
                        <icons.AngleLeft />
                      </div>
                      Back
                    </Button>
                  </div>
                  <div className="app-l-cmp-items">
                    <h6>Secondary Button with icon right side</h6>
                    <Button className="app-c-btn app-c-btn--secondary">
                      Export
                      <div className="m-c-icon-inside-btn u-pl-lg">
                        <icons.GridExport />
                      </div>
                    </Button>
                  </div>
                  <div className="app-l-cmp-items">
                    <h6>Disabled</h6>
                    <Button
                      className="app-c-btn app-c-btn--secondary  min-btn-width"
                      disabled
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {/*************  --------------------BreadCrumbs-------------------- ************/}
            <div className="app-l-cmp-wrapper">
              <h3>BreadCrumbs</h3>
              <hr />
              <div className="app-l-cmp-data-container">
                <div className="app-l-cmp-row">
                  <div className="app-l-cmp-items">
                    <ul aria-label="breadcrumb" className="m-c-bredcrumb">
                      <li>
                        <a>Home</a>
                      </li>
                      <li>
                        <a className="active">Comapanies</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/*************  --------------------Checkbox-------------------- ************/}
            <div className="app-l-cmp-wrapper">
              <h3>Checkbox</h3>
              <hr />
              <div className="app-l-cmp-data-container">
                <div className="app-l-cmp-row">
                  <div className="app-l-cmp-items">
                    <h6>Gray Checkbox</h6>
                    <div className="m-c-checkbox">
                      {' '}
                      <CheckBox />
                    </div>
                  </div>
                  <div className="app-l-cmp-items">
                    <h6>Dark Checkbox</h6>
                    <div className="m-c-checkbox m-c-checkbox-dark">
                      {' '}
                      <CheckBox />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*************  --------------------Toggle switch-------------------- ************/}
            <div className="app-l-cmp-wrapper">
              <h3>Toggle switch</h3>
              <hr />
              <div className="app-l-cmp-data-container">
                <div className="app-l-cmp-row">
                  <div className="app-l-cmp-items">
                    <h6>Toggle switch active</h6>
                    <div className="m-l-toggle-switch">
                      <Switch defaultValue={true} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*************  --------------------Form Elements-------------------- ************/}
            <div className="app-l-cmp-wrapper form-elements">
              <h3>Form Elements</h3>
              <hr />
              <div className="app-l-cmp-data-container">
                <div className="app-l-cmp-row">
                  <div className="app-l-cmp-items">
                    <h6>Text Inputs</h6>
                    <div className="mb-3 m-c-form-group">
                      <label className="m-l-input-label">Company Name</label>
                      <TextBox className="m-c-input-control" placeholder="" />
                    </div>
                  </div>
                  <div className="app-l-cmp-items">
                    <h6>Text Inputs disabled</h6>
                    <div className="mb-3 m-c-form-group">
                      <label className="m-l-input-label">Company Name</label>
                      <TextBox
                        className="m-c-input-control"
                        placeholder=""
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="app-l-cmp-row">
                  <div className="app-l-cmp-items">
                    <h6>Select Input</h6>
                    <div className="m-c-form-group">
                      <label className="m-l-input-label">Name</label>
                      <SelectBox
                        className="m-c-input-control m-c-select-box"
                        dataSource={data}
                        valueExpr="ID"
                        displayExpr="Name"
                      ></SelectBox>
                    </div>
                  </div>
                  <div className="app-l-cmp-items">
                    <h6>Select Input Disabled</h6>
                    <div className="m-c-form-group">
                      <label className="m-l-input-label">Name</label>
                      <SelectBox
                        className="m-c-input-control m-c-select-box"
                        dataSource={data}
                        valueExpr="ID"
                        displayExpr="Name"
                        disabled
                      ></SelectBox>
                    </div>
                  </div>
                </div>
                <div className="app-l-cmp-row">
                  <div className="app-l-cmp-items">
                    <h6>Date Picker</h6>
                    <div className="m-c-form-group">
                      <label className="m-l-input-label">Name</label>
                      <DateBox
                        className="m-c-input-control m-c-date-picker"
                        type="date"
                        placeholder="DD/MM/YYYY"
                      />
                    </div>
                  </div>
                  <div className="app-l-cmp-items">
                    <h6>Date Picker Disabled</h6>
                    <div className="m-c-form-group">
                      <label className="m-l-input-label">Name</label>
                      <DateBox
                        className="m-c-input-control m-c-date-picker"
                        type="date"
                        placeholder="DD/MM/YYYY"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="app-l-cmp-row">
                  <div className="app-l-cmp-items">
                    <h6>Mutiselection Dropdown</h6>
                    <div className="m-c-form-group">
                      <label className="m-l-input-label">Terminals</label>
                      <TagBox
                        className="m-c-input-control m-c-multiselect-dropdown"
                        items={simpleProducts}
                        showSelectionControls={true}
                        multiline={false}
                        searchEnabled={true}
                        acceptCustomValue={true}
                      />
                    </div>
                  </div>
                  <div className="app-l-cmp-items">
                    <h6>Mutiselection Dropdown Disabled</h6>
                    <div className="m-c-form-group">
                      <label className="m-l-input-label">Name</label>
                      <TagBox
                        className="m-c-input-control m-c-multiselect-dropdown"
                        items={simpleProducts}
                        showSelectionControls={true}
                        multiline={false}
                        searchEnabled={true}
                        acceptCustomValue={true}
                        value={disabledValue}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="app-l-cmp-row">
                  <div className="app-l-cmp-items-nw">
                    <h6>Custom Dropdown</h6>
                    <form>
                      <div className="row">
                        <FormBuilder formConfig={[...personForm1]} />
                      </div>
                      <Button
                        className="app-c-btn app-c-btn--primary min-btn-width"
                        onClick={handleSaveClick}
                      >
                        Save
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/*************  ---------------------Headings------------------- ************/}
            <div className="app-l-cmp-wrapper">
              <h3>Headings</h3>
              <hr />
              <div className="app-l-cmp-data-container">
                <div className="app-l-cmp-row">
                  <div className="app-l-cmp-items">
                    <h6>Primary Heading</h6>
                    <div className="m-l-page-main-heading">ABC Cargo</div>
                  </div>
                  <div className="app-l-cmp-items">
                    <h6>Sub Heading</h6>
                    <div className="m-l-page-sub-heading">ABC Cargo</div>
                  </div>
                </div>
              </div>
            </div>
            {/*************  --------------------Icons-------------------- ************/}
            <div className="app-l-cmp-wrapper">
              <h3>Icons</h3>
              <hr />
              <div className="app-l-cmp-data-container">
                <div className="app-l-cmp-row">
                  {/* <div className="app-l-cmp-icons">
                    <icons.MarsLogo />
                  </div> */}
                  <div className="app-l-cmp-icons">
                    <icons.Search />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.Bell />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.Slider />
                  </div>
                  {/* <div className="app-l-cmp-icons">
                    <icons.Plus />
                  </div> */}
                  <div className="app-l-cmp-icons">
                    <icons.GridExport />
                  </div>

                  <div className="app-l-cmp-icons">
                    <icons.AngleLeft />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.NoListIcon />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.Confirmation />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.Calendar />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.ChevronDown />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.Trash />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.Image />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.Attach />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.Square />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.Star />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.BookMeck />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.Mail />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.Send />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.CircleTick />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.CircleCross />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.PlusIcon />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.MinusIcon />
                  </div>
                  <div className="app-l-cmp-icons">
                    <icons.ChevronUp />
                  </div>
                </div>
              </div>
            </div>
            {/*************  ---------------------Color Palette------------------- ************/}
            <div className="app-l-cmp-wrapper">
              <h3>Color Palette</h3>
              <hr />
              <div className="app-l-cmp-data-container">
                <div className="app-l-cmp-row">
                  <div className="app-l-cmp-items">
                    <h6>Primary Color</h6>
                    <div className="app-l-color-pallete primary-color">
                      #174a84
                    </div>
                  </div>
                  <div className="app-l-cmp-items">
                    <h6>Secondary Color</h6>
                    <div className="app-l-color-pallete secondary-color">
                      #585c64
                    </div>
                  </div>
                  <div className="app-l-cmp-items">
                    <h6>Primary Blue Color</h6>
                    <div className="app-l-color-pallete primaryblue-color">
                      #2f80ed
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*************  ---------------------Tab------------------- ************/}
            <div className="app-l-cmp-wrapper">
              <h3>Tab</h3>
              <hr />
              <div className="app-l-cmp-data-container">
                <div className="app-l-cmp-row">
                  <div className="app-l-cmp-items">
                    <div className="m-l-inner-page-body">
                      <TabPanel
                        height={'200px'}
                        items={items}
                        className="m-c-tab-block"
                      ></TabPanel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*************  ---------------------Grid------------------- ************/}
            <div className="app-l-cmp-wrapper">
              <h3>Grid</h3>
              <hr />
              <div className="app-l-cmp-data-container">
                <div className="app-l-cmp-row">
                  <div className="app-l-cmp-items">
                    <div className="m-c-grid">
                      <Grid gridConfig={gridConfig} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*************  ---------------------Modal------------------- ************/}
            <div className="app-l-cmp-wrapper">
              <h3>Modal</h3>
              <hr />
              <div className="app-l-cmp-data-container">
                <div className="app-l-cmp-row">
                  <div className="app-l-cmp-items">
                    <div>
                      <Button
                        className="app-c-btn app-c-btn--secondary"
                        text="open confirm popup"
                        onClick={handleDialogOpen}
                      ></Button>
                      <ConfirmDialog
                        dialogConfig={getDialogConfig()}
                        isOpen={isOpen}
                      />
                    </div>
                  </div>
                  <div className="app-l-cmp-items">
                    <div>
                      <Button
                        className="app-c-btn app-c-btn--secondary"
                        text="Add New Incident"
                        onClick={clickButton}
                      ></Button>
                    </div>
                  </div>
                  <div className="app-l-cmp-items">
                    <div>
                      <Button
                        className="app-c-btn app-c-btn--secondary"
                        text="PSC Inspection"
                        onClick={clickButtonPsc}
                      ></Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Modal for add new incident */}
            <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
              <div className="m-l-modal__header">
                <h2 className="modal-title">Add New Incident</h2>
                <div className="m-l-modal-close-button-block">
                  <Button
                    className="app-c-btn app-c-icon-only-btn"
                    onClick={handleCancelClick}
                  >
                    <icons.IconCross />
                  </Button>
                </div>
              </div>
              <div className="m-l-modal__body">
                <ScrollView width="100%" height="100%">
                  <div className="m-l-adnw-icdnt-form-panel">
                    <div className="row m-l-custom-sixteen-space-row">
                      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-34">
                          <label className="m-l-input-label">
                            Date Of Incident
                          </label>
                          <DateBox
                            className="m-c-input-control m-c-date-picker"
                            type="date"
                            placeholder="DD/MM/YYYY"
                          />
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-34">
                          <label className="m-l-input-label">Port</label>
                          <TextBox
                            className="m-c-input-control"
                            placeholder="Enter Port"
                          ></TextBox>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-34">
                          <label className="m-l-input-label">Environment</label>
                          <TextBox
                            className="m-c-input-control"
                            placeholder="Enter Environment"
                          ></TextBox>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-34">
                          <label className="m-l-input-label">Marsen Grid</label>
                          <TextBox
                            className="m-c-input-control"
                            placeholder="Enter Marsen Grid"
                          ></TextBox>
                        </div>
                      </div>

                      {/* </div> */}
                      {/* <div className="row m-l-custom-sixteen-space-row"> */}
                      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-34">
                          <label className="m-l-input-label">
                            Casuality zone
                          </label>
                          <TextBox
                            className="m-c-input-control"
                            placeholder="Enter Casuality Zone"
                          ></TextBox>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-34">
                          <label className="m-l-input-label">
                            Location Name
                          </label>
                          <TextBox
                            className="m-c-input-control"
                            placeholder="Enter Location Name"
                          ></TextBox>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-34">
                          <label className="m-l-input-label">Event Type</label>
                          <SelectBox
                            dataSource={['Event', 'Event1']}
                            className="m-c-input-control m-c-select-box"
                            placeholder="Select"
                          ></SelectBox>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-34">
                          <label className="m-l-input-label">Load Status</label>
                          <TextBox
                            className="m-c-input-control"
                            placeholder="Enter Load Status"
                          ></TextBox>
                        </div>
                      </div>

                      {/* </div> */}
                      {/* <div className="row m-l-custom-sixteen-space-row"> */}
                      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-34">
                          <label className="m-l-input-label">
                            Event Type Details
                          </label>
                          <TextBox
                            className="m-c-input-control"
                            placeholder="Enter Event Type Details"
                          ></TextBox>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-34">
                          <label className="m-l-input-label">
                            Significance
                          </label>
                          <TextBox
                            className="m-c-input-control"
                            placeholder="Enter Significance"
                          ></TextBox>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-34">
                          <label className="m-l-input-label">Total Loss</label>
                          <SelectBox
                            dataSource={['Event', 'Event1']}
                            className="m-c-input-control m-c-select-box"
                            placeholder="Select"
                          ></SelectBox>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-34">
                          <label className="m-l-input-label">Pollution</label>
                          <SelectBox
                            dataSource={['Event', 'Event1']}
                            className="m-c-input-control m-c-select-box"
                            placeholder="Select"
                          ></SelectBox>
                        </div>
                      </div>
                      {/*          
              </div> */}
                      {/* <div className="row m-l-custom-sixteen-space-row"> */}
                      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-24">
                          <label className="m-l-input-label">Fire</label>
                          <SelectBox
                            dataSource={['Event', 'Event1']}
                            className="m-c-input-control m-c-select-box"
                            placeholder="Select"
                          ></SelectBox>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-24">
                          <label className="m-l-input-label">
                            Significant Damage
                          </label>
                          <SelectBox
                            dataSource={['Event', 'Event1']}
                            className="m-c-input-control m-c-select-box"
                            placeholder="Select"
                          ></SelectBox>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-24">
                          <label className="m-l-input-label">
                            Additional Event
                          </label>
                          <SelectBox
                            dataSource={['Event', 'Event1']}
                            className="m-c-input-control m-c-select-box"
                            placeholder="Select"
                          ></SelectBox>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-24">
                          <label className="m-l-input-label">
                            Additional Event
                          </label>
                          <SelectBox
                            dataSource={['Event', 'Event1']}
                            className="m-c-input-control m-c-select-box"
                            placeholder="Select"
                          ></SelectBox>
                        </div>
                      </div>

                      {/* </div> */}

                      {/* <div className="row m-l-custom-sixteen-space-row"> */}
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-24 pt-12">
                          <label className="m-l-input-label">Injury</label>
                          <div className="m-c-radio-button m-c-custom-space-radiobtn">
                            <RadioGroup items={items1} layout="horizontal" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-24">
                          <label className="m-l-input-label">Injury type</label>
                          <SelectBox
                            dataSource={['Event', 'Event1']}
                            className="m-c-input-control m-c-select-box"
                            placeholder="Select"
                          ></SelectBox>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-2 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-24">
                          <div className="m-l-label-data-wrapper">
                            <label className="m-l-input-label">Source</label>
                            <span className="m-l-data-label ml-16">Shell</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="m-l-desc-section">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="m-c-form-group mb-btm-space-24">
                            <label className="m-l-input-label">Headline</label>
                            <TextBox
                              className="m-c-input-control"
                              placeholder="Enter Headline"
                            ></TextBox>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="m-c-form-group mb-btm-space-24">
                            <label className="m-l-input-label">
                              Description
                            </label>
                            <TextBox
                              className="m-c-input-control"
                              placeholder="Enter Description"
                            ></TextBox>
                          </div>
                        </div>
                      </div>
                      <div className="row m-l-comment-row">
                        <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-xs-12">
                          <div className="m-c-form-group mb-btm-space-24">
                            <label className="m-l-input-label">Comments</label>
                            <TextArea
                              className="m-c-text-area m-c-text-area-custom"
                              placeholder="Enter Comments"
                            />
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                          <div className="m-c-form-group mb-btm-space-24">
                            <label className="m-l-input-label">
                              Root Cause
                            </label>
                            <SelectBox
                              dataSource={['Event', 'Event1']}
                              className="m-c-input-control m-c-select-box"
                              placeholder="Choose"
                            ></SelectBox>
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12">
                          <div className="m-c-form-group mb-btm-space-24">
                            <label className="m-l-input-label">Actions</label>
                            <SelectBox
                              dataSource={['Event', 'Event1']}
                              className="m-c-input-control m-c-select-box"
                              placeholder="Choose"
                            ></SelectBox>
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12">
                          <div className="m-c-form-group mb-btm-space-24">
                            <label className="m-l-input-label">Status</label>
                            <SelectBox
                              dataSource={['Event', 'Event1']}
                              className="m-c-input-control m-c-select-box"
                              placeholder="Select"
                            ></SelectBox>
                          </div>
                        </div>
                      </div>
                      {/* <div className="m-l-modal-form-footer">
                        <div className="m-l-group-btn-wrap">
                          <Button className="app-c-btn app-c-btn--secondary min-btn-width">
                            Accept
                          </Button>
                          <Button className="app-c-btn app-c-btn--secondary min-btn-width">
                            Cancel
                          </Button>
                          <Button
                            className="app-c-btn app-c-btn--primary min-btn-width"
                            onClick={handleSaveClick1}
                          >
                            Save
                          </Button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </ScrollView>
              </div>
              <div className="m-l-modal__footer">
                <div className="m-l-group-btn-wrap">
                  <Button className="app-c-btn app-c-btn--secondary">
                    Accept
                  </Button>
                  <Button className="app-c-btn app-c-btn--secondary">
                    Cancel
                  </Button>
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    onClick={handleSaveClick1}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Modal>
            {/* Modal for PSC Inspection */}
            <Modal
              modalConfig={modalConfigPsc}
              wrapperAttr={popupAttributesPsc}
            >
              <div className="m-l-modal__header">
                <h2 className="modal-title">PSC Inspection</h2>
                <div className="m-l-modal-close-button-block">
                  <Button
                    className="app-c-btn app-c-icon-only-btn"
                    onClick={handleCancelClickPsc}
                  >
                    <icons.IconCross />
                  </Button>
                </div>
              </div>
              <div className="m-l-modal__body">
                <ScrollView width="100%" height="100%">
                  <div className="m-l-psc-form-panel">
                    <div className="row m-l-custom-sixteen-space-row">
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-20">
                          <label className="m-l-input-label">
                            Date of Inspection
                          </label>
                          <DateBox
                            className="m-c-input-control m-c-date-picker"
                            type="date"
                            placeholder="DD/MM/YYYY"
                          />
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-20">
                          <label className="m-l-input-label">Port</label>
                          <TextBox
                            className="m-c-input-control"
                            placeholder="Enter Port"
                          ></TextBox>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-20">
                          <label className="m-l-input-label">Authority</label>
                          <TextBox
                            className="m-c-input-control"
                            placeholder="Enter Authority"
                          ></TextBox>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-20">
                          <label className="m-l-input-label">
                            Type of Inspection
                          </label>
                          <TextBox
                            className="m-c-input-control"
                            placeholder="Enter Inspection Type"
                          ></TextBox>
                        </div>
                      </div>
                    </div>

                    <div className="row m-l-custom-sixteen-space-row">
                      <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-20 tp-space-det">
                          <label className="m-l-input-label">Detention</label>
                          <div className="m-c-radio-button m-c-custom-space-radiobtn">
                            <RadioGroup items={items1} layout="horizontal" />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-20">
                          <label className="m-l-input-label">Duration</label>
                          <TextBox
                            className="m-c-input-control"
                            placeholder=""
                          ></TextBox>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-20">
                          <label className="m-l-input-label">
                            No of Deficiencies
                          </label>
                          <TextBox
                            className="m-c-input-control"
                            placeholder=""
                          ></TextBox>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-12 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-24">
                          <label className="m-l-input-label">Source</label>
                          <TextArea
                            className="m-c-text-area m-c-text-area-custom"
                            placeholder="Comment"
                          />
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-24 tp-space-status">
                          <label className="m-l-input-label m-l-status-label">
                            Status
                          </label>
                          <SelectBox
                            dataSource={['Closed', 'Open', 'Awaiting Info']}
                            className="m-c-input-control m-c-select-box m-c-st-box"
                            fieldRender={(data) => {
                              return (
                                <TextBox
                                  className="active-lbl"
                                  defaultValue={data}
                                  readOnly={true}
                                />
                              );
                            }}
                            itemRender={(data) => {
                              return (
                                <div
                                  className={
                                    data === 'Open'
                                      ? 'm-l-status-item status-bg-red'
                                      : 'm-l-status-item status-bg-green'
                                  }
                                >
                                  {data}
                                </div>
                              );
                            }}
                          />
                          {/* <div className="m-l-status-block-dropdown m-l-st-blck-insde-modal">
                            <Button
                              className="m-l-status-btn m-l-status-btn-with-arrow status-bg-red"
                              onClick={showStatus}
                            >
                              Open
                            </Button>
                          
                            <div
                              className={
                                'm-l-status-dropdown-box ' +
                                (isStatusblock ? '' : 'u-display-none')
                              }
                            >
                              <ul className="m-l-status-items">
                                <li className="m-l-status-item status-bg-green">
                                  Approved
                                </li>
                                <li className="m-l-status-item status-bg-red">
                                  Rejected
                                </li>
                                <li className="m-l-status-item status-bg-green">
                                  Conditionaly Approved
                                </li>
                              </ul>
                            </div>
                          </div> */}
                        </div>
                      </div>

                      <div className="col-xl-1 col-lg-1 col-md-3 col-sm-4 col-6 m-l-custom-sixteen-space-col">
                        <div className="m-c-form-group mb-btm-space-24 tp-space-source">
                          <label className="m-l-input-label">Source</label>
                          <div className="m-l-data-label">Shell</div>
                        </div>
                      </div>
                    </div>

                    <div className="m-l-data-entry-wrapper">
                      <div className="row m-l-custom-sixteen-space-row">
                        <div className="col-xl col-lg-3 col-md-3 col-sm-3 col-12 m-l-custom-sixteen-space-col">
                          <div className="mb-btm-space-30 m-c-form-group">
                            <label className="m-l-input-label">
                              Defect Code
                            </label>
                            <SelectBox
                              dataSource={['Tankers', 'Container']}
                              className="m-c-input-control m-c-select-box"
                              placeholder="Enter defect code"
                            ></SelectBox>
                          </div>
                        </div>
                        <div className="col-xl col-lg-3 col-md-3 col-sm-3 col-12 m-l-custom-sixteen-space-col">
                          <div className="mb-btm-space-30 m-c-form-group">
                            <label className="m-l-input-label">
                              Defect Description
                            </label>
                            <TextBox
                              className="m-c-input-control"
                              placeholder="Defect description"
                              value=""
                            ></TextBox>
                          </div>
                        </div>
                        <div className="col-xl col-lg-3 col-md-3 col-sm-3 col-12 m-l-custom-sixteen-space-col">
                          <div className="mb-btm-space-30 m-c-form-group">
                            <label className="m-l-input-label">Risk Area</label>
                            <SelectBox
                              dataSource={['Tankers', 'Container']}
                              className="m-c-input-control m-c-select-box"
                              placeholder="Enter area of risk "
                            ></SelectBox>
                          </div>
                        </div>
                        <div className="col-xl col-lg-3 col-md-3 col-sm-3 col-12 m-l-custom-sixteen-space-col">
                          <div className="mb-btm-space-30 m-c-form-group">
                            <label className="m-l-input-label">Sub Area</label>
                            <SelectBox
                              dataSource={['Tankers', 'Container']}
                              placeholder="Enter sub area"
                              className="m-c-input-control m-c-select-box"
                            ></SelectBox>
                          </div>
                        </div>
                        <div className="col-xl col-lg-3 col-md-3 col-sm-3 col-12 m-l-custom-sixteen-space-col">
                          <div className="mb-btm-space-30 m-c-form-group">
                            <label className="m-l-input-label">
                              Root Cause
                            </label>
                            <TextBox
                              className="m-c-input-control"
                              placeholder="Enter root cause "
                              value=""
                            ></TextBox>
                          </div>
                        </div>
                        <div className="col-xl col-lg-3 col-md-3 col-sm-3 col-12 m-l-custom-sixteen-space-col">
                          <div className="mb-btm-space-30 m-c-form-group">
                            <label className="m-l-input-label">Actions</label>
                            <TextBox
                              className="m-c-input-control"
                              placeholder="Enter action "
                              value=""
                            ></TextBox>
                          </div>
                        </div>
                        <div className="col-xl-auto col-lg-auto col-md-auto col-sm-auto col-12 m-l-custom-sixteen-space-col">
                          <div className="mb-btm-space-30 m-c-form-group m-l-button-vertical-center-align m-l-fm-icon-btn">
                            <Button className="app-c-btn app-c-btn--remove">
                              <div className="m-c-icon-inside-btn">
                                <icons.RemoveIcon />
                              </div>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <hr className="m-l-horizontal-line" />
                      <div className="row m-l-custom-sixteen-space-row">
                        <div className="col-xl col-lg-3 col-md-3 col-sm-3 col-12 m-l-custom-sixteen-space-col">
                          <div className="mb-btm-space-30 m-c-form-group">
                            {/* <label className="m-l-input-label">Defect Code</label> */}
                            <SelectBox
                              dataSource={['Tankers', 'Container']}
                              className="m-c-input-control m-c-select-box"
                              placeholder="Enter defect code"
                            ></SelectBox>
                          </div>
                        </div>
                        <div className="col-xl col-lg-3 col-md-3 col-sm-3 col-12 m-l-custom-sixteen-space-col">
                          <div className="mb-btm-space-30 m-c-form-group">
                            {/* <label className="m-l-input-label">Defect Description</label> */}
                            <TextBox
                              className="m-c-input-control"
                              placeholder="Defect description"
                              value=""
                            ></TextBox>
                          </div>
                        </div>
                        <div className="col-xl col-lg-3 col-md-3 col-sm-3 col-12 m-l-custom-sixteen-space-col">
                          <div className="mb-btm-space-30 m-c-form-group">
                            {/* <label className="m-l-input-label">Risk Area</label> */}
                            <SelectBox
                              dataSource={['Tankers', 'Container']}
                              className="m-c-input-control m-c-select-box"
                              placeholder="Enter area of risk "
                            ></SelectBox>
                          </div>
                        </div>
                        <div className="col-xl col-lg-3 col-md-3 col-sm-3 col-12 m-l-custom-sixteen-space-col">
                          <div className="mb-btm-space-30 m-c-form-group">
                            {/* <label className="m-l-input-label">Sub Area</label> */}
                            <SelectBox
                              dataSource={['Tankers', 'Container']}
                              placeholder="Enter sub area"
                              className="m-c-input-control m-c-select-box"
                            ></SelectBox>
                          </div>
                        </div>
                        <div className="col-xl col-lg-3 col-md-3 col-sm-3 col-12 m-l-custom-sixteen-space-col">
                          <div className="mb-btm-space-30 m-c-form-group">
                            {/* <label className="m-l-input-label">Root Cause</label> */}
                            <TextBox
                              className="m-c-input-control"
                              placeholder="Enter root cause "
                              value=""
                            ></TextBox>
                          </div>
                        </div>
                        <div className="col-xl col-lg-3 col-md-3 col-sm-3 col-12 m-l-custom-sixteen-space-col">
                          <div className="mb-btm-space-30 m-c-form-group">
                            {/* <label className="m-l-input-label">Actions</label> */}
                            <TextBox
                              className="m-c-input-control"
                              placeholder="Enter action "
                              value=""
                            ></TextBox>
                          </div>
                        </div>
                        <div className="col-xl-auto col-lg-auto col-md-auto col-sm-auto col-12 m-l-custom-sixteen-space-col">
                          <div className="mb-btm-space-30 m-c-form-group ">
                            <Button className="app-c-btn app-c-btn--secondary min-btn-width-sm-pls">
                              <div className="m-c-icon-inside-btn">
                                <icons.AddIcon />
                              </div>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ScrollView>
              </div>
              <div className="m-l-modal__footer">
                <Button className="app-c-btn app-c-btn--secondary min-btn-width">
                  Accept
                </Button>
                <Button className="app-c-btn app-c-btn--secondary min-btn-width">
                  Cancel
                </Button>
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width"
                  onClick={handleSaveClickPsc}
                >
                  Save
                </Button>
              </div>
            </Modal>
            {/*************  ---------------------Toast------------------- ************/}
            <div className="app-l-cmp-wrapper">
              <h3>Toast</h3>
              <hr />
              <div className="app-l-cmp-data-container">
                <div className="app-l-cmp-row">
                  <div className="app-l-cmp-items">
                    <div>
                      <Button
                        className="app-c-btn app-c-btn--secondary"
                        onClick={() =>
                          toast.custom({ message: 'Custom message' })
                        }
                      >
                        Show Custom Toast
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*************  ---------------------Accordion------------------- ************/}
            <div className="app-l-cmp-wrapper">
              <h3>Accordion</h3>
              <hr />
              <div className="app-l-cmp-data-container">
                <div className="app-l-cmp-row">
                  <div className="app-l-cmp-items full-width">
                    <div className="m-c-accordion">
                      <Accordion>
                        <Item title="Vet Request Details">
                          <span>Network Administrator</span>
                        </Item>
                        <Item title="Basic Details">
                          <span>Technical Writer</span>
                        </Item>
                        <Item title="Hull Type">
                          <span>Technical Writer</span>
                        </Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function cellRender(data: any) {
  return <div>{data.value}</div>;
}
const renderItem = (data: any) => {
  return (
    <div>
      <div style={{ display: 'inline-block', textAlign: 'left' }}>
        {data?.callingCode} ({data?.value})
      </div>
    </div>
  );
};

const renderField = (data: any) => {
  return (
    <div>
      <TextBox
        style={{ display: 'inline-block' }}
        value={data?.callingCode}
        readOnly={false}
      />
    </div>
  );
};
