import React, { useEffect, useState } from 'react';
import { IGridConfig } from '../../../../../types/grid';
import Grid from '../../../../common/grid/Grid';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { useTranslation } from 'react-i18next';
import ManagementEditModal from '../edit-management/EditManagement';
import { getAPIRoute } from '../../../../../utils/api-route';

export default function History(props: {
  imoNumber?: string;
  historyDetails: any;
  getHistoryDetails: any;
}) {
  const { imoNumber, historyDetails, getHistoryDetails } = props;

  const { t } = useTranslation();

  const [managementRow, setManagementRow] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const [selectedModalId, setSelectedModalId] = useState(0);

  const onRowClick = (e: any) => {
    if (e.event.target.outerText.length > 1 && e.event.target.cellIndex != 0) {
      getManagementRow(
        e.columns[e.event.target.cellIndex].caption,
        e.data.date
      );
      setSelectedModalId(3);
      setIsEdit(true);
    }
  };

  const handleCancelClick = () => {
    setIsEdit(!isEdit);
  };

  const getManagementRow = async (roleName: any, date: any) => {
    await api
      .get({
        url: getAPIRoute('vesselManagementHistorySingle', [
          imoNumber,
          roleName,
          date,
        ]),
      })
      .then((res) => {
        setManagementRow(res);
      });
  };

  useEffect(() => {
    if (imoNumber) {
      getHistoryDetails();
    }
  }, [imoNumber]);

  const gridConfig: IGridConfig = {
    class: 'm-c-grid m-c-grid-in-tab m-c-auto-scroll-grid no-border-top',
    testId: 'managementRolesGrid',
    dataSource: historyDetails,
    defaultColumns: [
      {
        caption: t('labels.fromDate'),
        dataField: 'date',
        dataType: 'date',
        format: 'dd MMM yyyy',
        minWidth: 150,
      },
      {
        caption: t('labels.groupOwner'),
        dataField: 'groupOwner',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.shipManager'),
        dataField: 'shipManager',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.technicalManager'),
        dataField: 'technicalManager',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.docCompany'),
        dataField: 'docCompany',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.operator'),
        dataField: 'operator',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.registeredOwner'),
        dataField: 'registeredOwner',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.barboatOwner'),
        dataField: 'bareboatOwner',
        dataType: 'string',
        minWidth: 150,
      },
    ],
    showHeader: false,
    showExport: false,
    showAddButton: false,
    hideFilterRow: true,
    excelFileName: 'VesselManagementRoles.xlsx',
    rowClick: onRowClick,
  };

  return (
    <React.Fragment>
      <Grid gridConfig={gridConfig} />
      {isEdit ? (
        <ManagementEditModal
          imoNumber={imoNumber}
          isVisible={isEdit}
          modalId={selectedModalId}
          handleCancelClick={handleCancelClick}
          managementRow={managementRow}
          getVesselMAnagementRoles={getHistoryDetails}
        />
      ) : null}
    </React.Fragment>
  );
}
