import Button from 'devextreme-react/button';
import DataGrid from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { IDialogConfig } from '../../../../../types/dialog';
import { IGridConfig } from '../../../../../types/grid';
import { IOptionvalue } from '../../../../../types/master-data';
import { getCompanyId, getCompanyType } from '../../../../../utils/jwt-decode';
import toast from '../../../../../utils/toast';
import ConfirmDialog from '../../../../common/confirm/ConfirmDialog';
import Grid from '../../../../common/grid/Grid';
import icons from '../../../../common/icons/icons';
import EditIncident from './edit-incident/EditIncident';
import {
  Restricted,
  usePermission,
} from '../../../../../contexts/PermissionContext';
import { IncidentStatus } from '../../../../../enums/status-enum';
import { useLoaderContext } from '../../../../../contexts/LoaderContext';
import { CompanyType } from '../../../../../enums/company-type-enum';

export default function ViewIncidents(props: { imoNumber: any }) {
  const { imoNumber } = props;
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [incidents, setIncidents] = useState([]);
  const [eventId, setEventId] = useState<any>();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmAccept, setConfirmAccept] = useState(false);
  const [confirmIgnore, setConfirmIgnore] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState<any>();
  const [isMarsUser, setIsMarsUser] = useState<boolean>();
  const [incidentState, setIncidentState] = useState<IOptionvalue[]>([]);
  const companyId = getCompanyId();
  const companyType = getCompanyType();
  const { findFeature } = usePermission();
  const { setIsLoading } = useLoaderContext();
  const IsCompanyTypeMars = () => {
    if (companyType === 'M') {
      setIsMarsUser(true);
    } else {
      setIsMarsUser(false);
    }
  };

  const getIncidentsList = async () => {
    await api
      .get({
        url: apiConfig.incidents + imoNumber,
      })
      .then((data) => {
        setIncidents(data);
      });
  };

  useEffect(() => {
    getIncidentsList();
    IsCompanyTypeMars();
  }, []);

  useEffect(() => {
    if (!isVisible) {
      setEventId('');
    }
  }, [isVisible]);

  const onRowClick = (e: any) => {
    if (!findFeature('Ship.ViewIncidentDetail')) {
      return;
    } else if (
      ![
        'app-c-btn app-c-btn--secondary',
        'm-l-grid-delete-icon-wrapper',
      ].includes(e.event.target.className)
    ) {
      setEventId(e.data.eventId);
      setIsVisible(true);
    }
  };

  const onClickDelete = (id: any) => {
    setConfirmDelete(true);
    setSelectedEventId(id);
  };

  const confirmDeleteDialogConfig: IDialogConfig = {
    id: 'confirmDeleteIncident',
    content: t('labels.deleteIncident'),
    handleSubmit: () => {
      setConfirmDelete(false);
      deleteIncident(selectedEventId);
    },
    handleClose: () => {
      setConfirmDelete(false);
      setSelectedEventId('');
    },
  };

  const deleteIncident = async (id: any) => {
    await api
      .delete({ url: apiConfig.incidentById + id }, setIsLoading)
      .then(() => {
        toast.custom({
          title: t('toast.deleteSuccessful'),
        });
        getIncidentsList();
        setSelectedEventId('');
      });
  };

  const onClickAccept = (id: any) => {
    setConfirmAccept(true);
    setSelectedEventId(id);
  };

  const onClickIgnore = (id: any) => {
    setConfirmIgnore(true);
    setSelectedEventId(id);
  };

  const confirmAcceptDialogConfig: IDialogConfig = {
    id: 'confirmAcceptIncident',
    content: t('labels.acceptIncident'),
    handleSubmit: () => {
      acceptIncident(selectedEventId);
      setConfirmAccept(false);
    },
    handleClose: () => {
      setConfirmAccept(false);
      setSelectedEventId('');
    },
  };

  const confirmIgnoreDialogConfig: IDialogConfig = {
    id: 'confirmIgnoreIncident',
    content: t('labels.ignoreIncident'),
    handleSubmit: () => {
      ignoreIncident(selectedEventId);
      setConfirmIgnore(false);
    },
    handleClose: () => {
      setConfirmIgnore(false);
      setSelectedEventId('');
    },
  };

  const acceptIncident = async (id: any) => {
    await api
      .put(
        { url: apiConfig.incidentById + id, params: { IsIgnored: false } },
        setIsLoading
      )
      .then(() => {
        toast.custom({
          title: t('toast.incidentAcceptSuccess'),
        });
        getIncidentsList();
        setSelectedEventId('');
      });
  };

  const ignoreIncident = async (id: any) => {
    await api
      .put(
        { url: apiConfig.incidentById + id, params: { IsIgnored: true } },
        setIsLoading
      )
      .then(() => {
        toast.custom({
          title: t('toast.incidentIgnoreSuccess'),
        });
        getIncidentsList();
        setSelectedEventId('');
      });
  };

  const incidentStatus = {
    store: new CustomStore({
      key: 'optionValueCode',
      loadMode: 'raw',
      load: () => {
        return api.get({ url: apiConfig.incidentStatus });
      },
    }),
  };

  const stateOfIncidentVal: any = {
    store: new CustomStore({
      key: 'optionValueCode',
      loadMode: 'raw',
      load: () => {
        return api.get({ url: apiConfig.stateOfIncident }).then((res) => {
          setIncidentState(res);
          return res;
        });
      },
    }),
  };

  const cellPrepared = (e: any) => {
    if (e.rowType === 'data' && e.column.dataField === 'incidentStatus') {
      e.cellElement.className =
        e.data.incidentStatus === IncidentStatus.Closed
          ? 'status-active-wtout'
          : e.data.incidentStatus === IncidentStatus.AwaitingInfo
          ? 'status-pending-wtout'
          : e.data.incidentStatus === IncidentStatus.WrongEntry
          ? 'status-wrong-entry-wtout'
          : 'status-inactive-wtout';
    }
    if (e.rowType === 'data' && e.column.dataField === 'marsStatus') {
      e.cellElement.className =
        e.data.marsStatus === IncidentStatus.Closed
          ? 'status-active-wtout'
          : e.data.marsStatus === IncidentStatus.AwaitingInfo
          ? 'status-pending-wtout'
          : e.data.marsStatus === IncidentStatus.WrongEntry
          ? 'status-wrong-entry-wtout'
          : 'status-inactive-wtout';
    }
  };

  const showAdditionalFields = useMemo(() => {
    if (companyType === CompanyType.RequestorCompany) {
      return findFeature('Ship.ViewIncidentAdditionalDetails');
    } else {
      if (findFeature('Ship.EditIncident') || findFeature('Ship.AddIncident')) {
        return true;
      } else {
        return findFeature('Ship.ViewIncidentAdditionalDetails');
      }
    }
  }, [companyType]);

  useEffect(() => {
    if (isMarsUser) {
      gridRef.current?.instance.columnOption(5, 'visible', true);
      gridRef.current?.instance.columnOption(7, 'visible', false);
    } else {
      gridRef.current?.instance.columnOption(
        7,
        'visible',
        showAdditionalFields
      );
      gridRef.current?.instance.columnOption(5, 'visible', false);
    }
  }, [isMarsUser]);

  const gridRef = useRef<DataGrid>(null);

  const gridConfig: IGridConfig = {
    class: 'm-c-grid m-l-grid-with--top-border',
    testId: 'incidentsViewGrid',
    dataSource: incidents,
    ref: gridRef,
    defaultColumns: [
      {
        caption: t('labels.eventType'),
        dataField: 'eventType',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.additionalEventType'),
        dataField: 'additionalEventType',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.significance'),
        dataField: 'significance',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.placeOfIncident'),
        dataField: 'placeOfIncident',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.incidentDate'),
        dataField: 'incidentDate',
        dataType: 'date',
        minWidth: 150,
        format: 'dd MMM yyyy',
        sortOrder: 'des',
      },
      {
        caption: t('labels.source'),
        dataField: 'source',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.status'),
        dataField: 'incidentStatus',
        dataType: 'string',
        minWidth: 150,
        lookup: {
          dataSource: incidentStatus,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
        visible:
          companyType === CompanyType.RequestorCompany
            ? false
            : showAdditionalFields,
      },
      {
        caption: t('labels.MARSStatus'),
        dataField: 'marsStatus',
        dataType: 'string',
        minWidth: 150,
        lookup: {
          dataSource: incidentStatus,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
      },
      {
        caption: t('labels.action'),
        dataField: 'stateOfIncident',
        dataType: 'string',
        width: 200,
        cellTemplate: 'actionTemplate',
        lookup: {
          dataSource: stateOfIncidentVal,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
      },
    ],
    showHeader: true,
    showAddButton: !(companyType === 'R') && findFeature('Ship.AddIncident'),
    noDataText: t('labels.companyVesselNoIncident'),
    showExport: true,
    hidePagination: false,
    rowClick: onRowClick,
    onClickAddButton: () => {
      setIsVisible(true);
    },
    cellPrepared: cellPrepared,
  };

  const getStateOfIncidentText = (stateOfIncident: any) => {
    const item = incidentState?.find(
      (item: any) => item.optionValueCode == stateOfIncident
    );
    return item?.optionValueText;
  };

  const actionTemplate = (data: any) => {
    return (
      <div className="m-l-icon-btn-holder">
        {isMarsUser &&
          (data.data.stateOfIncident ? (
            <div className="m-l-field-labels">
              {getStateOfIncidentText(data.data.stateOfIncident)}
            </div>
          ) : (
            !data.data.isMarsOrExternal && (
              <>
                <Restricted permission="Ship.AcceptIncident">
                  <Button
                    className="app-c-btn app-c-btn--secondary"
                    onClick={() => onClickAccept(data.data.eventId)}
                  >
                    {t('buttons.accept')}
                  </Button>
                </Restricted>
                <Restricted permission="Ship.IgnoreIncident">
                  <Button
                    className="app-c-btn app-c-btn--secondary"
                    onClick={() => onClickIgnore(data.data.eventId)}
                  >
                    {t('buttons.ignore')}
                  </Button>
                </Restricted>
              </>
            )
          ))}
        {isMarsUser
          ? data.data.isMarsOrExternal && (
              <Restricted permission="Ship.DeleteIncident">
                <div
                  className="m-l-grid-delete-icon-wrapper"
                  onClick={() => onClickDelete(data.data.eventId)}
                >
                  <icons.Trash />
                </div>
              </Restricted>
            )
          : companyId == data.data.companyId && (
              <Restricted permission="Ship.DeleteIncident">
                <div
                  className="m-l-grid-delete-icon-wrapper"
                  onClick={() => onClickDelete(data.data.eventId)}
                >
                  <icons.Trash />
                </div>
              </Restricted>
            )}
      </div>
    );
  };

  return (
    <div>
      <Grid gridConfig={gridConfig} actionTemplate={actionTemplate} />
      {isVisible && (
        <EditIncident
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          eventId={eventId}
          imoNumber={imoNumber}
          getIncidentsList={getIncidentsList}
          isMarsUser={isMarsUser}
          companyId={companyId}
        />
      )}
      {confirmDelete && (
        <ConfirmDialog
          dialogConfig={confirmDeleteDialogConfig}
          isOpen={confirmDelete}
        />
      )}
      {confirmAccept && (
        <ConfirmDialog
          dialogConfig={confirmAcceptDialogConfig}
          isOpen={confirmAccept}
        />
      )}
      {confirmIgnore && (
        <ConfirmDialog
          dialogConfig={confirmIgnoreDialogConfig}
          isOpen={confirmIgnore}
        />
      )}
    </div>
  );
}
