import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPSCInspection } from '../../../../types/psc-inspection';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import CustomStore from 'devextreme/data/custom_store';
import { IGridConfig } from '../../../../types/grid';
import Button from 'devextreme-react/button';
import Grid from '../../../common/grid/Grid';
import EditPSCInspection from '../../vessel-details/psc/edit-psc-inspection/EditPSCInspection';
import { useDataStore } from '../../../../hooks/useDataStore';
import { getCompanyId, getCompanyType } from '../../../../utils/jwt-decode';
import { CompanyType } from '../../../../enums/company-type-enum';
import {
  Restricted,
  usePermission,
} from '../../../../contexts/PermissionContext';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import { getAPIRoute } from '../../../../utils/api-route';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../../types/dialog';
import toast from '../../../../utils/toast';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import DataGrid from 'devextreme-react/data-grid';

export default function PSCGrid(props: {
  imoNumber?: string;
  ruleId: number;
  getRuleList: () => Promise<void>;
  setPscList: any;
  isReviewer?: boolean;
  vetRequestId: number;
}) {
  const { t } = useTranslation();

  const {
    imoNumber,
    ruleId,
    getRuleList,
    setPscList,
    isReviewer,
    vetRequestId,
  } = props;

  const [showEditPSCModal, setShowEditPSCModal] = useState(false);

  const [inspections, setInspections] = useState<IPSCInspection[]>([]);

  const [selectedInspectionId, setSelectedInspectionId] = useState<any>();

  const { getDataSource } = useDataStore();

  const portDataSource = getDataSource('portId', apiConfig.activePorts);

  const { findFeature } = usePermission();

  const companyId = getCompanyId();

  const companyType = getCompanyType();

  const [showConfirmAccept, setShowConfirmAccept] = useState(false);
  const [showConfirmIgnore, setShowConfirmIgnore] = useState(false);
  const [pscInspectionId, setPSCInspectionId] = useState<number>(0);
  const { setIsLoading } = useLoaderContext();
  const [isMarsUser, setIsMarsUser] = useState<boolean>();
  const gridRef = useRef<DataGrid>(null);
  const IsCompanyTypeMars = () => {
    if (companyType === 'M') {
      setIsMarsUser(true);
    } else {
      setIsMarsUser(false);
    }
  };
  const getPSCInspections = async () => {
    await api
      .get({
        url: getAPIRoute('vetRequestRulePsc', [vetRequestId, ruleId]),
      })
      .then((res: IPSCInspection[]) => {
        setInspections(res);
      });
  };
  const { formatDate } = useDateFormat();
  const selectionChanged = (e: any) => {
    const pscData: any[] = [];
    e.selectedRowsData.map((element: any) => {
      const psc = { inspectionDate: '', port: '', pscmou: '' };
      psc.inspectionDate =
        element.inspectionDate && formatDate(element.inspectionDate);
      psc.port = element.port;
      psc.pscmou = element.pscmou;
      pscData.push(psc);
    });
    setPscList(pscData);
  };

  const showPSCStatus = useMemo(() => {
    if (companyType === CompanyType.RequestorCompany) {
      return findFeature('Ship.ViewPSCAdditionalDetails');
    } else {
      if (findFeature('Ship.EditPSC') || findFeature('Ship.AddPSC')) {
        return true;
      } else {
        return findFeature('Ship.ViewPSCAdditionalDetails');
      }
    }
  }, [companyType]);

  const pscInspectionStatus = {
    store: new CustomStore({
      key: 'optionValueCode',
      loadMode: 'raw',
      load: () => {
        return api.get({ url: apiConfig.pscStatus, params: { ruleId } });
      },
    }),
  };
  const stateOfPSCInspection: any = {
    store: new CustomStore({
      key: 'optionValueCode',
      loadMode: 'raw',
      load: () => {
        return api.get({ url: apiConfig.stateOfPSCInspection }).then((res) => {
          return res;
        });
      },
    }),
  };

  useEffect(() => {
    IsCompanyTypeMars();
    if (imoNumber) {
      getPSCInspections();
    }
  }, []);

  useEffect(() => {
    if (isMarsUser) {
      gridRef.current?.instance.columnOption(9, 'visible', true);
      gridRef.current?.instance.columnOption(8, 'visible', false);
    } else {
      gridRef.current?.instance.columnOption(8, 'visible', showPSCStatus);
      gridRef.current?.instance.columnOption(9, 'visible', false);
    }
  }, [isMarsUser]);

  const cellPrepared = (e: any) => {
    if (e.rowType === 'data' && e.column.dataField === 'status') {
      e.cellElement.className =
        e.data.status === 'Closed'
          ? 'status-active-wtout'
          : e.data.status === 'AwaitingInfo'
          ? 'status-pending-wtout'
          : e.data.status === 'WrongEntry'
          ? 'status-wrong-entry-wtout'
          : 'status-inactive-wtout';
    }
    if (e.rowType === 'data' && e.column.dataField === 'marsStatus') {
      e.cellElement.className =
        e.data.marsStatus === 'Closed'
          ? 'status-active-wtout'
          : e.data.marsStatus === 'AwaitingInfo'
          ? 'status-pending-wtout'
          : e.data.marsStatus === 'WrongEntry'
          ? 'status-wrong-entry-wtout'
          : 'status-inactive-wtout';
    }
  };

  const onClickRow = (e: any) => {
    setSelectedInspectionId(e.data.pscInspectionId);
    setShowEditPSCModal(true);
  };

  const gridConfig: IGridConfig = {
    ref: gridRef,
    cellPrepared: cellPrepared,
    class:
      'm-c-grid full-border-checkbox-grid m-l-incident-grid m-c-auto-scroll-grid',
    testId: 'managementRolesGrid',
    onClickAddButton: () => {
      setShowEditPSCModal(true);
    },
    rowClick: onClickRow,
    dataSource: inspections,
    showSelection: true,
    selectionChanged: selectionChanged,
    rowClickPermission: 'Ship.ViewPSCDetail',
    defaultColumns: [
      {
        caption: t('labels.authority'),
        dataField: 'pscmou',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.portOfInspection'),
        dataField: 'port',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.dateOfInspection'),
        dataField: 'inspectionDate',
        dataType: 'date',
        minWidth: 150,
        format: 'dd MMM yyyy',
      },
      {
        caption: t('labels.typeOfInspection'),
        dataField: 'pscInspectionType',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.noOfDeficiencies'),
        dataField: 'noOfDeficiencies',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.detention'),
        dataField: 'isDetained',
        dataType: 'string',
        minWidth: 150,
        lookup: {
          dataSource: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          valueExpr: 'value',
          displayExpr: 'label',
        },
      },
      {
        caption: t('labels.durationDays'),
        dataField: 'duration',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.status'),
        dataField: 'status',
        dataType: 'string',
        minWidth: 150,
        lookup: {
          dataSource: pscInspectionStatus,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
        visible:
          companyType === CompanyType.RequestorCompany ? false : showPSCStatus,
      },
      {
        caption: t('labels.MARSStatus'),
        dataField: 'marsStatus',
        dataType: 'string',
        minWidth: 150,
        lookup: {
          dataSource: pscInspectionStatus,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
      },
      {
        caption: t('labels.source'),
        dataField: 'source',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.action'),
        dataField: 'stateOfPSCInspection',
        dataType: 'string',
        cellTemplate: 'actionTemplate',
        alignment: 'center',
        minWidth: 200,
        lookup: {
          dataSource: stateOfPSCInspection,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
      },
    ],
    showHeader: false,
  };

  const onAcceptPSC = (data: any) => {
    setShowConfirmAccept(true);
    setPSCInspectionId(data.pscInspectionId);
  };

  const onIgnorePSC = (data: any) => {
    setShowConfirmIgnore(true);
    setPSCInspectionId(data.pscInspectionId);
  };

  const acceptPSC = async () => {
    await api
      .put(
        {
          url: apiConfig.pscInspectionAccept + pscInspectionId,
          params: { IsIgnored: false },
        },
        setIsLoading
      )
      .then((res) => {
        if (res) {
          toast.success({ title: t('toast.pscAccepted') });
          getPSCInspections();
          getRuleList();
        }
      });
  };

  const ignorePSC = async () => {
    await api
      .put(
        {
          url: apiConfig.pscInspectionAccept + pscInspectionId,
          params: { IsIgnored: true },
        },
        setIsLoading
      )
      .then((res) => {
        if (res) {
          toast.success({ title: t('toast.pscIgnored') });
          getPSCInspections();
          getRuleList();
        }
      });
  };

  const confirmAcceptDialogConfig: IDialogConfig = {
    id: 'confirmAccept',
    content: t('toast.pscInspectionAccept'),
    handleSubmit: () => {
      acceptPSC();
      setShowConfirmAccept(false);
    },
    handleClose: () => {
      setShowConfirmAccept(false);
    },
  };

  const confirmIgnoreDialogConfig: IDialogConfig = {
    id: 'confirmIgnore',
    content: t('toast.pscInspectionIgnore'),
    handleSubmit: () => {
      ignorePSC();
      setShowConfirmIgnore(false);
    },
    handleClose: () => {
      setShowConfirmIgnore(false);
    },
  };
  const showAddPSCModal = () => {
    setSelectedInspectionId('');
    setShowEditPSCModal(true);
  };

  const actionTemplate = (data: any) => {
    return (
      <div className="m-l-icon-btn-holder">
        {!data.data.stateOfPSCInspection &&
          !data.data.isMarsOrExternal &&
          isMarsUser && (
            <>
              <Button
                onClick={() => {
                  onAcceptPSC(data.data);
                }}
                className="app-c-btn app-c-btn--secondary"
              >
                {t('buttons.accept')}
              </Button>
              <Button
                onClick={() => {
                  onIgnorePSC(data.data);
                }}
                className="app-c-btn app-c-btn--secondary"
              >
                {t('buttons.ignore')}
              </Button>
            </>
          )}
        {data.data.stateOfPSCInspection && isMarsUser && (
          <div>{data.data.stateOfPSCInspection}</div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="row m-l-cap-wrapper mrbtm-space">
        <div className="col-xl-6 col-lg-6 co-md-6 col-sm-6 col-12 m-l-cap-left-part">
          <div className="m-l-page-small-sub-heading">{t('headers.psc')}</div>
        </div>
        <div className="col-xl-6 col-lg-6 co-md-6 col-sm-6 col-12 m-l-cap-right-part">
          <Restricted permission="Ship.AddPSC">
            <div className="m-l-cap-right-icon-part">
              {isReviewer && companyType !== CompanyType.RequestorCompany && (
                <Button
                  className="app-c-btn app-c-btn--primary"
                  onClick={showAddPSCModal}
                >
                  {t('buttons.addNewPSC')}
                </Button>
              )}
            </div>
          </Restricted>
        </div>
      </div>
      <div className="row m-l-cap-rules-wrapper">
        <Grid gridConfig={gridConfig} actionTemplate={actionTemplate} />
        {showEditPSCModal && (
          <EditPSCInspection
            imoNumber={imoNumber}
            pscInspectionId={selectedInspectionId}
            isVisible={showEditPSCModal}
            portDataSource={portDataSource}
            setIsVisible={(isVisible: boolean) =>
              setShowEditPSCModal(isVisible)
            }
            getPSCInspections={getPSCInspections}
            isMarsUser={companyType === CompanyType.Mars}
            companyId={companyId}
            getRuleList={getRuleList}
          />
        )}
        <ConfirmDialog
          dialogConfig={confirmAcceptDialogConfig}
          isOpen={showConfirmAccept}
        />
        <ConfirmDialog
          dialogConfig={confirmIgnoreDialogConfig}
          isOpen={showConfirmIgnore}
        />
      </div>
    </>
  );
}
