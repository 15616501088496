import React, { useState, useEffect } from 'react';
import TabPanel from 'devextreme-react/tab-panel';
import { Template } from 'devextreme-react/core/template';
import { IGridConfig } from '../../../types/grid';
import Grid from '../../../components/common/grid/Grid';
import icons from '../../../components/common/icons/icons';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { useTranslation } from 'react-i18next';
import { getAPIRoute } from '../../../utils/api-route';
import { useNavigate } from 'react-router-dom';
import { VetRequestStatusCode } from '../../../enums/vet-request-status-enum';
import { useDateFormat } from '../../../hooks/useDateFormat';

export default function AppealHistory(props: {
  showAppealHistoryBtn?: any;
  setShowhowAppealHistoryBtn?: any;
  imoNumber?: string;
  vetRequestId?: string;
}) {
  const [showResults, setShowResults] = React.useState(false);
  const { t } = useTranslation();
  const [appealHistoryResult, setAppealHistoryResult] = useState<any>();
  const navigate = useNavigate();
  const { formatDate } = useDateFormat();
  const {
    showAppealHistoryBtn,
    setShowhowAppealHistoryBtn,
    imoNumber,
    vetRequestId,
  } = props;
  const [buId, setBuId] = useState<any>();
  useEffect(() => {
    if (vetRequestId) {
      getVetRequestDetails();
    }
  }, []);

  const getVetRequestDetails = async () => {
    await api
      .get({ url: apiConfig.vetRequests + vetRequestId })
      .then((data: any) => {
        if (
          data.status === VetRequestStatusCode.notRecommended ||
          data.status === VetRequestStatusCode.appeal ||
          data.status === VetRequestStatusCode.approved ||
          data.status === VetRequestStatusCode.rejected ||
          data.status === VetRequestStatusCode.conditionallyApproved
        ) {
          setShowhowAppealHistoryBtn(true);
          getVetRequestAppealHistory(data?.buId);
          setBuId(data?.buId);
        }
      });
  };

  const getVetRequestAppealHistory = async (buId: any) => {
    if (buId && imoNumber) {
      await api
        .get({
          url: getAPIRoute('vetAppealHistory', [vetRequestId, imoNumber]),
        })
        .then((data: any) => {
          setAppealHistoryResult(data);
        });
    }
  };

  const popUp = () => {
    setShowResults(!showResults);
  };

  const closePopup = () => {
    setShowResults(false);
  };
  const itemsMessage = [
    {
      title: t('labels.AppealHistory'),
      template: 'AppealHistory',
    },
  ];

  const onRowClick = (e: any) => {
    if (
      ![
        'dx-switch-on',
        'dx-switch-off',
        'dx-switch-handle',
        'dx-button-content',
        'dx-widget dx-datagrid-pager dx-pager',
      ].includes(e.event.target.className)
    ) {
      navigate('/vessel-info/vet-request', {
        state: { imoNumber: e.key.imoNumber, vetRequestId: e.key.vetRequestId },
      });
    }
  };

  const parseISOString = () => (rowData: any) => {
    if (rowData.appealDate !== null && rowData.appealDate !== '') {
      return formatDate(rowData.appealDate);
    }
  };

  const gridConfig: IGridConfig = {
    dataSource: appealHistoryResult,
    testId: 'appealHistoryGrid',
    defaultColumns: [
      {
        caption: t('labels.VetRequestID'),
        dataField: 'vetId',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.date'),
        dataField: 'appealDate',
        dataType: 'string',
        format: 'dd MMM yyyy',
        minWidth: 150,
        calculateCellValue: parseISOString(),
      },
      {
        caption: t('labels.Requester'),
        dataField: 'appealRequesterUserName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.AppealResult'),
        dataField: 'statusText',
        dataType: 'string',
        minWidth: 150,
        // calculateCellValue: getVetStatusName(),
      },
      {
        caption: t('labels.AppealTo'),
        dataField: 'appealUserName',
        dataType: 'string',
        minWidth: 150,
      },
    ],
    rowClick: onRowClick,
    hidePagination: false,
    hideFilterRow: true,
  };

  return (
    <div className="inner-wrapper">
      <div
        className={
          'm-l-panel-from-bottom m-l-panel__bottom m-l-appeal-panel ' +
          (showResults ? 'm-l-panel__show' : 'm-l-panel__hide')
        }
        data-testid="showAppealPopup"
      >
        <div
          className={
            'm-c-panel__container ' +
            (showResults ? 'm-l-panel-expanded' : 'm-l-panel-collapsed')
          }
        >
          {showAppealHistoryBtn ? (
            <>
              <TabPanel
                items={itemsMessage}
                className="m-c-tab-block m-c-tab-in-panel m-c-tab-block--full-width m-c-appl-lg"
              >
                <Template
                  name="AppealHistory"
                  render={() => (
                    <div className="m-l-rqst-comment-wrapper m-l-appl-history-wrap">
                      <div className="m-l-grid-wrapper-block m-l-appl-history-blck">
                        <div className="m-c-grid m-c-grid-with-no-outerborder m-c-btm-no-border">
                          <Grid gridConfig={gridConfig} />
                        </div>
                      </div>
                    </div>
                  )}
                />
              </TabPanel>

              <div
                className="m-c-message-box-up-icon"
                onClick={popUp}
                data-testid="closeAppealPopup"
              >
                <span className={showResults ? 'u-hide' : ''}>
                  <icons.ChevronUp />
                </span>
                <span
                  className={showResults ? '' : 'u-hide'}
                  onClick={closePopup}
                >
                  <icons.IconCross />
                </span>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
