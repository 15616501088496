import Button from 'devextreme-react/button';
import React, { useEffect, useState } from 'react';
import icons from '../../../common/icons/icons';
import VesselClassificationEdit from './VesselClassificationEdit';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { useTranslation } from 'react-i18next';
import { IOptionvalue } from '../../../../types/master-data';
import { Restricted } from '../../../../contexts/PermissionContext';
import { useDateFormat } from '../../../../hooks/useDateFormat';

export default function VesselClassification(props: { imoNumber?: string }) {
  const [isclassificationEdit, setIsClassificationEdit] = useState(false);
  const [classSociety, setClassSociety] = useState<IOptionvalue[]>([]);
  const [vesselStatus, setVesselStatus] = useState<IOptionvalue[]>([]);
  const [survey, setSurvey] = useState<IOptionvalue[]>([]);
  const [capRating, setCapRating] = useState<IOptionvalue[]>([]);
  const [vesselclassificationList, setVesselClassificationList] = useState<any>(
    []
  );
  const { imoNumber } = props;
  const { t } = useTranslation();
  const { formatDate } = useDateFormat();
  const showClassificationEdit = () => {
    setIsClassificationEdit(!isclassificationEdit);
  };

  const getClassSociety = async () => {
    await api
      .get({
        url: apiConfig.classSociety,
      })
      .then((res: IOptionvalue[]) => {
        setClassSociety(res);
      });
  };

  const getCapRating = async () => {
    await api
      .get({
        url: apiConfig.capRating,
      })
      .then((res: IOptionvalue[]) => {
        setCapRating(res);
      });
  };

  const getSurvey = async () => {
    await api
      .get({
        url: apiConfig.survey,
      })
      .then((res: IOptionvalue[]) => {
        setSurvey(res);
      });
  };

  const getVesselStatus = async () => {
    await api
      .get({
        url: apiConfig.vesselClassStatus,
      })
      .then((res: IOptionvalue[]) => {
        setVesselStatus(res);
      });
  };

  const getClassifications = async () => {
    await api
      .get({
        url: apiConfig.vesselClassificationTab + imoNumber,
      })
      .then((response: any) => {
        setVesselClassificationList(response);
      });
  };

  useEffect(() => {
    getClassSociety();
    getVesselStatus();
    getSurvey();
    getCapRating();
  }, []);

  useEffect(() => {
    if (imoNumber) {
      getClassifications();
    }
  }, []);
  return (
    <span>
      <div className="m-l-inner-page-body">
        {/* <div className="m-l-expand-btn-wrap m-l-edit-view-mode">
                                    <Button className="app-c-btn app-c-icon-only-btn">
                                      <icons.IconPencil />
                                    </Button>
                                  </div> */}
        {!isclassificationEdit ? (
          <div className="m-l-form-panel-block">
            <div className="m-l-tbl-square-wrapper m-l-tbl-square-section-p-b">
              <div className="m-l-ads-table-block">
                <div className="row m-l-ads-table-row m-l-ads-table--inner-row">
                  <div className="row">
                    <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 m-l-custom-sixteen-space-col-wrap">
                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.classSociety')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {vesselclassificationList.classSociety}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.classStatus')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {vesselclassificationList?.classStatus}{' '}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.classNotation')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {vesselclassificationList.classNotation}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.iceClass')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {vesselclassificationList.iceClass}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.serviceRestriction')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {vesselclassificationList.serviceRestriction}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.classificationDate')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {formatDate(
                              vesselclassificationList.classificationDate
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.specialSurveyDate')}{' '}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {formatDate(
                              vesselclassificationList.specialSurveyDate
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.survey')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {vesselclassificationList.surveyType}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.surveyDate')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {formatDate(
                              vesselclassificationList.annualSurveyDate
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.nextSurveyDate')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {formatDate(
                              vesselclassificationList.nextAnnualSurveyDate
                            )}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.survey2')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {vesselclassificationList.survey2}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.survey2Date')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {formatDate(vesselclassificationList.survey2Date)}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.survey2NextSurveyDate')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {formatDate(
                              vesselclassificationList.nextDrydockDate
                            )}
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.classNarrative')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {vesselclassificationList.classNarrative}
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items">
                          <div className="m-l-ads-tbl-title">
                            {t('labels.historicClassStatus')}
                          </div>
                          <div className="m-l-ads-tbl-desc">
                            {vesselclassificationList?.historicClassStatus}{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                      <div className="m-l-expand-btn-wrap m-l-edit-view-mode">
                        <Restricted permission="Ship.EditClassification">
                          <Button
                            className="app-c-btn app-c-icon-only-btn"
                            onClick={showClassificationEdit}
                          >
                            <icons.IconPencil />
                          </Button>
                        </Restricted>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <VesselClassificationEdit
            imoNumber={imoNumber}
            setIsClassificationEdit={setIsClassificationEdit}
            vesselclassificationList={vesselclassificationList}
            classSociety={classSociety}
            vesselStatus={vesselStatus}
            getClassifications={getClassifications}
            survey={survey}
            capRating={capRating}
          />
        )}
      </div>
    </span>
  );
}
