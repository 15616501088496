import React, { useEffect, useMemo, useRef, useState } from 'react';
import LineChart from '../../common/charts/line-chart/LineChart';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { GadgetsTimePeriod } from '../../../enums/gadgets-time-period-enum';
import { IOptionvalue } from '../../../types/master-data';
import SelectBox from 'devextreme-react/select-box';
import Button from 'devextreme-react/button';
import icons from '../../common/icons/icons';
import Popover from 'devextreme-react/popover';
import { useTranslation } from 'react-i18next';
import { useResize } from '../../../hooks/useResize';
import { getOPtionValueByType } from '../../../services/master-data-service';
import { OptionTypes } from '../../../enums/option-type-enum';
export default function TurnaroundByCompanyGadget() {
  const { setIsLoading } = useLoaderContext();
  const [gadgetsTimePeriod, setGadgetsTimePeriod] = useState<any[]>([]);
  const [timePeriod, setTimePeriod] = useState(
    gadgetsTimePeriod[0]?.optionValueCode
  );
  const [showExportPopover, setShowExportPopover] = useState(false);
  const exportPopupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-dashboard__chart-export',
      'data-testid': 'rolePopover',
    };
  }, []);
  const { t } = useTranslation();
  const [data, setData] = useState<any>([]);
  const [seriesData, setSeriesData] = useState<any>([]);

  useEffect(() => {
    getGadgetsTimePeriod();
    // getTurnAroundGadget();
  }, []);

  useEffect(() => {
    if (timePeriod) {
      setDataSource();
    }
  }, [timePeriod]);

  const setTurnAroundByCompany = (turnAroundByCompany: any) => {
    if (turnAroundByCompany && turnAroundByCompany.length > 0) {
      const resSeriesOg = turnAroundByCompany.map((item: any) => {
        return {
          name: item.companyName,
          value: item.companyName.replace(/\s/g, ''),
        };
      });
      const uniqueIds: any = [];
      const resSeries = resSeriesOg.filter((element: any) => {
        const isDuplicate = uniqueIds.includes(element.value);
        if (!isDuplicate) {
          uniqueIds.push(element.value);
          return true;
        }
        return false;
      });
      const months: any = [];
      turnAroundByCompany.forEach((item: any) => {
        if (!months?.includes(item.dayWeekMonth)) {
          months?.push(item.dayWeekMonth);
        }
      });
      const resData = months.map((month: any) => {
        const companyMonthVal = resSeries.map((company: any) => {
          const companyData = turnAroundByCompany?.find(
            (tAC: any) => tAC.companyName === company.name
          );
          let companyMonthTime: any;
          if (companyData.dayWeekMonth == month) {
            companyMonthTime = companyData;
          }
          return {
            [company.name]: parseInt(
              companyMonthTime?.totalHours?.toFixed(2) || 0
            ),
          };
        });
        const monthObj = { month };
        const monthWiseRes = Object.assign({}, monthObj, ...companyMonthVal);
        return monthWiseRes;
      });
      setData(resData);
      setSeriesData(resSeries);
    } else {
      setData([]);
      setSeriesData([]);
    }
  };

  const setDataSource = () => {
    if (timePeriod == GadgetsTimePeriod.Week) {
      getTurnAroundGadgetTimePeriod(GadgetsTimePeriod.Week);
    } else if (timePeriod == GadgetsTimePeriod.Month) {
      getTurnAroundGadgetTimePeriod(GadgetsTimePeriod.Month);
    } else if (timePeriod == GadgetsTimePeriod.Quarter) {
      getTurnAroundGadgetTimePeriod(GadgetsTimePeriod.Quarter);
    } else if (timePeriod == GadgetsTimePeriod.HalfYear) {
      getTurnAroundGadgetTimePeriod(GadgetsTimePeriod.HalfYear);
    } else {
      getTurnAroundGadgetTimePeriod(GadgetsTimePeriod.Year);
    }
  };

  const getTurnAroundGadget = async () => {
    await api
      .get(
        {
          url: apiConfig.turnAroundByCompany,
          params: { timePeriod: GadgetsTimePeriod.Week },
        },
        setIsLoading
      )
      .then((data: any) => {
        setTurnAroundByCompany(data.turnaroundByCompany);
      });
  };

  const getTurnAroundGadgetTimePeriod = async (period: any) => {
    await api
      .get(
        {
          url: apiConfig.turnAroundByCompany,
          params: { timePeriod: period },
        },
        setIsLoading
      )
      .then((data: any) => {
        setTurnAroundByCompany(data.turnaroundByCompany);
      });
  };

  const getGadgetsTimePeriod = async () => {
    await getOPtionValueByType(OptionTypes.GadgetsTimePeriod)?.then(
      (res: IOptionvalue[]) => {
        const data = res ? JSON.parse(JSON.stringify(res)) : [];
        const fromIndex = data?.findIndex((x: any) => x?.isDefault == true);
        const element = data?.splice(fromIndex, 1)[0];
        const toIndex = 0;
        data?.splice(toIndex, 0, element);
        setGadgetsTimePeriod(data);
      }
    );
  };

  useMemo(() => {
    if (gadgetsTimePeriod && gadgetsTimePeriod.length > 0) {
      setTimePeriod(gadgetsTimePeriod[0].optionValueCode);
    }
  }, [gadgetsTimePeriod]);

  const fileFormats = [
    {
      id: 1,
      name: 'PNG File',
      value: 'png',
    },
    {
      id: 2,
      name: 'JPEG File',
      value: 'jpeg',
    },
    {
      id: 3,
      name: 'PDF File',
      value: 'pdf',
    },
    {
      id: 4,
      name: 'SVG File',
      value: 'svg',
    },
  ];

  const chartRef: any = useRef();

  const config = {
    argumentField: 'month',
    ref: chartRef,
  };

  const onCLickExport = (format: any) => {
    chartRef?.current.instance?.exportTo(
      t('labels.turnaroundByCompany'),
      format
    );
  };

  const { isMobileResolution } = useResize();
  const [position, setPosition] = useState<any>('bottom');
  useEffect(() => {
    if (isMobileResolution) {
      setPosition('left');
    } else {
      setPosition('bottom');
    }
  }, [isMobileResolution]);

  return (
    <div>
      {gadgetsTimePeriod && gadgetsTimePeriod.length > 0 && (
        <div className="row">
          <div className="col-sm-4 col-md-5 col-lg-6 col-xl-4 col-6">
            <div className="m-c-form-group mb-btm-space">
              <SelectBox
                className="m-c-input-control m-c-select-box"
                placeholder="Select option type"
                dataSource={gadgetsTimePeriod}
                defaultValue={gadgetsTimePeriod[0].optionValueCode}
                searchEnabled
                valueExpr="optionValueCode"
                displayExpr="optionValueText"
                onValueChanged={(e) => setTimePeriod(e.value)}
              />
            </div>
          </div>
          <div
            className="col-sm-8 col-md-7 col-lg-6 col-xl-8 
          col-6 d-flex"
          >
            <div className="ml-dashboard-export">
              <Button
                id="linkTurnAroungByCompany"
                className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                aria-label="Export-btn"
                onClick={() => setShowExportPopover(true)}
              >
                <div className="m-c-icon-inside-btn" data-testid="export-all">
                  <icons.download />
                </div>
              </Button>
              <Popover
                target="#linkTurnAroungByCompany"
                position={position}
                width={132}
                visible={showExportPopover}
                wrapperAttr={exportPopupAttributes}
                closeOnOutsideClick={true}
                onHiding={() => setShowExportPopover(false)}
              >
                <ul className="ml-expport-list">
                  {/* <li>Print</li> */}
                  {fileFormats.map((role: any, index: any) => {
                    return (
                      <li key={index} onClick={() => onCLickExport(role.value)}>
                        {role.name}
                      </li>
                    );
                  })}
                </ul>
              </Popover>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <LineChart
          dataSource={data}
          series={seriesData}
          chartConfig={config}
          chartRef={chartRef}
        />
      </div>
    </div>
  );
}
