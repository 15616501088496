import React, { useEffect, useState } from 'react';
import { IGridConfig } from '../../../../types/grid';
import Grid from '../../../common/grid/Grid';
import LoadingRateEdit from '../loading-rate-edit-modal/LoadingRateEdit';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { IBerthLoadingRate } from '../../../../types/port-management';

export default function LoadingRateGrid(props: {
  berthCode: any;
  isFromViewInfoModal?: boolean;
}) {
  const { berthCode, isFromViewInfoModal } = props;
  const [showEditModal, setShowEditModal] = useState(false);
  const [loadingRateData, setLoadingRateData] = useState<IBerthLoadingRate[]>(
    []
  );
  const [isEdit, setIsEdit] = useState(false);
  const [loadingRateId, setLoadingRateId] = useState(null);

  const getLoadingRateData = async () => {
    await api
      .get({
        url: apiConfig.berthLoadDischarge,
        params: { berthCode: berthCode },
      })
      .then((res) => {
        setLoadingRateData(res.data);
      });
  };

  useEffect(() => {
    getLoadingRateData();
  }, []);

  const onClickAddNew = () => {
    setIsEdit(false);
    setShowEditModal(true);
  };

  const onRowClick = (e: any) => {
    if (!isFromViewInfoModal) {
      setLoadingRateId(e.key.berthLoadAndDisrateId);
      setIsEdit(true);
      setShowEditModal(true);
    }
  };

  const gridConfig: IGridConfig = {
    class: 'm-c-grid m-l-grid-with--top-border m-c-righticon-grid',
    testId: 'vesselLoadingRateGrid',
    height: '350',
    dataSource: loadingRateData,
    // rowClickPermission: 'Ship.EditName',
    rowClick: onRowClick,
    onClickAddButton: onClickAddNew,
    defaultColumns: [
      {
        caption: 'Berth Type',
        dataField: 'berthTypeDesc',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: 'Loading Rate',
        dataField: 'loadingRate',
        dataType: 'number',
        minWidth: 150,
      },
      {
        caption: 'Discharge Rate',
        dataField: 'dischargeRate',
        dataType: 'number',
        minWidth: 150,
      },
    ],
    showHeader: true,
    showExport: false,
    showAddButton: !isFromViewInfoModal,
  };

  return (
    <div className="m-l-tab-accordion-item-content-section">
      <Grid gridConfig={gridConfig} />
      {showEditModal && (
        <LoadingRateEdit
          isVisible={showEditModal}
          handleCancelClick={() => setShowEditModal(false)}
          berthCode={berthCode}
          getLoadingRateData={getLoadingRateData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          loadingRateId={loadingRateId}
          setLoadingRateId={setLoadingRateId}
        />
      )}
    </div>
  );
}
