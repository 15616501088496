import React, { useState, useEffect, useRef, useMemo } from 'react';
import TextBox from 'devextreme-react/text-box';
import icons from '../common/icons/icons';
import Switch from 'devextreme-react/switch';
import TabPanel from 'devextreme-react/tab-panel';
import { Template } from 'devextreme-react/core/template';
// import imageURL from '../../assets/images';
import { MarsApiService as api } from '../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import CheckBox from 'devextreme-react/check-box';
import { INotifications } from '../../types/notifications';
import { useAuth } from '../../contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLoaderContext } from '../../contexts/LoaderContext';

const items = [
  {
    title: 'Active',
    template: 'active',
  },
  {
    title: 'Archived',
    template: 'archived',
  },
];

export default function ViewNotifications(props: { refreshConfig?: any }) {
  const { refreshConfig } = props;
  const relationRef = useRef<any>(null);
  const { t } = useTranslation();
  const [filteredNotifications, setFilteredNotifications] = useState<any>([]);
  const [notificationsList, setNotificationsList] = useState<any>([]);
  const [unreadNotificationList, setUnreadNotficationList] = useState<any>([]);
  const [unreadActiveNotificationList, setUnreadActiveNotficationList] =
    useState<any>([]);
  const [unreadArchiveNotificationList, setUnreadArchiveNotficationList] =
    useState<any>([]);
  const [unread, setUnread] = React.useState(false);
  const [notifyBell, setNotifyBell] = useState();
  const [unreadActiveCount, setUnreadActiveCount] = useState<any>(0);
  const [unreadArchiveCount, setUnreadArchiveCount] = useState<any>(0);
  const auth = useAuth();
  const [isNotfcn, setNotfcn] = useState(false);
  const userGridRef = useRef<any>();
  const navigation = useNavigate();
  const { setIsLoading } = useLoaderContext();

  const showNotification = () => {
    setNotfcn(!isNotfcn);
  };

  const notificationBell = useMemo(() => {
    let notifications: any = [];
    unreadNotificationList?.notificationGroups?.forEach((item: any) => {
      notifications = [...notifications, ...item.notifications];
    });
    if (notifications.length > 0) {
      return true;
    } else {
      return false;
    }
  }, [unreadNotificationList]);

  const onInputSearch = async (e: any) => {
    await api
      .get({
        url: apiConfig.notifications,
        params: { keyword: e.event.target.value },
      })
      .then((data) => {
        setFilteredNotifications(data);
      });
  };

  const onMarkAllAsRead = (flag: any) => {
    let ids: any = [];
    if (flag == true) {
      unreadArchiveNotificationList?.notificationGroups?.map((item: any) => {
        item.ids = item.notifications?.map(
          (notifications: any) => notifications?.notificationId
        );
      });
      ids = unreadArchiveNotificationList?.notificationGroups?.map(
        (item: any) => item.ids
      );
    } else {
      unreadActiveNotificationList?.notificationGroups?.map((item: any) => {
        item.ids = item.notifications?.map(
          (notifications: any) => notifications?.notificationId
        );
      });
      ids = unreadActiveNotificationList?.notificationGroups?.map(
        (item: any) => item.ids
      );
    }

    let notificationIds: any = [];
    ids.forEach((item: any) => {
      notificationIds = [...notificationIds, ...item];
    });
    notificationsReadUnread(notificationIds, 'R');
  };

  const onTabSwitch = (e: any) => {
    setUnread(e.value);
    if (e.value) {
      setFilteredNotifications(unreadNotificationList);
    } else {
      setFilteredNotifications(notificationsList);
    }
  };

  const notificationsReadUnread = async (notificationIds: any, status: any) => {
    const roleJsonEdit: INotifications = {
      userId: auth.user,
      status: status,
      notificationIds: notificationIds,
    };

    await api
      .put(
        { url: apiConfig.notificationsReadUnread, data: roleJsonEdit },
        setIsLoading
      )
      .then(() => {
        getNotificationList();
      });
  };

  const notificationsArchive = async (notification: any) => {
    setNotfcn(false);
    const obj = JSON.parse(notification.relativeUrl);
    navigation(obj.url, {
      state: obj.state,
    });
    await api.put(
      {
        url: apiConfig.notificationsArchive,
        data: { notificationId: notification.notificationId },
      },
      setIsLoading
    );
    getNotificationList();
  };

  const setReadUnread = (e: any, notify: any) => {
    if (e.event) {
      let status: any;
      if (e.value == true) {
        status = 'N';
      } else {
        status = 'R';
      }
      notificationsReadUnread([notify.notificationId], status);
    }
  };

  const getNotificationList = async () => {
    await api
      .get({
        url: apiConfig.notifications,
      })
      .then((data) => {
        setNotificationsList(JSON.parse(JSON.stringify(data)));
        const unreadList = JSON.parse(JSON.stringify(data));
        unreadList?.notificationGroups?.filter((item: any) => {
          item.notifications = item.notifications?.filter(
            (notifications: any) => notifications.notificationStatus === 'N'
          );
        });
        const unreadActiveList = JSON.parse(JSON.stringify(data));
        unreadActiveList?.notificationGroups?.filter((item: any) => {
          item.notifications = item.notifications?.filter(
            (notifications: any) =>
              notifications.notificationStatus === 'N' &&
              notifications.isArchived === false
          );
        });
        const unreadArchiveList = JSON.parse(JSON.stringify(data));
        unreadArchiveList?.notificationGroups?.filter((item: any) => {
          item.notifications = item.notifications?.filter(
            (notifications: any) =>
              notifications.notificationStatus === 'N' &&
              notifications.isArchived === true
          );
        });
        setUnreadNotficationList(JSON.parse(JSON.stringify(unreadList)));
        setUnreadArchiveNotficationList(
          JSON.parse(JSON.stringify(unreadArchiveList))
        );
        setUnreadActiveNotficationList(
          JSON.parse(JSON.stringify(unreadActiveList))
        );
        unread
          ? setFilteredNotifications(unreadList)
          : setFilteredNotifications(data);
      });
  };

  useEffect(() => {
    if (
      refreshConfig &&
      refreshConfig.length > 0 &&
      refreshConfig[0]?.settingValue != '0'
    ) {
      const interval = setInterval(() => {
        getNotificationList();
      }, parseInt(refreshConfig[0]?.settingValue) * 60000);
      return () => clearInterval(interval);
    }
  }, [refreshConfig.length > 0]);

  useEffect(() => {
    if (isNotfcn) {
      getNotificationList();
    }
  }, [isNotfcn]);

  useEffect(() => {
    getNotificationList();
  }, []);

  useMemo(() => {
    if (unreadActiveNotificationList) {
      let notifications: any = [];
      unreadActiveNotificationList?.notificationGroups?.forEach((item: any) => {
        notifications = [...notifications, ...item.notifications];
      });
      setUnreadActiveCount(notifications);
    }
  }, [unreadActiveNotificationList]);

  useMemo(() => {
    if (unreadArchiveNotificationList) {
      let notifications: any = [];
      unreadArchiveNotificationList?.notificationGroups?.forEach(
        (item: any) => {
          notifications = [...notifications, ...item.notifications];
        }
      );
      setUnreadArchiveCount(notifications);
    }
  }, [unreadArchiveNotificationList]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        relationRef.current &&
        !relationRef.current.contains(event.target) &&
        event.target.localName != 'path'
      ) {
        setNotfcn(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [relationRef]);
  return (
    <>
      <div className="m-l-notification-block" onClick={showNotification}>
        {notificationBell ? (
          <span className="m-l-notification-mark"></span>
        ) : null}
        <icons.Bell />
      </div>
      <div
        ref={relationRef}
        className={
          'm-l-notification-list-block ' + (isNotfcn ? '' : 'u-display-none')
        }
      >
        <div className="m-l-notification-list-block-header">
          <div className="left-block">
            <h5>{t('labels.notifications')}</h5>
          </div>
          <div className="right-block">
            <div className="m-l-input-search-area-block">
              <TextBox
                placeholder="Search"
                className="m-c-input-control"
                onInput={onInputSearch}
              ></TextBox>
              <div className="m-c-search-icon">
                <icons.Search />
              </div>
            </div>
          </div>
        </div>
        <div className="m-l-notification-list-block-body">
          <TabPanel
            items={items}
            className="m-c-tab-block m-c-tab-block--full-width"
          >
            <Template name="active">
              <div className="notification-wrapper">
                <div className="m-l-mark-read">
                  {unreadActiveCount?.length > 0 && (
                    <a
                      onClick={() => onMarkAllAsRead(false)}
                      className="mark-read--text"
                    >
                      {t('labels.markAllAsRead')}
                    </a>
                  )}
                </div>
                {filteredNotifications?.notificationGroups?.map(
                  (value: any, index: any) => {
                    return (
                      <div key={index}>
                        {value &&
                          value.notifications?.length > 0 &&
                          value.notifications?.filter(
                            (item: any) => item.isArchived == false
                          )?.length > 0 && (
                            <>
                              <div className="m-l-time-line-block">
                                <p className="today-text">{value.day}</p>
                              </div>
                              <ul>
                                {value.notifications.map(
                                  (notify: any, index: any) => {
                                    if (!notify.isArchived) {
                                      return (
                                        <li key={index}>
                                          <div
                                            onClick={() => {
                                              notificationsArchive(notify);
                                            }}
                                            className="left-block"
                                          >
                                            <div className="m-l-user-image-block">
                                              <div className="m-l-profile-block">
                                                {/* <img
                                                  src={imageURL.avatar}
                                                  className="img-responsive"
                                                  alt="Logo"
                                                /> */}
                                                <icons.Profile />
                                              </div>
                                            </div>
                                            <div className="m-l-user-details-section">
                                              <div className="m-l-user-details-block">
                                                <h1
                                                  dangerouslySetInnerHTML={{
                                                    __html: notify.htmlTitle,
                                                  }}
                                                  className="m-l-company-name"
                                                ></h1>
                                              </div>
                                              <p>{notify.receivedTime}</p>
                                            </div>
                                          </div>
                                          <div className="right-block">
                                            <div className="m-c-checkbox m-c-checkbox--round">
                                              <CheckBox
                                                value={
                                                  notify.notificationStatus ==
                                                  'N'
                                                    ? true
                                                    : false
                                                }
                                                onValueChanged={(e: any) =>
                                                  setReadUnread(e, notify)
                                                }
                                              ></CheckBox>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    }
                                  }
                                )}
                              </ul>
                            </>
                          )}
                      </div>
                    );
                  }
                )}
              </div>
            </Template>
            {
              <Template name="archived">
                <div className="notification-wrapper">
                  <div className="m-l-mark-read">
                    {unreadArchiveCount?.length > 0 && (
                      <a
                        onClick={() => onMarkAllAsRead(true)}
                        className="mark-read--text"
                      >
                        {t('labels.markAllAsRead')}
                      </a>
                    )}
                  </div>
                  {filteredNotifications?.notificationGroups?.map(
                    (value: any, index: any) => {
                      return (
                        <div key={index}>
                          {value &&
                            value.notifications?.length > 0 &&
                            value.notifications?.filter(
                              (notify: any) => notify.isArchived
                            ).length > 0 && (
                              <>
                                <div className="m-l-time-line-block">
                                  <p className="today-text">{value.day}</p>
                                </div>
                                <ul>
                                  {value.notifications.map(
                                    (notify: any, index: any) => {
                                      if (notify.isArchived) {
                                        return (
                                          <li key={index}>
                                            <div
                                              onClick={() => {
                                                const obj = JSON.parse(
                                                  notify.relativeUrl
                                                );
                                                navigation(obj.url, {
                                                  state: obj.state,
                                                });
                                                setNotfcn(false);
                                              }}
                                              className="left-block"
                                            >
                                              <div className="m-l-user-image-block">
                                                <div className="m-l-profile-block">
                                                  {/* <img
                                                    src={imageURL.avatar}
                                                    className="img-responsive"
                                                    alt="Logo"
                                                  /> */}
                                                  <icons.Profile />
                                                </div>
                                              </div>
                                              <div className="m-l-user-details-section">
                                                <div className="m-l-user-details-block">
                                                  <h1
                                                    dangerouslySetInnerHTML={{
                                                      __html: notify.htmlTitle,
                                                    }}
                                                    className="m-l-company-name"
                                                  ></h1>
                                                </div>
                                                <p>{notify.receivedTime}</p>
                                              </div>
                                            </div>
                                            <div className="right-block">
                                              <div className="m-c-checkbox m-c-checkbox--round">
                                                <CheckBox
                                                  value={
                                                    notify.notificationStatus ==
                                                    'N'
                                                      ? true
                                                      : false
                                                  }
                                                  onValueChanged={(e: any) =>
                                                    setReadUnread(e, notify)
                                                  }
                                                ></CheckBox>
                                              </div>
                                            </div>
                                          </li>
                                        );
                                      }
                                    }
                                  )}
                                </ul>
                              </>
                            )}
                        </div>
                      );
                    }
                  )}
                </div>
              </Template>
            }
          </TabPanel>
          <div className="m-l-toggle-switch-block">
            <span>{t('labels.onlyShowUnread')}</span>
            <div className="m-l-toggle-switch">
              <Switch value={unread} onValueChanged={onTabSwitch} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
