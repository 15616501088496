import React, { useState, useMemo, useEffect, useRef } from 'react';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import imageURL from '../../assets/images';
import { Button } from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import Tooltip from 'devextreme-react/tooltip';
import { IModalConfig } from '../../types/modal';
import Modal from '../common/modal/Modal';
import icons from '../common/icons/icons';
import { IChangePassword } from '../../types/change-password';
import { MarsApiService as api } from '../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import Validator from 'devextreme-react/validator';
import { useLoaderContext } from '../../contexts/LoaderContext';
import { getAdditionalInfo } from '../../utils/jwt-decode';
import { useTranslation } from 'react-i18next';
import ValidationGroup from 'devextreme-react/validation-group';
import toast from '../../utils/toast';

export default function ViewChangePassword(props: {
  isChangePassword: any;
  setIsChangePassword: any;
}) {
  const { t } = useTranslation();
  const validationGroupOldPassword: any = useRef();
  const validationGroupNewPassword: any = useRef();
  const validationGroupConfirmPassword: any = useRef();
  const [oldpassword, setOldPassword] = React.useState('');
  const [newpassword, setNewPassword] = React.useState('');
  const [confirmpassword, setConfirmPassword] = React.useState('');
  const { isChangePassword, setIsChangePassword } = props;
  const [passwordpolicyList, setPasswordPolicyList] = useState<any>([]);
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const { setIsLoading } = useLoaderContext();
  const tokenFlag = sessionStorage?.getItem('update_password')
    ? sessionStorage?.getItem('update_password')
    : localStorage?.getItem('update_password');

  const PasswordPolicyList = async () => {
    await api
      .get({
        url: apiConfig.configurations,
        params: { settingArea: 'password' },
      })
      .then((response) => {
        setPasswordPolicyList(response);
      });
  };
  const popupAttributesChangePWD = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-modal-change__pwd',
    };
  }, []);

  const handleCancelClickChangePWD = (e: any) => {
    setIsChangePassword(false);
    clearFields();
  };

  const clearFields = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const modalConfigChangePWD: IModalConfig = {
    width: 400,
    height: 434,
    visible: isChangePassword,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  /////////////////////password change success modal/////////////////////
  const [isShowSuccessModal, setShowSuccessModal] = useState(false);
  const modalConfigSuccess: IModalConfig = {
    width: 400,
    height: 216,
    visible: isShowSuccessModal,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const SuccessPwdChange = (e: any) => {
    if (
      validationGroupOldPassword.current?.instance.validate().isValid &&
      validationGroupNewPassword.current?.instance.validate().isValid &&
      validationGroupConfirmPassword.current?.instance.validate().isValid
    ) {
      const roleJsonEdit: IChangePassword = {
        OldPassword: oldpassword,
        NewPassword: newpassword,
      };
      if (newpassword.length >= passwordpolicyList[0].settingValue) {
        if (confirmpassword == newpassword) {
          api
            .put(
              { url: apiConfig.changePassword, data: roleJsonEdit },
              setIsLoading
            )
            .then((response) => {
              if (response == true) {
                handleCancelClickChangePWD(e);
                toast.success({
                  title: t('toast.passwordSavedSuccessfully'),
                });
                setShowSuccessModal(true);
                sessionStorage.removeItem('update_password');
                localStorage.removeItem('update_password');
              }
            });
        }
      }
    }
    return;
  };

  const handleCancelConfigSuccess = (e: any) => {
    setShowSuccessModal(false);
  };
  const popupAttributesConfigSuccess = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-modal-cm m-l-modal__success',
    };
  }, []);

  ////////////////////////tooltip/////////////////////
  const renderContent = () => {
    return (
      <div className="m-l-tooltip-wrapper m-l-info-tooltip">
        {t('labels.passworlPolicy1')}
        {passwordpolicyList[0]?.settingValue} {t('labels.passworlPolicy2')}
        {passwordpolicyList[1]?.settingValue} {t('labels.passworlPolicy3')},
        {passwordpolicyList[2]?.settingValue} {t('labels.passworlPolicy4')},{' '}
        {passwordpolicyList[4]?.settingValue} {t('labels.passworlPolicy5')}{' '}
        {passwordpolicyList[3]?.settingValue} {t('labels.passworlPolicy6')}
      </div>
    );
  };

  const systemPasswordLogin = () => {
    if (tokenFlag == 'true') {
      return false;
    } else {
      return true;
    }
  };

  const validatePassword = () => (params: any) => {
    if (newpassword == params.value) {
      return true;
    }
    return false;
  };

  const validateNewPassword = () => (params: any) => {
    if (oldpassword == params.value) {
      return false;
    }
    return true;
  };

  const newPasswordButton = useMemo(() => {
    return {
      icon: showNewPassword ? imageURL.openedeye : imageURL.closedeye,
      onClick: () => {
        setShowNewPassword(!showNewPassword);
      },
    };
  }, [showNewPassword]);

  const confirmPasswordButton = useMemo(() => {
    return {
      icon: showConfirmPassword ? imageURL.openedeye : imageURL.closedeye,
      onClick: () => {
        setShowConfirmPassword(!showConfirmPassword);
      },
    };
  }, [showConfirmPassword]);

  const oldPasswordButton = useMemo(() => {
    return {
      icon: showOldPassword ? imageURL.openedeye : imageURL.closedeye,
      onClick: () => {
        setShowOldPassword(!showOldPassword);
      },
    };
  }, [showOldPassword]);

  useEffect(() => {
    PasswordPolicyList();
    systemPasswordLogin();
  }, []);

  return (
    <div>
      {isChangePassword && (
        <Modal
          modalConfig={modalConfigChangePWD}
          wrapperAttr={popupAttributesChangePWD}
        >
          <div className="m-l-confirmation-dialogbox-wrap">
            <div className="m-l-confirm-dialogbox-header m-l-cmn-modal-header">
              <div className="m-l-hdr-mdl-title">
                {t('labels.changePassword')}
              </div>
              {systemPasswordLogin() ? (
                <Button
                  icon="close"
                  onClick={handleCancelClickChangePWD}
                ></Button>
              ) : null}
            </div>
            <div className="m-l-confirm-dialogbox-body">
              <ScrollView width="100%" height="100%">
                <div className="m-l-pwd-chng-wrap">
                  <form>
                    <div className="m-c-form-group mb-btm-space-24">
                      <label className="m-l-input-label">
                        {t('labels.oldPassword')}
                      </label>
                      <ValidationGroup ref={validationGroupOldPassword}>
                        <TextBox
                          className="m-c-input-control m-c-input-password"
                          mode={showOldPassword ? 'text' : 'password'}
                          value={oldpassword}
                          onValueChanged={(e) => {
                            setOldPassword(e.value);
                          }}
                          validationMessageMode="always"
                        >
                          <TextBoxButton
                            name="password"
                            location="after"
                            options={oldPasswordButton}
                            // tabindex="-1"
                          />
                          <Validator
                            validationRules={[
                              {
                                type: 'required',
                                message: t('errors.required'),
                              },
                            ]}
                          />
                        </TextBox>
                      </ValidationGroup>
                    </div>
                    <div className="m-c-form-group mb-btm-space-24">
                      <div className="m-l-label-icon-wrap">
                        <label className="m-l-input-label">
                          {t('labels.newPassword')}
                        </label>
                        <div className="m-l-tooltip-show-icon" id="info">
                          <icons.Iconinfo />
                        </div>
                      </div>
                      <Tooltip
                        target="#info"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        contentRender={renderContent}
                        position="top"
                      />
                      <ValidationGroup ref={validationGroupNewPassword}>
                        <TextBox
                          className="m-c-input-control m-c-input-password"
                          mode={showNewPassword ? 'text' : 'password'}
                          value={newpassword}
                          maxLength={32}
                          onValueChanged={(e) => {
                            setNewPassword(e.value);
                          }}
                          validationMessageMode="always"
                        >
                          <TextBoxButton
                            name="password"
                            location="after"
                            options={newPasswordButton}
                          />
                          <Validator
                            validationRules={[
                              {
                                type: 'required',
                                message: t('errors.required'),
                              },
                              passwordpolicyList[0]?.settingValue && {
                                type: 'stringLength',
                                min: passwordpolicyList[0]?.settingValue,
                                message:
                                  t('labels.minPassword') +
                                  '' +
                                  passwordpolicyList[0]?.settingValue +
                                  t('labels.characters'),
                              },
                              {
                                type: 'custom',
                                validationCallback: validateNewPassword(),
                                message: t('errors.passwordMatchError2'),
                              },
                            ]}
                          />
                        </TextBox>
                      </ValidationGroup>
                    </div>
                    <div className="m-c-form-group mb-btm-space-24">
                      <label className="m-l-input-label">
                        {t('labels.confirmPassword')}
                      </label>
                      <ValidationGroup ref={validationGroupConfirmPassword}>
                        <TextBox
                          className="m-c-input-control m-c-input-password"
                          mode={showConfirmPassword ? 'text' : 'password'}
                          value={confirmpassword}
                          maxLength={32}
                          onValueChanged={(e) => {
                            setConfirmPassword(e.value);
                          }}
                          validationMessageMode="always"
                        >
                          <TextBoxButton
                            name="password"
                            location="after"
                            options={confirmPasswordButton}
                          />
                          <Validator
                            validationRules={[
                              {
                                type: 'required',
                                message: t('errors.required'),
                              },
                              {
                                type: 'custom',
                                validationCallback: validatePassword(),
                                message: t('errors.passwordMatchError'),
                              },
                            ]}
                          />
                        </TextBox>
                      </ValidationGroup>
                    </div>
                  </form>
                </div>
              </ScrollView>
            </div>
            <div className="m-l-chp-mdl-footer">
              <Button
                className="app-c-btn app-c-btn--primary"
                onClick={SuccessPwdChange}
              >
                {t('labels.changePassword')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {/* ----------------- password change success modal --------------------------------*/}
      <Modal
        modalConfig={modalConfigSuccess}
        wrapperAttr={popupAttributesConfigSuccess}
      >
        <div className="m-l-confirmation-dialogbox-wrap">
          <div className="m-l-confirm-dialogbox-header">
            <Button icon="close" onClick={handleCancelConfigSuccess}></Button>
          </div>
          <br></br>
          <div className="m-l-confirm-dialogbox-body">
            <ScrollView width="100%" height="100%">
              <div className="m-l-confirm-dialogbox-title-block">
                <img
                  src={imageURL.successIconlg}
                  className="img-responsive"
                  alt="success icon"
                />
              </div>
              <div className="m-l-status-message">
                {t('labels.changePasswordSuccess')}
              </div>
            </ScrollView>
          </div>
        </div>
      </Modal>
    </div>
  );
}
