import CheckBox from 'devextreme-react/check-box';
import SelectBox from 'devextreme-react/select-box';
import Validator, { CustomRule } from 'devextreme-react/validator';
import React, { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Entity } from '../../../enums/entity-enum';
import { IEntityAttribute, IMasterEntity } from '../../../types/master-data';

export default function EntityAttributeValueFields(props: {
  index: number;
  nestedIndex: number;
  entities: IMasterEntity[];
  attributes: IEntityAttribute[];
  renderParent: any;
  customRequiredValidation: any;
}) {
  const {
    index,
    nestedIndex,
    entities,
    attributes,
    renderParent,
    customRequiredValidation,
  } = props;

  const { t } = useTranslation();

  const { control, getValues, setValue } = useFormContext();

  const [forceRender, setForceRender] = useState(false);

  const valueChanged = (e: any, field: any) => {
    field.onChange(e.value);
  };

  const onChangeEntity = (e: any, field: any) => {
    valueChanged(e, field);
    setValue(
      `groups[${nestedIndex}].ruleConditions[${index}].valueAttributeId`,
      null
    );
    setForceRender(!forceRender);
  };

  const onChangeAttribute = (e: any, field: any) => {
    valueChanged(e, field);
    setValue(
      `groups[${nestedIndex}].ruleConditions[${index}].canValueUserEnter`,
      false
    );
    setForceRender(!forceRender);
  };

  const onChangeIsEntity = (e: any, field: any) => {
    valueChanged(e, field);
    setValue(
      `groups[${nestedIndex}].ruleConditions[${index}].valueEntityId`,
      null
    );
    setValue(
      `groups[${nestedIndex}].ruleConditions[${index}].valueAttributeId`,
      null
    );
    setValue(
      `groups[${nestedIndex}].ruleConditions[${index}].canValueUserEnter`,
      false
    );
    renderParent();
  };

  const attributeDataSource = useMemo(() => {
    return attributes.filter(
      (attribute) =>
        attribute.masterEntityId ===
        getValues().groups[nestedIndex].ruleConditions[index].valueEntityId
    );
  }, [
    getValues().groups[nestedIndex].ruleConditions[index].valueEntityId,
    attributes,
  ]);

  const entityDataSource = useMemo(() => {
    return entities.filter(
      (entity) =>
        entity.entityName !== Entity.Incident &&
        entity.entityName !== Entity.PSC
    );
  }, [entities]);

  const disableCanUserEnter = useMemo(() => {
    return !attributes.find(
      (item) =>
        item.masterAttributeId ===
        getValues().groups[nestedIndex].ruleConditions[index].valueAttributeId
    )?.isEditable;
  }, [
    attributes,
    getValues().groups[nestedIndex].ruleConditions[index].valueAttributeId,
  ]);

  return (
    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 m-l-row-custom-column m-l-custom-sixteen-space-col">
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 m-l-row-custom-column m-l-custom-sixteen-space-col">
          <div className="mb-btm-space m-c-form-group">
            <div className="m-l-default-checkbox-unit">
              <label className="m-l-input-label">{t('labels.entity')}</label>
              <div className="m-c-checkbox">
                {' '}
                <Controller
                  name={`groups[${nestedIndex}].ruleConditions[${index}].isEntityValue`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <CheckBox
                        elementAttr={{
                          'data-testid': 'isEntity',
                        }}
                        name="isEntity"
                        value={field.value}
                        onValueChanged={(e) => onChangeIsEntity(e, field)}
                      />
                      <span className="m-l-checkbox--small-label">
                        {t('labels.entity')}
                      </span>
                    </>
                  )}
                />
              </div>
            </div>
            <Controller
              name={`groups[${nestedIndex}].ruleConditions[${index}].valueEntityId`}
              control={control}
              render={({ field }) => (
                <SelectBox
                  name="valueEntityId"
                  searchEnabled={true}
                  valueExpr="masterEntityId"
                  displayExpr="displayName"
                  value={field.value}
                  elementAttr={{ 'data-testid': 'valueEntityId' }}
                  dataSource={entityDataSource}
                  className="m-c-input-control m-c-select-box"
                  onValueChanged={(e) => onChangeEntity(e, field)}
                  validationMessageMode="always"
                >
                  <Validator>
                    <CustomRule
                      reevaluate={true}
                      message={t('errors.required')}
                      validationCallback={(params: any) =>
                        customRequiredValidation(params, index)
                      }
                    />
                  </Validator>
                </SelectBox>
              )}
            />
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 m-l-row-custom-column m-l-custom-sixteen-space-col">
          <div className="mb-btm-space m-c-form-group">
            <div className="m-l-default-checkbox-unit">
              <label className="m-l-input-label">{t('labels.attribute')}</label>
              <div className="m-c-checkbox">
                {' '}
                <Controller
                  name={`groups[${nestedIndex}].ruleConditions[${index}].canValueUserEnter`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <CheckBox
                        elementAttr={{
                          'data-testid': 'canValueUserEnter',
                        }}
                        name="canValueUserEnter"
                        value={field.value}
                        disabled={disableCanUserEnter}
                        onValueChanged={(e) => valueChanged(e, field)}
                      />
                      <span className="m-l-checkbox--small-label">
                        {t('labels.canUserEnter')}
                      </span>
                    </>
                  )}
                />
              </div>
            </div>
            <Controller
              name={`groups[${nestedIndex}].ruleConditions[${index}].valueAttributeId`}
              control={control}
              render={({ field }) => (
                <SelectBox
                  name="valueAttributeId"
                  searchEnabled={true}
                  displayExpr="displayName"
                  valueExpr="masterAttributeId"
                  value={field.value}
                  elementAttr={{ 'data-testid': 'valueAttributeId' }}
                  dataSource={attributeDataSource}
                  className="m-c-input-control m-c-select-box"
                  onValueChanged={(e) => onChangeAttribute(e, field)}
                  validationMessageMode="always"
                  dropDownOptions={{
                    resizeEnabled: true,
                    width: '500px',
                  }}
                >
                  <Validator>
                    <CustomRule
                      reevaluate={true}
                      message={t('errors.required')}
                      validationCallback={(params: any) =>
                        customRequiredValidation(params, index)
                      }
                    />
                  </Validator>
                </SelectBox>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
